import { useState } from 'react';
import { TextField } from '@mui/material';

import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import SimpleSelect from 'components/common/SimpleSelect';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SelectPhenomenons from 'modules/React/utils/components/SelectPhenomenons';

import RenderMap from './RenderMap';

import type { PhenomenonsItem } from '../../../types';

type SymbolItem = { id: string; name: string };

const symbols: SymbolItem[] = [
    { id: '<', name: '<' },
    { id: '<=', name: '<=' },
    { id: '>', name: '>' },
    { id: '>=', name: '>=' },
    { id: '=', name: '=' },
];

interface PhenomenonFormProps {
    isOpen: boolean;
    onClose: () => void;
    item?: PhenomenonsItem;
    onAccept: (item: PhenomenonsItem) => void;
}

const PhenomenonForm = ({ isOpen, onClose, item, onAccept }: PhenomenonFormProps) => {
    const validation = useValidation();

    const [data, setData] = useState({
        phenomenon: item?.phenomenon || null,
        phenomenon_id: item?.phenomenon_id || null,
        value: item?.value || '',
        entity_id: item?.entity_id || null,
    });

    const [condition, setCondition] = useState(() => symbols.find((symbol) => symbol.id === item?.condition) || null);

    const handleSave = () => {
        const prepareData = removeEmptyFields({ ...data, condition: condition?.id });

        onAccept(prepareData as PhenomenonsItem);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={item ? 'Изменить явление' : 'Добавить явление'}
            noPadding
            medium={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: !data.phenomenon_id || !condition || !data.value || !data.entity_id
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <SelectPhenomenons
                    selected={data.phenomenon as any}
                    onChange={(value: any) => setData({
                        ...data,
                        phenomenon: value || {},
                        phenomenon_id: value?.id || null,
                        entity_id: null
                    })}
                    required
                    error={!data?.phenomenon_id}
                />
                <div className="row">
                    <FormInfoWrapper
                        className="row__item block"
                        style={{ marginTop: '0.5rem' }}
                        helperText={validation.get('condition')}
                        error={validation.isKey('condition')}
                    >
                        <SimpleSelect
                            label={'Условие'}
                            options={symbols}
                            selected={condition}
                            onChange={(value) => setCondition(value as SymbolItem | null)}
                            renderLabel={(option) => option?.name || ''}
                            required
                            error={!condition}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="row__item block"
                        helperText={validation.get('value')}
                        error={validation.isKey('value')}
                    >
                        <TextField
                            label={'Значение'}
                            value={data.value || ''}
                            onChange={(e) => setData({ ...data, value: e.target.value })}
                            name={'value'}
                            type={'number'}
                            required={true}
                            error={!data?.value}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        className="row__item block"
                        helperText={validation.get('unit')}
                        error={validation.isKey('unit')}
                    >
                        <TextField
                            label={'Ед.изм.'}
                            value={data.phenomenon?.unit || ''}
                            disabled
                        />
                    </FormInfoWrapper>
                </div>
                <RenderMap
                    url={data.phenomenon?.api}
                    microservice={data.phenomenon?.microservice}
                    service={data.phenomenon?.service}
                    entity_id={data.entity_id}
                    onChange={(value: any) => setData({ ...data, entity_id: value })}
                />
            </form>
        </Modal>
    );
};

export default PhenomenonForm;
