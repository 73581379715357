import { useState } from 'react';
import { useDispatch } from 'react-redux';

import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { createResponseScenario, editResponseScenario } from 'modules/React/redux/actions';
import PassportTabs from 'components/common/PassportTabs';

import BasicData from './BasicData';
import Operations from './Operations';

import type { ResponseScenario } from '../types';

interface FormModalProps {
    isOpen: boolean;
    onClose: () => void;
    isNew?: boolean;
    isCopy?: boolean;
    item?: ResponseScenario;
    reloadList: () => void;
}

const FormModal = ({ 
    isOpen, 
    onClose, 
    isNew = false, 
    isCopy = false, 
    item, 
    reloadList 
}: FormModalProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        name: isCopy ? '' : item?.name || '',
        operation_list: item?.operation_list || [],
        event: item?.event || null,
        event_id: item?.event_id || null,
        category: item?.category || null,
        category_id: item?.category_id || null,
        keyword_list: item?.keyword_list || [],
        keyword_id_list: item?.keyword_id_list || [],
        event_object_list: item?.event_object_list || [],
        event_object_id_list: item?.event_object_id_list || [],
        is_typical: item?.is_typical || false,
        attachments: item?.attachments || [],
        comment: item?.comment || '',
        types: item?.types?.length ? item.types[0] : null,
        factors: item?.factors || null,
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (name: string, value: any) => {
        setFormData(prev => ({ ...prev, [name]: value }));
        validation.deleteKey(name);
    };

    const onSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        const preparedData = {
            ...formData,
            category_id: formData.category?.id || null,
            event_id: formData.event?.id || null,
            event_object_id_list: formData.event_object_list.map(({ id }) => id),
            keyword_id_list: formData.keyword_list.map(({ id }) => id),
            types: [{ id: formData.types?.id }],
            factors: formData.factors?.map(el => ({ id: el.id })),
        };

        isNew || isCopy
            ? dispatch(createResponseScenario(preparedData, callback))
            : dispatch(editResponseScenario(item?.id, preparedData, callback));
    };

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.CREATE : isCopy ? titles.COPY : titles.EDIT}
            onClose={onClose}
            noPadding={true}
            medium={true}
            heightMedium={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                        disabled: !formData?.name || !formData.types?.id
                    }
                ]}
            />}
        >
            <PassportTabs tabs={[
                {
                    value: 'basicData',
                    label: titles.BASIC_DATA,
                    icon: <i className="fal fa-info-square"/>,
                    contentComponent: <BasicData
                        formData={formData as ResponseScenario}
                        handleChange={handleChange}
                    />
                },
                {
                    value: 'operations',
                    label: titles.OPERATIONS,
                    icon: <i className="fas fa-th"/>,
                    contentComponent: <Operations
                        formData={formData as ResponseScenario}
                        handleChange={handleChange}
                    />
                }
            ]}/>
        </Modal>
    );
};

export default FormModal;
