import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { useDebounce } from '../../../../helpers/hooks';
import { authSelectors } from '../../../../redux/Auth';
import { dadataSelectors } from '../../../../redux/Dadata';
import getAddressStringFromObject from '../LoadAddressByCoords/getAddressStringFromObject';
import {
    loadAddressAreasAutocomplete,
    loadedAddressAreasAutocomplete,
    loadedAddressCitiesAutocomplete,
    loadedAddressHousesAutocomplete,
    loadedAddressStreetsAutocomplete,
    loadGeocodeAddressItem
} from '../../../../redux/Dadata/actions';
import createPointGJ from '../../../MapComponents/leaflet/helpers/createPointGJ';
import FormButtonsComponent from '../../../common/FormButtons';

// выбрать адрес из списка
const AddressList = ({
    // значение
    address,
    //address_text: inputValue = '',
    // функция изменения
    onChange = () => {},
    // обезательно
    required,
    returnAddressText = true,
    returnLatLon = false,
    returnGeometry = false,
    readOnly = false,
}) => {
    const dispatch = useDispatch();
    const project = useSelector(authSelectors.project);
    const regionProject = project?.nominatim_region || '';
    const cityProject = project?.nominatim_city || '';

    const [openAreasList, setOpenAreasList] = useState(false);
    const [openCitiesList, setOpenCitiesList] = useState(false);
    const [openStreetsList, setOpenStreetsList] = useState(false);
    const [openHousesList, setOpenHousesList] = useState(false);
    const addressAreaslist = useSelector(dadataSelectors.addressAreaslist);
    const addressCitieslist = useSelector(dadataSelectors.addressCitieslist);
    const addressStritslist = useSelector(dadataSelectors.addressStritslist);
    const addressHouseslist = useSelector(dadataSelectors.addressHouseslist);

    const ref = useRef({
        area: '',
        city: '',
        street: '',
        house: ''
    });

    const [area, setArea] = useState(regionProject);
    const [city, setCity] = useState(cityProject);
    const [street, setStreet] = useState('');
    const [house, setHouse] = useState('');

    useEffect(() => {
        if (Object.keys(address).length > 0) {
            setArea(address?.region || '');
            setCity(address?.city_name || '');
            setStreet(address?.street || '');
            setHouse(address?.house || '');
        }
    }, [address]);

    useEffect(() => {
        loadListAreas();
    }, []);

    const loadData = (params, successCallback) =>
        dispatch(loadAddressAreasAutocomplete(params, (data) => {
            dispatch(successCallback(data));
        }));

    const loadListAreas = () => {
        const params = { area: regionProject, limit: 1 };
        loadData(params, loadedAddressAreasAutocomplete);
    };

    const loadListSities = () => {
        const params = { area: addressAreaslist[0].id, city_name: city };
        loadData(params, loadedAddressCitiesAutocomplete);
        setOpenCitiesList(true);
    };

    const loadListStrits = () => {
        const params = {
            area: addressAreaslist[0].id,
            city_name: ref.current.city.id,
            street
        };
        loadData(params, loadedAddressStreetsAutocomplete);
        setOpenStreetsList(true);
    };

    const loadListHouses = () => {
        const params = {
            area: addressAreaslist[0].id,
            city_name: ref.current.city.id,
            street: ref.current.street.id,
            house
        };
        loadData(params, loadedAddressHousesAutocomplete);
        setOpenHousesList(true);
    };

    const loadedItem = (address) => {
        if (address?.city_name /*&& address?.street*/) {
            const ret = {
                address,
            };

            setArea(address?.region || '');
            setCity(address?.city_name || '');
            setStreet(address?.street || '');
            setHouse(address?.house || '');

            if (returnAddressText) {
                ret.address_text = getAddressStringFromObject(address || {});
            }

            if (
                returnLatLon
                && address?.geo_lat
                && address?.geo_lon
            ) {
                ret.lat = address?.geo_lat;
                ret.lon = address?.geo_lon;
            }

            if (
                returnGeometry
                && address?.geo_lat
                && address?.geo_lon
            ) {
                ret.geometry = createPointGJ(address?.geo_lat, address?.geo_lon);
            }

            onChange(ret);
        }
    };
    const fetchItem = (address) => dispatch(loadGeocodeAddressItem(address, loadedItem));
    const fetchListDebounce = useDebounce(fetchItem, 2000);

    // из its/address
    // const geocodeAddress = useLoadByAddress(loadedItem);
    // const fetchList = (address) => geocodeAddress.load(address);
    // const fetchListDebounce = useDebounce(fetchList, 1000);

    const handleChange = (set) => (e) => {
        const { value } = e.target;
        set(value);
    };

    const onClick = (set, item, field) => {
        ref.current[field] = item;
        set(item.name);
        fetchListDebounce([
            area || '',
            city || '',
            street || '',
            house || ''
        ]
            .reduce((res, i) => i ? [...res, i] : res, [])
            .join(', '));

        setOpenAreasList(false);
        setOpenCitiesList(false);
        setOpenStreetsList(false);
        setOpenHousesList(false);
    };

    const AddressList = ({ list, onClick }) => (
        <Paper style={{ padding: 8, zIndex: 99, top: 40, width: '100%' }}>
            {list.map((item, index) => {
                return (
                    <div key={`${item.name}_${index}`}>
                        <FormButtonsComponent
                            justButton
                            buttons={[
                                {
                                    onClick: () => onClick(item, address),
                                    name: item.name,
                                }
                            ]}
                            disabled={readOnly}
                        />
                    </div>
                );
            })}
        </Paper>
    );

    const adornment = (query, bool, onClose) => {
        const adornment = {};
        if (query.length > 0 && bool) {
            adornment.endAdornment = (
                <InputAdornment position="end">
                    <IconButton
                        onClick={onClose}
                        size="small"
                        title="Свернуть"
                    >
                        <ExpandLess fontSize="inherit"/>
                    </IconButton>
                </InputAdornment>
            );
        }
        return adornment;
    };

    return (
        <>
            <FormControl fullWidth required className="block" variant="outlined" style={{ position: 'relative' }}>
                <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Область"
                    name="address_text"
                    required={required}
                    value={area}
                    onChange={handleChange(setArea)}
                    onKeyUp={() => setOpenAreasList(true)}
                    InputProps={adornment(area, openAreasList, () => setOpenAreasList(false))}
                    disabled={readOnly}
                />
                {(openAreasList && !!area.length)
                && <AddressList
                    list={addressAreaslist}
                    onClick={(value) => onClick(setArea, value, 'area')}
                />}
            </FormControl>

            <FormControl fullWidth required className="block" variant="outlined" style={{ position: 'relative' }}>
                <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Город"
                    name="address_text"
                    required={required}
                    value={city}
                    onChange={handleChange(setCity)}
                    onKeyUp={loadListSities}
                    InputProps={adornment(city, openCitiesList, () => setOpenCitiesList(false))}
                    disabled={readOnly || !area?.length}
                />
                {(openCitiesList && !!city.length)
                && <AddressList
                    list={addressCitieslist}
                    onClick={(value) => onClick(setCity, value, 'city')}
                />}
            </FormControl>

            <FormControl fullWidth required className="block" variant="outlined">
                <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Улица"
                    name="address_text"
                    required={required}
                    value={street}
                    onChange={handleChange(setStreet)}
                    onKeyUp={loadListStrits}
                    InputProps={adornment(street, openStreetsList, () => setOpenStreetsList(false))}
                    disabled={readOnly || !city?.length}
                />
                {(openStreetsList && !!street.length)
                && <AddressList
                    list={addressStritslist}
                    onClick={(value) => onClick(setStreet, value, 'street')}
                />}
            </FormControl>

            <FormControl fullWidth required className="block" variant="outlined">
                <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    label="Дом"
                    name="address_text"
                    required={required}
                    value={house}
                    onChange={handleChange(setHouse)}
                    onKeyUp={loadListHouses}
                    InputProps={adornment(house, openHousesList, () => setOpenHousesList(false))}
                    disabled={readOnly | !street?.length}
                />
                {(openHousesList && !!house.length)
                && <AddressList
                    list={addressHouseslist}
                    onClick={(value) => onClick(setHouse, value, 'house')}
                />}
            </FormControl>
        </>
    );
};

export default AddressList;
