import * as types from './types';
import { parseToken } from './helper';

// const user = cookie.load('authUser') || null;
const user = JSON.parse(localStorage.getItem('authUser'));
const authSuperUser = JSON.parse(localStorage.getItem('authSuperUser'));

const initialState = {
    loading: false,
    authUser: user ? parseToken(user) : null,
    authSuperUser: authSuperUser ? parseToken(authSuperUser) : null,
    project: {},
    services: [],
    permissionList: [],
    mapLayers: [],
    userFilters: null,
    loadingUserFilters: false,
    loadingButton: false,
    accessTemplate: {},
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADING:
            return {
                ...state,
                loading: payload,
            };

        case types.SAVE_USER_DATA: {
            const { authUser } = state;
            return {
                ...state,
                authUser: {
                    ...payload,
                    info: authUser?.info
                },
            };
        }

        case types.PUT_USER_NAME:
            return {
                ...state,
                authUser: {
                    ...state.authUser,
                    info: {
                        ...state.authUser?.info,
                        ...payload,
                    },
                },
            };

        case types.SAVE_CURRENT_PROJECT:
            return {
                ...state,
                project: payload,
            };

        case types.SIGNOUT_USER: {
            return {
                ...state,
                authUser: null,
            };
        }

        // получили данные авторизованного пользователя
        case types.SAVE_AUTH_USER_DATA: {
            const { authUser } = state;
            return {
                ...state,
                authUser: {
                    ...authUser,
                    info: {
                        ...authUser?.info,
                        ...payload,
                    },
                },
            };
        }

        case types.SAVE_USER_SERVICES: {
            return {
                ...state,
                services: payload,
            };
        }

        case types.SET_PERMISSIONS: {
            return {
                ...state,
                permissionList: payload,
            };
        }

        case types.LOADED_MAP_LAYER_LIST: {
            return {
                ...state,
                mapLayers: payload,
            };
        }

        case types.CHANGED_MAP_LAYER: {
            if (state?.authUser?.info?.map_layer) {
                return {
                    ...state,
                    authUser: {
                        ...state?.authUser,
                        info: {
                            ...state?.authUser?.info,
                            map_layer: {
                                ...state?.authUser?.info?.map_layer,
                                id: payload
                            }
                        }
                    },
                };
            }
            return state;
        }
        case types.LOADING_USER_FILTERS: {
            return {
                ...state,
                loadingUserFilters: payload
            };
        }
        case types.LOADED_USER_FILTERS: {
            return {
                ...state,
                userFilters: payload
            };
        }

        case types.SET_SUPER_USER: {
            return {
                ...state,
                authSuperUser: payload,
            };
        }

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload
            };
        }
        case types.LOADED_ACCESS_TEMPLATE: {
            return {
                ...state,
                accessTemplate: {
                    ...state.accessTemplate,
                    ...payload,
                }
            };
        }
        case types.CLEAR_ACCESS_TEMPLATE: {
            return {
                ...state,
                accessTemplate: {}
            };
        }

        default:
            return state;
    }
}
