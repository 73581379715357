const dtpTitles = {
    DTP: 'ДТП',
    EDIT_DTP_TYPE: 'Редактировать тип ДТП',
    EDIT_COLOR_DTP_ICON: 'Изменить цвет иконки',
    DTP_TYPE: 'Тип ДТП',
    DTP_TYPES: 'Типы ДТП',
    COUNT_DTP_MEMBERS:  'Количество участников',
    COUNT_DTP_WOUNDED: 'Количество раненых',
    COUNT_DTP_DEAD: 'Количество погибших',
    COUNT_DTP_TS:  'Количество ТС',
    DTP_KM: 'Километр (если авария на дороге)',
    DTP_M: 'Метр (если авария на дороге)',
    DTP_DESCRIPTION: 'Описание ДТП',
    SCHEMA_DTP: 'Схема ДТП',
    DTP_DATA_SOURCE: 'Источник загрузки данных',
    ROAD: 'Дорога',
    ROAD_VALUE: 'Значение дороги',
    ROAD_CATEGORY: 'Категория дороги',
    ROAD_CONDITION: 'Состояние проезжей части',
    STREET_CATEGORY: 'Категория улицы',
    WEATHER: 'Погода',
    LIGHTING: 'Освещение',
    CHANGES_DRIVING: 'Изменения в режиме движения',
    INTERSECTION_STREET : 'Улица пересечения',
    DATE_TIME_DTP: 'Дата, время ДТП',
    SEVERITY_AFTER_DTP: 'Состояние после ДТП',
    RELATIVE_EVENT: 'Связанное событие',
    COUNT_DTP_WOUNDED_KIDS: 'Количество раненых детей',
    COUNT_DTP_DEAD_KIDS: 'Количество погибших детей',
    DTP_COUNT: 'Всего ДТП',
    DTP_NUMBER: 'Номер ДТП',
    PARTICIPANT_CATEGORY: 'Категория участника',
    TS: 'ТС',
    LEAVING_SCENE: 'Сведения об оставлении места ДТП',
    FIO: 'ФИО участника',
    BIRTH_YEAR: 'Год рождения',
    GENDER: 'Пол',
    DIRECT_VIOLATIONS: 'Непосредственные нарушения ПДД',
    ASSOCIATED_VIOLATIONS: 'Сопутствующие нарушения ПДД',
    FASTENED: 'Использовался ли ремень',
    TYPE_OF_CHILD_RESTRAINT: 'Тип детского удерживающего устройства',
    INTOXICATION: 'Степень опьянения',
    EXPERIENCE: 'Водительский стаж',
    PLACES_OF_DAMAGE: 'Места повреждения',
    YEAR_OF_MANUFACTURE: 'Год выпуска',
    TECHNICAL_PROBLEMS: 'Технические неисправности',
    DRIVE_LOCATION_TYPE: 'Расположение руля, тип привода',
    OWNERSHIP_FORM: 'Форма собственности',
    DEVICE_TRAFFIC_LIGHT_CROSSROAD: 'Объекты УДС на месте (светофор на перекрестке)',
    DEVICE_TRAFFIC_LIGHT_CROSSWALK: 'Объекты УДС на месте (светофор на пешеходном переходе)',
    ROAD_OBJECTS_AROUND: 'Объекты УДС вблизи места ДТП',
    ROAD_DEFECTS: 'Недостатки транспортно-эксплуатационного содержания УДС',
    DRIVING_MODE_INFLUENCE_FACTORS: 'Факторы, оказывающие влияние на режим движения',
    MANUAL_EDIT: 'Ручное редактирование',
    INCIDENTS: 'Инциденты'
};

export default dtpTitles;
