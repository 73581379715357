import { keyBy } from 'lodash';

import * as types from './types';

/**
 * Reducers
 * */
const initialState = {
    list: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    listLoading: false,
    listFilter: {},

    sidebar: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    sidebarLoading: false,

    // полигон
    polygon: [],
    polygonLoading: false,

    // фильтры
    filters: {},
    // активный
    active: {},

    statuses: [],
    types: [],

    traffic: [],
    trafficLoading: false,

    // report
    report: [],
    loadingReport: false,
    filterReport: {},
    reportGroups: [],
    reportTypes: [],

    transportFlow: {},
    loadingTransportFlow: false,
    transportFlowGroups: [],
    // transportFlowTypes: [],
    throughputLoading: false,
    throughputData: {},

    heatMap: [],
    heatMapLoading: false,
    heatMapFilter: {},
    showcase: {},
    loadingShowcase: false,
    reportIndicators: {},
    loadingReportIndicators: false,
    filterReportIndicators: {},
    listByRadius: [],
    loadingLogicalPuid: false,
    savedLogicalPuid: false,

    puidByRadius: [],
    loadingPpuidByRadius: false,

    settings: { data: [] },
    loadingSettings: false,

    settingsGroup: { data: [] },
    loadingSettingsGroup: false,

    loadingButton: false,
    reportStatisticType: [],
    reportTimeSlot: [],
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.SAVE_POLYGON: {
            // const newData = Object.keys(payload || {}).reduce((res, item) => ([
            //     ...res,
            //     payload[item],
            // ]), []);

            return {
                ...state,
                polygon: payload,
            };
        }

        case types.LOADING_POLYGON: {
            return {
                ...state,
                polygonLoading: payload,
            };
        }

        case types.LOADED_SIDEBAR: {
            const {
                data,
                replace,
            } = payload;

            if (replace) {
                return {
                    ...state,
                    sidebar: data,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state.sidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.SET_FILTER: {
            return {
                ...state,
                filters: payload,
            };
        }

        case types.LOADED_TRAFFIC: {
            return {
                ...state,
                traffic: payload,
            };
        }

        case types.LOADING_TRAFFIC: {
            return {
                ...state,
                trafficLoading: payload,
            };
        }

        case types.LOADED_LIST: {
            return {
                ...state,
                list: payload,
            };
        }

        case types.LOADING_LIST: {
            return {
                ...state,
                listLoading: payload,
            };
        }

        case types.LOADED_STATUS: {
            return {
                ...state,
                statuses: payload,
            };
        }

        case types.LOADED_TYPES: {
            return {
                ...state,
                types: payload,
            };
        }

        case types.SET_FILTER_LIST: {
            return {
                ...state,
                listFilter: payload,
            };
        }

        // for efficiency
        case types.LOADED_REPORT:
            return {
                ...state,
                report: payload,
            };

        case types.LOADING_REPORT:
            return {
                ...state,
                loadingReport: payload,
            };

        case types.SET_FILTER_REPORT:
            return {
                ...state,
                filterReport: payload,
            };

        case types.LOADED_REPORT_GROUPS:
            return {
                ...state,
                reportGroups: payload,
            };

        case types.LOADED_REPORT_TYPES:
            return {
                ...state,
                reportTypes: payload,
            };
        case types.LOADING_THROUGHPUT:
            return {
                ...state,
                throughputLoading: payload,
            };
        case types.LOADED_THROUGHPUT: {
            return {
                ...state,
                throughputData: payload,
            };
        }

        case types.LOADED_HEAT_MAP: {
            return {
                ...state,
                heatMap: payload,
            };
        }
        case types.LOADING_HEAT_MAP: {
            return {
                ...state,
                heatMapLoading: payload,
            };
        }
        case types.FILTER_HEAT_MAP: {
            return {
                ...state,
                heatMapFilter: payload,
            };
        }

        case types.LOADED_TRANSPORT_FLOW_REPORT: {
            return {
                ...state,
                transportFlow: payload,
            };
        }

        case types.LOADING_TRANSPORT_FLOW_REPORT: {
            return {
                ...state,
                loadingTransportFlow: payload,
            };
        }

        case types.LOADED_TRANSPORT_FLOW_GROUPS: {
            return {
                ...state,
                transportFlowGroups: payload,
            };
        }

        // case types.LOADED_TRANSPORT_FLOW_TYPES: {
        //     return {
        //         ...state,
        //         transportFlowTypes: payload,
        //     };
        // }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.LOADED_REPORT_INDICATORS: {
            return {
                ...state,
                reportIndicators: payload,
            };
        }
        case types.LOADING_REPORT_INDICATORS: {
            return {
                ...state,
                loadingReportIndicators: payload
            };
        }
        case types.SET_FILTER_REPORT_INDICATORS: {
            return {
                ...state,
                filterReportIndicators: payload
            };
        }
        case types.LOADED_TRANSPORT_FLOW_BY_RADIUS: {
            return {
                ...state,
                listByRadius: payload
            };
        }

        case types.LOADING_LOGICAL_PUID: {
            return {
                ...state,
                loadingLogicalPuid: payload
            };
        }

        case types.SAVED_LOGICAL_PUID: {
            return {
                ...state,
                savedLogicalPuid: payload
            };
        }

        case types.REPLACE_POLYGON_SIDEBAR_LOGICAL_PUID: {

            const indexPolygon = state.polygon?.findIndex(({ id }) => id === payload?.id);
            const indexSidebar = state.sidebar?.data?.findIndex(({ id }) => id === payload?.id);

            const returnData = {};

            if (indexPolygon >= 0) {
                returnData.polygon = [
                    ...state.polygon.slice(0, indexPolygon),
                    {
                        ...state.polygon[indexPolygon],
                        ...payload
                    },
                    ...state.polygon.slice(indexPolygon + 1),
                ];
            }
            if (indexSidebar >= 0) {
                returnData.sidebar = {
                    ...state.sidebar,
                    data: [
                        ...state.sidebar?.data?.slice(0, indexSidebar),
                        {
                            ...state.sidebar?.data?.[indexSidebar],
                            ...payload
                        },
                        ...state.sidebar?.data?.slice(indexSidebar + 1),
                    ]
                };
            }

            return {
                ...state,
                ...returnData,
            };
        }

        case types.LOADED_PUID_BY_RADIUS: {
            return {
                ...state,
                puidByRadius: payload,
            };
        }
        case types.LOADING_PUID_BY_RADIUS: {
            return {
                ...state,
                loadingPpuidByRadius: payload
            };
        }
        case types.CLEAR_PUID_BY_RADIUS: {
            return {
                ...state,
                loadingPpuidByRadius: initialState.puidByRadius
            };
        }

        case types.LOADED_SETTINGS:
            return {
                ...state,
                settings: payload
            };

        case types.LOADING_SETTINGS:
            return {
                ...state,
                loadingSettings: payload
            };

        case types.LOADED_SETTINGS_GROUP:
            return {
                ...state,
                settingsGroup: payload
            };

        case types.LOADING_SETTINGS_GROUP:
            return {
                ...state,
                loadingSettingsGroup: payload
            };

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload
            };
        }
        case types.LOADED_REPORT_STATISTIC_TYPES: {
            return {
                ...state,
                reportStatisticType: payload
            };
        }

        case types.LOADED_REPORT_TIME_SLOT: {
            return {
                ...state,
                reportTimeSlot: payload
            };
        }

        default:
            return state;
    }
}
