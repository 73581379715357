import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Information from 'components/common/Information';

const TabInfo = ({ data }) => {
    
    const arrayValue = (value) =>
        Array.isArray(value) && value.length > 0
            ? value.map((item, i) => <div key={i}>{item}</div>)
            : messages.INFO_IS_NOT_FOUND;

    return (
        <Information
            data={data}
            defaultValue={messages.INFO_IS_NOT_FOUND}
            defaultValueClass="info__item"
            returnNull
            title={{
                road: {
                    title: titles.ROAD
                },
                road_purpose_text: {
                    title: titles.ROAD_VALUE
                },
                road_category: {
                    title: titles.ROAD_CATEGORY
                },
                road_condition: {
                    title: titles.ROAD_CONDITION
                },
                street_category: {
                    title: titles.STREET_CATEGORY
                },
                weather: {
                    title: titles.WEATHER
                },
                lighting: {
                    title: titles.LIGHTING
                },
                changes_driving: {
                    title: titles.CHANGES_DRIVING
                },
                device_traffic_light_crossroads: {
                    title: 'Объекты УДС на месте (светофор на перекрестке)'
                },
                device_traffic_light_crosswalk: {
                    title: 'Объекты УДС на месте (светофор на пешеходном переходе)'
                },
                road_objects_around: {
                    title: 'Объекты УДС вблизи места ДТП',
                    value: () => arrayValue(data?.road_objects_around)
                },
                road_defects_text: {
                    title: 'Недостатки транспортно-эксплуатационного содержания улично-дорожной сети',
                    value: () => arrayValue(data?.road_defects_text)
                },
                driving_mode_influence_factors_text: {
                    title: 'Факторы, оказывающие влияние на режим движения',
                    value: () => arrayValue(data?.driving_mode_influence_factors_text)
                },
            }}
        />
    );
};

export default TabInfo;
