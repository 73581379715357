import L from 'leaflet';

import { loadVehicleCategories } from 'redux/TransportPassenger/actions';
import { GeoJson } from 'components/MapComponents/leaflet';
import { MapPopUp, usePopModalListener } from 'components/MapComponents/MapPopUp';
import useTransportCategory, { transportCategoryHelper } from 'helpers/hooks/Transport/useTransportCategory';

import RoutePopUp from './RoutePopUp';
import icons from './icons/icons';

const RouteJson = ({
    item,
    onMap = true,
    disabled = false,
    permissions,
    readOnly = false,
    getTransportCategory,
    isHidePopup = false,
    ...props
}) => {
    const { category_id, road } = item;
    // const getTransportCategory = useTransportCategory(
    //     loadVehicleCategories,
    //     'transportPassenger',
    //     'vehicleCategories'
    // );
    const {
        color,
        getImageTpl,
    } = transportCategoryHelper(getTransportCategory)
        .getCategoryPassenger(category_id, true, true);

    const modalProvider = usePopModalListener(item);

    const handleClick = () => {
        if (isHidePopup === false) modalProvider.setModal(item);

        const json = L.geoJSON(road);
        const bounds = json?.getBounds?.() || null;
        if (bounds?.isValid?.()) props?.map?.fitBounds?.(bounds);
    };

    if(Object.keys(road).length === 0) return null;

    return (
        <>
            <GeoJson
                {...props}
                map={onMap ? props.parent : props.map}
                data={{
                    ...road,
                    style: {
                        color: color,
                        // color: color.fill,
                        weight: 3
                    },
                    properties: {
                        ...road.properties,
                        data: {
                            num: item?.num
                            // ...item
                        }
                    }
                }}
                toolTipTemplate={({ num }) => <div>Маршрут №{num}</div>}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, 0],
                    sticky: true,
                }}
                refreshTooltip={true}
                onClick={handleClick}
                // onClick={() => modalProvider.setModal(item)}
            />

            <MapPopUp
                isOpen={modalProvider.isOpen && isHidePopup === false}
                data={modalProvider.modal}
                icon={() => getImageTpl('img', icons({ fill: '#fff' }))}
                title={({ num = '' }) => `Маршрут №${num}`}
                titleBackgroundColor={color}
                onClose={() => modalProvider.clear()}
            >
                <RoutePopUp
                    permissions={permissions}
                    readOnly={readOnly}
                />
            </MapPopUp>

        </>
    );
};

export default RouteJson;
