import { SetStateAction, useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Checkbox, FormControlLabel } from '@mui/material';

import { loadDtpList, loadDtpTypes, createNewDtp, editDTPStatuses } from 'modules/Dtp/redux/actions';
import { dtpSelectors } from 'modules/Dtp';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import ModalForm from 'components/MapComponents/Layers/Incidents/ModalForm';
import ModalFormPopUp from 'components/MapComponents/Layers/Incidents/PopUp/ModalForm';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormButtonsComponent from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import ReportBlock from 'components/common/Report/ReportBlock';
import PageLayout from 'components/layout/PageLayout';

import Item from './Item';
import Filter from './Filter';
import type { IncidentItem, Params } from './types';


const Incidents = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const loading = useSelector(dtpSelectors.loading);
    const data = useSelector(dtpSelectors.listData) as IncidentItem[];
    const meta = useSelector(dtpSelectors.listMeta);
    const storageLimit = Number(localStorage.getItem('limit')) || 25;

    const typesAllObj = useStoreFromSelectorListToObject(loadDtpTypes, dtpSelectors.dtpTypesData);

    const initialState: Params = {
        street: [],
        house: '',
        selectedTypes: [],
        selectedTypeLoad: [],
        start_date: getStartOf('year'),
        end_date: getEndOf('day'),
        with_wounded: false,
        with_dead: false,
        ids: []
    };

    const [limit, setLimit] = useState(storageLimit);
    const [params, setParams] = useState(initialState);
    const [openModal, setOpenModal] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);

    const [checkedAll, setCheckedAll] = useState(false);
    const [checkedElements, setCheckedElements] = useState<number[]>([]);

    const paramsWithoutEmpty = (filters: Params = params) => removeEmptyFields({
        ...filters,
        dtp_type: filters?.selectedTypes?.map(item => item.id),
        type_load: filters?.selectedTypeLoad?.map(item => item.id),
        start_date: filters.start_date && fullDateTimeWithTimeZone(filters.start_date),
        end_date: filters.end_date && fullDateTimeWithTimeZone(filters.end_date),
        with_dead: filters.with_dead ? '1' : null,
    }) as Params;

    const handleCheckAll = () => {
        if (checkedAll) {
            setCheckedElements([]);
        } else {
            const selectedIds = data.map(item => item.id);
            setCheckedElements(selectedIds);
        }
        setCheckedAll(!checkedAll);
    };

    const handleCheckFalse = () => {
        setCheckedElements([]);
        setCheckedAll(false);
    };

    const handleCheckboxCheck = (e: ChangeEvent<HTMLInputElement>, id: number) => {
        const index = checkedElements.findIndex(el => el === id);
        if (index < 0) {
            setCheckedElements([...checkedElements, id]);
        } else {
            setCheckedElements([...checkedElements.slice(0, index), ...checkedElements.slice(index + 1)]);
            setCheckedAll(false);
        }
    };

    useEffect(() => {
        if (!params) {
            const queryObject = queryString.parse(location.search, { arrayFormat: 'bracket' });
            setParams((previous) => ({
                ...previous,
                ...queryObject
            }));
        }
    }, [location]);

    const reloadList = (closeModal?: (value: SetStateAction<boolean>) => void) => {
        dispatch(loadDtpList(meta?.current_page || 1, limit, params));
        typeof closeModal === 'function' && closeModal(false);
        handleCheckFalse();
    };

    useEffect(() => {
        dispatch(loadDtpList(1, limit, paramsWithoutEmpty()));
    }, [dispatch, params]);

    useEffect(() => {
        dispatch(loadDtpTypes());
    }, [dispatch]);

    const renderContent = () => {
        return data.length > 0 ? (
            <LSContainer
                headers={[
                    { title: '', width: '4%' },
                    { title: '', width: '50px' },
                    { title: titles.ID, width: '8%' },
                    { title: titles.DATE, width: '12%' },
                    { title: titles.TYPE, width: '13%' },
                    { title: titles.ADDRESS, width: '25%' },
                    { title: titles.INTERSECTION_STREET, width: '15%' },
                    { title: titles.INCIDENTS, width: '8%' },
                    { title: titles.ACTIONS, isActions: true }
                ]}
            >
                {data.map((item) => (
                    <Item
                        key={item.id}
                        data={item}
                        types={typesAllObj}
                        reloadList={reloadList}
                        onCheck={handleCheckboxCheck}
                        checked={checkedElements.includes(item.id)}
                    />
                ))}
            </LSContainer>
        ) : (
            !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>
        );
    };

    const getReport = (formats: string[]) => {
        dispatch(loadDtpList(1, limit, { ...paramsWithoutEmpty(), report: 1, formats }));
    };

    return (
        <div>
            <PageLayout
                header="Отчет по ДТП"
                filters={
                    <Filter
                        dtpTypes={typesAllObj.list}
                        setParams={setParams}
                        initialState={initialState}
                        prepareData={paramsWithoutEmpty}
                    />
                }
                content={renderContent}
                loading={loading}
                actionPanel={
                    <FormControlLabel
                        control={<Checkbox checked={checkedAll} onChange={handleCheckAll} />}
                        label={checkedElements.length === limit ? 'Снять отметки' : 'Выбрать все'}
                    />
                }
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                key={'add_button'}
                                positionLeft
                                noPadding
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(!openModal),
                                    }
                                ]}
                            />
                            <ReportBlock 
                                key={'report_block'}
                                //@ts-ignore
                                onGetReport={getReport} reportId={22} 
                            />
                            <FormButtonsComponent
                                key={'change_status'}
                                positionLeft
                                noPadding
                                buttons={[
                                    {
                                        ...buttonsTypes.defaultInfo,
                                        onClick: () => setOpenStatusModal(true),
                                        disabled: checkedElements.length === 0,
                                        name: 'Изменить статус',
                                        size: 'small'
                                    }
                                ]}
                            />
                        </>
                    ),
                    total: meta?.total
                }}
                paginationProps={{
                    loadList: (page, limit) => dispatch(loadDtpList(page, limit, paramsWithoutEmpty())),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openModal && (
                <ModalForm
                    onAccept={(newFields: any) => {
                        dispatch(createNewDtp(newFields, reloadList));
                    }}
                    open={openModal}
                    onClose={() => setOpenModal(!openModal)}
                />
            )}
            {openStatusModal && (
                <ModalFormPopUp
                    open={openStatusModal}
                    onClose={() => setOpenStatusModal(false)}
                    onSave={(status: string) =>
                        dispatch(
                            editDTPStatuses({ ids: checkedElements, new_status: status }, () =>
                                reloadList(setOpenStatusModal)
                            )
                        )
                    }
                />
            )}
        </div>
    );
};

export default Incidents;
