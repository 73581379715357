/**
 * Sagas
 * **/
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import api from './api.methods';
import * as types from './types';

function* loadCompaniesListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.setDadataLoading(true));
    const companiesList = yield call(api.getCompanies, page, limit, query);
    if (companiesList) {
        yield put(actions.loadedDadataCompaniesList(companiesList));
    }
    yield put(actions.setDadataLoading(false));
}

function* loadAddressListSaga({ payload }) {
    const params = {
        source: 'dadata',
        ...payload
    };
    yield put(actions.setDadataLoading(true));
    const companiesList = yield call(api.getAddressList, params);
    if (companiesList) {
        yield put(actions.loadedDadataAddressList(companiesList));
    }
    yield put(actions.setDadataLoading(false));
}

function* loadAutocompleteAddressSaga({ payload }) {
    const params = { source: 'dadata', ...payload };
    yield put(actions.loadingAutoCompleteAddress(true));
    const response = yield call(api.getAddressAutocomplete, params);
    if (response) {
        yield put(actions.loadedAutoCompleteAddress(response.data));
    }
    yield put(actions.loadingAutoCompleteAddress(false));
}


function* loadDadataAddressSaga({ payload }) {
    const params = {
        source: 'nominatim',
        // source: 'dadata',
        ...payload
    };
    yield put(actions.setDadataLoading(true));
    const response = yield call(api.getAddressDadata, params);
    if (response) {
        yield put(actions.loadedDadataAddress(response?.data));
    }
    yield put(actions.setDadataLoading(false));
}

function* loadAddressByCoordinatesSaga({ payload }) {
    const params = payload;
    yield put(actions.setDadataLoading(true));
    const address = yield call(api.getAddressByCoordinates, params);
    if (address) {
        yield put(actions.loadedDadataAddressByCoordinates(address?.data));
    }
    yield put(actions.setDadataLoading(false));
}

function* getAddressByCoordinatesSaga({ payload, fnCallback }) {
    const address = yield call(api.getAddressByCoordinates, payload);
    if (address) {
        yield fnCallback?.(address?.data);
    }
}

function* loadGeocodeAddressSaga({ payload }) {
    const params = {
        source: 'nominatim',
        ...payload
    };
    yield put(actions.loadingGeocodeAddress(true));
    const response = yield call(api.getGeocodeAddress, params);
    if (response) {
        yield put(actions.loadedGeocodeAddress(response));
    }
    yield put(actions.loadingGeocodeAddress(false));
}

function* loadRegionsSaga({ payload }) {
    yield put(actions.loadingRegions(true));
    const response = yield call(api.getRegions, payload);
    if (response?.success) {
        yield put(actions.loadedRegions(response?.data || {}));
    }
    yield put(actions.loadingRegions(false));
}

function* loadGeocodeAddressItemSaga({ payload , callback }) {
    const params = {
        source: 'nominatim',
        ...payload
    };
    yield put(actions.setDadataLoading(true));
    const response = yield call(api.getGeocodeAddressItem, params);
    if (response?.success) {
        callback?.(response?.data || {});
        // yield put(actions.loadedGeocodeAddressItem(response?.data || {}));
    }
    yield put(actions.setDadataLoading(false));
}

function* loadAddressAreasAutocompleteSaga({ payload , callback }) {
    yield put(actions.setDadataLoading(true));
    const response = yield call(api.getAddressAreasAutocomplete, payload);
    if (response?.success) {
        callback?.(response?.data);
    }
    yield put(actions.setDadataLoading(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_DADATA_COMPANIES, loadCompaniesListSaga),
        // takeLatest(types.LOAD_DADATA_ADDRESS, loadAddressListSaga),
        takeLatest(types.LOAD_AUTOCOMPLETE_ADDRESS, loadAutocompleteAddressSaga),
        takeLatest(types.LOAD_DADATA_ADDRESS_LIST, loadAddressListSaga),
        takeLatest(types.LOAD_DADATA_ADDRESS, loadDadataAddressSaga),
        takeLatest(types.LOAD_DADATA_ADDRESS_BY_COORDINATES, loadAddressByCoordinatesSaga),
        takeLatest(types.LOAD_GEOCODE_ADDRESS, loadGeocodeAddressSaga),

        takeLatest(types.LOAD_GEOCODE_ADDRESS_ITEM, loadGeocodeAddressItemSaga),
        takeLatest(types.LOAD_REGIONS, loadRegionsSaga),

        takeLatest(types.LOAD_ADDRESS_AREAS_AUTOCOMPLETE, loadAddressAreasAutocompleteSaga),

        takeLatest(types.GET_ADDRESS_BY_COORDINATES, getAddressByCoordinatesSaga),
    ]);
}
