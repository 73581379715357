import React, { Fragment } from 'react';
import { IconButton, Tooltip } from '@mui/material';

const Prices = ({ tariff, tariffCategories }) => {

    const renderIcons = (type) => {
        const categoryObj = tariffCategories[type];
        
        return <Tooltip title={categoryObj.name} >
            <IconButton>
                {categoryObj.icon.map((el, i) => <Fragment key={'category_' + i}>
                    <i className={el} />{categoryObj.icon?.length - 1 !== i ? '|' : ''}
                </Fragment>)}
            </IconButton>
        </Tooltip>;
    };

    return (
        <div>
            {tariff?.prices?.days?.all.map((item, i) => <div key={'prices_' + i}>
                {renderIcons(item?.type)}: {item?.periods[0].price}₽ - {item?.periods[0].start}-{item?.periods[0].end}
            </div>)}
        </div>
    );
};

export default Prices;
