import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import {
    addWorkorderLogManualStatus,
    editWorkorderLogManualStatus,
    loadWorkorderLogManualStatus, loadWorkorderLogs
} from 'redux/TransportRoad/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

import type { EventContent } from '../types';

interface StatusesModalProps {
    isNew: boolean;
    onClose: () => void;
    isOpen: boolean;
    item: EventContent;
}

const StatusesModal = ({
    isNew,
    onClose,
    isOpen,
    item,
}: StatusesModalProps) => {
    const dispatch = useDispatch();

    const initialState = {
        status_id: item?.manual_status_id || '',
        comment: item?.manual_status_comment || '',
    };

    const [data, setData] = useState(initialState);

    const statuses = useStoreProp(
        loadWorkorderLogManualStatus,
        'transportRoad',
        'workorderManualStatusList'
    );

    const onSave = () => {
        const callback = () => {
            dispatch(loadWorkorderLogs(item.work_order_id));
            onClose();
        };

        if (isNew) {
            dispatch(addWorkorderLogManualStatus(item.id, data, callback));
        } else {
            dispatch(editWorkorderLogManualStatus(item.id, data, callback));
        }
    };

    const onChange = (e: { target: { name: string; value: string; }; }) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    return (
        <Modal
            title={isNew ? titles.ADD_STATUS : titles.EDIT_STATUS}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                        disabled: !data.status_id && !data.comment
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <InputLabel>Выберите статус от диспетчера</InputLabel>
                    <Select
                        value={data.status_id}
                        required
                        onChange={onChange}
                        name="status_id"
                        label="Выберите статус от диспетчера"
                    >
                        {Object.keys(statuses)?.map((key) => (
                            <MenuItem key={key} value={key}>{statuses[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    required={true}
                    className="block"
                    size="small"
                    variant="outlined"
                >
                    <TextField
                        label="Комментарий"
                        variant="outlined"
                        size="small"
                        name="comment"
                        value={data.comment}
                        onChange={onChange}
                    />
                </FormControl>
            </div>
        </Modal>
    );
};

export default StatusesModal;
