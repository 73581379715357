import Form from 'modules/DigitalTwin/Dictionaries/Edges/Form';

const FilterForm = ({ data, onChange }) => {
    return (
        <form
            className="modal__form"
            onSubmit={(e) => { e.preventDefault(); }}
        >
            <Form data={data} onChange={onChange} />
        </form>
    );
};

export default FilterForm;
