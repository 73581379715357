import { Block, Warning } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import Image from 'components/common/Image';
import messages from 'helpers/constants/messages';

const RenderTableCell = ({ event, violationCode = [], setOpenCheckListModal, title, item }) => {

    if (Object.keys(event).length > 0) {
        const { is_warning, photo_ts_thumb } = event;
        // чтобы отобразить комплекс и камеру в модалке, если это событие без материалов
        if (!event.uid) {
            event.complex = item.complex;
            event.complex_serial_number = item.complex?.serial_number;
            event.camera_duplo_code = item.code;
        }

        if (photo_ts_thumb) {
            return (
                <Image
                    style={{ height: '40px', cursor: 'pointer' }}
                    src={photo_ts_thumb}
                    alt="preview"
                    onClick={() => setOpenCheckListModal()}
                />
            );
        } else if (is_warning) {
            return (
                <Tooltip style={{ cursor: 'pointer' }}  title={messages.NO_MATERIALS} onClick={() => setOpenCheckListModal()} >
                    <Warning color="error" fontSize="small" />
                </Tooltip>
            );
        }
    }

    return (
        <Tooltip
            title={violationCode.length === 1 && violationCode[0] === 0
                ? 'Данные о проезде отсутствуют'
                : 'Статья КОАП не прикреплена к текущей распознающей камере по текущему направлению'
            }
        >
            <Block fontSize="small" />
        </Tooltip>
    );
};

export default RenderTableCell;
