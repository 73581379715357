import { useMemo, useState } from 'react';
import { TextField } from '@mui/material';

import { loadOwnerships, loadPlacesOfDamageTypes } from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { getYear, getYearDiff } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import FormButtons,{ buttonsTypes } from 'components/common/FormButtons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import Modal from 'components/common/Modal';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { generateOptions, getValue } from 'components/MapComponents/Layers/Incidents/helper';

const AddEditModal = ({
    isOpen = false, 
    onClose = () => {},
    vehicle,
    index,
    onAccept   
}) => {
    const placesOfDamageTypes = useStoreProp(loadPlacesOfDamageTypes, 'incidents', 'placesOfDamageTypes');
    const ownerships = useStoreProp(loadOwnerships, 'incidents', 'ownerships');

    const placesOfDamageTypesOptions = useMemo(() => generateOptions(placesOfDamageTypes), [placesOfDamageTypes]);
    const ownershipsOptions = useMemo(() => generateOptions(ownerships), [ownerships]);
    

    const initialState = {
        ...vehicle,
        leaving_scene: vehicle?.leaving_scene || null,
        grn: vehicle?.grn || null,
        class_name: vehicle?.class_name || null,
        brand: vehicle?.brand || null,
        model: vehicle?.model || null,
        color: vehicle?.color || null,
        year: vehicle?.year || null,
        places_of_damage: vehicle?.places_of_damage || [],
        technical_issues: vehicle?.technical_issues || null,
        drive_location_type: vehicle?.drive_location_type || null,
        ownership_type: vehicle?.ownership_type || null,
        comment: vehicle?.comment || null,
    };

    const [data, setData] = useState(initialState);

    const onChangeTextField = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value || null
        });
    };

    const handleChange = (name, value) => {
        setData(prev => ({ ...prev, [name]: value || null }));
    };

    const handleSuccess = () => {
        onAccept && (vehicle ? onAccept(index, data) : onAccept(data));
        onClose();
        
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            small
            title={vehicle ? 'Редактировать ТС' : 'Добавить ТС'}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: !data.grn || !data.brand || !data.model
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">
                <div className="block">
                    <TextField
                        label={titles.LEAVING_SCENE}
                        variant="outlined"
                        size="small"
                        name="leaving_scene"
                        value={data?.leaving_scene || ''}
                        onChange={onChangeTextField}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.GRZ}
                        variant="outlined"
                        size="small"
                        name="grn"
                        value={data.grn || ''}
                        onChange={onChangeTextField}
                        error={!data.grn}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.TRANSPORT_TYPE}
                        variant="outlined"
                        size="small"
                        name="class_name"
                        value={data.class_name || ''}
                        onChange={onChangeTextField}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.BRAND}
                        variant="outlined"
                        size="small"
                        name="brand"
                        value={data.brand || ''}
                        onChange={onChangeTextField}
                        error={!data.brand}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.MODEL}
                        variant="outlined"
                        size="small"
                        name="model"
                        value={data.model || ''}
                        onChange={onChangeTextField}
                        error={!data.model}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.COLOR}
                        variant="outlined"
                        size="small"
                        name="color"
                        value={data.color || ''}
                        onChange={onChangeTextField}
                    />
                </div>
                <SingleKeyboardDateTimePicker
                    label={titles.YEAR_OF_MANUFACTURE}
                    className="block"
                    value={String(data.year) || null}
                    onChange={(newDate) => handleChange('year', getYear(newDate))}
                    dateOnly={true}
                    views={['year']}
                    pickerFormat="yyyy"
                    minDate={{ year: getYearDiff(50) }}
                />
                <CommonAutocomplete
                    className="block"
                    multiple
                    label={titles.PLACES_OF_DAMAGE}
                    selected={getValue(placesOfDamageTypes, data?.places_of_damage)}
                    options={placesOfDamageTypesOptions}
                    onChange={(value) => {
                        handleChange('places_of_damage', value.map(el => el.id));
                        handleChange('places_of_damage_text', value.map(el => el.name));
                    }}
                    renderLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    showAsSelect
                    limitTags={1}
                />
                <div className="block">
                    <TextField
                        label={titles.TECHNICAL_PROBLEMS}
                        variant="outlined"
                        size="small"
                        name="technical_issues"
                        value={data.technical_issues || ''}
                        onChange={onChangeTextField}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.DRIVE_LOCATION_TYPE}
                        variant="outlined"
                        size="small"
                        name="drive_location_type"
                        value={data.drive_location_type || ''}
                        onChange={onChangeTextField}
                    />
                </div>
                <CommonAutocomplete
                    className="block"
                    label={titles.OWNERSHIP_FORM}
                    selected={getValue(ownerships, data?.ownership_type)}
                    options={ownershipsOptions}
                    onChange={(value) => handleChange('ownership_type', value?.id)}
                    renderLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    showAsSelect
                />
                <div className="block">
                    <TextField
                        label={titles.COMMENT}
                        variant="outlined"
                        size="small"
                        name="comment"
                        value={data.comment || ''}
                        onChange={onChangeTextField}
                    />
                </div>
            </div>     
        </Modal>
    );
};

export default AddEditModal;
