import L from 'leaflet';

// получение длины линии
export const getDistance = (latLngArray = []) => {
    // сибираем длины по отрезкам (в метрах)
    const distance = latLngArray
        .reduce((res, latlng, index, arr) => arr[index + 1]
            ? res + latlng.distanceTo(arr[index + 1])
            : res
        , 0);
    // ручное конвертирование
    if (distance > 900) {
        return (distance / 1000).toFixed(2) + ' км';
    }
    return (distance).toFixed(2) + ' м';
};

// получение площади полигона
export const getSquare = (latLngArray) => {
    // площадь в метрах
    const area = L.GeometryUtil.geodesicArea(latLngArray);
    // ручное конвертирование
    if (area > 900000) {
        return (area / 1000000).toFixed(2) + ' км²';
    }
    return (area).toFixed(2) + ' м²';
    // конвертирование стандартной функцией
    // return L.GeometryUtil.readableArea(area, 'km');
};

// маркер
export const getMarker = L.divIcon({
    className: 'rules-square-marker',
    iconSize: [14, 14],
    iconAnchor: [7, 7],
    // popupAnchor: [0, -10],
    // tooltipAnchor: [0, -10],
});

// типы
export const rulerTypes = {
    line: 1,
    polygon: 2,
};

export const geometryOptions = {
    color: '#585858', // цвет линии
    weight: 3, // точщина линии
};

export const fnDeleteIndex = (id) => old => {
    const index = old.findIndex((i) => i.id === id);
    return index >= 0
        ? [
            ...old.slice(0, index),
            ...old.slice(index + 1)
        ]
        : old;
};
