/**
 * Sagas
 * **/
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';
import messageTypes from 'redux/Message/messagetypes';
import titles from 'helpers/constants/titles';

import * as actions from './actions';
import api from './api.methods';
import * as types from './types';

// получить полигон
function* loadPolygonSaga({ payload }) {
    const { polygon, filter } = payload;
    yield put(actions.loadingPolygon(true));
    yield put(setMapLoading('parking', true));
    const response = yield call(api.getPolygon, polygon, filter);
    if (response?.success) {
        yield put(actions.loadedPolygon(response?.data || []));
    }
    yield put(actions.loadingPolygon(false));
    yield put(setMapLoading('parking', false));
}

// Получение списка для сайдбара
function* loadSideBarSaga({ payload }) {
    const { page } = payload;
    yield put(actions.loadingSidebar(true));
    const response = yield call(api.getSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedSidebar(response, page === 1));
    }
    yield put(actions.loadingSidebar(false));
}

// Получение списка
function* loadParkingListSaga({ payload }) {
    yield put(actions.loadingParkingList(true));
    const response = yield call(api.getSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedParkingList(response));
    }
    yield put(actions.loadingParkingList(false));
}

// создание
function* createParkSaga({ payload: data, callback }) {
    yield put(actions.setLoading(true));
    const response = yield call(api.createPark, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.setLoading(false));
}
// редактирование
function* editParkSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.setLoading(true));
    const response = yield call(api.editPark, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.setLoading(false));
}
// удаление
function* deleteParkSaga({ payload: id, callback }) {
    yield put(actions.setLoading(true));
    const response = yield call(api.deletePark, id);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.setLoading(false));
}

function* loadStatusesSaga() {
    const response = yield call(api.getStatuses);
    if (response?.success) {
        yield put(actions.loadedStatuses(response?.data));
    }
}
function* loadTypesSaga() {
    const response = yield call(api.getTypes);
    if (response?.success) {
        yield put(actions.loadedTypes(response?.data));
    }
}
function* loadZonesSaga() {
    const response = yield call(api.getZones);
    if (response?.success) {
        yield put(actions.loadedZones(response?.data));
    }
}

function* loadStatusStatisticSaga() {
    const response = yield call(api.getStatusStatistic);
    if (response?.success) {
        yield put(actions.loadedStatusStatistic(response?.data));
    }
}

function* loadTariffListSaga({ payload, callback }) {
    const response = yield call(api.getTariff, payload);
    if (response?.success) {
        callback
            ? callback(response?.data)
            : yield put(actions.loadedTariffList(response?.data));
    }
}

function* loadCategoriesSaga({ payload }) {
    const response = yield call(api.getCategory, payload);
    if (response?.success) {
        yield put(actions.loadedCategories(response?.data));
    }
}

function* loadTariffCategoriesSaga() {
    const response = yield call(api.getTariffCategories);
    if (response?.success) {
        yield put(actions.loadedTariffCategories(response?.data));
    }
}

function* loadTariffDaysSaga() {
    const response = yield call(api.getTariffDays);
    if (response?.success) {
        yield put(actions.loadedTariffDays(response?.data));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_POLYGON, loadPolygonSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSideBarSaga),
        takeLatest(types.LOAD_PARKING_LIST, loadParkingListSaga),
        takeLatest(types.CREATE_PARK, createParkSaga),
        takeLatest(types.EDIT_PARK, editParkSaga),
        takeLatest(types.DELETE_PARK, deleteParkSaga),

        takeLatest(types.LOAD_STATUSES, loadStatusesSaga),
        takeLatest(types.LOAD_TYPES, loadTypesSaga),
        takeLatest(types.LOAD_ZONES, loadZonesSaga),

        takeLatest(types.LOAD_STATUS_STATISTIC, loadStatusStatisticSaga),
        takeLatest(types.LOAD_TARIFF_LIST, loadTariffListSaga),
        takeLatest(types.LOAD_CATEGORIES, loadCategoriesSaga),

        takeLatest(types.LOAD_TARIFF_CATEGORIES, loadTariffCategoriesSaga),
        takeLatest(types.LOAD_TARIFF_DAYS, loadTariffDaysSaga),
    ]);
}
