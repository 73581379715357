import { useCallback, useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, List } from '@mui/material';

import { loadCommutators } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import { filterFunc, parseParamsFromUrl, stringifyParamsForUrl } from 'helpers/doris.control/helper';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import BasicDataForm from './PassportPage/BasicData/BasicDataForm';
import PassportTabs from './PassportPage/PassportTabs';
import Item from './Item';
import Filter from './Filter';

import type { CommutatorItem } from 'types';
import type { FilterParams, FilterState, URLParams } from './types';

const Commutator = () => {
    const dispatch = useDispatch();

    const scrollRef = useRef<Scrollbars>(null);

    const commutator = useSelector(roadNetworksSelectors.commutator);
    const loading_commutator = useSelector(roadNetworksSelectors.loadingCommutator);
    const history = useHistory();
    const location = useLocation();

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const [params, setParams] = useState<FilterParams>({
        page: 1,
        filter: {},
    });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));
    const [openModal, setOpenModal] = useState(false);
    const [currentCommutator, setCurrentCommutator] = useState<CommutatorItem | null>(null);

    const stringifyUrlFunc = (param: URLParams) => {
        history.replace({ ...location, search: stringifyParamsForUrl(param) });
    };

    const handleClick = (item: CommutatorItem) => {
        const { page } = params;
        if (item.id === currentCommutator?.id) {
            stringifyUrlFunc({ page, limit });
        } else {
            stringifyUrlFunc({ page, limit, commutator_id: item.id });
        }
    };

    useEffect(()=> {
        const { page, limit } = parseParamsFromUrl(location.search);
        if (page || limit) {
            const filterParams = filterFunc({ page: Number(page) || 1, limit: Number(limit) || storageLimit });
            setParams((prev)=> ({
                ...prev,
                ...filterParams
            }));
        }
    }, [location.search, storageLimit]);

    useEffect(() => {
        const { commutator_id } = parseParamsFromUrl(location.search);
        const commut = commutator_id ? commutator.data?.find((item: { id: number; }) => item.id === Number(commutator_id)) : null;
        setCurrentCommutator(commut);
    },[location.search, commutator.data]);

    const requestProps = useCallback(() => {
        return removeEmptyFields(params.filter);
    }, [params.filter]);

    useEffect(() => {
        dispatch(loadCommutators(
            params.page,
            limit,
            requestProps()
        ));

    }, [params.page, limit, dispatch, requestProps]);

    const handleAdded = () => {
        dispatch(loadCommutators(
            params.page,
            limit,
            requestProps()
        ));

        stringifyUrlFunc({ page: params.page, limit });
        scrollTop();
    };

    const handleEdited = () => {
        dispatch(loadCommutators(
            params.page,
            limit,
            requestProps()
        ));
    };

    const handleDeleted = () => {
        const page = commutator?.data?.length === 1 && commutator?.meta?.last_page > 1
            ? params.page - 1
            : params.page;


        dispatch(loadCommutators(
            page,
            limit,
            requestProps()
        ));

        stringifyUrlFunc({ page: params.page, limit });
        scrollTop();
    };

    const scrollTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const changePage = (page: number, limit: number | undefined) => {
        setParams({
            ...params,
            page,
        });
        stringifyUrlFunc({ page, limit });
        scrollTop();
    };

    const onChangeFilter = (value: FilterState | {}) => {
        setParams({
            filter: value,
            page: 1,
        });
        stringifyUrlFunc({ page: 1, limit });
    };

    const itemTemplate = (item: CommutatorItem, propsList: string[] = []) => {
        const selectedItem = item.id === currentCommutator?.id;
        const props = propsList.reduce((result, key) => ({ ...result, [key]: true }), {});

        return (
            <Item
                key={item.id}
                selectedItem={selectedItem}
                onClickItem={() => handleClick(item)}
                item={item}
                currentCommutator={currentCommutator}
                {...props}
            />
        );
    };

    const renderListHeader = (commutatorList: CommutatorItem[] = commutator?.data ?? []) => {
        return currentCommutator
            ? (
                <List className="list" disablePadding={true}>
                    {commutatorList.map((item: CommutatorItem) => itemTemplate(item))}
                </List>
            )
            : (
                <LSContainer
                    headers={[
                        { title: titles.NAME, width: 'calc(40% - 120px)' },
                        { title: 'Серийный №', width: '25%' },
                        { title: titles.ADDRESS, width: '25%' },
                        { title: '', isActions: true },
                    ]}
                >
                    {commutatorList.map((item: CommutatorItem) => itemTemplate(item))}
                </LSContainer>
            );
    };

    const renderContent = () => {

        return (
            <>
                {commutator?.data?.length > 0
                    ? <div
                        style={{ height: '100%' }}
                    >
                        <Grid container style={{ height: '100%' }}>
                            <Grid item xs={ currentCommutator ? 3 : 12 } style={{ height: '100%' }}>
                                {currentCommutator
                                    ? (
                                        <Scrollbars style={{ height: '100%' }} ref={scrollRef}>
                                            {renderListHeader()}
                                        </Scrollbars>
                                    )
                                    : (
                                        <>
                                            {renderListHeader()}
                                        </>
                                    )
                                }
                            </Grid>
                            {currentCommutator && (
                                <Grid item xs={9}>
                                    <PassportTabs
                                        item={currentCommutator}
                                        onEdited={handleEdited}
                                        onDeleted={handleDeleted}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    : (!loading_commutator) && <div>{messages.DATA_IS_NOT_FOUND}</div>
                }
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Коммутатор"
                filters={<Filter
                    setParams={onChangeFilter}
                />}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={
                                [{
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }]
                            }
                            positionLeft
                            noPadding
                        />
                    ),
                    total: commutator?.meta?.total
                }}
                loading={loading_commutator}
                paginationProps={{
                    loadList: changePage,
                    list: commutator?.meta || {},
                    limit: Number(limit),
                    setLimit
                }}
                content={renderContent}
                customStyles={{ 
                    overflowY: currentCommutator ? 'hidden' : 'scroll',
                    ...(currentCommutator && { padding: 0 })
                }}
            />
            {openModal && (
                <BasicDataForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    onAdded={handleAdded}
                    isNew
                />
            )}
        </>
    );
};

export default Commutator;
