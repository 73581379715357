import { formatInTimeZone } from 'date-fns-tz';
import { addDays, format, isBefore, isMatch, isValid, parse } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { DateTime, Duration, SystemZone } from 'luxon';

import { config } from '../config';

import messages from './constants/messages';

// константы
const timeRegExp = /(\d{2}):(\d{2})(?::(\d{2}))?(?:[+-](\d{2}):(\d{2}))?/;
const zone = () => config.getTimeZone(); // TODO!!! не работает зона
// date-fns
export const dateFNSFormats = {
    FULL_DATE_TIME_WITH_TZ: 'yyyy-MM-dd\'T\'HH:mm:ssxxx',
    FULL_DATE_TIME_WITHOUT_TZ: 'yyyy-MM-dd HH:mm:ss',
    FULL_DATE_TIME_WITHOUT_SECONDS: 'dd.MM.yyyy HH:mm',
    FULL_DATE_TIME: 'dd.MM.yyyy HH:mm:ss',
    FULL_DATE: 'dd.MM.yyyy',
    YYYY_MM_DD_WITH_DOT: 'yyyy.MM.dd',
    FULL_TIME: 'HH:mm:ss',
    FULL_TIME_WITHOUT_SECONDS: 'HH:mm',
    DATE_WITH_DASH: 'yyyy-MM-dd',
    MONTH_YEAR: 'MMM yyyy',
    YEAR: 'yyyy',
    FULL_DATE_WITH_TZ_HHMM: 'yyyy-MM-dd\'T\'HH:mm:ssxxxx', //  - нет двоеточия в таймзоне
    TEXT_MONTH_FULL_DATE_TIME: 'PPPp', //September 4 1986 8:30 PM	Month name, day of month, year, time
};

export const dateNow = () => {
    return DateTime.local({ zone: zone() })?.toJSDate();
};

export const isDateValid = (date) => {
    return isValid(new Date(date));
};

export const isTimeValid = (time, format) => {
    return isMatch(time, format);
};

export const isDateBefore = (date1, date2) => {
    const dateFirst = new Date(date1);
    const dateSecond = new Date(date2);
    return isBefore(dateFirst, dateSecond);
};

// функция вычисления времени окончания с учетом длительности и интервала(опционально)
export const getEventEndTime = (startTime, duration) => {
    const luxonDateNow = DateTime.now();
    const [_startTime, startTimeHH, startTimeMM] = startTime?.match(timeRegExp);
    const [_duration, durationHH, durationMM] = duration?.match(timeRegExp);
    const setStartTime = luxonDateNow.set({ hour: startTimeHH, minute: startTimeMM });
    const newTime = setStartTime.plus({
        hours: durationHH,
        minutes: durationMM,
    });
    // return getTimeWithoutSeconds(newTime.toJSDate());
    return getFormatFromDate(newTime.toJSDate(), 'HH:mm');
};

// преобразуем время эвентов в полные даты, чтобы правильно происходил переход через полночь
// время передаем в формате  2023-04-22T12:04:00+03:00
// duration в формате HH:mm
export const convertEventTimesToDates = (startTime, duration) => {
    const startDate = DateTime.fromISO(startTime);
    const [_duration, durationHH, durationMM] = duration?.match(timeRegExp);
    const endDate = startDate.plus({
        hours: durationHH,
        minutes: durationMM,
    });
    return fullDateTimeWithTimeZone(endDate.toJSDate());
};



// передаем время в формате ЧЧ:ММ:СС c таймзоной или без таймзоны и получаем объект даты
// вида Thu Feb 24 2022 00:00:00 GMT+0300 (Москва, стандартное время)
// если time нет то устанавливаем текущее время
export const getDateObject = (time) => {
    const date = new Date();
    const matchedTime = time ? time.match(timeRegExp) : [date, date.getHours(), date.getMinutes(), date.getSeconds()];
    const [_, HH, MM, SS] = matchedTime;
    return new Date(date.setHours(HH,MM,SS));
};

// преобразуем время из формата ЧЧ:ММ в объект даты
// вида Thu Feb 24 2022 00:00:00 GMT+0300 (Москва, стандартное время)
// если time нет то устанавливаем текущее время
export const getDateObjectFromHHMM = (time = '00:00') => {
    const date = new Date();
    const matchedTime = time ? time.match(timeRegExp) : [date, date.getHours(), date.getMinutes()];
    const [_, HH, MM] = matchedTime;
    return new Date(date.setHours(HH, MM, 0 , 0));
};

// передаем время в формате 12:05:00+04:00 и получаем объект даты в зоне UTC
// Thu Feb 24 2022 00:00:00 GMT+0000
// если time нет то устанавливаем текущее время
export const getUTCDateObjectFromUTCTime = (time) => {
    const date = new Date();
    const matchedTime = time ? time.match(timeRegExp) : [date, date.getHours(), date.getMinutes(), date.getSeconds()];
    const [_, HH, MM, SS] = matchedTime;
    return new Date(date.setUTCHours(HH,MM,SS));
};

// получаем строку вида 12:05:00+04:00 и возвращаем только время без таймзоны
export const getTimeWithoutZone = (time = '') => {
    const matchedTime = time.match(timeRegExp);
    const [_, HH, MM, SS] = matchedTime || [];
    return matchedTime ? `${HH}:${MM}:${SS}` : null;
};
// получаем строку вида 12:05:00 и возвращаем 12:05
export const getTimeWithoutSecond = (time = '') => {
    const matchedTime = time.match(timeRegExp);
    const [_, HH, MM] = matchedTime || [];
    return matchedTime ? `${HH}:${MM}` : 0;
};
// возвращает год минус переданное количество лет
// например передаем 20 и получаем 2023 - 20 лет (т.е. 2003)
export const getYearDiff = (years = 0) => {
    const currentDate = new Date();
    const newDate = currentDate.setFullYear(currentDate.getFullYear() - years);
    return new Date(newDate).getFullYear();
};

// функция преобразования полученной даты в заданный формат
export const getDateToFormat = (date, format, tz) => {
    const timezone = tz || config.project?.timezone; //'+04:00'
    const dateFormat = (date && isDateValid(date))
        ? formatInTimeZone(date, timezone, format, { locale: ru })
        : null;
    return dateFormat;
};

export const getDateToFormatNew = (date, format) => { // не допроверено
    if (date) {
        if (date instanceof Date) {
            return getFormatFromDate(date, format);
        } else if (date instanceof DateTime && date?.isValid) {
            return getFormatFromDate(date.toJSDate(), format);
        } else if (typeof date === 'string') {
            const newDate = createLuxonFromString(date).toJSDate();
            console.log('newDate', newDate);
            console.log('format', format);
            return getFormatFromDate(newDate, format);
        }
    }
    return messages.NO_DATA;
};

// получаем дату в любом формате и возвращаем 2021-10-04T07:27:00+04:00 с таймзоной проекта
export const fullDateTimeWithTimeZone = (date) => {
    return getDateToFormat(date, dateFNSFormats.FULL_DATE_TIME_WITH_TZ);
};
// получаем дату в любом формате и возвращаем 2021-10-04T07:27:00+0400 - нет двоеточия между 04 и 00
export const fullDateTimeUTC = (date) => {
    return getDateToFormat(date, dateFNSFormats.FULL_DATE_WITH_TZ_HHMM);
};

// переводим полученную дату из любого формата в формат 10.12.2021 12:03:27 с учетом таймзоны проекта
export const fullDateTime = (date) => {
    return getDateToFormat(date, dateFNSFormats.FULL_DATE_TIME);
};
// переводим полученную дату в формат 10.12.2021 12:03
export const fullDateTimeWithoutSeconds = (date) => {
    return getDateToFormat(date, dateFNSFormats.FULL_DATE_TIME_WITHOUT_SECONDS);
};

// получаем на вход дату в любом формате и возвращаем дату в формате 22.02.2022
export const getHumanDate = (date) => {
    return getDateToFormat(date, dateFNSFormats.FULL_DATE);
};
// получаем на вход дату в любом формате и возвращаем дату в формате 02.2022
export const getMonthYear = (date) => {
    return getDateToFormat(date, dateFNSFormats.MONTH_YEAR);
};
// получаем на вход дату в любом формате и возвращаем дату в формате 2022
export const getYear = (date) => {
    return getDateToFormat(date, dateFNSFormats.YEAR);
};
// получаем дату, возврашаем в формате 2022.09.28
export const onlyDateYYYYMMDD = (date) => {
    return getDateToFormat(date, dateFNSFormats.YYYY_MM_DD_WITH_DOT);
};

// получаем дату, возврашаем в формате 2022-09-28
export const dateWithDashYYYYMMDD = (date) => {
    return getDateToFormat(date, dateFNSFormats.DATE_WITH_DASH);
};
// переводим полученную дату из любого формата в формат 12:03:27
export const getFullTime = (date) => {
    return getDateToFormat(date, dateFNSFormats.FULL_TIME);
};
// переводим полученную дату из любого формата в формат 12:03
export const getTimeWithoutSeconds = (date) => {
    return getDateToFormat(date, dateFNSFormats.FULL_TIME_WITHOUT_SECONDS);
};
// переводим полученную дату из любого формата в формат September 4 1986 8:30 PM	Month name, day of month, year, time
export const getFullDateTimeWithTextMonth = (date) => {
    return getDateToFormat(date, dateFNSFormats.TEXT_MONTH_FULL_DATE_TIME);
};
// переводим полученную дату из любого формата в формат 10.12.2021 12:03:27 без учета таймзоны проекта
export const humanDateTimeWithoutTZ = (date) => {
    const newDate = (date && isDateValid(date))
        ? format(new Date(date), dateFNSFormats.FULL_DATE_TIME)
        : null;
    return newDate;
};
// переводим полученную дату из любого формата в формат 10.12.2021 12:03 без учета таймзоны проекта
export const humanDateTimeWithoutSecongsWithoutTZ = (date) => {
    const newDate = (date && isDateValid(date))
        ? format(new Date(date), dateFNSFormats.FULL_DATE_TIME_WITHOUT_SECONDS)
        : null;
    return newDate;
};
// переводим полученную дату из любого формата в формат 10.12.2021 без учета таймзоны проекта
export const humanDateWithoutTZ = (date) => {
    const newDate = (date && isDateValid(date))
        ? format(new Date(date), dateFNSFormats.FULL_DATE)
        : null;
    return newDate;
};

// получаем дату в любом формате и возвращаем 2021-10-04 07:27:00 без учета таймзоны
export const fullDateTimeWithoutTimeZone = (date) => {
    const newDate = (date && isDateValid(date))
        ? format(new Date(date), dateFNSFormats.FULL_DATE_TIME_WITHOUT_TZ)
        : null;
    return newDate;
};

// передаем начальную и конечную дату
// получаем массив дат от начальной до конечной (для графиков)
export const getDaysOfInterval = (startDate, endDate) => {
    // переводим в формат только дата, чтобы не учитывать разницы в минутах, секундах и тп
    const start = new Date(onlyDateYYYYMMDD(startDate));
    const end = new Date(onlyDateYYYYMMDD(endDate));
    const dateArray = [];
    while (start <= end) {
        dateArray.push(new Date(start));
        start.setDate(start.getDate() + 1);
    }
    return dateArray;
};

export const dateAddDays = (date, days) => {
    return addDays(parse(date), days);
};

// передаем дату или время в виде строки и формат этой даты
// например дата'2023-03-01' формат 'yyyy-MM-dd', плюс указывается таймзона проекта
// на выходе получаем js объект даты в зоне соответствующей машине пользователя
// например если проект Саратов, то 2023-03-01 00:00 часов в Саратове это 23 часа предыдущего дня в Мосвке
// Tue Feb 28 2023 23:00:00 GMT+0300 (Москва, стандартное время)
export const getDateObjectFromFormat = (value, format) => {
    if (value) {
        if (typeof value === 'string') {
            return DateTime.fromFormat(value, format, { zone: zone() })?.toJSDate();
        }
    }
    return DateTime.local({ zone: zone() })?.toJSDate();
};
// с таймзоной
// преобразуем полученную дату в нужный формат
// например передали Tue Feb 28 2023 00:00:00 GMT+0800 и формат 'HH:mm'
// получили 19:00 в зоне +3
export const getFormatFromDate = (value, format) => {
    if (value instanceof Date) {
        return DateTime.fromJSDate(value, { zone: zone() }).toFormat(format);
    }
    return value;
};

// без таймзоны
// просто преобразуем полученную дату в нужный формат
// например передали  Tue Feb 28 2023 23:00:00 GMT+0800 и формат 'HH:mm'
// получили 23:00
export const fromDateToFormat = (value, expectFormat) => {
    if (value) {
        if (value instanceof Date && isDateValid(value)) {
            return format(value, expectFormat);
        } else {
            const newDate = new Date(value);
            return format(newDate, expectFormat);
        }
    }
    return value;
};

// Получить начало периода
// передаем дату в формате js Date и начало чего мы хотим получить (month, day, year, week, hour)
// если дата не передана - получаем начало для текущей даты для зоны проекта
// например для Tue Feb 28 2023 00:00:00 GMT+0800 и периода month мы получим
// Tue Feb 01 2023 00:00:00 GMT+0800
export const getStartOf = (period = 'day', date) => {
    if (date) {
        // если это дата люксон
        if (date instanceof DateTime && date?.isValid) {
            return DateTime.startOf(period).toJSDate();
        }
        if (date instanceof Date) {
            return DateTime.fromJSDate(date, { zone: zone() }).startOf(period).toJSDate();
        }
        const newDate = new Date(date);
        return DateTime.fromJSDate(newDate, { zone: zone() }).startOf(period).toJSDate();
    }

    // если дата не передана, получаем начало периода для текущей даты
    return DateTime.local({ zone: zone() }).startOf(period).toJSDate();
};

// Получить конец периода
// передаем дату в формате js Date и конец чего мы хотим получить (month, day, year, week, hour)
// если дата не передана - получаем конец для текущей даты для зоны проекта
// например для Tue Feb 20 2023 00:00:00 GMT+0800 и периода month мы получим
// Tue Feb 28 2023 23:59:59 GMT+0800
export const getEndOf = (period = 'day', date) => {
    if (date) {
        // если это дата люксон
        if (date instanceof DateTime && date?.isValid) {
            return DateTime.endOf(period).toJSDate();
        }
        if (date instanceof Date) {
            return DateTime.fromJSDate(date, { zone: zone() }).endOf(period).toJSDate();
        }
        const newDate = new Date(date);
        return DateTime.fromJSDate(newDate, { zone: zone() }).endOf(period).toJSDate();
    }
    // если дата не передана, получаем конец периода для текущей даты
    return DateTime.local({ zone: zone() }).endOf(period).toJSDate();
};

// получение разницы между двумя датами в заданный формат
// передаем объект с временной разницей - например { milliseconds: 12345670 }
// и формат в котором хотим получить ответ - например 'hh:mm')
export const durationFromObjectToFormat = (obj = {}, format = 'hh:mm') => {
    return Duration.fromObject(obj).toFormat(format);
};

// увеличиваем время на заданный интервал
// duration передаем в виде объекта типа
//  { days: 1, hours: '10', minutes: '05', seconds: '30'}
// для вычитания передаем с минусом { days: -2 }
export const getDateWithDuration = (duration = {}, date) => {
    if (date instanceof Date || isDateValid(date)) {
        return DateTime.fromJSDate(new Date(date), { zone: zone() }).plus(duration).toJSDate();
    }
    // если дату не передали прибавляем или отнимаем от текущей
    return DateTime.local({ zone: zone() }).plus(duration).toJSDate();
};

// получаем для даты значение конкретного "юнита" - год, месяц, день месяца и т.п.
// например, для даты Tue Feb 28 2023 00:00:00 GMT+0800  и периода 'month' мы получим 2
// для периода 'month' - 2
// если передан inText true - получаем "февраль"
export const getTimeUnitValue = (unit, date, inText = false) => {
    let newDate;
    if (date instanceof Date) {
        newDate = DateTime.fromJSDate(date, { zone: zone() });
    } else {
        newDate = DateTime.local({ zone: zone() });
    }
    if (inText) {
        return newDate.toLocaleString({ [unit]: 'long' });
    }
    return newDate.get(unit);
};
// для переданных дат проеряем что у них одинаковый юнит - месяц, год, день, денеля и т.п
export const hasSameUnitOfTime = (date1, date2, unit) => {
    const date = DateTime.fromJSDate(date1, { zone: zone() });
    const otherDate = DateTime.fromJSDate(date2, { zone: zone() });
    return date.hasSame(otherDate, unit);
};

// config в виде объекта типа { hour: '00', minute: '00' }
export const setDateTime = (date, config = {}) => {
    if (date) {
        // если дату передали
        // если это дата люксон
        if (date instanceof DateTime && date?.isValid) {
            return date.set(config).toJSDate();
        }
        // иначе создаем объект даты из полученной даты
        const newDate = new Date(date);
        return DateTime.fromJSDate(newDate, { zone: zone() }).set(config).toJSDate();
    }
    return DateTime.local({ zone: zone() }).set(config).toJSDate();
};

export const isDatesEqual = (date1, date2) => {
    const date = DateTime.fromJSDate(date1, { zone: zone() });
    const otherDate = DateTime.fromJSDate(date2, { zone: zone() });
    return date.equals(otherDate);
};
// params это массив строк типа ['months', 'days']
// в ответе объект типа { months: 1, days: 2 }
// если params нет то сравниваются в милисекундах
export const compareDatesByParams = (date1, date2, params) => {
    const dateJS1 = date1 instanceof Date ? date1 : new Date(date1);
    const dateJS2 = date2 instanceof Date ? date2 : new Date(date2);
    const date = DateTime.fromJSDate(dateJS1, { zone: zone() });
    const otherDate = DateTime.fromJSDate(dateJS2, { zone: zone() });
    return params ? date.diff(otherDate, params).toObject() : date.diff(otherDate).toObject();
};
// получаем оффсет таймзоны компьютера
// format принимает значения 'narrow', 'short', or 'techie', возвращает '+6', '+06:00', or '+0600'
export const getLocalTZOffset = (format = 'short') => {
    const browserZone = (new SystemZone()).name; // системная тайймзона
    const browserTime = DateTime.fromJSDate(new Date(), { zone: browserZone }); // системное время
    const browserTimeZ = browserTime.zone; // создаем luxon объект Zone
    return browserTimeZ.formatOffset(browserTime.ts, format);
};

// получаем номер дня недели для luxon-даты или обычного объекта даты
export const weekDayNumber = (date) => {
    if (date) {
        if (date instanceof DateTime && date?.isValid) {
            return date.weekday;
        }
        const newDate = new Date(date);
        return DateTime.fromJSDate(newDate, { zone: zone() }).weekday;
    }
    return null;

};
// проверка это выходной день или нет
export const isWeekend = (date) => {
    const dayNumber = weekDayNumber(date);
    return dayNumber === 6 || dayNumber === 7;

};
// преобразует дату в timestamp в милисекундах
export const convertDateToTimestamp = (date) => {
    if (date) {
        // если это дата люксон
        if (date instanceof DateTime && date?.isValid) {
            return date.valueOf();
        }
        const newDate = new Date(date);
        return DateTime.fromJSDate(newDate, { zone: zone() }).valueOf();
    }
    // если даты нет - берем таймстамп от текущей даты
    return DateTime.local({ zone: zone() }).valueOf();
};

export const createLuxonFromString = (string) => {
    const splitterRegex = /\D/;
    const splittedString = string
        ? string.split(splitterRegex).map((el) => Number(el))
        : [];

    return DateTime.local(...splittedString, { zone: zone() });
};

// получаем конфиг вида {
//     year: 2021, -----> number
//     month: 8,
//     day: 22,
//     hour: 0,
//     minute: 0,
//     second: 0
// }
// если передана дата то берем из нее нужные поля
// если даты нет - берем от текущей даты
export const getConfigForMinMaxDate = (date) => {
    if (date) {
        if (date instanceof Date) {
            return DateTime.fromJSDate(date).toObject();
        } else if (date instanceof DateTime && date?.isValid) {
            return date.toObject();
        } else if (typeof date === 'string') {
            const newDate = createLuxonFromString(date);
            return newDate.toObject();
        }
    }

    return DateTime.local({ zone: zone() }).toObject();
};

// преобразование полученного конфига в люксон дату для поля minDate, maxDate etc.
export const getMinMaxDate = (dateConfig, needDefaultConfig = false) => {
    const MIN_DATE_TIME = '2018-01-01';
    const minDateConfig = () => getConfigForMinMaxDate(MIN_DATE_TIME);
    return (dateConfig && Object.keys(dateConfig).length > 0)
        ? DateTime.local({ zone: zone() }).set(dateConfig)
        : needDefaultConfig
            ? DateTime.local({ zone: zone() }).set(minDateConfig())
            : undefined;
};
