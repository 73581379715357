import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { config } from 'config';
import titles from 'helpers/constants/titles';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import {
    Cluster,
    Map,
    MapControl,
    Marker,
    PolyLine,
    ToolTip,
} from 'components/MapComponents/leaflet';
import MapLoading from 'components/MapComponents/MapLoading';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons/index';
import { getEdgeColor, getMapNode, getNodeColor } from 'modules/DigitalTwin/Layers/DigitalTwin/helper';
import { loadNodes } from 'modules/DigitalTwin/redux/actions';
// import { getTypeText } from 'modules/DigitalTwin/utils/helpers';

const MapModal = ({
    data = {},
    isOpen,
    onClose,
    onAccept,
}) => {
    const dispatch = useDispatch();

    const selectedMarkerColor = 'rgb(97, 177, 54)';

    const [mapCenter] = useState(config.get('mapCenter'));
    const [mapZoom] = useState( 10);

    const [fields, setFields] = useState({
        previous_node_id: data.previous_node_id || '',
        next_node_id: data.next_node_id || '',
    });

    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(false);

    const isDisabled = !fields.next_node_id
        || !fields.next_node_id
        || fields?.previous_node_id === data?.previous_node_id
        || fields?.next_node_id === data?.next_node_id;

    const selectedIds = useMemo(() => {
        return [
            fields.previous_node_id,
            fields.next_node_id
        ].reduce((r, i) => i ? [...r, i] : r, []);
    }, [fields.previous_node_id, fields.next_node_id]);

    const setPreviousNodeId = (previous_node_id) => setFields(old => ({ ...old, previous_node_id }));
    const setNextNodeId = (next_node_id) => setFields(old => ({ ...old, next_node_id }));

    const onSelectNode = (id) => {
        if (fields.previous_node_id === id) {
            setPreviousNodeId(fields.next_node_id || null);
            setNextNodeId(null);
        } else if (fields.next_node_id === id) {
            setNextNodeId(null);
        } else if (!fields.previous_node_id) {
            setPreviousNodeId(id);
        } else if (!fields.next_node_id) {
            setNextNodeId(id);
        } else {
            setPreviousNodeId(fields.next_node_id);
            setNextNodeId(id);
        }
    };

    useEffect(() => {
        dispatch(loadNodes(
            {},
            (data) => {
                setNodes(data?.items || []);
            },
            setLoading
        ));
    }, [dispatch]);

    const handleAccept = () => {
        const findsNodes = [
            fields.previous_node_id,
            fields.next_node_id
        ].reduce((r, nodeId) => {
            const oneNodeFind = nodes.find(node => node.id === nodeId) || null;
            return oneNodeFind ? [...r, oneNodeFind] : r;
        }, []);

        onAccept({
            ...fields,
            // вершины
            nodes: findsNodes,
            geometry: { type: 'LineString', coordinates: findsNodes.map(item => [parseFloat(item.lon), parseFloat(item.lat),]) }
        });
        onClose();
    };

    // координаты линии из вершин
    const polylineCoords = useMemo(() => {
        return [
            fields.previous_node_id,
            fields.next_node_id
        ].reduce((r, nodeId) => {
            const oneNodeFind = nodes.find(node => node.id === nodeId) || null;
            return oneNodeFind && oneNodeFind.lat && oneNodeFind.lon
                ? [...r, [ oneNodeFind?.lat, oneNodeFind?.lon]]
                : r;
        }, []);
    }, [fields, nodes]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={'Выбрать вершины'}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleAccept,
                            disabled: isDisabled,
                        }
                    ]}
                />
            }
            medium
            heightMedium
        >
            <div
                style={{
                    position: 'relative',
                    height: '100%'
                }}
            >
                <MapLoading loading={loading} />

                <Map
                    center={mapCenter}
                    zoom={mapZoom}
                    // сдвиг по вершинам
                    bounds={polylineCoords.length > 1 ? polylineCoords : null }
                >
                    <MapControl>
                        <Cluster
                            maxClusterRadius={10}
                            iconCreateFunction={iconCreateFunctionSimple}
                        >
                            {/* все вершины */}
                            {nodes?.map(item => (
                                <Marker
                                    key={item.id}
                                    // перепутаны координаты
                                    // latlng={[item.lat, item.lon]}
                                    latlng={[item.lat, item.lon]}
                                    onClick={(e) => onSelectNode(item.id)}
                                    icon={getMapNode(selectedIds.includes(item.id) ? selectedMarkerColor : getNodeColor)}
                                    attribution={{
                                        color: selectedIds.includes(item.id) ? selectedMarkerColor : getNodeColor
                                    }}
                                >
                                    <ToolTip
                                        direction="top"
                                        offset={[0, -5]}
                                    >
                                        <div>
                                            {!!item.created_at && (
                                                <div>
                                                    <strong>{titles.DATE_TIME_CREATED}:</strong>&nbsp;
                                                    {getFullDateTimeWithTextMonth(item.created_at)}
                                                </div>
                                            )}

                                            {/*{!!item.type && (*/}
                                            {/*    <div>*/}
                                            {/*        <strong>{titles.TYPE}:</strong>&nbsp;*/}
                                            {/*        {getTypeText(item.type)}*/}
                                            {/*    </div>*/}
                                            {/*)}*/}

                                            {fields.previous_node_id === item.id && <div><strong>Предыдущий узел</strong></div>}
                                            {fields.next_node_id === item.id && <div><strong>Следующий узел</strong></div>}
                                        </div>
                                    </ToolTip>
                                </Marker>
                            ))}

                            {/* линия */}
                            {polylineCoords.length > 1 && (
                                <PolyLine
                                    coordinates={polylineCoords}
                                    style={{
                                        color: getEdgeColor,
                                        weight: 8,
                                    }}
                                >
                                    <ToolTip
                                        direction="top"
                                        offset={[0, -5]}
                                        sticky={true}
                                    >
                                        <div>
                                            Ребро
                                        </div>
                                    </ToolTip>
                                </PolyLine>
                            )}

                        </Cluster>
                    </MapControl>
                </Map>
            </div>
        </Modal>
    );
};

export default MapModal;
