import { useState, ChangeEvent } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone, getDateWithDuration, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreProp } from 'helpers/hooks';
import { loadStoryStatuses } from 'modules/React/redux/actions';

interface FiltersProps {
    setParams: (params: { page: number; data: {} }) => void;
}

interface FilterState {
    story_id_list?: number | null;
    name?: string;
    start_period: Date | null | string;
    end_period: Date | null | string;
    incident_id_list?: string;
    incident_name?: string;
    status_id_list?: number | string;
}

const Filters = ({ setParams }: FiltersProps) => {

    const storyStatuses = useStoreProp(loadStoryStatuses, 'react', 'storyStatuses');

    const initState: FilterState = {
        story_id_list: null,
        name: '',
        start_period: fullDateTimeWithTimeZone(getStartOf('year', getDateWithDuration())),
        end_period: fullDateTimeWithTimeZone(new Date()),
        incident_id_list: '',
        incident_name: '',
        status_id_list: '',
    };

    const [data, setData] = useState(initState);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleChangeStatus = (name: string, value: string | number | null) => {
        setData({ ...data, [name]: value });
    };

    const search = (seachData: FilterState = data) => {
        const paramsWithoutEmpty = removeEmptyFields({
            ...seachData,
            ...(seachData.story_id_list ? { story_id_list: [seachData.story_id_list] } : null),
            ...(seachData.incident_id_list ? { incident_id_list: [seachData.incident_id_list] } : null),
            ...(seachData.status_id_list ? { status_id_list: [seachData.status_id_list] } : null),
        });
        setParams({ page: 1, data: paramsWithoutEmpty });
    };

    const resetSearch = (needRefresh: boolean) => {
        setData(initState);
        if (needRefresh) {
            const paramsWithoutEmpty = removeEmptyFields(initState);
            search(paramsWithoutEmpty as FilterState);
        }
    };

    const updateFilter = (filters: FilterState = initState) => {
        setData({ ...initState, ...filters });
        if (Object.keys(filters).length > 0) {
            search(filters);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={search}
            filter={data}
            setUserFilter={updateFilter}
            filterException={['start_period', 'end_period',]}
            countException={['start_period', 'end_period',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={data?.start_period}
                    valueEndDate={data?.end_period}
                    handleDate={(start_period: Date | null) => setData({ ...data, start_period: fullDateTimeWithTimeZone(start_period) })}
                    handleEndDate={(end_period: Date | null) => setData({ ...data, end_period: fullDateTimeWithTimeZone(end_period) })}
                    dateOnly={true}
                    equalDates={true}
                />
                <TextField
                    label={'ID'}
                    value={data.story_id_list}
                    name="story_id_list"
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                    onChange={handleChange}
                />
                <TextField
                    label={titles.NAME}
                    value={data.name}
                    name="name"
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                />
                <FormControl size={'small'} variant="outlined">
                    <InputLabel>Статус</InputLabel>
                    <Select
                        value={data.status_id_list}
                        onChange={(e) => handleChangeStatus(e.target.name, e.target.value)}
                        label="Статус"
                        name="status_id_list"
                    >
                        <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                        {Object.keys(storyStatuses).map((key) => (
                            <MenuItem value={key} key={key}>{storyStatuses[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label={'ID инцидента'}
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                    value={data.incident_id_list}
                    name="incident_id_list"
                    onChange={handleChange}
                />
                <TextField
                    label={'Название инцидента'}
                    variant="outlined"
                    size="small"
                    value={data.incident_name}
                    name="incident_name"
                    onChange={handleChange}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;