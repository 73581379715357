import { useMemo, useState } from 'react';

import { getConfigForMinMaxDate } from 'helpers/date.config';
import formatDate from 'helpers/constants/dateFormat';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import { uri } from 'modules/Dtp/module-config';

const test_id_prefix=`${uri}/heat/map/district`;

const Filter = ({
    loading,
    onChange,
    tab,
}) => {

    const initialState = {
        date: new Date(),
    };
    const [fields, setFields] = useState(initialState);

    const resetFilters = (needRefresh) => {
        setFields(initialState);
        if (needRefresh) {
            onChange(initialState);
        }
    };

    const handleChange = () => {
        onChange(fields);
    };

    const updateFilter = (filters) => {
        const newFields = {
            ...fields,
            ...filters,
        };
        if (newFields.date) newFields.date = new Date(newFields.date);

        setFields(newFields);
        onChange(newFields);
    };

    const isDisabled = useMemo(() => {
        return !fields.date;
    }, [fields]);

    const handleChangeDate = (value) => {
        setFields(old => ({
            ...old,
            date: value,
        }));
    };

    const RenderFilter = () => (
        <div className="row noMargin">
            <SingleKeyboardDateTimePicker
                value={fields.date}
                onChange={handleChangeDate}
                pickerFormat={formatDate.MONTH_FOR_PICKER}
                dateOnly={true}
                views={['month', 'year']}
                maxDate={getConfigForMinMaxDate()}
                label="Месяц/год"
                isDisabled={loading}
                test_id_prefix={`${test_id_prefix}:filter/data`}
            />
        </div>
    );

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={handleChange}
            filter={fields}
            disabled={isDisabled}
            setUserFilter={updateFilter}
            test_id_prefix={test_id_prefix}
            isFilterVisible
            tab={tab}
        >
            <LayoutFilter.Visible>
                <RenderFilter/>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
