export const boardsState = (state) => state.boards;

export const loading = (state) => boardsState(state).loading;

export const boardStatuses = (state) => boardsState(state).boardStatuses;
export const boardTypes = (state) => boardsState(state).boardTypes;

export const boards = (state) => boardsState(state).boards;
export const boardsData = (state) => boards(state).data;

export const loadingBefore = (state) => boardsState(state).loadingBefore;

export const loadingMessages = (state) => boardsState(state).loadingMessages;
export const boardMessages = (state) => boardsState(state).boardMessages;

// export const messagePriorities = (state) => boardsState(state).messagePriorities;
// export const boardColors = (state) => boardsState(state).boardColors;

export const polygon = (state) => boardsState(state).polygon;
export const polygonLoading = (state) => boardsState(state).polygonLoading;
export const active = (state) => boardsState(state).active;
export const filters = (state) => boardsState(state).filters;
export const deleteForm = (state) => boardsState(state).deleteForm;
export const editForm = (state) => boardsState(state).editForm;
export const sidebar = (state) => boardsState(state).sidebar;
export const sidebarData = (state) => sidebar(state).data;
export const sidebarMeta = (state) => sidebar(state).meta;
export const sidebarLoading = (state) => boardsState(state).sidebarLoading;
export const sidebarParams = (state) => boardsState(state).sidebarParams;
export const saved = (state) => boardsState(state).saved;

// export const messagePreview = (state) => boardsState(state).messagePreview;
export const messageJSONPreview = (state) => boardsState(state).messageJSONPreview;
export const loadingMessageJSONPreview = (state) => boardsState(state).loadingMessageJSONPreview;

export const activeBoardMessage = (state) => boardsState(state).activeBoardMessage;
export const loadingActiveBoardMessage = (state) => boardsState(state).loadingActiveBoardMessage;

export const fontsList = (state) => boardsState(state).fontsList;
// export const fontsListForTypeWithImg = (state) => boardsState(state).fontsListForTypeWithImg;
// export const signsList = (state) => boardsState(state).signsList;

export const boardRotationMessages = (state) => boardsState(state).boardRotationMessages;
export const loadingBoardRotationMessages = (state) => boardsState(state).loadingBoardRotationMessages;

export const boardEvents = (state) => boardsState(state).boardEvents;
export const loadingBoardEvents = (state) => boardsState(state).loadingBoardEvents;

export const ditLink = (state) => boardsState(state).ditLink;
export const loadingDitLink = (state) => boardsState(state).loadingDitLink;

export const loadingMessageTemplates = (state) => boardsState(state).loadingMessageTemplates;
export const boardMessageTemplates = (state) => boardsState(state).boardMessageTemplates;

// export const alertTemplateStatuses = (state) => boardsState(state).alertTemplateStatuses;
// export const alertTemplateTypes = (state) => boardsState(state).alertTemplateTypes;
// export const alertTemplateFields = (state) => boardsState(state).alertTemplateFields;
export const loadingAlertTemplates = (state) => boardsState(state).loadingAlertTemplates;
export const alertTemplates = (state) => boardsState(state).alertTemplates;

export const boardsFromLink = (state) => boardsState(state).boardsFromLink;
export const loadingBoardsFromLink = (state) => boardsState(state).loadingBoardsFromLink;

export const templateImageList = (state) => boardsState(state).templateImageList;
export const templateImageListLoading = (state) => boardsState(state).templateImageListLoading;
export const templateImageLoading = (state) => boardsState(state).templateImageLoading;

export const boardSigns = (state) => boardsState(state).boardSigns;
export const boardSignsLoading = (state) => boardsState(state).boardSignsLoading;

// export const alignX = (state) => boardsState(state).alignX;
// export const alignY = (state) => boardsState(state).alignY;

export const showcase = (state) => boardsState(state).showcase;
export const loadingShowcase = (state) => boardsState(state).loadingShowcase;

export const boadrCategories = (state) => boardsState(state).boadrCategories;
