import messages from 'helpers/constants/messages';
import { createIcon, MapPopUp } from 'components/MapComponents/MapPopUp';
import MapScroll from 'components/MapComponents/MapScroll';

import { getIconEdge, getIconNode } from '../helper';

import Edge from './Edge';
import Node from './Node';

const PopUpModal = ({
    item,
    isOpen,
    onClose,
}) => {

    const {
        id = 0,
        color,
        name,
        type,
    } = item || {};

    const renderByType = (edgeType, nodeType, defaultType) => {
        switch (type) {
            case 'edge':
                return edgeType;
            case 'node':
                return nodeType;
            default:
                return defaultType;
        }
    };

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={createIcon(renderByType(getIconEdge, getIconNode, getIconNode))}
            // title={name || renderByType('Ребро', 'Узел', '')}
            title={name || ''}
            titleBackgroundColor={color}
            onClose={onClose}
            medium
        >
            <MapScroll>
                {renderByType(
                    <Edge id={id}/>,
                    <Node id={id}/>,
                    <div className="not-found" style={{ padding: '1rem' }}>{messages.NO_DATA}</div>
                )}
            </MapScroll>
        </MapPopUp>
    );
};

export default PopUpModal;
