import { useMemo } from 'react';

import { getHumanDate } from 'helpers/date.config';
import CircleStatus from 'components/common/CircleStatus';
import NoValue from 'components/common/List/Components/NoValue';
import renderAddress from 'helpers/renderAddress';
import {
    loadRoadSectionCategories,
    loadRoadSectionTypes,
    loadStatusesRoadSection,
} from 'modules/InfrastructureObjects/redux/actions';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import DataTable from 'components/common/DataTable';

import Actions from './Actions';
import Info from './Info';

import type { CellContext, ColumnDef } from '@tanstack/react-table';
import type { RoadSection, Status } from './types';

interface ListProps {
    list: RoadSection[];
    reloadList: () => void;
    inPrint?: boolean;
}

const List = ({ list, reloadList, inPrint }: ListProps) => {
    const roadSectionTypes = useStoreProp(loadRoadSectionTypes, 'roadNetworks', 'roadSectionTypes');
    const roadSectionCategories = useStoreProp(loadRoadSectionCategories, 'roadNetworks', 'roadSectionCategories');
    const statusesList: Status[] = useStoreProp(loadStatusesRoadSection, 'roadNetworks', 'statusesRoadSection');

    const statuses = useMemo(() => {
        return statusesList.reduce((res, el) => ({ ...res, [el.id]: el }), {} as { [key: string]: Status });
    }, [statusesList]);

    const columns = useMemo<ColumnDef<RoadSection>[]>(
        () => [
            {
                id: 'status',
                accessorKey: 'status',
                header: inPrint ? 'Статус' : '',
                cell: (info) => {
                    const status: Status | undefined = statuses[info.row.original.status];
                    if (inPrint) return status?.name;
                    return <CircleStatus title={status?.name} color={status?.color} />;
                },
                size: inPrint ? 150 : 50,
            },
            {
                id: 'name',
                accessorKey: 'name',
                header: titles.NAME,
                cell: (info) => info.getValue() || messages.NO_VALUE,
            },
            {
                id: 'category',
                header: titles.CATEGORY,
                accessorFn: (info) => roadSectionCategories[info.category] || messages.NO_VALUE,
            },
            {
                id: 'type',
                header: titles.TYPE,
                accessorFn: (info) => roadSectionTypes[info.type],
            },
            {
                id: 'organization',
                header: titles.ORGANIZATION,
                cell: (info) => info.row.original.organization?.title || messages.NO_VALUE,
            },
            {
                id: 'address',
                accessorKey: 'address',
                header: titles.ADDRESS,
                accessorFn: (info) => (info.address ? renderAddress(info.address) : <NoValue />),
            },
            {
                id: 'length',
                accessorKey: 'length',
                header: 'Протяжённость',
                cell: (info) => info.getValue() || messages.NO_VALUE,
            },
            {
                id: 'created_at',
                header: 'Дата создания',
                accessorFn: (info) =>
                    info.created_at ? getHumanDate(info.created_at) : <NoValue />,
            },
            {
                id: 'fixated_at',
                header: 'Дата регистрации',
                accessorFn: (info) =>
                    info.fixated_at ? getHumanDate(new Date(info.fixated_at)) : <NoValue />,
            },
            ...(inPrint
                ? []
                : [
                    {
                        id: 'actions',
                        header: titles.ACTIONS,
                        cell: (info: CellContext<RoadSection, unknown>) => (
                            <Actions
                                item={info.row.original}
                                reloadList={reloadList}
                                toggleInfo={info.row.getToggleExpandedHandler()}
                            />
                        ),
                    },
                ]),
        ],
        [inPrint, reloadList, roadSectionCategories, roadSectionTypes, statuses]
    );

    return (
        <DataTable
            data={list}
            columns={columns}
            renderInfo={(item) => <Info data={item} />}
        />
    );
};

export default List;
