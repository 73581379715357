import { useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@mui/material';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import { eputsTypes, getItemLinkedObject, objectTypes, renderItemText, typesRu } from '../objectHelper';
import EditModal from '../EditModal';
import useBindingsProvider from '../useBindingsProvider';

const ListContainer = ({
    itemId, // id
    itemType, // тип
    itemLatLon,
    readOnly,
}) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);

    const bindingsProvider = useBindingsProvider(
        setData,
        setLoading
    );

    const groupProp = useMemo(() => {
        switch (itemType) {
            case objectTypes.node:
            case objectTypes.edge:
                return {
                    group: 'eputs_type',
                    value: 'eputs_id',
                    object: 'eputs',
                };
            case eputsTypes.infrastructure:
            case eputsTypes.roadNetwork:
                return {
                    group: 'object_type',
                    value: 'object_id',
                    object: 'object',
                };
            default:
                return null;
        }
    }, [itemType]);

    const groupedData = useMemo(() => {
        if (groupProp) {
            return Object.groupBy(data, (item) => item[groupProp.group]);
        }
        return {};
    }, [data, groupProp]);

    const fetchList = () => bindingsProvider.load(itemId, itemType);

    useEffect(() => {
        fetchList();
    }, [itemId, itemType]);

    useEffect(() => () => {
        setData([]);
    }, []);

    const renderRelations = () => {
        return Object.keys(groupedData).map((key, index) =>
            <div key={`${key}_${index}`}>
                <p><strong>{typesRu[key]}:</strong></p>

                {groupedData[key]?.map((el, i) => {
                    const item = getItemLinkedObject(el, itemType);
                    return (
                        <div key={i} style={{ marginLeft: 10 }}>
                            {i + 1}. <strong>{renderItemText(item)}</strong>;
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div>

            <div style={{ display: 'flex' }}>
                <FormButtonsComponent
                    noPadding
                    noMarginLeft
                    buttons={
                        !readOnly
                            ? [{
                                ...buttonsTypes.change,
                                color: 'infoButton',
                                size: 'small',
                                name: 'Связь',
                                onClick: () => setIsModal(true),
                            }]
                            : []
                    }
                    beforeTemplate={<h4>Присвоенные объекты ЦД:</h4>}
                />
            </div>

            <div style={{ margin: '0 15px' }}>
                {loading
                    ? <LinearProgress/>
                    : (
                        <>
                            {Object.keys(groupedData).length > 0
                                ? renderRelations()
                                : 'Нет присвоенных объектов'
                            }
                        </>
                    )
                }
            </div>

            {isModal && (
                <EditModal
                    isOpen={isModal}
                    onClose={() => setIsModal(false)}
                    onReload={fetchList}
                    data={data}
                    currentId={itemId}
                    currentType={itemType}
                    currentLatLon={itemLatLon}
                />
            )}
        </div>
    );
};

export default ListContainer;
