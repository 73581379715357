export const dashboard = (state) => state.dashboard;

export const dashboardPage = (state) => dashboard(state).dashboardPage;
export const dashboardPageLoading = (state) => dashboard(state).dashboardPageLoading;

export const userDashboard = (state) => dashboard(state).userDashboard;
export const loadingUserDashboard = (state) => dashboard(state).loadingUserDashboard;

export const dashboardsItem = (state) => dashboard(state).dashboardsItem;
export const loadingDashboards = (state) => dashboard(state).loadingDashboards;

export const dashboardItemUser = (state) => dashboard(state).dashboardItemUser;
export const loadingDashboardItemUser = (state) => dashboard(state).loadingDashboardItemUser;

export const userAvailableDashboardList = (state) => dashboard(state).userAvailableDashboardList;
export const loadingUserAvailableDashboardList = (state) => dashboard(state).loadingUserAvailableDashboardList;
