import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadUserActivity, loadUserTypes } from 'redux/Admin/actions';
import { adminSelectors } from 'redux/Admin';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';
import GetReports from 'components/common/GetReports';
import ReportBlock from 'components/common/Report/ReportBlock';
import { useStoreProp } from 'helpers/hooks';

import Item from './Item';
import Filter from './Filter';

import type { UserActivityItem, UserType } from 'types/Admin';
import type { Params } from './types';

const UserActivity = () => {
    const dispatch = useDispatch();

    const userTypes: UserType[] = useStoreProp(loadUserTypes, 'admin', 'userTypes');

    const list = useSelector(adminSelectors.userActivity);
    const loading = useSelector(adminSelectors.loadingUserActivity);

    const [limit, setLimit] = useState<number>(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState<Params>({
        page: 1,
        data: {
            start_date: fullDateTimeWithTimeZone(getStartOf('day', getDateWithDuration({ weeks: -1 }))),
            end_date: fullDateTimeWithTimeZone(getEndOf()),
        },
    });
    const [selectedFormats, setSelectedFormats] = useState<string[]>([]);

    useEffect(() => {
        dispatch(loadUserActivity(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const getReport = (formats: string[]) => {
        dispatch(loadUserActivity(params.page, limit, { ...params.data, report: 1, formats }));
    };

    // конвертация массива в объект с ключами id, чтобы в каждом элементе не искать через .find
    const userTypesObj = useMemo(() => userTypes?.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
    }, {} as { [key: number]: UserType }), [userTypes]);

    const renderContent = () => (
        <>
            {list?.data?.length > 0 ? (
                <LSContainer
                    headers={[
                        { title: titles.DATE, width: '20%' },
                        { title: titles.USER, width: '20%' },
                        { title: titles.SERVICE, width: '20%' },
                        { title: titles.ACTION, width: '20%' },
                        { title: titles.USER_TYPE, width: '20%' },
                    ]}
                >
                    {list.data.map((item: UserActivityItem, index: number) => (
                        <Item
                            key={`${item.user_id}${index}`}
                            item={item}
                            userTypes={userTypesObj}
                        />
                    ))}
                </LSContainer>
            ) : (
                !loading && <NoData />
            )}
        </>
    );

    return (
        <PageLayout
            header="Действия пользователей"
            filters={<Filter setParams={setParams} />}
            informPanelProps={{
                buttons: (
                    <ReportBlock
                        customComponents={() => (
                            <GetReports
                                onDownload={getReport}
                                selected={selectedFormats}
                                onChange={setSelectedFormats}
                                reportId={25}
                                helperText={undefined}
                            />
                        )}
                    />
                ),
                total: list?.meta?.total,
            }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: list.meta,
                limit,
                setLimit,
            }}
        />
    );
};

export default UserActivity;
