import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Icon,
} from '@mui/material';
import { isEqual } from 'lodash';

import * as actions from 'redux/FocusIncidents/actions';
import { loadConcentrationAreaStatuses, loadConcentrationAreaTypes } from 'redux/Incidents/actions';
import { focusIncidentsSelectors } from 'redux/FocusIncidents';
import { incidentsSelectors } from 'redux/Incidents';
import Loading from 'components/common/Loading';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import { usePrevious, useStoreFromSelectorListToObject } from 'helpers/hooks';

import { createIcon, getFilter } from '../helper';
import config from '../config';

import Item from './Item';
import FilterForm from './Filter';
import Statistic from './Statistic';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const sidebar = useSelector(focusIncidentsSelectors.sidebar);
    const data = useSelector(focusIncidentsSelectors.sidebarData);
    const meta = useSelector(focusIncidentsSelectors.sidebarMeta);
    const sidebarLoading = useSelector(focusIncidentsSelectors.sidebarLoading);
    const active = useSelector(focusIncidentsSelectors.active);
    const saved = useSelector(focusIncidentsSelectors.saved);
    const filters = useSelector(focusIncidentsSelectors.filters);

    const prevFilters = usePrevious(filters);

    const activeId = Object.keys(active).length > 0 ? active.id : 0;

    const filter = getFilters(filters, getFilter);

    const types = useStoreFromSelectorListToObject(loadConcentrationAreaTypes, incidentsSelectors.concentrationAreaTypes);
    const statuses = useStoreFromSelectorListToObject(loadConcentrationAreaStatuses, incidentsSelectors.concentrationAreaStatuses);

    const fetchList = () => {
        dispatch(actions.loadSidebar(
            page,
            limit,
            {
                ...filter,
                with_dtp_list: 1,
            },
            page === 1
        ));
    };

    const handleScrollUpdate = (values) => {
        const last_page = meta?.last_page || 0;
        if (
            values.top > 0.98
            && sidebarLoading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setPage(1);
        }
        fetchList();
    }, [page, limit, filters]);

    useEffect(() => {
        if (saved) {
            handleLoaded();
        }
    }, [saved]);

    const handleLoaded = () => {
        if (page > 1) {
            setPage(1);
        } else {
            fetchList();
        }
    };

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                const typeObj = types.get(item?.type);
                const statusObj = statuses.get(item?.status);

                const typeColor = typeObj?.color;
                const statusColor = statusObj?.color;

                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        iconComponent={<Icon color="primary" />}
                        icon={createIcon(typeColor, statusColor)}
                        // icon={iconCircle(getColor(item),createIcon(item))}
                        buttons={{
                            edit: {
                                title: buttons.EDIT,
                                icon: <i className="fas fa-pen" />,
                                onClick: () => {
                                // редактирование
                                    dispatch(actions.setEditForm(item));
                                }
                            },
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => {
                                // удаление
                                    dispatch(actions.setDeleteForm(item.id));
                                }
                            }
                        }}
                        onClick={() => {
                            dispatch(actions.setActive(item));
                        }}
                    >
                        <Item
                            {...item}
                            type={typeObj?.name}
                            status={statusObj?.name}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => setPage(1)}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange }) => <FilterForm data={data} onChange={onChange} />}
                layer={config.slug}
            />

            {sidebarLoading && data.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <>
                    <Statistic/>

                    <Scrollbars
                        onUpdate={handleScrollUpdate}
                        renderTrackHorizontal={(props) => (
                            <div {...props} className="track-horizontal" />
                        )}
                    >
                        <div className="layers-sidebar__layer">
                            <SidebarTitle
                                title={config.name}
                                list={sidebar}
                            />
                            {data.length === 0
                                ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                                : renderList(data)}
                        </div>
                        {sidebarLoading && <Loading className="center" />}
                    </Scrollbars>
                </>
            )}
        </div>
    );
};

export default SideBar;
