import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { editDTPStatuses, saveDtp } from 'redux/Incidents/actions';

import ModalForm from '../../ModalForm';

// модалка изменить статус
const ChangeStatusForm = ({
    // id из модели
    dataId,
    // статус из модели
    dataStatus,
}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const callback = () => {
        dispatch(saveDtp(true));
        setOpen(false);
    };

    return (
        <>
            <IconButton
                size="small"
                onClick={handleOpen}
            >
                <Tooltip title="Изменить статус">
                    <EditIcon fontSize="small"/>
                </Tooltip>
            </IconButton>

            {open && (
                <ModalForm
                    open={open}
                    onClose={handleClose}
                    onSave={(status) => {
                        dispatch(
                            editDTPStatuses(
                                {
                                    ids: [dataId],
                                    new_status: Number(status)
                                },
                                callback
                            )
                        );
                    }}
                    initialStatus={dataStatus?.toString()}
                />
            )}
        </>
    );
};

export default ChangeStatusForm;
