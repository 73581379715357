import { useState } from 'react';

import {
    Cluster,
    ClusterPopUp,
    Map,
    MapControl,
} from 'components/MapComponents/leaflet';
import MapLegends from 'components/common/Transport/MapLegends';
import { config } from 'config';

import Legend from '../Legend';

import iconCreateFunction from './icon/iconCreateFunction';
import createIcon from './icon/CreateIcon';
import Polygon from './Polygon';
import ClusterPopUpModal from './ClusterInfo/PopUpModal';

const DtpMap = ({ reportCategory }) => {
    const [mapCenter] = useState(config.get('mapCenter'));
    const [mapZoom] = useState( 10);
    const [showModal, setShowModal] = useState( []);

    const getIdsCluster = (cluster) => {
        const ids = [];

        if (cluster && cluster.getChildCount() > 0) {
            const markers = cluster.getAllChildMarkers();
            markers.forEach(marker => {
                const attribution = marker.getAttribution() || {};
                const dtpId = attribution?.id || null;
                if (dtpId && !ids.includes(dtpId)) {
                    ids.push(dtpId);
                }
            });
        }

        return ids;
    };

    return (
        <Map
            center={mapCenter}
            zoom={mapZoom}
        >
            <MapControl>
                <Cluster
                    iconCreateFunction={iconCreateFunction(createIcon)}
                    isClick
                    onClick={(layers) => setShowModal(getIdsCluster(layers))}
                >
                    <Polygon/>

                    <MapLegends visibleRequired={true}>
                        <Legend reportCategory={reportCategory}/>
                    </MapLegends>

                    {/*<ClusterPopUp*/}
                    {/*    minWidth={750}*/}
                    {/*    isMoveOnClick={true}*/}
                    {/*>*/}
                    {/*    <ClusterInfo />*/}
                    {/*</ClusterPopUp>*/}
                </Cluster>

                {showModal.length > 0 && (
                    <ClusterPopUpModal
                        ids={showModal}
                        isOpen={showModal.length > 0}
                        onClose={() => setShowModal([])}
                    />
                )}
            </MapControl>
        </Map>
    );
};

export default DtpMap;
