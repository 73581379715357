import { getEndOf, getStartOf } from 'helpers/date.config';

import * as types from './types';

const initialState = {
    infrastructure: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingInfrastructure: false,
    infrastructureTypes: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingInfrastructureTypes: false,
    loadingRoadSections: false,
    roadSections: {
        data: [],
    },
    editForm: false,
    roadSectionTypes: [],
    roadSectionCategories: [],
    // polygon
    polygonRoadSection: [],
    loadingPolygonRoadSection: false,
    // sidebar
    sidebarRoadSection: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingSidebarRoadSection: false,
    // active and filter
    activeRoadSection: {},
    filtersRoadSection: {},
    // deleted / edited
    deletedFormRoadSection: null,
    editedFormRoadSection: null,
    // saved
    savedRoadSection: false,
    statusesRoadSection: [],
    relationTypes: {},
    roadSectionsByRadius: [],
    infrastructureByRadius: [],
    overlapPolygon: [],
    loadingOverlapPolygon: false,
    filterOverlap: {
        start_at: getStartOf('month'),
        end_at: getEndOf('month'),
    },
    activeOverlap: {},
    overlapList: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingOverlapList: false,

    polygonInfrastructure: [],
    loadingPolygonInfrastructure: false,
    filterPolygonInfrastructure: {},
    activePolygonInfrastructure: {},
    sidebarInfrastructure: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingSidebarInfrastructure: false,
    savedInfrastructure: false,
    overlapTypes: {},
    overlapSource: {},

    streetList: [],
    loadingStreetList: false,

    categoryTypes: [],
    loadedCategoryTypes: false,

    loadingRoadSectionRelation: false,

    videoCamerasDictionaryInfrastructureTypes: [],
    loadingVideoCamerasDictionaryInfrastructureTypes: false,

    videoCamerasDictionaryCommutator: [],
    loadingVideoCamerasDictionaryCommutator: false,

    videoCamerasDictionaryConnectionLine: [],
    loadingVideoCamerasDictionaryConnectionLine: false,

    videoCamerasDictionaryConnection: [],
    loadingVideoCamerasDictionaryConnection: false,

    videoCamerasDictionaryElectricalOptionalSystem: [],
    loadingVideoCamerasDictionaryElectricalOptionalSystem: false,

    videoCamerasDictionaryDocument: [],
    loadingVideoCamerasDictionaryDocument: false,

    videoCamerasDictionaryPower: [],
    loadingVideoCamerasDictionaryPower: false,

    videoCamerasDictionaryReliance: [],
    loadingVideoCamerasDictionaryReliance: false,

    commutators: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingCommutators: false,
    commutatorTypes: {},
    loadingButton: false,

    connectionLines: {
        data: [],
        meta: { last_page: 0 }
    },
    loadingConnectionLines: false,

    cameras: [],
    loadingCameras: false,

    cameraTypes: {},
    loadingCameraTypes: false,

    cameraModel: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingCameraModel: false,

    cameraVendor: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingCameraVendor: false,

    connectionPoints: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingConnectionPoints: false,
    connectionPointTypes: {},
    pillars: {
        data: [],
        meta: { last_page: 0 }
    },
    loadingPillars: false,

    electricalOpticalSystems: {
        data: [],
        meta: { last_page: 0 }
    },
    loadingElectricalOpticalSystems: false,

    pakVendors: {
        data: [],
        meta: { last_page: 0 }
    },
    loadingPakVendors: false,

    pakModels: {
        data: [],
        meta: { last_page: 0 }
    },
    loadingPakModels: false,

    PAKList: {
        data: [],
        meta: { last_page: 0 }
    },
    loadingPAKList: false,

    DVR: {
        data: [],
        meta: { last_page: 0 }
    },
    loadingDVR: false,
    infrastructureByPolygon: [],
    loadingInfrastructureByPolygon: false,
    infrastructureList: {
        data: [],
        meta: { last_page: 0 }
    },
    loadingInfrastructureList: false,
    activeInfrastructureLayer: {},
    polygonGeoItemRoadSection: {},
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADING_INFRASTRUCTURE:
            return {
                ...state,
                loadingInfrastructure: payload
            };
        case types.LOADED_INFRASTRUCTURE: {
            return {
                ...state,
                infrastructure: payload
            };
        }
        case types.LOADING_INFRASTRUCTURE_TYPES: {
            return {
                ...state,
                loadingInfrastructureTypes: payload
            };
        }
        case types.LOADED_INFRASTRUCTURE_TYPES: {
            return {
                ...state,
                infrastructureTypes: payload
            };
        }
        case types.LOADED_ROAD_SECTION_TYPES: {
            return {
                ...state,
                roadSectionTypes: payload,
            };
        }
        case types.LOADED_ROAD_SECTION_CATEGORIES: {
            return {
                ...state,
                roadSectionCategories: payload,
            };
        }
        case types.LOADING_ROAD_SECTIONS: {
            return {
                ...state,
                loadingRoadSections: payload,
            };
        }
        case types.LOADED_ROAD_SECTIONS: {
            return {
                ...state,
                roadSections: payload,
            };
        }
        case types.LOADED_POLYGON_LOADED_ROAD_SECTION: {
            return {
                ...state,
                polygonRoadSection: payload,
            };
        }
        case types.LOADING_POLYGON_LOADED_ROAD_SECTION: {
            return {
                ...state,
                loadingPolygonRoadSection: payload,
            };
        }
        case types.LOADED_SIDEBAR_ROAD_SECTION: {
            const { data, isReplace } = payload;

            return {
                ...state,
                sidebarRoadSection: isReplace
                    ? data
                    : {
                        ...state.sidebarRoadSection,
                        data: [
                            ...state.sidebarRoadSection.data,
                            ...data?.data,
                        ],
                        meta: data?.meta,
                    },
            };
        }
        case types.CLEAR_SIDEBAR_ROAD_SECTION: {
            return {
                ...state,
                sidebarRoadSection: initialState.sidebarRoadSection,
            };
        }
        case types.LOADING_SIDEBAR_ROAD_SECTION: {
            return {
                ...state,
                loadingSidebarRoadSection: payload,
            };
        }
        case types.SET_ACTIVE_ROAD_SECTION: {
            return {
                ...state,
                activeRoadSection: payload,
            };
        }
        case types.SET_FILTERS_ROAD_SECTION: {
            const newFilter = { ...payload };
            if (newFilter?.type_list?.length > 0) {
                const categoryIds = newFilter.category_list?.map(i => i.id) || [];
                const typeOthers = newFilter.type_list?.filter((type) => categoryIds.includes(type.category_id));

                if (newFilter.category_list?.length === 0) {
                    delete newFilter?.category_list;
                }
                if (typeOthers.length === 0) {
                    delete newFilter?.type_list;
                }
            }

            return {
                ...state,
                filtersRoadSection: newFilter,
            };
        }
        case types.SET_DELETE_FORM_ROAD_SECTION: {
            return {
                ...state,
                deletedFormRoadSection: payload,
            };
        }
        case types.SET_EDIT_FORM_ROAD_SECTION: {
            return {
                ...state,
                editedFormRoadSection: payload,
            };
        }

        case types.SET_SAVED_ROAD_SECTION: {
            return {
                ...state,
                savedRoadSection: payload,
            };
        }

        case types.LOADED_STATUSES_ROAD_SECTION: {
            return {
                ...state,
                statusesRoadSection: payload,
            };
        }

        case types.SET_EDIT_FORM: {
            return {
                ...state,
                editForm: payload,
            };
        }

        case types.LOADED_ROAD_SECTION_RELATION_TYPES: {
            return {
                ...state,
                relationTypes: payload,
            };
        }

        case types.LOADED_ROAD_SECTION_BY_RADIUS: {
            return {
                ...state,
                roadSectionsByRadius: payload
            };
        }

        case types.LOADED_INFRASTRUCTURE_BY_RADIUS: {
            return {
                ...state,
                infrastructureByRadius: payload
            };
        }

        case types.LOADED_OVERLAP_POLYGON:
            return {
                ...state,
                overlapPolygon: payload
            };

        case types.LOADING_OVERLAP_POLYGON:
            return {
                ...state,
                loadingOverlapPolygon: payload
            };

        case types.SET_FILTER_OVERLAP:
            return {
                ...state,
                filterOverlap: payload
            };

        case types.LOADED_OVERLAP_LIST: {
            const { data, isReplace } = payload;

            return {
                ...state,
                overlapList: isReplace
                    ? data
                    : {
                        ...state.overlapList,
                        data: [
                            ...state.overlapList.data,
                            ...data?.data,
                        ],
                        meta: data?.meta,
                    },
            };
        }

        case types.CLEAR_OVERLAP_LIST:
            return {
                ...state,
                overlapList: initialState.overlapList
            };


        case types.LOADING_OVERLAP_LIST:
            return {
                ...state,
                loadingOverlapList: payload
            };

        case types.SET_ACTIVE_OVERLAP:
            return {
                ...state,
                activeOverlap: payload
            };

        case types.LOADED_POLYGON_INFRASTRUCTURE:
            return {
                ...state,
                polygonInfrastructure: payload
            };

        case types.LOADING_POLYGON_INFRASTRUCTURE:
            return {
                ...state,
                loadingPolygonInfrastructure: payload
            };

        case types.SET_FILTER_POLYGON_INFRASTRUCTURE:
            return {
                ...state,
                filterPolygonInfrastructure: payload
            };

        case types.SET_ACTIVE_POLYGON_INFRASTRUCTURE:
            return {
                ...state,
                activePolygonInfrastructure: payload
            };

        case types.LOADED_INFRASTRUCTURE_SIDEBAR:
            const { data, isReplace } = payload;

            return {
                ...state,
                sidebarInfrastructure: isReplace
                    ? data
                    : {
                        ...state.sidebarInfrastructure,
                        data: [
                            ...state.sidebarInfrastructure.data,
                            ...data?.data,
                        ],
                        meta: data?.meta,
                    },
            };

        case types.LOADING_INFRASTRUCTURE_SIDEBAR:
            return {
                ...state,
                loadingSidebarInfrastructure: payload
            };

        case types.SAVED_INFRASTRUCTURE:
            return {
                ...state,
                savedInfrastructure: payload
            };
        case types.LOADED_OVERLAP_TYPES:
            return {
                ...state,
                overlapTypes: payload
            };

        case types.LOADED_OVERLAP_SOURCE:
            return {
                ...state,
                overlapSource: payload
            };

        case types.LOADED_STREET_LIST:
            return {
                ...state,
                streetList: payload,
            };

        case types.LOADING_STREET_LIST:
            return {
                ...state,
                loadingStreetList: payload,
            };

        case types.CLEAR_STREET_LIST:
            return {
                ...state,
                streetList: initialState.streetList,
                loadingStreetList: initialState.loadingStreetList,
            };

        case types.LOADED_CATEGORY_TYPES:
            return {
                ...state,
                categoryTypes: payload,
                loadedCategoryTypes: true
            };

        case types.LOADING_ROAD_SECTION_RELATION:
            return {
                ...state,
                loadingRoadSectionRelation: payload,
            };

        case types.LOADED_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES:
            return {
                ...state,
                videoCamerasDictionaryInfrastructureTypes: payload,
            };

        case types.LOADING_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES:
            return {
                ...state,
                loadingVideoCamerasDictionaryInfrastructureTypes: payload,
            };

        case types.LOADED_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR:
            return {
                ...state,
                videoCamerasDictionaryCommutator: payload,
            };

        case types.LOADING_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR:
            return {
                ...state,
                loadingVideoCamerasDictionaryCommutator: payload,
            };

        case types.LOADED_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE:
            return {
                ...state,
                videoCamerasDictionaryConnectionLine: payload,
            };

        case types.LOADING_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE:
            return {
                ...state,
                loadingVideoCamerasDictionaryConnectionLine: payload,
            };

        case types.LOADED_VIDEO_CAMERAS_DICTIONARY_CONNECTION:
            return {
                ...state,
                videoCamerasDictionaryConnection: payload,
            };

        case types.LOADING_VIDEO_CAMERAS_DICTIONARY_CONNECTION:
            return {
                ...state,
                loadingVideoCamerasDictionaryConnection: payload,
            };

        case types.LOADED_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM:
            return {
                ...state,
                videoCamerasDictionaryElectricalOptionalSystem: payload,
            };

        case types.LOADING_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM:
            return {
                ...state,
                loadingVideoCamerasDictionaryElectricalOptionalSystem: payload,
            };

        case types.LOADED_VIDEO_CAMERAS_DICTIONARY_DOCUMENT:
            return {
                ...state,
                videoCamerasDictionaryDocument: payload,
            };

        case types.LOADING_VIDEO_CAMERAS_DICTIONARY_DOCUMENT:
            return {
                ...state,
                loadingVideoCamerasDictionaryDocument: payload,
            };

        case types.LOADED_VIDEO_CAMERAS_DICTIONARY_POWER:
            return {
                ...state,
                videoCamerasDictionaryPower: payload,
            };

        case types.LOADING_VIDEO_CAMERAS_DICTIONARY_POWER:
            return {
                ...state,
                loadingVideoCamerasDictionaryPower: payload,
            };

        case types.LOADED_VIDEO_CAMERAS_DICTIONARY_RELIANCE:
            return {
                ...state,
                videoCamerasDictionaryReliance: payload,
            };

        case types.LOADING_VIDEO_CAMERAS_DICTIONARY_RELIANCE:
            return {
                ...state,
                loadingVideoCamerasDictionaryReliance: payload,
            };

        case types.LOADED_COMMUTATORS:
            return {
                ...state,
                commutators: payload,
            };

        case types.LOADING_COMMUTATORS:
            return {
                ...state,
                loadingCommutators: payload,
            };

        case types.LOADED_COMMUTATOR_TYPES:
            return {
                ...state,
                commutatorTypes: payload,
            };

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload
            };
        }

        case types.LOADED_CONNECTION_LINES:
            return {
                ...state,
                connectionLines: payload
            };

        case types.LOADING_CONNECTION_LINES:
            return {
                ...state,
                loadingConnectionLines: payload
            };

        case types.LOADED_PILLARS:
            return {
                ...state,
                pillars: payload
            };

        case types.LOADING_PILLARS:
            return {
                ...state,
                loadingPillars: payload
            };

        case types.LOADING_CAMERAS:
            return {
                ...state,
                loadingCameras: payload
            };

        case types.LOADED_CAMERAS: {
            return {
                ...state,
                cameras: payload
            };
        }

        case types.LOADING_CAMERA_TYPES:
            return {
                ...state,
                loadingCameraTypes: payload
            };

        case types.LOADED_CAMERA_TYPES: {
            return {
                ...state,
                cameraTypes: payload
            };
        }

        case types.LOADING_CAMERA_MODEL:
            return {
                ...state,
                loadingCameraModel: payload
            };

        case types.LOADED_CAMERA_MODEL: {
            return {
                ...state,
                cameraModel: payload
            };
        }

        case types.LOADING_CAMERA_VENDOR:
            return {
                ...state,
                loadingCameraVendor: payload
            };

        case types.LOADED_CAMERA_VENDOR: {
            return {
                ...state,
                cameraVendor: payload
            };
        }

        case types.LOADED_ELECTRICAL_OPTICAL_SYSTEMS:
            return {
                ...state,
                electricalOpticalSystems: payload
            };

        case types.LOADING_ELECTRICAL_OPTICAL_SYSTEMS:
            return {
                ...state,
                loadingElectricalOpticalSystems: payload
            };
        case types.LOADED_PAK_VENDORS:
            return {
                ...state,
                pakVendors: payload
            };

        case types.LOADING_PAK_VENDORS:
            return {
                ...state,
                loadingPakVendors: payload
            };

        case types.LOADED_PAK_MODELS:
            return {
                ...state,
                pakModels: payload
            };

        case types.LOADING_PAK_MODELS:
            return {
                ...state,
                loadingPakModels: payload
            };

        case types.LOADED_PAK_LIST:
            return {
                ...state,
                PAKList: payload
            };

        case types.LOADING_PAK_LIST:
            return {
                ...state,
                loadingPAKList: payload
            };

        case types.LOADED_DVR:
            return {
                ...state,
                DVR: payload
            };

        case types.LOADING_DVR:
            return {
                ...state,
                loadingDVR: payload
            };
        case types.LOADED_INFRASTRUCTURE_BY_POLYGON: {
            return {
                ...state,
                infrastructureByPolygon: payload,
            };
        }
        case types.LOADING_INFRASTRUCTURE_BY_POLYGON: {
            return {
                ...state,
                loadingInfrastructureByPolygon: payload,
            };
        }
        case types.LOADED_INFRASTRUCTURE_LIST: {
            return {
                ...state,
                infrastructureList: payload,
            };
        }
        case types.LOADING_INFRASTRUCTURE_LIST: {
            return {
                ...state,
                loadingInfrastructureList: payload,
            };
        }
        case types.SET_ACTIVE_INFRASTRUCTURE_LAYER: {
            return {
                ...state,
                activeInfrastructureLayer: payload
            };
        }


        case types.LOADED_CONNECTION_POINTS:
            return {
                ...state,
                connectionPoints: payload,
            };

        case types.LOADING_CONNECTION_POINTS:
            return {
                ...state,
                loadingConnectionPoints: payload,
            };

        case types.LOADED_CONNECTION_POINT_TYPES:
            return {
                ...state,
                connectionPointTypes: payload,
            };

        case types.SET_ROAD_SECTION_POLYGON_GEO_ITEM:
            return {
                ...state,
                polygonGeoItemRoadSection: payload,
            };

        default:
            return state;
    }
}
