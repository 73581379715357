import { useMemo, useState } from 'react';

import { loadVehicleCategories } from 'redux/TransportPassenger/actions';
import { config } from 'config';
import messages from 'helpers/constants/messages/common';
import titles from 'helpers/constants/titles';
import useTransportCategory, { convertCategories } from 'helpers/hooks/Transport/useTransportCategory';
import {
    CircleMarker,
    FeatureGroup,
    Map,
    MapControl,
    ToolTip,
} from 'components/MapComponents/leaflet';
import { getCheckPointColor } from 'components/MapComponents/Layers/TransportPassenger/helper';
import RouteJson from 'components/MapComponents/Layers/TransportPassenger/RouteJson';


const MapForm = ({
    currentRoute = {},
    checkPoints = []
}) => {
    const mapCenter = config.get('mapCenter');
    const [mapZoom, setMapZoom] = useState(null);
    const [bounds, setBounds] = useState(null);

    const getTransportCategory = useTransportCategory(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );
    const color = useMemo(() => {
        const categoriesObj = convertCategories(getTransportCategory?.categories);
        return categoriesObj?.[currentRoute?.category_id]?.color || '';
    }, [currentRoute?.category_id, getTransportCategory]);

    const stations = checkPoints || [];
    // const stations = checkPoints?.filter((item) => item.station) || [];

    const checkPointsMarkers = useMemo(() => {
        return stations?.map((item, index) => {
            const name = item?.station?.name || item.check_point?.name || '';
            const direction = item?.station?.direction;
            const isStation = !!item?.station;
            const key = item?.id || item?.station?.id || item?.check_point_id || index + 1;

            const { lat, lon } = item?.station?.check_point || item?.check_point;

            return (
                <CircleMarker
                    key={key}
                    latlng={[lat, lon]}
                    radius={8}
                    weight={2}
                    fillColor={isStation ? color : getCheckPointColor(color)}
                    // fillColor={color.checkPoint}
                    fillOpacity={1}
                >
                    <CircleMarker
                        latlng={[lat, lon]}
                        radius={4}
                        weight={2}
                        fillColor="#fff"
                        fillOpacity={1}
                    >
                        <ToolTip
                            offset={[0, -5]}
                            direction="top"
                        >
                            <div>
                                <div><b>{/*titles.NAME*/}{isStation ? titles.STATION : titles.CHECK_POINT}:</b> {name || messages.INFO_IS_NOT_FOUND}</div>
                                {isStation && (<div><b>{titles.DIRECTION}:</b> {direction || messages.INFO_IS_NOT_FOUND}</div>)}
                            </div>
                        </ToolTip>
                    </CircleMarker>
                </CircleMarker>
            );
        });
    }, [stations]);

    return (
        <div className="map-form-wrap__container">
            <Map
                center={mapCenter}
                zoom={mapZoom}
                bounds={bounds}
            >
                <MapControl>
                    <FeatureGroup>
                        {checkPointsMarkers}
                    </FeatureGroup>
                    <RouteJson
                        onMap={false}
                        centerAfter={true}
                        item={currentRoute}
                        key={currentRoute.id}
                        getTransportCategory={getTransportCategory}
                        disabled
                        isHidePopup
                    />
                </MapControl>
            </Map>
        </div>
    );
};

export default MapForm;
