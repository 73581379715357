/** Ленивый резолв модулей для удобного подключения фич
 * например, скопом подключить reducer'ы из папки redux
 *
 const reducersFromFeaturesFolder = {};
 lazyFeatureLoad(module => {
      reducersFromFeaturesFolder[module.moduleName] = module.reducer;
    });
 */
const reduxContext = require.context(
    '../redux',
    true,
    /^\.\/[^/]+\/index\.js$/
);

const modulesContext = require.context(
    '../modules',
    true,
    /^\.\/[^/]+\/index\.(js|ts)$/
);

export default function lazyFeatureLoad(forEachModule) {
    reduxContext.keys().forEach((key) => {
        forEachModule(reduxContext(key), key);
    });

    modulesContext.keys().forEach((key) => {
        forEachModule(modulesContext(key), key);
    });
}
