import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';

import { getDadataAddressByCoordinates } from 'redux/Dadata/actions';
import { MetroSchemeForm } from 'components/common/Metro/MetroShema';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { createLineGJLatLon } from 'components/MapComponents/leaflet/helpers/createLineGJ';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';

// import MetroList from '../MetroList';

const MetroFormMap = ({
    data,
    setData,
    loading,
    onLoadObjects,
}) => {
    const dispatch = useDispatch();
    const radius = 100;

    // тип
    const [type, setType] = useState(0);
    // станции
    const [stations, setStations] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSave = () => {
        const station = stations[0];

        setData(old => ({
            ...old,
            metro_stations: stations,
            type_close_id: type,
            lat: station.lat,
            lon: station.lon,
            geometry: stations.length === 1
                ? createPointGJ(station.lat, station.lon, { radius })
                : createLineGJLatLon(stations, { radius }),
            objects: [],
        }));

        // подгрузка адреса
        dispatch(getDadataAddressByCoordinates(
            station.lat,
            station.lon,
            (address) => setData(old => ({
                ...old,
                address,
            }))
        ));

        if (stations.length > 0) onLoadObjects(stations);

        setIsOpen(false);
    };

    const handleOpenModal = () => {
        setType(data?.type_close_id || 0);
        setStations(data?.metro_stations || []);
        setIsOpen(true);
    };

    const isDisabled = useMemo(() => {
        const getIds = (list) => list?.map(i => i.id) || [];
        return stations?.length === 0
            || !type
            || isEqual(getIds(stations), getIds(data.metro_stations));
    }, [stations, type, data?.metro_stations]);

    return (
        <>
            <Modal
                isOpen={isOpen}
                title="Схема"
                onClose={handleClose}
                fullWidth
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: handleClose
                            },
                            {
                                ...buttonsTypes.save,
                                onClick: handleSave,
                                disabled: isDisabled
                            }
                        ]}
                    />
                }
            >
                <MetroSchemeForm
                    type={type || 0}
                    setType={setType}
                    stations={stations}
                    setStations={setStations}
                />
            </Modal>

            {/*<MetroList stations={data?.metro_stations || []} />*/}

            <div className="block">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.select,
                            name: 'Выбрать станции',
                            onClick: handleOpenModal,
                            color: 'infoButton',
                            disabled: loading,
                            loading,
                        },
                    ]}
                    positionLeft
                    afterTemplate={loading ? <div>Загрузка данных</div> : null}
                />
            </div>
        </>
    );
};

export default MetroFormMap;
