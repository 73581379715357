import { useState, ChangeEvent } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';

import { loadConnectionPointTypes } from 'modules/InfrastructureObjects/redux/actions';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';

import { FilterValues, FiltersProps } from './types';

const Filters = ({ setParams }: FiltersProps) => {
    const connectionPointTypes = useStoreProp(loadConnectionPointTypes, 'roadNetworks', 'connectionPointTypes');
    const validation = useValidation();

    const [value, setValue] = useState<FilterValues>({
        name: '',
        type_id: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        const { name, value: newValue } = e.target;
        if (name) {
            setValue(prev => ({
                ...prev,
                [name]: newValue,
            }));
        }
    };

    const search = (data: FilterValues = value) => {
        if (data.name || data.type_id) {
            setParams({ page: 1, data: removeEmptyFields(data) });
        }
    };

    const resetSearch = (needRefresh: boolean) => {
        setValue({ name: '', type_id: '' });
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const updateFilter = (filters: FilterValues) => {
        setValue(filters);
        if (Object.keys(filters).length > 0) {
            search(filters);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={() => resetSearch(true)}
            onSearch={() => search()}
            filter={value}
            setUserFilter={updateFilter}
            disabled={!value.name && !value.type_id}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={value.name || ''}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    name="name"
                />
                <FormInfoWrapper
                    helperText={validation.get('type_id')}
                    error={validation.isKey('type_id')}
                >
                    <FormControl size={'small'} variant="outlined" className="block" required>
                        <InputLabel>Тип</InputLabel>
                        <Select
                            value={value.type_id || ''}
                            onChange={handleChange}
                            label="Тип"
                            name="type_id"
                            required
                        >
                            <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                            {connectionPointTypes && Object.keys(connectionPointTypes).map((key) => (
                                <MenuItem key={key} value={key}>{connectionPointTypes[key]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
