import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';

import {
    deleteInfrastructure,
    editInfrastructure,
    infrastructureSubscription,
} from 'modules/InfrastructureObjects/redux/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons } from 'components/common/List';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { helpers, ObjectBindingModal } from 'modules/DigitalTwin/utils/components/ObjectBindings';

import Modal from './Modal';
import SubscriptionModal from './SubscriptionModal';

function Actions({ el, reloadList }) {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
    const [isModalLink, setIsModalLink] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(
            deleteInfrastructure(el.id, () => {
                reloadList();
                setOpenDeleteModal(false);
            })
        );
    };

    const onSaveData = (data) => {
        const newData = {
            ...removeEmptyFields(data, true, true),
            repair_objects: data.repair_objects,
        };

        dispatch(editInfrastructure(el.id, newData, () => {
            reloadList();
            setOpenEditModal(false);
        }));
    };

    const onSubscribe = () => {
        dispatch(infrastructureSubscription(el.id, () => {
            setOpenSubscriptionModal(false);
            reloadList();
        }));
    };
    return (
        <>
            <div className="flex-end">
                <FieldsModal
                    hideButtonAccept={true}
                    title={titles.SEE_ON_MAP}
                    iconButton={
                        <Tooltip title={buttons.SHOW_ON_MAP}>
                            <i className="fas fa-map-marked" />
                        </Tooltip>
                    }
                >
                    <MapGeoJson geometry={el?.geometry || {}} readOnly={true} />
                </FieldsModal>

                <ActionsButtons
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            name: el?.is_subscribe ? 'Отменить подписку' : 'Подписаться',
                            onClick: () => setOpenSubscriptionModal(true),
                            icon: (
                                <i className={el?.is_subscribe ? 'far fa-times' : 'far fa-check'} />
                            ),
                        },
                        {
                            ...buttonsTypes.linkIcon,
                            onClick: () => setIsModalLink(true),
                        },
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setOpenEditModal(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setOpenDeleteModal(true),
                        },
                    ]}
                />
            </div>

            {openEditModal && (
                <Modal
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    el={el}
                    onAcceptModal={onSaveData}
                />
            )}

            {openSubscriptionModal && (
                <SubscriptionModal
                    isOpen={openSubscriptionModal}
                    isSubscribe={el?.is_subscribe}
                    onClose={() => setOpenSubscriptionModal(false)}
                    onAcceptModal={onSubscribe}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isOpenDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}

            {isModalLink && (
                <ObjectBindingModal
                    isOpen={isModalLink}
                    currentId={el.id}
                    currentType={helpers.eputsTypes.infrastructure}
                    currentLatLon={el.lat && el.lon ? [el.lat, el.lon] : null}
                    onClose={() => setIsModalLink(false)}
                />
            )}
        </>
    );
}

export default Actions;
