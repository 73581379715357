import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Collapse, IconButton } from '@mui/material';

import { loadDtpById } from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Image from 'components/common/Image';

import styles from './dtp.module.scss';

const data = {
    information: {
        camera_name: { name: 'Название камеры' },
        count_members: { name: titles.COUNT_DTP_MEMBERS },
        wounded: { name: titles.COUNT_DTP_WOUNDED },
        dead: { name: titles.COUNT_DTP_DEAD },
        count_ts: { name: titles.COUNT_DTP_TS },
        km: { name: titles.DTP_KM },
        m: { name: titles.DTP_M },
        description: { name: titles.DTP_DESCRIPTION },
        schema_img_url: { name: titles.SCHEMA_DTP },
        type_load_text: { name: titles.DTP_DATA_SOURCE },
    },
    situation: {
        road: { name: titles.ROAD },
        road_value: { name: titles.ROAD_VALUE },
        road_category: { name: titles.ROAD_CATEGORY },
        road_condition: { name: titles.ROAD_CONDITION },
        street_category: { name: titles.STREET_CATEGORY },
        weather: { name: titles.WEATHER },
        lighting: { name: titles.LIGHTING },
        changes_driving: { name: titles.CHANGES_DRIVING },
    },
};

function Info({ info, isOpen }) {
    const dispatch = useDispatch();

    const [dtp, setDtp] = useState(info);
    const [openPreview, setOpenPreview] = useState(false);
    const [openVideo, setOpenVideo] = useState(false);

    useEffect(() => {
        dispatch(loadDtpById(info.id, setDtp));
    }, [dispatch, info.id]);

    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
            <div className={styles.dtp_info_block}>
                <div className={styles.dtp_info_block__item}>
                    {Object.keys(data.information).map((key) => {
                        const { name, value } = data.information[key];
                        const itemValue = value ? value(dtp[key]) : dtp[key];

                        return (itemValue || itemValue === 0) && (
                            <p key={key}>
                                <b>{name}:</b> {itemValue}
                            </p>
                        );
                    })}
                </div>
                <div className={styles.dtp_info_block__item}>
                    {Object.keys(data.situation).map((key) => {
                        const { name, value } = data.situation[key];
                        const itemValue = value ? value(dtp[key]) : dtp[key];

                        return (itemValue || itemValue === 0) && (
                            <p key={key}>
                                <b>{name}:</b> {itemValue}
                            </p>
                        );
                    })}
                </div>
            </div>

            <div>
                {dtp?.video_url && (
                    <div style={{ padding: '0 16px' }}>
                        <Button onClick={() => setOpenVideo(true)}>Посмотреть видео</Button>
                    </div>
                )}

                {dtp?.frame_url && (
                    <IconButton onClick={() => setOpenPreview(true)}>
                        <div className={styles.preview}>
                            <Image src={dtp.frame_url} alt="preview"/>
                        </div>
                    </IconButton>
                )}
            </div>

            {openPreview && (
                <Modal
                    title={'Превью'}
                    isOpen={openPreview}
                    onClose={() => setOpenPreview(false)}
                    fullWidth
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenPreview(false)
                            },
                        ]}
                    />}
                >
                    <div className={styles.preview_modal}>
                        <Image src={dtp?.frame_url} alt="preview"/>
                    </div>
                </Modal>
            )}

            {openVideo && (
                <Modal
                    title={'Видео'}
                    isOpen={openVideo}
                    onClose={() => setOpenVideo(false)}
                    fullWidth
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenVideo(false)
                            },
                        ]}
                    />}
                >
                    <div className={styles.preview_modal}>
                        <video src={dtp?.video_url} controls width="100%" height="100%" />
                    </div>
                </Modal>
            )}
        </Collapse>
    );
}

export default Info;
