import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import L from 'leaflet';

import mapHelper from 'helpers/mapHelper';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    GeoJsonNew,
    Marker,
    ToolTip,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { MapPopUpListener }  from 'components/MapComponents/MapPopUp';
import { clearCongestionsPolygon, loadCongestionsPolygon, setNgptActive, setNgptSaved } from 'modules/HeatMapCongestions/redux/actions';
import { heatMapCongestionsSelectors } from 'modules/HeatMapCongestions';

import PopUpModal from './PopUpModal';
import config from './config.js';
import { getFilter, getMapMarker, getMarkerColor } from './helper';


const Layer = (props) => {
    const { map, hideEmpty = false } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const polygon = useSelector(heatMapCongestionsSelectors.congestionPolygon);
    const active = useSelector(heatMapCongestionsSelectors.ngptActive);
    const filters = useSelector(heatMapCongestionsSelectors.ngptFilters);
    const ngptSaved = useSelector(heatMapCongestionsSelectors.ngptSaved);

    const showPopUp = useRef(false);
    // выбранный геообъект
    const [selectedItem, setSelectedItem] = useState(null);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(loadCongestionsPolygon({
            polygon,
            ...getFilters(filters, getFilter)
        }));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    const handleClickMap = () => {
        setSelectedItem(null);
    };

    useEffect(() => {
        if (ngptSaved) {
            fetchPolygon();
            dispatch(setNgptSaved(false));
        }
    }, [ngptSaved]);

    useEffect(() => {
        fetchPolygon();

        map
            .on('click', handleClickMap)
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(setNgptActive());
            dispatch(clearCongestionsPolygon());
            map
                .off('click', handleClickMap)
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active;

            if (geometry) {
                showPopUp.current = active.uuid;
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);
            }

            setSelectedItem(active?.uuid || null);
        }
    }, [active]);

    // собираем
    const {
        markers,
        staticMarkers,
    } = useMemo(() => {
        return polygon?.reduce(({
            markers,
            staticMarkers,
        }, item) => {
            // маркер
            if (item?.geometry?.geometry?.type === 'Point' && !item?.geometry?.properties?.radius) {
                const {
                    geometry,
                    ...prs
                } = item;

                return {
                    markers,
                    staticMarkers: [
                        ...staticMarkers,
                        {
                            ...geometry,
                            properties: {
                                ...geometry.properties,
                                ...prs,
                                geometry
                            },
                            attribution: {
                                slug: config.slug,
                                color: getMarkerColor
                            },
                            style: {
                                color: getMarkerColor,
                                weight: 7
                            }
                        }
                    ],
                };
            }

            // остальные геообъекты
            const { lat, lng } = item.lat && item.lon
                ? { lat: item.lat, lng: item.lon }
                : L.geoJSON(item?.geometry).getBounds().getCenter();

            return {
                staticMarkers,
                markers: [
                    ...markers,
                    {
                        uuid: item.uuid,
                        key: item.uuid,
                        icon: getMapMarker(),
                        latlng: [lat, lng],
                        attribution: {
                            slug: config.slug,
                            color: getMarkerColor
                        },
                        data: item || {},
                    }
                ]
            };
        }, {
            markers: [],
            staticMarkers: [],
        });

    }, [polygon]);

    const handleClick = (item) => () => {
        setSelectedItem(item?.data?.uuid || null);
    };

    const geoItem = useMemo(() => {
        const item = polygon?.find(({ uuid }) => uuid === selectedItem);

        if (selectedItem && item) {
            const {
                geometry,
                ...props
            } = item ||  {};

            return {
                ...geometry,
                properties: {
                    ...geometry.properties,
                    ...props,
                    geometry
                },
                attribution: {
                    slug: config.slug,
                    color: getMarkerColor
                },
                style: {
                    color: getMarkerColor,
                    weight: 7
                }
            };
        }

        return null;
    }, [selectedItem, polygon]);

    return (
        <>
            {/* маркеры */}
            <GeoJsonNew
                {...props}
                data={staticMarkers}
                icon={getMapMarker}
                toolTipTemplate={({ uuid }) => <div>{uuid}</div>}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, 0],
                    sticky: true,
                }}
                idPrefix={config.slug}
                onClick={(item) => {
                    setSelectedItem(null);
                    dispatch(setNgptActive(item));
                }}
            />

            {/* геообъекты маркерами */}
            {markers?.map((item, index) => {
                if (selectedItem !== item?.uuid) {
                    return (
                        <Marker
                            {...props}
                            {...item}
                            key={index}
                            onClick={handleClick(item)}
                        >
                            <ToolTip
                                direction="top"
                                offset={[0, -40]}
                            >
                                <div>Кликните, чтобы отобразить объект</div>
                            </ToolTip>
                        </Marker>
                    );
                }

                return null;
            })}

            {/* кликнутый геообъект (линия, полигон, окружность)*/}
            {geoItem && (
                <GeoJsonNew
                    {...props}
                    parent={map}
                    data={geoItem}
                    icon={() => getMapMarker()}
                    toolTipTemplate={({ uuid }) => <div>{uuid || ''}</div>}
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, 0],
                        sticky: true,
                    }}
                    idPrefix={config.slug}
                    centerAfter={true}
                    onClick={(item) => {
                        dispatch(setNgptActive(item));
                    }}
                />
            )}

            {/* popup */}
            <MapPopUpListener
                activeSelector={heatMapCongestionsSelectors.ngptActive}
                polygonSelector={heatMapCongestionsSelectors.congestionPolygon}
                keyProp="uuid"
            >
                <PopUpModal
                    history={history}
                    hideEmpty={hideEmpty}
                    onClose={() => {
                        dispatch(setNgptActive());
                    }}
                />
            </MapPopUpListener>

        </>
    );
};

export default Layer;
