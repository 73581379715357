import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadEvents } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import titles from 'helpers/constants/titles';
import reactTitles from 'modules/React/utils/helpers/constants';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';

import ModalForm from './ModalForm';
import Item from './Item';
import Filter from './Filter';

const Events = () => {
    const dispatch = useDispatch();

    const data = useSelector(reactSelectors.eventsData);
    const meta = useSelector(reactSelectors.eventsMeta);
    const loadingEvents = useSelector(reactSelectors.loadingEvents);

    const [openModal, setOpenModal] = useState(false);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const initState = {
        name: '',
        date_start: null,
        date_end: null,
    };

    const [params, setParams] = useState({
        page: 1,
        limit: storageLimit,
        data: initState
    });

    useEffect(() => {
        const newParams = removeEmptyFields({
            ...params.data,
            date_start: fullDateTimeWithTimeZone(params.data.date_start),
            date_end: fullDateTimeWithTimeZone(params.data.date_end)
        });
        dispatch(loadEvents(params.page, params.limit, newParams));
    },[params, dispatch]);

    const renderContent = () => {
        return data?.length > 0
            ? (<>
                <LSContainer
                    headers={[
                        { title: reactTitles.NAME, width: '30%' },
                        { title: titles.START_DATE, width: '30%' },
                        { title: titles.END_DATE, width: '30%' },
                        { title: titles.ACTION, isActions: true },
                    ]}
                >
                    {data?.map((item) => <Item
                        key={item.id + item.name}
                        item={item}
                    />)}

                </LSContainer>

            </>)
            : (!loadingEvents && <div>{messages.DATA_IS_NOT_FOUND}</div>);

    };

    return (<>
        <PageLayout
            header="Мероприятия"
            filters={<Filter
                setParams={(data) => setParams((prev) => ({ ...prev, page: 1, data }))}
                initState={initState}
            />}
            informPanelProps={{
                buttons: (
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: () => setOpenModal(true),
                            }
                        ]}
                        noPadding
                    />
                ),
                total: meta?.total,
            }}
            loading={loadingEvents}
            content={renderContent}
            paginationProps={{
                loadList: (page,) => setParams((prev) => ({ ...prev, page, data: prev.data })),
                list: meta,
                limit: params.limit,
                setLimit: (limit) => setParams((prev) => ({ ...prev, limit }))
            }}
        />

        {openModal
            && <ModalForm
                isOpen={openModal}
                onClose={setOpenModal}
                isNew={true}
            />
        }

    </>
    );
};

export default Events;
