import { useState } from 'react';
import {
    Button,
    TableCell,
    TableRow,
} from '@mui/material';
import {
    ExpandLess,
    ExpandMore
} from '@mui/icons-material';

import { getDateToFormat } from 'helpers/date.config';

import Inner from './Inner';

const Item = (props) => {
    const {
        month,
        counts,
        categoryIds,
        onShowDtp = (id) => {}
    } = props;

    const result = counts?.result || [];
    const [open, setOpen] = useState(null);

    return (
        <>
            <TableRow style={{ borderBottom: 'unset' }}>
                <TableCell width={180} align="left">
                    <Button
                        color="primary"
                        sx={{
                            fontSize: '0.875rem',
                            lineHeight: '1.43',
                            fontWeight: 400,
                        }}
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open
                            ? <ExpandLess />
                            : <ExpandMore />
                        }
                        {month}
                    </Button>

                </TableCell>
                <TableCell align="center">{counts.total || 0}</TableCell>
                {categoryIds.map((catId) => (
                    <TableCell
                        key={`cluster-info-${catId}`}
                        align="center"
                    >
                        {counts[catId] || 0}
                    </TableCell>
                ))}
            </TableRow>

            {result.map((item, index) => {
                const {
                    dtp_at,
                    address,
                    address_text,
                    categories,
                    id,
                } = item;

                const categoriesCount = categories
                    .reduce((r, i) =>
                        i.dtp_category
                            ? {
                                ...r,
                                [i.dtp_category]: i.count || 0
                            }
                            : r, {});

                const addressText = [
                    address.street,
                    address.house,
                    ''
                ].reduce((r, i) => i ? [...r, i] : r, [])
                    .join(', ');

                return (
                    <TableRow key={index}>
                        <Inner
                            width={180}
                            colSpan={2}
                            open={open}
                        >
                            <Button
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    onShowDtp(id);
                                }}
                                style={{
                                    textAlign: 'left'
                                }}
                            >
                                {getDateToFormat(dtp_at, 'dd.MM, HH:mm')}<br/>{address_text || addressText}
                            </Button>
                        </Inner>
                        {categoryIds.map((catId) => (
                            <Inner
                                key={catId}
                                open={open}
                                align="center"
                            >
                                {categoriesCount[catId] || 0}
                            </Inner>
                        ))}
                    </TableRow>
                );
            })}
        </>
    );
};

export default Item;
