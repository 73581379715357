import { moduleName } from '../module-config';

const prefix = `${moduleName}`;

export const LOAD_INCIDENTS = `${prefix}/LOAD_INCIDENTS`;
export const LOADING_INCIDENTS = `${prefix}/LOADING_INCIDENTS`;
export const LOADED_INCIDENTS = `${prefix}/LOADED_INCIDENTS`;
export const CLEAR_INCIDENTS = `${prefix}/CLEAR_INCIDENTS`;
export const LOAD_INCIDENT_BY_ID = `${prefix}/LOAD_INCIDENT_BY_ID`;

export const LOAD_OPERATION_TYPES = `${prefix}/LOAD_OPERATION_TYPES`;
export const LOADED_OPERATION_TYPES = `${prefix}/LOADED_OPERATION_TYPES`;

export const LOAD_TYPICAL_OPERATIONS = `${prefix}/LOAD_TYPICAL_OPERATIONS`;
export const LOADED_TYPICAL_OPERATIONS = `${prefix}/LOADED_TYPICAL_OPERATIONS`;
export const LOADING_TYPICAL_OPERATIONS = `${prefix}/LOADING_TYPICAL_OPERATIONS`;
export const CREATE_TYPICAL_OPERATION = `${prefix}/CREATE_TYPICAL_OPERATION`;
export const EDIT_TYPICAL_OPERATION = `${prefix}/EDIT_TYPICAL_OPERATION`;
export const DELETE_TYPICAL_OPERATION = `${prefix}/DELETE_TYPICAL_OPERATION`;

export const CREATE_INCIDENT = `${prefix}/CREATE_INCIDENT`;
export const EDIT_INCIDENT = `${prefix}/EDIT_INCIDENT`;
export const DELETE_INCIDENT = `${prefix}/DELETE_INCIDENT`;

export const LOAD_INCIDENT_SIDEBAR = `${prefix}/LOAD_INCIDENT_SIDEBAR`;
export const LOADING_INCIDENT_SIDEBAR = `${prefix}/LOADING_INCIDENT_SIDEBAR`;
export const LOADED_INCIDENT_SIDEBAR = `${prefix}/LOADED_INCIDENT_SIDEBAR`;
export const CLEAR_INCIDENT_SIDEBAR = `${prefix}/CLEAR_INCIDENT_SIDEBAR`;
export const NEED_RELOAD_INCIDENT_SIDEBAR = `${prefix}/NEED_RELOAD_INCIDENT_SIDEBAR`;

export const LOAD_INCIDENT_POLYGON = `${prefix}/LOAD_INCIDENT_POLYGON`;
export const LOADING_INCIDENT_POLYGON = `${prefix}/LOADING_INCIDENT_POLYGON`;
export const LOADED_INCIDENT_POLYGON = `${prefix}/LOADED_INCIDENT_POLYGON`;

export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const SET_SAVED = `${prefix}/SET_SAVED`;
export const SET_EDIT_FORM = `${prefix}/SET_EDIT_FORM`;
export const SET_DELETE_FORM = `${prefix}/SET_DELETE_FORM`;

export const LOAD_INCIDENT_HEAT_MAP = `${prefix}/LOAD_INCIDENT_HEAT_MAP`;
export const LOADING_INCIDENT_HEAT_MAP = `${prefix}/LOADING_INCIDENT_HEAT_MAP`;
export const LOADED_INCIDENT_HEAT_MAP = `${prefix}/LOADED_INCIDENT_HEAT_MAP`;

export const LOAD_INCIDENTS_STATUSES = `${prefix}/LOAD_INCIDENTS_STATUSES`;
export const LOADED_INCIDENTS_STATUSES = `${prefix}/LOADED_INCIDENTS_STATUSES`;

export const LOAD_INCIDENTS_FACTORS = `${prefix}/LOAD_INCIDENTS_FACTORS`;
export const LOADED_INCIDENTS_FACTORS = `${prefix}/LOADED_INCIDENTS_FACTORS`;
export const LOADING_INCIDENTS_FACTORS = `${prefix}/LOADING_INCIDENTS_FACTORS`;
export const CREATE_INCIDENTS_FACTORS = `${prefix}/CREATE_INCIDENTS_FACTORS`;
export const EDIT_INCIDENTS_FACTORS = `${prefix}/EDIT_INCIDENTS_FACTORS`;
export const DELETE_INCIDENTS_FACTORS = `${prefix}/DELETE_INCIDENTS_FACTORS`;

export const LOAD_RESPONSE_SCENARIOS = `${prefix}/LOAD_RESPONSE_SCENARIOS`;
export const LOADING_RESPONSE_SCENARIOS = `${prefix}/LOADING_RESPONSE_SCENARIOS`;
export const LOADED_RESPONSE_SCENARIOS = `${prefix}/LOADED_RESPONSE_SCENARIOS`;
export const LOADED_RESPONSE_SCENARIOS_FOR_SELECT = `${prefix}/LOADED_RESPONSE_SCENARIOS_FOR_SELECT`;
export const CREATE_RESPONSE_SCENARIO = `${prefix}/CREATE_RESPONSE_SCENARIO`;
export const EDIT_RESPONSE_SCENARIO = `${prefix}/EDIT_RESPONSE_SCENARIO`;
export const DELETE_RESPONSE_SCENARIO = `${prefix}/DELETE_RESPONSE_SCENARIO`;
export const GET_RESPONSE_SCENARIO_BY_ID = `${prefix}/GET_RESPONSE_SCENARIO_BY_ID`;

export const LOAD_CATEGORIES = `${prefix}/LOAD_CATEGORIES`;
export const LOADING_CATEGORIES = `${prefix}/LOADING_CATEGORIES`;
export const LOADED_CATEGORIES = `${prefix}/LOADED_CATEGORIES`;

export const LOAD_EVENTS = `${prefix}/LOAD_EVENTS`;
export const LOADING_EVENTS = `${prefix}/LOADING_EVENTS`;
export const LOADED_EVENTS = `${prefix}/LOADED_EVENTS`;
export const CREATE_EVENT = `${prefix}/CREATE_EVENT`;
export const EDIT_EVENT = `${prefix}/EDIT_EVENT`;
export const DELETE_EVENT = `${prefix}/DELETE_EVENT`;

export const LOAD_EVENT_OBJECTS = `${prefix}/LOAD_EVENT_OBJECTS`;
export const LOADING_EVENT_OBJECTS = `${prefix}/LOADING_EVENT_OBJECTS`;
export const LOADED_EVENT_OBJECTS = `${prefix}/LOADED_EVENT_OBJECTS`;
export const LOAD_EVENT_OBJECTS_TYPES = `${prefix}/LOAD_EVENT_OBJECTS_TYPES`;
export const CREATE_EVENT_OBJECTS = `${prefix}/CREATE_EVENT_OBJECTS`;
export const EDIT_EVENT_OBJECTS = `${prefix}/EDIT_EVENT_OBJECTS`;
export const DELETE_EVENT_OBJECTS = `${prefix}/DELETE_EVENT_OBJECTS`;
export const LOADED_EVENT_OBJECTS_TYPES = `${prefix}/LOADED_EVENT_OBJECTS_TYPES`;

export const LOAD_THREAT_LEVELS = `${prefix}/LOAD_THREAT_LEVELS`;
export const LOADING_THREAT_LEVELS = `${prefix}/LOADING_THREAT_LEVELS`;
export const LOADED_THREAT_LEVELS = `${prefix}/LOADED_THREAT_LEVELS`;
export const CREATE_THREAT_LEVEL = `${prefix}/CREATE_THREAT_LEVEL`;
export const EDIT_THREAT_LEVEL = `${prefix}/EDIT_THREAT_LEVEL`;
export const DELETE_THREAT_LEVEL = `${prefix}/DELETE_THREAT_LEVEL`;

export const LOAD_VEHICLES_CATEGORIES = `${prefix}/VEHICLES/CATEGORIES/LOAD`;
export const LOADED_VEHICLES_CATEGORIES = `${prefix}/VEHICLES/CATEGORIES/LOADED`;

export const LOAD_INCIDENT_EVENTS = `${prefix}/LOAD_INCIDENT_EVENTS`;
export const LOADING_INCIDENT_EVENTS = `${prefix}/LOADING_INCIDENT_EVENTS`;
export const LOADED_INCIDENT_EVENTS = `${prefix}/LOADED_INCIDENT_EVENTS`;

export const LOAD_PHENOMENONS = `${prefix}/LOAD_PHENOMENONS`;
export const LOADING_PHENOMENONS = `${prefix}/LOADING_PHENOMENONS`;
export const LOADED_PHENOMENONS = `${prefix}/LOADED_PHENOMENONS`;

export const LOAD_TRIGGER_STATUSES = `${prefix}/LOAD_TRIGGER_STATUSES`;
export const LOADED_TRIGGER_STATUSES = `${prefix}/LOADED_TRIGGER_STATUSES`;
export const LOAD_TRIGGERS = `${prefix}/LOAD_TRIGGERS`;
export const LOADING_TRIGGERS = `${prefix}/LOADING_TRIGGERS`;
export const LOADED_TRIGGERS = `${prefix}/LOADED_TRIGGERS`;
export const CREATE_TRIGGER = `${prefix}/CREATE_TRIGGER`;
export const EDIT_TRIGGER = `${prefix}/EDIT_TRIGGER`;
export const DELETE_TRIGGER = `${prefix}/DELETE_TRIGGER`;
export const LOAD_POLYGON_FOR_TRIGGERS = `${prefix}/LOAD_POLYGON_FOR_TRIGGERS`;

export const LOAD_INCIDENT_TYPES = `${prefix}/LOAD_INCIDENT_TYPES`;
export const LOADED_INCIDENT_TYPES = `${prefix}/LOADED_INCIDENT_TYPES`;
export const LOADING_INCIDENT_TYPES = `${prefix}/LOADING_INCIDENT_TYPES`;
export const CREATE_INCIDENT_TYPE = `${prefix}/CREATE_INCIDENT_TYPE`;
export const EDIT_INCIDENT_TYPE = `${prefix}/EDIT_INCIDENT_TYPE`;
export const DELETE_INCIDENT_TYPE = `${prefix}/DELETE_INCIDENT_TYPE`;
export const GET_INCIDENT_TYPE_BY_ID = `${prefix}/GET_INCIDENT_TYPE_BY_ID`;

export const LOAD_INCIDENT_HISTORY_BY_ID = `${prefix}/LOAD_INCIDENT_HISTORY_BY_ID`;
export const LOADING_INCIDENT_HISTORY_BY_ID = `${prefix}/LOADING_INCIDENT_HISTORY_BY_ID`;
export const LOADED_INCIDENT_HISTORY_BY_ID = `${prefix}/LOADED_INCIDENT_HISTORY_BY_ID`;

export const LOAD_KEY_WORD_LIST = `${prefix}/LOAD_KEY_WORD_LIST`;
export const LOADED_KEY_WORD_LIST = `${prefix}/LOADED_KEY_WORD_LIST`;
export const LOADING_KEY_WORD_LIST = `${prefix}/LOADING_KEY_WORD_LIST`;
export const EDIT_KEY_WORD = `${prefix}/EDIT_KEY_WORD`;
export const ADD_KEY_WORD = `${prefix}/ADD_KEY_WORD`;
export const DELETE_KEY_WORD = `${prefix}/DELETE_KEY_WORD`;

export const LOAD_INFOPANEL = `${prefix}/LOAD_INFOPANEL`;
export const LOADING_INFOPANEL = `${prefix}/LOADING_INFOPANEL`;
export const LOADED_INFOPANEL = `${prefix}/LOADED_INFOPANEL`;
export const CLEAR_INFOPANEL = `${prefix}/CLEAR_INFOPANEL`;

export const LOAD_INCIDENT_CATEGORIES = `${prefix}/LOAD_INCIDENT_CATEGORIES`;
export const LOADING_INCIDENT_CATEGORIES = `${prefix}/LOADING_INCIDENT_CATEGORIES`;
export const LOADED_INCIDENT_CATEGORIES = `${prefix}/LOADED_INCIDENT_CATEGORIES`;

export const LOAD_INCIDENT_OBJECTS = `${prefix}/LOAD_INCIDENT_OBJECTS`;
export const LOAD_INCIDENT_OBJECTS_TYPES = `${prefix}/LOAD_INCIDENT_OBJECTS_TYPES`;
export const LOADED_INCIDENT_OBJECTS_TYPES = `${prefix}/LOADED_INCIDENT_OBJECTS_TYPES`;

export const LOAD_INCIDENTS_CLOSE_REASONS = `${prefix}/LOAD_INCIDENTS_CLOSE_REASONS`;
export const LOAD_INCIDENTS_CLOSE_TYPES = `${prefix}/LOAD_INCIDENTS_CLOSE_TYPES`;

export const LOAD_INCIDENTS_NOTIFICATIONS = `${prefix}/LOAD_INCIDENTS_NOTIFICATIONS`;
export const CREATE_INCIDENTS_NOTIFICATIONS = `${prefix}/CREATE_INCIDENTS_NOTIFICATIONS`;

export const LOAD_INCIDENT_OPERATIONS = `${prefix}/LOAD_INCIDENT_OPERATIONS`;
export const LOADING_INCIDENT_OPERATIONS = `${prefix}/LOADING_INCIDENT_OPERATIONS`;
export const LOADED_INCIDENT_OPERATIONS = `${prefix}/LOADED_INCIDENT_OPERATIONS`;

export const EDIT_INCIDENT_OPERATIONS = `${prefix}/EDIT_INCIDENT_OPERATIONS`;

export const LOAD_STORIES = `${prefix}/LOAD_STORIES`;
export const LOADED_STORIES = `${prefix}/LOADED_STORIES`;
export const LOADING_STORIES = `${prefix}/LOADING_STORIES`;
export const CREATE_STORY = `${prefix}/CREATE_STORY`;
export const EDIT_STORY = `${prefix}/EDIT_STORY`;
export const DELETE_STORY = `${prefix}/DELETE_STORY`;

export const LOAD_STORY_STATUSES = `${prefix}/LOAD_STORY_STATUSES`;
export const LOAD_STORY_CONNECTION = `${prefix}/LOAD_STORY_CONNECTION`;
export const LOADED_STORY_STATUSES = `${prefix}/LOADED_STORY_STATUSES`;
export const LOADED_STORY_CONNECTION = `${prefix}/LOADED_STORY_CONNECTION`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;
