import * as types from './types';

const initialState = {
    colectionServicesList: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingColectionServices: false,
    types: [],
    templates: [],
    services: [],
    jobsList: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingJobs: false,
    period: []
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_COLLECTION_SERVICES: {
            return {
                ...state,
                colectionServicesList: payload
            };
        }

        case types.LOADING_COLLECTION_SERVICES: {
            return {
                ...state,
                loadingColectionServices: payload
            };
        }

        case types.LOADED_COLLECTION_SERVICES_TYPES: {
            return {
                ...state,
                types: payload
            };
        }

        case types.LOADED_COLLECTION_SERVICES_TEMPLATES: {
            return {
                ...state,
                templates: payload
            };
        }

        case types.LOADED_COLLECTION_SERVICES_SERVICES: {
            return {
                ...state,
                services: payload
            };
        }

        case types.LOADED_JOBS: {
            return {
                ...state,
                jobsList: payload
            };
        }

        case types.LOADING_JOBS: {
            return {
                ...state,
                loadingJobs: payload
            };
        }

        case types.LOADED_JOBS_PERIOD: {
            return {
                ...state,
                period: payload
            };
        }

        default:
            return state;
    }
}
