import React from 'react';
import { useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import type { Components } from 'react-virtuoso';
import { List as MUIList } from '@mui/material';
import { debounce } from 'lodash';

import { notificationsSelectors } from 'redux/Notifications';
import { useNotifications } from 'helpers/hooks';
import { useWsAlerts } from 'helpers/ws/hooks';
import Loading from 'components/common/Loading';
import NoData from 'components/common/Information/NoData';

import NotificationItem from './NotificationItem';

import type { Meta } from 'types/pagination';
import type { Notification } from '../../types';

const List: Components['List'] = React.forwardRef(({ style, children }, ref) => {
    return (
        <MUIList style={style} {...({ ref: ref } as any)}>
            {children}
        </MUIList>
    );
});

interface Context {
    loading: boolean;
}

const Footer: Components['Footer'] = ({ context }) => {
    const ctx = context as Context;

    if (!ctx?.loading) {
        return null;
    }

    return (
        <div>
            <Loading circular />
        </div>
    );
};

interface ListProps {
    params: { page: number; limit: number; filter: any; props: any };
    openedTab: any;
    onClose: () => void;
}

function NotificationList({ params, openedTab, onClose }: ListProps) {
    const notifications = useNotifications();
    const alertsProvider = useWsAlerts();

    const meta: Meta = useSelector(notificationsSelectors.listMeta);
    const loading: boolean = useSelector(notificationsSelectors.loading);
    const list: Notification[] = useSelector(notificationsSelectors.listData);

    const hasMore = meta?.current_page < meta?.last_page;

    const handleLoadMore = () => {
        if (!loading && hasMore) {
            notifications.loadList(meta?.current_page + 1, params.limit, {
                ...params.filter,
                ...params.props,
            });
        }
    };

    const doReloadList = () => {
        const { filter, props } = params;

        notifications.reloadList({
            ...filter,
            ...props,
        });
    };

    const reloadListDebounce = debounce(doReloadList, 500);

    const reloadList = () => {
        if (openedTab.reload) {
            reloadListDebounce();
        }
    };

    const handleClickItem = (data: Notification) => {
        const { id, link = null, viewed = false } = data;
        alertsProvider.onClick(id, link, viewed);
        onClose();
        reloadList();
    };

    const handleClickItemRead = (id: number) => {
        notifications.changeRead(id);
        reloadList();
    };

    if (list.length === 0 && !loading) {
        return <NoData />;
    }

    return (
        <Virtuoso
            data={list}
            components={{ List, Footer }}
            endReached={handleLoadMore}
            context={{ loading }}
            itemContent={(index, item) => (
                <NotificationItem
                    key={item.id + index}
                    item={item}
                    onClickItem={handleClickItem}
                    onClickItemRead={handleClickItemRead}
                />
            )}
        />
    );
}

export default NotificationList;
