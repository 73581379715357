import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import Information from 'components/common/Information';

const Content = ({ data }) => {
    const fields = {
        created_at: {
            title: titles.DATE_TIME_CREATED,
            value: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },
        type_id_text: {
            title: titles.TYPE,
        },
        speed: {
            title: 'Скорость',
        },
        vehicle_count: {
            title: 'Колличество ТС',
        },
    };

    return (
        <>
            <Information
                data={data}
                title={fields}
                returnNull
            />
        </>
    );
};

export default Content;
