import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import L from 'leaflet';

import mapHelper from 'helpers/mapHelper';
import { loadPolygonForTriggers } from 'modules/React/redux/actions';
import { useDebounce } from 'helpers/hooks';
import { Marker, ToolTip } from 'components/MapComponents/leaflet';
import messages from 'helpers/constants/messages';
import layersLazyLoad from 'components/MapComponents/helpers/layersLazyLoad';
import removeEmptyFields from 'helpers/removeEmptyFields';

const Layer = (props) => {
    const { map, url, microservice, entity_id, onChange } = props;
    const dispatch = useDispatch();
    const [polygonData, setPolygonData] = useState([]);
    

    // грузим данные
    const fetchPolygon = () => {
        const polygon = microservice === 'dtp'
            ? mapHelper.getGeometryPolygon(map)
            : mapHelper.getPolygon(map);

        const prepareData = removeEmptyFields({ 
            polygon, 
            type_device: { ecologe: 1,  meteologe: 2 }?.[microservice],
        });

        url && dispatch(loadPolygonForTriggers(
            url, 
            prepareData, 
            (data) => setPolygonData(data)
        ));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        handleFetchPolygon();
    }, [url]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            setPolygonData([]);
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [url, map]);

    const layerIcons = layersLazyLoad('layerIcon');

    const servicesIcons = {
        'dtp': layerIcons['incidents'],
        'dit': layerIcons['board'],
        'doris-control': layerIcons['camera'],
        'video-analytics': layerIcons['videoanalytics'],
        'video-cameras': layerIcons['city-cameras'],
        'ecologe': layerIcons['ecology'],
        'meteologe': layerIcons['meteo'],
        'lights': layerIcons['traffic-lights'],
        'traffic-flow': layerIcons['puid']
    };

    const createIconMarker = (color) => L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: `
            <i class="${servicesIcons[microservice]}"
               style="width:100%;color:${color};position:absolute;left:1px;top:0;height:calc(100% - 5px);display:flex;justify-content:center;align-items:center;font-size:1.2rem;"
            />
        `,
    });
    
    return (
        polygonData.map((item) => {
            const { lat, lon, name, address_text } = item;

            const color = item.id === entity_id ? '#0000FF' : '#00BFFF';
            
            return (
                <Marker
                    {...props}
                    key={item.id}
                    latlng={[lat, lon]}
                    icon={createIconMarker(color)}
                    onClick={() => onChange(item.id === entity_id ? null : item.id)}
                    attribution={{ color }}
                >
                    <ToolTip
                        offset={[5, -30]}
                        direction="top"
                    >
                        <div>{name || address_text || messages.INFO_IS_NOT_FOUND}</div>
                    </ToolTip>
                </Marker>
            );
        })
    );
};

export default Layer;
