import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addMonths, set, subDays } from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';

import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import Table from 'components/common/DtpHeat/Table';
import Legend from 'components/common/DtpHeat/Legend';
import MapRegions from 'components/common/Map/MapRegions';
import PageLayout from 'components/layout/PageLayout';
import { loadHeatMapDistricts } from 'modules/Dtp/redux/actions';

import Filter from './Filter';

const HeatMapDistrict = ({
    tab,
    hideTitle,
    onBlocked,
}) => {
    const dispatch = useDispatch();

    // районы городов или области
    const [isArea, setIsArea] = useState(false);
    // const changeIsArea = (area) => {
    //     setIsArea(area);
    //     // сбрасываем регион при смене
    //     setSelectedRegion(null);
    // };

    const [currentMonth, setCurrentMonth] = useState({});

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    // выбранный регион
    const [selectedRegion, setSelectedRegion] = useState(null);
    const changeSelectedRegion = (region) => {
        setSelectedRegion((old) => region?.id === old?.id ? null : region);
    };

    const handleChange = (filter) => {
        const {
            date,
            ...othersFilter
        } = filter;

        setCurrentMonth(date);

        // загрузка данных
        dispatch(loadHeatMapDistricts(
            {
                ...othersFilter,
                // выбираем 1 января
                start_date: dateWithDashYYYYMMDD(set(date, { date: 1, month: 0 })),
                // выбираем весь выбранный месяц
                end_date: dateWithDashYYYYMMDD(
                    subDays(
                        addMonths(
                            set(date, { date: 1 }),
                            1
                        ),
                        1
                    )
                )
            },
            setData,
            setLoading
        ));
    };

    const regionTitle = selectedRegion?.name
        || (
            isArea
                ? 'Данные по районам области'
                : 'Данные по районам города'
        );

    // стили
    const regionsStyle = useMemo(() => {
        const el = data?.[isArea === false ? 'cities' : 'areas']?.month?.data || {};
        return Object.entries(el).reduce((r, [key, d]) => {
            return {
                ...r,
                [key]: {
                    opacity: 1,
                    fillOpacity: .8,
                    color: '#fff',
                    weight: 2,
                    fillColor: d.count.color,
                }
            };
        }, {});
    }, [isArea, data]);

    const filterByRegion = (region) => (item) => {
        return region && Object.keys(region).length > 0
            ? item?.data?.[region?.id] || item?.data?.[region?.name] || {}
            : item?.total || {};
    };

    const renderContent = () => (
        <>
            {loading && <Loading circular/>}

            <Grid container direction="row" spacing={2} style={{ height: '100%' }} >
                <Grid item xs={12} sm={6}
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {loading === false && (
                        <>
                            {/* переключатель регионов */}
                            {/*{!config.get('disableSwitcher')*/}
                            {/*&& <div style={{ marginBottom: '.5rem' }}>*/}
                            {/*    <DistrictSwitch*/}
                            {/*        value={isArea}*/}
                            {/*        onChange={changeIsArea}*/}
                            {/*        asButton*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*}*/}

                            {/* тепловая карта */}
                            <div style={{ height: '100%', maxHeight: '500px', paddingTop: '1rem' }}>
                                <MapRegions
                                    isArea={isArea}
                                    selectedRegion={selectedRegion}
                                    onSelectRegion={changeSelectedRegion}
                                    regionsStyle={regionsStyle}
                                    selectedStyle={{
                                        color: '#000',
                                    }}
                                    onBlocked={onBlocked}
                                />
                            </div>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {loading === false && (
                        <>
                            <div className="row justify-content-center">
                                <Typography variant="h6">
                                    {regionTitle}
                                </Typography>

                                {selectedRegion
                                    ? (
                                        <Tooltip title="Сбросить выбранный район">
                                            <IconButton
                                                aria-label="settings"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedRegion(null);
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                    : null
                                }
                            </div>

                            {currentMonth && (
                                <Table
                                    data={data?.[isArea === false ? 'cities' : 'areas']}
                                    filter={filterByRegion(selectedRegion)}
                                    date={currentMonth}
                                />
                            )}

                            <Legend data={data?.legend || []} />

                        </>
                    )}

                </Grid>
            </Grid>
        </>
    );

    return (
        <PageLayout
            header={hideTitle ? ' ' : 'Тепловая карта по ДТП по районам'}
            tab={tab}
            filters={<Filter loading={loading} onChange={handleChange} tab={tab} />}
            loading={loading}
            content={renderContent}
        />
    );
};

export default HeatMapDistrict;
