import { useState } from 'react';
import { useDispatch, } from 'react-redux';
import { Divider, Grid } from '@mui/material';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import Attachments from 'components/common/Upload/Attachments';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormModal from 'modules/React/Dictionaries/ResponseScenarios/FormModal';
import { deleteResponseScenario } from 'modules/React/redux/actions';
import type { FactorType } from 'modules/React/reactTypes/factors';

import type { ResponseScenario } from '../types';

interface BasicDataProps {
    item: ResponseScenario;
    reloadList: (isDelete?: boolean) => void;
}

const BasicData = ({ item, reloadList }: BasicDataProps) => {
    const dispatch = useDispatch();

    const [isOpenEditModal, setOpenEditModal] = useState(false);
    const [isOpenCopyModal, setOpenCopyModal] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

    const onDelete = () => {
        dispatch(deleteResponseScenario(
            item.id,
            () => { reloadList(true); setOpenDeleteModal(false);}
        ));
    };


    return (
        <Grid
            container
            direction="row"
            spacing={1}
        >
            <Grid item xs={12}>
                <Divider style={{ margin: '0.5rem 0' }}/>
                <div className="filter filter__wrap__btn">
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                                testName: 'edit'
                            },
                            {
                                ...buttonsTypes.copyIcon,
                                onClick: () => setOpenCopyModal(true),
                                testName: 'edit'
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                                testName: 'delete'
                            },
                        ]}
                    />
                </div>
                <div>
                    <strong>{titles.NUMBER}: </strong>
                    {item?.id || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.NAME}: </strong>
                    {item?.name || messages.NO_DATA}
                </div>
                <div>
                    <strong>Типовой: </strong>
                    {item?.is_typical ? 'Да' : 'Нет'}
                </div>
                <div>
                    <strong>{titles.THREAT_CATEGORY}: </strong>
                    {item.category?.name || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.KEYWORDS}: </strong>
                    {item.keyword_list?.map(el => el.name).join(', ') || messages.NO_DATA}
                </div>

                <div>
                    <strong>Тип инцидента: </strong>
                    {item.types[0]?.name || messages.NO_DATA}
                </div>

                <div>
                    <strong>Пользовательские факторы: </strong>
                    {item?.factors?.length && item?.factors?.length > 0
                        ? item.factors?.map((el: FactorType) => el.name).join(', ')
                        : messages.NO_DATA}
                </div>

                <div>
                    <strong>{titles.COMMENT}: </strong>
                    {item.comment || messages.NO_DATA}
                </div>

                <Attachments
                    readOnly
                    //@ts-ignore
                    files={item?.attachments || []}
                    renderContent={null}
                />
            </Grid>

            {isOpenEditModal&& (
                <FormModal
                    isOpen={isOpenEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}
            {isOpenCopyModal && (
                <FormModal
                    isOpen={isOpenCopyModal}
                    onClose={() => setOpenCopyModal(false)}
                    item={item}
                    reloadList={reloadList}
                    isCopy={true}
                />
            )}
            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={onDelete}
                />
            )}
        </Grid>
    );
};

export default BasicData;
