import { useState } from 'react';

import { MetroSchemeForm } from 'components/common/Metro/MetroShema';

const MetroTest = () => {

    // станции
    const [stations, setStations] = useState({});
    // тип
    const [type, setType] = useState({});

    return (
        <MetroSchemeForm
            type={type}
            setType={setType}
            stations={stations}
            setStations={setStations}
        />
    );

};

export default MetroTest;
