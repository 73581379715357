import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import Modal from 'components/common/Modal';
import NoData from 'components/common/Information/NoData';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { reactSelectors } from 'modules/React';
import { loadResponseScenarios } from 'modules/React/redux/actions';
import type { ResponseScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';
import reactTitles from 'modules/React/utils/helpers/constants';

import Filter from './Filter';
import Item from './Item';

import type { Meta } from 'types/pagination';
import type { FilterFields } from './types';

interface SelectScenarioModalProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    selected: ResponseScenario
    onChange: (selected: ResponseScenario) => void
    filters?: FilterFields
}

const SelectScenarioModal = ({
    isOpen,
    onClose,
    title = 'Выбор сценария реагирования',
    selected,
    onChange,
    filters
}: SelectScenarioModalProps) => {
    const dispatch = useDispatch();
    const data: ResponseScenario[] = useSelector(reactSelectors.responseScenariosData);
    const meta: Meta = useSelector(reactSelectors.responseScenariosMeta);
    const loading = useSelector(reactSelectors.loadingResponseScenarios);

    const initialFilter = {
        name: filters?.name || '',
        types_id_list: filters?.types_id_list || [],
        factors_list: filters?.factors_list || [],
        factors_id_list: Array.isArray(filters?.factors_list) ? filters?.factors_list.map(({ id }) => id) : [],
        keyword_list: filters?.keyword_list || [],
        keyword_id_list: filters?.keyword_list?.map(({ id }) => id) || [],
        category: filters?.category || null,
        category_id: filters?.category_id || null,
    };

    const [filter, setFilter] = useState<FilterFields>(initialFilter);
    const [selectedItem, setSelectedItem] = useState(selected);
    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
        filters: prepareData(initialFilter)
    });

    useEffect(() => {
        dispatch(loadResponseScenarios(params.page, params.limit, params.filters));
    }, [dispatch, params]);

    const onSearch = (filters: FilterFields) => {
        setParams(prev => ({ ...prev, page: 1, filters: prepareData(filters) }));
    };

    function prepareData (newFilter = filter){
        return removeEmptyFields({
            name: newFilter.name,
            types_id_list: newFilter.types_id_list,
            factors_id_list: newFilter.factors_list?.map(({ id }) => id),
            category_id: newFilter.category?.id,
            keyword_id_list: newFilter.keyword_list?.map(({ id }) => id),
        });
    };

    const renderList = () => (
        data?.length > 0
            ? <LSContainer
                headers={[
                    { title: '', width: '4%' },
                    { title: titles.NUM, width: '4%' },
                    { title: titles.NAME, width: '30%' },
                    { title: reactTitles.TYPICAL, width: '10%' },
                    { title: reactTitles.OBJECTS, width: '20%' },
                    { title: reactTitles.FACTORS, width: '20%' },
                    { title: titles.KEYWORDS, width: '20%' },
                    { title: titles.THREAT_CATEGORY, width: '20%' },
                ]}
            >
                {data.map((item: ResponseScenario) => (
                    <Item
                        key={item.id}
                        item={item}
                        isSelected={selectedItem?.id === item.id}
                        onClickItem={() => setSelectedItem(item)}
                        filter={filter}
                    />
                ))}
            </LSContainer>
            : <NoData />
    );

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            fullWidth
            contentStyle={{ paddingTop: '2.4rem' }}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.save,
                            onClick: () => {onChange(selectedItem); onClose();},
                            disabled: !selectedItem
                        },
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                    ]}
                />
            }
        >
            <PageLayout
                filters={
                    <Filter
                        filter={filter}
                        setFilter={setFilter}
                        onSearch={onSearch}
                    />
                }
                loading={loading}
                content={renderList}
                paginationProps={{
                    loadList: (page) => setParams(prev => ({ ...prev, page })),
                    list: meta,
                    limit: params.limit,
                    setLimit: (limit) => setParams(prev => ({ ...prev, limit })),
                }}
                customStyles={{ padding: 0 }}
            />
        </Modal>
    );
};

export default SelectScenarioModal;
