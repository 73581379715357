import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import CircleStatus from 'components/common/CircleStatus';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import ConfirmDelete from 'components/common/ConfirmModal';
import { deleteStories, loadStoryStatuses } from 'modules/React/redux/actions';

import ModalForm from './ModalForm';

type IncidentItemProps = {
  item: any
  reloadList: (isDelete: boolean) => void
}

const colors: { [key: string]: string } = { '1': '#ff66ff', '2': '#33CC33', '3': '#cc0066' };

const IncidentItem = ({ 
    item,
    reloadList
}: IncidentItemProps) => {
    const dispatch = useDispatch();
    const storyStatuses = useStoreProp(loadStoryStatuses, 'react', 'storyStatuses');
    const getStatusObject = (id: string | number, statusMap: { [x: string]: any; }) => (
        statusMap[id] ? { id: +id, name: statusMap[id], color: colors[id] } : null
    );
    const statuses = getStatusObject(item?.status_id, storyStatuses);
    
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    // const [chatModal, setChatModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteStories(item.id, () => {
            reloadList(true);
            setDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem 
                onClick={()=> {}} 
            >
                <LSContentColumn>
                    <CircleStatus 
                        title={statuses?.name}
                        color={statuses?.color} 
                    />
                </LSContentColumn>
                <LSContentColumn>
                    {item.id || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {getHumanDate(item.start_date) || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setEditModal(true),
                                accessProp: '',
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setDeleteModal(true),
                                accessProp: '',
                            },
                            // {
                            //     ...buttonsTypes.chatIcon,
                            //     onClick: () => setChatModal(true),
                            //     accessProp: '',
                            // },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {editModal
                && <ModalForm
                    isOpen={editModal}
                    onClose={() => setEditModal(false)}
                    reloadList={reloadList}
                    item={item}
                />
            }

            {deleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={deleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setDeleteModal(false)}
                />
            )}
        </>
    );
};

export default IncidentItem;