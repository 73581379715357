import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

import titles from 'helpers/constants/titles';
import {
    dateNow,
    fullDateTimeWithoutSeconds,
    fullDateTimeWithTimeZone,
    getStartOf,
    getTimeWithoutSeconds,
} from 'helpers/date.config';
import { metroSelectors } from 'modules/Metro';
import { loadVestibuleTraffic } from 'modules/Metro/redux/actions';
import FormButtons from 'components/common/FormButtons';
import { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer, LSContentColumn, LSContentItem } from 'components/common/List';

import type { Traffic } from '../types';

interface ReportsListProps {
    id: number;
}

function ReportsList({ id }: ReportsListProps) {
    const dispatch = useDispatch();

    const vestibuleTraffic: Traffic[] = useSelector(metroSelectors.vestibuleTraffic);

    const [isShowList, setIsShowList] = useState(false);

    useEffect(() => {
        const date = {
            start_date: fullDateTimeWithTimeZone(getStartOf('day')),
            end_date: fullDateTimeWithTimeZone(Date.now()),
        };

        dispatch(loadVestibuleTraffic(id, date));
    }, [dispatch, id]);

    const now = fullDateTimeWithoutSeconds(dateNow());

    return (
        <>
            <div style={{ padding: '0 15px' }}>
                <FormButtons
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.defaultInfo,
                            name: 'Отчет',
                            endIcon: <ExpandMoreIcon />,
                            onClick: () => setIsShowList(!isShowList),
                        },
                    ]}
                />
            </div>

            {isShowList && (
                <Typography style={{ color: '#17b1e0' }} variant={'h6'} align="center">
                    {now}
                </Typography>
            )}

            {isShowList && (
                <div className={isShowList ? 'scroll' : ''}>
                    <LSContainer
                        headers={[
                            { title: titles.TIME, width: '50%' },
                            { title: 'Количество проходов', align: 'center', width: '50%' },
                        ]}
                    >
                        {vestibuleTraffic.map((item) => {
                            return (
                                <LSContentItem key={item.vestibule_id}>
                                    <LSContentColumn>
                                        {getTimeWithoutSeconds(item.start_at)}-
                                        {getTimeWithoutSeconds(item.end_at)}
                                    </LSContentColumn>

                                    <LSContentColumn>{item.count}</LSContentColumn>
                                </LSContentItem>
                            );
                        })}
                    </LSContainer>
                </div>
            )}
        </>
    );
}

export default ReportsList;
