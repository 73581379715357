import titles from 'helpers/constants/titles';
import TabListT from 'components/MapComponents/TabList';
import Forecast from 'components/pages/Dictionaries/Meteo/Devices/PassportPage/Forecast';

import TabInformation from './TabInformation';
import TabEvents from './TabEvents';


const PopUp = ({
    data,
    readOnly = false,
}) => {
    const { external_id } = data;

    const tabList = [
        {
            key: 'information',
            title: titles.INFORMATION,
            component: <TabInformation external_id={external_id} readOnly={readOnly} />
        },
        {
            value: 'forecast',
            title: 'Прогноз',
            component: <Forecast item={data} isPopUp />,
        }
    ];

    // если только чтение - не показывать вкладку события
    if (readOnly === false) {
        tabList.push({
            key: 'events',
            title: titles.EVENTS,
            component: <TabEvents external_id={external_id} />
        });
    }

    return (
        <TabListT
            list={tabList}
        />
    );
};

export default PopUp;
