import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import messages from '../../../../helpers/constants/messages';
import titles from '../../../../helpers/constants/titles';
import { useValidation } from '../../../../helpers/hooks';
import { createAip, editAip, loadAipOperators, loadAipTypes } from '../../../../redux/Aip/actions';
import { loadComplexes } from '../../../../redux/DorisControl/actions';
import SelectComplex from '../../../common/Autocomplete/DorisControl/Complex';
import FormButtonsComponent, { buttonsTypes } from '../../../common/FormButtons';
import Modal from '../../../common/Modal';
import useStoreProp from '../../../../helpers/hooks/useStoreProp';
import TextMaskPhone from '../../../common/TextMaskPhone';
import TextMaskIP from '../../../common/TextMaskIP';
import LoadAddressByCoords from '../../../common/Location/LoadAddressByCoords';
import AddressList from '../../../common/Location/AddressList';
import MapDragMarker from '../../../common/Location/MapDragMarker';
import LatLonCoordinates from '../../../common/Location/LatLonCoordinates';
import { dorisControlSelectors } from '../../../../redux/DorisControl';


const ModalForm = ({
    isOpen = false,
    onClose = () =>{},
    isNew = false,
    item={}
}) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const types = useStoreProp(
        loadAipTypes,
        'aip',
        'types'
    );
    const operators = useStoreProp(
        loadAipOperators,
        'aip',
        'operators'
    );
    const [complex] = useSelector(dorisControlSelectors.complexesData);
    const [selectedComplex, setSelectedComplex] = useState({});

    const initialState = {
        name: item?.name || '',
        serial_number: item?.serial_number || '',
        ip_route: item?.ip_route || '',
        mask: item?.mask|| '',
        mac: item?.mac || '',
        imei: item?.imei || '',
        address_text: item?.address_text || '',
        lat: item?.lat || '',
        lon: item?.lon || '',
        complex_serial_number: item?.complex_serial_number || '',
        type: item?.type || '',
        icc: item?.icc || '',
        phone: item?.phone || '',
        phone_operator: item?.phone_operator || '',
    };

    const [data, setData] = useState(initialState);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const handleSave = () => {
        const TransformationDataForSend = Object.keys(data).reduce(
            (res, key) => {
                const el = data[key];
                if (el) {
                    res[key] = el;
                    return res;
                }
                return res;
            },
            {}
        );
        dispatch(isNew
            ? createAip(TransformationDataForSend)
            : editAip(item.id, TransformationDataForSend)
        );
        validation.clear();
    };

    const onChangeCoordinates = (params) => {
        setData({
            ...data,
            ...params
        });
        validation.deleteKey('address');
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    useEffect(() => {
        if (item?.complex_serial_number) {
            dispatch(loadComplexes(1, 1, { serial_number_list : [item?.complex_serial_number] }));
        }
    }, [dispatch, item?.complex_serial_number]);

    useEffect(() => {
        if (complex && item?.complex_serial_number === complex.serial_number) {
            setSelectedComplex(complex);
        }
    }, [complex, item?.complex_serial_number]);

    return <>
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? 'Добавить АИП' : 'Редактировать АИП'}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: !data.name || !data.serial_number || !data.ip_route || !data.imei || !data.address_text || !data.lat || !data.lon
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <TextField
                    value={data.name}
                    name={'name'}
                    onChange={handleChange}
                    className="block"
                    label={titles.NAME}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                />
                <TextField
                    value={data.serial_number}
                    name={'serial_number'}
                    onChange={handleChange}
                    className="block"
                    label={titles.SERIAL_NUMBER}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('serial_number')}
                    helperText={validation.get('serial_number')}
                />
                <TextField
                    value={data.ip_route}
                    name={'ip_route'}
                    onChange={handleChange}
                    className="block"
                    label={titles.IP_ADDRESS}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('ip_route')}
                    helperText={validation.get('ip_route')}
                    InputProps={{
                        inputComponent: TextMaskIP,
                    }}
                />
                <TextField
                    value={data.imei}
                    name={'imei'}
                    onChange={handleChange}
                    className="block"
                    label={'imei'}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('imei')}
                    helperText={validation.get('imei')}
                    placeholder="123456789012345"
                    inputProps={{ maxLength: 15 }}
                />
                <div className="block">
                    <SelectComplex
                        selected={selectedComplex}
                        onChange={(value) => {
                            setSelectedComplex(value);
                            setData({ ...data, complex_serial_number: value.serial_number });
                        }}
                        label={'Серийный номер КФВФ'}
                    />
                </div>
                <FormControl className="block" size="small" variant="outlined">
                    <InputLabel>Тип АИП</InputLabel>
                    <Select
                        value={data.type}
                        label="Тип АИП"
                        onChange={handleChange}
                        name="type"
                    >
                        {Object.keys(types)?.length
                            ? Object.keys(types)?.map((key) =>
                                <MenuItem value={key} key={key}>{types[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <TextField
                    value={data.icc}
                    name={'icc'}
                    onChange={handleChange}
                    className="block"
                    label={'icc SIM-карты'}
                    variant={'outlined'}
                    size={'small'}
                    placeholder="123456789012345678"
                    inputProps={{ maxLength: 18 }}
                />
                <TextField
                    value={data.phone}
                    name={'phone'}
                    onChange={handleChange}
                    className="block"
                    label={'Моб. телефон'}
                    variant={'outlined'}
                    size={'small'}
                    InputProps={{
                        inputComponent: TextMaskPhone,
                    }}
                />
                <FormControl className="block" size="small" variant="outlined">
                    <InputLabel>Оператор сети</InputLabel>
                    <Select
                        value={data.phone_operator}
                        label="Оператор сети"
                        onChange={handleChange}
                        name="phone_operator"
                    >
                        {Object.keys(operators)?.length
                            ? Object.keys(operators)?.map((key) =>
                                <MenuItem value={key} key={key}>{operators[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <LatLonCoordinates
                        {...data}
                        onChange={onChangeCoordinates}
                        required
                    />
                </FormControl>
                <FormControl className="block" variant="outlined">
                    <LoadAddressByCoords
                        {...data}
                        onChange={onChangeCoordinates}
                    >
                        <AddressList required />
                    </LoadAddressByCoords>
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <MapDragMarker
                        {...data}
                        onChange={onChangeCoordinates}
                        required
                    />
                </FormControl>
            </div>
        </Modal>
    </>;
};

export default ModalForm;
