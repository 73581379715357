import { memo, useCallback, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import L from 'leaflet';

import {
    Cluster,
    FeatureGroup,
    GeoJsonNew,
    Map,
    MapControl,
    Marker,
    ToolTip
} from 'components/MapComponents/leaflet';
import messages from 'helpers/constants/messages';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';
import { createMarker } from 'components/MapComponents/Layers/RoadNetwork/helper';
import { config } from 'components/MapComponents/Layers/RoadNetwork';
import { config as rootConfig } from 'config';

const useStyles = makeStyles({
    mapIcon: {
        backgroundColor: 'inherit',
    },
});

const iconClasses = {
    1: 'far fa-warehouse-alt', // infrastructure
    2: 'fas fa-camera-alt', // complex
    3: 'fas fa-cloud-sun', // meteo
    4: 'fas fa-leaf', // eco
    5: 'far fa-traffic-light-stop', // traffic light
    6: 'fas fa-cctv', // cameras
    7: 'fad fa-cars', // traffic flow
    8: 'far fa-road', // road section
    9: 'fas fa-video' // city cameras
};

const MapFromModal = ({ data, roadSection, markersTitle, setMarkerColor, relations, selectedType, relationsMap }) => {
    const styles = useStyles();

    const createHtml = useCallback((color, iconClass = 'fa fa-map-marker' ) => {
        return (
            `<div style="color: ${color}">
            <i style="font-size: 30px" class="${iconClass}" aria-hidden="true"></i>
            </div>`
        );
    }, []);

    const currentData = useMemo(() => selectedType
        ? data
        : (relationsMap?.map(elem => ({
            ...elem.entity_data,
            type: elem.entity_type,
        }))), [data, relationsMap, selectedType]);

    const selectedMarkers = useMemo(() => {
        const getCoordsFromGeometry = (coordArr) => {
            if (coordArr) {
                if (typeof coordArr?.[0] === 'number') {
                    return [coordArr?.[1], coordArr?.[0]];
                }
                return getCoordsFromGeometry(coordArr?.[0]);
            }
            return [];
        };

        return currentData?.map((item, index) => {
            if (selectedType === '8' || item?.type === '8') return null;

            const { color, onClick } = setMarkerColor(item, selectedType ? selectedType : item.type);
            const icon = L.divIcon({
                className: styles.mapIcon,
                html: createHtml(color, selectedType ? iconClasses[selectedType] : iconClasses[item.type])
            });

            const coords = item?.lat && item?.lon
                ? [item?.lat, item?.lon]
                : (item?.address && item?.address?.geo_lat && item?.address?.geo_lon
                    ? [item.address.geo_lat, item.address.geo_lon]
                    : getCoordsFromGeometry(item.geometry?.geometry?.coordinates));

            return (
                <Marker
                    key={index}
                    icon={icon}
                    latlng={coords}
                    attribution={{
                        color: color
                    }}
                    onClick={onClick}
                >
                    <ToolTip
                        offset={[5, -7]}
                        direction="top"
                    >
                        <div>
                            <b>{markersTitle} </b>
                            <span>{item?.name || messages.INFO_IS_NOT_FOUND}</span>
                        </div>
                    </ToolTip>
                </Marker>
            );
        });
    }, [currentData, createHtml, styles, relations, relationsMap]);

    const geometry = useMemo(() => {
        return currentData?.map((item) => {
            const { data = {}, line_width } = item;

            const { color, onClick } = setMarkerColor(item, selectedType ? selectedType : item.type);

            return {
                ...data,
                properties: {
                    ...data?.properties,
                    ...item,
                    color,
                    onClick
                },
                style: {
                    color: color || config.mapMarkersColors.default,
                    weight: config.lineWidth[line_width] || config.lineWidth.default,
                },
            };
        });
    }, [currentData, selectedType, setMarkerColor]);

    const mapCenter = roadSection?.lat
        && roadSection?.lon
        ? [
            roadSection?.lat,
            roadSection?.lon
        ]
        : rootConfig.get('mapCenter');

    return(<>
        <div style={{ minWidth: '60%', minHeight: '400px', height: '100%', marginRight: 20 }}>
            <Map
                center={mapCenter}
            >
                <MapControl>
                    <FeatureGroup>
                        {/* кластер объектов */}
                        <Cluster
                            iconCreateFunction={iconCreateFunctionSimple}
                            maxClusterRadius={40}
                        >
                            {selectedMarkers}
                        </Cluster>

                        {/* объекты элементов дорожной сети */}
                        {(!selectedType || selectedType === '8') && (
                            <GeoJsonNew
                                data={geometry}
                                toolTipTemplate={({ name = '' }) => <div><b>{markersTitle} </b>{name ?? ''}</div>}
                                icon={item => createMarker(item)}
                                toolTipOptions={{
                                    direction: 'top',
                                    offset: [0, -10]
                                }}
                                onClick={({ onClick }) => onClick()}
                            />
                        )}

                        {roadSection?.lat && roadSection?.lon
                            ? <Marker latlng={[roadSection?.lat, roadSection?.lon]}>
                                <ToolTip
                                    offset={[5, -7]}
                                    direction="top"
                                >
                                    <div>
                                        <b>Элемент дорожной сети</b>
                                        <div>Название: {roadSection?.name || messages.INFO_IS_NOT_FOUND}</div>
                                        <div>Тип: {roadSection?.type_text || messages.INFO_IS_NOT_FOUND}</div>
                                    </div>
                                </ToolTip>
                            </Marker>
                            : null
                        }

                    </FeatureGroup>
                </MapControl>
            </Map>
        </div>
    </>
    );
};

export default memo(MapFromModal);
