import { config } from '../../config';

const apiTransportUrl = () => config.get('urls').apiTransportPassengerUrl;
const apiDtpUrl = () => config.get('urls').apiDtpUrl;
const apiMeteoUrl = () => config.get('urls').apiMeteoUrl;
const apiVideoAnaliticUrl = () => config.get('urls').apiVideoAnaliticUrl;
const apiTransportRoadUrl = () => config.get('urls').apiTransportRoadUrl;
const apiAuthUrl = () => config.getGeneral('urls').apiAuthUrl;
const apiMain = () => config.getGeneral('urls').apiMain;
const apiLogger = () => config.get('urls').apiLogger;
const apiReports = () => config.get('urls').apiReports;

const apiUrls = {
    getDtpStatus: () => `${apiDtpUrl()}/status`, // статус работы микросервиса ДТП

    getMeteoStatus: () => `${apiMeteoUrl()}/status`,// статус работы микросервиса Метео и Эко

    getCameraStatus: () => `${apiVideoAnaliticUrl()}/status`,// статус работы микросервиса Видеоаналитики

    getRouteStatus: () => `${apiTransportUrl()}/status`,// статус работы микросервиса пассажирского транспорта

    getVehiclesStatus: () => `${apiTransportRoadUrl()}/status`,// статус работы микросервиса спецтранспорта

    getUsers: () => `${apiAuthUrl()}/user/`,

    getRoles: () => `${apiAuthUrl()}/role/`,

    registerUser: () => `${apiAuthUrl()}/user/register`,

    createUser: () => `${apiAuthUrl()}/user`,

    editUser: (id) => `${apiAuthUrl()}/user/${id}`,

    deleteUser: (id) => `${apiAuthUrl()}/user/${id}`,

    getPermissions: () => `${apiAuthUrl()}/permission/`,

    createRole: () => `${apiAuthUrl()}/role`,

    editRole: (id) => `${apiAuthUrl()}/role/${id}`,

    deleteRole: (id) => `${apiAuthUrl()}/role/${id}`,

    getDtpSettings: () => `${apiDtpUrl()}/settings`,

    editDtpSettings: () => `${apiDtpUrl()}/settings/list`,

    getCurrentUser: (id) => `${apiAuthUrl()}/user/${id}`,

    resetPassword: () => `${apiAuthUrl()}/user/reset-password`,

    getSettings: () => `${apiMain()}/settings`,

    editSetting: (id) => `${apiMain()}/settings/${id}`,

    editSettingsList: () => `${apiMain()}/settings/list`,

    getUserActivity: () => `${apiLogger()}/log`,

    getUserActivityReport: () => `${apiReports()}/logging/log/`,

    getMethods: () => `${apiLogger()}/method`,

    getMicroservices: () => `${apiLogger()}/microservice`,

    getActions: () => `${apiLogger()}/action`,

    getSettingsGroup: () => `${apiMain()}/settings_group`,

    editSettingsGroup: (id) => `${apiMain()}/settings_group/${id}/settings/update`,

    getServiceVersions: () => `${apiMain()}/service/versions`,

    loadUserTypes: () => `${apiAuthUrl()}/user/types`,

    getAppLogs: () => `${apiLogger()}/api-event/list`,

    getAppLogsReport: () => `${apiReports()}/logging/api-events`,

    getLibraryAPI: () => `${apiMain()}/library/api`,

    createLibraryAPI: () => `${apiMain()}/library/api`,

    editLibraryAPI: (id) => `${apiMain()}/library/api/${id}`,

    deleteLibraryAPI: (id) => `${apiMain()}/library/api/${id}`,
};

export default apiUrls;
