import { useMemo, useState } from 'react';
import { valid } from 'geojson-validation';

import { config } from 'config';
import { getEdgeColor, getMapNode, getNodeColor } from 'modules/DigitalTwin/Layers/DigitalTwin/helper';
import {
    GeoJsonNew,
    Map,
    MapControl,
} from 'components/MapComponents/leaflet';

const ModalForm = ({
    geometry = {},
    nodes = [],
}) => {
    const [mapCenter] = useState(config.get('mapCenter'));
    const [mapZoom] = useState(10);

    const nodesData = useMemo(() => {
        return nodes.map(({ geometry, id, type, created_at }) => ({
            type: 'Feature',
            geometry,
            properties: {
                id,
                type,
                created_at
            }
        }));
    },[nodes]);

    return (
        <Map
            center={mapCenter}
            zoom={mapZoom}
        >
            <MapControl>
                <GeoJsonNew
                    data={nodesData}
                    icon={() => getMapNode(getNodeColor)}
                    toolTipTemplate={({ created_at, type }) => (
                        <div>
                            Узел
                            {/*{!!created_at && (*/}
                            {/*    <div>*/}
                            {/*        <strong>{titles.DATE_TIME_CREATED}:</strong>&nbsp;*/}
                            {/*        {getFullDateTimeWithTextMonth(created_at)}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {/*{!!type && (*/}
                            {/*    <div>*/}
                            {/*        <strong>{titles.TYPE}:</strong>&nbsp;*/}
                            {/*        {getTypeText(type)}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    )}
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5],
                        sticky: true
                    }}
                    centerAfter
                />

                {valid(geometry) && (
                    <GeoJsonNew
                        data={geometry}
                        toolTipTemplate={() => (
                            <div>
                                Ребро
                            </div>
                        )}
                        toolTipOptions={{
                            direction: 'top',
                            offset: [0, -5],
                            sticky: true
                        }}
                        style={{
                            color: getEdgeColor,
                            weight: 8
                        }}
                    />
                )}
            </MapControl>
        </Map>
    );
};

export default ModalForm;
