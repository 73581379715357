import { fullDateTimeWithoutSeconds } from 'helpers/date.config';

interface Props {
    status_text: string;
     updated_at: Date | null;
}
const RenderStatusDate = ({ status_text, updated_at }: Props) => {
    return (
        <div className="row align-items-center">
            <strong>Статус: </strong>
            <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                {status_text} - {fullDateTimeWithoutSeconds(updated_at)} / &nbsp;
            </span>
            <span style={{ color: '#e7c559' }}>В архиве</span>
            <i
                className="fa fa-folder-open"
                aria-hidden="true"
                style={{ color: '#e7c559', fontSize: '16px', marginLeft: '4px' }}
            />
        </div>
    );
};

export default RenderStatusDate;
