import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import UniversalSelect from 'components/common/UniversalSelect';
import Loading from 'components/common/Loading';
import * as dashboardSelectors from 'modules/Dashboard/redux/selectors';
import * as actions from 'modules/Dashboard/redux/actions';

import List from './List';

const Settings = ({
    dashboard_id,
    onClose,
    onReload,
}) => {
    const dispatch = useDispatch();

    const loadingDashboardItemUser = useSelector(dashboardSelectors.loadingDashboardItemUser);
    const loadingDashboards = useSelector(dashboardSelectors.loadingDashboards);

    const [items, setItems] = useState([]);
    const [isShowUniversal, setIsShowUniversal] = useState(false);

    // сонвертирование в нужный формат
    const convertDefault = (items) => {
        return items.map((i, index) => ({
            id: i.id || i.dashboard_item_id,
            dashboard_item_id: i.id || i.dashboard_item_id,
            dashboard_item: {
                name: i.dashboard_item?.name || i.name
            },
            sort: index + 1,
        }));
    };

    useEffect(() => {
        if (dashboard_id) {
            // выбранные поля из дашборда
            dispatch(actions.loadDashboardItemUser({
                dashboard_id
            }, (data) => {
                if (data.length === 0) {
                    // все поля доашборда
                    dispatch(actions.loadDashboardsItem(
                        { dashboard_id },
                        (data) => {
                            // дефолтные поля дашборда
                            const filteredData = data.filter(i => i.is_default);
                            setItems(convertDefault(filteredData));
                        }
                    ));
                } else {
                    setItems(convertDefault(data));
                }
            }));
        }
    }, [dashboard_id]);

    const handleClose = () => {
        onClose();
    };

    const handleSave = () => {
        const data = {
            items: items.map(i => ({
                dashboard_item_id: i.dashboard_item_id,
                sort: i.sort
            })),
            dashboard_id
        };

        // групповое обновление
        dispatch(actions.editDashboardItemUserMultiply(data, () => {
            onClose();
            onReload();
        }));
    };

    const getList = (params) => {
        const { page, query } = params;

        dispatch(actions.loadDashboardsItem({
            ...(query && { name: query }),
            page,
            limit: 25,
            dashboard_id
        }));
    };

    const handleChangeSelect = (e) => {
        setItems(convertDefault(e));
        setIsShowUniversal(false);
    };

    return (
        <>
            <Modal
                isOpen={!!dashboard_id}
                onClose={handleClose}
                noPadding={true}
                title={'Порядок вывода параметров*'}
                maxWidthProp={'md'}
                overflowVisible
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.edit,
                                name: 'Изменить',
                                onClick: () => setIsShowUniversal(true)
                            },
                            {
                                ...buttonsTypes.close,
                                onClick: handleClose
                            },
                            {
                                ...buttonsTypes.save,
                                onClick: handleSave,
                                loading: loadingDashboardItemUser,
                                disabled: items.length === 0
                            },
                        ]}
                    />
                }
                heightMedium
                disabledDragDrop
            >
                <List
                    items={items}
                    setItems={setItems}
                />

                {(loadingDashboardItemUser || loadingDashboards)
                    && (
                        <Loading className="absolute bottom fill"/>
                    )
                }
            </Modal>

            {isShowUniversal
                && <UniversalSelect
                    isOpen={isShowUniversal}
                    storeName={'dashboard'}
                    storeNameProps={'dashboardsItem'}
                    storeLoadingProps={'loadingDashboards'}
                    keyProp={'id'}
                    fetchList={getList}
                    // withSearch={true}
                    multiple
                    isSelected={true}
                    selected={items}
                    renderProps={({ name }) => (<span>{name}</span>)}
                    onClose={() => setIsShowUniversal(false)}
                    onAccept={handleChangeSelect}
                    noPadding
                    small
                    title={'Выбрать параметры'}
                />
            }
        </>
    );
};

export default Settings;
