import { ReactNode, useMemo } from 'react';

import { renderChilds } from 'components/common/List/helper';

import usePopUpLisner from './usePopUpLisner';

interface MapPopUpLisnerProps {
    children: ReactNode;
    activeSelector: any;
    polygonSelector: any;
    keyProp?: string;
}

// отслеживание активных данных для слоя из полигона
const MapPopUpListener = ({
    // всё что между тегами <Modal></Modal>
    children,
    // активный элемент
    activeSelector,
    // полигон (обновление данных)
    polygonSelector,
    // ключ проверки элемента
    keyProp = 'id',
}: MapPopUpLisnerProps) => {

    const popupLisner = usePopUpLisner({
        activeSelector,
        polygonSelector,
        keyProp,
    });

    // контент
    const content = useMemo(() => {
        const items = Array.isArray(children)
            ? children
            : [children];

        return popupLisner.popup
            ? renderChilds(items, {
                data: popupLisner.popup,
                isOpen: !!popupLisner.popup,
            })
            : null;
    }, [children, popupLisner.popup]);

    return content;
};

export default MapPopUpListener;
