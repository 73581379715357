import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ecologySelectors } from 'redux/Ecology';
import {
    clearDeviceDataEcology,
    getDeviceDataEcology
} from 'redux/Ecology/actions';
import { usePrevious } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import { isValue } from 'helpers/checkValue';
import Loading from 'components/common/Loading';
import MapScroll from 'components/MapComponents/MapScroll';

const TabInformation = (props) => {
    const { external_id, readOnly = false } = props;

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);

    const dataDevice = useSelector(ecologySelectors.dataDevice);
    const loading = useSelector(ecologySelectors.loading);
    const prevLoading = usePrevious(loading);

    useEffect(() => {
        dispatch(getDeviceDataEcology(external_id));
        return () => {
            dispatch(clearDeviceDataEcology());
        };
    }, []);

    useEffect(() => {
        if (prevLoading === true && loading === false) {
            setIsOpen(true);
        }
    }, [loading]);

    const RenderList = () => {
        const device_data = dataDevice?.[0]?.device_data || [];

        if (device_data.length === 0) {
            return <div className="info__item">{messages.INFO_IS_NOT_FOUND}</div>;
        }

        return (
            <>
                <h3 style={{ marginLeft: '1rem', marginBottom: '.5rem' }}>
                    {titles.DATA_FROM} {fullDateTimeWithoutSeconds(dataDevice[0]?.created_at)}
                </h3>

                <table className="table-information">
                    <tbody>
                        <tr>
                            <th>Наименование</th>
                            <th>Текущее значение</th>
                            {/* <th>Предельно допустимые значения</th> */}
                        </tr>

                        {device_data.map((field, index) => {
                            // если только чтение и нет данных - не показывать
                            if (readOnly && !isValue(field.value)) return null;

                            return (
                                <tr key={`tr_${index}`}>
                                    <td>
                                        {field?.name}
                                    </td>
                                    <td>
                                        <div>{isValue(field.value) ? `${field.value} ${field.unit}` : messages.NO_DATA}</div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </>
        );
    };

    return (
        <>
            {loading && (
                <div>
                    <Loading size={50} className="preloader center" />
                </div>
            )}

            {isOpen && (
                <MapScroll>
                    <RenderList />
                </MapScroll>
            )}
        </>
    );
};

export default TabInformation;
