import { memo, useMemo } from 'react';

import useCategoryTypes from 'helpers/hooks/RoadNetwork/useCategoryTypes';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import { loadStatusesRoadSection } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';

import iconSvg from './icons/icon';
import config from './config';

const Legend = () => {
    const categoriesProvider = useCategoryTypes();
    const statuses = useStoreFromSelectorListToObject(
        loadStatusesRoadSection, 
        roadNetworksSelectors.statusesRoadSection
    );

    const renderCategoryList = useMemo(() => {
        const categoryIcon = (id) => {
            switch (parseInt(id)) {
                case 2: return (
                    <div className="image" >
                        <i className={'far fa-dot-circle'}/>
                    </div>
                );
                case 3: return (
                    <div
                        className="image"
                        style={{ height: '1rem' }}
                        dangerouslySetInnerHTML={{ __html: iconSvg('#fff') }}
                    />
                );
                default: return (
                    <div className="image" >
                        <i className={config.layerIcon}/>
                    </div>
                );
            }
        };

        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '2.5rem'
                }}
            >
                {[2, 3].map((id) => {
                    const name = categoriesProvider.getCategoryById(id)?.name;
                    return (
                        <div
                            key={id}
                            className="flex"
                            style={{ alignItems: 'start', fontSize: '1rem' }}
                        >
                            {categoryIcon(id)}
                            <div className="small">- {name}</div>
                        </div>
                    );
                })}
                <div
                    className="flex"
                    style={{ alignItems: 'start', fontSize: '1rem' }}
                >
                    {categoryIcon()}
                    <div className="small">- Остальные</div>
                </div>
            </div>
        );
    }, [categoriesProvider]);

    const renderStatusList = useMemo(() => (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '2.5rem'
            }}
        >
            {statuses.list.map((status) => (
                <div
                    key={status.id}
                    className="flex"
                    style={{ alignItems: 'start', fontSize: '1rem' }}
                >
                    <div
                        className="image"
                        style={{
                            width: '15px',
                            height: '15px',
                            backgroundColor: colorExtend(status.color || '')
                        }}
                    />

                    <div className="small">
                    - {status.name}
                    </div>
                </div>
            ))}
        </div>
    ), [statuses]);

    return (
        <div className="sidebar-tp-legend column">
            {categoriesProvider.getCategories()?.length > 0 && (
                <div className="item">
                    <div className="title">Категории</div>
                    {renderCategoryList}
                </div>
            )}

            {statuses.list?.length > 0 && (
                <div className="item">
                    <div className="title">Статусы</div>
                    {renderStatusList}    
                </div>
            )}
        </div>
    );
};

export default memo(Legend);