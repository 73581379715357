import { useState } from 'react';

import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import SelectScenarioModal from './SelectScenarioModal';

import type { FilterFields } from './SelectScenarioModal/types';

interface SelectIncidentScenarioProps {
    selected: any;
    onChange?: any;
    required?: boolean;
    disabled?: boolean;
    label?: string;
    error?: boolean;
    helperText?: string;
    className?: string;
    filters?: FilterFields;
}

const SelectIncidentScenario = ({
    selected,
    onChange,
    required,
    disabled,
    label = 'Сценарий реагирования',
    error,
    helperText = '',
    className,
    filters
}: SelectIncidentScenarioProps) => {
    const [showList, setShowList] = useState(false);

    return (
        <>
            <CommonAutocomplete
                className={className}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                renderLabel={(option) => option?.name || ''}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
            />

            {showList && (
                <SelectScenarioModal
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    selected={selected}
                    onChange={onChange}
                    filters={filters}
                />  
            )}
        </>
    );
};

export default SelectIncidentScenario;
