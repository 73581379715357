import { 
    useState, 
    useCallback, 
    useEffect 
} from 'react';
import {
    Background,
    Controls,
    ReactFlow,
    useNodesState,
    useEdgesState,
    Panel,
    MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';
import { useDispatch } from 'react-redux';
import { 
    FormControl, 
    InputLabel, 
    Menu, 
    MenuItem, 
    Select 
} from '@mui/material';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import UniversalSelect from 'components/common/UniversalSelect';
import Modal from 'components/common/Modal';
import FormButtons from 'components/common/FormButtons';
import { loadIncidents, loadStoryConnection } from 'modules/React/redux/actions';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';

import { allEdges, allNodes, generateFlowData } from './renderFlow';


const Diagram = ({ modalData, onChange, setDisabled }: any) => { // TODO: поправить типы
    const dispatch = useDispatch();
    const storyConnections = useStoreProp(loadStoryConnection, 'react', 'storyConnections');

    const [nodes, setNodes, onNodesChange] = useNodesState(allNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(allEdges);
    const [data, setData] = useState<any[]>([]);
    
    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; nodeId: string | null; edgeId: string | null } | null>(null);
    const [openModal, setOpenModal] = useState(false);
    const [showList, setShowList] = useState(false);
    const [newEdgeType, setNewEdgeType] = useState('');
    const [selectedEdgeId, setSelectedEdgeId] = useState<string | null>(null);
    const [sourceNodeId, setSourceNodeId] = useState<string | null>(null); // ID исходного узла

    const removeDuplicatesById = <T extends { id: string | number }>(items: T[]): T[] => {
        const seen = new Set();
        return items.filter(item => !seen.has(item.id) && seen.add(item.id));
    };

    useEffect(() => {
        const isEveryNodeConnected = nodes
            .filter(node => !isNaN(Number(node.id))) // Учитываем только ноды с числовым ID
            .every(node => {
                const connectedEdges = edges.filter(
                    edge => String(edge.source) === String(node.id) || String(edge.target) === String(node.id)
                );
    
                const hasInvalidEdge = connectedEdges.some(edge => !edge.data?.connection_id);
                return connectedEdges.length > 0 && !hasInvalidEdge;
            });
    
        setDisabled(!isEveryNodeConnected);
    }, [nodes, edges, setDisabled]);
    
    const processIncidents = (modalData: any) => {
        const combinedData = modalData.incidents.flatMap((incident: any) => [
            incident.first_incident,
            incident.second_incident,
        ]);
    
        const newEdges = modalData.incidents.map((incident: any) => ({
            id: `edge-${incident.first_incident.id}-${incident.second_incident.id}`,
            source: String(incident.first_incident.id),
            target: String(incident.second_incident.id),
            type: 'smoothstep',
            data: {
                connection_id: incident?.connection_id,
                first_incident: incident?.first_incident,
                second_incident: incident?.second_incident,
                connection_text: incident?.connection_text
            },
            label: incident.connection_text || '',
            ...(incident.connection_text === 'Следствие' && {
                markerEnd: { type: MarkerType.ArrowClosed, orient: 'auto-start-reverse', width: 30, height: 30 },
            }),
            ...(incident.connection_text === 'Эквивалентный' && {
                markerEnd: { type: MarkerType.ArrowClosed, width: 30, height: 30  },
                markerStart: { type: MarkerType.ArrowClosed, orient: 'auto-start-reverse', width: 30, height: 30 },
            }),
        }));
    
        const uniqueNodes = Array.from(new Map(combinedData.map((item: any) => [item.id, item])).values());
        const uniqueEdges = Array.from(new Map([...edges, ...newEdges].map((edge) => [edge.id, edge])).values());
    
        return { uniqueNodes, uniqueEdges };
    };
    
    useEffect(() => {
        if (modalData.incidents.length) {
            const { uniqueNodes, uniqueEdges } = processIncidents(modalData);
            const { nodesData, edgesData } = generateFlowData(uniqueNodes, nodes, uniqueEdges);
    
            const node = removeDuplicatesById(nodesData);
            setNodes(node);
            setEdges(edgesData);
            const newData = modalData?.incidents
                .map((item: any) => [item.first_incident, item.second_incident])
                .flat()
                .filter((item: { id: any; }, index: any, array: any[]) => 
                    array.findIndex(incident => incident.id === item.id) === index
                );
                        
            setData((prevData) => removeDuplicatesById([...prevData, ...newData]));
        }
    }, [modalData]);
    
    const updateDiagramState = (newNodes: any, newEdges: any) => {
        setNodes(newNodes);
        setEdges(newEdges);
    
        const incidents = newEdges
            .filter((item: any) => item.id !== 'horizontal-line' && item.id !== 'vertical-line')
            .map((item: any) => ({
                connection_id: item?.data?.connection_id,
                first_incident: item?.data?.first_incident,
                second_incident: item?.data?.second_incident,
                connection_text: storyConnections[item?.data?.connection_id]
            }));
        onChange(incidents);
    };

    const updateDataState = (selectedData: any, newNodes: any, newEdges: any) => {
        const updatedNodes = Array.from(new Map([...nodes, ...newNodes].map((item) => [item.id, item])).values());
        const updatedEdges = Array.from(new Map([...edges, ...newEdges].map((edge) => [edge.id, edge])).values());
    
        setTimeout(() => {
            setNodes(updatedNodes);
            setEdges(updatedEdges);
        }, 0);
        setData(selectedData);
    };
    
    const onConnect = (connection: any) => {
        const source = data.find((item) => String(item.id) == connection.source);
        const target = data.find((item) => String(item.id) == connection.target);

        const newEdge = {
            ...connection,
            id: `edge-${source.id}-${target.id}`,
            label: '',
            type: 'smoothstep',
            data: { connection_id: null, first_incident: source, second_incident: target },
        };
    
        updateDataState(data, [], [newEdge]);
    };
    
    const onNodeContextMenu = useCallback((event, node) => {
        event.preventDefault();
        setContextMenu({ mouseX: event.clientX - 2, mouseY: event.clientY - 4, nodeId: node.id, edgeId: null });
    }, []);

    const onEdgeContextMenu = useCallback((event, edge) => {
        event.preventDefault();
        setContextMenu({ mouseX: event.clientX - 2, mouseY: event.clientY - 4, nodeId: null, edgeId: edge.id });
    }, []);

    const onNodeDragStop = useCallback(
        (event, node) => {
            setNodes((nds) =>
                nds.map((n) =>
                    n.id === node.id
                        ? { ...n, position: { x: node.data.fixedX || n.position.x, y: node.position.y } }
                        : n
                )
            );
        },
        [setNodes]
    );    

    const handleRemoveIncidentNodes = () => {
        if (contextMenu?.nodeId) {
            const nodeIdToRemove = contextMenu.nodeId;
            const updatedNodes = nodes.filter((node) => node.id !== nodeIdToRemove);
            const updatedEdges = edges.filter((edge) => edge.source !== nodeIdToRemove && edge.target !== nodeIdToRemove);
    
            setData((prevData) => prevData.filter((item) => String(item.id) !== nodeIdToRemove));
            updateDiagramState(updatedNodes, updatedEdges);
        }
        setContextMenu(null);
    };    

    const handleRemoveIncidentEdges = () => {
        if (contextMenu?.edgeId) {
            const edgeIdToRemove = contextMenu.edgeId;
            setEdges((eds) => {
                const updatedEdges = eds.filter((edge) => edge.id !== edgeIdToRemove);
                return updatedEdges;
            });
        }
        setContextMenu(null);
    };

    const handleChangeConnectionType = () => {
        if (contextMenu?.edgeId) {
            setSelectedEdgeId(contextMenu.edgeId);
            setOpenModal(true);
        }
        setContextMenu(null);
    };

    const handleAddIncidentNodes = () => {
        if (contextMenu?.nodeId) {
            setSourceNodeId(contextMenu.nodeId);
            setShowList(true);
        }
        setContextMenu(null);
    };

    const handleAccept = (selectedNodes: any) => {
        const newNodes = selectedNodes.filter((item: any) => !data.some((existingNode) => existingNode.id === item.id));
        const sourceNode = selectedNodes.find((item: any) => item.id == sourceNodeId);
        
        const { nodesData, edgesData } = generateFlowData(
            selectedNodes,
            [],
            edges,
            sourceNode ? [sourceNode, ...newNodes] : null
        );

        updateDataState(selectedNodes, nodesData, edgesData);
        setShowList(false);
        setSourceNodeId(null);
    };

    const handleUpdateEdgeType = () => {
        if (selectedEdgeId && newEdgeType) {
            const updatedEdges = edges.map((edge) =>
                edge.id === selectedEdgeId
                    ? {
                        ...edge,
                        data: {
                            ...edge.data,
                            connection_id: newEdgeType,
                        },
                        label: storyConnections[newEdgeType] || '',
                    }
                    : edge
            );

            updateDiagramState(nodes, updatedEdges);
            setOpenModal(false);
            setSelectedEdgeId(null);
            setNewEdgeType('');
        }
    };    

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query } = params;

        dispatch(loadIncidents(page, limit, { name: query }));
    };

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexGrow: 1, height: '400px' }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    onNodeContextMenu={onNodeContextMenu}
                    onEdgeContextMenu={onEdgeContextMenu}
                    defaultViewport={{ x: 400, y: 200, zoom: 0 }}
                    onNodeDragStop={onNodeDragStop}
                >
                    <Controls/>
                    <Background />
                    <Panel position={'top-left'}>
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    accessProp: '',
                                    onClick: () => setShowList(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    </Panel>
                </ReactFlow>
            </div>

            {contextMenu !== null && <Menu
                open={contextMenu !== null}
                onClose={() => setContextMenu(null)}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu && { top: contextMenu.mouseY, left: contextMenu.mouseX }}
            >
                {contextMenu?.nodeId && [
                    <MenuItem key="add-incident" onClick={handleAddIncidentNodes}>Добавить новый инцидент</MenuItem>,
                    <MenuItem key="remove-incident" onClick={handleRemoveIncidentNodes}>Удалить из сюжета</MenuItem>
                ]}
                {contextMenu?.edgeId && [
                    <MenuItem key="change-connection" onClick={handleChangeConnectionType}>Изменить тип связи</MenuItem>,
                    <MenuItem key="remove-connection" onClick={handleRemoveIncidentEdges}>Удалить связь</MenuItem>
                ]}
            </Menu>}

            {showList && (
                <UniversalSelect
                    multiple
                    fetchList={getList}
                    storeName={'react'}
                    storeNameProps={'incidents'}
                    storeLoadingProps={'loadingIncidents'}
                    keyProp={'id'}
                    withSearch
                    isSelected
                    selected={data}
                    searchTitle="поиск по ID и названию"
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    noPadding={true}
                />
            )}

            {openModal && (
                <Modal
                    small
                    isOpen={openModal}
                    onClose={() => {
                        setOpenModal(false);
                        setSelectedEdgeId(null);
                    }}
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.save,
                                    onClick: handleUpdateEdgeType,
                                },
                                {
                                    ...buttonsTypes.cancel,
                                    onClick: () => setOpenModal(false),
                                },
                            ]}
                        />
                    }
                >
                    <div className="block">
                        <FormControl size="small" variant="outlined" style={{ marginBottom: '1rem', minWidth: 200 }}>
                            <InputLabel>Тип связи</InputLabel>
                            <Select
                                value={newEdgeType}
                                onChange={(e) => setNewEdgeType(e.target.value)}
                                label="Тип связи"
                            >
                                <MenuItem value="">{titles.NOT_CHOSEN}</MenuItem>
                                {Object.entries(storyConnections).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Diagram;
