import { all, call, put, takeLatest } from 'redux-saga/effects';

import { showMessage } from 'redux/Message/actions';
import messagetypes from 'redux/Message/messagetypes';
import { setValidation } from 'redux/Validation/actions';
import messageTypes from 'redux/Message/messagetypes';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import api from './api.methods';
import * as actions from './actions';
import * as types from './types';

// инциденты
function* loadIncidentsSaga({ payload, fnCallback, fnLoading }) {
    const { page, limit, params } = payload;
    yield fnLoading
        ? fnLoading(true)
        : put(actions.loadingIncidents(true));
    const response = yield call(api.getIncidents, page, limit, params);
    if (response?.success) {
        yield fnCallback
            ? fnCallback(response)
            : put(actions.loadedIncidents(response));
    }
    yield fnLoading
        ? fnLoading(false)
        : put(actions.loadingIncidents(false));
}

function* loadIncidentByIdSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.getIncidentById, payload);
    if (response?.success) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}

function* loadOperationTypesSaga({ payload }) {
    const response = yield call(api.getOperationTypes);
    if (response?.success) {
        yield put(actions.loadedOperationTypes(response.data));
    }
}

function* loadTypicalOperationsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingTypicalOperations(true));
    const typicalOperationsList = yield call(api.getTypicalOperations, page, limit, params);
    if (typicalOperationsList) {
        yield put(actions.loadedTypicalOperations(typicalOperationsList));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* createTypicalOperationSaga({ payload: data, callback }) {
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.createTypicalOperation, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* editTypicalOperationSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.editTypicalOperation, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* deleteTypicalOperationSaga({ payload: id, callback }) {
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.deleteTypicalOperation, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingTypicalOperations(false));
}

// статус инц.
function* loadIncidentsStatusesSaga() {
    const statuses = yield call(api.getIncidentsStatuses);
    if (statuses) {
        yield put(actions.loadedIncidentsStatuses(statuses.data));
    }
}

function* createIncidentSaga({ payload: data, callback }) {
    const response = yield call(api.createIncident, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

// сайдбар инц.
function* loadIncidentSidebarSaga({ payload }) {
    const { page } = payload;
    yield put(actions.loadingIncidentSidebar(true));
    const response = yield call(api.getIncidentSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedIncidentSidebar(response, page === 1));
    }
    yield put(actions.loadingIncidentSidebar(false));
}

// полигон инц.
function* loadIncidentPolygonSaga({ payload }) {
    const { polygon, filter } = payload;
    yield put(actions.loadingIncidentPolygon(true));
    const response = yield call(api.getIncidentPolygon, { polygon, ...filter });
    if (response?.success) {
        yield put(actions.loadedIncidentPolygon(response.data));
    }
    yield put(actions.loadingIncidentPolygon(false));
}

// тепловая карта инц.
function* loadIncidentHeatMapSaga({ payload }) {
    yield put(actions.loadingIncidentHeatMap(true));
    const response = yield call(api.getIncidentHeatMap, { ...payload });
    if (response?.success) {
        yield put(actions.loadedIncidentHeatMap(response.data));
    }
    yield put(actions.loadingIncidentHeatMap(false));
}

// типы инц.
function* loadIncidentTypesSaga({ payload, callback }) {
    yield put(actions.loadingIncidentTypes(true));
    const typesList = yield call(api.getIncidentTypes, payload);

    if (typesList) {
        if (callback) {
            callback(typesList);
        } else {
            yield put(actions.loadedIncidentTypes(typesList));
        }
    }
    yield put(actions.loadingIncidentTypes(false));
}

function* createIncidentTypeSaga({ payload: data, callback }) {
    const response = yield call(api.createIncidentType, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

function* editIncidentTypeSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editIncidentType, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

function* deleteIncidentTypeSaga({ payload: id, callback }) {
    const response = yield call(api.deleteIncidentType, id);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

function* loadIncidentTypeByIdSaga({ payload: id, callback }) {
    const response = yield call(api.getIncidentTypeById, id);
    if (response?.success) {
        yield callback?.(response.data);
    }
}
// факторы инц.
function* loadIncidentsFactorsSaga({ payload, callback }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingIncidentsFactors(true));
    const response = yield call(api.getIncidentsFactors, page, limit, params);
    if (response?.success) {
        if (callback) {
            callback(response);
        } else {
            yield put(actions.loadedIncidentsFactors(response));
        }
    }
    yield put(actions.loadingIncidentsFactors(false));
}

function* createIncidentsFactorsSaga({ payload: data, callback }) {
    yield put(actions.loadingIncidentsFactors(true));
    const response = yield call(api.createIncidentsFactors, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingIncidentsFactors(false));
}

function* editIncidentSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editIncident, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteIncidentSaga({ payload, callback }) {
    const response = yield call(api.deleteIncident, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

function* editIncidentsFactorsSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingIncidentsFactors(true));
    const response = yield call(api.editIncidentsFactors, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingIncidentsFactors(false));
}

function* deleteIncidentsFactorsSaga({ payload: id, callback }) {
    yield put(actions.loadingIncidentsFactors(true));
    const response = yield call(api.deleteIncidentsFactors, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingIncidentsFactors(false));
}

// сценарии реагирования
function* loadResponseScenariosSaga({ payload, forSelect }) {
    yield put(actions.loadingResponseScenarios(true));
    const response = yield call(api.getResponseScenarios, payload);
    if (response?.success) {
        forSelect
            ? yield put(actions.loadedResponseScenariosForSelect(response))
            : yield put(actions.loadedResponseScenarios(response));
    }
    yield put(actions.loadingResponseScenarios(false));
}

function* createResponseScenarioSaga({ payload: data, callback }) {
    const response = yield call(api.createResponseScenario, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
}

function* getResponseScenarioByIdSaga({ payload, callback }) {
    const { id } = payload;
    const response = yield call(api.getResponseScenarioById, id);
    if (response?.success) {
        yield callback?.();
    }
}
function* editResponseScenarioSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editResponseScenario, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteResponseScenarioSaga({ payload: id, callback }) {
    const response = yield call(api.deleteResponseScenario, id);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

//категории сценариев
function* loadCategoriesSaga({ payload }) {
    yield put(actions.loadingCategories(true));
    const response = yield call(api.getCategories, payload);
    if (response?.success) {
        yield put(actions.loadedCategories(response));
    }
    yield put(actions.loadingCategories(false));
}

//категории инцидентов
function* loadIncidentCategoriesSaga() {
    yield put(actions.loadingIncidentCategories(true));
    const response = yield call(api.getIncidentCategories);
    if (response?.success) {
        yield put(actions.loadedIncidentCategories(response.data));
    }
    yield put(actions.loadingIncidentCategories(false));
}

// мероприятия
function* loadEventsSaga({ payload }) {
    yield put(actions.loadingEvents(true));
    const response = yield call(api.getEvents, payload);
    if (response?.success) {
        yield put(actions.loadedEvents(response));
    }
    yield put(actions.loadingEvents(false));
}

function* createEventSaga({ payload: data }) {
    yield put(actions.loadingEvents(true));
    const response = yield call(api.createEvent, data);
    if (response?.success) {
        yield put(actions.loadEvents());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEvents(false));
}

function* editEventSaga({ payload }) {
    const { id, data } = payload;
    yield put(actions.loadingEvents(true));
    const response = yield call(api.editEvent, id, data);
    if (response?.success) {
        yield put(actions.loadEvents());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEvents(false));
}

function* deleteEventSaga({ payload: id }) {
    yield put(actions.loadingEvents(true));
    const response = yield call(api.deleteEvent, id);
    if (response?.success) {
        yield put(actions.loadEvents());
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEvents(false));
}

// объекты мероприятий
function* loadEventObjectsSaga({ payload }) {
    yield put(actions.loadingEventObjects(true));
    const response = yield call(api.getEventObjects, payload);
    if (response?.success) {
        yield put(actions.loadedEventObjects(response));
    }
    yield put(actions.loadingEventObjects(false));
}

function* createEventObjectsSaga({ payload: data, callback }) {
    yield put(actions.loadingEventObjects(true));
    const response = yield call(api.createEventObjects, data);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEventObjects(false));
}

function* editEventObjectsSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingEventObjects(true));
    const response = yield call(api.editEventObjects, id, data);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEventObjects(false));
}

function* deleteEventObjectsSaga({ payload: id, callback }) {
    yield put(actions.loadingEventObjects(true));
    const response = yield call(api.deleteEventObjects, id);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEventObjects(false));
}

function* loadEventObjectsTypesSaga() {
    const typesList = yield call(api.getEventObjectsTypes);
    if (typesList) {
        yield put(actions.loadedEventObjectsTypes(typesList.data));
    }
}

// уровень угрозы
function* loadThreatLevelsSaga({ payload }) {
    yield put(actions.loadingThreatLevels(true));
    const response = yield call(api.getThreatLevels, payload);
    if (response?.success) {
        yield put(actions.loadedThreatLevels(response));
    }
    yield put(actions.loadingThreatLevels(false));
}

// function* createThreatLevelSaga({ payload: data, callback }) {
//     yield put(actions.loadingThreatLevels(true));
//     const response = yield call(api.createThreatLevel, data);
//     if (response?.success) {
//         yield callback && callback();
//         yield put(setValidation({ success: response.success }));
//         yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
//     }
//     yield put(actions.loadingThreatLevels(false));
// }

// function* editThreatLevelSaga({ payload, callback }) {
//     const { id, data } = payload;
//     yield put(actions.loadingThreatLevels(true));
//     const response = yield call(api.editThreatLevel, id, data);
//     if (response?.success) {
//         yield callback && callback();
//         yield put(setValidation({ success: response.success }));
//         yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
//     }
//     yield put(actions.loadingThreatLevels(false));
// }

// function* deleteThreatLevelSaga({ payload: id, callback }) {
//     yield put(actions.loadingThreatLevels(true));
//     const response = yield call(api.deleteThreatLevel, id);
//     if (response?.success) {
//         yield callback && callback();
//         yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
//     }
//     yield put(actions.loadingThreatLevels(false));
// }
//  виды тс
function* loadVehicleCategoriesSaga() {
    const response = yield call(api.getVehicleCategories);
    if (response) {
        yield put(actions.loadedVehicleCategories(response));
    }
}

function* loadIncidentEventsSaga({ payload }) {
    yield put(actions.loadingIncidentEvents(true));
    const response = yield call(api.getIncidentEvents, payload);
    if (response) {
        yield put(actions.loadedIncidentEvents(response.data));
    }
    yield put(actions.loadingIncidentEvents(false));
}

// явления
function* loadPhenomenonsSaga({ payload }) {
    yield put(actions.loadingPhenomenons(true));
    const response = yield call(api.getPhenomenons, payload);
    if (response?.success) {
        yield put(actions.loadedPhenomenons(response));
    }
    yield put(actions.loadingPhenomenons(false));
}

// триггеры
function* loadTriggerStatusesSaga() {
    const response = yield call(api.getTriggerStatuses);
    if (response?.success) {
        yield put(actions.loadedTriggerStatuses(response.data));
    }
}

function* loadTriggersSaga({ payload }) {
    yield put(actions.loadingTriggers(true));
    const response = yield call(api.getTriggers, payload);
    if (response?.success) {
        yield put(actions.loadedTriggers(response));
    }
    yield put(actions.loadingTriggers(false));
}

function* createTriggerSaga({ payload, callback }) {
    const response = yield call(api.createTrigger, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
}

function* editTriggerSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editTrigger, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteTriggerSaga({ payload, callback }) {
    const response = yield call(api.deleteTrigger, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

function* getPolygonForTriggers ({ payload, callback }) {
    const { url, params } = payload;
    const response = yield call(api.getPolygonForTriggers, url, params);
    if (response?.success) {
        yield callback?.(response.data);
    }
}

function* loadKeyWordsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingKeyWordList(true));
    const response = yield call(api.getKeywordList, page, limit, params);
    if (response) {
        yield put(actions.loadedKeyWordList(response));
    }
    yield put(actions.loadingKeyWordList(false));
}

function* editKeyWordSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editKeyWord, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
        yield put(setValidation({ success: true }));
    }
}

function* addKeyWordSaga({ payload, callback }) {
    const response = yield call(api.addKeyWord, payload);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield put(setValidation({ success: true }));
    }
}

function* deleteKeyWordSaga({ payload: id, callback }) {
    const response = yield call(api.deleteKeyWord, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

function* loadIncidentHistoryByIdSaga({ payload }) {
    yield put(actions.loadingIncidentHistoryById(true));
    const response = yield call(api.getIncidentHistoryById, payload.id, payload.params);
    if (response) {
        yield put(actions.loadedIncidentHistoryById(response.data));
    }
    yield put(actions.loadingIncidentHistoryById(false));
}

function* loadIncidentObjectsSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    // yield put(actions.loadingIncidentObjects(true));
    const response = yield call(api.getIncidentObjects, payload);
    if (response?.success) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}

function* loadIncidentObjectTypesSaga() {
    const response = yield call(api.getIncidentObjectTypes);
    if (response?.success) {
        yield put(actions.loadedIncidentObjectTypes(response.data));
    }
}

function* loadIncidentsCloseReasonsSaga({ fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.getIncidentCloseReasons);
    if (response?.success) {
        yield fnCallback?.(response.data);
    }

    yield fnLoading?.(false);
}

function* loadIncidentsCloseTypesSaga({ fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.getIncidentCloseTypes);
    if (response?.success) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}

function* loadIncidentsNotificationsSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.getIncidentsNotifications, payload);
    if (response?.success) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}

function* createIncidentsNotificationsSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.createIncidentsNotifications, payload);
    if (response?.success) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}

//операции инцидента
function* loadIncidentOperationsSaga({ payload }) {
    yield put(actions.loadingIncidentOperations(true));
    const response = yield call(api.getIncidentOperations, payload);
    if (response) {
        yield put(actions.loadedIncidentOperations(response));
    }
    yield put(actions.loadingIncidentOperations(false));
}

function* editIncidentOperationsSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingIncidentOperations(true));
    const response = yield call(api.editIncidentOperations, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingIncidentOperations(false));
}

function* loadInfopanelSaga({ payload }) {
    yield put(actions.loadingInfopanel(true));
    const request = yield call(api.loadInfopanel, payload);
    if (request) {
        yield put(actions.loadedInfopanel(request));
    }
    yield put(actions.loadingInfopanel(false));
}

function* loadStoriesSaga({ payload }) {
    yield put(actions.loadingStories(true));
    const response = yield call(api.getStories, payload);
    if (response?.success) {
        yield put(actions.loadedStories(response));
    }
    yield put(actions.loadingStories(false));
}

function* createStorySaga({ payload: data, callback }) {
    yield put(actions.loadingStories(true));
    const response = yield call(api.createStory, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingStories(false));
}

function* editStorySaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingStories(true));
    const response = yield call(api.editStory, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingStories(false));
}

function* deleteStorySaga({ payload: id, callback }) {
    yield put(actions.loadingStories(true));
    const response = yield call(api.deleteStory, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingStories(false));
}

function* loadStoryStatusesSaga() {
    const response = yield call(api.getStoryStatuses);
    if (response?.success) {
        yield put(actions.loadedStoryStatuses(response.data));
    }
}

function* loadStoryConnectionSaga() {
    const response = yield call(api.getStoryConnection);
    if (response?.success) {
        yield put(actions.loadedStoryConnection(response.data));
    }
}

function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messagetypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_INCIDENTS, loadIncidentsSaga),
        takeLatest(types.CREATE_INCIDENT, createIncidentSaga),
        takeLatest(types.EDIT_INCIDENT, editIncidentSaga),
        takeLatest(types.DELETE_INCIDENT, deleteIncidentSaga),
        takeLatest(types.LOAD_INCIDENT_BY_ID, loadIncidentByIdSaga),
        takeLatest(types.LOAD_OPERATION_TYPES, loadOperationTypesSaga),

        takeLatest(types.LOAD_INCIDENT_HISTORY_BY_ID, loadIncidentHistoryByIdSaga),

        takeLatest(types.LOAD_TYPICAL_OPERATIONS, loadTypicalOperationsSaga),
        takeLatest(types.CREATE_TYPICAL_OPERATION, createTypicalOperationSaga),
        takeLatest(types.EDIT_TYPICAL_OPERATION, editTypicalOperationSaga),
        takeLatest(types.DELETE_TYPICAL_OPERATION, deleteTypicalOperationSaga),

        takeLatest(types.LOAD_INCIDENTS_STATUSES, loadIncidentsStatusesSaga),
        takeLatest(types.LOAD_THREAT_LEVELS, loadThreatLevelsSaga),
        // takeLatest(types.CREATE_THREAT_LEVEL, createThreatLevelSaga),
        // takeLatest(types.EDIT_THREAT_LEVEL, editThreatLevelSaga),
        // takeLatest(types.DELETE_THREAT_LEVEL, deleteThreatLevelSaga),
        takeLatest(types.LOAD_INCIDENT_SIDEBAR, loadIncidentSidebarSaga),
        takeLatest(types.LOAD_INCIDENT_POLYGON, loadIncidentPolygonSaga),
        takeLatest(types.LOAD_INCIDENT_HEAT_MAP, loadIncidentHeatMapSaga),
        takeLatest(types.LOAD_VEHICLES_CATEGORIES, loadVehicleCategoriesSaga),
        takeLatest(types.LOAD_INCIDENT_EVENTS, loadIncidentEventsSaga),
        takeLatest(types.LOAD_INCIDENTS_FACTORS, loadIncidentsFactorsSaga),
        takeLatest(types.CREATE_INCIDENTS_FACTORS, createIncidentsFactorsSaga),
        takeLatest(types.EDIT_INCIDENTS_FACTORS, editIncidentsFactorsSaga),
        takeLatest(types.DELETE_INCIDENTS_FACTORS, deleteIncidentsFactorsSaga),
        takeLatest(types.LOAD_RESPONSE_SCENARIOS, loadResponseScenariosSaga),
        takeLatest(types.CREATE_RESPONSE_SCENARIO, createResponseScenarioSaga),
        takeLatest(types.GET_RESPONSE_SCENARIO_BY_ID, getResponseScenarioByIdSaga),
        takeLatest(types.EDIT_RESPONSE_SCENARIO, editResponseScenarioSaga),
        takeLatest(types.DELETE_RESPONSE_SCENARIO, deleteResponseScenarioSaga),
        takeLatest(types.LOAD_CATEGORIES, loadCategoriesSaga),
        takeLatest(types.LOAD_EVENTS, loadEventsSaga),
        takeLatest(types.CREATE_EVENT, createEventSaga),
        takeLatest(types.EDIT_EVENT, editEventSaga),
        takeLatest(types.DELETE_EVENT, deleteEventSaga),
        takeLatest(types.LOAD_EVENT_OBJECTS, loadEventObjectsSaga),
        takeLatest(types.CREATE_EVENT_OBJECTS, createEventObjectsSaga),
        takeLatest(types.EDIT_EVENT_OBJECTS, editEventObjectsSaga),
        takeLatest(types.DELETE_EVENT_OBJECTS, deleteEventObjectsSaga),
        takeLatest(types.LOAD_EVENT_OBJECTS_TYPES, loadEventObjectsTypesSaga),
        takeLatest(types.LOAD_PHENOMENONS, loadPhenomenonsSaga),
        takeLatest(types.LOAD_TRIGGER_STATUSES, loadTriggerStatusesSaga),
        takeLatest(types.LOAD_TRIGGERS, loadTriggersSaga),
        takeLatest(types.CREATE_TRIGGER, createTriggerSaga),
        takeLatest(types.EDIT_TRIGGER, editTriggerSaga),
        takeLatest(types.DELETE_TRIGGER, deleteTriggerSaga),
        takeLatest(types.LOAD_POLYGON_FOR_TRIGGERS, getPolygonForTriggers),
        takeLatest(types.LOAD_INCIDENT_TYPES, loadIncidentTypesSaga),
        takeLatest(types.CREATE_INCIDENT_TYPE, createIncidentTypeSaga),
        takeLatest(types.EDIT_INCIDENT_TYPE, editIncidentTypeSaga),
        takeLatest(types.DELETE_INCIDENT_TYPE, deleteIncidentTypeSaga),
        takeLatest(types.GET_INCIDENT_TYPE_BY_ID, loadIncidentTypeByIdSaga),
        takeLatest(types.LOAD_KEY_WORD_LIST, loadKeyWordsSaga),
        takeLatest(types.ADD_KEY_WORD, addKeyWordSaga),
        takeLatest(types.EDIT_KEY_WORD, editKeyWordSaga),
        takeLatest(types.DELETE_KEY_WORD, deleteKeyWordSaga),
        takeLatest(types.LOAD_INFOPANEL, loadInfopanelSaga),
        takeLatest(types.LOAD_INCIDENT_CATEGORIES, loadIncidentCategoriesSaga),

        takeLatest(types.LOAD_INCIDENT_OBJECTS, loadIncidentObjectsSaga),
        takeLatest(types.LOAD_INCIDENT_OBJECTS_TYPES, loadIncidentObjectTypesSaga),

        takeLatest(types.LOAD_INCIDENTS_CLOSE_REASONS, loadIncidentsCloseReasonsSaga),
        takeLatest(types.LOAD_INCIDENTS_CLOSE_TYPES, loadIncidentsCloseTypesSaga),
        takeLatest(types.LOAD_INCIDENTS_NOTIFICATIONS, loadIncidentsNotificationsSaga),
        takeLatest(types.CREATE_INCIDENTS_NOTIFICATIONS, createIncidentsNotificationsSaga),

        takeLatest(types.LOAD_INCIDENT_OPERATIONS, loadIncidentOperationsSaga),
        takeLatest(types.EDIT_INCIDENT_OPERATIONS, editIncidentOperationsSaga),

        takeLatest(types.LOAD_STORIES, loadStoriesSaga),
        takeLatest(types.CREATE_STORY, createStorySaga),
        takeLatest(types.EDIT_STORY, editStorySaga),
        takeLatest(types.DELETE_STORY, deleteStorySaga),
        takeLatest(types.LOAD_STORY_STATUSES, loadStoryStatusesSaga),
        takeLatest(types.LOAD_STORY_CONNECTION, loadStoryConnectionSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),
    ]);
}
