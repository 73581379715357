import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { List } from '@mui/material';
import { TabPanel } from '@mui/lab';

import common from 'helpers/constants/buttons/common';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import Item from './Item';
import styles from './styles.module.scss';

import type { Action } from 'types/common';
import type { Setting } from './types';

interface TabProps {
    item: Setting
    editAction: Action
    reloadList: () => void
    loadingButton: boolean
}

const SettingsTab = ({ item, editAction, reloadList, loadingButton }: TabProps) => {
    const dispatch = useDispatch();

    const [settingsState, setSettingsState] = useState<Setting[]>(item.settings || []);
    const [changed, setChanged] = useState(false);
    const [error, setError] = useState<boolean>(false);

    const onChange = (id: number, value: any) => {
        const newValue = settingsState.map((item) => (item.id === id ? { ...item, value } : item));
        setSettingsState(newValue);
        setChanged(true);
    };

    const onSave = () => {
        const prepareData = settingsState.reduce((acc, curr) => ({ ...acc, [curr.name]: curr.value }), {});

        dispatch(editAction(item.id, prepareData, reloadList));
        setChanged(false);
    };

    const onReset = () => {
        setSettingsState(item.settings || []);
        setChanged(false);
    };

    return (
        <TabPanel value={String(item.id)}>
            <div className={styles.wrapper}>
                <List className={styles.list}>
                    {settingsState.map(
                        (item, i) =>
                            i % 2 === 0 && <Item key={item.id} item={item} onChange={onChange} setError={setError} />
                    )}
                </List>

                <List className={styles.list}>
                    {settingsState.map(
                        (item, i) =>
                            i % 2 !== 0 && <Item key={item.id} item={item} onChange={onChange} setError={setError} />
                    )}
                </List>
            </div>

            {settingsState.length > 0 && (
                <FormButtons
                    positionLeft
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            name: common.RESET,
                            disabled: !changed,
                            onClick: onReset
                        },
                        {
                            ...buttonsTypes.save,
                            disabled: !changed || error,
                            loading: loadingButton,
                            onClick: onSave
                        }
                    ]}
                />
            )}
        </TabPanel>
    );
};

export default SettingsTab;
