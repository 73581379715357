import { useEffect } from 'react';
import L from 'leaflet';

import { Marker, PolyLine, ToolTip } from 'components/MapComponents/leaflet';

const InnerStations = ({
    data,
    ...props
}) => {

    const icon = (color) => L.divIcon({
        className: 'marker',
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        popupAnchor: [0, -30],
        html: `
        <div style="background: ${color};" class="digitalTwin markerIcon">
            <i class="fas fa-subway"></i>
        </div>
    `,
    });

    const vals = data?.metro_stations || [];

    useEffect(() => {
        if (vals.length > 0) {
            props?.map?.fitBounds?.(vals.map(i => ([i.lat, i.lon])));
        }
    }, [vals]);

    return (
        <>
            {vals?.length > 1 && (
                <PolyLine
                    {...props}
                    coordinates={vals.map(i => ([i.lat, i.lon]))}
                    style={{
                        color: '#005889',
                        weight: 8,
                        smoothFactor: 1
                    }}
                />
            )}

            {vals?.map((item) => (
                <Marker
                    {...props}
                    key={item.id}
                    latlng={[item.lat, item.lon]}
                    icon={icon('#005889')}
                >
                    <ToolTip
                        offset={[0, -20]}
                        direction="top"
                    >
                        <div>{item.name || ''}</div>
                    </ToolTip>
                </Marker>
            ))}
        </>
    );
};

export default InnerStations;
