import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import renderAddress from 'helpers/renderAddress';
import messages from 'helpers/constants/messages';
import { LSContentColumn, LSContentItem } from 'components/common/List';

import type { CommutatorItem } from 'types';

interface ItemProps {
    item: CommutatorItem;
    selectedItem: boolean;
    onClickItem: () => void;
    currentCommutator: CommutatorItem | null;
}

const Item = (props: ItemProps) => {
    const {
        item,
        selectedItem,
        onClickItem,
        currentCommutator,
    } = props;

    const buttonsRender = () => (
        <IconButton size="small" onClick={onClickItem}>
            {selectedItem
                ? <ArrowBackIosIcon fontSize="inherit"/>
                : <ArrowForwardIosIcon fontSize="inherit"/>
            }
        </IconButton>
    );

    return (
        <>
            {currentCommutator
                ? <LSContentItem
                    onClick={onClickItem}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: selectedItem ? '#0000001f' : ''
                    }}
                >
                    <LSContentColumn>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 16
                        }}>
                            <div>
                                <div>
                                    <b>Название: </b>{item?.name || messages.NO_DATA}
                                </div>
                                <div>
                                    <b>Серийный №: </b><span>{item?.serial_number || messages.NO_DATA}</span>
                                </div>
                            </div>
                            <div className="flex-center">
                                {buttonsRender()}
                            </div>
                        </div>
                    </LSContentColumn>
                </LSContentItem>
                : <LSContentItem onClick={onClickItem} style={{ cursor: 'pointer' }}>
                    <LSContentColumn>
                        {item?.name || messages.NO_DATA}
                    </LSContentColumn>
                    <LSContentColumn>
                        {item?.serial_number || messages.NO_DATA}
                    </LSContentColumn>
                    <LSContentColumn>
                        {item?.address_text || renderAddress(item?.address, [
                            'area',
                            'city_name',
                            'district',
                            'street',
                            'house'
                        ]) || messages.NO_DATA}
                    </LSContentColumn>
                    <LSContentColumn isActions >
                        {buttonsRender()}
                    </LSContentColumn>
                </LSContentItem>
            }
        </>
    );
};

export default Item;
