import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';

const Item = ({
    item = {},
    statusesObjects,
}) => {

    const {
        // name, // название инцидента
        status_id, // статус инцидента
        type_text, // тип инцидента
        registered_at, // Дата/время регистрации инцидента
        description, // описание инцидента
        solution, // решение (новое поле)
    } = item;

    const renderStatus = () => (
        <div style={{ display: 'flex' }}>
            <CircleStatus
                title={statusesObjects?.get(status_id).name}
                color={statusesObjects?.get(status_id).color}
            />
            <span style={{ marginLeft: '.5rem' }}>{statusesObjects?.get(status_id).name}</span>
        </div>
    );

    return (
        <LSContentItem>
            <LSContentColumn>
                {fullDateTimeWithoutSeconds(registered_at)}
            </LSContentColumn>

            <LSContentColumn>
                {renderStatus()}
            </LSContentColumn>

            <LSContentColumn>
                {type_text || messages.NO_VALUE}
            </LSContentColumn>

            <LSContentColumn>
                {description || messages.NO_VALUE}
            </LSContentColumn>

            <LSContentColumn>
                {solution || messages.NO_VALUE}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
