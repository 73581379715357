import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, } from '@mui/material';

import { createTypicalOperation, editTypicalOperation } from 'redux/SituationalPlans/actions';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const ModalForm = ({ isOpen, onClose, isNew = false, item={}, reloadList }) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const [data, setData] = useState({
        name: item?.name || '',
    });

    const closeForm = () => onClose(false);

    const onAcceptModal = () => {
        isNew
            ? dispatch(createTypicalOperation(data, reloadList))
            : dispatch(editTypicalOperation(item?.id, data, reloadList));
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [requestStatus.success, onClose, validation]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: data.name === '' || validation.isValidation()
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <FormInfoWrapper
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    >
                        <TextField
                            label={titles.NAME}
                            size="small"
                            value={data.name}
                            variant="outlined"
                            name="name"
                            type="text"
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            error={!data.name || validation.isKey('name')}
                            required
                        />
                    </FormInfoWrapper>
                    
                </div>
            </form>
        </Modal>
    );
};

export default ModalForm;
