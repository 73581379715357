import { useState } from 'react';
import { useSelector } from 'react-redux';

import { loadRoutesTransportations, loadVehicleCategories } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { useStoreProp } from 'helpers/hooks';
import useTransportCategory, { transportCategoryHelper } from 'helpers/hooks/Transport/useTransportCategory';
import AddEditCopyModal from 'components/pages/Dictionaries/PassengerTransport/Routes/AddEditCopyModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import icons from '../icons/icons';

import RouteData from './RouteData';


const RoutePopUp = (props) => {
    const {
        data: route = {},
        // id: uuid,
        permissions,
        readOnly = false,
    } = props;

    const types = useSelector(transportPassengerSelectors.types);
    const active = useSelector(transportPassengerSelectors.active);

    // const route = active?.find(({ id }) => uuid === id) || {};
    const {
        category_id = 0,
        num
    } = route;
    // const currentTransport = types.find(({ id }) => id === parseInt(category_id));


    const typeTransportation = useStoreProp(
        loadRoutesTransportations,
        'transportPassenger',
        'transportations'
    );

    const [modalOpen, setModalOpen] = useState(null);

    const handleCloseModal = () => {
        // делать запрос
        setModalOpen(null);
    };

    return (
        <>
            <RouteData
                route={route}
            />

            {modalOpen && (
                <AddEditCopyModal
                    data={modalOpen}
                    isNew={false}
                    isOpen={!!modalOpen}
                    onClose={handleCloseModal}
                    typeTransportation={typeTransportation}
                    permissions={permissions}
                />
            )}

            {!readOnly && (
                <div
                    className="filter__wrap__btn filter__btn__column"
                    style={{
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                    }}
                >
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.edit,
                                size: 'small',
                                disabled: !permissions?.is_update,
                                onClick: () => {
                                    setModalOpen({ ...route });
                                }
                            }
                        ]}
                    />
                </div>
            )}
        </>
    );
};

export default RoutePopUp;
