import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, FormHelperText, TextField } from '@mui/material';

import { createPark, editPark } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';
import { useValidation } from 'helpers/hooks';
import InfoBlock from 'components/common/InfoBlock';
import MapForm from 'components/MapComponents/MapForm';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import SelectCompany from 'components/common/Autocomplete/Companies';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import TitleValidator from 'components/common/Location/TitleValidator';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const ModalForm = ({ isNew, onClose, el = {}, isOpen, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(transportPassengerSelectors.loadingButton);

    const initialState = {
        ...el,
        name: el?.name || '',
        lat: el?.check_point?.lat || '',
        lon: el?.check_point?.lon || '',
        area: el?.check_point?.geometry || {},
        comment: el?.comment || '',
        organizations: isNew ? [] : el?.organizations,
    };

    const [formData, setFormData] = useState(initialState);
    const [openLatLonMap, setOpenLatLonMap] = useState(false);

    const onChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAcceptCoordinates = ({ 0: lat, 1: lon }) => {
        setFormData({ ...formData, lat: lat, lon: lon });
        setOpenLatLonMap(false);
    };

    const handleSaved = () => {
        const { lat, lon, area, organizations, ...others } = formData;

        const result = {
            ...others,
            organization_ids: organizations.map((Item) => Item.id),
            check_point: {
                lat,
                lon,
                geometry: area,
            },
        };

        const callback = () => {
            reloadList();
            onClose();
        };

        if (isNew) {
            dispatch(createPark(result, callback));
        } else {
            dispatch(editPark(el.id, result, callback));
        }
    };

    const getLatLonGeometry = formData.lat && formData.lon
        ? {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    formData.lon,
                    formData.lat,
                ],
            }
        } : null;

    const isDisabled = !formData.name || !formData.lat || !formData.lon || !formData.area;

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                        },
                        {
                            ...(isNew ? buttonsTypes.create : buttonsTypes.save),
                            disabled: isDisabled,
                            onClick: handleSaved,
                            loading: loadingButton,
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={formData.name || ''}
                        name={'name'}
                        onChange={(e) => onChange('name', e.target.value)}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    />
                </FormControl>
                <div className="block">
                    <SelectCompany
                        multiple
                        selected={formData.organizations}
                        onChange={(value) => onChange('organizations', value)}
                        filter={{ withDeleted: 1 }}
                    />
                </div>

                <InfoBlock label={titles.LOCATION} className="block">
                    <div className="row">
                        <FormInfoWrapper
                            error={validation.isKey('lat')}
                            helperText={validation.get('lat')}
                            className="row__item"
                        >
                            <TextField
                                required
                                label={titles.LAT}
                                variant="outlined"
                                size="small"
                                name="lat"
                                value={formData.lat || ''}
                                onChange={(e) => onChange('lat', e.target.value)}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            error={validation.isKey('lon')}
                            helperText={validation.get('lon')}
                            className="row__item"
                        >
                            <TextField
                                required
                                label={titles.LON}
                                variant="outlined"
                                size="small"
                                name="lon"
                                value={formData.lon || ''}
                                onChange={(e) => onChange('lon', e.target.value)}
                            />
                        </FormInfoWrapper>
                    </div>

                    {openLatLonMap && (
                        <MapForm
                            isOpen={openLatLonMap}
                            latlon={[formData.lat, formData.lon]}
                            onAccept={handleAcceptCoordinates}
                            onClose={() => setOpenLatLonMap(false)}
                        />
                    )}

                    <FormButtonsComponent
                        noPadding
                        positionLeft
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.defaultInfo,
                                color: 'infoButton',
                                name: buttons.SELECT,
                                onClick: () => setOpenLatLonMap(true),
                            }
                        ]}
                    />

                    <div className="block">
                        <TitleValidator
                            fields={formData}
                            validationFields={['area']}
                        >
                            Зона действия *:
                        </TitleValidator>
                        {validation.isKey('check_point.geometry')
                            ? (
                                <FormHelperText className="error">
                                    {validation.get('check_point.geometry')}
                                </FormHelperText>
                            )
                            : null
                        }
                        <FieldsModal
                            title="Выбрать область на карте"
                            fields={{ geometry: formData?.area || {} }}
                            buttonText={buttons.SELECT}
                            buttonType={buttonsTypes.selectOnMap}
                            buttonVariant="contained"
                            onChange={({ geometry }) => {
                                onChange('area', geometry);
                                validation.deleteKeys(['area.geometry.coordinates.0', 'check_point.geometry']);
                            }}
                        >
                            <MapGeoJson
                                visibleGeometry={getLatLonGeometry}
                                circle={true}
                                polygon={true}
                                polyline={false}
                                marker={false}
                                isHideValidation
                            />
                        </FieldsModal>
                    </div>
                </InfoBlock>

                <FormInfoWrapper
                    error={validation.isKey('comment')}
                    helperText={validation.get('comment')}
                    className="block"
                >
                    <TextField
                        label={titles.COMMENT}
                        multiline
                        rows={3}
                        variant="outlined"
                        value={formData.comment || ''}
                        name="comment"
                        onChange={(e) => onChange('comment', e.target.value)}
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default ModalForm;
