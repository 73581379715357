import instance from 'helpers/axios.config';

import apiBoardsUrl from './api.urls';

const ApiAdminMethods = {
    getBoardStatus: async () => {
        const response = await instance.get(apiBoardsUrl.getBoardStatus());
        return response.data;
    },

    getBoardTypes: async (params) => {
        const response = await instance.get(apiBoardsUrl.getBoardTypes(), { params });
        return response?.data;
    },

    getBoards: async (params) => {
        const response = await instance.get(apiBoardsUrl.getBoards(), { params });
        return response?.data;
    },

    createBoard: async (params) => {
        const response = await instance.post(apiBoardsUrl.createBoard(), params);
        return response?.data;
    },

    editBoard: async (id, data) => {
        const response = await instance.put(apiBoardsUrl.editBoard(id), data);
        return response?.data;
    },

    deleteBoard: async (id) => {
        const response = await instance.delete(apiBoardsUrl.deleteBoard(id));
        return response?.data;
    },

    getMessagePriorities: async () => {
        const response = await instance.get(apiBoardsUrl.getMessagePriorities());
        return response.data;
    },

    getMessages: async (params) => {
        const response = await instance.get(apiBoardsUrl.getMessages(), { params });
        return response?.data;
    },

    createMessage: async (params) => {
        const formData = new FormData();
        const { file, text, boards, ...props }  = params;

        if(file) {
            formData.append(
                'file', 
                file
            );  
        }
        Object.keys(props).map((key) => formData.append(key, props[key]));
        const response = file 
            ? await instance.post(apiBoardsUrl.createMessage(), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            : await instance.post(apiBoardsUrl.createMessage(), params);
        return response?.data;
    },

    editMessage: async (id, data) => {
        const formData = new FormData();
        const { file, text, boards, ...props }  = data;

        if(file) {
            formData.append(
                'file', 
                file
            );  
        }
        Object.keys(props).map((key) => formData.append(key, props[key]));
        const response = file 
            ? await instance.put(apiBoardsUrl.editMessage(id), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            : await await instance.put(apiBoardsUrl.editMessage(id), data);
        return response?.data;
    },

    deleteMessage: async (id) => {
        const response = await instance.delete(apiBoardsUrl.deleteMessage(id));
        return response?.data;
    },

    getBoardColors: async () => {
        const response = await instance.get(apiBoardsUrl.getBoardColors());
        return response?.data;
    },

    getForPolygon: async (polygon, params = {}) => {
        const response = await instance.post(
            apiBoardsUrl.getPolygon(),
            {
                polygon,
                ...params
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        );
        return response?.data?.data;
    },

    getSideBar: async (page, limit, params) => {
        const response = await instance.get(apiBoardsUrl.getSidebar(), {
            params: { page, limit, ...params },
        });
        return response.data;
    },

    fetchMessagePreview: async (id, data) => {
        const response = await instance.post(apiBoardsUrl.fetchMessagePreview(id), data);
        return response?.data;
    },

    fetchMessageJSONPreview: async (id, data) => {
        const response = await instance.post(apiBoardsUrl.fetchMessageJSONPreview(id), data);
        return response?.data;
    },

    fetchActiveBoardMessage: async (id) => {
        const response = await instance.post(apiBoardsUrl.fetchActiveBoardMessage(id));
        return response?.data;
    },

    getFontsList: async (params) => {
        const response = await instance.get(apiBoardsUrl.getFontsList(), { params });
        return response?.data;
    },

    getFontsListForTypeWithImg: async () => {
        const response = await instance.get(apiBoardsUrl.getFontsListForTypeWithImg());
        return response?.data;
    },

    getSignsList: async () => {
        const response = await instance.get(apiBoardsUrl.getSignsList());
        return response?.data;
    },

    getBoardRotationMessages: async (id) => {
        const response = await instance.get(apiBoardsUrl.getBoardRotationMessages(id));
        return response?.data;
    },

    getBoardEvents: async (params) => {
        const response = await instance.post(apiBoardsUrl.getBoardEvents(), { id: params });
        return response?.data;
    },

    getDitLink: async (params, microservice) => {
        const response = await instance.get(apiBoardsUrl.getDitLink(microservice), {
            params
        });
        return response.data;
    },

    createDitLink: async (params, microservice) => {
        const response = await instance.post(apiBoardsUrl.createDitLink(microservice), params);
        return response.data;
    },

    getMessageTemplates: async (params) => {
        const response = await instance.get(apiBoardsUrl.getMessageTemplates(), { params });
        return response?.data;
    },

    createMessageTemplate: async (params) => {
        const formData = new FormData();
        const { file, text, ...props }  = params;

        if(file) {
            formData.append(
                'file',
                file
            );
        }
        Object.keys(props).map((key) => formData.append(key, props[key]));
        const response = file
            ? await instance.post(apiBoardsUrl.createMessageTemplate(), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            : await instance.post(apiBoardsUrl.createMessageTemplate(), params);
        return response?.data;
    },

    editMessageTemplate: async (id, data) => {
        const formData = new FormData();
        const { file, text, ...props }  = data;

        if(file) {
            formData.append(
                'file',
                file
            );
        }
        Object.keys(props).map((key) => formData.append(key, props[key]));
        const response = file
            ? await instance.put(apiBoardsUrl.editMessageTemplate(id), formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            : await instance.put(apiBoardsUrl.editMessageTemplate(id), data);
        return response?.data;
    },

    deleteMessageTemplate: async (id) => {
        const response = await instance.delete(apiBoardsUrl.deleteMessageTemplate(id));
        return response?.data;
    },

    createMessageTemplateBoards: async (params) => {
        const response = await instance.post(apiBoardsUrl.createMessageTemplateBoards(), params);
        return response?.data;
    },

    listTemplateImage: async (params) => {
        const response = await instance.get(apiBoardsUrl.listTemplateImage(), { params });
        return response.data;
    },

    createTemplateImage: async (data) => {
        const response = await instance.post(apiBoardsUrl.createTemplateImage(), data);
        return response.data;
    },

    editTemplateImage: async ({ id, data }) => {
        const response = await instance.put(apiBoardsUrl.editTemplateImage(id), data);
        return response.data;
    },

    deleteTemplateImage: async (id) => {
        const response = await instance.delete(apiBoardsUrl.deleteTemplateImage(id));
        return response?.data;
    },
    // шаблоны уведомлений
    getAlertTemplatesFields: async () => {
        const response = await instance.get(apiBoardsUrl.getAlertTemplatesFields());
        return response?.data;
    },
    
    getAlertTemplatesStatuses: async () => {
        const response = await instance.get(apiBoardsUrl.getAlertTemplatesStatuses());
        return response?.data;
    },
    
    getAlertTemplatesTypes: async () => {
        const response = await instance.get(apiBoardsUrl.getAlertTemplatesTypes());
        return response?.data;
    },
    
    getAlertTemplates: async (params) => {
        const response = await instance.get(apiBoardsUrl.getAlertTemplates(), { params });
        return response?.data;
    },
    
    createAlertTemplate: async (params) => {
        const response = await instance.post(apiBoardsUrl.createAlertTemplate(), params);
        return response?.data;
    },
    
    editAlertTemplate: async (id, data) => {
        const response = await instance.put(apiBoardsUrl.editAlertTemplate(id), data);
        return response?.data;
    },
    
    deleteAlertTemplate: async (id) => {
        const response = await instance.delete(apiBoardsUrl.deleteAlertTemplate(id));
        return response?.data;
    },

    getAlignHorizontal: async () => {
        const response = await instance.get(apiBoardsUrl.getAlignHorizontal());
        return response?.data;
    },

    getAlignVertical: async () => {
        const response = await instance.get(apiBoardsUrl.getAlignVertical());
        return response?.data;
    },

    loadShowcase: async () => {
        const response = await instance.post(apiBoardsUrl.loadShowcase());
        return response?.data;
    },

    getBoardCategories: async () => {
        const response = await instance.get(apiBoardsUrl.getBoardCategories());
        return response?.data;
    },

};

export default ApiAdminMethods;
