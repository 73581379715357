import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from '@mui/icons-material';

import { editDtp } from 'redux/Incidents/actions';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import ConfirmModal from 'components/common/ConfirmModal';

import { compareDtp as fnCompareDtp } from '../../../../helper';

const CompareDtpButton = ({
    index,
    sourceDtp,
    compareDtp,
    onRefresh,
}) => {

    const dispatch = useDispatch();

    const [isModal, setIsModal] = useState(false);

    return (
        <>
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.editIcon,
                        icon: <Link/>,
                        name: 'Объединить ДТП',
                        onClick: () => setIsModal(true),
                    }
                ]}
                noMarginLeft
                justButton
            />

            {isModal && (
                <ConfirmModal
                    open={isModal}
                    onClose={() => setIsModal(false)}
                    onSuccess={() => {
                        setIsModal(false);
                        const cDtp = fnCompareDtp(sourceDtp, compareDtp);
                        // выполнение
                        dispatch(editDtp(
                            cDtp.id,
                            removeEmptyFields(cDtp, false),
                            () => {
                                setIsModal(false);
                                onRefresh?.();
                            }
                        ));
                    }}
                    message={
                        index === 1
                            ? 'Копировать данные из связанного в основное?'
                            : 'Копировать данные из основного в связанное?'
                    }
                />
            )}
        </>
    );

};

export default CompareDtpButton;
