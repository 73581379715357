import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import mapHelper from 'helpers/mapHelper';
import { useDebounce } from 'helpers/hooks';
import { ContextMenuItem, GeoJsonNew } from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { usePopModalListener } from 'components/MapComponents/MapPopUp';
import * as actions from 'modules/DigitalTwin/redux/actions';
import * as selectors from 'modules/DigitalTwin/redux/selectors';
import MapLegends from 'components/common/Transport/MapLegends';

import { getEdgeColor, getFilter, getMapNode, getNodeColor } from './helper';
import config from './config.js';
import PopUpModal from './PopUpModal';
import CollectorForms from './CollectorForms';
import Legend from './Legend';

import './style.scss';

const Layer = (props) => {
    const { map, visibleLegend = false } = props;
    const dispatch = useDispatch();

    const fetchRef = useRef(null);

    const active = useSelector(selectors.active);
    const filters = useSelector(selectors.filters);
    const saved = useSelector(selectors.saved);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const popupListener = usePopModalListener();

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, ({ lat, lng }) => {
            dispatch(actions.setEditForm({}));
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => () => {
        setNodes([]);
        setEdges([]);
    }, []);

    const handleLoaded = (data) => {
        setEdges(createList(data?.edges || [], getEdgeColor, 'edge', ''));
        setNodes(createList(data?.nodes || [], getNodeColor, 'node', 'Узел'));
    };

    // грузим полигон
    useEffect(() => {
        fetchRef.current = () => {
            const filter = getFilters(filters, getFilter);

            const polygon = filters.area?.geo_json
                ? {
                    type: 'Feature',
                    geometry: filters.area.geo_json
                }
                : mapHelper.getGeometryPolygon(map);

            dispatch(actions.loadPolygon(
                {
                    geometry: polygon,
                    ...filter
                },
                handleLoaded
            ));
        };
        fetchRef.current?.();
    }, [filters]);

    const fetchPolygon = () => fetchRef.current?.();

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 2000);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            fetchPolygon();
        }
    }, [saved]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.clearActive());

            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, []);

    const handleZoomedToBounds = (item) => {
        const { geometry = null } = item || {};
        if (geometry) {
            const b = L.geoJSON(geometry).getBounds();
            map.fitBounds(b);

            popupListener.setModal({
                ...item,
                color: '#343333',
                type: 'edge',
                defaultName: '',
            });
        }
    };

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            handleZoomedToBounds(active);
        }
    }, [active]);

    const createList = (list, color, type, defaultName = '') => list.map(item => ({
        type: 'Feature',
        geometry: item.geometry,
        properties: {
            id: item.id,
            name: item.name || defaultName,
            color,
            type,
        },
        attribution: {
            slug: config.slug,
            color
        },
        style: {
            color
        }
    }));

    // меню маркера, линии, полигона при клике
    const renderContextMenu = (item, rcmProps) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProps}
                    value="Редактировать"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.loadEdgerById(item.id, (data) => dispatch(actions.setEditForm(data))));
                    }}
                />
                <ContextMenuItem
                    {...rcmProps}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    const showElements = useMemo(() => {
        return (
            <>
                <GeoJsonNew
                    {...props}
                    data={nodes}
                    toolTipTemplate={({ name = '' }) => <div>{name || ''}</div>}
                    icon={({ color }) => getMapNode(color)}
                    idPrefix={config.slug}
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5]
                    }}
                    onClick={(item) => popupListener.setModal(item)}
                />

                <GeoJsonNew
                    {...props}
                    data={edges}
                    toolTipTemplate={({ name = '' }) => <div>{name || ''}</div>}
                    icon={({ color }) => getMapNode(color)}
                    idPrefix={config.slug}
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5]
                    }}
                    onClick={(item) => popupListener.setModal(item)}
                    contextMenuTemplate={renderContextMenu}
                />
            </>
        );

    }, [edges, nodes]);

    return (
        <>
            {showElements}

            {/* popup pier */}
            {popupListener.isOpen && (
                <PopUpModal
                    isOpen={popupListener.isOpen}
                    item={popupListener.modal}
                    onClose={() => {
                        popupListener.clear();
                        dispatch(actions.clearActive());
                    }}
                />
            )}

            <CollectorForms />

            {/* легенда */}
            <MapLegends
                layer={config.slug}
                visibleRequired={visibleLegend}
            >
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
