import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { incidentsSelectors } from 'redux/Incidents';
import { loadReportCategory } from 'redux/Incidents/actions';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import DtpInfoModal from 'components/pages/Dictionaries/SituationalPlans/TransportIncidents/DtpInfoModal';

import Item from './Item';

const Info = ({
    data = [],
}) => {

    const reportCategoryFilter = useSelector(incidentsSelectors.reportCategoryFilter);
    const category = reportCategoryFilter.category || [];
    const reportCategory = useStoreProp(loadReportCategory, 'incidents', 'reportCategory');
    const categoryIds = category.length > 0 ? category.map(({ id }) => id) : Object.keys(reportCategory);

    const [showDtpId, setShowDtpId] = useState(null);

    return (
        <>
            {data.length > 0
                ? (
                    <TableContainer
                        component={Paper}
                        style={{
                            borderRadius: '0',
                            boxShadow: 'none',
                        }}
                    >
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell width={180}></TableCell>
                                    <TableCell align="center">{titles.DTP_COUNT}</TableCell>

                                    {categoryIds.map((catId) => (
                                        <TableCell align="center" key={catId}>{reportCategory[catId].name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, indexRow) => {
                                    const {
                                        month,
                                        counts,
                                    } = row;

                                    return (
                                        <Item
                                            key={`cluster-info-${indexRow}`}
                                            month={month}
                                            counts={counts}
                                            categoryIds={categoryIds}
                                            onShowDtp={(id) => setShowDtpId(id)}
                                        />
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
                : (
                    <div>{messages.INFO_IS_NOT_FOUND}</div>
                )
            }

            {/* информация по дтп */}
            {showDtpId && (
                <DtpInfoModal
                    title={titles.DTP}
                    isOpen={!!showDtpId}
                    setOpen={() => setShowDtpId(null)}
                    dtpId={showDtpId}
                />
            )}
        </>
    );
};

export default Info;
