import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';

import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { loadIncidentsCloseTypes } from 'modules/React/redux/actions';

const MetroSelectType = ({
    label = 'Тип закрытия',
    value,
    onChange,
    helperText,
    error,
    disabled,
    required,
    multiple
}) => {

    // todo подгрузка типов
    const dispatch = useDispatch();

    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    const selected = useMemo(() => {
        return multiple
            ? types.filter(i => value.includes(i.id)) || []
            : types.find(i => i.id === value) || {};
    }, [value, types, multiple]);

    const handleChange = (newValue) => {
        onChange(
            multiple
                ? newValue?.map(i => i.id)
                : newValue?.id
        );
    };

    useEffect(() => {
        dispatch(loadIncidentsCloseTypes(setTypes, setLoading));
    }, []);

    return (
        <>
            {loading && <LinearProgress />}

            <CommonAutocomplete
                selected={selected}
                error={error}
                helperText={helperText}
                multiple={multiple}
                options={types}
                label={label}
                required={required}
                disabled={disabled}
                renderLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                showAsSelect
                limitTags={1}
                onChange={handleChange}
                disableReset
                // loading={loading}
            />
        </>
    );
};

export default MetroSelectType;
