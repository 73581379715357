import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { isEqual } from 'lodash';

import messages from 'helpers/constants/messages';
import { usePrevious, useStoreFromSelectorListToObject, useStoreProp } from 'helpers/hooks';
import getFilters from 'components/MapComponents/helpers/getFilters';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import Loading from 'components/common/Loading';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import * as actions from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';

import { getFilter } from '../helper';
import config from '../config';

import FilterForm from './FilterForm';
import Item from './Item';

const SideBar = () => {
    const dispatch = useDispatch();
    const [parameters, setParameters] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const sidebar = useSelector(reactSelectors.incidentSidebar);
    const loadingSidebar = useSelector(reactSelectors.loadingIncidentSidebar);
    const threat_levels = useStoreProp(actions.loadThreatLevels, 'react', 'threatLevels');
    const filters = useSelector(reactSelectors.filters);
    const active = useSelector(reactSelectors.active);
    const needReloadIncidentSidebar = useSelector(reactSelectors.needReloadIncidentSidebar);

    const statuses = useStoreFromSelectorListToObject(
        actions.loadIncidentsStatuses,
        reactSelectors.incidentsStatuses
    );

    const prevFilters = usePrevious(filters);

    const activeId = active?.id ?? 0;

    const fetchList = useCallback(() => {
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadIncidentSidebar(
            parameters.page,
            parameters.limit,
            filter
        ));
    }, [filters, parameters, dispatch]);

    useEffect(() => {
        if (needReloadIncidentSidebar) {

            fetchList();
            dispatch(actions.reloadIncidentSidebar(false));
        }
    }, [needReloadIncidentSidebar, fetchList, dispatch]);

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = sidebar?.meta || {};
        if (
            values.top > 0.95
            && loadingSidebar === false
            && parameters.page < last_page
        ) {
            setParameters(old => ({
                ...old,
                page: old.page + 1,
            }));
        }
    };

    const resetParameters = () => {
        setParameters(old => ({
            ...old,
            page: 1,
        }));
    };

    useEffect(() => () => {
        dispatch(actions.clearIncidentSidebar());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        } else {
            fetchList();
        }
    }, [parameters, filters, prevFilters, fetchList]);

    const renderList = (dataList) => (
        dataList.map((item) => {
            const {
                id,
                status_id,
                threat_level_id,
            } = item;
            const { color, name }  = item.threat_level || threat_levels.data.find(el => el.id === threat_level_id);
            const status_text   = item.status_text || statuses.find(el => el.id === status_id);
            item.color = color;
            item.title = name;
            item.status_text = status_text;

            return <Item item={item} activeId={activeId} key={id} reloadList={fetchList} />;
        })
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={resetParameters}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                layer={config.slug}
                content={({ data, onChange }) => (
                    <FilterForm
                        data={data}
                        onChange={onChange}
                    />
                )}
            />

            {loadingSidebar && sidebar?.data?.length === 0
                ? <Loading className="absolute bottom fill" />
                : <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={sidebar}
                        />
                        {sidebar?.data?.length === 0
                            ? <div className="layers-sidebar__empty">
                                {messages.REQUEST_DATA_IS_NOT_FOUND}
                            </div>
                            : renderList(sidebar?.data || [])}
                    </div>
                    {loadingSidebar && <Loading className="center" />}
                </Scrollbars>
            }
        </div>
    );
};

export default SideBar;
