import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    LinearProgress,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { dorisControlSelectors } from 'redux/DorisControl';
import { clearGeocodeAddress } from 'redux/Dadata/actions';
import {
    createComplex,
    editComplex,
    loadComplexById,
    loadComplexMeasuring,
    loadComplexModelTypes,
    loadComplexTypeGrouping,
    loadComplexTypes,
    loadComplexUnitTypes,
} from 'redux/DorisControl/actions';
import { loadGovernmentContract } from 'redux/GovernmentContracts/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Companies from 'components/common/Autocomplete/Companies';
import Select1CNames from 'components/common/Autocomplete/DorisControl/Names1C';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import GovernmentContractList from 'components/common/Autocomplete/GovernmentContract';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import InfoBlock from 'components/common/InfoBlock';

import GovernmentContractModal from './GovernmentContractModal';

const BasicDataForm = ({
    isOpen,
    onClose,
    item = {},
    isNew = true,
    id = null,
    onAdded = () => {},
    onEdited = () => {},
    isButtonToGk = false,
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingComplexById = useSelector(dorisControlSelectors.loadingComplexById);

    const types = useStoreProp(loadComplexTypes, 'dorisControl', 'doris_complex_types');
    const unit_types = useStoreProp(loadComplexUnitTypes, 'dorisControl', 'doris_complex_unit_types');
    const model_types = useStoreProp(loadComplexModelTypes, 'dorisControl', 'doris_complex_model_types');
    const complex_type_grouping = useStoreProp(loadComplexTypeGrouping, 'dorisControl', 'complex_type_grouping');
    const complex_measuring = useStoreProp(loadComplexMeasuring, 'dorisControl', 'complex_measuring');

    const [dependence, setDependence] = useState(false);
    const [openGovernmentContract, setOpenGovernmentContract] = useState(false);
    const dependenceHandler = (event) => {
        setDependence(event.target.checked);
    };

    const getInitialState = useCallback((fields) => ({
        name: fields?.name || '',
        name_1c_id: fields?.name_1c_id || '',
        serial_number: fields?.serial_number || '',
        inventory_number: fields?.inventory_number || '',
        species_id: fields?.species_id || '',
        type_id: fields?.type_id || '',
        model_id: fields?.model_id || '',
        ip_route: fields?.ip_route || '',
        mask: fields?.mask || '',
        lat: fields?.lat || '',
        lon: fields?.lon || '',
        vgk: Boolean(fields?.vgk),
        address: fields?.address || {},
        address_text: fields?.address_text || '',
        is_locality: fields?.is_locality || false,
        comment: fields?.comment || '',
        _comment: '',
        is_lifetime: fields?.id ? true : fields?.is_lifetime,
        organization: fields?.organization || null,
        organization_id: fields?.organization_id || null,
        speed_limit: fields?.speed_limit || 0,
        type_grouping: fields?.type_grouping || null,
        measuring: fields?.measuring || null,
        duplo_export: fields?.duplo_export || [],
        doris_export: fields?.doris_export || [],
        contract_id: fields?.contract_id || '',
        contract: fields.contract || {},
        contract_number: fields?.contract_number || '',
    }), []);

    const [data, setData] = useState(getInitialState(item));

    useEffect(() => {
        // догрузка данных по комплексу
        if (item.id) {
            dispatch(loadComplexById(item.id, (complexById) => {
                const newFields = getInitialState({
                    ...item,
                    ...complexById,
                });

                // если в комлпексе нет данных по ГК
                if (newFields?.contract_id && Object.keys(newFields?.contract || {}).length === 0) {
                    dispatch(loadGovernmentContract({
                        params: {
                            ids: [newFields.contract_id]
                        },
                    }, (contract) => {
                        setData({
                            ...newFields,
                            contract: contract[0] || {}
                        });
                    }));
                } else {
                    setData(newFields);
                }
            }));
        }
    }, []);

    const onChange = (name, value) => {
        validation.deleteKey(name);

        if (name === 'type_id') {
            return setData({
                ...data,
                [name]: value,
                model_id: '',
            });
        }
        if (name === 'is_lifetime') {
            return setData({
                ...data,
                [name]: value === 'true',
            });
        }
        if (name === 'name') {
            return setData({
                ...data,
                [name]: value.name || '',
                name_1c_id: value.id || ''
            });
        }
        setData({
            ...data,
            [name]: value
        });
    };

    const onChangeCoordinates = (params) => {
        setData({
            ...data,
            ...params
        });
        validation.deleteKeys(Object.keys(params));
    };

    const onChangeGovernmentContract = (params) => {
        setData({
            ...data,
            contract: params || '',
            contract_id: params?.id || null
        });
    };


    const closeForm = () => {
        onClose(false);
        dispatch(clearGeocodeAddress());
    };

    const onAcceptModal = () => {
        const prepareData = {
            ...removeEmptyFields(data),
            duplo_export: data?.duplo_export,
            doris_export: data?.doris_export,
            vgk: Number(data.vgk)
        };

        isNew
            ? dispatch(createComplex(prepareData, () => { onClose(false); onAdded(); }))
            : dispatch(editComplex(id, prepareData, () => { onClose(false); onEdited(); }));
    };

    const title = `${isNew ? titles.CREATE : titles.EDIT}: Комплекс ${data?.name || ''}`;

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            onClose={closeForm}
            noPadding
            maxWidthProp={'md'}
            buttons={
                <>
                    {!isNew && (
                        <FormInfoWrapper
                            helperText={
                                validation.get('_comment')
                                || ((!data._comment || data._comment?.length < 6)
                                && 'Введите не менее 6 символов')
                            }
                            error={
                                !data._comment
                                || data._comment?.length < 6
                                || validation.isKey('_comment')
                            }
                            style={{ margin: 14, width: '48%' }}
                        >
                            <TextField
                                required
                                label={'Причина редактирования'}
                                variant="outlined"
                                size="small"
                                value={data?._comment || ''}
                                name={'_comment'}
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                type="text"
                                error={
                                    !data._comment
                                    || data._comment?.length < 6
                                    || validation.isKey('_comment')
                                }

                                InputProps={{
                                    style: { background: 'white' }
                                }}
                            />
                        </FormInfoWrapper>
                    )}
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: closeForm
                            },
                            {
                                ...buttonsTypes.save,
                                onClick: onAcceptModal,
                            }
                        ]}
                    />
                </>

            }
        >
            <form className="modal__form">
                {loadingComplexById && <LinearProgress style={{ marginBottom: '1rem' }} />}

                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                        <FormInfoWrapper
                            helperText={validation.get('name') || messages.DECLARED_BY_MANUFACTURER}
                            error={!data?.name || validation.isKey('name')}
                        >
                            <FormControl required className="block" variant="outlined" fullWidth>
                                <Select1CNames
                                    selected={data.name}
                                    onChange={(value) => onChange('name', value)}
                                    label="Наименование"
                                    error={!data?.name || validation.isKey('name')}
                                    required
                                    // editable
                                />
                            </FormControl>
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('serial_number')}
                            error={!data.serial_number || validation.isKey('serial_number')}
                        >
                            <TextField
                                label="Серийный номер"
                                className="block"
                                fullWidth
                                size="small"
                                value={data.serial_number || ''}
                                variant="outlined"
                                name="serial_number"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                required
                                error={!data.serial_number || validation.isKey('serial_number')}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('inventory_number')}
                            error={validation.isKey('inventory_number')}
                        >
                            <TextField
                                label="Инвентарный номер"
                                className="block"
                                fullWidth
                                size="small"
                                value={data.inventory_number || ''}
                                variant="outlined"
                                name="inventory_number"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('contract_number')}
                            error={validation.isKey('contract_number')}
                        >
                            <TextField
                                label="Номер комплекса по ГК"
                                className="block"
                                fullWidth
                                size="small"
                                value={data.contract_number || ''}
                                variant="outlined"
                                name="contract_number"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('species_id')}
                            error={validation.isKey('species_id')}
                        >
                            <FormControl fullWidth className="block" size="small" required variant="outlined">
                                <InputLabel
                                    style={ !data.species_id ? { color: '#ff0000' } : { color: '#000000' } }
                                >
                                    Вид комплекса
                                </InputLabel>
                                <Select
                                    value={data.species_id || ''}
                                    required={true}
                                    label="Вид комплекса"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    name="species_id"
                                    error={!data.species_id || validation.isKey('species_id')}
                                >
                                    {Object.keys(unit_types)?.length
                                        ? Object.keys(unit_types)?.map((key) =>
                                            <MenuItem value={key} key={key}>{unit_types[key]}</MenuItem>
                                        )
                                        : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('type_id')}
                            error={validation.isKey('type_id')}
                        >
                            <FormControl className="block" size="small" required variant="outlined" fullWidth>
                                <InputLabel
                                    style={ !data.type_id ? { color: '#ff0000' } : { color: '#000000' } }
                                >
                                    Тип комплекса
                                </InputLabel>
                                <Select
                                    value={data.type_id || ''}
                                    required={true}
                                    label="Тип комплекса"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    name="type_id"
                                    error={!data.type_id || validation.isKey('type_id')}
                                >
                                    {types?.length > 0
                                        ? types.map(type => (
                                            <MenuItem key={type.id} value={type.id}>
                                                {type.name}
                                            </MenuItem>
                                        ))
                                        : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem> }
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('model_id')}
                            error={validation.isKey('model_id')}
                        >
                            <FormControl className="block" size="small" required variant="outlined" fullWidth>
                                <InputLabel
                                    style={ data.type_id && !data.model_id
                                        ? { color: '#ff0000' }
                                        : !data.type_id
                                            ? { color: '#b0a5a5' }
                                            : { color: '#000000' } }
                                >
                                    Модель комплекса
                                </InputLabel>
                                <Select
                                    value={data.model_id || ''}
                                    required={true}
                                    label="Модель комплекса"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    name="model_id"
                                    disabled={!data.type_id }
                                    error={!data.model_id || validation.isKey('model_id')}
                                >
                                    {model_types?.length > 0
                                        ? Object.entries(model_types)
                                            .filter(el => el[1].type === data.type_id)
                                            .map((elem) => <MenuItem
                                                value={elem[1].id}
                                                key={elem[1].description}
                                            >
                                                {elem[1].description}
                                            </MenuItem>
                                            )
                                        : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('type_grouping')}
                            error={validation.isKey('type_grouping')}
                        >
                            <FormControl className="block" size="small" required variant="outlined" fullWidth>
                                <InputLabel
                                    style={ !data.type_grouping ? { color: '#ff0000' } : { color: '#000000' } }
                                >
                                    Тип группировки Комплекса
                                </InputLabel>
                                <Select
                                    value={data.type_grouping || ''}
                                    required={true}
                                    label="Тип группировки Комплекса"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    name="type_grouping"
                                    error={!data.type_grouping || validation.isKey('type_grouping')}
                                >
                                    {Object.keys(complex_type_grouping)?.length
                                        ? Object.keys(complex_type_grouping)?.map((key) =>
                                            <MenuItem value={key} key={key}>{complex_type_grouping[key]}</MenuItem>
                                        )
                                        : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('measuring')}
                            error={validation.isKey('measuring')}
                        >
                            <FormControl className="block" size="small" required variant="outlined" fullWidth>
                                <InputLabel
                                    style={ !data.measuring ? { color: '#ff0000' } : { color: '#000000' } }
                                >Принцип измерения параметров
                                </InputLabel>
                                <Select
                                    value={data.measuring || ''}
                                    required={true}
                                    label="Принцип измерения параметров"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    name="measuring"
                                    error={!data.measuring || validation.isKey('measuring')}
                                >
                                    {Object.keys(complex_measuring)?.length
                                        ? Object.keys(complex_measuring)?.map((key) =>
                                            <MenuItem value={key} key={key}>{complex_measuring[key]}</MenuItem>
                                        )
                                        : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>

                        <FormControlLabel
                            style={{ pointerEvents: 'none' }}
                            control={
                                <Checkbox
                                    checked={data.vgk}
                                    onChange={(e) => onChange(e.target.name, e.target.checked)}
                                    name="vgk"
                                    style={{ pointerEvents: 'auto' }}
                                />
                            }
                            label="Весогабаритный контроль"
                        />

                        {/*{complexTransferRules.length > 0*/}
                        {/*    && complexTransferRules.map((el) => */}
                        {/*        <RenderTransferFields*/}
                        {/*            key={el.field}*/}
                        {/*            element={el} */}
                        {/*            currentData={data?.data}*/}
                        {/*            handleChange={onChangeTransferTypes}*/}
                        {/*        />)*/}
                        {/*}*/}
                        <FormControl className="block">
                            <h3>Срок эксплуатации комплекса</h3>
                            <RadioGroup
                                onChange={(e) => onChange('is_lifetime', e.target.value)}
                            >
                                <FormControlLabel
                                    checked={!!data.is_lifetime}
                                    value={true}
                                    control={<Radio color="primary" />}
                                    label="Бессрочно"
                                />
                                <FormControlLabel
                                    checked={!data.is_lifetime}
                                    value={false}
                                    control={<Radio color="primary" />}
                                    label="На время действия контракта"
                                />
                            </RadioGroup>
                        </FormControl>

                        <Companies
                            label="Балансодержатель (владелец) комплекса"
                            selected={data.organization}
                            onChange={(value) => {
                                setData({
                                    ...data,
                                    organization: value,
                                    organization_id: value.id,
                                });
                                validation.deleteKey('organization_id');
                            }}
                            filter={{ withDeleted: 1 }}
                        />

                    </Grid>

                    <Grid item xs={6}>
                        <InfoBlock label={titles.LOCATION} className="block">
                            <FormControlLabel
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={data.is_locality}
                                        onChange={(e) => onChange(e.target.name, e.target.checked)}
                                        name="is_locality"
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                    />
                                }
                                label="Населенный пункт"
                            />

                            <FormControlLabel
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        checked={dependence}
                                        onChange={dependenceHandler}
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                    />
                                }
                                label="Адрес зависит от координат"
                            />

                            <FormControl className="block" variant="outlined">
                                <LatLonCoordinates
                                    {...data}
                                    onChange={onChangeCoordinates}
                                    required
                                />
                            </FormControl>

                            <FormControl className="block" variant="outlined">
                                <LoadAddressByCoords
                                    {...data}
                                    onChange={onChangeCoordinates}
                                    isLoadOnLatLon={dependence}
                                    isLatLonColumnView={true} // такого поля нет
                                >
                                    <AddressList required />
                                </LoadAddressByCoords>
                            </FormControl>

                            <FormControl className="block" variant="outlined">
                                <MapDragMarker
                                    {...data}
                                    onChange={onChangeCoordinates}
                                    required
                                />
                            </FormControl>
                        </InfoBlock>

                        <div className="block" style={{ marginTop: '1rem' }}>
                            <FormInfoWrapper
                                helperText={validation.get('comment') || 'Введите не менее 6 символов'}
                                error={validation.isKey('comment')}
                            >
                                <TextField
                                    label={'Дополнительная информация'}
                                    value={data.comment || ''}
                                    name="comment"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                />
                            </FormInfoWrapper>
                        </div>

                        <div className="block">
                            <GovernmentContractList
                                selected={data.contract}
                                onChange={onChangeGovernmentContract}
                                confirmable
                            />
                        </div>

                        {(isButtonToGk && data?.contract?.id) && (
                            <Button onClick={() => setOpenGovernmentContract(true)}>
                                Информация по ГК
                            </Button>
                        )}

                        {openGovernmentContract && (
                            <GovernmentContractModal
                                open={openGovernmentContract}
                                onClose={() => setOpenGovernmentContract(false)}
                                onEdited={onEdited}
                                contractId={data?.contract?.id}
                            />
                        )}

                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};

export default BasicDataForm;
