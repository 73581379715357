import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { List } from '@mui/material';

import * as actions from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import { usePrevious, useStoreFromSelector } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import useForm from 'components/MapComponents/Layers/TrafficFlow/Form/useForm';

import Item from './Item';
import Filter from './Filter';


const TrafficFlow = () => {
    const dispatch = useDispatch();

    const list = useSelector(trafficFlowSelectors.list);
    const listLoading = useSelector(trafficFlowSelectors.listLoading);
    const listFilter = useSelector(trafficFlowSelectors.listFilter);

    const statuses = useStoreFromSelector(actions.loadStatus, trafficFlowSelectors.statuses);
    const statusesName = useMemo(() => statuses.reduce((r, i) => ({ ...r, [i.id]: i.name }), {}), [statuses]);
    const types = useStoreFromSelector(actions.loadTypes, trafficFlowSelectors.types);

    const listFilterPrev = usePrevious(listFilter);
    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const onSaved = () => {
        // обновление
        setParams(old => ({
            ...old,
        }));
    };

    const formProvider = useForm(onSaved);

    useEffect(() => {
        if (!isEqual(listFilter, listFilterPrev) && params.page !== 1) {
            setParams(old => ({
                ...old,
                page: 1,
            }));
        } else {
            const filter = removeEmptyFields(listFilter);
            dispatch(actions.loadList(params.page, params.limit, filter));
        }
    },[params, listFilter, dispatch]);

    const renderContent = () => (
        <>
            {list.data?.length > 0
                ? <List className="list">
                    {list?.data?.map((el) => (
                        <Item
                            key={el.id}
                            el={el}
                            statuses={statusesName}
                            types={types}
                            onEdit={() => {
                                formProvider.showEditForm(el);
                            }}
                            onDelete={() => {
                                formProvider.showConfirmDelete(el.id);
                            }}
                        />
                    ))}
                </List>
                : (!listLoading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>
    );

    return (
        <>
            <PageLayout
                header="Посты учета интенсивности движения (ПУИДы)"
                filters={
                    <Filter
                        statuses={statuses}
                        types={types}
                        onFilter={() => {}}
                        onReset={() => {}}
                    />
                }
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => formProvider.showAddForm()
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: list.meta?.total
                }}
                paginationProps={{
                    loadList: (page) => setParams(old => ({ ...old, page })),
                    list: list.meta,
                    limit: params.limit,
                    setLimit: (limit) => setParams(old => ({ ...old, limit })),
                }}
                loading={listLoading}
                content={renderContent}
                additionalButtons={null}
            />

            {formProvider.renderComponent()}
        </>
    );
};

export default TrafficFlow;
