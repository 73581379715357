export const dtp = (state) => state.dtp;

export const typesAll = (state) => state.typesAll;

export const dtp_types = (state) => state.dtp_types;
export const dtpTypesData = (state) => dtp_types(state).data;
export const dtpTypesMeta = (state) => dtp_types(state).meta;
export const list = (state) => dtp(state).list;
export const listData = (state) => list(state).data;
export const listMeta = (state) => list(state).meta;
export const loading = (state) => dtp(state).loading;
export const loadingDtpTypes = (state) => dtp(state).loadingDtpTypes;
// export const loadingDtpFile = (state) => dtp(state).loadingDtpFile;

export const dtpStreets = (state) => dtp(state).dtpStreets;
export const dtpStreetsLoading = (state) => dtp(state).dtpStreetsLoading;