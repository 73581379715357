import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

import { createIncidentsFactors, editIncidentsFactors } from 'modules/React/redux/actions';
import reactTitles from 'modules/React/utils/helpers/constants';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import type { FactorType } from 'modules/React/reactTypes';

interface ModalFormProps {
    isOpen: boolean;
    onClose: (isOpen: boolean) => void;
    isNew?: boolean;
    item?: FactorType
    reloadList: () => void;
}

const ModalForm = ({ isOpen, onClose, isNew = false, item, reloadList }: ModalFormProps) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }: { validation: { success: boolean } }) => validation);

    const [data, setData] = useState({
        name: item?.name || '',
        is_geo: item?.is_geo || false
    });

    const closeForm = () => onClose(false);

    const onAcceptModal = () => {
        if (isNew) {
            dispatch(createIncidentsFactors(data, reloadList));
        } else {
            dispatch(editIncidentsFactors(item?.id || '', data, reloadList));
        }
    };

    const onChange = (name: string, value: string | boolean) => {
        setData({
            ...data,
            [name]: value
        });
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose(false);
            validation.clear();
        }
    }, [requestStatus.success, onClose, validation]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose(false)}
            noPadding={true}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm,
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: data.name === '' || validation.isValidation(),
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <FormInfoWrapper
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    >
                        <TextField
                            label={titles.NAME}
                            size="small"
                            value={data.name}
                            variant="outlined"
                            name="name"
                            type="text"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.name, e.target.value)}
                            error={!data.name || validation.isKey('name')}
                            required
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={!data.is_geo || validation.isKey('is_geo')}
                        helperText={validation.get('is_geo')}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data?.is_geo}
                                    onClick={(e) =>
                                        onChange('is_geo', (e.target as HTMLInputElement).checked)
                                    }
                                    size="small"
                                />
                            }
                            label={reactTitles.GEOZONE}
                        />
                    </FormInfoWrapper>
                </div>
            </form>
        </Modal>
    );
};

export default ModalForm;
