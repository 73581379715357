import { Checkbox, FormControlLabel, Typography } from '@mui/material';

import { useStoreFromSelector } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import SimpleSelect from 'components/common/SimpleSelect';
import DateRange from 'components/common/Dates/DateRange';
import { loadCongestionTypes } from 'modules/HeatMapCongestions/redux/actions';
import { heatMapCongestionsSelectors } from 'modules/HeatMapCongestions';

const Filter = ({ data, onChange, className }) => {
    const types = useStoreFromSelector(loadCongestionTypes, heatMapCongestionsSelectors.congestionTypes);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="row">
                <DateRange
                    handleDate={(newDate) => onChange({ start_date: newDate })}
                    handleEndDate={(newDate) => onChange({ end_date: newDate })}
                    valueStartDate={data.start_date || null}
                    valueEndDate={data.end_date || null}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                    required
                />
            </div>

            <SimpleSelect
                label={titles.TYPE}
                selected={data?.type_id || null}
                onChange={(value) => onChange({ type_id: value })}
                options={types}
                renderLabel={(options) => options?.name || ''}
                className={className}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={data?.with_regular?.id || false}
                        onChange={(event) =>
                            onChange({
                                with_regular: {
                                    id: event.target.checked || false,
                                    name: 'Показать регулярные'
                                }
                            })
                        }
                        color="primary"
                    />
                }
                label={
                    <Typography variant="body2">
                        Показать регулярные
                    </Typography>
                }
            />
        </form>
    );
};

export default Filter;
