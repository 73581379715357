import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import * as actions from 'redux/TrafficLights/actions';

import type { GreenSteetTypes, Item } from './types';

const SelectGreenStreet = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = titles.GREEN_STREET,
    error = false,
    helperText = '',
    sortedList = null,
    limitTags = 1,
    test_id_prefix = ''
}: GreenSteetTypes<Item>) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query: name } = params;
        dispatch(actions.loadGreenStreets({ page, limit, name }));
    };

    const handleClose = () => setShowList(false);

    const handleAccept = (data: any) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : {};
        // @ts-ignore
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                test_id_prefix={test_id_prefix}
                isOpen={showList}
            />
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={!sortedList && getList}
                    sortedList={sortedList}
                    storeName="trafficLights"
                    storeNameProps="greenStreets"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    small
                    title={titles.SELECT_GREEN_STREET}
                    test_id_prefix={test_id_prefix}
                />
            }
        </>
    );
};

export default SelectGreenStreet;
