import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import { fullDateTimeWithoutSeconds, fullDateTimeWithTimeZone } from 'helpers/date.config';
import { LSContainer } from 'components/common/List';
import Loading from 'components/common/Loading';
import PageLayout from 'components/layout/PageLayout';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';
import { loadIncidents, loadIncidentsStatuses } from 'modules/React/redux/actions';
import * as reactSelectors from 'modules/React/redux/selectors';

import Filter from './Filter';
import Item from './Item';

const IncidentsMetro = ({
    tab,
    hideTitle
}) => {
    const dispatch = useDispatch();

    const statusesObjects = useStoreFromSelectorListToObject(loadIncidentsStatuses,reactSelectors.incidentsStatuses);

    const data = useSelector(reactSelectors.incidentsList);
    const meta = useSelector(reactSelectors.incidentsMeta);
    const loading = useSelector(reactSelectors.loadingIncidents);

    const [params, setParams] = useState({ filter: {}, page: 1, limit: 25 });
    const [isLoaded, setIsLoaded] = useState(false);

    const convertFilter = (filter) => removeEmptyFields({
        ...filter,
        // todo потом удалить лишнее
        status: filter.status?.map((el) => el.id),
        status_id_list: filter.status?.map((el) => el.id),

        incident_types: filter?.types?.map((el) => el.id),
        type_id_list: filter?.types?.map((el) => el.id),

        start_date: fullDateTimeWithTimeZone(filter?.start_date),
        end_date: fullDateTimeWithTimeZone(filter?.end_date),
    });

    useEffect(() => {
        const { filter, page, limit } = params;
        dispatch(loadIncidents(
            page,
            limit,
            {
                ...convertFilter(filter),
                category_id: 2
            }
        ));
    }, [params]);

    const handleChangeFilter = (formValues) => {
        setParams(old => ({ ...old, page: 1, filter: formValues }));
        setIsLoaded(true);
    };

    const renderContent = () => (
        <>
            {loading && <Loading circular/>}

            {data.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: titles.DATE_TIME_CREATED, width: '20%' },
                            { title: titles.STATUS, width: '15%' },
                            { title: titles.TYPE, width: '15%' },
                            { title: titles.DESCRIPTION, width: '25%' },
                            { title: 'Решение', width: '25%' }
                        ]}
                    >
                        {data?.map(item => (
                            <Item
                                key={item.id}
                                item={item}
                                statusesObjects={statusesObjects}
                            />
                        ))}
                    </LSContainer>
                )
                : (isLoaded && !loading)
                    ? (
                        <div
                            style={{
                                padding: '.5rem',
                                textAlign: 'center'
                            }}
                        >
                            {messages.DATA_IS_NOT_FOUND}
                        </div>
                    )
                    : null
            }
        </>
    );

    const titleDate = useMemo(() => {
        if (params?.filter?.start_date && params?.filter?.end_date) {
            return `: ${fullDateTimeWithoutSeconds(params.filter.start_date)} - ${fullDateTimeWithoutSeconds(params.filter.end_date)}`;
        }
        return '';
    }, [params.filter]);

    return (
        <PageLayout
            header={hideTitle ? ' ' : `Отчет об инцидентах метрополитена${titleDate}`}
            tab={tab}
            filters={
                <Filter
                    tab={tab}
                    onChange={handleChangeFilter}
                    statuses={statusesObjects.list}
                />
            }
            informPanelProps={{
                buttons: (
                    <PageReportModal>
                        <PageReportBlock filter={convertFilter(params.filter || {})}/>
                    </PageReportModal>
                )
            }}
            content={renderContent}
            customStyles={{
                overflow: 'auto',
                padding: 0,
                background: 'transparent'
            }}
            paginationProps={{
                loadList: (page) => setParams(old => ({ ...old, page })),
                list: meta,
                limit: params.limit,
                setLimit: (limit) => setParams(old => ({ ...old, limit }))
            }}
        />
    );
};

export default IncidentsMetro;
