import * as types from './types';

const initialState = {
    types: {},

    nodes: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingNodes: false,

    filters: {},
    active: {},

    saved: false,

    deleteForm: false,

    editForm: false,

    edges: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingEdges: false,

    edgeTypes: [],
    loadingEdgeTypes: false,

    edgeClasses: [],
    loadingEdgeClasses: false,

    loadingButton: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case types.LOADED_TYPES:
            return {
                ...state,
                types: payload,
            };

        case types.LOADING_NODES:
            return {
                ...state,
                loadingNodes: payload
            };

        case types.LOADED_NODES:
            const nodes = {
                data: payload.items,
                meta: payload,
            };

            delete nodes.meta.items;

            return {
                ...state,
                nodes,
            };

        case types.SET_FILTERS:
            return {
                ...state,
                filters: payload,
            };

        case types.SET_ACTIVE:
            return {
                ...state,
                active: payload,
            };

        case types.SET_SAVED:
            return {
                ...state,
                saved: payload,
            };

        case types.SET_DELETE_FORM:
            return {
                ...state,
                deleteForm: payload
            };

        case types.SET_EDIT_FORM:
            return {
                ...state,
                editForm: payload
            };

        case types.LOADING_EDGES:
            return {
                ...state,
                loadingEdges: payload
            };

        case types.LOADED_EDGES:

            const edges = {
                data: payload.items,
                meta: payload,
            };

            delete edges.meta.items;

            return {
                ...state,
                edges
            };

        case types.LOADING_EDGE_TYPES:
            return {
                ...state,
                loadingEdgeTypes: payload
            };

        case types.LOADED_EDGE_TYPES:
            return {
                ...state,
                edgeTypes: payload
            };

        case types.LOADING_EDGE_CLASSES:
            return {
                ...state,
                loadingEdgeClasses: payload
            };

        case types.LOADED_EDGE_CLASSES:
            return {
                ...state,
                edgeClasses: payload
            };

        case types.LOADING_BUTTON:
            return {
                ...state,
                loadingButton: payload
            };

        default:
            return state;
    }
}
