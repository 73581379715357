const Legend = ({
    data = [],
}) => {
    return (
        <div className="row" style={{ justifyContent: 'center' }}>
            {data?.map(({ color, title }, index) => (
                <div
                    key={index}
                    style={{
                        marginRight: '.5rem',
                    }}
                >
                    <i
                        style={{
                            backgroundColor: color,
                            width: '15px',
                            height: '15px',
                            display: 'inline-block',
                            borderRadius: '50%',
                            margin: '0 7px -3px 0',
                        }}
                    />
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {title}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default Legend;
