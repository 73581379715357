import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useEffect, useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

import { parseParamsFromUrl, stringifyParamsForUrl } from 'helpers/doris.control/helper';

import PassportTabs from './PassportTabs';

import type { Tab } from './types';


interface PassportTabsGridProps {
    tabs: Tab[];
    selectedItem: boolean;
    renderList: () => ReactNode;
    queryKey?: string;
    urlParams: Record<string, number | null>;
    setUrlParams: Dispatch<SetStateAction<{[key: string]: number | null; }>>;
    setParams: Dispatch<SetStateAction<{page: number; limit: number;}>>;
    initialTab?: string;
    resetInitialTab?: Dispatch<SetStateAction<string>>;
}
  
const PassportTabsGrid = ({
    tabs,
    selectedItem = false,
    renderList,
    queryKey = 'id',
    urlParams,
    setUrlParams,
    setParams,
    initialTab,
    resetInitialTab
}: PassportTabsGridProps) => {
    const scrollRef = useRef<Scrollbars>(null);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        //При первом загрузке считываем параметры из URL, устанавливаем их для params и urlParams
        const { page, limit, [queryKey]: query } = parseParamsFromUrl(location.search);

        setUrlParams({ 
            page: Number(page) || 1, 
            limit: Number(limit)|| Number(localStorage.getItem('limit')) || 25,
            [queryKey]: Number(query) || null
        });
        
        setParams(prev => ({ 
            ...prev,
            page: Number(page) || prev.page,
            limit: Number(limit) || prev.limit
        }));
    }, []);

    useEffect(() => {
        history.replace({ ...location, search: stringifyParamsForUrl(urlParams) });
    }, [urlParams]);
  
    return (
        <Grid container height="100%">
            <Grid item xs={selectedItem ? 3 : 12}>
                {selectedItem
                    ? <Scrollbars ref={scrollRef}>
                        {renderList()}
                    </Scrollbars>
                    : renderList()
                }
            </Grid>
            {selectedItem && (
                <Grid item xs={9}>
                    <PassportTabs tabs={tabs} initialTab={initialTab} resetInitialTab={resetInitialTab}/>
                </Grid>
            )}
        </Grid>
    );
};
  
export default PassportTabsGrid;