import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadIncidentEvents } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';
import { LSContainer, LSContentColumn, LSContentItem } from 'components/common/List';
import Loading from 'components/common/Loading';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import titles from 'helpers/constants/titles';

import type { EventItem, IncidentItem } from '../../types';

const Events = ({ item }: { item: IncidentItem | null }) => {
    const dispatch = useDispatch();

    const events: EventItem[] = useSelector(reactSelectors.incidentEvents);
    const loading: boolean = useSelector(reactSelectors.loadingIncidentEvents);

    useEffect(() => {
        if (item?.id) {
            dispatch(loadIncidentEvents(item?.id));
        }
    }, [dispatch, item?.id]);

    return (
        <>
            {loading && <Loading linear/>}
            {events.length > 0
                ? <LSContainer
                    headers={[
                        { title: titles.NAME, width: '25%' },
                        { title: titles.DATE_TIME_CREATED, width: '20%' },
                        { title: titles.UNIT, width: '15%' },
                    ]}
                >
                    {events.map(({ phenomenon }) => (
                        <LSContentItem key={phenomenon.id}>
                            <LSContentColumn>
                                {phenomenon.name || messages.NO_VALUE}
                            </LSContentColumn>
                            <LSContentColumn>
                                {phenomenon.created_at ? fullDateTime(phenomenon.created_at) : messages.NO_VALUE}
                            </LSContentColumn>
                            <LSContentColumn>
                                {phenomenon.unit || messages.NO_VALUE}
                            </LSContentColumn>
                        </LSContentItem>
                    ))}
                </LSContainer>
                : !loading && <div>{messages.DATA_IS_NOT_FOUND}</div>}
        </>
    );
};

export default Events;
