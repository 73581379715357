import * as types from './types';

const initialState = {
    list: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loading: false,
    dtp_types: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingDtpTypes: false,
    saved: false,
    loadingStatuses: false,
    dtpStreets: [],
    dtpStreetsLoading: false,
    dtpById: {},
    typesAll: [],
    loadingDtpTypes: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case types.LOADED_DTP_TYPES_ALL: {
            return {
                ...state,
                typesAll: payload
            };
        }

        case types.LOADING_DTP_TYPES:
            return {
                ...state,
                loadingDtpTypes: payload,
            };

        case types.LOADED_DTP_TYPES:
            return {
                ...state,
                dtp_types: payload,
            };
        
        case types.LOADED_DTP_LIST: {
            const { data, replaceStore } = payload;
            return {
                ...state,
                list: replaceStore
                    ? data
                    : {
                        ...state.list,
                        ...data,
                        data: [
                            ...state.list.data,
                            ...data.data
                        ],
                        meta: {
                            ...state.list.meta,
                            ...data.meta
                        },
                    }
            };
        };

        case types.LOADING: {
            return {
                ...state,
                loading: payload,
            };
        };

        case types.SAVE_DTP: {
            return {
                ...state,
                saved: payload,
            };
        };

        case types.LOADING_EDIT_STATUS: {
            return {
                ...state,
                loadingStatuses: payload,
            };
        };

        case types.LOADED_DTP_STREETS:{
            return {
                ...state,
                dtpStreets: payload.data,
            };
        };

        case types.LOADING_DTP_STREETS:{
            return {
                ...state,
                dtpStreetsLoading: payload,
            };
        };

        case types.LOADED_DTP_BY_ID: {
            return {
                ...state,
                dtpById: payload,
            };
        }

        default:
            return state;
    }
}
