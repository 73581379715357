import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import { config } from 'config';
import { useDebounce } from 'helpers/hooks';
import mapHelper from 'helpers/mapHelper';
import { heatMapCongestionsSelectors } from 'modules/HeatMapCongestions/index';
import { loadCongestionsHeatMap } from 'modules/HeatMapCongestions/redux/actions';
import { Cluster, GeoJsonNew, Map, MapControl } from 'components/MapComponents/leaflet';
import Loading from 'components/common/Loading';
import MapLegends from 'components/common/Transport/MapLegends';

import { geoClusterIcon } from './geoClusterIcon';
import './heatMapStyles.scss';
import Legend from './Legend';
import PopUp from './PopUp';

const HeatMap = ({ params }) => {
    const dispatch = useDispatch();

    const heatMapData = useSelector(heatMapCongestionsSelectors.congestionsHeatMap);
    const loading = useSelector(heatMapCongestionsSelectors.loadingCongestionsHeatMap);

    const [polygon, setPolygon] = useState(null);
    const [clusterItems, setClusterItems] = useState([]);

    const handleChangeMap = (map) => {
        const polygon = mapHelper.getGeometryPolygon(map?.type === 'zoomend' ? map.target : map);

        setPolygon(polygon);
    };

    const debounceFetchPolygon = useDebounce(handleChangeMap, 400);
    const handleFetchPolygon = (map) => debounceFetchPolygon(map);

    useEffect(() => {
        if (polygon) {
            dispatch(loadCongestionsHeatMap({ polygon, ...params }));
        }
    }, [dispatch, polygon, params]);

    const geometries = useMemo(() => {
        return (
            heatMapData?.map((item) => ({
                ...item,
                attribution: item.properties || {},
            })) || []
        );
    }, [heatMapData]);

    const getAttributionCluster = (cluster) => {
        const ids = [];

        if (cluster && cluster.getChildCount() > 0) {
            const markers = cluster.getAllChildMarkers();
            markers.forEach(marker => {
                const attribution = marker.getAttribution() || {};
                const dtpId = attribution?.uuid || null;
                if (dtpId && !ids.includes(dtpId)) {
                    ids.push(dtpId);
                }
            });
        }

        setClusterItems(ids);
    };

    return (
        <>
            {loading && heatMapData?.length === 0 && <Loading circular />}

            <Map
                center={config.get('mapCenter')}
                onInit={handleFetchPolygon}
                onZoom={handleFetchPolygon}
                onMoveEnd={handleFetchPolygon}
            >
                <MapControl>
                    <Cluster
                        iconCreateFunction={geoClusterIcon}
                        isClick
                        onClick={getAttributionCluster}
                        clusterOptions={{
                            chunkedLoading: true,
                            spiderfyOnMaxZoom: false,
                            zoomToBoundsOnClick: false,
                            singleMarkerMode: true,
                            showCoverageOnHover: false,
                            disableClusteringAtZoom: 21,
                        }}
                    >
                        <GeoJsonNew
                            data={geometries}
                            icon={() => L.divIcon({ className: 'hidden' })}
                            onClick={(attribution) => {
                                setClusterItems([attribution.uuid]);
                            }}
                        />
                    </Cluster>
                </MapControl>

                <MapLegends layer={config.slug} visibleRequired={true}>
                    <Legend />
                </MapLegends>
            </Map>

            {clusterItems?.length > 0 && (
                <PopUp
                    isOpen={clusterItems.length > 0}
                    ids={clusterItems}
                    onClose={() => setClusterItems([])}
                />
            )}
        </>
    );
};

export default HeatMap;
