import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Tooltip } from '@mui/material';

import { loadVehicleCategories } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { checkBase64 } from 'helpers/transport.types';
import { ActionMore, LSContainer, LSContentColumn, LSContentItem } from 'components/common/List';

import RouteItem from './RouteItem';

const RouteGroupItem = ({
    data,
    currentRoute,
    onClickRoute,
    isOpenGroup,
    onClickGroup,
    typeTransportation,
    reloadList,
    focus,
    test_id_prefix
}) => {
    const handleClickGroup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickGroup();
    };

    const handleClickRoute = (e, route) => {
        e.preventDefault();
        e.stopPropagation();
        onClickRoute(route, data);
    };

    const transportTypes = useStoreProp(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    const routeWithCheckPoints = useSelector(transportPassengerSelectors.routeWithCheckPoints);
    const loadingGroupsList = useSelector(transportPassengerSelectors.loadingGroupsList);

    const [groupRoutes, setGroupRoutes] = useState(data?.routes || []);

    const currentCategory = transportTypes.find(({ id }) => id === data?.category_id) || {};

    useEffect(() => {
        const routes = data?.routes?.map((el => {
            const item = routeWithCheckPoints[el.id];
            return item ?? el;
        }));
        setGroupRoutes(routes);
    }, [routeWithCheckPoints, data?.routes]);

    const imgUrl = checkBase64(currentCategory.image_with_work_order);

    const isLoading = loadingGroupsList.find(group => group.num === data.num && group.category_id === data.category_id);

    return (
        <>
            <LSContentItem onClick={handleClickGroup} isMobile={!!currentRoute}>
                <LSContentColumn>
                    <Tooltip title={currentCategory?.name || messages.NO_DATA}>
                        <Avatar sx={{ backgroundColor: currentCategory?.color }}>
                            <img
                                style={{ width: 30, height: 30, margin: 0 }}
                                src={imgUrl}
                                alt=""
                            />
                        </Avatar>
                    </Tooltip>
                </LSContentColumn>
                <LSContentColumn title={titles.NUM}>
                    {data?.num || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn title={titles.NAME}>
                    {data?.name|| messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <ActionMore
                        isOpen={isOpenGroup}
                        test_id_prefix={test_id_prefix}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpenGroup && <LSContainer
                headers={[
                    { title: '', width: '33px' },
                    { title: '', width: '60%' },
                    { title: ' ', isActions: true }
                ]}
                hideHeader
            >
                {groupRoutes?.map(item => (
                    <RouteItem
                        key={item?.id}
                        data={item}
                        selectedId={item?.id === currentRoute?.id}
                        currentRoute={currentRoute}
                        typeTransportation={typeTransportation}
                        onClickItem={(e) => handleClickRoute(e, item)}
                        reloadList={reloadList}
                        category={currentCategory}
                        loading={isLoading}
                        focus={focus}
                        test_id_prefix={test_id_prefix}
                    />
                ))}
            </LSContainer>
            }
        </>
    );
};

export default RouteGroupItem;
