import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { LinearProgress } from '@mui/material';

import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { loadIncidentsCloseReasons } from 'modules/React/redux/actions';

const SelectCloseReason = ({
    label = 'Причина закрытия',
    value,
    onChange,
    helperText = '',
    error = false,
    disabled = false,
    required = false,
    multiple = false,
}) => {
    const dispatch = useDispatch();

    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(loadIncidentsCloseReasons(setTypes, setLoading));
    }, []);

    const selected = useMemo(() => {
        return multiple
            ? types.filter(item => value?.includes?.(item.id))
            : types.find(item => item.id === value);
    }, [value, types]);

    const handleChange = (value) => {
        onChange(multiple ? value?.map(item => item.id) : value.id);
    };

    return (
        <>
            {loading && <LinearProgress />}

            <CommonAutocomplete
                selected={selected}
                error={error}
                helperText={helperText}
                multiple={multiple}
                options={types}
                label={label}
                required={required}
                disabled={disabled}
                renderLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                showAsSelect
                limitTags={1}
                onChange={handleChange}
                disableReset
                // loading={loading}
            />
        </>
    );
};

export default SelectCloseReason;
