import { useState } from 'react';
import { TextField } from '@mui/material';

import { useValidation } from 'helpers/hooks';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import type { OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

interface EditStatusModalProps {
    isOpen: boolean;
    onClose: () => void;
    item: OperationInScenario;
    onChangeItem: (item: OperationInScenario) => void
    disableIsDone: boolean;
}

const EditStatusModal = ({ 
    isOpen,
    onClose,
    item,
    onChangeItem,
    disableIsDone
}: EditStatusModalProps) => {
    const validation = useValidation();
    
    const [data, setData] = useState<OperationInScenario>(item);

    const handleChange = (name: string, value: any) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSave = () => {
        const prepareData = {
            ...data,
            is_done: !!data?.end_fact
        };

        onChangeItem(prepareData);
    };

    const validateIndex = data?.position && data.position - 1;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title="Выполнение операции"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                        disabled: data.end_fact && !data.comment
                    }
                ]}
            />}
        >
            <form className="modal__form">

                <div className="block">
                    <SingleKeyboardDateTimePicker
                        label="Дата/время начала факт"
                        value={data.start_fact}
                        onChange={(value) => handleChange('start_fact', fullDateTimeWithTimeZone(value))}
                        error={validation.isKey(`${validateIndex}.start_fact`)}
                        helperText={validation.get(`${validateIndex}.start_fact`)}
                    />
                </div>

                <div className="block">
                    <SingleKeyboardDateTimePicker
                        isDisabled={disableIsDone}
                        label="Дата/время выполнения факт"
                        value={data.end_fact}
                        onChange={(value) => handleChange('end_fact', fullDateTimeWithTimeZone(value))}
                        error={validation.isKey(`${validateIndex}.end_fact`)}
                        helperText={validation.get(`${validateIndex}.end_fact`)}
                    />
                </div>

                <div className="block">
                    <TextField
                        required={!!data.end_fact}
                        label="Комментарий"
                        value={data.comment || ''}
                        onChange={({ target: { name, value } }) => handleChange(name, value)}
                        name="comment"
                        rows={3}
                        error={(!!data.end_fact && !data.comment) || validation.isKey(`${validateIndex}.comment`)}
                        helperText={validation.get(`${validateIndex}.comment`)}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default EditStatusModal;
