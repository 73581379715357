import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import { groupBy, isEqual, uniqBy, values } from 'lodash';

import { transportPassengerSelectors } from 'redux/TransportPassenger';
import {
    loadVehicleCategories,
    seWsRoute as seWsRouteTP,
} from 'redux/TransportPassenger/actions';
import * as actions from 'redux/TransportPassenger/actions';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import useTransportCoords from 'helpers/hooks/Transport/useTransportCoords';
import { useDebounce, usePrevious } from 'helpers/hooks';
import { useWsSubscribe } from 'helpers/ws/hooks';
import Context from 'helpers/context';
import mapHelper from 'helpers/mapHelper';
import MapLegends from 'components/common/Transport/MapLegends';
import MapLegendList from 'components/common/Transport/MapLegendList';
import getFilters from 'components/MapComponents/helpers/getFilters';
import {
    FeatureGroup,
} from 'components/MapComponents/leaflet';

import Station from './Components/Station';
import RouteJson from './RouteJson';
import RouteMarkers from './RouteMarkers';
import CheckPointsMarkers from './CheckPointsMarkers';
import icons from './icons/icons';
import {
    defaultStationColor,
    isShowStations,
} from './helper';
import Markers from './Markers';
import './style.scss';

const Layer = (props) => {
    const { permissions } = useContext(Context);
    const {
        map,
        hideStationsPopup = false,
        readOnly = false,
    } = props;
    const dispatch = useDispatch();

    const active = useSelector(transportPassengerSelectors.active);
    const changeRouteStatus = useSelector(transportPassengerSelectors.changeRouteStatus);
    const stationPolygon = useSelector(transportPassengerSelectors.stationPolygon);
    const filters = useSelector(transportPassengerSelectors.filters);

    // отображать остановки по зуму
    const [visibleZoom, setVisibleZoom] = useState(isShowStations(map.getZoom()));

    // отображать остановки
    const isVisibleStations = useMemo(() =>
        // если нет активных и разрешено по зуму
        active?.length === 0 && visibleZoom
    , [active, visibleZoom]);

    // группировка и кт и остановок
    const activeGroup = useMemo(() => {
        return values(groupBy(active.filter(i => !i.hide), item => `${item.num}_${item.category_id}`))
            .map(list => {
                const {
                    num,
                    category_id,
                    check_points,
                    stations,
                } = list.reduce((r, item) => ({
                    num: r.num || item.num,
                    category_id: r.category_id || item.category_id,
                    check_points: [
                        ...r.check_points,
                        ...(item.check_points || [])
                    ],
                    stations: [
                        ...r.stations,
                        ...(item.stations || [])
                    ]
                }), {
                    num: '',
                    category_id: '',
                    check_points: [],
                    stations: [],
                });
                return {
                    num,
                    category_id,
                    check_points: uniqBy(check_points, 'id'),
                    stations: uniqBy(stations, 'id'),
                };
            });
    }, [active]);

    const fetchPolygon = useCallback(() => {
        if (isVisibleStations) {
            const polygon = mapHelper.getGeometryPolygon(map);
            dispatch(actions.loadStationPolygon({ polygon }));
        }
    }, [isVisibleStations]);

    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = () => {
        const isVisible = isShowStations(map.getZoom());
        setVisibleZoom(isVisible);

        if (isVisible) {
            debounceFetchPolygon();
        } else {
            debounceFetchPolygon.clear();
        }
    };

    useEffect(() => {
        if (isVisibleStations === false) {
            dispatch(actions.clearStationPolygon());
        }
    }, [isVisibleStations]);

    useEffect(() => {
        if (isVisibleStations) {
            fetchPolygon();
        }
    }, [isVisibleStations]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [handleFetchPolygon]);

    const getTransportCategory = useTransportCategory(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    // const categoryTS = useStoreProp(
    //     loadVehicleCategories,
    //     'transportPassenger',
    //     'vehicleCategories'
    // );

    useWsSubscribe('transport-passenger_route_update_model_v2', (events) => {
        dispatch(seWsRouteTP(events));
    });

    const filter = getFilters(filters);

    // первоначальная загрузка координат
    const passengerCoords = useTransportCoords(
        actions.loadCoords,
        actions.clearCoords,
        actions.setMarkers,
        'transportPassenger.coords'
    );

    const prevFilters = usePrevious(filters);
    const prevActive = usePrevious(active);
    const isSetBounds = useRef(true);

    useEffect(() => {
        if (!isEqual(active, prevActive)) {
            isSetBounds.current = true;
        }
    }, [prevActive, active]);

    useEffect(() => {
        if (changeRouteStatus) {
            dispatch(actions.setChangeRoutesStatus(false));
            isSetBounds.current = false;
        }
    }, [changeRouteStatus]);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            passengerCoords.load(filter);
        }
    }, [filters, prevFilters]);

    useEffect(() => {
        passengerCoords.load(filter);

        return () => {
            map.closeContextMenu();
            dispatch(actions.clearActive());
            dispatch(actions.clearWsTelemetry());
            dispatch(actions.clearCoords());
        };
    }, []);

    // выбранные маршруты
    // const currentRoutes = useMemo(() => {
    //     return uniq(active.map(i => `${i.num}_${i.category_id}`));
    // }, [active]);

    // объединяем и переходим
    // маршруты
    useEffect(() => {
        if (active.length > 0) {
            const activeRoads = active.reduce((res, item) => {
                if (item.road) res.push(item.road);
                return res;
            }, []);
            const json = L.geoJSON(activeRoads);
            const bounds = json?.getBounds?.() || null;
            if (bounds?.isValid?.()) map.fitBounds(bounds);
        }
    }, [active]);

    // все осьтановки
    const allStations = useMemo(() => {
        // проверка не разрешено отображать маршруты
        if (!isVisibleStations) {
            return null;
        }

        return stationPolygon
            ?.filter(item => item?.check_point?.lat && item?.check_point?.lon)
            ?.map((item) => (
                <Station
                    {...props}
                    key={item.id}
                    station={item}
                    color={defaultStationColor}
                    route={null}
                    hideStationsPopup={hideStationsPopup}
                />
            ));
    }, [stationPolygon, isVisibleStations]);

    if (getTransportCategory.isLoaded === false) return null;

    return (
        <>
            {/* машинки */}
            <Markers
                {...props}
                transportHelper={getTransportCategory?.getHelper}
            />

            {/* контрольные точки и остановки */}
            {activeGroup.map(item => (
                <FeatureGroup
                    {...props}
                    key={`cs-group_${item.num}_${item.category_id}`}
                >
                    {/* контрольные точки маршрутов */}
                    <CheckPointsMarkers
                        item={item}
                        categoriesObj={getTransportCategory?.categoriesObj}
                    />
                    {/* остановки маршрутов */}
                    <RouteMarkers
                        item={item}
                        hideStationsPopup={hideStationsPopup}
                        categoriesObj={getTransportCategory?.categoriesObj}
                    />

                </FeatureGroup>
            ))}

            {/* построение линий маршрута */}
            <FeatureGroup {...props}>
                {active.map((item) => {
                    const hide = item.hide || false;
                    const { id } = item;

                    if (!hide) {
                        return (
                            <RouteJson
                                permissions={permissions}
                                item={item}
                                key={`json_${id}`}
                                readOnly={readOnly}
                                getTransportCategory={getTransportCategory}
                            />
                        );
                    }
                })}
            </FeatureGroup>

            {/* все остановки */}
            {allStations}

            {/* отобразить историю */}
            {/*<HistoryMap />*/}

            <MapLegends
                // category={getTransportCategory?.categories}
                layer="routes"
                // isVisible={getTransportCategory?.categories?.length > 0}
            >
                <MapLegendList
                    category={getTransportCategory?.categories}
                    noImage={{
                        color: '#8c8c8c',
                        name: 'нет иконки в категории',
                        image: icons({ fill: '#000' })
                    }}
                    fromService={'passengerTransport'}
                />
            </MapLegends>

            {/* svg иконка с направления */}
            {/*{transportSvgIcon.render()}*/}
        </>
    );
};

export default Layer;
