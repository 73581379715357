import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { editDtpLink, loadDtpLinkStatuses } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { useStoreFromSelector } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import StatusModal from './StatusModal';

const StatusButton = ({
    dtpLinkData,
    onRefresh,
}) => {
    const dispatch = useDispatch();

    const linkStatuses = useStoreFromSelector(loadDtpLinkStatuses, incidentsSelectors.dtpLinkStatuses);

    const {
        id,
        status,
    } = dtpLinkData || {};

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleSave = () => {
        onRefresh?.();
    };

    const handleChange = (newStatus) => {
        dispatch(editDtpLink(
            id,
            {
                ...dtpLinkData,
                status: newStatus,
            },
            handleSave,
            setLoading
        ));
    };

    return (
        <>
            {/*<strong>Подтверждение связи: </strong>*/}
            <strong>Статус связи: </strong>
            {linkStatuses[status] || ''}
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.editIcon,
                        onClick: () => setShowModal(true),
                        testName: 'change',
                        loading,
                    }
                ]}
                noMarginLeft
                justButton
            />

            {showModal && (
                <StatusModal
                    isOpen={showModal}
                    value={status}
                    onClose={() => setShowModal(false)}
                    onSave={handleChange}
                    loading={loading}
                />
            )}
        </>
    );

};

export default StatusButton;
