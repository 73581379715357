import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import cn from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

import classes from './modal.module.scss';
import PaperDraggableComponent from './PaperDraggableComponent';

import type { Breakpoint } from '@mui/system';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children?: React.ReactNode;
    title?: React.ReactNode;
    fullWidth?: boolean; // модалка на весь экран (карты и тп)
    buttons?: React.ReactElement;
    noPadding?: boolean; // без отступов у ModalContent
    overflowVisible?: boolean;
    small?: boolean; // маленькая модалка - например confirmDelete
    maxWidthProp?: Breakpoint | false; // Максимальная ширина модалки
    showCloseInTitle?: boolean;
    medium?: boolean; // 50% ширина окна
    heightMedium?: boolean; // 55% высоты окна
    heightSmall?: boolean, // 35% высоты окна
    size?: string; // предназначен задавать ширину модалки в процентах
    onBackdropClick?: () => void; //клик вне модалки
    noOverflowY?: boolean; // если не нужна вертикальная прокуртка
    disabledDragDrop?: boolean; // todo: dnd криво работает в модалке с react-draggable
    titleButtons?: React.ReactNode; // кнопки в шапке
    titleColor?: string; // цвет заголовка
    titleBackgroundColor?: string; // цвет фона шапки
    wrapperStyle?: React.CSSProperties; // стили для модалки
    contentStyle?: React.CSSProperties; // стили для контента
    buttonStyleWrap?: React.CSSProperties; // стили для DialogActions
}

const Modal = ({
    children,
    isOpen,
    onClose,
    fullWidth,
    title,
    buttons,
    noPadding,
    overflowVisible,
    small,
    maxWidthProp,
    showCloseInTitle,
    medium,
    heightMedium,
    heightSmall,
    size,
    onBackdropClick,
    noOverflowY,
    disabledDragDrop,
    titleButtons,
    titleColor,
    titleBackgroundColor,
    wrapperStyle,
    contentStyle,
    buttonStyleWrap,
}: ModalProps) => {

    const RenderCloseButton = ({ className, color }: { className: string, color?: React.CSSProperties['color']}) => {
        return (
            <>
                {(showCloseInTitle || fullWidth) && (
                    <div className={className}>
                        <IconButton onClick={() => onClose && onClose()}>
                            <CloseIcon style={{ color }} />
                        </IconButton>
                    </div>
                )}
            </>
        );
    };

    return (
        <Dialog
            open={isOpen}
            onClose={(_, r) => {
                if (r === 'escapeKeyDown') {
                    onClose();
                }
            }}
            onBackdropClick={onBackdropClick}
            maxWidth={maxWidthProp}
            fullWidth={fullWidth}
            PaperProps={{
                className: cn(classes.root, {
                    [classes.noPadding]: noPadding,
                    [classes.overflowVisible]: overflowVisible,
                    [classes.fullWidth]: fullWidth,
                    [classes.small]: small,
                    [classes.medium]: medium,
                    [classes.heightMedium]: heightMedium,
                    [classes.heightSmall]: heightSmall,
                }),
                style: {
                    ...(size && {
                        width: size,
                        maxWidth: size,
                    }),
                    ...wrapperStyle
                },
            }}
            {...!disabledDragDrop ? { PaperComponent: PaperDraggableComponent } : {}}
            aria-labelledby="draggable-dialog-title"
        >
            {(!!title || !!titleButtons)
                ? (
                    <div className={classes.titleContainer}
                        style={{
                            cursor: 'move',
                            ...(titleBackgroundColor ? { backgroundColor: titleBackgroundColor } : {})
                        }}
                        id="draggable-dialog-title"
                    >
                        <>
                            <DialogTitle color={titleColor} classes={{ root: classes.title }}>
                                {title}
                            </DialogTitle>
                        </>

                        <div style={{ display: 'flex' }}>
                            {!!titleButtons && (
                                <div
                                    style={{ color: titleColor }}
                                    className={cn({
                                        [classes.titleButtonsBlock]: true,
                                        [classes.titleButtonsBlockMargin]: !showCloseInTitle,
                                    })}
                                >
                                    {titleButtons}
                                </div>
                            )}

                            <RenderCloseButton color={titleColor} className={cn(classes.titleButton, classes.alignItemsCenter)}/>
                        </div>

                    </div>
                )
                : (
                    <RenderCloseButton className={cn(classes.titleButton, classes.absolute)}/>
                )
            }

            {children && (
                <DialogContent
                    classes={{
                        root: cn(classes.content, {
                            [classes.noPadding]: noPadding,
                            [classes.noOverflowY]: noOverflowY,
                        }),
                    }}
                    style={contentStyle}
                >
                    {children}
                </DialogContent>
            )}

            {buttons && (
                <DialogActions className={classes.actions} style={buttonStyleWrap}>
                    {buttons}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default Modal;
