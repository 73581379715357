import { useState } from 'react';
import { Check } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import { reactSelectors } from 'modules/React';
import { loadOperationTypes } from 'modules/React/redux/actions';
import type { OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

import EditStatusModal from './EditStatusModal';

interface ItemProps {
    item: OperationInScenario;
    onChangeItem: (item: OperationInScenario, onCloseModal: () => void) => void
    disableIsDone?: boolean
}

const Item = ({ item, onChangeItem, disableIsDone = false }: ItemProps) => {
    const types = useStoreFromSelector(loadOperationTypes, reactSelectors.operationTypes);

    const [openEditStatus, setOpenEditStatus] = useState(false);

    return <>
        <LSContentItem key={item.position}>
            <LSContentColumn>
                <Tooltip title={types[item?.type] || ''}>
                    <span style={{ cursor: 'pointer' }}>{item?.position}</span>
                </Tooltip>
            </LSContentColumn>
            <LSContentColumn>
                {item.name || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {fullDateTimeWithoutSeconds(item?.start_fact) || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {fullDateTimeWithoutSeconds(item?.end_fact) || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.time || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.comment || messages.NO_VALUE}
            </LSContentColumn>

            <LSContentColumn>
                <FormButtons
                    buttons={[
                        ...(item?.is_done
                            ? [
                                {
                                    icon: <Check color="secondary"/>,
                                    name: 'Выполнено',
                                    onClick: () => setOpenEditStatus(true),
                                    // disabled: item.group?.includes('.')
                                },
                            ]
                            : [
                                {
                                    ...buttonsTypes.editIcon,
                                    name: 'Отметка о выполнении',
                                    onClick: () => setOpenEditStatus(true),
                                    // disabled: item.group?.includes('.')
                                },
                            ]
                        )
                    ]}
                    noMarginLeft
                    justButton
                    showDisabled
                />
            </LSContentColumn>
        </LSContentItem>

        {openEditStatus && (
            <EditStatusModal
                item={item}
                isOpen={openEditStatus}
                onClose={() => setOpenEditStatus(false)}
                onChangeItem={(item) => onChangeItem(item, () => setOpenEditStatus(false))}
                disableIsDone={disableIsDone}
            />
        )}
    </>;
};

export default Item;
