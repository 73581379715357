import HeaderPanel from 'components/common/Showcase/HeaderPanel';
import { HeaderPageVisible } from 'components/common/Showcase/HeaderPage';
import Tabs from 'modules/Dashboard/utils/Components/Tabs';
import IncidentsHeatMap from 'modules/React/Dictionaries/IncidentsHeatMap';

// ленивая загрузка - иначе ошибка сборки
import InfoPanelLazy from './InfoPanelLazy';
import HeatMapLazy from './HeatMapLazy';

const StatisticsIncidents = () => (
    <div
        style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}
    >
        <HeaderPageVisible/>

        <HeaderPanel title="Инциденты" />

        <Tabs
            list={[
                {
                    tab: 'infopanel',
                    label: 'Инфопанель',
                    component: <InfoPanelLazy tab="infopanel" hideTitle />
                },
                {
                    tab: 'heat-map-incidents',
                    label: 'Тепловая карта инцидентов',
                    component: <IncidentsHeatMap tab="heat-map-incidents" hideTitle />
                },
                {
                    tab: 'heat-map-ngpt',
                    label: 'Тепловая карта скоплений НГПТ',
                    component: <HeatMapLazy tab="heat-map-ngpt" hideTitle />
                },
            ]}
        />
    </div>
);

export default StatisticsIncidents;
