import { useState } from 'react';
import { IconButton, Link, Tooltip } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { LSContentColumn, LSContentItem } from 'components/common/List';
import Modal from 'components/common/Modal';
import { RenderPassportTabs } from 'modules/React/Dictionaries/Incidents/RenderPassportTabs';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

const Item = ({ item = {}, onReload }) => {

    const [isOpenPassport, setIsOpenPassport] = useState(false);

    const closePassport = () => setIsOpenPassport(false);

    return (
        <>
            <LSContentItem key={item.id}>
                <LSContentColumn>
                    <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setIsOpenPassport(true);
                        }}
                    >
                        {item.id || ''}
                    </Link>
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || ''}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <Tooltip title="Паспорт">
                        <IconButton
                            size="small"
                            onClick={() => {
                                setIsOpenPassport(true);
                            }}
                        >
                            <FindInPageIcon/>
                        </IconButton>
                    </Tooltip>
                </LSContentColumn>
            </LSContentItem>

            {isOpenPassport && (
                <Modal
                    isOpen={isOpenPassport}
                    onClose={closePassport}
                    noPadding={true}
                    title={item?.name || ''}
                    fullWidth
                    showCloseInTitle
                    buttons={
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: closePassport
                                },
                            ]}
                        />
                    }
                >
                    <RenderPassportTabs
                        selectedItem={item}
                        passportOnly
                        reloadList={onReload}
                    />
                </Modal>
            )}
        </>
    );
};

export default Item;
