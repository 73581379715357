import React from 'react';

import { loadMilestoneEvents } from 'redux/TransportPassenger/actions';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { useStoreProp } from 'helpers/hooks';

const SelectMilestoneEvents = ({
    selected = [],
    onChange = () => {},
    multiple = false,
    required = false,
    isDisabled = false,
    label = 'Контрольные события',
    readOnly = false,
    limitTags = 1,
    error= required && (multiple ? selected.length === 0 : !selected?.id),
    helperText = '',
    test_id_prefix = ''
}) => {
    const milestoneEvents = useStoreProp(loadMilestoneEvents, 'transportPassenger', 'milestoneEvents');
    const initialState = multiple ? [] : {};

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                options={Object.keys(milestoneEvents)?.map(id => ({ id, name: milestoneEvents[id] }))}
                onChange={onChange}
                error={error}
                helperText={helperText}
                required={required}
                inputName="milestone_event"
                disabled={isDisabled}
                onReset={() => onChange(initialState)}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={readOnly ? '' : label}
                showAsSelect
                isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                test_id_prefix={test_id_prefix}
            />
        </>
    );
};

export default SelectMilestoneEvents;
