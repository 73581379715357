import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import Clear from '@mui/icons-material/Clear';

import type { Dadata } from 'redux/Dadata/ts-types';
import { clearDadataAddressList, loadDadataAddressList } from 'redux/Dadata/actions';
import titles from 'helpers/constants/titles';
import { useDebounce, useValidation } from 'helpers/hooks';

import AddressList from './AddressList';

interface AddressFieldProps {
    value: string;
    onChange: (arg: string) => void;
}

const AddressField = ({ value, onChange }: AddressFieldProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    // @ts-ignore
    const { addressList, loading }: { addressList: Dadata, loading: boolean } = useSelector(({ dadata }) => dadata);

    const fetchList = (query: any) => dispatch(loadDadataAddressList(10, query));
    const fetchListDebounce = useDebounce(fetchList, 800);
    const clearList = useCallback(() => dispatch(clearDadataAddressList()), [dispatch]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        onChange(value);

        if (value.length > 3) {
            fetchListDebounce(value);
        } else {
            fetchListDebounce.clear();
        }

        clearList();
    };

    const resetSearch = useCallback(() => {
        fetchListDebounce.clear();
        clearList();
        onChange('');
    }, [clearList, fetchListDebounce, onChange]);

    const adornment = () => {
        if (value.length > 0) {
            return {
                endAdornment: (
                    <InputAdornment position="end">
                        {loading && <CircularProgress color="inherit" size={20}/>}
                        <IconButton
                            onClick={resetSearch}
                            size="small"
                            title={titles.CLEAR_FIELD}
                        >
                            <Clear />
                        </IconButton>
                    </InputAdornment>
                )
            };
        }

        return {};
    };

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === 'Enter') fetchList(value);
    };

    const overlayClick = (): void => {
        clearList();
    };

    useEffect(() => {
        document.addEventListener('click', () => overlayClick());

        return document.removeEventListener('click', overlayClick);
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <TextField
                value={value}
                fullWidth
                onChange={handleChange}
                label="Адрес дислокации"
                variant="outlined"
                size="small"
                InputProps={adornment()}
                inputProps={{ onKeyDown: handleEnter }}
                error={validation.isKey('address')}
                helperText={validation.get('address')}
            />

            {addressList?.data?.length > 0 && (
                <AddressList
                    list={addressList.data}
                    onClick={(_, address) => {
                        onChange(address);
                        clearList();
                    }}
                />
            )}
        </div>
    );
};

export default AddressField;