import { config } from 'config';

const apiDigitalTwinUrl = () => config.get('urls').apiDigitalTwinUrl;

const apiUrls = {
    getTypes: () => `${apiDigitalTwinUrl()}/types`,

    getNodes: () => `${apiDigitalTwinUrl()}/nodes`,

    createNodes: () => `${apiDigitalTwinUrl()}/nodes`,

    editNodes: (id) => `${apiDigitalTwinUrl()}/nodes/${id}`,

    deleteNodes: (id) => `${apiDigitalTwinUrl()}/nodes/${id}`,

    getSidebar: () => `${apiDigitalTwinUrl()}/edges`,

    getPolygon: () => `${apiDigitalTwinUrl()}/get-graph`,

    getPopup: () => `${apiDigitalTwinUrl()}/popup`,

    getEdges: () => `${apiDigitalTwinUrl()}/edges`,

    getEdgeById: (id) => `${apiDigitalTwinUrl()}/edges/${id}`,

    getEdgeInfoById: (id) => `${apiDigitalTwinUrl()}/edges-info/${id}`,

    getNodeById: (id) => `${apiDigitalTwinUrl()}/nodes/${id}`,

    getEdgeTypes: () => `${apiDigitalTwinUrl()}/edges-types`,

    getEdgeClasses: () => `${apiDigitalTwinUrl()}/edges-classes`,

    createEdge: () => `${apiDigitalTwinUrl()}/edges`,

    editEdge: (id) => `${apiDigitalTwinUrl()}/edges/${id}`,

    deleteEdge: (id) => `${apiDigitalTwinUrl()}/edges/${id}`,

    getObjectBindings: () => `${apiDigitalTwinUrl()}/object-bindings`, // Получить привязку объекта

    getObjectBindingsAll: () => `${apiDigitalTwinUrl()}/object-bindings-all`, // Получить все привязки объектов

    getObjectBindingsNearly: () => `${apiDigitalTwinUrl()}/object-bindings-nearby`, // Получить объекты рядом

    createObjectBindings: () => `${apiDigitalTwinUrl()}/object-bindings`, // Создать привязку объекта

    updateObjectBindings: (id) => `${apiDigitalTwinUrl()}/object-bindings/${id}`, // Обновить привязку объекта

    deleteObjectBindings: (id) => `${apiDigitalTwinUrl()}/object-bindings/${id}`, // Удалить привязку объекта
};

export default apiUrls;
