import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const SET_DADATA_LOADING = `${prefix}/SET/DADATA/LOADING`;
export const LOAD_DADATA_COMPANIES = `${prefix}/LOAD/DADATA/COMPANIES`;
export const LOADED_DADATA_COMPANIES = `${prefix}/LOADED/DADATA/COMPANIES`;
export const LOAD_DADATA_ADDRESS_LIST = `${prefix}/LOAD/DADATA/ADDRESS/LIST`;
export const LOADED_DADATA_ADDRESS_LIST = `${prefix}/LOADED/DADATA/ADDRESS/LIST`;
export const CLEAR_DADATA_ADDRESS_LIST = `${prefix}/CLEAR/DADATA/ADDRESS/LIST`;
export const LOAD_DADATA_ADDRESS = `${prefix}/LOAD/DADATA/ADDRESS`;
export const LOADING_DADATA_ADDRESS = `${prefix}/LOADED/DADATA/ADDRESS`;
export const LOADED_DADATA_ADDRESS = `${prefix}/LOADED/DADATA/ADDRESS`;
export const CLEAR_DADATA_ADDRESS = `${prefix}/CLEAR/DADATA/ADDRESS`;
export const LOAD_AUTOCOMPLETE_ADDRESS = `${prefix}/LOAD/AUTOCOMPLETE/ADDRESS`;
export const LOADED_AUTOCOMPLETE_ADDRESS = `${prefix}/LOADED/AUTOCOMPLETE/ADDRESS`;
export const LOADING_AUTOCOMPLETE_ADDRESS = `${prefix}/LOADING/AUTOCOMPLETE/ADDRESS`;
export const LOAD_DADATA_ADDRESS_BY_COORDINATES = `${prefix}/LOAD/DADATA/ADDRESS/BY/COORDINATES`;
export const LOADED_DADATA_ADDRESS_BY_COORDINATES = `${prefix}/LOADED/DADATA/ADDRESS/BY/COORDINATES`;
//UAPTS-1519
export const LOAD_GEOCODE_ADDRESS = `${prefix}/LOAD_GEOCODE_ADDRESS`;
export const LOADING_GEOCODE_ADDRESS = `${prefix}/LOADING_GEOCODE_ADDRESS`;
export const LOADED_GEOCODE_ADDRESS = `${prefix}/LOADED_GEOCODE_ADDRESS`;
export const CLEAR_GEOCODE_ADDRESS = `${prefix}/CLEAR_GEOCODE_ADDRESS`;

// regions
export const LOAD_REGIONS = `${prefix}LOAD_REGIONS`;
export const LOADING_REGIONS = `${prefix}LOADING_REGIONS`;
export const LOADED_REGIONS = `${prefix}LOADED_REGIONS`;

export const LOAD_GEOCODE_ADDRESS_ITEM = `${prefix}LOAD_GEOCODE_ADDRESS_ITEM`;
// export const LOADED_GEOCODE_ADDRESS_ITEM = `${prefix}LOADED_GEOCODE_ADDRESS_ITEM`;

export const LOAD_ADDRESS_AREAS_AUTOCOMPLETE = `${prefix}LOAD_ADDRESS_AREAS_AUTOCOMPLETE`;
export const LOADED_ADDRESS_AREAS_AUTOCOMPLETE = `${prefix}LOADED_ADDRESS_AREAS_AUTOCOMPLETE`;
export const LOADED_ADDRESS_STREETS_AUTOCOMPLETE = `${prefix}LOADED_ADDRESS_STREETS_AUTOCOMPLETE`;
export const LOADED_ADDRESS_HOUSES_AUTOCOMPLETE = `${prefix}LOADED_ADDRESS_HOUSE_AUTOCOMPLETE`;
export const LOADED_ADDRESS_CITIES_AUTOCOMPLETE = `${prefix}LOADED_ADDRESS_CITY_AUTOCOMPLETE`;

export const GET_ADDRESS_BY_COORDINATES = `${prefix}GET_ADDRESS_BY_COORDINATES`;
