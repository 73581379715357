function Legend() {
    return (
        <div className="sidebar-tp-legend">
            <div className="item">
                <div className="title">Цветовая легенда</div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {[
                        { id: 1, name: 'Высокая интенсивность', color: '#C02C16' },
                        { id: 2, name: 'Средняя интенсивность', color: '#EDAD09' },
                    ].map((item) => (
                        <div
                            key={item.id}
                            className="flex"
                            style={{
                                width: '200px',
                                // alignItems: 'start',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                className="image"
                                style={{
                                    height: 'auto',
                                    width: 'auto',
                                    alignItems: 'top',
                                }}
                            >
                                <div
                                    style={{
                                        width: '10px',
                                        height: '10px',
                                        backgroundColor: item.color,
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>

                            <div className="small">{item.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Legend;
