import { List, ListItem, ListItemText } from '@mui/material';

const Card = ({ data = {} }) => {
    const cells = data.cells || [];

    return (
        <List>
            {cells?.map(({ name, value, threshold, color }, index) => (
                <ListItem dense disableGutters key={index} style={{ paddingLeft: '26px' }}>
                    <ListItemText style={{ marginRight: '10px' }}>
                        {name}
                    </ListItemText>
                    <div style={{ color:`${threshold ? 'red' : 'inherit'}` }}>{value}</div>
                </ListItem>
            ))}
        </List>
    );
};

export default Card;
