import { moduleName } from '../module-config';

const prefix = `${moduleName}`;

/**
 * Constants
 * */

//
export const LOAD_DASHBOARD = `${prefix}/LOAD_DASHBOARD`;
export const LOADED_DASHBOARD = `${prefix}/LOADED_DASHBOARD`;
export const CLEAR_DASHBOARD = `${prefix}/CLEAR_DASHBOARD`;
export const LOADING_DASHBOARD = `${prefix}/LOADING_DASHBOARD`;

//
export const LOAD_USER_DASHBOARD = `${prefix}/LOAD_USER_DASHBOARD`;
export const LOADED_USER_DASHBOARD = `${prefix}/LOADED_USER_DASHBOARD`;
export const LOADING_USER_DASHBOARD = `${prefix}/LOADING_USER_DASHBOARD`;
export const EDIT_USER_DASHBOARD = `${prefix}/EDIT_USER_DASHBOARD`;

//
export const LOAD_DASHBOARDS_ITEM = `${prefix}/LOAD_DASHBOARDS_ITEM`;
export const LOADED_DASHBOARDS_ITEM = `${prefix}/LOADED_DASHBOARDS_ITEM`;
export const LOADING_DASHBOARDS = `${prefix}/LOADING_DASHBOARDS`;

export const LOAD_DASHBOARD_ITEM_USER = `${prefix}/LOAD_DASHBOARD_ITEM_USER`;
export const LOADED_DASHBOARD_ITEM_USER = `${prefix}/LOADED_DASHBOARD_ITEM_USER`;
export const CLEAR_DASHBOARD_ITEM_USER = `${prefix}/CLEAR_DASHBOARD_ITEM_USER`;
export const CREATE_DASHBOARD_ITEM_USER = `${prefix}/CREATE_DASHBOARD_ITEM_USER`;
export const EDIT_DASHBOARD_ITEM_USER = `${prefix}/EDIT_DASHBOARD_ITEM_USER`;
export const EDIT_DASHBOARD_ITEM_USER_MULTIPLY = `${prefix}/EDIT_DASHBOARD_ITEM_USER_MULTIPLY`;
export const DELETE_DASHBOARD_ITEM_USER = `${prefix}/DELETE_DASHBOARD_ITEM_USER`;
export const LOADING_DASHBOARD_ITEM_USER = `${prefix}/LOADING_DASHBOARD_ITEM_USER`;

export const LOAD_USER_AVAILABLE_DASHBOARD_LIST = `${prefix}LOAD_USER_AVAILABLE_DASHBOARD_LIST`;
export const LOADED_USER_AVAILABLE_DASHBOARD_LIST = `${prefix}LOADED_USER_AVAILABLE_DASHBOARD_LIST`;
export const LOADING_USER_AVAILABLE_DASHBOARD_LIST = `${prefix}LOADING_USER_AVAILABLE_DASHBOARD_LIST`;
