import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { loadIncidents } from 'modules/React/redux/actions';

import List from './List';

const IncidentsList = ({ ids = [] }) => {
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleReload = () => {
        if (ids.length > 0) {
            // todo возможно менять метод с get на post
            dispatch(loadIncidents(
                1,
                ids.length,
                { ids },
                (request) => {
                    setData(request.data || []);
                },
                setLoading
            ));
        }
    };

    useEffect(() => () => {
        setIsOpen(false);
        setLoading(false);
        setData([]);
    }, []);

    const handleChange = () => {
        setIsOpen(isOpen => {
            if (isOpen) {
                setData([]);
            } else {
                handleReload();
            }
            return !isOpen;
        });
    };

    return (
        <div>
            <FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.show,
                        name: `${isOpen ? 'Скрыть' : 'Показать'} перечень инцидентов`,
                        onClick: handleChange,
                        loading,
                    },
                ]}
            />

            {isOpen && <List data={data} onReload={() => handleReload()} />}
        </div>
    );
};

export default IncidentsList;
