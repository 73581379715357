import { useDispatch } from 'react-redux';
import { useState } from 'react';

import SidebarItem from 'components/MapComponents/SidebarItem';
import CircleStatus from 'components/common/CircleStatus';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import * as actions from 'modules/React/redux/actions';
import messages from 'helpers/constants/messages';
import buttons from 'helpers/constants/buttons';
import ConfirmModal from 'components/common/ConfirmModal';
import { AddModal } from 'modules/React/Dictionaries/Incidents/AddModal/Index';

const Item = ({ item, activeId, reloadList }) => {
    const dispatch = useDispatch();
    const [openDeleteForm, setOpenDeleteForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);

    const {
        id,
        name,
        registered_at,
        type_text,
        address_text,
        title,
        color,
        status_text,
    } = item;
    const isDisabled = item?.status_id === 3 || item?.status_id === 4;
    return (
        <>
            <SidebarItem
                key={id}
                active={activeId === id}
                sideComponent={<CircleStatus title={title} color={color}/>}
                onClick={() => dispatch(actions.setActive(item))}
                {...(!isDisabled
                    ? {
                        buttons: {
                            edit: {
                                title: buttons.EDIT,
                                icon: <i className="fas fa-pen" />,
                                onClick: () => setOpenEditForm(true),
                            },
                            delete: {
                                title: buttons.DELETE,
                                icon: <i className="fas fa-trash-alt" />,
                                onClick: () => setOpenDeleteForm(true),
                            }
                        }
                    }
                    : {}
                )}
            >
                <div>
                    {registered_at ? getFullDateTimeWithTextMonth(registered_at) : ''}
                </div>

                <div>
                    <strong>ID: </strong>
                    <span>{id}</span>
                </div>
                <div>
                    <strong>Наименование: </strong>
                    <span>{name}</span>
                </div>

                <div>
                    <strong>Адрес: </strong>
                    <span>{address_text || messages.NO_DATA}</span>
                </div>

                <div>
                    <strong>Тип: </strong>
                    <span>{type_text}</span>
                </div>

                <div>
                    <strong>Статус: </strong>
                    <span>{status_text}</span>
                </div>
            </SidebarItem>
            {openDeleteForm
                && <ConfirmModal
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteForm}
                    onSuccess={() => {
                        dispatch(actions.deleteIncident(id, reloadList));
                        setOpenDeleteForm(false);
                    }}
                    onClose={() => {
                        setOpenDeleteForm(false);
                    }}
                />
            }
            {openEditForm
                && (
                    <AddModal
                        isOpen={openEditForm}
                        onClose={() => setOpenEditForm(false)}
                        item={item}
                        reloadList={reloadList}
                    />
                )
            }
        </>
    );
};

export default Item;
