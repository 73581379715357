import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadInfrastructureTypes } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';

import Filters from './Filters';
import ITItem from './ITItem';
import ITModal from './ITModal';

const InfrastructureTypes = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const loading = useSelector(roadNetworksSelectors.loadingInfrastructureTypes);
    const infrastructureTypesData = useSelector(roadNetworksSelectors.infrastructureTypesData);
    const meta = useSelector(roadNetworksSelectors.infrastructureTypesMeta);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const [params, setParams] = useState({
        page: 1,
        data: {}
    });

    const reloadList = () => dispatch(loadInfrastructureTypes(params.page, limit, params.data));

    useEffect(() => {
        dispatch(loadInfrastructureTypes(params.page, limit, params.data));
    }, [params, limit, dispatch]);

    const renderContent = () => {
        return (infrastructureTypesData?.length > 0
            ? (<>
                <LSContainer
                    headers={[
                        { title: titles.NAME, width: '70%' },
                        { title: 'Действия', isActions: true }
                    ]}
                >
                    {infrastructureTypesData?.map(el =>
                        <ITItem
                            key={el.id}
                            el={el}
                            reloadList={reloadList}
                            permissions={permissions}
                        />)}
                </LSContainer>
            </>)
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Типы объектов инфраструктуры"
                filters={<Filters setParams={setParams}/>}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(true),
                                        disabled: !permissions?.is_create,
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openModal && (
                <ITModal
                    isNew={true}
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default InfrastructureTypes;
