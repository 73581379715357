import { useState } from 'react';
import { FormControl, TextField } from '@mui/material';

import DateRange from 'components/common/Dates/DateRange';
import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';


const Filter = ({ initState, setParams }) => {
    const [filterData, setFilterData] = useState(initState);


    const isDisabled = !filterData.name && !filterData.date_start && !filterData.date_end;

    const handleSearch = () => {
        setParams(filterData);
    };

    const updateFilter = (filters) => {
        setFilterData({ ...initState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ ...initState, ...filters });
        }
    };

    const resetFilter = (needRefresh) => {
        setFilterData(initState);

        if (needRefresh) {
            setParams(initState);
        }
    };

    return (
        <LayoutFilter
            filter={filterData}
            onSearch={handleSearch}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterData.date_start}
                    valueEndDate={filterData.date_end}
                    handleDate={(e) => setFilterData({ ...filterData, date_start: e })}
                    handleEndDate={(e) => setFilterData({ ...filterData, date_end: e })}
                    startLabel={titles.START_DATE}
                    endLabel={titles.END_DATE}
                    disableFuture={false}
                />
                <FormControl size="small" variant="outlined">
                    <TextField
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={filterData.name}
                        name={'name'}
                        onChange={(e) => setFilterData({ ...filterData, name: e.target.value })}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    />
                </FormControl>

            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
