import * as types from './types';

export const loadCollectionServicesList = (page, limit, params = {}) => ({
    type: types.LOAD_COLLECTION_SERVICES,
    payload: { page, limit, ...params },
});

export const loadedCollectionServicesList = (data) => ({
    type: types.LOADED_COLLECTION_SERVICES,
    payload: data,
});

export const loadingCollectionServicesList = (bool = false) => ({
    type: types.LOADING_COLLECTION_SERVICES,
    payload: bool,
});

export const loadCollectionServicesTypes = () => ({
    type: types.LOAD_COLLECTION_SERVICES_TYPES,
});

export const loadedCollectionServicesTypes = (data) => ({
    type: types.LOADED_COLLECTION_SERVICES_TYPES,
    payload: data,
});

export const loadCollectionServicesTemplates = () => ({
    type: types.LOAD_COLLECTION_SERVICES_TEMPLATES,
});

export const loadedCollectionServicesTemplates = (data) => ({
    type: types.LOADED_COLLECTION_SERVICES_TEMPLATES,
    payload: data,
});

export const createCollectionService = (data, callback) => ({
    type: types.CREATE_COLLECTION_SERVICE,
    payload: data,
    callback
});

export const editCollectionService = (id, data, callback) => ({
    type: types.EDIT_COLLECTION_SERVICE,
    payload: {
        id,
        data
    },
    callback
});

export const deleteCollectionService = (id, callback) => ({
    type: types.DELETE_COLLECTION_SERVICE,
    payload: id,
    callback
});

export const loadCollectionServicesServices = () => ({
    type: types.LOAD_COLLECTION_SERVICES_SERVICES,
});

export const loadedCollectionServicesServices = (data) => ({
    type: types.LOADED_COLLECTION_SERVICES_SERVICES,
    payload: data,
});

export const loadJobsList = (params = {}) => ({
    type: types.LOAD_JOBS,
    payload: { ...params },
});

export const loadedJobsList = (data) => ({
    type: types.LOADED_JOBS,
    payload: data,
});

export const loadingJobsList = (bool = false) => ({
    type: types.LOADING_JOBS,
    payload: bool,
});
export const createJob = (data, callback) => ({
    type: types.CREATE_JOB,
    payload: data,
    callback
});

export const editJob = (id, data, callback) => ({
    type: types.EDIT_JOB,
    payload: {
        id,
        data
    },
    callback
});

export const loadJobsPeriod = (page, limit, params = {}) => ({
    type: types.LOAD_JOBS_PERIOD,
    payload: { page, limit, ...params },
});

export const loadedJobsPeriod = (data) => ({
    type: types.LOADED_JOBS_PERIOD,
    payload: data,
});