import { useMemo } from 'react';
import { TextField } from '@mui/material';

import { loadDrivingModeFactorTypes, loadRoadDefectTypes, loadRoadPurposeTypes } from 'redux/Incidents/actions';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { generateOptions, getValue } from 'components/MapComponents/Layers/Incidents/helper';

const Situation = ({ fields, onChange }) => {
    const validation = useValidation();

    const roadPurposeTypes = useStoreProp(loadRoadPurposeTypes, 'incidents', 'roadPurposeTypes');
    const roadDefectTypes = useStoreProp(loadRoadDefectTypes, 'incidents', 'roadDefectTypes');
    const drivingModeFactorTypes = useStoreProp(loadDrivingModeFactorTypes, 'incidents', 'drivingModeFactorTypes');

    const roadPurposeTypesOptions = useMemo(() => generateOptions(roadPurposeTypes), [roadPurposeTypes]);
    const roadDefectTypesOptions = useMemo(() => generateOptions(roadDefectTypes), [roadDefectTypes]);
    const drivingModeFactorTypesOptions = useMemo(() => generateOptions(drivingModeFactorTypes), [drivingModeFactorTypes]);

    const handleEvent = (key) => (newValue) => {
        const { target: { value } } = newValue;

        if (newValue.target.type !== 'number' || Number(value) >= 0) {
            onChange({
                [key]: value
            });
        }

    };

    return (
        <div className="modal__form">
            <div className="block">
                <TextField
                    size="small"
                    label={titles.ROAD}
                    variant="outlined"
                    value={fields.road || ''}
                    onChange={handleEvent('road')}
                    error={validation.isKey('road')}
                    helperText={validation.get('road')}
                />
            </div>
            <CommonAutocomplete
                className="block"
                label={titles.ROAD_VALUE}
                selected={getValue(roadPurposeTypes, fields?.road_purpose)}
                options={roadPurposeTypesOptions}
                onChange={(value) => onChange({ road_purpose: value?.id || null })}
                renderLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                showAsSelect
            />
            <div className="block">
                <TextField
                    size="small"
                    label={titles.ROAD_CATEGORY}
                    variant="outlined"
                    value={fields.road_category || ''}
                    onChange={handleEvent('road_category')}
                    error={validation.isKey('road_category')}
                    helperText={validation.get('road_category')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label={titles.ROAD_CONDITION}
                    variant="outlined"
                    value={fields.road_condition || ''}
                    onChange={handleEvent('road_condition')}
                    error={validation.isKey('road_condition')}
                    helperText={validation.get('road_condition')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label={titles.STREET_CATEGORY}
                    variant="outlined"
                    value={fields.street_category || ''}
                    onChange={handleEvent('street_category')}
                    error={validation.isKey('street_category')}
                    helperText={validation.get('street_category')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label={titles.WEATHER}
                    variant="outlined"
                    value={fields.weather || ''}
                    onChange={handleEvent('weather')}
                    error={validation.isKey('weather')}
                    helperText={validation.get('weather')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label={titles.LIGHTING}
                    variant="outlined"
                    value={fields.lighting || ''}
                    onChange={handleEvent('lighting')}
                    error={validation.isKey('lighting')}
                    helperText={validation.get('lighting')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label={titles.CHANGES_DRIVING}
                    variant="outlined"
                    value={fields.changes_driving || ''}
                    onChange={handleEvent('changes_driving')}
                    error={validation.isKey('changes_driving')}
                    helperText={validation.get('changes_driving')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label={titles.DEVICE_TRAFFIC_LIGHT_CROSSROAD}
                    variant="outlined"
                    value={fields.device_traffic_light_crossroads || ''}
                    onChange={handleEvent('device_traffic_light_crossroads')}
                    error={validation.isKey('device_traffic_light_crossroads')}
                    helperText={validation.get('device_traffic_light_crossroads')}
                />
            </div>
            <div className="block">
                <TextField
                    size="small"
                    label={titles.DEVICE_TRAFFIC_LIGHT_CROSSWALK}
                    variant="outlined"
                    value={fields.device_traffic_light_crosswalk || ''}
                    onChange={handleEvent('device_traffic_light_crosswalk')}
                    error={validation.isKey('device_traffic_light_crosswalk')}
                    helperText={validation.get('device_traffic_light_crosswalk')}
                />
            </div>
            <div className="block">
                <MultipleInput
                    label={titles.ROAD_OBJECTS_AROUND}
                    value={fields.road_objects_around || []}
                    onChange={(value) => onChange({ road_objects_around: value })}
                />
            </div>
            <CommonAutocomplete
                multiple
                className="block"
                label={titles.ROAD_DEFECTS}
                selected={getValue(roadDefectTypes, fields?.road_defects)}
                options={roadDefectTypesOptions}
                onChange={(value) => onChange({ road_defects: value.map(el => el.id) })}
                renderLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                showAsSelect
                limitTags={1}
            />
            <CommonAutocomplete
                multiple
                className="block"
                label={titles.DRIVING_MODE_INFLUENCE_FACTORS}
                selected={getValue(drivingModeFactorTypes, fields?.driving_mode_influence_factors)}
                options={drivingModeFactorTypesOptions}
                onChange={(value) => onChange({ driving_mode_influence_factors: value.map(el => el.id) })}
                renderLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                showAsSelect
                limitTags={1}
            />
        </div>
    );    
};

export default Situation;
