import { useEffect, useState } from 'react';
import { List } from '@mui/material';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import Item from './Item';
import AddModal from './AddEditModal';

const Members = ({ fields, onChange }) => {
    
    const [members, setMembers] = useState(fields.members);
    const [openAddModal, setOpenAddModal] = useState(false);    

    const handleAdd = (member) => {
        setMembers([
            ...members,
            member
        ]);
    };

    const handleChange = (index, member) => {
        const newItems = [
            ...members.slice(0, index),
            member,
            ...members.slice(index + 1)
        ];
        setMembers(newItems);
    };

    const handleDelete = (index) => {
        if (index >= 0) {
            setMembers([
                ...members.slice(0, index),
                ...members.slice(index + 1),
            ]);
        }
    };

    useEffect(() => (
        onChange({ members })
    ),[members]);
    
    return (
        <div className="modal__form">
            <List className="list">
                {members?.map((member, index) => (
                    <Item
                        key={index}
                        index={index}
                        member={member}
                        onChange={handleChange}
                        onDelete={handleDelete}
                    />
                ))}
            </List>            
            {openAddModal 
                && <AddModal
                    isOpen={openAddModal} 
                    onClose={() => setOpenAddModal(false)}
                    onAccept={handleAdd}
                />
            }
            <FormButtonsComponent
                buttons={[
                    {   
                        ...buttonsTypes.add,
                        onClick: () => setOpenAddModal(true)                        
                        
                    }
                ]}
            />
        </div>        
    );    
};

export default Members;
