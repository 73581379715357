import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHumanDate } from 'helpers/date.config';
import Information from 'components/common/Information';
import Loading from 'components/common/Loading';
import { loadVestibuleLoad } from 'modules/Metro/redux/actions';
import { metroSelectors } from 'modules/Metro';

const Info = ({ id }) => {
    const dispatch = useDispatch();

    const loading = useSelector(metroSelectors.loadingVestibuleTraffic);

    const [vestibuleData, setVestibuleData] = useState({});

    useEffect(() => {
        if (id) {
            dispatch(loadVestibuleLoad(id, setVestibuleData));
        }
    }, [dispatch, id]);

    const fields = {
        data: {
            title: 'Период',
            value: ({ start_at, end_at }) => `${getHumanDate(new Date())} ${start_at}-${end_at}`,
        },
        trash_hold: {
            title: 'Пороговое значение',
        },
        count: {
            title: 'Реальное значение',
        },
        excess: {
            title: 'Превышение',
        },
    };

    return (
        <>
            {loading
                ? <Loading linear />
                : <Information
                    data={vestibuleData}
                    title={fields}
                    returnNull
                />
            }
        </>
    );
};

export default Info;
