import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';

import ModalForm from './ModalForm';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const deleteForm = useSelector(incidentsSelectors.deleteForm);
    const editForm = useSelector(incidentsSelectors.editForm);
    const saved = useSelector(incidentsSelectors.saved);

    const clearEditForm = () => dispatch(actions.setEditForm());

    useEffect(() => {
        if (saved) {
            dispatch(actions.saveDtp(false));
        }
    }, [dispatch, saved]);

    return (
        <>
            <ModalForm
                data={editForm}
                onAccept={(newFields) => {
                    if (!newFields['id']) {
                        dispatch(actions.createNewDtp(newFields, clearEditForm));
                    } else {
                        dispatch(actions.editDtp(newFields.id, newFields, clearEditForm));
                    }
                }}
                onClose={clearEditForm}
            />

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deleteForm}
                onSuccess={() => {
                    dispatch(actions.deleteDtp(deleteForm));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
