import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import { difference, isEqual } from 'lodash';
import 'leaflet.marker.slideto';

import { setWsTelemetry as setWsTelemetryTS } from 'redux/TransportSpecial/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import { usePrevious } from 'helpers/hooks';
import { useWsSubscribe } from 'helpers/ws/hooks';
import compareEventWs from 'helpers/ws/helper/compareEventWs';
import location from 'components/MapComponents/leaflet/icon/location.png';
import usePopModalListener from 'components/MapComponents/MapPopUp/PopUpListener/usePopModalListener';

import PopUpModal from './PopUpModal';

// иконка по умолчанию
const defaultIcon = L.icon({
    iconUrl: location,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
});

// маркер карты
const Markers = (props) => {

    const mapModal = usePopModalListener();

    const {
        map,
        // area = null,
        parent,
        icon,
        toolTipTemplate,
        toolTipOption = {},
        onClosePopUp = () => {},
        history,
    } = props;

    const dispatch = useDispatch();

    // подключение к каналу
    useWsSubscribe('transport-special_telemetry_v2', (events) => {
        if (events?.length > 0) {
            // объединение
            compareEventWs(
                'transport-special_telemetry',
                (data) => dispatch(setWsTelemetryTS(data)),
                'external_id'
            ).list(events);

            events?.length > 0 && events?.map((item) => wsItem(item));
        }
    });

    const handleClosePopUp = () => {
        onClosePopUp();
    };

    const wsItem = (event) => {
        const {
            external_id,
            source,
            direction,
            in_registry,
            in_work_order,
            lat,
            lon,
        } = event;

        const category_id = event?.vehicle?.category_id || null;

        const iconOption = {
            in_registry,
            category_id,
            in_work_order
        };

        const key = `${external_id}`;
        if (refKeys.current[key]) {
            const item = refKeys.current[key];
            // меняем цвет иконки
            const currenIconOption = refKeysOptions.current[key];

            if (!isEqual(currenIconOption, iconOption)) {
                const number = active?.serial_egts !== '' ? `${active?.serial_egts}`
                    : (active?.serial_yandex !== '' ? `${active?.serial_yandex}` : 0);

                const itemData = {
                    ...event,
                    currentShow: number === key,
                    category_id
                };

                refKeys.current[key].setIcon(icon ? icon(itemData) : defaultIcon);

                refKeysOptions.current[key] = {
                    ...iconOption
                };
            }

            latlonArr.current[key] = [lat,lon];

            item.slideTo([lat, lon], {
                duration: 5000,
                // keepAtCenter
            });

            const toolTip = refKeysTooltip.current[key] || null;
            if (toolTipTemplate && toolTip) {
                const tooltipContent = toolTipTemplate(event || {});
                const renderString = renderToString(tooltipContent);
                toolTip.setContent(renderString);
            }
        }
    };

    const data = useSelector(transportSpecialSelectors.wsData);
    const active = useSelector(transportSpecialSelectors.active);

    const refKeysOptions = useRef({});
    const refKeysTooltip = useRef({});
    const refKeys = useRef({});
    const prevData = usePrevious(data);

    const dataKeys = Object.keys(data);
    const prevDataKeys = Object.keys(prevData);

    const addKeys = difference(dataKeys, prevDataKeys);
    const deleteKeys= difference(prevDataKeys, dataKeys);
    const latlonArr = useRef([]);

    // открытие модалки от сайдбара
    useEffect(() => {
        if (Object.keys(active).length > 0) {
            const number = active?.serial_egts !== '' ? `${active?.serial_egts}`
                : (active?.serial_yandex !== '' ? `${active?.serial_yandex}` : 0);

            if (number && data[number]) {
                mapModal.setModal(number);
            }
        }
    }, [active, data]);

    const handleAdd = (key) => {
        const item = refKeys.current[key];
        const {
            lat,
            lon,
            in_registry,
            in_work_order
        } = data[key];

        if (!item) {
            const category_id = data[key]?.vehicle?.category_id || null;

            const iconOption = {
                in_registry,
                category_id,
                in_work_order
            };

            const number = active?.serial_egts !== '' ? `${active?.serial_egts}`
                : (active?.serial_yandex !== '' ? `${active?.serial_yandex}` : 0);

            const itemData = {
                ...data[key],
                currentShow: number === key,
                category_id
            };
            const newMarker = L
                .marker([lat, lon], {
                    icon: icon ? icon(itemData) : defaultIcon
                });
            parent.addLayer(newMarker);
            refKeys.current[key] = newMarker;
            refKeysOptions.current[key] = iconOption;

            // tooltip
            if (toolTipTemplate) {
                const tooltipContent = toolTipTemplate(data[key] || {});
                const renderString = renderToString(tooltipContent);
                const toolTip = L.tooltip({
                    ...toolTipOption,
                });
                toolTip.setContent(renderString);
                newMarker.bindTooltip(toolTip);

                refKeysTooltip.current[key] = toolTip;
            }

            newMarker.on('click', () => {
                mapModal.setModal(key);
            });
        }
    };

    const handleDelete = (key) => {
        if (refKeys.current[key]) {
            const item = refKeys.current[key];
            item.off('click');
            item.off('popupclose', handleClosePopUp);
            parent.removeLayer(item);
            item.remove();
            delete refKeys.current[key];
            delete refKeysOptions.current[key];
            delete refKeysTooltip.current[key];
        }
    };

    useEffect(() => {
        // добавляем маркеры
        if (addKeys.length) {
            addKeys.map((itemKey) => {
                if (data[itemKey]) {
                    handleAdd(itemKey);
                }
            });
        }
    }, [addKeys]);

    useEffect(() => {
        // удаляем маркеры
        if (deleteKeys.length) {
            deleteKeys.map((itemKey) => {
                handleDelete(itemKey);
            });
        }
    }, [deleteKeys]);

    useEffect(() => {
        // удаляем маркеры
        return () => {
            Object.keys(refKeys.current).map((itemKey) => {
                handleDelete(itemKey);
            });
        };
    }, []);

    useEffect(() => {
        if (active && Object.keys(active).length > 0) {

            const number = active?.serial_egts !== '' ? `${active?.serial_egts}`
                : (active?.serial_yandex !== '' ? `${active?.serial_yandex}` : 0);

            const item = refKeys.current[number || 0] || null;

            if (item) { // && !active.isClick
                const dataItem = data[number];
                const category_id = data[number]?.vehicle?.category_id || null;

                item.setIcon(icon({
                    ...dataItem,
                    currentShow: true,
                    category_id,
                }));

                return () => {
                    item.setIcon(icon({
                        ...dataItem,
                        currentShow: false,
                        category_id,
                    }));
                    item.isPopupOpen() && item.closePopup();
                    item.isTooltipOpen() && item.closeTooltip();
                };
            }
        }
    },[active]);

    return (
        <>
            {mapModal.isOpen && (
                <PopUpModal
                    isOpen={mapModal.isOpen}
                    uuid={mapModal.modal}
                    onClose={() => {
                        mapModal.clear();
                        onClosePopUp();
                    }}
                    history={history}
                />
            )}

            {/*<MapPopUpListener*/}
            {/*    activeSelector={transportSpecialSelectors.active}*/}
            {/*    polygonSelector={transportSpecialSelectors.wsData}*/}
            {/*>*/}
            {/*    <PopUpModal*/}
            {/*        isOpen={mapModal.isOpen}*/}
            {/*        uuid={mapModal.modal}*/}
            {/*        onClose={() => {*/}
            {/*            mapModal.clear();*/}
            {/*            onClosePopUp();*/}
            {/*        }}*/}
            {/*        history={history}*/}
            {/*    />*/}
            {/*</MapPopUpListener>*/}
        </>
    );
};

export default Markers;
