import { config } from 'config';

const apiDataBusUrl = () => config.get('urls').apiDataBusUrl;

const apiUrls = {
    getCollectionServices: () => `${apiDataBusUrl()}/collection-service/list`,
    
    getCollectionServicesTypes: () => `${apiDataBusUrl()}/collection-service/types`,

    getCollectionServicesTemplates: () => `${apiDataBusUrl()}/collection-service/templates`,

    createCollectionService: () => `${apiDataBusUrl()}/collection-service`,

    editCollectionService: (id) => `${apiDataBusUrl()}/collection-service/${id}`,

    deleteCollectionService: (id) => `${apiDataBusUrl()}/collection-service/${id}`,

    getCollectionServicesServices: () => `${apiDataBusUrl()}/collection-service/services`,

    getJobs: () => `${apiDataBusUrl()}/job/list`,

    createJob: () => `${apiDataBusUrl()}/job`,

    editJob: (id) => `${apiDataBusUrl()}/job/${id}`,

    getJobsPeriod: () => `${apiDataBusUrl()}/job/period`,

};

export default apiUrls;