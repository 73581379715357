import { useState } from 'react';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';

import Form from './Form';

import type { FilterParamsType } from './types';

type FilterProps = {
  setParams: React.Dispatch<React.SetStateAction<FilterParamsType>>
};

const initState: FilterParamsType['data'] = {
    from_created_at: null,
    to_created_at: null,
    from_updated_at: null,
    to_updated_at: null,
    type: null,
    class: null,
    road_name: '',
    road_number: '',
};

export const Filter = ({ setParams }: FilterProps) => {
    const [filterData, setFilterData] = useState<FilterParamsType['data']>(initState);

    const resetFilter = (needRefresh: boolean) => {
        setFilterData(initState);

        if(needRefresh) {
            setParams({
                page: 1,
                data: {}
            });
        }
    };

    const search = () => {
        const prepareData: FilterParamsType['data'] = removeEmptyFields({
            ...filterData,
            from_created_at: fullDateTimeWithTimeZone(filterData?.from_created_at),
            to_created_at: fullDateTimeWithTimeZone(filterData?.to_created_at),
            from_updated_at: fullDateTimeWithTimeZone(filterData?.from_updated_at),
            to_updated_at: fullDateTimeWithTimeZone(filterData?.to_updated_at),
            type: filterData?.type?.name,
            class: filterData?.class?.name,
        }, false);

        setParams({ page: 1, data: prepareData });
    };

    const updateFilter = (filters: Partial<FilterParamsType['data']>) => {
        setFilterData({ ...initState, ...filters });

        if (Object.keys(filters).length) {
            search();
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={search}
            filter={filterData}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <Form
                    data={filterData}
                    onChange={(newFields) => setFilterData((old) => ({ ...old, ...newFields }))}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};
