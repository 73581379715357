import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { adminSelectors } from 'redux/Admin';
import { loadServiceVersions } from 'redux/Admin/actions';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';

import Item from './Item';
import Filters from './Filters';

import type { MicroserviceVersionFilters, MicroserviceVersionItem } from 'types/Admin/MicroserviceVersions';

const MicroserviseVersions = () => {
    const dispatch = useDispatch();

    const initialState: MicroserviceVersionFilters = {
        microservice: '',
        start_at: null,
        end_at: null,
        tag: ''
    };

    const list: MicroserviceVersionItem[] = useSelector(adminSelectors.serviceVersionsData);
    const meta = useSelector(adminSelectors.serviceVersionsMeta);
    const loading = useSelector(adminSelectors.loadingServiceVersions);

    const [limit, setLimit] = useState<number>(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState<{ page: number; query: MicroserviceVersionFilters}>({
        page: 1,
        query: initialState
    });

    useEffect(() => {
        dispatch(loadServiceVersions(params.page, limit, removeEmptyFields(params.query)));
    }, [dispatch, params, limit]);

    const renderContent = () => {
        return list.length > 0
            ? <LSContainer
                headers={[
                    { title: 'Микросервис', width: '30%' },
                    { title: 'Версия', width: '10%' },
                    { title: titles.DATE_TIME_CREATED, width: '30%' },
                ]}
            >
                {list.map((item) => (
                    <Item
                        key={item.id} 
                        item={item}
                    />
                ))}
            </LSContainer>
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        ;
    };

    return (
        <PageLayout
            header="Версионность микросервисов"
            filters={<Filters
                setParams={setParams}
                initialState={initialState}
            />}
            informPanelProps={{ total: meta?.total }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit,
                setLimit
            }}
        />
    );
};

export default MicroserviseVersions;
