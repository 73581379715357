import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious, useStoreFromSelectorListToObject, useStoreProp } from 'helpers/hooks';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { ContextMenu, ContextMenuItem, Marker } from 'components/MapComponents/leaflet';
import { MapPopUpListener } from 'components/MapComponents/MapPopUp';
import MapLegends from 'components/common/Transport/MapLegends';
import { reactSelectors } from 'modules/React';
import * as actions from 'modules/React/redux/actions';

import config from './config';
import { createIconMarker, getFilter } from './helper';
import PopUpModal from './PopUpModal';
import CollectorForms from './CollectorForms';
import Legend from './Legend';

const Layer = (props) => {
    const { map, filter: filterProp, visibleLegend = false } = props;
    const dispatch = useDispatch();

    const incidentPolygon = useSelector(reactSelectors.incidentPolygon);
    const filtersSelector = useSelector(reactSelectors.filters);
    const saved = useSelector(reactSelectors.saved);
    const active = useSelector(reactSelectors.active);
    const filters =  filterProp || filtersSelector;
    const prevFilters = usePrevious(filters);

    const statuses = useStoreFromSelectorListToObject(
        actions.loadIncidentsStatuses,
        reactSelectors.incidentsStatuses
    );

    const threat_levels = useStoreProp(actions.loadThreatLevels, 'react', 'threatLevels');

    const fetchPolygon = useCallback(() => {
        const filter = getFilters(filters, getFilter);
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.loadIncidentPolygon(polygon, filter));
    },[dispatch, filters, map]);

    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = useCallback(() => debounceFetchPolygon(), [debounceFetchPolygon]);

    useEffect(() => {
        fetchPolygon();
    }, [fetchPolygon]);

    useEffect(() => () => {
        dispatch(actions.clearIncidentPolygon());
        dispatch(actions.clearActive());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters, map]);

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            fetchPolygon();
        }
    }, [saved]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon]);
            }
        }
    }, [active]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, ({ latlng : { lat, lng } }) => {
            dispatch(actions.setEditForm({ lat, lon: lng }));
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProps }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProps}
                    value="Редактировать инцидент"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setEditForm(item));
                    }}
                />
                {/* <ContextMenuItem
                    {...rcmProps}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                /> */}
            </div>
        );
    };

    const polygonData = useMemo(() => incidentPolygon?.map((item) => {
        const { id, lat, lon, status_id, threat_level_id } = item;

        const { color, name }  = item.threat_level || threat_levels.data.find(el => el.id === threat_level_id);
        const status_text = item.status_text || statuses.find(el => el.id === status_id);
        item.status_text = status_text;
        item.threat_level_color = color;
        item.threat_level_name = name;
        const componentProps = {
            ...props,
            key: `${config.slug}_${id}`,
            // для кластера
            attribution: {
                slug: config.slug,
                color,
            },
            onClick: () => {
                dispatch(actions.setActive(item));
            },
        };

        return (
            <Marker
                {...componentProps}
                latlng={[lat, lon]}
                icon={createIconMarker(color)}
            >
                <ContextMenu>
                    <RenderContextMenu item={item} />
                </ContextMenu>
            </Marker>
        );
    }), [incidentPolygon]);

    return (
        <>
            {polygonData}
            <MapPopUpListener
                activeSelector={reactSelectors.active}
                polygonSelector={reactSelectors.incidentPolygon}
            >
                <PopUpModal
                    onClose={() => { dispatch(actions.clearActive()); }}
                    statuses={statuses}
                />
            </MapPopUpListener>

            <CollectorForms />

            {/* легенда */}
            <MapLegends
                layer={config.slug}
                visibleRequired={visibleLegend}
            >
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
