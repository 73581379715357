import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actionsDorisControl from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import PassportTabs from 'components/pages/Dictionaries/DorisControl/VFComplex/PassportPage/PassportTabs';

// кнопки в модалке
const ModalButtons = ({
    data,
    history,
    showButtons = true,
    disabledButtons = false,
    onEdited,
}) => {

    const dispatch = useDispatch();

    const active = useSelector(dorisControlSelectors.active);

    const [activeTab, setIsActiveTab] = useState(null);
    const [isOpenPassport, setIsOpenPassport] = useState(false);

    const closePassport = () => {
        setIsOpenPassport(false);
        setIsActiveTab(null);
    };

    // открываем комплекс по tab
    useEffect(() => {
        const tab = active.tab;
        if (isOpenPassport === false && tab) {
            setIsActiveTab(tab);
            setIsOpenPassport(true);
            dispatch(actionsDorisControl.setActive({
                ...active,
                tab: null
            }));
        }
    },[active, isOpenPassport, data, dispatch]);

    const buttons = showButtons
        ? {
            buttons: [
                {
                    ...buttonsTypes.add,
                    name: 'Паспорт',
                    onClick: () => {
                        // setIsActiveTab('data');
                        setIsOpenPassport(true);
                    },
                    disabled: disabledButtons
                },
                {
                    ...buttonsTypes.add,
                    name: 'Отчет по событиям',
                    // onClick: onClickShowReport, // переход на страницу Отчеты с id комплекса в урле
                    onClick: () => {
                        setIsActiveTab('report');
                        setIsOpenPassport(true);
                    },
                    disabled: disabledButtons
                }
            ]
        }
        : {};

    return (
        <>
            <FormButtons
                {...buttons}
            />

            {isOpenPassport && (
                <Modal
                    isOpen={isOpenPassport}
                    onClose={closePassport}
                    noPadding={true}
                    title={data?.name}
                    fullWidth
                    showCloseInTitle
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: closePassport
                            },
                        ]}
                    />}
                >
                    <PassportTabs
                        callback={closePassport}
                        history={history}
                        item={data}
                        tab={ activeTab }
                        onEdited={onEdited}
                        readOnly={data.external_id}
                    />
                </Modal>
            )}
        </>
    );
};

export default ModalButtons;
