import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Autocomplete, TextField } from '@mui/material';

import * as actions from 'redux/Reports/actions';
import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import styles from './getReport.module.scss';

const GetReports = ({
    onDownload,
    disabled = false,
    multiple = true,
    // showType = true,
    helperText,
    selected = [],
    onChange,
    reportId,
    required = false,
    newApi =  false, // новый метод получения списка форматов
    test_id_prefix = ''
}) => {
    const dispatch = useDispatch();

    const { formats } = useSelector(({ reports }) => reports);
    // const formats = useStoreProp(actions.loadFormats, 'reports', 'formats');
    // const templates = useStoreProp(actions.loadTemplates, 'reports', 'templates');

    useEffect(() => {
        if (!disabled && reportId) {
            newApi
                ? dispatch(actions.loadFormatsNew({ ...(reportId && { id: reportId }) }))
                : dispatch(actions.loadFormats({ ...(reportId && { id: reportId }) }));
        }

    }, [disabled, dispatch, reportId, newApi]);


    const formatList = Object.keys(formats)
        .reduce((res, key) => [ ...res, { id: key, name: formats[key] } ], []);

    const handleDownload = () => {
        onDownload(selected.map(({ id }) => id));
    };

    return (
        <div className="row noMargin">
            <Autocomplete
                // required={required}
                noOptionsText={titles.NO_RESULTS}
                disabled={disabled}
                multiple={multiple}
                value={selected}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={formatList}
                filterSelectedOptions
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, values) => onChange(values)}
                size="small"
                openText={'Открыть'}
                closeText={'Закрыть'}
                clearText={'Очистить'}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label={titles.FORMAT}
                        helperText={helperText}
                        InputProps={{
                            ...params.InputProps,
                            'data-testid': `${test_id_prefix}:format/input`,
                        }}
                        required={required}
                        error={required && selected?.length === 0}
                    />
                )}
                className={cn(styles.item, 'row__item')}
            />

            {onDownload
                && <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.getReport,
                            name: 'Получить',
                            onClick: handleDownload,
                            disabled: selected.length === 0 || disabled,
                            testName: 'get-report'
                        }
                    ]}
                    test_id_prefix={test_id_prefix}
                    positionLeft
                    noPadding
                    noMarginLeft
                    justButton
                />
            }
        </div>
    );
};

export default GetReports;
