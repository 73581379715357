import { useState } from 'react';

import { loadComplexTypes } from 'redux/DorisControl/actions';
import { useStoreProp } from 'helpers/hooks';
import ComplexTypeSelect from 'components/common/SimpleSelect/index';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter/index';
import { PageReportFields, useReportTemplatesFrontendLinks } from 'components/common/PageReport';
import removeEmptyFields from 'helpers/removeEmptyFields';

interface ComplexType {
    id: number;
    name: string;
}

interface Format {
    id: string;
    name: string;
}

interface DataState {
    complex_type: ComplexType | null;
    formats: Format[];
}

const Filter = () => {
    const templateProvider = useReportTemplatesFrontendLinks();
    const reportFilter = templateProvider.filter;
    
    const complexTypes = useStoreProp(
        loadComplexTypes,
        'dorisControl',
        'doris_complex_types'
    );

    const initialState: DataState = {
        complex_type: null,
        formats: [],
    };

    const [data, setData] = useState<DataState>(initialState);

    const handleChange = (name: keyof DataState, value: any) => {
        setData({ ...data, [name]: value });
    };

    const handleLoadReport = () => {
        const paramsWithoutEmpty = removeEmptyFields({
            ...data,
            type_id: data.complex_type?.id,
            complex_type: null,
            formats: data.formats.map(item => item.id),
        }, false);

        const { url } = reportFilter.getToApi();
        //@ts-ignore
        const { formats } = paramsWithoutEmpty;
        templateProvider.loadReport(url, formats, paramsWithoutEmpty);
    };

    const resetFilter = () => {
        setData(initialState);
    };

    const updateFilter = (filter: Partial<DataState>) => {
        setData({
            ...initialState,
            ...filter
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={handleLoadReport}
            filter={data}
            setUserFilter={updateFilter}
            disabled={(!data.complex_type && !data.formats.length)}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <ComplexTypeSelect
                    options={complexTypes}
                    selected={data.complex_type}
                    onChange={(value) => handleChange('complex_type', value)}
                    label="Тип комплекса"
                    renderLabel={(option) => option?.name || ''}
                />
                <PageReportFields
                    reportType={reportFilter.type}
                    reportFormats={data.formats}
                    onReportType={reportFilter.setType}
                    onReportFormat={(values: Format[]) => setData({ ...data, formats: values })}
                    isOneTypeAuto
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
