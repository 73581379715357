import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadDVR } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import PageLayout from 'components/layout/PageLayout';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';
import titles from 'helpers/constants/titles';

import Item from './Item';
import ModalForm from './ModalForm';

import type { DVRItem } from 'types/RoadNetwork';

const DVR = () => {
    const dispatch = useDispatch();

    const data = useSelector(roadNetworksSelectors.DVRData);
    const meta = useSelector(roadNetworksSelectors.DVRMeta);
    const loading = useSelector(roadNetworksSelectors.loadingDVR);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = useCallback(() => {
        dispatch(loadDVR(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    return (
        <>
            <PageLayout
                header="Видеорегистратор"
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setOpenAddModal(true),
                            }]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={() => data?.length > 0
                    ? (
                        <LSContainer
                            headers={[
                                { title: titles.NAME, width: '25%' },
                                { title: 'IP-адрес', width: '25%' },
                                { title: 'Адрес', width: '25%' },
                                { title: 'Действия', isActions: true }
                            ]}
                        >
                            {data.map((item: DVRItem) => (
                                <Item
                                    key={item?.id}
                                    item={item}
                                    reloadList={reloadList}
                                />
                            ))}
                        </LSContainer>
                    )
                    : !loading && <NoData/>
                }
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default memo(DVR);
