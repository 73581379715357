import L from 'leaflet';

import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import { getMarker } from 'components/MapComponents/marker/Marker';

import config from './config';

export const createIconMarker = (color = '#000', icon = config.layerIcon) => L.divIcon({
    className: 'marker',
    iconSize: [30, 36],
    iconAnchor: [15, 36],
    popupAnchor: [0, -36],
    html: `
        ${getMarker(color)}
        <i class="${icon}"
           style="width:100%;color:#fff;position:absolute;left:1px;top:0;height:calc(100% - 5px);display:flex;justify-content:center;align-items:center;font-size:1rem;"
        />
    `,
});

export const getFilter = (filter = {}) => {
    const newFilter = {
        ...filter,
        start_date: fullDateTimeWithTimeZone(filter.start_date),
        end_date: fullDateTimeWithTimeZone(filter.end_date),
    };

    return removeEmptyFields(newFilter, false);
};