import { useCallback, useEffect, useState } from 'react';

import { useStoreProp } from 'helpers/hooks';
import {
    loadIncidentsStatuses,
} from 'modules/React/redux/actions';

export const useAvailableStatuses = (scenario_id: number | null | undefined, statusId: number) => {
    const [availalbleStatuses, setAvailableStatuses] = useState<any[]>([]);

    const statuses = useStoreProp(loadIncidentsStatuses, 'react', 'incidentsStatuses');

    const checkStatus = useCallback((name: 'Обработан' | 'В работе' | 'Завершен' | 'Новый' | 'Отказ'): boolean => {
        return statusId === statuses.find((el: any) => el.name === name)?.id;
    }, [statuses, statusId]);

    useEffect(() => {
        const statusList = [];

        ((scenario_id && checkStatus('Обработан')) || (!scenario_id && checkStatus('В работе')))
          && statusList.push(statuses.find((el: any) => el.name === 'Завершен'));

        checkStatus('Новый') && statusList.push(statuses.find((el: any) => el.name === 'Отказ'));

        setAvailableStatuses(statusList);
    }, [statuses, scenario_id, statusId, checkStatus]);

    return availalbleStatuses;
};