import { useState } from 'react';

import messages from 'helpers/constants/messages';
import TransportIncidentInfoModal from 'components/pages/Reports/DTP/Incidents/TransportIncidentInfoModal';

const Information = ({ data, list = {}, hideEmpty = false }) => {
    const [openTIInfoModal, setOpenTIInfoModal] = useState(false);

    return (
        <>
            {Object.keys(list).map((key) => {
                const value = data[key];
                const result = list[key]?.value
                    ? list[key]?.modal
                        ? list[key]?.value(value, () => setOpenTIInfoModal(true))
                        : list[key]?.value(value, data)
                    : value || (hideEmpty === false ? messages.INFO_IS_NOT_FOUND : null);

                if (hideEmpty === true && !result) return null;
                
                return (
                    <div className="info__item" key={`information_${key}`}>
                        <strong className="info__item-title">
                            {list[key].title || ''}
                            {': '}
                        </strong>
                        <span>
                            {result}
                        </span>
                    </div>
                );
            })}
            {openTIInfoModal && (
                <TransportIncidentInfoModal
                    transportIncidentId={data?.ti_id}
                    onClose={() => setOpenTIInfoModal(false)}
                />
            )}
        </>
    );
};

export default Information;
