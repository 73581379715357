import { useState } from 'react';
import { TextField } from '@mui/material';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectMicroservices from 'components/common/Autocomplete/Microservices';
import DateRange from 'components/common/Dates/DateRange';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';

import type { MicroserviceVersionFilters } from 'types/Admin/MicroserviceVersions';

interface FilterProps {
    setParams: (data: { page: number, query: MicroserviceVersionFilters }) => void;
    initialState: MicroserviceVersionFilters;
}

const Filters = ({ setParams, initialState }: FilterProps) => {

    const [filters, setFilters] = useState(initialState);

    const onChange = (name: string, value: any) => {
        setFilters({ ...filters, [name]: value });
    };

    const handleSearch = (data = filters) => {
        const prepareData = removeEmptyFields({
            ...data,
            microservice: data?.microservice?.key || '',
            start_at: fullDateTimeWithTimeZone(data?.start_at),
            end_at: fullDateTimeWithTimeZone(data?.end_at)
        }, false);

        setParams({ page: 1, query: prepareData });
    };

    const handleReset = (needRefresh: boolean) => {
        setFilters(initialState);
        if (needRefresh) {
            setParams({ page: 1, query: {} });
        }
    };

    const updateFilter = (filters: MicroserviceVersionFilters) => {
        setFilters({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            handleSearch({
                ...initialState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={handleReset} 
            onSearch={handleSearch} 
            filter={filters} 
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filters.start_at || null}
                    valueEndDate={filters.end_at || null}
                    handleDate={(e) => onChange('start_at', e)}
                    handleEndDate={(e) => onChange('end_at', e)}
                />
                <SelectMicroservices
                    selected={filters.microservice}
                    onChange={(value) => onChange('microservice', value)}
                />
                <TextField
                    value={filters.tag}
                    onChange={(e) => onChange('tag', e.target.value)}
                    label="Версия"
                    variant="outlined"
                    size="small"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
