import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadVehicleCategories,
    setActive,
} from 'redux/TransportSpecial/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import * as actions from 'redux/TransportSpecial/actions';
import { loadCurrentCompany } from 'redux/Companies/actions';
import { companiesSelectors } from 'redux/Companies';
import { getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import titles from 'helpers/constants/titles';
import { MapPopUp } from 'components/MapComponents/MapPopUp';

import icon from '../icons/icon';
import config from '../config';

import TabInformation from './TabInformation';
import ModalButtons from './ModalButtons';

// import { getColorByStatus } from '../helper';

const PopUp = ({
    isOpen,
    uuid,
    history,
    onClose,
}) => {
    const dispatch = useDispatch();

    const currentCompany = useSelector(companiesSelectors.currentCompany);
    const wsData = useSelector(transportSpecialSelectors.wsData);
    const active = useSelector(transportSpecialSelectors.active);
    const [telemetryData] = useSelector(transportSpecialSelectors.telemetryReceiversData);

    const data = wsData[uuid]?.vehicle || null;
    const vehicleId = data?.id || null;

    useEffect(() => {
        if(data?.egts_receiver_id) {
            dispatch(actions.loadTelemetryReceivers({ id: data.egts_receiver_id }));
        }
    }, []);

    const telemetry_receiver = telemetryData?.name || '';

    const {
        external_id,
        in_registry = false,
        in_work_order = false,
        speed = 0,
        navigation_at = null,
    } = wsData[uuid] || {};

    const category_id = data?.category_id;
    const getTransportCategory = useTransportCategory(
        loadVehicleCategories,
        'transportSpecial',
        'vehicleCategories'
    );

    const {
        getImageTpl,
        color: categoryColor,
    } = getTransportCategory
        .getHelper
        .getCategory(category_id, in_registry, in_work_order);

    const categoryName = getTransportCategory
        .getHelper
        .getName(category_id);

    const dataInfo = {
        ...data,
        external_id,
        speed,
        navigation_at,
        categoryTS: categoryName || '',
        telemetry_receiver
    };

    // todo не ясно будет эта связь
    // useEffect(() => {
    //     if (dataInfo?.number) {
    //         dispatch(loadRoadWorkOrders(1, 25, { grz: [dataInfo.number.trim()] }));
    //     }
    // }, [dispatch]);

    // useEffect(() => {
    //     if (
    //         data
    //         && active?.serial_egts !== data?.serial_egts
    //         && active?.serial_yandex !== data?.serial_yandex
    //     ) {
    //         dispatch(setActive({
    //             ...data,
    //             isClick: true
    //         }));
    //     }
    // }, []);

    useEffect(() => {
        if (!currentCompany[dataInfo?.organization_id] && dataInfo?.organization_id) {
            dispatch(loadCurrentCompany(dataInfo.organization_id));
        }
    }, [dispatch, currentCompany, dataInfo?.organization_id]);

    const currentShow = external_id === active?.serial_egts
        || external_id === active?.serial_yandex
        || null;

    const titleHistory = () => {
        const title = [];
        const categoryNameCurrent = categoryName || config.name;

        title.push(categoryNameCurrent);
        const garage_number = data?.garage_number || null;
        if (garage_number) {
            title.push(`номер ${garage_number}`);
        } else if (uuid) {
            title.push(uuid);
        }
        return title.join(', ');
    };

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={getImageTpl('img', icon({ fill: '#000' }))}
            title={config.name}
            titleBackgroundColor={categoryColor}
            // titleBackgroundColor={
            //     currentShow
            //         ? getColorByStatus('active')
            //         : categoryColor
            // }
            medium
            onClose={onClose}
            buttons={
                <ModalButtons
                    vehicleId={vehicleId}
                    titleHistory={titleHistory}
                />
            }
        >
            <TabInformation
                history={history}
                data={dataInfo}
                title={{
                    external_id: {
                        title: 'Идентификатор'
                    },
                    number: {
                        title: 'Номер',
                    },
                    garage_number: {
                        title: 'Гаражный номер',
                        // value: (value) =>
                        //     value ? (
                        //         <span>{props.statuses[value]}</span>
                        //     ) : (
                        //         messages.INFO_IS_NOT_FOUND
                        //     ),
                    },
                    // name: {
                    //     title: 'Название',
                    // },
                    brand_name: {
                        title: 'Марка',
                    },
                    model_name: {
                        title: 'Модель',
                    },
                    categoryTS: {
                        title: titles.TYPE_OF_VEHICLE,
                    },
                    organization_id: {
                        title: 'Организация',
                        value: ({ organization_id }) =>
                            organization_id
                                ? (<span>{currentCompany[organization_id]?.title}</span>)
                                : (messages.INFO_IS_NOT_FOUND),
                    },
                    telemetry_receiver: {
                        title: 'Приемник/организация телеметрии',
                    },
                    speed: {
                        title: 'Последняя скорость',
                        value: ({ speed, navigation_at }) => `${speed} км/ч ${navigation_at ? `(на ${getHumanDate(navigation_at)})` : ''}`
                    },
                }}
            />
        </MapPopUp>
    );
};

export default PopUp;
