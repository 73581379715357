import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editPddViolation } from '../../../../../redux/DorisControl/actions';
import ConfirmDelete from '../../../../common/ConfirmModal';
import { renderArticleName } from '../../../../common/DorisControl/PddViolation/renderArticleName';
import Info from './Info';
import { ActionMore, LSContentColumn, LSContentItem } from '../../../../common/List';
import { ItemProps } from './types';
import Image from 'components/common/Image';
import { isValue } from 'helpers/checkValue';
import { checkBase64 } from 'components/MapComponents/Layers/TransportPassenger/helper';


const Item = ({ item, loadList }: ItemProps) => {
    const dispatch = useDispatch();

    const [openEditActiveModal, setOpenEditActiveModal] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    // const [openEditIconModal, setOpenEditIconModal] = useState(false);

    const handleChangeActive = () => {
        const prepareData = {
            ...item,
            is_active: !item?.is_active
        };

        const callback = () => {
            setOpenEditActiveModal(false);
            loadList();
        };

        dispatch(editPddViolation(item?.id, prepareData, callback));
    };

    const isInfo = item?.duplo_codes && item?.duplo_codes?.length > 0;

    return (
        <>
            {/*@ts-ignore*/}
            <LSContentItem
                {...(isInfo ? { onClick: () => setInfoOpen((prev) => !prev) } : {})}
            >
                <LSContentColumn>
                    <Checkbox
                        color={'success'}
                        checked={item?.is_active}
                        onChange={() => setOpenEditActiveModal(true)}
                    />
                </LSContentColumn>
                <LSContentColumn>
                    <div className="row align-items-center">
                        <Image
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                margin: 5,
                                width: '40px',
                                height: '40px',
                            }}
                            src={checkBase64(item?.icon)}
                            alt=""
                        />&nbsp;
                        {isValue(item?.id) && `${item.id} `}
                        {item?.events_to_gost && `${item.events_to_gost}: `}
                        {item?.name && `${item.name} `}
                    </div>
                </LSContentColumn>
                <LSContentColumn>
                    {renderArticleName(item)}
                </LSContentColumn>
                {isInfo 
                    &&  <ActionMore
                        isOpen={infoOpen}
                        // onClick={() => setInfoOpen(!infoOpen)}
                    />
                }
            </LSContentItem>
            {infoOpen && <Info isOpen={infoOpen} item={item} />}
            {openEditActiveModal && (
                <ConfirmDelete
                    open={openEditActiveModal}
                    onClose={() => setOpenEditActiveModal(false)}
                    onSuccess={handleChangeActive}
                    message={`Вы уверены, что хотите сделать эту статью ${item?.is_active ? 'неактивной' : 'активной'}?`}
                />
            )}
            {/* {openEditIconModal && (
                <EditIconModal
                    isOpen={openEditIconModal}
                    onClose={() => setOpenEditIconModal(false)}
                    item={item}
                    loadList={loadList}
                />
            )} */}
        </>
    );
};

export default Item;
