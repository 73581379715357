import { useDispatch } from 'react-redux';

import * as actions from 'redux/TrafficFlow/actions';
import messages from 'helpers/constants/messages';
import colorExtend from 'helpers/mapHelper/colorExtend';
import Information from 'components/common/Information';
import MapScroll from 'components/MapComponents/MapScroll';
import {
    MapPopUp,
} from 'components/MapComponents/MapPopUp';

import icon from '../icons/icon';

import fields from './fields';
import Traffic from './Traffic';
import ModalButtons from './ModalButtons';

const PopUp = ({
    data: currentData = {},
    statuses,
    types,
}) => {

    const dispatch = useDispatch();

    return (
        <MapPopUp
            isOpen={!!currentData}
            icon={<span className="inner-icon" dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}/>}
            title={currentData?.name || messages.INFO_IS_NOT_FOUND}
            onClose={() => dispatch(actions.resetActive())}
            titleBackgroundColor={colorExtend(currentData.color)}
            medium
            buttons={
                <ModalButtons
                    uuid={currentData?.id}
                />
            }
        >
            <MapScroll>
                <Information
                    data={currentData}
                    title={fields(statuses, types)}
                />

                <Traffic
                    id={currentData?.id}
                />
            </MapScroll>
        </MapPopUp>
    );
};

export default PopUp;
