import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import { deleteCollectionService, loadCollectionServicesList } from 'modules/DataBus/redux/actions';

import ModalForm from './ModalForm';

import type { CollectionService, Templates, Services } from './types';

interface ItemProp {
    item: CollectionService;
    services: Services[];
    templates: Templates[];
}

const Item = ({ item, services, templates }: ItemProp) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleDelete = () => {
        dispatch(deleteCollectionService(item.id, () => {
            dispatch(loadCollectionServicesList());
            setDeleteModalOpen(false);
        }));
    };

    const renderTemplates = () => templates?.find(el => el.type_id === item.type_id)?.template_list[0].name;

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.service_name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {renderTemplates() || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setModalOpen(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setDeleteModalOpen(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {deleteModalOpen
                && <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={deleteModalOpen}
                    onSuccess={handleDelete}
                    onClose={() => setDeleteModalOpen(false)}
                />
            }
            {modalOpen
                && <ModalForm
                    open={modalOpen}
                    data={item}
                    onClose={() => setModalOpen(false)}
                    isNew={false}
                    reloadList={() => dispatch(loadCollectionServicesList())}
                    services={services}
                    templates={templates}
                />
            }
        </>
    );
};

export default Item;
