import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, IconButton, Tooltip } from '@mui/material';

import { notificationsSelectors } from 'redux/Notifications';
import titles from 'helpers/constants/titles';
import { useNotifications } from 'helpers/hooks';

import NotificationModal from './NotificationModal';

function Notifications() {
    const notifications = useNotifications();

    const countActive = useSelector(notificationsSelectors.countActive);

    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(true);
        notifications.loadCountActive();
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        notifications.loadCountActive();
    }, [notifications]);

    return (
        <>
            <Badge
                overlap="circular"
                max={1000}
                badgeContent={countActive}
                color="error"
                onClick={handleClick}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Tooltip title={titles.NOTIFICATIONS}>
                    <IconButton style={{ width: 34 }} size="small">
                        <i
                            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 24 }}
                            className="far fa-bell"
                        />
                    </IconButton>
                </Tooltip>
            </Badge>

            {isOpen && <NotificationModal isOpen={isOpen} onClose={handleClose} />}
        </>
    );
}

export default Notifications;
