import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */

export const LOAD_VIDEO_CAMERAS_LIST = `${prefix}/LOAD/VIDEO/CAMERAS/LIST`;
export const LOADING_VIDEO_CAMERAS_LIST = `${prefix}/LOADING/VIDEO/CAMERAS/LIST`;
export const LOADED_VIDEO_CAMERAS_LIST = `${prefix}/LOADED/VIDEO/CAMERAS/LIST`;
export const CREATE_VIDEO_CAMERA = `${prefix}/CREATE/VIDEO/CAMERA`;
export const EDIT_VIDEO_CAMERA = `${prefix}/EDIT/VIDEO/CAMERA`;
export const DELETE_VIDEO_CAMERA = `${prefix}/DELETE/VIDEO/CAMERA`;
export const LOAD_STATUSES_VIDEO_CAMERA = `${prefix}/LOAD/STATUSES/VIDEO/CAMERA`;
export const LOADED_STATUSES_VIDEO_CAMERA = `${prefix}/LOADED/STATUSES/VIDEO/CAMERA`;
export const CHANGE_VIDEO_CAMERA_STATUS = `${prefix}/CHANGE/VIDEO/CAMERA/STATUS`;
export const LOAD_TYPES_VIDEO_CAMERA = `${prefix}/LOAD/TYPES/VIDEO/CAMERA`;
export const LOADED_TYPES_VIDEO_CAMERA = `${prefix}/LOADED/TYPES/VIDEO/CAMERA`;

export const GET_POLYGON = `${prefix}/GET/POLYGON/VIDEO/CAMERA`;
export const SAVE_POLYGON = `${prefix}/SAVE/POLYGON/VIDEO/CAMERA`;
export const LOADING_SIDEBAR = `${prefix}/LOADING/SIDEBAR/VIDEO/CAMERA`;
export const LOADED_SIDEBAR = `${prefix}/LOADED/SIDEBAR/VIDEO/CAMERA`;
export const LOAD_SIDEBAR = `${prefix}/LOAD/SIDEBAR/VIDEO/CAMERA`;
export const SET_ACTIVE = `${prefix}/SET/ACTIVE/VIDEO/CAMERA`;
export const SET_DELETE_FORM = `${prefix}/SET/DELETE/FORM/VIDEO/CAMERA`;
export const SET_EDIT_FORM = `${prefix}/SET/EDIT/FORM/VIDEO/CAMERA`;
export const SAVED = `${prefix}/SAVED/VIDEO/CAMERA`;
export const SAVED_CAMERA = `${prefix}/SAVED/CAMERA`;
export const SET_FILTER = `${prefix}/SET/FILTER/VIDEO/CAMERA`;
export const SET_FILTER_GROUP = `${prefix}/SET/FILTER/GROUP/VIDEO/CAMERA`;
export const LOAD_VIDEO_CAMERA_FRAME = `${prefix}/LOAD/VIDEO/CAMERA/FRAME`;
export const LOADED_VIDEO_CAMERA_FRAME = `${prefix}/LOADED/VIDEO/CAMERA/FRAME`;
export const LOADING_VIDEO_CAMERA_FRAME = `${prefix}/LOADING/VIDEO/CAMERA/FRAME`;
export const LOAD_ACTIVE_FRAME = `${prefix}/LOAD/ACTIVE/FRAME`;

export const LOAD_CAMERA_GROUP_LIST = `${prefix}/LOAD/CAMERA/GROUP/LIST`;
export const LOADING_CAMERA_GROUP_LIST = `${prefix}/LOADING/CAMERA/GROUP/LIST`;
export const LOADED_CAMERA_GROUP_LIST = `${prefix}/LOADED/CAMERA/GROUP/LIST`;
export const CREATE_CAMERA_GROUP = `${prefix}/CREATE/CAMERA/GROUP`;
export const EDIT_CAMERA_GROUP = `${prefix}/EDIT/CAMERA/GROUP`;
export const DELETE_CAMERA_GROUP = `${prefix}/DELETE/CAMERA/GROUP`;

export const LOAD_CAMERA_TEMPLATE_LIST = `${prefix}/LOAD/CAMERA/TEMPLATE/LIST`;
export const LOADING_CAMERA_TEMPLATE_LIST = `${prefix}/LOADING/CAMERA/TEMPLATE/LIST`;
export const LOADED_CAMERA_TEMPLATE_LIST = `${prefix}/LOADED/CAMERA/TEMPLATE/LIST`;
export const CREATE_CAMERA_TEMPLATE = `${prefix}/CREATE/CAMERA/TEMPLATE`;
export const EDIT_CAMERA_TEMPLATE = `${prefix}/EDIT/CAMERA/TEMPLATE`;
export const DELETE_CAMERA_TEMPLATE = `${prefix}/DELETE/CAMERA/TEMPLATE`;

export const LOAD_VIDEOCAMERAS_BY_RADIUS = `${prefix}/LOAD/VIDEOCAMERAS/BY/RADIUS`;
export const LOADED_VIDEOCAMERAS_BY_RADIUS = `${prefix}/LOADED/VIDEOCAMERAS/BY/RADIUS`;

export const LOAD_VIDEOCAMERAS_LIVE = `${prefix}/LOAD/VIDEOCAMERAS/LIVE`;
export const DOWNLOAD_VIDEO = `${prefix}/DOWNLOAD_VIDEO`;
export const LOAD_VIDEO_RECORD = `${prefix}/LOAD_VIDEO_RECORD`;

export const LOADED_CAMERA_BY_ID = `${prefix}/LOADED_CAMERA_BY_ID`;
