import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import { putUserName, saveAuthUserData } from 'redux/Auth/actions';
import { authSelectors } from 'redux/Auth';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';


const EditModal = ({
    isOpen,
    onClose,
    isDisabled = false
}) => {
    const dispatch = useDispatch();
    const user = useSelector(authSelectors.authUserInfo);

    const [isMapMerge, setIsMapMerge] = useState(user.is_map_merge);
    const [isShowLegend, setIsShowLegend] = useState(user.is_show_legend);

    const handleClose = () => {
        onClose?.();
    };

    const handleSave = () => {
        dispatch(saveAuthUserData({ ...user, is_map_merge: isMapMerge, is_show_legend: isShowLegend }));
        dispatch(putUserName({ is_map_merge: isMapMerge, is_show_legend: isShowLegend }, user.id, handleClose));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            showCloseInTitle
            noPadding
            title="Настройки"
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
            
        >
            <FormGroup sx={{ px: '40px' }}>
                <FormControlLabel control={
                    <Checkbox checked={isMapMerge} onChange={() => setIsMapMerge(!isMapMerge)}/>
                } label="Закреплять слои карты сразу" />
                <FormControlLabel control={
                    <Checkbox checked={isShowLegend} onChange={() => setIsShowLegend(!isShowLegend)}/>
                } label="Показывать легенду" />
            </FormGroup>
        </Modal>
    );
};

export default EditModal;
