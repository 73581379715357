import { IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import messages from 'helpers/constants/messages';

const RelationItem = ({ item, index, relationTypes, handleClick }) => {
    
    return (
        <ListItem className="list_item">
            <ListItemIcon>
                {index + 1}.
            </ListItemIcon>
            <ListItemText>
                <b>{relationTypes[item.entity_type]}: </b>{item?.entity_data.name || messages.NO_DATA}
            </ListItemText>
            <Tooltip title="Удалить связь">
                <IconButton onClick={() => handleClick(item, false)} size="small">
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </ListItem>
    );
};

export default RelationItem;