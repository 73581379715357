import { Collapse } from '@mui/material';
import { ListItem, ListItemText } from '@mui/material/';

import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import commonTitles from 'helpers/constants/titles';
import { getTypeText } from 'modules/DigitalTwin/utils/helpers';
import prepareCoords from 'helpers/prepareCoords';

import titles from '../../utils/constants';

import type { Node as NodeType } from '../../utils/types';
import type { Edge } from '../../utils/types';

function Node({ node }: { node: NodeType }) {
    return (
        <ListItem>
            <ListItemText>
                <div>
                    <b>{commonTitles.DATE_TIME_CREATED}: </b>{' '}
                    {getFullDateTimeWithTextMonth(node.created_at) || messages.NO_VALUE}
                </div>
                <div>
                    <b>{commonTitles.TYPE}: </b> {getTypeText(node.type) || messages.NO_VALUE}
                </div>
                <div>
                    <b>{commonTitles.COORDINATES}: </b>{' '}
                    {node.lat && node.lon
                        ? `${prepareCoords(node.lat, 6)} / ${prepareCoords(node.lon, 6)}`
                        : messages.NO_VALUE}
                </div>
            </ListItemText>
        </ListItem>
    );
}

interface InfoProps {
    // isOpen: boolean;
    item: Edge;
}

function Info({ item }: InfoProps) {
    const prevNode = item.nodes.find((node) => node.id === item.previous_node_id);
    const nextNode = item.nodes.find((node) => node.id === item.next_node_id);

    return (
        <Collapse className="info" in timeout={'auto'} style={{ padding: 16, backgroundColor: '#f5f5f5' }}>
            {prevNode && (
                <>
                    <b>{titles.PREVIOUS_NODE}:</b>
                    <Node node={prevNode} />
                </>
            )}

            {nextNode && (
                <>
                    <b>{titles.NEXT_NODE}:</b>
                    <Node node={nextNode} />
                </>
            )}
        </Collapse>
    );
}

export default Info;
