import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOAD_STATIONS = `${prefix}/LOAD/STATIONS`;
export const LOADING_STATIONS = `${prefix}/LOADING/STATIONS`;
export const LOADED_STATIONS = `${prefix}/LOADED/STATIONS`;
export const EDIT_STATION = `${prefix}/EDIT/STATION`;
export const DELETE_STATION = `${prefix}/DELETE/STATION`;
export const CREATE_NEW_STATIONS = `${prefix}/CREATE/NEW/STATION`;
export const LOAD_SCHEDULE = `${prefix}/LOAD/SCHEDULE`;
export const LOADING_SCHEDULE = `${prefix}/LOADING/SCHEDULE`;
export const LOADED_SCHEDULE = `${prefix}/LOADED/SCHEDULE`;
export const LOAD_ROUTE_TYPES = `${prefix}/LOAD/TYPES`;
export const LOADED_ROUTE_TYPES = `${prefix}/LOADED/TYPES`;
export const LOAD_BRANDS = `${prefix}/LOAD/BRANDS`;
export const LOADING_BRANDS = `${prefix}/LOADING/BRANDS`;
export const LOADED_BRANDS = `${prefix}/LOADED/BRANDS`;
export const CREATE_BRAND = `${prefix}/CREATE/BRAND`;
export const EDIT_BRAND = `${prefix}/EDIT/BRAND`;
export const DELETE_BRAND = `${prefix}/DELETE/BRAND`;
export const LOAD_VEHICLES_LIST = `${prefix}/VEHICLES/LOAD`;
export const DELETE_VEHICLE = `${prefix}/VEHICLE/DELETE`;
export const CREATE_VEHICLE = `${prefix}/VEHICLE/CREATE`;
export const EDIT_VEHICLE = `${prefix}/VEHICLE/EDIT`;
export const LOADED_VEHICLES_LIST = `${prefix}/VEHICLES/LOADED`;
export const LOADING_VEHICLES_LIST = `${prefix}/VEHICLES/LOADING`;
export const LOAD_VEHICLES_TYPES = `${prefix}/VEHICLES/TYPES/LOAD`;
export const LOADED_VEHICLES_TYPES = `${prefix}/VEHICLES/TYPES/LOADED`;
export const LOAD_VEHICLES_CATEGORIES = `${prefix}/VEHICLES/CATEGORIES/LOAD`;
export const LOADED_VEHICLES_CATEGORIES = `${prefix}/VEHICLES/CATEGORIES/LOADED`;
export const LOAD_VEHICLE_CATEGORY_LIST = `${prefix}/VEHICLE/CATEGORY/LIST/LOAD`;
export const LOADED_VEHICLE_CATEGORY_LIST = `${prefix}/VEHICLE/CATEGORY/LIST/LOADED`;
export const LOADING_VEHICLE_CATEGORY_LIST = `${prefix}/VEHICLE/CATEGORY/LIST/LOADING`;
export const CREATE_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/CREATE`;
export const EDIT_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/EDIT`;
export const DELETE_VEHICLE_CATEGORY = `${prefix}/VEHICLE/CATEGORY/DELETE`;
export const LOAD_VEHICLES_LIST_NO_WORKORDER = `${prefix}/LOAD/VEHICLES/NO/WORKORDER`;
export const LOADED_VEHICLES_LIST_NO_WORKORDER = `${prefix}/LOADED/VEHICLES/NO/WORKORDER`;
export const LOAD_MODELS = `${prefix}/LOAD/MODELS`;
export const LOADED_MODELS = `${prefix}/LOADED/MODEL`;
export const CREATE_MODEL = `${prefix}/CREATE/MODEL`;
export const EDIT_MODEL = `${prefix}/EDIT/MODEL`;
export const DELETE_MODEL = `${prefix}/DELETE/MODEL`;
export const LOAD_COMPANIES = `${prefix}/LOAD/COMPANIES`;
export const LOADED_COMPANIES = `${prefix}/LOADED/COMPANY`;
export const CREATE_COMPANY = `${prefix}/CREATE/COMPANY`;
export const EDIT_COMPANY = `${prefix}/EDIT/COMPANY`;
export const DELETE_COMPANY = `${prefix}/DELETE/COMPANY`;
export const GET_COMPANIES = `${prefix}/GET/COMPANIES`;
export const SAVE_COMPANIES = `${prefix}/SAVE/COMPANY`;
export const LOAD_COMPANY = `${prefix}/LOAD/COMPANY`;
export const LOADED_COMPANY = `${prefix}/LOADED/COMPANY`;
export const LOAD_ROUTE_NUMS = `${prefix}/LOAD/ROUTE/NUMS`;
export const LOADING_ROUTE_NUMS = `${prefix}/LOADING/ROUTE/NUMS`;
export const LOADED_ROUTE_NUMS = `${prefix}/LOADED/ROUTE/NUMS`;
export const LOAD_ROUTES_GROUP = `${prefix}/LOAD/ROUTES/GROUP`;
export const LOADING_ROUTES_GROUP = `${prefix}/LOADING/ROUTES/GROUP`;
export const LOAD_ROUTE_STATION = `${prefix}/LOAD/ROUTE/STATION`;
export const LOADING_ROUTE_STATION = `${prefix}/LOADING/ROUTE/STATION`;
export const LOADED_ROUTE_STATION = `${prefix}/LOADED/ROUTE/STATION`;
export const LOAD_ROUTES = `${prefix}/LOAD/ROUTES`;
export const LOADED_ROUTES = `${prefix}/LOADED/ROUTES`;
export const LOADED_ROUTES_FOR_TS = `${prefix}/LOADED/ROUTES/FOR/TS`;
export const LOADED_ROUTES_GROUP = `${prefix}/LOADED/ROUTES/GROUP`;
export const LOAD_ROUTES_STATUSES = `${prefix}/LOAD/ROUTES/STATUSES`;
export const LOADED_ROUTES_STATUSES = `${prefix}/LOADED/ROUTES/STATUSES`;
export const LOAD_ROUTES_TRANSPORTATIONS = `${prefix}/LOAD/ROUTES/TRANSPORTATIONS`;
export const LOADED_ROUTES_TRANSPORTATIONS = `${prefix}/LOADED/ROUTES/TRANSPORTATIONS`;
export const SAVE_ROUTES = `${prefix}/SAVE/ROUTES`;
export const SAVE_ROUTE = `${prefix}/SAVE/ROUTE`;
export const CHANGE_ROUTE = `${prefix}/CHANGE_ROUTE`;
export const SAVE_ROUTE_V2 = `${prefix}/SAVE_ROUTE_V2`;
export const CHANGE_ROUTE_V2 = `${prefix}/CHANGE_ROUTE_V2`;
export const SET_ROUTE_LOADING = `${prefix}/SET_ROUTE_LOADING`;
export const LOADING_ROUTE_SCHEDULE = `${prefix}/LOADING/ROUTE/SCHEDULE`;
export const LOADED_ROUTE_SCHEDULE = `${prefix}/LOADED/ROUTE/SCHEDULE`;
export const LOAD_ROUTE_SCHEDULE = `${prefix}/LOAD/ROUTE/SCHEDULE`;
export const LOADED_SCHEDULE_TYPES = `${prefix}/LOADED/SCHEDULE/TYPES`;
export const LOAD_SCHEDULE_TYPES = `${prefix}/LOAD/SCHEDULE/TYPES`;
export const ADD_SCHEDULE = `${prefix}/ADD/SCHEDULE`;
export const DELETE_SCHEDULE = `${prefix}/DELETE/SCHEDULE`;
export const EDIT_SCHEDULE = `${prefix}/EDIT/SCHEDULE`;
export const GET_FILTERED_LIST = `${prefix}/GET_FILTERED_LIST`;
export const LOADED_FILTERED_LIST = `${prefix}/LOADED_FILTERED_LIST`;
export const LOADING_FILTERED_LIST = `${prefix}/LOADING_FILTERED_LIST`;
export const SET_FILTERED_LIST_FILTER = `${prefix}/SET_FILTERED_LIST_FILTER`;
export const CLEAR_FILTERED_LIST_FILTER = `${prefix}/CLEAR_FILTERED_LIST_FILTER`;
export const GET_VEHICLE_REPORT = `${prefix}/GET_VEHICLE_REPORT`;
// export const GET_CURRENT_PASSENGER_REPORT = `${prefix}/GET/CURRENT/PASSENGER/REPORT`;
export const GET_CURRENT_SPECIAL_REPORT = `${prefix}/GET/CURRENT/SPECIAL/REPORT`;
export const LOADING_ROUTES = `${prefix}/LOADING/ROUTES`;
export const SET_ACTIVE = `${prefix}/SET/ACTIVE`;
export const CLEAR_ACTIVE = `${prefix}/CLEAR/ACTIVE`;
export const SET_WS_DATA = `${prefix}/SET/WS/DATA`;
export const CLEAR_WS_DATA = `${prefix}/CLEAR/WS/DATA`;
export const SET_MARKERS = `${prefix}/SET/MARKERS`;
export const CLEAR_MARKERS = `${prefix}/CLEAR/MARKERS`;
export const SET_MARKERS_EXTEND = `${prefix}/SET_MARKERS_EXTEND`;
export const CLEAR_MARKERS_EXTEND = `${prefix}/CLEAR_MARKERS_EXTEND`;
// station
export const LOAD_NEXT_STATION = `${prefix}/LOAD/NEXT/STATION`;
export const LOADED_NEXT_STATION = `${prefix}/LOADED/NEXT/STATION`;
export const LOADING_NEXT_STATION = `${prefix}/LOADING/NEXT/STATION`;
export const LOAD_STATION_TYPES = `${prefix}/LOAD/STATION/TYPES`;
export const LOADED_STATION_TYPES = `${prefix}/LOADED/STATION/TYPES`;
export const SAVE_ROUTE_STATIONS = `${prefix}/SAVE/ROUTE/STATIONS`;
export const LOAD_STATION_ATTRIBUTES = `${prefix}/LOAD/STATION/ATTRIBUTES`;
export const LOADED_STATION_ATTRIBUTES = `${prefix}/LOADED/STATION/ATTRIBUTES`;
// vehicle popup
export const LOAD_VEHICLE_POPUP = `${prefix}/LOAD/VEHICLE/POPUP`;
export const LOADED_VEHICLE_POPUP = `${prefix}/LOADED/VEHICLE/POPUP`;
export const LOADING_VEHICLE_POPUP = `${prefix}/LOADING/VEHICLE/POPUP`;
// history
export const LOAD_VEHICLE_HISTORY = `${prefix}/LOAD/VEHICLE/HISTORY`;
export const LOADING_VEHICLE_HISTORY = `${prefix}/LOADING/VEHICLE/HISTORY`;
export const LOADED_VEHICLE_HISTORY = `${prefix}/LOADED/VEHICLE/HISTORY`;
// flight history
export const LOAD_VEHICLE_FLIGHT_HISTORY = `${prefix}/LOAD/VEHICLE/FLIGHT/HISTORY`;
export const LOADING_VEHICLE_FLIGHT_HISTORY = `${prefix}/LOADING/VEHICLE/FLIGHT/HISTORY`;
export const LOADED_VEHICLE_FLIGHT_HISTORY = `${prefix}/LOADED/VEHICLE/FLIGHT/HISTORY`;
//
export const SET_FILTER = `${prefix}/SET/FILTER`;
export const CHANGE_FILTER = `${prefix}/CHANGE/FILTER`;
export const LOAD_CURRENT_VEHICLES = `${prefix}/LOAD/CURRENT/VEHICLES`;
export const LOADING_CURRENT_VEHICLES = `${prefix}/LOADING/CURRENT/VEHICLES`;
export const LOADED_CURRENT_VEHICLES = `${prefix}/LOADED/CURRENT/VEHICLES`;
export const EDIT_VEHICLE_TO_ROUTE = `${prefix}/EDIT/VEHICLE/TO/ROUTE`;
export const LOAD_CURRENT_VEHICLE_TYPE = `${prefix}/LOAD/CURRENT/VEHICLE/TYPE`;
export const LOADING_CURRENT_VEHICLE_TYPE = `${prefix}/LOADING/CURRENT/VEHICLE/TYPE`;
export const LOADED_CURRENT_VEHICLE_TYPE = `${prefix}/LOADED/CURRENT/VEHICLE/TYPE`;
export const SAVE_SELECTED_VEHICLE_TO_ROUTE = `${prefix}/SAVE/SELECTED/VEHICLE/TO/ROUTE`;
//UAPTS-1192:
export const LOADED_ROUTES_ = `${prefix}/LOADED_ROUTES_`;
export const LOAD_ROUTES_ = `${prefix}/LOAD_ROUTES_`;
// positions
export const LOAD_POSITIONS = `${prefix}/LOAD/POSITIONS`;
export const LOADING_POSITIONS = `${prefix}/LOADING/POSITIONS`;
export const LOADED_POSITIONS = `${prefix}/LOADED/POSITIONS`;
export const EDIT_POSITIONS = `${prefix}/EDIT/POSITIONS`;
export const ADD_POSITIONS = `${prefix}/ADD/POSITIONS`;
export const DELETE_POSITIONS = `${prefix}/DELETE/POSITIONS`;
// position
export const LOAD_POSITION = `${prefix}/LOAD/POSITION`;
export const LOADING_POSITION = `${prefix}/LOADING/POSITION`;
export const LOADED_POSITION = `${prefix}/LOADED/POSITION`;
// schedule events
export const LOAD_SCHEDULE_EVENTS = `${prefix}/LOAD/SCHEDULE/EVENTS`;
export const LOADING_SCHEDULE_EVENTS = `${prefix}/LOADING/SCHEDULE/EVENTS`;
export const LOADED_SCHEDULE_EVENTS = `${prefix}/LOADED/SCHEDULE/EVENTS`;
export const EDIT_SCHEDULE_EVENTS = `${prefix}/EDIT/SCHEDULE/EVENTS`;
export const ADD_SCHEDULE_EVENTS = `${prefix}/ADD/SCHEDULE/EVENTS`;
export const DELETE_SCHEDULE_EVENTS = `${prefix}/DELETE/SCHEDULE/EVENTS`;
// employees
export const LOAD_EMPLOYEES = `${prefix}/LOAD/EMPLOYEES`;
export const LOADING_EMPLOYEES = `${prefix}/LOADING/EMPLOYEES`;
export const LOADED_EMPLOYEES = `${prefix}/LOADED/EMPLOYEES`;
export const EDIT_EMPLOYEES = `${prefix}/EDIT/EMPLOYEES`;
export const ADD_EMPLOYEES = `${prefix}/ADD/EMPLOYEES`;
export const DELETE_EMPLOYEES = `${prefix}/DELETE/EMPLOYEES`;
// organizations
export const LOAD_ORGANIZATION = `${prefix}/LOAD/ORGANIZATION`;
export const LOADING_ORGANIZATION = `${prefix}/LOADING/ORGANIZATION`;
export const LOADED_ORGANIZATION = `${prefix}/LOADED/ORGANIZATION`;
// equipments
export const LOAD_EQUIPMENTS_LIST = `${prefix}/LOAD/EQUIPMENTS/LIST`;
export const LOADING_EQUIPMENTS_LIST = `${prefix}/LOADING/EQUIPMENTS/LIST`;
export const LOADED_EQUIPMENTS_LIST = `${prefix}/LOADED/EQUIPMENTS/LIST`;
//employees
export const LOAD_EMPLOYEE = `${prefix}/LOAD/EMPLOYEE`;
export const LOADING_EMPLOYEE = `${prefix}/LOADING/EMPLOYEE`;
export const LOADED_EMPLOYEE = `${prefix}/LOADED/EMPLOYEE`;
// brigades
export const LOAD_BRIGADES = `${prefix}/LOAD/BRIGADES`;
export const LOADING_BRIGADES = `${prefix}/LOADING/BRIGADES`;
export const LOADED_BRIGADES = `${prefix}/LOADED/BRIGADES`;
export const EDIT_BRIGADES = `${prefix}/EDIT/BRIGADES`;
export const ADD_BRIGADES = `${prefix}/ADD/BRIGADES`;
export const DELETE_BRIGADES = `${prefix}/DELETE/BRIGADES`;
export const LOAD_BRIGADES_NO_WORKORDER = `${prefix}/LOAD/BRIGADES/NO/WORKORDER`;
export const LOADED_BRIGADES_NO_WORKORDER = `${prefix}/LOADED/BRIGADES/NO/WORKORDER`;
// SCHEDULE_TEMPLATE
export const LOAD_SCHEDULE_TEMPLATE_LIST = `${prefix}/LOAD/SCHEDULE/TEMPLATE/LIST`;
export const LOADING_SCHEDULE_TEMPLATE_LIST = `${prefix}/LOADING/SCHEDULE/TEMPLATE/LIST`;
export const LOADED_SCHEDULE_TEMPLATE_LIST = `${prefix}/LOADED/SCHEDULE/TEMPLATE/LIST`;
export const CLEAR_SCHEDULE_TEMPLATE_LIST = `${prefix}/CLEAR/SCHEDULE/TEMPLATE/LIST`;
export const LOAD_SCHEDULE_TEMPLATE_STATUSES = `${prefix}/LOAD/SCHEDULE/TEMPLATE/STATUSES`;
export const LOADED_SCHEDULE_TEMPLATE_STATUSES = `${prefix}/LOADED/SCHEDULE/TEMPLATE/STATUSES`;
export const CREATE_SCHEDULE_TEMPLATE = `${prefix}/CREATE/SCHEDULE/TEMPLATE`;
export const EDIT_SCHEDULE_TEMPLATE = `${prefix}/EDIT/SCHEDULE/TEMPLATE`;
export const DELETE_SCHEDULE_TEMPLATE = `${prefix}/DELETE/SCHEDULE/TEMPLATE`;
export const LOAD_SCHEDULE_TEMPLATE_STATIONS_LIST = `${prefix}/LOAD/SCHEDULE/TEMPLATE/STATIONS/LIST`;
export const LOADING_SCHEDULE_TEMPLATE_STATIONS_LIST = `${prefix}/LOADING/SCHEDULE/TEMPLATE/STATIONS/LIST`;
export const LOADED_SCHEDULE_TEMPLATE_STATIONS_LIST = `${prefix}/LOADED/SCHEDULE/TEMPLATE/STATIONS/LIST`;
export const DELETE_SCHEDULE_TEMPLATE_STATION = `${prefix}/DELETE/SCHEDULE/TEMPLATE/STATION`;
export const EDIT_SCHEDULE_TEMPLATE_STATION = `${prefix}/EDIT/SCHEDULE/TEMPLATE/STATION`;
export const CREATE_SCHEDULE_TEMPLATE_STATION = `${prefix}/CREATE/SCHEDULE/TEMPLATE/STATION`;
export const COPY_SCHEDULE_TEMPLATE = `${prefix}/COPY/SCHEDULE/TEMPLATE`;
//PARKS
export const LOAD_PARKS_LIST = `${prefix}/LOAD/PARKS/LIST`;
export const LOADING_PARKS_LIST = `${prefix}/LOADING/PARKS/LIST`;
export const LOADED_PARKS_LIST = `${prefix}/LOADED/PARKS/LIST`;
export const CREATE_PARK = `${prefix}/CREATE/PARK`;
export const EDIT_PARK = `${prefix}/EDIT/PARK`;
export const DELETE_PARK = `${prefix}/DELETE/PARK`;
//search routes by geojson
export const LOAD_INTERSECTION_ROUTES = `${prefix}/LOAD_INTERSECTION_ROUTES`;
export const LOADED_INTERSECTION_ROUTES = `${prefix}/LOADED_INTERSECTION_ROUTES`;
//Workorders
export const LOAD_WORKORDERS = `${prefix}/LOAD/WORKORDERS`;
export const LOADING_WORKORDERS = `${prefix}/LOADING/WORKORDERS`;
export const LOADED_WORKORDERS = `${prefix}/LOADED/WORKORDERS`;
export const LOAD_CURRENT_WORKORDER = `${prefix}/LOAD/CURRENT/WORKORDER`;
export const LOADING_CURRENT_WORKORDER = `${prefix}/LOADING/CURRENT/WORKORDER`;
export const LOADED_CURRENT_WORKORDER = `${prefix}/LOADED/CURRENT/WORKORDER`;
export const CLEAR_WORKORDERS = `${prefix}/CLEAR/WORKORDERS`;
export const CREATE_WORKORDER = `${prefix}/CREATE/WORKORDER`;
export const EDIT_WORKORDER = `${prefix}/EDIT/WORKORDER`;
export const EDIT_WORKORDER_STATUS = `${prefix}/EDIT/WORKORDER/STATUS`;
export const DELETE_WORKORDER = `${prefix}/DELETE/WORKORDER`;
export const SET_SELECTED_WORKORDER_DATA = `${prefix}/SET/SELECTED/WORKORDER/DATA`;
export const LOAD_WORKORDERS_BY_DATE = `${prefix}/LOAD/WORKORDERS/BY/DATE`;
export const LOADING_WORKORDERS_BY_DATE = `${prefix}/LOADING/WORKORDERS/BY/DATE`;
export const LOADED_WORKORDERS_BY_DATE = `${prefix}/LOADED/WORKORDERS/BY/DATE`;
export const CLEAR_WORKORDERS_BY_DATE = `${prefix}/CLEAR/WORKORDERS/BY/DATE`;
export const LOAD_WORKORDER_STATUSES = `${prefix}/LOAD/WORKORDER/STATUSES`;
export const LOADED_WORKORDER_STATUSES = `${prefix}/LOADED/WORKORDER/STATUSES`;
export const LOAD_WORKORDER_EVENTS = `${prefix}/LOAD/WORKORDER/EVENTS`;
export const LOADING_WORKORDER_EVENTS = `${prefix}/LOADING/WORKORDER/EVENTS`;
export const LOADED_WORKORDER_EVENTS = `${prefix}/LOADED/WORKORDER/EVENTS`;
export const LOAD_WORKORDER_EVENT_STATUSES = `${prefix}/LOAD/WORKORDER/EVENT/STATUSES`;
export const LOADED_WORKORDER_EVENT_STATUSES = `${prefix}/LOADED/WORKORDER/EVENT/STATUSES`;
export const LOAD_WORKORDER_EVENT_MANUAL_STATUSES = `${prefix}/LOAD_WORKORDER_EVENT_MANUAL_STATUSES`;
export const LOADED_WORKORDER_EVENT_MANUAL_STATUSES = `${prefix}/LOADED_WORKORDER_EVENT_MANUAL_STATUSES`;
export const LOAD_WORKORDER_EVENT_FLIGHT_MANUAL_STATUSES = `${prefix}/LOAD_WORKORDER_EVENT_FLIGHT_MANUAL_STATUSES`;
export const LOADED_WORKORDER_EVENT_FLIGHT_MANUAL_STATUSES = `${prefix}/LOADED_WORKORDER_EVENT_FLIGHT_MANUAL_STATUSES`;
export const LOAD_FLIGHT_DEFECT_GROUP = `${prefix}/LOAD_FLIGHT_DEFECT_GROUP`;
export const LOADED_FLIGHT_DEFECT_GROUP = `${prefix}/LOADED_FLIGHT_DEFECT_GROUP`;
export const LOAD_FLIGHT_DEFECTS = `${prefix}/LOAD_FLIGHT_DEFECTS`;
export const LOADED_FLIGHT_DEFECTS = `${prefix}/LOADED_FLIGHT_DEFECTS`;
export const CREATE_WORKORDER_EVENT_STATUS = `${prefix}/CREATE_WORKORDER_EVENT_STATUS`;
export const EDIT_WORKORDER_EVENT_STATUS = `${prefix}/EDIT_WORKORDER_EVENT_STATUS`;
export const DELETE_WORKORDER_EVENT_STATUS = `${prefix}/DELETE_WORKORDER_EVENT_STATUS`;
//traffic schedule
export const LOAD_TRAFFIC_SCHEDULE = `${prefix}/LOAD/TRAFFIC/SCHEDULE`;
export const LOADING_TRAFFIC_SCHEDULE = `${prefix}/LOADING/TRAFFIC/SCHEDULE`;
export const LOADED_TRAFFIC_SCHEDULE = `${prefix}/LOADED/TRAFFIC/SCHEDULE`;
export const LOAD_TRAFFIC_SCHEDULE_NO_WORKORDER = `${prefix}/LOAD/TRAFFIC/SCHEDULE/NO/WORKORDER`;
export const LOADED_TRAFFIC_SCHEDULE_NO_WORKORDER = `${prefix}/LOADED/TRAFFIC/SCHEDULE/NO/WORKORDER`;
export const LOAD_TRAFFIC_SCHEDULE_STATUSES = `${prefix}/LOAD_TRAFFIC_SCHEDULE_STATUSES`;
export const LOADED_TRAFFIC_SCHEDULE_STATUSES = `${prefix}/LOADED_TRAFFIC_SCHEDULE_STATUSES`;
export const CREATE_TRAFFIC_SCHEDULE = `${prefix}/CREATE_TRAFFIC_SCHEDULE`;
export const LOAD_TRAFFIC_SCHEDULE_TYPES = `${prefix}/LOAD_TRAFFIC_SCHEDULE_TYPES`;
export const LOADED_TRAFFIC_SCHEDULE_TYPES = `${prefix}/LOADED_TRAFFIC_SCHEDULE_TYPES`;
export const LOAD_CURRENT_TRAFFIC_SCHEDULE = `${prefix}/LOAD_CURRENT_TRAFFIC_SCHEDULE`;
export const LOADING_CURRENT_TRAFFIC_SCHEDULE = `${prefix}/LOADING_CURRENT_TRAFFIC_SCHEDULE`;
export const LOADED_CURRENT_TRAFFIC_SCHEDULE = `${prefix}/LOADED_CURRENT_TRAFFIC_SCHEDULE`;
export const LOAD_APPEAL = `${prefix}/LOAD/APPEAL`;
export const LOADING_APPEAL = `${prefix}/LOADING/APPEAL`;
export const LOADED_APPEAL = `${prefix}/LOADED/APPEAL`;
export const CREATE_APPEAL = `${prefix}/CREATE/APPEAL`;
export const EDIT_APPEAL = `${prefix}/EDIT/APPEAL`;
export const DELETE_APPEAL = `${prefix}/DELETE/APPEAL`;
export const LOADED_APPEAL_TYPE = `${prefix}/LOADED/APPEAL/TYPE`;
export const LOAD_APPEAL_TYPE = `${prefix}/LOAD/APPEAL/TYPE`;
export const LOADED_APPEAL_STATUS = `${prefix}/LOADED/APPEAL/STATUS`;
export const LOAD_APPEAL_STATUS = `${prefix}/LOAD/APPEAL/STATUS`;

export const LOAD_ROUTE_CHECKPOINTS = `${prefix}/LOAD/ROUTE/CHECKPOINTS`;
export const LOADING_ROUTE_CHECKPOINTS = `${prefix}/LOADING/ROUTE/CHECKPOINTS`;
export const LOADED_ROUTE_CHECKPOINTS = `${prefix}/LOADED/ROUTE/CHECKPOINTS`;
export const SAVE_CHECKPOINTS_TO_ROUTE = `${prefix}/SAVE_CHECKPOINTS_TO_ROUTE`;

export const LOAD_NEAR_STATION = `${prefix}/LOAD/NEAR/STATION`;
export const LOADED_NEAR_STATION = `${prefix}/LOADED/NEAR/STATION`;

export const CREATE_ROUTE_CHECKPOINTS = `${prefix}/CREATE/ROUTE/CHECKPOINTS`;
export const ROUTE_SELECT_LIST = `${prefix}/ROUTE/SELECT/LIST`;

export const LOADED_ROUTE_WITH_CHECK_POINTS = `${prefix}/LOADED/ROUTE/WITH/CHECK/POINTS`;
export const LOADING_ROUTE_WITH_CHECK_POINTS = `${prefix}/LOADING/ROUTE/WITH/CHECK/POINTS`;
export const LOAD_ROUTE_WITH_CHECK_POINTS = `${prefix}/LOAD/ROUTE/WITH/CHECK/POINTS`;

//ROUTE_REQUESTS
export const LOAD_ROUTE_REQUEST_LIST = `${prefix}/LOAD/ROUTE/REQUEST/LIST`;
export const LOADING_ROUTE_REQUESTS_LIST = `${prefix}/LOADING/ROUTE/REQUEST/LIST`;
export const LOADED_ROUTE_REQUESTS_LIST = `${prefix}/LOADED/ROUTE/REQUEST/LIST`;
export const LOAD_STATUSES_ROUTE_REQUESTS = `${prefix}/LOAD/STATUSES/ROUTE/REQUESTS`;
export const LOADED_STATUSES_ROUTE_REQUESTS = `${prefix}/LOADED/STATUSES/ROUTE/REQUESTS`;
export const CREATE_ROUTE_REQUEST = `${prefix}/CREATE/ROUTE/REQUEST`;
export const EDIT_ROUTE_REQUEST = `${prefix}/EDIT/ROUTE/REQUEST`;
export const DELETE_ROUTE_REQUEST = `${prefix}/DELETE/ROUTE/REQUEST`;
export const LOAD_CAPTCHA = `${prefix}/LOAD/CAPTCHA`;
export const LOADED_CAPTCHA = `${prefix}/LOADED/CAPTCHA`;
export const CHANGE_TRAFFIC_SCHEDULE_STATUS = `${prefix}/CHANGE_TRAFFIC_SCHEDULE_STATUS`;

//SCHEDULE
export const FETCH_SCHEDULES = `${prefix}/FETCH_SCHEDULES`;
export const FETCHING_SCHEDULES = `${prefix}/FETCHING_SCHEDULES`;
export const FETCHED_SCHEDULES = `${prefix}/FETCHED_SCHEDULES`;
export const POST_SCHEDULE = `${prefix}/POST_SCHEDULE`;
export const PUT_SCHEDULE = `${prefix}/PUT_SCHEDULE`;
export const DEL_SCHEDULE = `${prefix}/DEL_SCHEDULE`;
export const FETCH_SCHEDULE_STATUSES = `${prefix}/FETCH_SCHEDULE_STATUSES`;
export const FETCHED_SCHEDULE_STATUSES = `${prefix}/FETCHED_SCHEDULE_STATUSES`;
export const FETCH_SCHEDULE_EVENTS = `${prefix}/FETCH_SCHEDULE_EVENTS`;
export const FETCHING_SCHEDULE_EVENTS = `${prefix}/FETCHING_SCHEDULE_EVENTS`;
export const FETCHED_SCHEDULE_EVENTS = `${prefix}/FETCHED_SCHEDULE_EVENTS`;
export const PUT_SCHEDULE_STATUS = `${prefix}/PUT_SCHEDULE_STATUS`;

// schedule templates checkpoints
export const LOAD_SCHEDULETEMPLATE_CHECKPOINTS = `${prefix}/LOAD_SCHEDULETEMPLATE_CHECKPOINTS`;
export const LOADING_SCHEDULETEMPLATE_CHECKPOINTS = `${prefix}/LOADING_SCHEDULETEMPLATE_CHECKPOINTS`;
export const LOADED_SCHEDULETEMPLATE_CHECKPOINTS = `${prefix}/LOADED_SCHEDULETEMPLATE_CHECKPOINTS`;
export const SAVE_CHECKPOINTS_TO_SCHEDULETEMPLATE = `${prefix}/SAVE_CHECKPOINTS_TO_SCHEDULETEMPLATE`;
//ROUTE_TRANSPORTATION_TARIFF
export const SAVE_TRANSPORTATION_TARIFF = `${prefix}/SAVE/TRANSPORTATION/TARIFF`;
// ticket points
export const LOAD_TICKET_POINTS = `${prefix}/LOAD/TICKET/POINTS`;
export const LOADING_TICKET_POINTS = `${prefix}/LOADING/TICKET/POINTS`;
export const LOADED_TICKET_POINTS = `${prefix}/LOADED/TICKET/POINTS`;
export const CREATE_TICKET_POINT = `${prefix}/CREATE/TICKET/POINT`;
export const EDIT_TICKET_POINT = `${prefix}/EDIT/TICKET/POINT`;
export const DELETE_TICKET_POINT = `${prefix}/DELETE/TICKET/POINT`;
export const LOAD_TICKET_POINT_TYPES = `${prefix}/LOAD/TICKET/POINT/TYPES`;
export const LOADED_TICKET_POINT_TYPES = `${prefix}/LOADING/TICKET/POINT/TYPES`;

export const SAVE_CURRENT_ROUTE = `${prefix}/SAVE_CURRENT_ROUTE`;
export const CLEAR_CURRENT_ROUTE = `${prefix}/CLEAR_CURRENT_ROUTE`;

//WAYBILLS
export const LOAD_WAYBILLS_LIST = `${prefix}/LOAD/WAYBILLS/LIST`;
export const LOADING_WAYBILLS_LIST = `${prefix}/LOADING/WAYBILLS/LIST`;
export const LOADED_WAYBILLS_LIST = `${prefix}/LOADED/WAYBILLS/LIST`;
export const CREATE_WAYBILLS = `${prefix}/CREATE/WAYBILLS`;
export const EDIT_WAYBILLS = `${prefix}/EDIT/WAYBILLS`;
export const DELETE_WAYBILLS = `${prefix}/DELETE/WAYBILLS`;
export const GET_WAYBILLS = `${prefix}/GET/WAYBILLS`;
export const LOAD_WAYBILLS_LICENCE = `${prefix}LOAD/WAYBILLS/LICENCE`;
export const LOADED_WAYBILLS_LICENCE = `${prefix}LOADED/WAYBILLS/LICENCE`;


export const CHANGE_ROUTE_AFTER = `${prefix}/CHANGE_ROUTE_AFTER`;
export const SET_CHANGE_ROUTE_STATUS = `${prefix}/SET_CHANGE_ROUTE_STATUS`;
//TARIFFS
export const LOAD_TARIFFS_LIST = `${prefix}/LOAD/TARIFFS/LIST`;
export const LOADING_TARIFFS_LIST = `${prefix}/LOADING/TARIFFS/LIST`;
export const LOADED_TARIFFS_LIST = `${prefix}/LOADED/TARIFFS/LIST`;
export const CREATE_TARIFF = `${prefix}/CREATE/TARIFF`;
export const EDIT_TARIFF = `${prefix}/EDIT/TARIFF`;
export const DELETE_TARIFF = `${prefix}/DELETE/TARIFF`;

export const LOAD_VEHICLE_CARD = `${prefix}/LOAD_VEHICLE_CARD`;
export const LOADING_VEHICLE_CARD = `${prefix}/LOADING_VEHICLE_CARD`;
export const LOADED_VEHICLE_CARD = `${prefix}/LOADED_VEHICLE_CARD`;
export const CLEAR_VEHICLE_CARD = `${prefix}/CLEAR_VEHICLE_CARD`;

export const LOAD_TRAFFIC_SCHEDULE_LIST_OF_EVENTS = `${prefix}/LOAD_TRAFFIC_SCHEDULE_LIST_OF_EVENTS`;
export const LOADED_TRAFFIC_SCHEDULE_LIST_OF_EVENTS = `${prefix}/LOADED_TRAFFIC_SCHEDULE_LIST_OF_EVENTS`;

export const EDIT_TRAFFIC_SCHEDULE = `${prefix}/EDIT_TRAFFIC_SCHEDULE`;

export const LOAD_COORDS = `${prefix}/LOAD_COORDS`;
export const LOADED_COORDS = `${prefix}/LOADED_COORDS`;
export const CLEAR_COORDS = `${prefix}/CLEAR_COORDS`;
export const LOADING_COORDS = `${prefix}/LOADING_COORDS`;

export const LOAD_VEHICLE = `${prefix}/LOAD_VEHICLE`;
export const LOADED_VEHICLE = `${prefix}/LOADED_VEHICLE`;
export const CLEAR_VEHICLE = `${prefix}/CLEAR_VEHICLE`;
export const LOADING_VEHICLE = `${prefix}/LOADING_VEHICLE`;

export const GET_REPORT_SUMMARY_SCHEDULE = `${prefix}/GET/REPORT/SUMMARY/SCHEDULE`;
export const LOADED_SUMMARY_SCHEDULE = `${prefix}/LOADED/SUMMARY/SCHEDULE`;
export const LOADING_SUMMARY_SHEDULE = `${prefix}/LOADING/SUMMARY/SHEDULE`;
export const CLEAR_SUMMARY_SCHEDULE = `${prefix}/CLEAR/SUMMARY/SCHEDULE`;
export const SET_SUMMARY_SCHEDULE_FILTER = `${prefix}/SET/SUMMARY/SCHEDULE/FILTER`;
export const CLEAR_SUMMARY_SCHEDULE_FILTER = `${prefix}/CLEAR/SUMMARY/SCHEDULE/FILTER`;
export const LOAD_ORGANIZATION_INFORMATION = `${prefix}/LOAD_ORGANIZATION_INFORMATION`;
export const LOADED_ORGANIZATION_INFORMATION = `${prefix}/LOADED_ORGANIZATION_INFORMATION`;
export const LOADING_ORGANIZATION_INFORMATION = `${prefix}/LOADING_ORGANIZATION_INFORMATION`;

export const LOAD_VEHICLE_TELEMETRY_STATISTIC = `${prefix}/LOAD_VEHICLE_TELEMETRY_STATISTIC`;
export const LOAD_TELEMETRY_STATISTIC = `${prefix}/LOAD_TELEMETRY_STATISTIC`;
export const LOADED_VEHICLE_TELEMETRY_STATISTIC = `${prefix}/LOADED_VEHICLE_TELEMETRY_STATISTIC`;
export const LOADED_TELEMETRY_STATISTIC = `${prefix}/LOADED_TELEMETRY_STATISTIC`;

export const LOAD_WORKORDERS_REPORT = `${prefix}/LOAD/WORKORDERS/REPORT`;
export const LOADING_WORKORDERS_REPORT = `${prefix}/LOADING/WORKORDERS/REPORT`;
export const LOADED_WORKORDERS_REPORT = `${prefix}/LOADED/WORKORDERS/REPORT`;
export const SET_WORKORDERS_REPORT_FILTER = `${prefix}/SET/WORKORDERS/REPORT/FILTER`;
export const CLEAR_WORKORDERS_REPORT_FILTER = `${prefix}/CLEAR/WORKORDERS/REPORT/FILTER`;


export const LOAD_WO_REQUISITE_CHARTERER_TYPES = `${prefix}/LOAD_WO_REQUISITE_CHARTERER_TYPES`;
export const LOADED_WO_REQUISITE_CHARTERER_TYPES = `${prefix}/LOADED_WO_REQUISITE_CHARTERER_TYPES`;
export const LOAD_WO_REQUISITE_LIST = `${prefix}/LOAD_WO_REQUISITE_LIST`;
export const LOADED_WO_REQUISITE_LIST = `${prefix}/LOADED_WO_REQUISITE_LIST`;
export const LOADING_WO_REQUISITE = `${prefix}/LOADING_WO_REQUISITE`;
export const CREATE_WO_REQUISITE = `${prefix}/CREATE_WO_REQUISITE`;
export const EDIT_WO_REQUISITE = `${prefix}/EDIT_WO_REQUISITE`;
export const DELETE_WO_REQUISITE = `${prefix}/DELETE_WO_REQUISITE`;

export const LOAD_RADIO_TYPE = `${prefix}/LOAD_RADIO_TYPE`;
export const LOADED_RADIO_TYPE = `${prefix}/LOADED_RADIO_TYPE`;
export const LOADING_RADIO_TYPE = `${prefix}/LOADING_RADIO_TYPE`;

export const LOAD_REPORT_BY_FLIGHTS = `${prefix}/LOAD_REPORT_BY_FLIGHTS`;
export const LOADED_REPORT_BY_FLIGHTS = `${prefix}/LOADED_REPORT_BY_FLIGHTS`;
export const LOADING_REPORT_BY_FLIGHTS = `${prefix}/LOADING_REPORT_BY_FLIGHTS`;
export const CLEAR_REPORT_BY_FLIGHTS = `${prefix}/CLEAR_REPORT_BY_FLIGHTS`;
export const SET_REPORT_BY_FLIGHTS_FILTER = `${prefix}/CLEAR_REPORT_BY_FLIGHTS_FILTER`;

export const SET_HISTORY_OPTION = `${prefix}/SET_HISTORY_OPTION`;
export const LOAD_REPORT_BY_VEHICLES = `${prefix}/LOAD_REPORT_BY_VEHICLES`;
export const LOADED_REPORT_BY_VEHICLES = `${prefix}/LOADED_REPORT_BY_VEHICLES`;
export const LOADING_REPORT_BY_VEHICLES = `${prefix}/LOADING_REPORT_BY_VEHICLES`;
export const SET_REPORT_BY_VEHICLES_FILTER = `${prefix}/SET_REPORT_BY_VEHICLES_FILTER`;
export const CLEAR_REPORT_BY_VEHICLES_FILTER = `${prefix}/CLEAR_REPORT_BY_VEHICLES_FILTER`;

export const LOAD_EMPLOYEES_TRANSPORTATION_REPORT = `${prefix}/LOAD/EMPLOYEES/TRANSPORTATION/REPORT`;
export const LOADED_EMPLOYEES_TRANSPORTATION_REPORT = `${prefix}/LOADED/EMPLOYEES/TRANSPORTATION/REPORT`;
export const LOADING_EMPLOYEES_TRANSPORTATION_REPORT = `${prefix}/LOADING/EMPLOYEES/TRANSPORTATION/REPORT`;
export const SET_EMPLOYEES_TRANSPORTATION_REPORT_FILTER = `${prefix}/SET/EMPLOYEES/TRANSPORTATION/REPORT/FILTER`;
export const CLEAR_EMPLOYEES_TRANSPORTATION_REPORT_FILTER = `${prefix}/CLEAR/EMPLOYEES/TRANSPORTATION/REPORT/FILTER`;

export const LOAD_FLIGHT_TYPES = `${prefix}/LOAD/FLIGHT/TYPES`;
export const LOADED_FLIGHT_TYPES = `${prefix}/LOADED/FLIGHT/TYPES`;

export const EDIT_FLIGHT_TYPE = `${prefix}/EDIT/FLIGHT/TYPE`;

export const LOAD_SPEED_VIOLATIONS_REPORT = `${prefix}/LOAD_SPEED_VIOLATIONS_REPORT`;
export const LOADED_SPEED_VIOLATIONS_REPORT = `${prefix}/LOADED_SPEED_VIOLATIONS_REPORT`;
export const LOADING_SPEED_VIOLATIONS_REPORT = `${prefix}/LOADING_SPEED_VIOLATIONS_REPORT`;
export const SET_SPEED_VIOLATIONS_REPORT_FILTER = `${prefix}/SET_SPEED_VIOLATIONS_REPORT_FILTER`;
export const CLEAR_SPEED_VIOLATIONS_REPORT_FILTER = `${prefix}/CLEAR_SPEED_VIOLATIONS_REPORT_FILTER`;

//TRANSPORT_SETTINGS
export const LOAD_TRANSPORT_SETTINGS = `${prefix}LOAD/TRANSPORT/SETTINGS`;
export const LOADING_TRANSPORT_SETTINGS = `${prefix}LOADING/TRANSPORT/SETTINGS`;
export const LOADED_TRANSPORT_SETTINGS = `${prefix}LOADED/TRANSPORT/SETTINGS`;
export const EDIT_TRANSPORT_SETTING = `${prefix}/EDIT/TRANSPORT/SETTING`;

export const EDIT_SETTINGS_LIST = `${prefix}EDIT_SETTINGS_LIST`;

export const LOAD_SETTINGS_GROUP = `${prefix}LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}EDIT_SETTINGS_GROUP`;

//REPORT_PASSENGERS
export const LOAD_PASSENGERS_COUNT = `${prefix}LOAD_PASSENGERS_COUNT`;
export const LOADING_PASSENGERS_COUNT = `${prefix}LOADING_PASSENGERS_COUNT`;
export const LOADED_PASSENGERS_COUNT = `${prefix}LOADED_PASSENGERS_COUNT`;
export const SET_PASSENGERS_COUNT_FILTER = `${prefix}SET_PASSENGERS_COUNT_FILTER`;
export const CLEAR_PASSENGERS_COUNT_FILTER = `${prefix}CLEAR_PASSENGERS_COUNT_FILTER`;

export const CLEAR_ROUTE_WITH_CHECK_POINTS = `${prefix}CLEAR_ROUTE_WITH_CHECK_POINTS`;

export const LOAD_SIDEBAR = `${prefix}LOAD_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}LOADED_SIDEBAR`;
export const LOADING_SIDEBAR = `${prefix}LOADING_SIDEBAR`;
export const SET_SIDEBAR_PARAMS = `${prefix}SET_SIDEBAR_PARAMS`;
export const SET_ROUTE_WS = `${prefix}SET_ROUTE_WS`;

export const GET_TEMPLATE = `${prefix}GET_TEMPLATE`;
export const LOADING_TEMPLATE = `${prefix}LOADING_TEMPLATE`;

export const LOAD_TRANSORGANIZATIONS = `${prefix}LOAD_TRANSORGANIZATIONS`;
export const LOADING_TRANSORGANIZATIONS = `${prefix}LOADING_TRANSORGANIZATIONS`;
export const LOADED_TRANSORGANIZATIONS = `${prefix}LOADED_TRANSORGANIZATIONS`;
export const EDIT_TRANSORGANIZATION = `${prefix}EDIT_TRANSORGANIZATION`;

/* color speed */
export const LOAD_COLOR_SPEED_LIST = `${prefix}LOAD_COLOR_SPEED_LIST`;
export const LOADED_COLOR_SPEED_LIST = `${prefix}LOADED_COLOR_SPEED_LIST`;
export const LOADING_COLOR_SPEED_LIST = `${prefix}LOADING_COLOR_SPEED_LIST`;
export const SET_COLOR_SPEED_LIST_PROPS = `${prefix}SET_COLOR_SPEED_LIST_PROPS`;
export const RESET_COLOR_SPEED_LIST_PROPS = `${prefix}RESET_COLOR_SPEED_LIST_PROPS`;
export const ADD_COLOR_SPEED = `${prefix}ADD_COLOR_SPEED`;
export const EDIT_COLOR_SPEED = `${prefix}EDIT_COLOR_SPEED`;
export const DELETE_COLOR_SPEED = `${prefix}DELETE_COLOR_SPEED`;

export const LOAD_ROUTES_GROUPED = `${prefix}LOAD_ROUTES_GROUPED`;
export const LOADED_ROUTES_GROUPED = `${prefix}LOADED_ROUTES_GROUPED`;
export const LOADING_ROUTES_GROUPED = `${prefix}LOADING_ROUTES_GROUPED`;

export const LOAD_MILESTONE_EVENTS = `${prefix}LOAD_MILESTONE_EVENTS`;
export const LOADED_MILESTONE_EVENTS = `${prefix}LOADED_MILESTONE_EVENTS`;

export const LOADING_BUTTON = `${prefix}LOADING_BUTTON`;

export const LOAD_COLOR_SPEED = `${prefix}LOAD_COLOR_SPEED`;
export const LOADED_COLOR_SPEED = `${prefix}LOADED_COLOR_SPEED`;

export const LOAD_STATION_POLYGON = `${prefix}LOAD_STATION_POLYGON`;
export const LOADED_STATION_POLYGON = `${prefix}LOADED_STATION_POLYGON`;
export const LOADING_STATION_POLYGON = `${prefix}LOADING_STATION_POLYGON`;

export const LOAD_STATION_SCHEDULE_BY_DATE = `${prefix}LOAD_STATION_SCHEDULE_BY_DATE`;
export const LOADED_STATION_SCHEDULE_BY_DATE = `${prefix}LOADED_STATION_SCHEDULE_BY_DATE`;
export const LOADING_STATION_SCHEDULE_BY_DATE = `${prefix}LOADING_STATION_SCHEDULE_BY_DATE`;

export const LOAD_TELEMETRY_RECEIVERS = `${prefix}LOAD_TELEMETRY_RECEIVERS`;
export const LOADED_TELEMETRY_RECEIVERS = `${prefix}LOADED_TELEMETRY_RECEIVERS`;
export const LOADING_TELEMETRY_RECEIVERS = `${prefix}LOADING_TELEMETRY_RECEIVERS`;
export const ADD_TELEMETRY_RECEIVER = `${prefix}ADD_TELEMETRY_RECEIVER`;
export const EDIT_TELEMETRY_RECEIVER = `${prefix}EDIT_TELEMETRY_RECEIVER`;
export const DELETE_TELEMETRY_RECEIVER = `${prefix}DELETE_TELEMETRY_RECEIVER`;

export const LOAD_TELEMETRY_RECEIVER_TYPES = `${prefix}LOAD_TELEMETRY_RECEIVER_TYPES`;
export const LOADED_TELEMETRY_RECEIVER_TYPES = `${prefix}LOADED_TELEMETRY_RECEIVER_TYPES`;

export const COPY_TRAFFIC_SCHEDULE = `${prefix}COPY_TRAFFIC_SCHEDULE`;

export const CHANGE_ROUTE_STATUS = `${prefix}CHANGE_ROUTE_STATUS`;
export const LOADING_CHANGE_ROUTE_STATUS = `${prefix}LOADING_CHANGE_ROUTE_STATUS`;

export const LOAD_WORK_CALENDAR = `${prefix}LOAD_WORK_CALENDAR`;
export const LOADED_WORK_CALENDAR = `${prefix}LOADED_WORK_CALENDAR`;
export const LOADING_WORK_CALENDAR = `${prefix}LOADING_WORK_CALENDAR`;
export const EDIT_WORK_CALENDAR = `${prefix}EDIT_WORK_CALENDAR`;
export const LOAD_WORK_CALENDAR_DAY_TYPES = `${prefix}LOAD_WORK_CALENDAR_DAY_TYPES`;
export const LOADED_WORK_CALENDAR_DAY_TYPES = `${prefix}LOADED_WORK_CALENDAR_DAY_TYPES`;
export const LOADING_WORK_CALENDAR_DAY_TYPES = `${prefix}LOADING_WORK_CALENDAR_DAY_TYPES`;
export const LOAD_WORK_CALENDAR_HISTORY = `${prefix}LOAD_WORK_CALENDAR_HISTORY`;
export const LOADED_WORK_CALENDAR_HISTORY = `${prefix}LOADED_WORK_CALENDAR_HISTORY`;
export const LOADING_WORK_CALENDAR_HISTORY = `${prefix}LOADING_WORK_CALENDAR_HISTORY`;

export const DELETE_ROUTE = `${prefix}/DELETE_ROUTE`;

export const LOAD_VEHICLE_CLASSES = `${prefix}LOAD_VEHICLE_CLASSES`;
export const LOADING_VEHICLE_CLASSES = `${prefix}LOADING_VEHICLE_CLASSES`;
export const LOADED_VEHICLE_CLASSES = `${prefix}LOADED_VEHICLE_CLASSES`;
export const CREATE_VEHICLE_CLASS = `${prefix}CREATE_VEHICLE_CLASS`;
export const EDIT_VEHICLE_CLASS = `${prefix}EDIT_VEHICLE_CLASS`;
export const DELETE_VEHICLE_CLASS = `${prefix}DELETE_VEHICLE_CLASS`;
export const LOAD_REGULAR_TRANSPORTATIONS = `${prefix}/LOAD_REGULAR_TRANSPORTATIONS`;
export const LOADED_REGULAR_TRANSPORTATIONS = `${prefix}/LOADED_REGULAR_TRANSPORTATIONS`;
export const LOAD_SEASONALITY = `${prefix}/LOAD_SEASONALITY`;
export const LOADED_SEASONALITY = `${prefix}/LOADED_SEASONALITY`;
export const LOAD_ROUTE_SORT_DIRECTIONS = `${prefix}/LOAD_ROUTE_SORT_DIRECTIONS`;
export const LOADED_ROUTE_SORT_DIRECTIONS = `${prefix}/LOADED_ROUTE_SORT_DIRECTIONS`;
export const EDIT_ROUTE_ADDITIONAL_PARAMS = `${prefix}/EDIT_ROUTE_ADDITIONAL_PARAMS`;
export const LOADING_ROUTE_ADDITIONAL_PARAMS = `${prefix}/LOADING_ROUTE_ADDITIONAL_PARAMS`;

export const LOAD_ROUTE_HISTORY_HUMAN = `${prefix}LOAD_ROUTE_HISTORY_HUMAN`;
export const LOADING_ROUTE_HISTORY_HUMAN = `${prefix}LOADING_ROUTE_HISTORY_HUMAN`;
export const LOADED_ROUTE_HISTORY_HUMAN = `${prefix}LOADED_ROUTE_HISTORY_HUMAN`;

export const ADD_GROUP_TO_LOADING_GROUPS = `${prefix}/ADD_GROUP_TO_LOADING_GROUPS`;
export const REMOVE_GROUP_FROM_LOADING_GROUPS = `${prefix}/REMOVE_GROUP_FROM_LOADING_GROUPS`;

export const UPDATE_ROUTE = `${prefix}/UPDATE_ROUTE`;

//SHOWCASE
export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

// export const LOAD_ROUTE_BY_NUM = `${prefix}/LOAD_ROUTE_BY_NUM`;

export const LOAD_ROUTES_BY_NC_CALLBACK = `${prefix}/LOAD_ROUTES_BY_NC_CALLBACK`;
export const SET_SHOW_ROUTE_BY_VEHICLE = `${prefix}/SET_SHOW_ROUTE_BY_VEHICLE`;

export const LOAD_EGTS_TRANSMITTER = `${prefix}/LOAD_EGTS_TRANSMITTER`;
export const ADD_EGTS_TRANSMITTER = `${prefix}/ADD_EGTS_TRANSMITTER`;
export const EDIT_EGTS_TRANSMITTER = `${prefix}/EDIT_EGTS_TRANSMITTER`;
export const DELETE_EGTS_TRANSMITTER = `${prefix}/DELETE_EGTS_TRANSMITTER`;

// export const SET_CURRENT_MARKER = `${prefix}/SET_CURRENT_MARKER`;
// export const REFRESH_CURRENT_MARKER = `${prefix}/REFRESH_CURRENT_MARKER`;
