import {
    TextField,
} from '@mui/material';

import FormInfoWrapper from 'components/common/FormInfoWrapper';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import InfoBlock from 'components/common/InfoBlock';
import type { FactorType, GeometryType } from 'modules/React/reactTypes';

interface FactorItemProps {
    factor: FactorType;
    onChange: (factor: FactorType) => void;
    visibleGeometry: GeometryType | null;
    error: boolean;
    helperText: string;
}

const FactorItem = ({ factor, onChange, visibleGeometry, error, helperText }: FactorItemProps) => {
    return (
        <>{factor.is_geo
            && <InfoBlock label={factor.name} mt="1rem">
                <div className="block row">
                    <FieldsModal
                        title="Выбрать область на карте"
                        fields={{ geometry: factor?.geo_json || {} }}
                        buttonText={factor?.geo_json ? 'Редактировать геозону' :'Добавить геозону'}
                        //@ts-ignore
                        buttonType={factor?.geo_json ? buttonsTypes.edit :buttonsTypes.add}
                        buttonVariant="contained"
                        onChange={({ geometry }: {geometry: GeometryType}) => {
                            onChange({ ...factor, geo_json: Object.keys(geometry).length > 0 ? geometry : null, comment: '' });
                        }}
                    >
                        <MapGeoJson
                            visibleGeometry={visibleGeometry}
                            circle={true}
                            polygon={true}
                            polyline={true}
                            marker={true}
                            isHideValidation
                        />
                    </FieldsModal>
                    {factor?.geo_json
                        && <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.reset,
                                    onClick: () => onChange({ ...factor, geo_json: null, comment: '' }),
                                    name: 'Удалить',
                                }
                            ]}
                            justButton
                        />
                    }
                </div>
                <FormInfoWrapper error={error} helperText={helperText}>
                    <TextField
                        label="Причина отказа"
                        size="small"
                        value={factor.comment}
                        variant="outlined"
                        name="name"
                        type="text"
                        error={error}
                        onChange={(e) => {
                            onChange({ ...factor, comment: e.target.value });
                        }}
                        disabled={!!factor?.geo_json}
                    />
                </FormInfoWrapper>
            </InfoBlock>
        }
        </>
    );
};

export default FactorItem;
