import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material';

import { deleteComplexEntity } from 'redux/DorisControl/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import CommentAction from 'components/common/CommentAction';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { ActionMore } from 'components/common/List';

import EntityForm from './EntityForm';
import MoveEntityModal from './MoveEntityModal';
import Info from './Info';

const Item = ({
    types,
    item,
    currentComplex,
    readOnly = false,
    onDeleted,
    onEdited,
    onOpenChildren,
    isOpenChildren,
    test_id_prefix_passport = '',
    project
}) => {
    const complex_id = currentComplex?.id;
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [commentBeforeDelete, setCommentBeforeDelete] = useState(false);
    const [isInfoOpen, setInfoOpen] = useState(false);
    const [isOpenMoveEntityModal, setIsOpenMoveEntityModal] = useState(false);

    const showInfoButton = !!item.lat
        || !!item.lon
        || !!item.ip_src
        || !!item.ip_dst
        || !!item.port_stream
        || !!item.port_static
        || !!item.direction?.length > 0
        || !!item.comment
        || !!Object.keys(item.pdd_violations || {}).length > 0;


    const handleOpenInfo = () => {
        showInfoButton && setInfoOpen(!isInfoOpen);
    };

    const [currentBool, currentOnClick] = (onOpenChildren && item.entities?.length > 0)
        ? [isOpenChildren, onOpenChildren]
        : [isInfoOpen, handleOpenInfo];

    return (<>
        <ListItem onClick={currentOnClick} className="list_item" >
            <ListItemText>
                <Typography variant="body2">
                    <span><strong>Тип: </strong> {types?.[item.entity_id]?.name || messages.NO_DATA}</span>
                    {item?.serial && project !== '34_vlg_vlz'
                        && <span>; <strong>Серийный номер: </strong> {item?.serial}</span>
                    }
                    {item?.code && project !== '34_vlg_vlz'
                        && <span>; <strong>Идентификатор: </strong> {item?.code}</span>
                    }
                    {item.name  && project !== '34_vlg_vlz'
                        && <span>; <strong>Название: </strong> {item.name}</span>
                    }
                    {item?.admin_url
                        && <span>; <strong>Админпанель: </strong>
                            <a href={item?.admin_url} target="_blank" rel="noreferrer">{item?.admin_url}</a>
                        </span>
                    }
                </Typography>


            </ListItemText>
            {!readOnly
                && <FormButtonsComponent
                    buttons={[
                        ...(types?.[item.entity_id]?.url === '/complex/computer'
                            ? [{
                                icon: <i className="far fa-camera-home" />,
                                name: 'Перенести в КФВФ',
                                onClick: (event) => {
                                    event.stopPropagation();
                                    setIsOpenMoveEntityModal(true);
                                },
                                testName: 'transfer_cpvf'
                            }]
                            : []
                        ),
                        {
                            ...buttonsTypes.editIcon,
                            onClick: (event) => {
                                event.stopPropagation();
                                setOpenModal(true);
                            },
                            testName: 'edit'
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: (event) => {
                                event.stopPropagation();
                                setDeleteModalOpen(true);
                            },
                            testName: 'delete'
                        },
                    ]}
                    noMarginLeft
                    justButton
                    test_id_prefix={test_id_prefix_passport}
                />
            }
            {showInfoButton
                && <ActionMore
                    isOpen={currentBool}
                    test_id_prefix={test_id_prefix_passport}
                    size="middle"
                />
            }
        </ListItem>
        {openModal && (
            <EntityForm
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                item={item}
                types={types}
                complex_id={complex_id}
                // loadList={loadList}
                onEdited={onEdited}
                currentComplex={currentComplex}
                test_id_prefix_passport={test_id_prefix_passport}
            />
        )}

        {commentBeforeDelete && (
            <CommentAction
                title={titles.COMMENT_DELETE}
                inputLabel={'Укажите причину удаления'}
                open={commentBeforeDelete}
                onClose={() => {
                    setCommentBeforeDelete(false);
                    setDeleteModalOpen(false);
                }}
                onAccept={(_comment) => {
                    dispatch(deleteComplexEntity(`${types?.[item.entity_id]?.url}/${item.id}`, { _comment }, () => { onDeleted && onDeleted(); }));
                }}
            />
        )}

        {deleteModalOpen && (
            <ConfirmDelete
                open={deleteModalOpen}
                message={messages.CONFIRM_DELETE}
                onClose={() => setDeleteModalOpen(false)}
                onSuccess={() => setCommentBeforeDelete(true)}
            />
        )}

        {isInfoOpen && (
            <Info
                item={item}
                isInfoOpen={isInfoOpen}
            />
        )}

        {isOpenMoveEntityModal && (
            <MoveEntityModal
                isOpen={isOpenMoveEntityModal}
                onClose={() => setIsOpenMoveEntityModal(false)}
                entityId={item.id}
                currentComplex={currentComplex}
                onEdited={onEdited}
            />
        )}

    </>);
};

export default Item;
