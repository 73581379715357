import { createSelector } from 'reselect';

import { compareQueryStrings, parseUrlString } from 'helpers/doris.control/helper';

export const auth = (state: any) => state.auth;

export const loading = (state: any) => auth(state).loading;

export const authUser = (state: any) => auth(state)?.authUser;
export const authSuperUser = (state: any) => auth(state).authSuperUser;
export const authUserInfo = (state: any) => authUser(state)?.info || {};
// id слоя пользователя
export const userMapLayer = createSelector(
    authUserInfo,
    (userInfo) => userInfo?.map_layer?.id || null
);

export const project = (state: any) => auth(state).project;
export const projectName = (state: any) => project(state).name;
export const projectCode = (state: any) => project(state).code;

export const services = (state: any) => auth(state).services;

export const permissionList = (state: any) => auth(state).permissionList;

export const loadingUserFilters = (state: any) => auth(state).loadingUserFilters;

export const userFilters = (state: any) => auth(state).userFilters;

// выбираем среди фильтров только отсящиеся к данной странице
export const currentPageFilter = createSelector(
    userFilters,
    (_: any, { page, tab, layer, search }: {
        page: string,
        tab?: string| null,
        layer?: string | null,
        search?:string
    }) => ({ page, tab, layer, search }),
    (userFilters, { page, tab = null, layer = null, search = '' }) => {
        if (Array.isArray(userFilters)) {
            const currentFilter = userFilters.find((el) => {
                const querySaved = parseUrlString(el.link).query; // строка query сохраненная в поле link
                const pathSaved = parseUrlString(el.link).path; // строка path сохраненная в поле link
                // совпадают ли параметры query в урле и сохраненные
                const linkContainsSearchParams = compareQueryStrings(querySaved, search);
                const pagesIdentical = pathSaved === page && (tab ? linkContainsSearchParams : true);
                return  pagesIdentical && el.tab === tab && el.layer === layer;
            });
            return currentFilter || {};
        }
        return null;
    }
);


export const mapLayers = (state: any) => auth(state)?.mapLayers;

export const loadingButton = (state: any) => auth(state).loadingButton;

export const accessTemplate = (state: any) => auth(state).accessTemplate;
