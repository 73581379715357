import React, { useState } from 'react';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import BoardsListModal from 'components/common/Boards/BoardsListModal';

const Item = (props) => {
    const {
        name,
        onClick,
        id,
        entity_name,
    } = props;

    const [isOpenBoardListModal, setIsOpenBoardListModal] = useState(false);

    const openBoardListModal = (e) => {
        e.stopPropagation();
        setIsOpenBoardListModal(true);
    };

    const closeBoardListModal = () => {
        setIsOpenBoardListModal(false);
    };

    return (
        <>
            <div className="description" style={{ display: 'flex', justifyContent:'space-between' }} onClick={onClick}>
                <div>
                    Название: {name}
                </div>
                <FormButtonsComponent
                    noPadding
                    noMarginLeft
                    buttons={[
                        {
                            ...buttonsTypes.defaultOutlined,
                            onClick: openBoardListModal,
                            name: 'ДИТ',
                            size: 'small'
                        }
                    ]}
                />
            </div>
            {isOpenBoardListModal && (
                <BoardsListModal
                    id={id}
                    name={name}
                    entity_name={entity_name}
                    isOpen={isOpenBoardListModal}
                    onClose={closeBoardListModal}
                    microservice="ecology"
                />
            )}
        </>
    );
};

export default Item;
