import Paper from '@mui/material/Paper';

import type { Address } from 'redux/Dadata/ts-types';
import FormButtonsComponent from 'components/common/FormButtons';

interface AddressListProps {
    list: Address[];
    onClick: (arg1: Address, arg2: string) => void;
}

const AddressList = ({ list, onClick }: AddressListProps) => {

    return (
        <Paper style={{ padding: 8, position: 'absolute', zIndex: 99, top: 40, width: '100%' }}>
            {list.map((item, index) => {
                const address = `${item.city_name || item.settlement || item.region || ''} ${item.street || ''} ${item.house || ''}`;

                return (
                    <div key={`${item.house}_${index}`}>
                        <FormButtonsComponent
                            justButton
                            buttons={[
                                {
                                    onClick: () => onClick(item, address),
                                    name: address
                                }
                            ]}
                        />
                    </div>
                );
            })}
        </Paper>
    );
};

export default AddressList;