import { useMemo, useState } from 'react';
import { v4 as uuid_v4 } from 'uuid';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import type { OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

import EditOperationList from '../EditOperationList';

export const initialRow = {
    position: null,
    is_typical: false,
    name: '',
    type: 1,
    operation_id: null,
    organization: null,
    organization_id: null,
    cycle: '',
    time: null,
    is_auto: false,
    is_transfer: false,
    is_parallel: false,
    parallel: [],
    group: null as string | null,
};

interface AddEditOperationModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (newData: OperationInScenario[]) => void;
    data: OperationInScenario[];
    showPlaceSelector?: boolean;
    scenarioId?: number;
}

const AddEditOperationModal = ({
    data = [],
    isOpen,
    onClose,
    onSave,
    scenarioId,
}: AddEditOperationModalProps) => {    

    const isDisabled = useMemo(() => data.length > 0 && data.some(el => !el.name || !el.organization_id), [data]);

    const [newData, setNewData] = useState(data.map((el: any) => ({ ...el, id: uuid_v4() })));

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Операции"
            noPadding={true}
            fullWidth
            buttons={
                <FormButtons
                    noPadding
                    justButton
                    buttons={
                        [{
                            ...buttonsTypes.save,
                            onClick: () => onSave(newData),
                            disabled: isDisabled,
                        },
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                        }]
                    }
                />
            }

        >
            <div className="modal__form">
                <EditOperationList
                    list={newData}
                    setData={(value) => setNewData(value.map((el: any) => ({ ...el, id: el.id || uuid_v4() })))}
                    scenarioId={scenarioId}
                />
            </div>
        </Modal>
    );
};

export default AddEditOperationModal;
