import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */

export const LOAD_TYPES = `${prefix}/LOAD_TYPES`;
export const LOADED_TYPES = `${prefix}/LOADED_TYPES`;

export const LOAD_STATUS = `${prefix}/LOAD_STATUS`;
export const LOADED_STATUS = `${prefix}/LOADED_STATUS`;
export const LOADING_TRAFFIC = `${prefix}/LOADING_TRAFFIC`;

export const LOAD_TRAFFIC = `${prefix}/LOAD_TRAFFIC`;
export const LOADED_TRAFFIC = `${prefix}/LOADED_TRAFFIC`;
export const LOAD_TRAFFIC_INFO = `${prefix}/LOAD_TRAFFIC_INFO`;

export const GET_POLYGON = `${prefix}/GET_POLYGON`;
export const SAVE_POLYGON = `${prefix}/SAVE_POLYGON`;
export const LOADING_POLYGON = `${prefix}/LOADING_POLYGON`;

export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR_SIDEBAR`;
export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;

export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;

export const SET_FILTER = `${prefix}/SET_FILTER`;

export const LOADING_LIST = `${prefix}/LOADING_LIST`;
export const LOADED_LIST = `${prefix}/LOADED_LIST`;
export const CLEAR_LIST = `${prefix}/CLEAR_LIST`;
export const LOAD_LIST = `${prefix}/LOAD_LIST`;
export const SET_FILTER_LIST = `${prefix}/SET_FILTER_LIST`;

export const LOAD_REPORT = `${prefix}/LOAD_REPORT`;
export const LOADED_REPORT = `${prefix}/LOADED_REPORT`;
export const LOADING_REPORT = `${prefix}/LOADING_REPORT`;
export const SET_FILTER_REPORT = `${prefix}/SET_FILTER_REPORT`;
export const LOAD_REPORT_GROUPS = `${prefix}/LOAD_REPORT_GROUPS`;
export const LOADED_REPORT_GROUPS = `${prefix}/LOADED_REPORT_GROUPS`;
export const LOAD_REPORT_TYPES = `${prefix}/LOAD_REPORT_TYPES`;
export const LOADED_REPORT_TYPES = `${prefix}/LOADED_REPORT_TYPES`;


export const LOAD_TRANSPORT_FLOW_REPORT = `${prefix}/LOAD_TRANSPORT_FLOW_REPORT`;
export const LOADED_TRANSPORT_FLOW_REPORT = `${prefix}/LOADED_TRANSPORT_FLOW_REPORT`;
export const LOADING_TRANSPORT_FLOW_REPORT = `${prefix}/LOADING_TRANSPORT_FLOW_REPORT`;
export const LOAD_TRANSPORT_FLOW_GROUPS = `${prefix}/LOAD_TRANSPORT_FLOW_GROUPS`;
export const LOADED_TRANSPORT_FLOW_GROUPS = `${prefix}/LOADED_TRANSPORT_FLOW_GROUPS`;
// export const LOAD_TRANSPORT_FLOW_TYPES = `${prefix}/LOAD_TRANSPORT_FLOW_TYPES`;
// export const LOADED_TRANSPORT_FLOW_TYPES = `${prefix}/LOADED_TRANSPORT_FLOW_TYPES`;

export const LOAD_THROUGHPUT = `${prefix}LOAD_THROUGHPUT`;
export const LOADING_THROUGHPUT = `${prefix}LOADING_THROUGHPUT`;
export const LOADED_THROUGHPUT = `${prefix}LOADED_THROUGHPUT`;

export const LOAD_HEAT_MAP = `${prefix}/LOAD_HEAT_MAP`;
export const LOADED_HEAT_MAP = `${prefix}/LOADED_HEAT_MAP`;
export const LOADING_HEAT_MAP = `${prefix}/LOADING_HEAT_MAP`;
export const FILTER_HEAT_MAP = `${prefix}/FILTER_HEAT_MAP`;

export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

export const LOAD_REPORT_INDICATORS = `${prefix}/LOAD_REPORT_INDICATORS`;
export const LOADING_REPORT_INDICATORS = `${prefix}/LOADING_REPORT_INDICATORS`;
export const LOADED_REPORT_INDICATORS = `${prefix}/LOADED_REPORT_INDICATORS`;
export const SET_FILTER_REPORT_INDICATORS = `${prefix}/SET_FILTER_REPORT_INDICATORS`;

export const LOAD_TRANSPORT_FLOW_BY_RADIUS = `${prefix}/LOAD_TRANSPORT_FLOW_BY_RADIUS`;
export const LOADED_TRANSPORT_FLOW_BY_RADIUS = `${prefix}/LOADED_TRANSPORT_FLOW_BY_RADIUS`;

export const CREATE_LOGICAL_PUID = `${prefix}/CREATE_LOGICAL_PUID`;
export const EDIT_LOGICAL_PUID = `${prefix}/EDIT_LOGICAL_PUID`;
export const DELETE_LOGICAL_PUID = `${prefix}/DELETE_LOGICAL_PUID`;
export const LOADING_LOGICAL_PUID = `${prefix}/LOADING_LOGICAL_PUID`;
export const SAVED_LOGICAL_PUID = `${prefix}/SAVED_LOGICAL_PUID`;
export const REPLACE_POLYGON_SIDEBAR_LOGICAL_PUID = `${prefix}/REPLACE_POLYGON_SIDEBAR_LOGICAL_PUID`;

export const LOAD_PUID_BY_IDS = `${prefix}/LOAD_PUID_BY_IDS`;

export const LOAD_PUID_BY_RADIUS = `${prefix}/LOAD_PUID_BY_RADIUS`;
export const LOADED_PUID_BY_RADIUS = `${prefix}/LOADED_PUID_BY_RADIUS`;
export const LOADING_PUID_BY_RADIUS = `${prefix}/LOADING_PUID_BY_RADIUS`;
export const CLEAR_PUID_BY_RADIUS = `${prefix}/CLEAR_PUID_BY_RADIUS`;

export const LOAD_SETTINGS = `${prefix}LOAD/SETTINGS`;
export const LOADED_SETTINGS = `${prefix}/LOADED/SETTINGS`;
export const LOADING_SETTINGS = `${prefix}/LOADING/SETTINGS`;
export const EDIT_SETTING = `${prefix}/EDIT/SETTING`;
export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const LOAD_REPORT_STATISTIC_TYPES = `${prefix}/LOAD_REPORT_STATISTIC_TYPES`;
export const LOADED_REPORT_STATISTIC_TYPES = `${prefix}/LOADED_REPORT_STATISTIC_TYPES`;

export const LOAD_REPORT_TIME_SLOT = `${prefix}/LOAD_REPORT_TIME_SLOT`;
export const LOADED_REPORT_TIME_SLOT = `${prefix}/LOADED_REPORT_TIME_SLOT`;
