import { useState } from 'react';

import InformPanel from 'components/layout/PageLayout/InformPanel';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import AddEditOperationModal from 'modules/React/Dictionaries/ResponseScenarios/PassportPage/Operations/AddFormModal';
import RenderOperationList from 'modules/React/Dictionaries/ResponseScenarios/PassportPage/Operations/RenderOperationList';
import type { OperationInScenario, ResponseScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

interface OperationsProps {
    formData: ResponseScenario;
    handleChange: (name: string, value: any) => void
}

const Operations = ({ formData, handleChange  }: OperationsProps) => {
    const { operation_list: data } = formData;

    const [isOpenForm, setIsOpenForm] = useState(false);

    const onSave = (list: OperationInScenario[]) => {
        handleChange('operation_list', list);
        setIsOpenForm(false);
    };

    return (
        <>
            <InformPanel
                buttons={
                    <FormButtons
                        noPadding
                        justButton
                        buttons={[{
                            ...( data.length > 0 ? buttonsTypes.edit : buttonsTypes.add),
                            onClick: () => setIsOpenForm(true),
                        }]
                        }
                    />
                }
            />
            <RenderOperationList
                data={data}
            />
            {isOpenForm && (
                <AddEditOperationModal
                    isOpen={isOpenForm}
                    onClose={() => setIsOpenForm(false)}
                    onSave={onSave}
                    data={data}
                    scenarioId={formData.id}
                />
            )}
        </>
    );
};

export default Operations;
