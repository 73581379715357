import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import titles from 'helpers/constants/titles';
import NoData from 'components/common/Information/NoData';
import Loading from 'components/common/Loading';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import { LSContainer } from 'components/common/List';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import reactTitles from 'modules/React/utils/helpers/constants';
import { reactSelectors } from 'modules/React';
import AddEditOperationModal from 'modules/React/Dictionaries/ResponseScenarios/PassportPage/Operations/AddFormModal';
import { 
    editIncident,
    editIncidentOperations, 
    loadIncidentOperations 
} from 'modules/React/redux/actions';
import type { OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';
import type { IncidentItem } from 'modules/React/Dictionaries/Incidents/types';

import Item from './Item';

interface ScenarioProps {
    item: IncidentItem | null;
    onEdited: () => void;
}

const Scenario = ({ item: incident, onEdited }: ScenarioProps) => {
    const dispatch = useDispatch();

    const operations = useSelector(reactSelectors.incidentOperations).data;
    const loading = useSelector(reactSelectors.loadingIncidentOperations);
    const statuses = useSelector(reactSelectors.incidentsStatuses);
    
    const [isOpenForm, setIsOpenForm] = useState(false);

    const loadList = useCallback(() => {
        incident?.id && dispatch(loadIncidentOperations(incident?.id));
    }, [dispatch, incident?.id]);

    useEffect(() => {
        loadList();
    }, [loadList]);

    const onChangeList = (list: OperationInScenario[]) => {
        const callback = () => {
            setIsOpenForm(false);
            loadList();
        };
        
        const preparedList = list.map(el => ({ ...el, is_done: el.is_done || false }));

        dispatch(editIncidentOperations(incident?.id, preparedList, callback));
    };

    const onChangeItem = (item: OperationInScenario, onCloseModal: () => void,  index: number) => {
        const newOperations = [
            ...operations.slice(0, index),
            item,
            ...operations.slice(index + 1)
        ];

        dispatch(editIncidentOperations(incident?.id, newOperations, () => {
            loadList();
            onEdited();
            onCloseModal();
        }));

        // Если все операции Выполнены, то меняем статус инцидента
        let newStatusId;

        if (newOperations.find(el => !el.is_done)) {
            newStatusId = incident?.response_scenario
                ? statuses.find((el: any) => el.name === 'Присвоен сценарий')?.id
                : statuses.find((el: any) => el.name === 'В работе')?.id;
        } else {
            newStatusId = statuses.find((el: any) => el.name === 'Обработан')?.id;
        }

        if (newStatusId && incident?.status_id !== newStatusId) {
            dispatch(editIncident(incident?.id, { ...incident, status_id: newStatusId }));
        }
    };

    return  <>
        {loading && <Loading linear/>}

        <InformPanel
            buttons={
                <FormButtons
                    noPadding
                    justButton
                    buttons={[{
                        ...(operations?.length > 0 ? buttonsTypes.edit : buttonsTypes.add),
                        onClick: () => setIsOpenForm(true),
                    }]
                    }
                />
            }
        />

        {incident?.response_scenario && (
            <>
                <Typography variant="subtitle1">Сценарий реагирования:</Typography>
                <Typography><b>ID Сценария:</b> {incident.response_scenario.id}</Typography>
                <Typography><b>Наименование сценария:</b> {incident.response_scenario.name}</Typography>
            </>
        )}
            
        {operations?.length > 0
            ? <LSContainer
                headers={[
                    { title: titles.NUM, width: '5%' },
                    { title: reactTitles.OPERATION, width: '20%' },
                    { title: <span>Дата/время<br/>начала факт</span>, width: '15%' },
                    { title: <span>Дата/время<br/>выполнения факт</span>, width: '15%' },
                    { title: <span>Время<br/>по регламенту (мин)</span>, width: '15%' },
                    { title: titles.COMMENT, width: '20%' },
                    { title: titles.STATUS, width: '10%' },
                ]}
            >
                {operations.map((item: OperationInScenario, index: number) => (
                    <Item
                        key={item.position}
                        item={item}
                        disableIsDone={(index !== 0 && !operations[index - 1]?.is_done )|| operations[index + 1]?.is_done}
                        onChangeItem={(el, cb) => onChangeItem(el, cb, index)}
                    />
                ))}
            </LSContainer>
            : <NoData/>
        }

        {isOpenForm && (
            <AddEditOperationModal
                isOpen={isOpenForm}
                onClose={() => setIsOpenForm(false)}
                onSave={onChangeList}
                data={operations}
            />
        )}
    </>;
};

export default Scenario;
