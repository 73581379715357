import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import { useStoreFromSelector } from 'helpers/hooks';
import { reactSelectors } from 'modules/React';
import { loadIncidentCategories } from 'modules/React/redux/actions';

interface TypeProps<T> extends CommonAutocompleteProps<T> {
    filter?: {
        name: string;
    },
}

type IncidentCategoriesType = {
  id?: number,
  name?: string
}

const SelectIncidentCategories = ({
    multiple,
    selected,
    onChange,
    required,
    disabled,
    label = 'Категория инцидента',
    error,
    helperText,
    className,
    onReset,
}: TypeProps<IncidentCategoriesType>) => {
    const incidentCategories = useStoreFromSelector(loadIncidentCategories, reactSelectors.incidentCategories);
    const loadingIncidentCategories = useStoreFromSelector(loadIncidentCategories, reactSelectors.loadingIncidentCategories);
    
    return (
        <>
            <CommonAutocomplete
                className={className}
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                renderLabel={(option) => option?.name || ''}
                label={label}
                error={error}
                helperText={helperText}
                onReset={onReset}
                showAsSelect
                options={incidentCategories || []}
                filterSelectedOptions
                loading={loadingIncidentCategories}
            />
        </>
    );
};

export default SelectIncidentCategories;
