import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteInfrastructureTypes } from 'modules/InfrastructureObjects/redux/actions';
import messages from 'helpers/constants/messages';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';

import ITModal from './ITModal';

const ITItem = ({ el, reloadList, }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(deleteInfrastructureTypes(el.id, () => {
            reloadList();
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            {openEditModal && (
                <ITModal
                    isNew={false}
                    onClose={() => setOpenEditModal(false)}
                    isOpen={openEditModal}
                    el={el}
                    reloadList={reloadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
            <LSContentItem>
                <LSContentColumn>{el.name || messages.NO_DATA}</LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
        </>
    );
};

export default ITItem;
