import { useState } from 'react';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { LSContentColumn, LSContentItem } from 'components/common/ListStyle';
import ConfirmDelete from 'components/common/ConfirmModal';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

const RepairObjectsItem = ({ index, item, onEdit, onDelete }) => {
    const [openDelete, setOpenDelete] = useState(false);

    return (
        <>
            <LSContentItem fullWidth >
                <LSContentColumn title="№" width="7%">
                    {index + 1}
                </LSContentColumn>
                <LSContentColumn title="ID" width="11%">
                    {item?.repair_object_id}
                </LSContentColumn>
                <LSContentColumn title={titles.NAME} width="30%">
                    {item?.data?.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn title={titles.COUNT} width="20%">
                    <TextField
                        label={titles.COUNT}
                        variant="outlined"
                        size="small"
                        name="count"
                        value={item?.count || ''}
                        type="number"
                        onChange={(e) => onEdit(index, item, e)}
                        inputProps={{ min: 1 }}
                    />
                </LSContentColumn>
                <LSContentColumn title={'Ед. изм.'} width="12%">
                    {'шт.'}
                </LSContentColumn>
                <LSContentColumn align="right"  isActions>
                    <Tooltip title={titles.DELETE}>
                        <IconButton onClick={() => setOpenDelete(true)} size="small">
                            <Delete fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </LSContentColumn>
            </LSContentItem>
            {openDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDelete}
                    onSuccess={() => {onDelete(index); setOpenDelete(false);}}
                    onClose={() => setOpenDelete(false)}
                />
            )}
        </>
    );
};

export default RepairObjectsItem;
