import { useState } from 'react';
import { useSelector } from 'react-redux';

import { focusIncidentsSelectors } from 'redux/FocusIncidents';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import DtpListModal from './DtpListModal';
import OperationListModal from './OperationListModal';

// кнопки в модалке
const ModalButtons = ({
    data,
}) => {

    const polygonLoading = useSelector(focusIncidentsSelectors.polygonLoading);
    const [openDtpList, setOpenDtpList] = useState(false);
    const [openOperationList, setOpenOperationList] = useState(false);

    return (
        <>
            <FormButtons
                buttons={[
                    ...(data.transport_incident_id
                        ? [{
                            ...buttonsTypes.defaultPrimary,
                            size: 'small',
                            name: 'Мероприятия',
                            onClick: () => setOpenOperationList(true)
                        }]
                        : []
                    ),
                    {
                        ...buttonsTypes.defaultPrimary,
                        size: 'small',
                        name: 'Присвоенные ДТП',
                        disabled: !data?.dtp_list?.length,
                        onClick: () => !polygonLoading && setOpenDtpList(true)
                    },

                ]}
            />

            {openDtpList && (
                <DtpListModal
                    isOpen={openDtpList}
                    onClose={() => setOpenDtpList(false)}
                    data={data}
                />
            )}

            {openOperationList && (
                <OperationListModal
                    isOpen={openOperationList}
                    onClose={() => setOpenOperationList(false)}
                    tiId={data.transport_incident_id}
                />
            )}

        </>
    );
};

export default ModalButtons;
