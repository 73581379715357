import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';

import { clearValidation } from 'redux/Validation/actions';
import {
    createInfrastructureTypes,
    editInfrastructureTypes
} from 'modules/InfrastructureObjects/redux/actions';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const ITModal = ({
    isNew,
    onClose,
    isOpen,
    el = {},
    reloadList
}) => {
    const dispatch = useDispatch();

    const initialState = {
        name: el?.name || ''
    };

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const [data, setData] = useState(initialState);

    const handleCreateRouteRequests = () => {
        dispatch(createInfrastructureTypes(data, () => {
            reloadList();
            onClose();
        }));
    };

    const handleEditRouteRequests = () => {
        dispatch(editInfrastructureTypes(el.id, data, () => {
            reloadList();
            onClose();
        }));
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
            dispatch(clearValidation());
        }
    }, [dispatch, onClose, requestStatus, validation]);

    return (
        <Modal
            title={isNew ? titles.ADD_INFRASTRUCTURE_TYPES: titles.EDIT_INFRASTRUCTURE_TYPES}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    isNew
                        ? {
                            ...buttonsTypes.create,
                            onClick: handleCreateRouteRequests,
                            disabled: !data.name
                        }
                        : {
                            ...buttonsTypes.save,
                            onClick: handleEditRouteRequests
                        }
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        required={true}
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name}
                        onChange={
                            (e) =>
                                setData({ 'name': e.target.value })
                        }
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default ITModal;