import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    isEqual,
    keyBy,
    merge,
    values,
} from 'lodash';

import {
    changeFilter,
    clearFilter,
    loadRoutesStatuses,
    loadSidebar,
    loadVehicleCategories,
    setActive,
    setFilter,
    setShowRouteByVehicle,
    setSidebarParams,
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import { usePrevious, useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import useLoadRouteCheckPoints from 'helpers/hooks/TransportPassenger/useLoadRouteCheckPoints';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import Loading from 'components/common/Loading';
import getFilters from 'components/MapComponents/helpers/getFilters';

import config from '../config';

import Form from './Filter/Form';
import GroupItem from './GroupItem';
import './style.scss';


const SideBarTP = (props) => {
    const dispatch = useDispatch();

    const active = useSelector(transportPassengerSelectors.active);
    const filters = useSelector(transportPassengerSelectors.filters);
    const sidebar = useSelector(transportPassengerSelectors.sidebar);
    const sidebarLoading = useSelector(transportPassengerSelectors.sidebarLoading);
    const sidebarParams = useSelector(transportPassengerSelectors.sidebarParams);
    const showRouteByVehicle = useSelector(transportPassengerSelectors.showRouteByVehicle);
    const statuses = useStoreProp(loadRoutesStatuses, 'transportPassenger', 'statuses');

    useEffect(() => {
        if (Object.keys(statuses)?.length) {
            dispatch(setFilter({
                ...filters,
                status_list: Object.keys(statuses)
                    ?.map((id) => ({ id, name: statuses[id] }))
                    .filter(({ id }) => [1,2].includes(Number(id)))
            }));
        }
    }, [dispatch, statuses]);

    useEffect(() => {
        return () => {
            dispatch(setSidebarParams({
                ...sidebarParams,
                page: 1
            }));
        };
    }, []);

    const prevFilters = usePrevious(filters);

    const getTransportCategory = useTransportCategory(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    // догрузка
    const [loadedActive, setLoadedActive] = useState(null);
    useEffect(() => {
        if (loadedActive) {
            const loadedActiveData = Array.isArray(loadedActive)
                ? loadedActive
                : [loadedActive];

            const activeIds = active.map(({ id }) => id);
            const filtered = loadedActiveData.filter(({ id }) => activeIds.includes(id));

            const merged = merge(
                keyBy(active, 'id'),
                keyBy(filtered, 'id')
            );
            const newActive = values(merged);
            dispatch(setActive(newActive));
            setLoadedActive(null);
        }
    }, [active, loadedActive]);
    const providerRouteCheckPoint = useLoadRouteCheckPoints((loadedItem) => {
        setLoadedActive(loadedItem);
    });

    // отслеживаем изменение проекта
    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            dispatch(setSidebarParams({ page: 1 }));
        } else {
            dispatch(loadSidebar(
                sidebarParams.page,
                sidebarParams.limit,
                getFilters(filters)
            ));
        }
    }, [sidebarParams, filters, prevFilters, dispatch]);

    // применить фильтр
    const handleFilterChange = (newFilter) => {
        dispatch(setFilter(newFilter));
        dispatch(setActive([]));
    };

    // сбросить фильтр
    const handleFilterReset = () => {
        dispatch(clearFilter());
        dispatch(setActive([]));
    };

    // вывести фильтр
    const renderFilter = () => (
        <SidebarFilter
            filters={filters}
            onSetFilter={handleFilterChange}
            onClearFilter={handleFilterReset}
            content={(props) => <Form {...props} statuses={statuses} />}
            layer={config.slug}
        />
    );

    const handleSetActive = (item, lock = false) => {
        const lockItems = active.reduce(
            (res, el) => (el.lock ? [...res, el] : res),
            []
        );

        const findIndex = active.findIndex(({ id }) => id === item?.id);

        if (findIndex === -1) {
            dispatch(setActive([
                ...lockItems,
                item,
            ]));

            // догрузка данных
            providerRouteCheckPoint.findByRoute(item);
        } else {
            dispatch(setActive([
                ...lockItems,
            ]));
        }
    };

    // клик по группе
    const handleSetActiveGroup = (items, isGrouped = false) => {
        const lockItems = active.filter(item => item.lock);
        const lockedIds = active.map(({ id }) => id);
        const list = items.reduce((r, item) => {
            if (!lockedIds.includes(item.id)) {
                const newItem = { ...item };
                if (isGrouped) newItem.lock = true;
                r.push(newItem);
            }
            return r;
        }, []);

        if (list.length > 0) {
            dispatch(setActive([
                ...lockItems,
                ...list,
            ]));

            // догрузка данных
            providerRouteCheckPoint.findByRouteList(list[0]);
            // providerRouteCheckPoint.findByRoute(item);
        } else {
            dispatch(setActive([
                ...lockItems,
            ]));
        }
    };

    const handleClearActive = (items) => {
        if (showRouteByVehicle) {
            onClearRouteByVehicle();
        } else {
            const itemsIds = items.map(({ id }) => id);
            const lockItems = active.filter(item => item.lock && !itemsIds.includes(item.id));

            dispatch(setActive([
                ...lockItems,
            ]));
        }
    };

    const changeLock = (item, lock) => {
        const index = active.findIndex(({ id }) => id === item?.id);
        if (index >= 0) {
            const isLock = active[index]?.lock || false;
            const newActive = isLock
                ? [
                    ...active.slice(0, index),
                    ...active.slice(index + 1)
                ]
                : [
                    ...active.slice(0, index),
                    {
                        ...active[index],
                        lock,
                    },
                    ...active.slice(index + 1)
                ];
            dispatch(setActive(newActive));
        } else {
            dispatch(setActive([
                ...active,
                {
                    ...item,
                    lock,
                }
            ]));
            // догрузка данных
            providerRouteCheckPoint.findByRoute(item);
        }
    };

    const activeById = (cId) => active.find(({ id }) => id === cId) || null;

    // сброс маршрута из транспорта
    const onClearRouteByVehicle = () => dispatch(setShowRouteByVehicle(false));

    // сброс при выходе с маршрута тс
    useEffect(() => showRouteByVehicle
        ? () => {
            // сброс маршрута из транспорта
            dispatch(changeFilter({
                num_list: [],
                category_id_list: []
            }));
            dispatch(setActive([]));
            onClearRouteByVehicle();
        }
        : null
    , [showRouteByVehicle]);

    const renderList = () => (
        <div className="passanger-transport">
            {sidebar?.data?.map((elem, index) => (
                <GroupItem
                    key={index}
                    // key={`${elem.num}${elem.category_id}`}
                    elem={elem}
                    handleSetActive={handleSetActive}
                    handleClearActive={handleClearActive}
                    handleSetActiveGroup={handleSetActiveGroup}
                    changeLock={changeLock}
                    activeById={activeById}
                    providerRouteCheckPoint={providerRouteCheckPoint}
                    transportCategory={getTransportCategory}
                    onClearRouteByVehicle={
                        showRouteByVehicle
                        // текущая категория
                        && active.find(e =>
                            e.num === elem.num
                            && e.category_id === elem.category_id
                        )
                            ? onClearRouteByVehicle
                            : null
                    }
                />
            ))}
        </div>
    );

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = sidebar.meta;
        if (
            values.top > 0.88
            && sidebarLoading === false
            && sidebarParams.page < last_page
        ) {
            dispatch(setSidebarParams({
                page: sidebarParams.page + 1,
            }));
        }
    };

    return (
        <div className="layers-sidebar__flex-column">
            {renderFilter()}

            {sidebarLoading && Object.keys(sidebar?.data).length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={titles.PASSENGER_TRANSPORT}
                            list={sidebar}
                            // count={routesCount}
                        />
                        {Object.keys(sidebar?.data).length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList()}
                    </div>
                    {sidebarLoading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBarTP;
