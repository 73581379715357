import IncidentsMetro from 'modules/React/Reports/IncidentsMetro';
import InfoPanel from 'modules/React/Reports/InfoPanel';  

const reportRoutes = [
    {
        path: '/react/reports/incidents-metro',
        component: IncidentsMetro,

    },
    {
        path: '/react/reports/infopanel',
        component: InfoPanel,
        exact: true,
    },
];

export default reportRoutes;