import { useState } from 'react';

import HeaderPanel from 'components/common/Showcase/HeaderPanel';
import { HeaderPageVisible } from 'components/common/Showcase/HeaderPage';
import DtpReportTypes from 'components/pages/Reports/DTP/DtpReportTypes';
import DangersArea from 'components/pages/Reports/DTP/DangersArea';
import HeatMapConcentration from 'components/pages/Reports/DTP/HeatMapConcentration';
import HeatMapDistrict from 'modules/Dtp/Reports/HeatMapDistrict';
import DtpGraph from 'modules/Dtp/Reports/DtpGraph';
import Tabs from 'modules/Dashboard/utils/Components/Tabs';

import DtpMap from './DtpMap';

const StatisticsDtp = () => {
    // блокировка табов
    const [disabled, setDisabled] = useState(false);

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <HeaderPageVisible/>

            <HeaderPanel title="Сводка ДТП" />

            <Tabs
                disabled={disabled}
                list={[
                    {
                        tab: 'heat-map-region',
                        label: 'Тепловая карта по ДТП по районам',
                        component: (
                            <HeatMapDistrict
                                tab="heat-map-region"
                                hideTitle
                                onBlocked={setDisabled}
                            />
                        ),
                    },
                    {
                        tab: 'report-type',
                        label: 'По типам',
                        component: <DtpReportTypes tab="report-type" hideTitle />,
                    },
                    {
                        tab: 'report-graph',
                        label: 'Сравнение периодов',
                        component: <DtpGraph tab="report-graph" hideTitle />,
                    },
                    {
                        tab: 'heat-map-dtp',
                        label: 'Тепловая карта по ДТП',
                        component: <DangersArea tab="heat-map-dtp" hideTitle />,
                    },
                    {
                        tab: 'heat-map-mkdtp',
                        label: 'Тепловая карта по МКДТП',
                        component: <HeatMapConcentration tab="heat-map-mkdtp" hideTitle />,
                    },
                    {
                        tab: 'dtp-map',
                        label: 'ДТП на карте',
                        component: <DtpMap tab="dtp-map" />,
                    },
                ]}
            />
        </div>
    );
};

export default StatisticsDtp;
