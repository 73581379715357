import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Autocomplete, TextField, } from '@mui/material';
import _ from 'lodash';

import { createEventObjects, editEventObjects, loadEventObjectsTypes } from 'modules/React/redux/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import KeyWords from 'components/common/Autocomplete/DorisReact/KeyWords';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import SelectEvents from 'modules/React/utils/components/SelectEvents';
import SelectEventObjectsTypes from 'modules/React/utils/components/SelectEventObjectTypes';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const ModalForm = ({
    isOpen,
    onClose,
    isNew = false,
    item = {},
    reloadList = () => {}
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const types = useStoreProp(loadEventObjectsTypes, 'react', 'eventObjectsTypes');

    const initialState = {
        lat: item.lat || '',
        lon: item.lon || '',
        name: item?.name || '',
        event: item?.event || {},
        event_id: item?.id || null,
        type: isNew ? {} : { id: item?.type_id, name: item?.type_name },
        type_id: item?.type_id || null,
        address: item?.address || '',
        geometry: item?.geometry || {},
        keyword_list: item?.keyword_list || [],
        keyword_id_list: item?.keyword_id_list || [],
        route_id: item?.route_id || null,
        route: item?.route || {},
    };

    console.log('item', item);


    const [data, setData] = useState(initialState);
    console.log('data', data);

    const handleChangeRoute = (route) => {
        setData({
            ...data,
            route,
            route_id: route.id

        });
    };

    const onAcceptModal = () => {
        const newData = {
            ...data,
        };
        delete newData.lat;
        delete newData.lon;

        const transformationDataForSend = removeEmptyFields(newData);

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createEventObjects(transformationDataForSend, callback))
            : dispatch(editEventObjects(item?.id, transformationDataForSend, callback));
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChangeType = (newValue) => {
        setData({
            ...data,
            type: newValue,
            type_id: newValue.id,
            route_id: null,
            route: {}
        });
    };

    const onChangeGeoJson = (params) => {
        const { address, address_text, ...pr } = params;
        if (address_text) pr.address = address_text;

        setData({
            ...data,
            ...pr
        });
    };

    const isDisabled = !data?.name
        // || !data?.event_id?.id
        || !data?.type_id
        || !data.address
        || (data?.type_id === 1 && !data?.route_id);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isNew ? titles.CREATE : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        label={titles.NAME}
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        required
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </div>
                <div className="block">
                    <SelectEvents
                        required
                        onChange={(value) => setData({ ...data, event: value, event_id: value.id })}
                        selected={data.event}
                        error={Object.keys(data.event).length === 0 || validation.isKey('event_id')}
                        helperText={validation.get('event_id')}
                    />
                </div>
                <div className="block">
                    <SelectEventObjectsTypes
                        selected={data.type}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={types}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option.label || ''}
                        onChange={handleChangeType}
                    />
                </div>
                <div className="block">
                    <KeyWords
                        multiple
                        selected={data.keyword_list}
                        onChange={(value) => setData({
                            ...data,
                            keyword_list: value,
                            keyword_id_list: value.id
                        })}
                    />
                </div>
                {data.type_id === 1 && (
                    <FormInfoWrapper error={_.isEmpty(data.route)} helperText="Маршурты, используемые в мероприятиях">
                        <SelectRoutes
                            selected={data.route}
                            onChange={handleChangeRoute}
                            required
                            error={_.isEmpty(data.route)}
                            filter={{ is_event: true }}

                        />
                    </FormInfoWrapper>
                )}
                <div className="block">
                    <TextField
                        required
                        label={titles.ADDRESS}
                        size="small"
                        value={data.address}
                        error={!data.address}
                        variant="outlined"
                        name="address"
                        type="text"
                        disabled
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                    />
                </div>
                <LoadAddressByCoords
                    {...data}
                    onChange={onChangeGeoJson}
                    isLoadOnLatLon={true}
                    isLoadOnChange={true}
                >
                    <MapGeoJson
                        // geometry={data.geometry}
                        // onChange={onChangeGeoJson}
                        circle={false}
                        rectangle={false}
                        polyline={true}
                        polygon={true}
                        marker={true}
                        returnLatLon={true}
                    />
                </LoadAddressByCoords>
            </form>
        </Modal>
    );
};

export default ModalForm;
