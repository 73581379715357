import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEqual } from 'lodash';

import * as actions from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import Loading from 'components/common/Loading';
import { buttonsTypes } from 'components/common/FormButtons';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import { usePrevious, useStoreProp } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';

import { getFilter } from '../helper';
import config from '../config';
import { getIcon } from '../helper';

import Item from './Item';
import Form from './Form';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const [parameters, setParameters] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const roadSectionTypes = useStoreProp(actions.loadRoadSectionTypes, 'roadNetworks', 'roadSectionTypes');
    const roadSectionCategories = useStoreProp(actions.loadRoadSectionCategories, 'roadNetworks', 'roadSectionCategories');
    const sidebarRoadSection = useSelector(roadNetworksSelectors.sidebarRoadSection);
    const loadingSidebarRoadSection = useSelector(roadNetworksSelectors.loadingSidebarRoadSection);
    const filtersRoadSection = useSelector(roadNetworksSelectors.filtersRoadSection);
    const activeRoadSection = useSelector(roadNetworksSelectors.activeRoadSection);
    const polygonGeoItemRoadSection = useSelector(roadNetworksSelectors.polygonGeoItemRoadSection);
    const savedRoadSection = useSelector(roadNetworksSelectors.savedRoadSection);
    const loadingRoadSectionRelation = useSelector(roadNetworksSelectors.loadingRoadSectionRelation);

    const statuses = useStoreProp(actions.loadStatusesRoadSection, 'roadNetworks', 'statusesRoadSection');
    const statusObj = useMemo(() => statuses
        .reduce((res, { id, color, name }) => ({
            ...res,
            [id]: {
                color,
                name,
            },
        }), {})
        || {}
    , [statuses]);

    const prevFilters = usePrevious(filtersRoadSection);

    const activeId = activeRoadSection?.id ?? 0;

    const fetchList = () => {
        const filter = getFilters(filtersRoadSection, getFilter);
        dispatch(actions.loadSidebarRoadSection(
            parameters.page,
            parameters.limit,
            filter,
            parameters.page === 1
        ));
    };

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = sidebarRoadSection?.meta || {};
        if (
            values.top > 0.98
            && loadingSidebarRoadSection === false
            && parameters.page < last_page
        ) {
            setParameters(old => ({
                ...old,
                page: parameters.page + 1,
            }));
        }
    };

    useEffect(() => () => {
        dispatch(actions.clearSidebarRoadSection());
    }, []);

    useEffect(() => {
        if (!isEqual(filtersRoadSection, prevFilters)) {
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        }
        fetchList();
    }, [parameters, filtersRoadSection]);

    useEffect(() => {
        if (savedRoadSection) {
            dispatch(actions.clearSidebarRoadSection());
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        }
    }, [savedRoadSection]);

    const resetParameters = () => {
        setParameters(old => ({
            ...old,
            page: 1,
        }));
    };

    const renderList = (dataList) => (
        dataList.map((item) => {
            const color = item.color
                ? colorExtend(item.color)
                : config.mapMarkersColors.default;
            // const color = statusObj[item?.status]?.color || config.mapMarkersColors.default;
            return (
                <SidebarItem
                    key={item.id}
                    active={activeId === item.id}
                    icon={getIcon(item.category, color)}
                    // icon={`<i class="far fa-road" style="color: ${color}"/>`}
                    buttons={{
                        edit: {
                            title: buttons.EDIT,
                            icon: <i className="fas fa-pen" />,
                            onClick: () => {
                                // редактирование
                                dispatch(actions.setEditFormRoadSection(item));
                            }
                        },
                        clone: {
                            title: buttons.CLONE,
                            ...buttonsTypes.cloneIcon,
                            onClick: () => {
                                dispatch(actions.setCloneFormRoadSection(item));
                            }
                        },
                        delete: {
                            title: buttons.DELETE,
                            icon: <i className="fas fa-trash-alt" />,
                            onClick: () => {
                                // удаление
                                dispatch(actions.setDeleteFormRoadSection(item.id));
                            }
                        }
                    }}
                    onClick={() => {
                        dispatch(actions.setActiveRoadSection(item));
                        // сброс показать геобъекты
                        dispatch(actions.setRoadSectionPolygonGeoItem({}));
                    }}
                >
                    <Item
                        {...item}
                        categories={roadSectionCategories}
                        types={roadSectionTypes}
                        statuses={statusObj}
                        isShowObjects={
                            polygonGeoItemRoadSection?.id === item.id
                        }
                        setIsShowObjects={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            dispatch(actions.setRoadSectionPolygonGeoItem(
                                polygonGeoItemRoadSection?.id === item.id
                                    ? {}
                                    : item
                            ));
                        }}
                        isLoading={
                            activeId === item.id
                            && activeRoadSection?.isShowObjects
                            && loadingRoadSectionRelation
                        }
                    />
                </SidebarItem>
            );
        })
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filtersRoadSection}
                resetListPage={resetParameters}
                onSetFilter={(filter) => dispatch(actions.setFiltersRoadSection(filter))}
                onClearFilter={() => dispatch(actions.clearFiltersRoadSection())}
                content={({ data, onChange }) => <Form data={data} onChange={onChange} />}
                layer={config.slug}
            />

            <Scrollbars
                onUpdate={handleScrollUpdate}
                renderTrackHorizontal={(props) => (
                    <div {...props} className="track-horizontal" />
                )}
            >
                <div className="layers-sidebar__layer">
                    <SidebarTitle
                        title={config.name}
                        list={sidebarRoadSection}
                    />
                    {sidebarRoadSection?.data?.length === 0 && loadingSidebarRoadSection === false
                        ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                        : renderList(sidebarRoadSection?.data || [])}
                </div>
            </Scrollbars>

            {loadingSidebarRoadSection && <Loading className="absolute bottom fill" />}
        </div>
    );
};

export default SideBar;
