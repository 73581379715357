import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import UniversalSelectNew from 'components/common/UniversalSelect/index-new'; // Импортируем новый компонент
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { loadActions } from 'redux/Admin/actions';
import { useStoreProp } from 'helpers/hooks';
import type { CompanyTypes, Item, MicroserviceAction } from './types';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

const SelectActions = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = 'Возможные действия',
    error = false,
    helperText = '',
    sortedList = null,
    limitTags = 1,
    test_id_prefix = '',
    microservices = null
}: CompanyTypes<Item>) => {
    const dispatch = useDispatch();
    const actions: Record<string , string[]> = useStoreProp(loadActions, 'admin', 'actions');
    const prevData = useRef(selected);

    const actionsMicroservices = Object.entries(actions || {}).filter(
        ([key]: [string, string[]]) => microservices?.some((el: { value: string }) => key === el.value)
    );

    // Преобразование данных в нужный формат
    const transformedData = actionsMicroservices.reduce<MicroserviceAction[]>((acc, [key, value]) => {
        value.map((el: string) => ({
            id: `${key} ${el}`,
            microservice: key,
            value: el,
        })).forEach((current) => {
            const existingMicroservice = acc.find(item => item.microservice === current.microservice);
            if (existingMicroservice) {
                existingMicroservice.actions.push(current);
            } else {
                acc.push({
                    microservice: current.microservice,
                    actions: [current],
                });
            }
        });
        return acc;
    }, []);

    const [showList, setShowList] = useState(false);
    const [selectedList, setSelectedList] = useState(selected);
    const [allCount, setAllCount] = useState(0);

    useEffect(() => {
        // сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) {
            setSelectedList(selected);
            prevData.current = selected;
            setAllCount(transformedData.length);
        }
    }, [selected]);

    const handleClose = () => {
        setSelectedList(selected);
        setShowList(false);
    };

    const handleAccept = () => {
        onChange(selectedList);
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : {};
        onChange(initialState);
    };

    const renderName = (data?: string) => <Typography variant="body2">{data}</Typography>;

    const handleChange = (item: Item) => {
        if (multiple) {
            const updatedList = selectedList.some((el: { id: string }) => el.id === item.id)
                ? selectedList.filter((el: { id: string }) => el.id !== item.id)
                : [...selectedList, item];
            setSelectedList(updatedList);
        } else {
            setSelectedList(item);
        }
    };

    const handleChangeArrayInItem = (microservice: MicroserviceAction) => {
        const allSelected = microservice.actions.every(action =>
            selectedList.some((el: { id: string }) => el.id === action.id)
        );

        if (allSelected) {
            const newSelectedList = selectedList.filter((el: { microservice: string }) => el.microservice !== microservice.microservice);
            setSelectedList(newSelectedList);
        } else {
            const newSelectedList = selectedList.filter((el: { microservice: string }) => el.microservice !== microservice.microservice);
            setSelectedList([...newSelectedList, ...microservice.actions]);
        }
    };

    const onSelectAll = () => {
        const allActions = transformedData.reduce((acc: Item[], microservice) => {
            return [...acc, ...microservice.actions];
        }, []);
    
        const allSelected = allActions.every((action) =>
            selectedList.some((selectedItem: { id: string | number ; }) => selectedItem.id === action.id)
        );
    
        if (allSelected) {
            setSelectedList(multiple ? [] : {});
            setAllCount(0);
        } else {
            setSelectedList(allActions);
            setAllCount(allActions?.length);
        }
    };

    const getList = (params: { query?: string; }) => {
        const { query: action } = params;
        dispatch(loadActions(action && { action }));
    };
    
    const handleRenderProps = (el: MicroserviceAction) => (
        <div key={el.microservice}>
            <div>{renderName(el.microservice)}</div>
            {el.actions.map((item) => (
                <ul style={{ paddingInlineStart: 20, listStyle: 'none' }} key={item.id}>
                    <FormControlLabel
                        sx={{ marginTop: '1rem' }}
                        label={renderName(item.value)}
                        control={
                            <Checkbox
                                checked={selectedList.some((selectedItem: Item) => selectedItem.id === item.id)}
                                onChange={() => handleChange(item)}
                            />
                        }
                    />
                </ul>
            ))}
        </div>
    );

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option: Item | null) => option?.value || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                test_id_prefix={test_id_prefix}
            />
            {showList && (
                <UniversalSelectNew
                    list={transformedData}
                    sortedList={sortedList}
                    selected={selectedList}
                    multiple={multiple}
                    keyProp="microservice"
                    fetchList={getList}
                    withSearch
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={'Возможные действия'}
                    arrayInItem={'actions'}
                    renderKey={(el) => el.microservice}
                    onChangeArrayInItem={handleChangeArrayInItem}
                    renderProps={handleRenderProps}
                    onSelectAll={onSelectAll}
                    selectAllChecked={selectedList?.length === allCount && allCount > 0}
                    storeName="admin"
                    storeNameProps="actions"
                />
            )}
        </>
    );
};

export default SelectActions;