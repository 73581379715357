import React from 'react';

import HeaderPanel from 'components/common/Showcase/HeaderPanel';
import { HeaderPageVisible } from 'components/common/Showcase/HeaderPage';
import BoxContainer from 'components/common/Showcase/BoxContainer';

import Page from './Page';

// страница
const Board = () => (
    <BoxContainer>

        {/* шапка */}
        <HeaderPageVisible/>

        <HeaderPanel title="ДИТ и ЗПИ"/>

        {/* содержимое страницы */}
        <Page/>

    </BoxContainer>
);

export default Board;