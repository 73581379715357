import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import ConfirmModal from 'components/common/ConfirmModal';
import { RenderPassportTabs } from 'modules/React/Dictionaries/Incidents/RenderPassportTabs';
import { loadIncidentById } from 'modules/React/redux/actions';
import { setNgptSaved, сongestionMarkRegular } from 'modules/HeatMapCongestions/redux/actions';

const Buttons = ({ data }) => {

    const dispatch = useDispatch();

    // модалка
    const [isOpenPassport, setIsOpenPassport] = useState(false);
    const [passportData, setPassportData] = useState({});
    const [passportLoading, setPassportLoading] = useState(false);
    // регулярное
    const [regularLoading, setRegularLoading] = useState(false);
    const [isRegularConfirm, setIsRegularConfirm] = useState(false);

    const {
        uuid = '',
        incident_id = 0,
        is_regular = false,
    } = data || {};

    const closePassport = () => {
        setIsOpenPassport(false);
        setPassportLoading(false);
        setPassportData({});
    };

    const handleShowPassport = () => {
        dispatch(loadIncidentById(
            incident_id,
            (data) => {
                setPassportData(data);
                setIsOpenPassport(true);
            },
            setPassportLoading));
    };

    const handleReload = () => {
        dispatch(setNgptSaved(true));
    };

    useEffect(() => () => {
        setIsOpenPassport(false);
        setPassportData({});
        setPassportLoading(false);
        setIsRegularConfirm(false);
        setRegularLoading(false);
    }, []);

    const regularButton = {
        ...buttonsTypes.show,
        name: 'Добавить в регулярные',
        onClick: () => setIsRegularConfirm(true),
        loading: regularLoading,
        // disabled: is_regular,
    };

    const incindentButton = {
        ...buttonsTypes.show,
        name: 'Перейти к инциденту',
        onClick: handleShowPassport,
        loading: passportLoading,
        disabled: !incident_id || is_regular,
    };

    return (
        <>
            <FormButtonsComponent
                buttons={is_regular
                    ? [incindentButton]
                    : [
                        regularButton,
                        incindentButton
                    ]
                }
            />

            {isRegularConfirm && (
                <ConfirmModal
                    open={isRegularConfirm}
                    message="Вы действительно хотите добавить в регулярные?"
                    onSuccess={() => {
                        dispatch(сongestionMarkRegular(
                            uuid,
                            () => {
                                // reload
                                handleReload();
                                setIsRegularConfirm(false);
                            },
                            setRegularLoading
                        ));
                        setIsRegularConfirm(false);
                    }}
                    onClose={() => setIsRegularConfirm(false)}
                />
            )}

            {isOpenPassport && (
                <Modal
                    isOpen={isOpenPassport}
                    onClose={closePassport}
                    noPadding={true}
                    title={passportData?.name}
                    fullWidth
                    showCloseInTitle
                    buttons={<FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: closePassport
                            },
                        ]}
                    />}
                >
                    <RenderPassportTabs
                        selectedItem={passportData}
                        passportOnly
                        reloadList={handleReload}
                    />
                </Modal>
            )}
        </>
    );
};

export default Buttons;
