import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setValidation } from 'redux/Validation/actions';
import { showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';
import messageTypes from 'redux/Message/messagetypes';
import { messageHelper } from 'helpers/messageHelper';
import messages from 'helpers/constants/messages';
import common from 'helpers/constants/messages/common';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

// Infrastructure
function* loadInfrastructureTypesSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingInfrastructureTypes(true));
    const response = yield call(api.loadInfrastructureTypes, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedInfrastructureTypes(response));
    }
    yield put(actions.loadingInfrastructureTypes(false));
}

function* createInfrastructureTypesSaga({ payload, callback }) {
    const response = yield call(api.createInfrastructureTypes, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback?.();
    }
}

function* editInfrastructureTypesSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editInfrastructureTypes, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        callback?.();
    }
}

function* deleteInfrastructureTypesSaga({ payload, callback }) {
    const response = yield call(api.deleteInfrastructureTypes, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback?.();
    }
}

function* loadInfrastructureSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingInfrastructure(true));
    const response = yield call(api.loadInfrastructure, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedInfrastructure(response));
    }
    yield put(actions.loadingInfrastructure(false));
}

function* createInfrastructureSaga({ payload, callback }) {
    const response = yield call(api.createInfrastructure, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        callback?.();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editInfrastructureSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editInfrastructure, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        callback?.();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* infrastructureSubscriptionSaga({ payload, callback }) {
    const response = yield call(api.infrastructureSubscription, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield call(showMessage, messageTypes.success, common.EDIT_SUCCESS);
        callback?.();
    }
}

function* deleteInfrastructureSaga({ payload, callback }) {
    const response = yield call(api.deleteInfrastructure, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback?.();
    }
}

// участки дорог
function* loadRoadSectionTypesSaga() {
    const response = yield call(api.getRoadSectionTypes);
    if (response?.success) {
        yield put(actions.loadedRoadSectionTypes(response?.data));
    }
}

function* loadRoadSectionCategoriesSaga() {
    const response = yield call(api.getRoadSectionCategories);
    if (response?.success) {
        yield put(actions.loadedRoadSectionCategories(response?.data));
    }
}

function* loadRoadSectionsReportSaga({ payload }) {
    yield put(actions.loadingRoadSections(true));

    const response = yield call(api.getRoadSections, payload);
    if (response?.success) {
        yield messageHelper(response);
    }
    yield put(actions.loadingRoadSections(false));
}

function* loadRoadSectionsSaga({ payload }) {
    yield put(actions.loadingRoadSections(true));

    const response = yield call(api.getRoadSections, payload);
    if (response?.success) {
        yield put(actions.loadedRoadSections(response));
    }
    yield put(actions.loadingRoadSections(false));
}

function* editRoadSectionSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editRoadSection, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        callback && callback();
    }
}

function* createRoadSectionSaga({ payload, callback }) {
    const { data } = payload;
    const response = yield call(api.createRoadSection, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        callback && callback();
    }
}

function* deleteRoadSectionSaga({ payload, callback }) {
    const { id } = payload;
    const response = yield call(api.deleteRoadSection, id);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        callback && callback();
    }
}

function* loadPolygonRoadSectionSaga({ payload }) {
    yield put(actions.loadingPolygonRoadSection(true));
    yield put(setMapLoading('rn_road_section', true));
    const response = yield call(api.loadPolygonRoadSection, payload);
    if (response) {
        yield put(actions.loadedPolygonRoadSection(response?.data ?? []));
    }
    yield put(actions.loadingPolygonRoadSection(false));
    yield put(setMapLoading('rn_road_section', false));
}

function* loadSidebarRoadSectionSaga({ payload, isReplace }) {
    yield put(actions.loadingSidebarRoadSection(true));
    const list = yield call(api.loadSideBarRoadSection, payload);
    if (list) {
        yield put(actions.loadedSidebarRoadSection(list, isReplace));
    }
    yield put(actions.loadingSidebarRoadSection(false));
}

function* loadStatusesRoadSectionSaga() {
    const request = yield call(api.loadStatusesRoadSection);
    if (request) {
        yield put(actions.loadedStatusesRoadSection(request?.data || []));
    }
}

function* loadRoadSectionRelationsTypesSaga() {
    const response = yield call(api.loadRoadSectionRelationTypes);
    if (response?.success) {
        yield put(actions.loadedRoadSectionRelationTypes(response.data));
    }
}

function* loadRoadSectionRelationsSaga({ payload, callback }) {
    yield put(actions.loadingRoadSectionRelation(true));
    const response = yield call(api.loadRoadSectionRelation, payload);
    if (response?.success) {
        yield callback?.(response.data);
    }
    yield put(actions.loadingRoadSectionRelation(false));
}

function* loadRoadSectionAllRelationsSaga({ payload, callback, savedRelations }) {
    const response = yield call(api.loadRoadSectionRelation);
    if (response?.success) {
        const res = response.data.filter(item => item.road_section_id !== payload.id);
        const relation = response.data.filter(item => item.road_section_id === payload.id);
        yield callback?.(res);
        yield savedRelations?.(relation);
    }
}

function* createRoadSectionRelationSaga({ payload, callback }) {
    const response = yield call(api.createRoadSectionRelation, payload);
    if (response?.success) {
        yield put(callback({ road_section_id_list: [payload.road_section_id] }));
    }
}

function* createMultipleRoadSectionRelationSaga({ payload, callback }) {
    const { road_section_id, relations } = payload;
    const response = yield call(api.createMultipleRoadSectionRelation, relations, road_section_id);
    if (response?.success) {
        callback();
    }
}

function* deleteRoadSectionRelationSaga({ payload, callback }) {
    const response = yield call(api.deleteRoadSectionRelation, payload.id);
    if (response?.success) {
        yield put(callback({ road_section_id_list: [ payload.road_section_id ] }));
    }
}

function* loadRoadSectionByRadiusSaga({ payload, callback }) {
    const response = yield call(api.loadRoadSectionByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedRoadSectionByRadius(response.data));
        callback(response.data);
    }
}

function* loadInfrastructureByRadiusSaga({ payload, callback }) {
    const response = yield call(api.loadInfrastructureByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedInfrastructureByRadius(response.data));
        callback(response.data);
    }
}

function* loadOverlapPolygonSaga({ payload }) {
    yield put(actions.loadingOverlapPolygon(true));
    yield put(setMapLoading('rn_overlap_polygon', true));
    const response = yield call(api.loadOverlapPolygon, payload);
    if (response?.success) {
        yield put(actions.loadedOverlapPolygon(response.data));
    }
    yield put(actions.loadingOverlapPolygon(false));
    yield put(setMapLoading('rn_overlap_polygon', false));
}

function* loadOverlapListSaga({ payload }) {
    yield put(actions.loadingOverlapList(true));
    const response = yield call(api.loadOverlapList, payload);
    if (response?.success) {
        yield put(actions.loadedOverlapList(response, payload?.page === 1));
    }
    yield put(actions.loadingOverlapList(false));
}

function* loadPolygonInfrastructureSaga({ payload }) {
    yield put(actions.loadingPolygonInfrastructure(true));
    yield put(setMapLoading('rn_polygon_infrastructure',true));
    const response = yield call(api.loadPolygonInfrastructure, payload);
    if (response?.success) {
        yield put(actions.loadedPolygonInfrastructure(response?.data || []));
    }
    yield put(actions.loadingPolygonInfrastructure(false));
    yield put(setMapLoading('rn_polygon_infrastructure',false));
}

function* loadInfrastructureSidebarSaga({ payload }) {
    yield put(actions.loadingInfrastructureSidebar(true));
    const list = yield call(api.loadInfrastructureSidebar, payload);
    if (list) {
        yield put(actions.loadedInfrastructureSidebar(list, payload.page === 1));
    }
    yield put(actions.loadingInfrastructureSidebar(false));
}

function* loadOverlapTypesSaga() {
    const response = yield call(api.loadOverlapTypes);
    if (response?.success) {
        yield put(actions.loadedOverlapTypes(response.data || {}));
    }
}

function* loadOverlapSourceSaga() {
    const response = yield call(api.loadOverlapSource);
    if (response?.success) {
        yield put(actions.loadedOverlapSource(response.data || {}));
    }
}

function* loadStreetListSaga({ payload }) {
    yield put(actions.loadingStreetList(true));
    const response = yield call(api.loadStreetList, payload);
    if (response?.success) {
        yield put(actions.loadedStreetList(response?.data));
    }
    yield put(actions.loadingStreetList(false));
}

function* loadCategoryTypesSaga() {
    const response = yield call(api.loadCategoryTypes);
    if (response?.success) {
        yield put(actions.loadedCategoryTypes(response?.data));
    }
}

//
function* loadVideoCamerasDictionaryInfrastructureTypesSaga() {
    yield put(actions.loadingVideoCamerasDictionaryInfrastructureTypes(true));
    const response = yield call(api.loadVideoCamerasDictionaryInfrastructureTypes);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasDictionaryInfrastructureTypes(response?.data));
    }
    yield put(actions.loadingVideoCamerasDictionaryInfrastructureTypes(false));
}

function* loadVideoCamerasDictionaryCommutatorSaga() {
    yield put(actions.loadingVideoCamerasDictionaryCommutator(true));
    const response = yield call(api.loadVideoCamerasDictionaryCommutator);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasDictionaryCommutator(response?.data));
    }
    yield put(actions.loadingVideoCamerasDictionaryCommutator(false));
}

function* loadVideoCamerasDictionaryConnectionLineSaga() {
    yield put(actions.loadingVideoCamerasDictionaryConnectionLine(true));
    const response = yield call(api.loadVideoCamerasDictionaryConnectionLine);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasDictionaryConnectionLine(response?.data));
    }
    yield put(actions.loadingVideoCamerasDictionaryConnectionLine(false));
}

function* loadVideoCamerasDictionaryConnectionSaga() {
    yield put(actions.loadingVideoCamerasDictionaryConnection(true));
    const response = yield call(api.loadVideoCamerasDictionaryConnection);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasDictionaryConnection(response?.data));
    }
    yield put(actions.loadingVideoCamerasDictionaryConnection(false));
}

function* loadVideoCamerasDictionaryElectricalOptionalSystemSaga() {
    yield put(actions.loadingVideoCamerasDictionaryElectricalOptionalSystem(true));
    const response = yield call(api.loadVideoCamerasDictionaryElectricalOptionalSystem);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasDictionaryElectricalOptionalSystem(response?.data));
    }
    yield put(actions.loadingVideoCamerasDictionaryElectricalOptionalSystem(false));
}

function* loadVideoCamerasDictionaryDocumentSaga() {
    yield put(actions.loadingVideoCamerasDictionaryDocument(true));
    const response = yield call(api.loadVideoCamerasDictionaryDocument);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasDictionaryDocument(response?.data));
    }
    yield put(actions.loadingVideoCamerasDictionaryDocument(false));
}

function* loadVideoCamerasDictionaryPowerSaga() {
    yield put(actions.loadingVideoCamerasDictionaryPower(true));
    const response = yield call(api.loadVideoCamerasDictionaryPower);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasDictionaryPower(response?.data));
    }
    yield put(actions.loadingVideoCamerasDictionaryPower(false));
}


function* loadVideoCamerasDictionaryRelianceSaga() {
    yield put(actions.loadingVideoCamerasDictionaryReliance(true));
    const response = yield call(api.loadVideoCamerasDictionaryReliance);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasDictionaryReliance(response?.data));
    }
    yield put(actions.loadingVideoCamerasDictionaryReliance(false));
}

function* loadCommutatorsSaga({ payload }) {
    yield put(actions.loadingCommutators(true));
    const response = yield call(api.loadCommutators, payload);
    if (response?.success) {
        yield put(actions.loadedCommutators(response));
    }
    yield put(actions.loadingCommutators(false));
}

function* createCommutatorSaga({ payload, callback }) {
    yield put(actions.loadingCommutators(true));
    const response = yield call(api.createCommutator, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        callback && callback();
    }
    yield put(actions.loadingCommutators(false));
}

function* editCommutatorSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingCommutators(true));
    const response = yield call(api.editCommutator, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
        callback && callback();
    }
    yield put(actions.loadingCommutators(false));
}

function* deleteCommutatorSaga({ payload, callback }) {
    yield put(actions.loadingCommutators(true));
    const response = yield call(api.deleteCommutator, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        callback && callback();
    }
    yield put(actions.loadingCommutators(false));
}

function* loadCommutatorTypesSaga() {
    const response = yield call(api.loadCommutatorTypes);
    if (response?.success) {
        yield put(actions.loadedCommutatorTypes(response.data));
    }
    yield put(actions.loadingCommutators(false));
}

function* loadConnectionLinesSaga({ payload }) {
    yield put(actions.loadingConnectionLines(true));
    const response = yield call(api.getConnectionLines, payload);
    if (response?.success) {
        yield put(actions.loadedConnectionLines(response));
    }
    yield put(actions.loadingConnectionLines(false));
}

function* createConnectionLineSaga({ payload, callback  }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createConnectionLine, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* editConnectionLineSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editConnectionLine, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* deleteConnectionLineSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteConnectionLine, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        yield callback?.();
    }
}

function* loadCamerasSaga({ payload }) {
    yield put(actions.loadingCameras(true));
    const response = yield call(api.loadCameras, payload);
    if (response?.success) {
        yield put(actions.loadedCameras(response));
    }
    yield put(actions.loadingCameras(false));
}

function* createCameraSaga({ payload, callback }) {
    const response = yield call(api.createCamera, payload);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editCameraSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editCamera, id, data);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteCameraSaga({ payload, callback }) {
    const response = yield call(api.deleteCamera, payload);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadCameraTypesSaga() {
    yield put(actions.loadingCameraTypes(true));
    const response = yield call(api.loadCameraTypes);
    if (response?.success) {
        yield put(actions.loadedCameraTypes(response.data));
    }
    yield put(actions.loadingCameraTypes(false));
}

// Модель оборудования Камера
function* loadCameraModelSaga({ payload, returnResponse }) {
    yield put(actions.loadingCameraModel(true));
    const response = yield call(api.loadCameraModel, payload);
    if (response?.success) {
        if (returnResponse) {
            returnResponse(response.data);
        } else {
            yield put(actions.loadedCameraModel(response));
        }
    }
    yield put(actions.loadingCameraModel(false));
}

function* createCameraModelSaga({ payload, callback }) {
    const response = yield call(api.createCameraModel, payload);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editCameraModelSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editCameraModel, id, data);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteCameraModelSaga({ payload, callback }) {
    const response = yield call(api.deleteCameraModel, payload);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

// Вендор оборудования Камера
function* loadCameraVendorSaga({ payload }) {
    yield put(actions.loadingCameraVendor(true));
    const response = yield call(api.loadCameraVendor, payload);
    if (response?.success) {
        yield put(actions.loadedCameraVendor(response));
    }
    yield put(actions.loadingCameraVendor(false));
}

function* createCameraVendorSaga({ payload, callback }) {
    const response = yield call(api.createCameraVendor, payload);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editCameraVendorSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editCameraVendor, id, data);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteCameraVendorSaga({ payload, callback }) {
    const response = yield call(api.deleteCameraVendor, payload);
    if (response?.success) {
        yield callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadConnectionPointSaga({ payload }) {
    yield put(actions.loadingConnectionPoints(true));
    const response = yield call(api.loadConnectionPoints, payload);
    if (response?.success) {
        yield put(actions.loadedConnectionPoints(response));
    }
    yield put(actions.loadingConnectionPoints(false));
}

function* createConnectionPointSaga({ payload, callback }) {
    const response = yield call(api.createConnectionPoint, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        callback && callback();
    }
}

function* editConnectionPointSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editConnectionPoint, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
        callback && callback();
    }
}

function* deleteConnectionPointSaga({ payload, callback }) {
    const response = yield call(api.deleteConnectionPoint, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        callback && callback();
    }
}

function* loadConnectionPointTypesSaga() {
    yield put(actions.loadingConnectionPoints(true));
    const response = yield call(api.loadConnectionPointTypes);
    if (response?.success) {
        yield put(actions.loadedConnectionPointTypes(response.data));
    }
    yield put(actions.loadingConnectionPoints(false));
}

function* loadDVRSaga({ payload }) {
    yield put(actions.loadingDVR(true));
    const response = yield call(api.getDVR, payload);
    if (response?.success) {
        yield put(actions.loadedDVR(response));
    }
    yield put(actions.loadingDVR(false));
}

function* createDVRSaga({ payload, callback  }) {
    yield put(actions.loadingDVR(true));
    const response = yield call(api.createDVR, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingDVR(false));
}

function* editDVRSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingDVR(true));
    const response = yield call(api.editDVR, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingDVR(false));
}

function* deleteDVRSaga({ payload, callback }) {
    yield put(actions.loadingDVR(true));
    const response = yield call(api.deleteDVR, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingDVR(false));
}

function* loadPakVendorsSaga({ payload }) {
    yield put(actions.loadingPakVendors(true));
    const response = yield call(api.getPakVendors, payload);
    if (response?.success) {
        yield put(actions.loadedPakVendors(response));
    }
    yield put(actions.loadingPakVendors(false));
}

function* createPakVendorSaga({ payload, callback  }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createPakVendor, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* loadPillarsSaga({ payload }) {
    yield put(actions.loadingPillars(true));
    const response = yield call(api.getPillars, payload);
    if (response?.success) {
        yield put(actions.loadedPillars(response));
    }
    yield put(actions.loadingPillars(false));
}

function* createPillarSaga({ payload, callback  }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createPillar, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* editPillarSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editPillar, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}
function* editPakVendorSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editPakVendor, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* deletePillarSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deletePillar, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* deletePakVendorSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deletePakVendor, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* loadPakModelsSaga({ payload }) {
    yield put(actions.loadingPakModels(true));
    const response = yield call(api.getPakModels, payload);
    if (response?.success) {
        yield put(actions.loadedPakModels(response));
    }
    yield put(actions.loadingPakModels(false));
}

function* createPakModelSaga({ payload, callback  }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createPakModel, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* editPakModelSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editPakModel, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* deletePakModelSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deletePakModel, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* loadPAKListSaga({ payload }) {
    yield put(actions.loadingPAKList(true));
    const response = yield call(api.getPAKList, payload);
    if (response?.success) {
        yield put(actions.loadedPAKList(response));
    }
    yield put(actions.loadingPAKList(false));
}

function* createPAKSaga({ payload, callback  }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createPAK, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* editPAKSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editPAK, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* deletePAKSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deletePAK, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* loadInfrastructureByPolygonSaga({ payload }) {
    yield put(actions.loadingInfrastructureByPolygon(true));
    yield put(setMapLoading('rn_infrastructure', true));
    const response = yield call(api.loadInfrastructureByPolygon, payload);
    if (response?.success) {
        yield put(actions.loadedInfrastructureByPolygon(response?.data || []));
    }
    yield put(actions.loadingInfrastructureByPolygon(false));
    yield put(setMapLoading('rn_infrastructure', false));
}

function* loadInfrastructureListSaga({ payload }) {
    yield put(actions.loadingInfrastructureList(true));
    const response = yield call(api.loadInfrastructureList, payload);
    if (response?.success) {
        yield put(actions.loadedInfrastructureList(response));
    }
    yield put(actions.loadingInfrastructureList(false));
}

function* loadElectricalOpticalSystemsSaga({ payload }) {
    yield put(actions.loadingElectricalOpticalSystems(true));
    const response = yield call(api.getElectricalOpticalSystems, payload);
    if (response?.success) {
        yield put(actions.loadedElectricalOpticalSystems(response));
    }
    yield put(actions.loadingElectricalOpticalSystems(false));
}

function* createElectricalOpticalSystemsSaga({ payload, callback  }) {
    yield put(actions.loadingElectricalOpticalSystems(true));
    const response = yield call(api.createElectricalOpticalSystems, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingElectricalOpticalSystems(false));
}

function* editElectricalOpticalSystemsSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingElectricalOpticalSystems(true));
    const response = yield call(api.editElectricalOpticalSystems, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingElectricalOpticalSystems(false));
}

function* deleteElectricalOpticalSystemsSaga({ payload, callback }) {
    yield put(actions.loadingElectricalOpticalSystems(true));
    const response = yield call(api.deleteElectricalOpticalSystems, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
        yield callback?.();
    }
    yield put(actions.loadingElectricalOpticalSystems(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_INFRASTRUCTURE_TYPES, loadInfrastructureTypesSaga),
        takeLatest(types.LOAD_INFRASTRUCTURE, loadInfrastructureSaga),
        takeLatest(types.CREATE_INFRASTRUCTURE, createInfrastructureSaga),
        takeLatest(types.EDIT_INFRASTRUCTURE, editInfrastructureSaga),
        takeLatest(types.DELETE_INFRASTRUCTURE, deleteInfrastructureSaga),
        takeLatest(types.CREATE_INFRASTRUCTURE_TYPES, createInfrastructureTypesSaga),
        takeLatest(types.EDIT_INFRASTRUCTURE_TYPES, editInfrastructureTypesSaga),
        takeLatest(types.DELETE_INFRASTRUCTURE_TYPES, deleteInfrastructureTypesSaga),
        takeLatest(types.CREATE_INFRASTRUCTURE_SUBSCRIPTION, infrastructureSubscriptionSaga),

        takeLatest(types.LOAD_ROAD_SECTION_TYPES, loadRoadSectionTypesSaga),
        takeLatest(types.LOAD_ROAD_SECTION_CATEGORIES, loadRoadSectionCategoriesSaga),
        takeLatest(types.LOAD_ROAD_SECTIONS_REPORT, loadRoadSectionsReportSaga),
        takeLatest(types.LOAD_ROAD_SECTIONS, loadRoadSectionsSaga),
        takeLatest(types.EDIT_ROAD_SECTION, editRoadSectionSaga),
        takeLatest(types.CREATE_ROAD_SECTION, createRoadSectionSaga),
        takeLatest(types.DELETE_ROAD_SECTION, deleteRoadSectionSaga),
        takeLatest(types.LOAD_POLYGON_ROAD_SECTION, loadPolygonRoadSectionSaga),
        takeLatest(types.LOAD_SIDEBAR_ROAD_SECTION, loadSidebarRoadSectionSaga),
        takeLatest(types.LOAD_STATUSES_ROAD_SECTION, loadStatusesRoadSectionSaga),

        takeLatest(types.LOAD_ROAD_SECTION_RELATION, loadRoadSectionRelationsSaga),
        takeLatest(types.LOAD_ROAD_SECTION_ALL_RELATION, loadRoadSectionAllRelationsSaga),
        takeLatest(types.LOAD_ROAD_SECTION_RELATION_TYPES, loadRoadSectionRelationsTypesSaga),
        takeLatest(types.CREATE_ROAD_SECTION_RELATION, createRoadSectionRelationSaga),
        takeLatest(types.CREATE_MULTIPLE_ROAD_SECTION_RELATION, createMultipleRoadSectionRelationSaga),
        takeLatest(types.DELETE_ROAD_SECTION_RELATION, deleteRoadSectionRelationSaga),
        takeLatest(types.LOAD_ROAD_SECTION_BY_RADIUS, loadRoadSectionByRadiusSaga),
        takeLatest(types.LOAD_INFRASTRUCTURE_BY_RADIUS, loadInfrastructureByRadiusSaga),
        takeLatest(types.LOAD_OVERLAP_POLYGON, loadOverlapPolygonSaga),
        takeLatest(types.LOAD_OVERLAP_LIST, loadOverlapListSaga),

        takeLatest(types.LOAD_POLYGON_INFRASTRUCTURE, loadPolygonInfrastructureSaga),
        takeLatest(types.LOAD_INFRASTRUCTURE_SIDEBAR, loadInfrastructureSidebarSaga),
        takeLatest(types.LOAD_OVERLAP_TYPES, loadOverlapTypesSaga),
        takeLatest(types.LOAD_OVERLAP_SOURCE, loadOverlapSourceSaga),

        takeLatest(types.LOAD_STREET_LIST, loadStreetListSaga),

        takeLatest(types.LOAD_CATEGORY_TYPES, loadCategoryTypesSaga),

        // Словари для городских видеокамер
        takeLatest(types.LOAD_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES, loadVideoCamerasDictionaryInfrastructureTypesSaga),
        takeLatest(types.LOAD_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR, loadVideoCamerasDictionaryCommutatorSaga),
        takeLatest(types.LOAD_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE, loadVideoCamerasDictionaryConnectionLineSaga),
        takeLatest(types.LOAD_VIDEO_CAMERAS_DICTIONARY_CONNECTION, loadVideoCamerasDictionaryConnectionSaga),
        takeLatest(types.LOAD_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM, loadVideoCamerasDictionaryElectricalOptionalSystemSaga),
        takeLatest(types.LOAD_VIDEO_CAMERAS_DICTIONARY_DOCUMENT, loadVideoCamerasDictionaryDocumentSaga),
        takeLatest(types.LOAD_VIDEO_CAMERAS_DICTIONARY_POWER, loadVideoCamerasDictionaryPowerSaga),
        takeLatest(types.LOAD_VIDEO_CAMERAS_DICTIONARY_RELIANCE, loadVideoCamerasDictionaryRelianceSaga),

        takeLatest(types.LOAD_COMMUTATORS, loadCommutatorsSaga),
        takeLatest(types.CREATE_COMMUTATOR, createCommutatorSaga),
        takeLatest(types.EDIT_COMMUTATOR, editCommutatorSaga),
        takeLatest(types.DELETE_COMMUTATOR, deleteCommutatorSaga),
        takeLatest(types.LOAD_COMMUTATOR_TYPES, loadCommutatorTypesSaga),

        takeLatest(types.LOAD_CONNECTION_LINES, loadConnectionLinesSaga),
        takeLatest(types.CREATE_CONNECTION_LINE, createConnectionLineSaga),
        takeLatest(types.EDIT_CONNECTION_LINE, editConnectionLineSaga),
        takeLatest(types.DELETE_CONNECTION_LINE, deleteConnectionLineSaga),

        takeLatest(types.LOAD_CAMERAS, loadCamerasSaga),
        takeLatest(types.CREATE_CAMERA, createCameraSaga),
        takeLatest(types.EDIT_CAMERA, editCameraSaga),
        takeLatest(types.DELETE_CAMERA, deleteCameraSaga),

        takeLatest(types.LOAD_CAMERA_TYPES, loadCameraTypesSaga),

        takeLatest(types.LOAD_CAMERA_MODEL, loadCameraModelSaga),
        takeLatest(types.CREATE_CAMERA_MODEL, createCameraModelSaga),
        takeLatest(types.EDIT_CAMERA_MODEL, editCameraModelSaga),
        takeLatest(types.DELETE_CAMERA_MODEL, deleteCameraModelSaga),

        takeLatest(types.LOAD_CAMERA_VENDOR, loadCameraVendorSaga),
        takeLatest(types.CREATE_CAMERA_VENDOR, createCameraVendorSaga),
        takeLatest(types.EDIT_CAMERA_VENDOR, editCameraVendorSaga),
        takeLatest(types.DELETE_CAMERA_VENDOR, deleteCameraVendorSaga),

        takeLatest(types.LOAD_CONNECTION_POINTS, loadConnectionPointSaga),
        takeLatest(types.CREATE_CONNECTION_POINTS, createConnectionPointSaga),
        takeLatest(types.EDIT_CONNECTION_POINTS, editConnectionPointSaga),
        takeLatest(types.DELETE_CONNECTION_POINTS, deleteConnectionPointSaga),
        takeLatest(types.LOAD_CONNECTION_POINT_TYPES, loadConnectionPointTypesSaga),

        takeLatest(types.LOAD_PILLARS, loadPillarsSaga),
        takeLatest(types.CREATE_PILLAR, createPillarSaga),
        takeLatest(types.EDIT_PILLAR, editPillarSaga),
        takeLatest(types.DELETE_PILLAR, deletePillarSaga),

        takeLatest(types.LOAD_ELECTRICAL_OPTICAL_SYSTEMS, loadElectricalOpticalSystemsSaga),
        takeLatest(types.CREATE_ELECTRICAL_OPTICAL_SYSTEMS, createElectricalOpticalSystemsSaga),
        takeLatest(types.EDIT_ELECTRICAL_OPTICAL_SYSTEMS, editElectricalOpticalSystemsSaga),
        takeLatest(types.DELETE_ELECTRICAL_OPTICAL_SYSTEMS, deleteElectricalOpticalSystemsSaga),

        takeLatest(types.LOAD_PAK_VENDORS, loadPakVendorsSaga),
        takeLatest(types.CREATE_PAK_VENDOR, createPakVendorSaga),
        takeLatest(types.EDIT_PAK_VENDOR, editPakVendorSaga),
        takeLatest(types.DELETE_PAK_VENDOR, deletePakVendorSaga),

        takeLatest(types.LOAD_PAK_MODELS, loadPakModelsSaga),
        takeLatest(types.CREATE_PAK_MODEL, createPakModelSaga),
        takeLatest(types.EDIT_PAK_MODEL, editPakModelSaga),
        takeLatest(types.DELETE_PAK_MODEL, deletePakModelSaga),

        takeLatest(types.LOAD_PAK_LIST, loadPAKListSaga),
        takeLatest(types.CREATE_PAK, createPAKSaga),
        takeLatest(types.EDIT_PAK, editPAKSaga),
        takeLatest(types.DELETE_PAK, deletePAKSaga),

        takeLatest(types.LOAD_DVR, loadDVRSaga),
        takeLatest(types.CREATE_DVR, createDVRSaga),
        takeLatest(types.EDIT_DVR, editDVRSaga),
        takeLatest(types.DELETE_DVR, deleteDVRSaga),

        takeLatest(types.LOAD_INFRASTRUCTURE_BY_POLYGON, loadInfrastructureByPolygonSaga),
        takeLatest(types.LOAD_INFRASTRUCTURE_LIST, loadInfrastructureListSaga),

    ]);
}
