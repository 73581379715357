const reactTitles = {
    NAME: 'Название',
    GEOZONE: 'Геозона',
    TIME_ACCORDING_REGULATIONS: 'Время по регламенту',
    ACTIONS: 'Действия',
    OPERATION: 'Операция',
    SCENARIO: 'Сценарий',
    IS_DONE: 'Отметка о выполнении',
    TYPICAL: 'Типовой',
    OBJECTS: 'Объекты',
    FACTORS: 'Факторы',
    TYPICAL_OPERATION: 'Типовая операция',
};

export default reactTitles;
