import type { OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

export const recalculateRows = (rows: OperationInScenario[]) => {
    const rowsWithPositions = rows.map((row, index) => ({
        ...row,
        position: index + 1
    }));

    const rowsWithParallels = rowsWithPositions.map((row, index, arr) => {
        if (row.is_parallel) {
            let startPosition = index;
            let endPosition = index;

            while (startPosition > 0 && (arr[startPosition - 1].is_parallel
                || arr[startPosition - 1].group?.endsWith('.1') 
                || arr[startPosition - 1].group?.endsWith('.2')
            )) {
                startPosition--;
            }

            while (endPosition < arr.length - 1 && (arr[endPosition + 1].is_parallel
                || arr[endPosition + 1].group?.endsWith('.1') 
                || arr[endPosition + 1].group?.endsWith('.2')
            )) {
                endPosition++;
            }

            const newParallel = [];
            for (let i = startPosition; i <= endPosition; i++) {
                const el = rowsWithPositions[i];
                el.is_parallel && newParallel.push(el.position);
            }

            return { ...row, parallel: newParallel };
        }

        return { ...row, parallel: [] };
    });

    return rowsWithParallels;
};
