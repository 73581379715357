import { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';

import { deleteDVR } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

import ModalForm from './ModalForm';

import type { DVRItem } from 'types/RoadNetwork';

interface DVRItemProps {
    item: DVRItem;
    reloadList: () => void;
}

const Item = ({ item, reloadList }: DVRItemProps) => {
    const dispatch = useDispatch();
    const  loadingButton  = useSelector(roadNetworksSelectors.loadingButton);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        const callback = () => {
            setIsDelete(false);
            reloadList();
        };
        dispatch(deleteDVR(item.id, callback));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.ip_route || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.address_text || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <Edit fontSize="inherit"/>,
                                name: titles.EDIT,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                icon: <Delete fontSize="inherit"/>,
                                name: titles.DELETE,
                                onClick: () => setIsDelete(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={()=> setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    loadingButton={loadingButton}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default memo(Item);
