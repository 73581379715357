import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import renderAddress from 'helpers/renderAddress';
import { metroSelectors } from 'modules/Metro';
import metroTitles from 'modules/Metro/utils/helpers/constants';
import Information from 'components/common/Information';
import { MapPopUp } from 'components/MapComponents/MapPopUp';

import icon from '../icons/icon';

import ReportsList from './ReportsList';

const PopUp = ({
    id = 0,
    isOpen,
    onClose,
}) => {
    const vestibulePolygon = useSelector(metroSelectors.vestibulePolygon);

    const data = useMemo(
        () => vestibulePolygon?.find((item) => item.id === id) || {},
        [id, vestibulePolygon]
    );

    const fields = {
        address_text: {
            title: titles.ADDRESS,
            value: ({ address, address_text }) => address_text || renderAddress(address),
        },
        branch: {
            title: metroTitles.BRANCH,
            value: ({ station }) => station?.branch?.name || null,
        },
        number_on_branch: {
            title: metroTitles.NUMBER_ON_BRANCH,
            value: ({ station }) => station?.number_on_branch || null,
        },
    };

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={<span className="inner-icon" dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}></span>}
            title={
                <>
                    <span style={{ color: data?.station?.branch?.color }}>
                        {data?.station?.name || ''}
                    </span>&nbsp;
                    {data?.name && <span>({data?.name})</span>}
                </>
            }
            titleBackgroundColor={'rgb(218, 166, 85)'}
            // titleBackgroundColor={data?.color_status || 'rgb(218, 166, 85)'}
            onClose={onClose}
        >
            <div>
                {data?.status === 2 && (
                    <div className="flex-center error">
                        <h1>Станция закрыта!</h1>
                    </div>
                )}
                <Information
                    data={data}
                    title={fields}
                    returnNull
                />

                <ReportsList id={id} />
            </div>
        </MapPopUp>
    );
};

export default PopUp;
