export const transportPassenger = (state) => state.transportPassenger;

export const loading = (state) => transportPassenger(state).loading;
export const stationsList = (state) => transportPassenger(state).stationsList;
export const stationsListData = (state) => stationsList(state).data;
export const stationsListMeta = (state) => stationsList(state).meta;

export const schedule = (state) => transportPassenger(state).schedule;
export const scheduleLoading = (state) => transportPassenger(state).scheduleLoading;

export const types = (state) => transportPassenger(state).types;
// export const statuses = (state) => transportPassenger(state).statuses;

export const brands = (state) => transportPassenger(state).brands;
export const brandsData = (state) => brands(state).data;
export const brandsMeta = (state) => brands(state).meta;
export const loadingBrands = (state) => transportPassenger(state).loadingBrands;

export const vehicleLoading = (state) => transportPassenger(state).vehicleLoading;
export const vehicles = (state) => transportPassenger(state).vehicles;
export const vehiclesData = (state) => vehicles(state).data;
export const vehiclesMeta = (state) => vehicles(state).meta;

// export const vehiclesNoWorkorder = (state) => transportPassenger(state).vehiclesNoWorkorder;
// export const vehiclesNoWorkorderData = (state) => vehiclesNoWorkorder(state).data;
// export const vehiclesNoWorkorderMeta = (state) => vehiclesNoWorkorder(state).meta;

export const models = (state) => transportPassenger(state).models;
export const modelsData = (state) => models(state).data;
export const modelsMeta = (state) => models(state).meta;

// export const companies = (state) => transportPassenger(state).companies;
// export const companiesData = (state) => companies(state).data;
// export const companiesMeta = (state) => companies(state).meta;
// export const searchCompanies = (state) => transportPassenger(state).searchCompanies;

export const vehiclesTypes = (state) => transportPassenger(state).vehiclesTypes;
export const vehicleCategories = (state) => transportPassenger(state).vehicleCategories;
// export const vehicleCategoriesLoaded = (state) => transportPassenger(state).vehicleCategoriesLoaded;

export const loadingVehicleCategoryList = (state) => transportPassenger(state).loadingVehicleCategoryList;
export const vehicleCategoryList = (state) => transportPassenger(state).vehicleCategoryList;
// export const vehicleCategoryListData = (state) => vehicleCategoryList(state).data;
// export const vehicleCategoryListMeta = (state) => vehicleCategoryList(state).meta;

export const routeNums = (state) => transportPassenger(state).routeNums;
export const routeNumsLoading = (state) => transportPassenger(state).routeNumsLoading;

// export const loadingRoutes = (state) => transportPassenger(state).loadingRoutes;
export const routes = (state) => transportPassenger(state).routes;
export const routesData = (state) => routes(state).data;
export const routesMeta = (state) => routes(state).meta;

// export const routesForTS = (state) => transportPassenger(state).routesForTS;
// export const routesForTSData = (state) => routesForTS(state).data;
// export const routesForTSMeta = (state) => routesForTS(state).meta;

// export const scheduleTypes = (state) => transportPassenger(state).scheduleTypes;

// export const routesGroup = (state) => transportPassenger(state).routesGroup;
// export const routesGroupLoading = (state) => transportPassenger(state).routesGroupLoading;

// export const transportations = (state) => transportPassenger(state).transportations;

// export const routeSchedule = (state) => transportPassenger(state).routeSchedule;
// export const loadingRouteSchedule = (state) => transportPassenger(state).loadingRouteSchedule;

export const active = (state) => transportPassenger(state).active;
export const activeOne = (state) => transportPassenger(state).activeOne;
// export const activeWsFilter = (state) => transportPassenger(state).activeWsFilter;
export const markers = (state) => transportPassenger(state).markers;

export const markersExtend = (state) => transportPassenger(state).markersExtend;

export const nextStation = (state) => transportPassenger(state).nextStation;

export const vehiclePopup = (state) => transportPassenger(state).vehiclePopup;
export const loadingVehiclePopup = (state) => transportPassenger(state).loadingVehiclePopup;

export const vehicleHistory = (state) => transportPassenger(state).vehicleHistory;
export const loadingVehicleHistory = (state) => transportPassenger(state).loadingVehicleHistory;

export const vehicleFlightHistory = (state) => transportPassenger(state).vehicleFlightHistory;
export const loadingVehicleFlightHistory = (state) => transportPassenger(state).loadingVehicleFlightHistory;

export const filters = (state) => transportPassenger(state).filters;

// export const filter = (state) => transportPassenger(state).filter;
// export const filterData = (state) => filter(state).data;
// export const filterMeta = (state) => filter(state).meta;

export const currentVehiclesListLoading = (state) => transportPassenger(state).currentVehiclesListLoading;
export const currentVehiclesList = (state) => transportPassenger(state).currentVehiclesList;

// export const currentVehicleTypeLoading = (state) => transportPassenger(state).currentVehicleTypeLoading;
// export const currentVehicleType = (state) => transportPassenger(state).currentVehicleType;
// export const currentVehicleTypeData = (state) => currentVehicleType(state).data;
// export const currentVehicleTypeMeta = (state) => currentVehicleType(state).meta;

// export const routes_ = (state) => transportPassenger(state).routes_;
// export const routes_Data = (state) => routes_(state).data;
// export const routes_Meta = (state) => routes_(state).meta;

// export const positions = (state) => transportPassenger(state).positions;
// export const positionsData = (state) => positions(state).data;
// export const positionsMeta = (state) => positions(state).meta;
// export const positionsLoading = (state) => transportPassenger(state).positionsLoading;

// export const position = (state) => transportPassenger(state).position;
// export const positionLoading = (state) => transportPassenger(state).positionLoading;

export const scheduleEventsLoading = (state) => transportPassenger(state).scheduleEventsLoading;
export const scheduleEvents = (state) => transportPassenger(state).scheduleEvents;
export const scheduleEventsData = (state) => scheduleEvents(state).data;
export const scheduleEventsMeta = (state) => scheduleEvents(state).meta;

// export const employees = (state) => transportPassenger(state).employees;
// export const employeesData = (state) => employees(state).data;
// export const employeesMeta = (state) => employees(state).meta;
// export const employeesLoading = (state) => transportPassenger(state).employeesLoading;

// export const organizations = (state) => transportPassenger(state).organizations;
// export const organizationsLoading = (state) => transportPassenger(state).organizationsLoading;
export const loadingEquipments = (state) => transportPassenger(state).loadingEquipments;
export const equipments = (state) => transportPassenger(state).equipments;

// export const brigades = (state) => transportPassenger(state).brigades;
// export const brigadesData = (state) => brigades(state).data;
// export const brigadesMeta = (state) => brigades(state).meta;
// export const brigadesLoading = (state) => transportPassenger(state).brigadesLoading;

// export const brigadesNoWorkorder = (state) => transportPassenger(state).brigadesNoWorkorder;
// export const brigadesNoWorkorderData = (state) => brigadesNoWorkorder(state).data;
// export const brigadesNoWorkorderMeta = (state) => brigadesNoWorkorder(state).meta;

// export const employee = (state) => transportPassenger(state).employee;
// export const employeeLoading = (state) => transportPassenger(state).employeeLoading;

export const loadingParks = (state) => transportPassenger(state).loadingParks;
export const parks = (state) => transportPassenger(state).parks;

export const stationTypes = (state) => transportPassenger(state).stationTypes;
// export const routeStation = (state) => transportPassenger(state).routeStation;
// export const loadingRouteStation = (state) => transportPassenger(state).loadingRouteStation;

export const loadingScheduleTemplate = (state) => transportPassenger(state).loadingScheduleTemplate;
export const scheduleTemplate = (state) => transportPassenger(state).scheduleTemplate;
export const scheduleTemplateData = (state) => scheduleTemplate(state).data;
export const scheduleTemplateMeta = (state) => scheduleTemplate(state).meta;

// export const scheduleTemplateStatuses = (state) => transportPassenger(state).scheduleTemplateStatuses;
export const routesIntersection = (state) => transportPassenger(state).routesIntersection;
// export const routesIntersectionData = (state) => routesIntersection(state).data;

export const loadingWorkorders = (state) => transportPassenger(state).loadingWorkorders;
export const workorders = (state) => transportPassenger(state).workorders;
export const workordersData = (state) => workorders(state).data;
export const workordersMeta = (state) => workorders(state).meta;

export const loadingWorkordersByDate = (state) => transportPassenger(state).loadingWorkordersByDate;
export const workordersByDate = (state) => transportPassenger(state).workordersByDate;
export const workordersByDateData = (state) => workordersByDate(state).data;
export const workordersByDateMeta = (state) => workordersByDate(state).meta;

// export const workorderEventStatuses = (state) => transportPassenger(state).workorderEventStatuses;

export const currentWorkordersLoading = (state) => transportPassenger(state).currentWorkordersLoading;

export const loadingTrafficSchedule = (state) => transportPassenger(state).loadingTrafficSchedule;
export const trafficSchedule = (state) => transportPassenger(state).trafficSchedule;
export const trafficScheduleData = (state) => trafficSchedule(state).data;
export const trafficScheduleMeta = (state) => trafficSchedule(state).meta;

// export const trafficScheduleNoWorkoder = (state) => transportPassenger(state).trafficScheduleNoWorkoder;
// export const trafficScheduleNoWorkoderData = (state) => trafficScheduleNoWorkoder(state).data;
// export const trafficScheduleNoWorkoderMeta = (state) => trafficScheduleNoWorkoder(state).meta;

// export const workorderSelectedData = (state) => transportPassenger(state).workorderSelectedData;

// export const scheduleTemplateStations = (state) => transportPassenger(state).scheduleTemplateStations;
// export const loadingScheduleTemplateStations = (state) => transportPassenger(state).loadingScheduleTemplateStations;

// export const workorderStatuses = (state) => transportPassenger(state).workorderStatuses;
// export const trafficScheduleStatuses = (state) => transportPassenger(state).trafficScheduleStatuses;
// export const trafficScheduleTypes = (state) => transportPassenger(state).trafficScheduleTypes;

export const trafficScheduleEventsLoading = (state) => transportPassenger(state).trafficScheduleEventsLoading;
export const trafficScheduleEvents = (state) => transportPassenger(state).trafficScheduleEvents;

export const loadingAppeals = (state) => transportPassenger(state).loadingAppeals;
export const appeals = (state) => transportPassenger(state).appeals;
export const appealsData = (state) => appeals(state).data;
export const appealsMeta = (state) => appeals(state).meta;

// export const appealStatus = (state) => transportPassenger(state).appealStatus;
// export const appealType = (state) => transportPassenger(state).appealType;

export const routeCheckpoints = (state) => transportPassenger(state).routeCheckpoints;
export const routeCheckpointsLoading = (state) => transportPassenger(state).routeCheckpointsLoading;

// export const routeSelectList = (state) => transportPassenger(state).routeSelectList;
// export const routeSelectListData = (state) => routeSelectList(state).data;

export const nearStation = (state) => transportPassenger(state).nearStation;

export const workorderEvents = (state) => transportPassenger(state).workorderEvents;
export const loadingWorkorderEvents = (state) => transportPassenger(state).loadingWorkorderEvents;

export const loadingRouteRequests = (state) => transportPassenger(state).loadingRouteRequests;
export const routeRequests = (state) => transportPassenger(state).routeRequests;
export const routeRequestsData = (state) => routeRequests(state).data;
export const routeRequestsMeta = (state) => routeRequests(state).meta;

// export const statusesRouteRequests = (state) => transportPassenger(state).statusesRouteRequests;
// export const captcha = (state) => transportPassenger(state).captcha;

export const loadingSchedules = (state) => transportPassenger(state).loadingSchedules;
export const schedules = (state) => transportPassenger(state).schedules;
export const schedulesData = (state) => schedules(state).data;
export const schedulesMeta = (state) => schedules(state).meta;

// export const scheduleStatuses = (state) => transportPassenger(state).scheduleStatuses;

export const currentScheduleEvents = (state) => transportPassenger(state).currentScheduleEvents;
export const loadingCurrentScheduleEvents = (state) => transportPassenger(state).loadingCurrentScheduleEvents;

export const scheduleTemplateCheckpoints = (state) => transportPassenger(state).scheduleTemplateCheckpoints;
export const loadingScheduleTemplateCheckpoints = (state) => transportPassenger(state).loadingScheduleTemplateCheckpoints;

export const ticketPointsLoading = (state) => transportPassenger(state).ticketPointsLoading;
export const ticketPoints = (state) => transportPassenger(state).ticketPoints;
export const ticketPointsData = (state) => ticketPoints(state).data;
export const ticketPointsMeta = (state) => ticketPoints(state).meta;

// export const ticketPointTypes = (state) => transportPassenger(state).ticketPointTypes;

// export const currentRoute = (state) => transportPassenger(state).currentRoute;

export const loadingWaybills = (state) => transportPassenger(state).loadingWaybills;
export const waybills = (state) => transportPassenger(state).waybills;
export const waybillsData = (state) => waybills(state).data;
export const waybillsMeta = (state) => waybills(state).meta;

// export const stationAttributes = (state) => transportPassenger(state).stationAttributes;
// export const waybills_licence = (state) => transportPassenger(state).waybills_licence;
export const changeRouteStatus = (state) => transportPassenger(state).changeRouteStatus;

export const tariffs = (state) => transportPassenger(state).tariffs;
export const loadingTariffs = (state) => transportPassenger(state).loadingTariffs;

export const vehicleCard = (state) => transportPassenger(state).vehicleCard;
export const loadingVehicleCard = (state) => transportPassenger(state).loadingVehicleCard;
// export const trafficScheduleListOfEvents = (state) => transportPassenger(state).trafficScheduleListOfEvents;

// export const coords = (state) => transportPassenger(state).coords;
// export const coordsLoading = (state) => transportPassenger(state).coordsLoading;

export const currentVehicle = (state) => transportPassenger(state).currentVehicle;
export const currentVehicleLoading = (state) => transportPassenger(state).currentVehicleLoading;

export const loadingSummarySchedule = (state) => transportPassenger(state).loadingSummarySchedule;
export const summarySchedule = (state) => transportPassenger(state).summarySchedule;
export const summaryScheduleData = (state) => summarySchedule(state).data;
export const summaryScheduleMeta = (state) => summarySchedule(state).meta;


export const summaryScheduleFilter = (state) => transportPassenger(state).summaryScheduleFilter;

export const organizationInformation = (state) => transportPassenger(state).organizationInformation;
export const loadingOrganizationInformation = (state) => transportPassenger(state).loadingOrganizationInformation;

// export const telemetryStatistic = (state) => transportPassenger(state).telemetryStatistic;
// export const telemetryVehicleStatistic = (state) => transportPassenger(state).telemetryVehicleStatistic;

export const loadingWorkordersByReport = (state) => transportPassenger(state).loadingWorkordersByReport;
export const workordersByReport = (state) => transportPassenger(state).workordersByReport;
export const workordersByReportData = (state) => workordersByReport(state).data;
export const workordersByReportMeta = (state) => workordersByReport(state).meta;

export const workordersReportFilter = (state) => transportPassenger(state).workordersReportFilter;
// export const requisiteChartererTypes = (state) => transportPassenger(state).requisiteChartererTypes;

export const loadingRequisite = (state) => transportPassenger(state).loadingRequisite;
export const requisiteList = (state) => transportPassenger(state).requisiteList;
export const requisiteListData = (state) => requisiteList(state).data;
export const requisiteListMeta = (state) => requisiteList(state).meta;

// export const loadingRadioType = (state) => transportPassenger(state).loadingRadioType;
// export const radioType = (state) => transportPassenger(state).radioType;
// export const radioTypeData = (state) => radioType(state).data;
// export const radioTypeMeta = (state) => radioType(state).meta;

// export const eventManualStatus = (state) => transportPassenger(state).eventManualStatus;
// export const eventManualFlightStatus = (state) => transportPassenger(state).eventManualFlightStatus;
// export const flightDefectGroup = (state) => transportPassenger(state).flightDefectGroup;
export const flightDefects = (state) => transportPassenger(state).flightDefects;

export const reportByFlightsFilter = (state) => transportPassenger(state).reportByFlightsFilter;
export const loadingReportByFlights = (state) => transportPassenger(state).loadingReportByFlights;
export const reportByFlights = (state) => transportPassenger(state).reportByFlights;
export const reportByFlightsData = (state) => reportByFlights(state).data;
export const reportByFlightsMeta = (state) => reportByFlights(state).meta;

export const historyOption = (state) => transportPassenger(state).historyOption;

export const reportByVehicles = (state) => transportPassenger(state).reportByVehicles;
export const loadingReportByVehicles = (state) => transportPassenger(state).loadingReportByVehicles;
export const reportByVehiclesFilter = (state) => transportPassenger(state).reportByVehiclesFilter;

export const employeesTransportation = (state) => transportPassenger(state).employeesTransportation;
export const employeesTransportationData = (state) => employeesTransportation(state).data;
export const loadingEmployeesTransportation = (state) => transportPassenger(state).loadingEmployeesTransportation;

export const employeesTransportationFilter = (state) => transportPassenger(state).employeesTransportationFilter;
// export const flightTypes = (state) => transportPassenger(state).flightTypes;

export const speedViolationsReport = (state) => transportPassenger(state).speedViolationsReport;
export const speedViolationsReportData = (state) => speedViolationsReport(state).data;
export const speedViolationsReportMeta = (state) => speedViolationsReport(state).meta;
export const loadingSpeedViolationsReport = (state) => transportPassenger(state).loadingSpeedViolationsReport;

export const speedViolationsReportFilter = (state) => transportPassenger(state).speedViolationsReportFilter;

export const loadingTransportSettings = (state) => transportPassenger(state).loadingTransportSettings;
// export const transportSettings = (state) => transportPassenger(state).transportSettings;
// export const transportSettingsData = (state) => transportSettings(state).data;
// export const transportSettingsMeta = (state) => transportSettings(state).meta;

export const loadingPassengersCount = (state) => transportPassenger(state).loadingPassengersCount;
export const passengersCount = (state) => transportPassenger(state).passengersCount;
export const passengersCountData = (state) => passengersCount(state).data;
export const passengersCountMeta = (state) => passengersCount(state).meta;

// export const passengersCountFilter = (state) => transportPassenger(state).passengersCountFilter;

export const vehicleFilteredListFilter = (state) => transportPassenger(state).vehicleFilteredListFilter;
export const loadingVehicleFilteredList = (state) => transportPassenger(state).loadingVehicleFilteredList;
export const vehicleFilteredList = (state) => transportPassenger(state).vehicleFilteredList;
export const vehicleFilteredListData = (state) => vehicleFilteredList(state).data;
export const vehicleFilteredListMeta = (state) => vehicleFilteredList(state).meta;


export const sidebarParams = (state) => transportPassenger(state).sidebarParams;
export const sidebarLoading = (state) => transportPassenger(state).sidebarLoading;
export const sidebar = (state) => transportPassenger(state).sidebar;
export const sidebarData = (state) => sidebar(state).data;
export const sidebarMeta = (state) => sidebar(state).meta;

export const routeWithCheckPoints = (state) => transportPassenger(state).routeWithCheckPoints;
export const loadingRouteWithCheckPoints = (state) => transportPassenger(state).loadingRouteWithCheckPoints;

export const loadingTemplate = (state) => transportPassenger(state).loadingTemplate;

export const transorganizations = (state) => transportPassenger(state).transorganizations;
export const loadingTransorganizations = (state) => transportPassenger(state).loadingTransorganizations;

// export const colorSpeed = (state) => transportPassenger(state).colorSpeed;
// export const colorSpeedList = (state) => transportPassenger(state).colorSpeedList;
// export const colorSpeedListData = (state) => colorSpeedList(state).data;

// export const colorSpeedListLoading = (state) => transportPassenger(state).colorSpeedListLoading;
// export const colorSpeedListProps = (state) => transportPassenger(state).colorSpeedListProps;

export const loadingRoutesGrouped = (state) => transportPassenger(state).loadingRoutesGrouped;
export const routesGrouped = (state) => transportPassenger(state).routesGrouped;
export const routesGroupedData = (state) => routesGrouped(state).data;
export const routesGroupedMeta = (state) => routesGrouped(state).meta;

export const milestoneEvents = (state) => transportPassenger(state).milestoneEvents;
export const loadingButton = (state) => transportPassenger(state).loadingButton;
export const stationPolygon = (state) => transportPassenger(state).stationPolygon;
export const loadingStationPolygon = (state) => transportPassenger(state).loadingStationPolygon;

export const stationByDate = (state) => transportPassenger(state).stationByDate;
export const loadingStationByDate = (state) => transportPassenger(state).loadingStationByDate;

export const telemetryReceiversLoading = (state) => transportPassenger(state).telemetryReceiversLoading;
export const telemetryReceivers = (state) => transportPassenger(state).telemetryReceivers;
export const telemetryReceiversData = (state) => telemetryReceivers(state).data;
export const telemetryReceiversMeta = (state) => telemetryReceivers(state).meta;

export const telemetryReceiverTypes = (state) => transportPassenger(state).telemetryReceiverTypes;

export const workCalendar = (state) => transportPassenger(state).workCalendar;
export const workCalendarLoading = (state) => transportPassenger(state).workCalendarLoading;
export const workCalendarDayTypes = (state) => transportPassenger(state).workCalendarDayTypes;
export const workCalendarDayTypesLoading = (state) => transportPassenger(state).workCalendarDayTypesLoading;
export const workCalendarHistory = (state) => transportPassenger(state).workCalendarHistory;
export const workCalendarHistoryLoading = (state) => transportPassenger(state).workCalendarHistoryLoading;

export const vehicleClasses = (state) => transportPassenger(state).vehicleClasses;
export const loadingVehicleClasses = (state) => transportPassenger(state).loadingVehicleClasses;
export const routeSeasonality = (state) => transportPassenger(state).routeSeasonality;
export const regularTransportationsList = (state) => transportPassenger(state).regularTransportationsList;
export const loadingRouteAdditionalParams = (state) => transportPassenger(state).loadingRouteAdditionalParams;

export const routeHistoryHuman = (state) => transportPassenger(state).routeHistoryHuman;
export const loadingRouteHistoryHuman = (state) => transportPassenger(state).loadingRouteHistoryHuman;

export const loadingGroupsList = (state) => transportPassenger(state).loadingGroupsList;
export const loadingRouteChanged = (state) => transportPassenger(state).loadingRouteChanged;

//showcase
export const showcase = (state) => transportPassenger(state).showcase;
export const loadingShowcase = (state) => transportPassenger(state).loadingShowcase;

export const showRouteByVehicle = (state) => transportPassenger(state).showRouteByVehicle;

// export const currentMarker = (state) => transportPassenger(state).currentMarker;
