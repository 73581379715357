import { moduleName } from '../module-config';

const prefix = `${moduleName}`;

export const LOAD_STATION_STATUSES = `${prefix}/LOAD_STATION_STATUSES`;
export const LOADED_STATION_STATUSES = `${prefix}/LOADED_STATION_STATUSES`;

export const LOAD_STATION_LIST = `${prefix}/LOAD_STATION_LIST`;
export const LOADED_STATION_LIST = `${prefix}/LOADED_STATION_LIST`;
export const LOADING_STATION_LIST = `${prefix}/LOADING_STATION_LIST`;

export const LOAD_STATION_SIDEBAR = `${prefix}/LOAD_STATION_SIDEBAR`;
export const LOADED_STATION_SIDEBAR = `${prefix}/LOADED_STATION_SIDEBAR`;
export const LOADING_STATION_SIDEBAR = `${prefix}/LOADING_STATION_SIDEBAR`;
export const CLEAR_STATION_SIDEBAR = `${prefix}/CLEAR_STATION_SIDEBAR`;

export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;

export const LOAD_VESTIBULE_POLYGON = `${prefix}/LOAD_VESTIBULE_POLYGON`;
export const LOADING_VESTIBULE_POLYGON = `${prefix}/LOADING_VESTIBULE_POLYGON`;
export const LOADED_VESTIBULE_POLYGON = `${prefix}/LOADED_VESTIBULE_POLYGON`;
export const CHANGE_COLOR_VESTIBULE_POLYGON = `${prefix}/CHANGE_COLOR_VESTIBULE_POLYGON`;

export const LOAD_VESTIBULE_LIST = `${prefix}/LOAD_VESTIBULE_LIST`;
export const LOADED_VESTIBULE_LIST = `${prefix}/LOADED_VESTIBULE_LIST`;
export const LOADING_VESTIBULE_LIST = `${prefix}/LOADING_VESTIBULE_LIST`;

export const LOAD_VESTIBULE_STATUSES = `${prefix}/LOAD_VESTIBULE_STATUSES`;
export const LOADED_VESTIBULE_STATUSES = `${prefix}/LOADED_VESTIBULE_STATUSES`;

export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR_SIDEBAR`;
export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;

export const SET_FILTERS_VESTIBULE = `${prefix}/SET_FILTERS_VESTIBULE`;
export const SET_ACTIVE_VESTIBULE = `${prefix}/SET_ACTIVE_VESTIBULE`;

export const LOAD_VESTIBULE_LOAD = `${prefix}/LOAD_VESTIBULE_LOAD`;
export const LOADING_VESTIBULE_LOAD = `${prefix}/LOADING_VESTIBULE_LOAD`;
export const LOADED_VESTIBULE_LOAD = `${prefix}/LOADED_VESTIBULE_LOAD`;

export const LOAD_VESTIBULE_TRAFFIC = `${prefix}/LOAD_VESTIBULE_TRAFFIC`;
export const LOADING_VESTIBULE_TRAFFIC = `${prefix}/LOADING_VESTIBULE_TRAFFIC`;
export const LOADED_VESTIBULE_TRAFFIC = `${prefix}/LOADED_VESTIBULE_TRAFFIC`;
