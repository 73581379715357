import ServiceStatus from 'components/pages/Admin/ServiceStatus';
import RoleManagement from 'components/pages/Admin/RoleManagement';
import UserManagment from 'components/pages/Admin/UserManagment';
import DtpSettings from 'components/pages/Admin/DtpSettings';
import CameraSettings from 'components/pages/Admin/CameraSettings';
import EmailSettings from 'components/pages/Admin/emailSettings';
import AdminSettings from 'components/pages/Admin/Settings';
import UserActivity from 'components/pages/Admin/UserActivity';
import MicroserviseVersions from 'components/pages/Admin/MicroserviseVersions';
import AppLogs from 'components/pages/Admin/AppLogs';
import LibraryAPI from 'components/pages/Admin/LibraryAPI';

const routes = [
    {
        path: '/admin/services',
        component: ServiceStatus,
        exact: true,
    },
    {
        path: '/admin/roles',
        component: RoleManagement,
        exact: true,
    },
    {
        path: '/admin/users',
        component: UserManagment,
        exact: true,
    },
    {
        path: '/admin/dtp-settings',
        component: DtpSettings,
        exact: true,
    },
    {
        path: '/admin/camera-settings',
        component: CameraSettings,
        exact: true,
    },
    {
        path: '/admin/email-settings',
        component: EmailSettings,
        exact: true,
    },
    {
        path: '/admin/settings',
        component: AdminSettings,
        exact: true,
    },
    {
        path: '/admin/user-activity',
        component: UserActivity,
        exact: true,
    },
    {
        path: '/admin/service-versions',
        component: MicroserviseVersions,
        exact: true,
    },
    {
        path: '/admin/logger',
        component: AppLogs,
        exact: true,
    },
    {
        path: '/admin/library-api',
        component: LibraryAPI,
        exact: true,
    },
];

export default routes;
