import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import * as actions from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import { config } from 'config';
import messages from 'helpers/constants/messages/common';
import Loading from 'components/common/Loading';
import { Cluster, Map, MapControl } from 'components/MapComponents/leaflet';
import HeatLayer from 'components/MapComponents/leaflet/components/HeatLayer';
import Layer from 'components/MapComponents/Layers/TrafficFlow/layer';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';

import Filter from './Filter';

const HeatMap = () => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [showLayer, setShowLayer] = useState(false);

    const heatMap = useSelector(trafficFlowSelectors.heatMap);
    const heatMapLoading = useSelector(trafficFlowSelectors.heatMapLoading);

    useEffect(() => {
        return () => {
            dispatch(actions.clearHeatMap());
        };
    }, []);

    const CreateLayer = (props) => {
        if (showLayer) return (
            <Cluster
                {...props}
                iconCreateFunction={iconCreateFunctionSimple}
                maxClusterRadius={40}
            >
                <Layer filter={{}}/>
            </Cluster>
        );
        return null;
    };

    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
            <h1>Тепловая карта по загруженности участков дорог</h1>

            <Filter
                onFilter={(filter) => {
                    dispatch(actions.loadHeatMap(filter));
                    setLoaded(true);
                }}
                onReset={() => {
                    dispatch(actions.clearHeatMap());
                    setLoaded(false);
                }}
                component={
                    <FormControlLabel
                        control={
                            <Checkbox
                                onClick={(e) => setShowLayer(e.target.checked)}
                                checked={showLayer}
                            />
                        }
                        label="Показать ПУИД"
                    />
                }
            />

            {heatMapLoading && <Loading circular={true}/>}
            {heatMap.length > 0
                ? (
                    <div style={{ display: 'contents', minHeight: '400px' }}>
                        <Map
                            center={config.get('mapCenter')}
                        >
                            <MapControl>
                                {/* тепловая */}
                                <HeatLayer
                                    json={heatMap}
                                />

                                {/* слой */}
                                <CreateLayer/>
                            </MapControl>
                        </Map>
                    </div>
                )
                : (<>{loaded && !heatMapLoading && <div>{messages.NO_DATA_FOR_PERIOD}</div>}</>)
            }
        </div>
    );
};

export default HeatMap;
