import HeatMapCongestions from 'modules/HeatMapCongestions/Dictionaries/HeatMapCongestions';

const dictionariesRoutes = [
    {
        path: '/heat-map-congestions',
        component: HeatMapCongestions,
        exact: true,
    },
];

export default dictionariesRoutes;
