import { config } from '../../config';

const apiVideoCamerasUrl = () => config.get('urls').apiVideoCameras;

const apiUrls = {
    getVideoCamerasList: () => `${apiVideoCamerasUrl()}/videocamera`,

    postVideoCamera: () => `${apiVideoCamerasUrl()}/videocamera/`,

    putVideoCamera: (id) => `${apiVideoCamerasUrl()}/videocamera/${id}`,

    deleteVideoCamera: (id) => `${apiVideoCamerasUrl()}/videocamera/${id}`,

    getStatusesVideoCamera: () => `${apiVideoCamerasUrl()}/videocamera/statuses`,

    changeVideoCameraStatus: (id) => `${apiVideoCamerasUrl()}/videocamera/${id}/status`,

    getTypesVideoCamera: () => `${apiVideoCamerasUrl()}/videocamera/types`,

    getPolygon: () => `${apiVideoCamerasUrl()}/videocamera/polygon`,

    getSidebarList: () => `${apiVideoCamerasUrl()}/videocamera`,

    getVideoCameraFrame: (id) => `${apiVideoCamerasUrl()}/videocamera/frame/${id}`,

    getCameraGroup: () => `${apiVideoCamerasUrl()}/group`,

    postCameraGroup: () => `${apiVideoCamerasUrl()}/group/`,

    putCameraGroup: (id) => `${apiVideoCamerasUrl()}/group/${id}`,

    deleteCameraGroup: (id) => `${apiVideoCamerasUrl()}/group/${id}`,

    getCameraTemplateList: () => `${apiVideoCamerasUrl()}/template-group`,

    postCameraTemplate: () => `${apiVideoCamerasUrl()}/template-group/`,

    putCameraTemplate: (id) => `${apiVideoCamerasUrl()}/template-group/${id}`,

    deleteCameraTemplate: (id) => `${apiVideoCamerasUrl()}/template-group/${id}`,

    getVideoCamerasByRadius: () => `${apiVideoCamerasUrl()}/videocamera/radius`,

    getVideoLive: (id) => `${apiVideoCamerasUrl()}/stream-sources/${id}/live`,

    getVideoDownload: (id) => `${apiVideoCamerasUrl()}/stream-sources/${id}/download`,

    getVideoRecord: (id) => `${apiVideoCamerasUrl()}/stream-sources/${id}/record`,

    getCameraById: (id) => `${apiVideoCamerasUrl()}/videocamera/${id}`,

};

export default apiUrls;
