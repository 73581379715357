import { moduleName } from '../module-config';

const prefix = `${moduleName}`;

export const LOAD_COLLECTION_SERVICES = `${prefix}/LOAD_COLLECTION_SERVICES`;
export const LOADED_COLLECTION_SERVICES = `${prefix}/LOADED_COLLECTION_SERVICES`;
export const LOADING_COLLECTION_SERVICES = `${prefix}/LOADING_COLLECTION_SERVICES`;
export const CREATE_COLLECTION_SERVICE = `${prefix}/CREATE_COLLECTION_SERVICE`;
export const EDIT_COLLECTION_SERVICE = `${prefix}/EDIT_COLLECTION_SERVICE`;
export const DELETE_COLLECTION_SERVICE = `${prefix}/DELETE_COLLECTION_SERVICE`;

export const LOAD_COLLECTION_SERVICES_TYPES = `${prefix}/LOAD_COLLECTION_SERVICES_TYPES`;
export const LOADED_COLLECTION_SERVICES_TYPES = `${prefix}/LOADED_COLLECTION_SERVICES_TYPES`;

export const LOAD_COLLECTION_SERVICES_TEMPLATES = `${prefix}/LOAD_COLLECTION_SERVICES_TEMPLATES`;
export const LOADED_COLLECTION_SERVICES_TEMPLATES = `${prefix}/LOADED_COLLECTION_SERVICES_TEMPLATES`;

export const LOAD_COLLECTION_SERVICES_SERVICES = `${prefix}/LOAD_COLLECTION_SERVICES_SERVICES`;
export const LOADED_COLLECTION_SERVICES_SERVICES = `${prefix}/LOADED_COLLECTION_SERVICES_SERVICES`;

export const LOAD_JOBS = `${prefix}/LOAD_JOBS`;
export const LOADED_JOBS = `${prefix}/LOADED_JOBS`;
export const LOADING_JOBS = `${prefix}/LOADING_JOBS`;
export const CREATE_JOB = `${prefix}/CREATE_JOB`;
export const EDIT_JOB = `${prefix}/EDIT_JOB`;

export const LOAD_JOBS_PERIOD = `${prefix}/LOAD_JOBS_PERIOD`;
export const LOADED_JOBS_PERIOD = `${prefix}/LOADED_JOBS_PERIOD`;