import { LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';

import type { MicroserviceVersionItem } from 'types/Admin/MicroserviceVersions';

interface ItemProps {
    item: MicroserviceVersionItem
}

const Item = ({ item }: ItemProps) => {
    return (
        <LSContentItem>
            <LSContentColumn>
                {item?.microservice_text || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.tag || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.deploy_time ? fullDateTime(item?.deploy_time) : messages.NO_VALUE}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
