import { useEffect, useRef } from 'react';

import { dbCartaSvg } from '../lib/dbcartasvg.lib';
import { BFS, inarray, makecrds, makeid, makeind, maketipn } from '../lib/bfs.lib';
import { BFS_LABELS, BFS_LINES, BFS_STATIONS } from '../lib/data';
// import { BFS_LABELS, BFS_LINES, BFS_STATIONS } from '../lib/data_old';
import { closingTypes } from '../types';

import '../style.scss';

const MetroScheme = ({
    type, // тип id
    stations, // станции []
    setStations, // изменить станции []
}) => {

    // набор данных
    const shemaProps = useRef({
        /** @desc объект схемы из библы */
        schemeInstance: null,
        /** @desc Крайние станции? из библы */
        msel: {},
        /** @desc Хранилище ид:атрибуты из библы */
        mopt: {},
        /** @desc Упорядоченные станции выбранного маршрута */
        entries: [],
        /** @desc Хранилище всех нод */
        schemeMarkersRefs: {},
        handleClickStation: () => {},
        lineId: null,
    });

    /** @desc Клик по маркеру */
    const onSelectStation = (type, stations) => (event, bfs_station) => {
        if (!type) return;

        const targetStation = bfs_station[4],
            targetLineId = bfs_station[5].id;

        const isSameStations = stations?.findIndex?.(el => el.id === targetStation.id) >= 0;
        const selectedStationsIds = stations?.map?.(i => i.id) || [];

        /**
         * @desc Станция, Вход
         * Нажатие на 1 станцию делает выбранной данную станцию
         * Нажатие на 2 станции делает выбранной последнюю нажатую станцию
         * Нажатие на ранее выбранную станцию делает ее неактивной
         */
        if (type === closingTypes.station || type === closingTypes.entry) {
            if (isSameStations) {
                setStations([]);
            } else {
                setStations([
                    targetStation,
                ]);
            }
            shemaProps.current.lineId = null;
        }

        /**
         * @desc Перегон
         * Нажатие на 1 перегон фиксирует выбранным данный перегон
         * Нажатие на 2 перегона одной ветки фиксирует выбранными их и все перегоны между ними
         * Нажатие на несколько последовательных перегонов одной ветки фиксирует выбранным каждый из них
         * Нажатие на 2 перегона разных веток делает выбранным последний нажатый перегон
         * Нажатие на ранее выбранный перегон делает его неактивным
         */
        if (type === closingTypes.stage) {

            if (selectedStationsIds.length === 0) {
                // нет выбранных станций
                setStations([targetStation]);
                shemaProps.current.lineId = targetLineId;
            } else if (isSameStations) {
                // станции уже добавлены
                // Разрешить снятие только первой и последней станций
                if (
                    selectedStationsIds[0] === targetStation.id
                    || selectedStationsIds[selectedStationsIds.length - 1] === targetStation.id
                ) {
                    const newStations = stations.reduce((r, i) => {
                        return i.id === targetStation.id
                            ? r
                            : [...r, i];
                    }, []);
                    setStations(newStations);
                    shemaProps.current.lineId = targetLineId;
                }
            } else if (targetLineId !== shemaProps.current.lineId) { // } else if (targetLineId !== lineId) {
                // кликнули на станцию в другой ветке
                setStations([ targetStation ]);
                shemaProps.current.lineId = targetLineId;
            } else {
                // маршрут станций на 1 ветке
                const selected = shemaProps.current.schemeMarkersRefs[selectedStationsIds[0]].stationElem;
                const fromid = selected.getAttribute('id');
                const toid = event.target.getAttribute('id');

                const inches = makecrds(BFS_LINES.filter((a) => a[0] === 'inch'));
                const entries = BFS(BFS_STATIONS, inches, makeind(BFS_STATIONS, fromid), makeind(BFS_STATIONS, toid));

                setStations(entries.reduce((r, bfs_station) => ([
                    ...r,
                    bfs_station[4]
                ]), []));

                shemaProps.current.lineId = targetLineId;
            }
        }
        /**
         * @desc Переход
         * UPDATE: станции, не имеющие переходов, заблокированы к выбору,
         * при выборе перехода также выбирается и связанная с ним станция
         */
        if (type === closingTypes.transition) {
            const fromid = bfs_station[4].transition;
            if (fromid) {
                if (stations[fromid]){
                    return;
                } else {
                    setStations([]);
                }

                if (
                    // 1 или последняя станция
                    selectedStationsIds[0] === targetStation.id
                    || selectedStationsIds[selectedStationsIds.length - 1] === targetStation.id
                ) {
                    const newStations = stations.reduce((r, i) => {
                        return i.id === targetStation.id
                            ? r
                            : [...r, i];
                    }, []);

                    setStations(newStations);
                } else if (
                    selectedStationsIds.length === 1
                    && stations[0].transition === bfs_station[1]
                ) {
                    // кликнути по станции связанной в переходе
                    setStations([
                        ...stations,
                        bfs_station[4],
                    ]);
                } else {
                    const attached = BFS_STATIONS.find((station) => station[1] === fromid);
                    shemaProps.current.msel = [event.target, attached];
                    calcSchemeRoute(fromid, attached[4].id);
                    setStations([
                        bfs_station[4],
                        attached[4]
                    ]);
                }
            }
        }
    };

    useEffect(() => {
        shemaProps.current.handleClickStation = onSelectStation(type, stations);
    },[type, stations, shemaProps.current.lineId]);

    // инициализация карты
    const initScheme = () => {
        shemaProps.current.schemeInstance = new dbCartaSvg({
            id: 'metro-scheme',
            bg: 'white',
            sbar: false,
        });

        drawScheme();
    };

    /** @desc Поиск маршрута */
    const calcSchemeRoute = (fromid, toid) => {
        const inches = makecrds(BFS_LINES.filter((a) => a[0] === 'inch'));
        shemaProps.current.entries = BFS(BFS_STATIONS, inches, makeind(BFS_STATIONS, fromid), makeind(BFS_STATIONS, toid));
    };

    /** @desc Рисование маршрута */
    const drawSchemeRoute = () => {
        const inches = makecrds(BFS_LINES.filter((a) => a[0] === 'inch'));
        shemaProps.current.entries.forEach((station, i, stations) => {
            const stationElement = shemaProps.current.schemeInstance.root.getElementById(makeid(station));
            // Рисование путей
            if (i < stations.length - 1) {
                // TODO: refactor!
                const d = stations[i][2][0],
                    e = stations[i + 1][2][0];
                let cc = [],
                    r0,
                    r1,
                    rr;
                BFS_LINES.forEach((b) => {
                    if (maketipn(b) == maketipn(stations[i]) && maketipn(b) == maketipn(stations[i + 1])) {
                        b[2].forEach((c) => {
                            // find coords between stations
                            r0 = Math.sqrt(Math.pow(d[0] - c[0], 2) + Math.pow(d[1] - c[1], 2));
                            r1 = Math.sqrt(Math.pow(e[0] - c[0], 2) + Math.pow(e[1] - c[1], 2));
                            rr = Math.sqrt(Math.pow(d[0] - e[0], 2) + Math.pow(d[1] - e[1], 2));
                            if (r0 <= rr && r1 <= rr) {
                                if (!inarray(cc, c)) cc.push(c);
                            }
                        });
                    }
                });
                if (cc.length < 3) {
                    cc = cc.map((a) => {
                        return a.slice(0, 2);
                    }); //remove Q flag bezier without L
                }
                if (
                    cc.length
                    // rotate first with last if indirect
                    && Math.sqrt(Math.pow(d[0] - cc[0][0], 2) + Math.pow(d[1] - cc[0][1], 2))
                    >= Math.sqrt(Math.pow(d[0] - cc[cc.length - 1][0], 2) + Math.pow(d[1] - cc[cc.length - 1][1], 2))
                ) {
                    if (!inarray(cc, e)) cc.unshift(e);
                    if (!inarray(cc, d)) cc.push(d);
                } else {
                    if (!inarray(cc, d)) cc.unshift(d);
                    if (!inarray(cc, e)) cc.push(e);
                }
                const pts = shemaProps.current.schemeInstance.interpolateCoords(cc, true),
                    path = ('M ' + pts).replace(/,/g, ' ');
                const bInch = inches.filter((m) => {
                    return inarray(m, d) && inarray(m, e);
                }).length;

                shemaProps.current.schemeInstance.extend(
                    shemaProps.current.schemeInstance.append('path', {
                        class: 'selpath',
                        d: path,
                        stroke: stationElement.getAttribute('stroke'),
                        fill: 'none',
                        'stroke-linejoin': 'round',
                        'stroke-linecap': bInch ? 'butt' : 'round',
                        'stroke-dasharray': bInch ? [3, 2] : 'none',
                        'stroke-width': bInch ? 4 : 10,
                    }),
                    {
                        onclick: (event) => {
                            shemaProps.current.handleClickStation(event, station);
                        },
                    }
                );
            }

            // Рисование маркеров
            const textElement = stationElement.nextSibling;
            shemaProps.current.schemeInstance.attr(textElement, {
                class: 'route',
            });
            shemaProps.current.schemeInstance.extend(
                shemaProps.current.schemeInstance.append('circle', {
                    class: 'selpath',
                    stroke: stationElement.getAttribute('stroke'),
                    cx: stationElement.getAttribute('cx'),
                    cy: stationElement.getAttribute('cy'),
                    // Выделять начальный маркер, тк является исходной точкой маршрута
                    fill: i ? 'white' : 'black',
                    'stroke-width': 2,
                    r: 5,
                }),
                {
                    onclick: (event) => {
                        shemaProps.current.handleClickStation(event, station);
                    },
                }
            );
        });
    };

    /** @desc Очистить все маршруты */
    const clearSchemeRoute = () => {
        while ((shemaProps.current.msel = shemaProps.current.schemeInstance.root.querySelectorAll('.selpath')).length) {
            shemaProps.current.schemeInstance.vp.removeChild(shemaProps.current.msel[0]);
        }
        shemaProps.current.schemeInstance.root.querySelectorAll('.selected').forEach((o) => {
            const m = o.getAttribute('mclass');
            shemaProps.current.schemeInstance.attr(o, {
                class: shemaProps.current.mopt[m].class,
                fill: shemaProps.current.mopt[m].bg,
                r: shemaProps.current.mopt[m].size,
            });
        });
        shemaProps.current.schemeInstance.root.querySelectorAll('.route, .marker').forEach((o) => {
            shemaProps.current.schemeInstance.attr(o, {
                class: 'marker',
            });
        });
    };

    /** @desc Начальная отрисовка и привязка к ref */
    const drawScheme = () => {
        const route = (o) => {
            return shemaProps.current.schemeInstance.extend(
                { class: 'route', bg: 'none', join: 'round', width: 5, anchor: ['start', 'middle'], labelscale: 1 },
                o || {}
            );
        };
        const river = (o) => {
            return route(
                shemaProps.current.schemeInstance.extend(
                    { fg: '#daebf4', cap: 'round', labelcolor: '#5555ff', labelscale: 0 },
                    o || {}
                )
            );
        };
        const inch = (o) => {
            return route(shemaProps.current.schemeInstance.extend({ fg: '#ddd', cap: 'round' }, o || {}));
        };
        const station = (o) => {
            return shemaProps.current.schemeInstance.extend(
                { class: 'marker', bg: 'white', size: 4, width: 1, labelscale: 1 },
                o || {}
            );
        };

        const r1 = route({ fg: '#44b85c' }),
            r2 = route({ fg: '#8e479c' }),
            r3 = route({ fg: '#0078bf' }),
            r4 = route({ fg: '#ffcB31' }),
            r5 = route({ fg: '#ed1b35' });

        shemaProps.current.schemeInstance.extend(shemaProps.current.mopt, {
            // lines
            neva_river1: river({ width: 20, rotate: -43 }),
            neva_river2: river({ width: 25, rotate: 0 }),
            neva_river3: river({ width: 20, rotate: 36 }),
            r1,
            r2,
            r3,
            r4,
            r5,
            //inch
            inch: inch(),
            // stations
            s1: station({ fg: r1.fg, anchor: ['end', 'middle'] }),
            s1_1: station({ fg: r1.fg, anchor: ['start', 'top'] }),
            s1_2: station({ fg: r1.fg, anchor: ['start', 'bottom'] }),
            s1_3: station({ fg: r1.fg, anchor: ['start', 'middle'] }),
            s2: station({ fg: r2.fg, anchor: ['end', 'middle'] }),
            s2_1: station({ fg: r2.fg, anchor: ['start', 'middle'] }),
            s3: station({ fg: r3.fg, anchor: ['start', 'middle'] }),
            s3_1: station({ fg: r3.fg, anchor: ['end', 'middle'] }),
            s4: station({ fg: r4.fg, anchor: ['end', 'middle'] }),
            s4_1: station({ fg: r4.fg, anchor: ['start', 'bottom'] }),
            s4_2: station({ fg: r4.fg, anchor: ['start', 'middle'] }),
            s5: station({ fg: r5.fg, anchor: ['start', 'middle'] }),
            s5_1: station({ fg: r5.fg, anchor: ['end', 'middle'] }),
        });

        BFS_LINES.map((line) => {
            const [ftype, _, coords] = line;
            const pts = shemaProps.current.schemeInstance.interpolateCoords(coords, true),
                path = ('M ' + pts).replace(/,/g, ' ');

            shemaProps.current.schemeInstance.append('path', {
                id: makeid(line),
                d: path,
                class: shemaProps.current.mopt[ftype].class,
                mclass: ftype,
                fill: shemaProps.current.mopt[ftype].bg,
                stroke: shemaProps.current.mopt[ftype].fg,
                'stroke-dasharray': shemaProps.current.mopt[ftype].dash,
                'stroke-linejoin': shemaProps.current.mopt[ftype].join,
                'stroke-linecap': shemaProps.current.mopt[ftype].cap,
                'stroke-width': shemaProps.current.mopt[ftype].width,
            });
        });

        BFS_STATIONS.map((station) => {
            const [ftype, _, coords, label, item] = station;
            const stype = 's' + ftype.slice(1); // station type in this.mopt
            const id = makeid(station);
            const pts = shemaProps.current.schemeInstance.toPoints(coords[0], true);
            const stationElem = shemaProps.current.schemeInstance.append('circle', {
                id,
                guid: item.id,
                class: shemaProps.current.mopt[ftype].class,
                mclass: stype,
                fill: shemaProps.current.mopt[stype].bg,
                stroke: shemaProps.current.mopt[stype].fg,
                'stroke-width': shemaProps.current.mopt[stype].width,
                cx: pts[0],
                cy: pts[1],
                r: shemaProps.current.mopt[stype].size,
            });
            shemaProps.current.schemeInstance.extend(stationElem, {
                onclick: (event) => {
                    shemaProps.current.handleClickStation(event, station);
                    // onSelectStation(event, station);
                },
            });

            if (!label) return;
            // text anchor
            let a,
                dx = 10,
                dy = 0;
            if ((a = shemaProps.current.mopt[stype].anchor)) {
                if (a[0] === 'start') dx = 10;
                else if (a[0] === 'middle') dx = -5;
                else if (a[0] === 'end') dx = -10;
                if (a[1] === 'top') dy = 16;
                else if (a[1] === 'middle') dy = 4;
                else if (a[1] === 'bottom') dy = -8;
            }
            const textElem = shemaProps.current.schemeInstance.append('text', {
                id: 't' + id,
                guid: item.id,
                class: shemaProps.current.mopt[ftype].class,
                x: pts[0] + dx,
                y: pts[1] + dy,
                fill: shemaProps.current.mopt[stype].labelcolor || 'black',
                'font-family': 'sans-serif',
                'font-size': 7,
                'text-anchor': a ? a[0] : '',
            });
            textElem.appendChild(document.createTextNode(label));

            shemaProps.current.schemeMarkersRefs[item.id] = {
                label,
                stationElem,
                textElem,
            };
        });
        // Надписи
        BFS_LABELS.map((label) => {
            const [ftype, _, __, t, coords] = label;
            let a,
                dx = 10,
                dy = 0;
            const pts = shemaProps.current.schemeInstance.toPoints(coords, true);
            if ((a = shemaProps.current.mopt[ftype].anchor)) {
                if (a[0] === 'start') dx = 10;
                else if (a[0] === 'middle') dx = -5;
                else if (a[0] === 'end') dx = -10;
                if (a[1] === 'top') dy = 14;
                else if (a[1] === 'middle') dy = 4;
                else if (a[1] === 'bottom') dy = -8;
            }
            const text = shemaProps.current.schemeInstance.append('text', {
                x: pts[0] + dx,
                y: pts[1] + dy,
                class: shemaProps.current.mopt[ftype].class,
                fill: shemaProps.current.mopt[ftype].labelcolor || '',
                'font-family': 'sans-serif',
                'font-size': 10,
                'text-anchor': a ? a[0] : '',
            });
            if ('rotate' in shemaProps.current.mopt[ftype]) {
                shemaProps.current.schemeInstance.attr(text, {
                    transform: 'rotate(' + shemaProps.current.mopt[ftype].rotate + ',' + pts[0] + ',' + pts[1] + ')',
                });
            }
            text.appendChild(document.createTextNode(t));
        });
        //
        const ys = 210, // needed height of map in degrees
            mpts = shemaProps.current.schemeInstance.viewsizeOf(), // visible size of map in degrees
            scaley = (mpts[1] - mpts[3]) / ys;

        shemaProps.current.schemeInstance.scaleCarta(scaley);
    };

    /** @desc привязка к DOM для установки значения при любом изменении стора */
    const setSelectedStations = (stations = []) => {

        clearSelectedStations();
        clearSchemeRoute();
        // acceptShowPopUp();

        const selectedStations = stations;
        if (
            type
            && (type === closingTypes.stage || type === closingTypes.transition)
            && selectedStations?.length > 1
        ) {
            // !Важно сохранить порядок BFS_STATIONS.filter((station) => stations[station[4].id]);
            shemaProps.current.entries = selectedStations.map((station) => {
                return BFS_STATIONS.find((bfs_station) => bfs_station[4].id === station.id);
            });

            drawSchemeRoute();
        } else {
            selectedStations?.forEach((station) => {
                const { stationElem, textElem } = shemaProps.current.schemeMarkersRefs[station.id];
                shemaProps.current.schemeInstance.attr(stationElem, {
                    class: 'selected',
                    r: 7,
                });
                shemaProps.current.schemeInstance.attr(textElem, {
                    class: 'selected-text',
                });
            });
        }
    };

    /** @desc Очистка выбранных станций */
    const clearSelectedStations = () => {
        const selectedMarkers = shemaProps.current.schemeInstance.root.querySelectorAll('.selected');

        selectedMarkers.forEach((element) => {
            const m = element.getAttribute('mclass');
            shemaProps.current.schemeInstance.attr(element, {
                class: shemaProps.current.mopt[m].class,
                fill: shemaProps.current.mopt[m].bg,
                r: shemaProps.current.mopt[m].size,
            });
        });

        const selectedText = shemaProps.current.schemeInstance.root.querySelectorAll('.selected-text');
        selectedText.forEach((element) => {
            shemaProps.current.schemeInstance.attr(element, {
                class: 'marker',
            });
        });
    };

    // инициализация карты
    useEffect(() => {
        initScheme();
    }, []);

    // обновление станций
    useEffect(() => {
        if (shemaProps.current.schemeInstance) {
            setSelectedStations(stations);

            if (
                closingTypes.transition
                && stations?.length > 0
                && type === closingTypes.stage
            ) {
                // применить lineId
                const station = stations[stations.length - 1];
                const stationArray = BFS_STATIONS.find((i) => i[4].id === station?.id) || null;

                if (stationArray && stationArray[5]) {
                    shemaProps.current.lineId = stationArray[5]?.id || null;
                }
            }
        }
    }, [shemaProps.current.schemeInstance, stations]);

    // обновления типа
    useEffect(() => {
        if (type !== closingTypes.transition) {
            shemaProps.current.lineId = null;
        }
    }, [type]);

    return (
        <div id="metro-scheme" className="metro-transport-incidents-edit-scheme__image" />
    );
};

export default MetroScheme;
