import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { useStoreProp, useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import TextMaskIP from 'components/common/TextMaskIP';
import {
    createCommutator,
    editCommutator,
    loadCommutatorTypes,
    loadVideoCamerasDictionaryInfrastructureTypes
} from 'modules/InfrastructureObjects/redux/actions';
import { clearGeocodeAddress } from 'redux/Dadata/actions';

import type { CommutatorItem } from 'types';

interface BasicDataFormProps {
    isOpen: boolean;
    onClose: (open: boolean) => void;
    item?: CommutatorItem;
    isNew?: boolean;
    onAdded?: () => void;
    onEdited?: () => void;
}

const BasicDataForm = ({
    isOpen,
    onClose,
    item,
    isNew = true,
    onAdded = () => {},
    onEdited = () => {},
}: BasicDataFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const commutatorTypes = useStoreProp(loadCommutatorTypes, 'roadNetworks', 'commutatorTypes');
    const infrastructureTypes = useStoreProp(loadVideoCamerasDictionaryInfrastructureTypes, 'roadNetworks', 'videoCamerasDictionaryInfrastructureTypes');

    const getInitialState = useCallback((fields: CommutatorItem | undefined) => ({
        name: fields?.name || '',
        type_id: fields?.type_id || '',
        lat: fields?.lat || '',
        lon: fields?.lon || '',
        address: fields?.address || {},
        address_text: fields?.address_text || '',
        infrastructure_type_id: fields?.infrastructure_type_id || '',
        serial_number: fields?.serial_number || '',
        ip_route: fields?.ip_route || '',
        vendor_id: 1,
        vendor_model_id: 1,
    }), []);

    const [data, setData] = useState(getInitialState(item));

    const onChange = (name: string, value: string) => {
        validation.deleteKey(name);
        setData({
            ...data,
            [name]: value
        });
    };

    const closeForm = () => {
        onClose(false);
        dispatch(clearGeocodeAddress());
    };

    const onChangeCoordinates = (params: { lat: string; lon: string }) => {
        setData({
            ...data,
            ...params
        });
        validation.deleteKeys(Object.keys(params));
    };

    const onAcceptModal = () => {
        const prepareData = removeEmptyFields(data);

        isNew
            ? dispatch(createCommutator(prepareData, () => { onClose(false); onAdded(); }))
            : dispatch(editCommutator(item?.id, prepareData, () => { onClose(false); onEdited(); }));
    };

    const title = `${isNew ? titles.CREATE : titles.EDIT}: Коммутатор ${data?.name || ''}`;

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            onClose={closeForm}
            noPadding
            maxWidthProp={'md'}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: closeForm
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onAcceptModal,
                        }
                    ]}
                />
            }
        >
            <form className="modal__form">
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                        <FormInfoWrapper
                            helperText={validation.get('name')}
                            error={!data.name || validation.isKey('name')}
                        >
                            <TextField
                                label="Наименование"
                                className="block"
                                fullWidth
                                size="small"
                                value={data.name || ''}
                                variant="outlined"
                                name="name"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                error={validation.isKey('name')}
                            />
                        </FormInfoWrapper>
                        <FormInfoWrapper
                            helperText={validation.get('serial_number')}
                            error={!data.serial_number || validation.isKey('serial_number')}
                        >
                            <TextField
                                label="Серийный номер"
                                className="block"
                                fullWidth
                                size="small"
                                value={data.serial_number || ''}
                                variant="outlined"
                                name="serial_number"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                error={validation.isKey('serial_number')}
                            />
                        </FormInfoWrapper>
                        <FormInfoWrapper
                            helperText={validation.get('type_id')}
                            error={validation.isKey('type_id')}
                        >
                            <FormControl size={'small'} variant="outlined" className="block">
                                <InputLabel>Тип коммутатора</InputLabel>
                                <Select
                                    value={commutatorTypes[data?.type_id]}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    label="Тип коммутатора"
                                    name="type_id"
                                >
                                    <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                                    {Object?.keys(commutatorTypes)?.map((key) => (
                                        <MenuItem key={key} value={key}>{commutatorTypes[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>
                        <FormInfoWrapper
                            helperText={validation.get('infrastructure_type_id')}
                            error={validation.isKey('infrastructure_type_id')}
                        >
                            <FormControl size={'small'} variant="outlined">
                                <InputLabel>Тип инфраструктуры</InputLabel>
                                <Select
                                    value={infrastructureTypes[data?.infrastructure_type_id]}
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    label="Тип инфраструктуры"
                                    name="infrastructure_type_id"
                                >
                                    <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                                    {Object?.keys(infrastructureTypes)?.map((key) => (
                                        <MenuItem key={key} value={key}>{infrastructureTypes[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>
                        <TextField
                            value={data.ip_route}
                            name={'ip_route'}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            className="block"
                            label={titles.IP_ADDRESS}
                            variant={'outlined'}
                            size={'small'}
                            required={true}
                            error={validation.isKey('ip_route')}
                            helperText={validation.get('ip_route')}
                            InputProps={{
                                inputComponent: TextMaskIP as any,
                            }}
                        />
                        <FormControl className="block" variant="outlined">
                            <LatLonCoordinates
                                {...data}
                                onChange={onChangeCoordinates}
                                required
                            />
                        </FormControl>
                        <FormControl className="block" variant="outlined">
                            <LoadAddressByCoords
                                {...data}
                                onChange={onChangeCoordinates}
                                isLoadOnLatLon
                            >
                                <AddressList required returnGeometry={undefined} />
                            </LoadAddressByCoords>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className="block" variant="outlined">
                            <MapDragMarker
                                {...data}
                                onChange={onChangeCoordinates}
                                required
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};

export default BasicDataForm;
