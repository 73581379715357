export const videoAnalytics = (state) => state.videoAnalytics;

export const cameras = (state) => videoAnalytics(state).cameras;
export const camerasData = (state) => cameras(state).data;
export const camerasMeta = (state) => cameras(state).meta;
export const loading = (state) => videoAnalytics(state).loading;

export const settings = (state) => videoAnalytics(state).settings;
export const settingsLoading = (state) => videoAnalytics(state).settingsLoading;

// export const infoList = (state) => videoAnalytics(state).infoList;
export const violations = (state) => videoAnalytics(state).violations;

export const events = (state) => videoAnalytics(state).events;
// export const eventsData = (state) => events(state).data;
// export const eventsMeta = (state) => events(state).meta;
export const eventsLoading = (state) => videoAnalytics(state).eventsLoading;

// export const reportGroups = (state) => videoAnalytics(state).reportGroups;
// export const reportTypes = (state) => videoAnalytics(state).reportTypes;

export const reportData = (state) => videoAnalytics(state).reportData;
export const reportLoading = (state) => videoAnalytics(state).reportLoading;
// export const cameraReportFilter = (state) => videoAnalytics(state).cameraReportFilter;

export const active = (state) => videoAnalytics(state).active;
export const filters = (state) => videoAnalytics(state).filters;
export const polygon = (state) => videoAnalytics(state).polygon;

export const list = (state) => videoAnalytics(state).list;
// export const listData = (state) => list(state).data;
// export const listMeta = (state) => list(state).meta;
export const listLoading = (state) => videoAnalytics(state).listLoading;

export const statuses = (state) => videoAnalytics(state).statuses;

export const grzPointsGroup = (state) => videoAnalytics(state).grzPointsGroup;
export const loadingGrzPointsGroup = (state) => videoAnalytics(state).loadingGrzPointsGroup;

export const videoAnalyticsReportFilter = (state) => videoAnalytics(state).videoAnalyticsReportFilter;

// export const eventsFilters = (state) => videoAnalytics(state).eventsFilters;

export const cameraObjects = (state) => videoAnalytics(state).cameraObjects;
export const loadingCameraObjects = (state) => videoAnalytics(state).loadingCameraObjects;
export const filterCameraObjects = (state) => videoAnalytics(state).filterCameraObjects;

export const passengerTrafficList = (state) => videoAnalytics(state).passengerTrafficList;
// export const passengerTrafficTypesList = (state) => videoAnalytics(state).passengerTrafficTypesList;
export const passengerTrafficFilter = (state) => videoAnalytics(state).passengerTrafficFilter;
export const passengerTrafficLoading = (state) => videoAnalytics(state).passengerTrafficLoading;

export const inOutCamerasReport = (state) => videoAnalytics(state).inOutCamerasReport;
export const inOutCamerasReportLoading = (state) => videoAnalytics(state).inOutCamerasReportLoading;

export const showcase = (state) => videoAnalytics(state).showcase;
export const loadingShowcase = (state) => videoAnalytics(state).loadingShowcase;
export const excludedKeysForEventsLabels = (state) => videoAnalytics(state).excludedKeysForEventsLabels;
