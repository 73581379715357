import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import {
    changeRouteV2,
    loadRouteCheckpoints,
    loadRoutes,
    loadRoutesStatuses,
    saveRouteV2,
    saveTransportationTariff,
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import InfoBlock from 'components/common/InfoBlock';
import TransportTypesSelect from 'components/common/Transport/TransportTypesSelect';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import SelectCompany from 'components/common/Autocomplete/Companies';
import Loading from 'components/common/Loading';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import StationRoute from 'components/common/Transport/StationRoute';

import CalculateRoutes from './CalculateRoutes';
import MapForm from './PassportPage/BasicData/MapForm';

const AddEditCopyModal = ({
    data = {},
    isOpen,
    onClose,
    isNew,
    isCopy,
    reloadList = () => {},
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const statuses = useStoreProp(loadRoutesStatuses, 'transportPassenger', 'statuses');

    const { id, ...newData } = data;
    const changedData = isNew ? newData : data;

    const routeCheckpoints = useSelector(transportPassengerSelectors.routeCheckpoints);
    const routeCheckpointsLoading = useSelector(transportPassengerSelectors.routeCheckpointsLoading);
    const loadingRouteChanged = useSelector(transportPassengerSelectors.loadingRouteChanged);

    const [routePoints, setRouteCheckPoints] = useState([]);

    const calculateDuration = () => {
        return routePoints?.reduce((acc, item) => {
            if (typeof item?.interval === 'string') {
                const getHourMin = item?.interval?.split(':');
                const [hour, min] = getHourMin;
                if (item.interval) {
                    return (+hour * 60) + +min + +acc;
                }
                return acc;
            }
            return acc;

        }, 0);
    };

    const calculateLength = () => {
        return  routePoints?.reduce((acc, item) => {
            return Number(+acc + +(item.mileage || 0)).toFixed(2);
        }, 0);
    };

    const [route, setRoute] = useState({
        name: '',
        num: '',
        category_id: '',
        from: '',
        to: '',
        road: {},
        ...changedData,
        type_transportation: data.type_transportation || '',
        duration: data.duration || calculateDuration(),
        length: data.length || calculateLength(),
        status: isNew ? 1 : data?.status,
        enterprises: changedData?.enterprises || [],
        is_direct: isNew ? true : changedData.is_direct,
        direction: '',
        check_points: data.check_points || [],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        validation.deleteKey('id');
        const regex = /^[a-zA-Zа-яА-Я0-9].*$/;
        const saveFunc = () => setRoute({ ...route, [name]: value });
        (name === 'num') ? (regex.test(value) || value === '') && saveFunc() : saveFunc();
    };

    const handleChangeDirection = (e) => {
        const { value } = e.target;
        setRoute({
            ...route,
            is_direct: value,
            direction: value ? 'A' : 'B'
        });
    };

    const createNewRoute = () => {
        const routeObj = {
            ...route,
            check_points: routePoints
        };

        dispatch(saveRouteV2(routeObj, () => {
            reloadList();
            onClose(false);
            validation.clear();
        }));
    };

    const saveChangedRoute = () => {
        if ((route.tariff_passenger !== data.tariff_passenger)
            || (route.tariff_baggage !== data.tariff_baggage)) {
            dispatch(saveTransportationTariff(route.id, {
                tariff_passenger: route.tariff_passenger,
                tariff_baggage: route.tariff_baggage
            }));
        }
        route.duration = calculateDuration();
        route.length = calculateLength();
        dispatch(changeRouteV2(route?.id, {
            ...route,
            check_points: routePoints
        }, () => {
            reloadList();
            onClose(false);
        }));
    };

    const isDisabled = (
        !route.name
        || !route.num
        || !Object.keys(route?.road)?.length
        || !route.category_id
    );
    useEffect(() => {
        const selectedRouteCheckPoints = routeCheckpoints[id] || [];
        setRouteCheckPoints(selectedRouteCheckPoints);
    }, [routeCheckpoints, id]);

    // загружаем контрольные точки и остановки маршрута
    useEffect(()=> {
        if (id) {
            dispatch(loadRouteCheckpoints(id));
        }
    }, [id, dispatch]);

    // загружаем полный маршрут (иначе некоторые поля приходят пустыми)
    useEffect(() => {
        if (id) {
            dispatch(loadRoutes(1, 1, { id_list: [id] }, false, ({ data }) => {
                setRoute(prev => ({
                    ...prev,
                    ...data[0]
                }));
            }));
        }
    }, [dispatch, id, isNew]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose(false)}
            noPadding
            fullWidth
            title={isNew ? (isCopy ? 'Скопировать маршрут' : titles.ADD_ROUTE) : titles.EDIT_ROUTE}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: () => onClose(false)
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled || loadingRouteChanged,
                        onClick: isNew ? createNewRoute : saveChangedRoute
                    },
                ]}
            />}
        >
            {(routeCheckpointsLoading || loadingRouteChanged) && <Loading circular />}
            <form className="modal__form">

                <Grid container direction="row" spacing={2} justify="center" alignItems="flex-start">
                    <Grid item xs={12} md={6}>

                        <div className="row">
                            <FormInfoWrapper
                                error={validation.isKey('name')}
                                helperText={validation.get('name')}
                                className="row__item"
                            >
                                <TextField
                                    label={titles.NAME}
                                    required={true}
                                    size="small"
                                    variant="outlined"
                                    name="name"
                                    value={route.name}
                                    onChange={handleChange}
                                />
                            </FormInfoWrapper>
                            <FormInfoWrapper
                                error={validation.isKey('num')}
                                helperText={validation.get('num')}
                                className="row__item"
                            >
                                <TextField
                                    label={titles.ROUTE_NUM}
                                    required={true}
                                    size="small"
                                    variant="outlined"
                                    name="num"
                                    value={route.num}
                                    onChange={handleChange}
                                    className="row__item"
                                />
                            </FormInfoWrapper>
                        </div>

                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('id')}
                            helperText={validation.get('id')}
                        >
                            <FormControl  size="small" variant="outlined">
                                <InputLabel>{titles.STATUS}</InputLabel>
                                <Select
                                    value={route.status}
                                    required
                                    onChange={handleChange}
                                    label={titles.STATUS}
                                    name="status"
                                    disabled
                                    error={validation.isKey('id')}
                                >
                                    {Object.keys(statuses)?.map((key) => (
                                        <MenuItem key={key} value={key}>{statuses[key]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('enterprises')}
                            helperText={validation.get('enterprises')}
                        >
                            {/* в бэке поле не обязательно */}
                            <SelectCompany
                                selected={route.enterprises}
                                onChange={(value) => setRoute({
                                    ...route,
                                    enterprises: value
                                })}
                                multiple={true}
                                label={titles.ENTERPRISES}
                                helperText={validation.get('enterprises')}
                                error={validation.isKey('enterprises')}
                                filter={{ withDeleted: 1 }}
                            />
                        </FormInfoWrapper>

                        <div className="row">
                            <FormInfoWrapper
                                error={validation.isKey('category_id')}
                                helperText={validation.get('category_id')}
                                className="row__item"
                            >
                                <TransportTypesSelect
                                    label={titles.TYPE_OF_VEHICLE}
                                    value={route.category_id}
                                    required
                                    name="category_id"
                                    onChange={handleChange}
                                />
                            </FormInfoWrapper>
                            <FormControl
                                variant="outlined"
                                size="small"
                                className="row__item"
                            >
                                <InputLabel>{titles.ROUTE_TYPE}</InputLabel>
                                <Select
                                    label={titles.ROUTE_TYPE}
                                    value={route.is_direct}
                                    onChange={handleChangeDirection}
                                    name="is_direct"
                                >
                                    <MenuItem value={true}>
                                        Прямой
                                    </MenuItem>
                                    <MenuItem value={false}>
                                        Обратный
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {(!isNew && route?.road) && <CalculateRoutes routePoints={routePoints}/>}
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InfoBlock label={titles.LOCATION} className="block">
                            {route?.road && (
                                <MapForm
                                    currentRoute={route}
                                    checkPoints={routePoints}
                                />
                            )}

                            <StationRoute
                                road={route.road}
                                points={routePoints}
                                onChange={(road, points) => {
                                    setRoute(old => ({
                                        ...old,
                                        road,
                                    }));
                                    setRouteCheckPoints(points);
                                }}
                                validationKeys={[
                                    'road',
                                    'check_points'
                                ]}
                            />
                        </InfoBlock>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};

export default AddEditCopyModal;
