import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import prepareCoords from 'helpers/prepareCoords';
import Loading from 'components/common/Loading';
import Information from 'components/common/Information';
import { loadNodeById } from 'modules/DigitalTwin/redux/actions';
import { getTypeText } from 'modules/DigitalTwin/utils/helpers';
import { helpers, ObjectBindingList } from 'modules/DigitalTwin/utils/components/ObjectBindings';

import Edge from './Edge';

const Node = ({
    id,
    item,
    title,
    isHideEdges,
}) => {

    const dispatch = useDispatch();

    const [data, setData] = useState(item || {});

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (id && !item) {
            dispatch(loadNodeById(
                id,
                setData,
                setLoading
            ));
        }
    }, [id, item, dispatch]);

    const fields = {
        created_at: {
            title: titles.DATE_TIME_CREATED,
            value: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },
        type: {
            title: titles.TYPE,
            value: ({ type }) => getTypeText(type)
        },
        latlon: {
            title: titles.COORDINATES,
            value: ({ lat, lon }) => lat && lon ? `${prepareCoords(lat, 6)} / ${prepareCoords(lon, 6)}` : null
        },
    };

    const renderEdges = () => {
        return isHideEdges
            ? null
            : data?.edges?.map?.((edge, index) => (
                <div key={edge.id}>
                    <hr/>
                    {index === 0 && <h2 style={{ margin: '.5rem 1rem 0' }}>Список ребер</h2>}
                    <Edge item={edge} isHideNodes />
                </div>
            ));
    };

    return (
        <>
            {title && <h2 style={{ margin: '.5rem 1rem 0' }}>{title}</h2>}
            {loading && <Loading circular/>}

            <Information
                data={data}
                title={fields}
                returnNull
            />

            {/* связанные ребра */}
            {renderEdges()}

            <hr/>

            {!isHideEdges && (
                <ObjectBindingList
                    itemId={id}
                    itemType={helpers.objectTypes.node}
                    itemLatLon={data.lat && data.lon ? [data.lat, data.lon] : null}
                />
            )}

        </>
    );
};

export default Node;
