import { memo, useCallback, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import L from 'leaflet';
import { pick } from 'lodash';

import {
    GeoJsonNew
} from 'components/MapComponents/leaflet';
import { createMarker as createMarkerRN } from 'components/MapComponents/Layers/RoadNetwork/helper';
import { config } from 'components/MapComponents/Layers/RoadNetwork';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';

import {
    eputsTypes,
    typesIcons,
    typesRuOne,
} from '../objectHelper';


const useStyles = makeStyles({
    mapIcon: {
        backgroundColor: 'inherit',
    },
});

const MapComponents = ({
    data, // выбранные объекты
    type, // выбранный тип
    onClick,
    color,
    ...props
}) => {
    const styles = useStyles();

    const createHtml = useCallback((color, iconClass = 'fa fa-map-marker' ) => {
        return `
            <div style="color: ${color}">
                <i style="font-size: 30px" class="${iconClass}" aria-hidden="true"></i>
            </div>
        `;
    }, []);

    const createMarker = (color) => {
        return L.divIcon({
            className: styles.mapIcon,
            html: createHtml(color, typesIcons[type]),
        });
    };

    const renderTooltip = (item) => (
        <div>
            <div><b>{typesRuOne[type]}</b></div>
            {!!item.id && (<div>id: {item.id}</div>)}
            {!!item.uuid && (<div>id: {item.uuid}</div>)}
            {!!item.name && (<div>Название: {item.name}</div>)}
            {!!item.lat && !!item.lon && (<div>lat: {item.lat}, lon: {item.lon}</div>)}
        </div>
    );

    const geometry = useMemo(() => {
        return data.map(item => {
            const g = type === eputsTypes.roadNetwork
                ? item.data
                : (
                    type === eputsTypes.infrastructure
                        ? createPointGJ(item.lat, item.lon)
                        : item.geometry
                );

            return {
                ...g,
                properties: {
                    ...g?.properties,
                    ...pick(item, ['id', 'uuid', 'lat', 'lon', 'delId', 'name', 'category']),
                },
                style: {
                    color: color || '#8f8f8f',
                    weight: config.lineWidth[item?.line_width] || 4,
                },
                attribution: {
                    color
                }
            };

        });
    }, [data, type, color]);

    return (
        <>
            <GeoJsonNew
                {...props}
                data={geometry}
                icon={(item) => type === eputsTypes.roadNetwork
                    ? createMarkerRN({ color, category: item.category || 0 })
                    : createMarker(color)
                }
                toolTipTemplate={(item) => renderTooltip(item)}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, -10]
                }}
                onClick={(item) => onClick(item)}
            />
        </>
    );
};

export default memo(MapComponents);
