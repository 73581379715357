import { config } from 'config';

const apiDtpUrl = () => config.get('urls').apiDtpUrl;

const apiUrls = {

    getCountByMonths: () => `${apiDtpUrl()}/v2/dtp/count-by-months`,

    getCountByPeriods: () => `${apiDtpUrl()}/v2/dtp/count-by-periods`,

    loadHeatMapDistricts: () => `${apiDtpUrl()}/v2/dtp/by-periods`,

    getDtpTypesAll: () => `${apiDtpUrl()}/dtp/types`,

    getDtpTypes: () => `${apiDtpUrl()}/dtp/types`,

    editDtpType: () => `${apiDtpUrl()}/dtp/types/color`,

    getDtpList: () => `${apiDtpUrl()}/dtp`,

    createNewDtp: () => `${apiDtpUrl()}/dtp`,

    editStatuses: () => `${apiDtpUrl()}/dtp/status`,

    getDtpStreets: () => `${apiDtpUrl()}/dtp/streets`,

    editDtp: (id) => `${apiDtpUrl()}/dtp/${id}`,

    getDtpById: (id) => `${apiDtpUrl()}/dtp/${id}`,
};

export default apiUrls;
