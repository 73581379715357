import { getEndOf, getStartOf } from 'helpers/date.config';

import * as types from './types';

const initialState = {
    congestionTypes: [],

    congestionsHeatMap: [],
    loadingCongestionsHeatMap: false,

    ngptFilters: {
        // задаю дату - иначе данные не получаем
        start_date: getStartOf('day'),
        end_date: getEndOf('day'),
        with_regular: {
            id: true,
            name: 'Показать регулярные'
        }
    },
    ngptActive: {},
    congestionPolygon: [],
    ngptSaved: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_CONGESTION_TYPES: {
            return {
                ...state,
                congestionTypes: payload,
            };
        }

        case types.LOADED_CONGESTIONS_HEAT_MAP: {
            return {
                ...state,
                congestionsHeatMap: payload,
            };
        }

        case types.LOADING_CONGESTIONS_HEAT_MAP: {
            return {
                ...state,
                loadingCongestionsHeatMap: payload,
            };
        }

        case types.SET_NGPT_ACTIVE: {
            return {
                ...state,
                ngptActive: payload,
            };
        }

        case types.SET_NGPT_FILTER: {
            return {
                ...state,
                ngptFilters: Object.keys(payload || {}).length > 0
                    ? payload
                    : { ...initialState.ngptFilters },
            };
        }

        case types.LOADED_CONGESTION_POLYGON: {
            return {
                ...state,
                congestionPolygon: payload,
            };
        }

        case types.SET_NGPT_SAVED: {
            return {
                ...state,
                ngptSaved: payload,
            };
        }

        default:
            return state;
    }
}
