import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import isObjectTrue from '../functions/isObjectTrue';

// модалка с обновлением
const usePopUpLisner = ({
    activeSelector = null,
    polygonSelector = null,
    // ключ проверки элемента
    keyProp = 'id',
}) => {
    // отображаемый объект
    const [popup, setPopup] = useState(null);
    // получение пропса по ключу
    const getProp = useCallback(item => item?.[keyProp] || null, [keyProp]);

    const active = useSelector(activeSelector || {});
    const polygon = useSelector(polygonSelector || []);

    // обработка данных
    useEffect(() => {
        // попап уже открыт
        if (popup) {
            // очистили активный
            if (isObjectTrue(active) === false) {
                setPopup(null);
            } else {
                // обновление из полигона
                const item = polygon?.find(item => getProp(item) === getProp(popup)) || null;

                if (item && !isEqual(item, popup)) {
                    setPopup(item);
                }
            }
        } else if (isObjectTrue(active)) {
            // новый active
            const item = polygon?.find(item => getProp(item) === getProp(active)) || null;

            if (
                item // есть элемент в полигоне
                && (!popup || getProp(popup) !== getProp(active))
            ) {
                setPopup(item);
            } else {
                setPopup(null);
            }
        }
    }, [active, polygon, popup]);

    return {
        get popup() { return popup; },
        get isPopUp() { return !!popup; },
        setPopUp: (value = null) => setPopup(value),
        clearPopUp: () => setPopup(null),
    };
};

export default usePopUpLisner;

usePopUpLisner.propTypes = {
    activeSelector: PropTypes.func,
    polygonSelector: PropTypes.func,
    keyProp: PropTypes.string,
};

usePopUpLisner.defaultProps = {
    // title: null,
    // buttons: null,
};
