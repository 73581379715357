import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import { checkAndReturnValue } from 'helpers/checkValue';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import DataTable from 'components/common/DataTable';
import { getTypeText } from 'modules/DigitalTwin/utils/helpers';
import { loadNodes } from 'modules/DigitalTwin/redux/actions';
import { digitalTwinSelectors } from 'modules/DigitalTwin';
import Actions from 'modules/DigitalTwin/Dictionaries/Nodes/Actions';
import type { Node } from 'modules/DigitalTwin/utils/types';

import { Filters } from './Filters';
import ModalForm from './ModalForm';

import type { Meta } from 'types/pagination';

const checkValue = (value: any) => checkAndReturnValue(value, messages.NO_VALUE);

function Nodes() {
    const dispatch = useDispatch();

    const list: Node[] = useSelector(digitalTwinSelectors.nodesData);
    const meta: Meta = useSelector(digitalTwinSelectors.nodesMeta);
    const loading = useSelector(digitalTwinSelectors.loadingNodes);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [isOpenAddModal, setIsOpenAddModal] = useState(false);

    const reloadList = (isDelete?: boolean) => {
        const isNotLastPage = meta?.last_page > 1;
        const isSingleItem = list?.length === 1;

        const page = isDelete && isSingleItem && isNotLastPage ? params.page - 1 : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadNodes({ page: params.page, per_page: limit, ...params.data }));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        if (list.length === 0) return <NoData />;

        return (
            <DataTable
                data={list}
                columns={[
                    {
                        id: 'created_at',
                        accessorKey: 'created_at',
                        header: 'Дата, время создания',
                        cell: (info) => checkValue(getFullDateTimeWithTextMonth(info.getValue())),
                    },
                    {
                        id: 'type',
                        accessorKey: 'type',
                        header: 'Тип',
                        cell: (info) => checkValue(getTypeText(info.getValue() as string)),
                    },
                    {
                        id: 'coordinates',
                        header: 'Координаты',
                        accessorFn: (row) => row.lat && row.lon
                            ? `${prepareCoords(row.lat, 6)} / ${prepareCoords(row.lon, 6)}`
                            : messages.NO_VALUE,
                    },
                    {
                        id: 'actions',
                        header: titles.ACTIONS,
                        enableHiding: false,
                        cell: (row) => <Actions item={row} reloadList={reloadList} />,
                    },
                ]}
            />
        );
    };

    return (
        <>
            <PageLayout
                header="Узлы"
                filters={<Filters setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setIsOpenAddModal(true),
                                },
                            ]}
                            noPadding
                            noMarginLeft
                        />
                    ),
                    total: meta?.total,
                }}
                content={renderContent}
                loading={loading}
                customStyles={{ padding: '4px 8px', borderRadius: 4 }}
                paginationProps={{
                    loadList: (page) => setParams((prev) => ({ ...prev, page })),
                    list: meta,
                    limit,
                    setLimit,
                }}
            />

            {isOpenAddModal && (
                <ModalForm
                    isNew
                    isOpen={isOpenAddModal}
                    onClose={() => setIsOpenAddModal(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
}

export default Nodes;
