import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { createVideoCamera, editVideoCamera, loadedCameraById, loadTypesVideoCamera } from 'redux/VideoCameras/actions';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';
import removeEmptyFields from 'helpers/removeEmptyFields';
import useCompany from 'helpers/hooks/useCompany';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressOne from 'components/common/Location/AddressOne';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import FieldsModal from 'components/common/Location/FieldsModal';
import SelectCompany from 'components/common/Autocomplete/Companies';
import InfoBlock from 'components/common/InfoBlock';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import type { CityCamera, Statuses, Types } from './types';

interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    statuses: Statuses[]
    reloadList: (isDelete?: boolean) => void
    item?: CityCamera
    isNew?: boolean
}

const ModalForm = ({
    isNew,
    isOpen,
    onClose,
    item,
    statuses,
    reloadList
}: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const company = useCompany(item?.organization_id);

    const types: Types = useStoreProp(
        loadTypesVideoCamera,
        'videoCameras',
        'videoCameraTypes'
    );

    const initialState = {
        address: item?.address || {},
        address_text: item?.address_text || '',
        name: item?.name || '',
        status: item?.status || '',
        type: item?.type || '',
        stream_url: item?.stream_url || '' as CityCamera['stream_url'],
        lat: item?.lat || '',
        lon: item?.lon || '',
        external_id: item?.external_id || '',
        rtsp: item?.rtsp || '' as CityCamera['rtsp'],
        geometry: null,
        ip_route: item?.ip_route || '',
        organization_id: item?.organization_id || null,
        organization: company || null,
        link: item?.link || '',
        camera_external_id: item?.camera_external_id || null
    };

    const [formData, setFormData] = useState(initialState);
    const [dependence, setDependence] = useState(true);

    // дограузка данных
    useEffect(() => {
        if (item?.id) {
            dispatch(loadedCameraById(
                item.id,
                (data: CityCamera) => setFormData(prev => ({ ...prev, ...data }))
            ));
        }

    }, [item?.id]);

    useEffect(() => {
        if (company) {
            setFormData(prevState => ({ ...prevState, organization: company }));
        }
    }, [company]);

    const dependenceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDependence(event.target.checked);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        validation.deleteKey(name);
    };

    const onChangeLocation = (props: any) => {
        setFormData({
            ...formData,
            ...props
        });

        validation.deleteKey('address_text');
    };

    const onSave = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            organization_id: formData?.organization_id,
            organization: null
        }, false);

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createVideoCamera({ ...prepareData, stream_url: formData.stream_url }, callback))
            : dispatch(editVideoCamera(item?.id, { ...prepareData, stream_url: formData.stream_url }, callback));
    };

    const handleChange = (value: any) => {
        setFormData({
            ...formData,
            organization: value,
            organization_id: value.id
        });
    };

    const isDisabled = !formData.name
        || !formData.address_text
        // || !(formData.external_id?.length > 2)
        || !formData.lat
        || !formData.lon
        || (Number(formData.type) === 4 && !formData.rtsp)
        || (Number(formData.type) === 6 && !formData.stream_url);

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            maxWidthProp="sm"
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes[isNew ? 'create' : 'save'],
                        onClick: onSave,
                        disabled: isDisabled
                    },
                ]}
            />}
        >
            <div className="modal__form">
                {!isNew && (
                    <FormControl className="block" size="small" variant="outlined" disabled>
                        <InputLabel>{titles.STATUS}</InputLabel>
                        <Select
                            value={formData.status}
                            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                            label={titles.STATUS}
                        >
                            {statuses.map((item: Statuses) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        required
                        label={titles.NAME}
                        value={formData.name}
                        name={'name'}
                        onChange={onChange}
                        error={validation.isKey('name')}
                        disabled={!!formData.camera_external_id}
                    />
                </FormInfoWrapper>

                <FormControl className="block" size="small" variant="outlined" >
                    <InputLabel>{titles.TYPE}</InputLabel>
                    <Select
                        value={formData.type}
                        onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                        label={titles.TYPE}
                    >
                        {Object.keys(types).map((index) => (
                            <MenuItem key={index} value={index}>{types[Number(index)]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {Number(formData.type) === 4 && (
                    <FormInfoWrapper
                        className="block"
                        error={validation.isKey('rtsp')}
                        helperText={validation.get('rtsp')}
                    >
                        <TextField
                            required
                            label="Протокол rtsp"
                            value={formData.rtsp || ''}
                            name={'rtsp'}
                            onChange={onChange}
                            type="url"
                            error={validation.isKey('rtsp')}
                        />
                    </FormInfoWrapper>
                )}

                {/* <FormInfoWrapper
                    className="block"
                    helperText={validation.get('stream_url')}
                    error={validation.isKey('stream_url')}
                >
                    <TextField
                        label="Ссылка на rtsp поток"
                        value={formData.stream_url}
                        name="stream_url"
                        onChange={onChange}
                        type="url"
                        error={validation.isKey('stream_url')}
                        required={Number(formData?.type) === 6}
                    />
                </FormInfoWrapper> */}

                <InfoBlock label={titles.LOCATION} className="block">
                    <FormControlLabel
                        style={{ pointerEvents: 'none' }}
                        control={
                            <Checkbox
                                checked={dependence}
                                onChange={dependenceHandler}
                                color="primary"
                                style={{ pointerEvents: 'auto' }}
                            />
                        }
                        label="Адрес зависит от координат"
                    />

                    <FormControl className="block" variant="outlined">
                        <LatLonCoordinates
                            lat={formData.lat}
                            lon={formData.lon}
                            onChange={onChangeLocation}
                            required
                        />
                    </FormControl>

                    <FormControl className="block" variant="outlined">
                        <LoadAddressByCoords
                            {...formData}
                            type={String(formData.type)}
                            onChange={onChangeLocation}
                            isLoadOnLatLon={dependence}
                        >
                            <AddressOne
                                required
                                disabled={false}
                            />
                        </LoadAddressByCoords>
                    </FormControl>

                    <FieldsModal
                        title="Поставьте/передвиньте метку на карте"
                        buttonVariant="contained"
                        buttonText={buttons.SELECT}
                        fields={formData}
                        onChange={onChangeLocation}
                    >
                        <MapDragMarker required/>
                    </FieldsModal>
                </InfoBlock>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('external_id')}
                    helperText={validation.get('external_id')}
                >
                    <TextField
                        label={titles.EXTERNAL_ID}
                        required
                        disabled={!isNew}
                        value={formData.external_id}
                        name="external_id"
                        onChange={onChange}
                        error={validation.isKey('external_id')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('link')}
                    helperText={validation.get('link')}
                >
                    <TextField
                        value={formData.link}
                        label={titles.LINK}
                        error={validation.isKey('link')}
                        disabled
                    />
                </FormInfoWrapper>

                <div className="block">
                    <SelectCompany
                        label="Балансодержатель"
                        selected={formData.organization}
                        onChange={(value) => handleChange(value)}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ModalForm;
