import cookie from 'react-cookies';

import { config } from '../../config';
import { setAuthToken, setProject } from '../../helpers/axios.config';

import * as types from './types';

/**
 * Actions
 **/

export const loading = (bool = false) => ({
    type: types.LOADING,
    payload: bool,
});

// авторизоваться
export const userSignIn = (user) => ({
    type: types.SIGNIN_USER,
    payload: user,
});

export const saveUser = (userData) => ({
    type: types.SAVE_USER,
    payload: userData,
});

// сохранить данные авторизации
export const saveUserData = (userData) => ({
    type: types.SAVE_USER_DATA,
    payload: userData,
});

//REFRESH TOKEN
export const refreshToken = (tokens) => ({
    type: types.REFRESH_TOKEN,
    payload: tokens,
});
export const refreshTokenSuccess = (bool) => ({
    type: types.REFRESH_TOKEN_SUCCESS,
    payload: bool,
});

// RESET PASSWORD
export const resetPassword = (username) => ({
    type: types.RESET_PASSWORD,
    payload: username,
});
/*export const resetPasswordSuccess = (bool = true) => ({
    type: types.RESET_PASSWORD_SUCCESS,
    payload: bool
});*/

export const changePassword = (newPassword, callback) => ({
    type: types.CHANGE_PASSWORD,
    payload: newPassword,
    callback,
});

// получить данные авторизованного пользователя
export const getAuthUser = (access_token) => ({
    type: types.GET_AUTH_USER,
    payload: access_token,
});

export const saveAuthUserData = (user_data) => ({
    type: types.SAVE_AUTH_USER_DATA,
    payload: user_data,
});

export const putUserName = (data, id, callback) => ({
    type: types.PUT_USER_NAME,
    payload: data,
    id,
    callback,
});

export const userSignOutSuccess = () => {
    // юзер успешно разлогинен - удаляем все куки и очищаем настройки axios
    cookie.remove('authUser', config.getCookieOptions());
    cookie.remove('needRefresh', config.getCookieOptions());
    localStorage.clear();
    setAuthToken();
    setProject();
    return {
        type: types.SIGNOUT_USER,
    };
};

// изменить время жизни токена
export const changeRefreshTime = (time) => ({
    type: types.CHANGE_REFRESH_TIME,
    payload: time,
});

export const saveCurrentProject = (current) => {
    // сохраняем последний выбранный регион в axios и в конфиг
    setProject(current.code);
    config.setProject(current); // меняем конфиг проекта для выбранного региона

    return ({
        type: types.SAVE_CURRENT_PROJECT,
        payload: current,
    });
};

export const saveUserLastProject = (current) => ({
    type: types.SAVE_USER_LAST_PROJECT,
    payload: current,
});

// получаем доступные юзеру сервисы
export const getUserServices = () => ({
    type: types.GET_USER_SERVICES,
});

// сохраняем доступные юзеру сервисы
export const saveUserServices = (services) => ({
    type: types.SAVE_USER_SERVICES,
    payload: services,
});

// сохранение прав доступа
export const setPermissions = (data) => ({
    type: types.SET_PERMISSIONS,
    payload: data
});

// выход из системы
export const logout = (callback, deleteAllTokens = false) => ({
    type: types.LOGOUT,
    payload: { callback, deleteAllTokens }
});


export const loadMapLayerList = () => ({
    type: types.LOAD_MAP_LAYER_LIST,
});
export const loadedMapLayerList = (data) => ({
    type: types.LOADED_MAP_LAYER_LIST,
    payload: data
});

export const setMapLayer = (layerId) => ({
    type: types.SET_MAP_LAYER,
    payload: layerId
});
export const changedMapLayer = (layer) => ({
    type: types.CHANGED_MAP_LAYER,
    payload: layer
});
export const saveUserFilters = (data) => ({
    type: types.SAVE_USER_FILTERS,
    payload: data
});
export const deleteUserFilters = (data) => ({
    type: types.DELETE_USER_FILTERS,
    payload: data
});
export const loadUserFilters = (data) => ({
    type: types.LOAD_USER_FILTERS,
    payload: data
});
export const loadingUserFilters = (bool) => ({
    type: types.LOADING_USER_FILTERS,
    payload: bool
});
export const loadedUserFilters = (data) => ({
    type: types.LOADED_USER_FILTERS,
    payload: data
});

export const loadAccessTemplate = (uri, callback) => ({
    type: types.LOAD_ACCESS_TEMPLATE,
    payload: { uri },
    callback
});
export const loadedAccessTemplate = (data) => ({
    type: types.LOADED_ACCESS_TEMPLATE,
    payload: data
});
export const clearAccessTemplate = () => ({
    type: types.CLEAR_ACCESS_TEMPLATE,
});

export const authByUserId = (id, callback) => ({
    type: types.AUTH_BY_USER_ID,
    payload: { id },
    callback
});

export const setSuperUser = (user) => ({
    type: types.SET_SUPER_USER,
    payload: user,
});

export const logoutCurrentUser = () => ({
    type: types.LOGOUT_CURRENT_USER,
});

export const loadingButton = (bool) => ({
    type: types.LOADING_BUTTON,
    payload: bool
});
