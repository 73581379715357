export const react = (state) => state.react;

export const incidents = (state) => react(state).incidents;
export const incidentsList = (state) => incidents(state).data;
export const incidentsMeta = (state) => incidents(state).meta;

// export const incidentsFilterParams = (state) => react(state).incedentsFilterParams;

export const loadingIncidents = (state) => react(state).loadingIncidents;

export const incidentSidebar = (state) => react(state).incidentSidebar;
export const loadingIncidentSidebar = (state) => react(state).loadingIncidentSidebar;
export const needReloadIncidentSidebar = (state) => react(state).needReloadIncidentSidebar;

export const incidentPolygon = (state) => react(state).incidentPolygon;
export const loadingIncidentPolygon = (state) => react(state).loadingIncidentPolygon;

export const active = (state) => react(state).active;
export const filters = (state) => react(state).filters;
export const saved = (state) => react(state).saved;
export const editForm = (state) => react(state).editForm;
export const deleteForm = (state) => react(state).deleteForm;

export const incidentHeatMap = (state) => react(state).incidentHeatMap;
export const loadingIncidentHeatMap = (state) => react(state).loadingIncidentHeatMap;

export const operationTypes = (state) => react(state).operationTypes;

export const typicalOperations = (state) => react(state).typicalOperations;
export const typicalOperationsData = (state) => typicalOperations(state).data;
export const typicalOperationsMeta = (state) => typicalOperations(state).meta;
export const loadingTypicalOperations = (state) => react(state).loadingTypicalOperations;

export const incidentsStatuses = (state) => react(state).incidentsStatuses;

export const threatLevels = (state) => react(state).threatLevels;
export const threatLevelsData = (state) => threatLevels(state).data;
export const threatLevelsMeta = (state) => threatLevels(state).meta;
export const loadingThreatLevels = (state) => react(state).loadingThreatLevels;

export const responseScenarios = (state) => react(state).responseScenarios;
export const responseScenariosData = (state) => responseScenarios(state).data;
export const responseScenariosMeta = (state) => responseScenarios(state).meta;
export const loadingResponseScenarios = (state) => react(state).loadingResponseScenarios;

export const categories = (state) => react(state).categories;
export const categoriesData = (state) => categories(state).data;
export const categoriesMeta = (state) => categories(state).meta;
export const loadingCategories = (state) => react(state).loadingCategories;

export const events = (state) => react(state).events;
export const eventsData = (state) => events(state).data;
export const eventsMeta = (state) => events(state).meta;
export const loadingEvents = (state) => react(state).loadingEvents;

export const eventObjects = (state) => react(state).eventObjects;
export const eventObjectsData = (state) => eventObjects(state).data;
export const eventObjectsMeta = (state) => eventObjects(state).meta;
export const loadingEventObjects = (state) => react(state).loadingEventObjects;

export const incidentEvents = (state) => react(state).incidentEvents;
export const loadingIncidentEvents = (state) => react(state).loadingIncidentEvents;

export const incidentHistory = (state) => react(state).incidentHistory;
export const loadingIncidentHistory = (state) => react(state).loadingIncidentHistory;

export const incidentFactors = (state) => react(state).incidentFactors;
export const incidentFactorsData = (state) => incidentFactors(state).data;
export const incidentFactorsMeta = (state) => incidentFactors(state).meta;
export const loadingIncidentFactors = (state) => react(state).loadingIncidentFactors;

export const types = (state) => react(state).types;
export const typesList = (state) => types(state).data;
export const typesMeta = (state) => types(state).meta;
export const loadingTypes = (state) => react(state).loadingTypes;

export const phenomenons = (state) => react(state).phenomenons;
export const phenomenonsList = (state) => phenomenons(state).data;
export const phenomenonsMeta = (state) => phenomenons(state).meta;
export const loadingPhenomenons = (state) => react(state).loadingPhenomenons;

export const triggerStatuses = (state) => react(state).triggerStatuses;

export const triggers = (state) => react(state).triggers;
export const triggersData = (state) => triggers(state).data;
export const triggersMeta = (state) => triggers(state).meta;
export const loadingTriggers = (state) => react(state).loadingTriggers;

export const incidentTypes = (state) => react(state).incidentTypes;
export const incidentTypesData = (state) => incidentTypes(state).data;
export const incidentTypesMeta = (state) => incidentTypes(state).meta;
export const loadingIncidentTypes = (state) => react(state).loadingIncidentTypes;

export const keyWords = (state) => react(state).keyWords;
export const keyWordsData = (state) => keyWords(state).data;
export const keyWordsMeta = (state) => keyWords(state).meta;
export const keyWordsLoading = (state) => react(state).keyWordsLoading;

export const infopanel = (state) => react(state).infopanel;
export const loadingInfopanel = (state) => react(state).loadingInfopanel;

export const incidentCategories = (state) => react(state).incidentCategories;
// export const incidentCategoriesData = (state) => incidentCategories(state).data;
// export const incidentCategoriesMeta = (state) => incidentCategories(state).meta;
export const loadingIncidentCategories = (state) => react(state).loadingIncidentCategories;

export const objectTypes = (state) => react(state).objectTypes;
export const incidentObjects = (state) => react(state).incidentObjects;
// export const loadingIncidentObjects = (state) => react(state).loadingIncidentObjects;

export const incidentOperations = (state) => react(state).incidentOperations;
export const loadingIncidentOperations = (state) => react(state).loadingIncidentOperations;

export const stories = (state) => react(state).stories;
export const storiesData = (state) => stories(state).data;
export const storiesMeta = (state) => stories(state).meta;
export const loadingStories = (state) => react(state).loadingStories;
export const storyStatuses = (state) => react(state).storyStatuses;
export const storyConnections = (state) => react(state).storyConnections;

export const loadingButton = (state) => react(state).loadingButton;
