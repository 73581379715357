import { useState } from 'react';
import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';

import EditModal from './AddEditModal';

const Item = ({
    index,
    member,
    onChange,
    onDelete,
}) => {

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        onDelete && onDelete(index);
        setIsDelete(false);
    };

    return (
        <ListItem className="list_item">
            <ListItemText>
                <div>
                    <strong> {titles.PARTICIPANT_CATEGORY}:</strong>&nbsp;
                    {member?.category || messages.NO_DATA}
                </div>
                <div>
                    <strong> {titles.TS}:</strong>&nbsp;
                    {member?.ts || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.LEAVING_SCENE}:</strong>&nbsp;
                    {member?.leaving_scene || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.FIO}:</strong>&nbsp;
                    {member?.fio || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.BIRTH_YEAR}:</strong>&nbsp;
                    {member?.birth_year || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.GENDER}:</strong>&nbsp;
                    {member?.gender_text || messages.NO_DATA}
                </div>
                <div>
                    <strong> {titles.SEVERITY_AFTER_DTP}:</strong>&nbsp;
                    {member?.severity_text || messages.NO_DATA}
                </div>
                <div>
                    <strong> {titles.DIRECT_VIOLATIONS}:</strong>&nbsp;
                    {member?.direct_violations_text || messages.NO_DATA}
                </div>
                <div>
                    <strong> {titles.ASSOCIATED_VIOLATIONS}:</strong>&nbsp;
                    {member?.associated_violations_text || messages.NO_DATA}
                </div>
                <div>
                    <strong> {titles.FASTENED}:</strong>&nbsp;
                    {member?.fastened_text || messages.NO_DATA}
                </div>
                <div>
                    <strong> {titles.TYPE_OF_CHILD_RESTRAINT}:</strong>&nbsp;
                    {member?.type_of_child_restraint_text || messages.NO_DATA}
                </div>
                <div>
                    <strong> {titles.INTOXICATION}:</strong>&nbsp;
                    {member?.intoxication_p || messages.NO_DATA}
                </div>
                <div>
                    <strong> {titles.EXPERIENCE}:</strong>&nbsp;
                    {member?.experience || messages.NO_DATA}
                </div>
            </ListItemText>
            <Tooltip title={titles.EDIT}>
                <IconButton onClick={() => setOpenEditModal(true)} size="small">
                    <EditIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={titles.DELETE}>
                <IconButton onClick={() => setIsDelete(true)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {openEditModal &&  (
                <EditModal
                    member={member}
                    index={index}
                    isOpen={openEditModal} 
                    onClose={() => setOpenEditModal(false)}
                    onAccept={onChange}
                />
            )}
            {isDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isDelete}
                    onSuccess={handleDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}
        </ListItem>
    );
};

export default Item;
