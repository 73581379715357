import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { loadPageHeaders } from 'redux/Menu/actions';
import { menuSelectors } from 'redux/Menu';
import { config } from 'config';

let mutex = null;

export const usePageHeader = (location) => {
    const dispatch = useDispatch();
    const currentProject = config?.project?.code;
    const selectedLayer = useSelector(menuSelectors.selectedLayer);

    const pageHeaders = useSelector(menuSelectors.pageHeaders);

    useEffect(() => {
        // перезапрашиваем при каждой смене региона
        if (currentProject && mutex !== currentProject) {
            mutex= currentProject;
            dispatch(loadPageHeaders());
        }
    }, [currentProject, dispatch]);

    const currentPageHeader = pageHeaders.find((el) => {
        return el.link === location.pathname && (selectedLayer ? el.layer === selectedLayer : true);
    }) || {};
    return currentPageHeader;
};
