import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouteLink } from 'react-router-dom';
import { Avatar, Tooltip } from '@mui/material';
import { blue } from '@mui/material/colors';

import { authSelectors } from 'redux/Auth';
import { config } from 'config';
import useShowcase from 'helpers/hooks/useShowcase';
import logo from 'components/common/Showcase/HeaderPage/logo.jpg';
import ProjectTime from 'components/common/ProjectTime';
import Expand from 'components/common/Buttons/Expand';
import SelectLocation from 'components/common/Location/SelectLocation';

import styles from './header.module.scss';
import ProfileMenu from './ProfileMenu';
import HelpMenu from './HelpMenu';
import Notifications from './Notifications';

interface HeaderProps {
    headerRef?: React.Ref<HTMLDivElement>
}

const Header = ({ headerRef }: HeaderProps) => {

    const project = useSelector(authSelectors.project);

    const { first_name, last_name, middle_name } = useSelector(authSelectors.authUserInfo);
    const authSuperUser = useSelector(authSelectors.authSuperUser);

    const [showProfileInfo, setShowProfileInfo] = useState(false);
    const [showHelpInfo, setShowHelpInfo] = useState(false);
    const [anchorHelp, setAnchorHelp] = useState<HTMLElement | null>(null);
    const [anchorProfile, setAnchorProfile] = useState<HTMLElement | null>(null);

    const openHelpMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorHelp(event.currentTarget);
        setShowHelpInfo(true);
    };

    const closeHelpMenu = () => {
        setAnchorHelp(null);
        setShowHelpInfo(false);
    };

    const openProfileMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorProfile(event.currentTarget);
        setShowProfileInfo(true);
    };

    const closeProfileMenu = () => {
        setAnchorProfile(null);
        setShowProfileInfo(false);
    };

    // у витрины свой хедер
    const isShowCase = useShowcase();
    if (isShowCase) return null;

    const title = config.get('title') || project.title || config.getGeneral('title');
    const logoIcon = config.get('icon') || logo;
    const showHelper = config.get('showHelper') || false;

    return (
        <div ref={headerRef} className={styles.header}>
            <div className={styles.left}>
                <div className={styles.logo_wrapper}>
                    <RouteLink className={styles.link} to="/">
                        <img src={logoIcon} alt={title} />
                        <b>{title}</b>
                    </RouteLink>
                </div>
            </div>

            <div className={styles.right}>
                <div className={styles.location}>
                    <div style={{ width: 3, backgroundColor: '#34ccfe', height: 28 }} />
                    <div className={styles.item}>
                        <SelectLocation/>
                        <span className={styles.project_description}><b>{project.description}</b></span>
                    </div>
                </div>

                <div style={{ display: 'flex', gap: 24 }}>
                    <div className={styles.item}>
                        <ProjectTime onlyProject withTitle={false} className={''} />
                    </div>

                    <div className={styles.item}>
                        <Notifications />
                    </div>

                    <div className={styles.item}>
                        <div className={styles.expand_btn_wrapper} onClick={openProfileMenu}>
                            <Tooltip title={`${first_name || ''} ${last_name || ''} ${middle_name || ''}`}>
                                <Avatar
                                    sx={authSuperUser ? { bgcolor: blue[500] } : {}}
                                    className={styles.avatar}
                                >
                                    {first_name?.[0]}
                                    {last_name?.[0]}
                                </Avatar>
                            </Tooltip>

                            <Expand disableRipple expand={showProfileInfo} />
                        </div>

                        <ProfileMenu
                            anchorEl={anchorProfile}
                            onClose={closeProfileMenu}
                            isSuperUser={Boolean(authSuperUser)}
                        />
                    </div>

                    {showHelper
                        && <div className={styles.item}>
                            <div className={styles.expand_btn_wrapper} onClick={openHelpMenu}>
                                <span style={{ fontSize: '0.9rem' }}>Помощь</span>
                                <Expand expand={showHelpInfo} disableRipple />
                            </div>

                            <HelpMenu
                                anchorEl={anchorHelp}
                                onClose={closeHelpMenu}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Header;
