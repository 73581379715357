import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar } from '@mui/material';

import { authSelectors } from 'redux/Auth';
import { getUsers } from 'redux/Admin/actions';
import { useStoreFromSelector } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import { isFloat } from 'helpers/validator';
import Modal from 'components/common/Modal';
import { CustomTab, CustomTabs } from 'components/common/Tabs';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { createIncident, editIncident, loadIncidentObjectTypes } from 'modules/React/redux/actions';
import { loadIncidentCategories } from 'modules/React/redux/actions';
import * as reactSelector from 'modules/React/redux/selectors';
import useObjectsProvider from 'modules/React/utils/helpers/incidents/useObjectsProvider';
import type { UserItem } from 'modules/React/reactTypes';
import { reactSelectors } from 'modules/React';

import { BasicData } from './BasicData';
import Infrastructure from './Infrastructure';


import type { IncidentItem, Objects, ObjectsData, ObjectTypes } from '../types';

type AddModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isNew?: boolean;
  item?: IncidentItem | null;
  reloadList: () => void;
  objectTypes?: ObjectTypes;
}

export const AddModal = ({ isOpen, onClose, isNew = false, item = {}, reloadList,  }: AddModalProps) => { // objectTypes
    const dispatch = useDispatch();
    const objectTypes: ObjectTypes = useStoreFromSelector(loadIncidentObjectTypes, reactSelector.objectTypes);

    const [loadingObjects, setLoadingObjects] = useState(false);

    const getFirstCoordRec = (value: any): {lat: number | string; lon: number | string;} => {
        if (!value) {
            return {
                lat: '',
                lon: '',
            };
        }

        const lat = value?.[1] || '';
        const lon = value?.[0] || '';

        if (value?.geometry) {
            return getFirstCoordRec(value?.geometry);
        }
        if (value?.coordinates) {
            return getFirstCoordRec(value?.coordinates);
        }
        if (isFloat(lat) && isFloat(lon)) {
            // if (parseFloat(lat) === lat && parseFloat(lon) === lon) {
            return {
                lat,
                lon,
            };
        }
        return getFirstCoordRec(value[0]);
    };

    const user = useSelector(authSelectors.authUserInfo);
    const incidentCategories: IncidentItem['category_id'][] = useStoreFromSelector(
        loadIncidentCategories, 
        reactSelectors.incidentCategories
    );

    useEffect(() => {
        if (typeof item?.category_id === 'number') {
            setData((prev) => ({
                ...prev,
                //@ts-ignore
                category_id: incidentCategories?.find(el => el.id === item.category_id),
            }));
        }
    }, [incidentCategories, item?.category_id]);

    const initialState: IncidentItem = {
        ...item,
        dtp_id: item?.dtp_id || null,
        name: item?.name || '',
        type_id: item?.type_id,
        status_id: isNew ? 1 : item?.status_id,
        vehicle_types: item?.vehicle_types || [],
        registered_at: item?.registered_at || new Date(),
        date_plan: item?.date_plan || null,
        date_fact: item?.date_fact || null,
        created_at: item?.created_at || new Date(),
        description: item?.description || item?.dtp_id ? item?.name : '',
        threat_level: item?.threat_level,
        factors: item?.factors || [],
        source: item?.source || '',
        organizations: item?.organizations || [],
        user: item?.user || [user],
        dispatcher: item?.dispatcher || [],
        resolve_rules: item?.resolve_rules || 1,
        attachments: item?.attachments || [],
        type_creation: item?.type_creation || 1,
        geometry: item?.geometry || [],
        lat: item?.lat ?? getFirstCoordRec(item?.geometry).lat ?? 0,
        lon: item?.lon ?? getFirstCoordRec(item?.geometry).lon ?? 0,
        address: item?.address || [],
        area: item?.area || {},
        keywords: item?.keywords || [],
        category_id: item?.category_id ? incidentCategories?.find(el => el?.id === item.category_id?.id) : null,
        objects: item?.objects ? item?.objects : [],
        metro_stations: item?.metro_stations || [],
        type_close_id: item?.type_close_id || 0,
        reason_close_id: item?.reason_close_id || 0,
        solution: item?.solution || '',
    };

    const [currentTab, setCurrentTab] = useState('basicData');
    const [data, setData] = useState(initialState);

    // провайдер для догрузки обектов
    const objectsProvider = useObjectsProvider(
        (objects: any) => {
            setData(prev => ({ ...prev, objects: prepareObjects(objects || []) }));
        },
        setLoadingObjects
    );

    const tabProps = (tabName: string) => ({
        id: `scrollable-force-tab-${tabName}`,
        'aria-controls': `scrollable-force-tabpanel-${tabName}`,
    });

    const tabPanelProps = (tabName: string) => ({
        role: 'tabpanel',
        id: `scrollable-force-tabpanel-${tabName}`,
        'aria-labelledby': `scrollable-force-tab-${tabName}`
    });

    // объединение объектов массив
    const prepareObjects = (objects: Objects[]) => {
        let resObjects: ObjectsData[] = [];
        objects.forEach(el => {
            resObjects = [ ...resObjects, ...el.data];
        });
        return resObjects;
    };

    const onAcceptModal = () => {
        const prepareData: any = removeEmptyFields({
            ...data,
            registered_at: fullDateTimeWithTimeZone(data.registered_at),
            created_at: fullDateTimeWithTimeZone(data.created_at),
            date_plan: fullDateTimeWithTimeZone(data.date_plan),
            date_fact: fullDateTimeWithTimeZone(data.date_fact),
            dispatcher: data.dispatcher?.map((el) => el.id),
            threat_level_id: data.threat_level?.id,
            user: data.user?.map((el: any) => el.id),
            category_id: data?.category_id?.id,
            response_scenario_id: data?.response_scenario?.id || null,
            // description: 'описание'
        }, true, false, true);

        // если не инцидент метрополитена - иначе ошибка
        if (data?.category_id?.id === 1) {
            delete prepareData?.type_close_id;
            delete prepareData?.reason_close_id;
        }

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createIncident(prepareData, callback))
            : dispatch(editIncident(item?.id, prepareData, callback));
    };

    useEffect(() => {
        const userIdList = [...new Set([...(item?.dispatcher || []), ...(item?.user || [])])];

        !isNew && userIdList.length > 0 && dispatch(getUsers({
            page: 1,
            limit: userIdList.length,
            //@ts-ignore
            ids: userIdList
        }, (response: UserItem[]) => {
            setData((prev) => ({
                ...prev,
                user: response.filter((el: any) => item?.user?.find((user: any) => user === el.id)),
                dispatcher: response.filter((el: any) => item?.dispatcher?.find((user: any) => user === el.id)),
            }));
        }));
    }, [item?.user, item?.dispatcher, isNew, dispatch]);

    const onLoadByIncident = (v: { lat: number | string, lon: number | string }) => {
        // загрузка по инциденту
        // const { lat, lon } = data;
        const { lat, lon } = v;
        objectsProvider.clear();
        objectsProvider.loadByLatLon(lat, lon);
    };
    const onLoadByStations = (stations: { lat: number | string, lon: number | string }[]) => {
        // загрузка по станциям метро
        objectsProvider.clear();
        stations?.map(({ lat, lon }) => objectsProvider.createLoadByLatLon(lat, lon));
        objectsProvider.doAction();
    };

    const isDisable = loadingObjects || (data.factors && data.factors.some(value => !value?.geo_json && value?.is_geo && value.comment === ''));

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? 'Создание Инцидента' : 'Редактирование Инцидента'}
            onClose={onClose}
            fullWidth
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose,
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisable,
                        loading: loadingObjects,
                    }
                ]}
            />}
        >
            <AppBar position="sticky" color="default">
                <CustomTabs
                    value={currentTab}
                    onChange={(_, newValue) => setCurrentTab(newValue)}
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="scrollable force tabs example"
                >
                    <CustomTab
                        iconPosition="start"
                        value="basicData"
                        label={'Основные данные'}
                        {...tabProps('basicData')}
                    />

                    {(
                        (data?.category_id?.id === 1 && data.lat && data.lon)
                        || (data?.category_id?.id === 2 && data?.metro_stations && data?.metro_stations?.length > 0)
                    )
                        && <CustomTab
                            iconPosition="start"
                            value="infrastructure"
                            label={'Объекты инфраструктуры'}
                            {...tabProps('infrastructure')}
                        />
                    }
                </CustomTabs>
            </AppBar>
            {currentTab === 'basicData'
                && <div {...tabPanelProps('basicData')}>
                    <BasicData
                        isNew={isNew}
                        data={data}
                        setData={setData}
                        loadingObjects={loadingObjects}
                        onLoadByIncident={onLoadByIncident}
                        onLoadByStations={onLoadByStations}
                    />
                </div>
            }
            {currentTab === 'infrastructure'
                && <div {...tabPanelProps('infrastructure')}>
                    <Infrastructure
                        item={data}
                        loading={loadingObjects}
                        objectTypes={objectTypes}
                    />
                </div>
            }
        </Modal>
    );
};
