import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { createWorkRequest, editWorkRequest, loadWorkRequestStatuses } from 'redux/WorkRequests/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import buttons from 'helpers/constants/buttons';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import InfoBlock from 'components/common/InfoBlock';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import Modal from 'components/common/Modal';
import Attachments from 'components/common/Upload/Attachments';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import FieldsModal from 'components/common/Location/FieldsModal';

const ModalForm = ({
    isOpen = false,
    onClose = () => {},
    isNew = false,
    item = {},
    loadList = () => {}
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);
    const statuses = useStoreProp(loadWorkRequestStatuses, 'workRequests', 'statuses');

    const initialState = {
        status: item?.status || 1,
        name: item?.name || '',
        lat: item?.lat || '',
        lon: item?.lon || '',
        description: item?.description || '',
        comment: item?.comment || '',
        files: item?.files || [],
        date_plan: item?.date_plan || '',
    };

    const [formData, setFormData] = useState(initialState);

    const handleSave = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            status: null
        }, false);

        dispatch(isNew
            ? createWorkRequest(prepareData)
            : editWorkRequest(item.id, prepareData)
        );
        validation.clear();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const onChangeDate = (value, key) => {
        setFormData({
            ...formData,
            [key]: fullDateTimeWithTimeZone(value)
        });
    };

    const onChangeCoordinates = (params) => {
        setFormData({
            ...formData,
            ...params
        });
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
            loadList();
        }
    }, [validation, onClose, requestStatus, loadList]);

    const isDisabled = !formData.name
        || !formData.lat
        || !formData.lon
        || !formData.description;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? 'Добавить Заявку' : 'Изменить Заявку'}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <TextField
                    value={formData.name}
                    name="name"
                    onChange={handleChange}
                    className="block"
                    label={titles.NAME}
                    variant={'outlined'}
                    size={'small'}
                    required={true}
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                />
                <FormControl className="block" size="small" variant="outlined">
                    <InputLabel>Статус</InputLabel>
                    <Select
                        value={formData.status}
                        label="Статус"
                        onChange={handleChange}
                        name="status"
                        disabled
                    >
                        {Object.keys(statuses)?.length
                            ? Object.keys(statuses)?.map((key) =>
                                <MenuItem value={key} key={key}>{statuses[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        onChange={(value) => onChangeDate(value, 'date_plan')}
                        value={formData.date_plan}
                        label={'Плановая дата выполнения'}
                        required
                        error={validation.isKey('date_plan')}
                        helperText={validation.get('date_plan')}
                    />
                </div>

                <InfoBlock label={titles.LOCATION} className="block">
                    <LatLonCoordinates
                        lat={formData?.lat}
                        lon={formData?.lon}
                        onChange={onChangeCoordinates}
                        required
                    />

                    <FieldsModal
                        title="Поставьте/передвиньте метку на карте"
                        buttonText={buttons.SELECT}
                        buttonVariant="contained"
                        fields={formData}
                        onChange={onChangeCoordinates}
                    >
                        <MapDragMarker required />
                    </FieldsModal>
                </InfoBlock>

                <div className="block">
                    <TextField
                        value={formData.description}
                        name="description"
                        onChange={handleChange}
                        className="block"
                        label={titles.DESCRIPTION}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('description')}
                        helperText={validation.get('description')}
                    />
                </div>
                <div className="block">
                    <TextField
                        value={formData.comment}
                        name="comment"
                        onChange={handleChange}
                        className="block"
                        label={titles.COMMENT}
                        variant={'outlined'}
                        size={'small'}
                        error={validation.isKey('comment')}
                        helperText={validation.get('comment')}
                    />
                </div>
                <div className="block">
                    <Attachments
                        onlyLink={true}
                        label="Файлы к заявке"
                        onChange={(files) => setFormData({ ...formData, files })}
                        files={formData?.files}
                        helperText={validation.get('files')}
                        error={validation.isKey('files')}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ModalForm;
