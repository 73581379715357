import { useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import { Card } from '@mui/material';

import { CustomTab, CustomTabs } from 'components/common/Tabs';
// import { Scrollbars } from 'react-custom-scrollbars';

const Tabs = ({
    list = [],
    disabled = false,
}) => {

    // const scrollRef = useRef(null);

    const tabPanelProps = (tabName) => ({
        role: 'tabpanel',
        id: `scrollable-force-tabpanel-${tabName}`,
        'aria-labelledby': `scrollable-force-tab-${tabName}`,
        style: {
            padding: '.5rem 1rem',
            // minHeight: '100%',
            width: '100%',
        }
    });

    const tabProps = (tabName) => ({
        id: `scrollable-force-tab-${tabName}`,
        'aria-controls': `scrollable-force-tabpanel-${tabName}`,
    });

    const [selectedTab, setSelectedTab] = useState( list?.[0]?.tab || '');

    // const scrollTop = () => {
    //     if (scrollRef.current) scrollRef.current?.scrollTop(0);
    // };

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
        // scrollTop();
    };

    return (
        <Card
            variant="elevation"
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <AppBar position="sticky" color="default">
                <CustomTabs
                    value={selectedTab}
                    onChange={handleChange}
                    textColor="primary"
                    variant="scrollable"
                    aria-label="scrollable force tabs example"
                >
                    {list?.map((item, index) => (
                        <CustomTab
                            key={index}
                            icon={item?.icon}
                            iconPosition="start"
                            value={item?.tab}
                            label={item?.label}
                            {...tabProps(item?.tab)}
                            disabled={disabled}
                        />
                    ))}
                </CustomTabs>
            </AppBar>

            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    width: '100%',
                }}
            >
                {/*<Scrollbars*/}
                {/*    style={{*/}
                {/*        height: '100%',*/}
                {/*        minHeight: '400px',*/}
                {/*    }}*/}
                {/*    ref={scrollRef}*/}
                {/*>*/}
                {/*</Scrollbars>*/}
                {list?.map((item, index) => {
                    if (item?.tab === selectedTab) {
                        return (
                            <div
                                key={index}
                                {...tabPanelProps(item?.tab)}
                            >
                                {item?.component}
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </Card>
    );
};

export default Tabs;
