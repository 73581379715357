import React, { Fragment, useState } from 'react';
import PushPinIcon from '@mui/icons-material/PushPin';
import { IconButton } from '@mui/material';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import cn from 'classnames';
import {
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable,
} from '@tanstack/react-table';

import DraggableHeader from 'components/common/DataTable/DraggableHeader';
import ColumnOptions from 'components/common/DataTable/ColumnOptions';
import SettingsMenu from 'components/common/DataTable/SettingsMenu';

import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core/dist/types';
import type { ColumnDef, ColumnPinningState, Header } from '@tanstack/react-table';
import type { VisibilityState } from '@tanstack/table-core/src/features/ColumnVisibility';

import 'components/common/DataTable/styles.scss';

interface DataTableProps<T> {
    data: T[];
    columns: ColumnDef<T>[];
    renderInfo?: (item: T) => React.ReactNode;
}

function DataTable<T>({ data, columns, renderInfo }: DataTableProps<T>) {
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
    const [columnOrder, setColumnOrder] = useState<string[]>(() => columns.map((c) => c.id!));
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({});
    const [draggable, setDraggable] = useState(false);
    const [draggingActiveHeader, setDraggingActiveHeader] = useState<Header<T, unknown> | null>(
        null
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: { columnVisibility, columnOrder, columnPinning },
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        onColumnPinningChange: setColumnPinning,
        getRowCanExpand: () => !!renderInfo,
        getExpandedRowModel: getExpandedRowModel(),
        autoResetExpanded: true,
    });

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setColumnOrder((columnOrder) => {
                const oldIndex = columnOrder.indexOf(active.id as string);
                const newIndex = columnOrder.indexOf(over.id as string);
                return arrayMove(columnOrder, oldIndex, newIndex);
            });
        }

        setDraggingActiveHeader(null);
    }

    const handleDragStart = (event: DragStartEvent) => {
        const activeHeader = table.getFlatHeaders().find((header) => header.id === event.active.id);

        if (activeHeader) {
            setDraggingActiveHeader(activeHeader);
        }
    };

    return (
        <>
            <SettingsMenu
                settings={{
                    visibility: {
                        columns: columnVisibility,
                        reset: () => setColumnVisibility({}),
                    },
                    draggable: {
                        enabled: draggable,
                        toggle: setDraggable,
                    },
                }}
            />

            <div className="data-table">
                <DndContext
                    collisionDetection={closestCenter}
                    modifiers={[restrictToHorizontalAxis]}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                >
                    <div className="data-table-row heading">
                        <SortableContext
                            items={columnOrder}
                            strategy={horizontalListSortingStrategy}
                        >
                            {table.getFlatHeaders().map((header) => {
                                if (draggable && !header.column.getIsPinned()) {
                                    return <DraggableHeader key={header.id} header={header} />;
                                }

                                return (
                                    <div
                                        key={header.id}
                                        className={cn('data-table-row-cell', { actions: header.column.id === 'actions' })}
                                        // todo: доработать гибкую настройку ширины столбцов
                                        style={header.getSize() !== 150 ? { maxWidth: header.getSize() } : {}}
                                    >
                                        <span>{header.column.columnDef.header}</span>

                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                gap: 8,
                                                marginRight: 4,
                                            }}
                                        >
                                            {header.column.id !== 'actions' && !draggable && (
                                                <ColumnOptions header={header} />
                                            )}

                                            {header.column.getIsPinned() && (
                                                <PushPinIcon
                                                    style={{ transform: 'rotate(45deg)' }}
                                                    fontSize="small"
                                                    color="disabled"
                                                />
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </SortableContext>

                        <DragOverlay>
                            {draggingActiveHeader ? (
                                <div
                                    className={cn('data-table-row-cell', {
                                        // todo: доработать стили, когда ячейка пустая
                                        dragging: !!draggingActiveHeader,
                                    })}
                                >
                                    <span>{draggingActiveHeader.column.columnDef.header}</span>

                                    <IconButton
                                        size="small"
                                        style={{
                                            width: 30,
                                            height: 30,
                                            position: 'absolute',
                                            right: 0,
                                        }}
                                        disableRipple
                                    >
                                        <i className="far fa-arrows-h" />
                                    </IconButton>
                                </div>
                            ) : null}
                        </DragOverlay>
                    </div>
                </DndContext>

                {table.getRowModel().rows.map((row) => {
                    return (
                        <Fragment key={row.id}>
                            <div
                                key={row.id}
                                className="data-table-row"
                                {...(row.getCanExpand()
                                    ? {
                                        onClick: () => row.toggleExpanded(!row.getIsExpanded()),
                                        style: { cursor: 'pointer' },
                                    }
                                    : {})}
                            >
                                {row.getVisibleCells().map((cell) => (
                                    <div
                                        key={cell.id}
                                        style={cell.column.getSize() !== 150 ? { maxWidth: cell.column.getSize() } : {}}
                                        className={cn('data-table-row-cell', {
                                            actions: cell.column.id === 'actions' && !draggable,
                                            'draggable-actions': draggable && cell.column.id === 'actions',
                                        })}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </div>
                                ))}
                            </div>

                            {/*todo: доработать закрытие изменении списка (фильтрация, пагинация и тд)*/}
                            {row.getIsExpanded() && (
                                <div>{renderInfo && renderInfo(row.original)}</div>
                            )}
                        </Fragment>
                    );
                })}
            </div>
        </>
    );
}

export default DataTable;
