import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createInfrastructure, loadInfrastructure } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import DataTable from 'components/common/DataTable';

import Actions from './Actions';
import Modal from './Modal';
import Filter from './Filter';

const Infrastructure = () => {
    const dispatch = useDispatch();

    const infrastructureData = useSelector(roadNetworksSelectors.infrastructureData);
    const infrastructureMeta = useSelector(roadNetworksSelectors.infrastructureMeta);
    const loadingInfrastructure = useSelector(roadNetworksSelectors.loadingInfrastructure);

    const [openAddModal, setOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = () => dispatch(loadInfrastructure(params.page, limit, params.data));

    useEffect(() => {
        dispatch(loadInfrastructure(params.page, limit, params.data));
    }, [params, limit, dispatch]);

    const onSaveData = (data) => {
        const newData = removeEmptyFields(data);
        dispatch(createInfrastructure(newData, () => {
            setParams({ page: 1, data: {} });
            setOpenAddModal(false);
        }));
    };

    const renderContent = () => {
        if (infrastructureData.length === 0) return <NoData />;

        return (
            <DataTable
                data={infrastructureData}
                columns={[
                    {
                        id: 'name',
                        accessorKey: 'name',
                        header: titles.NAME,
                        cell: (info) => info.getValue() || messages.NO_VALUE,
                    },
                    {
                        id: 'type',
                        accessorKey: 'type',
                        header: titles.TYPE,
                        cell: (info) => info.getValue()?.name || messages.NO_VALUE,
                    },
                    {
                        id: 'organization',
                        accessorKey: 'organization',
                        header: titles.ORGANIZATION,
                        cell: (info) => info.getValue()?.title || messages.NO_VALUE,
                    },
                    {
                        id: 'description',
                        accessorKey: 'description',
                        header: titles.DESCRIPTION,
                        cell: (info) => info.getValue() || messages.NO_VALUE,
                    },
                    {
                        id: 'actions',
                        header: 'Действия',
                        cell: (info) => <Actions el={info.row.original} reloadList={reloadList} />,
                    },
                ]}
            />
        );
    };

    return (
        <>
            <PageLayout
                header={titles.INFRASTRUCTURE_OBJECTS}
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                },
                            ]}
                            noPadding
                            noMarginLeft
                        />
                    ),
                    total: infrastructureMeta?.total,
                }}
                loading={loadingInfrastructure}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: infrastructureMeta,
                    limit,
                    setLimit,
                }}
            />

            {openAddModal && (
                <Modal
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew
                    onAcceptModal={onSaveData}
                />
            )}
        </>
    );
};

export default Infrastructure;
