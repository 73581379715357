import DateRange from 'components/common/Dates/DateRange';
import SelectThreatLevels from 'modules/React/utils/components/SelectThreatLevels';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';

const FilterForm = ({ data, onChange }) => {

    const handleChange = (name, value) => {
        onChange({ [name]: value });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => { e.preventDefault(); }}
        >
            <div className="row block">
                <DateRange
                    valueStartDate={data?.start_date || null}
                    valueEndDate={data?.end_date || null}
                    handleDate={(val) => handleChange('start_date', val)}
                    handleEndDate={(val) => handleChange('end_date', val)}
                    equalDates={true}
                />
            </div>
            <SelectIncidentTypes
                className="block"
                multiple
                label="Тип инцидента"
                selected={data.type_id_list || []}
                onChange={(val) => handleChange('type_id_list', val)}
            />
            <SelectThreatLevels
                className="block"
                multiple
                selected={data.threat_level_id_list || []}
                onChange={(val) => handleChange('threat_level_id_list', val)}
            />
            {/* <AddressList
                label="Адрес"
                className={''}
                address={data?.address_text || ''}
                address_text={data?.address_text}
                onChange={(value) => handleChange('address_text', value?.address_text)}
            /> */}
        </form>
    );
};

export default FilterForm;
