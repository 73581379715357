import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOAD_POLYGON = `${prefix}/LOAD_POLYGON`;
export const LOADED_POLYGON = `${prefix}/LOADED_POLYGON`;
export const LOADING_POLYGON = `${prefix}/LOADING_POLYGON`;

export const SET_DELETE_FORM = `${prefix}/SET_DELETE_FORM`;
export const SET_EDIT_FORM = `${prefix}/SET_EDIT_FORM`;

export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const RESET_ACTIVE = `${prefix}/RESET_ACTIVE`;

export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR_SIDEBAR`;

export const CREATE_PARK = `${prefix}/CREATE_PARK`;
export const EDIT_PARK = `${prefix}/EDIT_PARK`;
export const DELETE_PARK = `${prefix}/DELETE_PARK`;
export const SET_LOADING = `${prefix}/SET_LOADING`;
export const SET_SAVED = `${prefix}/SET_SAVED`;

export const LOAD_STATUSES = `${prefix}/LOAD_STATUSES`;
export const LOADED_STATUSES = `${prefix}/LOADED_STATUSES`;
export const LOAD_TYPES = `${prefix}/LOAD_TYPES`;
export const LOADED_TYPES = `${prefix}/LOADED_TYPES`;
export const LOAD_ZONES = `${prefix}/LOAD_ZONES`;
export const LOADED_ZONES = `${prefix}/LOADED_ZONES`;

export const LOAD_STATUS_STATISTIC = `${prefix}/LOAD_STATUS_STATISTIC`;
export const LOADED_STATUS_STATISTIC = `${prefix}/LOADED_STATUS_STATISTIC`;

export const LOAD_PARKING_LIST = `${prefix}/LOAD_PARKING_LIST`;
export const LOADED_PARKING_LIST = `${prefix}/LOADED_PARKING_LIST`;
export const LOADING_PARKING_LIST = `${prefix}/LOADING_PARKING_LIST`;

export const LOAD_TARIFF_LIST = `${prefix}/LOAD_TARIFF_LIST`;
export const LOADED_TARIFF_LIST = `${prefix}/LOADED_TARIFF_LIST`;

export const LOAD_CATEGORIES = `${prefix}/LOAD_CATEGORIES`;
export const LOADED_CATEGORIES = `${prefix}/LOADED_CATEGORIES`;

export const LOAD_TARIFF_CATEGORIES = `${prefix}/LOAD_TARIFF_CATEGORIES`;
export const LOADED_TARIFF_CATEGORIES = `${prefix}/LOADED_TARIFF_CATEGORIES`;

export const LOAD_TARIFF_DAYS = `${prefix}/LOAD_TARIFF_DAYS`;
export const LOADED_TARIFF_DAYS = `${prefix}/LOADED_TARIFF_DAYS`;