import { createIcon, MapPopUp } from 'components/MapComponents/MapPopUp';

import { getAnchorIcon } from '../helper';

import List from './List';

const PopUpPierModal = ({
    item,
    isOpen,
    onClose,
}) => {

    const {
        id,
        color,
        name,
    } = item || {};

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={createIcon(getAnchorIcon)}
            title={name}
            titleBackgroundColor={color}
            onClose={onClose}
            medium
        >
            <List id={id} />
        </MapPopUp>
    );
};

export default PopUpPierModal;
