import { loadConcentrationAreaStatuses, loadConcentrationAreaTypes } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { useStoreFromSelector } from 'helpers/hooks';

import { createIconTS } from './helper';

const Legend = () => {

    const types = useStoreFromSelector(
        loadConcentrationAreaTypes,
        incidentsSelectors.concentrationAreaTypes
    );

    const statuses = useStoreFromSelector(
        loadConcentrationAreaStatuses,
        incidentsSelectors.concentrationAreaStatuses
    );

    return (
        <div className="sidebar-tp-legend column">
            {/* типы */}
            <div className="item">
                <div className="title">
                    Вид
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {types?.map?.(type => (
                        <div
                            key={type.id}
                            className="flex"
                            style={{
                                width: '200px',
                                alignItems: 'start',
                            }}
                        >
                            <div
                                className="image"
                                dangerouslySetInnerHTML={{ __html: createIconTS(type.color, '#fff') }}
                            />
                            <div className="small">
                                - {type.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* статусы */}
            <div className="item">
                <div className="title">
                    Статус
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {statuses?.map?.(status => (
                        <div
                            key={status.id}
                            className="flex"
                            style={{
                                width: '200px',
                                alignItems: 'start',
                            }}
                        >
                            <div
                                className="image circle-color"
                                style={{
                                    backgroundColor: '#fff',
                                    border: `3px solid ${status.color}`
                                }}
                            />
                            <div className="small">
                                - {status.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default Legend;
