import { useDispatch } from 'react-redux';

import { getObjectBindingsAll } from 'modules/DigitalTwin/redux/actions';

import { eputsTypes, objectTypes } from './objectHelper';

const useBindingsProvider = (fnCallback, fnLoading) => {

    const dispatch = useDispatch();

    const getFilter = (itemId, itemType) => {
        if (!itemId) return null;
        switch (itemType) {
            case objectTypes.node:
            case objectTypes.edge:
                return {
                    object_type: itemType,
                    object_id: itemId,
                    per_page: 1000,
                };
            case eputsTypes.infrastructure:
            case eputsTypes.roadNetwork:
                return {
                    eputs_type: itemType,
                    eputs_id: itemId,
                    per_page: 1000
                };

            default:
                return null;
        }
    };

    return {
        load: (itemId, itemType) => {
            const filter = getFilter(itemId, itemType);
            if (filter) {
                dispatch(getObjectBindingsAll(
                    filter,
                    (request = {}) => fnCallback?.(request.data || []),
                    fnLoading
                ));
            }
        },
    };
};

export default useBindingsProvider;
