import { useState } from 'react';

import type { FactorType, GeometryType } from 'modules/React/reactTypes';

import FactorItem from './FactorItem';

export interface  FactorsListType  {
  factors: FactorType[] | [];
  onChange: (factors: FactorType[]) => void;
  visibleGeometry: GeometryType | null;
}

export const FactorsList = ({ factors, onChange, visibleGeometry }: FactorsListType) => {
    const [isErrorIds, setIsErorr] = useState<number[]>([]);

    const onChangeList = (index: number, value: FactorType) => {
        if (value.comment === null && value.geo_json) {
            value.comment = '';
        }

        if (!value?.geo_json && value?.is_geo && value.comment === '') {
            setIsErorr(prev => [...prev, value.id]);
        } else {
            setIsErorr(prev => prev.filter(el => el !== value.id));
        }

        const newList = [
            ...factors.slice(0, index),
            value,
            ...factors.slice(index +1)
        ];

        onChange(newList);
    };

    return (
        <>
            <h3>Добавление геоинформации:</h3>
            {factors.map((factor: FactorType, index: number) => (
                <FactorItem
                    key={factor.id}
                    factor={factor}
                    onChange={(val: FactorType) => onChangeList(index, val)}
                    visibleGeometry={visibleGeometry}
                    error={isErrorIds.includes(factor.id)}
                    helperText={isErrorIds.includes(factor.id) ? 'Необходимо добавить геозону, либо указать причину отказа' : ''}
                />
            ))}
        </>
    );
};
