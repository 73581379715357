import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadCategoryStreets } from 'redux/Incidents/actions';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';

export const CategoryStreetsType = ({
    multiple = false,
    selected = multiple ? [] : null, // полные объекты с типами или просто айдишники
    onChange,
    required,
    disabled,
    label = 'Категория улиц',
    error,
    helperText = '',
    limitTags = 1,
}) => {
    const dispatch = useDispatch();

    const [data, setData] = useState([]); // { id, name }
    const [loading, setLoading] = useState(false);
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        dispatch(loadCategoryStreets(
            setData,
            setLoading
        ));
    }, []);

    const findByElement = (element) => (typeof element === 'object')
        ? element
        : data.find(item => item.id === element) || null;

    const selectedObjects = Array.isArray(selected) && selected.length > 0
        ? selected.map(findByElement)
        : findByElement(selected);



    const handleClose = () => {
        setShowList(false);
    };

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        onChange(multiple ? [] : null);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selectedObjects}
                onChange={onChange}
                required={required}
                disabled={disabled}
                renderLabel={(option) => option?.name || ''}
                limitTags={limitTags}
                label={label}
                error={error}
                helperText={helperText}
                loading={loading}
                onReset={onReset}
                isOpen={showList}
                onShowList={() => setShowList(true)}
            />

            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    sortedList={data}
                    keyProp="id"
                    isSelected
                    selected={selectedObjects}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    small
                    title={`Выбрать категори${multiple ? 'и' : 'ю'}`}
                />
            )}
        </>
    );
};
