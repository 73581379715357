import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Chip from '@mui/material/Chip';

import { deleteEventObjects } from 'modules/React/redux/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(
            deleteEventObjects(item?.id, () => {
                reloadList(true);
                setIsDelete(false);
            })
        );
    };

    return (
        <div className="list_item">
            <LSContentItem>
                <LSContentColumn>
                    {item?.name}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.event?.name || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.type_name || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.keyword_list?.length > 0
                        ? item?.keyword_list?.map((word) => (
                            <Chip key={word.id} label={word?.name} size={'small'} />
                        ))
                        : messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsEditMode(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                            },
                        ]}
                        noMarginLeft
                        justButton
                    />
                </LSContentColumn>
            </LSContentItem>


            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    isNew={false}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </div>
    );
};

export default Item;
