import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import {
    clearComplexTransferRules,
    createComplexEntity,
    editComplexEntity,
    loadedCurrentComplexTransferTypes,
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import PasswordInput from 'components/common/PasswordInput/PasswordInput';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

import DirTypeForm from './DirTypeForm';
import RenderFields from './RenderFields';
import Map from './Map';
import LinesForm from './LinesForm';
import ZoneForm from './ZoneForm';
import { getInitialFields } from './helper';

const EntityForm = ({
    isOpen,
    onClose,
    isNew,
    types,
    item = {},
    complex_id,
    onAdded,
    onEdited,
    // loadList,
    currentComplex,
    withComputer = false, // есть ли добавленные ВМ у комплекса
    test_id_prefix_passport = ''
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [data, setData] = useState(isNew
        ? {}
        : item
    );

    const complexById = useSelector(dorisControlSelectors.complexById);
    const complex = { ...currentComplex, ...complexById };

    const selectedType = types[data?.entity_id];
    const showMap = data.hasOwnProperty('lat') || data.hasOwnProperty('lon');

    // TODO типы каналов связи (ВОЛС, ЛТЕ и пр) загружать через АПИ

    const changeType = (e) => {
        const { name, value } = e.target;

        const currentType = types[value];

        const newData = getInitialFields(currentType.fields, complex_id);

        if (Number(value) === 3) {
            // BM
            newData.serial = currentComplex?.serial_number;
        }
        if (Number(value) === 4) {
            // распознающая камера
            newData.dir_type = 0;
            if (Number(currentComplex?.type_grouping) === 4) {
                const fieldZone = currentType.fields.find(el => el.field === 'zones') || {};
                const initialZoneFields = getInitialFields(fieldZone.fields, complex_id);
                newData.zones = [initialZoneFields];
                newData.lines = null;
            } else {
                const fieldLine = currentType.fields.find(el => el.field === 'lines') || {};
                const initialLineFields = getInitialFields(fieldLine.fields, complex_id);
                newData.lines = [initialLineFields];
                newData.zones = null;
            }
        }
        if (Number(value) === 5) {
            // Обзорная камера
            newData.dir_type = 1;
            newData.direction = [];
        }

        setData({
            [name]: value,
            ...newData
        });
    };

    const handleClose = () => {
        onClose();
        dispatch(clearComplexTransferRules());
        dispatch(loadedCurrentComplexTransferTypes({}));
    };

    const removeEmptyArray = (array = []) => {
        // просматриваем каждый объект в массиве
        const newData = array?.reduce((result, item) => {
            // удаляем пустые ключи в объекте
            if (item !== null && item !== undefined && item !== '') {
                if (typeof item === 'object' || Array.isArray(item)) {
                    const filteredLine = Object.entries(item).reduce((acc, [key, value]) => {
                        if (value !== null && value !== undefined && value !== '') {
                            if (Array.isArray(value)) {
                                if (value.length > 0) {
                                    const newVal = removeEmptyArray(value);
                                    if (newVal && newVal?.length > 0) {
                                        acc[key] = newVal;
                                    }
                                }
                            } else {
                                if (typeof value === 'object') {
                                    const newObj = Object.entries(value).reduce((res, [k, val]) => {
                                        if (val) res[k] = val;
                                        return res;
                                    }, {});
                                    if (Object.keys(newObj).length > 0) {
                                        acc[key] =  newObj;
                                    }
                                } else  {
                                    acc[key] = value;
                                }
                            }
                        }
                        return acc;
                    }, {});
                    if (Object.keys(filteredLine).length > 0) {
                        result.push(filteredLine);
                    }
                } else {
                    result.push(item);
                }
            }
            return result;
        },[]);
        // если массив пустой, заменяем его на null
        if (newData.length === 0) {
            return null;
        }
        return newData;
    };

    const handleSave = () => {
        let newDuploСodes = {};

        if (data.lines && data.lines.length > 0) {
            const duploCodes = data.lines.reduce((res, el) => res.concat(el.duplo_codes), []);
            const uniqDuploCodes = Array.from(new Set(duploCodes));

            newDuploСodes = uniqDuploCodes.reduce((res, el) => (
                { ...res, [el]: data.duplo_codes?.[el] || true }
            ), {});
        }
        if (Number(complex?.type_grouping) === 4) {
            data.lines = null;
        } else {
            data.zones = null;
        }

        const prepareData = {
            ...removeEmptyFields({
                ...data,
                duplo_codes: newDuploСodes
            }),
            // передаем обязательно поля checkbox
            is_skip_report: data?.is_skip_report || false,
            is_skip_techdata: data?.is_skip_techdata || false,
            zones: data.zones === null ? null : removeEmptyArray(data.zones),
            lines: data.lines === null ? null : removeEmptyArray(data.lines),
        };


        const callback = () => {
            handleClose();
            // loadList();
            // обновляем комплекс т.к. мы там показываем инфу по ВМ
            isNew
                ? onAdded && onAdded()
                : onEdited && onEdited();
        };

        isNew
            ? dispatch(createComplexEntity(selectedType.url, prepareData, callback))
            : dispatch(editComplexEntity(`${selectedType.url}/${item.id}`, prepareData, callback));

    };

    const title = `${isNew ? titles.CREATE : titles.EDIT}: ${types[data.entity_id]?.name || 'Оборудование'} ${data?.name || ''}`;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            maxWidthProp={showMap ? 'md' : 'sm'}
            buttons={
                <>
                    {!isNew && (
                        <TextField
                            style={{ margin: 16, width: '48%' }}
                            required
                            label={'Укажите причину редактирования'}
                            variant="outlined"
                            size="small"
                            value={data?._comment || ''}
                            name={'_comment'}
                            onChange={({ target: { name, value } }) => {
                                setData({ ...data, [name]: value });
                            }}
                            type="text"
                            error={!data._comment
                                || data._comment?.length < 6
                                || validation.isKey('_comment')
                            }
                            helperText={validation.get('_comment')
                                || ((!data._comment || data._comment?.length < 6)
                                && 'Введите не менее 6 символов')
                            }
                            InputProps={{
                                style: { background: 'white' },
                                'data-testid': `${test_id_prefix_passport}:_comment/input`,
                            }}
                        />
                    )}

                    <FormButtons
                        test_id_prefix={test_id_prefix_passport}
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: handleClose,
                                testName: 'close'
                            },
                            {
                                ...buttonsTypes.save,
                                onClick: handleSave,
                                testName: 'save',
                                disabled: !data.entity_id
                                    || (+data.entity_id === 4 && data?.dir_type === undefined)
                                    || (!isNew && (!data._comment || data._comment?.length < 6)),

                            },
                        ]}
                    />
                </>
            }
        >
            <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="flex-start">
                <Grid item xs={12} sm={showMap ? 6 : 12}  className={showMap ? 'modal__form' : ''}>
                    <FormControl
                        fullWidth
                        className="block"
                        size="small"
                        variant="outlined"
                        required
                        error={!data.entity_id}
                    >
                        <InputLabel data-testid={`${test_id_prefix_passport}:entity_id/label`}>{'Тип оборудования'}</InputLabel>
                        <Select
                            disabled={!!item?.id}
                            value={data.entity_id || ''}
                            onChange={changeType}
                            label={'Тип оборудования'}
                            name={'entity_id'}
                            data-testid={`${test_id_prefix_passport}:entity_id/select`}
                        >
                            {Object.keys(types).map((key) => (
                                <MenuItem
                                    disabled={(Number(key) === 5 ||  Number(key) === 4) && !withComputer}
                                    key={key}
                                    value={key}
                                >
                                    {types[key].name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {selectedType?.fields?.length > 0
                        && selectedType.fields.map((item) => (
                            <RenderFields
                                key={item.field}
                                item={item}
                                data={data}
                                setData={setData}
                                currentComplex={complex}
                                test_id_prefix_passport={test_id_prefix_passport}
                            />
                        ))
                    }
                    {Number(data?.entity_id) === 3
                        && <>
                            <h2>Доступ к админской панели:</h2>
                            <TextField
                                value={data?.admin_username}
                                onChange={(e) => setData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                label="Логин"
                                variant="outlined"
                                size="small"
                                name="admin_username"
                                type="text"
                                className="block"
                                InputProps={{
                                    'data-testid': `${test_id_prefix_passport}:admin_username/input`,
                                }}
                            />
                            <PasswordInput
                                className="block"
                                value={data?.admin_password}
                                name="admin_password"
                                label="Пароль"
                                onChange={(e) => setData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                            />
                            <TextField
                                value={data?.admin_url}
                                onChange={(e) => setData(prev => ({ ...prev, [e.target.name]: e.target.value }))}
                                label="Ссылка"
                                variant="outlined"
                                size="small"
                                name="admin_url"
                                type="text"
                                className="block"
                                InputProps={{
                                    'data-testid': `${test_id_prefix_passport}:admin_url/input`,
                                }}
                            />
                        </>
                    }

                </Grid>

                {showMap && (
                    <Grid item xs={12} sm={showMap ? 6 : 12}>
                        <Map
                            data={data}
                            setData={setData}
                            test_id_prefix={test_id_prefix_passport}
                        />
                    </Grid>
                )}

                {+data?.entity_id === 4 && (
                    <Grid item xs={12}>
                        <DirTypeForm
                            data={data}
                            setData={setData}
                            selectedType={selectedType}
                        />
                        {Number(complex?.type_grouping) === 4
                            ? <ZoneForm
                                data={data}
                                setData={setData}
                                fields={(selectedType?.fields || []).find(el => el.field === 'zones') || {}}
                                validation={validation}
                            />
                            : <LinesForm
                                data={data}
                                setData={setData}
                                fields={(selectedType?.fields || []).find(el => el.field === 'lines') || {}}
                                validation={validation}
                            />
                        }
                    </Grid>
                )}
                {+data?.entity_id === 5 && (
                    <Grid item xs={12}>
                        <DirTypeForm
                            data={data}
                            setData={setData}
                            selectedType={selectedType}
                        />
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

export default EntityForm;
