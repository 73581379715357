import { useState } from 'react';
import { useDispatch } from 'react-redux';

import InformPanel from 'components/layout/PageLayout/InformPanel';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { editResponseScenario } from 'modules/React/redux/actions';
import type { OperationInScenario, ResponseScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

import AddEditOperationModal from './AddFormModal';
import RenderOperationList from './RenderOperationList';

interface OperationsProps {
    item: ResponseScenario;
    reloadList: () => void;
}

const Operations = ({ item, reloadList }: OperationsProps) => {
    const dispatch = useDispatch();
    const { operation_list: data } = item;

    const [isOpenForm, setIsOpenForm] = useState(false);

    const onSave = (list: OperationInScenario[]) => {
        dispatch(editResponseScenario(
            item.id,
            { ...item, operation_list: list },
            () => {
                setIsOpenForm(false);
                reloadList();
            }
        ));
    };

    return (
        <>
            <InformPanel
                buttons={
                    <FormButtons
                        noPadding
                        justButton
                        buttons={[{
                            ...( data.length > 0 ? buttonsTypes.edit : buttonsTypes.add),
                            onClick: () => setIsOpenForm(true),
                        }]
                        }
                    />
                }
            />
            <RenderOperationList
                data={data}
            />
            {isOpenForm && (
                <AddEditOperationModal
                    isOpen={isOpenForm}
                    onClose={() => setIsOpenForm(false)}
                    onSave={onSave}
                    data={data}
                    scenarioId={item.id}
                />
            )}
        </>
    );
};


export default Operations;
