import { useMemo } from 'react';

import { loadDtpLinkStatuses } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { useStoreFromSelector } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import { generateOptions } from '../helper';

const SelectDtpLinkStatus = ({
    multiple = false,
    value = multiple ? [] : null,
    onChange = () => {},
    required = false,
    disabled = false,
    label='Статус связи',
    error = false,
    helperText = '',
    limitTags = 1
}) => {

    const statuses = useStoreFromSelector(loadDtpLinkStatuses, incidentsSelectors.dtpLinkStatuses);
    const statusesOptions = useMemo(() => generateOptions(statuses), [statuses]);

    const valueOptions = useMemo(() => {
        if (!value) return {};
        return multiple
            ? value?.map(key => ({
                id: key,
                name: statuses?.[key] || ''
            }))
            : {
                id: value,
                name: statuses?.[value] || ''
            };
    }, [value, multiple, statuses]);

    const handleChange = (value) => {
        onChange(
            value?.id || null,
            value
        );
    };

    const handleReset = () => {
        onChange(multiple ? [] : null);
    };

    return (
        <CommonAutocomplete
            multiple={multiple}
            selected={valueOptions}
            onChange={handleChange}
            onReset={handleReset}
            required={required}
            disabled={disabled}
            renderLabel={(option) => option?.name || ''}
            label={label}
            error={error}
            helperText={helperText}
            options={statusesOptions}
            limitTags={limitTags}
            isOptionEqualToValue={(option, value) => parseInt(option?.id) === parseInt(value?.id)}
            disableCloseOnSelect={multiple}
            filterSelectedOptions={!multiple}
            showAsSelect
        />
    );
};

export default SelectDtpLinkStatus;
