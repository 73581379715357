import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useClipboard from 'react-use-clipboard';

import { messageTypes, showMessage } from 'redux/Message/actions';
import { deleteVideoCamera } from 'redux/VideoCameras/actions';
import messages from 'helpers/constants/messages';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';
// import VideoPlayer from 'components/common/VideoPlayer';
import FrameModal from 'components/common/FrameVideoModal/FrameModal';

import StatusModal from './StatusModal';
import ModalForm from './ModalForm';
import Info from './Info';

import type { CityCamera, Statuses } from './types';

interface ItemProps {
    item: CityCamera
    statuses: Statuses[]
    reloadList: (isDelete?: boolean) => void
}

const Item = ({ item, statuses, reloadList }: ItemProps) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openFrameModal, setOpenFrameModal] = useState(false);
    // const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
    // const [openRecordPlayer, setOpenRecordPlayer] = useState(false);
    const [isCopied, setCopied] = useClipboard(item.link, {
        successDuration: 6000,
    });

    const onDelete = () => {
        dispatch(deleteVideoCamera(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    const handleCopyToClipboard = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setCopied();
        showMessage(messageTypes.success, 'Текст скопирован в буфер обмена');
    };

    const handleOpenInfo = () => {
        if (Object.keys(item?.external_links || {}).length > 0) {
            setOpen(!open);
        }
    };

    return (
        <>
            <LSContentItem onClick={handleOpenInfo}>
                <LSContentColumn>
                    <CircleStatus title={item.status_text} color={item.status_color} />
                </LSContentColumn>
                <LSContentColumn>{item.name || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{item.address_text || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn isActions>
                    <FormButtonsComponent
                        buttons={[
                            {
                                icon: <i className="fas fa-play-circle"/>,
                                name: 'Просмотр видео',
                                onClick: () => setOpenFrameModal(true),
                                disabled: !item?.link,
                                size: 'small',
                            }
                        ]}
                        noMarginLeft
                        noPadding
                        justButton
                    />
                    <ActionsButtons
                        buttons={[
                            // @ts-ignore
                            ...(( Number(item.type) !== 2 || Number(item.type) !== 3)
                                ? [{
                                    ...buttonsTypes.editIcon,
                                    onClick: () => setOpenEditModal(true),
                                }]
                                :[]
                            ),
                            // @ts-ignore
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                            // @ts-ignore
                            {
                                icon: <i className="far fa-link"/>,
                                name: 'Скопировать ссылку',
                                onClick: handleCopyToClipboard,
                                disabled: !item?.link,
                                size: 'small',
                            },
                            // {
                            //     icon: <i className="fas fa-play-circle"/>,
                            //     name: 'Просмотр записи видео',
                            //     onClick: () => setOpenRecordPlayer(true),
                            //     // disabled: !item?.link,
                            //     size: 'small',
                            // }
                        ]}
                    />
                    {Object.keys(item?.external_links || {}).length > 0 && <ActionMore isOpen={open} />}
                </LSContentColumn>
            </LSContentItem>

            {open && <Info isOpen={open} item={item} />}
            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={onDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isNew={false}
                    statuses={statuses}
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {openStatusModal && (
                <StatusModal
                    isOpen={openStatusModal}
                    onClose={() => setOpenStatusModal(false)}
                    item={item}
                    reloadList={reloadList}
                    statuses={statuses}
                />
            )}

            {openFrameModal
                && <FrameModal
                    isOpen={openFrameModal}
                    onClose={() => setOpenFrameModal(false)}
                    link={item?.link}
                    title={item?.name}
                />
            }
            {/* {openVideoPlayer && (
                <VideoPlayer
                    isOpen={openVideoPlayer}
                    onClose={() => setOpenVideoPlayer(false)}
                    itemId={item.id}
                    // link={item?.stream_url_full}
                    // title={item?.name}
                    // id={item.id}
                />
            )} */}

            {/* {openRecordPlayer && (
                <VideoPlayer
                    isOpen={openRecordPlayer}
                    onClose={() => setOpenRecordPlayer(false)}
                    itemId={item.id}
                    type="record"
                    // link={item?.stream_url_full}
                    // title={item?.name}
                    // id={item.id}
                />
            )} */}
        </>
    );
};

export default Item;
