import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteConnectionPoints } from 'modules/InfrastructureObjects/redux/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';

import type { ConnectionPointItem } from 'types';

import ModalForm from './ModalForm';

interface ItemProps {
    el: ConnectionPointItem;
    reloadList: () => void;
}

const Item = ({ el, reloadList }: ItemProps) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const handleConfirmDelete = () => {
        dispatch(deleteConnectionPoints(el.id, () => {
            reloadList();
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {el.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {el.type || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {el.address_text || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {el.created_at ? getHumanDate(el.created_at) : messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    isNew={false}
                    onClose={() => setOpenEditModal(false)}
                    isOpen={openEditModal}
                    el={el}
                    reloadList={reloadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
