import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadTypicalOperations } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import reactTitles from 'modules/React/utils/helpers/constants';

import Item from './Item';
import ModalForm from './ModalForm';
import Filters from './Filters';
import { OperationItem } from './types';

const TypicalOperations = () => {
    const dispatch = useDispatch();

    const data = useSelector(reactSelectors.typicalOperationsData);
    const meta = useSelector(reactSelectors.typicalOperationsMeta);
    const loadingTypicalOperations: boolean = useSelector(reactSelectors.loadingTypicalOperations);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams({ page: params.page - 1, data: params.data });
        } else {
            dispatch(loadTypicalOperations(params.page, limit, params.data));
        }
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const renderContent = () => {
        return (
            data?.length > 0 ? (
                <LSContainer
                    headers={[
                        { title: reactTitles.OPERATION,  width: '40%' },
                        { title: reactTitles.TIME_ACCORDING_REGULATIONS, width: '40%' },
                        { title: reactTitles.ACTIONS, align: 'right', isActions: true },
                    ]}
                >
                    {data.map((item: OperationItem) => (
                        <Item
                            key={item.id}
                            item={item}
                            reloadList={reloadList}
                        />
                    ))}
                </LSContainer>
            ) : (
                !loadingTypicalOperations && <div>{messages.DATA_IS_NOT_FOUND}</div>
            )
        );
    };

    return (
        <>
            <PageLayout
                header="Типовые операции"
                loading={loadingTypicalOperations}
                filters={<Filters setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total,
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page: number) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit,
                }}
                customStyles={{ padding: 0 }}
            />

            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default TypicalOperations;

