// группировка объектов по типу
const groupObjects = (objectArray, objectTypes) => {
    if (objectTypes && typeof objectTypes === 'object') {
        return Object
            .entries(objectTypes).map(([typeId, name]) => ({
                name,
                data: objectArray?.filter(item => item?.type_id === Number(typeId)),
            }));
    }
    return [];
};

export default groupObjects;
