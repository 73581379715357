import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import FormModal from 'modules/React/Dictionaries/ResponseScenarios/FormModal';
import { deleteResponseScenario } from 'modules/React/redux/actions';

import type { ResponseScenario } from './types';

interface ItemProps {
    item: ResponseScenario;
    onClickItem?: () => void;
    selectedId?: number | null;
    reloadList: (isDelete?: boolean) => void;
}

const Item = ({ item, onClickItem, selectedId, reloadList }: ItemProps) => {
    const dispatch = useDispatch();

    const [isOpenEditModal, setOpenEditModal] = useState(false);
    const [isOpenCopyModal, setOpenCopyModal] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

    const onDelete = () => {
        dispatch(deleteResponseScenario(
            item.id,
            () => { reloadList(true); setOpenDeleteModal(false);}
        ));
    };

    return (
        <>
            <LSContentItem
                onClick={onClickItem} 
                isMobile={!!selectedId}
                needFocus={selectedId === item?.id}
            >
                <LSContentColumn>
                    {item.id || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.is_typical ? 'Да' : 'Нет'}
                </LSContentColumn>
                <LSContentColumn>
                    {item.category?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                                testName: 'edit'
                            },
                            {
                                ...buttonsTypes.copyIcon,
                                onClick: () => setOpenCopyModal(true),
                                testName: 'edit'
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                                testName: 'delete'
                            },
                        ]}
                    />

                    <ActionMore
                        isPassport
                        isOpen={selectedId === item.id} 
                        // onClick={onClickItem} 
                    />
                </LSContentColumn>
            </LSContentItem>
            {isOpenEditModal&& (
                <FormModal
                    isOpen={isOpenEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}
            {isOpenCopyModal && (
                <FormModal
                    isOpen={isOpenCopyModal}
                    onClose={() => setOpenCopyModal(false)}
                    item={item}
                    reloadList={reloadList}
                    isCopy={true}
                />
            )}
            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={onDelete}
                />
            )}
        </>
    );
};

export default Item;
