import { useMemo } from 'react';
import { Bar as GBar } from 'react-chartjs-2';

import { getHumanDate } from 'helpers/date.config';

import styles from './styles.module.scss';

// линейный график
const Graphic = ({
    data = [],
    legendPosition = 'bottom',
}) => {
    const dataG = useMemo(() => {
        const labels = data?.map(({ start_date, end_date }) => `${getHumanDate(start_date)} - ${getHumanDate(end_date)}`);

        const {
            units,
            colors
        } = data
            ?.[0]
            ?.data
            ?.reduce((res, { name, units, color }) => ({
                units: {
                    ...res?.units,
                    [name]: units,
                },
                colors: {
                    ...res?.colors,
                    [name]: color,
                },
            }), { colors: {}, units: {} });

        const datasets = data
            ?.reduce((res, { data }) => {
                const keysValue = data?.reduce((resI, { name, value }) => {
                    return {
                        ...resI,
                        [name]: value
                    };
                }, {});

                Object
                    .keys(keysValue)
                    .forEach((key) => res[key] = [...(res[key] || []), keysValue[key]]);

                return res;
            }, {});

        return {
            labels,
            datasets: Object
                .keys(datasets)
                .map((key) => ({
                    label: key,
                    backgroundColor: colors[key],
                    data: datasets[key],
                    unit: units[key],
                    barPercentage: 0.4,
                }))
        };
    }, [data]);

    const callbacks = {
        label: (context) => ` ${context?.dataset?.label}: ${context?.formattedValue} ${context?.dataset?.unit}`,
    };

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks
            },
            legend: {
                position: legendPosition,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'шт'
                }
            },
        },
    };

    return (
        <div className={styles.card}>
            <h2 className={styles.header}>Сравнительные данные по периодам</h2>
            <div className={styles.data}>
                <GBar
                    options={options}
                    data={dataG}
                    style={{ maxHeight: '400px' }}
                />
            </div>
        </div>
    );

};

export default Graphic;
