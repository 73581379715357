import { useEffect, useRef, useState } from 'react';
import L, { map as MapContainer } from 'leaflet';
import { isEqual } from 'lodash';

import { usePrevious } from 'helpers/hooks';

import MapInterLayer from '../mapInner/MapInterLayer';
import useMapCurrentLayer from '../helpers/useMapCurrentLayer';

import renderChild from './helper/renderChild';

// карта
const Map = ({
    children,
    center,
    zoom,
    maxZoom, // todo подумать - возможно убрать
    onMoveEnd = null,
    onInit = null,
    onClick,
    className = 'map-container',
    onZoom = null,
    bounds = null,
    isHideUnidentifiedPlaces = false,
    ...prop
}) => {
    const defaultZoom = 18;
    const mapCurrentLayer = useMapCurrentLayer();

    const mapCenter = center || [0, 0];
    const mapZoom = zoom || 13;

    const prevCenter = usePrevious(mapCenter);
    const mapRef = useRef(null);
    // блокировка клика
    const isBlockMapEvent = useRef(false);

    // объект карты
    const [map, setMap]= useState(null);

    const handleMoveEnd = () => onMoveEnd(map);
    const handleClick = (e) => {
        // блокируем обрабокту клика при сдвиге маркера
        isBlockMapEvent.current === false && onClick?.(e);
    };

    // отрабатываем события от маркера
    const setIsBlockMapEventTrue = () => {
        isBlockMapEvent.current = true;
    };
    const setIsBlockMapEventFalse = () => setTimeout(() => {
        isBlockMapEvent.current = false;
    }, 200);

    // создаем карту
    useEffect(() => {
        if (map === null && mapRef.current !== null) {
            const newMap = new MapContainer(mapRef.current, {
                center: mapCenter,
                zoom: mapZoom,
                maxZoom: maxZoom || defaultZoom,
                zoomControl: false,
                attributionControl: false
            });

            // закрыть контекстное меню
            newMap.closeContextMenu = () => {
                newMap.fire('context_menu_close');
            };

            // отрабатываем события по маркеру - начало и конец сдвига
            newMap.on('markerMoveStart', setIsBlockMapEventTrue);
            newMap.on('markerMoveEnd', setIsBlockMapEventFalse);

            setMap(newMap);

            onInit && onInit(newMap);

            // zoom control
            // newMap.zoomControl.setPosition('topright').getContainer();
            const zoomControl = new L.control.zoom({
                zoomInTitle: 'Приблизить',
                zoomOutTitle: 'Отдалить',
                position: 'topright',
            });
            zoomControl.addTo(newMap);

            return () => {
                newMap.off('markerMoveStart', setIsBlockMapEventTrue);
                newMap.off('markerMoveEnd', setIsBlockMapEventFalse);
                newMap.remove();
            };
        }
    }, [mapRef]);

    useEffect(() => {
        if (map && mapRef.current && ResizeObserver) {
            // создание подписки события
            const resizeObserver = new ResizeObserver(() => {
                // отправляем событие
                // window.dispatchEvent(new Event('resize'));
                map.invalidateSize?.();
            });
            // подписывание на событие
            resizeObserver.observe(mapRef.current);
            // отписка от события
            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [map, mapRef.current]);

    // выбранный слой
    useEffect(() => {
        if (
            map
            && mapCurrentLayer
        ) {
            // применение tile к карте
            L.tileLayer(mapCurrentLayer?.url, mapCurrentLayer.options || {}).addTo(map);
            // установка максимального зума по tile
            const max = mapCurrentLayer?.options?.maxZoom || 18;
            const min = mapCurrentLayer?.options?.minZoom || 1;

            map.setMinZoom(min);
            map.setMaxZoom(max);
        }
    }, [mapCurrentLayer, map]);

    useEffect(() => {
        if (onClick && map) {
            map.on('click', handleClick);
            return () => {
                map.off('click', handleClick);
            };
        }
    }, [onClick, map]);

    useEffect(() => {
        if (onZoom && map) {
            map.on('zoomend', onZoom);
            return () => {
                map.off('zoomend', onZoom);
            };
        }
    }, [onZoom, map]);

    useEffect(() => {
        if (onMoveEnd && map) {
            map.on('moveend', handleMoveEnd);
            return () => {
                map.off('moveend', handleMoveEnd);
            };
        }
    }, [onMoveEnd, map]);

    useEffect(() => {
        if (map && mapCenter && !isEqual(mapCenter, prevCenter)) {
            map.setView(mapCenter, mapZoom);
        }
    },[mapCenter, mapZoom, map]);

    useEffect(() => {
        if (map && bounds) {
            map.fitBounds(bounds);
        }
    }, [bounds, map]);

    // useEffect(() => {
    //     if (map && mapCenter) {
    //         if (!isEqual(mapCenter, prevCenter)) {
    //             !isEqual(mapZoom, prevZoom)
    //                 ? map.setView(mapCenter, mapZoom)
    //                 : map.setView(mapCenter);
    //         } else if (!isEqual(mapZoom, prevZoom)) {
    //             map.setZoom(mapZoom);
    //         }
    //     }
    // },[mapCenter, mapZoom, map]);

    // useEffect(() => {
    //     if (map && mapZoom  && !isEqual(mapZoom, prevZoom)) {
    //         setTimeout(() => {
    //             map.setZoom(mapZoom);
    //         }, 100);
    //     }
    // },[mapZoom, map]);

    const childs = () => renderChild(
        children,
        {
            map,
            parent: map,
            ...prop
        }
    );

    return (
        <div className={className} ref={mapRef}>
            {map && (
                <>
                    {isHideUnidentifiedPlaces
                        ? childs()
                        : (
                            <MapInterLayer map={map}>
                                {childs()}
                            </MapInterLayer>
                        )
                    }
                </>
            )}
        </div>
    );
};

export default Map;
