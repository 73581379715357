import { loadTrafficLightsStatuses } from 'redux/TrafficLights/actions';
import { trafficLightsSelectors } from 'redux/TrafficLights';
import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import InformationComponent from 'components/common/Information';


const Information = ({ data }) => {

    const statuses = useStoreFromSelector(loadTrafficLightsStatuses, trafficLightsSelectors.lightsStatuses);

    const fields = {
        name: {
            title: 'Название',
        },
        status: {
            title: 'Статус',
            value: ({ status }) => Object.keys(statuses).length === 0
                ? messages.LOADING
                : (
                    <span>
                        {statuses[status] || messages.INFO_IS_NOT_FOUND}
                    </span>
                )
        },
        state: {
            title: 'Состояние',
            value: ({ state }) => state?.full_name || messages.INFO_IS_NOT_FOUND
        },
        control_type: {
            title: 'Тип контроля',
            value: ({ control_type }) => control_type?.full_name || messages.INFO_IS_NOT_FOUND
        },
        desc: {
            title: 'Адрес',
        },
        created_at: {
            title: 'Создано',
            value: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },
        updated_at: {
            title: 'Изменено',
            value: ({ updated_at }) => getFullDateTimeWithTextMonth(updated_at) || messages.INFO_IS_NOT_FOUND
        },
        lat_lon: {
            title: 'Координаты (lat, lon)',
            value: ({ lat, lon }) => `${lat} ${lon}`
        },
    };

    return (
        <InformationComponent
            title={fields}
            data={data}
            returnNull
        />
    );
};

export default Information;
