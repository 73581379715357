import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import { useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import PasswordInput from 'components/common/PasswordInput/PasswordInput';

import IPList from './IPList';
import AdditionalVideoParams from './AdditionalVideoParams';

const EditorForm = ({ isOpen, onClose, onSave, item = {}, title = titles.ADD }) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const { types, complex_types, proto, dir_types, video_types } = useSelector(({ dorisControl }) => dorisControl);

    const [openAdditionalParams, setOpenAdditionalParams] = useState(!!item.data);

    const [data, setData] = useState({
        name: item?.name || '',
        lat: item?.lat || '',
        lon: item?.lon || '',
        type: item?.type || '',
        complex_type: item?.complex_type || '',
        proto: item?.proto || '',
        speed: item?.speed || '',
        direction: item?.direction || '',
        description: item?.description || '',
        dir_type: item?.dir_type || '',
        username: item?.username || '',
        password: item?.password || '',
        video_url: item?.video_url || '',
        video_type: item?.video_type || '',
        data: item?.data || [],
        ips: item?.ips || []
    });

    const disabledSendButton = data.name && data.type && data.complex_type && data.proto && data.video_url;

    const onAcceptModal = () => {
        if (!openAdditionalParams || data.data.length === 0) {
            data.data = null;
        }
        dispatch(onSave(data));
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const onChangeCoordinates = (params) => {
        setData({
            ...data,
            ...params
        });
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [requestStatus.success, onClose, validation]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={title}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: !disabledSendButton
                    }
                ]}
            />}
        >
            <form autoComplete="off" className="modal__form">
                <FormControl required className="block" variant="outlined">
                    <TextField
                        label="Название камеры"
                        size="small"
                        value={data.name}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        required
                        error={validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </FormControl>
                <FormControl className="block" size="small" error={validation.isKey('type')} required variant="outlined">
                    <InputLabel>Тип камеры</InputLabel>
                    <Select
                        value={data.type}
                        required
                        label="Тип камеры"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        name="type"
                    >
                        {Object.keys(types)?.length
                            ? Object.keys(types)?.map((key) =>
                                <MenuItem value={key} key={key}>{types[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                    {validation.type && <FormHelperText error={validation.isKey('type')}>{titles.REQUIRED}</FormHelperText>}
                </FormControl>
                <FormControl  className="block" size="small" error={validation.isKey('complex_type')} required variant="outlined">
                    <InputLabel>Тип комплекса видеофиксации</InputLabel>
                    <Select
                        value={data.complex_type}
                        required
                        label="Тип комплекса видеофиксации"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        name="complex_type"
                    >
                        {Object.keys(complex_types)?.length
                            ? Object.keys(complex_types)?.map((key) =>
                                <MenuItem value={key} key={key}>{complex_types[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                    {validation.complex_type && <FormHelperText error={!!validation.complex_type}>{titles.REQUIRED}</FormHelperText>}
                </FormControl>
                <FormControl  className="block" size="small" error={validation.isKey('proto')} required variant="outlined">
                    <InputLabel>Протокол FTP/API</InputLabel>
                    <Select
                        required
                        value={data.proto}
                        label="Протокол FTP/API"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        name="proto"
                    >
                        {Object.keys(proto)?.length
                            ? Object.keys(proto)?.map((key) =>
                                <MenuItem value={key} key={key}>{proto[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                    {validation.proto && <FormHelperText error={validation.isKey('proto')}>{titles.REQUIRED}</FormHelperText>}
                </FormControl>
                <FormControl className="block" variant="outlined">
                    <TextField
                        label="Ограничение скорости"
                        variant="outlined"
                        size="small"
                        value={data.speed}
                        name="speed"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        error={validation.isKey('speed')}
                        helperText={validation.get('speed')}
                        type="number"
                    />
                </FormControl>
                <FormControl className="block" variant="outlined">
                    <TextField
                        label="Градус обзора камеры"
                        size="small"
                        variant="outlined"
                        value={data.direction}
                        name="direction"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        error={validation.isKey('direction')}
                        helperText={validation.get('direction')}
                        type="number"
                    />
                </FormControl>
                <FormControl className="block" variant="outlined">
                    <TextField
                        label="Комментарий"
                        variant="outlined"
                        size="small"
                        value={data.description}
                        name="description"
                        multiline
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        error={validation.isKey('description')}
                        helperText={validation.get('description')}
                        type="text"
                    />
                </FormControl>
                <FormControl  className="block" size="small" error={validation.isKey('dir_type')} variant="outlined">
                    <InputLabel>{titles.CAMERA_DIRECTION}</InputLabel>
                    <Select
                        value={data.dir_type}
                        label={titles.CAMERA_DIRECTION}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        name="dir_type"
                    >
                        {dir_types?.length > 0
                            ? dir_types?.map((data, index) =>
                                <MenuItem value={index} key={data}>{data}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <FormControl className="block" variant="outlined">
                    <TextField
                        label="Логин"
                        variant="outlined"
                        size="small"
                        value={data.username}
                        name="username"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        error={validation.isKey('username')}
                        helperText={validation.get('username')}
                        type="text"
                    />
                </FormControl>
                <FormControl className="block" variant="outlined">
                    <PasswordInput
                        value={data.password}
                        name="password"
                        label="Пароль"
                        validation={validation.isKey('password')}
                        helperText={validation.get('password')}
                        onChange={(e) => onChange(e.target.name, e.target.value)} 
                    />
                </FormControl>
                <FormControl className="block" variant="outlined">
                    <TextField
                        label="Url для трансляции потокового видео"
                        variant="outlined"
                        size="small"
                        value={data.video_url}
                        name="video_url"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        error={validation.isKey('video_url')}
                        helperText={validation.get('video_url')}
                        type="text"
                    />
                </FormControl>
                <FormControl className="block" size="small" error={validation.isKey('video_type')} variant="outlined">
                    <InputLabel>Тип потокового видео</InputLabel>
                    <Select
                        value={data.video_type}
                        label="Тип потокового видео"
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        name="video_type"
                    >
                        {Object.keys(video_types)?.length
                            ? Object.keys(video_types)?.map((key) =>
                                <MenuItem value={key} key={key}>{video_types[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <FormGroup className="block">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={openAdditionalParams}
                                onChange={() => setOpenAdditionalParams(!openAdditionalParams)}
                                color="primary"
                            />
                        }
                        label="Добавить дополнительные параметры для получения видео"
                    />
                    {openAdditionalParams
                        && <AdditionalVideoParams data={data.data} onChange={(val) => onChange('data', val)} />
                    }
                </FormGroup>
                <LatLonCoordinates
                    lat={data.lat}
                    lon={data.lon}
                    onChange={onChangeCoordinates}
                />

                <IPList ips={data.ips} onChange={(val) => onChange('ips', val)} />
            </form>
        </Modal>
    );
};

export default EditorForm;
