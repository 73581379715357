import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField } from '@mui/material';

import { useValidation } from 'helpers/hooks/index';
import removeEmptyFields from 'helpers/removeEmptyFields';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons/index';
import { createEdge, editEdge } from 'modules/DigitalTwin/redux/actions';

import SelectEdgeTypes from '../EdgeTypes';
import SelectEdgeClasses from '../EdgeClasses';

import FormMap from './FormMap';
import MapModal from './MapModal';

const AddEditEdge = ({
    isOpen = false,
    onClose = () => {},
    item = {},
    reloadList,
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const isNew = !item?.id || false;

    const convertEl = (value) => value ? { id: value, name: value } : null;

    const initialState = {
        type: convertEl(item?.type),
        name: item?.name || '',
        geometry: item?.geometry || {},
        previous_node_id: item?.previous_node_id || '',
        next_node_id: item?.next_node_id || '',
        nodes: item?.nodes || [],
    };

    const initialStateInfo = {
        road_name: item?.info?.road_name || '',
        road_number: item?.info?.road_number || '',
        class: convertEl(item?.info?.class),
    };

    const [fields, setFields] = useState(initialState);
    const [info, setInfo] = useState(initialStateInfo);

    const [loading, setLoading] = useState(false);

    const [isMapEdit, setIsMapEdit] = useState(false);

    const handleReload = () => {
        onClose();
        reloadList();
    };

    const onSubmit = () => {
        const prepareInfo = {
            ...info,
            class: info?.class?.id || '',
        };

        const prepareData = {
            // ...fields,
            type: fields?.type?.id || '',
            name: fields?.name,
            geometry: fields?.geometry,
            previous_node_id: fields?.previous_node_id,
            next_node_id: fields?.next_node_id,
            info: removeEmptyFields(prepareInfo, false),
        };

        const prepareDataApi = removeEmptyFields(prepareData, false);

        isNew
            ? dispatch(createEdge(prepareDataApi, handleReload, setLoading))
            : dispatch(editEdge(item?.id, prepareDataApi, handleReload, setLoading));
    };

    const isDisable = !fields.name
        || !fields.type
        || !fields.next_node_id
        || !fields.previous_node_id
    ;

    const handleChangeField = (key, value) => {
        validation.deleteKey(key);
        setFields(old => ({ ...old, [key]: value }));
    };
    const handleChangeInfo = (key, value) => {
        validation.deleteKey(key);
        setInfo(old => ({ ...old, [key]: value }));
    };

    return <>
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={(isNew ? 'Добавить ' : 'Редактировать ') + 'ребро'}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSubmit,
                            disabled: isDisable,
                            loading,
                        }
                    ]}
                />
            }
            medium
            heightMedium
        >

            <Grid container style={{ height: '100%' }}>
                <Grid item xs={12} sm={6} style={{ paddingRight: '1.5rem' }}>

                    <FormInfoWrapper
                        error={validation.isKey('name') || !fields.name}
                        helperText={validation.get('name')}
                        className="block"
                    >
                        <TextField
                            label="Название"
                            size="small"
                            value={fields.name || ''}
                            variant="outlined"
                            name="name"
                            type="text"
                            onChange={({ target }) => handleChangeField('name', target.value)}
                            className="block"
                            required
                            error={validation.isKey('name') || !fields.name}
                        />
                    </FormInfoWrapper>

                    <SelectEdgeTypes
                        selected={fields.type || null}
                        onChange={(value) => handleChangeField('type', value)}
                        className="block"
                        error={validation.isKey('type') || !fields.type}
                        helperText={validation.get('type')}
                        required
                    />

                    <SelectEdgeClasses
                        selected={info.class || null}
                        onChange={(value) => handleChangeInfo('class', value)}
                        className="block"
                        error={validation.isKey('info.class')}
                        helperText={validation.get('info.class')}
                    />

                    <FormInfoWrapper
                        error={validation.isKey('info.road_name')}
                        helperText={validation.get('info.road_name')}
                        className="block"
                    >
                        <TextField
                            label="Название дороги"
                            size="small"
                            value={info.road_name || ''}
                            variant="outlined"
                            name="road_name"
                            type="text"
                            onChange={({ target }) => handleChangeInfo('road_name', target.value)}
                            className="block"
                        />
                    </FormInfoWrapper>

                    <FormInfoWrapper
                        error={validation.isKey('info.road_number')}
                        helperText={validation.get('info.road_number')}
                        className="block"
                    >
                        <TextField
                            label="Номер дороги"
                            size="small"
                            value={info.road_number || ''}
                            variant="outlined"
                            name="road_number"
                            type="text"
                            onChange={({ target }) => handleChangeInfo('road_number', target.value)}
                            // className="block"
                        />
                    </FormInfoWrapper>

                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <FormMap
                        nodes={fields.nodes || []}
                        geometry={fields.geometry || {}}
                    />

                    {isMapEdit && (
                        <MapModal
                            isOpen={isMapEdit}
                            data={fields}
                            onAccept={(newData) => setFields(old => ({
                                ...old,
                                ...newData,
                            }))}
                            onClose={() => setIsMapEdit(false)}
                        />
                    )}

                    <div
                        className="row"
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...fields?.nodes?.length > 0
                                        ? buttonsTypes.change
                                        : buttonsTypes.select,
                                    onClick: () => setIsMapEdit(true),
                                }
                            ]}
                            beforeTemplate={
                                <div>
                                    {(!fields.previous_node_id || !fields.next_node_id) && (
                                        <span className="error">Выбрать узлы</span>
                                    )}
                                </div>
                            }
                        />
                    </div>

                </Grid>
            </Grid>
        </Modal>
    </>;
};

export default AddEditEdge;
