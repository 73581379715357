import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';

import { loadDtpTypesAll, loadStreetList } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import useStoreFromSelector from 'helpers/hooks/useStoreFromSelector';
import titles from 'helpers/constants/titles';
import { useDebounce } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import { TypeLoad } from 'components/common/Autocomplete/DTP';
import SimpleSelect from 'components/common/SimpleSelect';

import SelectDtpLinkType from '../../forms/SelectDtpLinkType';
import SelectDtpLinkStatus from '../../forms/SelectDtpLinkStatus';

const Filter = ({ data, onChange, onDelete }) => {
    const dispatch = useDispatch();

    const getValue = (key, def = '') => data[key] || def;

    const typesAll = useStoreFromSelector(loadDtpTypesAll, incidentsSelectors.typesAll);
    // const types = useSelector(incidentsSelectors.dtpTypesData);
    const streetList = useSelector(incidentsSelectors.streetList);

    const streetListOptions = streetList.length > 0
        ? streetList.map((item) => ({
            id: item,
            name: item
        }))
        : [{
            id: '',
            name: titles.NO_RESULTS
        }];

    const handleLoadStreetList = (query) => {
        dispatch(loadStreetList(query));
    };
    const loadStreetListDebounce = useDebounce(handleLoadStreetList, 800);

    // убираем дефолтное значение
    const typesList = typesAll
        ?.filter(({ id }) => id && id !== 'default')
        .map(({ id, name }) => ({
            id: id,
            name
        }));

    const handleEvent = (name) => (_, newValue) => {
        onChange({ [name]: newValue });
    };

    const disableOption = (option) => {
        const hasValue = (data.query || []).find(item => item.value === option.value);
        return !!hasValue || !option.id;
    };

    const handleStreet = (e, newValue) => {
        const options = newValue.reduce((res, item) => {
            if (typeof item === 'string') {
                return res;
            }
            return [
                ...res,
                item
            ];
        }, []);

        onChange({
            'street': options
        });
    };

    const handleAddressChange = (e) => {
        const { target: { value } } = e;
        if (value?.length > 3) {
            loadStreetListDebounce(value);
        } else {
            loadStreetListDebounce.clear();
        }
    };

    const handleChange = useCallback((e) => {
        const { name, value } = e.target;
        
        const labels = {
            ids: 'ID ДТП',
            with_dead: 'ДТП с погибшими',
            with_wounded: 'ДТП с ранеными',
            with_vehicle: 'ДТП с транспортом'
        };

        parseInt(value) >= 0
            ? onChange({
                [name]: {
                    id: value,
                    name: labels[name]
                }
            })
            : onDelete(name);
    }, [onChange, onDelete]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="block">
                <TextField
                    label="ID ДТП"
                    value={data.ids?.id || ''}
                    name="ids"
                    type="number"
                    onChange={handleChange}
                />
            </div>

            <div className="block">
                <TextField
                    className="block"
                    label="ДТП с погибшими от"
                    value={data.with_dead?.id || ''}
                    name="with_dead"
                    type="number"
                    onChange={handleChange}
                /> 
            </div>

            <div className="block">
                <TextField
                    label="ДТП с ранеными от"
                    value={data.with_wounded?.id || ''}
                    name="with_wounded"
                    type="number"
                    onChange={handleChange}
                /> 
            </div>

            <div className="block">
                <TextField
                    label="ТС участники ДТП от"
                    value={data.with_vehicle?.id || ''}
                    name="with_vehicle"
                    type="number"
                    onChange={handleChange}
                />
            </div>
           
            <div className="block">
                <SimpleSelect
                    selected={getValue('with_concentration')}
                    options={[
                        { id: '0', name: 'Нет' },
                        { id: '1', name: 'Да' },
                    ]}
                    onChange={(value) => onChange({ with_concentration: value })}
                    label="Связь с МКДТП"
                    renderLabel={(option) => option?.name || ''}
                />
            </div>

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={getValue('dtp_type', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={typesList}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('dtp_type')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.TYPE_EVENTS}
                        />
                    )}
                />
            </div>

            {/* поиск по адресу */}
            <FormInfoWrapper
                helperText={'Введите улицу и выберите нужную из предложенного списка'}
                className={'block'}
            >
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={getValue('street', [])}
                    options={streetListOptions}
                    onChange={handleStreet}
                    filterSelectedOptions={true}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    freeSolo={true}
                    getOptionDisabled={disableOption}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Улица"
                        />
                    )}
                    onInputChange={handleAddressChange}
                />
            </FormInfoWrapper>

            <div className="block">
                <TypeLoad
                    multiple
                    selected={getValue('type_load', [])}
                    onChange={(type_load) => onChange({ type_load })}
                    openOnFocus
                />
            </div>

            <div className="row">
                <DateRange
                    className="row__item"
                    handleDate={(newDate) => onChange({ start_date: newDate })}
                    handleEndDate={(newDate) => onChange({ end_date: newDate })}
                    valueStartDate={getValue('start_date', null) && new Date(getValue('start_date', null))}
                    valueEndDate={getValue('end_date', null) && new Date(getValue('end_date', null))}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                />
            </div>

            <div className="block">
                <SelectDtpLinkType
                    multiple
                    value={data?.link_types?.map(i => i.id) || []}
                    onChange={(_, values) => onChange({ link_types: values })}
                />
            </div>

            <div className="block">
                <SelectDtpLinkStatus
                    multiple
                    value={data?.link_statuses?.map(i => i.id) || []}
                    onChange={(_, values) => onChange({ link_statuses: values })}
                />
            </div>
        </form>
    );
};

export default Filter;
