import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet.marker.slideto';
import { isEqual } from 'lodash';

import { usePrevious } from '../../../../helpers/hooks';
import location from '../icon/location.png';
import { config } from '../../../../config';

import '../helpers/Leaflet.Marker.SetAttribute';
import renderChild from './helper/renderChild';

// иконка маркера
const defaultIcon = L.icon({
    iconUrl: location,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
});

// маркер карты
const Marker = ({
    map,
    parent,
    icon = defaultIcon,
    attribution = null,
    onClick = null,
    children,
    onDragStart = null,
    onDragEnd = null,
    isBlockEventMapOnDrag = false,
    onInit,
    moveToAfterInit = false,
    onMove = null,
    // todo подумать
    // clusterColor = null,
    // clusterSlug = null,
    latlng: latLng = [],
    ...prop
}) => {
    const [mapCenter] = useState(config.get('mapCenter'));
    // на случай если нет координат чтобы не падало
    const latlng = (latLng?.length === 0 || latLng?.includes?.(undefined)) ? mapCenter : latLng;
    const prevLatLng = usePrevious(latlng);
    const prevIcon = usePrevious(icon);
    const prevAttribution = usePrevious(attribution);
    const [marker, setMarker] = useState(null);

    // меркер передвинут
    const onMarkerDrag = ({
        target: {
            _latlng: { lat, lng: lon },
        }
    }) => {
        // отправляем в карту что мактер закончили двигать
        // пока отрабатывает это событие Map.js
        isBlockEventMapOnDrag && map?.fire('markerMoveEnd');
        onDragEnd?.({ lat, lon });
    };

    const onMarkerDragStart = () => {
        // отправляем в карту что мактер начали двигать
        // пока отрабатывает это событие Map.js
        isBlockEventMapOnDrag && map?.fire('markerMoveStart');
        onDragStart?.();
    };

    const onMarkerMove = ({
        target: {
            _latlng: { lat, lng: lon },
        }
    }) => {
        onMove({ lat, lon });
    };

    const handleClick = () => {
        onClick(marker.getLatLng());
    };

    useEffect(() => {
        // конец сдвига
        if (marker && onMarkerDrag) {
            marker.on('dragend', onMarkerDrag);
            return () => {
                marker.off('dragend', onMarkerDrag);
            };
        }
    }, [onMarkerDrag, marker]);

    useEffect(() => {
        // начало сдвига
        if (marker && onMarkerDragStart) {
            marker.on('dragstart', onMarkerDragStart);
            return () => {
                marker.off('dragstart', onMarkerDragStart);
            };
        }
    }, [onMarkerDragStart, marker]);

    useEffect(() => {
        if (marker && onMove) {
            marker.on('move', onMarkerMove);
            return () => {
                marker.off('move', onMarkerMove);
            };
        }
    }, [onMove, marker]);

    const createMarker = () => {
        const newMarker = L
            .marker(latlng, {
                icon,
                attribution,
                draggable: !!onDragEnd
            });
        // todo подумать
        // if (clusterSlug) marker.setClusterSlug(clusterSlug);
        // if (clusterColor) marker.setClusterColor(clusterColor);

        parent?.addLayer(newMarker);
        setMarker(newMarker);

        return newMarker;
    };

    useEffect(() => {
        const newMarker = createMarker();

        onInit && onInit(newMarker);

        // сдвинуть карту к метке после инициализации
        if (moveToAfterInit) {
            const { zoom = 15, center = null } = typeof moveToAfterInit === 'object' ? moveToAfterInit : {};
            map.setView(center || newMarker.getLatLng(), zoom);
        }

        return () => {
            parent?.removeLayer(newMarker);
            newMarker.remove();
            setMarker(null);
        };
    }, [parent]);

    useEffect(() => {
        if (onClick && marker) {
            marker.on('click', handleClick);

            return () => {
                marker.off('click', handleClick);
            };
        }
    }, [onClick, marker]);

    useEffect(() => {
        if (marker && !isEqual(prevLatLng, latlng)) {
            marker.setLatLng(latlng);
        }
    }, [latlng, marker]);

    useEffect(() => {
        if (marker && !isEqual(prevIcon, icon) && icon) {
            marker.setIcon(icon);
        }
    }, [icon, marker]);

    //todo подумать
    // useEffect(() => {
    //     if (marker && clusterColor) {
    //         marker.setClusterColor(clusterColor);
    //         // map.fire('refreshClusters');
    //     }
    // }, [marker, clusterColor]);
    // useEffect(() => {
    //     if (marker && clusterSlug) {
    //         marker.setClusterSlug(clusterSlug);
    //         // map.fire('refreshClusters');
    //     }
    // }, [marker, clusterSlug]);

    useEffect(() => {
        if (marker && !isEqual(prevAttribution, attribution)) {
            marker.setAttribute(attribution);
            if (map) {
                map.fire('refreshClusters');
            }
        }
    }, [attribution, marker]);

    return marker
        ? renderChild(
            children,
            {
                map,
                parent: marker,
                ...prop,
                parentType: 'marker'
            }
        )
        : null;
};

export default Marker;
