import Dashboard from 'modules/Dashboard/Dictionaries/Dashboard';
import StatisticsDtp from 'modules/Dashboard/Dictionaries/StatisticsDtp';
import StatisticsIncidents from 'modules/Dashboard/Dictionaries/StatisticsIncidents';

const dictionariesRoutes = [
    {
        path: '/dashboard',
        component: Dashboard,
        exact: true,
    },
    {
        path: '/statistics/dtp',
        component: StatisticsDtp,
        exact: true,
    },
    {
        path: '/statistics/incidents',
        component: StatisticsIncidents,
        exact: false,
    },
];

export default dictionariesRoutes;
