import { config } from 'config';

const apiUrl = () => config.get('urls').apiRoadNetworkUrl;

// const apiRwUrl = () => config.get('urls').apiRwUrl;
// const apiEfficiencyUrl = () => config.get('urls').apiEfficiencyUrl;

const apiUrls = {

    loadInfrastructureTypes: () => `${apiUrl()}/infrastructure_type`,

    loadInfrastructure: () => `${apiUrl()}/infrastructure`,

    createInfrastructure: () => `${apiUrl()}/infrastructure`,

    editInfrastructure: (id) => `${apiUrl()}/infrastructure/${id}`,

    deleteInfrastructure: (id) => `${apiUrl()}/infrastructure/${id}`,

    infrastructureSubscription: (id) => `${apiUrl()}/infrastructure/${id}/subscribe`,

    createInfrastructureTypes: () => `${apiUrl()}/infrastructure_type/`,

    editInfrastructureTypes: (id) => `${apiUrl()}/infrastructure_type/${id}`,

    deleteInfrastructureTypes: (id) => `${apiUrl()}/infrastructure_type/${id}`,

    getRoadSectionTypes: () => `${apiUrl()}/road-section/types`,

    getRoadSectionCategories: () => `${apiUrl()}/road-section/categories`,

    getRoadSections: () => `${apiUrl()}/road-section`,

    createRoadSection: () => `${apiUrl()}/road-section`,

    editRoadSection: (id) => `${apiUrl()}/road-section/${id}`,

    deleteRoadSection: (id) => `${apiUrl()}/road-section/${id}`,

    loadPolygonRoadSection: () => `${apiUrl()}/road-section/polygon`,

    loadSideBarRoadSection: () => `${apiUrl()}/road-section`,

    loadStatusesRoadSection: () => `${apiUrl()}/road-section/statuses`,

    loadRoadSectionRelationTypes: () => `${apiUrl()}/road-section-relation/types`,

    loadRoadSectionRelation: () => `${apiUrl()}/road-section-relation`,

    createRoadSectionRelation: () => `${apiUrl()}/road-section-relation`,

    createMultipleRoadSectionRelation: (road_section_id) => `${apiUrl()}/road-section-relation/${road_section_id}`,

    deleteRoadSectionRelation: (id) => `${apiUrl()}/road-section-relation/${id}`,

    loadRoadSectionByRadius: () => `${apiUrl()}/road-section/radius`,

    loadInfrastructureByRadius: () => `${apiUrl()}/infrastructure/radius`,

    loadOverlapPolygon: () => `${apiUrl()}/overlap/polygon`,

    loadOverlapList: () => `${apiUrl()}/overlap`,

    loadPolygonInfrastructure: () => `${apiUrl()}/infrastructure/polygon`,

    loadInfrastructureSidebar: () => `${apiUrl()}/infrastructure`,

    loadOverlapTypes: () => `${apiUrl()}/overlap/types`,

    loadOverlapSource: () => `${apiUrl()}/overlap/source`,

    loadStreetList: () => `${apiUrl()}/overlap/streets`,

    loadCategoryTypes: () => `${apiUrl()}/road-section/category/types`,

    // Словари для городских видеокамер
    loadVideoCamerasDictionaryInfrastructureTypes: () => `${apiUrl()}/video-cameras-dictionaries/infrastructure-types`,

    loadVideoCamerasDictionaryCommutator: () => `${apiUrl()}/video-cameras-dictionaries/commutator`,

    loadVideoCamerasDictionaryConnectionLine: () => `${apiUrl()}/video-cameras-dictionaries/connection-line`,

    loadVideoCamerasDictionaryConnection: () => `${apiUrl()}/video-cameras-dictionaries/connection`,

    loadVideoCamerasDictionaryElectricalOptionalSystem: () => `${apiUrl()}/video-cameras-dictionaries/electrical-optional-system`,

    loadVideoCamerasDictionaryDocument: () => `${apiUrl()}/video-cameras-dictionaries/document`,

    loadVideoCamerasDictionaryPower: () => `${apiUrl()}/video-cameras-dictionaries/power`,

    loadVideoCamerasDictionaryReliance: () => `${apiUrl()}/video-cameras-dictionaries/reliance`,

    loadCommutators: () => `${apiUrl()}/commutators/`,

    createCommutator: () => `${apiUrl()}/commutators/`,

    editCommutator: (id) => `${apiUrl()}/commutators/${id}`,

    deleteCommutator: (id) => `${apiUrl()}/commutators/${id}`,

    loadCommutatorTypes: () => `${apiUrl()}/commutators/types`,

    getConnectionLines: () => `${apiUrl()}/entities/connection-line/`,

    getConnectionLineById: (id) => `${apiUrl()}/entities/connection-line/${id}`,

    createConnectionLine: () => `${apiUrl()}/entities/connection-line/`,

    editConnectionLine: (id) => `${apiUrl()}/entities/connection-line/${id}`,

    deleteConnectionLine: (id) => `${apiUrl()}/entities/connection-line/${id}`,

    loadCameras: () => `${apiUrl()}/entities/cameras`,

    createCamera: () => `${apiUrl()}/entities/cameras`,

    editCamera: (id) => `${apiUrl()}/entities/cameras/${id}`,

    deleteCamera: (id) => `${apiUrl()}/entities/cameras/${id}`,

    loadCameraTypes: () => `${apiUrl()}/entities/cameras/types`,

    // Модель оборудования Камера
    loadCameraModel: () => `${apiUrl()}/entities/cameras/model`,

    createCameraModel: () => `${apiUrl()}/entities/cameras/model`,

    editCameraModel: (id) => `${apiUrl()}/entities/cameras/model/${id}`,

    deleteCameraModel: (id) => `${apiUrl()}/entities/cameras/model/${id}`,

    // Вендор оборудования Камера
    loadCameraVendor: () => `${apiUrl()}/entities/cameras/vendor`,

    createCameraVendor: () => `${apiUrl()}/entities/cameras/vendor`,

    editCameraVendor: (id) => `${apiUrl()}/entities/cameras/vendor/${id}`,

    deleteCameraVendor: (id) => `${apiUrl()}/entities/cameras/vendor/${id}`,

    loadConnectionPoints: () => `${apiUrl()}/entities/connection-point`,

    createConnectionPoint: () => `${apiUrl()}/entities/connection-point/`,

    editConnectionPoint: (id) => `${apiUrl()}/entities/connection-point/${id}`,

    deleteConnectionPoint: (id) => `${apiUrl()}/entities/connection-point/${id}`,

    loadConnectionPointTypes: () => `${apiUrl()}/entities/connection-point/types`,

    getPillars: () => `${apiUrl()}/entities/pillar`,

    getPillarById: (id) => `${apiUrl()}/entities/pillar/${id}`,

    createPillar: () => `${apiUrl()}/entities/pillar`,

    editPillar: (id) => `${apiUrl()}/entities/pillar/${id}`,

    deletePillar: (id) => `${apiUrl()}/entities/pillar/${id}`,

    getElectricalOpticalSystems: () => `${apiUrl()}/entities/electrical-optical-systems`,

    createElectricalOpticalSystems: () => `${apiUrl()}/entities/electrical-optical-systems`,

    editElectricalOpticalSystems: (id) => `${apiUrl()}/entities/electrical-optical-systems/${id}`,

    deleteElectricalOpticalSystems: (id) => `${apiUrl()}/entities/electrical-optical-systems/${id}`,

    getPakVendors: () => `${apiUrl()}/entities/pak/vendor`,

    createPakVendor: () => `${apiUrl()}/entities/pak/vendor`,

    editPakVendor: (id) => `${apiUrl()}/entities/pak/vendor/${id}`,

    deletePakVendor: (id) => `${apiUrl()}/entities/pak/vendor/${id}`,

    getPakModels: () => `${apiUrl()}/entities/pak/model`,

    createPakModel: () => `${apiUrl()}/entities/pak/model`,

    editPakModel: (id) => `${apiUrl()}/entities/pak/model/${id}`,

    deletePakModel: (id) => `${apiUrl()}/entities/pak/model/${id}`,

    getPAKList: () => `${apiUrl()}/entities/pak`,

    getPAKById: (id) => `${apiUrl()}/entities/pak/${id}`,

    createPAK: () => `${apiUrl()}/entities/pak`,

    editPAK: (id) => `${apiUrl()}/entities/pak/${id}`,

    deletePAK: (id) => `${apiUrl()}/entities/pak/${id}`,

    getDVR: () => `${apiUrl()}/entities/dvr`,

    getDVRById: (id) => `${apiUrl()}/entities/dvr/${id}`,

    createDVR: () => `${apiUrl()}/entities/dvr/`,

    editDVR: (id) => `${apiUrl()}/entities/dvr/${id}`,

    deleteDVR: (id) => `${apiUrl()}/entities/dvr/${id}`,

    getInfrastructureByPolygon: () => `${apiUrl()}/infrastructure/data/polygon`,

    getInfrastructure: () => `${apiUrl()}/infrastructure/data`,
};

export default apiUrls;
