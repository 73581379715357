import { useState } from 'react';

import titles from 'helpers/constants/titles';
import { getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import StatusesSelect from 'components/common/Autocomplete/Statuses';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';

const Filter = ({
    tab,
    onChange,
    statuses,
}) => {

    const initialState = {
        start_date: getStartOf('minutes', getDateWithDuration({ day: -1 })),
        end_date: getEndOf('minutes'),
        status: [], // Статус (инцидента) из п.1 множественное не обязательное
        types: [], // Тип (инцидента) из п.2 множественное не обязательное
    };

    const [filter, setFilter] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleOnChange = (filter) => {
        onChange(filter);
    };

    const updateFilter = (filters) => { // фильтры с бэка
        const newFilters = {
            ...initialState,
            ...filters,
        };
        setFilter(newFilters);
        handleOnChange(newFilters);
    };

    const handleChange = (key) => (value) => {
        setFilter(old => ({ ...old, [key]: value }));
    };

    const resetFilter = (needRefresh) => {
        setFilter(initialState);
        if (needRefresh) handleOnChange(initialState);
    };

    const errorDateRange = () => {
        const {
            start_date = null,
            end_date = null,
        } = filter;

        return !start_date || !end_date || start_date >= end_date;
    };

    const handleChangeFilter = () => {
        handleOnChange(filter);
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={handleChangeFilter}
            filter={filter}
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            countException={['start_date', 'end_date']}
            setUserFilter={updateFilter}
            disabled={isDisabled || errorDateRange()}
            tab={tab}
        >
            <LayoutFilter.Visible>

                <DateRange
                    handleDate={handleChange('start_date')}
                    handleEndDate={handleChange('end_date')}
                    valueStartDate={filter?.start_date || null}
                    valueEndDate={filter?.end_date || null}
                    // dateOnly
                    isDisabledHandler={(value) => setIsDisabled(value)}
                    renderError={errorDateRange}
                    errorMessage={() => titles.SELECT_PERIOD}
                />

                <StatusesSelect
                    multiple
                    selected={filter?.status}
                    options={statuses}
                    renderLabel={(option) => option?.name || ''}
                    onChange={handleChange('status')}
                    filterSelectedOptions
                    isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                />

                <SelectIncidentTypes
                    multiple
                    selected={filter?.types}
                    onChange={handleChange('types')}
                    label="Тип"
                />

            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
