import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

import { adminSelectors } from 'redux/Admin';
import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import { fullDateTime } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import prepareCoords from 'helpers/prepareCoords';
import messages from 'helpers/constants/messages';

import TypeValue from '../TabInfo/TypeValue';

const TabItem = ({ item, statuses, loadTypes, users }) => {

    const loading = useSelector(adminSelectors.loading);

    const [isOpenInfo, setIsOpenInfo] = useState(false);
    
    const user = users?.find(el => el.id === Number(item.user_id));

    const { new_values, old_values } = item;

    const renderFields = (data) => {
        return <div style={{ paddingLeft: 10 }}>
            {Object.keys(data).length > 0
                ? <>
                    {data.hasOwnProperty('manual_edit')
                        && <div>
                            <strong>Ручное редактирование: </strong>
                            <span>{data.manual_edit ? 'Да' : 'Нет'}</span>
                        </div>
                    }
                    {data.address_text
                        && <div>
                            <strong>{titles.ADDRESS}: </strong>
                            <span>{statuses.address_text}</span>
                        </div>
                    }
                    {data.dtp_type
                        && <div>
                            <strong>{titles.DTP_TYPE}: </strong>
                            <TypeValue id={data.dtp_type} />
                        </div>
                    }
                    {data.lat
                        && <div>
                            <strong>{titles.LAT}: </strong>
                            <span>{prepareCoords(data.lat, 6)}</span>
                        </div>
                    }
                    {data.lon
                        && <div>
                            <strong>{titles.LON}: </strong>
                            <span>{prepareCoords(data.lon, 6)}</span>
                        </div>
                    }
                    {(data.km || data.km === 0)
                        && <div>
                            <strong>{titles.DTP_KM}: </strong>
                            <span>{data.km}</span>
                        </div>
                    }
                    {(data.m || data.m === 0)
                        && <div>
                            <strong>{titles.DTP_M}: </strong>
                            <span>{data.m}</span>
                        </div>
                    }
                    {(data.dead || data.dead === 0)
                        && <div>
                            <strong>{titles.COUNT_DTP_DEAD}: </strong>
                            <span>{data.dead}</span>
                        </div>
                    }
                    {(data.count_ts || data.count_ts === 0)
                        && <div>
                            <strong>{titles.COUNT_DTP_TS}: </strong>
                            <span>{data.count_ts}</span>
                        </div>
                    }
                    {data.status
                        && <div>
                            <strong>{titles.STATUS}: </strong>
                            <span>{loadTypes[data.status]}</span>
                        </div>
                    }
                    {(data.count_members || data.count_members === 0)
                        && <div>
                            <strong>{titles.COUNT_DTP_MEMBERS}: </strong>
                            <span>{data.count_members}</span>
                        </div>
                    }
                    {data.type_load
                        && <div>
                            <strong>{titles.DTP_DATA_SOURCE}: </strong>
                            <span>{loadTypes[data.type_load]}</span>
                        </div>
                    }
                </>
                : <span>{messages.NO_DATA}</span>
            }
        </div>;
    };
    

    return (
        <>
            <LSContentItem onClick={() => setIsOpenInfo(prev => !prev)} >
                <LSContentColumn>
                    {item?.created_at ? fullDateTime(item?.created_at) : messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {loading ? <CircularProgress size={14} /> : user?.first_name ? `${user?.first_name} ${user?.last_name}` : messages.NO_VALUE}
                </LSContentColumn>

                <ActionMore isOpen={isOpenInfo} />

            </LSContentItem>
            {isOpenInfo
                && <div style={{ paddingLeft: 25 }}>
                    <h2>Новые значения</h2>
                    {renderFields(new_values)}
                    <h2>Старые значения</h2>
                    {renderFields(old_values)}
                </div>
            }
        </>
    );
};

export default TabItem;