import type { Dispatch, SetStateAction } from 'react';
import { useRef } from 'react';
import { TextField } from '@mui/material';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectKeywords from 'modules/React/utils/components/SelectKeywords';
import SelectCategories from 'modules/React/utils/components/SelectCategories';
import SelectIncidentFactors from 'modules/React/utils/components/SelectIncidentFactors';

import type { FilterFields } from './types';

interface FilterProps {
    filter: FilterFields;
    setFilter: Dispatch<SetStateAction<FilterFields>>;
    onSearch: (filters: FilterFields) => void;
}

const Filter = ({ filter, setFilter, onSearch }: FilterProps) => {
    const initialFilter = useRef(filter);

    const handleChange = (name: string, value: any) => {
        setFilter(prev => ({ ...prev, [name]: value }));
    };

    const resetFilter = (needRefresh: boolean) => {
        setFilter(initialFilter.current);

        if (needRefresh) {
            onSearch(initialFilter.current);
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onSearch={onSearch}
            onResetFilter={resetFilter}
            setUserFilter={() => {}}
            isFilterVisible
            isHideSaveBtn
            countException={['factors_list', 'keyword_list', 'category']}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filter.name || ''}
                    label={'Наименование сценария'}
                    onChange={(e) => handleChange('name', e.target.value)}
                />
                <SelectIncidentFactors
                    multiple
                    selected={filter.factors_list || []}
                    onChange={(value: any) => handleChange('factors_list', value)}
                />
                <SelectKeywords
                    multiple
                    selected={filter.keyword_list || []}
                    onChange={(value: any) => handleChange('keyword_list', value)}
                />
                <SelectCategories
                    selected={filter.category || null}
                    onChange={(value: any) => handleChange('category', value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;