import { editSettingsGroup, editSettingsList, loadSettingsGroup } from 'redux/TrafficFlow/actions';
import Settings from 'components/common/Settings';

const PUIDSettings = () => {
    return (
        <Settings
            title="Настройки"
            storeProp="trafficFlow"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsListAction={editSettingsList}
            editSettingsGroupAction={editSettingsGroup}
        />
    );
};

export default PUIDSettings;
