import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { loadBoardRotationMessages } from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Loading from 'components/common/Loading';
import { signsList } from 'components/pages/Dictionaries/Boards/MessageHelpers/signsList';
import MapScroll from 'components/MapComponents/MapScroll';

import { EventList } from './EventList';


const Info = ({
    data,
    innerComponent,
}) => {

    const dispatch = useDispatch();

    // todo данные в формате { key: object } нужно { key: array }
    const messageRotationObject = useSelector(boardsSelectors.boardRotationMessages);
    const loadingMessages = useSelector(boardsSelectors.loadingBoardRotationMessages);
    const messageRotation = useMemo(() => messageRotationObject[data?.id] || [], [data?.id, messageRotationObject]);

    const count = 20;

    const [limit, setLimit] = useState(count);

    const displayLoad = debounce(() => setLimit(limit + count), 100);
    const handleUpdate = () => {
        if (limit < messageRotation?.length) displayLoad();
    };

    useEffect(() => {
        if (data?.id && !messageRotationObject[data?.id]) {
            dispatch(loadBoardRotationMessages(data.id));
        }
    },[data?.id]);

    const RenderList = () => (
        <>
            {/* сообщения */}
            {messageRotation?.length > 0 && loadingMessages === false ? (
                <div className="info__item" style={{ alignItems: 'start' }}>
                    <div className="info__item-title" style={{ padding: '1.5rem 0' }}>
                        {titles.MESSAGES}
                        {': '}
                    </div>
                    <div className="info__item-content">
                        <List>
                            {messageRotation?.slice(0, limit)?.map(({ id, text }, index) => {
                                return (
                                    <ListItem key={id} disableGutters>
                                        <ListItemIcon style={{ minWidth: '30px' }}>
                                            {index + 1}
                                        </ListItemIcon>
                                        <ListItemText>
                                            { text?.length > 0
                                                ? text?.map((txt, index) => (
                                                    <span key={index}>
                                                        {txt.sign && (
                                                            <img
                                                                style={{ width:'30px', height:'30px', verticalAlign: 'middle' }}
                                                                src={signsList[txt?.sign]?.img}
                                                                alt={txt?.sign}
                                                            />
                                                        )}
                                                        {txt?.text}{' '}
                                                    </span>
                                                ))
                                                : <span>{messages.NO_DATA}</span>
                                            }
                                        </ListItemText>
                                    </ListItem>
                                );
                            })}
                        </List>

                        {limit < messageRotation?.length && <Loading />}
                    </div>
                </div>
            ) : null}

            {loadingMessages && <Loading className="absolute bottom fill"/>}
        </>
    );

    return (
        <MapScroll
            onUpdate={handleUpdate}
        >
            {innerComponent}
            <RenderList/>

            {data.category_id === 2 && <EventList id={data.id}/>}
        </MapScroll>
    );
};

export default Info;
