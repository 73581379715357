import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar } from '@mui/material';

import { loadDtpById, loadedDtpById } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import tabsList from 'components/MapComponents/Layers/Incidents/PopUp/tabsList';
import Members from 'components/MapComponents/Layers/Incidents/PopUp/TabComponents/TabMembers';
import Vehicles from 'components/MapComponents/Layers/Incidents/PopUp/TabComponents/TabVehicles';
import Information from 'components/MapComponents/Layers/Incidents/PopUp/TabComponents/TabInfo';
import Situation from 'components/MapComponents/Layers/Incidents/PopUp/TabComponents/TabSituation';
import { CustomTab, CustomTabs } from 'components/common/Tabs';
import NoData from 'components/common/Information/NoData';
import Loading from 'components/common/Loading';

// import type { IncidentItem } from '../../types';

interface EventProps {
    dtp_id?: number;
}

const DTP = ({ dtp_id }: EventProps) => {
    const dispatch = useDispatch();
    const dtpData = useSelector(incidentsSelectors.dtpById);
    const loading = useSelector(incidentsSelectors.loading);

    const [tabIndex, setTabIndex] = useState(tabsList[0].key);

    useEffect(() => {
        if (dtp_id) {
            dispatch(loadDtpById(dtp_id));
        }

        return () => {
            dispatch(loadedDtpById({}));
        };
    }, [dispatch, dtp_id]);

    return (
        <div>
            {loading && <Loading linear={true} />}
            {Object.keys(dtpData).length > 0
                ? <>
                    <AppBar position="relative" color="default" >
                        <CustomTabs
                            onChange={(_, tab) => {
                                setTabIndex(tab);
                            }}
                            textColor="primary"
                            value={tabIndex}
                            variant="scrollable"
                        >
                            {tabsList.map(({ key, title }) => (
                                <CustomTab
                                    key={key}
                                    id={`scrollable-force-tab-${key}`}
                                    aria-controls={`scrollable-force-tabpanel-${key}`}
                                    value={key}
                                    label={title}
                                />
                            ))}
                        </CustomTabs>
                    </AppBar>

                    <div
                        role="tabpanel"
                        id={`scrollable-force-tabpanel-${tabIndex}`}
                        aria-labelledby={`scrollable-force-tab-${tabIndex}`}
                        className="tab__container"
                    >
                        {tabsList.map(({ key }) => (
                            tabIndex === key && (
                                <div
                                    style={{ height: '100%' }}
                                    key={key}
                                >
                                    {key === 'members'
                                        ? <Members
                                            data={dtpData?.members || []}
                                            tag="strong"
                                        />
                                        : key === 'vehicles'
                                            ? <Vehicles
                                                data={dtpData?.vehicles || []}
                                                tag="strong"
                                            />
                                            : key === 'situation'
                                                ? <Situation data={dtpData} />
                                                : <Information
                                                    readOnly
                                                    data={dtpData}
                                                />
                                    }
                                </div>
                            )
                        ))}
                    </div>
                </>
                : (!loading && <NoData />)
            }
        </div>
    );
};

export default DTP;
