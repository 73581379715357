import { memo, useMemo } from 'react';

import {
    Cluster,
    FeatureGroup,
    Map,
    MapControl,
    Marker,
    ToolTip,
} from 'components/MapComponents/leaflet';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';
import { config as rootConfig } from 'config';

import {
    eputsTypes,
    getColor,
    getItemData,
    objectTypes,
    typesRuOne,
} from '../objectHelper';

import MapComponents from './MapComponents';
import MapBind from './MapBind';

const MapFromModal = ({
    data, // выбранные объекты
    allObjects, // все объекты по типу без выбранных
    currentType, // текущий тип
    type, // выбранный тип
    currentLatLon,
    onAddItem,
    onDeleteItem,
}) => {

    const groupProp = useMemo(() => {
        switch (currentType) {
            case objectTypes.node:
            case objectTypes.edge:
                return {
                    group: 'eputs_type',
                    value: 'eputs_id',
                    object: 'eputs',
                };
            case eputsTypes.infrastructure:
            case eputsTypes.roadNetwork:
                return {
                    group: 'object_type',
                    value: 'object_id',
                    object: 'object',
                };
            default:
                return null;
        }
    }, [currentType]);

    const groupedData = useMemo(() => {
        if (groupProp) {
            return data.reduce((res, item) => {
                const groupKey = item[groupProp.group];
                const ob = getItemData(item[groupProp.object]);
                res[groupKey] = [
                    ...(res[groupKey] || []),
                    {
                        ...ob,
                        delId: item.id
                    }
                ];
                return res;
            }, {});
        }
        return {};
    }, [data, groupProp]);

    const mapCenter = rootConfig.get('mapCenter');

    return (
        <>
            <div style={{ minWidth: '60%', minHeight: '400px', height: '100%', marginRight: 20 }}>
                <Map
                    center={mapCenter}
                >
                    <MapControl>
                        <FeatureGroup>

                            <MapBind
                                data={data}
                                type={currentType}
                                currentLatLon={currentLatLon}
                            />

                            {/* не выбранные объекты */}
                            <Cluster
                                iconCreateFunction={iconCreateFunctionSimple}
                                maxClusterRadius={40}
                            >
                                <MapComponents
                                    data={allObjects}
                                    color={getColor(false)}
                                    type={type}
                                    // add
                                    onClick={({ id })=> onAddItem(id)}
                                />
                            </Cluster>

                            {/* кластер объектов */}
                            <Cluster
                                iconCreateFunction={iconCreateFunctionSimple}
                                maxClusterRadius={40}
                            >
                                {Object.keys(groupedData).map(key => (
                                    <MapComponents
                                        key={key}
                                        data={groupedData[key]}
                                        color={getColor(true)}
                                        type={key}
                                        onClick={({ delId }) => onDeleteItem(delId)}
                                    />
                                ))}
                            </Cluster>

                            {currentLatLon
                                ? <Marker latlng={currentLatLon}>
                                    <ToolTip
                                        offset={[0, -7]}
                                        direction="top"
                                    >
                                        <div>
                                            <b>{typesRuOne[currentType]}</b>
                                        </div>
                                    </ToolTip>
                                </Marker>
                                : null
                            }

                        </FeatureGroup>
                    </MapControl>
                </Map>
            </div>
        </>
    );
};

export default memo(MapFromModal);
