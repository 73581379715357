import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import ItemInfo from './ItemInfo';
import ModalAddEdit from './ModalAddEdit';

const Item = ({
    item,
    reloadList,
    actions,
    loading,
    storeName,
    company = {}
}) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const onDelete = () => dispatch(actions.deleteBrigades(item?.id, () => {reloadList(true); setOpenDeleteModal(false);}));

    const openItemInfo = () => {
        setIsOpenInfo(!isOpenInfo);
    };

    return (
        <>
            <LSContentItem onClick={openItemInfo}>
                <LSContentColumn>
                    {typeof item?.number === 'number' ? item?.number : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                                disabled: item.id === 0
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                                disabled: item.id === 0
                            }
                        ]}
                    />
                    <ActionMore
                        isOpen={isOpenInfo}
                        // onClick={openItemInfo}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpenInfo && <ItemInfo item={item} isOpen={isOpenInfo} company={company} />}

            {openEditModal
                && <ModalAddEdit
                    onClose={() => setOpenEditModal(false)}
                    isOpen={openEditModal}
                    item={item}
                    reloadList={reloadList}
                    loading={loading}
                    storeName={storeName}
                    actions={actions}
                />
            }
            {openDeleteModal
                && <ConfirmDelete
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={onDelete}
                    open={openDeleteModal}
                />
            }
        </>
    );
};

export default Item;
