import { config } from '../../config';

const apiBoardsUrl = () => config.get('urls').apiBoardsUrl;
const getMicroservicePath = (microservice) => config.getMicroservicePath(microservice);

const apiUrls = {
    getBoardStatus: () => `${apiBoardsUrl()}/v2/board/statuses`,

    getBoardTypes: () => `${apiBoardsUrl()}/board/types`,

    getBoards: () => `${apiBoardsUrl()}/board`,

    createBoard: () => `${apiBoardsUrl()}/board`,

    editBoard: (id) => `${apiBoardsUrl()}/board/${id}`,

    deleteBoard: (id) => `${apiBoardsUrl()}/board/${id}`,

    getMessagePriorities: () => `${apiBoardsUrl()}/message/priorities`,

    getMessages: () => `${apiBoardsUrl()}/message`,

    createMessage: () => `${apiBoardsUrl()}/message/`,

    editMessage: (id) => `${apiBoardsUrl()}/message/${id}`,

    deleteMessage: (id) => `${apiBoardsUrl()}/message/${id}`,

    getBoardColors: () => `${apiBoardsUrl()}/board/send/colors`,

    getSidebar: () => `${apiBoardsUrl()}/board`,

    getPolygon: () => `${apiBoardsUrl()}/board/polygon`,

    fetchMessagePreview: (id) => `${apiBoardsUrl()}/message/${id}/preview`,

    fetchMessageJSONPreview: (id) => `${apiBoardsUrl()}/board/${id}/message/preview`,

    fetchActiveBoardMessage: (id) => `${apiBoardsUrl()}/board/${id}/message/active`,

    getFontsList: () => `${apiBoardsUrl()}/board/fonts`,

    getFontsListForTypeWithImg: () => `${apiBoardsUrl()}/board/send/fonts`,

    getSignsList: () => `${apiBoardsUrl()}/board/signs`,

    getBoardRotationMessages: (id) => `${apiBoardsUrl()}/board/${id}/message/rotation`,
    
    getBoardEvents: () => `${apiBoardsUrl()}/board/events`,

    getMessageTemplates: () => `${apiBoardsUrl()}/message/template`,

    createMessageTemplate: () => `${apiBoardsUrl()}/message/template`,

    editMessageTemplate: (id) => `${apiBoardsUrl()}/message/template/${id}`,

    deleteMessageTemplate: (id) => `${apiBoardsUrl()}/message/template/${id}`,

    createMessageTemplateBoards: () => `${apiBoardsUrl()}/message/template/boards`,

    getDitLink: (microservice) => `${getMicroservicePath(microservice)}/dit_link`,

    createDitLink: (microservice) => `${getMicroservicePath(microservice)}/dit_link/multiple_update`,

    listTemplateImage: () => `${apiBoardsUrl()}/template/image`,

    createTemplateImage: () => `${apiBoardsUrl()}/template/image`,

    editTemplateImage: (id) => `${apiBoardsUrl()}/template/image/${id}`,

    deleteTemplateImage: (id) => `${apiBoardsUrl()}/template/image/${id}`,

    getAlertTemplatesFields: () => `${apiBoardsUrl()}/alert/fields`,

    getAlertTemplatesStatuses: () => `${apiBoardsUrl()}/alert/status`,

    getAlertTemplatesTypes: () => `${apiBoardsUrl()}/alert/type`,

    getAlertTemplates: () => `${apiBoardsUrl()}/alert/template`,

    createAlertTemplate: () => `${apiBoardsUrl()}/alert/template`,

    editAlertTemplate: (id) => `${apiBoardsUrl()}/alert/template/${id}`,

    deleteAlertTemplate: (id) => `${apiBoardsUrl()}/alert/template/${id}`,

    getAlignHorizontal: () => `${apiBoardsUrl()}/board/align/horizontal`,

    getAlignVertical: () => `${apiBoardsUrl()}/board/align/vertical`,

    loadShowcase: () => `${apiBoardsUrl()}/showcase`,

    getBoardCategories: () => `${apiBoardsUrl()}/board/categories`,

};

export default apiUrls;
