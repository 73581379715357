import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadEmployeesTransportationReport } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import DateRange from 'components/common/Dates/DateRange';
import SelectCompany from 'components/common/Autocomplete/Companies';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import formatDate from 'helpers/constants/dateFormat';
import { getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';

const Filter = ({ setParams, filters }) => {
    const dispatch = useDispatch();

    const employeesTransportationFilter = useSelector(transportPassengerSelectors.employeesTransportationFilter);

    const initialState = {
        selectedStartDate: employeesTransportationFilter.selectedStartDate || getStartOf('day', getDateWithDuration({ month: -1 })),
        selectedEndDate: employeesTransportationFilter.selectedEndDate || getEndOf('day'),
        selectedCompany: employeesTransportationFilter.selectedCompany || {}
    };

    const [filterValues, setFilterValues] = useState(initialState);

    const resetFilter = (needRefresh) => {
        setFilterValues(initialState);
        if (needRefresh) {
            setParams({});
            dispatch(loadEmployeesTransportationReport({ page: 1, limit: 25, ...filters({ ...initialState, selectedCompany: {} }) }));
        }
    };

    const handleChange = (name, value) => {
        setFilterValues({
            ...filterValues,
            [name]: value
        });
        setParams({
            ...filterValues,
            [name]: value
        });
    };

    const onSearch = (filter = filterValues) => {
        dispatch(loadEmployeesTransportationReport({ page: 1, limit: 25, ...filters(filter) }));
    };

    const updateFilter = (filters) => {
        if (Object.keys(filters).length > 0) {
            setFilterValues({ // обновляем локальный стейт
                ...initialState,
                ...filters,
            });

            if (filters.selectedStartDate
                && filters.selectedEndDate
                && filters.selectedCompany)
                onSearch({
                    ...initialState,
                    ...filters,
                });
        }
    };

    const isDisabled = !filterValues.selectedStartDate
        || !filterValues.selectedEndDate
        || !Object.keys(filterValues.selectedCompany).length;

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={onSearch}
            filter={filterValues}
            setUserFilter={updateFilter}
            disabled={isDisabled}
            isFilterVisible
        >
            <LayoutFilter.Visible>
                <SelectCompany
                    selected={filterValues.selectedCompany}
                    onChange={(value) => handleChange('selectedCompany', value ?? {})}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                    required
                    error={!Object.keys(filterValues.selectedCompany).length}
                />
                <DateRange
                    handleDate={(value) => handleChange('selectedStartDate', value)}
                    handleEndDate={(value) => handleChange('selectedEndDate', value)}
                    valueStartDate={filterValues.selectedStartDate}
                    valueEndDate={filterValues.selectedEndDate}
                    dateOnly={true}
                    pickersFormat={formatDate.DATE_FOR_PICKER}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
