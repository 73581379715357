import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteMaterial } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import RenderDuploCodesItem from 'components/common/DorisControl/RenderDuploCodesItem';
import { buttonsTypes } from 'components/common/FormButtons';
import ImagePreview from 'components/common/ImagePreview';

import ModalForm from './ModalForm';

import type { DuploCodesItem } from '../DuploCodes/types';
import type { ItemType } from './types';
import type { IPermissions } from 'types';

interface ItemProps {
    item: ItemType;
    reloadList: () => void;
    permissions: IPermissions;
    test_id_prefix: string;
}

const Item = ({ item, reloadList, permissions, test_id_prefix = '' }: ItemProps) => {
    const dispatch = useDispatch();
    const  loadingButton  = useSelector(dorisControlSelectors.loadingButton);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        const callback = () => {
            setIsDelete(false);
            reloadList();
        };

        dispatch(deleteMaterial(item.id, callback));
    };

    return (
        <>
            <LSContentItem style={{ alignItems: 'flex-start' }}>
                <LSContentColumn>
                    {item.duplo_codes_models.map((item: DuploCodesItem) => (
                        <RenderDuploCodesItem
                            key={item.id}
                            item={item}
                        />
                    ))}
                </LSContentColumn>

                <LSContentColumn>
                    {item.complex_types_text.map((el, i) => <div key={i}>{el}</div>)}
                </LSContentColumn>

                <LSContentColumn>
                    <ImagePreview
                        style={{ width: '40px', height: '40px' }}
                        src={item?.image}
                    />
                </LSContentColumn>

                <LSContentColumn>
                    {item.with_video ? 'Да' : 'Нет'}
                </LSContentColumn>

                <LSContentColumn isActions style={{ top: '25px' }}>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                                disabled: !permissions.is_update
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                                disabled: !permissions.is_delete
                            }
                        ]}
                        test_id_prefix={test_id_prefix}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={()=> setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    loadingButton={loadingButton}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                    test_id_prefix={test_id_prefix}
                />
            )}
        </>
    );
};

export default Item;
