import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

const usePopModalListener = (listener: any = null) => {

    // данные для модалки
    const [modal, setModal] = useState(null);

    // обновление
    useEffect(() => {
        if (!!listener && !!modal && !isEqual(modal, listener)) {
            setModal(listener);
        }
    }, [listener, modal]);

    return {
        // модалка открыта
        isOpen: !!modal,
        // данные модалки
        modal,
        // установить данные
        setModal,
        // очистить данные
        clear: () => setModal(null),
    };
};

export default usePopModalListener;
