import { useEffect, useMemo } from 'react';
import L from 'leaflet';
import 'leaflet.heat';

// тепловой слой
const HeatLayer = ({
    json = [],
    radius = 25,
    max = 12,
    minOpacity = 0.2,
    maxZoom = 16,
    gradient = null,
    parent,
    latGetProp = (m) => m.geometry.coordinates[1],
    lonGetProp = (m) => m.geometry.coordinates[0],
    intensityGetProp = (m) => m.properties.intensity,
}) => {
    /*[
        {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [77.75659494, 12.96821717]
            },
            properties: {
                intensity: 3,
                description: "описание"
            }
        },
        {}
    ]*/

    const config = useMemo(() => {

        const configObj = {
            radius,
            max,
            minOpacity,
            maxZoom,
            gradient,
        };

        Object.keys(configObj)
            .reduce((res, key) => {
                if (configObj[key]) {
                    return {
                        ...res,
                        [key]: configObj[key]
                    };
                }
                return res;
            }, {});

    }, [radius, max, minOpacity, maxZoom, gradient]);

    useEffect(() => {
        if (parent && json?.length > 0) {
            const data = json.reduce((res, item) => ([
                ...res,
                [
                    latGetProp(item),
                    lonGetProp(item),
                    intensityGetProp(item)
                ]
            ]), []);

            const layer = L.heatLayer(data, config).addTo(parent);

            return () => {
                parent.removeLayer(layer);
                layer.remove();
            };
        }
    }, [parent, config, json]);

    return null;
};

export default HeatLayer;
