import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import { loadBoardEvents } from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import { fullDateTime } from 'helpers/date.config';

type EventListProps = {
  id: number;
}

export const EventList = ({ id }: EventListProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadBoardEvents(id));
    },[id]);

    const messageRotationObject = useSelector(boardsSelectors.boardEvents)[id];

    return <Virtuoso
        data={messageRotationObject}
        style={{ height: '500px', width: '100%' }}
        itemContent={(_, item) => <table className="table-information">
            <tbody>
                <tr>
                    <th>Данные от {fullDateTime(item.created_at)}</th>
                    <th></th>
                </tr>
                {
                    item.data.map((item: any) =>
                        <tr>
                            <td>
                                {item.name}
                            </td>
                            <td>
                                <div>{item.value} {item.unit}</div>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
        }
    />;
};
