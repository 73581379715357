import { useSelector } from 'react-redux';

import { roadNetworksSelectors } from 'redux/RoadNetwork';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Information from 'components/common/Information';

import { createIcon, getColor } from '../helper';


const PopUp = ({ uuid, item = null }) => {
    // забираем данные из полигона
    const polygon = useSelector(roadNetworksSelectors.infrastructureList);
    const data = item
        || polygon.find(({ id }) => uuid === id)
        || {};
    const { address_text, name } = data;

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: getColor(data) }}>
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: createIcon('#fff') }}
                    />
                    <span>{name || address_text || messages.NO_DATA}</span>
                </div>
            </div>
            <div className="map-popup_body">
                <div className="scroll" style={{ height: 'auto', maxHeight: '300px' }}>
                    <Information
                        data={data}
                        title={{
                            name: {
                                title: titles.NAME,
                            },
                            type: {
                                title: titles.OBJECT_TYPE,
                                value: ({ type_text }) => type_text || messages.INFO_IS_NOT_FOUND
                            },
                            address: {
                                title: titles.ADDRESS,
                                value: ({ address_text }) => address_text || messages.INFO_IS_NOT_FOUND
                            },
                            latlon: {
                                title: titles.COORDINATES,
                                value: ({ lat, lon }) => lat && lon ? `${lat} / ${lon}` : null
                            },
                            organization: {
                                title: titles.ORGANIZATION,
                                value: ({ organization }) => organization?.title
                            },
                        }}
                    />
                </div>
            </div>

        </div>
    );
};

export default PopUp;