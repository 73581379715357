import React, { useState } from 'react';
import { FormControl, TextField, InputLabel, Select, MenuItem } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import type { Filters, Statuses } from './types';

interface FilterProps {
    statuses: Statuses[]
    setParams:  React.Dispatch<React.SetStateAction<{page: number, data: {}}>>
}

const Filter = ({ statuses, setParams }: FilterProps) => {

    const initData = {
        name: '',
        status: ''
    };

    const [filterData, setFilterData] = useState(initData);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFilterData({
            ...filterData,
            [name]: value
        });
    };

    const onSearch = (filters = filterData) => {
        setParams(prev => ({ ...prev, page: 1, data: removeEmptyFields(filters) }));
    };

    const onReset = (needRefresh: boolean) => {
        setFilterData(initData);

        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
    };

    const updateFilter = (filters: Filters) => {
        setFilterData({
            ...initData,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            onSearch({
                ...initData,
                ...filters,
            });
        }
    };

    const isDisabled = filterData.name.trim().length === 0 && !filterData.status;

    return(
        <LayoutFilter
            onResetFilter={onReset} 
            onSearch={onSearch} 
            filter={filterData} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={filterData.name}
                    name="name"
                    onChange={onChange}
                    onKeyPress={e => e.key === 'Enter' && onSearch()}
                />
                <FormControl size="small" variant="outlined">
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={filterData.status}
                        onChange={(e) => setFilterData({ ...filterData, status: e.target.value })}
                        label={titles.STATUS}
                    >
                        {statuses.map((item: Statuses) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
