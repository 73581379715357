import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setMapLoading } from 'redux/Map/actions';
import { showMessage } from 'redux/Message/actions';
import messageTypes from 'redux/Message/messagetypes';
import { setValidation } from 'redux/Validation/actions';
import messages from 'helpers/constants/messages';

import * as actions from './actions';
import * as types from './types';
import api from './api.methods';


function* loadCountByMonthsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getCountByMonths, payload);
    if (response?.success) {
        yield fnCallback?.(response.data || {});
    }
    yield fnLoading?.(false);
}

function* loadCountByPeriodsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getCountByPeriods, payload);
    if (response?.success) {
        yield fnCallback?.(response.data || {});
    }
    yield fnLoading?.(false);
}

function* loadHeatMapDistrictsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.loadHeatMapDistricts, payload);
    if (response?.success) {
        yield fnCallback?.(response?.data);
    }
    yield fnLoading?.(false);
}

function* loadDtpTypesAllSaga() {
    const response = yield call(api.getDtpTypesAll);
    if (response?.success) {
        yield put(actions.loadedDtpTypesAll(response.data || []));
    }
}

function* loadDtpTypesSaga({ payload }) {
    yield put(actions.loadingDtpTypes(true));
    const response = yield call(api.getDtpTypes, payload);
    if (response) {
        yield put(actions.loadedDtpTypes(response));
    }
    yield put(actions.loadingDtpTypes(false));
}

function* editDtpTypeSaga({ payload }) {
    const response = yield call(api.editDtpType, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(actions.loadDtpTypes());
    }
}

function* loadDtpSaga({ payload }) {
    const { page, limit, data, replaceStore } = payload;
    const downloadFormat = data?.report || false;
    const config = downloadFormat
        ? { params: { ...data } }
        : { params: { page, limit, ...data } };

    yield put(actions.loading(true));
    const response = yield call(api.getDtpList, config);

    if (downloadFormat) {
        yield put(showMessage(messageTypes.success, response?.data?.join('')));
    }

    else if (response?.success) {
        yield put(actions.loadedDtpList(response, replaceStore));
    }

    yield put(actions.loading(false));
}

function* createNewDtpSaga({ payload }) {
    const { data, callback } = payload;
    yield put(actions.loading(true));
    const response = yield call(api.createNewDtp, data);
    if (response?.success) {
        yield put(actions.saveDtp(response.success));
        callback && callback(response?.data?.id || null);
        yield put(showMessage(messageTypes.success, 'Данные сохранены'));
    }
    yield put(actions.loading(false));
}

function* editStatusesSaga({ payload, callback }) {
    yield put(actions.loadingEditStatus(true));
    const response = yield call(api.editStatuses, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Данные сохранены'));
        callback();
    }
    yield put(actions.loadingEditStatus(false));
}

function* loadDtpStreetsSaga({ payload }) {
    yield put(actions.loadingDtpStreets(true));
    const response = yield call(api.getDtpStreets, payload);
    if (response?.success) {
        yield put(actions.loadedDtpStreets(response));
    }
    yield put(actions.loadingDtpStreets(false));
}

function* editDtpSaga({ payload }) {
    const { id, data, callback } = payload;

    yield put(actions.loading(true));

    const response = yield call(api.editDtp, id, data);

    if (response?.success) {
        yield put(actions.saveDtp(response.success));
        callback && callback();
        yield put(showMessage(messageTypes.success, 'Данные сохранены'));
    }

    yield put(actions.loading(false));
}

function* loadDtpByIdSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    yield put(actions.loading(true));
    const response = yield call(api.getDtpById, payload);
    if (response?.success) {
        yield fnCallback
            ? fnCallback(response?.data || {})
            : put(actions.loadedDtpById(response?.data || {}));
    }
    yield put(actions.loading(false));
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_COUNT_BY_MONTH, loadCountByMonthsSaga),
        takeLatest(types.LOAD_COUNT_BY_PERIODS, loadCountByPeriodsSaga),
        takeLatest(types.LOAD_HEAT_MAP_DISTRICTS, loadHeatMapDistrictsSaga),

        takeLatest(types.LOAD_DTP_TYPES_ALL, loadDtpTypesAllSaga),
        takeLatest(types.LOAD_DTP_TYPES, loadDtpTypesSaga),
        takeLatest(types.LOAD_DTP_LIST, loadDtpSaga),
        takeLatest(types.EDIT_DTP_TYPE, editDtpTypeSaga),
        takeLatest(types.CREATE_NEW_DTP, createNewDtpSaga),
        takeLatest(types.EDIT_DTP_STATUSES, editStatusesSaga),
        takeLatest(types.LOAD_DTP_STREETS, loadDtpStreetsSaga),
        takeLatest(types.EDIT_DTP, editDtpSaga),
        takeLatest(types.LOAD_DTP_BY_ID, loadDtpByIdSaga),
    ]);
}
