import React from 'react';
import useClipboard from 'react-use-clipboard';
import {
    Checkbox,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
} from '@mui/material';

import messagetypes from 'redux/Message/messagetypes';
import { showMessage } from 'redux/Message/actions';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import titles from 'helpers/constants/titles';

import type { Notification } from '../../types';

interface NotificationItemProps {
    item: Notification;
    onClickItem: (value: Notification) => void;
    onClickItemRead: (id: number) => void;
}

const NotificationItem = ({ item, onClickItem, onClickItemRead }: NotificationItemProps) => {
    const {
        id,
        createdAt,
        viewed = false,
        // microservice = '',
        microservice_text = '',
        // всплывающее сообщение
        showAlert,
    } = item;

    const {
        title = '', // заголовок
        message = '', // сообщение (+ html)
    } = showAlert;

    const [isCopied, setCopied] = useClipboard(title || message, {
        successDuration: 6000,
    });

    const handleClickItem = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClickItem(item);
    };

    const handleClickItemRead = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onClickItemRead(id);
    };

    const handleCopyToClipboard = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setCopied();
        showMessage(messagetypes.success, 'Текст скопирован в буфер обмена');
    };

    return (
        <>
            <ListItem>
                <ListItemIcon style={{ minWidth: 'auto' }}>
                    <Tooltip title={viewed ? titles.VIEWED : titles.SET_VIEWED}>
                        <Checkbox
                            checked={viewed}
                            onClick={handleClickItemRead}
                        />
                    </Tooltip>
                </ListItemIcon>

                <ListItemText>
                    <div style={{ fontSize: 12, fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
                        {createdAt && fullDateTimeWithoutSeconds(createdAt)}
                    </div>

                    <Tooltip title={item.link ? 'Перейти' : ''}>
                        <ListItemButton onClick={handleClickItem} style={{ padding: 0 }}>
                            {title || message}
                        </ListItemButton>
                    </Tooltip>

                    <div style={{ fontSize: 14, fontWeight: 500, color: 'rgba(0, 0, 0, 0.54)' }}>
                        {microservice_text}
                    </div>
                </ListItemText>

                <ListItemSecondaryAction>
                    <Tooltip title="Скопировать текст">
                        <IconButton
                            onClick={handleCopyToClipboard}
                            style={{ fontSize: 20, padding: 8 }}
                        >
                            {isCopied
                                ? <i className="far fa-check" />
                                : <i className="far fa-copy" />
                            }
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>

            <Divider component="li" />
        </>
    );
};

export default NotificationItem;
