import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteTypicalOperation } from 'redux/SituationalPlans/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import ModalForm from './ModalForm';

const Item = ({ item, reloadList }) => {
    const dispatch = useDispatch();
    const id = item?.id;

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(deleteTypicalOperation(id, () => {
            reloadList(true);
            setIsDelete(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item?.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsEditMode(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isEditMode
              && <ModalForm
                  isOpen={isEditMode}
                  onClose={setIsEditMode}
                  item={item}
                  isNew={false}
                  reloadList={reloadList}
              />
            }
            {isDelete
              && <ConfirmDelete
                  open={isDelete}
                  onClose={() => setIsDelete(false)}
                  onSuccess={handleDelete}
                  message={messages.CONFIRM_DELETE}
              />
            }
        </>
    );
};

export default Item;
