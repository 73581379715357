import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';

import messages from 'helpers/constants/messages';
import { diffString } from 'helpers/diffString';

import CompareDtpButton from './CompareDtpButton';

const CompareListTable = ({
    dtp, // основное дтп
    dtpLinked, // связанное дтп
    title = {}, // список для отображения
    defaultValue = messages.NO_DATA,
    currentDtpId, // id текущего дтп
    onRefresh,
    isShowHeaderButton = false,
}) => {

    const getValue = (dtp, key, valueFn, index) => valueFn?.(dtp, index) || dtp?.[key] || defaultValue;

    const checkCurrentDtp = (dtpId) => currentDtpId === dtpId
        ? <Tooltip title="Текущее ДТП"><i className="far fa-check" style={{ marginRight: '.5rem' }}></i></Tooltip>
        : null;

    const rendedHeader = (dtpId, index) => currentDtpId === dtpId
        ? <CompareDtpButton index={index} sourceDtp={dtp} compareDtp={dtpLinked} onRefresh={onRefresh} />
        : <CompareDtpButton index={index} sourceDtp={dtpLinked} compareDtp={dtp} onRefresh={onRefresh} />;

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Параметры</b></TableCell>
                            <TableCell>
                                {checkCurrentDtp(dtp.id)}
                                <b>Основное ДТП</b>
                                {isShowHeaderButton && rendedHeader(dtp.id, 1)}
                            </TableCell>
                            <TableCell>
                                {checkCurrentDtp(dtpLinked.id)}
                                <b>Связанное ДТП</b>
                                {isShowHeaderButton && rendedHeader(dtpLinked.id, 2)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {Object.entries(title).map(([ key, values ]) => {

                            const {
                                title,
                                value,
                                buttons,
                            } = values;

                            const dtpV = getValue(dtp, key, value, 1).toString();
                            const linkedDtpV = getValue(dtpLinked, key, value, 2).toString();

                            const { str1, str2 } = buttons
                                ? {
                                    str1: buttons?.(1, dtp, dtpLinked),
                                    str2: buttons?.(2, dtpLinked, dtp)
                                }
                                : diffString(dtpV, linkedDtpV);

                            return (
                                <TableRow key={key}>
                                    <TableCell component="th" scope="row">
                                        <b>{title}</b>
                                    </TableCell>
                                    <TableCell>{str1}</TableCell>
                                    <TableCell>{str2}</TableCell>
                                </TableRow>
                            );
                        })}

                    </TableBody>
                </Table>
            </TableContainer>

        </>
    );
};

export default CompareListTable;
