import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import titles from 'helpers/constants/titles';
import { fullDateTime } from 'helpers/date.config';
import renderAddress from 'helpers/renderAddress';
import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Information from 'components/common/Information';
import { loadIncidentById } from 'modules/React/redux/actions';
import Modal from 'components/common/Modal';
import { RenderPassportTabs } from 'modules/Dtp/Reports/Incidents/RenderPassportTabs';
import { AddModal } from 'modules/React/Dictionaries/Incidents/AddModal/Index';

import numberValue from '../../numberValue';

import TypeValue from './TypeValue';
import ChangeStatusForm from './ChangeStatusForm';


const TabInfo = ({ data, readOnly }) => {
    const dispatch = useDispatch();
    const [openIncidentInfoModal, setOpenIncidentInfoModal] = useState(false);
    const [openIncidentModal, setOpenIncidentModal] = useState(false);
    const [incident, setIncident] = useState({});

    useEffect(() => {
        if (data.react_id) {
            dispatch(loadIncidentById(data.react_id, (incidentData) => setIncident(incidentData)));
        }
    }, [data.react_id, dispatch]);

    const newIncident = {
        dtp_id: data.id,
        name: `Дтп - ${data?.created_at}`,
        type_id: data?.dtp_type,
        description: data.description,
        lat: data.lat,
        lon: data.lon,
        geometry: {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    data.lon,
                    data.lat,
                ],
            }
        }
    };

    return (
        <>
            <Information
                data={data}
                defaultValue={messages.INFO_IS_NOT_FOUND}
                defaultValueClass="info__item"
                returnNull
                title={{
                    status_text: {
                        title: titles.STATUS,
                        value: ({ status_text }) => (
                            <>
                                {status_text}
                                &nbsp;
                                {readOnly === false && (
                                    <ChangeStatusForm
                                        dataId={data?.id}
                                        dataStatus={data?.status?.toString()}
                                    />
                                )}
                            </>
                        )
                    },
                    emtp_number: {
                        title: titles.DTP_NUMBER
                    },
                    dtp_type: {
                        title: titles.DTP_TYPE,
                        value: ({ dtp_type }) => <TypeValue id={dtp_type} />
                    },
                    manual_edit: {
                        title: 'Ручное редактирование',
                        value: ({ manual_edit }) => manual_edit ? 'Да' : 'Нет'
                    },
                    dtp_at: {
                        title: titles.DATE_TIME_DTP,
                        value: ({ dtp_at }) => fullDateTime(dtp_at)
                    },
                    lat: {
                        title: titles.LAT,
                        value: ({ lat }) => prepareCoords(lat, 6)
                    },
                    lon: {
                        title: titles.LON,
                        value: ({ lon }) => prepareCoords(lon, 6)
                    },
                    address: {
                        title: titles.ADDRESS,
                        value: ({ address, dtp }) => dtp?.address_text || Object.keys(address || {}).length > 0
                            ? dtp?.address_text || renderAddress(address)
                            : messages.INFO_IS_NOT_FOUND
                    },
                    intersection: {
                        title: titles.INTERSECTION_STREET,
                    },
                    km: {
                        title: titles.DTP_KM,
                        value: ({ km }) => numberValue(km)
                    },
                    m: {
                        title: titles.DTP_M,
                        value: ({ m }) => numberValue(m)
                    },
                    description: {
                        title: titles.DTP_DESCRIPTION
                    },
                    schema_img_url: {
                        title: titles.SCHEMA_DTP,
                        value: ({ schema_img_url }) =>
                            schema_img_url && schema_img_url.length > 0 ? (
                                <img
                                    alt="img"
                                    style={{
                                        height: 'auto',
                                        width: '200px',
                                        maxWidth: '100%',
                                        padding: '10px 0'
                                    }}
                                    src={schema_img_url}
                                />
                            ) : (
                                messages.INFO_IS_NOT_FOUND
                            )
                    },
                    type_load_text: {
                        title: titles.DTP_DATA_SOURCE
                    },
                    react_id: {
                        title: titles.RELATIVE_EVENT,
                        value: ({ react_id }) => {
                            return <FormButtons
                                buttons={react_id ? [
                                    {
                                        name: 'Просмотреть инцидент',
                                        color: 'primary',
                                        variant: 'contained',
                                        onClick: () => setOpenIncidentInfoModal(true),
                                    }
                                ] : [
                                    {
                                        name: 'Создать инцидент',
                                        color: 'primary',
                                        variant: 'contained',
                                        onClick: () => setOpenIncidentModal(true),
                                    },
                                ]}
                            />;
                        },
                        modal: true,
                    },
                    count_members: {
                        title: titles.COUNT_DTP_MEMBERS,
                        value: ({ count_members }) => numberValue(count_members)
                    },
                    dead: {
                        title: titles.COUNT_DTP_DEAD,
                        value: ({ dead }) => numberValue(dead)
                    },
                    wounded: {
                        title: titles.COUNT_DTP_WOUNDED,
                        value: ({ wounded }) => numberValue(wounded)
                    },
                    dead_child: {
                        title: titles.COUNT_DTP_DEAD_KIDS,
                        value: ({ dead_child }) => numberValue(dead_child)
                    },
                    wounded_child: {
                        title: titles.COUNT_DTP_WOUNDED_KIDS,
                        value: ({ wounded_child }) => numberValue(wounded_child)
                    },
                    count_ts: {
                        title: titles.COUNT_DTP_TS,
                        value: ({ count_ts }) => numberValue(count_ts)
                    },
                }}
            />

            {openIncidentInfoModal && (
                <Modal
                    isOpen={openIncidentInfoModal}
                    onClose={() => setOpenIncidentInfoModal(false)}
                    noPadding
                    title={incident?.name}
                    fullWidth
                    showCloseInTitle
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenIncidentInfoModal(false)
                            },
                        ]}
                    />}
                >
                    <RenderPassportTabs
                        selectedItem={incident}
                        passportOnly
                        reloadList={incident}
                    />
                </Modal>
            )}

            {openIncidentModal && (
                <AddModal
                    isOpen={openIncidentModal}
                    onClose={() => setOpenIncidentModal(false)}
                    isNew={true}
                    reloadList={()=> {}}
                    item={newIncident}
                />
            )}

        </>
    );
};

export default TabInfo;
