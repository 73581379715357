import DragAndDrop from 'components/common/DragAndDrop';

import Item from './Item';

const Settings = ({
    items,
    setItems,
}) => {

    const handleDelete = (index) => () => {
        setItems([
            ...items.slice(0, index),
            ...items.slice(index + 1)
        ]);
    };

    const handleReorder = (list) => {
        const reorder = list.map((el, i) => ({
            ...el,
            sort: i + 1,
        }));

        setItems(reorder);
    };

    const listRender = (item, index) => (
        <Item
            item={item}
            onDelete={handleDelete(index)}
        />
    );

    return (
        <DragAndDrop
            list={items}
            itemsRender={listRender}
            onChange={handleReorder}
        />
    );
};

export default Settings;
