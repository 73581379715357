import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import type { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';

const useStyles = makeStyles({
    wrapper: {
        height: '37px',
        lineHeight: '1.4375em',
    },
    input: {
        padding: '8.5px 14px',
        paddingRight: '35px !important',
        maxHeight: '40px',
    },
    focused: {
        maxHeight: 'none',
        backgroundColor: '#fff',
        zIndex: 5,
    },
    label: {
        zIndex: 6,
    },
});

interface MultipleInputProps
    extends Omit<
        AutocompleteProps<any, true, false, true>,
        'value' | 'onChange' | 'options' | 'renderInput'
    > {
    value: string[];
    onChange(value: string[]): void;
    addOnSearch?(value: string): void; // если данные в поле должны добавиться только после нажатия Найти, и не меняться по blur
    label?: string;
    error?: boolean;
    isBlurSelect?: boolean;
    needClearInput?: boolean;
    testIdPrefix?: string;
    testIdName?: string;
    testIdAction?: string;
    type?: 'text' | 'number';
}

function MultipleInput({
    value,
    onChange,
    label = '',
    error,
    addOnSearch,
    isBlurSelect = true,
    limitTags = 2,
    needClearInput,
    testIdPrefix = '',
    testIdName = '',
    testIdAction = 'filter',
    type = 'text',
    ...props
}: MultipleInputProps) {
    const styles = useStyles();

    const [inputValue, setInputValue] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const str = e.target.value.slice(0, -1); // убираем последний символ на случай если это запятая
        const hasValue = value.some((item) => item === str);
        const data = (e.nativeEvent as InputEvent).data;

        if (type === 'number' && data?.replace(/[^0-9]/, '') === '' && data !== ',') return;

        if (data === ',' && hasValue) {
            // если такое значение уже есть сбрасываем
            return setInputValue('');
        }

        setInputValue(e.target.value);

        if (data === ',') {
            setInputValue('');
            if (str.length > 0) onChange([...value, str]);
        }

        if (addOnSearch) {
            addOnSearch(e.target.value);
        }
    };

    useEffect(() => {
        if (needClearInput) setInputValue('');
    }, [needClearInput]);

    const autoComChange: AutocompleteProps<string, true, false, true>['onChange'] = (
        e,
        newValue,
        reason
    ) => {

        if (reason === 'clear') {
            setInputValue('');
        }

        if ('key' in e && e.key === 'Enter') return;

        onChange(newValue);
    };

    const onBlurSelect = () => {
        if (isBlurSelect) {
            setInputValue('');

            const hasValue = value.some((item) => item === inputValue);

            if (inputValue.trim().length > 0 && !hasValue) {
                onChange([...value, inputValue]);
            }
        }
    };

    return (
        <Autocomplete
            {...props}
            limitTags={limitTags}
            multiple
            value={value}
            options={[]}
            filterSelectedOptions={true}
            freeSolo={true}
            onChange={autoComChange}
            onBlur={onBlurSelect}
            onReset={() => onChange([])}
            size="small"
            fullWidth
            inputValue={inputValue}
            renderInput={(params) => (
                <TextField
                    classes={{ root: styles.wrapper }}
                    {...params}
                    label={label}
                    error={error}
                    onChange={handleChange}
                    InputProps={{
                        ...params.InputProps,
                        classes: { root: styles.input, focused: styles.focused },
                        ...{ 'data-testid': `${testIdPrefix}:${testIdAction}/${testIdName}/input`, }
                    }}
                    InputLabelProps={{
                        classes: { focused: styles.label },
                        ...{ 'data-testid': `${testIdPrefix}:${testIdAction}/${testIdName}/label` },
                    }}
                />
            )}
        />
    );
}

export default MultipleInput;
