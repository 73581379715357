import React, { useMemo, useState } from 'react';

import dateFormat from 'helpers/constants/dateFormat';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf
} from 'helpers/date.config';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import SelectCameras from 'components/common/Autocomplete/CameraVideoanalytics';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { useValidation } from 'helpers/hooks';

import { prepareData } from '../helpers';

import SelectCheckboxes from './SelectCheckboxes';
import SelectClass from './SelectClass';
import SelectColor from './SelectColor';
import SelectViolation from './SelectViolation';
import SelectVehicleBrand from './SelectVehicleBrand';
import SelectVehicleModel from './SelectVehicleModel';

import type { Camera, FilterState, Params } from '../types';

interface FiltersProps {
    setParams: React.Dispatch<React.SetStateAction<Params>>;
}

const initialCheckboxes = [
    {
        name: 'Показать авто, у которых есть марка и модель',
        key: 'with_brand_and_model',
        default: true
    },
    {
        name: 'Показать авто, у которых есть цвет',
        key: 'with_color',
        default: true
    },
];

const Filter = ({ setParams }: FiltersProps) => {

    const initialState: FilterState = useMemo(() => ({
        start_date: fullDateTimeWithTimeZone(getStartOf('day', getDateWithDuration({ day: -30 }))),
        end_date: fullDateTimeWithTimeZone(getEndOf('day')),
        vehicle_brand: '',
        vehicle_model: '',
        violation_types: [],
        without_violations: [],
        grz_list: [],
        class_name: '',
        vehicle_color: '',
        checkboxes: initialCheckboxes,
        cameras: [],
    }), []);

    const [filter, setFilter] = useState(initialState);

    const validation = useValidation();

    const handleChange = (name: keyof FilterState, value: any) => {
        setFilter({ ...filter, [name]: value });
    };

    const send = () => {
        setParams({ page: 1, data: prepareData(filter) });
    };

    const resetFilters = (needRefresh: boolean) => {
        setFilter(initialState);

        if (needRefresh) {
            setParams({
                page: 1,
                data: prepareData(initialState),
            });
        }
    };

    const updateFilter = (filters: FilterState) => {
        setFilter({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ page: 1, data: prepareData({ ...filter, ...filters }) });
        }
    };

    return (
        <LayoutFilter
            filter={filter}
            onSearch={send}
            onResetFilter={resetFilters}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    views={['day', 'hours', 'minutes', 'seconds']}
                    pickersFormat={dateFormat.DATE_WITH_HOUR_MIN_SECONDS}
                    valueStartDate={filter.start_date}
                    valueEndDate={filter.end_date}
                    handleDate={(date) => handleChange('start_date', date)}
                    handleEndDate={(date) => handleChange('end_date', date)}
                    renderError={() => validation.get('start_date') || validation.get('end_date')}
                    errorMessage={() => validation.get('start_date') || validation.get('end_date')}
                />

                <SelectCameras
                    multiple
                    selected={filter.cameras as never[]}
                    onChange={(value: Camera[]) => handleChange('cameras', value)}
                />
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <SelectViolation
                    selected={filter.violation_types}
                    onChange={(value) => handleChange('violation_types', value)}
                    label="Тип событий"
                />

                <SelectViolation
                    selected={filter.without_violations}
                    onChange={(value) => handleChange('without_violations', value)}
                    label="Исключить тип событий"
                />

                <SelectVehicleBrand
                    value={filter.vehicle_brand}
                    onChange={(value) => handleChange('vehicle_brand', value)}
                />

                <SelectVehicleModel
                    value={filter.vehicle_model}
                    onChange={(value) => handleChange('vehicle_model', value)}
                    disabled={!filter.vehicle_brand}
                    vehicleBrand={filter.vehicle_brand}
                />

                <SelectClass
                    value={filter.class_name}
                    onChange={(value) => handleChange('class_name', value)}
                />

                <SelectColor
                    value={filter.vehicle_color}
                    onChange={(value) => handleChange('vehicle_color', value)}
                />

                <SelectCheckboxes
                    selected={filter.checkboxes}
                    onChange={(value) => handleChange('checkboxes', value)}
                    className="grid-column-1-2"
                />

                <MultipleInput
                    label="Гос номер ТС"
                    value={filter.grz_list}
                    onChange={(value) => handleChange('grz_list', value)}
                    limitTags={1}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
