import { Divider, Grid, Typography } from '@mui/material';

import MapForm from '../../MapForm';
import { fullDateTime } from '../../../../../../../helpers/date.config';
import messages from '../../../../../../../helpers/constants/messages';

const PrintFile = ({
    currentComplex,
    currentInaccuracy,
    unit_types,
    types,
    model_types,
    complexTransferTypes,
    currentCompany,
    organization_id,
    project
}) => {
    return (
        <div>
            <h1>Паспорт комплекса: {currentComplex?.name}</h1>
            <Grid container direction="row" spacing={1} justify="center" alignItems="flex-start">
                <>
                    <Grid item xs={6}>
                        <Typography>
                            {currentComplex?.name
                            && <span><b>Наименование комплекса: </b> {currentComplex?.name}</span>}
                        </Typography>
                        <Typography>
                            {unit_types[currentComplex?.species_id]
                            && <span><b>Вид комплекса: </b> {unit_types[currentComplex?.species_id]}</span>}
                        </Typography>
                        <Typography>
                            {types?.length > 0 && (
                                <span>
                                    <b>Тип комплекса: </b> {types.find(type => type.id === currentComplex?.type_id)?.name}
                                </span>
                            )}
                        </Typography>
                        <Typography>
                            {model_types.find(elem => elem?.id === currentComplex?.model_id)?.description
                            && <span><b>Модель комплекса: </b> {model_types.find(elem => elem?.id === currentComplex?.model_id)?.description}</span>}
                        </Typography>
                        {currentComplex?.inventory_number && (
                            <Typography variant="body2">
                                <span><b>Инвентарный номер: </b> {currentComplex?.inventory_number}</span>
                            </Typography>
                        )}
                        {currentComplex?.serial_number && project !=='34_vlg_vlz'
                            && <Typography>
                                {currentComplex?.serial_number
                                && <span><b>Серийный  номер: </b> {currentComplex?.serial_number}</span>}
                            </Typography>
                        }
                        {currentComplex?.sign_imgs?.length > 0
                            && (
                                <div>
                                    <Typography variant="body2">
                                        <b>Фиксируемые нарушения:</b>
                                    </Typography>
                                    {currentComplex?.sign_imgs?.map(({ pdd_violation, description, icon }, index) => (
                                        <div key={`icon-${index}`}>
                                            <img src={icon} alt="fouls"/>
                                            <p>{`${pdd_violation} ${description}`}</p>
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                        {/* <h3>Фиксируемые нарушения:</h3>
                        <div style={{ paddingLeft: 15 }}>
                            {currentComplex?.sign_imgs?.map(item => <img key={item} src={item} alt="fouls"/>)}
                        </div> */}
                        <Divider style={{ margin: '10px 0' }}/>
                        <h2>Адрес:</h2>
                        <div>{currentComplex?.address_text}</div>
                        <h2>Координаты:</h2>
                        <Typography>
                            {currentComplex?.lat && <span><b>Широта: </b> {currentComplex?.lat}</span>}
                        </Typography>
                        <Typography>
                            {currentComplex?.lon && <span><b>Долгота: </b> {currentComplex?.lon}</span>}
                        </Typography>
                        <Divider style={{ margin: '10px 0' }}/>
                        <Typography>
                            { currentComplex?.ip_route && <span><b>IP адрес: </b> {currentComplex?.ip_route}</span>}
                        </Typography>
                        <Typography>
                            <b>Маска подсети: </b>{ currentComplex?.mask || messages.INFO_IS_NOT_FOUND}
                        </Typography>
                        <Typography>
                            { currentComplex?.data?.transfer_type
                            && <span><b>Источник получения данных: </b> {complexTransferTypes[currentComplex?.data?.transfer_type]}</span> }
                        </Typography>
                        <Divider style={{ margin: '10px 0' }}/>
                        <Typography>
                            { <span><b>Выгрузка в Дупло: </b> {currentComplex?.data?.export_duplo_enable ? 'да' : 'нет'}</span> }
                        </Typography>
                        <Typography>
                            { currentComplex?.data?.export_duplo_url
                            && <span><b>Адрес для выгрузки ДУПЛО: </b> {currentComplex?.data?.export_duplo_url}</span> }
                        </Typography>
                        <Divider style={{ margin: '10px 0' }}/>
                        {/* <Typography>
                            <span><b>Устройств в комплексе: </b> {currentComplex?.entities?.length}</span>
                        </Typography> */}
                        <Typography>
                            {currentInaccuracy
                                && <span>
                                    <b>Погрешность: </b>
                                    {currentInaccuracy.map(item => {
                                        return item.value
                                            ? `${item.type} ${item.value}`
                                            : item.description;
                                    })}
                                </span>
                            }
                        </Typography>
                        <Divider style={{ margin: '10px 0' }}/>
                        <h2>Документы:</h2>
                        <Typography>
                            { currentComplex?.inspection?.length > 0
                            && <span><b>Сертификат поверки: </b> Годен до {fullDateTime(currentComplex?.inspection[0]?.validity)}</span>}
                        </Typography>
                        <Typography>
                            <span><b>Срок эксплуатации комплекса: </b> {currentComplex?.is_lifetime ? 'Бессрочно' : 'На время действия контракта'}</span>
                        </Typography>

                        <Divider style={{ margin: '10px 0' }}/>

                        <Typography>
                            <span><b>Разрешенная скорость: </b> {currentComplex?.speed_limit}</span>
                        </Typography>

                        {organization_id > 0 && (
                            <>
                                <Divider style={{ margin: '10px 0' }}/>
                                <Typography>
                                    <span><b>Балансодержатель (владелец) комплекса: </b> {currentCompany?.[organization_id]?.title || ''}</span>
                                </Typography>
                            </>
                        )}

                    </Grid>
                    <Grid item xs={6}>
                        <MapForm
                            latlon={[currentComplex?.lat, currentComplex?.lon]}
                            onClose={() => {}}
                            readOnly={true}
                        />
                    </Grid>
                </>
            </Grid>
        </div>
    );
};

export default PrintFile;
