import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent from 'components/common/FormButtons';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { loadThreatLevels } from 'redux/SituationalPlans/actions';
import titles from 'helpers/constants/titles';

import Filters from './Filters';
import Item from './Item';
import ModalForm from './ModalForm';
import type { Data } from './types';

const Dangers = () => {
    const dispatch = useDispatch();

    const data = useSelector(SituationalPlansSelectors.threatLevelsData);
    const meta = useSelector(SituationalPlansSelectors.threatLevelsMeta);
    const loadingThreatLevels = useSelector(SituationalPlansSelectors.loadingThreatLevels);

    const [openModal, setOpenModal] = useState(false);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const reloadList = (isDelete: boolean) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadThreatLevels(params.page, limit, params.data));
    },[dispatch, limit, params]);

    const renderContent = () => (
        <LSContainer headers={[
            { title: titles.NAME },
            { title: titles.ACTIONS, align: 'right', isActions: true },
        ]}>
            {data?.map((item: Data) => 
                <Item
                    key={item.id + item.name}
                    item={item}
                    reloadList={reloadList}
                />)
            }
        </LSContainer>
    );

    return (
        <>
            <PageLayout
                header="Уровни угрозы"
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={loadingThreatLevels}
                content={renderContent}
                paginationProps={{
                    loadList: (page, limit) => dispatch(loadThreatLevels(page, limit, { name: params })),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />
            {openModal
                && <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew
                    reloadList={reloadList}
                />
            }
        </>
    );
};

export default Dangers;
