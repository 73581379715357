import * as types from './types';

export const loadTransportIncidentTypes = (page = 1, limit = 25, params) => ({
    type: types.LOAD_TRANSPORT_INCIDENT_TYPES,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingTransportIncidentTypes = (bool = false) => ({
    type: types.LOADING_TRANSPORT_INCIDENT_TYPES,
    payload: bool
});

export const loadedTransportIncidentTypes = (data) => ({
    type: types.LOADED_TRANSPORT_INCIDENT_TYPES,
    payload: data
});

export const createTransportIncidentType = (data, callback) => ({
    type: types.CREATE_TRANSPORT_INCIDENT_TYPE,
    payload: data,
    callback
});

export const editTransportIncidentType = (id, data, callback) => ({
    type: types.EDIT_TRANSPORT_INCIDENT_TYPE,
    payload: { id, data },
    callback
});

export const deleteTransportIncidentType = (id, callback) => ({
    type: types.DELETE_TRANSPORT_INCIDENT_TYPE,
    payload: id,
    callback
});

export const loadEvents = (page = 1, limit = 25, params) => ({
    type: types.LOAD_EVENTS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingEvents = (bool = false) => ({
    type: types.LOADING_TRANSPORT_INCIDENT_TYPES,
    payload: bool
});

export const loadedEvents = (data) => ({
    type: types.LOADED_EVENTS,
    payload: data
});

export const createEvent = (data) => ({
    type: types.CREATE_EVENT,
    payload: data
});

export const editEvent = (id, data) => ({
    type: types.EDIT_EVENT,
    payload: {
        id,
        data
    }
});

export const deleteEvent = (id) => ({
    type: types.DELETE_EVENT,
    payload: id
});


export const loadKeyWordList = (page = 1, limit = 25, params) => ({
    type: types.LOAD_KEY_WORD_LIST,
    payload: {
        page,
        limit,
        params
    }
});

export const loadedKeyWordList = (data) => ({
    type: types.LOADED_KEY_WORD_LIST,
    payload: data
});

export const loadingKeyWordList = (bool = false) => ({
    type: types.LOADING_KEY_WORD_LIST,
    payload: bool
});

export const deleteKeyWord = (id, callback) => ({
    type: types.DELETE_KEY_WORD,
    payload: id,
    callback
});

export const editKeyWord = (id, data, callback) => ({
    type: types.EDIT_KEY_WORD,
    payload: {
        id,
        data
    },
    callback
});

export const addKeyWord = (data, callback) => ({
    type: types.ADD_KEY_WORD,
    payload: data,
    callback
});

//Получение списка угроз и рисков
export const loadDangersList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_DANGERS_LIST,
    payload: { page, limit, query }
});

export const loadingDangersList = (bool = false) => ({
    type: types.LOADING_DANGERS_LIST,
    payload: bool
});

export const loadedDangersList = (data) => ({
    type: types.LOADED_DANGERS_LIST,
    payload: data
});

export const setStatusDanger = (status = false) => ({
    type: types.SET_STATUS_DANGER,
    payload: status
});

//Создание сущности угроз и риска
export const createDanger = (data, callback) => ({
    type: types.CREATE_DANGER,
    payload: { data },
    callback
});

//Редактирование сущности угроз и риска
export const editDanger = (id, data, callback) => ({
    type: types.EDIT_DANGER,
    payload: { id, data },
    callback
});

//Удаление сущности угроз и риска
export const deleteDanger = (id, callback) => ({
    type: types.DELETE_DANGER,
    payload: id,
    callback
});

export const loadThreatLevels = (page = 1, limit = 25, params) => ({
    type: types.LOAD_THREAT_LEVELS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingThreatLevels = (bool = false) => ({
    type: types.LOADING_THREAT_LEVELS,
    payload: bool
});

export const loadedThreatLevels = (data) => ({
    type: types.LOADED_THREAT_LEVELS,
    payload: data
});

export const createThreatLevel = (data, callback) => ({
    type: types.CREATE_THREAT_LEVEL,
    payload: data,
    callback
});

export const editThreatLevel = (id, data, callback) => ({
    type: types.EDIT_THREAT_LEVEL,
    payload: {
        id,
        data
    },
    callback
});

export const deleteThreatLevel = (id, callback) => ({
    type: types.DELETE_THREAT_LEVEL,
    payload: id,
    callback
});

export const loadTypicalOperations = (page = 1, limit = 25, params) => ({
    type: types.LOAD_TYPICAL_OPERATIONS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingTypicalOperations = (bool = false) => ({
    type: types.LOADING_TYPICAL_OPERATIONS,
    payload: bool
});


export const loadedTypicalOperations = (data) => ({
    type: types.LOADED_TYPICAL_OPERATIONS,
    payload: data
});

export const loadIncidentOperations = (id) => ({
    type: types.LOAD_INCIDENT_OPERATIONS,
    payload: id
});

export const loadingIncidentOperations = (bool = false) => ({
    type: types.LOADING_INCIDENT_OPERATIONS,
    payload: bool
});


export const loadedIncidentOperations = (data) => ({
    type: types.LOADED_INCIDENT_OPERATIONS,
    payload: data
});

export const editIncidentOperations = (id, data, callback = () => {}) => ({
    type: types.EDIT_INCIDENT_OPERATIONS,
    payload: { id, data },
    callback
});

export const createTypicalOperation = (data, callback) => ({
    type: types.CREATE_TYPICAL_OPERATION,
    payload: data,
    callback
});

export const editTypicalOperation = (id, data, callback) => ({
    type: types.EDIT_TYPICAL_OPERATION,
    payload: {
        id,
        data
    },
    callback
});

export const deleteTypicalOperation = (id, callback) => ({
    type: types.DELETE_TYPICAL_OPERATION,
    payload: id,
    callback
});

export const loadEventObjects = (page = 1, limit = 25, params) => ({
    type: types.LOAD_EVENT_OBJECTS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadResponseScenarios = (page = 1, limit = 25, params) => ({
    type: types.LOAD_RESPONSE_SCENARIOS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingResponseScenarios = (bool = false) => ({
    type: types.LOADING_RESPONSE_SCENARIOS,
    payload: bool
});

export const loadedResponseScenarios = (data) => ({
    type: types.LOADED_RESPONSE_SCENARIOS,
    payload: data
});

export const createResponseScenario = (data, callback) => ({
    type: types.CREATE_RESPONSE_SCENARIO,
    payload: data,
    callback
});

export const editResponseScenario = (id, data, callback) => ({
    type: types.EDIT_RESPONSE_SCENARIO,
    payload: {
        id,
        data
    },
    callback
});

export const loadingEventObjects = (bool = false) => ({
    type: types.LOADING_EVENT_OBJECTS,
    payload: bool
});

export const loadedEventObjects = (data) => ({
    type: types.LOADED_EVENT_OBJECTS,
    payload: data
});

export const createEventObjects = (data, callback = () => {}) => ({
    type: types.CREATE_EVENT_OBJECTS,
    payload: data,
    callback
});

export const editEventObjects = (id, data, callback = () => {}) => ({
    type: types.EDIT_EVENT_OBJECTS,
    payload: { id, data },
    callback
});

export const deleteResponseScenario = (id, callback) => ({
    type: types.DELETE_RESPONSE_SCENARIO,
    payload: id,
    callback
});

export const deleteEventObjects = (id, callback = () => {}) => ({
    type: types.DELETE_EVENT_OBJECTS,
    payload: id,
    callback
});

export const loadEventObjectsTypes = () => ({
    type: types.LOAD_EVENT_OBJECTS_TYPES
});

export const loadedEventObjectsTypes = (data) => ({
    type: types.LOADED_EVENT_OBJECTS_TYPES,
    payload: data
});

export const loadCurrentEvent = (id) => ({
    type: types.LOAD_CURRENT_EVENT,
    payload: id
});

export const loadingCurrentEvent = (id) => ({
    type: types.LOADING_CURRENT_EVENT,
    payload: id
});

export const loadedCurrentEvent = (data) => ({
    type: types.LOADED_CURRENT_EVENT,
    payload: data
});

export const loadCurrentKeyword = (id) => ({
    type: types.LOAD_CURRENT_KEYWORD,
    payload: id
});

export const loadedCurrentKeyword = (data) => ({
    type: types.LOADED_CURRENT_KEYWORD,
    payload: data
});

export const createTransportIncident = (data, callback) => ({
    type: types.CREATE_TRANSPORT_INCIDENT,
    payload: data,
    callback
});

export const editTransportIncident = (id, data, callback) => ({
    type: types.EDIT_TRANSPORT_INCIDENT,
    payload: {
        id,
        data
    },
    callback
});

export const deleteTransportIncident = (id, callback) => ({
    type: types.DELETE_TRANSPORT_INCIDENT,
    payload: id,
    callback
});

export const loadTransportIncidentStatuses = () => ({
    type: types.LOAD_TRANSPORT_INCIDENT_STATUSES
});

export const loadedTransportIncidentStatuses = (data) => ({
    type: types.LOADED_TRANSPORT_INCIDENT_STATUSES,
    payload: data
});

export const loadCurrentCategory = (id) => ({
    type: types.LOAD_CURRENT_CATEGORY,
    payload: id
});

export const loadedCurrentCategory = (data) => ({
    type: types.LOADED_CURRENT_CATEGORY,
    payload: data
});

export const loadTransportIncidents = (page = 1, limit = 25, params) => ({
    type: types.LOAD_TRANSPORT_INCIDENTS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingTransportIncidents = (bool = false) => ({
    type: types.LOADING_TRANSPORT_INCIDENTS,
    payload: bool
});

export const loadedTransportIncidents = (data) => ({
    type: types.LOADED_TRANSPORT_INCIDENTS,
    payload: data
});

export const loadCurrentResponseScenario = (id) => ({
    type: types.LOAD_CURRENT_RESPONSE_SCENARIO,
    payload: id
});

export const loadingCurrentResponseScenario = (bool = false) => ({
    type: types.LOADING_CURRENT_RESPONSE_SCENARIO,
    payload: bool
});


export const loadedCurrentResponseScenario = (data) => ({
    type: types.LOADED_CURRENT_RESPONSE_SCENARIO,
    payload: data
});

export const loadCurrentTransportIncidentType = (id) => ({
    type: types.LOAD_CURRENT_TRANSPORT_INCIDENT_TYPE,
    payload: id
});

export const loadingCurrentTransportIncidentType = (bool = false) => ({
    type: types.LOADING_CURRENT_TRANSPORT_INCIDENT_TYPE,
    payload: bool
});

export const loadedCurrentTransportIncidentType = (data) => ({
    type: types.LOADED_CURRENT_TRANSPORT_INCIDENT_TYPE,
    payload: data
});

export const loadCurrentThreatLevel = (id) => ({
    type: types.LOAD_CURRENT_THREAT_LEVEL,
    payload: id
});

export const loadingCurrentThreatLevel = (id) => ({
    type: types.LOADING_CURRENT_THREAT_LEVEL,
    payload: id
});

export const loadedCurrentThreatLevel = (data) => ({
    type: types.LOADED_CURRENT_THREAT_LEVEL,
    payload: data
});

export const loadCurrentEventObject = (id) => ({
    type: types.LOAD_CURRENT_EVENT_OBJECT,
    payload: id
});

export const loadedCurrentEventObject = (data) => ({
    type: types.LOADED_CURRENT_EVENT_OBJECT,
    payload: data
});

export const loadArrayOfKeywords = (page = 1, limit = 25, params) => ({
    type: types.LOAD_ARRAY_OF_KEYWORDS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadedArrayOfKeywords = (data) => ({
    type: types.LOADED_ARRAY_OF_KEYWORDS,
    payload: data
});

export const loadReportTransportIncidents = ( page = 1, limit = 25, params) => ({
    type: types.LOAD_REPORT_TRANSPORT_INCIDENTS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingReportTransportIncidents = (bool = false) => ({
    type: types.LOADING_REPORT_TRANSPORT_INCIDENTS,
    payload: bool
});

export const loadedReportTransportIncidents = (data) => ({
    type: types.LOADED_REPORT_TRANSPORT_INCIDENTS,
    payload: data
});

export const getReportTransportIncidents = (data) => ({
    type: types.GET_REPORT_TRANSPORT_INCIDENTS,
    payload: data
});

export const setReportTransportIncidentsFilter = (data) => ({
    type: types.SET_REPORT_TRANSPORT_INCIDENTS_FILTER,
    payload: data
});

export const clearReportTransportIncidentsFilter = () => ({
    type: types.CLEAR_REPORT_TRANSPORT_INCIDENTS_FILTER,
});

export const loadForPolygon = (polygon, params = {}) => ({
    type: types.LOAD_POLYGON,
    payload: { polygon, ...params },
});

export const saveForPolygon = (data) => ({
    type: types.SAVE_POLYGON,
    payload: data,
});

export const clearForPolygon = () => ({
    type: types.SAVE_POLYGON,
    payload: [],
});

export const loadingPolygon = (bool = false) => ({
    type: types.LOADING_POLYGON,
    payload: bool
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool
});

export const loadedSidebar = (data, replace = true) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        replace,
    }
});

export const loadSidebar = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        query,
    },
});

export const clearSidebar = () => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data: {
            data: [],
            meta: {
                last_page: 0,
            },
        },
        replace: true,
    },
});

export const setActive = (item) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

export const setDeleteForm = (data = false) => ({
    type: types.SET_DELETE_FORM,
    payload: data,
});

export const setEditForm = (data = false) => ({
    type: types.SET_EDIT_FORM,
    payload: data,
});

export const saveStatus = (bool = false) => ({
    type: types.SAVED,
    payload: bool,
});

export const setFilters = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});
export const clearFilters = () => ({
    type: types.SET_FILTER,
    payload: {},
});

export const loadReportTransportIncidentsResolutionTime = ( page = 1, limit = 25, params) => ({
    type: types.LOAD_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME,
    payload: {
        page,
        limit,
        ...params
    }
});

export const loadingReportTransportIncidentsResolutionTime = (bool = false) => ({
    type: types.LOADING_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME,
    payload: bool
});

export const loadedReportTransportIncidentsResolutionTime = (data) => ({
    type: types.LOADED_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME,
    payload: data
});

export const getReportTransportIncidentsResolutionTime = (data) => ({
    type: types.GET_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME,
    payload: data
});

export const setReportTransportIncidentsResolutionTimeFilter = (data) => ({
    type: types.SET_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME_FILTER,
    payload: data
});

export const clearReportTransportIncidentsResolutionTimeFilter = () => ({
    type: types.CLEAR_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME_FILTER,
});

// объекты мероприятий слой
export const loadForPolygonEvents = (polygon, params = {}) => ({
    type: types.LOAD_POLYGON_EVENTS,
    payload: { polygon, ...params },
});

export const saveForPolygonEvents = (data) => ({
    type: types.SAVE_POLYGON_EVENTS,
    payload: data,
});

export const clearForPolygonEvents = () => ({
    type: types.SAVE_POLYGON_EVENTS,
    payload: [],
});

export const loadingPolygonEvents = (bool = false) => ({
    type: types.LOADING_POLYGON_EVENTS,
    payload: bool,
});

export const loadingSidebarEvents = (bool = false) => ({
    type: types.LOADING_SIDEBAR_EVENTS,
    payload: bool,
});

export const loadedSidebarEvents = (data, replace = true) => ({
    type: types.LOADED_SIDEBAR_EVENTS,
    payload: {
        data,
        replace,
    }
});

export const loadSidebarEvents = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_SIDEBAR_EVENTS,
    payload: {
        page,
        limit,
        query,
    },
});

export const clearSidebarEvents = () => ({
    type: types.LOADED_SIDEBAR_EVENTS,
    payload: {
        data: {
            data: [],
            meta: {
                last_page: 0,
            },
        },
        replace: true,
    },
});

export const setActiveEvents = (item) => ({
    type: types.SET_ACTIVE_EVENTS,
    payload: item,
});

export const resetActiveEvents = () => ({
    type: types.SET_ACTIVE_EVENTS,
    payload: {},
});

export const setDeleteFormEvents = (data = false) => ({
    type: types.SET_DELETE_FORM_EVENTS,
    payload: data,
});

export const setEditFormEvents = (data = false) => ({
    type: types.SET_EDIT_FORM_EVENTS,
    payload: data,
});

export const setFiltersEvents = (filter) => ({
    type: types.SET_FILTER_EVENTS,
    payload: filter,
});
export const clearFiltersEvents = () => ({
    type: types.SET_FILTER_EVENTS,
    payload: {},
});

export const setSavedEvents = (bool = false) => ({
    type: types.SET_SAVED_EVENTS,
    payload: bool,
});
/**/

export const loadTransportIncidentHistory = (page = 1, limit = 25, id) => ({
    type: types.LOAD_TRANSPORT_INCIDENT_HISTORY,
    payload: { page, limit, id }
});

export const loadingTransportIncidentHistory = (bool = false) => ({
    type: types.LOADING_TRANSPORT_INCIDENT_HISTORY,
    payload: bool
});

export const loadedTransportIncidentHistory = (data) => ({
    type: types.LOADED_TRANSPORT_INCIDENT_HISTORY,
    payload: data
});

export const loadTransportIncidentTypeCreation = () => ({
    type: types.LOAD_TRANSPORT_INCIDENT_TYPE_CREATION
});

export const loadedTransportIncidentTypeCreation = (data) => ({
    type: types.LOADED_TRANSPORT_INCIDENT_TYPE_CREATION,
    payload: data
});

export const loadTransportIncidentById = (id, callback) => ({
    type: types.LOAD_TRANSPORT_INCIDENT,
    payload: {
        id
    },
    callback
});

export const loadingTransportIncident = (bool = false) => ({
    type: types.LOADING_TRANSPORT_INCIDENT,
    payload: bool
});

export const loadedTransportIncident = (data) => ({
    type: types.LOADED_TRANSPORT_INCIDENT,
    payload: data
});
export const clearTransportIncident = () => ({
    type: types.LOADED_TRANSPORT_INCIDENT,
    payload: {},
});


export const loadInfopanel = (params = {}) => ({
    type: types.LOAD_INFOPANEL,
    payload: params
});
export const loadingInfopanel = (bool = false) => ({
    type: types.LOADING_INFOPANEL,
    payload: bool
});
export const loadedInfopanel = (data) => ({
    type: types.LOADED_INFOPANEL,
    payload: data
});

export const loadDistrictsByMonth = (params = {}) => ({
    type: types.LOAD_DISTRICTS_BY_MONTH,
    payload: params,
});
export const loadingDistrictsByMonth = (bool = false) => ({
    type: types.LOADING_DISTRICTS_BY_MONTH,
    payload: bool
});
export const loadedDistrictsByMonth = (data) => ({
    type: types.LOADED_DISTRICTS_BY_MONTH,
    payload: data
});
export const setFilterDistrictsByMonth = (filter = {}) => ({
    type: types.SET_FILTER_DISTRICTS_BY_MONTH,
    payload: filter
});

export const setWsEO = (events) => ({
    type: types.SET_WS_EVENTS_OBJECTS,
    payload: events
});
export const setEventsProps = (params) => ({
    type: types.SET_EVENTS_PROPS,
    payload: params
});

export const setWsTI = (events) => ({
    type: types.SET_WS_TRANSPORT_INCIDENT,
    payload: events
});
export const setSidebarParams = (params) => ({
    type: types.SET_SIDEBAR_PARAMS,
    payload: params
});

export const loadingButton = (bool = false) => ({
    type: types.LOADING_BUTTON,
    payload: bool
});

export const loadTransportIncidentModal = (id, callback) => ({
    type: types.LOAD_TRANSPORT_INCIDENT_MODAL,
    payload: {
        id
    },
    callback,
});
export const loadingTransportIncidentModal = (bool = false) => ({
    type: types.LOADING_TRANSPORT_INCIDENT_MODAL,
    payload: bool
});
export const loadedTransportIncidentModal = (data) => ({
    type: types.LOADED_TRANSPORT_INCIDENT_MODAL,
    payload: data
});
export const clearTransportIncidentModal = () => ({
    type: types.LOADED_TRANSPORT_INCIDENT_MODAL,
    payload: {},
});

export const setTIModalData = (data) => ({
    type: types.SET_TI_MODAL_DATA,
    payload: data
});

export const setTIFilterParams = (data) => ({
    type: types.SET_TI_FILTER_PARAMS,
    payload: data
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});


export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});
export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});
export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});
export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

export const loadImagesLegends = () => ({
    type: types.LOAD_IMAGES_LEGENDS,
});
export const loadedImagesLegends = (data) => ({
    type: types.LOADED_IMAGES_LEGENDS,
    payload: data,
});

export const loadReportByEventPlans = (page, limit, filter = {}) => ({
    type: types.LOAD_REPORT_BY_EVENT_PLANS,
    payload: { page, limit, ...filter }
});

export const loadedReportByEventPlans = (data) => ({
    type: types.LOADED_REPORT_BY_EVENT_PLANS,
    payload: data
});

export const loadingReportByEventPlans = (bool = false) => ({
    type: types.LOADING_REPORT_BY_EVENT_PLANS,
    payload: bool
});
