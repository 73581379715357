import { memo, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
    Fade,
    IconButton,
    Paper,
    Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import './style.scss';

import { menuSelectors } from 'redux/Menu';
import { authSelectors } from 'redux/Auth';


const MapLegends = memo(({
    layer,
    isVisible = true,
    visibleRequired = false,
    children,
}) => {
    const currentLayer = useSelector(menuSelectors.selectedLayer || '', shallowEqual);
    const user = useSelector(authSelectors.authUserInfo, shallowEqual);

    const [showLegends, setShowLegends] = useState(!!user?.is_show_legend);
    const [visibleLegends, setVisibleLegends] = useState(false);

    useEffect(() => {
        setTimeout(() => setShowLegends(user?.is_show_legend), 1000);
    }, [user?.is_show_legend]);

    useEffect(() => {
        if ((layer === currentLayer || visibleRequired) && isVisible) {
            setTimeout(() => setVisibleLegends(true), 1000);
            return () => setVisibleLegends(false);
        }
    }, [layer, currentLayer, isVisible, visibleRequired]);

    return (
        <div
            style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                maxWidth: '70%',
                zIndex: 9999,
            }}
        >
            <Fade in={visibleLegends} timeout={500}>
                <div>
                    {showLegends === false ? (
                        <Tooltip title="Показать легенду" placement="right">
                            <IconButton
                                onClick={() => setShowLegends(true)}
                            >
                                <InfoIcon fontSize="medium" style={{ color: '#3f51b5' }} />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Paper
                            sx={{
                                marginLeft: '1rem',
                                marginBottom: '1rem',
                                zIndex: 999999
                            }}
                        >
                            <div className="close-legend-icon">
                                <Tooltip title="Скрыть легенду" placement="right">
                                    <IconButton
                                        size="small"
                                        onClick={() => setShowLegends(false)}
                                    >
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            {children}
                        </Paper>
                    )}
                </div>
            </Fade>
        </div>
    );
});

export default MapLegends;
