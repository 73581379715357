import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiDtpMethods = {

    getCountByMonths: async (params) => {
        const response = await instance.post(apiUrls.getCountByMonths(), params);
        return response.data;
    },

    getCountByPeriods: async (params) => {
        const response = await instance.post(apiUrls.getCountByPeriods(), params);
        return response.data;
    },

    loadHeatMapDistricts: async (params) => {
        const response = await instance.get(apiUrls.loadHeatMapDistricts(), { params });
        return response.data;
    },

    getDtpTypesAll: async () => {
        const response = await instance.get(apiUrls.getDtpTypesAll(), { params: { page: 1, limit: 100 } });
        return response?.data;
    },

    getDtpList: async (config = {}) => {
        const response = await instance.get(apiUrls.getDtpList(), config);
        return response?.data;
    },

    getDtpTypes: async (params) => {
        const response = await instance.get(apiUrls.getDtpTypes(), { params });
        return response?.data;
    },

    editDtpType: async (params) => {
        const response = await instance.put(apiUrls.editDtpType(), params);
        return response?.data;
    },

    createNewDtp: async (data) => {
        const response = await instance.post(apiUrls.createNewDtp(), data);
        return response.data;
    },

    editStatuses: async (params) => {
        const response = await instance.post(apiUrls.editStatuses(), params);
        return response?.data;
    },

    getDtpStreets: async (data) => {
        const response = await instance.get(apiUrls.getDtpStreets(), {
            params: { query: data }
        });
        return response.data;
    },

    editDtp: async (id, data) => {
        const response = await instance.put(apiUrls.editDtp(id), data);
        return response.data;
    },

    getDtpById: async (id) => {
        const response = await instance.get(apiUrls.getDtpById(id));
        return response.data;
    },
};

export default ApiDtpMethods;
