import { useContext, useEffect, useState } from 'react';
import { IconButton, Menu, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useFilterButtonsByPermissions } from 'helpers/hooks/Permissons';

import { ListContext } from '../helper';

import ActionList from './ActionList';

// действия кнопки меню
const ActionsButtons = ({
    tooltip = 'Показать действия',
    buttons = [],
    uri = '', // для страниц на которых получаем доп.права - например отчеты КВФВ
    test_id_prefix = ''
}) => {
    const { setHideActionsHeader } = useContext(ListContext) || {};

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleStop = (event) => {
        event?.preventDefault?.();
        event?.stopPropagation?.();
    };

    const handleClick = (event) => {
        handleStop(event);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        handleStop(event);
        setAnchorEl(null);
    };

    // const list = useMemo(() => buttons.filter(({ disabled = false }) => disabled === false), [buttons]);

    const list = useFilterButtonsByPermissions(buttons, uri);

    useEffect(() => {
        // Если список кнопок не пустой, показать заголовок Действия
        if (list?.length > 0) {
            setHideActionsHeader && setHideActionsHeader(false);
        }
    }, [setHideActionsHeader, list?.length]);

    // Если список кнопок пустой, то не отображаем этот компонент
    // if (list.length === 0) return null;

    return (list.length > 0
        ? <>
            <IconButton
                onClick={handleClick}
                data-testid={`${test_id_prefix}/action-buttons/button`}
            >
                {tooltip
                    ? (
                        <Tooltip title={tooltip}>
                            <MoreVertIcon />
                        </Tooltip>
                    )
                    : (
                        <MoreVertIcon />
                    )
                }
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <ActionList test_id_prefix={test_id_prefix} list={list} handleClose={handleClose} />
            </Menu>
        </>
        : null
    );
};

export default ActionsButtons;
