import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Checkbox, Divider, FormControlLabel, FormHelperText, List, ListItem, TextField } from '@mui/material';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import UploadImageField from 'components/common/Upload/UploadImageField';
import icon from 'components/MapComponents/Layers/TransportIncidents/icons/icon';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import titles from 'helpers/constants/titles';
import svgToBase64 from 'helpers/svgToBase64';
import { useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { createIncidentType, editIncidentType } from 'modules/React/redux/actions';
import type { FactorType, ScenarioType } from 'modules/React/reactTypes';

import Scenarios from './Scenarios';
import Factors from './Factors';


interface FormData {
    id?: number;
    name: string;
    response_scenarios: ScenarioType[];
    image: string | null;
    is_statistics: boolean;
    factors: FactorType[];
}

interface ModalFormProps {
    isOpen: boolean;
    onClose: () => void;
    isNew?: boolean;
    item?: Partial<FormData>;
    reloadList: () => void;
}

const ModalForm = ({ isOpen, onClose, isNew = false, item = {}, reloadList }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initState: FormData = {
        name: item.name || '',
        response_scenarios: item.response_scenarios || [], // Убедитесь, что это массив объектов Scenario
        image: item.image || null,
        is_statistics: item.is_statistics || false,
        factors: item.factors || [],
    };

    const [formData, setFormData] = useState(initState);

    const onAcceptModal = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            is_statistics: +formData.is_statistics,
        });

        const callback = () => {
            reloadList();
            onClose();
        };

        if (isNew) {
            dispatch(createIncidentType(prepareData, callback));
        } else {
            dispatch(editIncidentType(item?.id!, prepareData, callback));
        }
    };

    //TODO придумать как избавиться от any
    const onChange = (name: keyof FormData, value: any) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onAcceptModal,
                            disabled: formData.name.trim().length === 0,
                        },
                    ]}
                />
            }
        >
            <form className="modal__form">
                <Grid container direction="row" spacing={0} justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12}>
                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('name')}
                            helperText={validation.get('name')}
                        >
                            <TextField
                                label={titles.NAME}
                                size="small"
                                value={formData.name}
                                variant="outlined"
                                name="name"
                                type="text"
                                onChange={(e) => onChange(e.target.name as keyof FormData, e.target.value)}
                                required
                            />
                        </FormInfoWrapper>

                        <Scenarios
                            selected_scenarios={formData.response_scenarios}
                            onChange={(value: ScenarioType[]) => onChange('response_scenarios', value)}
                        />

                        <h2>Изображение для обозначения на карте</h2>
                        <FormHelperText>
                            Максимальный размер изображения 70х70 пикселей
                        </FormHelperText>
                        <List>
                            <ListItem divider>
                                <UploadImageField
                                    value={formData.image || svgToBase64(icon({ fill: '#000' })) || ''}
                                    name="image"
                                    //@ts-ignore
                                    color="#fff"
                                    onChange={(value: string) => onChange('image', value)}
                                    label="Изображение"
                                    isSelectBorder
                                    imageSize={40}
                                    acceptFiles=".png"
                                    helperText="Размер изображения не должен превышать 70х70 пикселей"
                                    imageErrorValidation={(img) => img.width > 70 || img.height > 70}
                                    errorText={validation.get('image')}
                                />
                            </ListItem>
                        </List>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.is_statistics}
                                    onClick={(e) =>
                                        onChange('is_statistics', (e.target as HTMLInputElement).checked)
                                    }
                                    size="small"
                                />
                            }
                            label={'Учитывать в статистике'}
                        />
                        <Divider style={{ marginBottom: '1rem' }}/>
                        <Factors
                            onChange={(value) => onChange('factors', value)}
                            factors={formData.factors}
                        />
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};

export default ModalForm;
