import { useState } from 'react';

import messages from 'helpers/constants/messages';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import ModalForm from './ModalForm';

import type { JobsTypes, Period } from './types';

interface ItemProp {
    item: JobsTypes;
    period: Period[];
    reloadList: () => void;
}

const Item = ({ item, period, reloadList }: ItemProp) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.service_name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.template_name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setModalOpen(true),
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {modalOpen
                && <ModalForm
                    open={modalOpen}
                    data={item}
                    onClose={() => setModalOpen(false)}
                    isNew={false}
                    reloadList={reloadList}
                    period={period}
                />
            }
        </>
    );
};

export default Item;
