import { useState } from 'react';
import { Button } from '@mui/material';

import LineButton from './LineButton';
import InnerComponent from './InnerComponent';
import { rulerTypes } from './helpers';

import './style.scss';

// линейка
const MapRulerSquare = ({ map }) => {
    // отобразить кнопки
    const [isVisible, setIsVisible] = useState(false);
    const [type, setType] = useState(rulerTypes.line);

    return(
        <div className="rs-wrapper">
            {/* кнопка линейка */}
            <LineButton
                tooltip="Линейка и планиметр"
                isVisible={isVisible}
                onClick={() => setIsVisible(visible => {
                    // сброс
                    if (visible) setType(rulerTypes.line);
                    return !visible;
                })}
            />
            {/* выбор типа */}
            {isVisible && (
                <div className="leaflet-draw-actions leaflet-draw-actions-top buttons-wrapper">
                    {/* компонент карты */}
                    <InnerComponent
                        map={map}
                        type={type}
                    />
                    <Button
                        size="small"
                        onClick={() => setType(rulerTypes.line)}
                        className={type === rulerTypes.line ? '' : 'no-selected'}
                    >
                        Линейка
                    </Button>
                    <Button
                        size="small"
                        onClick={() => setType(rulerTypes.polygon)}
                        className={type === rulerTypes.polygon ? '' : 'no-selected'}
                    >
                        Планиметр
                    </Button>
                </div>
            )}
        </div>
    );
};

export default MapRulerSquare;
