import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCameras } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import titles from 'helpers/constants/titles';
import NoData from 'components/common/Information/NoData';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

import type { Meta } from 'types/pagination';
import type { Camera } from './types';

const RoadNetworkVideoCamera = () => {
    const dispatch = useDispatch();

    const list: Camera[] = useSelector(roadNetworksSelectors.camerasList);
    const meta: Meta = useSelector(roadNetworksSelectors.camerasMeta);
    const loading: boolean = useSelector(roadNetworksSelectors.loadingCameras);

    const [isOpenAddModal, setIsOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete?: boolean) => {
        const page = isDelete && meta.last_page > 1 && list.length === 1
            ? params.page - 1
            : params.page;

        dispatch(loadCameras(page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadCameras(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    const renderContent = () => {
        return (
            <>
                {list?.length > 0 ? (
                    <LSContainer
                        headers={[
                            {
                                title: '',
                                width: '80px',
                            },
                            {
                                title: titles.NAME,
                                width: '20%',
                            },
                            {
                                title: titles.TYPE,
                                width: '20%',
                            },
                            {
                                title: titles.SERIAL_NUMBER,
                                width: '20%',
                            },
                            {
                                title: titles.ADDRESS,
                                width: '20%',
                            },
                            {
                                title: 'Действия',
                                align: 'right',
                                isActions: true,
                            },
                        ]}
                    >
                        {list.map((item) => (
                            <Item key={item.id} item={item} reloadList={reloadList} />
                        ))}
                    </LSContainer>
                ) : (
                    !loading && <NoData />
                )}
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Видеокамера"
                filters={<Filter setParams={setParams} />}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            positionLeft
                            noPadding
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setIsOpenAddModal(true),
                                    accessProp: ''
                                },
                            ]}
                        />
                    ),
                    total: meta?.total,
                }}
                content={renderContent}
                paginationProps={{
                    list: meta,
                    loadList: (page) => setParams({ ...params, page }),
                    limit,
                    setLimit,
                }}
            />

            {isOpenAddModal && (
                <ModalForm
                    isNew
                    isOpen={isOpenAddModal}
                    onClose={() => setIsOpenAddModal(false)}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default RoadNetworkVideoCamera;
