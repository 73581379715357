import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import { loadedCameraById, loadStatusesVideoCamera } from 'redux/VideoCameras/actions';
import { videoCamerasSelectors } from 'redux/VideoCameras';
import { useStoreFromSelector } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import Information from 'components/common/Information';
import { createIcon, MapPopUp } from 'components/MapComponents/MapPopUp';
import MapScroll from 'components/MapComponents/MapScroll';
import Loading from 'components/common/Loading';

import config from '../config';

import FrameButton from './FrameButton';

const PopUp = ({
    data = {},
    isOpen,
    onClose,
}) => {

    const dispatch = useDispatch();

    const [eData, setEData] = useState({});
    const [loading, setLoading] = useState(false);

    const {
        name = '',
        status_color = '',
        id = 0,
    } = data;

    const current = useMemo(() => ({ ...data, ...eData }), [data, eData]);

    // дограузка данных
    useEffect(() => {
        if (id) {
            dispatch(loadedCameraById(
                id,
                setEData,
                setLoading
            ));
        }
    }, [id]);

    const statuses = useStoreFromSelector(loadStatusesVideoCamera, videoCamerasSelectors.videoCameraStatuses);

    const fields = {
        name: {
            title: 'Название',
        },
        extrenal_id: {
            title: 'Внешний идентификатор',
        },
        status: {
            title: 'Статус',
            value: ({ status }) => statuses.length === 0 ? messages.LOADING : (statuses.find(st => st.id === status)?.name || messages.INFO_IS_NOT_FOUND)
        },
        address_text: {
            title: 'Адрес',
        },

        created_at: {
            title: 'Создано',
            value: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },

        updated_at: {
            title: 'Изменено',
            value: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },
        lat_lon: {
            title: 'Координаты (lat, lon)',
            value: ({ lat, lon }) => `${lat} ${lon}`
        },
    };

    return (
        <>
            <MapPopUp
                isOpen={isOpen}
                icon={createIcon(config.layerIcon)}
                title={name}
                titleBackgroundColor={status_color}
                titleButtons={
                    <FrameButton
                        cameraIds={[current.id]}
                        isPopUp
                    />
                }
                onClose={onClose}
                medium
            >
                {loading && <Loading circular />}

                {!current
                    ? (<div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>)
                    : (
                        <MapScroll>
                            <Information
                                title={fields}
                                data={current}
                                returnNull
                            />
                        </MapScroll>
                    )
                }
            </MapPopUp>
        </>
    );
};

export default PopUp;
