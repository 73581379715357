import { config } from 'config';

const apiEfficiencyUrl = () => config.get('urls').apiEfficiencyUrl;

const apiUrls = {

    getDashboard: () => `${apiEfficiencyUrl()}/efficiency/summary`,

    getUserDashboard: () => `${apiEfficiencyUrl()}/user-dashboard`,

    editUserDashboard: () => `${apiEfficiencyUrl()}/user-dashboard`,

    getDashboardsItem: () => `${apiEfficiencyUrl()}/dashboard/item`,

    getDashboardItemUser: () => `${apiEfficiencyUrl()}/dashboard/item/user`,

    createDashboardItemUser: () => `${apiEfficiencyUrl()}/dashboard/item/user`,

    editDashboardItemUser: (id) => `${apiEfficiencyUrl()}/dashboard/item/user/${id}`,

    deleteDashboardItemUser: (id) => `${apiEfficiencyUrl()}/dashboard/item/user/${id}`,

    editDashboardItemUserMultiply: () => `${apiEfficiencyUrl()}/dashboard/item/user/multiple`,

    getUserAvailableDashboardList: () => `${apiEfficiencyUrl()}/user-dashboard/available`,

};

export default apiUrls;
