import { useEffect, useState } from 'react';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import {
    loadRoadSectionCategories,
    loadRoadSectionTypes,
    loadStatusesRoadSection,
} from 'modules/InfrastructureObjects/redux/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filters = ({ setParams }) => {
    const roadSectionTypes = useStoreProp(loadRoadSectionTypes, 'roadNetworks', 'roadSectionTypes');
    const roadSectionCategories = useStoreProp(loadRoadSectionCategories, 'roadNetworks', 'roadSectionCategories');
    const statusesList = useStoreProp(loadStatusesRoadSection, 'roadNetworks', 'statusesRoadSection');

    const [sectionTypes, setSectionTypes] = useState(roadSectionTypes);

    const initialState = {
        name: '',
        description: '',
        address_text: '',
        category_list: [],
        type_list: [],
        start_at: null,
        end_at: null,
        status: '',
    };

    const [filterValues, setFilterValues] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isNotDisabledSaveButton, setIsNotDisabledSaveButton] = useState(false);

    useEffect(() => {
        if (filterValues?.category_list[0] === '1') {
            setSectionTypes({
                1: roadSectionTypes['1'],
                2: roadSectionTypes['2'],
                3: roadSectionTypes['3'],
            });
        } else if (filterValues?.category_list[0] === '2') {
            setSectionTypes({
                4: roadSectionTypes['4'],
            });
        } else {
            setSectionTypes(roadSectionTypes);
        }
    }, [filterValues?.category_list, roadSectionTypes]);

    const getPrepareData = (filters) => removeEmptyFields({
        ...filters,
        start_at: dateWithDashYYYYMMDD(filters.start_at),
        end_at: dateWithDashYYYYMMDD(filters.end_at),
    }, false);


    const handleChange = (value, key ) => {        
        setFilterValues({ ...filterValues, [key]: value });

        setIsNotDisabledSaveButton(Object.keys(getPrepareData({ ...filterValues, [key]: value })).length > 0 ? true : false);
        setIsDisabled(false);
    };

    const search = (filters = filterValues) => {
        const prepareData = getPrepareData(filters);

        setParams({ page: 1, data: prepareData });
        setIsDisabled(true);

        setIsNotDisabledSaveButton(Object.keys(prepareData).length > 0 > 0 ? true : false);
    };

    const resetFilter = (needRefresh) => {
        setFilterValues(initialState);
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
        setIsNotDisabledSaveButton(false);
        setIsDisabled(true);
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') search();
    };

    const updateFilter = (filters) => {
        setFilterValues({ // обновляем локальный стейт
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            search({ // отправляем в родителя, чтобы произошел запрос
                ...initialState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={search} 
            filter={filterValues} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            filterException={['start_at', 'end_at',]}
            saveButtonFilterNoDisabled={isNotDisabledSaveButton}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterValues.start_at}
                    valueEndDate={filterValues.end_at}
                    handleDate={(e) => handleChange(e, 'start_at')}
                    handleEndDate={(e) => handleChange(e, 'end_at')}
                    startLabel="Начало регистрации"
                    endLabel="Конец регистрации"
                    dateOnly
                />

                <TextField
                    label="Название участка"
                    value={filterValues.name || ''}
                    onChange={(e) => handleChange(e.target.value, 'name')}
                    name="name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {filterValues?.name?.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleChange('', 'name')}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>✕</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />

                <TextField
                    variant="outlined"
                    size="small"
                    value={filterValues.description || ''}
                    label={titles.DESCRIPTION}
                    onKeyPress={handleClickEnter}
                    onChange={({ target: { value } }) => handleChange(value, 'description')}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <FormControl
                    size="small"
                    variant="outlined"
                >
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={filterValues.status}
                        onChange={(event) => {
                            handleChange(event.target.value, 'status');
                        }}
                        label={titles.STATUS}
                    >
                        {/*<MenuItem value={null}>*/}
                        {/*    {titles.SELECT_STATUS}*/}
                        {/*</MenuItem>*/}
                        {statusesList?.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl
                    size="small"
                    variant="outlined"
                >
                    <InputLabel>{titles.CATEGORY}</InputLabel>
                    <Select
                        value={filterValues.category_list[0] || ''}
                        onChange={(event) => {
                            handleChange([event.target.value], 'category_list');
                        }}
                        label={titles.CATEGORY}
                    >
                        {Object.keys(roadSectionCategories).map((key) => (
                            <MenuItem key={key} value={key}>                            
                                {roadSectionCategories[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    size="small"
                    variant="outlined"
                >
                    <InputLabel>{titles.TYPE}</InputLabel>
                    <Select
                        value={filterValues.type_list[0] || ''}
                        onChange={(event) => {
                            handleChange([event.target.value], 'type_list');
                        }}
                        label={titles.TYPE}
                    >
                        {Object.keys(sectionTypes).map((key) => (
                            <MenuItem key={key} value={key}>                            
                                {sectionTypes[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    label="Адрес"
                    value={filterValues.address_text}
                    onChange={(e) =>  handleChange(e.target.value, 'address_text')}
                    //helperText="Some important text"
                    name="address_text"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {filterValues?.address_text ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleChange('', 'address_text')}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
                
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;