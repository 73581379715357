import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';

import {
    createContractEntitiesByName,
    loadContractEntitiesTypes,
    loadGovernmentContractById,
} from 'redux/GovernmentContracts/actions';
import SimpleSelect from 'components/common/SimpleSelect';
import LinkModal from 'components/common/LinkModal';

import EntityListModal from '../EntityListModal';

import type { Entity, EntityType } from '../../types';

interface LinkObjectsModalProps {
    isOpen: boolean;
    onClose: () => void;
    idGC: number;
    contractEntities: {};
    nameGC: string;
}

const LinkObjectsModal = ({
    isOpen,
    onClose,
    idGC,
    contractEntities,
    nameGC,
}: LinkObjectsModalProps) => {
    const dispatch = useDispatch();

    const [selectEndpoint, setSelectEndpoint] = useState<EntityType[]>([]);

    const [showEntities, setShowEntities] = useState(false);
    const [entities, setEntities] = useState<Entity[]>([]);
    const [linkObject, setLinkObject] = useState<EntityType>({
        api_list: '',
        key: '',
        name: '',
        service: '',
    });

    const entitiesFilter = useCallback(
        (entityList: EntityType[]) => {
            if (Object.keys(contractEntities).length > 0) {
                setSelectEndpoint(
                    entityList.filter(
                        (item: EntityType) =>
                            !Object.keys(contractEntities).includes(item.key) && item
                    )
                );
            } else {
                setSelectEndpoint(entityList);
            }
        },
        [contractEntities]
    );

    useEffect(() => {
        dispatch(loadContractEntitiesTypes(entitiesFilter));
    }, [dispatch, entitiesFilter]);

    const handleSelect = (selectLink: any) => {
        setEntities([]);
        setLinkObject(selectLink);
    };

    const handleAccept = (value: any) => {
        setEntities(value);
        setShowEntities(false);
    };

    const onAcceptModal = () => {
        dispatch(createContractEntitiesByName(
            idGC,
            linkObject?.key,
            {
                id_list: entities.map(el => el.id)
            },
            () => dispatch(loadGovernmentContractById(idGC))
        ));
        onClose();
    };

    const handleDeleteLink = (name: string) => {
        setEntities(entities.filter((el) => el.name !== name));
    };

    return (
        <LinkModal
            onClose={onClose}
            onSave={onAcceptModal}
            onChange={() => setShowEntities(true)}
            name={nameGC}
            linkObject={''}
            isOpen={isOpen}
            disabled={entities.length === 0}
            changeBtnDisable={!linkObject?.key}
            topComponent={
                <form className="modal__form">
                    <SimpleSelect
                        selected={linkObject}
                        options={selectEndpoint}
                        onChange={(value) => handleSelect(value)}
                        renderLabel={(option) => option?.name || ''}
                        label="Выбрать объект"
                    />
                </form>
            }
        >
            <form className="modal__form">
                <List className="list">
                    {entities.length > 0
                        && entities.map((el: Entity, i) => (
                            <ListItem className="list_item" key={el.name + i} divider>
                                <ListItemText>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        {++i}. {el.name}
                                        <Tooltip title="Удалить связь">
                                            <IconButton
                                                onClick={() => handleDeleteLink(el.name)}
                                                size="small"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </ListItemText>
                            </ListItem>
                        ))}
                </List>

                {showEntities && (
                    <EntityListModal
                        isOpen={showEntities}
                        onChange={handleAccept}
                        onClose={() => setShowEntities(false)}
                        selected={entities}
                        url={`${linkObject?.service}${linkObject?.api_list}`}
                        entityName={linkObject?.name}
                        idGC={idGC}
                    />
                )}
            </form>
        </LinkModal>
    );
};

export default LinkObjectsModal;
