import React from 'react';
import { Paper, Typography } from '@mui/material';
import cn from 'classnames';

import styles from './infoBlock.module.scss';

interface InfoBlockProps {
    children: React.ReactNode;
    label?: string;
    mt?: React.CSSProperties['marginTop'];
    style?: React.CSSProperties;
    className?: string;
}

// Блок для отрисовки информации с заголовком и border.
function InfoBlock({ label, mt, children, style, className }: InfoBlockProps) {
    return (
        <Paper
            className={cn(styles.paper, className)}
            style={{ marginTop: mt, ...style }}
            variant="outlined"
        >
            {label
                 && <Typography className={styles.label}>
                     {label}
                 </Typography>
            }

            <div>{children}</div>
        </Paper>
    );
}

export default InfoBlock;
