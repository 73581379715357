import { loadDefectStatuses } from 'redux/RoadWorks/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import useStoreProp from 'helpers/hooks/useStoreProp';
import { getHumanDate } from 'helpers/date.config';
import colorExtend from 'helpers/mapHelper/colorExtend';
import MapScroll from 'components/MapComponents/MapScroll';
import Information from 'components/common/Information';
import { createIcon, MapPopUp }  from 'components/MapComponents/MapPopUp';

import config from '../config';
import { getDefaultColor } from '../helper';

const PopUp = ({
    isOpen,
    // history,
    data,
    onClose,
}) => {
    // забираем данные
    const defectStatuses = useStoreProp(loadDefectStatuses, 'roadworks', 'defectStatuses');

    const titleObj = {
        name: {
            title: titles.NAME,
        },
        comment: {
            title: titles.COMMENT,
        },
        status: {
            title: 'Статус',
            value: ({ status, status_text }) => {
                const statusObject = defectStatuses?.find(({ status_id }) => status_id === status) || null;

                if (statusObject) {
                    return (
                        <span style={{ color: statusObject.color }}>{statusObject.name}</span>
                    );
                } else {
                    return (
                        <span>{status_text || messages.NO_DATA}</span>
                    );
                }
            },
        },
        address_text: {
            title: 'Адрес',
            value: ({ address_text }) => address_text
                ? address_text
                : messages.INFO_IS_NOT_FOUND
        },
        liquidated_at: {
            title: 'Фактическая дата устранения',
            value: ({ liquidated_at }) => liquidated_at
                ? getHumanDate(liquidated_at)
                : messages.INFO_IS_NOT_FOUND
        },
        organization_id: {
            title: 'Организация',
            value: ({ organization }) => organization
                ? organization.title
                : messages.INFO_IS_NOT_FOUND
        },
        group_id: {
            title: 'Группа',
            value: ({ group }) => group
                ? group?.name
                : messages.INFO_IS_NOT_FOUND
        },
        type_id: {
            title: 'Тип',
            value: ({ type }) => type
                ? type?.name
                : messages.INFO_IS_NOT_FOUND
        },
    };

    const {
        name = '',
        type = null,
    } = data || {};

    return (
        <MapPopUp
            isOpen={isOpen}
            title={name || ''}
            icon={createIcon(config.layerIcon)}
            onClose={onClose}
            titleBackgroundColor={type?.color
                ? colorExtend(type?.color)
                : getDefaultColor()
            }
            medium
        >
            <MapScroll>
                <Information
                    data={data}
                    title={titleObj}
                />
            </MapScroll>
        </MapPopUp>
    );
};

export default PopUp;
