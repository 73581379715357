import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MapPopUp } from 'components/MapComponents/MapPopUp';
import { metroSelectors } from 'modules/Metro';

import icon from '../icons/icon';

import Info from './Info';

const PopUp = ({
    id,
    isOpen,
    onClose,
}) => {

    const polygon = useSelector(metroSelectors.vestibulePolygon);
    const data = useMemo(() => polygon.find((item) => item.id === id) || {}, [id, polygon]);

    const {
        // id,
        name = '',
        color_load = '',
    } = data || {};

    return (
        <>
            <MapPopUp
                isOpen={isOpen}
                icon={
                    <span
                        className="inner-icon"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                }
                title={name}
                titleBackgroundColor={color_load}
                onClose={onClose}
                // medium
            >
                <Info id={id} />
            </MapPopUp>
        </>
    );
};

export default PopUp;
