import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setMapLoading } from 'redux/Map/actions';
import * as types from 'modules/HeatMapCongestions/redux/types';
import * as actions from 'modules/HeatMapCongestions/redux/actions';
import api from 'modules/HeatMapCongestions/redux/api.methods';

function* loadCongestionTypesSaga() {
    const response = yield call(api.getCongestionTypes);
    if (response?.success) {
        yield put(actions.loadedCongestionTypes(response.data));
    }
}

function* loadCongestionsHeatMapSaga({ payload }) {
    yield put(actions.loadingCongestionsHeatMap(true));
    const response = yield call(api.getCongestionsHeatMap, { ...payload });
    if (response?.success) {
        yield put(actions.loadedCongestionsHeatMap(response.data));
    }
    yield put(actions.loadingCongestionsHeatMap(false));
}

function* loadCongestionsHeatMapSummarySaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.getCongestionsHeatMapSummary, { ...payload });
    if (response?.success) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}

function* loadCongestionsPolygonSaga({ payload }) {
    yield put(setMapLoading('congestion-polygon', true));
    const response = yield call(api.getCongestionPolygon, payload);
    if (response?.success) {
        yield put(actions.loadedCongestionsPolygon(response.data || []));
    }
    yield put(setMapLoading('congestion-polygon', false));
}

function* loadCongestionsSidebarSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.getCongestionList, payload);
    if (response?.success) {
        yield fnCallback?.({ data: response.data || [], meta: response.meta || {} });
    }
    yield fnLoading?.(false);
}

function* сongestionMarkRegularSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.сongestionMarkRegular, payload);
    if (response?.success) {
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_CONGESTION_TYPES, loadCongestionTypesSaga),
        takeLatest(types.LOAD_CONGESTIONS_HEAT_MAP, loadCongestionsHeatMapSaga),
        takeLatest(types.LOAD_CONGESTIONS_HEAT_MAP_SUMMARY, loadCongestionsHeatMapSummarySaga),

        takeLatest(types.LOAD_CONGESTION_POLYGON, loadCongestionsPolygonSaga),
        takeLatest(types.LOAD_CONGESTION_SIDEBAR, loadCongestionsSidebarSaga),

        takeLatest(types.CONGESTION_MARK_REGULAR, сongestionMarkRegularSaga),
    ]);
}
