import React from 'react';

import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import { buttonsTypes } from 'components/common/FormButtons';

interface HistoryItemInfoProps {
  item: any | null;
}

const fieldDateFormat = [
    'Время регистрации',
    'Дата решения план',
    'Дата решения факт',
];

const fieldGeoFormat = [
    'Геометрия',
];

const formatData = (name: string, data: any) => {
    switch (!!name) {
        case fieldGeoFormat.includes(name):
            return 'Тут будет гео';

        case typeof data !== 'string':
            return messages.NO_DATA;

        case fieldDateFormat.includes(name):
            return fullDateTime(data);

        default:
            return data;
    }
};

export const HistoryItemInfo = ({ item }: HistoryItemInfoProps) => {
    const geoData = item.data.find((el: any) => fieldGeoFormat.includes(el.name));
    const itemDataArray = item.data.filter((el: any) => !fieldGeoFormat.includes(el.name));

    return (
        <div>
            <div>
                {
                    itemDataArray.map((el: any) => <div key={el.name}>
                        <h3>{el.name}</h3>
                        <div>
                            <i>Новое значение</i> {' - '} <span>{formatData(el.name, el.new)}</span>
                        </div>
                        <div>
                            <i>Старое значение</i> {' - '} <span>{formatData(el.name, el.old)}</span>
                        </div>
                        
                    </div>)
                }
                {
                    geoData && <div>
                        <h3>{geoData.name}</h3>
                        <div>
                            <i>Значения</i>{' - '}

                            <FieldsModal
                                title={geoData.name}
                                //@ts-ignore
                                buttonType={buttonsTypes.mapIcon}
                            >
                                <MapGeoJson
                                    visibleGeometry={[geoData.new, {
                                        ...geoData.old,
                                        style: {
                                            color: 'red',
                                        }
                                    }]}
                                    circle={false}
                                    polygon={false}
                                    polyline={false}
                                    marker={false}
                                    isHideValidation
                                />
                            </FieldsModal>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};
