import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from '@mui/material';

import { changePassword } from 'redux/Auth/actions.js';
import { clearValidation, setValidation } from 'redux/Validation/actions';
import { authSelectors } from 'redux/Auth/index.js';
import PasswordInput from 'components/common/PasswordInput/PasswordInput';
import Loading from 'components/common/Loading';
import FormButtonsComponent from 'components/common/FormButtons/FormButtonsComponent.js';
import buttonsTypes from 'components/common/FormButtons/buttonsTypes';
import Modal from 'components/common/Modal';

const formFields = [
    { label: 'Старый пароль', name: 'old_password' },
    { label: 'Новый пароль', name: 'password' },
    { label: 'Повторить новый пароль', name: 'password_confirmation' },
];

const ChangePasswordModal = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({
        old_password: '',
        password: '',
        password_confirmation: '',
        showold_password: false,
        showpassword: false,
        showpassword_confirmation: false,
    });

    const loading = useSelector(authSelectors.loading);
    const validation = useSelector(({ validation }) => validation);

    useEffect(() => {
        dispatch(clearValidation());
    }, [dispatch]);

    const clearData = () => {
        setValues({
            old_password: '',
            password: '',
            password_confirmation: '',
        });
    };

    const handleSend = (e) => {
        const { old_password, password, password_confirmation } = values;
        e.preventDefault();
        dispatch(clearValidation());
        dispatch(
            changePassword(
                {
                    old_password,
                    password,
                    password_confirmation,
                },
                () => {
                    clearData();
                    onClose();
                }
            )
        );
    };

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
        if (validation[name]) delete validation[name];
        dispatch(
            setValidation({
                ...validation,
            })
        );
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Сменить пароль"
            showCloseInTitle
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.save,
                            onClick: handleSend,
                            disabled: Object.keys(validation).length > 0
                        }
                    ]}
                />
            }
        >
            <FormGroup sx={{ gap: '1rem' }}>
                {formFields.map(({ label, name }) => (
                    <PasswordInput
                        key={name}
                        value={values[name]}
                        name={name}
                        label={label}
                        validation={!!validation[name]}
                        helperText={validation[name]}
                        onChange={handleChange}
                    />
                ))}
            </FormGroup>
            {loading && <Loading circular={true}/>}
        </Modal>
    );
};

export default ChangePasswordModal;
