import React, { useState } from 'react';
import {
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material/';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import FormInfoWrapper from 'components/common/FormInfoWrapper';

type PasswordInputProps = {
  value: string;
  name: string;
  label: string;
  helperText?: string;
  validation?: boolean;
  className?: string;
  onChange: (e?: any) => void;
}

const PasswordInput = ({
    value = '',
    name,
    label,
    helperText = '',
    validation = true,
    className = 'row__item',
    onChange
}: PasswordInputProps) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormInfoWrapper
            error={validation}
            helperText={helperText}
        >
            <TextField
                className={className}
                size="small"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                value={value}
                name={name}
                onChange={onChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position={'end'}>
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge={'end'} size="large">
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                label={label}
            />
        </FormInfoWrapper>
    );
};

export default PasswordInput;