import instance from 'helpers/axios.config';
import apiUrls from 'modules/HeatMapCongestions/redux/api.urls';

const ApiIncidentsMethods = {
    getCongestionTypes: async () => {
        const response = await instance.get(apiUrls.getCongestionTypes());
        return response.data;
    },

    getCongestionsHeatMap: async (data) => {
        const response = await instance.post(apiUrls.getCongestionsHeatMap(), data);
        return response.data;
    },

    getCongestionsHeatMapSummary: async (data) => {
        const response = await instance.post(apiUrls.getCongestionsHeatMapSummary(), data);
        return response.data;
    },

    getCongestionPolygon: async (data) => {
        const response = await instance.post(apiUrls.getCongestionPolygon(), data);
        return response.data;
    },

    getCongestionList: async (data) => {
        const response = await instance.post(apiUrls.getCongestionList(), data);
        return response.data;
    },

    сongestionMarkRegular: async (uuid) => {
        const response = await instance.patch(apiUrls.сongestionMarkRegular(uuid));
        return response.data;
    },
};


export default ApiIncidentsMethods;
