import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { meteoSelectors } from 'redux/Meteo';
import { loadDeviceStatuses, wsDataMeteo } from 'redux/Meteo/actions';
import * as actions from 'redux/Meteo/actions';
import mapHelper from 'helpers/mapHelper';
import { useWsActive, useWsSubscribe } from 'helpers/ws/hooks';
import { useStoreFromSelector } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    Marker,
} from 'components/MapComponents/leaflet';
import MapLegends from 'components/common/Transport/MapLegends';
import { createIcon, MapPopUp, MapPopUpListener }  from 'components/MapComponents/MapPopUp';

import { createIconMarker } from './helper';
import MeteoPopup from './PopUp';
import config from './config.js';
import Legend from './Legend';


const Layer = (props) => {
    const { map, readOnly = false, visibleLegend = false } = props;
    const dispatch = useDispatch();

    const types = useSelector(meteoSelectors.types);
    const polygon = useSelector(meteoSelectors.polygon);
    const active = useSelector(meteoSelectors.active);

    const statuses = useStoreFromSelector(loadDeviceStatuses, meteoSelectors.deviceStatuses);
    const statusesObj = useMemo(() => statuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [statuses]);

    useWsActive((activeData) => {
        dispatch(actions.setActiveMeteo({
            ...activeData,
            external_id: activeData.extenal_id,
        }));
    });

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygonMeteo(polygon));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useWsSubscribe('meteologe_telemetry_v2', (events) => {
        if (events?.length > 0) {
            dispatch(wsDataMeteo(events));
        }
    });

    useEffect(() => {
        if (Object.keys(types).length === 0) {
            dispatch(actions.getDeviceTypesMeteo());
        }

        fetchPolygon();

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        // setMapToMarker();

        return () => {
            dispatch(actions.resetActiveMeteo());
            dispatch(actions.clearForPolygonMeteo());
            map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (active?.lat && active?.lon) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    return (
        <>
            {polygon.map((item) => {
                const { external_id, lat, lon, status, status_color } = item;
                const color = colorExtend(status_color || statusesObj[status]?.color);

                const componentProps = {
                    ...props,
                    key: `item_${external_id}`,
                    attribution: {
                        slug: config.slug,
                        color
                    },
                    onClick: (latlng) => {
                        map.setView(latlng);
                        dispatch(actions.setActiveMeteo(item));
                    },
                };

                return (
                    <Marker
                        {...componentProps}
                        latlng={[lat, lon]}
                        icon={createIconMarker(color)}
                    />
                );
            })}

            {/* модалка */}
            <MapPopUpListener
                activeSelector={meteoSelectors.active}
                polygonSelector={meteoSelectors.polygon}
            >
                <MapPopUp
                    title={({ name = '' }) => name}
                    icon={createIcon(config.layerIcon)}
                    onClose={() => {
                        dispatch(actions.resetActiveMeteo());
                    }}
                    titleBackgroundColor={({ status_color, status }) => colorExtend(status_color || statusesObj[status]?.color)}
                    small
                >
                    <MeteoPopup readOnly={readOnly}/>
                </MapPopUp>
            </MapPopUpListener>

            <MapLegends
                layer={config.slug}
                visibleRequired={visibleLegend}
            >
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
