import { fullDateTime } from 'helpers/date.config';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';

import type { ItemProps } from './types';

const Item = ({ item, userTypes }: ItemProps) => {

    return (
        <LSContentItem>
            <LSContentColumn>
                {fullDateTime(item.date_at) || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.user?.first_name} {item?.user?.last_name}
            </LSContentColumn>
            <LSContentColumn>
                {item?.microservice || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.action || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.user?.type_account ? userTypes[item?.user?.type_account].name : messages.NO_VALUE}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
