import { config } from 'config';

import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';

import style from './style.module.scss';

const Graphics = () => {

    const apiSuperSetUrl = config.get('urls').apiSuperSetUrl || null;

    const renderContent = () => (
        <>
            {apiSuperSetUrl
                ? (
                    <iframe
                        title="Dashboard"
                        name="graphics"
                        height="100%"
                        width="100%"
                        className={style.frame}
                        // ссылка на страницу графиков
                        src={apiSuperSetUrl}
                        sandbox="allow-same-origin allow-scripts"
                    ></iframe>
                )
                : (
                    <div className={style.noData} >{messages.NO_DATA}</div>
                )
            }
        </>
    );

    return (
        <PageLayout
            // header="Графики"
            content={renderContent}
            customStyles={{
                padding: 0,
                margin: 0,
            }}
        />
    );
};

export default Graphics;
