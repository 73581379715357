import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteEvent } from 'modules/React/redux/actions';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import ModalForm from './ModalForm';

const Item = ({ item }) => {
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(deleteEvent(item?.id));
        setIsDelete(false);
    };

    return (
        <div className="list_item">
            <LSContentItem>
                <LSContentColumn>
                    { item?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.date_start ?  fullDateTimeWithoutSeconds(item?.date_start) : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.date_end ? fullDateTimeWithoutSeconds(item?.date_end) : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsEditMode(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                            },
                        ]}
                        noMarginLeft
                        justButton
                    />
                </LSContentColumn>
            </LSContentItem>

            { isEditMode
            && <ModalForm
                isOpen={isEditMode}
                onClose={setIsEditMode}
                item={item}
                isNew={false}
            />}
            { isDelete
            && <ConfirmDelete
                open={isDelete}
                onClose={()=> setIsDelete(false)}
                onSuccess={handleDelete}
                message={messages.CONFIRM_DELETE}
            />}
        </div>
    );
};

export default Item;
