import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import mapHelper from 'helpers/mapHelper';
import { useDebounce } from 'helpers/hooks';
import { useWsSubscribe } from 'helpers/ws/hooks';
import {
    Marker,
    PopUp,
    ToolTip,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { usePopModalListenerList } from 'components/MapComponents/MapPopUp';
import * as actions from 'modules/TransportWater/redux/actions';
import * as selectors from 'modules/TransportWater/redux/selectors';

import { getAnchorMarker, getFilter, isShowStations } from './helper';
import config from './config.js';
import Markers from './Markers';
import PopUpPierModal from './PopUpPierModal';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const active = useSelector(selectors.active);
    const isActive = Object.keys(active).length > 0;

    const filters = useSelector(selectors.filters);
    const isShowByActive = Object.keys(active).length > 0;

    // отображать остановки по зуму
    const [visibleZoom, setVisibleZoom] = useState(isShowStations(map.getZoom()));
    // отображать остановки
    const isVisibleStations = useMemo(() => !isActive && visibleZoom, [isActive, visibleZoom]);

    const [data, setData] = useState([]);

    const popupListener = usePopModalListenerList(data);

    // изменение статуса станции
    useWsSubscribe('transport-water_dock_moored_telemetry_v2', (events) => {
        // преобразуем в виду id: цвет
        const tmp = events.reduce((r, item) => {
            const {
                dock_id_list = [],
                color = '',
            } = item;
            if (dock_id_list.length > 0 && color) {
                dock_id_list.forEach(id => {
                    r[id] = color;
                });
            }
            return r;
        }, {});

        dispatch(actions.setDockColorRefresh(tmp));

        const tmpKeys = Object.keys(tmp);

        // заменяем цвет
        setData(old => old.map(station => tmpKeys.includes(station.id)
            ? { ...station, color: tmp[station.id] }
            : station
        ));
    });

    // грузим полигон
    const fetchPolygon = useCallback(() => {
        if (isVisibleStations) {
            const filter = getFilters(filters, getFilter);
            const polygon = mapHelper.getGeometryPolygon(map);
            dispatch(actions.loadDockPolygon(
                {
                    polygon,
                    ...filter
                },
                null,
                (data) => {
                    setData(data.data);
                }
            ));
        }
    }, [isVisibleStations, isShowByActive, filters]);

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => {
        const isVisible = isShowStations(map.getZoom());
        setVisibleZoom(isVisible);

        if (isVisible) {
            debounceFetchPolygon();
        } else {
            debounceFetchPolygon.clear();
            setData([]);
        }
    };

    // обновление по фильтру
    useEffect(() => {
        fetchPolygon();
    }, [filters, fetchPolygon]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [map, handleFetchPolygon]);

    useEffect(() => () => {
        setData([]);
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        const { lat = null, lon = null, isNoMove = false } = active;
        if (lat && lon) {
            if (!isNoMove) {
                map.setView([lat, lon], 16);
            }
            popupListener.setModal(active);
        }
    }, [active]);

    const createItem = (item) => {
        const { id, lat, lon, color, name } = item;
        const componentProps = {
            ...props,
            key: `${config.slug}_${id}`,
            // для кластера
            attribution: {
                slug: config.slug,
                color: color
            },
            onClick: (latlng) => {
                map.setView(latlng);
            },
        };

        return (
            <Marker
                {...componentProps}
                latlng={[lat, lon]}
                icon={getAnchorMarker(color)}
                onClick={() => {
                    map.setView([item.lat, item.lon]);
                    popupListener.setModal(item);
                }}
            >
                <ToolTip
                    offset={[0, -40]}
                    direction="top"
                >
                    <div>{name}</div>
                </ToolTip>
            </Marker>
        );
    };

    // активный объект
    const activeMarker = useMemo(() => {
        return Object.keys(active).length > 0
            ? createItem(active)
            : null;
    }, [active]);

    // список
    const polygonData = useMemo(() => {
        return (Object.keys(active).length > 0
            ? data?.filter(({ id }) => id !== active.id)
            : data)?.map((item) => createItem(item));
    }, [data, active]);

    return (
        <>
            {polygonData}
            {activeMarker}

            <Markers {...props} />

            {/* popup pier */}
            {popupListener.isOpen && (
                <PopUpPierModal
                    isOpen={popupListener.isOpen}
                    item={popupListener.modal}
                    onClose={() => {
                        popupListener.clear();
                        dispatch(actions.setActive({}));
                    }}
                />
            )}
        </>
    );
};

export default Layer;
