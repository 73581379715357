import React, { useState } from 'react';

import { loadComplexTypes } from 'redux/DorisControl/actions';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import SelectDuploCodes from 'components/common/Autocomplete/DorisControl/DuploCodes';

import type { IComplexType, IFilterData } from './types';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{page: number, data: {}}>>,
    test_id_prefix: string
}

const initialState: IFilterData = { 
    complex_types: [],
    duplo_codes: []
};

const Filter = ({ setParams, test_id_prefix = '' }: FilterProps) => {
    const complexTypes = useStoreProp(loadComplexTypes, 'dorisControl', 'doris_complex_types');

    const [data, setData] = useState(initialState);

    const handleChange = (name: string, value: any) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (filters = data) => {
        const prepareData = {
            complex_types: filters.complex_types.map(({ id }) => id),
            duplo_codes: filters.duplo_codes.map(({ id }) => id)
        };
        setParams({ page: 1, data: removeEmptyFields(prepareData) });
    };

    const resetFilter = (needRefresh: boolean) => {
        needRefresh && onSearch(initialState);
        setData(initialState);
    };

    const updateFilter = (filters: IFilterData) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            test_id_prefix={test_id_prefix}
        >
            <LayoutFilter.Visible>
                <CommonAutocomplete<IComplexType>
                    multiple={true}
                    selected={data.complex_types}
                    onChange={(value) => handleChange('complex_types', value)}
                    onReset={() => handleChange('complex_types', [])}
                    inputName={titles.COMPLEX_TYPE}
                    limitTags={1}
                    label={titles.COMPLEX_TYPE}
                    options={complexTypes}
                    filterSelectedOptions={true}
                    renderLabel={(option) => option?.name  || ''}
                    showAsSelect
                    test_id_prefix={`${test_id_prefix}:filter/complex_types`}
                />

                <SelectDuploCodes
                    multiple={true}
                    selected={data.duplo_codes}
                    onChange={(value) => handleChange('duplo_codes', value)}
                    test_id_prefix={`${test_id_prefix}:filter/duplo_codes`}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
