import MapScroll from 'components/MapComponents/MapScroll';

import Information from './Information';

const PopUp = ({
    data,
    readOnly = false,
    statusesObject = {},
}) => {
    return (
        <MapScroll>
            <Information
                data={data}
                readOnly={readOnly}
                statusesObject={statusesObject}
            />
        </MapScroll>
    );
};

export default PopUp;
