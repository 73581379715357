import Infrastructure from '../../AddModal/Infrastructure';

import type { Incident, ObjectTypes } from '../../types';

interface InfrastructureObjectsProps {
    objectTypes: ObjectTypes;
    item: Incident;
}

const InfrastructureObjects = ({ objectTypes, item }: InfrastructureObjectsProps) => {

    return (
        <Infrastructure
            item={item}
            objectTypes={objectTypes}
        />
    );
};

export default InfrastructureObjects;
