import * as types from './types';


export const loadNodes = (params, onCallback, onLoading) => ({
    type: types.LOAD_NODES,
    payload: params,
    onCallback,
    onLoading
});

export const loadingNodes = (bool) => ({
    type: types.LOADING_NODES,
    payload: bool,
});

export const loadedNodes = (data) => ({
    type: types.LOADED_NODES,
    payload: data,
});

export const createNodes = (data, onCallback, onLoading) => ({
    type: types.CREATE_NODES,
    payload: data,
    onCallback,
    onLoading
});

export const editNodes = (id, data, onCallback, onLoading) => ({
    type: types.EDIT_NODES,
    payload: { id, data },
    onCallback,
    onLoading
});

export const deleteNodes = (id, onCallback, onLoading) => ({
    type: types.DELETE_NODES,
    payload: { id },
    onCallback,
    onLoading
});

export const loadSidebar = (filter, fnCallback, fnLoading) => ({
    type: types.LOAD_SIDEBAR,
    payload: filter,
    fnLoading,
    fnCallback
});

export const loadPolygon = (filter, fnCallback, fnLoading) => ({
    type: types.LOAD_POLYGON,
    payload: filter,
    fnLoading,
    fnCallback
});

export const setFilters = (filter = {}) => ({
    type: types.SET_FILTERS,
    payload: filter,
});

export const setActive = (active = {}) => ({
    type: types.SET_ACTIVE,
    payload: active,
});
export const clearActive = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

export const loadTypes = () => ({
    type: types.LOAD_TYPES,
});

export const loadedTypes = (data) => ({
    type: types.LOADED_TYPES,
    payload: data,
});

export const setSaved = (bool = false) => ({
    type: types.SET_SAVED,
    payload: bool,
});
export const loadPopup = (id, filter, fnCallback, fnLoading) => ({
    type: types.LOAD_POPUP,
    payload: {
        id,
        filter,
    },
    fnCallback,
    fnLoading,
});

export const setDeleteForm = (data = null) => ({
    type: types.SET_DELETE_FORM,
    payload: data,
});
export const setEditForm = (data = null) => ({
    type: types.SET_EDIT_FORM,
    payload: data,
});

export const loadEdges = (params) => ({
    type: types.LOAD_EDGES,
    payload: params,
});

export const loadingEdges = (bool) => ({
    type: types.LOADING_EDGES,
    payload: bool,
});

export const loadedEdges = (data) => ({
    type: types.LOADED_EDGES,
    payload: data,
});

export const loadEdgerById = (id, fnCallback, fnLoading) => ({
    type: types.LOAD_EDGES_BY_ID,
    payload: id,
    fnCallback,
    fnLoading,
});

export const loadEdgerInfoById = (id, fnCallback, fnLoading) => ({
    type: types.LOAD_EDGES_INFO_BY_ID,
    payload: id,
    fnCallback,
    fnLoading,
});

export const loadNodeById = (id, fnCallback, fnLoading) => ({
    type: types.LOAD_NODE_BY_ID,
    payload: id,
    fnCallback,
    fnLoading,
});

export const loadEdgeTypes = (params) => ({
    type: types.LOAD_EDGE_TYPES,
    payload: params,
});

export const loadingEdgeTypes = (bool) => ({
    type: types.LOADING_EDGE_TYPES,
    payload: bool,
});

export const loadedEdgeTypes = (data) => ({
    type: types.LOADED_EDGE_TYPES,
    payload: data,
});

export const loadEdgeClasses = (params) => ({
    type: types.LOAD_EDGE_CLASSES,
    payload: params,
});

export const loadingEdgeClasses = (bool) => ({
    type: types.LOADING_EDGE_CLASSES,
    payload: bool,
});

export const loadedEdgeClasses = (data) => ({
    type: types.LOADED_EDGE_CLASSES,
    payload: data,
});

export const loadingButton = (bool) => {
    return ({
        type: types.LOADING_BUTTON,
        payload: bool,
    });
};

export const createEdge = (data, fnCallback, fnLoading) => ({
    type: types.CREATE_EDGE,
    payload: data,
    fnCallback,
    fnLoading
});

export const editEdge = (id, data, fnCallback, fnLoading) => ({
    type: types.EDIT_EDGE,
    payload: { id, data },
    fnCallback,
    fnLoading
});

export const deleteEdge = (id, fnCallback, fnLoading) => ({
    type: types.DELETE_EDGE,
    payload: { id },
    fnCallback,
    fnLoading
});

// Получить привязку объекта
// object_type string Тип объекта
// object_id string Идентификатор объекта
// eputs_id integer Идентификатор объекта
// eputs_type string Тип объекта
// eputs_project string Название объекта
export const getObjectBindings = (params = {}, fnCallback, fnLoading) => ({
    type: types.LOAD_OBJECT_BINDINGS,
    payload: params,
    fnCallback,
    fnLoading
});

// Получить все привязки объектов
// page integer Номер страницы списка привязок объектов
// per_page integer Количество возвращаемых объектов на страницу
// object_type string Фильтр по типу объекта (например, node или edge)
// object_id string UUID объекта, для фильтрации привязок
// eputs_type string Фильтр по типу объекта в системе EPUTS
// eputs_project string Фильтр по проекту в системе EPUTS
// eputs_id integer Фильтр по идентификатору в системе EPUTS
// from_created_at string Фильтр по времени создания (начало диапазона)
// to_created_at string Фильтр по времени создания (конец диапазона)
// from_updated_at string Фильтр по времени обновления (начало диапазона)
// to_updated_at string Фильтр по времени обновления (конец диапазона)
export const getObjectBindingsAll = (params = {}, fnCallback, fnLoading) => ({
    type: types.LOAD_OBJECT_BINDINGS_ALL,
    payload: params,
    fnCallback,
    fnLoading
});

// Получить объекты рядом
// lat
// lon
// radius
export const getObjectBindingsNearly = (params = {}, fnCallback, fnLoading) => ({
    type: types.LOAD_OBJECT_BINDINGS_NEARLY,
    payload: params,
    fnCallback,
    fnLoading
});

// Создать привязку объекта
// "object_type": "node",
// "object_id": "0192e8e1-3167-740d-b6b6-e3e686014d28",
// "eputs_type": "infrastructure",
// "eputs_id": 4,
// "eputs_project": "98_spb"
export const createObjectBindings = (data = {}, fnCallback, fnLoading) => ({
    type: types.CREATE_OBJECT_BINDINGS,
    payload: data,
    fnCallback,
    fnLoading
});

// Обновить привязку объекта
// id integer Идентификатор привязки объекта
// {
//     "object_type": "node",
//     "object_id": "0192e8e1-3167-740d-b6b6-e3e686014d28",
//     "eputs_type": "infrastructure",
//     "eputs_id": 4,
//     "eputs_project": "98_spb"
// }
export const updateObjectBindings = (id, data = {}, fnCallback, fnLoading) => ({
    type: types.UPDATE_OBJECT_BINDINGS,
    payload: {
        id,
        data,
    },
    fnCallback,
    fnLoading
});

// Удалить привязку объекта
// id integer Идентификатор привязки объекта
export const deleteObjectBindings = (id, fnCallback, fnLoading) => ({
    type: types.DELETE_OBJECT_BINDINGS,
    payload: id,
    fnCallback,
    fnLoading
});
