import { FilterVFComplex } from 'components/pages/Dictionaries/DorisControl/VFComplex/FilterVFComplex';

const Filter = ({ data, onChange, complexStatuses }) => {
    return <form
        className="modal__form"
        onSubmit={(e) => {
            e.preventDefault();
        }}
    >
        <FilterVFComplex
            setParams={onChange}
            statuses={complexStatuses}
            test_id_prefix = ""
            sidebarFiler={data}
            isSidebar
        />
    </form>;
};

export default Filter;
