import createIconCluster from 'components/MapComponents/helpers/createIconCluster';

import { getLayerIcon, moduleName } from './helper';

const config = {
    name: 'Скопления НГПТ',
    slug: moduleName,
    layerIcon: getLayerIcon,
    clusterIcon: () => createIconCluster(getLayerIcon),
};

export default config;
