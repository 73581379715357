import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { isEqual } from 'lodash';

import { usePrevious } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import Loading from 'components/common/Loading';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { loadCongestionsSidebar, setNgptActive, setNgptFilter } from 'modules/HeatMapCongestions/redux/actions';
import { heatMapCongestionsSelectors } from 'modules/HeatMapCongestions';

import { getFilter, getMarkerColor, getMarkerIcon } from '../helper';
import config from '../config';

import Form from './Form';

const SideBar = () => {
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const [data, setData] = useState([]);
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(false);

    const filters = useSelector(heatMapCongestionsSelectors.ngptFilters);
    const active = useSelector(heatMapCongestionsSelectors.ngptActive);
    const ngptSaved = useSelector(heatMapCongestionsSelectors.ngptSaved);
    const prevFilters = usePrevious(filters);

    const activeId = active?.uuid ?? 0;

    const fetchList = () => {
        const filter = getFilters(filters, getFilter);

        dispatch(loadCongestionsSidebar(
            {
                // page: params.page,
                // limit: params.limit,
                ...filter,
            },
            (request) => {
                const {
                    data = [],
                    meta = {},
                } = request;

                setData(data);

                // setData(old => params.page === 1
                //     ? data
                //     : [...old, ...data]
                // );
                setMeta(meta);
            },
            setLoading
        ));
    };

    // const handleScrollUpdate = (values) => {
    //     const {
    //         last_page = 0
    //     } = meta || {};
    //     if (
    //         values.top > 0.95
    //         && loading === false
    //         && params.page < last_page
    //     ) {
    //         setParams(old => ({
    //             ...old,
    //             page: old.page + 1,
    //         }));
    //     }
    // };

    const resetParams = () => {
        setParams(prev => ({ ...prev, page: 1 }));
    };

    useEffect(() => () => {
        setData([]);
        setMeta({});
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            resetParams();
        } else {
            fetchList();
        }
    }, [params, filters, prevFilters]);

    useEffect(() => {
        if (ngptSaved) {
            fetchList();
        }
    }, [ngptSaved]);

    const renderList = (dataList) => (
        dataList.map((item) => {
            return (
                <SidebarItem
                    key={item.uuid}
                    active={activeId === item.uuid}
                    icon={<i className={getMarkerIcon} style={{ color: getMarkerColor, fontSize: '1.5rem' }} />}
                    onClick={() => {
                        if (activeId) {
                            dispatch(setNgptActive({}));
                            setTimeout(() => {
                                dispatch(setNgptActive(item));
                            }, 300);
                        } else {
                            dispatch(setNgptActive(item));
                        }
                    }}
                >
                    <div><strong>Id: </strong>{item.uuid}</div>
                    <div><strong>{titles.DATE_TIME_CREATED}: </strong>{getFullDateTimeWithTextMonth(item.created_at)}</div>
                    <div><strong>{titles.TYPE}: </strong>{item.type_id_text}</div>
                    <div><strong>Колличество ТС: </strong>{item.vehicle_count}</div>
                    {item.is_regular && <div><strong>Регулярное</strong></div>}
                </SidebarItem>
            );
        })
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={resetParams}
                onSetFilter={(filter) => dispatch(setNgptFilter(filter))}
                onClearFilter={() => dispatch(setNgptFilter())}
                layer={config.slug}
                content={({ data, onChange }) => (
                    <Form
                        data={data}
                        onChange={onChange}
                        className="block"
                    />
                )}
            />

            {loading && data?.length === 0
                ? <Loading className="absolute bottom fill" />
                : (
                    <Scrollbars
                        // onUpdate={handleScrollUpdate}
                        renderTrackHorizontal={(props) => (
                            <div {...props} className="track-horizontal" />
                        )}
                    >
                        <div className="layers-sidebar__layer">
                            <SidebarTitle
                                title={config.name}
                                count={meta?.total || data?.length}
                            />
                            {data?.length === 0
                                ? (
                                    <div className="layers-sidebar__empty">
                                        {messages.REQUEST_DATA_IS_NOT_FOUND}
                                    </div>
                                )
                                : renderList(data)}
                        </div>
                        {loading && <Loading className="center" />}
                    </Scrollbars>
                )
            }
        </div>
    );
};

export default SideBar;
