import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/DorisControl/actions';
import { loadComplexStatuses } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { useStoreFromSelector } from 'helpers/hooks';
import useComplexById from 'helpers/hooks/DorisControl/useComplexById';
import colorExtend from 'helpers/mapHelper/colorExtend';
import Loading from 'components/common/Loading';
import {
    MapPopUp,
} from 'components/MapComponents/MapPopUp';

import icon from '../icons/icon';

import Information from './Information';
import ModalButtons from './ModalButtons';

const PopUpModal = ({
    external_id,
    history,
    prop = 'polygon',
    showButtons = true,
}) => {

    const dispatch = useDispatch();

    const complexByIdProvider = useComplexById();

    useEffect(() => {
        if (external_id && prop === 'polygon') {
            complexByIdProvider.load(external_id);
        }
    }, [external_id, prop]);

    useEffect(() => () => {
        complexByIdProvider.clear();
    }, []);

    const stateData = useSelector(({ dorisControl }) => dorisControl[prop]);
    const data = stateData.find(({ id }) => external_id === id) || {};

    const complexStatuses = useStoreFromSelector(loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);
    const complexStatusesObj = useMemo(() => complexStatuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [complexStatuses]);

    const status = data?.status || 0;
    const { name } = data;

    const title = `${name} ${complexStatusesObj[status] ? `(${complexStatusesObj[status]?.name || ''})` : ''}`;
    const notValid = data?.is_not_valid_verification;

    return (
        <MapPopUp
            isOpen={!!external_id}
            icon={
                notValid === null
                    ? <i
                        className="far fa-file-certificate"
                        style={{ color: '#aaaaaa', fontSize: '16px' }}
                    />
                    : notValid
                        ? <i
                            className="fad fa-file-certificate"
                            style={{ '--fa-primary-color': '#d32f2f', '--fa-secondary-color': '#aaaaaa', fontSize: '16px' }}
                        />
                        : <span className="inner-icon" dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}/>
            }
            title={title}
            onClose={() => dispatch(actions.resetActive())}
            titleBackgroundColor={colorExtend(complexStatusesObj[status]?.color || '')}
            medium
            buttons={
                <ModalButtons
                    data={{
                        ...complexByIdProvider.data,
                        ...data
                    }}
                    history={history}
                    showButtons={showButtons}
                    disabledButtons={complexByIdProvider.loading}
                    onEdited={() => {
                        // редактирование комплекса
                        external_id && complexByIdProvider.load(external_id);
                    }}
                />
            }
        >
            {complexByIdProvider.loading && <Loading linear={true} />}

            <Information
                data={{
                    ...complexByIdProvider.data,
                    ...data
                }}
                showButtons={showButtons}
            />
        </MapPopUp>
    );
};

export default PopUpModal;
