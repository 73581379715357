import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';

import type { Device } from 'types/Meteo';

interface ItemProps {
    item: Device;
    onClickItem: () => void;
    selectedItem: boolean;
}

const Item = ({ item, onClickItem, selectedItem }: ItemProps) => {
    return (
        <LSContentItem onClick={onClickItem}>
            <LSContentColumn>
                {item.name || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn isActions>
                <ActionMore
                    isOpen={selectedItem} 
                    // onClick={onClickItem} 
                    isPassport 
                />
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
