import titles from 'helpers/constants/titles';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';

import Item from './Item';

const List = ({ data = [], loading, onReload }) => {
    return (
        <>
            {/*{loading && <Loading circular />}*/}
            {data.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: 'Id', width: '20%' },
                            { title: titles.NAME, width: '75%' },
                            { title: titles.ACTIONS, align: 'right', isActions: true }
                        ]}
                    >
                        {data.map(item => (
                            <Item key={item.id} item={item} onReload={onReload}/>
                        ))}
                    </LSContainer>
                )
                : (!loading)
                    ? (
                        <div style={{ padding: '15px' }}>
                            <NoData/>
                        </div>
                    )
                    : null

            }
        </>
    );
};

export default List;
