import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadedCameraById, loadVideoCamerasList, loadVideoLive, loadVideoRecord } from 'redux/VideoCameras/actions';
import { fullDateTimeWithTimeZone, getDateWithDuration } from 'helpers/date.config';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import Player from './Player';
import styles from './style.module.scss';

const VideoPlayer = ({ itemId, isOpen = false, onClose = () => {}, isLayoutGrid = false, type = 'live', isScreen = false }) => {
    const searchParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const [linkData, setLinkData] = useState();
    const [params, setParams] = useState();
    const [playback, setPlayback] = useState(1);
    const [data, setData] = useState({});

    const item_id = itemId ? itemId : searchParams.get('item_id');

    const isScreenProp = searchParams.get('isScreen') ? searchParams.get('isScreen') : isScreen;

    useEffect(() => {
        dispatch(loadedCameraById(item_id, setData));
    }, []);

    // const videoSrc = 'http://91.227.17.169:8080/cam89/index.m3u8?dvr=true&proto=hls';
    // const videoSrc = 'http://91.227.17.169:8080/cam89/index.mpd';


    const startData = fullDateTimeWithTimeZone(getDateWithDuration({ hours: -1 }));

    useEffect(() => {
        if (data.id) {
            setParams(data.interface);
            const filter = () => {
                const liveParams = data.interface?.find(el => el.action === type);
                if (liveParams) {
                    return {
                        protocol: Object.keys(liveParams.protocols)[0],
                        // protocol: 'hls',
                        speed: playback,
                        ...(type === 'live'
                            ? {}
                            : {
                                from: startData
                            }
                        )
                        // rewind: startData,
                        // ...liveParams.parameters.reduce((res, el) => ({ ...res, [el]: startData }), {})
                    };
                }
            };

            type === 'live'
                ? dispatch(loadVideoLive(data.id, filter(), (link) => {
                    setLinkData(link);
                }))
                : dispatch(loadVideoRecord(data.id, filter(), (link) => {
                    setLinkData(link);
                }));
        }
    }, [data, startData, dispatch, playback]);

    if (isScreenProp) {
        return <>
            {linkData
                && <Player
                    link={linkData}
                    // link={'http://10.4.218.215/10.OVD-1-6.220-22/embed.html?dvr=true&token=NDY%3D.Imhpc2xsYW0zNV9BQUFfZ21haWwuY29tXzE3MDc0MDAwMzAuMjQ0MSI%3D.WzIwMTUsMjAxNl0%3D'}
                    // handleDownload={handleDownload}
                    startData={startData}
                    id={data.id}
                    params={params}
                    setPlayback={setPlayback}
                    playback={playback}
                    type={type}
                />
            }
        </>;
    };

    return (<>
        {isLayoutGrid
            ? linkData && <Player
                // link={'http://10.4.218.215/10.OVD-1-6.220-22/index.m3u8?token=NDY%3D.Imhpc2xsYW0zNV9BQUFfZ21haWwuY29tXzE3MDc0MDAwMzAuMjQ0MSI%3D.WzIwMTUsMjAxNl0%3D'}
                // handleDownload={handleDownload}
                startData={startData}
                link={linkData}
                id={data.id}
                params={params}
                isLayoutGrid
            />
            : <Modal
                title={data.title}
                isOpen={isOpen}
                onClose={onClose}
                noPadding
                medium
                customStyle={styles.modal_style}
                buttons={<FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                    ]}
                />}
            >
                {linkData
                    && <Player
                        link={linkData}
                        // link={data.link}
                        // link={'http://its_cam:7NmD712S@10.119.194.15/live/media/ITVSERVER14/DeviceIpint.73/SourceEndpoint.video:0:0?format=hls&keep_alive=60'}
                        // link={'http://10.119.194.15/live/media/ITVSERVER8/DeviceIpint.1981/SourceEndpoint.video:0:0?exp=20241102T180056&format=mp4&nonce=5007&hmac=1mxY2ZvcYX-6x2lcaYn6JOZ8fjE'}
                        // link={'http://10.4.218.215/10.OVD-1-6.220-22/embed.html?dvr=true&token=NDY%3D.Imhpc2xsYW0zNV9BQUFfZ21haWwuY29tXzE3MDc0MDAwMzAuMjQ0MSI%3D.WzIwMTUsMjAxNl0%3D'}
                        // handleDownload={handleDownload}
                        startData={startData}
                        id={data.id}
                        params={params}
                        setPlayback={setPlayback}
                        playback={playback}
                        type={type}
                    />
                }
            </Modal>
        }
    </>);
};

export default VideoPlayer;
