import { Fragment } from 'react';
import { Divider } from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

const TabMembers = ({ data, tag = 'span' }) => {
    const TextTag = tag;
    return (
        <>
            {data?.length
                ? data?.map((item, i) => {
                    return (
                        <Fragment key={item.id}>
                            <div className="info__item">
                                <TextTag>{titles.PARTICIPANT_CATEGORY}: </TextTag>
                                <span>{item.category || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.TS}: </TextTag>
                                <span>{item.ts || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.LEAVING_SCENE}: </TextTag>
                                <span>{item.leaving_scene || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.FIO}: </TextTag>
                                <span>{item.fio || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.BIRTH_YEAR}: </TextTag>
                                <span>{item.birth_year || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.GENDER}: </TextTag>
                                <span>{item.gender_text || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.SEVERITY_AFTER_DTP}: </TextTag>
                                <span>{item.severity_text || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.DIRECT_VIOLATIONS}: </TextTag>
                                {item.direct_violations_text
                                    ? <span>{item.direct_violations_text.split(';').map((item, i) => (
                                        <div key={i}>{item}</div>))}</span> 
                                    : <span>Нет нарушений</span>
                                }
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.ASSOCIATED_VIOLATIONS}: </TextTag>
                                {item.associated_violations_text
                                    ? <span>{item.associated_violations_text.split(';').map((item, i) => (
                                        <div key={i}>{item}</div>))}</span> 
                                    : <span>Нет нарушений</span>
                                }
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.FASTENED}: </TextTag>
                                <span>{item.fastened_text || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.TYPE_OF_CHILD_RESTRAINT}: </TextTag>
                                <span>{item.type_of_child_restraint_text || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.INTOXICATION}: </TextTag>
                                <span>{item.intoxication_p ?? messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.EXPERIENCE}: </TextTag>
                                <span>{item.experience || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            {data?.length -1 !== i && <Divider/>}
                        </Fragment>
                    );
                })
                : (
                    <div className="info__item">
                        {messages.INFO_IS_NOT_FOUND}
                    </div>
                )
            }
        </>
    );
};

export default TabMembers;
