import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { createJob, editJob } from 'modules/DataBus/redux/actions';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import removeEmptyFields from 'helpers/removeEmptyFields';
import formatDate from 'helpers/constants/dateFormat';
import { getTimeWithoutSeconds } from 'helpers/date.config';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';

import type { JobsTypes, Period } from './types';

interface ModalFormProps {
    data?: JobsTypes;
    isNew: boolean;
    open: boolean;
    onClose: () => void;
    reloadList: () => void;
    period: Period[];
}

const weekdays = [
    { id: 1, name: 'Понедельник' },
    { id: 2, name: 'Вторник' },
    { id: 3, name: 'Среда' },
    { id: 4, name: 'Четверг' },
    { id: 5, name: 'Пятница' },
    { id: 6, name: 'Суббота' },
    { id: 7, name: 'Воскресенье' },
];

const ModalForm = ({ data, isNew, open, onClose, reloadList, period }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [formData, setFormData] = useState({
        name: data?.name || '',
        period: data?.period || '',
        interval: data?.interval || null,
        at: data?.at || null,
        day_of_month: data?.day_of_month || null,
        weekday: data?.weekday || ''
    });

    const handleChange = (name: string, value: string | number | Date | null) => {
        setFormData({ ...formData, [name]: value });
    };


    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        const preparedData = removeEmptyFields({
            ...formData,
            at: getTimeWithoutSeconds(formData.at),
            day_of_month: Number(formData.day_of_month) || null,
            interval: Number(formData.interval) || null,
        });

        dispatch(isNew
            ? createJob(preparedData, callback)
            : editJob(data?.id, preparedData, callback)
        );
    };

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: !(formData.name || formData.period)
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper className="block" error={validation.isKey('name')} helperText={validation.get('name')}>
                    <TextField
                        value={formData.name || ''}
                        name={'name'}
                        onChange={(e) => handleChange('name', e.target.value)}
                        label={titles.NAME}
                        variant={'outlined'}
                        size={'small'}
                        disabled
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper className="block" error={validation.isKey('interval')} helperText={validation.get('interval')}>
                    <TextField
                        label={titles.INTERVAL}
                        variant="outlined"
                        size="small"
                        value={formData.interval || ''}
                        name={'interval'}
                        onChange={(e) => handleChange('interval', e.target.value)}
                        type="number"
                        error={validation.isKey('interval')}
                    />
                </FormInfoWrapper>
                
                <FormInfoWrapper className="block" error={validation.isKey('period')} helperText={validation.get('period')}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        error={validation.isKey('period')}
                        required
                    >
                        <InputLabel>Период</InputLabel>
                        <Select
                            label="Период"
                            value={formData.period}
                            onChange={(e) => handleChange('period', e.target.value)}
                            name={'period'}
                            required
                        >
                            {period?.length > 0
                                ? period.map((el) =>
                                    <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                )
                                : <MenuItem disabled={true} value="">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
                <SingleKeyboardDateTimePicker
                    onChange={(value) => handleChange('at', value)}
                    value={formData.at}
                    label={titles.TIME}
                    pickerFormat={formatDate.HH_MM}
                    error={validation.isKey('at')}
                    helperText={validation.get('at')}
                    className="block"
                    timeOnly
                />
                <FormInfoWrapper className="block" error={validation.isKey('day_of_month')} helperText={validation.get('day_of_month')}>
                    <TextField
                        label={'Число месяца'}
                        variant="outlined"
                        size="small"
                        value={formData.day_of_month || ''}
                        name={'day_of_month'}
                        onChange={(e) => handleChange('day_of_month', e.target.value)}
                        type="number"
                        error={validation.isKey('day_of_month')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper className="block" error={validation.isKey('weekday')} helperText={validation.get('weekday')}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        error={validation.isKey('weekday')}
                        required
                    >
                        <InputLabel>День недели</InputLabel>
                        <Select
                            label="День недели"
                            value={formData.weekday}
                            onChange={(e) => handleChange('weekday', e.target.value)}
                            name={'weekday'}
                            required
                        >
                            {weekdays?.length > 0
                                ? weekdays.map((el) =>
                                    <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                )
                                : <MenuItem disabled={true} value="">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
            </form>
        </Modal>
    );
};

export default ModalForm;
