import { useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectTypicalOperations from 'modules/React/utils/components/SelectTypicalOperations';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import SelectCompany from 'components/common/Autocomplete/Companies';
import { useStoreFromSelector } from 'helpers/hooks';
import { loadOperationTypes } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';
import type { Operation, OperationInScenario, OperationType } from 'modules/React/Dictionaries/ResponseScenarios/types';

import SelectOperationsModal from './SelectScenarioOperationsModal';

export const initialRow = {
    position: null,
    is_typical: true,
    name: '',
    type: 1,
    operation_id: null,
    organization: null,
    organization_id: null,
    cycle: '',
    time: null,
    is_auto: false,
    is_transfer: false,
    is_parallel: false,
    parallel: [],
    group: null as string | null,
};

interface AddFormModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (newRow: OperationInScenario[], placeOfAdding: string) => void;
    maxGroupNum: number;
    isNew?: boolean;
    scenarioId?: number;
}

const AddFormModal = ({
    isOpen,
    onClose,
    onAdd,
    maxGroupNum,
    isNew = false,
    scenarioId
}: AddFormModalProps) => {
    const types = useStoreFromSelector(loadOperationTypes, reactSelectors.operationTypes);

    const typeArray: OperationType[] = Object.keys(types).map(key => ({ id: +key, name: types[key] }));

    const [formData, setFormData] = useState<OperationInScenario>(initialRow);
    const [selectedType, setSelectedType] = useState<OperationType | null>(typeArray[0]);
    const [selectedOperations, setSelectedOperations] = useState<OperationInScenario[]>([]); // для вложенных сценариев
    const [openSelectOperations, setOpenSelectOperations] = useState<boolean>(false);
    const [placeOfAdding, setPlaceOfAdding] = useState<string>('down');

    const handleAdd = (data = formData as OperationInScenario) => {
        const type = selectedType?.id || 1;

        let newData = [{ ...data, type }];

        if (type === 2) {
            const currentGroupNum = maxGroupNum + 1;

            newData = [
                { ...data, type, group: String(currentGroupNum) },
                { ...initialRow, is_typical: data.is_typical, group: String(currentGroupNum + 0.1) },
                { ...initialRow, is_typical: data.is_typical, group: String(currentGroupNum + 0.2) },
            ];
        }

        if (type === 5) {
            newData = [...selectedOperations];
        }

        onAdd(newData, placeOfAdding);
        onClose();
    };

    const isDisabled = !formData.type || selectedType?.id === 5
        ? !selectedOperations.length
        : (formData?.is_typical && !formData?.operation_id) || (!isNew && !placeOfAdding) || !formData.organization_id;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Добавить операцию"
            noPadding={true}
            small={true}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: () => handleAdd() ,
                            disabled: isDisabled
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                {!isNew && (
                    <FormControl className="block" size="small" variant="outlined" required>
                        <InputLabel required error={!placeOfAdding}>Выберите место добавления</InputLabel>
                        <Select
                            value={placeOfAdding || ''}
                            onChange={(e) => {
                                setPlaceOfAdding(e.target.value);
                            }}
                            label="Выберите место добавления"
                            name="size"
                            error={!placeOfAdding}
                            required
                        >
                            <MenuItem value="up">Выше выбранной строки</MenuItem>
                            <MenuItem value="down">Ниже выбранной строки</MenuItem>
                        </Select>
                    </FormControl>
                )}

                <CommonAutocomplete
                    required
                    label={'Тип операции'}
                    options={typeArray}
                    selected={selectedType}
                    onChange={(value: any) =>  setSelectedType(value)}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderLabel={(option) => option?.name || ''}
                    error={!formData.type}
                    showAsSelect
                />

                {selectedType?.id === 5
                    ? <FormButtons
                        noMarginLeft
                        positionLeft
                        buttons={[
                            {
                                ...buttonsTypes.defaultTextInfo,
                                name: 'Выбрать операции',
                                onClick: () => setOpenSelectOperations(true)
                            }
                        ]}
                    />
                    : <>
                        <SelectCompany
                            className="block"
                            selected={formData.organization}
                            onChange={(value: any) =>  {
                                setFormData((prev) => ({
                                    ...prev,
                                    organization: value,
                                    organization_id: value?.id
                                }));
                            }}
                            error={!formData.organization?.id}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.is_typical}
                                    onChange={(e) => {
                                        setFormData((prev) => ({
                                            ...prev,
                                            is_typical: e.target.checked,
                                            ...(!e.target.checked
                                                ? {
                                                    operation_id: null,
                                                    name: '',
                                                    time: null,
                                                }
                                                : {}
                                            ),
                                        }));
                                    }}
                                    name="is_typical"
                                />
                            }
                            label="Типовая"
                        />

                        {formData.is_typical
                            && <SelectTypicalOperations
                                selected={formData as Operation}
                                onChange={(value: any) => setFormData((prev) => ({
                                    ...prev,
                                    operation_id: value?.id || null,
                                    name: value?.name || '',
                                    time: value?.regulation_at ? value.regulation_at.split(':').reduce((h: string, m: string) => +h * 60 + +m) : null,
                                }))}
                                disabled={!formData.is_typical}
                                required={formData.is_typical}
                                error={!!formData.is_typical && !formData.operation_id}
                            />
                        }
                    </>
                }

                {openSelectOperations && (
                    <SelectOperationsModal
                        isOpen={openSelectOperations}
                        onClose={() => setOpenSelectOperations(false)}
                        selected={selectedOperations}
                        setSelected={setSelectedOperations}
                        scenarioId={scenarioId}
                    />
                )}
            </div>
        </Modal>
    );
};

export default AddFormModal;
