import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import PageLayout from 'components/layout/PageLayout';
import { loadIncidentHeatMap } from 'modules/React/redux/actions';
import Loading from 'components/common/Loading';

import Filter from './Filter';

const initialFilter = {
    start_date: getStartOf('month'),
    end_date: getEndOf(),
    type_id_list: [],
    threat_level_id_list: [],
    address_text: '',
};

const IncidentsHeatMap = ({
    tab,
    hideTitle
}) => {

    const dispatch = useDispatch();

    // ленивая догрузка карты - иначе проблема в 'modules\Dashboard\Dictionaries\StatisticsIncidents\index.js'
    const RenderMap = lazy(() => import('./RenderMap'));

    const [polygonFilter, setPolygonFilter] = useState(initialFilter);
    const [showLayer, setShowLayer] = useState(false);

    const onSearch = useCallback((filters = initialFilter) => {
        const prepareData = removeEmptyFields({
            ...filters,
            start_date: fullDateTimeWithTimeZone(filters.start_date),
            end_date: fullDateTimeWithTimeZone(filters.end_date),
            type_id_list: filters?.type_id_list?.map((el) => el.id),
            threat_level_id_list: filters?.threat_level_id_list?.map((el) => el.id),
        });

        setPolygonFilter(prepareData);
        dispatch(loadIncidentHeatMap(prepareData));
    }, [dispatch]);

    useEffect(() => {
        onSearch();
    }, [dispatch, onSearch]);

    return (
        <PageLayout
            header={hideTitle ? ' ' : 'Тепловая карта инцидентов'}
            tab={tab}
            filters={
                <Filter
                    initialFilter={initialFilter}
                    onSearch={onSearch}
                    tab={tab}
                />
            }
            actionPanel={
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={(e) => setShowLayer(e.target.checked)}
                            checked={showLayer}
                            size="small"
                        />
                    }
                    label="Показать иконки инцидентов"
                />
            }
            content={() => (
                <Suspense fallback={<Loading linear/>}>
                    <RenderMap
                        showLayer={showLayer}
                        polygonFilter={polygonFilter}
                    />
                </Suspense>
            )}
            customStyles={{ padding: 0 }}
        />
    );
};

export default IncidentsHeatMap;
