import { useEffect, useState } from 'react';

import GetReports from 'components/common/GetReports';
import SimpleSelect from 'components/common/SimpleSelect';

interface Report {
    name: string;
    key: number;
    url: string;
}

interface GetReportFieldsProps {
    reports?: Report[];
    handleDownload: (arg: any, arg2: any) => void;
}

const GetReportFields = ({ reports, handleDownload }: GetReportFieldsProps) => {
    const [selectedType, setSelectedType] = useState<any>('');
    const [selectedFormats, setSelectedFormats] = useState([]);

    const onDownload = (formats: unknown) => {
        handleDownload(formats, Number(selectedType?.key));
        setSelectedType('');
        setSelectedFormats([]);
    };

    useEffect(() => {
        if (!selectedType) {
            setSelectedFormats([]);
        }
    }, [selectedType]);

    return (
        <div className="row noMargin">
            <div className="row__item" style={{ minWidth: '160px' }}>
                <SimpleSelect
                    label="Тип отчета"
                    selected={selectedType}
                    options={reports || []}
                    onChange={(value) => setSelectedType(value)}
                    renderLabel={(option) => option?.name || ''}
                />
            </div>

            <GetReports
                onDownload={onDownload}
                disabled={!selectedType}
                onChange={setSelectedFormats}
                selected={selectedFormats}
                reportId={selectedType?.key}
                helperText={undefined}
            />
        </div>
    );
};

export default GetReportFields;
