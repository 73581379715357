import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, LinearProgress, List } from '@mui/material';

import {
    loadInfrastructureByRadius,
    loadRoadSectionByRadius,
} from 'redux/RoadNetwork/actions';
import { authSelectors } from 'redux/Auth';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import SimpleSelect from 'components/common/SimpleSelect';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import {
    createObjectBindings,
    deleteObjectBindings,
    getObjectBindings,
    loadPolygon,
} from 'modules/DigitalTwin/redux/actions';
import Loading from 'components/common/Loading';
import { config as rootConfig } from 'config';

import {
    eputsTypes,
    getItemLinkedObject,
    objectTypes,
    renderItemText,
    typesRu,
} from '../objectHelper';
import useBindingsProvider from '../useBindingsProvider';

import Map from './Map';
import Item from './Item';

const RelationModal = ({
    currentId,
    currentType,
    currentLatLon,
    isOpen,
    onReload = () => {},
    onClose = () => {},
}) => {
    const dispatch = useDispatch();

    const code = useSelector(authSelectors.projectCode);

    // список байндингов
    const [data, setData] = useState([]);
    // выбранный тип объектов
    const [type, setType] = useState(null);

    // список всех элементов типа
    const [allObjects, setAllObjects] = useState([]);

    const [loadingList, setLoadingList] = useState(false);
    const [loading, setLoading] = useState(false);

    const [addItem, setAddItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const [isChanged, setIsChanged] = useState(false);

    const bindingsProvider = useBindingsProvider(setData, setLoadingList);

    const fetchLoad = () => currentId && currentType && bindingsProvider.load(currentId, currentType);

    useEffect(() => {
        fetchLoad();
    }, [currentId, currentType]);

    useEffect(() => () => {
        setData([]);
        setAllObjects([]);
        setType(null);
    }, []);

    const handleClose = () => {
        if (!loading && !loadingList) {
            if (isChanged) {
                onReload?.();
            }
            onClose?.();
        }
    };

    const getLinkedKeys = (type) => {
        switch (type) {
            case objectTypes.node:
            case objectTypes.edge:
                return {
                    group: 'eputs_type',
                    value: 'eputs_id',
                    object: 'eputs',
                };
            case eputsTypes.infrastructure:
            case eputsTypes.roadNetwork:
                return {
                    group: 'object_type',
                    value: 'object_id',
                    object: 'object',
                };
            default:
                return null;
        }
    };

    // все объекты не связанные
    const filteredAllObjects = useMemo(() => {
        const linkedkeys = getLinkedKeys(currentType);
        // выбранные id из связей по типу
        const idsSelected = data.reduce((res, item) => {
            if (item[linkedkeys.group] === type?.id) {
                res.push(item[linkedkeys.value]);
            }
            return res;
        } ,[]);

        // фильтрование всех объектов
        return allObjects.filter(({ id }) => !idsSelected.includes(id));
    }, [data, allObjects, type, currentType]);

    const typesOptions = useMemo(() => {
        const createType = (id) => ({ id, name: typesRu[id] });
        switch (currentType) {
            case objectTypes.node:
            case objectTypes.edge:
                return [
                    createType(eputsTypes.infrastructure),
                    createType(eputsTypes.roadNetwork),
                ];
            case eputsTypes.infrastructure:
            case eputsTypes.roadNetwork:
                return [
                    createType(objectTypes.node),
                    createType(objectTypes.edge),
                ];

            default:
                return [];
        }
    }, [currentType]);

    const loadRelationElements = (type) => {

        const latLon = currentLatLon || rootConfig.get('mapCenter');

        const lat = parseFloat(latLon.lat || latLon[0]);
        const lon = parseFloat(latLon.lon || latLon.lng || latLon[1]);

        const params = {
            lat,
            lon,
            radius: 50000
        };

        if (type === eputsTypes.infrastructure) {
            dispatch(loadInfrastructureByRadius(
                params,
                (arr) => setAllObjects(arr)
            ));
        } else if (type === eputsTypes.roadNetwork) {
            dispatch(loadRoadSectionByRadius(
                params,
                (arr) => setAllObjects(arr)
            ));
        } else if (type === objectTypes.edge || type === objectTypes.node) {
            const deb = 0.1;
            const point1 = [(lon - deb), (lat + deb)];
            const point2 = [(lon + deb), (lat + deb)];
            const point3 = [(lon + deb), (lat - deb)];
            const point4 = [(lon - deb), (lat - deb)];
            const polygon = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [[
                        point1, point2, point3, point4, point1
                    ]]
                }
            };
            dispatch(loadPolygon(
                {
                    geometry: polygon,
                },
                (data) => setAllObjects(type === objectTypes.edge ? data?.edges || [] : data?.nodes || []),
                setLoading
            ));
        }
    };

    const handleSelectChange = (value) => {
        setAllObjects([]);
        setType(value);
        loadRelationElements(value?.id || '');
    };

    const handleDeleteItem = (id) => {
        setDeleteItem(id);
    };

    const getIt = (type, value) => {
        switch (type) {
            case objectTypes.node:
            case objectTypes.edge:
                return {
                    object_type: type,
                    object_id: value,
                };
            case eputsTypes.infrastructure:
            case eputsTypes.roadNetwork:
                return {
                    eputs_type: type,
                    eputs_id: value,
                };

            default:
                return {};
        }
    };

    const handleAddItem = (id) => {
        setAddItem({
            ...getIt(currentType, currentId),
            ...getIt(type?.id || '', id),
            eputs_project: code,
        });
    };

    return (
        <>
            <Modal
                title={`Изменение связей элемента "${typesRu[currentType]}"`}
                onClose={handleClose}
                fullWidth
                noPadding
                isOpen={isOpen}
                buttons={
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: handleClose,
                                loading: loading || loadingList,
                            },
                        ]}
                    />
                }
            >
                <Grid container direction="row" style={{ height: '100%' }}>
                    <Grid item xs={8} style={{ position: 'relative' }}>
                        {loadingList && <Loading circular />}
                        <Map
                            data={data}
                            allObjects={filteredAllObjects}
                            type={type?.id || ''}
                            currentType={currentType}
                            currentLatLon={currentLatLon}

                            onAddItem={handleAddItem}
                            onDeleteItem={handleDeleteItem}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <div className="block" style={{ paddingRight: 10, paddingTop: 10 }}>
                            <SimpleSelect
                                selected={type}
                                options={typesOptions}
                                onChange={handleSelectChange}
                                label="Выберите тип объекта"
                                renderLabel={(option) => option?.name || ''}
                            />
                        </div>

                        <h2>Связанные объекты:</h2>

                        {loadingList
                            ? <LinearProgress/>
                            : (
                                <>
                                    {data?.length > 0
                                        ? (
                                            <List className="list">
                                                {data?.map((item, i) => {
                                                    const itemObj = getItemLinkedObject(item, currentType) || {};

                                                    return (
                                                        <Item
                                                            key={item.id}
                                                            index={i}
                                                            handleDeleteItem={() => handleDeleteItem(item.id)}
                                                            text={renderItemText(itemObj, true)}
                                                        />
                                                    );
                                                })}
                                            </List>
                                        )
                                        : (<p style={{ padding: '1rem' }}>{messages.NO_DATA}</p>)
                                    }
                                </>
                            )
                        }

                    </Grid>
                </Grid>
            </Modal>

            {/* добавить элемент */}
            <ConfirmDelete
                message={'Вы действительно хотите добавить связь?'}
                open={!!addItem}
                loadingButton={loading}
                onSuccess={() => {

                    // добавляем связь
                    dispatch(createObjectBindings(
                        addItem,
                        () => {
                            // подгружаем добавленную связь
                            fetchLoad();
                        },
                        setLoading
                    ));

                    setAddItem(null);
                    setIsChanged(true);
                }}
                onClose={() => {
                    setAddItem(null);
                }}
            />

            {/* удаление связи */}
            <ConfirmDelete
                message={'Вы действительно хотите удалить связь?'}
                // message={messages.CONFIRM_DELETE}
                open={!!deleteItem}
                loadingButton={loading}
                onSuccess={() => {
                    dispatch(deleteObjectBindings(
                        deleteItem,
                        () => {
                            // удаление из списка
                            setData(old => {
                                const index = old.findIndex(({ id }) => id === deleteItem);
                                if (index >= 0) {
                                    return ([
                                        ...old.slice(0, index),
                                        ...old.slice(index + 1),
                                    ]);
                                }
                                return old;
                            });
                        },
                        setLoading
                    ));

                    setDeleteItem(null);
                    setIsChanged(true);

                }}
                onClose={() => {
                    setDeleteItem(null);
                }}
            />
        </>
    );

};

export default RelationModal;
