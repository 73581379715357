import { useMemo, useState } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { 
    loadChildRestraintTypes, 
    loadDtpSeverity, 
    loadFastened, 
    loadGenders, 
    loadParticipants, 
    loadViolations 
} from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { getYear, getYearDiff } from 'helpers/date.config';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormButtons,{ buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { generateOptions, getValue } from 'components/MapComponents/Layers/Incidents/helper';

const useStyles = makeStyles({
    wrapperCA : { margin: '0.5rem 0' }
});

const AddEditModal = ({
    isOpen = false,
    onClose = () => {},
    member,
    index,
    onAccept,
}) => {
    const styles = useStyles();
    const severity = useStoreProp(loadDtpSeverity, 'incidents', 'severity');
    const participants = useStoreProp(loadParticipants, 'incidents', 'participants');
    const genders = useStoreProp(loadGenders, 'incidents', 'genders');
    const violations = useStoreProp(loadViolations, 'incidents', 'violations');
    const fastened = useStoreProp(loadFastened, 'incidents', 'fastened');
    const childRestraintTypes = useStoreProp(loadChildRestraintTypes, 'incidents', 'childRestraintTypes');

    const participantsMap = useMemo(() => Object.values(participants).reduce((res, val) => ({ ...res, [val]: val }), {}), [participants]);

    const severityOptions = useMemo(() => generateOptions(severity), [severity]);
    const participantsOptions = useMemo(() => generateOptions(participants), [participants]);
    const gendersOptions = useMemo(() => generateOptions(genders), [genders]);
    const violationsOptions = useMemo(() => generateOptions(violations), [violations]);
    const fastenedOptions = useMemo(() => generateOptions(fastened), [fastened]);
    const childRestraintTypesOptions = useMemo(() => generateOptions(childRestraintTypes), [childRestraintTypes]);

    const initialState = {
        ...member,
        category: member?.category || null,
        ts: member?.ts || null,
        leaving_scene: member?.leaving_scene || null,
        fio: member?.fio || null,
        birth_year: member?.birth_year || null,
        gender: member?.gender || null,
        severity: member?.severity || null,
        direct_violations: member?.direct_violations || [],
        associated_violations: member?.associated_violations || [],
        fastened: member?.fastened || null,
        type_of_child_restraint: member?.type_of_child_restraint || null,
        intoxication_p: member?.intoxication_p || null,
        experience: member?.experience || null
    };

    const [data, setData] = useState(initialState);

    const onChangeTextField = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleChange = (name, value) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const handleSuccess = () => {
        const newData = {
            ...data,
        };

        member
            ? onAccept(index, newData)
            : onAccept(newData);
        onClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            small
            title={member ? 'Редактировать участника' : 'Добавить участника'}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: !data.fio || !data.birth_year
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">
                <div className={styles.wrapperCA}>
                    <CommonAutocomplete
                        label={titles.PARTICIPANT_CATEGORY}
                        selected={getValue(participantsMap, data?.category)}
                        options={participantsOptions}
                        onChange={(value) => handleChange('category', value.name)}
                        renderLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => option?.name === value?.name}
                        showAsSelect
                    /> 
                </div>
                <div className="block">
                    <TextField
                        label={titles.TS}
                        value={data?.ts || ''}
                        onChange={onChangeTextField}
                        variant="outlined"
                        size="small"
                        name="ts"
                        type="number"
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.LEAVING_SCENE}
                        value={data?.leaving_scene || ''}
                        onChange={onChangeTextField}
                        variant="outlined"
                        size="small"
                        name="leaving_scene"
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.FIO}
                        value={data?.fio || ''}
                        onChange={onChangeTextField}
                        variant="outlined"
                        size="small"
                        name="fio"
                        error={!data.fio}
                    />
                </div>
                <SingleKeyboardDateTimePicker
                    className="block"
                    value={String(data.birth_year)}
                    onChange={(newDate) => handleChange('birth_year', getYear(newDate))}
                    label={titles.BIRTH_YEAR}
                    dateOnly={true}
                    views={['year']}
                    pickerFormat="yyyy"
                    error={!data.birth_year}
                    minDate={{ year: getYearDiff(80) }} // минимальный возраст 80 лет
                />
                <div className={styles.wrapperCA}>
                    <CommonAutocomplete
                        label={titles.GENDER}
                        selected={getValue(genders, data?.gender)}
                        options={gendersOptions}
                        onChange={(value) => {
                            handleChange('gender', value.id);
                            handleChange('gender_text', value.name);
                        }}
                        renderLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                        showAsSelect
                    />
                </div>
                <div className={styles.wrapperCA}>
                    <CommonAutocomplete
                        label={titles.SEVERITY_AFTER_DTP}
                        selected={getValue(severity, data?.severity)}
                        options={severityOptions}
                        onChange={(value) => {
                            handleChange('severity', value?.id);
                            handleChange('severity_text', value?.name);
                        }}
                        renderLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                        showAsSelect
                        error={!data.severity}
                    /> 
                </div>
                <div className={styles.wrapperCA}>
                    <CommonAutocomplete
                        multiple
                        label={titles.DIRECT_VIOLATIONS}
                        selected={getValue(violations, data?.direct_violations)}
                        options={violationsOptions}
                        onChange={(value) => {
                            handleChange('direct_violations', value.map(el => el.id));
                            handleChange('direct_violations_text', value.map(el => el.name).join(', '));
                        }}
                        renderLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                        showAsSelect
                        limitTags={1}
                    /> 
                </div>
                <div className={styles.wrapperCA}>
                    <CommonAutocomplete
                        multiple
                        label={titles.ASSOCIATED_VIOLATIONS}
                        selected={getValue(violations, data?.associated_violations)}
                        options={violationsOptions}
                        onChange={(value) => {
                            handleChange('associated_violations', value.map(el => el.id));
                            handleChange('associated_violations_text', value.map(el => el.name).join(', '));
                        }}
                        renderLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                        showAsSelect
                        limitTags={1}
                    />
                </div>
                <div className={styles.wrapperCA}>
                    <CommonAutocomplete
                        label={titles.FASTENED}
                        selected={getValue(fastened, data?.fastened)}
                        options={fastenedOptions}
                        onChange={(value) => {
                            handleChange('fastened', value?.id);
                            handleChange('fastened_text', value?.name);
                        }}
                        renderLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                        showAsSelect
                    /> 
                </div>
                <div className={styles.wrapperCA}>
                    <CommonAutocomplete
                        label={titles.TYPE_OF_CHILD_RESTRAINT}
                        selected={getValue(childRestraintTypes, data?.type_of_child_restraint)}
                        options={childRestraintTypesOptions}
                        onChange={(value) => handleChange('type_of_child_restraint', value?.id)}
                        renderLabel={(option) => option?.name || ''}
                        isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                        showAsSelect
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.INTOXICATION}
                        value={data.intoxication_p || ''}
                        onChange={onChangeTextField}
                        variant="outlined"
                        size="small"
                        name="intoxication_p"
                        type="number"
                    />
                </div>
                <div className="block">
                    <TextField
                        label={titles.EXPERIENCE}
                        value={data.experience || ''}
                        onChange={onChangeTextField}
                        variant="outlined"
                        size="small"
                        name="experience"
                        type="number"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddEditModal;
