/**
 * Sagas
 * **/
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setValidation } from 'redux/Validation/actions';
import { showMessage } from 'redux/Message/actions';
import { setMapLoading } from 'redux/Map/actions';
import messagetypes from 'redux/Message/messagetypes';
import { messageHelper } from 'helpers/messageHelper';
import { getReport } from 'helpers/getReport';

import * as actions from './actions';
import api from './api.methods';
import * as types from './types';

function* loadCamerasSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingCameras(true));
    const camerasList = yield call(api.getCameras, page, limit, query);
    if (camerasList) {
        yield put(actions.loadedCameras(camerasList));
    }
    yield put(actions.loadingCameras(false));
}

function* editCameraSaga({ payload: { id, data }, callback }) {
    yield put(actions.loadingCameras(true));
    const response = yield call(api.editCamera, id, data);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messagetypes.success, 'Камера успешно изменена'));
    }
    yield put(actions.loadingCameras(false));
}

function* createCameraSaga({ payload: data }) {
    yield put(actions.loadingCameras(true));
    const response = yield call(api.createCamera, data);
    if (response?.success) {
        yield put(actions.loadCameras());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messagetypes.success, 'Камера успешно создана'));
    }
    yield put(actions.loadingCameras(false));
}

function* deleteCameraSaga({ payload: id, callback }) {
    yield put(actions.loadingCameras(true));
    const response = yield call(api.deleteCamera, id);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, 'Камера удалена'));
        yield callback?.();
    }
    yield put(actions.loadingCameras(false));
}

function* loadCameraSettingsSaga({ payload }) {
    const { page, limit } = payload;
    yield put(actions.loadingCameraSettings(true));
    const response = yield call(api.getCameraSettings, page, limit);
    if (response?.success) {
        yield put(actions.loadedCameraSettings(response));
    }
    yield put(actions.loadingCameraSettings(false));
}

function* editCameraSettingsSaga({ payload }) {
    const { id, params } = payload;
    const response = yield call(api.editCameraSettings, id, params);
    if (response?.success) {
        yield put(showMessage(messagetypes.success, 'Данные успешно изменены'));
    }
}

function* loadCameraInfoSaga() {
    const response = yield call(api.getInfoList);
    if (response?.success) {
        yield put(actions.loadedCameraInfo(response));
    }
}


function* loadCameraEventsSaga({ payload }) {
    const { page, limit, data } = payload;
    const config =  { page, limit, ...data };

    yield put(actions.loadingCameraEvents(true));
    const response = yield call(api.getEvents, config);
    if(response?.success) {
        yield put(actions.loadedCameraEvents(response));
    }
    yield put(actions.loadingCameraEvents(false));
}

function* loadCurrentCameraEventsSaga({ payload, callback }) {
    const { page, limit, data } = payload;
    const config = { page, limit, ...data };

    yield put(actions.loadingCameraEvents(true));
    const response = yield call(api.getEvents, config);
    if (response) {
        callback && callback(response);
    }
    yield put(actions.loadingCameraEvents(false));
}

function* loadCameraViolationsSaga() {
    const response = yield call(api.getCameraViolations);
    if (response?.success) {
        yield put(actions.loadedCameraViolations(response));
    }
}

function* loadCameraReportGroupsSaga() {
    const response = yield call(api.getReportGroups);
    if (response?.success) {
        yield put(actions.loadedCameraReportGroups(response));
    }
}

function* loadCameraReportTypesSaga() {
    const response = yield call(api.getReportTypes);
    if (response?.success) {
        yield put(actions.loadedCameraReportTypes(response));
    }
}

function* loadCameraReportSaga({ payload, callback }) {
    const report = payload?.report;
    const config = { params: { ...payload } };

    yield put(actions.loadingCameraReport(true));
    const response = yield call(api.getReport, config);

    if (response?.success) {
        if (report) {
            yield messageHelper(response);
        } else {
            callback?.(response.data);
            yield put(actions.loadedCameraReport(response));
        }
    }

    yield put(actions.loadingCameraReport(false));
}

// подгрузить полигон
function* getForPolygonSaga({ payload: params }) {
    yield put(setMapLoading('va_polygon', true));
    const response = yield call(api.getForPolygon, params);
    if (response) {
        yield put(actions.saveForPolygon(response));
    }
    yield put(setMapLoading('va_polygon', false));
}

function* getDeviceListSaga({ payload: params }) {
    const replace = (params.page || 1) === 1;
    yield put(actions.loadingDeviceList(true));
    const response = yield call(api.getDeviceList, params);
    if (response) {
        yield put(actions.saveDeviceList(response, replace));
    }
    yield put(actions.loadingDeviceList(false));
}

function* getStatusesSaga() {
    const response = yield call(api.getStatuses);
    if (response?.data) {
        yield put(actions.loadedCameraStatuses(response.data));
    }
}

function* loadCameraGrzPointsSaga({ payload }) {
    yield put(actions.loadingCameraGrzPoints(true));
    const response = yield call(api.getVideoAnalyticGrzPointsGroup, payload);
    if (response?.success) {
        yield put(actions.loadedCameraGrzPoints(response.data));
    }
    yield put(actions.loadingCameraGrzPoints(false));
}

function* downloadCameraEventsSaga({ payload }) {
    const config = {
        params: payload,
        responseType: 'blob'
    };
    const response = yield call(api.downloadEvents, config);
    if (response?.data) {
        getReport(response.data, payload.report);
    }
}

function* getEventsFilters() {
    const response = yield call(api.getEventsFilters);
    if (response?.success) {
        yield put(actions.loadedEventsFilters(response.data));
    }
}

function* loadCameraObjectsSaga({ payload }) {
    yield put(actions.loadingCameraObjects(true));

    const response = yield call(api.getCameraObjects, payload);
    if (response?.success) {
        yield put(actions.loadedCameraObjects(response));
    }

    yield put(actions.loadingCameraObjects(false));
}

function* loadPassengerTrafficTypesSaga() {
    yield put(actions.loadingPassengerTraffic(true));

    const response = yield call(api.getPassengerTrafficTypes);
    if (response?.success) {
        yield put(actions.loadedPassengerTrafficTypes(response.data));
    }

    yield put(actions.loadingPassengerTraffic(false));
}

function* loadPassengerTrafficSaga({ payload }) {
    yield put(actions.loadingPassengerTraffic(true));

    const response = yield call(api.getPassengerTraffic, payload);
    if (response?.success) {
        yield put(actions.loadedPassengerTraffic(response));
    }

    yield put(actions.loadingPassengerTraffic(false));
}

function* loadInOutCamerasReportSaga({ payload }) {
    yield put(actions.loadingInOutCamerasReport(true));

    const response = yield call(api.getInOutCamerasReport, payload);
    if (response?.success) {
        yield put(actions.loadedInOutCamerasReport(response.data));
    }

    yield put(actions.loadingInOutCamerasReport(false));
}

function* loadCamerasByRadiusSaga({ payload, callback }) {
    const response = yield call(api.loadCamerasByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedCamerasByRadius(response.data));
        callback(response.data);
    }
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* getEventsPopupSaga({ payload: { page, limit, params }, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const config =  { page, limit, ...params };
    const response = yield call(api.getEventsPopup, config);
    if (response) {
        yield fnCallback(response);
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_CAMERAS, loadCamerasSaga),
        takeLatest(types.EDIT_CAMERA, editCameraSaga),
        takeLatest(types.CREATE_CAMERA , createCameraSaga),
        takeLatest(types.DELETE_CAMERA , deleteCameraSaga),
        takeLatest(types.LOAD_CAMERA_SETTINGS , loadCameraSettingsSaga),
        takeLatest(types.EDIT_CAMERA_SETTINGS , editCameraSettingsSaga),
        takeLatest(types.LOAD_CAMERA_INFO , loadCameraInfoSaga),
        takeLatest(types.LOAD_CAMERA_EVENTS, loadCameraEventsSaga),
        takeLatest(types.LOAD_CURRENT_CAMERA_EVENTS, loadCurrentCameraEventsSaga),
        takeLatest(types.LOAD_CAMERA_VIOLATIONS, loadCameraViolationsSaga),
        takeLatest(types.LOAD_CAMERA_REPORT_GROUPS, loadCameraReportGroupsSaga),
        takeLatest(types.LOAD_CAMERA_REPORT_TYPES, loadCameraReportTypesSaga),
        takeLatest(types.LOAD_CAMERA_REPORT, loadCameraReportSaga),
        takeLatest(types.GET_POLYGON, getForPolygonSaga),
        takeLatest(types.GET_DEVICE_LIST, getDeviceListSaga),
        takeLatest(types.LOAD_STATUSES, getStatusesSaga),
        takeLatest(types.LOAD_CAMERA_GRZ_POINTS, loadCameraGrzPointsSaga),
        takeLatest(types.DOWNLOAD_CAMERA_EVENTS, downloadCameraEventsSaga),
        takeLatest(types.GET_EVENTS_FILTERS, getEventsFilters),
        takeLatest(types.LOAD_CAMERA_OBJECTS, loadCameraObjectsSaga),
        takeLatest(types.LOAD_PASSENGER_TRAFFIC, loadPassengerTrafficSaga),
        takeLatest(types.LOAD_PASSENGER_TRAFFIC_TYPES, loadPassengerTrafficTypesSaga),
        takeLatest(types.LOAD_IN_OUT_CAMERAS_REPORT, loadInOutCamerasReportSaga),
        takeLatest(types.LOAD_CAMERAS_BY_RADIUS, loadCamerasByRadiusSaga),
        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),

        takeLatest(types.GET_EVENTS_POPUP, getEventsPopupSaga),
    ]);
}
