import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';
import makeStyles from '@mui/styles/makeStyles';

import { videoAnalyticsSelectors } from 'redux/VideoAnalytics';
import {
    loadEventsPopup,
    resetActive,
} from 'redux/VideoAnalytics/actions';
import messages from 'helpers/constants/messages';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { usePrevious } from 'helpers/hooks';
import FormButtons from 'components/common/FormButtons';
import FrameModal from 'components/common/FrameVideoModal/FrameModal';
import Loading from 'components/common/Loading';
import {
    createIcon as createIconTitle,
    MapPopUp,
} from 'components/MapComponents/MapPopUp';
import MapScroll from 'components/MapComponents/MapScroll';
import getFilters from 'components/MapComponents/helpers/getFilters';

import { getFilter } from '../helper';
import config from '../config';

import Events from './Events';

const useStyles = makeStyles((theme) => ({
    noFound: {
        padding: '1rem'
    },
}));

const PopUpWrapper = ({
    isOpen,
    data,
    statusesOb
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const {
        id,
        name,
        status_color,
        status,
        url_player,
    } = data || {};

    const scrollRef = useRef();

    const [openFrameUrlModal, setOpenFrameUrlModal] = useState(false);
    const [dataEvents, setDataEvents] = useState({});
    const [loading, setLoading] = useState(false);
    const filters = useSelector(videoAnalyticsSelectors.filters);
    const filter = getFilters(filters, getFilter);
    const prevFilter = usePrevious(filter);

    const [params, setParams] = useState({
        page: 1,
        limit: 40,
    });
    const setPage = (page) => setParams(old => ({ ...old, page }));

    const total = (dataEvents?.meta && dataEvents?.meta?.last_page) || 0;

    useEffect(() => {
        if (isOpen) {
            dispatch(loadEventsPopup(
                params.page,
                params.limit,
                {
                    ...filter,
                    camera_id_list: [id]
                },
                (data) => setDataEvents(old => (
                    params.page === 1
                        ? data
                        : {
                            ...data,
                            data: [
                                ...(old?.data || []),
                                ...data.data
                            ],
                        }
                )),
                setLoading
            ));
        } else {
            setDataEvents({});
        }
    }, [isOpen, id, params]);

    useEffect(() => {
        if (!isEqual(filter, prevFilter)) {
            if (scrollRef.current) scrollRef.current.scrollTop(0);
            setPage(1);
        }
    }, [filter]);

    const handleScrollUpdate = () => {
        if (total > 0 && params.page < total && loading === false) {
            setPage(params.page + 1);
        }
    };

    const handleRefresh = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
        setPage(1);
    };

    return (
        <>
            <MapPopUp
                isOpen={isOpen}
                icon={createIconTitle(config.layerIcon)}
                title={name || ''}
                onClose={() => {
                    dispatch(resetActive({}));
                    setDataEvents({});
                    setLoading(false);
                }}
                titleBackgroundColor={status_color
                    ? colorExtend(status_color)
                    : colorExtend(statusesOb.get(status)?.color)
                }
                medium
                titleButtons={
                    <FormButtons
                        noPadding
                        className="action"
                        buttons={[
                            {
                                name: 'Просмотр видео',
                                onClick: () => setOpenFrameUrlModal(true),
                                icon: <i className="far fa-play-circle"></i>,
                                disabled: !url_player,
                                style: {
                                    fontSize: 13,
                                    padding: 0,
                                    color: '#fff',
                                    ...!url_player ? { opacity: '0.5' } : {}
                                }
                            },
                            {
                                name: 'Обновить данные',
                                onClick: handleRefresh,
                                icon: <RefreshIcon fontSize="small" style={{ 'color': '#fff' }} /*className={classes.icon}*/ />,
                                tooltipTitle: 'Обновить данные',
                                style: { padding: 0 }
                            }
                        ]}
                    />
                }
            >
                {loading && params.page === 1 && <Loading size={50} className="preloader center" />}

                {dataEvents?.data?.length > 0 && (
                    <MapScroll
                        refElement={scrollRef}
                        onUpdate={handleScrollUpdate}
                    >
                        <Events list={dataEvents}/>

                        {(total > 0 && loading) && (
                            <div>
                                <Loading className="preloader center"/>
                            </div>
                        )}
                    </MapScroll>
                )}

                {dataEvents?.data?.length === 0 && loading === false && (
                    <div className={classes.noFound}>{messages.EVENTS_IS_NOT_FOUND}</div>
                )}
            </MapPopUp>

            {openFrameUrlModal && (
                <FrameModal
                    isOpen={openFrameUrlModal}
                    onClose={() => setOpenFrameUrlModal(false)}
                    link={url_player}
                    title={name}
                />
            )}
        </>
    );
};

export default PopUpWrapper;
