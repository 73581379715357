import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDelete from 'components/common/ConfirmModal';
import messages from 'helpers/constants/messages';
import * as actions from 'modules/DigitalTwin/redux/actions';
import * as selectors from 'modules/DigitalTwin/redux/selectors';
import AddEditEdge from 'modules/DigitalTwin/utils/components/AddEditEdge';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const deletedForm = useSelector(selectors.deleteForm);
    const editedForm = useSelector(selectors.editForm);
    const saved = useSelector(selectors.saved);

    useEffect(() => {
        if (saved) {
            dispatch(actions.setSaved(false));
        }
    }, [dispatch, saved]);

    return (
        <>
            {/* форма редактирования ребра */}
            {editedForm && (
                <AddEditEdge
                    isOpen={!!editedForm}
                    item={editedForm}
                    onClose={() => {
                        dispatch(actions.setEditForm());
                    }}
                    reloadList={() => {
                        dispatch(actions.setSaved(true));
                    }}
                />
            )}

            {/* удаление ребра */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deletedForm}
                onSuccess={() => {
                    dispatch(actions.deleteEdge(deletedForm, () => {
                        dispatch(actions.setSaved(true));
                    }));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
