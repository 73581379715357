import { useState } from 'react';

import { config } from 'config';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import CheckPointsMarkers from 'components/MapComponents/Layers/TransportPassenger/CheckPointsMarkers';
import RouteJson from 'components/MapComponents/Layers/TransportPassenger/RouteJson';
import RouteMarkers from 'components/MapComponents/Layers/TransportPassenger/RouteMarkers';
import { FeatureGroup, Map, MapControl } from 'components/MapComponents/leaflet';

const ShowMapModal = ({ isOpen, onClose, route }) => {

    const [mapCenter] = useState(config.get('mapCenter'));

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
            title={route?.name}
            noPadding
            fullWidth
        >   
            <>
                <Map
                    center={mapCenter}
                >
                    <MapControl>
                        <FeatureGroup>
                            <CheckPointsMarkers
                                item={route}
                            />
                            <RouteMarkers
                                item={route}
                            />
                            <RouteJson
                                onMap={false}
                                centerAfter={true}
                                item={route}
                                key={route.id}
                                disabled
                            />
                        </FeatureGroup>
                    </MapControl>
                </Map>
            </>
        </Modal>
    );
};

export default ShowMapModal;
