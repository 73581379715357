import Cameras from '../../pages/Dictionaries/DorisControl/Cameras';
import VFComplex from '../../pages/Dictionaries/DorisControl/VFComplex';
import Wanted from '../../pages/Dictionaries/CarsWanted/Wanted';
import PassingVehicleCameras from '../../pages/Dictionaries/CarsWanted/PassingVehicleCameras';
import DSSettings from '../../pages/Dictionaries/DorisControl/Settings';
import ComplexNames from '../../pages/Dictionaries/DorisControl/ComplexNames';
import KFVFTags from '../../pages/Dictionaries/DorisControl/TagsForKFVF';
import FTPUpload from '../../pages/Dictionaries/DorisControl/FTPUpload';
import PDDSettings from '../../pages/Dictionaries/DorisControl/PDDSettings';
import GovernmentContract from '../../pages/Dictionaries/DorisControl/GovernmentContract';
import Checklists from '../../pages/Dictionaries/DorisControl/Checklists';
import CustomSettings from '../../pages/Dictionaries/DorisControl/CustomSettings';
import OTK from '../../pages/Dictionaries/DorisControl/OTK';
import CheckListGroup from '../../pages/Dictionaries/DorisControl/CheckListGroup';
import ExportMaterials from '../../pages/Dictionaries/DorisControl/ExportMaterials';
import ChecklistMistakes from '../../pages/Dictionaries/DorisControl/ChecklistMistakes';
import DuploCodes from '../../pages/Dictionaries/DorisControl/DuploCodes';
import ReExportMaterials from '../../pages/Dictionaries/DorisControl/ReExportMaterials';
import Materials from '../../pages/Dictionaries/DorisControl/Materials';

const routes = [
    {
        path: '/dictionaries/video-fixation/cameras', // TODO компонент больше не используется
        component: Cameras,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/complexes',
        component: VFComplex,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/wanted',
        component: Wanted,
        exact: true,
    },
    {
        path: '/dictionaries/doris-control/passing-vehicle-cameras',
        component: PassingVehicleCameras,
        exact: true,
    },
    {
        path: '/dictionaries/doris-control/settings',
        component: DSSettings,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/names',
        component: ComplexNames,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/tags',
        component: KFVFTags,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/ftp-upload',
        component: FTPUpload,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/pdd-settings',
        component: PDDSettings,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/government-contract',
        component: GovernmentContract,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/checklist-points',
        component: Checklists,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/custom-settings',
        component: CustomSettings,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/otk',
        component: OTK,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/checklist',
        component: CheckListGroup,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/export-materials',
        component: ExportMaterials,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/re-export-materials',
        component: ReExportMaterials,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/checklist-mistakes',
        component: ChecklistMistakes,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/duplo-codes',
        component: DuploCodes,
        exact: true
    },
    {
        path: '/dictionaries/doris-control/materials',
        component: Materials,
        exact: true
    },

];

export default routes;
