import { useMemo } from 'react';

import { wsSelectors } from 'redux/WS';
import { loadWsAvailableChannels } from 'redux/WS/actions';
import useStoreFromSelector from 'helpers/hooks/useStoreFromSelector';
import messages from 'helpers/constants/messages';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

import type { Alert, Alerts } from '../../types';

const SelectChannel = ({ selected, onChange }: CommonAutocompleteProps<Alert>) => {
    const channelsByProject: Alerts = useStoreFromSelector(
        loadWsAvailableChannels,
        wsSelectors.availableChannelsByProject
    );

    // оставляем только обязательные (уведомления)
    const listChannels = useMemo(() => {
        return Object.values(channelsByProject).filter(({ required = false }) => required);
    }, [channelsByProject]);


    return (
        <CommonAutocomplete
            multiple
            label="Источник"
            selected={selected}
            onChange={onChange}
            options={listChannels}
            renderLabel={(option) => option?.description || messages.NO_VALUE}
            limitTags={1}
            onReset={() => onChange([])}
        />
    );
};

export default SelectChannel;
