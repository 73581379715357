import * as types from './types';

export const loadIncidents = (page, limit, params, fnCallback, fnLoading) => ({
    type: types.LOAD_INCIDENTS,
    payload: { page, limit, params },
    fnCallback,
    fnLoading
});

export const loadingIncidents = (loading) => ({
    type: types.LOADING_INCIDENTS,
    payload: loading
});

export const loadedIncidents = (response) => ({
    type: types.LOADED_INCIDENTS,
    payload: response
});

export const clearIncidents = () => ({
    type: types.CLEAR_INCIDENTS,
});

export const loadIncidentById = (id, fnCallback, fnLoading) => ({
    type: types.LOAD_INCIDENT_BY_ID,
    payload: id,
    fnCallback,
    fnLoading,
});

export const createIncident = (data, callback) => ({
    type: types.CREATE_INCIDENT,
    payload: data,
    callback
});

export const editIncident = (id, data, callback) => ({
    type: types.EDIT_INCIDENT,
    payload: { id, data },
    callback
});

export const deleteIncident = (id, callback) => ({
    type: types.DELETE_INCIDENT,
    payload: id,
    callback
});

// сайдбар инцидентов
export const loadIncidentSidebar = (page, limit, params = {}) => ({
    type: types.LOAD_INCIDENT_SIDEBAR,
    payload: {
        page,
        limit,
        ...params,
    },
});

export const loadedIncidentSidebar = (data, isReplace = true) => ({
    type: types.LOADED_INCIDENT_SIDEBAR,
    payload: {
        data,
        isReplace,
    },
});

export const loadingIncidentSidebar = (bool = false) => ({
    type: types.LOADING_INCIDENT_SIDEBAR,
    payload: bool,
});

export const clearIncidentSidebar = () => ({
    type: types.CLEAR_INCIDENT_SIDEBAR,
});

export const reloadIncidentSidebar = (bool = false) => ({
    type: types.NEED_RELOAD_INCIDENT_SIDEBAR,
    payload: bool,
});

// полигон инцидентов
export const loadIncidentPolygon = (polygon, filter = {}) => ({
    type: types.LOAD_INCIDENT_POLYGON,
    payload: { polygon, filter }
});

export const loadedIncidentPolygon = (data) => ({
    type: types.LOADED_INCIDENT_POLYGON,
    payload: data
});

export const loadingIncidentPolygon = (bool = false) => ({
    type: types.LOADING_INCIDENT_POLYGON,
    payload: bool
});

export const clearIncidentPolygon = () => ({
    type: types.LOADED_INCIDENT_POLYGON,
    payload: []
});

export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const clearActive = () => ({
    type: types.SET_ACTIVE,
    payload: {}
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

export const setSaved = (bool = false) => ({
    type: types.SET_SAVED,
    payload: bool,
});

export const setEditForm = (data = null) => ({
    type: types.SET_EDIT_FORM,
    payload: data,
});

export const setDeleteForm = (data = null) => ({
    type: types.SET_DELETE_FORM,
    payload: data,
});

// тепловая карта инцидентов
export const loadIncidentHeatMap = (filter = {}) => ({
    type: types.LOAD_INCIDENT_HEAT_MAP,
    payload: filter
});

export const loadedIncidentHeatMap = (data) => ({
    type: types.LOADED_INCIDENT_HEAT_MAP,
    payload: data
});

export const clearIncidentHeatMap = () => ({
    type: types.LOADED_INCIDENT_HEAT_MAP,
    payload: []
});

export const loadingIncidentHeatMap = (bool = false) => ({
    type: types.LOADING_INCIDENT_HEAT_MAP,
    payload: bool,
});

// Статусы Инцидентов
export const loadIncidentsStatuses = () => ({
    type: types.LOAD_INCIDENTS_STATUSES
});

export const loadedIncidentsStatuses = (data) => ({
    type: types.LOADED_INCIDENTS_STATUSES,
    payload: data
});

// Типы Инцидентов
export const loadIncidentTypes = (page = 1, limit = 25, params, callback) => ({
    type: types.LOAD_INCIDENT_TYPES,
    payload: {
        page,
        limit,
        ...params
    },
    callback
});

export const loadingIncidentTypes = (bool = false) => ({
    type: types.LOADING_INCIDENT_TYPES,
    payload: bool
});

export const loadedIncidentTypes = (data) => ({
    type: types.LOADED_INCIDENT_TYPES,
    payload: data
});

export const createIncidentType = (data, callback) => ({
    type: types.CREATE_INCIDENT_TYPE,
    payload: data,
    callback
});

export const editIncidentType = (id, data, callback) => ({
    type: types.EDIT_INCIDENT_TYPE,
    payload: { id, data },
    callback
});

export const deleteIncidentType = (id, callback) => ({
    type: types.DELETE_INCIDENT_TYPE,
    payload: id,
    callback
});

export const loadIncidentTypeById = (id, callback) => ({
    type: types.GET_INCIDENT_TYPE_BY_ID,
    payload: id,
    callback
});

// Категории инцидентов
export const loadIncidentCategories = () => ({
    type: types.LOAD_INCIDENT_CATEGORIES,
});

export const loadingIncidentCategories = (bool = false) => ({
    type: types.LOADING_INCIDENT_CATEGORIES,
    payload: bool
});

export const loadedIncidentCategories = (data) => ({
    type: types.LOADED_INCIDENT_CATEGORIES,
    payload: data
});

// Сценарии реагирования
export const loadResponseScenarios = (page = 1, limit = 25, params = {}, forSelect = false) => ({
    type: types.LOAD_RESPONSE_SCENARIOS,
    payload: { page, limit, ...params },
    forSelect
});

export const loadingResponseScenarios = (loading) => ({
    type: types.LOADING_RESPONSE_SCENARIOS,
    payload: loading
});

export const loadedResponseScenarios = (data) => ({
    type: types.LOADED_RESPONSE_SCENARIOS,
    payload: data
});

export const loadedResponseScenariosForSelect = (data) => ({
    type: types.LOADED_RESPONSE_SCENARIOS_FOR_SELECT,
    payload: data
});



export const createResponseScenario = (data, callback) => ({
    type: types.CREATE_RESPONSE_SCENARIO,
    payload: data,
    callback
});

export const getResponseScenarioById = (id, callback) => ({
    type: types.GET_RESPONSE_SCENARIO_BY_ID,
    payload: { id },
    callback
});

export const editResponseScenario = (id, data, callback) => ({
    type: types.EDIT_RESPONSE_SCENARIO,
    payload: { id, data },
    callback
});

export const deleteResponseScenario = (id, callback) => ({
    type: types.DELETE_RESPONSE_SCENARIO,
    payload: id,
    callback
});

//категории сценариев
export const loadCategories = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_CATEGORIES,
    payload: { page, limit, ...params }
});

export const loadingCategories = (bool = false) => ({
    type: types.LOADING_CATEGORIES,
    payload: bool
});

export const loadedCategories = (data) => ({
    type: types.LOADED_CATEGORIES,
    payload: data
});

// мероприятия
export const loadEvents = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_EVENTS,
    payload: { page, limit, ...params }
});

export const loadingEvents = (bool = false) => ({
    type: types.LOADING_EVENTS,
    payload: bool
});

export const loadedEvents = (data) => ({
    type: types.LOADED_EVENTS,
    payload: data
});

export const createEvent = (data) => ({
    type: types.CREATE_EVENT,
    payload: data
});

export const editEvent = (id, data) => ({
    type: types.EDIT_EVENT,
    payload: {
        id,
        data
    }
});

export const deleteEvent = (id) => ({
    type: types.DELETE_EVENT,
    payload: id
});

// объекты мероприятий
export const loadEventObjects = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_EVENT_OBJECTS,
    payload: { page, limit, ...params }
});

export const loadingEventObjects = (bool = false) => ({
    type: types.LOADING_EVENT_OBJECTS,
    payload: bool
});

export const loadedEventObjects = (data) => ({
    type: types.LOADED_EVENT_OBJECTS,
    payload: data
});

export const createEventObjects = (data, callback = () => {}) => ({
    type: types.CREATE_EVENT_OBJECTS,
    payload: data,
    callback
});

export const editEventObjects = (id, data, callback = () => {}) => ({
    type: types.EDIT_EVENT_OBJECTS,
    payload: { id, data },
    callback
});

export const deleteEventObjects = (id, callback = () => {}) => ({
    type: types.DELETE_EVENT_OBJECTS,
    payload: id,
    callback
});

export const loadEventObjectsTypes = () => ({
    type: types.LOAD_EVENT_OBJECTS_TYPES
});

export const loadedEventObjectsTypes = (data) => ({
    type: types.LOADED_EVENT_OBJECTS_TYPES,
    payload: data
});

// уровень угрозы
export const loadThreatLevels = (page = 1, limit = 100, params) => ({
    type: types.LOAD_THREAT_LEVELS,
    payload: {
        page,
        limit,
        ...params
    }
});

export const loadingThreatLevels = (bool = false) => ({
    type: types.LOADING_THREAT_LEVELS,
    payload: bool
});

export const loadedThreatLevels = (data) => ({
    type: types.LOADED_THREAT_LEVELS,
    payload: data
});

export const createThreatLevel = (data, callback) => ({
    type: types.CREATE_THREAT_LEVEL,
    payload: data,
    callback
});

export const editThreatLevel = (id, data, callback) => ({
    type: types.EDIT_THREAT_LEVEL,
    payload: {
        id,
        data
    },
    callback
});

export const deleteThreatLevel = (id, callback) => ({
    type: types.DELETE_THREAT_LEVEL,
    payload: id,
    callback
});

// явления
export const loadPhenomenons = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_PHENOMENONS,
    payload: { page, limit, ...params }
});

export const loadingPhenomenons = (loading) => ({
    type: types.LOADING_PHENOMENONS,
    payload: loading
});

export const loadedPhenomenons = (data) => ({
    type: types.LOADED_PHENOMENONS,
    payload: data
});

// виды тс
export const loadVehicleCategories = () => ({
    type: types.LOAD_VEHICLES_CATEGORIES
});

export const loadedVehicleCategories = (data) => ({
    type: types.LOADED_VEHICLES_CATEGORIES,
    payload: data
});

export const loadIncidentEvents = (id) => ({
    type: types.LOAD_INCIDENT_EVENTS,
    payload: id
});

export const loadingIncidentEvents = (loading) => ({
    type: types.LOADING_INCIDENT_EVENTS,
    payload: loading
});

export const loadedIncidentEvents = (data) => ({
    type: types.LOADED_INCIDENT_EVENTS,
    payload: data
});

// Факторы
export const loadIncidentsFactors = (page = 1, limit = 25, params, callback) => ({
    type: types.LOAD_INCIDENTS_FACTORS,
    payload: {
        page,
        limit,
        params,
    },
    callback
});

export const loadingIncidentsFactors = (bool = false) => ({
    type: types.LOADING_INCIDENTS_FACTORS,
    payload: bool
});

export const loadedIncidentsFactors = (data) => ({
    type: types.LOADED_INCIDENTS_FACTORS,
    payload: data
});

export const createIncidentsFactors = (data, callback) => ({
    type: types.CREATE_INCIDENTS_FACTORS,
    payload: data,
    callback
});

export const editIncidentsFactors = (id, data, callback) => ({
    type: types.EDIT_INCIDENTS_FACTORS,
    payload: {
        id,
        data
    },
    callback
});

export const deleteIncidentsFactors = (id, callback) => ({
    type: types.DELETE_INCIDENTS_FACTORS,
    payload: id,
    callback
});

//типовые операции
export const loadOperationTypes = () => ({
    type: types.LOAD_OPERATION_TYPES
});

export const loadedOperationTypes = (data) => ({
    type: types.LOADED_OPERATION_TYPES,
    payload: data
});

export const loadTypicalOperations = (page = 1, limit = 25, params) => ({
    type: types.LOAD_TYPICAL_OPERATIONS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingTypicalOperations = (bool = false) => ({
    type: types.LOADING_TYPICAL_OPERATIONS,
    payload: bool
});

export const loadedTypicalOperations = (data) => ({
    type: types.LOADED_TYPICAL_OPERATIONS,
    payload: data
});

export const createTypicalOperation = (data, callback) => ({
    type: types.CREATE_TYPICAL_OPERATION,
    payload: data,
    callback
});

export const editTypicalOperation = (id, data, callback) => ({
    type: types.EDIT_TYPICAL_OPERATION,
    payload: {
        id,
        data
    },
    callback
});

export const deleteTypicalOperation = (id, callback) => ({
    type: types.DELETE_TYPICAL_OPERATION,
    payload: id,
    callback
});

// триггеры
export const loadTriggerStatuses = () => ({
    type: types.LOAD_TRIGGER_STATUSES
});

export const loadedTriggerStatuses = (data) => ({
    type: types.LOADED_TRIGGER_STATUSES,
    payload: data
});

export const loadTriggers = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_TRIGGERS,
    payload: { page, limit, ...params }
});

export const loadingTriggers = (loading) => ({
    type: types.LOADING_TRIGGERS,
    payload: loading
});

export const loadedTriggers = (data) => ({
    type: types.LOADED_TRIGGERS,
    payload: data
});

export const createTrigger = (data, callback) => ({
    type: types.CREATE_TRIGGER,
    payload: data,
    callback
});

export const editTrigger = (id, data, callback) => ({
    type: types.EDIT_TRIGGER,
    payload: { id, data },
    callback
});

export const deleteTrigger = (id, callback) => ({
    type: types.DELETE_TRIGGER,
    payload: id,
    callback
});

export const loadPolygonForTriggers = (url, params, callback) => ({
    type: types.LOAD_POLYGON_FOR_TRIGGERS,
    payload: { url, params },
    callback
});

export const loadKeyWordList = (page = 1, limit = 25, params) => ({
    type: types.LOAD_KEY_WORD_LIST,
    payload: {
        page,
        limit,
        params
    }
});

export const loadedKeyWordList = (data) => ({
    type: types.LOADED_KEY_WORD_LIST,
    payload: data
});

export const loadingKeyWordList = (bool = false) => ({
    type: types.LOADING_KEY_WORD_LIST,
    payload: bool
});

export const editKeyWord = (id, data, callback) => ({
    type: types.EDIT_KEY_WORD,
    payload: {
        id,
        data
    },
    callback
});

export const addKeyWord = (data, callback) => ({
    type: types.ADD_KEY_WORD,
    payload: data,
    callback
});

export const deleteKeyWord = (id, callback) => ({
    type: types.DELETE_KEY_WORD,
    payload: id,
    callback
});

// загрузка объектов инцидента по координатам
export const loadIncidentObjects = (params, fnCallback, fnLoading) => ({
    type: types.LOAD_INCIDENT_OBJECTS,
    payload: params,
    fnCallback,
    fnLoading
});

// загрузка типов объектов инцидента
export const loadIncidentObjectTypes = () => ({
    type: types.LOAD_INCIDENT_OBJECTS_TYPES
});

export const loadedIncidentObjectTypes = (data) => ({
    type: types.LOADED_INCIDENT_OBJECTS_TYPES,
    payload: data
});

export const loadIncidentsCloseReasons = (fnCallback, fnLoading) => ({
    type: types.LOAD_INCIDENTS_CLOSE_REASONS,
    fnCallback,
    fnLoading
});

// типы для метрополитена
export const loadIncidentsCloseTypes = (fnCallback, fnLoading) => ({
    type: types.LOAD_INCIDENTS_CLOSE_TYPES,
    fnCallback,
    fnLoading
});

// загрузка предварительных данных для уведомления
export const loadIncidentsNotifications = (data = {}, fnCallback, fnLoading) => ({
    type: types.LOAD_INCIDENTS_NOTIFICATIONS,
    payload: data,
    fnCallback,
    fnLoading
});

// создание уведомления
export const createIncidentsNotifications = (data = {}, fnCallback, fnLoading) => ({
    type: types.CREATE_INCIDENTS_NOTIFICATIONS,
    payload: data,
    fnCallback,
    fnLoading
});

//операции инцидента
export const loadIncidentOperations = (id) => ({
    type: types.LOAD_INCIDENT_OPERATIONS,
    payload: id
});

export const loadingIncidentOperations = (loading) => ({
    type: types.LOADING_INCIDENT_OPERATIONS,
    payload: loading
});

export const loadedIncidentOperations = (data) => ({
    type: types.LOADED_INCIDENT_OPERATIONS,
    payload: data
});

export const editIncidentOperations = (id, data, callback) => ({
    type: types.EDIT_INCIDENT_OPERATIONS,
    payload: { id, data },
    callback
});

export const loadInfopanel = (params = {}) => ({
    type: types.LOAD_INFOPANEL,
    payload: params
});

export const loadingInfopanel = (bool = false) => ({
    type: types.LOADING_INFOPANEL,
    payload: bool
});

export const loadedInfopanel = (data) => ({
    type: types.LOADED_INFOPANEL,
    payload: data
});

export const clearInfopanel = () => ({
    type: types.CLEAR_INFOPANEL,
});

export const loadStories = (page = 1, limit = 25, params) => ({
    type: types.LOAD_STORIES,
    payload: {
        page,
        limit,
        ...params
    }
});

export const loadingStories = (bool = false) => ({
    type: types.LOADING_STORIES,
    payload: bool
});

export const loadedStories = (data) => ({
    type: types.LOADED_STORIES,
    payload: data
});

export const createStories = (data, callback) => ({
    type: types.CREATE_STORY,
    payload: data,
    callback
});
export const loadIncidentHistoryById = (page, limit, id) => ({
    type: types.LOAD_INCIDENT_HISTORY_BY_ID,
    payload: {
        id,
        params: { page, limit }
    }
});

export const loadingIncidentHistoryById = (bool = false) => ({
    type: types.LOADING_INCIDENT_HISTORY_BY_ID,
    payload: bool
});

export const loadedIncidentHistoryById = (data) => ({
    type: types.LOADED_INCIDENT_HISTORY_BY_ID,
    payload: data,
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

export const editStories = (id, data, callback) => ({
    type: types.EDIT_STORY,
    payload: {
        id,
        data
    },
    callback
});

export const deleteStories = (id, callback) => ({
    type: types.DELETE_STORY,
    payload: id,
    callback
});

export const loadStoryStatuses = () => ({
    type: types.LOAD_STORY_STATUSES,
});

export const loadStoryConnection = () => ({
    type: types.LOAD_STORY_CONNECTION,
});

export const loadedStoryStatuses = (data) => ({
    type: types.LOADED_STORY_STATUSES,
    payload: data
});

export const loadedStoryConnection = (data) => ({
    type: types.LOADED_STORY_CONNECTION,
    payload: data
});

export const loadingButton = (bool) => ({
    type: types.LOADING_BUTTON,
    payload: bool,
});
