import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMonths, set, subDays } from 'date-fns';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';

import * as actions from 'redux/Efficiency/actions';
import { efficiencySelectors } from 'redux/Efficiency';
import { dateWithDashYYYYMMDD, getConfigForMinMaxDate } from 'helpers/date.config';
import { useDebounce } from 'helpers/hooks';
import formatDate from 'helpers/constants/dateFormat';
import Loading from 'components/common/Loading';
import DistrictSwitch from 'components/common/Switcher';
import MapRegions from 'components/common/Map/MapRegions';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import Table from 'components/common/DtpHeat/Table';
import Legend from 'components/common/DtpHeat/Legend';
import { config } from 'config';


const DtpByPeriod = () => {
    const dispatch = useDispatch();

    const filterReportEfficiencyDtpReport = useSelector(efficiencySelectors.filterReportEfficiencyDtpReport);
    const reportEfficiencyDtpReport = useSelector(efficiencySelectors.reportEfficiencyDtpReport);
    const loadingReportEfficiencyDtpReport = useSelector(efficiencySelectors.loadingReportEfficiencyDtpReport);

    // районы городов или области
    const [isArea, setIsArea] = useState(false);
    const changeIsArea = (area) => {
        setIsArea(area);
        // сбрасываем регион при смене
        setSelectedRegion(null);
    };

    const defaultDate = new Date();
    const [currentMonth, setCurrentMonth] = useState(
        filterReportEfficiencyDtpReport?.date || defaultDate
    );

    // выбранный регион
    const [selectedRegion, setSelectedRegion] = useState(null);
    const changeSelectedRegion = (region) => {
        setSelectedRegion((old) => region?.id === old?.id ? null : region);
    };
    const fetchData = (date) => dispatch(actions.loadReportsEfficiencyDtpReport(
        // выбираем 1 января
        dateWithDashYYYYMMDD(set(date, { date: 1, month: 0 })),
        // выбираем весь выбранный месяц
        dateWithDashYYYYMMDD(
            subDays(
                addMonths(
                    set(date, { date: 1 }),
                    1
                ),
                1
            )
        )
    ));
    const debounceFetch = useDebounce(fetchData, 500);

    useEffect(() => {
        fetchData(currentMonth);

        return () => {
            dispatch(actions.clearReportsEfficiencyDtpReport());
        };
    }, []);

    const regionTitle = selectedRegion?.name
        || (
            isArea
                ? 'Данные по районам области'
                : 'Данные по районам города'
        );

    const filterByRegion = (region) => (item) => {
        return region && Object.keys(region).length > 0
            ? item?.data?.[region?.id] || item?.data?.[region?.name] || {}
            : item?.total || {};
    };

    const setFilter = (filter) => dispatch(actions.setFilterReportsEfficiencyDtpReport(filter));

    const resetFilter = () => {
        setFilter({});
        setCurrentMonth(new Date());
        setIsArea(false);
        setSelectedRegion(null);
        fetchData(new Date());
    };

    const changeDate = (value) => {
        setCurrentMonth(value);
        setFilter({
            ...filterReportEfficiencyDtpReport,
            date: value,
        });
        debounceFetch(value);
    };

    return (
        <>
            <Grid
                container
                direction="row"
                spacing={2}
                style={{ height: '100%' }}
            >
                <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'pace-around',
                    }}
                >

                    {loadingReportEfficiencyDtpReport && (
                        <div
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <Loading />
                        </div>
                    )}

                    {loadingReportEfficiencyDtpReport === false && (
                        <>
                            {/* переключатель регионов */}
                            {!config.get('disableSwitcher')
                                && <div style={{ marginBottom: '.5rem' }}>
                                    <DistrictSwitch
                                        value={isArea}
                                        onChange={changeIsArea}
                                        asButton
                                    />
                                </div>
                            }

                            {/* тепловая карта */}
                            <div style={{ height: '100%', maxHeight: '500px' }}>
                                <MapRegions
                                    isArea={isArea}
                                    selectedRegion={selectedRegion}
                                    onSelectRegion={changeSelectedRegion}
                                />
                            </div>
                        </>
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    {loadingReportEfficiencyDtpReport === false && (
                        <>
                            <div style={{ marginBottom: '.5rem' }}>
                                <SingleKeyboardDateTimePicker
                                    onChange={changeDate}
                                    value={currentMonth}
                                    pickerFormat={formatDate.MONTH_FOR_PICKER}
                                    dateOnly={true}
                                    views={['month', 'year']}
                                    // minDate={set(new Date(), { month: 0, year: 2022 })}
                                    maxDate={getConfigForMinMaxDate()}
                                    label="Месяц/год"
                                />
                                {/*{*/}
                                {/*    (*/}
                                {/*        currentMonth.getFullYear() !== defaultDate.getFullYear()*/}
                                {/*        || currentMonth.getMonth() !== defaultDate.getMonth()*/}
                                {/*    )*/}
                                {/*        ? (*/}
                                {/*            <Tooltip title="Сбросить фильтр">*/}
                                {/*                <IconButton*/}
                                {/*                    aria-label="settings"*/}
                                {/*                    size="small"*/}
                                {/*                    onClick={resetFilter}*/}
                                {/*                >*/}
                                {/*                    <ClearIcon />*/}
                                {/*                </IconButton>*/}
                                {/*            </Tooltip>*/}
                                {/*        )*/}
                                {/*        : null*/}
                                {/*}*/}
                            </div>

                            <div className="row justify-content-center">
                                <Typography variant="h6">
                                    {regionTitle}
                                </Typography>

                                {selectedRegion
                                    ? (
                                        <Tooltip title="Сбросить выбранный район">
                                            <IconButton
                                                aria-label="settings"
                                                size="small"
                                                onClick={() => {
                                                    setSelectedRegion(null);
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                    : null
                                }
                            </div>

                            <Table
                                data={reportEfficiencyDtpReport?.[isArea === false ? 'cities' : 'areas']}
                                filter={filterByRegion(selectedRegion)}
                                date={currentMonth}
                            />

                            <Legend
                                data={reportEfficiencyDtpReport?.legend || []}
                            />

                        </>
                    )}

                </Grid>
            </Grid>
        </>
    );
};

export default DtpByPeriod;
