import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import titles from 'helpers/constants/titles';

interface TypeProps<T> extends CommonAutocompleteProps<T> {
    filter?: {
        name: string;
    },
}

type Type = {
  id?: number,
  name?: string
}

const SelectEventObjectsTypes = ({
    multiple,
    selected,
    onChange,
    required,
    disabled,
    label = titles.TYPE_EVENTS,
    error,
    helperText,
    className,
    onReset,
    options
}: TypeProps<Type>) => {
    return (
        <>
            <CommonAutocomplete
                className={className}
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                renderLabel={(option) => option?.name || ''}
                label={label}
                error={error}
                helperText={helperText}
                onReset={onReset}
                showAsSelect
                options={options}
                filterSelectedOptions
            />
        </>
    );
};

export default SelectEventObjectsTypes;
