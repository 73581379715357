import CollectionServices from '../Dictionaries/CollectionServices';
import Jobs from '../Dictionaries/Jobs';

const dictionariesRoutes = [
    {
        path: '/data-bus/dictionaries/collection-services',
        component: CollectionServices,
        exact: true,
    },
    {
        path: '/data-bus/dictionaries/jobs',
        component: Jobs,
        exact: true,
    },
];

export default dictionariesRoutes;