import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';

import mapHelper from 'helpers/mapHelper';
import { useDebounce, } from 'helpers/hooks';
import { GeoJsonNew } from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { MapPopUpListener } from 'components/MapComponents/MapPopUp';
import { centerToGeometry } from 'components/MapComponents/helpers/goToGeometry';
import * as actions from 'modules/ExternalTransport/redux/actions';
import { externalTransportSelectors } from 'modules/ExternalTransport';
import { createIconMarker, getFilter } from 'modules/ExternalTransport/utils/helper';

import config from './config';
import PopUpModal from './PopUpModal';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const stationPolygon = useSelector(externalTransportSelectors.stationPolygon);
    const active = useSelector(externalTransportSelectors.active);
    const filters = useSelector(externalTransportSelectors.filters);

    const fetchPolygon = useCallback(() => {
        const filter = getFilters(filters, getFilter);
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadStationPolygon(polygon, filter));
    },[dispatch, filters, map]);

    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = useCallback(() => debounceFetchPolygon(), [debounceFetchPolygon]);

    useEffect(() => {
        fetchPolygon();
    }, [fetchPolygon]);

    useEffect(() => () => {
        dispatch(actions.clearActive());
    }, []);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);
        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [handleFetchPolygon, map]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active;
            if (geometry) {
                centerToGeometry(map, geometry);
            }
        }
    }, [active]);

    const polygonGeometry = useMemo(() => {
        return stationPolygon?.reduce((res, item) => {
            const {
                id,
                name,
                geometry = {},
                color,
                transport_type
            } = item;

            res.push({
                ...geometry,
                properties: {
                    ...geometry?.properties,
                    id,
                    name,
                    color,
                    transport_type
                },
                attribution: {
                    color,
                },
                style: {
                    color,
                },
            });

            return res;
        }, []);
    }, [stationPolygon, active]);

    return (
        <>
            <GeoJsonNew
                {...props}
                data={polygonGeometry}
                toolTipTemplate={({ name = '' }) => <div>{name ?? ''}</div>}
                icon={item => createIconMarker(item.color, item.transport_type)}
                idPrefix={config.slug}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, -36]
                }}
                onClick={(item) => {
                    const element = stationPolygon?.find?.(i => i?.id === item?.id);
                    if (element) dispatch(actions.setActive(element));
                }}
            />

            {/* popup */}
            <MapPopUpListener
                polygonSelector={externalTransportSelectors.stationPolygon}
                activeSelector={externalTransportSelectors.active}
            >
                <PopUpModal
                    onClose={() => dispatch(actions.clearActive())}
                />
            </MapPopUpListener>

        </>
    );
};

export default Layer;
