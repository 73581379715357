import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadAppLogs } from 'redux/Admin/actions';
import { adminSelectors } from 'redux/Admin';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';
import titles from 'helpers/constants/titles';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf,
} from 'helpers/date.config';

import Item from './Item';
import Filter from './Filter';

import type { Meta } from 'types/pagination';
import type { Log } from './types';

const AppLogs = () => {
    const dispatch = useDispatch();

    const list: Log[] = useSelector(adminSelectors.appLogsData);
    const meta: Meta = useSelector(adminSelectors.appLogsMeta);
    const loading = useSelector(adminSelectors.loadingAppLogs);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({
        page: 1,
        data: {
            start_date: fullDateTimeWithTimeZone(getStartOf('day', getDateWithDuration({ weeks: -1 }))),
            end_date: fullDateTimeWithTimeZone(getEndOf()),
        },
    });

    useEffect(() => {
        dispatch(loadAppLogs(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    const renderContent = () => (
        <>
            {list?.length > 0 ? (
                <LSContainer
                    headers={[
                        { title: titles.DATE, width: '14%' },
                        { title: titles.SERVICE, width: '32%' },
                        { title: titles.URL, width: '34%' },
                        { title: titles.METHOD, width: '10%' },
                        { title: titles.REQUEST_CODE, width: '10%' },
                    ]}
                >
                    {list.map((item, index) => (
                        <Item key={`${item.date_at}${index}`} item={item} />
                    ))}
                </LSContainer>
            ) : (
                !loading && <NoData />
            )}
        </>
    );

    return (
        <PageLayout
            header="Логи приложения"
            filters={<Filter setParams={setParams} />}
            informPanelProps={{
                buttons: (
                    <PageReportModal>
                        <PageReportBlock filter={params.data} />
                    </PageReportModal>
                ),
                total: meta?.total,
            }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit,
                setLimit,
            }}
        />
    );
};

export default AppLogs;
