import { useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { createNewStation, editStation, loadStationAttributes } from 'redux/TransportPassenger/actions';
import buttons from 'helpers/constants/buttons';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { useStoreProp, useValidation } from 'helpers/hooks';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import FieldsModal from 'components/common/Location/FieldsModal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import MapForm from 'components/MapComponents/MapForm';
import SelectVehicleType from 'components/common/Autocomplete/PassengerTransport/VehicleTypes';
import InfoBlock from 'components/common/InfoBlock';

const useStyles = makeStyles({
    selected: {
        margin: '4px 5px 4px 0',
        '& > span': {
            fontSize: '12px'
        },
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    title: {
        paddingTop: 20
    }
});

function StationModal ({ data, isNew, open, onClose, reloadList }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const validation = useValidation();

    const stationAttributes = useStoreProp(
        loadStationAttributes,
        'transportPassenger',
        'stationAttributes'
    );

    const initialState = {
        ...data,
        name: data?.name || '',
        lat: data?.check_point?.lat || '',
        lon: data?.check_point?.lon || '',
        area: data?.check_point?.geometry || {},
        direction: data?.direction || '',
        comment: data?.comment || '',
        attribute: data?.attribute || 1,
        is_smart: data?.is_smart || false,
        type_list: data?.type_list || [],
    };

    const [mapModal, setMapModal] = useState(false);
    const [formData, setFormData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name] : value
        });
    };

    const handleCheck = (name, value) => {
        setFormData({
            ...formData,
            [name] : value
        });
    };

    const getErrorValidation = (key) => validation.get(key);

    const handleSaved = () => {
        const { lat, lon, area, ...others } = formData;

        const result = {
            ...others,
            check_point: { lat, lon, geometry: area, },
        };

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createNewStation(result, callback))
            : dispatch(editStation(data.id, result, callback));
    };

    const getLatLonGeometry = formData.lat && formData.lon
        ? {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    formData.lon,
                    formData.lat,
                ],
            }
        } : null;

    return (
        <Modal
            title={isNew ? titles.ADD_STATION: titles.EDIT_STATION}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding={true}
            isOpen={open}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSaved,
                        disabled: !formData.name || !formData.lat || !formData.lon || !Object.keys(formData.area)?.length > 0
                    }
                ]}
            />}
        >
            <div className="modal__form">
                {mapModal && (
                    <MapForm
                        isOpen={mapModal}
                        noPadding
                        latlon={[formData.lat, formData.lon]}

                        onAccept={({ 0: lat, 1: lon }) => {
                            setFormData({ ...formData, lat: lat, lon: lon });
                            setMapModal(false);
                        }}
                        onClose={() => setMapModal(false)}
                    />
                )}
                <FormInfoWrapper error={validation.isKey('name')} helperText={validation.get('name')} >
                    <TextField
                        value={formData.name}
                        onChange={onChange}
                        className="block"
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        name="name"
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>

                <FormControl className="block" variant="outlined" size="small">
                    <InputLabel>Обязательность</InputLabel>
                    <Select
                        label="Обязательность"
                        onChange={onChange}
                        value={formData.attribute}
                        name="attribute"
                    >
                        {Object.keys(stationAttributes).length > 0
                            ? Object.keys(stationAttributes).map(key =>
                                <MenuItem key={key} value={key}>{stationAttributes[key]}</MenuItem>
                            )
                            : <MenuItem>{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>

                <SelectVehicleType
                    multiple
                    selected={formData.type_list}
                    onChange={(value) => setFormData({ ...formData, type_list: value })}
                    renderLabel={(option) => option?.station_name || ''}
                    renderProps={(el) => <div>{el?.station_name}</div>}
                    helperText="Виды ТС, которые могут останавливаться на остановке"
                />

                <div className="block">
                    <FormControlLabel
                        // sx={{ marginTop: '1rem' }}
                        label="Умная остановка"
                        control={
                            <Checkbox
                                name="is_smart"
                                onChange={(e) => handleCheck('is_smart', e.target.checked)}
                                checked={formData.is_smart}
                            />
                        }
                    />
                </div>

                <InfoBlock label={titles.LOCATION} className="block">
                    <div className="row">
                        <FormInfoWrapper error={validation.isKey('lat')} helperText={validation.get('lat')}>
                            <TextField
                                className="row__item"
                                label={titles.LAT}
                                variant="outlined"
                                size="small"
                                name="lat"
                                value={formData.lat}
                                onChange={onChange}
                                error={validation.isKey('lat')}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper error={validation.isKey('lon')} helperText={validation.get('lon')}>
                            <TextField
                                className="row__item"
                                label={titles.LON}
                                variant="outlined"
                                size="small"
                                name="lon"
                                value={formData.lon}
                                onChange={onChange}
                                error={validation.isKey('lon')}
                            />
                        </FormInfoWrapper>
                    </div>

                    <FormButtonsComponent
                        noPadding
                        positionLeft
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.defaultInfo,
                                name: buttons.SELECT,
                                onClick: () => setMapModal(true),
                            }
                        ]}
                    />

                    <div className="block">
                        <h3 className={classes.title}>{titles.STOP_AREA_STATION}:</h3>

                        <FieldsModal
                            title="Выбрать область на карте"
                            fields={{ geometry: formData?.area || {} }}
                            buttonText={buttons.SELECT}
                            buttonVariant="contained"
                            onChange={({ geometry }) => {
                                setFormData({ ...formData, area: geometry });
                                validation.deleteKey('area.geometry.coordinates.0');
                            }}
                        >
                            <MapGeoJson
                                visibleGeometry={getLatLonGeometry}
                                circle={true}
                                polygon={true}
                                polyline={false}
                                marker={false}
                                isHideValidation
                            />
                        </FieldsModal>

                        {validation.isKey('area')
                            ? <FormHelperText error>{getErrorValidation('area')}</FormHelperText>
                            : null
                        }
                    </div>
                </InfoBlock>

                <FormInfoWrapper error={validation.isKey('direction')} helperText={validation.get('direction')}>
                    <TextField
                        value={formData.direction}
                        onChange={onChange}
                        className="block"
                        label={titles.DIRECTION}
                        variant="outlined"
                        size="small"
                        name="direction"
                        error={validation.isKey('direction')}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper error={validation.isKey('comment')} helperText={validation.get('comment')}>
                    <TextField
                        className="block"
                        label={titles.COMMENT}
                        multiline
                        rows={3}
                        variant="outlined"
                        value={formData.comment}
                        name="comment"
                        onChange={onChange}
                        error={validation.isKey('comment')}
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
}

export default StationModal;
