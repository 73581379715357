import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { createNodes, editNodes, loadingButton } from 'modules/DigitalTwin/redux/actions';
import { digitalTwinSelectors } from 'modules/DigitalTwin';

import type { Node } from '../../utils/types';

interface BaseProps {
    isOpen: boolean;
    onClose: () => void;
    reloadList: () => void;
}

interface AddProps extends BaseProps {
    isNew: true;
    item?: never;
}

interface EditProps extends BaseProps {
    item: Node;
    isNew?: false;
}

function ModalForm({ isOpen, onClose, isNew, item, reloadList }: AddProps | EditProps) {
    const dispatch = useDispatch();

    const loading = useSelector(digitalTwinSelectors.loadingButton);

    const initialState = {
        lat: item?.lat,
        lon: item?.lon,
    };

    const [formData, setFormData] = useState(initialState);

    const handleSave = () => {
        const { lat, lon } = formData;

        const prepareData = {
            ...(!isNew && { ...item }),
            lat: String(formData.lat),
            lon: String(formData.lon),
            type: 'Point',
            geometry: {
                type: 'Point',
                coordinates: [lon, lat],
            },
        };

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createNodes(prepareData, callback, loadingButton))
            : dispatch(editNodes(item.id, prepareData, callback, loadingButton));
    };

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            loading,
                        },
                    ]}
                />
            }
        >
            <MapDragMarker {...formData} onChange={setFormData} required />
        </Modal>
    );
}

export default ModalForm;
