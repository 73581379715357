import { memo } from 'react';

const Legend = () => {
    return (
        <div className="sidebar-tp-legend">
            <div className="item">
                <div className="title"></div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        fontSize: '1rem',
                        gap: '2rem',
                    }}
                >
                    <div className="flex">
                        <i className="far fa-dot-circle"/>
                        <div className="small">Узел</div>
                    </div>
                    <div className="flex" >
                        <i className="fas fa-horizontal-rule"/>
                        <div className="small">Ребро</div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default memo(Legend);
