import { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ setParams, test_id_prefix = '' }) => {
    const initState = {
        name: '',
        complex_id_list: [],
    };

    const [data, setData] = useState(initState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleSearch = () => {
        const prepareData = {
            ...data,
            complex_id_list: data.complex_id_list.map(({ id }) => +id)
        };
        
        setParams({ page: 1, query: removeEmptyFields(prepareData) });
    };

    const handleResetFilter = (needRefresh) => {
        if (needRefresh) {
            setParams(({ page: 1, query: {} }));
        }
        setData(initState);
    };

    const updateFilter = (filters) => {
        const params = {
            ...initState,
            ...filters,
        };
        setData(params);
        if (Object.keys(filters).length > 0) {
            setParams({ 
                page: 1, 
                query: removeEmptyFields({ ...params, complex_id_list: filters?.complex_id_list.map(({ id }) => id) }) 
            });
        }
    };

    const isDisabled = !data.name && !data.complex_id_list.length;

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter} 
            onSearch={handleSearch} 
            filter={data} 
            setUserFilter={updateFilter}
            disabled={isDisabled}
            test_id_prefix={test_id_prefix}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={data.name}
                    name="name"
                    onChange={handleChange}
                    inputProps={{
                        'data-testid': `${test_id_prefix}:filter/name/input`,
                    }}
                />
                <SelectComplex
                    selected={data.complex_id_list}
                    onChange={(complex_id_list) => setData({ ...data, complex_id_list })}
                    multiple
                    test_id_prefix={`${test_id_prefix}:filter/complex_id_list/input`}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
