import { editSettingsGroup, editSettingsList, loadSettingsGroup } from 'modules/React/redux/actions';
import Settings from 'components/common/Settings';

function ReactSettings() {
    return (
        <Settings
            storeProp="react"
            listName="settingsGroup"
            loadingName="loadingSettingsGroup"
            loadSettingsGroupAction={loadSettingsGroup}
            editSettingsGroupAction={editSettingsGroup}
            editSettingsListAction={editSettingsList}
        />
    );
}

export default ReactSettings;
