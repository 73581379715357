import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip
} from '@mui/material';

import { loadDtpLoadTypes, loadDtpStreets } from 'redux/Incidents/actions';
import { loadDtpStatuses } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import titles from 'helpers/constants/titles';
import formatDate from 'helpers/constants/dateFormat';
import { useStoreProp, useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import buttons from 'helpers/constants/buttons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import AddressOne from 'components/common/Location/AddressOne';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import TransportIncidentInfoModal from 'components/pages/Reports/DTP/Incidents/TransportIncidentInfoModal';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { generateOptions, getValue } from 'components/MapComponents/Layers/Incidents/helper';
import InfoBlock from 'components/common/InfoBlock';

const BasicData = (props) => {
    const {
        fields = {},
        onChange,
        requiredFields,
        typesList = [],
        isTi
    } = props;
    const dispatch = useDispatch();
    const validation = useValidation();

    const dtpStreets = useSelector(incidentsSelectors.dtpStreets);
    const dtpStreetsLoading = useSelector(incidentsSelectors.dtpStreetsLoading);
    const statuses = useStoreProp(loadDtpStatuses, 'incidents', 'statuses');
    const loadTypes = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');

    const statusesOptions = useMemo(() => generateOptions(statuses), [statuses]);
    const loadTypesOptions = useMemo(() => generateOptions(loadTypes), [loadTypes]);

    // const [showList, setShowList] = useState(false);
    const [openTIInfo, setOpenTIInfo] = useState(false);

    const handleEvent = (key) => (newValue) => {
        const { target: { value } } = newValue;

        if (newValue.target.type !== 'number') {
            onChange({
                [key]: value
            });
        }
        if (newValue.target.type === 'number' && Number(value) >= 0) {
            onChange({
                [key]: Number(value)
            });
        }

    };

    const handleLocationChange = (props) => {
        onChange({ ...props });
    };

    const handleChange = (key, value) => {
        onChange({ [key]: value });
    };

    const isErrorField = (key) => {
        return (requiredFields.includes(key) && !fields[key]) || validation.isKey(key);
    };

    const getErrorValidation = (key) => validation.get(key);

    return <>
        <div className="modal__form">
            <CommonAutocomplete
                className="block"
                label={titles.STATUS}
                selected={getValue(statuses, fields?.status)}
                options={statusesOptions}
                onChange={(value) => handleChange('status', value?.id || null)}
                renderLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                showAsSelect
                disabled={fields.type_load === 2}
            />

            <CommonAutocomplete
                className="block"
                label={titles.DTP_TYPE}
                selected={fields.dtp_type || null}
                options={typesList}
                onChange={(value) => handleChange('dtp_type', value || null)}
                renderLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                showAsSelect
                error={isErrorField('dtp_type')}
                helperText={getErrorValidation('dtp_type')}
            />

            <div className="block">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={fields.manual_edit}
                            onChange={(e, value) => handleChange('manual_edit', value)}
                            color="primary"
                        />
                    }
                    label={titles.MANUAL_EDIT}
                />
            </div>

            <div className="block">
                <SingleKeyboardDateTimePicker
                    onChange={(value) => handleChange('dtp_at', value)}
                    value={fields.dtp_at || null}
                    error={isErrorField('dtp_at')}
                    helperText={getErrorValidation('dtp_at')}
                    pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                    isDisabled={!!fields?.ti_id}
                />
            </div>

            <InfoBlock label={titles.LOCATION} className="block">
                <FormControl className="block" variant="outlined">
                    <LatLonCoordinates
                        {...fields}
                        onChange={handleLocationChange}
                        required
                    />
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <LoadAddressByCoords
                        {...fields}
                        onChange={handleLocationChange}
                    >
                        <AddressOne required />
                    </LoadAddressByCoords>
                </FormControl>

                <FieldsModal
                    title="Поставьте/передвиньте метку на карте"
                    buttonText={buttons.SELECT}
                    buttonVariant="contained"
                    fields={fields}
                    onChange={handleLocationChange}
                >
                    <MapDragMarker required />
                </FieldsModal>
            </InfoBlock>

            <div className="block">
                <Autocomplete
                    value={fields?.intersection || null}
                    options={dtpStreets}
                    onChange={(e, value) => onChange({ intersection: value })}
                    getOptionLabel={(option) => option}
                    freeSolo
                    autoSelect
                    loading={dtpStreetsLoading}
                    loadingText="Загрузка..."
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={titles.INTERSECTION_STREET}
                            onChange={(e) => dispatch(loadDtpStreets(e.target.value))}
                        />
                    )}
                />
            </div>

            <div className="block">
                <TextField
                    label="Километр (если авария на дороге)"
                    variant="outlined"
                    value={fields?.km || ''}
                    onChange={handleEvent('km')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    error={isErrorField('km')}
                    helperText={getErrorValidation('km')}
                />
            </div>

            <div className="block">
                <TextField
                    label="Метр (если авария на дороге)"
                    variant="outlined"
                    value={fields?.m || ''}
                    onChange={handleEvent('m')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    error={isErrorField('m')}
                    helperText={getErrorValidation('m')}
                />
            </div>

            <div className="block">
                <TextField
                    multiline={true}
                    label={titles.DTP_DESCRIPTION}
                    variant="outlined"
                    value={fields?.description || ''}
                    onChange={handleEvent('description')}
                    error={isErrorField('description')}
                    helperText={getErrorValidation('description')}
                />
            </div>

            <div className="block">
                <TextField
                    label={titles.SCHEMA_DTP}
                    variant="outlined"
                    value={fields?.schema_img_url || ''}
                    onChange={handleEvent('schema_img_url')}
                    error={isErrorField('schema_img_url')}
                    helperText={getErrorValidation('schema_img_url')}
                />
            </div>

            <CommonAutocomplete
                className="block"
                label={titles.DTP_DATA_SOURCE}
                selected={getValue(loadTypes, fields?.type_load)}
                options={loadTypesOptions}
                onChange={(value) => handleChange('type_load', value?.id || null)}
                renderLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => +option?.id === +value?.id}
                showAsSelect
                error={isErrorField('type_load')}
                helperText={getErrorValidation('type_load')}
            />

            <div style={{ marginBottom: 10 }}>
                <strong>Связанное событие:</strong> {fields?.ti_name || messages.NO_DATA}
                <Tooltip title={titles.SHOW}>
                    <span>
                        <IconButton onClick={() => setOpenTIInfo(true)} size="small" disabled={!fields.ti_id}>
                            <i className="far fa-arrows"/>
                        </IconButton>
                    </span>
                </Tooltip>
            </div>

            {openTIInfo && (
                <TransportIncidentInfoModal
                    transportIncidentId={fields?.ti_id}
                    onClose={() => setOpenTIInfo(false)}
                />
            )}

            <div className="block">
                <TextField
                    label={titles.COUNT_DTP_MEMBERS}
                    variant="outlined"
                    value={fields?.count_members || ''}
                    onChange={handleEvent('count_members')}
                    error={isErrorField('count_members')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    helperText={getErrorValidation('count_members')}
                />
            </div>

            <div className="block">
                <TextField
                    label={titles.COUNT_DTP_DEAD}
                    variant="outlined"
                    value={fields?.dead || ''}
                    onChange={handleEvent('dead')}
                    error={isErrorField('dead')}
                    helperText={getErrorValidation('dead')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>

            <div className="block">
                <TextField
                    label={titles.COUNT_DTP_WOUNDED}
                    variant="outlined"
                    value={fields?.wounded || ''}
                    onChange={handleEvent('wounded')}
                    error={isErrorField('wounded')}
                    helperText={getErrorValidation('wounded')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>

            <div className="block">
                <TextField
                    label={titles.COUNT_DTP_DEAD_KIDS}
                    variant="outlined"
                    value={fields?.dead_child || ''}
                    onChange={handleEvent('dead_child')}
                    error={isErrorField('dead_child')}
                    helperText={getErrorValidation('dead_child')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>

            <div className="block">
                <TextField
                    label={titles.COUNT_DTP_WOUNDED_KIDS}
                    variant="outlined"
                    value={fields?.wounded_child || ''}
                    onChange={handleEvent('wounded_child')}
                    error={isErrorField('wounded_child')}
                    helperText={getErrorValidation('wounded_child')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>

            <div className="block">
                <TextField
                    label={titles.COUNT_DTP_TS}
                    variant="outlined"
                    value={fields?.count_ts || ''}
                    onChange={handleEvent('count_ts')}
                    error={isErrorField('count_ts')}
                    helperText={getErrorValidation('count_ts')}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </div>
        </div>
    </>;
};

export default BasicData;
