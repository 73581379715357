import { useEffect } from 'react';
import L from 'leaflet';
import { valid } from 'geojson-validation';

import {
    getItemLinkedObject,
} from '../objectHelper';

const MapBind = ({
    data, // выбранные объекты
    type, // выбранный тип
    currentLatLon,
    map,
}) => {

    useEffect(() => {
        if (data.length > 0 || currentLatLon) {
            const llBounds = L.latLngBounds();
            if (currentLatLon) llBounds.extend(currentLatLon);


            data?.forEach(item => {
                const i = getItemLinkedObject(item, type)?.object || {};

                if (i?.lat && i?.lon) {
                    llBounds.extend([i.lat, i.lon]);
                } else if (valid(item?.geometry)) {
                    const json = L.geoJSON(item.geometry);
                    const boundce = json.getBounds();
                    llBounds.extend(boundce);
                }
            });
            if (llBounds.isValid()) map.fitBounds(llBounds);
        }
    }, [data, type, currentLatLon]);

    return null;
};

export default MapBind;
