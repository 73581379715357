import { Tooltip } from '@mui/material';

import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';

import CircleStatus from './CircleStatus';

import type { MonitoringEntity, Status } from './types';

interface ItemProps {
    item: MonitoringEntity;
    statuses: Status[];
}

const Item = ({ item, statuses }: ItemProps) => {

    const geometry = createPointGJ(item.lat, item.lon);

    const currentStatus = statuses.find((status) => status.id === item.status);

    return (
        <LSContentItem>
            <LSContentColumn>
                {currentStatus
                    ? <CircleStatus
                        title={currentStatus.description}
                        color={currentStatus.color}
                    />
                    : null}
            </LSContentColumn>

            <LSContentColumn>{item.device_name || messages.NO_VALUE}</LSContentColumn>

            <LSContentColumn>{item.reference_name || messages.NO_VALUE}</LSContentColumn>

            <LSContentColumn>{item.address_text || messages.NO_VALUE}</LSContentColumn>

            <LSContentColumn isActions>
                <FieldsModal
                    hideButtonAccept={true}
                    title={item.device_name}
                    iconButton={
                        <Tooltip title={buttons.SHOW_ON_MAP}>
                            <i className="fas fa-map-marked" />
                        </Tooltip>
                    }
                >
                    <MapGeoJson geometry={geometry} readOnly={true} />
                </FieldsModal>
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
