import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, ListItem } from '@mui/material';

import { createMaterial, editMaterial, loadComplexTypes } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import SelectDuploCodes from 'components/common/Autocomplete/DorisControl/DuploCodes';
import UploadImageField from 'components/common/Upload/UploadImageField';

import type { IComplexType, ItemType } from './types';

interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    reloadList: () => void
    isNew?: boolean
    item?: ItemType,
    test_id_prefix: string
}

const ModalForm = ({ isOpen, isNew, onClose, reloadList, item, test_id_prefix = '' }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const complexTypes = useStoreProp(loadComplexTypes, 'dorisControl', 'doris_complex_types');
    const  loadingButton  = useSelector(dorisControlSelectors.loadingButton);

    const initialState = {
        complex_types: item?.complex_types.map((id, i) => (
            { id, name: item?.complex_types_text[i] }
        )) || [],
        duplo_codes_models: item?.duplo_codes_models || [],
        duplo_codes: item?.duplo_codes || [],
        image: item?.image || '',
        with_video: item?.with_video || false,
    };

    const [data, setData] = useState(initialState);

    const handleChange = (name: string, value: any) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSave = () => {
        const prepareData = {
            ...data,
            complex_types: data.complex_types.map(({ id }) => id),
            duplo_codes: data.duplo_codes_models.map(({ id }) => id)
        };

        const callback = () => { onClose(); reloadList(); };

        isNew 
            ? dispatch(createMaterial(prepareData, callback))
            : dispatch(editMaterial(item?.id, prepareData, callback));
    };

    const isDisabled = !data.image 
        || data.duplo_codes_models.length === 0;

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding={true}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            loading: loadingButton,
                            disabled: isDisabled
                        }
                    ]}
                    test_id_prefix={test_id_prefix}
                />
            }
        >
            <form className="modal__form">
                <div className="block">
                    <CommonAutocomplete<IComplexType>
                        multiple={true}
                        selected={data.complex_types}
                        onChange={(value) => handleChange('complex_types', value)}
                        onReset={() => handleChange('complex_types', [])}
                        inputName={titles.COMPLEX_TYPE}
                        limitTags={1}
                        label={titles.COMPLEX_TYPE}
                        options={complexTypes}
                        filterSelectedOptions={true}
                        renderLabel={(option) => option?.name  || ''}
                        error={validation.isKey('complex_types')}
                        helperText={validation.get('complex_types')}
                        showAsSelect
                        test_id_prefix={`${test_id_prefix}:add/complex_types`}
                    />
                </div>

                <div className="block">
                    <SelectDuploCodes
                        multiple={true}
                        selected={data.duplo_codes_models}
                        onChange={(value) => handleChange('duplo_codes_models', value)}
                        required
                        error={validation.isKey('duplo_codes')}
                        helperText={validation.get('duplo_codes')}
                        test_id_prefix={`${test_id_prefix}:add/duplo_codes`}
                    />
                </div>
                
                <ListItem divider>
                    <UploadImageField
                        label="Образец коллажа"
                        value={data?.image}
                        name="image"
                        onChange={handleChange}
                        required
                        errorText={validation.get('image')}
                        imageErrorValidation={() => false}
                        helperText={''}
                        showPreview={true}
                        test_id_prefix={`${test_id_prefix}:add/image`}
                    />
                </ListItem>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.with_video}
                            onChange={() => setData(prev => ({ ...prev, with_video: !prev.with_video }))}
                            data-testid={`${test_id_prefix}:add/with_video/input`}
                        />
                    }
                    label="Требуется видео"
                    data-testid={`${test_id_prefix}:add/with_video/label`}
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
