import createIconCluster from 'components/MapComponents/helpers/createIconCluster';

const config = {
    name: 'Цифровой двойник',
    slug: 'digital-twin',
    clusterIcon: () => createIconCluster('far fa-vector-square'),
    layerIcon: 'far fa-vector-square',
    mapContextMenu: {
        title: 'Добавить ребро',
        event: 'add_digital_edge',
        minWidth: 210
    },
};

export default config;
