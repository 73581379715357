import { all, call, put, takeLatest } from 'redux-saga/effects';

import { messageTypes, showMessage } from 'redux/Message/actions';
import messages from 'helpers/constants/messages';

import api from './api.methods';
import * as actions from './actions';
import * as types from './types';

function* loadCollectionServicesSaga({ payload }) {
    yield put(actions.loadingCollectionServicesList(true));
    const response = yield call(api.getCollectionServices, payload);
    if (response?.success) {
        yield put(actions.loadedCollectionServicesList(response));
    }
    yield put(actions.loadingCollectionServicesList(false));
}

function* loadCollectionServiceTypesSaga() {
    const response = yield call(api.getCollectionServicesTypes);
    if (response?.success) {
        yield put(actions.loadedCollectionServicesTypes(response.data));
    }
}

function* loadCollectionServiceTemplatesSaga() {
    const response = yield call(api.getCollectionServicesTemplates);
    if (response?.success) {
        yield put(actions.loadedCollectionServicesTemplates(response.data));
    }
}

function* createCollectionServiceSaga({ payload, callback }) {
    const response = yield call(api.createCollectionService, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* editCollectionServiceSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editCollectionService, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* deleteCollectionServiceSaga({ payload, callback }) {
    const response = yield call(api.deleteCollectionService, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* loadCollectionServiceServicesSaga() {
    const response = yield call(api.getCollectionServicesServices);
    if (response?.success) {
        yield put(actions.loadedCollectionServicesServices(response.data));
    }
}

function* loadJobsListSaga({ payload }) {
    yield put(actions.loadingJobsList(true));
    const response = yield call(api.getJobs, payload);
    if (response?.success) {
        yield put(actions.loadedJobsList(response));
    }
    yield put(actions.loadingJobsList(false));
}

function* createJobSaga({ payload, callback }) {
    const response = yield call(api.createJob, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* editJobSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editJob, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* loadJobsPeriodSaga() {
    const response = yield call(api.getJobsPeriod);
    if (response?.success) {
        yield put(actions.loadedJobsPeriod(response.data));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_COLLECTION_SERVICES, loadCollectionServicesSaga),
        takeLatest(types.LOAD_COLLECTION_SERVICES_TYPES, loadCollectionServiceTypesSaga),
        takeLatest(types.LOAD_COLLECTION_SERVICES_TEMPLATES, loadCollectionServiceTemplatesSaga),

        takeLatest(types.CREATE_COLLECTION_SERVICE, createCollectionServiceSaga),
        takeLatest(types.EDIT_COLLECTION_SERVICE, editCollectionServiceSaga),
        takeLatest(types.DELETE_COLLECTION_SERVICE, deleteCollectionServiceSaga),

        takeLatest(types.LOAD_COLLECTION_SERVICES_SERVICES, loadCollectionServiceServicesSaga),

        takeLatest(types.LOAD_JOBS, loadJobsListSaga),
        takeLatest(types.CREATE_JOB, createJobSaga),
        takeLatest(types.EDIT_JOB, editJobSaga),
        takeLatest(types.LOAD_JOBS_PERIOD, loadJobsPeriodSaga),

    ]);
}
