import iconCluster from './icons/iconCluster';

const config = {
    name: 'Метеомониторинг',
    slug: 'meteo',
    mapContextMenu: false,
    clusterIcon: iconCluster,
    layerIcon: 'fas fa-cloud-sun',
};
// добавление на карте
export default config;
