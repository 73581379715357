import React from 'react';
import { useSelector } from 'react-redux';
import { Collapse, List, ListItem } from '@mui/material';

import { authSelectors } from 'redux/Auth';
import titles from 'helpers/constants/titles';
import { fullDateTime, getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

const UserInfo = ({ isOpen, user = {} }) => {
    const authUser = useSelector(authSelectors.authUser);
    const projects = authUser?.info?.currentService?.entity_list || [];

    return (
        <Collapse in={isOpen} timeout="auto" unmountOnExit className="info">
            {user.email && <div><strong>{titles.EMAIL}:</strong> {user.email}</div>}
            {user.phone && <div><strong>{titles.PHONE}:</strong> {user.phone}</div>}
            {user.created_at && <div><strong>{titles.CREATED_AT}:</strong> {getHumanDate(user.created_at)}</div>}
            {typeof user.is_active === 'boolean' && (
                <div><strong>{titles.USER}:</strong> {user.is_active ? titles.ACTIVE : titles.INACTIVE}</div>
            )}
            {user.end_access_at && <div><strong>{titles.END_ACCESS_DATE}:</strong> {fullDateTime(user.end_access_at)}</div>}
            {user.type_account_text && <div><strong>{titles.TYPE}:</strong> {user.type_account_text}</div>}
            {user.role_list?.length > 0 && (
                <div>
                    <h3>{titles.ROLES}:</h3>
                    <List dense disablePadding>
                        {user.role_list.map((el) => {
                            const role_project = projects.find((item) => item.code === el.entity_code) || {};
                            return (<ListItem key={`${el.id}_${el.entity_code}`}>
                                <div><strong>{el.description}</strong> - Проект: {role_project.description
                                    ? <span>{role_project.description}</span>
                                    : <span>{messages.INFO_NOT_AVAILABLE}</span>
                                }</div>
                            </ListItem>);
                        })}
                    </List>
                </div>
            )}
        </Collapse>
    );
};

export default UserInfo;
