import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCurrentCompany } from 'redux/Companies/actions';
import { loadRoadWorkOrders } from 'redux/RoadWorks/actions';
import { loadCategoriesVehicle, loadVehicle, setActive } from 'redux/TransportRoad/actions';
import * as actions from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import { companiesSelectors } from 'redux/Companies';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import Information from 'components/common/Information';
import MapScroll from 'components/MapComponents/MapScroll';
import { MapPopUp }  from 'components/MapComponents/MapPopUp';

import icon from '../icons/icon';
import config from '../config';

import ModalButtons from './ModalButtons';
import Orders from './Orders';

const PopUp = ({
    uuid,
    isOpen,
    history,
    readOnly,
    onClose,
}) => {
    const dispatch = useDispatch();

    const currentCompany = useSelector(companiesSelectors.currentCompany);

    const wsData = useSelector(transportRoadSelectors.wsData);
    const active = useSelector(transportRoadSelectors.active);
    const vehicleActive = useSelector(transportRoadSelectors.vehicleActive);
    const [telemetryData] = useSelector(transportRoadSelectors.telemetryReceiversData);

    const data = wsData?.[uuid]?.vehicle || null;
    const telemetry_receiver = telemetryData?.name || '';

    const vehicleId = data?.id || null;

    const {
        external_id,
        in_registry = false,
        in_work_order = false,
        speed = 0,
        navigation_at = null,
    } = wsData[uuid] || {};

    const category_id = data?.category_id || 0;

    const getTransportCategory = useTransportCategory(
        loadCategoriesVehicle,
        'transportRoad',
        'categoriesVehicle'
    );

    const {
        color: categoryColor,
        getImageTpl,
    } = getTransportCategory
        .getHelper
        .getCategory(category_id, in_registry, in_work_order);

    const categoryName = getTransportCategory
        .getHelper
        .getName(category_id);

    const dataInfo = {
        ...data,
        ...vehicleActive,
        external_id,
        speed,
        navigation_at,
        categoryTS: categoryName,
        telemetry_receiver
    };

    useEffect(() => {
        if(data?.egts_receiver_id) {
            dispatch(actions.loadTelemetryReceivers({ id: data.egts_receiver_id }));
        }
    }, []);

    const loadRWOrders = () => dispatch(loadRoadWorkOrders(1, 25, { grz_list: [dataInfo.number.trim()] }));

    useEffect(() => {
        if (dataInfo?.number) {
            loadRWOrders();
        }
    }, []);

    useEffect(() => {
        if (active) {
            dispatch(loadVehicle({ page: 1, limit: 10, serial_egts: uuid }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (
            data
            && active?.characteristics?.serial_egts !== data?.characteristics?.serial_egts
            && active?.characteristics?.serial_yandex !== data?.characteristics?.serial_yandex
            && Object.keys(active).length === 0
            && !active?.findVehicle
        ) {
            dispatch(setActive({
                ...data,
                isClick: true
            }));
        }
    }, []);

    useEffect(() => {
        if (!currentCompany[dataInfo?.organization_id] && dataInfo?.organization_id) {
            dispatch(loadCurrentCompany(dataInfo.organization_id));
        }
    }, [dispatch, currentCompany, dataInfo?.organization_id]);

    // const currentShow = client_full === active?.characteristics?.serial_egts
    //     || client_full === active?.characteristics?.serial_yandex
    //     || null;

    const titleHistory = () => {
        const title = [];
        const categoryNameCurrent = categoryName || config.name;

        title.push(categoryNameCurrent);
        const garage_number = data?.garage_number || null;
        if (garage_number) {
            title.push(`номер ${garage_number}`);
        } else if (uuid) {
            title.push(uuid);
        }
        return title.join(', ');
    };

    const titlesObj = {
        external_id: {
            title: 'Идентификатор'
        },
        number: {
            title: 'Номер',
        },
        garage_number: {
            title: 'Гаражный номер',
            // value: (value) =>
            //     value ? (
            //         <span>{props.statuses[value]}</span>
            //     ) : (
            //         messages.INFO_IS_NOT_FOUND
            //     ),
        },
        // name: {
        //     title: 'Название',
        // },
        brand_name: {
            title: 'Марка',
        },
        model_name: {
            title: 'Модель',
        },
        categoryTS: {
            title: titles.TYPE_OF_VEHICLE,
        },
        engine: {
            title: 'Двигатель запущен'
        },
        fuel: {
            title: 'Уровень топлива в баке'
        },
        organization_id: {
            title: 'Организация',
            value: ({ organization_id }) => organization_id
                ? (<span>{currentCompany[organization_id]?.title}</span>)
                : (messages.INFO_IS_NOT_FOUND),
        },
        elemetry_receiver: {
            title: 'Приемник/организация телеметрии',
        },
        speed: {
            title: 'Последняя скорость',
            value: ({ speed, navigation_at }) => `${speed} км/ч ${navigation_at ? `(на ${fullDateTime(navigation_at)})` : ''}`
        },
    };

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={getImageTpl('img', icon({ fill: '#000' }, in_registry))}
            title={<span>{categoryName || config.name} ({external_id})</span>}
            titleBackgroundColor={categoryColor}
            onClose={onClose}
            buttons={
                <ModalButtons
                    vehicleId={vehicleId}
                    titleHistory={titleHistory}
                />
            }
        >
            <MapScroll
                // height="250px"
                autoHeight
            >
                <Information
                    data={dataInfo}
                    title={titlesObj}
                    requiredKeys={['engine', 'fuel']}
                />

                <Orders
                    data={dataInfo}
                    readOnly={readOnly}
                    reloadList={loadRWOrders}
                />
            </MapScroll>
        </MapPopUp>
    );
};

export default PopUp;
