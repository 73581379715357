import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Divider, Grid } from '@mui/material';

import { getUsers } from 'redux/Admin/actions';
import { adminSelectors } from 'redux/Admin';
import { reactSelectors } from 'modules/React';
import { deleteIncident, loadIncidentById, loadIncidentsStatuses } from 'modules/React/redux/actions';
import { AddModal } from 'modules/React/Dictionaries/Incidents/AddModal/Index';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';

import StatusModal from './StatusModal';

import type { User } from 'types';
import type { IncidentItem } from '../../types';

interface BasicDataProps {
    item: IncidentItem | null;
    onEdited: (item?: IncidentItem) => void;
    onDeleted: () => void;
}

const BasicData = ({ item, onEdited, onDeleted }: BasicDataProps) => {

    const dispatch = useDispatch();

    const loading = useSelector(adminSelectors.loading);
    const [data, setData] = useState<{ user: User[], dispatcher: User[]}>({
        user: [],
        dispatcher: []
    });

    const [currentItem, setCurrentItem] = useState(item);

    useEffect(() => {
        // для обновления данных при переключении табов
        setCurrentItem(item);
    }, [item]);


    const statuses = useStoreFromSelectorListToObject(
        loadIncidentsStatuses,
        reactSelectors.incidentsStatuses
    );
    
    useEffect(() => {
        const userIdList: Array<number> = [...new Set([...(item?.dispatcher || []), ...(item?.user || [])])];

        userIdList.length > 0
            ? dispatch(getUsers({
                page: 1,
                limit: userIdList.length,
                //@ts-ignore
                ids: userIdList
            }, (response: User[]) => {
                setData({
                    user: response.filter((el) => item?.user.find((user: number) => user === el.id)),
                    dispatcher: response.filter((el) => item?.dispatcher.find((user: number) => user === el.id)),
                });
            }))
            : setData({ user: [], dispatcher: [] });

        return setData({ user: [], dispatcher: [] });
    }, [dispatch, item?.user, item?.dispatcher]);

    const [editStatus, setEditStatus] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openMapModal, setOpenMapModal] = useState(false);

    const onDelete = () => {
        dispatch(deleteIncident(
            currentItem?.id,
            () => { onDeleted(); setOpenDeleteModal(false);}
        ));
    };

    const onEditItem = () => {
        // для обновления данных в модалке на карте и в паспорте на карте
        dispatch(loadIncidentById(currentItem?.id, (data: IncidentItem) => {
            setCurrentItem((prev) => ({
                ...prev,
                ...data
            }));
            onEdited(data);
        }));
    };

    const isDisabled = currentItem?.status_id === 3 || currentItem?.status_id === 4;

    const renderUserField = (elem: User[]) => {
        if (loading) {
            return <CircularProgress size={14} />;
        }
        return elem.map((el) => `${el?.first_name || ''} ${el?.last_name || ''}`.trim() || el).join(', ') || messages.NO_DATA;
    };

    return (
        <Grid
            container
            direction="row"
            spacing={1}
        >
            <Grid item xs={6}>
                <>
                    <b>Статус: </b>
                    <span style={{ color: statuses.get(currentItem?.status_id).color }}>
                        {currentItem?.status_text || statuses.get(currentItem?.status_id).name || ''}
                    </span>
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.changeStatusIcon,
                                onClick: () => setEditStatus(true),
                                style: { color: statuses.get(currentItem?.status_id).color },
                                disabled: isDisabled,
                            }
                        ]}
                        noMarginLeft
                        justButton
                    />
                </>
                <Divider style={{ margin: '0.5rem 0' }}/>
                <div className="filter filter__wrap__btn">
                    <FormButtons
                        buttons={isDisabled
                            ? []
                            : [
                                {
                                    ...buttonsTypes.editIcon,
                                    onClick: () => setOpenEditModal(true),
                                    testName: 'edit'
                                },
                                {
                                    ...buttonsTypes.deleteIcon,
                                    onClick: () => setOpenDeleteModal(true),
                                    testName: 'delete'
                                },
                            ]}
                    />
                </div>
                <div>
                    <b>Название: </b>
                    {currentItem?.name || messages.DATA_IS_NOT_FOUND}
                </div>
                <div>
                    <b>Тип: </b>
                    {currentItem?.type_text || messages.NO_DATA}
                </div>
                <div>
                    <b>Дата/время инцидента: </b>
                    {fullDateTimeWithoutSeconds(currentItem?.registered_at) || messages.NO_DATA}
                </div>
                <div>
                    <b>Дата/время решения инцидента план: </b>
                    {fullDateTimeWithoutSeconds(currentItem?.date_plan) || messages.NO_DATA}
                </div>
                <div>
                    <b>Дата/время решения инцидента факт: </b>
                    {fullDateTimeWithoutSeconds(currentItem?.date_fact) || messages.NO_DATA}
                </div>
                <div>
                    <b>Дата/время регистрации: </b>
                    {fullDateTimeWithoutSeconds(currentItem?.created_at) || messages.NO_DATA}
                </div>
                <div>
                    <b>Описание: </b>
                    {currentItem?.description || messages.NO_DATA}
                </div>
                <div>
                    <b>Уровень угрозы: </b>
                    <span style={{ color: currentItem?.threat_level?.color }}>
                        {currentItem?.threat_level?.name || messages.NO_DATA}
                    </span>
                </div>
                <div>
                    <b>Источник: </b>
                    {currentItem?.source || messages.NO_DATA}
                </div>
                <div>
                    <b>Вид ТС: </b>
                    {item?.vehicle_types?.map((vehicle) => vehicle.name).join(' ') || messages.NO_DATA}
                </div>
                <div>
                    <b>Ответственная организация: </b>
                    {currentItem?.organizations?.map(el => el.title).join(', ') || messages.NO_DATA}
                </div>
                <div>
                    <b>Ответственный: </b> 
                    {renderUserField(data.user)}
                </div>
                <div>
                    <b>Диспетчер: </b> 
                    {renderUserField(data.dispatcher)}
                </div>
                <div>
                    <b>Правило закрытия: </b> 
                    {currentItem?.resolve_rules === 1 ? 'Вручную' : 'Автоматом'}
                </div>
            </Grid>

            <Grid item xs={6}>
                <MapDragMarker
                    lat={currentItem?.lat}
                    lon={currentItem?.lon}
                    readOnly
                />

                <FormButtons
                    noMarginLeft
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.defaultPrimary,
                            name: titles.STOP_AREA,
                            onClick: () => setOpenMapModal(true),
                        },
                    ]}
                />

            </Grid>

            {editStatus && (
                <StatusModal
                    isOpen={editStatus}
                    onClose={() => setEditStatus(false)}
                    item={currentItem}
                    reloadList={onEditItem}
                />
            )}

            {openEditModal && (
                <AddModal
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={currentItem}
                    reloadList={onEditItem}
                />
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={onDelete}
                />
            )}
            
            {openMapModal && (
                <Modal
                    isOpen={openMapModal}
                    onClose={() => setOpenMapModal(false)}
                    title={titles.STOP_AREA}
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setOpenMapModal(false),
                                }
                            ]}
                        />
                    }
                >
                    <MapGeoJson
                        visibleGeometry={[
                            currentItem?.geometry,
                            createPointGJ(currentItem?.lat, currentItem?.lon)
                        ]}
                        readOnly={true}
                        isHideValidation
                    />
                </Modal>
            )}
        </Grid>
    );
};

export default BasicData;
