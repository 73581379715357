import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, TextField } from '@mui/material';

import { clearGeocodeAddress, loadGeocodeAddress } from 'redux/Dadata/actions';
import { useDebounce, usePrevious, useValidation } from 'helpers/hooks';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';

import List from './List';

// выбрать адрес из списка
const AddressList = ({
    // значение
    address_text: inputValue = '',
    lat = '',
    lon = '',
    // функция изменения
    onChange = (some) => {},
    // возвращать lat и lon
    isChangeLatLon = true,
    // только чтение
    readOnly = false,
    // обезательно
    required,
    // вернуть геообъект - маркер (false)
    returnGeometry,
    className = 'block',
    label = 'Адрес дислокации'
}) => {

    const dispatch = useDispatch();
    const prevValue = usePrevious(inputValue);
    const validation = useValidation();
    const ref = useRef();

    const { geocodeAddress } = useSelector(({ dadata }) => dadata);

    const [query, setQuery] = useState(inputValue || '');
    const [isModalOpen, setModalOpen] = useState(false);

    const fetchList = (query) => dispatch(loadGeocodeAddress(20, query));
    const fetchListDebounce = useDebounce(fetchList, 1000);
    const clearList = () => dispatch(clearGeocodeAddress());

    useOnClickOutside(ref, () => setModalOpen(false));

    function useOnClickOutside(ref, handler) {
        useEffect(
            () => {
                const listener = (e) => {
                    if (!ref.current || ref.current.contains(e.target)) {
                        return;
                    }
                    handler(e);
                };
                document.addEventListener('mousedown', listener);
                return () => {
                    document.removeEventListener('mousedown', listener);
                };
            },
            [ref, handler]
        );
    }

    const listConvert = geocodeAddress?.data?.reduce((res, item) => {
        const {
            area,
            city_name,
            settlement,
            district,
            street,
            house,
            geo_lat,
            geo_lon,
        } = item;

        const titleArray = [
            area,
            city_name,
            settlement,
            district,
            street,
            house
        ].reduce((res, item) => item ? [...res, item] : res, []);

        if (titleArray.length > 0) {
            return [
                ...res,
                {
                    title: titleArray.join(', ') || '',
                    geo_lat,
                    geo_lon
                }
            ];
        }
        return res;
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;

        setQuery(value);

        if (value.length > 3) {
            fetchListDebounce(value);
        } else {
            fetchListDebounce.clear();
        }

        onChange({
            address_text: value,
            address: {}
        });

        //clearList();
    };

    useEffect(() => {
        if (geocodeAddress?.data?.length > 0) {
            setModalOpen(true);
        }
    }, [geocodeAddress.data]);

    useEffect(() => {
        return () => {
            clearList();
        };
    }, []);

    useEffect(() => {
        if (inputValue !== prevValue) {
            setQuery(inputValue);
        }
    }, [inputValue, prevValue]);

    useEffect(() => {
        validation.deleteKey('address_text');
    }, [query]);

    return (
        <FormControl fullWidth required className={className} variant="outlined">
            <TextField
                required={required}
                label={label}
                size="small"
                value={query}
                variant="outlined"
                name="address_text"
                type="text"
                onChange={handleChange}
                error={validation.isKey('address_text')}
                helperText={validation.get('address_text')}
                disabled={readOnly}
            />
            {isModalOpen
                && <List
                    innerRef={ref}
                    list={listConvert}
                    onClick={(item) => {
                        const { title, index, geo_lat, geo_lon } = item;
                        const returnObject = {
                            address: geocodeAddress?.data
                                ?.find((elem, i) => i == index),
                            address_text: title,
                        };
                        // добавляем lat lon
                        if (isChangeLatLon || (!lat && !lon)) {
                            returnObject.lat = geo_lat;
                            returnObject.lon = geo_lon;
                        }
                        // добавляем геометрию по координатам
                        if (returnGeometry) {
                            returnObject.geometry = createPointGJ(geo_lat, geo_lon);
                        }

                        onChange(returnObject);
                        clearList();
                    }}
                />
            }
        </FormControl>
    );
};

export default AddressList;
