export const getParamsByAccessFilter = (filter = {}, initialValues) => {
    return Object.keys(filter).reduce((res, key) => {
        if (filter[key]) {
            res[key] = initialValues[key];
            if (key === 'complex_id_list') {
                res.selectedComplexes = initialValues.selectedComplexes;
            }
        }
        return res;
    }, {});
};
