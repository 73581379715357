import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { incidentsSelectors } from 'redux/Incidents';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import useReportCategoryTable from 'helpers/hooks/Incidents/useReportCategoryTable';
import {
    createIcon,
    MapPopUp,
} from 'components/MapComponents/MapPopUp';
import Loading from 'components/common/Loading';
import config from 'components/MapComponents/Layers/Incidents/config';

import Info from './Info';

const PopUpModal = ({
    isOpen,
    ids = [],
    onClose,
}) => {

    const reportCategoryFilter = useSelector(incidentsSelectors.reportCategoryFilter);

    const tableProvider = useReportCategoryTable();

    // получаем данные по ids
    useEffect(() => {
        if (ids && ids.length > 0) {
            tableProvider.load({ ids });
        }
    }, [ids]);

    useEffect(() => () => {
        tableProvider.clear();
    }, []);

    return (
        <MapPopUp
            isOpen={isOpen}
            title={
                <>
                    <span>{fullDateTimeWithoutSeconds(reportCategoryFilter.start_date)}</span>
                    <span> - </span>
                    <span>{fullDateTimeWithoutSeconds(reportCategoryFilter.end_date)}</span>
                </>
            }
            titleBackgroundColor="rgb(218, 166, 85)"
            icon={createIcon(config.layerIcon)}
            onClose={onClose}
            medium
        >
            {tableProvider.loading ? (
                <Loading className="center padding" />
            ) : (
                <Info data={tableProvider.data}/>
            )}

        </MapPopUp>
    );
};

export default PopUpModal;
