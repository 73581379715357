import React, { forwardRef, useEffect, useRef, useState } from 'react';
import noimage from 'img/noimage.png';
import styles from './image.module.scss';
import messages from 'helpers/constants/messages';

interface ImageProps {
    src?: string;
    alt?: string;
    onClick?: () => void;
    className?: string;
    style?: {
        [key: string]: any
    };
}
const Image = forwardRef<HTMLImageElement, ImageProps>(({ src, alt, onClick, className, style = {}, ...props }, ref) => {
    const [img, setImg] = useState(src);
    const [width, setWidth] = useState('auto');

    useEffect(() => {
        if (src) {
            setImg(src);
        } else {
            setImg(noimage);
            setWidth('84px');
        }
    }, [src]);


    return (<img
        {...props}
        ref={ref}
        src={img}
        title={src ? alt : messages.NO_IMAGE}
        alt={src ? alt : messages.NO_IMAGE}
        onError={() => {
            setImg(noimage);
            setWidth('84px');
        }}
        onClick={onClick}
        className={className || styles.picture}
        style={{ ...style, maxWidth: width, maxHeight: width }}
    />);
});

export default Image;