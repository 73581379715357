const createLineGJ = (coords, properties = {}) => {
    if (coords?.length > 0) {
        return (
            {
                type: 'Feature',
                properties,
                geometry: {
                    type: 'LineString',
                    coordinates: coords,
                },
            }
        );
    }
    return null;
};

export const createLineGJLatLon = (coordsLatLon, properties = {}) => {
    return createLineGJ(
        coordsLatLon.reduce((r, { lat, lon }) => {
            const latFloat = parseFloat(lat);
            const lonFloat = parseFloat(lon);

            if (latFloat && lonFloat) {
                r.push([
                    lonFloat,
                    latFloat
                ]);
            }
            return r;
        }, []),
        properties
    );
};

export default createLineGJ;