import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import * as actions from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { useDebounce, usePrevious, useStoreFromSelectorListToObject } from 'helpers/hooks';
import mapHelper from 'helpers/mapHelper';
import {
    ContextMenu,
    ContextMenuItem,
    GeoJsonNew,
    Marker,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';
import { MapPopUpListener }  from 'components/MapComponents/MapPopUp';

import { createIconMarker, getColor, getFilter } from './helper';
import PopUpDtp from './PopUp';
import Legend from './Legend';
import CollectorForms from './CollectorForms';
import config from './config.js';


const Layer = (props) => {
    const { map, readOnly = false, isTrafficBlocking = false, filter: filterProp = null } = props;
    const dispatch = useDispatch();

    const typesAll = useStoreFromSelectorListToObject(actions.loadDtpTypesAll, incidentsSelectors.typesAll);
    const polygon = useSelector(incidentsSelectors.polygon);
    const active = useSelector(incidentsSelectors.active);
    const saved = useSelector(incidentsSelectors.saved);
    const filtersSelector = useSelector(incidentsSelectors.filters);
    const filters = filterProp || filtersSelector;

    // связи
    const [dataLinks, setDataLinks] = useState([]);

    // const polygonLoading = useSelector(incidentsSelectors.polygonLoading);
    // const polygonVisible = useSelector(incidentsSelectors.polygonVisible);

    const prevFilters = usePrevious(filters);

    const filter = getFilters(filters, getFilter);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadDtpPolygon(
            polygon,
            filter
        ));
    };
    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            lat,
            lon: lng,
        }));
    };

    useEffect(() => () => {
        dispatch(actions.clearDtpPolygon());
        setDataLinks([]);
    }, []);

    const fetchLinks = () => {
        dispatch(actions.loadDtpLinkNew(filter, setDataLinks));
    };

    useEffect(() => {
        if (
            readOnly === false
            && (
                !filters.start_date
                || !filters.end_date
            )
        ) {
            dispatch(actions.loadDtpDates());
        }

        if (readOnly) fetchPolygon();
    }, [readOnly]);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
            fetchLinks();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.resetDtpActive());
            dispatch(actions.clearDtpPolygon());

            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [map, filters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            dispatch(actions.setEditForm());
            fetchPolygon();
            fetchLinks();
        }
    }, [saved]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (
            Object.keys(active).length > 0
            && !active?.isNoMove
        ) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 16);
            }
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                {item.status !== 3 ? (
                    <ContextMenuItem
                        {...rcmProp}
                        value="Редактировать"
                        onClick={() => {
                            map.fire('context_menu_close');
                            dispatch(actions.setEditForm(item));
                        }}
                    />
                ) : null}
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    const createItem = (item) => {
        const { id, lat, lon } = item;

        const currentColor = getColor(item, typesAll.get);
        const componentProps = {
            ...props,
            key: `${config.slug}_${id}`,
            // для кластера
            attribution: {
                slug: config.slug,
                color: currentColor
            },
            onClick: (latlng) => {
                map.setView(latlng);
                dispatch(actions.setDtpActive({
                    ...item,
                    isNoMove: true,
                }));
            },
        };

        return (
            <Marker
                {...componentProps}
                latlng={[lat, lon]}
                icon={createIconMarker(currentColor)}
            >
                {readOnly === false
                    && (
                        <ContextMenu>
                            <RenderContextMenu item={item} />
                        </ContextMenu>
                    )
                }
            </Marker>
        );
    };

    const polygonData = useMemo(() => polygon
        ?.map((item) => createItem(item))
    , [polygon]);

    return (
        <>
            {polygonData}

            {/* связи */}
            {dataLinks.length > 0 && (
                <GeoJsonNew
                    {...props}
                    data={dataLinks}
                />
            )}

            <MapLegends layer="incidents">
                <Legend/>
            </MapLegends>

            {/* формы */}
            {readOnly === false && (
                <CollectorForms />
            )}

            {/* popup */}
            <MapPopUpListener
                activeSelector={incidentsSelectors.active}
                polygonSelector={incidentsSelectors.polygon}
            >
                <PopUpDtp
                    readOnly={readOnly}
                    sTrafficBlocking={isTrafficBlocking}
                    typesAll={typesAll}
                    onClose={() => {
                        dispatch(actions.resetDtpActive());
                    }}
                />
            </MapPopUpListener>
        </>
    );
};

export default Layer;
