import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';

import type { TelemetryReceiver } from './types';

export interface ItemProps {
    item: TelemetryReceiver
    actions: { [key: string]: any }
    reloadList: (isDelete?: boolean) => void
    types: { [key: string]: string }
    page: string
}

const Item = ({ item, actions, reloadList, types, page }: ItemProps) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const onDelete = () => {
        dispatch(actions.deleteTelemetryReceiver(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item?.name || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.port || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.ip || messages.NO_DATA}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    reloadList={reloadList}
                    actions={actions}
                    item={item}
                    types={types}
                    page={page}
                />
            )}
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}
                    onSuccess={onDelete}
                    message={messages.CONFIRM_DELETE}
                    onlyClose={false}
                    loadingButton={false}
                />
            )}
        </>

    );
};

export default Item;
