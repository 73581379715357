import titles from 'helpers/constants/titles';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import { getTypeText } from 'modules/DigitalTwin/utils/helpers';

const Item = (props) => {
    const {
        name,
        created_at,
        type,
    } = props;

    return (
        <>
            <div className="description">{name}</div>
            {!!created_at && (
                <div>
                    <strong>{titles.DATE_TIME_CREATED}:</strong>&nbsp;
                    {getFullDateTimeWithTextMonth(created_at)}
                </div>
            )}

            {!!type && (
                <div>
                    <strong>{titles.TYPE}:</strong>&nbsp;
                    {getTypeText(type)}
                </div>
            )}
        </>
    );
};

export default Item;
