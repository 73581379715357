import { config } from 'config';

const apiMetroUrl = () => config.get('urls').apiMetroUrl;

const apiUrls = {
    getStationStatuses: () => `${apiMetroUrl()}/station/statuses`,

    loadStationList: () => `${apiMetroUrl()}/station`,

    loadVestibulePolygon: () => `${apiMetroUrl()}/vestibule/polygon`,

    loadVestibuleList: () => `${apiMetroUrl()}/vestibule`,

    getVestibuleStatuses: () => `${apiMetroUrl()}/vestibule/statuses`,

    loadVestibuleLoad: (id) => `${apiMetroUrl()}/vestibule/${id}/load`,

    getVestibuleTraffic: (id) => `${apiMetroUrl()}/vestibule/${id}/traffic`,
};

export default apiUrls;
