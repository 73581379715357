import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Grid, Typography } from '@mui/material';

import { deleteRoadWorkOrder, loadOrderStatuses } from 'redux/RoadWorks/actions';
import FormButtonsComponent from 'components/common/FormButtons';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Attachments from 'components/common/Upload/Attachments';
import ConfirmDelete from 'components/common/ConfirmModal';
import InfoBlock from 'components/common/InfoBlock';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import useOrganizationsByOrderId from 'helpers/hooks/Transport/useOrganizationsByOrderId';
import { getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

import AddEditModal from '../../AddEditModal';
import EditOrderStatusModal from '../../EditOrderStatus';
import RoadWorkSites from '../../SitesArea/RoadWorkSites';
import OrderOrganizations from '../../OrderOrganizations';

const BasicData = ({ currentOrder, readOnly, reloadList }) => {
    const dispatch = useDispatch();

    const statuses = useStoreProp(loadOrderStatuses, 'roadworks', 'orderStatuses');
    const itemStatus = statuses.find((el) => Number(el.status_id) === Number(currentOrder?.status)) || {};

    const [editMode, setEditMode] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const orderOrganizations = useOrganizationsByOrderId(currentOrder.id);

    const handleReload = (isDelete) => {
        reloadList(isDelete);
        orderOrganizations.reload();
    };

    const handleDelete = () => {
        dispatch(deleteRoadWorkOrder(currentOrder?.id, () => {
            handleReload(true);
            setOpenDeleteModal(false);
        }));
    };

    const currentButtons = [
        {
            ...buttonsTypes.editIcon,
            size: 'small',
            onClick: () => setEditMode(true),
        },
        {
            ...buttonsTypes.deleteIcon,
            onClick: () => setOpenDeleteModal(true),
        },
    ];

    return (
        <>
            <div className="tab__container">
                <Typography variant="body2">
                    {currentOrder && (
                        <>
                            <strong>Статус: </strong>
                            <span style={{ color: itemStatus.color }}>
                                {currentOrder?.status ? currentOrder?.status_text : messages.INFO_IS_NOT_FOUND}
                            </span>
                            {!readOnly && (
                                <FormButtonsComponent
                                    buttons={[
                                        {
                                            ...buttonsTypes.changeStatusIcon,
                                            onClick: () => setEditStatus(true),
                                        },
                                    ]}
                                    noMarginLeft
                                    justButton
                                />
                            )}
                        </>
                    )}
                </Typography>

                <Divider style={{ margin: '10px 0' }} />

                <div className="filter filter__wrap__btn">
                    <FormButtons buttons={currentButtons} />
                </div>

                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body2">
                            {currentOrder?.name && (
                                <span>
                                    <b>{titles.NAME}: </b>
                                    {currentOrder?.name}
                                </span>
                            )}

                            &nbsp;

                            {currentOrder?.id && (
                                <span>
                                    <b>ID: </b>
                                    {currentOrder?.id}
                                </span>
                            )}
                        </Typography>

                        <Typography variant="body2">
                            {currentOrder?.description && (
                                <span>
                                    <b>Описание: </b>
                                    {currentOrder?.description}
                                </span>
                            )}
                        </Typography>

                        <InfoBlock label="Даты" style={{ margin: '1rem 0' }}>
                            <Typography variant="body2">
                                <span>
                                    <b>{titles.DATE_OF_REGISTRATION}: </b>
                                    {getHumanDate(currentOrder?.register_at) || messages.NO_DATA}
                                </span>

                                <br/>

                                <span>
                                    <b>План. дата начала выполнения: </b>
                                    {getHumanDate(currentOrder?.start_date_plan) || messages.NO_DATA}
                                </span>

                                <br/>

                                <span>
                                    <b>План. дата окончания выполнения: </b>
                                    {getHumanDate(currentOrder?.end_date_plan) || messages.NO_DATA}
                                </span>

                                <br/>

                                <span>
                                    <b>Факт. дата окончания выполнения: </b>
                                    {getHumanDate(currentOrder?.end_at) || messages.NO_DATA}
                                </span>
                            </Typography>
                        </InfoBlock>
                    </Grid>
                </Grid>

                <OrderOrganizations list={orderOrganizations} loading={orderOrganizations.loading}/>

                <Divider style={{ margin: '10px 0' }} />

                {currentOrder?.end_at && currentOrder.status === 4 && (
                    <>
                        <Typography variant="body2">
                            <span>
                                <b>Фактическая дата выполнения: </b>
                                {getHumanDate(currentOrder?.end_at)}
                            </span>
                        </Typography>

                        <Divider style={{ margin: '10px 0' }} />
                    </>
                )}

                <div>
                    <RoadWorkSites list={currentOrder?.works} />
                </div>

                <Divider style={{ margin: '10px 0' }} />

                <Attachments files={currentOrder?.files || []} readOnly={true} />
            </div>

            {editStatus && (
                <EditOrderStatusModal
                    data={currentOrder}
                    onClose={() => setEditStatus(false)}
                    isOpen={editStatus}
                    reloadList={handleReload}
                />
            )}

            {editMode && (
                <AddEditModal
                    data={currentOrder}
                    onClose={() => setEditMode(false)}
                    isOpen={editMode}
                    reloadList={handleReload}
                />
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default BasicData;
