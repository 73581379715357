import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { List, Typography } from '@mui/material';

import { loadRecognitionCameraDirTypes, loadTsCategories } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { loadPddViolations } from 'redux/DorisControl/actions';
import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import { renderArticleName } from 'components/common/DorisControl/PddViolation/renderArticleName';

const RenderInfo = ({ item = {} }) => {
    const dispatch = useDispatch();

    const dirTypes = useStoreFromSelector(loadRecognitionCameraDirTypes, dorisControlSelectors.recognitionCameraDirTypes);
    const tsCategories = useStoreFromSelector(loadTsCategories, dorisControlSelectors.tsCategories);

    const [koap, setKoap] = useState([]);

    useEffect(() => {
        if (item?.koap && item?.koap?.length) {
            dispatch(loadPddViolations(
                1,
                item?.koap?.length,
                { ids: item?.koap },
                true,
                (koap) => setKoap(koap)
            ));
        }
    }, [item?.koap]);

    // [{id: 0, degree: 0, direction: "пропроп"}]
    if (item.hasOwnProperty('degree') && item.hasOwnProperty('direction')) {
        return (
            <List>
                <Typography variant="body2">
                    Направление фиксации камеры: {dirTypes?.[item?.id]}
                </Typography>
                <Typography variant="body2">
                    Градус отклонения камеры от N: {item?.degree}&deg;
                </Typography>
                <Typography variant="body2">
                    Направление фиксации камеры: {item?.direction}
                </Typography>
            </List>
        );
    } else if (item.hasOwnProperty('speed') && item.hasOwnProperty('direction')) {
        return (
            <List>
                <Typography variant="body2">
                    Номер полосы: {item?.id || messages.NO_DATA}
                </Typography>
                <Typography variant="body2">
                    Направление движения ТС: {item?.direction || messages.NO_DATA}
                </Typography>

                {item?.speed?.length > 0 && (
                    <>
                        <Typography variant="body2" style={{ marginTop: '.5rem' }}>
                            Фиксация скорости
                        </Typography>

                        {item?.speed?.map((el, index) => (
                            <div key={index}>
                                <Typography variant="body2">
                                    Вид транспорта: {tsCategories?.[el.type] || messages.NO_DATA}
                                </Typography>
                                <Typography variant="body2">
                                    Ограничение скорости: {el.speed || messages.NO_DATA}
                                </Typography>
                            </div>
                        ))}
                    </>
                )}

                {koap?.length && (
                    <>
                        <Typography variant="body2" style={{ marginTop: '.5rem' }}>
                            Список КОАП статей:
                        </Typography>
                        {koap?.map((el, index) => (
                            <div key={index}>
                                {renderArticleName(el, '- ')}
                            </div>
                        ))}
                    </>
                )}
            </List>
        );
    }

    return null;
};

export default RenderInfo;
