import { useMemo } from 'react';

import {
    FeatureGroup,
    Map,
    MapControl,
} from 'components/MapComponents/leaflet';
import { config } from 'config';

import LoadPolygon from './LoadPolygon';
import CreateMarker from './CreateMarker';

const MapForm = ({
    value,
    mainDtp,
    onChange,
    multiply,
}) => {

    const selectedDtp = multiply
        ? value
        : value && Object.keys(value || {}).length > 0
            ? [value]
            : [];

    const renderMainDtp = useMemo(() => {
        if (mainDtp) {
            return (
                <CreateMarker
                    item={mainDtp}
                    color="#000000"
                />
            );
        }
    }, [mainDtp]);

    // выбранные маркеры
    const selectedDtpMarkers = useMemo(() => {
        const color = '#4b9c4b';
        return selectedDtp?.map((item) => (
            <CreateMarker
                key={item.id}
                item={item}
                color={color}
                onClick={() => {
                    onChange(
                        multiply
                            ? selectedDtp.filter(el => el.id !== item.id)
                            : {}
                    );
                }}
            />
        ));
    }, [selectedDtp, onChange, multiply]);

    const centerMain = useMemo(() => {
        return mainDtp ? [mainDtp.lat, mainDtp.lon] : null;
    }, [mainDtp]);

    const centerConfig = config.get('mapCenter');

    return (
        <div
            style={{
                minWidth: '60%',
                minHeight: '400px',
                height: '100%',
            }}
        >
            <Map
                center={centerMain || centerConfig}
                zoom={17}
            >
                <MapControl>
                    <FeatureGroup>

                        {/* главное дтп */}
                        {renderMainDtp}

                        {/* подгрузка дтп из полигона */}
                        <LoadPolygon
                            selected={selectedDtp}
                            multiply={multiply}
                            onChange={onChange}
                            mainDtp={mainDtp || {}}
                        />

                        {/* выбранные дтп */}
                        {selectedDtpMarkers}

                    </FeatureGroup>
                </MapControl>
            </Map>
        </div>
    );
};

export default MapForm;
