import { useState } from 'react';
import { Collapse } from '@mui/material';

import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import prepareCoords from 'helpers/prepareCoords';
import messages from 'helpers/constants/messages';
import { getFullTime, getHumanDate } from 'helpers/date.config';

const Item = ({ item, index }) => {
    const [isInfo, setIsInfo] = useState(false);

    const showInfo = () => setIsInfo(!isInfo);

    return (
        <>
            <LSContentItem onClick={showInfo}>
                <LSContentColumn>{index + 1}</LSContentColumn>
                <LSContentColumn>{getHumanDate(item.created_at) || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{item.route_num || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{item.grz || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{item.speed || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{item.max_speed || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{getFullTime(item.created_at) || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>{item.work_order_id || messages.NO_VALUE}</LSContentColumn>
                <LSContentColumn>
                    {prepareCoords(item?.lat, 2)}/{prepareCoords(item?.lon, 2)}
                </LSContentColumn>
                
                <LSContentColumn isActions>
                    <ActionMore
                        isOpen={isInfo}
                        // onClick={showInfo}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isInfo &&  (
                <Collapse in={isInfo} timeout="auto" unmountOnExit className="info">
                    <div><b>№ графика: </b>{item.route || messages.NO_DATA}</div>
                    <div><b>Название маршрута: </b>{item.route_name || messages.NO_DATA}</div>
                    <div><b>Время окончания: </b>{getFullTime(item.end_at) || messages.NO_DATA}</div>
                    <div><b>Адрес точки нарушения: </b>{item.address || messages.NO_DATA}</div>
                </Collapse>
            )}
        </>
    );
};

export default Item;
