import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import * as actions from 'redux/Parking/actions';
import { parkingSelectors } from 'redux/Parking';
import { usePrevious } from 'helpers/hooks';
import useStoreProp from 'helpers/hooks/useStoreProp';
import messages from 'helpers/constants/messages';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import Loading from 'components/common/Loading';

import { getColor, getFilter } from '../helper';
import config from '../config';

import Item from './Item';
import Form from './Form';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const [parameters, setParameters] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const sidebar = useSelector(parkingSelectors.sidebar);
    const loadingSidebar = useSelector(parkingSelectors.sidebarLoading);
    const filters = useSelector(parkingSelectors.filters);
    const active = useSelector(parkingSelectors.active);
    const saved = useSelector(parkingSelectors.saved);
    
    const prevFilters = usePrevious(filters);
    
    const tariffs = useStoreProp(actions.loadTariffList, 'parking', 'tariffList');
    const tariffCategories = useStoreProp(actions.loadTariffCategories, 'parking', 'tariffCategories');
    const tariffDays = useStoreProp(actions.loadTariffDays, 'parking', 'tariffDays');

    const activeId = active?.id ?? 0;

    const fetchList = () => {
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadSidebar(
            parameters.page,
            parameters.limit,
            filter
        ));
    };

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = sidebar?.meta || {};
        if (
            values.top > 0.95
            && loadingSidebar === false
            && parameters.page < last_page
        ) {
            setParameters(old => ({
                ...old,
                page: old.page + 1,
            }));
        }
    };

    useEffect(() => () => {
        dispatch(actions.clearSidebar());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        } else {
            fetchList();
        }
    }, [parameters, filters]);

    useEffect(() => {
        if (saved) {
            dispatch(actions.clearSidebar());
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        }
    }, [saved]);

    const resetParameters = () => {
        setParameters(old => ({
            ...old,
            page: 1,
        }));
    };

    const renderList = (dataList) => (
        dataList.map((item) => {
            const color = item?.status_color || getColor();
            return (
                <SidebarItem
                    key={item.id}
                    active={activeId === item.id}
                    icon={`<i class="${config.layerIcon}" style="color: ${color}"/>`}
                    // buttons={{
                    //     edit: {
                    //         title: buttons.EDIT,
                    //         icon: <i className="fas fa-pen" />,
                    //         onClick: () => {
                    //             // редактирование
                    //             dispatch(actions.setEditForm(item));
                    //         }
                    //     },
                    //     delete: {
                    //         title: buttons.DELETE,
                    //         icon: <i className="fas fa-trash-alt" />,
                    //         onClick: () => {
                    //             // удаление
                    //             dispatch(actions.setDeleteForm(item.id));
                    //         }
                    //     }
                    // }}
                    onClick={() => {
                        dispatch(actions.setActive(item));
                    }}
                >
                    <Item
                        item={item}
                        // tariff={tariffs.find(el => el.id === item.tariff_id)}
                        tariffCategories={tariffCategories}
                        tariffDays={tariffDays}
                    />
                </SidebarItem>
            );
        })
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={resetParameters}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange, onDelete }) => <Form data={data} onChange={onChange} onDelete={onDelete} />}
                layer={config.slug}
            />

            {loadingSidebar && sidebar?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={sidebar}
                        />
                        {sidebar?.data?.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(sidebar?.data || [])}
                    </div>
                    {loadingSidebar && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
