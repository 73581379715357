import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    ListItem,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import {
    createConnectionLine,
    editConnectionLine,
    loadVideoCamerasDictionaryConnectionLine
} from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import FieldsModal from 'components/common/Location/FieldsModal';
import TitleValidator from 'components/common/Location/TitleValidator';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import UploadImageField from 'components/common/Upload/UploadImageField';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import buttons from 'helpers/constants/buttons';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';

import type { ConnectionLineItem } from 'types/RoadNetwork';

interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    reloadList: () => void
    isNew?: boolean
    item?: ConnectionLineItem
}

const ModalForm = ({ isOpen, isNew, onClose, reloadList, item }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(roadNetworksSelectors.loadingButton);
    const types = useStoreProp(
        loadVideoCamerasDictionaryConnectionLine,
        'roadNetworks',
        'videoCamerasDictionaryConnectionLine'
    );

    const initialState = {
        name: item?.name || '',
        type_id: item?.type_id || null,
        length: item?.length || null,
        geometry: item?.geometry || null,
        additional_info: item?.additional_info || '',
        organization_id: item?.organization_id || null,
        organization_contacts: item?.organization_id
            ? { ...item?.organization_contacts, id: item?.organization_id }
            : null,
        supply_document: item?.supply_document || '',
        smr_guarantee: item?.smr_guarantee || null,
        maintenance_agreement: item?.maintenance_agreement || '',
        maintenance_agreement_date: item?.maintenance_agreement_date || null,
        custom_icon: item?.custom_icon || ''
    };

    const [data, setData] = useState(initialState);

    const handleChange = (name: string, value: any) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSave = () => {
        const prepareData = removeEmptyFields({
            ...data,
            organization_id: data.organization_contacts?.id || null,
            smr_guarantee: dateWithDashYYYYMMDD(data.smr_guarantee),
            maintenance_agreement_date: dateWithDashYYYYMMDD(data.maintenance_agreement_date)
        }, false);

        const callback = () => { onClose(); reloadList(); };

        isNew
            ? dispatch(createConnectionLine(prepareData, callback))
            : dispatch(editConnectionLine(item?.id, prepareData, callback));
    };

    const isDisabled = !data.name
        || !data.type_id
        || !data.geometry;

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding={true}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            loading: loadingButton,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <form className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name}
                        name="name"
                        onChange={(e) => handleChange('name', e.target.value)}
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        required={true}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('type_id')}
                    helperText={validation.get('type_id')}
                >
                    <FormControl
                        size="small"
                        variant="outlined"
                        required
                    >
                        <InputLabel>{titles.TYPE}</InputLabel>
                        <Select
                            value={data.type_id || ''}
                            name="type_id"
                            onChange={(e) => handleChange('type_id', e.target.value)}
                            label={titles.TYPE}
                        >
                            {Object.keys(types).map((key) => (
                                <MenuItem key={key} value={key}>{types[key]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>

                <TitleValidator
                    fields={data}
                    validationFields={['geometry']}
                >
                    Укажите линию связи *:
                </TitleValidator>

                <FieldsModal
                    title="Выбрать область на карте"
                    fields={{ geometry: data.geometry || {} }}
                    buttonText={buttons.SELECT_ON_MAP}
                    onChange={({ geometry = null }) => handleChange('geometry', geometry)}
                    fullWidth={false}
                    iconButton={null}
                >
                    <MapGeoJson
                        polyline={true}
                        circle={false}
                        polygon={false}
                        marker={false}
                        isHideValidation
                    />
                </FieldsModal>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('length')}
                    helperText={validation.get('length')}
                >
                    <TextField
                        value={data.length || ''}
                        name="length"
                        onChange={(e) => handleChange('length', e.target.value)}
                        label={'Протяженность линии'}
                        variant="outlined"
                        size="small"
                        type="number"
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('additional_info')}
                    helperText={validation.get('additional_info')}
                >
                    <TextField
                        value={data.additional_info || ''}
                        name="additional_info"
                        onChange={(e) => handleChange('additional_info', e.target.value)}
                        label={'Доп. информация'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <SelectCompany
                    className="block"
                    label={'Собственник оборудования'}
                    selected={data.organization_contacts}
                    onChange={(value) => handleChange('organization_contacts', value)}
                    selectDeletedCompanies
                    error={validation.isKey('organization_id')}
                    helperText={validation.get('organization_id')}
                />

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('supply_document')}
                    helperText={validation.get('supply_document')}
                >
                    <TextField
                        value={data.supply_document || ''}
                        name="supply_document"
                        onChange={(e) => handleChange('supply_document', e.target.value)}
                        label={'Договор поставки'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <SingleKeyboardDateTimePicker
                    className="block"
                    label={'Срок действия гарантии на СМР'}
                    onChange={(date: Date | null) => handleChange('smr_guarantee', date)}
                    value={data.smr_guarantee}
                    dateOnly
                    error={validation.isKey('smr_guarantee')}
                    helperText={validation.get('smr_guarantee')}
                />

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('maintenance_agreement')}
                    helperText={validation.get('maintenance_agreement')}
                >
                    <TextField
                        value={data.maintenance_agreement || ''}
                        name="maintenance_agreement"
                        onChange={(e) => handleChange('maintenance_agreement', e.target.value)}
                        label={'Договор на ТО'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <SingleKeyboardDateTimePicker
                    className="block"
                    label={'Срок действия договора на ТО'}
                    onChange={(date: Date | null) => handleChange('maintenance_agreement_date', date)}
                    value={data.maintenance_agreement_date}
                    dateOnly
                    error={validation.isKey('maintenance_agreement_date')}
                    helperText={validation.get('maintenance_agreement_date')}
                />

                <ListItem divider>
                    <UploadImageField
                        value={data.custom_icon}
                        name="custom_icon"
                        onChange={handleChange}
                        errorText={validation.get('custom_icon')}
                    />
                </ListItem>
            </form>
        </Modal>
    );
};

export default ModalForm;
