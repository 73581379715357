import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Tooltip } from '@mui/material';

import { videoCamerasSelectors } from 'redux/VideoCameras';
import { loadStatusesVideoCamera, loadVideoCamerasList } from 'redux/VideoCameras/actions';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import Switcher from 'components/common/Switcher';
import messages from 'helpers/constants/messages';

import ModalForm from './ModalForm';
import Filter from './Filter';
import Item from './Item';
import styles from './styles.module.scss';

import type { Meta } from 'types/pagination';
import type { CityCamera, Statuses, } from './types';


const CityCameras = () => {
    const dispatch = useDispatch();

    const statuses: Statuses[] = useStoreProp(
        loadStatusesVideoCamera,
        'videoCameras',
        'videoCameraStatuses'
    );

    const list: CityCamera[] = useSelector(videoCamerasSelectors.videoCameraListData);
    const meta: Meta = useSelector(videoCamerasSelectors.videoCameraListMeta);
    const loading: boolean = useSelector(videoCamerasSelectors.loadingVideoCameraList);

    const [isOpenAddModal, setIsOpenAddModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [layoutGrid, setLayoutGrid] = useState(false); // false - list, true - video grid

    const reloadList = (isDelete?: boolean) => {
        isDelete && list?.length === 1 && meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadVideoCamerasList(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadVideoCamerasList(params.page, limit, params.data));
    },[dispatch, limit, params]);

    const openInNewWindow = (url: string | URL, item: CityCamera | {}) => {
        // const playerProps = { item_id: item.id, isScreen: true };
        // // @ts-ignore
        // const query = new URLSearchParams(playerProps).toString();
        // window.open(`${url}?${query}`, '_blank')?.focus();
        window.open(url, '_blank')?.focus();
    };

    const renderContent = () => (
        <>
            {list?.length > 0
                ? layoutGrid
                    ? <Grid container spacing={1.2} marginTop={1}>
                        {list.map((item) => (
                            <Grid item xs={3} key={item.id} style={{ minHeight: 220 }}>
                                <div
                                    // onClick={() => openInNewWindow('/video-player', item)}
                                    className={styles.frameTitle}
                                >
                                    <Tooltip title={item.name} placement="top">
                                        <strong> {item.name}</strong>
                                    </Tooltip>
                                </div>
                                <div
                                    // onClick={() => openInNewWindow('/video-player', item)}
                                    className={styles.frameTitle}
                                >
                                    <Tooltip title={item.address_text} placement="bottom">
                                        <span>{item.address_text}</span>
                                    </Tooltip>
                                </div>

                                {(item.embed || item.link)
                                    ? <div className={styles.frameWrap}>
                                        <div onClick={() => openInNewWindow(`${item.embed || item.link}`, {})}/>
                                        <iframe
                                            key={item.id}
                                            id="item.id"
                                            // @ts-ignore
                                            src={`${item.embed || item.link}`}
                                            title="camera"
                                            name="output_frame"
                                            width="100%"
                                            height="180px"
                                        />

                                        {/* <VideoPlayer
                                        item={item}
                                        isLayoutGrid={true}
                                        // link={item?.stream_url_full}
                                        // title={item?.name}
                                        // id={item.id}
                                    /> */}
                                    </div>
                                    : <div className={styles.frameWrap}>{messages.NO_DATA}</div>
                                }
                            </Grid>
                        )
                        )}
                    </Grid>
                    : (<LSContainer
                        headers={[
                            { title: '', width: '50px' },
                            { title: titles.NAME, width: '29%' },
                            { title: titles.ADDRESS, width: '61%' },
                            { title: titles.ACTIONS, align: 'right', isActions: true }
                        ]}
                    >
                        {list.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                statuses={statuses}
                                reloadList={reloadList}
                            />)
                        )}
                    </LSContainer>)
                : !loading && <NoData/>
            }
        </>
    );

    return (
        <>
            <PageLayout
                header="Камеры видеонаблюдения"
                filters={<Filter statuses={statuses} setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setIsOpenAddModal(true),
                                    },
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),

                    total: meta.total
                }}
                actionPanel={
                    <Switcher
                        value={layoutGrid}
                        onChange={setLayoutGrid}
                        title1="Список"
                        title2="Мозаика"
                        icon1={<i className="fas fa-list" />}
                        icon2={<i className="fas fa-th" />}
                    />
                }
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {isOpenAddModal && (
                <ModalForm
                    statuses={statuses}
                    isNew={true}
                    isOpen={isOpenAddModal}
                    onClose={() => setIsOpenAddModal(false)}
                    reloadList={() => setParams({ ...params, page: 1 })}
                />
            )}
        </>
    );
};

export default CityCameras;
