import React, { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { loadTriggerStatuses } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';
import SelectScenarios from 'modules/React/utils/components/SelectScenarios';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';

import type { FilterFields } from './types';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; data: {} }>>;
}

const Filter = ({ setParams }: FilterProps) => {
    const initialState = {
        name: '',
        status: null,
        type_id: null,
        response_scenario_id: null,
    };

    const statuses = useStoreFromSelectorListToObject(
        loadTriggerStatuses,
        reactSelectors.triggerStatuses
    );

    const [filterValues, setFilterValues] = useState<FilterFields>(initialState);

    const handleChange = (name: keyof FilterFields, value: any) => {
        setFilterValues(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (filters = filterValues) => {
        const prepareData = removeEmptyFields({
            ...filters,
            status: filters.status?.id,
            type_id: filters.type_id?.id,
            response_scenario_id: filters.response_scenario_id?.id,
        });
        setParams({ page: 1, data: prepareData });
    };

    const resetFilter = (needRefresh: boolean) => {
        setFilterValues(initialState);

        if (needRefresh) {
            onSearch(initialState);
        }
    };

    const updateFilter = (filters = {}) => {
        const newFilter = { ...filterValues, ...filters };
        setFilterValues(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={filterValues}
            onSearch={onSearch}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filterValues.name}
                    label={titles.NAME}
                    onChange={(e) => handleChange('name', e.target.value)}
                />
                <CommonAutocomplete
                    label={titles.STATUS}
                    options={statuses.list as any[]}
                    selected={filterValues.status}
                    onChange={(value) => handleChange('status', value)}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderLabel={(option) => option?.name || ''}
                    showAsSelect
                />
                <SelectIncidentTypes
                    selected={filterValues.type_id}
                    onChange={(value) => handleChange('type_id', value)}
                />
                <SelectScenarios
                    selected={filterValues.response_scenario_id}
                    onChange={(value) => handleChange('response_scenario_id', value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
