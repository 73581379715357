import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { incidentsSelectors } from 'redux/Incidents';
import { loadDtpHistory, loadDtpLoadTypes, loadDtpStatuses, loadedDtpHistory } from 'redux/Incidents/actions';
import { getUsers } from 'redux/Admin/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { LSContainer } from 'components/common/List';
import { useStoreProp } from 'helpers/hooks';
import Loading from 'components/common/Loading';

import TabItem from './TabItem';

const TabHistory = ({ data }) => {
    const dispatch = useDispatch();

    const statuses = useStoreProp(loadDtpStatuses, 'incidents', 'statuses');
    const loadTypes = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');

    const dtpHistory = useSelector(incidentsSelectors.dtpHistory);
    const loadingDtpHistory = useSelector(incidentsSelectors.loadingDtpHistory);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        const userIds = [ ...new Set(dtpHistory.map(item => item.user_id))];

        userIds.length > 0 && dispatch(getUsers({ ids: userIds }, setUsers));
        return setUsers([]);
    }, [dispatch, dtpHistory]);

    useEffect(() => {
        dispatch(loadDtpHistory(data.id));

        return dispatch(loadedDtpHistory([]));
    }, [dispatch, data.id]);

    return (
        <>
            {loadingDtpHistory && <Loading linear />}
            {dtpHistory?.length
                ? <LSContainer
                    headers={[
                        { title: titles.NAME, width: '50%' },
                        { title: titles.USER, width: '50%' },
                        { title: '', isActions: true, align: 'right' }
                    ]}
                >
                    {dtpHistory?.map((item) =>
                        <TabItem
                            key={item.id}
                            item={item}
                            statuses={statuses}
                            loadTypes={loadTypes}
                            users={users}
                        />)}
                </LSContainer>
                : !loadingDtpHistory
                    && <div className="info__item">
                        {messages.INFO_IS_NOT_FOUND}
                    </div>
            }
        </>
    );
};

export default TabHistory;
