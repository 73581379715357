import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authSelectors } from 'redux/Auth';
import {
    clearAccessTemplate,
    loadAccessTemplate
} from 'redux/Auth/actions';
import Context from 'helpers/context';
import { config } from 'config';

import type { Permisson } from 'types';

let project_code: string | null = null;

// хук - получение прав доступа для конкретной страницы
export const usePermissions = (uri?: string) => {
    const dispatch = useDispatch();
    const permissionList = useSelector(authSelectors.permissionList || []); // из меню
    const access = useSelector(authSelectors.accessTemplate);

    const context = useContext(Context);

    const userPermissions = useMemo(() => {
        return uri
            ? permissionList.find((el: Permisson) => el?.link === uri)?.permissions || {}
            : context?.permissions;
    }, [uri, context?.permissions, permissionList]);

    const [permission, setPermission] = useState({ actions: userPermissions });

    const project: {[K: string]: any} = config.project ?? {};
    const code = project.code;

    useEffect(() => {
        if (uri) {
            if (access[uri]) {
                setPermission((prev) => ({
                    ...access[uri],
                    actions: {
                        ...prev.actions, // права из меню
                        ...access[uri].actions ?? {}, // права для страницы - в приоритете
                    }
                }));
            } else {
                dispatch(loadAccessTemplate(uri));
            }
        } else {
            setPermission({ actions: userPermissions });
        }

    }, [uri, access, userPermissions, dispatch]);

    useEffect(() => {
        if (code !== project_code) {
            project_code = code;
            dispatch(clearAccessTemplate());
            setPermission({
                actions: {
                    ...userPermissions, // права из меню
                }
            });
        }
    }, [code, userPermissions, dispatch]);

    return permission;
};
