import {
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField,
} from '@mui/material';

import { useValidation } from 'helpers/hooks';
import PasswordInput from 'components/common/PasswordInput/PasswordInput';

import RenderArray from './RenderArray';

const RenderTransferFields = ({ element, currentData, handleChange, test_id_prefix_passport = '' }) => {
    const validation = useValidation();
  
    if (element?.type === 'array' && element?.content?.length > 0) {
        return (
            <RenderArray 
                current={currentData?.[element?.field] || []}
                element={element}
                handleChange={handleChange}
                test_id_prefix_passport={test_id_prefix_passport}
            />
        );
        
    } else if (element?.type === 'boolean') {
        return (
            <FormControl className="block" key={element?.field}>
                <FormControlLabel
                    size="small"
                    data-testid={`${test_id_prefix_passport}:${element?.field}/label`}
                    control={
                        <Checkbox
                            checked={currentData?.[element?.field] || false}
                            onChange={(e) => handleChange(e.target.name, e.target.checked)}
                            name={element?.field}
                            color="primary"
                            style={{ pointerEvents: 'auto' }}
                            data-testid={`${test_id_prefix_passport}:${element?.field}/checkbox`}
                        />
                    }
                    label={element?.name}
                />
            </FormControl>
        );
    } else if (element?.type === 'password') {
        return (
            <FormControl
                className="block"
                variant="outlined"
                required={element?.require ?? false}
                key={element?.field}
            >
                <PasswordInput
                    value={currentData?.[element?.field] || ''}
                    name={element?.field}
                    label={element?.name}
                    validation={(!currentData?.[element?.field] && element?.require) || validation.isKey(`data.${[element?.field]}`)}
                    helperText={validation.get(`data.${[element?.field]}`)}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}  
                />
            </FormControl>
        );
    } else {
        return <FormControl
            className="block"
            variant="outlined"
            required={element?.require ?? false}
            key={element?.field}
        >
            <TextField
                label={element?.name}
                size="small"
                value={currentData?.[element?.field] || ''}
                name={element?.field}
                type={'text'}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                required={element?.require}
                error={(!currentData?.[element?.field] && element?.require) || validation.isKey(`data.${[element?.field]}`)}
                helperText={validation.get(`data.${[element?.field]}`)}
                InputProps={{
                    'data-testid': `${test_id_prefix_passport}:${element?.field}/input`,
                }}
            />
        </FormControl>;
    }
};

export default RenderTransferFields;