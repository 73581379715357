import { useState } from 'react';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import Events from './Events';

// кнопки в модалке
const ModalButtons = ({
    uuid,
}) => {

    const [showTraffic, setShowTraffic] = useState(false);

    return (
        <>
            <FormButtons
                buttons={[
                    {
                        onClick: () => setShowTraffic(true),
                        name: 'Проезды',
                        ...buttonsTypes.defaultPrimary,
                    },
                ]}
            />

            {showTraffic && (
                <Events
                    id={uuid}
                    isOpen={showTraffic}
                    onClose={() => setShowTraffic(false)}
                />
            )}
        </>
    );
};

export default ModalButtons;
