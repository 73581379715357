import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authSelectors } from 'redux/Auth';
import { createUser, deleteUser, editUser } from 'redux/Admin/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import AuthByUser from './AuthByUser';
import ResetPassModal from './ResetPassModal';
import Info from './Info';
import Form from './EditorForm';

const UserItem = ({ item, loadUserList, userTypes }) => {
    const dispatch = useDispatch();

    const authSuperUser = useSelector(authSelectors.authSuperUser);

    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openResetPassModal, setOpenResetPassModal] = useState(false);
    const [openCopyModal, setOpenCopyModal] = useState(false);
    const [isOpenAuthByUser, setIsOpenAuthByUser] = useState(false);

    return (
        <>
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onSuccess={() => {
                        dispatch(deleteUser(item.id, () => {setOpenDeleteModal(false); loadUserList();}));
                    }}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
            {modalOpen
                && <Form
                    onClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                    formData={item}
                    onSuccess = {(newData) => {
                        dispatch(editUser(newData, () => {setModalOpen(false); loadUserList();}));
                    }}
                    userTypes={userTypes}
                />
            }
            {openCopyModal
                && <Form
                    onClose={() => setOpenCopyModal(false)}
                    isOpen={openCopyModal}
                    title="Копирование пользователя"
                    formData={{ role_list: item?.role_list, user_id: item?.id }}
                    onSuccess = {(newData) => {
                        dispatch(createUser(newData, () => {setOpenCopyModal(false); loadUserList();}));
                    }}
                    userTypes={userTypes}
                />
            }
            {openResetPassModal && (
                <ResetPassModal
                    isOpen={openResetPassModal}
                    onClose={() => setOpenResetPassModal(false)}
                    user={item}
                />
            )}

            <LSContentItem onClick={() => setIsOpenInfo(!isOpenInfo)}>
                <LSContentColumn>
                    {item?.last_name ? [item?.last_name, item?.first_name, item?.middle_name].join(' ') : messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item.position || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn>
                    {item.organization_names.length > 0
                        ? item.organization_names.map((el) => <div key={el.id}>{el.title}</div>)
                        : messages.NO_DATA

                    }
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <i className="fas fa-key /" />,
                                name: 'Сбросить пароль',
                                size: 'small',
                                onClick: (e) => {e.stopPropagation(); setOpenResetPassModal(true);},
                            },
                            {
                                ...buttonsTypes.copyIcon,
                                onClick: (e) => {e.stopPropagation(); setOpenCopyModal(true);},
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: (e) => {e.stopPropagation(); setModalOpen(true);},
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: (e) => {e.stopPropagation(); setOpenDeleteModal(true);},
                                disabled: item.is_default,
                            },
                            {
                                name: 'Войти пользователем',
                                icon: <i className="fas fa-sign-in-alt" />,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setIsOpenAuthByUser(true);
                                },
                                disabled: Boolean(authSuperUser),
                            },
                        ]}
                    />
                    <ActionMore
                        isOpen={isOpenInfo}
                    />
                </LSContentColumn>
            </LSContentItem>
            {isOpenInfo && <Info isOpen={isOpenInfo} user={item} />}

            {isOpenAuthByUser && (
                <AuthByUser
                    isOpen={isOpenAuthByUser}
                    onClose={() => setIsOpenAuthByUser(false)}
                    user={item}
                />
            )}
        </>
    );
};

export default UserItem;
