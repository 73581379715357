import React from 'react';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectScenarios from 'modules/React/utils/components/SelectScenarios';
import type { OperationInScenario, ResponseScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

import RenderOperationList from '../RenderOperationList';

interface SelectScenarioOperationsModalProps {
    isOpen: boolean;
    onClose: () => void;
    selected: OperationInScenario[];
    setSelected: (operations: OperationInScenario[]) => void;
    scenarioId?: number
}

const SelectScenarioOperationsModal = ({
    isOpen,
    onClose,
    selected = [],
    setSelected,
    scenarioId
}: SelectScenarioOperationsModalProps) => {
    const [scenario, setScenario] = React.useState<ResponseScenario>({ operation_list: selected } as ResponseScenario);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Выбрать операции"
            noPadding={true}
            fullWidth
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => {setSelected(scenario.operation_list); onClose();},
                            disabled: !scenario.operation_list?.length
                        },
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                <SelectScenarios
                    label="Выбрать сценарий"
                    selected={scenario}
                    onChange={(value: any) => setScenario(value || {})}
                    disableFunc={(item) => scenarioId ? item.id === scenarioId : false}
                />
                <RenderOperationList
                    data={scenario.operation_list || []}
                />
            </div>
        </Modal>
    );
};

export default SelectScenarioOperationsModal;
