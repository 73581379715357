import createIconCluster from 'components/MapComponents/helpers/createIconCluster';

const config = {
    name: 'Инциденты',
    slug: 'react-incidents',
    clusterIcon: () =>  createIconCluster('fas fa-car-bump'),
    layerIcon: 'far fa-car-bump',
    mapContextMenu: {
        title: 'Добавить инцидент',
        event: 'add_react_incident',
        minWidth: 160
    },
};

export default config;
