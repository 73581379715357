import {
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import cn from 'classnames';

import styles from '../styles.module.scss';

const CardWidget = ({
    children,
    title = '',
    description = '',
    buttons = [],
    onClick,
    icon,
    editMode,
    onDelete,
    onEdit
}) => {
    const handleDelete = (e) => {
        e?.preventDefault?.();
        e?.stopPropagation?.();
        onDelete?.(e);
    };

    const handleEdit = (e) => {
        e?.preventDefault?.();
        e?.stopPropagation?.();
        onEdit?.(e);
    };

    return (
        <Card className={styles.card}>
            <div
                className={cn({
                    [styles.header]: true,
                    [styles.pointer]: !!onClick,
                })}
                {...(!editMode && { onClick })}
            >
                <div>
                    <span className={styles.icon}>{icon && <i className={icon} />}</span>
                    <span>{title}</span>
                </div>
                {editMode && (
                    <div>
                        <EditIcon className={styles.icon} onClick={handleEdit}/>
                        <DeleteIcon className={styles.icon} onClick={handleDelete}/>
                    </div>
                )}
            </div>

            <CardContent className={styles.cardContent}>
                {children}
                {description
                    && <Typography variant="body2" color="text.secondary">
                        {description}
                    </Typography>
                }
            </CardContent>

            {buttons.length > 0
                && <CardActions>
                    {buttons.map((item, index) => (
                        <Button size="small" key={index} {...item}>{item.name}</Button>
                    ))}
                </CardActions>
            }
        </Card>
    );
};

export default CardWidget;
