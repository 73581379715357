import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import { reactSelectors } from 'modules/React';
import { loadTriggers } from 'modules/React/redux/actions';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

import type { Meta } from 'types/pagination';
import type { Trigger } from './types';

const Triggers = () => {
    const dispatch = useDispatch();

    const data: Trigger[] = useSelector(reactSelectors.triggersData);
    const meta: Meta = useSelector(reactSelectors.triggersMeta);
    const loading = useSelector(reactSelectors.loadingTriggers);
    
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openModal, setOpenModal] = useState(false);

    const reloadList = (isDelete = false) => {
        const page = isDelete && meta.last_page > 1 && data.length === 1
            ? params.page - 1
            : params.page;

        dispatch(loadTriggers(page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadTriggers(params.page, limit, params.data));
    }, [dispatch, params, limit]);

    const renderContent = () => (
        data?.length > 0
            ? <LSContainer
                headers={[
                    { title: '', width: '50px' },
                    { title: titles.NAME, width: '30%' },
                    { title: 'Тип инцидента', width: '20%' },
                    { title: titles.RESPONSE_SCENARIO, width: '35%' },
                    { title: titles.ACTIONS, isActions: true }
                ]}
            >
                {data.map((item: Trigger) => (
                    <Item 
                        key={item.id} 
                        item={item}
                        reloadList={reloadList}
                    />
                ))}
            </LSContainer>
            : (!loading && <NoData/>)
    );

    return (<>
        <PageLayout
            header="Триггеры инцидентов"
            filters={<Filter setParams={setParams} />}
            informPanelProps={{
                buttons: 
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: () => setOpenModal(true),
                            }
                        ]}
                        positionLeft
                        noPadding
                    />,
                total: meta?.total
            }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams(old => ({ ...old, page })),
                list: meta,
                limit,
                setLimit
            }}
        />

        {openModal
            && <ModalForm
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                isNew={true}
                reloadList={reloadList}
            />
        }
    </>);
};

export default Triggers;