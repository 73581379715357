import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const apiMenuMethods = {
    getScenarios: async (access_token) => {
        const response = await instance.get(apiUrls.getScenarios());
        return response.data;
    },

    getScopes: async (scenario, access_token) => {
        const response = await instance.get(apiUrls.getScopes(scenario));
        return response.data;
    },

    getMenus: async (access_token, service) => {
        const response = await instance.get(apiUrls.getAllMenus(service));
        return response.data;
    },

    getMenusForScope: async (access_token, scope, service, scenario) => {
        const response = await instance.get(apiUrls.getMenusForScope(scope, service, scenario));
        return response.data;
    },

    createMenuItem: async (data, access_token) => {
        const response = await instance.post(apiUrls.createMenuItem(),
            data);
        return response.data;
    },

    editMenuItem: async (id, data, access_token) => {
        const response = await instance.put(apiUrls.editMenuItem(id),
            data);
        return response.data;
    },

    deleteMenuItem: async (id, access_token) => {
        const response = await instance.delete(apiUrls.deleteMenuItem(id));
        return response.data;
    },

    getPageHeaders: async () => {
        const response = await instance.get(apiUrls.getPageHeaders());
        return response.data;
    },
};

export default apiMenuMethods;
