import { useState } from 'react';
import { useDispatch } from 'react-redux';

import MapGeoJson from 'components/common/Location/MapGeoJson';
import Modal from 'components/common/Modal';
import { deleteEdge } from 'modules/DigitalTwin/redux/actions';
import AddEditEdge from 'modules/DigitalTwin/utils/components/AddEditEdge';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { ActionMore, ActionsButtons } from 'components/common/List';

import type { Edge } from '../../utils/types';

interface ActionsProps {
    item: Edge;
    onReload: (isDelete?: boolean) => void;
    toggleInfo: () => void;
}

function Actions({ item, onReload, toggleInfo }: ActionsProps) {
    const dispatch = useDispatch();

    const [isOpenMapModal, setIsOpenMapModal] = useState(false);
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    const handleDelete = () => {
        const callback = () => {
            setIsOpenDelete(false);
            onReload();
        };

        dispatch(deleteEdge(item.id, callback, null));
    };

    return (
        <>
            <div className="flex-end">
                <ActionsButtons
                    buttons={[
                        {
                            ...buttonsTypes.mapIcon,
                            onClick: () => setIsOpenMapModal(true),
                            disabled: !item.geometry?.type,
                        },
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setIsOpenEdit(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setIsOpenDelete(true),
                        },
                    ]}
                />

                <ActionMore
                    isOpen={isOpenInfo}
                    onClick={() => {
                        setIsOpenInfo((prev) => !prev);
                        toggleInfo();
                    }}
                />
            </div>

            {isOpenMapModal && (
                <Modal
                    isOpen={isOpenMapModal}
                    onClose={() => setIsOpenMapModal(false)}
                    noPadding
                    buttons={
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setIsOpenMapModal(false),
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson geometry={item.geometry} readOnly={true} />
                </Modal>
            )}

            {isOpenEdit && (
                <AddEditEdge
                    isOpen={isOpenEdit}
                    item={item}
                    onClose={() => setIsOpenEdit(false)}
                    reloadList={onReload}
                />
            )}

            {isOpenDelete && (
                <ConfirmDelete
                    open={isOpenDelete}
                    onSuccess={handleDelete}
                    onClose={() => setIsOpenDelete(false)}
                />
            )}
        </>
    );
}

export default Actions;
