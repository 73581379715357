import DtpGraph from 'modules/Dtp/Reports/DtpGraph';
import HeatMapDistrict from 'modules/Dtp/Reports/HeatMapDistrict';
import Incidents from 'modules/Dtp/Reports/Incidents';

const reportsRoutes = [
    {
        path: '/dtp/reports/graph',
        component: DtpGraph,
        exact: true,
    },
    {
        path: '/dtp/reports/heat-map-district',
        component: HeatMapDistrict,
        exact: true,
    },
    {
        path: '/dtp/reports/incidents',
        component: Incidents,
        exact: true,
    },
];

export default reportsRoutes;
