import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';

import {
    loadIncidentObjects,
    loadIncidentsStatuses,
    loadIncidentTypeById,
    loadVehicleCategories,
} from 'modules/React/redux/actions';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';
import SelectThreatLevels from 'modules/React/utils/components/SelectThreatLevels';
import SelectKeywords from 'modules/React/utils/components/SelectKeywords';
import SelectFactorIncident from 'modules/React/utils/components/SelectIncidentFactors';
import SelectIncidentCategories from 'modules/React/utils/components/SelectIncidentCategories';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { fullDateTimeWithoutSeconds, isDateValid } from 'helpers/date.config';
import Companies from 'components/common/Autocomplete/Companies';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import AddressFields from 'components/common/Location/AddressFields';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SelectUsers from 'components/common/Autocomplete/Users';
import Attachments from 'components/common/Upload/Attachments';
import StatusesSelect from 'components/common/Autocomplete/Statuses';
import TitleValidator from 'components/common/Location/TitleValidator';
import FieldsModal from 'components/common/Location/FieldsModal';
import { buttonsTypes } from 'components/common/FormButtons';
import TransportTypes from 'components/common/Autocomplete/Transport/Types';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import InfoBlock from 'components/common/InfoBlock';
import SelectCloseReason from 'components/common/Metro/MetroShema/SelectCloseReason';
import type { IncidentItem } from 'modules/React/Dictionaries/Incidents/types';
import { useAvailableStatuses } from 'modules/React/utils/helpers/hooks';
import type { FactorType, GeometryType, IncidentType, ScenarioType } from 'modules/React/reactTypes';
import type { UserItem } from 'components/common/Autocomplete/Users/types';
import type { StatusItem } from 'components/common/Autocomplete/Statuses';

import MetroFormMap from '../MetroModal/MetroFormMap';
import InnerStations from '../InnerStations';
import NotificationModal from '../NotificationModal';

import { FactorsList } from './FactorsList';
import SelectIncidentScenario from './SelectIncidentScenario';
import RenderStatusDate from './RenderStatusDate';

type BasicDataProps = {
  data?: IncidentItem;
  isNew?: boolean;
  isMkDtp?: boolean,
  loadingObjects?: boolean,
  onLoadByIncident: (v: { lat: number | string, lon: number | string }) => void,
  onLoadByStations: (v: { lat: number | string, lon: number | string }[]) => void,
  setData: React.Dispatch<React.SetStateAction<IncidentItem>>,
}

export const BasicData = ({
    data = {},
    setData = () => {},
    isNew = false,
    isMkDtp = false,
    loadingObjects = false,
    onLoadByIncident = () => {},
    onLoadByStations = () => {},
}: BasicDataProps) => {
    const dispatch = useDispatch();
    const statuses: StatusItem[] = useStoreProp(loadIncidentsStatuses, 'react', 'incidentsStatuses');

    const [type, setType] = useState<IncidentType | null>(null);
    const [dependence, setDependence] = useState(true);

    const checkStatus = (name: 'Обработан' | 'В работе' | 'Завершен' | 'Новый' | 'Отказ'): boolean => {
        // проверить у нас статус такой как передали в параметрах?
        return data.status_text
            ? data.status_text === name
            : data.status_id === statuses.find((el: any) => el.name === name)?.id;
    };
    const [isDisableEdit, setIsDisableEdit] = useState(checkStatus('Завершен') || checkStatus('Отказ'));

    const availalbleStatuses = useAvailableStatuses(data?.response_scenario_id, data.status_id as number);

    const validation = useValidation();
    let categories = useStoreProp(loadVehicleCategories, 'react', 'vehicleCategories');

    categories = Object.keys(categories).map(key => ({ id: Number(key), name: categories[key] }));

    const isCategoryMetro = data.category_id?.id === 2;

    const [factorsList, setFactorsList] = useState<FactorType[]>([]);

    useEffect(() => {
        if (data.lat && data.lon && isNew) {
            dispatch(loadIncidentObjects({ lat: data.lat, lon: data.lon, radius: 50 }));
        }
        // else if (isNew) {
        //     dispatch(clearIncidentObjects());
        // }
    }, [data.lat, data.lon, isNew, dispatch]);

    const changeFactors = (array: FactorType[]) => {
        const setComment = (f: FactorType) => {
            switch (true) {
                case !!f.comment:
                    return f.comment;
                case (f.comment === ''):
                    return '';
                case (!!(f.comment === null && f.geo_json)):
                    return '';
                default:
                    return 'Отсутствуют необходимые сведения';
            }
        };

        return array.map((f) => ({
            ...f,
            ...(f.is_geo
                ? {
                    comment: setComment(f),
                    geo_json: f.geo_json || null
                }
                : {}
            ) //список всех факторов из типа с is_default - добавляем им коммент и геозону
        }));
    };

    const updateDataFactors = useCallback((list?: FactorType[], typeChanged = true) => { // TODO перенести в FactorList
        if (list && list.length > 0) {
            // обновился factorsList - надо из него взять те,
            // которых еще нет в инциденте и у которых is_default и добавить в инцидент
            // typeChanged - если поменяли тип то не надо объединять с уже имеющимися у инцидента факторами
            setData((prev) => {
                const newArray = prev.factors && prev.factors.length > 0 && !typeChanged
                    ? prev.factors
                    : list.filter((elem: FactorType) => elem.is_default);

                // && !prev.factors.find((f: Factor) => f.id === elem.id))

                const newFactors: FactorType[] = changeFactors(newArray as FactorType[]);

                const result: IncidentItem = {
                    ...prev,
                    factors: newFactors
                };

                return result;
            });
        } else {
            setData((prev) => ({
                ...prev,
                factors: []
            }));
        }
    }, [setData]);


    useEffect(() => {
        if (data.type_id && data.type_id !== type?.id) {
            dispatch(loadIncidentTypeById(data.type_id, (el: IncidentType) => {
                setType(el); // заполняем поле тип
                setFactorsList(el.factors); //список всех факторов типа - их будем выбирать в универсальном селекте
                updateDataFactors(el.factors, false);
            }));
        }
    }, [data.type_id, dispatch, updateDataFactors, type]);

    const dependenceHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDependence(event.target.checked);
    };


    const onChangeGeoJsonCoordinates = (params: any) => {
        const isLatLon = !!params?.lat || !!params?.lon;
        setData((prevData) => ({
            ...prevData,
            ...params,
            ...(isLatLon ? { objects: [] } : {}) // для обновления объектов
        }));
        validation.deleteKeys(Object.keys(params || {}));
        if (isLatLon && !isCategoryMetro) onLoadByIncident(params);
    };

    //TODO - придумать как избавиться от any
    const onChange = (name: keyof IncidentItem, value: any) => {

        setData((prev: IncidentItem) => ({
            ...prev,
            ...(name === 'status_id'
                ? {
                    status_id: value.id,
                    status_color: value.color,
                    status_text: value.name
                }
                : {
                    [name]: value,
                    ...changeStatus(name, value)

                }
            ),

        }));

        if (name === 'status_id') {
            setIsDisableEdit(value.name ==='Завершен' || value.name === 'Отказ');
        }

        validation.deleteKey(name);
    };

    const onChangeIsArchive = (value: boolean) => {
        setData((prev: IncidentItem) => ({
            ...prev,
            is_archive: value,
        }));
    };

    const changeStatus = (name: string, value: UserItem[] | ScenarioType)  => {
        let newStatus;
        if (name === 'dispatcher' && Array.isArray(value)) {
            newStatus = value?.length > 0
                ? statuses.find((el: any) => el.name === 'В работе')
                : statuses.find((el: any) => el.name === 'Новый'); // TODO уточнить если удалили Диспетчера то на что меняем статус
        }
        if (name === 'response_scenario') {
            newStatus = value
                ? statuses.find((el: any) => el.name === 'Присвоен сценарий')
                : statuses.find((el: any) => el.name === 'В работе'); // TODO а если есть операции и они все чекнуты?
        }

        return newStatus
            ? {
                status_id: newStatus.id,
                status_color: newStatus.color,
                status_text: newStatus.name
            }
            : {};
    };

    const onChangeFactors = (value: FactorType[]) => {
        setData((prev: IncidentItem) => ({
            ...prev,
            factors: value ? changeFactors(value) : value
        }));

        validation.deleteKey('factors');
    };

    const handleChangeIncidentTypes = (e: IncidentType | null) => {
        setType(e);
        if (e) {
            setData({
                ...data,
                type_id: e?.id,
                type_text: e?.name,
                vehicle_types: [],
            });
            setFactorsList(e.factors);
            validation.deleteKey('type_id');
            updateDataFactors(e.factors);
        } else {
            setData({
                ...data,
                type_id: null,
                type_text: '',
                vehicle_types: [],
            });
            setFactorsList([]);
            updateDataFactors([]);
        }
    };

    const checkInvalidDate = useCallback((date) => {
        return date && !isDateValid(date);
    }, []);

    const handleChangeDocuments = (files: string[]) => {
        setData({
            ...data,
            attachments: files
        });
    };

    const getLatLonGeometry: GeometryType | null = data.lat && data.lon
        ? {
            type: 'Feature',
            properties: {
                type: 'Feature',
            },
            geometry: {
                type: 'Point',
                coordinates: [
                    data.lon,
                    data.lat,
                ],
            }
        }
        : null;

    return (
        <form className="modal__form">
            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <div className="block">
                        {isDisableEdit && <FormControlLabel
                            style={{ pointerEvents: 'none' }}
                            control={
                                <Checkbox
                                    disabled={false}
                                    checked={data.is_archive || false}
                                    onChange={(e) => onChangeIsArchive(e.target.checked)}
                                    color="primary"
                                    style={{ pointerEvents: 'auto' }}
                                />
                            }
                            label="Архив"
                        />}

                        <FormInfoWrapper error={!data.name || !!validation.isKey('name')} helperText={validation.get('name')}>
                            <TextField
                                label={titles.NAME}
                                size="small"
                                value={data.name}
                                variant="outlined"
                                name="name"
                                type="text"
                                onChange={(e) => onChange('name', e.target.value)}
                                required={true}
                                error={!data.name || !!validation.isKey('name')}
                                disabled={isDisableEdit || Number(data.status_id) === 3 || Number(data?.type_creation) === 2 || !!data.dtp_id}
                            />
                        </FormInfoWrapper>
                    </div>

                    <div className="block">
                        {data.is_archive
                            ? <RenderStatusDate status_text={data.status_text || ''} updated_at={data.updated_at || null} />
                            : <StatusesSelect
                                selected={statuses.find(el => el.id === data.status_id) || null}
                                label="Статус"
                                options={availalbleStatuses}
                                disabled={isDisableEdit || availalbleStatuses.length === 0}
                                renderLabel={(option) => option?.name || ''}
                                onChange={(value) => onChange('status_id', value)}
                                helperText={validation.get('status_id')}
                                error={validation.isKey('status_id')}
                                disableReset
                            />
                        }
                    </div>

                    <div className="block">
                        <SelectIncidentTypes
                            selected={type}
                            error={!data?.type_id || !!validation.isKey('type_id')}
                            helperText={validation.get('type_id')}
                            onChange={(el) => handleChangeIncidentTypes(el as (IncidentType | null))}
                            disabled={isDisableEdit || !isNew || isMkDtp || !!data.dtp_id}
                        />
                    </div>

                    <div className="block">
                        <SelectIncidentCategories
                            selected={data?.category_id || null}
                            error={!data?.category_id || validation.isKey('category_id')}
                            helperText={validation.get('category_id')}
                            onChange={(el: any) => {
                                // onChange('category_id', el);
                                setData((old: any) => ({
                                    ...old,
                                    category_id: el,
                                    ...(el?.id === 1 ? {
                                        // geometry: null,
                                        // lat: 0,
                                        // lon: 0,
                                        // address: null,
                                        metro_stations: [],
                                        type_close_id: 0,
                                        reason_close_id: 0,
                                    } : {})
                                }));
                            }}
                            required
                            disabled={isDisableEdit || isMkDtp}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={date => onChange('registered_at', date)}
                            value={data.registered_at}
                            label="Дата/время инцидента"
                            pickerFormat="dd.MM.yyyy HH:mm"
                            required
                            isDisabled={isDisableEdit || Number(data.status_id) === 3 || Number(data?.type_creation) === 2 || !!data.dtp_id}
                            error={!data.registered_at
                                        || checkInvalidDate(data.registered_at)
                                        || validation.isKey('registered_at')}
                            helperText={validation.get('registered_at')}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={date => onChange('date_plan', date)}
                            value={data.date_plan}
                            label="Дата/время решения инцидента план"
                            pickerFormat="dd.MM.yyyy HH:mm"
                            isDisabled={isDisableEdit || Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                            helperText={validation.get('date_plan')}
                            error={validation.isKey('date_plan')}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={date => onChange('date_fact', date)}
                            value={data.date_fact}
                            label="Дата/время решения инцидента факт"
                            pickerFormat="dd.MM.yyyy HH:mm"
                            isDisabled={isDisableEdit || true}
                            helperText={validation.get('date_fact')}
                            error={validation.isKey('date_fact')}
                        />
                    </div>

                    <div className="block">
                        <FormInfoWrapper error={validation.isKey('solution')} helperText={validation.get('solution')}>
                            <TextField
                                label={titles.SOLUTION}
                                size="small"
                                value={data.solution || ''}
                                variant="outlined"
                                name="solution"
                                type="text"
                                onChange={(e) => onChange('solution', e.target.value)}
                                error={validation.isKey('solution')}
                            />
                        </FormInfoWrapper>
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={date => onChange('created_at', date)}
                            value={data.created_at}
                            label="Дата/время регистрации"
                            pickerFormat="dd.MM.yyyy HH:mm"
                            isDisabled={isDisableEdit || true}
                            helperText={validation.get('created_at')}
                            error={validation.isKey('created_at')}
                        />
                    </div>

                    <div className="block">
                        <FormInfoWrapper error={!data.description || validation.isKey('description')} helperText={validation.get('description')}>
                            <TextField
                                label={titles.DESCRIPTION}
                                size="small"
                                value={data.description}
                                variant="outlined"
                                name="description"
                                type="text"
                                onChange={(e) => onChange('description', e.target.value)}
                                required
                                error={!data.description || validation.isKey('description')}
                                disabled={isDisableEdit || !!data.dtp_id}
                            />
                        </FormInfoWrapper>
                    </div>

                    <div className="block">
                        <SelectThreatLevels
                            required
                            selected={data?.threat_level || { id: 0, name: '' }}
                            helperText={validation.get('threat_level')}
                            error={!data?.threat_level || validation.isKey('threat_level')}
                            onChange={(value: any) => onChange('threat_level', value)}
                            disabled={isDisableEdit}
                        />
                    </div>

                    {
                        !isNew && <div className="block">
                            <FormInfoWrapper error={!!validation.isKey('source')} helperText={validation.get('source')}>
                                <TextField
                                    disabled
                                    label={titles.DATA_SOURCE}
                                    size="small"
                                    value={data.source}
                                    variant="outlined"
                                    name="source"
                                    type="text"
                                    onChange={(e) => onChange('source', e.target.value)}
                                />
                            </FormInfoWrapper>
                        </div>
                    }

                    <TransportTypes
                        multiple
                        label={titles.TYPE_OF_VEHICLE}
                        selected={data.vehicle_types || []}
                        onChange={(el) => onChange('vehicle_types', el)}
                        options={categories}
                        helperText={validation.get('vehicle_types')}
                        error={validation.isKey('vehicle_types')}
                        disabled={isDisableEdit}
                    />

                    <div className="block">
                        <Companies
                            multiple
                            filter={{ withDeleted: 1 }}
                            label="Ответственная организация"
                            selected={data.organizations}
                            onChange={(value) => { onChange('organizations', value);}}
                            helperText={validation.get('organizations')}
                            error={validation.isKey('organizations')}
                            disabled={isDisableEdit}
                        />
                    </div>

                    <div className="block">
                        <SelectUsers
                            multiple
                            selected={data.user || []}
                            onChange={(value) => onChange('user', value)}
                            helperText={validation.get('user_id')}
                            error={validation.isKey('user_id')}
                            disabled={isDisableEdit || Number(data.status_id) === 3 || data.organizations?.length === 0}
                            label={titles.EMPLOYEES}
                            filter={{ org_ids: data.organizations?.map(el => el.id) }}
                        />
                    </div>

                    <FormControl className="block" size="small" variant="outlined">
                        <SelectUsers
                            multiple
                            selected={data.dispatcher || []}
                            onChange={(dispatcher) => onChange('dispatcher', dispatcher)}
                            helperText={validation.get('dispatcher') || 'Пример: "ФИО Диспетчера"'}
                            error={validation.isKey('dispatcher')}
                            // disabled={Number(data.status_id) === 3 || data.organizations?.length === 0}
                            label={'Диспетчер'}
                            filter={{ org_ids: data.organizations?.map(el => el.id) }}
                            disabled={isDisableEdit}
                        />
                    </FormControl>

                    <FormInfoWrapper>
                        <FormControl
                            variant="outlined"
                            size="small"
                            className="block"
                            required={true}
                            error={!Number.isInteger(data?.resolve_rules) || validation.isKey('resolve_rules')}
                            disabled={isDisableEdit}
                        >
                            <InputLabel>Правило закрытия</InputLabel>
                            <Select
                                value={data.resolve_rules || ''}
                                name="resolve_rules"
                                //@ts-ignore
                                onChange={(event) => onChange('resolve_rules', event.target.value)}
                                label="Правило закрытия"
                            >
                                <MenuItem value={1}>Вручную</MenuItem>
                                <MenuItem value={2}>Автоматом</MenuItem>
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>

                    <SelectKeywords
                        multiple
                        selected={data.keywords || []}
                        onChange={(value) => onChange('keywords', value)}
                        disabled={isDisableEdit}
                    />

                    <SelectIncidentScenario
                        className="block"
                        selected={data?.response_scenario || null}
                        disabled={isDisableEdit || !data?.type_id}
                        onChange={(value: ScenarioType) => onChange('response_scenario', value)}
                        helperText={(data?.type_id
                            ? validation.get('response_scenario_id')
                            : 'Необходимо выбрать тип инцидента')
                        }
                        error={!data?.type_id || validation.isKey('response_scenario_id')}
                        filters={{
                            types_id_list: data?.type_id ? [data?.type_id] : null,
                            factors_list: data?.factors ||  null,
                            keyword_list: data?.keywords || null,
                        }}
                    />

                    <InfoBlock label="Пользовательские факторы"  mt="1rem">

                        <div className="block">
                            <SelectFactorIncident
                                multiple
                                required={factorsList.length > 0}
                                error={!data?.type_id || validation.isKey('factors')}
                                selected={data?.factors || []}
                                sortedList={factorsList}
                                disabled={isDisableEdit || !data?.type_id || factorsList.length === 0}
                                onChange={(value) => onChangeFactors(value as FactorType[])}
                                helperText={!data?.type_id
                                    ? 'Необходимо выбрать тип инцидента'
                                    : factorsList.length === 0
                                        ? 'Для выбранного типа инцидента не добавлено ни одного фактора'
                                        : validation.get('factors')
                                }
                            />
                        </div>

                        {!isDisableEdit && <div className="block">
                            <FactorsList
                                factors={data.factors || []}
                                onChange={(factors) => setData({ ...data, factors })}
                                visibleGeometry={getLatLonGeometry}
                            />
                        </div>}
                    </InfoBlock>
                </Grid>

                <Grid item xs={6}>
                    {isCategoryMetro === false && (
                        <>
                            <FormControlLabel
                                style={{ pointerEvents: 'none' }}
                                control={
                                    <Checkbox
                                        disabled={isDisableEdit || Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                                        checked={dependence}
                                        onChange={dependenceHandler}
                                        color="primary"
                                        style={{ pointerEvents: 'auto' }}
                                    />
                                }
                                label="Адрес зависит от координат"
                            />

                            <FormControl className="block" variant="outlined">
                                {/* lat lon */}
                                <LoadAddressByCoords
                                    {...data}
                                    isLoadOnLatLon={false}
                                    isLoadOnChange={dependence}
                                    onChange={onChangeGeoJsonCoordinates}
                                    disabled={isDisableEdit || !!data.dtp_id}
                                >
                                    <LatLonCoordinates required/>
                                </LoadAddressByCoords>
                            </FormControl>

                            <FormControl className="block" variant="outlined">
                                {/* address */}
                                <AddressFields
                                    {...data}
                                    //@ts-ignore
                                    onChange={(props) => onChangeGeoJsonCoordinates(props)}
                                    returnLatLon={dependence}
                                    returnAddressText={dependence}
                                    returnGeometry={Object.keys(data.geometry || {}).length === 0 || dependence}
                                    readOnly={isDisableEdit || !!data.dtp_id}
                                />
                            </FormControl>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {JSON.stringify(data.geometry) === '{}'
                                    && <h2 style={{ color: 'red' }}>Укажите транспортный инцидент*</h2>
                                }
                            </div>

                            <FormControl className="block" variant="outlined" error={true}>
                                <LoadAddressByCoords
                                    {...data}
                                    onChange={onChangeGeoJsonCoordinates}
                                    isLoadOnLatLon={dependence}
                                    isLatLonColumnView={true} // такого поля нет
                                >
                                    <AddressList
                                        required
                                        returnGeometry={false}
                                        readOnly={isDisableEdit || !!data.dtp_id}
                                    />
                                </LoadAddressByCoords>
                            </FormControl>
                        </>
                    )}

                    <FormControl className="block" variant="outlined">
                        <MapDragMarker
                            {...(isCategoryMetro === false ? data : {})}
                            onChange={onChangeGeoJsonCoordinates}
                            required
                            // @ts-ignore
                            innerComponent={(props: object) => <InnerStations {...props} data={data} />}
                            readOnly={isDisableEdit}
                        />
                    </FormControl>


                    {isCategoryMetro
                        ?  (
                            <>
                                {/*{(data?.metro_stations && data?.metro_stations?.length > 0) && (*/}
                                {/*    <NotificationModal*/}
                                {/*        data={data?.metro_stations?.map(i => i.id)}*/}
                                {/*    />*/}
                                {/*)}*/}
                                {/*<Link style={{ cursor: 'pointer' }} href="https://orgp.spb.ru/metro/" target="_blank">Узнать больше</Link>*/}

                                {/* метро */}
                                <MetroFormMap
                                    data={data}
                                    setData={setData}
                                    loading={loadingObjects}
                                    onLoadObjects={onLoadByStations}
                                />

                                <SelectCloseReason // @ts-ignore
                                    value={data?.reason_close_id || 0}
                                    onChange={(data: any) => onChange('reason_close_id', data)}
                                    helperText={validation.get('reason_close_id')}
                                    error={!data.reason_close_id || validation.isKey('reason_close_id')}
                                    required
                                    disabled={isDisableEdit}
                                />
                            </>
                        )
                        : (
                            <div className="block">
                                <TitleValidator
                                    fields={data}
                                    validationFields={['area']}
                                >
                                    Зона действия *:
                                </TitleValidator>
                                {validation.isKey('check_point.geometry')
                                    ? (
                                        <FormHelperText className="error">
                                            {validation.get('check_point.geometry')}
                                        </FormHelperText>
                                    )
                                    : null
                                }
                                {/* //@ts-ignore */}
                                <FieldsModal
                                    title="Выбрать область на карте"
                                    fields={{ geometry: data?.geometry || {} }}
                                    buttonText={isNew ? 'Выбрать' : 'Изменить'}
                                    //@ts-ignore
                                    buttonType={buttonsTypes.selectOnMap}
                                    buttonVariant="contained"
                                    onChange={({ geometry }: any) => {
                                        onChange('geometry', geometry);
                                        validation.deleteKeys(['area.geometry.coordinates.0', 'check_point.geometry']);
                                    }}
                                >
                                    <MapGeoJson
                                        visibleGeometry={getLatLonGeometry}
                                        circle={true}
                                        polygon={true}
                                        polyline={false}
                                        marker={false}
                                        isHideValidation
                                        readOnly={isDisableEdit}
                                    />
                                </FieldsModal>

                            </div>
                        )
                    }

                    <Divider style={{ margin: '0.5rem 0' }}/>

                    <Attachments
                        //TODO - придумать как убрать ts-ignore
                        //@ts-ignore
                        onChange={handleChangeDocuments}
                        files={data?.attachments}
                        readOnly={isDisableEdit}
                        service="situational-plans"
                        onlyLink={false}
                    />
                </Grid>
            </Grid>
        </form>
    );
};
