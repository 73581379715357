import { useEffect, useMemo, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { isEqual } from 'lodash';

import {
    loadRoadSectionRelationTypes,
    loadStatusesRoadSection,
} from 'redux/RoadNetwork/actions';
import { usePrevious, useStoreProp, useValidation } from 'helpers/hooks';
import { getDateToFormat } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';
import useCategoryTypes from 'helpers/hooks/RoadNetwork/useCategoryTypes';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import TitleValidator from 'components/common/Location/TitleValidator';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import FieldsModal from 'components/common/Location/FieldsModal';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import SelectCompany from 'components/common/Autocomplete/Companies';
import RoadSectionRelationList from 'components/common/RoadSectionRelationList';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import InfoBlock from 'components/common/InfoBlock';

import { RelationModal } from '../RelationModal';

const EditorForm = (props) => {
    const { dataForm, onClose, onSuccess, isOpen, isNew, isClone } = props;
    // const roadSectionTypes = useStoreProp(loadRoadSectionTypes, 'roadNetworks', 'roadSectionTypes');
    // const roadSectionCategories = useStoreProp(loadRoadSectionCategories, 'roadNetworks', 'roadSectionCategories');
    const statuses = useStoreProp(loadStatusesRoadSection, 'roadNetworks', 'statusesRoadSection');
    const relationTypes = useStoreProp(loadRoadSectionRelationTypes, 'roadNetworks', 'relationTypes');

    const categoryTypesProvider = useCategoryTypes();

    const validation = useValidation();

    const initialFields = {
        ...dataForm,
        name: isNew || isClone ? '' : dataForm?.name,
        is_can_deleted: dataForm?.is_can_deleted || false,
        created_at: dataForm?.created_at || null,
        fixated_at: dataForm?.fixated_at || new Date(),
        description: dataForm?.description || '',
        address_text: isNew || isClone ? '' : dataForm?.address_text,
        category: dataForm?.category || '',
        type: dataForm?.type || '',
        geometry: isNew || isClone ? {} : dataForm?.geometry,
        data: dataForm?.data || null,
        status: dataForm?.status || 1, // по умолчанию статус планируемые
        length: dataForm?.length || 0,
        lat: isNew || isClone ? '' : dataForm?.lat,
        lon: isNew || isClone ? '' : dataForm?.lon,
        organization: dataForm?.organization || {},
        organization_id: dataForm?.organization_id || 0,
    };

    const [dependence, setDependence] = useState(true);
    const [fields, setFields] = useState(initialFields);
    // const [sectionTypes, setSectionTypes] = useState(roadSectionTypes);
    // const [dislocationData, setDislocationData] = useState(
    //     dataForm?.address
    //         ? {
    //             lat: dataForm?.address?.geo_lat,
    //             lon: dataForm?.address?.geo_lon,
    //         }
    //         : {}
    // );
    const [relationState, setRelationState] = useState(false);

    // useEffect(() => {
    //     if (Number(fields?.category) === 1) {
    //         setSectionTypes({
    //             1: roadSectionTypes['1'],
    //             2: roadSectionTypes['2'],
    //             3: roadSectionTypes['3'],
    //         });
    //     } else if (Number(fields?.category) === 2) {
    //         setSectionTypes({
    //             4: roadSectionTypes['4'],
    //         });
    //     } else {
    //         setSectionTypes(roadSectionTypes);
    //     }
    // }, [fields?.category, roadSectionTypes]);

    const handleClose = () => {
        if (onClose) onClose();
    };

    const acceptMapModal = ({ geometry }) => {
        validation.deleteKey('data');
        // setGeoJsonObj(newCoordinates);
        handleChange('data')(geometry);
    };

    const onChangeCoordinates = (params) => {
        const { address, lat, lon } = params;
        if (
            (lat && lon)
            || (address?.geo_lat && address?.geo_lon)
        ) {
            params.geometry = createPointGJ(
                lat || address?.geo_lat,
                lon || address?.geo_lon
            );
        }

        setFields(old => ({ ...old, ...params }));
        validation.deleteKeys(Object.keys(params));
    };

    const handleSuccess = () => {
        if (onSuccess) {
            const { address = null } = fields;
            const address_text = () => {
                if (address) {
                    const { city_name = '', street = '', house = '' } = address;
                    return [city_name, street, house].join(', ');
                }
                return '';
            };

            onSuccess({
                ...fields,
                created_at: fields.created_at ? getDateToFormat(fields.created_at, 'yyyy-MM-dd') : null,
                fixated_at: fields.fixated_at ? getDateToFormat(new Date(fields.fixated_at), 'yyyy-MM-dd') : null,
                is_can_deleted: false,
                address_text: address_text(),
                length: Number(fields.length),
                type: fields.type || null
            });
        }
    };

    const handleChange = (name) => (value) => {
        validation.deleteKey(name);
        setFields((oldFields) => ({
            ...oldFields,
            [name]: value,
        }));
    };

    const btnList = [
        {
            ...buttonsTypes.close,
            onClick: handleClose
        },
        {
            ...buttonsTypes.save,
            onClick: handleSuccess
        }
    ];

    useEffect(() => {
        // для старых записей проверяем что есть такая категория и такой тип
        if (
            categoryTypesProvider.isLoaded
            && fields.category
            && fields.type
            && !categoryTypesProvider.isValid(fields.category, fields.type)
        ) {
            setFields(old => ({
                ...old,
                type: ''
            }));
        }
    }, []);

    const typesList = categoryTypesProvider.getTypes(fields.category);

    const geometryTypes = useMemo(() => {
        // ттк или перекресток
        const isMarker = [2, 3].includes(fields.category);
        return {
            circle: false,
            polygon: false,
            polyline: !isMarker,
            marker: isMarker,
        };
    }, [fields.category]);
    const prevGeometryTypes = usePrevious(geometryTypes);

    useEffect(() => {
        if (
            Object.keys(fields.data || {}).length > 0
            && !isEqual(geometryTypes, prevGeometryTypes)
        ) {
            handleChange('data')(null);
        }
    }, [fields.data, geometryTypes, prevGeometryTypes]);

    const title = isNew
        ? 'Добавление элемента сети'
        : isClone
            ? 'Клонирование элемента сети'
            : 'Редактирование элемента сети';

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            noPadding
            title={title}
            maxWidthProp={'md'}
            buttons={
                <FormButtons
                    buttons={btnList}
                />
            }
        >
            <div className="modal__form">
                <div className="row">
                    <FormInfoWrapper className="row__item" error={!fields.name || validation.isKey('name')} helperText={validation.get('name')}>
                        <TextField
                            size="small"
                            multiline
                            variant="outlined"
                            label="Название элемента сети"
                            maxRows={5}
                            required={true}
                            defaultValue={fields.name}
                            onChange={(event) => {
                                handleChange('name')(event.target.value);
                            }}
                            error={!fields.name || validation.isKey('name')}
                        />
                    </FormInfoWrapper>
                    {fields?.created_at && (
                        <SingleKeyboardDateTimePicker
                            value={fields?.created_at || null}
                            onChange={handleChange('created_at')}
                            label={titles.DATE_TIME_CREATED}
                            isDisabled={true}
                            className={'row__item'}
                        />
                    )}
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        value={fields?.fixated_at || null}
                        onChange={handleChange('fixated_at')}
                        label="Дата регистрации"
                        dateOnly={true}
                        error={!fields?.fixated_at || validation.isKey('fixated_at')}
                        helperText={validation.get('fixated_at')}
                        required
                        className={'block'}
                    />
                </div>
                <div className="row">
                    <FormInfoWrapper className="row__item" error={validation.isKey('status')} helperText={validation.get('status')}>
                        <FormControl
                            size="small"
                            variant="outlined"
                            required
                        >
                            <InputLabel>{titles.STATUS}</InputLabel>
                            <Select
                                value={fields.status}
                                onChange={(event) => {
                                    handleChange('status')(event.target.value);
                                }}
                                label={titles.STATUS}
                                error={validation.isKey('status')}
                            >
                                {statuses
                                    ?.map(({ id, name }) => (
                                        <MenuItem
                                            key={id}
                                            value={id}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>

                    <FormInfoWrapper className="row__item" error={!fields.category || validation.isKey('category')} helperText={validation.get('category')}>
                        <FormControl
                            size="small"
                            variant="outlined"
                            required
                        >
                            <InputLabel>{titles.CATEGORY}</InputLabel>
                            <Select
                                value={fields.category}
                                onChange={(event) => {
                                    handleChange('category')(event.target.value);
                                    handleChange('type')('');
                                    handleChange('data')(null);
                                }}
                                label={titles.CATEGORY}
                                error={!fields.category}
                                disabled={!categoryTypesProvider.isLoaded}
                            >
                                <MenuItem value={''}>
                                    Не выбрано
                                </MenuItem>
                                {categoryTypesProvider
                                    .getCategories()
                                    .map(({
                                        id,
                                        name
                                    }) => (
                                        <MenuItem key={id} value={id}>
                                            {name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>
                </div>

                <div className="row">
                    <FormInfoWrapper className="row__item" error={validation.isKey('type')} helperText={!fields?.category ? 'Выберите сначала категорию!' : validation.get('category')}>

                        <FormControl
                            size="small"
                            variant="outlined"
                            required={typesList.length > 0}
                        >
                            <InputLabel>{titles.TYPE}</InputLabel>
                            <Select
                                value={fields.type}
                                onChange={(event) => {
                                    handleChange('type')(event.target.value);
                                }}
                                label={titles.TYPE}
                                disabled={!fields?.category || typesList.length === 0 || !categoryTypesProvider.isLoaded}
                                error={!fields.type && typesList.length > 0}
                            >
                                <MenuItem value={''}>
                                    Не выбрано
                                </MenuItem>
                                {typesList.map(({ id, name }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>

                    <FormInfoWrapper className="row__item" error={validation.isKey('length')} helperText={validation.get('length')}>
                        <TextField
                            size="small"
                            variant="outlined"
                            type="number"
                            label="Протяженность (км)"
                            defaultValue={fields.length}
                            onChange={(event) => {
                                handleChange('length')(event.target.value);
                            }}
                        />
                    </FormInfoWrapper>
                </div>
                <FormInfoWrapper className="block" error={validation.isKey('description')} helperText={validation.get('description')}>
                    <TextField
                        size="small"
                        multiline
                        variant="outlined"
                        label={titles.DESCRIPTION}
                        maxRows={5}
                        defaultValue={fields.description}
                        onChange={(event) => {
                            handleChange('description')(event.target.value);
                            // setSelectedDesc(event.target.value);
                        }}
                        error={validation.isKey('description')}
                    />
                </FormInfoWrapper>

                <InfoBlock label={titles.LOCATION} className="block">
                    <FormControlLabel
                        style={{ pointerEvents: 'none' }}
                        control={
                            <Checkbox
                                checked={dependence}
                                onChange={(e) => setDependence(e.target.checked)}
                                color="primary"
                                style={{ pointerEvents: 'auto' }}
                            />
                        }
                        label="Адрес зависит от координат"
                    />

                    <FormControl className="block" variant="outlined">
                        <LatLonCoordinates
                            lat={fields.lat || ''}
                            lon={fields.lon || ''}
                            onChange={onChangeCoordinates}
                            required
                        />
                    </FormControl>

                    <FormControl className="block" variant="outlined">
                        <LoadAddressByCoords
                            {...fields}
                            onChange={onChangeCoordinates}
                            isLoadOnLatLon={dependence}
                        >
                            <AddressList required />
                        </LoadAddressByCoords>
                    </FormControl>

                    <FormControl className="block" variant="outlined">
                        <MapDragMarker
                            {...fields}
                            onChange={onChangeCoordinates}
                            required
                        />
                    </FormControl>

                    <TitleValidator
                        fields={fields}
                        validationFields={['data']}
                    >
                        Укажите зону действия *:
                    </TitleValidator>
                    <small style={{ color: 'red' }}>{validation.get('data') || ''}</small>

                    <FieldsModal
                        title="Выбрать область на карте"
                        fields={{ geometry: fields?.data || {} }}
                        buttonText={buttons.SELECT}
                        buttonType={buttonsTypes.selectOnMap}
                        buttonVariant="contained"
                        onChange={acceptMapModal}
                    >
                        <MapGeoJson
                            {...geometryTypes}
                            isHideValidation
                            visibleGeometry={
                                fields?.data
                                    ? null
                                    : createPointGJ(
                                        fields.lat,
                                        fields.lon
                                    )
                            }
                        />
                    </FieldsModal>
                </InfoBlock>

                <div className="block">
                    <SelectCompany
                        selected={fields.organization}
                        error={validation.isKey('organization_id')}
                        helperText={validation.get('organization_id')}
                        onChange={(company) => setFields(old => ({
                            ...old,
                            organization: company,
                            organization_id: company.id
                        }))}
                        label="Организация"
                        filter={{ withDeleted: 1 }}
                    />
                </div>

                {fields?.address && (
                    <div className="block">
                        <TextField
                            className="block"
                            size="small"
                            variant="outlined"
                            label="Район"
                            disabled={true}
                            value={fields?.address?.area || fields?.address?.district || ''}
                        />
                    </div>
                )}
                {!relationState
                    && fields?.lat
                    && fields?.lon
                    && dataForm?.id
                    ? <RoadSectionRelationList
                        roadSectionId={dataForm?.id}
                        openClick={() => setRelationState(true)}
                    />
                    : null
                }

                {relationState
                    && <RelationModal
                        isOpen={relationState}
                        onClose={() => setRelationState(false)}
                        relationTypes={relationTypes}
                        // roadSection={dataForm}
                        roadSection={fields}
                        // dislocation={dislocationData}
                    />
                }
            </div>
        </Modal>
    );
};

export default EditorForm;
