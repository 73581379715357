import { loadDtpTypesAll } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';

const TypeValue = (props) => {

    const typesAll = useStoreFromSelectorListToObject(loadDtpTypesAll, incidentsSelectors.typesAll);

    const id = props.id || 0;

    const getValue = () => {
        const currentItem = typesAll.get(id);
        return currentItem ? currentItem.name : '';
    };

    return (
        <>
            {getValue()}
        </>
    );
};

export default TypeValue;
