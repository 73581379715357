import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import '@videojs/http-streaming';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Range } from 'react-range';
import videojs from 'video.js';
import Hls from 'hls.js';
import { saveAs } from 'file-saver';
import { Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { downloadVideo } from 'redux/VideoCameras/actions';
import { fullDateTimeWithTimeZone, getDateWithDuration, humanDateTimeWithoutTZ } from 'helpers/date.config';

import styles from './style.module.scss';

const Player = ({ link, startData, id, params, isLayoutGrid = false, setPlayback, playback, type }) => {
    const dispatch = useDispatch();
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const progressBarRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [rangeValues, setRangeValues] = useState([0, 1]);
    const [quality, setQuality] = useState('auto');
    const [rangeVisible, setRangeVisible] = useState(false);
    const [progressBarWidth, setProgressBarWidth] = useState(0);

    // const [isReversing, setIsReversing] = useState(false);

    const downloadParams = params?.find(el => el.action === 'download');

    const newParams = {
        ...params?.find(el => el.action === 'live'),
        parameters: [
            ...params?.find(el => el.action === 'live').parameters,
            {
                'name': 'speed',
                'constraint': [
                    -1,
                    1,
                    4,
                    8
                ]
            }
        ]
    };

    const speedParams = newParams.parameters?.find(el => el.name === 'speed')?.constraint;

    const formatDisplayTime = (seconds) => {
        return humanDateTimeWithoutTZ(getDateWithDuration({ seconds: seconds }, startData));
    };
    const handleDownload = (from, to) => {
        if (downloadParams) {
            dispatch(downloadVideo({ from, to, id }, (e) => {
                saveAs(e, 'my-video');
            }));
            return {
                protocol: downloadParams.parameters_available[0].protocol,
                ...downloadParams.parameters_available[0].parameters.reduce((res, el) => ({ ...res, [el]: startData }), {})
            };
        }
    };

    const renderPlaybackSelect = () => {
        if (speedParams?.length > 0) {
            const playbackSelect = document.createElement('select');
            playbackSelect.value = playback;
            playbackSelect.addEventListener('change', (e) => {
                console.log(e);
                setPlayback(e.target.value);
            });

            speedParams.forEach(el => {
                const optionTag = document.createElement('option');
                optionTag.value = el;
                optionTag.innerText = `${el}x`;
                playbackSelect.append(optionTag);
            });
            return playbackSelect;
        }

    };

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            const player = videojs(
                videoElement,
                {
                    controls: !isLayoutGrid,
                    fluid: true,
                    autoplay: true,
                    playbackRates: (speedParams?.length > 0 && type !== 'live') ? speedParams : [],
                    techOrder: ['html5'],
                    liveui: true,
                    plugins: {
                        // hlsQualitySelector: {
                        //     displayCurrentQuality: true
                        // },
                    },

                },
            );

            playerRef.current = player;

            const customizeVideoJSControls = () => {
                const controlBar = player.controlBar;
                const progressControl = controlBar.progressControl.el();
                const remainingTime = controlBar.remainingTimeDisplay.el();
                remainingTime.className = styles['vjs-time-control'];

                // Создать новый контейнер для прогресс-бара
                const progressContainer = document.createElement('div');
                progressContainer.className = styles['vjs-progress-container'];
                progressContainer.appendChild(progressControl);
                progressContainer.appendChild(remainingTime);

                // Создать контейнер для кнопок
                const controlsRow = document.createElement('div');
                controlsRow.className = `vjs-controls-row ${styles['vjs-controls-row']}`;
                const controlRowFirst = document.createElement('div');
                const controlRowSecond = document.createElement('div');
                controlRowFirst.className = styles['control-row-child'];
                controlRowSecond.className = styles['control-row-child'];

                controlsRow.appendChild(controlRowFirst);
                controlsRow.appendChild(controlRowSecond);

                // Переместить все кнопки в новый контейнер
                Array.from(controlBar.el().children).forEach(child => {
                    if (child.classList.contains('vjs-volume-panel') || child.classList.contains('vjs-play-control')) {
                        controlRowFirst.appendChild(child);
                    } else if (!child.classList.contains('vjs-progress-control') || !child.classList.contains('vjs-time-control')) {
                        controlRowSecond.appendChild(child);
                    }
                });

                // Вставить новый контейнер выше панели управления
                controlBar.el().insertBefore(progressContainer, controlBar.el().firstChild);
                controlBar.el().appendChild(controlsRow);

                progressControl.className = styles['vjs-progress-control'];

                // Применить дополнительные стили, если нужно
                controlBar.el().classList.add(styles['custom-control-bar']);
            };

            player.ready(customizeVideoJSControls);

            const updateDuration = (needChange = true) => {
                setDuration(videoElement.duration);
                if (videoElement.duration && needChange) {
                    // setRangeValues([videoElement.duration - 900, videoElement.duration]);
                }
            };

            const updateCurrentTimeDisplay = () => {
                const currentTimeDisplay = playerRef.current.controlBar.currentTimeDisplay;
                if (currentTimeDisplay) {
                    const currentTime = playerRef.current.currentTime();
                    document.querySelector('.vjs-current-time-display').innerText = formatDisplayTime(currentTime);
                }
            };

            playerRef.current.on('timeupdate', updateCurrentTimeDisplay);

            // Инициализируем начальное отображение
            updateCurrentTimeDisplay();

            const updateProgressBarWidth = () => {
                if (progressBarRef.current) {
                    setProgressBarWidth(player.controlBar.progressControl.seekBar.el_.offsetWidth);
                }
            };

            const pipElem = document.querySelector('.vjs-playback-rate');

            const renderCutButtons = () => {
                // pipElem.style.display = 'flex';
                // pipElem.style.justifyContent = 'center';
                // pipElem.innerHTML = '';
                // pipElem.append(renderPlaybackSelect());

                const rangeBtn = document.createElement('div');
                rangeBtn.style.width = 30;
                rangeBtn.style.alignSelf = 'center';
                rangeBtn.innerHTML = downloadParams ? '<i class="far fa-cut"></i>' : '';
                rangeBtn.addEventListener('click', () => setRangeVisible(prev => !prev));

                pipElem.before(rangeBtn);
            };

            type !== 'live' && renderCutButtons();

            // type === 'live' && removePlaybackAndQualityButton();

            window.addEventListener('resize', updateProgressBarWidth);

            // ОБНОВЛЕНИЕ ДЛИНЫ СЛАЙДЕРА КОГДА МЕНЯЕТСЯ ВРЕМЯ
            player.on('timeupdate', () => {
                updateDuration(false);
            });

            // player.on('ratechange', (e) => {
            //     setPlayback(player.playbackRate());
            //     if (player.playbackRate() !== 1) {
            //         player.playbackRate(1);
            //     }
            //     console.log('ratechange =', e);
            // });

            // player.controlBar.playbackRateMenuButton.on('change', () => {
            //     const selectedRate = player.playbackRate();
            //     if (selectedRate < 0) {
            //         setPlayback(selectedRate);
            //         console.log(`Отрицательная скорость ${selectedRate} выбрана - начните манипуляции`);
            //         player.playbackRate(1); // возвращаем нормальную скорость, не меняя логику выбора
            //     }
            // });

            // Обновление отображения времени
            // player.on('timeupdate', () => {
            // updateDisplayTime();
            // const currentTime = player.currentTime();
            // const formattedTime = fullDateTime(getDateWithDuration({ seconds: currentTime }, startData));
            // // console.log(formattedTime);

            // document.querySelector('.vjs-play-progress .vjs-time-tooltip').innerText = formattedTime;
            // });

            if (Hls.isSupported() && type === 'live') {
                const hls = new Hls();
                hls.loadSource(link);
                hls.attachMedia(videoElement);

                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    updateProgressBarWidth();
                    updateDuration();
                });

                player.on('loadedmetadata', () => {
                    updateProgressBarWidth();
                    updateDuration();
                    // updateDisplayTime();
                });
            } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
                videoElement.src = link;
                videoElement.addEventListener('loadedmetadata', () => {
                    updateDuration();
                });
            }

            // // cho to tam
            // let reverseInterval;

            // // Функция для запуска обратного воспроизведения
            // const startReversePlayback = () => {
            //     clearInterval(reverseInterval);
            //     reverseInterval = setInterval(() => {
            //         if (player.currentTime() <= 0) {
            //             clearInterval(reverseInterval);
            //             return;
            //         }
            //         player.currentTime(player.currentTime() - 0.1); // Перематываем назад по 0.1 сек
            //     }, 100);
            // };

            // // Функция для остановки обратного воспроизведения
            // const stopReversePlayback = () => {
            //     clearInterval(reverseInterval);
            // };

            // // Управляем обратным воспроизведением при изменении флага
            // if (isReversing) {
            //     startReversePlayback();
            // } else {
            //     stopReversePlayback();
            // }

            return () => {
                // clearInterval(reverseInterval);
                if (playerRef.current) {
                    playerRef.current.dispose();
                }
                window.removeEventListener('resize', updateProgressBarWidth);

            };
        }
    }, [link, setPlayback]);


    const handleDownloadSegment = () => {
        const start = fullDateTimeWithTimeZone(getDateWithDuration({ seconds: rangeValues[0] }, startData));
        const end = fullDateTimeWithTimeZone(getDateWithDuration({ seconds: rangeValues[1] }, startData));

        handleDownload(start, end);

        alert(`Download segment from ${start} to ${end} seconds.`);
    };

    const handleQualityChange = (event) => {
        const selectedQuality = event.target.value;
        if (selectedQuality === 'auto') {
            setQuality('auto');
        } else {
            setQuality(parseInt(selectedQuality));
        }
    };

    const handleSliderChange = (values) => {
        setRangeValues(values);
        if (playerRef.current) {
            playerRef.current.currentTime(values[0]);
        }
    };

    return (
        <div>
            {isLayoutGrid
                ? <video width={250} ref={videoRef} id="video-js" className="video-js" preload="auto" />
                : <div data-vjs-player>
                    <video width={700} ref={videoRef} id="video-js" className="video-js" preload="auto" />
                    {(duration > 0 && rangeVisible)
                        && <div
                            ref={progressBarRef}
                            style={{
                                position: 'absolute',
                                bottom: 42,
                                left: 20,
                                width: '95%',
                                minWidth: 600
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignSelf: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    alignItems: 'center',
                                }}
                            >
                                <Range
                                    step={1}
                                    min={0}
                                    max={duration}
                                    values={rangeValues}
                                    onChange={handleSliderChange}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            onMouseDown={props.onMouseDown}
                                            onTouchStart={props.onTouchStart}
                                            style={{
                                                ...props.style,
                                                height: '6px',
                                                background: '#67cfffa6',
                                                display: 'flex',
                                                width: progressBarWidth ? `${progressBarWidth}px` : '93%', // Устанавливаем ширину трека
                                            }}
                                        >
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: '5px',
                                                    width: '100%',
                                                    borderRadius: '4px',
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            key={props.key}
                                            style={{
                                                ...props.style,
                                                height: '16px',
                                                width: '5px',
                                                background: '#008000',
                                                display: 'inline-block',
                                                borderRadius: '4px',
                                                backgroundColor: '#FFF',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                boxShadow: '0px 2px 6px #AAA'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '-40px',
                                                    left: props.key === 0 ? -82 : 2,
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                                                    padding: '4px',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#548BF4'
                                                }}
                                            >
                                                {humanDateTimeWithoutTZ(getDateWithDuration({ seconds: rangeValues[props.key] }, startData))}
                                            </div>
                                        </div>
                                    )}
                                />
                                <Tooltip title="Скачать фрагмент">
                                    <DownloadIcon onClick={handleDownloadSegment} />
                                </Tooltip>
                            </div>
                        </div>
                    }
                </div>
            }
            {/* <div>
                <label htmlFor="quality">Quality:</label>
                <select id="quality" value={quality} onChange={handleQualityChange}>
                    <option value="auto">Auto</option>
                    <option value="360">360p</option>
                    <option value="720">720p</option>
                    <option value="1080">1080p</option>
                </select>
            </div> */}
        </div>
    );
};
export default Player;
