import { useMemo } from 'react';
import { Bar as GBar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// линейный график
const Bar = ({
    data = [],
    legendPosition = 'bottom',
    horizontal = false,
}) => {

    // useEffect(() => {
    //     Chart.register(plugin);
    // }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: legendPosition,
            },
            datalabels: {
                color: function(context) {
                    return context.dataset.backgroundColor;
                    // return context.active ? 'white' : context.dataset.backgroundColor;
                },
                display: function(context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    size: '16',
                    weight: 'bold'
                },
                // formatter: Math.round,
                align: 'start',
                anchor: 'end',
                offset: -24,
                // clamp: true
            },
            // title: {
            //     display: true, text: 'Chart.js Line Chart',
            // },
        },
        layout: {
            padding: {
                top: 20
            }
        },
        // scales: {
        //     yAxis: {
        //         min: 0,
        //         max: 10,
        //     },
        // }
    };

    if (horizontal) {
        options.indexAxis = 'y';
    }

    const dataG = useMemo(() => {
        const labels = data[0]?.items?.map(({ label = '' }) => label) || [];

        const datasets = data.map(({ items = [], color, name }) => {
            return {
                label: name,
                // borderColor: color,
                backgroundColor: color,
                data: items.map(({ value }) => value),
                maxBarThickness: 80
            };
        });

        return {
            labels,
            datasets
        };
    }, [data]);

    return <GBar
        options={options}
        data={dataG}
        plugins={[ChartDataLabels]}
        style={{ maxHeight: '300px' }}
    />;
};

export default Bar;
