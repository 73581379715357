import { useEffect, useState } from 'react';
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import titles from 'helpers/constants/titles';
import { LSContainer } from 'components/common/List';
import type { OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import Item from '../RenderOperationList/Item';
import { initialRow } from '../AddFormModal';
import AddFormModal from '../AddFormModal/AddNewOperationForm';
import { recalculateRows } from '../helpers';

import SortableItem from './SortableItem';

import type { DragEndEvent } from '@dnd-kit/core/dist/types';

interface EditOperationListProps {
    list: OperationInScenario[];
    setData?: (operations: OperationInScenario[]) => void;
    scenarioId?: number;
}

const EditOperationList = ({
    list: data = [],
    setData = () => {},
    scenarioId
}: EditOperationListProps) => {
    const [isOpenAddForm, setIsOpenAddForm] = useState(false);
    const [addRowIndex, setAddRowIndex] = useState<number>(0);

    const sensors = useSensors(
        useSensor(PointerSensor)
    );

    useEffect(() => {
        if (data.length === 0) {
            // если нет операций то открываем модалку добавления
            setIsOpenAddForm(true);
        }
    }, [data.length]);

    const addRows = (newRows: OperationInScenario[], placeOfAdding: string = 'up', index: number) => {
        // newRows - массив т.к. мы можем вставить сразу несколько операций из другого сценария
        let updatedRows: OperationInScenario[] = [];

        const isGroup = data[index]?.group;
        let insertIndex = placeOfAdding === 'up' ? index : index + 1;

        if (isGroup && placeOfAdding === 'down') {
            // Ищем последний индекс группы, чтобы добавить после неё
            let lastGroupIndex = index;
            while (
                lastGroupIndex + 1 < data.length
                && data[lastGroupIndex + 1].group?.startsWith(isGroup)
            ) {
                lastGroupIndex += 1;
            }
            insertIndex = lastGroupIndex + 1;
        }

        updatedRows = [
            ...data.slice(0, insertIndex),
            ...newRows,
            ...data.slice(insertIndex),
        ];


        setData(recalculateRows(updatedRows));
        setAddRowIndex(0);
    };

    // Добавление новой строки
    const handleAddRow = (index: number, row: OperationInScenario) => {
        setAddRowIndex(index);
        row.group?.endsWith('.1') || row.group?.endsWith('.2')
            ? addRows([{ ...initialRow, group: row.group }], 'down', index)
            : setIsOpenAddForm(true);
    };

    // Редактирование строки
    const handleEditRow = (index: number, newRow: OperationInScenario) => {
        const updatedRows = [
            ...data.slice(0, index),
            newRow,
            ...data.slice(index + 1),
        ];

        setData(recalculateRows(updatedRows));
    };

    // // Удаление строки
    const handleDeleteRow = (row: OperationInScenario, index: number) => {
        let lastIndex = index;
        if (row.group && row.group.indexOf('.') === -1) {
            // если мы удаляем условную операцию на уровне самого условия мы должны удалить и все его ветки true/false
            lastIndex = data.findLastIndex(item =>  item.group?.startsWith(`${row.group}.`));
        }

        const updatedRows = [
            ...data.slice(0, index),
            ...data.slice(lastIndex+1)
        ];

        setData(recalculateRows(updatedRows));
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            // Находим active и over элементы и их индексы
            const { activeItem, activeIndex, overItem, overIndex } = data.reduce((res, item, index) => {
                if (item.position === active.id) {
                    res.activeItem = item;
                    res.activeIndex = index;
                }
                if (item.position === over.id) {
                    res.overItem = item;
                    res.overIndex = index;
                }
                return res;
            }, { activeItem: null, activeIndex: -1, overItem: null, overIndex: -1 } as any);

            if (!activeItem || !overItem || overItem.group || overItem?.is_done) return;

            const isParent = activeItem.group && !activeItem.group.includes('.');

            if (isParent) {
                // Находим все подгруппы для родительского элемента
                const activeGroupItems = data.filter(item =>
                    item.group === activeItem.group || item.group?.startsWith(`${activeItem.group}.`)
                );

                // Создаем новый массив без группы
                const remainingData = data.filter(item => !activeGroupItems.includes(item));

                // Находим правильный индекс вставки, чтобы учесть группы при перемещении вниз
                let remOverIndex = remainingData.findIndex(item => item.position === over.id);
                

                if (overIndex > activeIndex) {
                    // Если перетаскиваем вниз и целевой элемент принадлежит группе, пропускаем его группу
                    const overGroup = overItem.group;
                    if (overGroup) {
                        while (
                            remOverIndex + 1 < remainingData.length
                            && remainingData[remOverIndex + 1].group?.startsWith(overGroup)
                        ) {
                            remOverIndex += 1;
                        }
                    }
                    remOverIndex += 1;
                }

                const newData = [
                    ...remainingData.slice(0, remOverIndex),
                    ...activeGroupItems,
                    ...remainingData.slice(remOverIndex),
                ];

                setData(recalculateRows(newData));
            } else {
                setData(recalculateRows(arrayMove(data, activeIndex, overIndex)));
            }
        }
    };

    return <>
        {data?.length > 0 
            ? <LSContainer
                headers={[
                    { title: <span>№</span>, width: '4%', align: 'center' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >{titles.NAME}</span>, width: '16%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >{titles.ORGANIZATION}</span>, width: '15%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Цикл</span>, width: '6%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Параллельное <br /> исполнение</span>, width: '12%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Время по регламенту (мин)</span>, width: '10%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Выполнять автомат.<br /> после регламента</span>, width: '14%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Передать в организацию автомат.</span>, width: '14%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Типовая</span>, width: '6%' },
                ]}
            >
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={data.map(item => item.position as number)} strategy={verticalListSortingStrategy}>
                        {data.map((row, index, arr) => (
                            <SortableItem key={row.id} item={row} index={index}>
                                <Item
                                    row={row}
                                    isEditable={!row?.is_done}
                                    readOnly={row?.is_done}
                                    onAdd={() =>  handleAddRow(index, row)}
                                    onEdit={(newRow) => handleEditRow(index, newRow)}
                                    onDelete={() => handleDeleteRow(row, index)}
                                    disableDelete={
                                        (row.group?.endsWith('.1') || row.group?.endsWith('.2'))
                                        && !((arr[index - 1]?.group === row.group) || (arr[index + 1]?.group === row.group))
                                    }
                                />

                            </SortableItem>
                        ))}
                    </SortableContext>
                </DndContext>
            </LSContainer>
            : <InformPanel
                buttons={
                    <FormButtons
                        noPadding
                        justButton
                        buttons={[{
                            ...buttonsTypes.add,
                            onClick: () => setIsOpenAddForm(true),
                        }]
                        }
                    />
                }
            />
        }
        {isOpenAddForm && (
            <AddFormModal
                isOpen={isOpenAddForm}
                onClose={() => setIsOpenAddForm(false)}
                onAdd={(newRows, placeOfAdding) => addRows(newRows, placeOfAdding, addRowIndex)}
                isNew={data.length === 0}
                maxGroupNum={data.reduce((max, el: OperationInScenario) => Math.max(max, +Number(el.group).toFixed() || 0), 0)}
                scenarioId={scenarioId}
            />
        )}
    </>;
};

export default EditOperationList;
