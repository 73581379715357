import { useEffect, useMemo, useState } from 'react';
import { TextField } from '@mui/material';

import { loadComplexTypes } from 'redux/DorisControl/actions';
import { loadRegions } from 'redux/Dadata/actions';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreProp } from 'helpers/hooks';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import SelectPddViolation from 'components/common/Autocomplete/DorisControl/PddViolation';
import SimpleSelect from 'components/common/SimpleSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import SelectTags from 'components/common/Autocomplete/DorisControl/Tags';
import GovernmentContractList from 'components/common/Autocomplete/GovernmentContract';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { config } from 'config';

const initState = {
    name: '',
    ip_list: [],
    status_list: [],
    serial_number_list: [],
    pdd_violation_id_list: [],
    address: '',
    tag_id_list: [],
    uuid_list: [],
    contract_id_list: [],
    serial_number_tmp: '',  // сохранение введенного в Серийный номер,
    // должно записаться в serial_number только по клику на Найти + даём сохранять на бэк
    id_list: [],
    type_id_list: [],
    area_list: [],
    district_list: [],
};

export const FilterVFComplex = ({
    setParams,
    statuses,
    test_id_prefix = '',
    isSidebar = false,
    sidebarFiler = {}, // чтобы использовать единый компонент для карты и списка
}) => {
    const project = config.project;
    const regionsAll = useStoreProp(loadRegions, 'dadata', 'regions');
    const complexTypes = useStoreProp(loadComplexTypes, 'dorisControl', 'doris_complex_types');

    const [data, setData] = useState({ ...initState, ...sidebarFiler });
    const [area, setArea] = useState([]);
    const [district, setDistrict] = useState([]);

    const prepareData = (filter = data) => removeEmptyFields({
        ...filter,
        status_list: filter.status_list?.map(item => item.id),
        tag_id_list: filter.tag_id_list?.map(item => item.id),
        pdd_violation_id_list: filter.pdd_violation_id_list?.map(item => item.id),
        contract_id_list: filter.contract_id_list?.map(item => item.id),
        id_list: filter.id_list?.map(item => item.id),
        type_id_list: filter.type_id_list?.map(item => item.id),
        ...(isSidebar ? { ...convertSerial(filter) } : {})
    }, true);

    useEffect(() => {
        if (isSidebar) {
            setData({ ...initState, ...sidebarFiler });
            if (sidebarFiler.area_list?.length > 0) {
                const areas = sidebarFiler.area_list.map((el) => {
                    const elFull = regionsAll.areas.find(item => item.name === el) || { name: el };
                    return elFull;
                });
                setArea(areas);
            } else {
                setArea([]);
            }
            if (sidebarFiler.district_list?.length > 0) {
                const districts = sidebarFiler.district_list.map((el) => {
                    const elFull = regionsAll.cities.find(item => item.name === el) || { name: el };
                    return elFull;
                });
                setDistrict(districts);
            } else {
                setDistrict([]);
            }
        }
    }, [sidebarFiler, isSidebar, regionsAll]);

    const cities = useMemo(() => {
        // комментирую код т.к. сейчас все parent_id null
        // if (Array.isArray(regionsAll.cities) && area.length > 0) {
        //     return regionsAll.cities.filter(city => !!area.find(el => el.id === city.parent_id));
        // }

        return regionsAll.cities;
    }, [area, regionsAll]);

    const onChange = (name, value) => {
        const newData = {
            ...data,
            [name]: value,
            ...((name === 'serial_number_list' && value.length === 0) && { serial_number_tmp: '' })
        };

        setData(newData);
        if (isSidebar) {
            setParams(newData);
        }
    };

    const handleChangeArea = (value) => {
        setArea(value);
        onChange('area_list', value.map(el => el.name));
    };

    const handleChangeDistrict = (value) => {
        setDistrict(value);
        onChange('district_list', value.map(el => el.name));
    };

    const convertSerial = (filters) => {
        const serialAlreadyInArray = filters.serial_number_list.find(item => item === filters.serial_number_tmp);
        return {
            serial_number_list: filters.serial_number_tmp.length > 0 && !serialAlreadyInArray
                ? [...filters.serial_number_list, filters.serial_number_tmp]
                : filters.serial_number_list,
            serial_number_tmp: ''
        };
    };

    const onSearch = (filters = data) => {
        // const serialAlreadyInArray = filters.serial_number_list.find(item => item === filters.serial_number_tmp);
        // const expandSerial = {
        //     serial_number_list: filters.serial_number_tmp.length > 0 && !serialAlreadyInArray
        //         ? [...filters.serial_number_list, filters.serial_number_tmp]
        //         : filters.serial_number_list,
        //     serial_number_tmp: ''
        // };
        const expandSerial = convertSerial(filters);
        const filter = {
            ...filters,
            ...expandSerial,
        };
        if (filters.serial_number_tmp.length > 0) {
            setData(filter);
        }
        setParams(prepareData(filter));
    };

    const resetFilter = (needRefresh) => { //  аргумент needRefresh приходит из LayoutFilter
        setData(initState); // обновляем локальный стейт
        setArea([]);
        if (needRefresh) { // если надо перезапросить список
            setParams(prepareData(initState)); // отправляем в родителя, чтобы произошел запрос
        }
    };

    const updateFilter = (filters) => { // фильтры с бэка
        setData({ // обновляем локальный стейт
            ...initState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {  // если фильтры не пустые
            onSearch({ // делаем запрос
                ...initState, // начальное значение + пришедшие фильтры
                ...filters,
            });
        }
    };

    const renderVisible = () => (<>
        <TextField
            value={data?.name}
            onChange={(e) => onChange(e.target.name, e.target.value)}
            label={titles.NAME}
            variant="outlined"
            size="small"
            name="name"
            type="text"
            InputProps={{
                'data-testid': `${test_id_prefix}:name/input`,
            }}
            className={isSidebar ? 'block' : ''}
        />
        <CommonAutocomplete
            multiple
            selected={data.status_list}
            onChange={(value) => onChange('status_list', value)}
            onReset={() => onChange('status_list', initState.status_list)}
            inputName={titles.STATUS}
            limitTags={1}
            label={titles.STATUS}
            options={statuses}
            filterSelectedOptions={true}
            renderLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            showAsSelect
            test_id_prefix={test_id_prefix}
            className={isSidebar ? 'block' : ''}
        />
        <GovernmentContractList
            label="Номер ГК"
            multiple
            selected={data.contract_id_list || []}
            onChange={(value) => onChange('contract_id_list', value)}
            renderLabel={item => item?.name || ''}
            additionalItem={{ name: 'Нет ГК', id: null }}
            test_id_prefix={test_id_prefix}
            className={isSidebar ? 'block' : ''}
        />
    </>
    );

    const renederInvisible = () => (
        <>
            {!isSidebar
                && <MultipleInput
                    value={data.uuid_list}
                    onChange={(e) => onChange('uuid_list', e)}
                    label="UUID"
                    variant="outlined"
                    name="uuid_list"
                    size="small"
                    type="text"
                    testIdPrefix={test_id_prefix}
                    testIdName="uuid"
                    className={isSidebar ? 'block' : ''}
                />
            }
            {project?.code !=='34_vlg_vlz'
                && <FormInfoWrapper
                    helperText={'После ввода серийного номера поставьте запятую, чтобы добавить.'}
                    className={isSidebar ? 'block' : ''}
                >
                    <MultipleInput
                        label={titles.SERIAL_NUMBER}
                        value={data.serial_number_list}
                        onChange={(value) => onChange('serial_number_list', value)}
                        blurSelect={false}
                        needClearInput={data.serial_number_tmp?.length === 0}
                        testIdPrefix={test_id_prefix}
                        testIdName="serial_number"
                    />
                </FormInfoWrapper>
            }
            <TextField
                value={data?.ip_list}
                onChange={(e) => onChange(e.target.name, [e.target.value])}
                label={titles.IP_ADDRESS}
                variant="outlined"
                size="small"
                name="ip_list"
                type="text"
                InputProps={{
                    'data-testid': `${test_id_prefix}:ip_route/input`,
                }}
                className={isSidebar ? 'block' : ''}
            />

            {!isSidebar
                && <SelectPddViolation
                    multiple
                    selected={data.pdd_violation_id_list}
                    onChange={(value) => onChange('pdd_violation_id_list', value)}
                    test_id_prefix={test_id_prefix}
                    className={isSidebar ? 'block' : ''}
                />
            }

            <TextField
                value={data.address}
                onChange={(e) => onChange(e.target.name, e.target.value)}
                label="Адрес"
                size="small"
                name="address"
                type="text"
                InputProps={{
                    'data-testid': `${test_id_prefix}:address/input`,
                }}
                className={isSidebar ? 'block' : ''}
            />
            {!isSidebar
                && <SelectTags
                    multiple
                    selected={data.tag_id_list}
                    onChange={(val) => onChange('tag_id_list', val)}
                    validation={false}
                    required={false}
                    disabled={false}
                    label="Тег"
                    test_id_prefix={test_id_prefix}
                    className={isSidebar ? 'block' : ''}
                />
            }

            {Array.isArray(regionsAll.areas) && regionsAll.areas.length > 0
                && <SimpleSelect
                    multiple
                    label="Район области"
                    selected={area}
                    onChange={handleChangeArea}
                    options={Array.isArray(regionsAll.areas) ? regionsAll.areas : []}
                    renderLabel={(options) => options?.name || ''}
                    test_id_prefix={test_id_prefix}
                    className={isSidebar ? 'block' : ''}
                />
            }
            {Array.isArray(cities) && cities.length > 0
                && <SimpleSelect
                    multiple
                    label="Район города"
                    selected={district}
                    onChange={handleChangeDistrict}
                    options={cities}
                    renderLabel={(options) => options?.name || ''}
                    test_id_prefix={test_id_prefix}
                    // className={isSidebar ? 'block' : ''}
                />
            }

            <SelectComplex
                multiple={true}
                selected={data.id_list}
                onChange={(value) => onChange('id_list', value)}
                test_id_prefix={test_id_prefix}
                className={isSidebar ? 'block' : ''}
                renderPropsInList={(el) => (<div>
                    Название: {el.name},<br/>
                    {project?.code ==='34_vlg_vlz' ? `${titles.INVENTORY_NUMBER}: ${el.inventory_number}` : `${titles.SERIAL_NUMBER}: ${el.serial_number}`},<br/>
                    Адрес: {el.address_text}
                </div>)}
                renderLabel={project?.code ==='34_vlg_vlz'
                    ? (option) => option?.inventory_number || ''
                    : (option) => option?.serial_number || ''
                }
            />

            <CommonAutocomplete
                multiple={true}
                selected={data.type_id_list}
                onChange={(value) => onChange('type_id_list', value)}
                onReset={() => onChange('type_id_list', initState.type_id_list)}
                inputName={titles.COMPLEX_TYPE}
                limitTags={1}
                label={titles.COMPLEX_TYPE}
                options={complexTypes}
                filterSelectedOptions={true}
                renderLabel={(option) => option.name}
                showAsSelect
                test_id_prefix={test_id_prefix}
            />
        </>
    );

    return (<>
        {
            isSidebar
                ? <>
                    {renderVisible()}
                    {renederInvisible()}
                </>
                :  <LayoutFilter
                    onResetFilter={resetFilter}
                    onSearch={onSearch}
                    filter={data}
                    setUserFilter={updateFilter}
                    test_id_prefix={test_id_prefix}
                >
                    <LayoutFilter.Visible>
                        {renderVisible()}
                    </LayoutFilter.Visible>
                    <LayoutFilter.Invisible>
                        {renederInvisible()}
                    </LayoutFilter.Invisible>
                </LayoutFilter>
        }
    </>

    );
};
