import * as types from 'modules/HeatMapCongestions/redux/types';

export const loadCongestionTypes = () => ({
    type: types.LOAD_CONGESTION_TYPES,
});

export const loadedCongestionTypes = (data) => ({
    type: types.LOADED_CONGESTION_TYPES,
    payload: data,
});

// тепловая карта скоплений
export const loadCongestionsHeatMap = (filter = {}) => ({
    type: types.LOAD_CONGESTIONS_HEAT_MAP,
    payload: filter,
});

export const loadingCongestionsHeatMap = (bool = false) => ({
    type: types.LOADING_CONGESTIONS_HEAT_MAP,
    payload: bool,
});

export const loadedCongestionsHeatMap = (data) => ({
    type: types.LOADED_CONGESTIONS_HEAT_MAP,
    payload: data,
});

export const clearCongestionsHeatMap = () => ({
    type: types.LOADED_CONGESTIONS_HEAT_MAP,
    payload: [],
});

export const loadCongestionsHeatMapSummary = (uuid_list, fnCallback, fnLoading) => ({
    type: types.LOAD_CONGESTIONS_HEAT_MAP_SUMMARY,
    payload: {
        uuid_list,
    },
    fnCallback,
    fnLoading
});


export const loadCongestionsPolygon = (filter) => ({
    type: types.LOAD_CONGESTION_POLYGON,
    payload: filter,
});

export const loadedCongestionsPolygon = (data) => ({
    type: types.LOADED_CONGESTION_POLYGON,
    payload: data,
});

export const clearCongestionsPolygon = () => ({
    type: types.LOADED_CONGESTION_POLYGON,
    payload: [],
});

export const loadCongestionsSidebar = (filter, fnCallback, fnLoading) => ({
    type: types.LOAD_CONGESTION_SIDEBAR,
    payload: filter,
    fnCallback,
    fnLoading
});

export const setNgptFilter = (filter = {}) => ({
    type: types.SET_NGPT_FILTER,
    payload: filter,
});

export const setNgptActive = (active = {}) => ({
    type: types.SET_NGPT_ACTIVE,
    payload: active,
});

export const setNgptSaved = (bool = false) => ({
    type: types.SET_NGPT_SAVED,
    payload: bool,
});

export const сongestionMarkRegular = (uuid, fnCallback, fnLoading) => ({
    type: types.CONGESTION_MARK_REGULAR,
    payload: uuid,
    fnCallback,
    fnLoading
});
