import React, { useState } from 'react';

import removeEmptyFields from 'helpers/removeEmptyFields';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectMicroservices from 'components/common/Autocomplete/Microservices';
import SelectMethods from 'components/common/Autocomplete/Methods';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf,
} from 'helpers/date.config';
import type { Method, Service } from 'components/pages/Admin/AppLogs/types';

interface FilterProps {
    setParams: React.Dispatch<
        React.SetStateAction<{
            page: number;
            data: { start_date: string | null; end_date: string | null };
        }>
    >;
}

const Filter = ({ setParams }: FilterProps) => {
    const initState = {
        method_list: [] as Method[],
        service_list: [] as Service[],
        code_list: [],
        start_date: getStartOf('day', getDateWithDuration({ weeks: -1 })),
        end_date: getEndOf(),
    };

    const [filterData, setFilterData] = useState(initState);

    const prepareData = (filter = filterData) => {
        return removeEmptyFields({
            ...filter,
            start_date: fullDateTimeWithTimeZone(filter.start_date),
            end_date: fullDateTimeWithTimeZone(filter.end_date),
            code_list: filter.code_list.map(Number),
            method_list: filter.method_list.map((item) => item.key),
            service_list: filter.service_list.map((item) => item.key),
        }) as { start_date: string; end_date: string };
    };

    const handleChange = (
        name: keyof typeof initState,
        value: (typeof initState)[keyof typeof initState]
    ) => {
        setFilterData({ ...filterData, [name]: value });
    };

    const handleSearch = () => {
        setParams({ page: 1, data: prepareData() });
    };

    const resetFilter = (needRefresh: boolean) => {
        setFilterData(initState);

        if (needRefresh) {
            setParams({
                page: 1,
                data: {
                    start_date: fullDateTimeWithTimeZone(
                        getStartOf('day', getDateWithDuration({ weeks: -1 }))
                    ),
                    end_date: fullDateTimeWithTimeZone(getEndOf()),
                },
            });
        }
    };

    const updateFilter = (filters: { [key: string]: any }) => {
        setFilterData({ ...initState, ...filters });

        if (Object.keys(filters).length) {
            setParams({
                page: 1,
                data: prepareData({ ...initState, ...filters }),
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={handleSearch}
            filter={filterData}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterData.start_date}
                    valueEndDate={filterData.end_date}
                    handleDate={(value) => handleChange('start_date', value)}
                    handleEndDate={(value) => handleChange('end_date', value)}
                />

                <SelectMicroservices
                    multiple={true}
                    selected={filterData.service_list}
                    onChange={(value) => handleChange('service_list', value)}
                />

                <SelectMethods
                    multiple={true}
                    selected={filterData.method_list}
                    onChange={(value) => handleChange('method_list', value)}
                />
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <MultipleInput
                    value={filterData.code_list}
                    onChange={(value) => handleChange('code_list', value)}
                    type="number"
                    label="Коды запроса"
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
