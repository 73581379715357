import { Button } from '@mui/material';

import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import CircleStatus from 'components/common/CircleStatus';
import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import { reactSelectors } from 'modules/React';
import { loadIncidentCategories, loadIncidentsStatuses } from 'modules/React/redux/actions';
import renderAddress from 'helpers/renderAddress';

import type { IncidentItem as IncidentItemType } from './types';

type IncidentItemProps = {
  item: IncidentItemType;
  onClickItem: () => void;
  selectedId: number | null;
  setOpenDtpTab: any;
  reloadList: (isDelete: boolean) => void;
}

export const IncidentItem = ({
    item,
    onClickItem,
    selectedId,
    setOpenDtpTab
}: IncidentItemProps) => {
    const statuses = useStoreFromSelectorListToObject(loadIncidentsStatuses,reactSelectors.incidentsStatuses);
    const incidentCategories = useStoreFromSelectorListToObject(loadIncidentCategories, reactSelectors.incidentCategories);

    const renderThreatLevel = () => item?.threat_level?.color
        ? <span style={{ color: item?.threat_level.color }}>{ item?.threat_level?.name }</span>
        : item?.threat_level?.name;

    return (
        <LSContentItem
            onClick={onClickItem}
            isMobile={!!selectedId}
            needFocus={selectedId === item?.id}
        >
            <LSContentColumn>
                <CircleStatus
                    title={statuses.get(item?.status_id).name}
                    color={statuses.get(item?.status_id).color}
                />
            </LSContentColumn>
            <LSContentColumn>
                {item.id || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item.name || messages.NO_VALUE}
            </LSContentColumn>
            {!selectedId && [
                <LSContentColumn>
                    {fullDateTimeWithoutSeconds(item.created_at) || messages.NO_VALUE}
                </LSContentColumn>,
                <LSContentColumn>
                    {fullDateTimeWithoutSeconds(item.date_plan) || messages.NO_VALUE}
                </LSContentColumn>,
                <LSContentColumn>
                    {fullDateTimeWithoutSeconds(item.date_fact) || messages.NO_VALUE}
                </LSContentColumn>,
                <LSContentColumn>
                    {item?.dtp_id ? (
                        <Button
                            onClick={(e) => {
                                // e.stopPropagation();
                                setOpenDtpTab('dtp');
                            }}
                            size="small"
                            variant="text"
                            sx={{
                                textDecoration: 'underline',
                                minWidth: '10px',
                                display: 'inline-block',
                                fontSize: '1rem',
                                padding: '0 4px',
                            }}
                        >
                            {item?.dtp_id}
                        </Button>
                    ) : (
                        messages.NO_VALUE
                    )}
                </LSContentColumn>,
                <LSContentColumn>
                    {item.address_text || renderAddress(item.address || {}) || messages.NO_VALUE}
                </LSContentColumn>,
                <LSContentColumn>
                    {incidentCategories.get(item?.category_id)?.name || messages.NO_VALUE}
                </LSContentColumn>,
                <LSContentColumn>
                    {item?.type_text || messages.NO_VALUE}
                </LSContentColumn>,
                <LSContentColumn>
                    {renderThreatLevel() || messages.NO_VALUE}
                </LSContentColumn>,
            ]}
            <LSContentColumn isActions>
                <ActionMore
                    isPassport
                    isOpen={selectedId === item.id}
                    // onClick={onClickItem}
                />
            </LSContentColumn>
        </LSContentItem>
    );
};
