import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEqual } from 'lodash';

import * as actions from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import { usePrevious, useStoreFromSelector } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import Loading from 'components/common/Loading';
import CircleStatus from 'components/common/CircleStatus';
import MenuItemInfo from 'components/layout/Menu/MenuItemInfo';

import Icon from '../icons/icon';
import config from '../config';

import Item from './Item';
import FilterForm from './Filter';
import CheckBox from './CheckBox';


const SideBar = (props) => {
    const dispatch = useDispatch();

    const complexStatuses = useStoreFromSelector(actions.loadComplexStatuses, dorisControlSelectors.doris_complex_statuses);
    const complexStatusesObj = useMemo(() => complexStatuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [complexStatuses]);

    const polygonLoading = useSelector(dorisControlSelectors.polygonLoading);
    const active = useSelector(dorisControlSelectors.active);
    const filters = useSelector(dorisControlSelectors.filters);
    const loadingCoordinationHistory = useSelector(dorisControlSelectors.loadingCoordinationHistory);
    const coordinationHistoryDates = useSelector(dorisControlSelectors.coordinationHistoryDates);
    const list = useSelector(dorisControlSelectors.sidebar);
    const loading = useSelector(dorisControlSelectors.sidebarLoading);
    const sidebarParams = useSelector(dorisControlSelectors.sidebarParams);

    useEffect(() => {
        return () => {
            dispatch(actions.setSidebarParamsDC({
                ...sidebarParams,
                page: 1,
            }));
        };
    }, []);

    const scrollRef = useRef(null);
    const prevFilters = usePrevious(filters);

    const scroolTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const activeId = active?.id || 0;

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = list.meta;
        if (
            values.top > 0.88
            && loading === false
            && sidebarParams.page < last_page
        ) {
            dispatch(actions.setSidebarParamsDC({
                ...sidebarParams,
                page: sidebarParams.page + 1
            }));
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            dispatch(actions.setSidebarParamsDC({
                ...sidebarParams,
                page: 1
            }));
            scroolTop();
        } else {
            const filter = getFilters(filters,null,true);
            dispatch(actions.loadSidebar(
                sidebarParams.page,
                sidebarParams.limit,
                filter
            ));
        }
    }, [sidebarParams, filters, dispatch]);

    useEffect(() => {
        dispatch(actions.loadComplexStatuses());
        return () => {
            dispatch(actions.clearSidebar());
        };
    }, []);

    // useEffect(() => {
    //     if (saved) {
    //         fetchList(1);
    //     }
    // }, [saved]);

    // useEffect(() => {
    //     fetchList(1);
    // }, []);

    const [historyId, setHistoryId] = useState(0);

    const onChangeDateHistory = ({ date, item }) => {
        const { id, lat, lon } = item;
        if (date) {
            dispatch(actions.setActive({}));
            setHistoryId(id);
            dispatch(actions.setCoordinationHistoryDates({
                [id]: date,
            }));
            // const lat_ = parseFloat(lat);
            // const lon_ = parseFloat(lon);
            // const polygon = {
            //     type: 'Feature',
            //     geometry: {
            //         type: 'Polygon',
            //         coordinates: [
            //             { lat: lat_ + 0.01, lon: lon_ - 0.01 },
            //             { lat: lat_ + 0.01, lon: lon_ + 0.01 },
            //             { lat: lat_ - 0.01, lon: lon_ + 0.01 },
            //             { lat: lat_ - 0.01, lon: lon_ - 0.01 },
            //         ]
            //     }
            // };
            // dispatch(actions.getForPolygon(polygon));
            dispatch(actions.loadCoordinationHistory([id], fullDateTimeWithTimeZone(date)));
        } else {
            setHistoryId(0);
            dispatch(actions.setCoordinationHistoryDates({}));
            dispatch(actions.loadedCoordinationHistory([]));
        }
    };

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                const notValid = item?.is_not_valid_verification;
                const statusObj = complexStatusesObj[item.status] || {};

                return (
                    <SidebarItem
                        key={item.id}
                        active={activeId === item.id}
                        sideComponent={<div style={{ padding: 3 }}>
                            <CircleStatus
                                title={statusObj?.name || ''}
                                color={colorExtend(statusObj?.color || '')}
                            />
                        </div>}
                        // icon={
                        //     notValid
                        //         ? `<img src="${noValidity}" style="max-width: 100%;height: auto;"/>`
                        //         : <CircleStatus title={complexStatuses[item.status]} color={getColorByStatus(item.status)}/>
                        // }
                        icon={
                            notValid === null
                                ? '<i class="far fa-file-certificate" style="color: #aaaaaa;"/>'
                                : notValid
                                    ? ' <i class="fad fa-file-certificate" style="--fa-primary-color: #d32f2f; --fa-secondary-color: #aaaaaa;" />'
                                    : Icon({ fill: statusObj?.color || '' })
                        }
                        buttons={
                            item?.external_id
                                ? {}
                                : {
                                    edit: {
                                        title: buttons.EDIT,
                                        icon: <i className="fas fa-pen" />,
                                        onClick: () => {
                                            // редактирование
                                            dispatch(actions.setEditForm(item));
                                        },
                                    },
                                    delete: {
                                        title: buttons.DELETE,
                                        icon: <i className="fas fa-trash-alt" />,
                                        onClick: () => {
                                            // удаление
                                            dispatch(actions.setDeleteForm(item.id));
                                        },
                                    }
                                }
                        }
                        // onClick={() => {
                        //     dispatch(actions.setActive(item));
                        // }}
                    >
                        <Item
                            date={coordinationHistoryDates[item?.id] || null}
                            {...item}
                            complexStatuses={complexStatuses}
                            onClick={() => {
                                if (historyId > 0 && historyId !== item?.id) {
                                    setHistoryId(0);
                                    dispatch(actions.setCoordinationHistoryDates({}));
                                    dispatch(actions.loadedCoordinationHistory([]));
                                }
                                dispatch(actions.setActive(item));
                            }}
                            onChangeDateHistory={(params) => onChangeDateHistory({ ...params, item })}
                            loading={(loadingCoordinationHistory || polygonLoading) && historyId === item?.id}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={() => dispatch(actions.setSidebarParamsDC({ ...sidebarParams, page: 1 }))}
                onSetFilter={(filter) => dispatch(actions.setFilters(filter))}
                onClearFilter={() => dispatch(actions.clearFilters())}
                content={({ data, onChange }) => <FilterForm data={data} complexStatuses={complexStatuses} onChange={onChange} />}
                layer={config.slug}
            />

            {loading && list?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                    ref={scrollRef}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={titles.CPVF}
                            list={list}
                            infoPanel={<MenuItemInfo
                                action={actions.loadComplexStatistic}
                                selector={dorisControlSelectors.complexStatistic}
                            />}
                        />
                        <CheckBox />
                        {
                            list.data.length === 0
                                ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                                : renderList(list.data)
                        }
                    </div>
                    {loading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
    return null;
};

export default SideBar;
