import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import { setFilterList } from 'redux/TrafficFlow/actions';
import titles from 'helpers/constants/titles';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const Filter = ({ statuses, types }) => {
    const dispatch = useDispatch();

    const initialState = {
        uuid: [],
        name: '',
        status: null,
        type_id: null
    };

    const [data, setData] = useState(initialState);
    
    const handleChange = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const onSearch = (filters = data) => {
        const prepareData = {
            ...filters,
            status: filters.status?.id,
            type_id: filters.type_id?.id
        };

        dispatch(setFilterList(prepareData));
    };

    const resetFilter = (needRefresh) => {
        needRefresh && onSearch(initialState);
        setData(initialState);
    };

    const updateFilter = (filters) => {
        const newFilters = { ...initialState, ...filters };
        setData(newFilters);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilters);
        }
    };

    return(
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <FormInfoWrapper
                    helperText="Добавление одного или нескольких элементов, разделяя запятой"
                >
                    <MultipleInput
                        label="UUID"
                        value={data?.uuid || []}
                        onChange={(value) => handleChange('uuid', value) }
                    />
                </FormInfoWrapper>
                <TextField
                    label={titles.NAME}
                    size="small"
                    variant="outlined"
                    name="name"
                    value={data.name || ''}
                    onChange={({ target: { name, value } }) => handleChange(name, value)}
                />
                <CommonAutocomplete
                    label={titles.STATUS}
                    selected={data.status}
                    options={statuses}
                    onChange={(value) => handleChange('status', value)}
                    renderLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    showAsSelect
                />
                <CommonAutocomplete
                    label={titles.TYPE}
                    selected={data.type_id}
                    options={Object.entries(types).map(([key, value]) => ({ id: Number(key), name: value }))}
                    onChange={(value) => handleChange('type_id', value)}
                    renderLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    showAsSelect
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
