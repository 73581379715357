import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadDtpById, loadDtpTypesAll, saveDtp } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import titles from 'helpers/constants/titles';
import { useStoreFromSelector, useValidation } from 'helpers/hooks';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

import PassportTabs from './PassportTabs';

const initialState = {
    status: '',
    dtp_type: '',
    manual_edit: false,
    dtp_at: new Date(),
    address: {},
    lat: '',
    lon: '',
    intersection: '',
    km: null,
    m: null,
    description: '',
    schema_img_url: '',
    type_load: '',
    ti_id: null,
    ti_name: null,
    count_members: 0,
    dead: '',
    wounded: '',
    dead_child: '',
    wounded_child: '',
    count_ts: 0,
    members: [],
    vehicles: [],
    road: '',
    road_purpose: '',
    road_category: '',
    road_condition: '',
    street_category: '',
    weather: '',
    lighting: '',
    changes_driving: '',
    device_traffic_light_crossroads: '',
    device_traffic_light_crosswalk: '',
    road_objects_around: [],
    road_defects: [],
    driving_mode_influence_factors: [],
    materials: []
};

const ModalForm = ({
    data = {},
    onAccept = (e) => {},
    onClose = () => {},
    open = false,
    isTi = false
}) => {

    const dispatch = useDispatch();

    const saved = useSelector(incidentsSelectors.saved);
    const loading = useSelector(incidentsSelectors.loading);
    const typesAll = useStoreFromSelector(loadDtpTypesAll, incidentsSelectors.typesAll);

    const validation = useValidation();

    const [fields, setFields] = useState(initialState);
    const [isOpen, setIsOpen] = useState(open);

    const requiredFields = [
        'dtp_type',
        'status',
        'count_members',
        'count_ts'
    ];

    // новые данные сохранили
    useEffect(() => {
        if (saved) {
            setIsOpen(false);
            dispatch(saveDtp(false));
        }
    },[dispatch, saved]);

    // данные в форме изменились
    useEffect(() => {
        if (!!data) {
            const callback = (res) => {
                const newData = {
                    ...initialState,
                    ...res,
                };

                if (parseInt(newData.dtp_type) > 0) {
                    newData.dtp_type = typesAll?.length > 0
                        ? typesAll.find(({ id }) => id === newData.dtp_type)
                        : { id: res?.dtp_type, name: res?.dtp_type_name };
                }

                setFields(prev => ({
                    ...initialState,
                    ...prev,
                    ...newData
                }));
                setIsOpen(true);
            };

            data?.id
                ? dispatch(loadDtpById(data.id, callback))
                : callback(data);
        }
    }, [data]);

    const handleClose = () => {
        onClose();
        setIsOpen(false);
        setFields(initialState);
    };

    const handleSuccess = () => {
        const newFields = {
            ...fields,
            dtp_type: fields.dtp_type ? fields.dtp_type.id : null,
            dtp_type_name: fields.dtp_type?.name || null,
            dtp_at: fields.dtp_at ? fullDateTimeWithTimeZone(fields.dtp_at) : null,
            ti_id: fields.ti_id ? fields.ti_id.id : null,
        };

        const paramsWithoutEmpty = removeEmptyFields(newFields);

        onAccept({ ...paramsWithoutEmpty, manual_edit : newFields.manual_edit });
        !isTi && handleClose();
    };

    const handleChange = (changeData) => {
        validation.deleteKeys(Object.keys(changeData));

        setFields({
            ...fields,
            ...changeData
        });
    };

    const isFormValid = requiredFields.reduce((res, key) => {
        return res && fields[key];
    }, true) && validation.isValidation() === false;

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            noPadding
            title={!fields?.id ? titles.ADD : titles.EDIT}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: handleClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: !isFormValid
                        }
                    ]}
                />
            }
        >
            <PassportTabs
                fields={fields}
                requiredFields={requiredFields}
                onChange={handleChange}
                loading={loading}
                typesList={typesAll}
                isTi={isTi}
            />
        </Modal>
    );
};

export default ModalForm;
