import { moduleName } from 'modules/Dtp/module-config';

const prefix = `${moduleName}`;

export const LOAD_COUNT_BY_MONTH = `${prefix}/LOAD_COUNT_BY_MONTH`;
export const LOAD_COUNT_BY_PERIODS = `${prefix}/LOAD_COUNT_BY_PERIODS`;
export const LOAD_HEAT_MAP_DISTRICTS = `${prefix}/LOAD_HEAT_MAP_DISTRICTS`;

export const LOAD_DTP_TYPES_ALL = `${prefix}/LOAD_DTP_TYPES_ALL`;
export const LOADED_DTP_TYPES_ALL = `${prefix}/LOADED_DTP_TYPES_ALL`;
export const LOADING = `${prefix}/LOADING`;
export const LOAD_DTP_LIST = `${prefix}/DTP_LOAD`;
export const LOADED_DTP_LIST = `${prefix}/DTP_LOADED`;
export const LOAD_DTP_TYPES = `${prefix}/LOAD_DTP_TYPES`;
export const LOADING_DTP_TYPES = `${prefix}/LOADING_DTP_TYPES`;
export const LOADED_DTP_TYPES = `${prefix}/LOADED_DTP_TYPES`;
export const EDIT_DTP_TYPE = `${prefix}/EDIT_DTP_TYPE`;
export const CREATE_NEW_DTP = `${prefix}/CREATE_NEW_DTP`;
export const SAVE_DTP = `${prefix}/SAVE_DTP`;
export const EDIT_DTP = `${prefix}/EDIT_DTP`;

export const EDIT_DTP_STATUSES = `${prefix}/EDIT_DTP_STATUSES`;
export const LOADING_EDIT_STATUS = `${prefix}/LOADING_EDIT_STATUS`;

export const LOAD_DTP_STREETS = `${prefix}/LOAD_DTP_STREETS`;
export const LOADED_DTP_STREETS = `${prefix}/LOADED_DTP_STREETS`;
export const LOADING_DTP_STREETS = `${prefix}/LOADING_DTP_STREETS`;
export const LOAD_DTP_BY_ID = `${prefix}/LOAD_DTP_BY_ID`;
export const LOADED_DTP_BY_ID = `${prefix}/LOADED_DTP_BY_ID`;

