import { useState } from 'react';
import { useSelector } from 'react-redux';

import { incidentsSelectors } from 'redux/Incidents';
import { getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import { config } from 'config';
import Loading from 'components/common/Loading';
import PageLayout from 'components/layout/PageLayout';
import IncidentsLayer from 'components/MapComponents/Layers/Incidents';
import { Cluster, Map, MapControl } from 'components/MapComponents/leaflet';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';

import Filter from './Filter';

const initialFilter = {
    start_date: getStartOf('day', getDateWithDuration({ month: -1 })),
    end_date: getEndOf(),
};

const DtpMap = ({ tab }) => {
    const polygonLoading = useSelector(incidentsSelectors.polygonLoading);
    const [filter, setFilter] = useState(initialFilter);

    const renderContent = () => (
        <>
            {polygonLoading && <Loading circular/>}
            <Map
                center={config.get('mapCenter')}
            >
                <MapControl>
                    <Cluster
                        iconCreateFunction={iconCreateFunctionSimple}
                        disableClusteringAtZoom={15}
                        maxClusterRadius={10}
                    >
                        <IncidentsLayer readOnly={true} filter={filter} />
                    </Cluster>
                </MapControl>
            </Map>
        </>
    );

    return (
        <PageLayout
            tab={tab}
            filters={
                <Filter
                    filter={filter}
                    setFilter={setFilter}
                    onSearch={() => {}}
                    tab={tab}
                />
            }
            content={renderContent}
            customStyles={{ padding: 0 }}
        />
    );
};

export default DtpMap;
