import Station from './Components/Station';

const RouteMarkers = ({
    item,
    handleBounds,
    hideStationsPopup = false,
    categoriesObj = {},
    ...props
}) => {
    const { category_id, num, stations } = item;
    const id = `${num}_${category_id}`;

    const color = categoriesObj?.[category_id]?.color || '';

    return (
        <>
            {stations && stations
                ?.filter((station) => station?.check_point?.lat && station?.check_point?.lon)
                .map((station, index) => {
                    const {
                        id: stationId,
                        // check_point: { lat, lon }
                    } = station;
                    // if (!lat || !lon) return null;

                    return (
                        <Station
                            {...props}
                            key={`circlemarker_${id}_${stationId}_${index}`}
                            station={station}
                            color={color}
                            route={num}
                            category_id={category_id}
                            hideStationsPopup={hideStationsPopup}
                        />
                    );
                })}
        </>
    );
};

export default RouteMarkers;
