import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uniq } from 'lodash';
import { FormControl, TextField } from '@mui/material';

import {
    createLogicalPuid,
    editLogicalPuid,
    loadPuidByIds,
    loadStatus,
    loadTypes,
} from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import titles from 'helpers/constants/titles';
import { useStoreFromSelector, useValidation } from 'helpers/hooks';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import { Marker, ToolTip } from 'components/MapComponents/leaflet';
import InfoBlock from 'components/common/InfoBlock';

import { createIconMarker } from '../helper';

import Formula from './Formula';


const AddEditForm = ({
    data = {},
    isOpen = false,
    onClose,
    onSave,
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initial = {
        name: '',
        status: 1,
        address_text: '',
        address: {},
        type_id: 4,
        equation: [],
        lat: '',
        lon: '',
    };

    const [fields, setFields] = useState(initial);
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [selectedPuids, setSelectedPuids] = useState([]);

    const statuses = useStoreFromSelector(loadStatus, trafficFlowSelectors.statuses);
    const types = useStoreFromSelector(loadTypes, trafficFlowSelectors.types);

    const isNew = !fields?.id || false;

    const statusName = useMemo(() => statuses.find(i => i.id === fields.status)?.name || '', [statuses, fields.status]);

    const isDisabled = useMemo(() => {
        return !fields.name
            || !fields.address_text
            || fields.equation?.length === 0;
    }, [fields]);

    const handleCallback = () => {
        onSave?.();
        onClose?.();
    };

    const handleSave = () => {
        dispatch(isNew
            // создание
            ? createLogicalPuid(
                fields,
                handleCallback
            )
            // редактирование
            : editLogicalPuid(
                fields.id,
                fields,
                handleCallback
            )
        );
    };

    const handleClose = () => {
        onClose?.();
    };

    const onChange = (e) => {
        const { name, value } = e?.target;
        validation.deleteKey(name);
        setFields(old => ({
            ...old,
            [name]: value,
        }));
    };

    const onLocationChange = (params) => {
        validation.deleteKeys(Object.keys(params));
        setFields(old => ({
            ...old,
            ...params,
        }));
    };

    // обновление данных при открытии
    useEffect(() => {
        if (
            isOpen
            && Object.keys(data).length > 0
            && isFirstOpen
        ) {
            setIsFirstOpen(false);
            setFields({
                ...initial,
                ...data,
            });
        }
    }, [isOpen, data, isFirstOpen]);

    useEffect(() => {
        if (isOpen === false && isFirstOpen === false) {
            setIsFirstOpen(true);
        }
    }, [isOpen, isFirstOpen]);

    // прогрузка и заполнение по ids
    useEffect(() => {
        // корвертирование данных
        const convert = (list = [], puids = {}) => {
            if (!list?.length) return [];
            const {
                0: operator,
                1: puid
            } = list?.slice(0, 2) || {};
            return [
                {
                    operator,
                    puid: puids[puid] || { id: puid, name: puid },
                },
                ...convert(list.slice(2), puids)
            ];
        };

        if (data?.equation?.length > 0) {
            const ids = uniq(data?.equation?.reduce((r,i) => parseInt(i) ? [...r, i] : r, []));
            dispatch(loadPuidByIds(ids, {}, (puids) => {
                setSelectedPuids(convert([null, ...data?.equation], puids));
            }));
        }
    }, [dispatch, data?.equation]);

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding
            isOpen={isOpen}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: handleClose
                        },
                        {
                            ...buttonsTypes.save,
                            disabled: isDisabled,
                            onClick: handleSave
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">

                <FormControl required className="block" size="small" variant="outlined">
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={fields.name || ''}
                        name="name"
                        onChange={onChange}
                        type="text"
                        helperText={validation.get('name')}
                        error={!fields.name || validation.isKey('name')}
                    />
                </FormControl>

                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        size="small"
                        disabled
                        label={titles.STATUS}
                        value={statusName}
                        // helperText={validation.get('status')}
                        // error={!fields.status || validation.isKey('status')}
                    />
                </FormControl>

                <FormControl className="block" size="small" variant="outlined">
                    <TextField
                        disabled
                        size="small"
                        label={titles.TYPE}
                        value={types[fields.type_id] || ''}
                        // helperText={validation.get('type')}
                        // error={!fields.type || validation.isKey('type')}
                    />
                </FormControl>

                <InfoBlock label={titles.LOCATION} className="block">
                    <div className="block">
                        <LoadAddressByCoords
                            {...fields}
                            onChange={onLocationChange}
                        >
                            <AddressList required/>
                        </LoadAddressByCoords>
                    </div>

                    <div className="block">
                        <MapDragMarker
                            {...fields}
                            onChange={onLocationChange}
                            required
                            permanent={false}
                            innerComponent={(props) => {
                                return selectedPuids.map(({ puid }) => (!!puid?.lat && !!puid?.lon) && (
                                    <Marker
                                        {...props}
                                        key={puid.id}
                                        latlng={[puid.lat, puid.lon]}
                                        icon={createIconMarker('#51a351')}
                                    >
                                        <ToolTip>
                                            <div>{puid.name} </div>
                                        </ToolTip>
                                    </Marker>
                                ));
                            }}
                        />
                    </div>
                </InfoBlock>

                <Formula
                    required
                    lat={fields.lat}
                    lon={fields.lon}
                    selected={selectedPuids}
                    setSelected={setSelectedPuids}
                    onSave={(equation) => {
                        setFields(old => ({
                            ...old,
                            equation,
                        }));
                        validation.deleteKey('equation');
                    }}
                    error={validation.isKey('equation')}
                    helperText={validation.get('equation')}
                />

            </div>
        </Modal>
    );
};

export default AddEditForm;
