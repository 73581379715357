import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadTypeOfMistakesForCheckList } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';

import Filter from './Filter';
import Item from './Item';
import AddEditModal from './AddEditModal';

import type { ElemProps } from './types';

const test_id_prefix = '/dictionaries/doris-control/checklist-mistakes';

const ChecklistMistakes = () => {
    const dispatch = useDispatch();
    const data = useSelector(dorisControlSelectors.typeOfMistakesForChecklistData);
    const meta = useSelector(dorisControlSelectors.typeOfMistakesForChecklistMeta);
    const loading = useSelector(dorisControlSelectors.loadingTypeOfMistakesForChecklist);

    const [params, setParams] = useState({ page: 1, query: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = (isDelete = false) => {
        const { page, query } = params;
        
        isDelete && data?.length === 1 && meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadTypeOfMistakesForCheckList({ page, limit, ...query }));    
    };

    useEffect(() => {
        const { page, query } = params;
        dispatch(loadTypeOfMistakesForCheckList({ page, limit, ...query }));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            data?.length > 0
                ? <LSContainer
                    headers={[
                        { title: titles.NAME, width: '60%' },
                        { title: 'Действия', isActions: true },
                    ]}
                >
                    {data.map((elem: ElemProps) => (
                        <Item 
                            key={elem.id} 
                            item={elem} 
                            reloadList={reloadList}
                            test_id_prefix={test_id_prefix}
                        />
                    ))}
                </LSContainer>
                : !loading && <NoData/>
        );
    }; 

    return (
        <>
            <PageLayout
                header="Типы ошибок для чек-листов"
                filters={<Filter setParams={setParams} test_id_prefix={test_id_prefix}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setOpenAddModal(true),
                            }]
                            }
                            positionLeft
                            noPadding
                            test_id_prefix={test_id_prefix}
                        />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />

            {openAddModal 
                && <AddEditModal
                    isNew
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    reloadList={reloadList}
                    test_id_prefix={test_id_prefix}
                />
            }
        </>
    );
};

export default ChecklistMistakes;