import TabList from 'components/MapComponents/TabList';
import {
    createIcon,
    MapPopUp,
} from 'components/MapComponents/MapPopUp';
import MapScroll from 'components/MapComponents/MapScroll';

import { getShipColor, getShipIcon } from '../helper';

import LocationTab from './LocationTab';
import PropertiesTab from './PropertiesTab';

// import { useEffect, useState } from 'react';
// import { loadVehiclesCB, setDockCurrentShow } from 'modules/TransportWater/redux/actions';

const PopUpTransportModal = ({
    isOpen,
    marker = {},
    onClose,
}) => {
    // const dispatch = useDispatch();
    // const [vehicle, setVehicle] = useState({});
    // const [loading, setLoading] = useState(false);
    // useEffect(() => {
    //     if (marker.vehicle_id) {
    //         const filter = { id_list: [marker.vehicle_id] };
    //         dispatch(loadVehiclesCB(
    //             1,
    //             1,
    //             filter,
    //             setLoading,
    //             (newData) => setVehicle(newData?.data?.[0] || {})
    //         ));
    //     }
    // }, [dispatch, marker.vehicle_id]);

    const tabList = [
        {
            key: 'location',
            title: 'Местоположение',
            component: (
                <MapScroll>
                    <LocationTab marker={marker} />
                </MapScroll>
            ),

        },
        {
            key: 'properties',
            title: 'Свойства судна',
            component: (
                <MapScroll>
                    <PropertiesTab marker={marker} />
                </MapScroll>
            ),
        },
    ];

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={createIcon(getShipIcon)}
            title={marker?.vehicle?.name || ''}
            titleBackgroundColor={marker.color || getShipColor}
            onClose={onClose}
            medium
        >
            <TabList list={tabList}/>
        </MapPopUp>
    );
};

export default PopUpTransportModal;
