import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import cn from 'classnames';

import { loadRouteCheckpoints } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { config } from 'config';
import FormButtons from 'components/common/FormButtons';
import buttonsTypes from 'components/common/FormButtons/buttonsTypes';
import Modal from 'components/common/Modal';
import Loading from 'components/common/Loading';
import { getRouteColor } from 'components/MapComponents/Layers/TransportPassenger/helper';
import RouteJson from 'components/MapComponents/Layers/TransportPassenger/RouteJson';
import {
    CircleMarker,
    FeatureGroup,
    Map,
    ToolTip,
} from 'components/MapComponents/leaflet';
import ts from 'helpers/transport.types';
import messages from 'helpers/constants/messages/common';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';

import styles from './routes.module.scss';

const RoutePassport = ({ isOpen, onClose, data }) => {
    const dispatch = useDispatch();
    const mapCenter = config.get('mapCenter');
    const color = '#fff'; //getRouteColor(data?.category_id);

    const routeCheckpoints = useSelector(transportPassengerSelectors.routeCheckpoints);
    const routeCheckpointsLoading = useSelector(transportPassengerSelectors.routeCheckpointsLoading);

    const stations = routeCheckpoints[data?.id]?.filter((item) => item.station) || [];

    const checkPointsMarkers = stations.map(({ station: { check_point: { lat , lon }, id, name, direction } }) =>
        <CircleMarker
            key={id}
            latlng={[lat, lon]}
            radius={8}
            weight={2}
            fillColor={color.checkPoint}
            fillOpacity={1}
        >
            <CircleMarker
                latlng={[lat, lon]}
                radius={4}
                weight={2}
                fillColor="#fff"
                fillOpacity={1}
            >
                <ToolTip
                    offset={[0, -5]}
                    direction="top"
                >
                    <div>
                        <div><b>{titles.NAME}:</b> {name || messages.INFO_IS_NOT_FOUND}</div>
                        <div><b>{titles.DIRECTION}:</b> {direction || messages.INFO_IS_NOT_FOUND}</div>
                    </div>
                </ToolTip>
            </CircleMarker>
        </CircleMarker>
    );

    useEffect(() => {
        if (data?.id) {
            dispatch(loadRouteCheckpoints(data?.id));
        }
    }, [data?.id, dispatch]);

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @media print {
                 @page {
                    size: 350mm 250mm;
                  }
                .pagebreak {
                    page-break-before: none;
                }
                .printHeader {
                    display: block;
                }
            }
        `,
    });

    const printHeader = `${titles.PASSPORT_ROUTE} ${ts[data?.category_id]?.name} №${data.num} ${data?.from || ''} ${data?.to || ''} ${data?.name || ''}`;

    return (
        <Modal
            fullWidth
            noPadding
            isOpen={isOpen}
            onClose={onClose}
            title={
                <div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>{printHeader}</div>
                    <Tooltip title={buttons.PRINT}>
                        <IconButton onClick={handlePrint} size="large">
                            <PrintIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            }
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            <div className={cn(styles.route_passport, 'modal__form')}>
                {routeCheckpointsLoading && <Loading circular={true}/>}
                <div ref={componentRef}  className={styles.route_passport__content}>
                    <div className={styles.route_passport__content_item}>
                        <span className={styles.printHeader}>{printHeader}</span>
                        <h2 style={{ fontSize: 18 }}>{titles.STATIONS}:</h2>
                        <List className="list">
                            {stations?.length > 0
                                ? stations?.map(({ station }) =>
                                    <ListItem key={station?.id} className="list_item">
                                        <ListItemText>
                                            <div>
                                                {station?.name || messages.INFO_IS_NOT_FOUND}
                                            </div>
                                            {station?.direction
                                                && <div>
                                                    <b> {titles.DIRECTION}</b>: {station?.direction}
                                                </div>
                                            }
                                        </ListItemText>
                                    </ListItem>
                                )
                                : (!routeCheckpointsLoading && <b>{messages.INFO_IS_NOT_FOUND}</b>)
                            }
                        </List>
                    </div>
                    <div className={styles.route_passport__content_item}>
                        <Map center={mapCenter}>
                            {stations?.length > 0
                                ? <FeatureGroup>
                                    {checkPointsMarkers}
                                </FeatureGroup>
                                : <></>
                            }
                            <RouteJson centerAfter={true} item={data}/>
                        </Map>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RoutePassport;
