import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox } from '@mui/material';

import { deleteIncidentsFactors } from 'modules/React/redux/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import type { FactorType } from 'modules/React/reactTypes';

import ModalForm from './ModalForm';

interface ItemProps {
    item: FactorType;
    reloadList: (shouldGoToPreviousPage?: boolean) => void;
}

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();
    const id = item?.id;

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(deleteIncidentsFactors(id, () => {
            reloadList(true);
            setIsDelete(false);
        }));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    <Checkbox
                        checked={item?.is_geo}
                        size="small"
                        disabled
                    />
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            // @ts-ignore
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsEditMode(true),
                                disabled: item.is_system
                            },
                            // @ts-ignore
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                                disabled: item.is_system
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={setIsEditMode}
                    item={item}
                    isNew={false}
                    reloadList={reloadList}
                />
            )}
            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </>
    );
};

export default Item;
