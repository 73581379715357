import { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import SelectInfrastructureTypes from 'components/common/Autocomplete/RoadNetwork/InfrastructureTypes';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ setParams = () => {} }) => {
    const initialState = {
        name: '',
        type_id_list: [],
    };

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const resetFilters = (needRefresh) => {
        setData(initialState);
        if (needRefresh) {
            setParams((prev) => ({ ...prev, data: {} }));
        }
    };

    const search = () => {
        setParams({
            page: 1,
            data: removeEmptyFields({
                ...data,
                type_id_list: data.type_id_list.map((el) => el.id),
            }),
        });
    };

    const updateFilter = (filters) => {
        setData({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({
                page: 1,
                data: removeEmptyFields({
                    ...initialState,
                    ...filters,
                    type_id_list: filters?.type_id_list?.map((el) => el.id),
                }),
            });
        }
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') search();
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={search}
            filter={data}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={data.name}
                    name="name"
                    onChange={onChange}
                    type="text"
                    onKeyPress={handleClickEnter}
                />
                <SelectInfrastructureTypes
                    multiple
                    onChange={(newData) => setData({ ...data, type_id_list: newData })}
                    selected={data.type_id_list}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
