import { Collapse } from '@mui/material';

import RoadSectionRelationList from 'components/common/RoadSectionRelationList';
import type { RoadSection } from 'modules/InfrastructureObjects/Dictionaries/RoadSections/types';

interface InfoProps {
    data: RoadSection;
}

const Info = ({ data }: InfoProps) => {
    return (
        <Collapse
            className="info"
            in
            timeout={'auto'}
            style={{ padding: 16, backgroundColor: '#f5f5f5' }}
        >
            <h2>Описание:</h2>
            <div>{data?.description ? data?.description : 'Описание отсутствует'}</div>
            <RoadSectionRelationList roadSectionId={data?.id} readOnly />
        </Collapse>
    );
};

export default Info;
