import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import * as actions from 'modules/React/redux/actions';
import { AddModal } from 'modules/React/Dictionaries/Incidents/AddModal/Index';
import { reactSelectors } from 'modules/React';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const saved = useSelector(reactSelectors.saved);
    const editedForm = useSelector(reactSelectors.editForm);
    const deletedForm = useSelector(reactSelectors.deleteForm);

    useEffect(() => {
        if (saved) {
            dispatch(actions.setSaved(false));
        }
    }, [dispatch, saved]);

    return (
        <>
            {/* форма редактирования */}
            {editedForm && (
                <AddModal
                    isOpen={!!editedForm}
                    item={editedForm}
                    isNew={!editedForm?.id}
                    onClose={() => {
                        dispatch(actions.setEditForm());
                    }}
                    reloadList={() => {
                        dispatch(actions.setSaved(true));
                    }}
                />
            )}

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deletedForm}
                onSuccess={() => {
                    dispatch(actions.deleteIncident(deletedForm, () => {
                        dispatch(actions.setSaved(true));
                    }));
                    dispatch(actions.setDeleteForm());
                }}
                onClose={() => {
                    dispatch(actions.setDeleteForm());
                }}
            />
        </>
    );
};

export default CollectorForms;
