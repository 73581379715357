import { SyntheticEvent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import useClipboard from 'react-use-clipboard';

import { messageTypes, showMessage } from 'redux/Message/actions';

const CopyTextButton = ({ text }: { text: string }) => {
    const [isCopied, setCopied] = useClipboard(text, {
        successDuration: 6000,
    });

    const handleCopyToClipboard = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setCopied();
        showMessage(messageTypes.success, 'Текст скопирован в буфер обмена');
    };

    return (<Tooltip title="Скопировать" >
        <IconButton onClick={handleCopyToClipboard}> <i style={{ color: '#4050b5' }} className="far fa-copy"/> </IconButton>
    </Tooltip>);
};

export default CopyTextButton;
