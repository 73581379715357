import renderAddress from 'helpers/renderAddress';
import messages from 'helpers/constants/messages';

import Prices from './Prices';


const Item = ({ item, tariffCategories, tariffDays }) => {
    const {
        address_text,
        address = {},
        name,
        category,
        tariff,
    } = item;

    const tariffKey = tariff ? Object.keys(tariff?.prices?.days)[0] : null;
    const dayText = tariffKey
        ? tariffDays[tariffKey]
        : null;

    return (
        <>
            <div><strong>{name}</strong></div>
            {/* <div><strong>Дата создания: </strong> {getHumanDate(created_at) || messages.NO_DATA}</div> */}
            <div><strong>Адрес: </strong> {address ? renderAddress(address) : address_text || messages.NO_DATA}</div>
            <div><strong>Категория: </strong> {category.name_ru || messages.NO_DATA}</div>

            <div><strong>Дни работы: </strong> {dayText || messages.NO_DATA}</div>
            <div>
                <strong>Цена за час и время работы: </strong>
                {tariff
                    ? <Prices tariff={tariff} tariffCategories={tariffCategories} />
                    : messages.NO_DATA
                }
            </div>
            
        </>
    );
};

export default Item;
