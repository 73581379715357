import React, { useState } from 'react';
import { TextField } from '@mui/material';

import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import type { FilterProps } from './types';

const Filter = ({ setParams, test_id_prefix = '' }: FilterProps) => {
    const initialState = { name: '' };
    
    const [data, setData] = useState(initialState);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (filters = data) => {
        setParams({ page: 1, query: removeEmptyFields(filters) });
    };

    const resetFilter = (needRefresh: boolean) => {
        needRefresh && onSearch(initialState);
        setData(initialState);
    };

    const updateFilter = (filters: typeof initialState) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter} 
            setUserFilter={updateFilter}
            test_id_prefix={test_id_prefix}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data.name}
                    name="name"
                    onChange={onChange}
                    label={titles.NAME}
                    size="small"
                    variant="outlined"
                    type="text"
                    inputProps={{
                        'data-testid': `${test_id_prefix}:filter/name/input`,
                    }}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;