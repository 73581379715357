import L from 'leaflet';

// центровать по координатам [lat, lon]
export const centerToLatLon = (map, centerLatLon) => centerLatLon && map.setView(centerLatLon, 18);

// центровать по геообъекту
// toBounds = true - весь геообъект должен поместиться в видимую область
// toBounds = false - цеттровать по центру геообъекта
export const centerToGeometry = (map, geometry, toBounds = false) => {
    if (geometry) {
        const bounds = L.geoJSON(geometry)?.getBounds?.();
        if (bounds) {
            if (toBounds) {
                map.fitBounds(bounds);
            } else {
                const center = bounds?.getCenter?.();
                centerToLatLon(map, center);
            }
        }
    }
};
