import { moduleName } from '../module-config';

const prefix = `${moduleName}`;

// Скопления
export const LOAD_CONGESTION_TYPES = `${prefix}/LOAD_CONGESTION_TYPES`;
export const LOADED_CONGESTION_TYPES = `${prefix}/LOADED_CONGESTION_TYPES`;

export const LOAD_CONGESTIONS_HEAT_MAP = `${prefix}/LOAD_CONGESTIONS_HEAT_MAP`;
export const LOADING_CONGESTIONS_HEAT_MAP = `${prefix}/LOADING_CONGESTIONS_HEAT_MAP`;
export const LOADED_CONGESTIONS_HEAT_MAP = `${prefix}/LOADED_CONGESTIONS_HEAT_MAP`;
export const LOAD_CONGESTIONS_HEAT_MAP_SUMMARY = `${prefix}/LOAD_CONGESTIONS_HEAT_MAP_SUMMARY`;

export const LOAD_CONGESTION_POLYGON = `${prefix}/LOAD_CONGESTION_POLYGON`;
export const LOADED_CONGESTION_POLYGON = `${prefix}/LOADED_CONGESTION_POLYGON`;
export const LOAD_CONGESTION_SIDEBAR = `${prefix}/LOAD_CONGESTION_SIDEBAR`;
export const SET_NGPT_FILTER = `${prefix}/SET_NGPT_FILTER`;
export const SET_NGPT_ACTIVE = `${prefix}/SET_NGPT_ACTIVE`;

export const CONGESTION_MARK_REGULAR = `${prefix}/CONGESTION_MARK_REGULAR`;
export const SET_NGPT_SAVED = `${prefix}/SET_NGPT_SAVED`;
