import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOAD_CAMERAS = `${prefix}/LOAD/CAMERAS`;
export const LOADING_CAMERAS = `${prefix}/LOADING/CAMERAS`;
export const LOADED_CAMERAS = `${prefix}/LOADED/CAMERAS`;
export const EDIT_CAMERA = `${prefix}/EDIT/CAMERA`;
export const CREATE_CAMERA = `${prefix}/CREATE/CAMERA`;
export const DELETE_CAMERA = `${prefix}/DELETE/CAMERA`;
export const LOAD_CAMERA_SETTINGS = `${prefix}/LOAD/CAMERA/SETTINGS`;
export const LOADED_CAMERA_SETTINGS = `${prefix}/LOADED/CAMERA/SETTINGS`;
export const LOADING_CAMERA_SETTINGS = `${prefix}/LOADING/CAMERA/SETTINGS`;
export const EDIT_CAMERA_SETTINGS = `${prefix}/EDIT/CAMERA/SETTINGS`;
export const LOAD_CAMERA_INFO = `${prefix}/LOAD/CAMERA/INFO`;
export const LOADED_CAMERA_INFO = `${prefix}/LOADED/CAMERA/INFO`;
export const LOADED_CAMERA_VIOLATIONS = `${prefix}/LOADED/CAMERA/VIOLATIONS`;
export const LOAD_CAMERA_VIOLATIONS = `${prefix}/LOAD/CAMERA/VIOLATIONS`;
export const LOAD_CAMERA_EVENTS = `${prefix}/LOAD/CAMERA/EVENTS`;
export const LOAD_CURRENT_CAMERA_EVENTS = `${prefix}/LOAD/CURRENT/CAMERA/EVENTS`;
export const LOADING_CAMERA_EVENTS = `${prefix}/LOADING/CAMERA/EVENTS`;
export const LOADED_CAMERA_EVENTS = `${prefix}/LOADED/CAMERA/EVENTS`;
export const LOAD_CAMERA_REPORT_GROUPS = `${prefix}/LOAD/CAMERA/REPORT/GROUPS`;
export const LOADED_CAMERA_REPORT_GROUPS = `${prefix}/LOADED/CAMERA/REPORT/GROUPS`;
export const LOAD_CAMERA_REPORT_TYPES = `${prefix}/LOAD/CAMERA/REPORT/TYPES`;
export const LOADED_CAMERA_REPORT_TYPES = `${prefix}/LOADED/CAMERA/REPORT/TYPES`;
export const LOAD_CAMERA_REPORT = `${prefix}/LOAD/CAMERA/REPORT`;
export const LOADING_CAMERA_REPORT = `${prefix}/LOADING/CAMERA/REPORT`;
export const LOADED_CAMERA_REPORT = `${prefix}/LOADED/CAMERA/REPORT`;
export const CLEAR_CAMERA_REPORT = `${prefix}/CLEAR/CAMERA/REPORT`;
export const SET_CAMERA_REPORT_FILTER = `${prefix}/SET/CAMERA/REPORT/FILTER`;
export const CLEAR_CAMERA_REPORT_FILTER = `${prefix}/CLEAR/CAMERA/REPORT/FILTER`;
export const LOAD_IN_OUT_CAMERAS_REPORT = `${prefix}/LOAD/IN_OUT_CAMERAS/REPORT`;
export const LOADING_IN_OUT_CAMERAS_REPORT = `${prefix}/LOADING/IN_OUT_CAMERAS/REPORT`;
export const LOADED_IN_OUT_CAMERAS_REPORT = `${prefix}/LOADED/IN_OUT_CAMERAS/REPORT`;
export const CLEAR_IN_OUT_CAMERAS_REPORT = `${prefix}/CLEAR_IN_OUT_CAMERAS_REPORT`;
export const SET_ACTIVE = `${prefix}/SET/ACTIVE`;
export const SET_FILTER = `${prefix}/SET/FILTER`;
export const GET_POLYGON = `${prefix}/GET/POLYGON`;
export const SAVE_POLYGON = `${prefix}/SAVE/POLYGON`;
export const GET_DEVICE_LIST = `${prefix}/GET/DEVICE/LIST`;
export const SAVE_DEVICE_LIST = `${prefix}/SAVE/DEVICE/LIST`;
export const SET_FILTERS = `${prefix}/SET/FILTERS`;
export const SET_LIST_LOADING = `${prefix}/SET/LIST/LOADING`;
export const CLEAR_DEVICE_LIST = `${prefix}/CLEAR/LIST/LOADING`;
export const LOAD_STATUSES = `${prefix}/LOAD/STATUSES`;
export const LOADED_STATUSES = `${prefix}/LOADED/STATUSES`;
export const LOADED_CAMERA_GRZ_POINTS = `${prefix}/LOADED/CAMERA/GRZ/POINTS`;
export const LOAD_CAMERA_GRZ_POINTS = `${prefix}/LOAD/CAMERA/GRZ/POINTS`;
export const LOADING_CAMERA_GRZ_POINTS = `${prefix}/LOADING/CAMERA/GRZ/POINTS`;
export const CLEAR_CAMERA_GRZ_POINTS = `${prefix}/CLEAR/CAMERA/GRZ/POINTS`;
export const DOWNLOAD_CAMERA_EVENTS = `${prefix}/DOWNLOAD/CAMERA/EVENTS`;
export const WS_REPLACE_DATA = `${prefix}/WS/REPLACE/DATA/CAMERA/EVENTS`;
export const SET_VA_REPORT_FILTER = `${prefix}/SET/VA/REPORT/FILTER`;
export const CLEAR_VA_REPORT_FILTER = `${prefix}/CLEAR/VA/REPORT/FILTER`;
export const GET_EVENTS_FILTERS = `${prefix}/GET/EVENTS/FILTERS`;
export const LOADED_EVENTS_FILTERS = `${prefix}/LOADED/EVENTS/FILTERS`;
export const LOAD_CAMERA_OBJECTS = `${prefix}/LOAD/CAMERA/OBJECTS`;
export const LOADING_CAMERA_OBJECTS = `${prefix}/LOADING/CAMERA/OBJECTS`;
export const LOADED_CAMERA_OBJECTS = `${prefix}/LOADED/CAMERA/OBJECTS`;
export const SET_FILTER_CAMERA_OBJECTS = `${prefix}/SET_FILTER_CAMERA_OBJECTS`;
export const LOAD_PASSENGER_TRAFFIC = `${prefix}/LOAD_PASSENGER_TRAFFIC`;
export const LOADED_PASSENGER_TRAFFIC = `${prefix}/LOADED_PASSENGER_TRAFFIC`;
export const LOADING_PASSENGER_TRAFFIC = `${prefix}/LOADING_PASSENGER_TRAFFIC`;
export const LOAD_PASSENGER_TRAFFIC_TYPES = `${prefix}/LOAD_PASSENGER_TRAFFIC_TYPES`;
export const LOADED_PASSENGER_TRAFFIC_TYPES = `${prefix}/LOADED_PASSENGER_TRAFFIC_TYPES`;
export const SET_PASSENGER_TRAFFIC_FILTER = `${prefix}/SET_PASSENGER_TRAFFIC_FILTER`;
export const LOAD_CAMERAS_BY_RADIUS = `${prefix}/LOAD_CAMERAS_BY_RADIUS`;
export const LOADED_CAMERAS_BY_RADIUS = `${prefix}/LOADED_CAMERAS_BY_RADIUS`;
export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;
export const SAVE_EXCLUDED_KEYS = `${prefix}/SAVE_EXCLUDED_KEYS`;

export const GET_EVENTS_POPUP = `${prefix}/GET_EVENTS_POPUP`;
