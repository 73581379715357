import { useState, MouseEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, IconButton, Tooltip } from '@mui/material';

import { loadIncidentById } from 'modules/React/redux/actions';
import { AddModal } from 'modules/React/Dictionaries/Incidents/AddModal/Index';
import { editDTPStatuses, editDtp } from 'modules/Dtp/redux/actions';
import dtpStatusConfig from 'helpers/dtpStatus.comfig';
import { fullDateTimeWithTimeZone, fullDateTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import ModalForm from 'components/MapComponents/Layers/Incidents/ModalForm';
import ModalFormPopUp from 'components/MapComponents/Layers/Incidents/PopUp/ModalForm';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

import Info from './Info';
import { RenderPassportTabs } from './RenderPassportTabs';
import type { IncidentItem, ItemProps } from './types';


const Item = ({
    data,
    types,
    reloadList,
    onCheck,
    checked = false
}: ItemProps) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openIncidentInfoModal, setOpenIncidentInfoModal] = useState(false);
    const [openIncidentModal, setOpenIncidentModal] = useState(false);

    const [tiID, setTiID] = useState<string | null>(null);
    const [incident, setIncident] = useState<IncidentItem | null>(null);

    useEffect(() => {
        if (tiID) {
            dispatch(loadIncidentById(tiID, (tiData: any) => setIncident(tiData)));
        }
    }, [tiID, dispatch]);
    
    const type = types.get(data.dtp_type) || {};
    const { address_text, intersection } = data;

    const toggleOpen = () => setOpen(!open);

    const toggleOpenWithEvent = (e: MouseEvent<HTMLElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.name !== 'checkbox') {
            setOpen(!open);
        }
    };

    const toggleChangeStatus = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setOpenStatusModal(!openStatusModal);
    };

    const toggleOpenModal = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setOpenModal(!openModal);
    };

    const toggleOpenIncidentModal = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setOpenIncidentModal(!openIncidentModal);
    };

    const onClose = () => {
        setOpenIncidentInfoModal(false);
        setIncident(null);
    };

    const disableStatusChange = data.type_load === 2;

    const renderStatus = () => {
        return (
            <p>
                <span style={{ color: dtpStatusConfig(data.status).color }}>
                    {dtpStatusConfig(data.status).icon}{' '}
                </span>
            </p>
        );
    };

    const AddModalItem = {
        name: `ДТП - ${fullDateTimeWithTimeZone(new Date())}` || '',
        type_id: data?.dtp_type,
        registered_at: data?.dtp_at,
        created_at: new Date(),
        description: data?.description,
        lat: data?.lat,
        lon: data?.lon,
        address: data?.address,
        dtp_id: data?.id,
    };

    return (
        <>
            <LSContentItem style={{ paddingLeft: 5 }} onClick={toggleOpenWithEvent}>
                <LSContentColumn>
                    <Checkbox
                        name="checkbox"
                        checked={checked}
                        onChange={(e) => onCheck(e, data.id)}
                        color="primary"
                    />
                </LSContentColumn>
                <LSContentColumn>
                    <Tooltip title={dtpStatusConfig(data.status).status_text}>
                        <IconButton
                            disableRipple={disableStatusChange}
                            size="small"
                            {...(!disableStatusChange && { onClick: toggleChangeStatus })}
                        >
                            {renderStatus()}
                        </IconButton>
                    </Tooltip>
                </LSContentColumn>
                <LSContentColumn>
                    {data.id || messages.NO_VALUE }
                </LSContentColumn>
                <LSContentColumn>
                    {data.dtp_at ? fullDateTimeWithoutSeconds(data.dtp_at) : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {type.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {address_text || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {intersection || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {data.react_id ? (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                setTiID(data?.react_id);
                                setOpenIncidentInfoModal(true);
                            }}
                            size="small"
                            variant="text"
                            sx={{
                                textDecoration: 'underline',
                                minWidth: '10px',
                                display: 'inline-block',
                                fontSize: '1rem',
                                padding: '0 4px',
                            }}
                        >
                            {data.react_id}
                        </Button>
                    ) : (
                        messages.NO_VALUE
                    )}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: toggleOpenModal,
                            },
                            ...(!data?.react_id
                                ? [{
                                    ...buttonsTypes.linkIcon,
                                    onClick: toggleOpenIncidentModal,
                                }]
                                : []
                            ),
                        ]}
                    />

                    <ActionMore isOpen={open} onClick={toggleOpen} />
                </LSContentColumn>
            </LSContentItem>

            {open && <Info info={data} isOpen={open} />}

            {openModal && (
                <ModalForm
                    data={data}
                    onAccept={(newFields: { id: number }) => {
                        dispatch(editDtp(newFields.id, newFields, reloadList));
                    }}
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                />
            )}
            {openStatusModal && (
                <ModalFormPopUp
                    open={openStatusModal}
                    onClose={() => setOpenStatusModal(false)}
                    onSave={(status: string) =>
                        dispatch(
                            editDTPStatuses({ ids: [data.id], new_status: status }, () =>
                                reloadList()
                            )
                        )
                    }
                    initialStatus={data.status.toString()}
                />
            )}
            {openIncidentInfoModal && (
                <Modal
                    isOpen={openIncidentInfoModal}
                    onClose={() => setOpenIncidentInfoModal(false)}
                    noPadding
                    title={incident?.name}
                    fullWidth
                    showCloseInTitle
                    buttons={<FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: onClose
                            },
                        ]}
                    />}
                >
                    <RenderPassportTabs
                        selectedItem={incident}
                        passportOnly
                        reloadList={() => incident}
                    />
                </Modal>
            )}
            {openIncidentModal && (
                <AddModal
                    item={AddModalItem}
                    isOpen={openIncidentModal}
                    onClose={() => setOpenIncidentModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default Item;
