import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, Link, Tooltip } from '@mui/material';

import {
    clearTransportIncident,
    loadTransportIncidentById,
} from 'redux/SituationalPlans/actions';
import {
    editConcentrationArea,
    loadConcentrationAreaStatuses,
    loadConcentrationAreaTypes,
} from 'redux/Incidents/actions';
import { saveDtpFocus } from 'redux/FocusIncidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import * as actions from 'redux/FocusIncidents/actions';
import { fullDateTimeWithoutSeconds, fullDateTimeWithTimeZone } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import prepareCoords from 'helpers/prepareCoords';
import titles from 'helpers/constants/titles';
import Information from 'components/common/Information';
import ModalForm from 'components/pages/Dictionaries/SituationalPlans/TransportIncidents/ModalForm';
import getAddressStringFromObject from 'components/common/Location/LoadAddressByCoords/getAddressStringFromObject';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    MapPopUp,
} from 'components/MapComponents/MapPopUp';
import MapScroll from 'components/MapComponents/MapScroll';

import { createIcon, getItemToSave } from '../helper';

import ModalButtons from './ModalButtons';

const PopUpModal = ({
    data: currentData = {},
    isReport = false,
    onClose,
    // selectedItem,
}) => {
    const dispatch = useDispatch();
    // забираем данные из полигона

    const { address_text, address } = currentData;
    const [editTIModal, setEditTIModal] = useState(false);
    const [addTIModal, setAddTIModal] = useState(false);

    const ti = useSelector(({ SituationalPlans }) => SituationalPlans?.transportIncident || {});
    const tiLoading = useSelector(({ SituationalPlans }) => SituationalPlans?.loadingTransportIncident || false);

    const types = useStoreFromSelectorListToObject(
        loadConcentrationAreaTypes,
        incidentsSelectors.concentrationAreaTypes
    );
    const statuses = useStoreFromSelectorListToObject(
        loadConcentrationAreaStatuses,
        incidentsSelectors.concentrationAreaStatuses
    );

    const typeColor = types.get(currentData?.type)?.color;
    const statusColor = statuses.get(currentData?.status)?.color;

    useEffect(() => {
        if (currentData?.transport_incident_id) {
            dispatch(loadTransportIncidentById(currentData?.transport_incident_id));
        }
    }, [currentData?.transport_incident_id, dispatch]);

    useEffect(() => () => dispatch(clearTransportIncident()), []);

    const handleEditTI = () => {
        setEditTIModal(true);
    };

    const handleAddTI = () => {
        setAddTIModal(true);
    };

    const infoData = {
        id: {
            title: 'Id',
        },
        name: {
            title: titles.NAME,
        },
        type: {
            title: 'Вид',
            value: ({ type }) => types.get(type)?.name,
        },
        status: {
            title:  titles.STATUS,
            value: ({ status }) => statuses.get(status)?.name,
        },
        address_text: {
            title: titles.ADDRESS,
            value: ({ address_text = '', address = {} }) => address_text || getAddressStringFromObject(address)
        },
        gps: {
            title: titles.COORDINATES,
            value: ({ lat, lon }) => (lat && lon) ? `${prepareCoords(lat)} / ${prepareCoords(lon)}` : messages.NO_DATA,
        },
        created_at: {
            title: titles.DATE_TIME_CREATED,
            value: ({ created_at }) => fullDateTimeWithoutSeconds(created_at),
        },
        confirmed_at: {
            title: titles.DATE_TIME_CONFIRMED,
            value: ({ confirmed_at }) => fullDateTimeWithoutSeconds(confirmed_at),
        },
        ...(!isReport && {
            transport_incident_id: {
                title: 'Событие',
                value: ({ transport_incident_id }) => transport_incident_id
                    ? (
                        <Tooltip title="Редактировать событие">
                            <Link
                                component="button"
                                variant="body2"
                                onClick={handleEditTI}
                            >
                                {transport_incident_id}
                            </Link>
                        </Tooltip>
                    )
                    : (
                        <Link
                            component="button"
                            variant="body2"
                            onClick={handleAddTI}
                        >
                            Создать событие
                        </Link>
                    ),
            }
        }),
    };

    return (
        <>
            <MapPopUp
                isOpen={!!currentData}
                icon={<span className="inner-icon" dangerouslySetInnerHTML={{ __html: createIcon(typeColor, statusColor) }} />}
                title={address_text || getAddressStringFromObject(address) || messages.NO_DATA}
                onClose={() => {
                    dispatch(actions.resetActive());
                    onClose?.();
                }}
                titleBackgroundColor={colorExtend(typeColor)}
                medium
                buttons={
                    <ModalButtons
                        data={currentData}
                        uuid={currentData?.id}
                    />
                }
            >
                {tiLoading && <LinearProgress />}
                <MapScroll>
                    <Information
                        data={currentData}
                        title={infoData}
                    />
                </MapScroll>
            </MapPopUp>

            {(editTIModal || addTIModal) && (
                <ModalForm
                    isOpen={editTIModal || addTIModal}
                    isMkDtp
                    isNew={addTIModal}
                    item={addTIModal
                        ? { // добавление
                            address_text: currentData.address_text,
                            address: currentData.address,
                            lat: currentData.lat,
                            lon: currentData.lon,
                            geometry: createPointGJ(currentData.lat, currentData.lon),
                            mkdtp_id: currentData.id,
                            source: 'МКДТП',
                        }
                        : { // редактирование
                            ...ti?.data,
                            mkdtp_id: ti?.data?.mkdtp_id || currentData.id,
                            source: ti?.data?.source || 'МКДТП',
                        }
                    }
                    onClose={() => {
                        setEditTIModal(false);
                        setAddTIModal(false);
                    }}
                    reloadList={(tiData) => {
                        // добавить ти
                        if (addTIModal) {
                            dispatch(editConcentrationArea(
                                currentData.id,
                                getItemToSave({
                                    ...currentData,
                                    transport_incident_id: tiData.id,
                                    // status: 3,
                                    confirmed_at: currentData.confirmed_at || fullDateTimeWithTimeZone(new Date())
                                }),
                                () => {
                                    dispatch(saveDtpFocus(true));
                                }
                            ));
                        }
                        if (editTIModal) {
                            dispatch(saveDtpFocus(true));
                        }
                    }}
                />
            )}
        </>
    );
};

export default PopUpModal;
