import { useMemo, useState } from 'react';
import { areIntervalsOverlapping } from 'date-fns';
import { Button, IconButton, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { Types } from 'modules/Dtp/utils/components/Types';
import {
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf
} from 'helpers/date.config';
import buttons from 'helpers/constants/buttons';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { uri } from 'modules/Dtp/module-config';

import styles from './styles.module.scss';

const test_id_prefix=`${uri}/reports/graph`;

const Filter = ({
    onChange,
    tab,
}) => {

    const prevMonth = getDateWithDuration({ month: -1 });

    const initialState = {
        dates: [
            {
                start_date: fullDateTimeWithTimeZone(getStartOf('month', prevMonth)),
                end_date: fullDateTimeWithTimeZone(getEndOf('month', prevMonth))
            },
            {
                start_date: fullDateTimeWithTimeZone(getStartOf('month')),
                end_date: fullDateTimeWithTimeZone(getEndOf('day'))
            }
        ],
        selectedTypes: []
    };

    const [fields, setFields] = useState(initialState);

    const handleChangeDate = (date, key, index) => {
        const newFields = {
            ...fields,
            dates: [
                ...fields?.dates?.slice(0, index),
                {
                    ...fields?.dates?.[index],
                    [key]: fullDateTimeWithTimeZone(date),
                },
                ...fields?.dates?.slice(index + 1),
            ],
        };
        setFields(newFields);
    };

    const handleChangeData = (name, value) => {
        setFields((prev) => ({ ...prev, [name]: value }));
    };

    const resetFilters = (needRefresh) => {
        setFields(initialState);
        if (needRefresh) {
            onChange(initialState);
        }
    };

    const handleChange = () => {
        onChange(fields);
    };

    const updateFilter = (filters) => {
        const newFields = {
            ...fields,
            ...filters,
        };
        setFields(newFields);
        onChange(newFields);
    };

    const isDisabled = useMemo(() => {
        return fields
            ?.dates
            ?.reduce((
                res,
                { start_date = '', end_date = '' }
            ) => res || !start_date || !end_date || (start_date && end_date && new Date(start_date) > new Date(end_date))
            , false);
    }, [fields]);

    // пересечение периодов
    const compareD = () => {
        if (isDisabled) return false;
        let error = false;
        for (let i = 0; i < fields?.dates?.length - 1; i++) {
            const r1 = {
                start: new Date(fields?.dates[i]?.start_date),
                end: new Date(fields?.dates[i]?.end_date),
            };
            const r2 = {
                start: new Date(fields?.dates[i + 1]?.start_date),
                end: new Date(fields?.dates[i + 1]?.end_date),
            };

            error = error || areIntervalsOverlapping(r1, r2, { inclusive: true });
        }
        return error;
    };

    const RenderFilter = () => (
        <div>
            <div className={styles.filterWrap}>
                {fields?.dates?.map((item, index) => {
                    const {
                        start_date,
                        end_date,
                    } = item;

                    return (
                        <div key={index} className={styles.filterItem}>
                            <DateRange
                                required
                                valueStartDate={start_date}
                                valueEndDate={end_date}
                                handleDate={(date) => handleChangeDate(date, 'start_date', index)}
                                handleEndDate={(date) => handleChangeDate(date, 'end_date', index)}
                                dateOnly
                                error={compareD()}
                                helperText={
                                    compareD()
                                        ? 'Периоды не должны пересекаться'
                                        : null
                                }
                                test_id_prefix={`${test_id_prefix}:filter/dates`}
                            />
                            {fields?.dates?.length > 1 && (
                                <IconButton
                                    onClick={() => {
                                        setFields({
                                            ...fields,
                                            dates: [
                                                ...fields?.dates?.slice(0, index),
                                                ...fields?.dates?.slice(index + 1),
                                            ],
                                        });
                                    }}
                                    data-testid={`${test_id_prefix}:filter/item/delete`}
                                >
                                    <Tooltip title={buttons.DELETE}>
                                        <ClearIcon />
                                    </Tooltip>
                                </IconButton>
                            )}

                        </div>
                    );
                })}
            </div>

            {fields?.dates?.length < 3 && (
                <Button
                    type="text"
                    onClick={() => {
                        setFields({
                            ...fields,
                            dates: [
                                ...fields?.dates,
                                {}
                            ],
                        });
                    }}
                    data-testid={`${test_id_prefix}:filter/item/add`}
                >
                    Добавить период
                </Button>
            )}

            <Types
                multiple
                selected={fields.selectedTypes}
                onChange={(value) => handleChangeData('selectedTypes', value)}
            />
        </div>
    );

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={handleChange}
            filter={fields}
            disabled={isDisabled || compareD()}
            setUserFilter={updateFilter}
            test_id_prefix={test_id_prefix}
            tab={tab}
        >
            <LayoutFilter.Visible>
                <RenderFilter/>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
