import { ReactNode, useMemo } from 'react';

import { renderChilds } from 'components/common/List/helper';
import Modal from 'components/common/Modal';

import './styleMapPopUp.scss';

interface MapPopUpProps {
    isOpen: boolean;
    children: ReactNode;
    onClose?: () => void;
    fullWidth?: boolean,
    small?: boolean,
    medium?: boolean,
    data?: object | null,
    icon?: string | ((data: any) => any);
    title?: string | ((data: any) => any);
    titleButtons?: any;
    titleBackgroundColor?: string | ((data: any) => string);
    buttons?: any;
}

// модалка карты
const MapPopUp = ({
    // отобразить
    isOpen,
    // всё что между тегами <Modal></Modal>
    children,
    // модалка закрывается
    onClose,
    // на весь экран
    fullWidth,
    // маленькая модалка
    small,
    // средняя модалка
    medium,
    //данные
    data,
    // иконка в заголовке
    icon,
    // заголовок
    title,
    // кнопки в заголовке
    titleButtons,
    // фон заголовка
    titleBackgroundColor,
    buttons,
}: MapPopUpProps) => {

    // контент
    const currentContent = useMemo(() => {
        const items = Array.isArray(children) ? children : [children];
        return isOpen
            ? renderChilds(items, { data })
            : null;
    }, [children, data, isOpen]);

    // цвет фона
    const backgroundColor = useMemo(() => {
        if (!isOpen) return null;

        return typeof titleBackgroundColor === 'function'
            ? titleBackgroundColor?.(data)
            : titleBackgroundColor;
    }, [titleBackgroundColor, data, isOpen]);

    // заголовок
    const getTitle = useMemo(() => {
        if (!isOpen) return null;

        // заголовок
        const currentTitle = typeof title === 'function'
            ? title?.(data) || ''
            : title;

        // иконка
        const currentIcon = typeof icon === 'function'
            ? icon?.(data) || ''
            : icon;

        return (
            <div className="mapPopUpListener-flex">
                <div className="mapPopUpListener-flex">
                    {!!currentIcon && (
                        <span className="spanIcon">
                            {currentIcon}
                        </span>
                    )}

                    {!!currentTitle && (
                        <span>
                            {currentTitle}
                        </span>
                    )}
                </div>
            </div>
        );
    }, [icon, title, data, isOpen]);

    // кнопки в шапке
    const currentTitleButtons = useMemo(() => {
        if (!isOpen) return null;

        return typeof titleButtons === 'function'
            ? titleButtons?.(data) || ''
            : titleButtons;
    }, [titleButtons, data, isOpen]);

    return isOpen
        ? (
            <Modal
                isOpen={isOpen}
                onClose={() => onClose?.()}
                fullWidth={fullWidth}
                medium={medium}
                heightMedium={medium}
                small={small}
                heightSmall={small}
                noPadding
                showCloseInTitle
                titleButtons={currentTitleButtons}
                title={getTitle}
                titleBackgroundColor={backgroundColor ?? ''}
                titleColor={backgroundColor ? '#fff' : '' }
                buttons={buttons}
            >
                <div className="map-popup_body">
                    {currentContent}
                </div>
            </Modal>
        )
        : null;
};

export default MapPopUp;
