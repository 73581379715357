import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadEventObjects, } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import reactTitles from 'modules/React/utils/helpers/constants';
import { LSContainer } from 'components/common/List';
import removeEmptyFields from 'helpers/removeEmptyFields';

import ModalForm from './ModalForm';
import Filter from './Filter';
import Item from './Item';

const EventObjects = () => {
    const dispatch = useDispatch();

    const data = useSelector(reactSelectors.eventObjectsData);
    const meta = useSelector(reactSelectors.eventObjectsMeta);
    const loading = useSelector(reactSelectors.loadingEventObjects);

    const [openModal, setOpenModal] = useState(false);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const initState = {
        name: '',
        keyword_id_list: [],
        type_id: null,
        address: '',
    };

    const [params, setParams] = useState({
        page: 1,
        limit: storageLimit,
        data: initState
    });

    const reloadList = (isDelete) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        const newParams = removeEmptyFields(params.data);
        dispatch(loadEventObjects(params.page, params.limit, newParams));
    }, [dispatch, params]);

    const renderContent = () => {
        return data?.length > 0
            ? (<>
                <LSContainer
                    headers={[
                        { title: reactTitles.NAME, width: '25%' },
                        { title: titles.EVENT, width: '20%' },
                        { title: titles.TYPE_EVENTS, width: '20%' },
                        { title: titles.KEYWORDS, width: '20%' },
                        { title: titles.ACTION, isActions: true },
                    ]}
                >
                    {data?.map((item) => <Item
                        key={item.id}
                        item={item}
                        reloadList={reloadList}
                    />
                    )
                    }

                </LSContainer>

            </>)
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>);

    };

    return (
        <>
            <PageLayout
                header="Объекты мероприятий"
                filters={<Filter
                    setParams={(data) => setParams((prev) => ({ ...prev, page: 1, data }))}
                    initialState={initState}
                />}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }
                            ]}
                            noPadding
                        />
                    ),
                    total: meta?.total,
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page,) => setParams((prev) => ({ ...prev, page, data: prev.data })),
                    list: meta,
                    limit: params.limit,
                    setLimit: (limit) => setParams((prev) => ({ ...prev, limit }))
                }}
            />

            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default EventObjects;
