import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import MapForm from './MapForm';

const SelectDtpModal = ({
    isOpen,
    onClose,
    onSave,
    value,
    mainDtp,
    multiply,
}) => {

    const initial = multiply ? [] : null;

    const [field, setField] = useState(initial);

    useEffect(() => {
        if (isOpen) {
            setField(value);
        }
    }, [value, isOpen]);

    const handleSave = () => {
        onSave?.(field);
        onClose?.();
    };

    const handleClose = () => {
        onClose?.();
        setField(initial);
    };

    const isDisabled = isEqual(field, value);

    return (
        <Modal
            title="Присвоить ДТП"
            onClose={onClose}
            fullWidth
            noPadding
            isOpen={isOpen}
            disabledDragDrop
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: handleClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            disabled: isDisabled,
                            // loading: loading
                        }
                    ]}
                />
            }
        >
            <MapForm
                value={field}
                mainDtp={mainDtp}
                onChange={(newValue) => setField(newValue)}
                multiply={multiply}
            />
        </Modal>
    );
};

export default SelectDtpModal;
