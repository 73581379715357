import { loadOverlapTypes } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import MapScroll from 'components/MapComponents/MapScroll';
import {
    createIcon,
    MapPopUp,
} from 'components/MapComponents/MapPopUp';
import Information from 'components/common/Information';

const ModalPopUp = ({
    isOpen,
    data: currentData = {},
    onClose,
}) => {

    const types = useStoreFromSelector(
        loadOverlapTypes,
        roadworksSelectors.overlapTypesRW
    );

    const openLinkInNewTab = (url) => {
        const otherWindow = window.open();
        otherWindow.opener = null;
        otherWindow.location = url;
    };

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={createIcon('fas fa-do-not-enter')}
            title={'Перекрытие'}
            onClose={onClose}
            titleBackgroundColor={'#ff0000'}
            small
        >
            <MapScroll>
                <Information
                    data={currentData}
                    title={{
                        name: {
                            title: 'Название',
                        },
                        address_text: {
                            title: 'Адрес',
                        },
                        type: {
                            title: titles.TYPE,
                            value: ({ type }) => types[type]
                        },
                        srk: {
                            title: 'Сроки',
                        },
                        rasp: {
                            title: 'Распоряжение',
                        },
                        status: {
                            title: 'Статус',
                        },
                        gati_link: {
                            title: 'АИС ГАТИ',
                            value: ({ gati_link }) => <span style={{ cursor: 'pointer' }} onClick={() => openLinkInNewTab(gati_link)}>{gati_link}</span>
                        },
                    }}
                    returnNull
                    defaultValue={messages.INFO_IS_NOT_FOUND}
                />
            </MapScroll>
        </MapPopUp>
    );
};

export default ModalPopUp;
