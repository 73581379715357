import { memo } from 'react';

import config from './config';

const Legend = () => {
    return (
        <div className="sidebar-tp-legend ">
            <div className="item">
                <div className="title"></div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        fontSize: '1rem',
                        gap: '2rem',
                    }}
                >
                    <div className="flex">
                        <i className={config.layerIcon}/>
                        <div className="small"> - Объект инфраструктуры</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(Legend);