import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete, TextField } from '@mui/material';

import { incidentsSelectors } from 'redux/Incidents';
import {
    loadConcentrationAreaStatuses,
    loadConcentrationAreaTypes,
    loadDtpLoadTypes
} from 'redux/Incidents/actions';
import { loadStreetList } from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { useDebounce, useStoreFromSelector, useStoreProp } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import { Types } from 'components/common/Autocomplete/DTP/Types';


const useStyles = makeStyles((theme) => ({
    btn: {
        marginLeft: '10px',
        width: '40px',
        height: '40px',
        alignSelf: 'center'
    },
}));

const Filter = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { data, onChange } = props;

    const getValue = (key, def = '') => data[key] || def;

    const loadTypes = useStoreProp(loadDtpLoadTypes, 'incidents', 'loadTypes');

    const types = useStoreFromSelector(loadConcentrationAreaTypes, incidentsSelectors.concentrationAreaTypes);
    const statuses = useStoreFromSelector(loadConcentrationAreaStatuses, incidentsSelectors.concentrationAreaStatuses);

    const [street, setStreet] = useState([]);

    // const types = useSelector(incidentsSelectors.dtpTypesData);
    const streetList = useSelector(incidentsSelectors.streetList);

    const streetListOptions = streetList.length > 0
        ? streetList.map((item) => ({
            id: item,
            name: item
        }))
        : [{
            id: '',
            name: titles.NO_RESULTS
        }];

    const handleLoadStreetList = (query) => {
        dispatch(loadStreetList(query));
    };
    const loadStreetListDebounce = useDebounce(handleLoadStreetList, 800);

    const loadOptions = Object
        .keys(loadTypes)
        .map((key) => ({
            id: key,
            name: loadTypes[key]
        }));

    const handleEvent = (name) => (_, newValue) => {
        onChange({ [name]: newValue });
    };

    const disableOption = (option) => {
        const hasValue = (data.query || []).find(item => item.value === option.value);
        return !!hasValue;
    };

    const handleStreet = (e, newValue) => {
        const options = newValue.reduce((res, item) => {
            if (typeof item === 'string') {
                return res;
            }
            return [
                ...res,
                item
            ];
        }, []);

        onChange({
            'street': options
        });

        setStreet([
            ...options
        ]);
    };

    const handleAddressChange = (e) => {
        const { target: { value } } = e;
        if (value.length > 3) {
            loadStreetListDebounce(value);
        } else {
            loadStreetListDebounce.clear();
        }
    };

    useEffect(() => {
        if (data.street && !isEqual(data.street || [], street)) {
            setStreet(data.street);
        }
    }, [data]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="row">
                <DateRange
                    handleDate={(newDate) => onChange({ start_date: newDate })}
                    handleEndDate={(newDate) => onChange({ end_date: newDate })}
                    valueStartDate={getValue('start_date', null)}
                    valueEndDate={getValue('end_date', null)}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                    dateOnly
                />
            </div>

            <div className="block">
                <Autocomplete
                    multiple
                    value={getValue('id_list', [])}
                    freeSolo
                    autoSelect
                    options={[]}
                    onChange={(_, value) => onChange({ id_list: value })}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Id"
                        />
                    )}
                />
            </div>

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={getValue('type', null)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={types}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('type')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Вид"
                        />
                    )}
                />
            </div>

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={getValue('status', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={statuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('status')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Статус"
                        />
                    )}
                    multiple
                />
            </div>

            <div className="block">
                <Types
                    multiple
                    selected={getValue('dtp_type', [])}
                    onChange={(value) => onChange({ dtp_type: value })}
                />
            </div>

            <div className="block">
                <Autocomplete
                    multiple
                    noOptionsText={titles.NO_RESULTS}
                    value={getValue('dtp_type_load', [])}
                    // value={getValue('type_load', null)}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={loadOptions}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('dtp_type_load')}
                    // onChange={handleEvent('type_load')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Источник создания ДТП"
                        />
                    )}
                />
            </div>

            {/*<div className="block">*/}
            {/*    <Autocomplete*/}
            {/*        noOptionsText={titles.NO_RESULTS}*/}
            {/*        multiple*/}
            {/*        value={getValue('dtp_type', [])}*/}
            {/*        isOptionEqualToValue={(option, value) => option.id === value.id}*/}
            {/*        options={typesOptios}*/}
            {/*        filterSelectedOptions={true}*/}
            {/*        getOptionLabel={(option) => option.name}*/}
            {/*        onChange={handleEvent('dtp_type')}*/}
            {/*        size="small"*/}
            {/*        renderInput={(params) => (*/}
            {/*            <TextField*/}
            {/*                {...params}*/}
            {/*                size="small"*/}
            {/*                variant="outlined"*/}
            {/*                label={titles.TYPE_EVENTS}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</div>*/}

            {/* поиск по адресу */}
            {/*<div className="row">*/}
            {/*    <Autocomplete*/}
            {/*        style={{*/}
            {/*            width: '100%'*/}
            {/*        }}*/}
            {/*        noOptionsText={titles.NO_RESULTS}*/}
            {/*        multiple*/}
            {/*        value={street}*/}
            {/*        options={streetListOptions}*/}
            {/*        onChange={handleStreet}*/}
            {/*        filterSelectedOptions={true}*/}
            {/*        getOptionLabel={(option) => option.name}*/}
            {/*        freeSolo={true}*/}
            {/*        getOptionDisabled={disableOption}*/}
            {/*        size="small"*/}
            {/*        renderInput={(params) => (*/}
            {/*            <TextField*/}
            {/*                {...params}*/}
            {/*                size="small"*/}
            {/*                variant="outlined"*/}
            {/*                label="Укажите улицу"*/}
            {/*            />*/}
            {/*        )}*/}
            {/*        onInputChange={handleAddressChange}*/}
            {/*    />*/}
            {/*    <Tooltip*/}
            {/*        title="Введите улицу и выберите нужную из предложенного списка"*/}
            {/*    >*/}
            {/*        <IconButton className={classes.btn} size="small">*/}
            {/*            <ErrorOutline/>*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*</div>*/}

        </form>
    );
};

export default Filter;
