import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../../common/Modal';
import FormButtons, { buttonsTypes } from '../../../../common/FormButtons';
import IncidentOperations from '../../../../pages/Dictionaries/SituationalPlans/TransportIncidents/IncidentOperations';
import { SituationalPlansSelectors } from '../../../../../redux/SituationalPlans';
import { loadIncidentOperations } from '../../../../../redux/SituationalPlans/actions';
import Loading from '../../../../common/Loading';

const OperationListModal = ({ isOpen, onClose, tiId }) => {
    const dispatch = useDispatch();

    const incidentOperations = useSelector(SituationalPlansSelectors.incidentOperations);
    const loading = useSelector(SituationalPlansSelectors.loadingIncidentOperations);

    useEffect(() => {
        if (!incidentOperations[tiId]) {
            dispatch(loadIncidentOperations(tiId));
        }
    }, [dispatch, incidentOperations, tiId]);


    return (
        <Modal
            title="Мероприятия по ликвидации МКДТП"
            onClose={onClose}
            medium
            isOpen={isOpen}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            {loading && <Loading linear/>}
            {incidentOperations[tiId] && (
                <IncidentOperations operations={incidentOperations[tiId]} readOnly/>
            )}
        </Modal>
    );
};

export default OperationListModal;
