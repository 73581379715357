import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadScheduleTemplateList,
    loadScheduleTemplateStatuses
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import Item from './Item';
import STModal from './Modal';
import STFilters from './Filters';
import { scheduleTemplatesURI } from './moduleConfig';

const test_id_prefix = `${scheduleTemplatesURI}:page`;

const ScheduleTemplates = ({ currentRoute = {}, handleClickSTIcon, reloadRoutesList, tabHeader }) => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const statuses = useStoreProp(
        loadScheduleTemplateStatuses,
        'transportPassenger',
        'scheduleTemplateStatuses'
    );

    const statusesForList = statuses.reduce((res, el) => {
        res[el.id] = el;
        return res;
    }, {});

    const scheduleTemplateData = useSelector(transportPassengerSelectors.scheduleTemplateData);
    const scheduleTemplateMeta = useSelector(transportPassengerSelectors.scheduleTemplateMeta);
    const loadingScheduleTemplate = useSelector(transportPassengerSelectors.loadingScheduleTemplate);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [params, setParams] = useState({
        page: 1,
        data: { ...(currentRoute.id && { route_id: currentRoute.id }) }
    });

    const reloadList = (isDelete) => {
        isDelete && scheduleTemplateData.length === 1 && params.page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadScheduleTemplateList(params.page, limit, params.data));
    };

    useEffect(() => {
        if (currentRoute.id) {
            setParams((prev) => ({
                page: 1,
                data: {
                    ...prev.data,
                    ...(currentRoute.id && { route_id: currentRoute.id })
                }
            }));
        }
    }, [currentRoute.id]);

    useEffect(() => {
        dispatch(loadScheduleTemplateList(params.page, limit, params.data));
    }, [dispatch, limit, params.page, params.data]);

    const renderContent = () => {
        return <>
            {loadingScheduleTemplate && <Loading linear/>}
            {scheduleTemplateData?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: '', width: '50px' },
                            { title: titles.NAME, width: '85%' },
                            { title: titles.ACTIONS, isActions: true }
                        ]}
                    >
                        {scheduleTemplateData.map((el) => (
                            <Item
                                key={el.id}
                                item={el}
                                status={statusesForList[el.status]}
                                currentRoute={currentRoute}
                                handleClickSTIcon={handleClickSTIcon}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loadingScheduleTemplate && (
                    !scheduleTemplateData
                        ? <div>Для отображения шаблонов расписаний по маршруту выберите маршрут</div>
                        : messages.NO_DATA)
                )
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header={!currentRoute.id && 'Шаблоны расписаний'}
                tabHeader={tabHeader}
                filters={
                    <STFilters
                        hasCurrentRoute={Boolean(currentRoute.id)}
                        setParams={setParams}
                    />
                }
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                test_id_prefix={test_id_prefix}
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true),
                                        disabled: !permissions?.is_create,
                                        testName: 'add'
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: scheduleTemplateMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: scheduleTemplateMeta,
                    limit,
                    setLimit
                }}
            />
            {openAddModal && (
                <STModal
                    isNew={true}
                    statuses={statuses}
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    currentRoute={currentRoute}
                    reloadList={() => {
                        setParams({
                            page: 1,
                            data: { ...(currentRoute.id && { route_id: currentRoute.id }) },
                        });
                        currentRoute?.id && reloadRoutesList();
                    }}
                />
            )}
        </>
    );
};

export default ScheduleTemplates;
