import L from 'leaflet';

const maxMarkersCount = 200;

export function geoClusterIcon(cluster) {
    const markersCount = cluster.getChildCount();

    /**
     * @description
     * Размер маркера вычисляется в зависимости от числа попавших в кластер точек.
     * По значению функции минимальный размер маркера 16px.
     * Предполагаемый диапазон значений markersCount от 1 до 200,
     * однако максимальное значение в бизнес-постановке точно не определено,
     * поэтому выбираем функцию с замедляющимся возрастанием для того,
     * чтобы маркер не получился слишком большим.
     */

    const size = Math.round(30 + Math.log(markersCount ** 2) ** 2);
    let className = 'congestions-cluster ';

    if (markersCount >= maxMarkersCount * 0.66) {
        className += 'danger';
    } else if (markersCount >= maxMarkersCount * 0.33) {
        className += 'warning';
    }

    return L.divIcon({
        html: `
        <div class="${className}" style="width:${size}px;height:${size}px;">${markersCount}</div>
      `,
        className: '',
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
    });
}
