import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderToString } from 'react-dom/server';
import L from 'leaflet';
import 'leaflet.marker.slideto';

import { useWsSubscribe } from 'helpers/ws/hooks';
import * as selectors from 'modules/TransportWater/redux/selectors';

import PopUpTransportModal from './PopUpTransportModal';
import { getShipColor, getShipMarker } from './helper';

// маркеры карты
const Markers = ({
    map,
    parent,
    onClosePopUp = () => {},
}) => {

    const filters = useSelector(selectors.filters);

    // функция обработки тс (ссылка) - костыль для фильтра
    const handleEvent = useRef(null);

    // данные текущего маркера для модалки
    const [currentMarker, setCurrentMarker] = useState(null);
    // данные
    const markersData = useRef({});
    // маркеры
    const markers = useRef({});

    useEffect(() => {
        // если маркер удалили - закрываем модалку
        if (
            currentMarker
            && !markersData.current[currentMarker]
        ) {
            setCurrentMarker(null);
        }
    }, [currentMarker, markersData.current]);

    useEffect(() => {
        // фильтрация при получении данных с канала
        handleEvent.current = (event) => {
            if (
                !filters.nameShip
                || (
                    filters.nameShip
                    && event
                        ?.vehicle
                        ?.name
                        ?.toLowerCase?.()
                        ?.includes
                        ?.(filters.nameShip?.trim?.()?.toLowerCase?.())
                )
            ) {
                wsItem(event);
            }
        };

        // удаляем корабли с карты не подходящие по названию
        if (filters.nameShip) {
            Object
                .keys(markersData.current)
                .forEach((shipKey) => {
                    if (
                        !markersData.current[shipKey]
                            ?.vehicle
                            ?.name
                            ?.toLowerCase?.()
                            ?.includes?.(filters.nameShip?.trim?.()?.toLowerCase?.())
                    ) {
                        handleDelete(shipKey);
                    }
                });
        }
    }, [filters]);

    // const filter = getFilters(filters, getFilter);
    // todo первоначальная загрузка - метода пока нет
    // useEffect(() => {
    //     // первоначальная загрузка маркеров
    //     // dispatch(loadDockCoords(
    //     //     filter,
    //     //     (list) => {
    //     //         console.log('list', list);
    //     //     }
    //     // ));
    //     // console.log('loadDockCoords');
    // }, [filter]);

    const handleShowPopup = (key, latlon) => () => {
        setCurrentMarker(key);
        map.setView(latlon);
    };

    const handleClosePopUp = () => {
        onClosePopUp();
        setCurrentMarker(null);
    };

    const createToolTip = ({ vehicle = {} }) => (<div>{vehicle?.name || ''}</div>);

    // создание маркера тс
    const handleCreate = (event) => {
        const {
            lat,
            lon,
            color,
            vehicle_id,
        } = event;

        const newMarker = L
            .marker(
                [lat, lon],
                {
                    icon: getShipMarker(color || getShipColor)
                }
            );
        parent.addLayer(newMarker);

        // tooltip
        const tooltipContent = createToolTip(event);
        const renderString = renderToString(tooltipContent);
        const toolTip = L.tooltip({
            offset: [0, -40],
            direction: 'top'
        });
        toolTip.setContent(renderString);
        newMarker.bindTooltip(toolTip);

        newMarker.on('click', handleShowPopup(vehicle_id, [lat, lon]));

        markers.current[vehicle_id] = {
            marker: newMarker,
            tooltip: toolTip,
        };
    };

    // обработка тс с канала
    const wsItem = (event) => {
        const {
            vehicle_id,
            color,
            lat,
            lon,
        } = event;

        // данные
        markersData.current[vehicle_id] = event;

        if (markers.current[vehicle_id]) { // маркер уже есть

            const {
                marker,
                tooltip,
                // popup,
            } = markers.current[vehicle_id];

            // смена иконки
            marker.setIcon(getShipMarker(color || getShipColor));

            // сдвиг маркера
            marker.slideTo([lat, lon], { duration: 5000 });

            // смена подсказки
            const renderString = renderToString(createToolTip(event));
            tooltip.setContent(renderString);

        } else { // маркера нет
            handleCreate(event);
        }
    };

    // подключение к каналу
    useWsSubscribe('transport-water_telemetry_v2', (events) => {
        events?.map((item) => handleEvent.current?.(item));
    });

    // удалить те которые не актуальны
    useWsSubscribe('transport-water_ships_within_range_telemetry_v2', (events) => {
        // нужно оставить
        const vehicle_id_list = events
            ?.reduce
            ?.((r, i) => ([...r, ...(i?.vehicle_id_list || [])]), [])
            || [];
        // ищем которые нужно удалить
        const keys = Object.keys(markers.current)
            .filter(i => !vehicle_id_list.includes(parseInt(i)))
            || [];
        // удаляем
        keys.forEach((vehicle_id) => {
            handleDelete(vehicle_id);
        });
    });

    // удаление маркера и его данных
    const handleDelete = (key) => {
        if (markers.current[key]) {
            const {
                marker,
                // tooltip,
            } = markers.current[key];

            marker.off('click');

            parent.removeLayer(marker);
            marker.remove();
            delete markers.current[key];
            delete markersData.current[key];
        }
    };

    useEffect(() => () => {
        setCurrentMarker(null);
        // удаляем маркеры
        Object.keys(markers.current).map((itemKey) => {
            handleDelete(itemKey);
        });
    }, []);

    return (
        <>
            {/* popup */}
            {currentMarker && (
                <PopUpTransportModal
                    isOpen={!!currentMarker}
                    onClose={handleClosePopUp}
                    marker={markersData.current[currentMarker] || {}}
                />
            )}
        </>
    );
};

export default Markers;
