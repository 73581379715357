import { useState } from 'react';

import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { LSContentColumn, LSContentItem } from 'components/common/ListStyle';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import titles from 'helpers/constants/titles';

import AddEditModal from './AddEditModal';

const Item = ({ index, item, onEdit, onDelete }) => {
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);

    return (
        <>
            <LSContentItem fullWidth >
                <LSContentColumn title="Показатель" width="30%">
                    {item?.indicator?.name || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn title="Значение" width="30%">
                    {item?.value || messages.NO_DATA}
                </LSContentColumn>
                <LSContentColumn title={titles.UNIT} width="30%">
                    {item?.indicator?.unit_of_measurement_list
                        ?.find(el => el.id === item?.units_of_measurement_id)?.long_name
                        || messages.NO_DATA
                    }
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEdit(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDelete(true),
                            },
                        ]}
                        noMarginLeft
                        justButton
                    />
                </LSContentColumn>
            </LSContentItem>

            {openEdit &&  (
                <AddEditModal
                    item={item}
                    index={index}
                    isOpen={openEdit}
                    onClose={() => setOpenEdit(false)}
                    onAccept={onEdit}
                />
            )}
            {openDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDelete}
                    onSuccess={() => onDelete(index, () => setOpenDelete(false))}
                    onClose={() => setOpenDelete(false)}
                />
            )}
        </>
    );
};

export default Item;
