import { useEffect, useState } from 'react';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import SelectDtpLinkStatus from '../../../../forms/SelectDtpLinkStatus';

const SelectDtpModal = ({
    isOpen,
    onClose,
    onSave,
    value,
    loading,
}) => {

    const [field, setField] = useState(1);

    useEffect(() => {
        setField(value);
    }, [value]);

    const handleSave = () => {
        onSave?.(field);
        onClose?.();
    };

    const handleClose = () => {
        onClose?.();
        setField(1);
    };

    const isDisabled = parseInt(value) === parseInt(field) || !field;

    return (
        <Modal
            title="Изменение статуса связи"
            onClose={onClose}
            noPadding
            isOpen={isOpen}
            disabledDragDrop
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: handleClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            loading,
                            disabled: isDisabled,
                        }
                    ]}
                />
            }
        >
            <form autoComplete="off" className="modal__form">
                <SelectDtpLinkStatus
                    value={field}
                    onChange={(newValue) => setField(newValue)}
                />
            </form>
        </Modal>
    );
};

export default SelectDtpModal;
