import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as transportPassengerActions from 'redux/TransportPassenger/actions';
import * as transportRoadActions from 'redux/TransportRoad/actions';
import * as transportSpecialActions from 'redux/TransportSpecial/actions';
import { useStoreProp } from 'helpers/hooks';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import Item from './Item';
import Filter from './Filter';

import type { TelemetryReceiver } from './types';

interface TelemetryReceiversProps {
    storeName: string
}

const TelemetryReceivers = ({ storeName }: TelemetryReceiversProps) => {
    const dispatch = useDispatch();
    
    const { 
        telemetryReceivers: { data, meta }, 
        telemetryReceiversLoading: loading 
        // @ts-ignore: error message
    } = useSelector((store) => store[storeName]);
    
    const actionList: { [key: string]: any } = {
        transportPassenger: transportPassengerActions,
        transportRoad: transportRoadActions,
        transportSpecial: transportSpecialActions
    };    
    const actions = actionList[storeName];

    const types = useStoreProp(
        actions.loadTelemetryReceiverTypes,
        storeName,
        'telemetryReceiverTypes'
    );

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));
    const [openAddModal, setOpenAddModal] = useState(false);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete?: boolean) => {
        isDelete && data?.length === 1 && meta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(actions.loadTelemetryReceivers({ page: params.page, limit }));
    };

    useEffect(() => {
        dispatch(actions.loadTelemetryReceivers({ page: params.page, limit, ...params.data }));
    }, [actions, dispatch, limit, params.data, params.page]);

    return (
        <>
            <PageLayout
                header="Приемники телеметрии"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent 
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true)
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: meta?.total
                }}
                loading={loading}
                content={() => (
                    <>
                        {data?.length > 0
                            ? (<LSContainer headers={[
                                { title: 'Приемник/организация телеметрии', width: '40%' },
                                { title: 'Порт', width: '25%' },
                                { title: 'IP', width: '25%' },
                                { title: 'Действия', align: 'right', isActions: true },
                            ]}>
                                {data?.map((item: TelemetryReceiver) => (
                                    <Item
                                        key={item.id}
                                        item={item}
                                        actions={actions}
                                        reloadList={reloadList}
                                        types={types}
                                        page={storeName}
                                    />
                                ))}
                            </LSContainer>)
                            : !loading && <NoData/>
                        }
                    </>
                )}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                    actions={actions}
                    types={types}
                    page={storeName}
                />
            )}
        </>
    );
};

export default TelemetryReceivers;
