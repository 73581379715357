import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import { transportRoadSelectors } from 'redux/TransportRoad';
import { loadWorkorders } from 'redux/TransportRoad/actions';
import PageLayout from 'components/layout/PageLayout';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import Loading from 'components/common/Loading';
import { LSContainer } from 'components/common/List';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { dateWithDashYYYYMMDD, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';

import Filter from './Filter';
import Item from './Item';

import type { Filters, Workorder } from './types';

const WorkordersControl = () => {
    const dispatch = useDispatch();

    const data: Workorder[] = useSelector(transportRoadSelectors.workordersData);
    const meta = useSelector(transportRoadSelectors.workordersMeta);
    const loading = useSelector(transportRoadSelectors.loadingWorkorders);

    const initialState: Filters = {
        start_date_at: getStartOf('day', getDateWithDuration({ days: -1 })),
        end_date_at: getEndOf('day'),
        route_ids: [],
        grz: {},
        organization_ids: [],
        check: false,
        id: '',
    };

    const [params, setParams] = useState<Filters>(initialState);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));
    const [page, setPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState<Workorder | null>(null);

    const handleSelectItem = (value: Workorder) => {
        setSelectedItem(prevState => prevState?.id === value?.id ? null : value);
    };

    const renderContent = () => <>
        {loading && <Loading linear/>}
        {data?.length > 0
            ? (
                <LSContainer
                    headers={[
                        { title: '', width: '50px' },
                        { title: 'ID', width: '4%' },
                        { title: titles.NAME, width: '10%' },
                        { title: titles.NUM_ROUTE, width: '7%' },
                        { title: titles.TYPE_OF_VEHICLE, width: '11%' },
                        { title: titles.GRZ, width: '10%' },
                        { title: titles.ORGANIZATION, width: '18%' },
                        { title: titles.DATE, width: '9%' },
                        { title: titles.START_TIME_NEWLINE, width: '10%' },
                        { title: titles.END_TIME_NEWLINE, width: '11%' },
                        { title: titles.ACTIONS, isActions: true }
                    ]}
                >
                    {data.map((item) =>
                        <Item
                            key={item.id}
                            item={item}
                            selectedId={selectedItem?.id || null}
                            setSelect={handleSelectItem}
                        />
                    )}
                </LSContainer>
            )
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        }
    </>;

    useEffect(() => {
        const result = {
            start_date_at: dateWithDashYYYYMMDD(params.start_date_at),
            end_date_at: dateWithDashYYYYMMDD(params.end_date_at),
            route_ids: params.route_ids.map(({ id }) => id),
            grz: params.grz?.number || '',
            organization_ids: params.organization_ids.map(({ id }) => id),
            id: params.id,
        };

        const paramsWithoutEmpty = removeEmptyFields(result);

        dispatch(loadWorkorders(page, limit, { ...paramsWithoutEmpty, check: params?.check }));
    }, [dispatch, limit, page, params]);

    return (
        <PageLayout
            header="Контроль нарядов"
            filters={<Filter
                setParams={(params) => {
                    setParams(params);
                    setPage(1);
                }}
                initialState={initialState}
            />}
            actionPanel={<>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={params?.check}
                            onChange={() => setParams(prev => ({ ...prev, check: !prev.check }))}
                        />
                    }
                    label="Показать выполненные"
                />
            </>}
            informPanelProps={{
                total: meta?.total
            }}
            paginationProps={{
                loadList: (page: number) => setPage(page),
                list: meta,
                limit: Number(limit),
                setLimit,
            }}
            content={renderContent}
            additionalButtons={<ReturnToButton/>}
        />
    );
};

export default WorkordersControl;
