import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { trafficFlowSelectors } from 'redux/TrafficFlow';
import * as actions from 'redux/TrafficFlow/actions';
import mapHelper from 'helpers/mapHelper';
import { useStoreFromSelector } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    ContextMenu,
    ContextMenuItem,
    Marker,
    ToolTip,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';
import {
    MapPopUpListener
} from 'components/MapComponents/MapPopUp';

import useForm from './Form/useForm';
import PopUpModal from './PopUpModal';
import { createIconMarker } from './helper';
import Legend from './Legend';
import config from './config.js';

const Layer = (props) => {
    const {
        map,
        filter,
    } = props;
    const dispatch = useDispatch();
    const polygon = useSelector(trafficFlowSelectors.polygon);
    // const polygonLoading = useSelector(trafficFlowSelectors.polygonLoading);
    const active = useSelector(trafficFlowSelectors.active);
    const filterSelected = useSelector(trafficFlowSelectors.filters);
    const filters = filter || filterSelected;

    // статусы
    const statuses = useStoreFromSelector(actions.loadStatus, trafficFlowSelectors.statuses);
    // типы
    const types = useStoreFromSelector(actions.loadTypes, trafficFlowSelectors.types);

    const handleSuccess = () => {
        fetchPolygon();
    };
    const logicalPuidForm = useForm(handleSuccess);

    // грузим полигон
    const fetchPolygon = () => {
        const filter = removeEmptyFields(getFilters(filters));
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygon(polygon, filter));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        logicalPuidForm.showAddForm({
            lat,
            lon: lng,
        });
    };

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);


    // сдвинуться к маркеру
    const setMapToMarker = () => {
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                setTimeout(() => {
                    map.setView([lat, lon]);
                }, 200);
            }
        }
    };

    useEffect(() => {
        fetchPolygon();

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        setMapToMarker();

        return () => {
            dispatch(actions.resetActive());
            dispatch(actions.clearForPolygon());
            map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProps }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProps}
                    value="Редактировать"
                    onClick={() => {
                        map.fire('context_menu_close');
                        logicalPuidForm.showEditForm(item);
                    }}
                />
                <ContextMenuItem
                    {...rcmProps}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        logicalPuidForm.showConfirmDelete(item.id);
                    }}
                    className="red"
                />
            </div>
        );
    };

    const polygonData = useMemo(() => {
        return polygon.map((item) => {
            const { id, lat, lon } = item;
            const componentProps = {
                ...props,
                key: id,
                latlng: [lat, lon],
                icon: createIconMarker(colorExtend(item.color)),
                attribution: {
                    slug: config.slug,
                    color: colorExtend(item.color),
                },
                onClick: (latlng) => {
                    map.setView(latlng);
                    dispatch(actions.setActive(item));
                },
            };

            return (
                <Marker
                    {...componentProps}
                >
                    <ToolTip
                        offset={[0, -40]}
                        direction="top"
                    >
                        <div>{item.name}</div>
                    </ToolTip>

                    {item.type_id === 4 && (
                        <ContextMenu>
                            <RenderContextMenu item={item} />
                        </ContextMenu>
                    )}
                </Marker>
            );
        });
    }, [polygon]);

    return (
        <>
            {polygonData}

            {logicalPuidForm.renderComponent()}

            {/* popup */}
            <MapPopUpListener
                activeSelector={trafficFlowSelectors.active}
                polygonSelector={trafficFlowSelectors.polygon}
            >
                <PopUpModal
                    statuses={statuses}
                    types={types}
                />
            </MapPopUpListener>

            <MapLegends layer={config.slug}>
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
