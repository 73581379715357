/**
 * Sagas
 * **/
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import messages from 'helpers/constants/messages';

import { showMessage } from '../../../redux/Message/actions';
import messageTypes from '../../../redux/Message/messagetypes';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* loadDashboardSaga({ payload }) {
    yield put(actions.loadingDashboard(true));

    const response = yield call(api.getDashboard, payload);
    if (response?.success) {
        yield put(actions.loadedDashboard(response?.data || []));
    }
    yield put(actions.loadingDashboard(false));
}

function* loadUserDashboardSaga({ payload }) {
    yield put(actions.loadingUserDashboard(true));

    const response = yield call(api.getUserDashboard, payload);
    if (response?.success) {
        yield put(actions.loadedUserDashboard(response?.data));
    }
    yield put(actions.loadingUserDashboard(false));
}

function* editUserDashboardSaga({ payload, callback }) {
    const { data } = payload;
    const response = yield call(api.editUserDashboard, data);
    if (response?.success) {
        yield put(actions.loadUserDashboard());
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield callback?.();
    }
}

function* loadDashboardsItemSaga({ payload, callback }) {
    yield put(actions.loadingDashboard(true));
    const response = yield call(api.getDashboardsItem, payload);
    if (response?.success) {
        if (callback) {
            yield callback(response.data);
        } else {
            yield put(actions.loadedDashboardsItem(response));
        }
    }
    yield put(actions.loadingDashboard(false));
}

function* loadDashboardItemUserSaga({ payload, callback }) {
    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.getDashboardItemUser, payload);
    if (response?.success) {
        if (callback) {
            yield callback(response.data);
        } else {
            yield put(actions.loadedDashboardItemUser(response.data));
        }
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* createDashboardItemUserSaga({ payload, callback }) {
    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.createDashboardItemUser, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* editDashboardItemUserSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.editDashboardItemUser, id, data);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* editDashboardItemUserMultiplySaga({ payload, callback }) {
    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.editDashboardItemUserMultiply, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* deleteDashboardItemUserSaga({ payload, callback }) {
    yield put(actions.loadingDashboardItemUser(true));
    const response = yield call(api.deleteDashboardItemUser, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
    yield put(actions.loadingDashboardItemUser(false));
}

function* loadUserAvailableDashboardListSaga({ payload }) {
    yield put(actions.loadingUserAvailableDashboardList(true));

    const response = yield call(api.getUserAvailableDashboardList, payload);
    if (response?.success) {
        yield put(actions.loadedUserAvailableDashboardList(response?.data));
    }
    yield put(actions.loadingUserAvailableDashboardList(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_DASHBOARD, loadDashboardSaga),
        takeLatest(types.LOAD_USER_DASHBOARD, loadUserDashboardSaga),
        takeLatest(types.EDIT_USER_DASHBOARD, editUserDashboardSaga),
        takeLatest(types.LOAD_DASHBOARDS_ITEM, loadDashboardsItemSaga),
        takeLatest(types.LOAD_DASHBOARD_ITEM_USER, loadDashboardItemUserSaga),
        takeLatest(types.CREATE_DASHBOARD_ITEM_USER, createDashboardItemUserSaga),
        takeLatest(types.EDIT_DASHBOARD_ITEM_USER, editDashboardItemUserSaga),
        takeLatest(types.EDIT_DASHBOARD_ITEM_USER_MULTIPLY, editDashboardItemUserMultiplySaga),
        takeLatest(types.DELETE_DASHBOARD_ITEM_USER, deleteDashboardItemUserSaga),
        takeLatest(types.LOAD_USER_AVAILABLE_DASHBOARD_LIST, loadUserAvailableDashboardListSaga),
    ]);
}
