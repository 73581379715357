import L from 'leaflet';

import removeEmptyFields from 'helpers/removeEmptyFields';

export const getFilter = (filter) => {
    return removeEmptyFields(filter, false);
};

export const getIconNode = 'far fa-dot-circle';
export const getIconEdge = 'fas fa-horizontal-rule';

export const getNodeColor = '#0c3c5f';
export const getEdgeColor = '#343333';

export const getMapNode = (color) => L.divIcon({
    className: 'marker',
    iconSize: [30, 30],
    iconAnchor: [15, 15],
    popupAnchor: [0, -30],
    html: `
        <div style="background: ${color};" class="digitalTwin markerIcon">
            <i class="${getIconNode}"></i>
        </div>
    `,
});
