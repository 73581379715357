import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, IconButton, ListItemIcon } from '@mui/material';

import type { OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

interface SortableItemProps {
  item: OperationInScenario;
  index: number;
  children: React.ReactElement,
}

const SortableItem = ({
    item,
    index,
    children,
}: SortableItemProps) => {
    const {
        attributes,
        listeners,
        transition,
        transform,
        setNodeRef,
        isDragging,
    } = useSortable({ id: Number(item.position) });
    const isParent = !item.group?.includes('.');
    const isDone = item?.is_done;

    const style = {
        padding: 0 ,
        transform: CSS.Translate.toString(transform),
        transition,
        ...((index % 2 === 0) && { backgroundColor: '#F0F1FA' }),
        ...(isDragging && { opacity: 0.6, backgroundColor: index % 2 === 0 ? '#F0F1FA' : '#fff' }),
    };

    return (
        <Box
            ref={setNodeRef}
            display={'flex'}
            alignItems={'center'}
            {...attributes}
            style={style}
        >
            <ListItemIcon
                {...(isParent && !isDone ? listeners : {})}
                style={{ minWidth: 'fit-content', padding: 0, marginRight: '-30px', zIndex: 1 }}
            >
                <IconButton 
                    disabled={!isParent || isDone} 
                    size="small" 
                    style={{ width: 30, height: 30 }} 
                >
                    {isParent && <i className="fas fa-grip-vertical" />}
                </IconButton>
            </ListItemIcon>
            {children}
        </Box>
    );
};

export default SortableItem;
