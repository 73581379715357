import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiItsMethods = {

    getDashboard: async (params) => {
        const response = await instance.get(apiUrls.getDashboard(), { params });
        return response.data;
    },

    getUserDashboard: async (params) => {
        const response = await instance.get(apiUrls.getUserDashboard(), { params });
        return response.data;
    },

    editUserDashboard: async (data) => {
        const response = await instance.put(apiUrls.editUserDashboard(), data);
        return response.data;
    },

    getDashboardsItem: async (params) => {
        const response = await instance.get(apiUrls.getDashboardsItem(), { params });
        return response?.data;
    },

    getDashboardItemUser: async (params) => {
        const response = await instance.get(apiUrls.getDashboardItemUser(),  { params });
        return response?.data;
    },

    createDashboardItemUser: async (data) => {
        const response = await instance.post(apiUrls.createDashboardItemUser(), data);
        return response?.data;
    },

    editDashboardItemUser: async (id, data) => {
        const response = await instance.put(apiUrls.editDashboardItemUser(id), data);
        return response?.data;
    },

    editDashboardItemUserMultiply: async (data) => {
        const response = await instance.put(apiUrls.editDashboardItemUserMultiply(), data);
        return response?.data;
    },

    deleteDashboardItemUser: async (id) => {
        const response = await instance.delete(apiUrls.deleteDashboardItemUser(id));
        return response?.data;
    },

    getUserAvailableDashboardList: async (params) => {
        const response = await instance.get(apiUrls.getUserAvailableDashboardList(), { params });
        return response.data;
    },

};

export default ApiItsMethods;
