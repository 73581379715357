import * as types from './types';

/**
 * Actions
 **/
export const loadCameras = (page = 1, limit = 25, query) => ({
    type: types.LOAD_CAMERAS,
    payload: {
        page,
        limit,
        query
    }
});

export const loadingCameras = (bool = false) => ({
    type: types.LOADING_CAMERAS,
    payload: bool
});

export const loadedCameras = (data) => ({
    type: types.LOADED_CAMERAS,
    payload: data
});

export const editCamera = (id, data, callback = () => {}) => ({
    type: types.EDIT_CAMERA,
    payload: { id, data },
    callback
});

export const createCamera = (data) => ({
    type: types.CREATE_CAMERA,
    payload: data
});

export const deleteCamera = (id, callback) => ({
    type: types.DELETE_CAMERA,
    payload: id,
    callback
});

export const loadCameraSettings = (page = 1, limit = 100) => ({
    type: types.LOAD_CAMERA_SETTINGS,
    payload: { page, limit }
});

export const loadedCameraSettings = (data) => ({
    type: types.LOADED_CAMERA_SETTINGS,
    payload: data
});

export const loadingCameraSettings = (bool = false) => ({
    type: types.LOADING_CAMERA_SETTINGS,
    payload: bool
});

export const editCameraSettings = (id, params) => ({
    type: types.EDIT_CAMERA_SETTINGS,
    payload: { id, params }
});

export const loadCameraInfo = () => ({
    type: types.LOAD_CAMERA_INFO,
});

export const loadedCameraInfo = (data) => ({
    type: types.LOADED_CAMERA_INFO,
    payload: data
});

export const loadCameraViolations = () => ({
    type: types.LOAD_CAMERA_VIOLATIONS,
});

export const loadedCameraViolations = (data) => ({
    type: types.LOADED_CAMERA_VIOLATIONS,
    payload: data
});

export const loadedCameraEvents = (data) => ({
    type: types.LOADED_CAMERA_EVENTS,
    payload: data
});

export const loadingCameraEvents = (bool = false) => ({
    type: types.LOADING_CAMERA_EVENTS,
    payload: bool
});

export const loadCameraEvents = (page = 1, limit = 25, data) => ({
    type: types.LOAD_CAMERA_EVENTS,
    payload: { page, limit, data }
});

export const loadCurrentCameraEvents = (page = 1, limit = 25, data, callback) => ({
    type: types.LOAD_CURRENT_CAMERA_EVENTS,
    payload: { page, limit, data },
    callback
});

export const loadCameraReportGroups = () => ({
    type: types.LOAD_CAMERA_REPORT_GROUPS
});

export const loadedCameraReportGroups = (data) => ({
    type: types.LOADED_CAMERA_REPORT_GROUPS,
    payload: data
});

export const loadCameraReportTypes = () => ({
    type: types.LOAD_CAMERA_REPORT_TYPES
});

export const loadedCameraReportTypes = (data) => ({
    type: types.LOADED_CAMERA_REPORT_TYPES,
    payload: data
});

export const loadedCameraReport = (data) => ({
    type: types.LOADED_CAMERA_REPORT,
    payload: data
});

export const loadCameraReport = (params, callback) => ({
    type: types.LOAD_CAMERA_REPORT,
    payload: params,
    callback
});

export const loadingCameraReport = (bool = false) => ({
    type: types.LOADING_CAMERA_REPORT,
    payload: bool
});

export const clearCameraReport = () => ({
    type: types.CLEAR_CAMERA_REPORT
});

// export const setCameraReportFilter = (data) => ({
//     type: types.SET_CAMERA_REPORT_FILTER,
//     payload: data
// });

// export const clearCameraReportFilter = () => ({
//     type: types.CLEAR_CAMERA_REPORT_FILTER,
// });

export const getForPolygonVideoAnalytic = (polygon, params = {}) => ({
    type: types.GET_POLYGON,
    payload: { polygon, ...params },
});

export const saveForPolygon = (data) => ({
    type: types.SAVE_POLYGON,
    payload: data,
});

export const setActive = (item) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActive = () => ({
    type: types.SET_ACTIVE,
    payload: [],
});

export const getDeviceList = (page = 1, limit = 10, params= {}) => ({
    type: types.GET_DEVICE_LIST,
    payload: { page, limit, ...params },
});

export const loadingDeviceList = (bool = false) => ({
    type: types.SET_LIST_LOADING,
    payload: bool,
});

export const saveDeviceList = (data, replace = false) => ({
    type: types.SAVE_DEVICE_LIST,
    payload: {
        data,
        replace
    },
});

export const clearDeviceList = () => ({
    type: types.CLEAR_DEVICE_LIST
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

export const listLoading = (bool = false) => ({
    type: types.LOADED_CAMERA_SETTINGS,
    payload: bool
});

export const loadEventsPopup = (page = 1, limit = 10, params= {}, fnCallback, fnLoading) => ({
    type: types.GET_EVENTS_POPUP,
    payload: { page, limit, params },
    fnCallback,
    fnLoading
});

export const loadCameraStatuses = () => ({
    type: types.LOAD_STATUSES
});

export const loadedCameraStatuses = (statuses) => ({
    type: types.LOADED_STATUSES,
    payload: statuses,
});

export const loadedCameraGrzPoints = (data) => ({
    type: types.LOADED_CAMERA_GRZ_POINTS,
    payload: data,
});

export const loadCameraGrzPoints = (params = {}) => ({
    type: types.LOAD_CAMERA_GRZ_POINTS,
    payload: params,
});

export const loadingCameraGrzPoints = (bool = false) => ({
    type: types.LOADING_CAMERA_GRZ_POINTS,
    payload: bool,
});

export const downLoadCameraEvents = (params = {}) => ({
    type: types.DOWNLOAD_CAMERA_EVENTS,
    payload: params,
});

export const clearCameraGrzPoints = () => ({
    type: types.CLEAR_CAMERA_GRZ_POINTS,
});

export const wsReplaceData = (events) => ({
    type: types.WS_REPLACE_DATA,
    payload: events,
});

export const setVAReportFilter = (data) => ({
    type: types.SET_VA_REPORT_FILTER,
    payload: data,
});

export const clearVAReportFilter = () => ({
    type: types.CLEAR_VA_REPORT_FILTER,
});

export const getEventsFilters = () => ({
    type: types.GET_EVENTS_FILTERS,
});

export const loadedEventsFilters = (data) => ({
    type: types.LOADED_EVENTS_FILTERS,
    payload: data,
});

export const loadCameraObjects = (page, limit, params) => ({
    type: types.LOAD_CAMERA_OBJECTS,
    payload: { page, limit, params },
});

export const loadingCameraObjects = (bool) => ({
    type: types.LOADING_CAMERA_OBJECTS,
    payload: bool,
});

export const loadedCameraObjects = (response) => ({
    type: types.LOADED_CAMERA_OBJECTS,
    payload: response,
});

export const setFilterCameraObjects = (filter) => ({
    type: types.SET_FILTER_CAMERA_OBJECTS,
    payload: filter,
});

export const clearFilterCameraObjects = () => ({
    type: types.SET_FILTER_CAMERA_OBJECTS,
    payload: {},
});

// passenger traffic report
export const loadPassengerTrafficTypes = () => ({
    type: types.LOAD_PASSENGER_TRAFFIC_TYPES,
});

export const loadedPassengerTrafficTypes = (data) => ({
    type: types.LOADED_PASSENGER_TRAFFIC_TYPES,
    payload: data,
});

export const loadPassengerTraffic = (data) => ({
    type: types.LOAD_PASSENGER_TRAFFIC,
    payload: data,
});

export const loadingPassengerTraffic = (bool) => ({
    type: types.LOADING_PASSENGER_TRAFFIC,
    payload: bool,
});

export const loadedPassengerTraffic = (data) => ({
    type: types.LOADED_PASSENGER_TRAFFIC,
    payload: data,
});

export const clearPassengerTraffic = () => ({
    type: types.LOADED_PASSENGER_TRAFFIC,
    payload: {},
});

export const setPassengerTrafficFilter = (filter = {}) => ({
    type: types.SET_PASSENGER_TRAFFIC_FILTER,
    payload: filter,
});

export const loadInOutCamerasReport = (filter) => ({
    type: types.LOAD_IN_OUT_CAMERAS_REPORT,
    payload: filter
});

export const loadingInOutCamerasReport = (bool = false) => ({
    type: types.LOADING_IN_OUT_CAMERAS_REPORT,
    payload: bool
});

export const loadedInOutCamerasReport = (data) => ({
    type: types.LOADED_IN_OUT_CAMERAS_REPORT,
    payload: data
});

export const clearInOutCamerasReport = () => ({
    type: types.CLEAR_IN_OUT_CAMERAS_REPORT,
});

export const loadCamerasByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_CAMERAS_BY_RADIUS,
    payload: data,
    callback
});

export const loadedCamerasByRadius = (data) => ({
    type: types.LOADED_CAMERAS_BY_RADIUS,
    payload: data
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});

export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});

export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});

export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

export const saveExcludedKeys = (data = []) => ({
    type: types.SAVE_EXCLUDED_KEYS,
    payload: data
});
