import { fullDateTime } from 'helpers/date.config';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';

import type { Log } from './types';

interface ItemProps {
    item: Log;
}

const Item = ({ item }: ItemProps) => {
    return (
        <LSContentItem>
            <LSContentColumn>
                {fullDateTime(item.date_at) || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.service || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.url || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.method || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item?.code || messages.NO_VALUE}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
