import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Print from '@mui/icons-material/Print';
import Save from '@mui/icons-material/Save';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    Button,
    ButtonGroup,
    Tooltip
} from '@mui/material';
import cn from 'classnames';

import { loadComplexEventArchive, loadCurrentEventList } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { compareDatesByParams, fullDateTimeWithTimeZone, getDateWithDuration } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import Loading from 'components/common/Loading';
import CommonImage from 'components/common/Image';
import Modal from 'components/common/Modal';
import useMediaFiles from 'helpers/hooks/useMediaFiles';
import { config } from 'config';

import PrintInfo from './PrintInfo';
import styles from './mediaModal.module.scss';

// компонент с пролистыванием вперед-назад
// подгрузку новых делаем тут же и храним весь список локально
const MediaModalList = ({
    isOpen,
    onClose,
    item = {},
    renderLabels,
    initialList = [],
    limit,
    meta,
    itemNum,
    filter = {},
    loadListAction = loadCurrentEventList,
    saveArchiveAction = loadComplexEventArchive,
    loadingProps = false,
    readOnly = false, // если надо показать только один эвент без перехода по событиям влево и вправо
    // needLoadFullObject = false,
}) => {
    const project = config.project;

    const dispatch = useDispatch();
    const loading = useSelector(dorisControlSelectors.loadingEvent) || loadingProps;
    const [currentMeta, setCurrentMeta] = useState(meta);
    const [eventList, setEventList] = useState(initialList);
    const [activeEvent, setActiveEvent] = useState(item);
    const [activeEventIndex, setActiveEventIndex] = useState(itemNum);

    // блокируем next если это последний эвент
    const disableshowNext = () => (activeEventIndex === eventList.length - 1 && currentMeta.current_page === currentMeta.last_page) || loading;
    const disableShowPrev = () => activeEventIndex < 0 || loading;

    useEffect(() => {
        return () => setEventList([]);
    }, []);

    // больше не загружаем полный объект все данные есть в событии
    // const { uid, base_name, created_at } = item;

    // useEffect(() => {
    //     if (needLoadFullObject) {
    //         dispatch(loadEventObject(uid || base_name, { created_at }, setActiveEvent));
    //     }
    // }, [uid, base_name, created_at, needLoadFullObject, dispatch]);


    const [allMediaItem, setAllMediaItem] = useState([]);
    const [activeMediaItem, setActiveMediaItem] = useState({});

    const allMedia = useMediaFiles(activeEvent);

    useEffect(() => {
        if (allMedia.length > 0) {
            allMedia.map((el) => {
                const img = new Image();
                img.onload = function() {
                    el.width = img.width; // для установления макс высоты, пока не работает
                };
                img.src = el.src;
                return el;
            });
        }
        setAllMediaItem(allMedia);
        setActiveMediaItem(allMedia[0]);
    }, [allMedia]);

    const showNext = () => {
        if (activeEventIndex === eventList.length - 1 && currentMeta.current_page !== currentMeta.last_page) {
            // если это последний элемент, подгружаем следующую часть списка
            // без времени начала, конец на 1 секунду раньше последнего эвента
            dispatch(loadListAction(currentMeta.current_page + 1, limit, filter, (list) => {
                const { data: newData, meta: newMeta } = list;
                if (newData?.length > 0) {
                    // добавляем новый список в конец
                    setEventList((prev) => ([
                        ...prev,
                        ...newData,
                    ]));
                    setCurrentMeta(newMeta);
                    // устанавливаем активный первый элемент из полученного списка
                    // и увеличиваем текущий индекс
                    setActiveEvent(newData[0]);
                    setActiveEventIndex(activeEventIndex + 1);
                } else {
                    setActiveEvent({});
                    setActiveEventIndex(activeEventIndex + 1); // устанавливаем активный индекс на +1 чтобы можно было потом вернуться на предыдущий эл-т
                }
            }));
        } else {
            // устанавливаем активный элемент и индекс на +1
            setActiveEvent(eventList[activeEventIndex + 1]);
            setActiveEventIndex(activeEventIndex + 1);
        }
    };

    const showPrevious = () => {
        if (activeEventIndex === 0) {
            // если это первый элемент, подгружаем какую-то более свежую часть списка
            // начиная со времени на 1 секунду позже первого эвента, без времени конца
            const newStartDate = fullDateTimeWithTimeZone(getDateWithDuration({ seconds: 1 }, activeEvent?.created_at));
            // проверяем что новая дата укладывается в интервал дат из фильтра - она должна быть меньше чем end_date
            const compare = compareDatesByParams(newStartDate, filter?.end_date);
            if (compare.milliseconds <= 0) {
                dispatch(loadListAction(1, limit, filter, (list) => {
                    const { data: newData, meta: newMeta } = list;
                    if (newData?.length > 0){
                    // добавляем новый список в начало
                        setEventList((prev) => ([
                            ...newData,
                            ...prev,
                        ]));
                        setCurrentMeta(newMeta);
                        // устанавливаем активный последний элемент из полученного списка
                        // и устанавливаем текущий индекс равный последнему элементу нового списка
                        setActiveEvent(newData[newData.length - 1]);
                        setActiveEventIndex(newData.length - 1);
                    } else {
                        setActiveEvent({});
                        setActiveEventIndex(activeEventIndex - 1); // уходим в минус 1, чтобы потом обратно получить 0
                    }
                }));
            } else {
                setActiveEvent({});
                setActiveEventIndex(activeEventIndex - 1); // уходим в минус 1, чтобы потом обратно получить 0
            }
        } else {
            // устанавливаем активный элемент и индекс на -1
            setActiveEvent(eventList[activeEventIndex - 1]);
            setActiveEventIndex(activeEventIndex - 1);
        }
    };

    const componentRef = useRef();
    const printImg = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
    });

    const openNewWindow = () => window.open(activeMediaItem.src);

    const title = <div className={styles.navigationBar}>
        <ButtonGroup className={styles.navigationBar__item} size="small">
            {saveArchiveAction && (
                <Tooltip title="Сохранить в архив">
                    <span>
                        <Button onClick={() => {
                            dispatch(saveArchiveAction(activeEvent.uid, { created_at: activeEvent.created_at, format: 'tar' }));
                        }} size="small">
                            <Save />
                        </Button>
                    </span>
                </Tooltip>
            )}
            <Tooltip title={activeMediaItem?.type === 'video' ? 'Видео невозможно распечатать' : 'На печать'}>
                <span>
                    <Button
                        disabled={activeMediaItem?.type === 'video'}
                        onClick={printImg} size="small"
                    >
                        <Print />
                    </Button>
                </span>
            </Tooltip>
        </ButtonGroup>
    </div>;

    return (
        <Modal
            fullWidth
            isOpen={isOpen}
            noPadding
            onClose={onClose}
            showCloseInTitle
            title={project?.code === '34_vlg_vlz' ? '' : title}
        >
            <div style={{ display: 'flex', height: '100%' }}>
                {!readOnly
                    && <Button
                        disabled={disableShowPrev()}
                        onClick={showPrevious}
                    >
                        <ArrowBackIosIcon />
                    </Button>
                }
                { activeEvent && Object.keys(activeEvent).length > 0
                    ? <div className={styles.mediaModalContainer} style={{ flex: 1 }}>
                        {loading && <Loading circular/>}

                        <div className={styles.content}>
                            {allMediaItem.length > 0
                                ? <>
                                    <div className={styles.sidebar}>
                                        <h2 className={styles.sidebar__header}>
                                            Медиафайлы
                                        </h2>
                                        <div className={styles.sidebar__content}>
                                            {allMediaItem.map((el, i) => (
                                                <div
                                                    className={cn(styles.mediaBlock, activeMediaItem?.src === el.src && styles.active)}
                                                    key={i}
                                                    onClick={() => setActiveMediaItem(el)}
                                                >
                                                    {el.type === 'image'
                                                        ? <CommonImage
                                                            className={styles.media}
                                                            src={el.src}
                                                        />
                                                        : el.type === 'video'
                                                            ? <video
                                                                src={el.src}
                                                                className={styles.media}
                                                            />
                                                            : null
                                                    }
                                                    {el.type === 'video'
                                                        && <div className={styles.playArrow}>
                                                            <PlayArrow fontSize="large"/>
                                                        </div>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={styles.mainContentBlock}>
                                        {activeMediaItem?.type === 'image'
                                            ? <CommonImage
                                                src={activeMediaItem?.src}
                                                onClick={openNewWindow}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            : activeMediaItem?.type === 'video'
                                                ? <video
                                                    controls
                                                    src={activeMediaItem?.src}
                                                />
                                                : null
                                        }
                                    </div>
                                </>
                                : <div>{messages.NO_MATERIALS}</div>
                            }
                        </div>
                        <div className={styles.footer}>
                            {renderLabels(activeEvent)}
                        </div>
                    </div>
                    : !loading
                        && <div className={styles.mediaModalContainer} style={{ flex: 1 }}>
                            Больше нет зарегистрированных событий в указанном периоде
                        </div>
                }

                {!readOnly
                    && <Button
                        disabled={disableshowNext()}
                        onClick={showNext}
                    >
                        <ArrowForwardIosIcon />
                    </Button>
                }
            </div>
            <div ref={componentRef}>
                <PrintInfo
                    activeItem={activeMediaItem}
                    renderLabels={renderLabels(activeEvent)}
                />
            </div>
        </Modal>
    );
};

export default MediaModalList;
