import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';

const MonthlyReportCPVF = () => {
    return (<PageLayout
        header={'Отчет по времени работы КФВФ по балансодержателям'}
        filters={<Filter />}
    />);
};

export default MonthlyReportCPVF;