import { FormControl, TextField } from '@mui/material';

import formatDate from 'helpers/constants/dateFormat';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';
import InfoBlock from 'components/common/InfoBlock';
import Loading from 'components/common/Loading';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressOne from 'components/common/Location/AddressOne';

// todo добавить загрузку фото по дтп, вынести в константы названия полей
const Form = (props) => {
    const {
        data = {},
        onChange,
        requiredFields,
        validation,
        loading,
    } = props;

    const handleEvent = (key) => (newValue) => {
        const { target: { value } } = newValue;

        onChange({
            [key]: value
        });
    };

    // const handleLocationChange = (props) => {
    //     onChange({
    //         ...props
    //     });
    // };

    const handleDateChange = (key, value) => {
        onChange({
            [key]: value
        });
    };

    const getValue = (key, def = '') => data[key] ? data[key] : def;

    const isErrorField = (key) => {
        return (requiredFields.includes(key) && !data[key]) || validation.isKey(key);
    };

    const getErrorValidation = (key) => validation.get(key);

    const onLocationChange = (props) => {
        onChange({
            ...props
        });
        // validation.deleteKeys(Object.keys(props));
    };

    return <>
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <InfoBlock label={titles.LOCATION} className="block">
                <FormControl className="block" variant="outlined">
                    <LatLonCoordinates
                        lat={data?.lat}
                        lon={data?.lon}
                        onChange={onLocationChange}
                        required
                    />
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <LoadAddressByCoords {...data} onChange={onLocationChange}>
                        <AddressOne required />
                    </LoadAddressByCoords>
                </FormControl>

                <div className="block">
                    <FieldsModal
                        title="Поставьте/передвиньте метку на карте"
                        buttonText={buttons.SELECT}
                        buttonVariant="contained"
                        fields={data}
                        onChange={onLocationChange}
                    >
                        <MapDragMarker required />
                    </FieldsModal>
                </div>
            </InfoBlock>

            <div className="block">
                <SingleKeyboardDateTimePicker
                    label="Дата регистрации"
                    onChange={(value) => handleDateChange('registered_at', value)}
                    value={getValue('registered_at', null)}
                    error={isErrorField('registered_at')}
                    helperText={getErrorValidation('registered_at')}
                    pickerFormat={formatDate.DT_EXCEPT_SECONDS}
                />
            </div>

            <div className="block">
                <TextField
                    size="small"
                    label="Принадлежность"
                    variant="outlined"
                    value={getValue('accessory')}
                    onChange={handleEvent('accessory')}
                    error={isErrorField('accessory')}
                    helperText={getErrorValidation('accessory')}
                />
            </div>

            {loading && <Loading linear={true}/>}
        </form>
    </>;
};

export default Form;
