import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    ListItem,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import {
    createPillar,
    editPillar,
    loadVideoCamerasDictionaryReliance
} from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import UploadImageField from 'components/common/Upload/UploadImageField';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';

import type { PillarsItem } from 'types/RoadNetwork';

interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    reloadList: () => void
    isNew?: boolean
    item?: PillarsItem
}

const ModalForm = ({ isOpen, isNew, onClose, reloadList, item }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(roadNetworksSelectors.loadingButton);
    const types = useStoreProp(
        loadVideoCamerasDictionaryReliance,
        'roadNetworks',
        'videoCamerasDictionaryReliance'
    );

    const initialState = {
        name: item?.name || '',
        type: item?.type || null,
        lat: item?.lat || '',
        lon: item?.lon || '',
        address: item?.address || null,
        address_text: item?.address_text || '',
        geometry: item?.geometry || null,
        additional_info: item?.additional_info || '',
        organization_id: item?.organization_id || null,
        organization_contacts: item?.organization_id
            ? { ...item?.organization_contacts, id: item?.organization_id }
            : null,
        contract_number: item?.contract_number || '',
        contract_at: item?.contract_at || null,
        custom_icon: item?.custom_icon || ''
    };

    const [data, setData] = useState(initialState);

    const handleChange = (name: string, value: any) => {
        setData(prev => ({ ...prev, [name]: value }));
        validation.deleteKey(name);
    };

    const onChangeCoordinates = (params: any) => {
        setData({
            ...data,
            ...params
        });
        validation.deleteKeys(Object.keys(params));
    };

    const onSave = () => {
        const prepareData = removeEmptyFields({
            ...data,
            organization_id: data?.organization_contacts?.id || null,
            smr_guarantee: dateWithDashYYYYMMDD(data?.contract_at),
        }, false);

        const callback = () => { onClose(); reloadList(); };

        isNew
            ? dispatch(createPillar(prepareData, callback))
            : dispatch(editPillar(item?.id, prepareData, callback));
    };

    const isDisabled = !data.name
        || !data.type
        || !data.lat
        || !data.lon
        || !data.address
        || !data.address_text
        || !data.geometry;

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding={true}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            loading: loadingButton,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <form className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name || ''}
                        name="name"
                        onChange={(e) => handleChange('name', e.target.value)}
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        required={true}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('type')}
                    helperText={validation.get('type')}
                >
                    <FormControl
                        size="small"
                        variant="outlined"
                        required
                    >
                        <InputLabel>{titles.TYPE}</InputLabel>
                        <Select
                            value={data.type || ''}
                            name="type"
                            onChange={(e) => handleChange('type', e.target.value)}
                            label={titles.TYPE}
                        >
                            {Object.keys(types).map((key) => (
                                <MenuItem key={key} value={key}>{types[key]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>

                <FormControl className="block" variant="outlined">
                    <LatLonCoordinates
                        lat={data.lat}
                        lon={data.lon}
                        onChange={onChangeCoordinates}
                        required
                    />
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <LoadAddressByCoords
                        {...data}
                        onChange={onChangeCoordinates}
                    >
                        <AddressList required returnGeometry/>
                    </LoadAddressByCoords>
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <MapGeoJson
                        visibleGeometry={data.geometry || {}}
                        onChange={onChangeCoordinates}
                        required
                        returnLatLon
                        marker={true}
                        circle={true}
                        polygon={false}
                        polyline={false}
                        isHideValidation
                        validationK
                    />
                </FormControl>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('additional_info')}
                    helperText={validation.get('additional_info')}
                >
                    <TextField
                        value={data?.additional_info || ''}
                        name="additional_info"
                        onChange={(e) => handleChange('additional_info', e.target.value)}
                        label={'Доп. информация'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <SelectCompany
                    className="block"
                    label={'Собственник'}
                    selected={data.organization_contacts}
                    onChange={(value) => handleChange('organization_contacts', value)}
                    selectDeletedCompanies
                    error={validation.isKey('organization_id')}
                    helperText={validation.get('organization_id')}
                />

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('contract_number')}
                    helperText={validation.get('contract_number')}
                >
                    <TextField
                        value={data.contract_number || ''}
                        name="contract_number"
                        onChange={(e) => handleChange('contract_number', e.target.value)}
                        label={'Номер договора'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <SingleKeyboardDateTimePicker
                    className="block"
                    label={'Срок действия договора'}
                    onChange={(date: Date | null) => handleChange('contract_at', date)}
                    value={data.contract_at}
                    dateOnly
                    error={validation.isKey('contract_at')}
                    helperText={validation.get('contract_at')}
                />

                <ListItem divider>
                    <UploadImageField
                        value={data?.custom_icon}
                        name="custom_icon"
                        onChange={handleChange}
                        errorText={validation.get('custom_icon')}
                    />
                </ListItem>
            </form>
        </Modal>
    );
};

export default ModalForm;
