export const digitalTwin = (state) => state.digitalTwin;

export const types = (state) => digitalTwin(state).types;

export const nodes = (state) => digitalTwin(state).nodes;
export const nodesData = (state) => nodes(state).data;
export const nodesMeta = (state) => nodes(state).meta;
export const loadingNodes = (state) => digitalTwin(state).loadingNodes;

export const filters = (state) => digitalTwin(state).filters;
export const active = (state) => digitalTwin(state).active;

export const saved = (state) => digitalTwin(state).saved;

export const deleteForm = (state) => digitalTwin(state).deleteForm;
export const editForm = (state) => digitalTwin(state).editForm;

export const edges = (state) => digitalTwin(state).edges;
export const edgesData = (state) => edges(state).data;
export const edgesMeta = (state) => edges(state).meta;
export const loadingEdges = (state) => digitalTwin(state).loadingEdges;

export const edgeTypes = (state) => digitalTwin(state).edgeTypes;
export const loadingEdgeTypes = (state) => digitalTwin(state).loadingEdgeTypes;

export const edgeClasses = (state) => digitalTwin(state).edgeClasses;
export const loadingEdgeClasses = (state) => digitalTwin(state).loadingEdgeClasses;

export const loadingButton = (state) => digitalTwin(state).loadingButton;
