export const menu = (state) => state.menu;

export const menuList = (state) => menu(state).menuList;
export const loading = (state) => menu(state).loading;
// export const activeId = (state) => menu(state).activeId;
export const activeLayers = (state) => menu(state).activeLayers;
export const selectedLayer = (state) => menu(state).selectedLayer;
export const attachList = (state) => menu(state).attachList;
export const showReturnTo = (state) => menu(state).showReturnTo;

export const pageHeaders = (state) => menu(state).pageHeaders;
