import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import Information from 'components/common/Information';

const FieldsList = ({
    data,
}) => {
    const fields = {
        name: {
            title: titles.NAME,
        },
        category_text: {
            title: 'Категория',
        },
        type_text: {
            title: titles.TYPE,
        },
        serial_number: {
            title: titles.SERIAL_NUMBER,
        },
        status_text:{
            title: titles.STATUS,
        },
        address_text:{
            title: titles.ADDRESS,
        },
    };

    return (
        <Information
            data={data}
            title={fields}
            defaultValue={messages.INFO_IS_NOT_FOUND}
            returnNull
        />
    );
};

export default FieldsList;
