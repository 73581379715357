import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, } from 'react-router-dom';

import { changeMapCenter, saveLastMapCenter } from 'redux/Map/actions';
import { mapSelectors } from 'redux/Map';
import {
    CustomControl,
    Map,
    MapControl,
} from 'components/MapComponents/leaflet';
import MapSearch from 'components/common/MapContlols/MapSearch';
import MapRulerSquare from 'components/common/MapContlols/MapRulerSquare';
import { config } from 'config';

import MapContainer from '../MapContainer';

import ReturnTo from './ReturnTo';


const MapLayer = (props) => {
    // const { history } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const mapCenter = useSelector(mapSelectors.mapCenter);
    const lastMapCenter = useSelector(mapSelectors.lastMapCenter);
    const mapCenterCoord = mapCenter?.coord || null;
    const mapCenterZoom = mapCenter?.zoom || null;
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (lastMapCenter) {
            // при создании - заменяем центр карты
            dispatch(changeMapCenter(lastMapCenter.coord, lastMapCenter.zoom));
        }
        setVisible(true);
    }, []);

    return visible ? (
        <>
            {/* карта */}
            <Map
                center={mapCenterCoord || config.get('mapCenter')}
                zoom={mapCenterZoom}
                onMoveEnd={(map) => {
                    const { lat, lng } = map.getCenter();
                    const data = {
                        coord: [lat, lng],
                        zoom: map.getZoom()
                    };
                    dispatch(saveLastMapCenter(data));
                }}
            >
                {/* стандартные компоненты управления картой */}
                <MapControl>
                    {/* подключение поиска на карте */}
                    <CustomControl>
                        <MapSearch />
                    </CustomControl>

                    {/* вернуться в дашборд */}
                    <CustomControl>
                        <ReturnTo history={history} />
                    </CustomControl>

                    {/* линейка */}
                    <CustomControl>
                        <MapRulerSquare/>
                    </CustomControl>

                    {/* контейнер карты */}
                    <MapContainer />
                </MapControl>
            </Map>
        </>
    ) : null;
};

export default MapLayer;
