import { memo } from 'react';

import { RenderPie } from './Graphs/Pie';
import { RenderBar } from './Graphs/Bar';
import { RenderScatter } from './Graphs/Scatter';

const Graph = ({ filter, data }) => {
    return (
        <>
            {data.map((item) => {

                return (
                    <div key={item.type} style={{ padding: '1rem 0', borderBottom: '1px #ccc solid' }}>
                        <h2 style={{ textAlign: 'center', padding: '1rem 0' }}>{item.type}</h2>
                        {filter.pie
                        && <RenderPie
                            data={item.data}
                        />
                        }
                        {filter.bar
                            && <RenderBar
                                data={item.data}
                            />
                        }
                        {filter.scatter
                            && <RenderScatter
                                data={item.data}
                            />
                        }
                        {item.comment && <div><strong>Комментарий:</strong> {item.comment}</div>}
                    </div>

                );
            }

            )}
        </>
    );
};

export default memo(Graph);
