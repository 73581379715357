import { useState } from 'react';
import { TextField } from '@mui/material';

import { loadEventObjectsTypes } from 'modules/React/redux/actions';
import KeyWords from 'components/common/Autocomplete/DorisReact/KeyWords';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectEventObjectsTypes from 'modules/React/utils/components/SelectEventObjectTypes';

const Filter = ({ initialState, setParams, }) => {
    const types = useStoreProp(loadEventObjectsTypes, 'react', 'eventObjectsTypes');

    const [filterData, setFilterData] = useState(initialState);

    const isDisabled = !filterData.address
        && !filterData.keyword_id_list.length
        && !filterData?.event_id
        && !filterData.name
        && !filterData.type_id?.id;

    const handleSearch = () => {
        setParams(filterData);
    };
    const updateFilter = (filters) => {
        setFilterData({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ ...initialState, ...filters });
        }
    };

    const resetFilter = (needRefresh) => {
        setFilterData(initialState);

        if (needRefresh) {
            setParams(initialState);
        }
    };


    const onChange = (name, value) => {
        setFilterData({
            ...filterData,
            [name]: value
        });
    };

    return (
        <LayoutFilter
            filter={filterData}
            onSearch={handleSearch}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={filterData.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                />
                <SelectEventObjectsTypes
                    selected={filterData.type_id}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={types}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={(value) => onChange('type_id', value)}

                />

                {/*<SelectEvents*/}
                {/*    selected={data.event}*/}
                {/*    onChange={(value) => setData({*/}
                {/*        ...data,*/}
                {/*        event: value,*/}
                {/*        event_id: value?.id || null*/}
                {/*    })}*/}
                {/*    label={titles.EVENT}*/}
                {/*/>*/}
                <KeyWords
                    multiple
                    selected={filterData.keyword_list}
                    onChange={(value) => setFilterData({
                        ...filterData,
                        keyword_list: value,
                        keyword_id_list: value.map(({ id }) => id)
                    })}
                />
                <TextField
                    label={titles.ADDRESS}
                    size="small"
                    value={filterData.address}
                    variant="outlined"
                    name="address"
                    type="text"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
