import { useState } from 'react';
import { useDispatch } from 'react-redux';

import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { ActionMore, ActionsButtons } from 'components/common/List';
import GeoJSONMap from 'components/common/Location/MapGeoJson';
import Modal from 'components/common/Modal';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import EditorForm from 'modules/InfrastructureObjects/Dictionaries/RoadSections/EditorForm';
import { deleteRoadSection, editRoadSection, setEditForm } from 'modules/InfrastructureObjects/redux/actions';

import type { RoadSection } from './types';

interface ActionsProps {
    item: RoadSection;
    reloadList: () => void;
    toggleInfo: () => void;
}

function Actions({ item, reloadList, toggleInfo }: ActionsProps) {
    const dispatch = useDispatch();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [isInfo, setIsInfo] = useState(false);

    const handleCloseEditForm = () => {
        dispatch(setEditForm());
        setEditOpen(false);
    };

    const handleInfo = () => {
        setIsInfo(!isInfo);
        toggleInfo();
    };

    const handleDelete = () => {
        dispatch(deleteRoadSection(item.id, () => {
            reloadList();
            setConfirmOpen(false);
        }));
    };

    return (
        <>
            <div className="flex-end">
                <ActionsButtons
                    buttons={[
                        {
                            icon: <i className="fas fa-map-marked"/>,
                            name: 'Посмотреть на карте',
                            onClick: () => setOpenMap(true),
                        },
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setEditOpen(true),
                            disabled: item.status === 3
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setConfirmOpen(true),
                        }
                    ]}
                />

                <ActionMore
                    isOpen={isInfo}
                    onClick={handleInfo}
                />
            </div>

            <Modal
                isOpen={openMap}
                onClose={() => setOpenMap(false)}
                noPadding
                title={item?.name || ''}
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenMap(false)
                            },
                        ]}
                    />
                }
            >
                <GeoJSONMap
                    geometry={item?.geometry}
                    readOnly={true}
                />
            </Modal>

            {confirmOpen && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={confirmOpen}
                    onSuccess={handleDelete}
                    onClose={() => setConfirmOpen(false)}
                />
            )}

            {editOpen && (
                <EditorForm
                    noPadding
                    title={titles.EDIT}
                    isOpen={editOpen}
                    dataForm={item}
                    onClose={handleCloseEditForm}
                    onSuccess={(editValue: any) => {
                        dispatch(editRoadSection(editValue.id, editValue, () => {
                            reloadList();
                            setEditOpen(false);
                        }));
                    }}
                    inModal
                    isNew={false}
                />
            )}
        </>
    );
}

export default Actions;
