import { memo, useMemo } from 'react';

import { useStoreProp } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { loadThreatLevels } from 'modules/React/redux/actions';

import config from './config';

const Legend = () => {

    const threatLevels = useStoreProp(loadThreatLevels, 'react', 'threatLevels');

    const list = useMemo(() => threatLevels
        ?.data
        ?.map(({ id, name, color }) => (
            <div
                key={id}
                className="flex"
                style={{ width: '200px', alignItems: 'start' }}
            >
                <div
                    className="image"
                    style={{
                        height: 'auto',
                        width: 'auto',
                        alignItems: 'top',
                    }}
                >
                    <i
                        className={config.layerIcon}
                        style={{
                            fontSize: '1rem',
                            color: colorExtend(color || '')
                        }}
                    />
                </div>

                <div className="small">
                    - {name}
                </div>
            </div>
        )), [threatLevels]);

    return (
        <div className="sidebar-tp-legend">
            <div className="item">
                <div className="title">
                    Уровни угрозы
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {list}
                </div>
            </div>
        </div>
    );
};

export default memo(Legend);
