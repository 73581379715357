import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import { TextField } from '@mui/material';

import { getEndOf, getStartOf } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import StatusesSelect from 'components/common/Autocomplete/Statuses';
import AutocompleteCompanies from 'components/common/Autocomplete/Companies';
import { loadIncidentsStatuses } from 'modules/React/redux/actions';
import SelectScenarios from 'modules/React/utils/components/SelectScenarios';
import SelectThreatLevels from 'modules/React/utils/components/SelectThreatLevels';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';
import SelectKeywords from 'modules/React/utils/components/SelectKeywords';
import SelectIncidentCategories from 'modules/React/utils/components/SelectIncidentCategories';

import type { FilterParams } from './types';

type FiltersProps = {
  setParams: Dispatch<SetStateAction<FilterParams>>;
}

const initState: FilterParams['data'] = {
    start_date: getStartOf('month'),
    end_date: getEndOf('day'),
    incident_id: '',
    name: '',
    address_text: '',
    threat_level_id_list: [],
    status_id_list: [],
    incident_types: [],
    organizations: [],
    response_scenario_id: [],
    plan_date_start: null,
    plan_date_end: null,
    fact_date_start: null,
    fact_date_end: null,
    keyword_id_list: null,
    dtp_ids: [],
    category_id: null
};

export const Filters = ({ setParams }: FiltersProps) => {
    const statuses = useStoreProp(loadIncidentsStatuses, 'react', 'incidentsStatuses');

    const [filter, setFilter] = useState<FilterParams['data']>(initState);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    //TODO - придумать как избавиться от any
    const onChangeByKeyValue = (value: any, key: keyof FilterParams['data']) => {
        setFilter({ ...filter, [key]: value });
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams(prev => ({ ...prev, page: 1, data: initState }));
        }

        setFilter(initState);
    };

    const onSearch = () => {
        setParams(prev => ({ ...prev, page: 1, data: filter }));
    };

    const updateFilter = (filters: FilterParams['data']) => {
        setFilter({ ...initState, ...filters });

        setParams(prev => ({ ...prev, page: 1, data: { ...initState, ...filters } }));
    };

    return (
        <LayoutFilter
            filter={filter}
            onResetFilter={resetFilter}
            onSearch={onSearch}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filter?.start_date || null}
                    valueEndDate={filter?.end_date || null}
                    handleDate={(start_date) => setFilter({ ...filter, start_date })}
                    handleEndDate={(end_date) => setFilter({ ...filter, end_date: getEndOf('day', end_date) })}
                    dateOnly={true}
                    equalDates={true}
                />
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={filter.name}
                    name="name"
                    onChange={onChange}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <TextField
                    variant="outlined"
                    value={filter.dtp_ids}
                    onChange={onChange}
                    label="ДТП ID"
                    size="small"
                    name="dtp_ids"
                />
                <TextField
                    label={titles.ADDRESS}
                    variant="outlined"
                    size="small"
                    value={filter.address_text}
                    name="address_text"
                    onChange={onChange}
                    // className="grid-column-2-3"
                />

                <SelectThreatLevels
                    multiple
                    selected={filter?.threat_level_id_list || []}
                    onChange={(value) => onChangeByKeyValue(value, 'threat_level_id_list')}
                />

                <StatusesSelect
                    multiple
                    selected={filter?.status_id_list || null}
                    options={statuses}
                    renderLabel={(option) => option?.name || ''}
                    onChange={(value) => onChangeByKeyValue(value, 'status_id_list')}
                    filterSelectedOptions
                    isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                />

                <SelectIncidentTypes
                    multiple
                    selected={filter?.incident_types || null}
                    onChange={(val) => {
                        onChangeByKeyValue(val, 'incident_types');
                    }}
                    label="Тип"
                />

                <AutocompleteCompanies
                    onChange={(value) => {
                        // @ts-ignore
                        onChangeByKeyValue(value?.map((item) => ({ ...item, name: item.title })), 'organizations');
                    }}
                    selected={filter?.organizations || []}
                    multiple
                    filter={{ withDeleted: 1 }}
                    label={'Организация'}
                />

                <SelectScenarios
                    multiple
                    selected={filter.response_scenario_id || null}
                    onChange={(value) => onChangeByKeyValue(value, 'response_scenario_id')}
                />

                <SelectKeywords
                    multiple
                    selected={filter.keyword_id_list || []}
                    onChange={(value) => onChangeByKeyValue(value, 'keyword_id_list')}
                />

                <SelectIncidentCategories
                    selected={filter?.category_id || null}
                    onChange={(value) => onChangeByKeyValue(value, 'category_id')}
                />

                <DateRange
                    valueStartDate={filter?.plan_date_start || null}
                    valueEndDate={filter?.plan_date_end || null}
                    handleDate={(plan_date_start) => setFilter({ ...filter, plan_date_start })}
                    handleEndDate={(plan_date_end) => setFilter({ ...filter, plan_date_end })}
                    dateOnly={true}
                    title="Дата закрытия план"
                    // renderError={() => isPeriodInvalid}
                    // errorMessage={() => titles.SELECT_PERIOD}
                    className="grid-column-1-2"
                />

                <DateRange
                    valueStartDate={filter?.fact_date_start || null}
                    valueEndDate={filter?.fact_date_end || null}
                    handleDate={(fact_date_start) => setFilter({ ...filter, fact_date_start })}
                    handleEndDate={(fact_date_end) => setFilter({ ...filter, fact_date_end })}
                    dateOnly={true}
                    title="Дата закрытия факт"
                    // renderError={() => isPeriodInvalid}
                    // errorMessage={() => titles.SELECT_PERIOD}
                    className="grid-column-1-2"
                />
            </LayoutFilter.Invisible>

        </LayoutFilter>
    );
};
