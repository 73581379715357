import React from 'react';

import messages from 'helpers/constants/messages';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import type { PhenomenonsItem } from 'modules/React/Dictionaries/Triggers/types';

import PhenomenonForm from './Form';

interface PhenomenonProps {
    item: PhenomenonsItem;
    index: number;
    handleEdit: (index: number, item: PhenomenonsItem) => void;
    handleDelete: (index: number) => void;
}

const Item = ({ item, index, handleEdit, handleDelete }: PhenomenonProps) => {
    const { phenomenon, condition, value } = item || {};
    const [openEditForm, setOpenEditForm] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);

    return <>
        <LSContentItem>
            <LSContentColumn>
                {phenomenon?.name || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {condition || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {`${value} ${phenomenon?.unit || ''} ` || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn isActions>
                <ActionsButtons
                    buttons={[
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => setOpenEditForm(true),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => setOpenDelete(true),
                        }
                    ]}
                />
            </LSContentColumn>
        </LSContentItem>
        {openEditForm && (
            <PhenomenonForm
                isOpen={openEditForm}
                onClose={() => setOpenEditForm(false)}
                item={item}
                onAccept={(value) => handleEdit(index, value)}
            />
        )}
        {openDelete && (
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={openDelete}
                onSuccess={() => handleDelete(index)}
                onClose={() => setOpenDelete(false)}
            />
        )}
    </>;
        
};

export default Item;
