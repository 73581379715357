import * as types from './types';

const initialState = {

    dashboardPage: [],
    dashboardPageLoading: false,

    userDashboard: {},
    loadingUserDashboard: false,

    dashboardsItem: {
        data: [],
        meta: {}
    },
    loadingDashboards: false,

    dashboardItemUser: [],
    loadingDashboardItemUser: false,

    userAvailableDashboardList: {},
    loadingUserAvailableDashboardList: false,
};

export default function reducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {

        case types.LOADED_DASHBOARD:
            return {
                ...state,
                dashboardPage: payload,
            };
        case types.LOADING_DASHBOARD:
            return {
                ...state,
                dashboardPageLoading: payload,
            };

        case types.LOADED_USER_DASHBOARD:
            return {
                ...state,
                userDashboard: payload,
            };

        case types.LOADING_USER_DASHBOARD:
            return {
                ...state,
                loadingUserDashboard: payload,
            };

        case types.LOADED_DASHBOARDS_ITEM: {
            return {
                ...state,
                dashboardsItem: payload
            };
        }
        case types.LOADING_DASHBOARDS: {
            return {
                ...state,
                loadingDashboards: payload
            };
        }
        case types.LOADED_DASHBOARD_ITEM_USER: {
            return {
                ...state,
                dashboardItemUser: payload
            };
        }
        case types.LOADING_DASHBOARD_ITEM_USER: {
            return {
                ...state,
                loadingDashboardItemUser: payload
            };
        }

        case types.LOADED_USER_AVAILABLE_DASHBOARD_LIST:
            return {
                ...state,
                userAvailableDashboardList: payload,
            };
        case types.LOADING_USER_AVAILABLE_DASHBOARD_LIST:
            return {
                ...state,
                loadingUserAvailableDashboardList: payload,
            };

        default:
            return state;
    }
};
