import React, { Children, useContext, useEffect, useRef, useState } from 'react';
import { __RouterContext } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Badge, Button, } from '@mui/material';

import { deleteUserFilters, saveUserFilters } from 'redux/Auth/actions';
import { authSelectors } from 'redux/Auth';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import countChanges from 'helpers/countChanges';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { parseParamsFromUrl, stringifyParamsForUrl } from 'helpers/doris.control/helper';

import styles from './styles.module.scss';
import ActionButtonsPanel from './ActionButtonsPanel';
import Visible from './Visible';
import Invisible from './Invisible';
import VisibleAdditional from './VisibleAdditional';


interface LayoutFilterProps {
    children: React.ReactNode,
    onResetFilter: (needRefresh: boolean) => void,
    onSearch: (filter?: any) => void,
    filter: any,
    filterException?: string[], // поля которые не надо сохранять пользователю на бэк (например, даты никогда не сохраняем)
    countException?: string[], // поля которые не надо считать (например, вспомогательные поля, которые выкидываются по onSearch
    setUserFilter: (filter: any) => void, // отправляем полученный с бэка фильтр в компонент на страницу,
    tab?: string | null, // если фильтр внутри таба
    layer?: string | null, // если фильтр на карте
    disabled?: boolean, // если в фильтре есть ошибки, например в датах
    buttonType?: keyof typeof buttonsTypes, // если нужна кнопка другого типа вместо show
    needSaveSearch?: boolean, // надо ли в фильтры на бэк сохранять параметры из search (?name=Complex1  и т.п.)
    isFilterVisible?: boolean, // свернут ли филььтр или открыт (начальное состояние)
    test_id_prefix?: string,
    saveButtonFilterNoDisabled?: boolean, // отвязка SaveFilterButton из ActionButtonsPanel от count
    wasSearchAtStart?: boolean // чтобы работала кнопка сбросить
    autoSave?: boolean, // автосохранение фильтра при клике на кнопку поиска
    isHideSaveBtn?: boolean,
}

// Visible - видимая часть фильтра
// Invisible - скрытая часть под кнопкой Все параметры
// VisibleAdditional - также видимая часть, которую надо расположить отдельно от Visible

const LayoutFilter = ({
    children,
    onResetFilter,
    onSearch,
    filter,
    filterException = [],
    countException = [],
    setUserFilter,
    tab = null,
    layer = null,
    disabled = false,
    buttonType = 'show',
    needSaveSearch = false,
    isFilterVisible = false,
    test_id_prefix = '',
    wasSearchAtStart = false, // TODO пересмотреть нужно ли это поле, по идее достаточно проверки на юзер-фильтры
    saveButtonFilterNoDisabled,
    autoSave = false,
    isHideSaveBtn = false,
}: LayoutFilterProps) => {
    const dispatch = useDispatch();
    const router = useContext(__RouterContext);
    // const location = useLocation();
    const location = router?.location;

    const testIdPrefix = `${test_id_prefix}:filter`;

    const userFilters = useSelector((state) => authSelectors.currentPageFilter(state, {
        page: location?.pathname || '',
        tab,
        layer,
        ...(needSaveSearch ? { search: location?.search || '' } : {}),
    }));
    const loadingUserFilters = useSelector(authSelectors.loadingUserFilters);

    const arrayChildren = Children.toArray(children);

    const visibleElements = arrayChildren.find((el ) => React.isValidElement(el) && el.type === Visible);
    const visibleAdditionalElements = arrayChildren.find((el ) => React.isValidElement(el) && el.type === VisibleAdditional);
    const invisibleElements = arrayChildren.find(el =>  React.isValidElement(el) && el.type === Invisible );

    const initialFilter = useRef(filter); // сохраняем начальное значение
    const savedUserFilter = useRef(false); // сохраняем предыдущее значение для случая сохраненного фильтра на бэке
    const visibleBlock = useRef<HTMLDivElement>(null);
    const visibleAddBlock = useRef<HTMLDivElement>(null);
    const [count, setCount] = useState(0);
    const [wasSearch, setWasSearch] = useState(wasSearchAtStart ?? false);

    useEffect(() => {
        if (userFilters && setUserFilter && !savedUserFilter.current && !loadingUserFilters) {

            // перезаписываем сохраненный фильтр - если для данной страницы ничего нет, userFilters будет {}
            savedUserFilter.current = true;
            setUserFilter(userFilters?.filter || {});

            // чтобы выполнить запрос при сбросе фильтра, если у нас были сохраненные пользовательские фильтрф
            setWasSearch(Object.keys(userFilters?.filter || {}).length > 0);
        }

    }, [userFilters, setUserFilter, wasSearch, loadingUserFilters]);

    useEffect(() => {
        return () => {
            savedUserFilter.current = false;
        };
    }, []);

    useEffect(() => {
        // TODO подумать как выкидывать из подсчета например КФВФ поле serial_number_tmp кроме 1го элемента
        const count = countChanges(filter, initialFilter.current, countException);

        setCount(count);
    }, [filter, countException]);

    useEffect(() => {
        wasSearchAtStart && setWasSearch(wasSearchAtStart );
    }, [wasSearchAtStart]);

    const initial = {
        invisibleHidden: true,
        vertical: false,
        horizontal: false,
        filerVisible: isFilterVisible || false,
    };

    const [mode, setMode] = useState(initial);

    const handleShowVertical = () => {
        setMode((prev) => ({
            ...prev,
            invisibleHidden: false,
            vertical: true,
        }));
    };
    const handleShowHorizontal = () => {
        setMode((prev) => ({
            ...prev,
            invisibleHidden: false,
            horizontal: true,
        }));
    };

    const resetFilterModes = () => {
        setMode({
            ...initial,
            filerVisible: true,
        });
    };

    // сохранение фильтра на бэк
    const saveFilter = () => {
        const filterWithoutException = Object.entries(filter).reduce((res: { [key: string]: any}, [key, value]) => {
            if (!filterException.includes(key)) {
                res[key] = value;
            }
            return res;
        }, {});
        if (Object.keys(filterWithoutException).length > 0) {
            const { page: page1, limit: limit1, ...query } = parseParamsFromUrl(location.search);
            const search = needSaveSearch
                ? `${Object.keys(query).length > 0 ? '?' : ''}${stringifyParamsForUrl(query)}` // не сохраняем в фильтр page и limit, но сохраняем прочие параметры
                : '';
            const newFilters = {
                link: `${location?.pathname}${search}`,
                filter: removeEmptyFields(filterWithoutException, false, true),
                ...(tab && { tab }),
                ...(layer && { layer }),
            };
            dispatch(saveUserFilters(newFilters));
        }
    };

    const resetFilter = () => {
        // удаление фильтра с бэка, если он там есть
        const { page: page1, limit: limit1, ...query } = parseParamsFromUrl(location?.search);
        if (userFilters?.filter && Object.keys(userFilters?.filter).length > 0) {
            const search = needSaveSearch
                ? `${Object.keys(query).length > 0 ? '?' : ''}${stringifyParamsForUrl(query)}` // не сохраняем в фильтр page и limit, но сохраняем прочие параметры
                : '';
            const newFilters = {
                link: `${location?.pathname}${search}`,
                ...(tab && { tab }),
                ...(layer && { layer }),
            };
            dispatch(deleteUserFilters(newFilters));
        }
        onResetFilter(wasSearch);
        setWasSearch(false);
        resetFilterModes();
        setCount(0);
    };

    const search = () => {
        setWasSearch(true);
        resetFilterModes();
        onSearch && onSearch();
        autoSave && saveFilter();
    };

    const setFilterVisible = () => {
        setMode({
            ...initial,
            filerVisible: !mode.filerVisible
        });
    };

    const countVisibleElements = visibleBlock.current?.childElementCount ?? 3;

    return (<div className={cn(styles.wrapper, {
        [styles.vertical]: mode.vertical,
    })}>
        <div className={cn(styles.filter, {
            [styles.intab]: tab,
            [styles.visible]:  mode.filerVisible,
        })}>
            <Badge
                badgeContent={count}
                color="error"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                invisible={mode.filerVisible}
                classes={{ badge: styles.badgeSmall }}
            >
                <Button
                    variant="text"
                    color="infoButton"
                    className={cn(styles.icon, {
                        [styles.intab]: tab
                    })}
                    onClick={setFilterVisible}
                    data-testid={`${testIdPrefix}/${mode.filerVisible ? 'hide-filters' : 'show-filters'}/button`}
                >
                    {!mode.filerVisible && <span style={{ fontSize: '0.875rem', fontWeight: 400, marginRight: '.5rem' }}>Фильтр</span>}
                    <i className={mode.filerVisible ? 'far fa-minus' : 'far fa-filter'} style={{ fontSize: '1rem', margin: 0,  }} />
                </Button>
            </Badge>
        </div>
        <div className={cn(styles.filterWrapper, {
            [styles.filterVisible]: mode.filerVisible,
            [styles.wrapperTab]: !!tab && !!location?.pathname,
            [styles.visibleWrap]: !mode.invisibleHidden,
            [styles.vertical]: mode.vertical,
            [styles.intab]: tab,
        })}>
            <div
                className={cn({
                    [styles.verticalMode]: mode.vertical,
                    [styles.visibleMode]:  !mode.invisibleHidden,
                    [styles.intab]: tab,
                    [styles.hiddenMode]: !mode.filerVisible,
                })}
            >
                <div className={cn({
                    'row': mode.invisibleHidden|| mode.horizontal,
                })}>
                    <div className={cn({
                        'row__item': (mode.invisibleHidden || mode.horizontal),
                        [styles.visible]: (!mode.invisibleHidden && mode.vertical),
                    })}>
                        <div
                            className={cn({
                                [styles.verticalBlock]: mode.vertical,
                                'filter__wrap filter__wrap__more__field': (mode.invisibleHidden || mode.horizontal),
                            })}
                            ref={visibleBlock}
                        >
                            {visibleElements}
                        </div>
                        <div
                            className={cn({
                                [styles.verticalBlock]: mode.vertical,
                                'filter__wrap filter__wrap__more__field': ((mode.invisibleHidden || mode.horizontal) && !!visibleAddBlock.current?.childElementCount),
                            })}
                            ref={visibleAddBlock}
                        >
                            {visibleAdditionalElements}
                        </div>
                        { (invisibleElements && !mode.invisibleHidden)
                            ? <div
                                className={cn({
                                    [styles.verticalBlock]: mode.vertical,
                                    [styles.horizontal]: mode.horizontal,
                                    [styles[`grid-template-columns-${countVisibleElements}`]]: mode.horizontal,
                                    'filter__wrap filter__wrap__more__field': (mode.invisibleHidden || mode.horizontal),
                                })}
                            >
                                {invisibleElements}
                            </div>
                            : null
                        }
                    </div>
                    {!mode.vertical && <div className={styles.searchButton}>
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes[buttonType],
                                    disabled: disabled,
                                    onClick: search,
                                },
                            ]}
                            positionLeft
                            noMarginLeft
                            noPadding
                            justButton
                            test_id_prefix={testIdPrefix}
                        />
                    </div>}
                </div>

                <div className="row noMargin ">
                    <ActionButtonsPanel
                        showInvisible={!!invisibleElements}
                        invisibleHidden={mode.invisibleHidden}
                        count={count}
                        onShowVertical={handleShowVertical}
                        onShowHorizontal={handleShowHorizontal}
                        onResetMode={resetFilterModes}
                        onResetFilter={resetFilter}
                        onSaveFilter={saveFilter}
                        canSave={!isHideSaveBtn && !!location?.pathname}
                        disabled={disabled}
                        noDisabled={saveButtonFilterNoDisabled}
                        testIdPrefix={testIdPrefix}
                    />
                    {mode.vertical
                        && <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes[buttonType],
                                    disabled: disabled,
                                    onClick: search,
                                },
                            ]}
                            noMarginLeft
                            noPadding
                            justButton
                            test_id_prefix={testIdPrefix}
                        />
                    }
                </div>
            </div>
        </div>
    </div>);

};

LayoutFilter.Visible = Visible;
LayoutFilter.Invisible = Invisible;
LayoutFilter.VisibleAdditional = VisibleAdditional;

export default LayoutFilter;
