import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { 
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import { createStories, editStories, loadStoryStatuses } from 'modules/React/redux/actions';

import Diagram from './Diagram';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

interface ModalFormProps {
    isOpen: boolean;
    onClose: () => void;
    reloadList: (isDelete: boolean) => void;
    isNew?: boolean;
    item?: {
        id: number;
        name: string;
        status_id: number;
        start_date: Date;
        incidents: any[]
    }
}

const ModalForm = ({ isOpen, onClose, isNew = false, item, reloadList }: ModalFormProps) => {
    const dispatch = useDispatch();
    const id = item?.id;
    const storyStatuses = useStoreProp(loadStoryStatuses, 'react', 'storyStatuses');
    const validation = useValidation();

    const [data, setData] = useState({
        name: item?.name || '',
        status_id: item?.status_id || '',
        incidents: item?.incidents || [],
        start_date: item?.start_date || fullDateTimeWithTimeZone(new Date()),
    });

    const [isDisabled, setDisabled] = useState(false);
    
    const checkIncidents = (
        incidents: Array<{ 
            connection_id?: string | number | null; 
            first_incident?: any; 
            second_incident?: any 
        }>
    ): string => {
        const allHaveConnectionId = incidents.length && incidents.every(({ connection_id }) => connection_id);
        const allHaveIncidents = incidents.length && incidents.every(({ first_incident, second_incident }) => first_incident && second_incident);
    
        return allHaveIncidents
            ? allHaveConnectionId ? '' : 'добавьте типы связи'
            : 'добавьте инциденты и типы связи';
    };

    const onAcceptModal = () => {
        const currentData = data.incidents.map((item: any) => 
            ({ 
                ...item, 
                first_incident_id: item.first_incident.id, 
                second_incident_id: item.second_incident.id
            })
        );

        isNew
            ? dispatch(createStories({ ...data, incidents: currentData }, reloadList))
            : dispatch(editStories(id, { ...data, incidents: currentData }, reloadList));

        onClose();
    };

    const onChange = (name: string, value: string | Date | null | number) => {
        setData({
            ...data,
            [name]: value
        });
        validation.clear();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.CREATE : titles.EDIT}
            noPadding
            size={'900px'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: validation.isValidation() || !data.name || !data.status_id || !data?.incidents?.length || isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        className="row__item"
                        value={data.name}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        variant="outlined"
                        size="small"
                        label={titles.NAME}
                        name="name"
                        error={validation.isKey('name')}
                        helperText={validation.get('name')}
                        required
                    />
                </div>
                <div className="block">
                    <FormControl size={'small'} variant="outlined">
                        <InputLabel>Статус</InputLabel>
                        <Select
                            value={data.status_id}
                            onChange={(e) => onChange(e.target.name, e.target.value)}
                            label="Статус"
                            name="status_id"
                        >
                            <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                            {Object.keys(storyStatuses).map((key) => (
                                <MenuItem value={key} key={key}>{storyStatuses[key]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="block">
                    <SingleKeyboardDateTimePicker
                        value={data.start_date}
                        label="Дата создания"
                        error={validation.isKey('start_date')}
                        helperText={validation.get('start_date')}
                        className="row__item"
                        isDisabled
                    />
                </div>
                <div className="block">
                    <FormInfoWrapper
                        // error={error}
                        helperText={checkIncidents(data.incidents)}
                        // className={className}
                        // test_id_prefix={`${test_id_prefix}/input`}
                    >
                        <Diagram modalData={data} onChange={(e: any) => onChange('incidents', e)} setDisabled={setDisabled}/>
                    </FormInfoWrapper>
                </div>
            </form>
        </Modal>
    );
};

export default ModalForm;