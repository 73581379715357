import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import * as actions from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious } from 'helpers/hooks';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { GeoJsonNew } from 'components/MapComponents/leaflet';
import { MapPopUpListener } from 'components/MapComponents/MapPopUp';

import { createIconMarker, getFilter } from './helper';
import config from './config.js';
import PopUpModal from './PopUpModal';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(roadworksSelectors.overlapPolygonRW);
    const active = useSelector(roadworksSelectors.activeOverlapRW);
    const filters = useSelector(roadworksSelectors.filtersOverlap);
    const loadingPolygon = useSelector(roadworksSelectors.loadingOverlapPolygonRW);
    const prevLoadingPolygon = usePrevious(loadingPolygon);

    const showPopup = useRef(0);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadOverlapPolygon(polygon, filter));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        fetchPolygon();
    }, [filters]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.resetActiveOverlapRW());

            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    useCallback(() => () => {
        dispatch(actions.clearOverlapPolygon());
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active;

            if (geometry && geometry?.geometry?.coordinates.length > 0) {
                showPopup.current = active.id;
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);
            }
        }
    }, [active]);

    useEffect(() => {
        if (
            showPopup.current
            && prevLoadingPolygon === true
            && loadingPolygon === false
        ) {
            setTimeout(() => {
                map.fire(`showBy${config.slug}${showPopup.current}`);
                showPopup.current = 0;
            }, 200);
        }
    }, [loadingPolygon, prevLoadingPolygon]);

    // собираем геообъект
    const geometry = useMemo(() => polygon
        ?.map(({ id, name, address_text, type, geometry = {} }) => ({
            ...geometry,
            properties: {
                id,
                name,
                address_text,
                type
            },
            attribution: {
                slug: config.slug,
                color: '#ff0000'
            },
            style: {
                weight: config.lineWidth.default,
            }
        }))
    , [polygon]);

    return (
        <>
            <GeoJsonNew
                {...props}
                data={geometry}
                toolTipTemplate={({ name = '' }) => <div>{name ?? ''}</div>}
                icon={({ color }) => createIconMarker(color)}
                idPrefix={config.slug}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, -5]
                }}
                onClick={(item) => {
                    // ищем элемент
                    const element = polygon?.find(i => i.id === item.id);
                    if (element) dispatch(actions.setActiveOverlapRW(element));
                }}
            />

            {/* popup */}
            <MapPopUpListener
                activeSelector={roadworksSelectors.activeOverlapRW}
                polygonSelector={roadworksSelectors.overlapPolygonRW}
            >
                <PopUpModal onClose={() => dispatch(actions.resetActiveOverlapRW())} />
            </MapPopUpListener>
        </>
    );
};

export default Layer;
