import { Checkbox, FormControlLabel } from '@mui/material';
import {
    FormControl,
    Radio,
    RadioGroup,
} from '@mui/material';

import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({
    onFilter,
    onReset,
    data,
    setData,
    initialState,
    tab
}) => {
    const handleChangeData = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const resetSearch = (needSearch) => {
        setData(initialState);

        if (needSearch) {
            onReset();
        }
    };

    const updateFilter = (filters) => {
        setData({
            ...initialState,
            ...filters,
            ...(Object.keys(filters).length > 0
                ? { type: (filters.type === undefined) ? '' : filters.type }
                : {}
            )
        });
        onFilter({
            ...initialState,
            ...filters,
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={onFilter}
            filter={data}
            setUserFilter={updateFilter}
            disabled={Object.values(data).length === 0 }
            isFilterVisible
            filterException={['start_date', 'end_date']}
            tab={tab}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChangeData('start_date', value)}
                    handleEndDate={(value) => handleChangeData('end_date', value)}
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                />
                <FormControl size="small">
                    <FormControlLabel
                        control={
                            <Checkbox
                                // name="driveways"
                                onClick={() => handleChangeData('checked', !data?.checked)}
                                checked={data?.checked}
                                size="small"
                            />
                        }
                        label="Подтвержденные"
                    />
                </FormControl>
            </LayoutFilter.Visible>
            <LayoutFilter.VisibleAdditional>
                <FormControl size="small">
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="2"
                        name="type-buttons-group"
                        value={data?.type}
                        onChange={(e) => setData(old => ({ ...old, type: e.target.value || '' }))}
                    >
                        <FormControlLabel value="2" control={<Radio size="small" />} label="Очаги" />
                        <FormControlLabel value="1" control={<Radio size="small" />} label="ПАОУ" />
                        <FormControlLabel value="" control={<Radio size="small" />} label="Все" />
                    </RadioGroup>
                </FormControl>
            </LayoutFilter.VisibleAdditional>
        </LayoutFilter>
    );
};

export default Filter;
