import { Pie } from 'react-chartjs-2';

export const RenderPie = ({ data }) => {

    const getLabel = (context, item) => {
        const label = context?.formattedValue || '';
        const count = item?.[context?.dataIndex]?.count || '';
        return ` ${label}${label && '%'} (${count}${count && 'шт.'})`;
    };

    const toolTipTitle = (tooltipItems) => {
        return tooltipItems[0]?.dataset.label[tooltipItems[0]?.dataIndex];
    };

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'x',
        redraw: false,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    label: (context) => getLabel(context, data),
                    title: toolTipTitle
                }
            },
            legend: {
                align: 'left',
                position: 'right',
                labels: {
                    boxWidth: 20,
                }
            },
        }
    };

    return (
        <div
            style={{
                maxWidth: '700px',
                margin: '0 auto',
                paddingBottom: '1rem'
            }}
        >
            <Pie
                width={'400px'}
                height={'400px'}
                data={{
                    // labels: item.data?.map(({ dtp_type_name }) => dtp_type_name),
                    labels: data?.map(({ dtp_type_name, percent, count }) => `${dtp_type_name} - ${percent || 0}% (${count || 0})`),
                    datasets: [{
                        data: data?.map(({ percent }) => percent),
                        maxBarThickness: 80,
                        label: data?.map(({ dtp_type_name }) => dtp_type_name),
                        backgroundColor: data?.map(({ dtp_type_color }) => dtp_type_color || '#000'),
                        borderColor: '#fff',
                    }],
                }}
                options={options}
                // plugins={[ChartDataLabels]}
                type="Pie"
            />
        </div>
    );
};



