import { useMemo } from 'react';

import { iconByType } from 'modules/ExternalTransport/utils/helper';
import {
    createIcon,
    MapPopUp,
} from 'components/MapComponents/MapPopUp';

import Content from './Content';

const PopUpModal = ({
    isOpen,
    data,
    onClose,
}) => {

    const {
        name = '',
        transport_type,
    } = data || {};

    const isAirport = useMemo(() => transport_type === 2 ? true : false,[transport_type]);

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={createIcon(iconByType[transport_type])}
            title={name}
            titleBackgroundColor={data.color || 'rgb(218, 166, 85)'}
            onClose={onClose}
            medium
        >
            <Content data={data} isAirport={isAirport} />
        </MapPopUp>
    );
};

export default PopUpModal;
