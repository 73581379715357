import { useState } from 'react';
import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField
} from '@mui/material';

import * as actions from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import { Types } from 'components/common/Autocomplete/DTP/Types';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({
    onChange,
    filter,
    setFilter,
    errorDateRange,
    resetFilter,
    initialState,
    tab
}) => {


    const loadTypes = useStoreProp(
        actions.loadDtpLoadTypes,
        'incidents',
        'loadTypes'
    );

    const [isDisabled, setIsDisabled] = useState(false);

    const handleChangeCB = (name) => (e) => {
        setFilter({ ...filter, [name]: e.target.checked });
    };

    const loadOptions = Object
        .keys(loadTypes)
        .map((key) => ({
            id: key,
            name: loadTypes[key]
        }));

    const updateFilter = (filters) => { // фильтры с бэка
        const newFilters = {
            ...initialState,
            ...filters,
        };
        setFilter(newFilters);
        onChange(newFilters);
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={onChange}
            filter={filter}
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
            disabled={isDisabled || errorDateRange()}
            tab={tab}
        >
            <LayoutFilter.Visible>

                <DateRange
                    handleDate={ (newDate) => {
                        setFilter({
                            ...filter,
                            start_date: newDate,
                        });
                    }}
                    handleEndDate={ (newDate) => {
                        setFilter({
                            ...filter,
                            end_date: newDate,
                        });
                    }}
                    valueStartDate={filter?.start_date || null}
                    valueEndDate={filter?.end_date || null}
                    // dateOnly
                    isDisabledHandler={(value) => setIsDisabled(value)}

                    renderError={errorDateRange}
                    errorMessage={() => titles.SELECT_PERIOD}
                />

                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    value={filter?.type_load || []}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={loadOptions}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, value) => {
                        setFilter(old => ({
                            ...old,
                            type_load: value,
                        }));
                    }}
                    size="small"
                    multiple
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Источник создания"
                        />
                    )}
                />

                <Types
                    multiple
                    selected={filter.dtp_type}
                    onChange={dtp_type => setFilter(old => ({ ...old, dtp_type }))}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <FormControl style={{ marginLeft: 9 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.with_wounded}
                                onChange={handleChangeCB('with_wounded')}
                                size="small"
                            />
                        }
                        label="ДТП с ранеными"
                    />
                </FormControl>

                <FormControl style={{ marginLeft: 9 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filter.with_dead}
                                onChange={handleChangeCB('with_dead')}
                                size="small"
                            />
                        }
                        label="ДТП с погибшими"
                    />
                </FormControl>

            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
