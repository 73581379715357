import L from 'leaflet';

import lightenDarkenColor from 'components/MapComponents/helpers/lightenDarkenColor';

import iconMap from './icons/iconMap';
import config from './config';
import iconTypes from './icons/iconTypes';

// цвет иконки от статуса
export const getColorByStatus = (status) => {
    return config.mapMarkersColors[status] || config.mapMarkersColors.default;
};

// todo проверить используется ли где-то
// получить иконку маркера
export const createIconMarker = (status) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: getColorByStatus(status) })
    });
};

// получить цвет контрольной точки от цвета тс
export const getCheckPointColor = (color) => lightenDarkenColor(color, -90);

// цвет остановки (когда отображаются все остановки)
export const defaultStationColor = '#797979';

export const getVTName = (type, defaultText) => {
    switch (type) {
        case iconTypes.Bus:
            return 'Автобус';

        case iconTypes.Tram:
            return 'Трамвай';

        case iconTypes.Trolleybus:
            return 'Троллейбус';

        case iconTypes.Shuttlebus:
            return 'Маршрутка';

        default: return defaultText;
    }
};

export const getVehicleCategory = (data = {}) => {
    return data?.vehicle?.category_id || data?.category_id || null;
};
// проверка есть ли вообще картинка ил есть ли у нее base64 префикс
export const checkBase64 = (img) => {
    if (img && img.indexOf('data:image/png;base64,') < 0) {
        return `data:image/png;base64,${img}`;
    }
    return img;
};

// комбинированный ключ для хранения
export const getKey = (item) => item.vehicle?.id ? `v_${item.vehicle?.id}` : `c_${item.external_id}`;

export const isShowStations = (zoom) => {
    return zoom >= 16;
};
