import {
    CircleMarker,
    ToolTip,
} from 'components/MapComponents/leaflet';
import {
    MapPopUp,
    usePopModalListener
} from 'components/MapComponents/MapPopUp';

import PlatformPopUp from '../PlatformPopUp';

// остановка с модалкой
const Station = ({
    station,
    route,
    color,
    category_id,
    hideStationsPopup = false,
    ...props
}) => {

    const modalProvider = usePopModalListener(station);

    const {
        // id: stationId,
        name,
        check_point: { lat, lon }
    } = station;

    if (!lat || !lon) return null;

    return (
        <>
            <CircleMarker
                {...props}
                latlng={[lat, lon]}
                radius={6}
                weight={1}
                color={'rgba(0,0,0,.4)'}
                fillColor={color}
                fillOpacity={1}
                onClick={() => {
                    modalProvider.setModal(station);
                    props?.map?.setView?.([lat, lon]);
                }}
            >
                <CircleMarker
                    latlng={[lat, lon]}
                    radius={3}
                    weight={1}
                    color={'rgba(0,0,0,.4)'}
                    fillColor="#fff"
                    fillOpacity={1}
                    onClick={() => {
                        modalProvider.setModal(station);
                        props?.map?.setView?.([lat, lon]);
                    }}
                >
                    <ToolTip
                        offset={[0, -5]}
                        //permanent={true}
                        direction="top"
                        //sticky={true}
                    >
                        <div>Остановка: {name}</div>
                    </ToolTip>
                </CircleMarker>
            </CircleMarker>

            <MapPopUp
                isOpen={modalProvider.isOpen && !hideStationsPopup}
                data={modalProvider.modal}
                title={({ name = '', isStation = true }) => `${isStation ? 'Остановка' : 'Контрольная точка'}: ${name}`}
                titleBackgroundColor={color}
                onClose={() => modalProvider.clear()}
            >
                <PlatformPopUp
                    map={props.map}
                    station={modalProvider.modal}
                    route={route}
                    category_id={category_id}
                />
            </MapPopUp>
        </>
    );
};

export default Station;
