import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';
import { deleteThreatLevel } from 'modules/React/redux/actions';

import ModalForm from './ModalForm';
import type { ItemProp } from './types';


const Item = ({ item, reloadList }: ItemProp) => {
    const dispatch = useDispatch();

    const [isEditMode, setIsEditMode] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const onAccept = () => dispatch(deleteThreatLevel(item?.id, () => {
        reloadList(true);
        setIsDelete(false);
    }));

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    <Typography>
                        <i className="fas fa-square-full" style={{ color: item?.color }}></i>&nbsp;
                        {item?.name}
                    </Typography>
                </LSContentColumn>

                {/* <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setIsEditMode(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                            },
                        ]}
                    />
                </LSContentColumn> */}
            </LSContentItem>

            {isEditMode && (
                <ModalForm
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    item={item}
                    isNew={false}
                    reloadList={reloadList}
                />
            )}

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    onSuccess={onAccept}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </>
    );
};

export default Item;
