import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectIndicators from 'components/common/Autocomplete/RoadWorks/RepairObjectIndicators';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const AddEditModal = ({ isOpen, onClose, onAccept, index, item }) => {
    const validation = useValidation();

    const initialState = {
        indicator: item?.indicator || {},
        indicator_id: item?.indicator?.id || '',
        units_of_measurement_id: item?.units_of_measurement_id || '',
        value: item?.value || '',
    };

    const [data, setData] = useState(initialState);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
        validation.clear();
    };

    const handleSuccess = () => {
        item 
            ? onAccept(index, data, onClose) 
            : onAccept(data, onClose);
    };

    const isDisabled = !data?.indicator_id || !data?.units_of_measurement_id || !data?.value;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            small
            title={item ? titles.EDIT : titles.ADD}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSuccess,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">
                <div className="block">
                    <SelectIndicators
                        label="Показатель"
                        required
                        selected={data.indicator}
                        onChange={(indicator) => {
                            setData({ 
                                ...data, 
                                indicator, 
                                indicator_id: indicator.id, 
                                units_of_measurement_id: '' 
                            });
                            validation.clear();
                        }}
                        error={validation.isKey(`indicators.${index}.indicator_id`)}
                        helperText={validation.get(`indicators.${index}.indicator_id`)}
                    />
                </div>
                
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey(`indicators.${index}.units_of_measurement_id`)}
                    helperText={validation.get(`indicators.${index}.units_of_measurement_id`)}
                >
                    <FormControl required size="small" variant="outlined">
                        <InputLabel>{titles.UNIT}</InputLabel>
                        <Select
                            disabled={!data?.indicator?.id}
                            value={data?.units_of_measurement_id || ''}
                            label={titles.UNIT}
                            name={'units_of_measurement_id'}
                            onChange={onChange}
                        >
                            {(data?.indicator?.unit_of_measurement_list || []).map(el => (
                                <MenuItem key={el.id} value={el.id}>{el.long_name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FormInfoWrapper>
                
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey(`indicators.${index}.value`)}
                    helperText={validation.get(`indicators.${index}.value`)}
                >
                    <TextField
                        label="Значение"
                        variant="outlined"
                        size="small"
                        required
                        name="value"
                        value={data?.value || ''}
                        type="number"
                        onChange={onChange}
                    />
                </FormInfoWrapper>
            </div>     
        </Modal>
    );
};

export default AddEditModal;
