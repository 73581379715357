export const dataBus = (state) => state.dataBus;

export const colectionServicesList = (state) => dataBus(state).colectionServicesList;
export const colectionServicesListData = (state) => colectionServicesList(state).data;
export const colectionServicesListMeta = (state) => colectionServicesList(state).meta;
export const loadingColectionServices = (state) => dataBus(state).loadingColectionServices;

export const types = (state) => dataBus(state).types;
export const templates = (state) => dataBus(state).templates;
export const services = (state) => dataBus(state).services;

export const jobsList = (state) => dataBus(state).jobsList;
export const jobsListData = (state) => jobsList(state).data;
export const jobsListMeta = (state) => jobsList(state).meta;
export const loadingJobs = (state) => dataBus(state).loadingJobs;
export const period = (state) => dataBus(state).period;