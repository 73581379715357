import { useRef, useState } from 'react';
import { TextField } from '@mui/material';

import removeEmptyFields from 'helpers/removeEmptyFields';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SimpleSelect from 'components/common/SimpleSelect';
import { TypeLoad } from 'components/common/Autocomplete/DTP';
import { Types } from 'modules/Dtp/utils/components/Types';

const Filter = ({
    filter,
    setFilter,
    tab
}) => {
    const initialFilter = useRef(filter);
    const [data, setData] = useState(initialFilter.current);

    const [isDisabled, setIsDisabled] = useState(false);

    const handleChange = (name, value) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const search = (filters = data) => {
        const prepareData = removeEmptyFields({
            ...filters
        }, false);

        setFilter(prepareData);
    };

    const resetSearch = (needRefresh) => {
        setData(initialFilter.current);
        if (needRefresh) {
            search(initialFilter.current);
        }
    };

    const updateFilter = (filters) => {
        const newFilters = { ...initialFilter.current, ...filters };
        setData(newFilters);
        if (Object.keys(filters).length > 0) {
            search(newFilters);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={search}
            filter={data}
            filterException={['start_date', 'end_date']}
            setUserFilter={updateFilter}
            disabled={isDisabled}
            isFilterVisible
            tab={tab}
        >
            <LayoutFilter.Visible >
                <DateRange
                    valueStartDate={data?.start_date || null}
                    valueEndDate={data?.end_date || null}
                    handleDate={(val) => handleChange('start_date', val)}
                    handleEndDate={(val) => handleChange('end_date', val)}
                    isDisabledHandler={(value) => setIsDisabled(value)}
                />

                <TypeLoad
                    multiple
                    selected={data.type_load || []}
                    onChange={(type_load) => handleChange('type_load', type_load)}
                    openOnFocus
                />

                <Types
                    multiple
                    selected={data.dtp_type || []}
                    onChange={(dtp_type) => handleChange('dtp_type', dtp_type)}
                />
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <TextField
                    label="ID ДТП"
                    value={data?.ids || ''}
                    name="ids"
                    type="number"
                    onChange={({ target: { name, value } }) => handleChange(name, parseInt(value) >= 0 ? value : '')}
                /> 

                <TextField
                    label="ДТП с погибшими от"
                    value={data?.with_dead || ''}
                    name="with_dead"
                    type="number"
                    onChange={({ target: { name, value } }) => handleChange(name, parseInt(value) >= 0 ? value : '')}
                />  
                
                <TextField
                    label="ДТП с ранеными от"
                    value={data?.with_wounded || ''}
                    name="with_wounded"
                    type="number"
                    onChange={({ target: { name, value } }) => handleChange(name, parseInt(value) >= 0 ? value : '')}
                /> 
                
                <TextField
                    label="ТС участники ДТП от"
                    value={data?.with_vehicle || ''}
                    name="with_vehicle"
                    type="number"
                    onChange={({ target: { name, value } }) => handleChange(name, parseInt(value) >= 0 ? value : '')}
                />

                <SimpleSelect
                    selected={data?.with_concentration || ''}
                    options={[
                        { id: '0', name: 'Нет' },
                        { id: '1', name: 'Да' },
                    ]}
                    onChange={(value) => handleChange('with_concentration', value)}
                    label="Связь с МКДТП"
                    renderLabel={(option) => option?.name || ''}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
