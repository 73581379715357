import { useEffect, useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import {
    getDeviceListEcology,
    loadDeviceStatuses,
    setActiveEcology
} from 'redux/Ecology/actions';
import { ecologySelectors } from 'redux/Ecology';
import colorExtend from 'helpers/mapHelper/colorExtend';
import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import CircleStatus from 'components/common/CircleStatus';
import Loading from 'components/common/Loading';
import config from 'components/MapComponents/Layers/Ecology/config';

import Item from './Item';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const statuses = useStoreFromSelector(loadDeviceStatuses, ecologySelectors.deviceStatuses);
    const statusesObj = useMemo(() => statuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [statuses]);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const list = useSelector(ecologySelectors.list);
    const listLoading = useSelector(ecologySelectors.listLoading);
    const active = useSelector(ecologySelectors.active);
    const filters = useSelector(ecologySelectors.filters);
    const activeEcologyId = Object.keys(active).length > 0 ? `${active.external_id}` : 0;

    const fetchList = () => {
        // const paramsWithoutEmpty = _.reduce(
        //     filters,
        //     (res, el, key) => {
        //         if (el) {
        //             res[key] = el;
        //         }
        //         return res;
        //     },
        //     {}
        // );
        dispatch(getDeviceListEcology({
            page,
            limit
            // ...paramsWithoutEmpty
        }, page === 1));
    };

    const handleScrollUpdate = (values) => {
        const {
            //current_page = 0,
            last_page = 0
        } = list.meta;
        if (
            values.top > 0.98
            && listLoading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    // отслеживаем изменение проекта
    useEffect(() => {
        fetchList();
    }, [page, limit, filters]);

    const renderList = (dataList) => (
        <>
            {dataList.map((item) => {
                const id = `${item.external_id}`;
                const status = statusesObj[item?.status] || {};
                return (
                    <SidebarItem
                        key={id}
                        active={activeEcologyId === id}
                        sideComponent={<div style={{ padding: 3 }}>
                            <CircleStatus
                                title={item?.status_text || status?.name || ''}
                                color={colorExtend(item?.status_color || status?.color)}
                            />
                        </div>}
                        // onClick={() => {
                        //     dispatch(setActiveEcology(item));
                        // }}
                    >
                        <Item
                            {...item}
                            onClick={() => {
                                dispatch(setActiveEcology(item));
                            }}
                        />
                    </SidebarItem>
                );
            })}
        </>
    );

    return (
        <div className="layers-sidebar__flex-column">
            {listLoading && list.data.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={list}
                        />
                        {list.data.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(list.data)}
                    </div>
                    {listLoading && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
