import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import { dataBusSelectors } from 'modules/DataBus';
import { loadJobsList, loadJobsPeriod } from 'modules/DataBus/redux/actions';

import Item from './Item';

import type { JobsTypes, Period } from './types';

const Jobs = () => {
    const dispatch = useDispatch();

    const period: Period[] = useStoreProp(loadJobsPeriod, 'dataBus', 'period');

    const list: JobsTypes[] = useSelector(dataBusSelectors.jobsListData);
    const meta = useSelector(dataBusSelectors.jobsListMeta);
    const loading = useSelector(dataBusSelectors.loadingJobs);

    const [data, setData] = useState({
        params: []
    });

    const reloadList = () => {
        dispatch(loadJobsList(data.params));
    };

    useEffect(() => {
        reloadList();
    }, [dispatch, data]);

    const renderContent = () => (
        list?.length > 0
            ? <LSContainer
                headers={[
                    { title: titles.NAME, width: '30%' },
                    { title: titles.SERVICE, width: '30%' },
                    { title: titles.TEMPLATE, width: '30%' },
                    { title: titles.ACTIONS, isActions: true }
                ]}
            >
                {list.map((item) => (
                    <Item
                        key={item.id}
                        item={item}
                        period={period}
                        reloadList={reloadList}
                    />
                ))}
            </LSContainer>
            : (!loading && <NoData/>)
    );

    return (
        <PageLayout
            header="Задачи"
            loading={loading}
            content={renderContent}
        />
    );
};

export default Jobs;
