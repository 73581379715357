import { types } from 'util';

import { useState } from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectCategories from 'modules/React/utils/components/SelectCategories';
import SelectKeywords from 'modules/React/utils/components/SelectKeywords';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';
import SelectIncidentFactors from 'modules/React/utils/components/SelectIncidentFactors';

import type { FilterFields } from './types';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; limit: number; data: {} }>>;
}

const initialState = {
    id: '',
    name: '',
    category_id: null,
    keyword_id_list: null,
    is_typical: false,
    types: null,
    factors: null,
};

const Filter = ({ setParams }: FilterProps) => {
    const [data, setData] = useState<FilterFields>(initialState);

    const handleChange = (name: string, value: any) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (filters = data) => {
        const prepareData: any = removeEmptyFields({
            ...filters,
            category_id: filters.category_id?.id,
            keyword_id_list: filters.keyword_id_list?.map(({ id }) => id),
            is_typical: filters.is_typical ? 1 : null,
            types_id_list: filters.types?.map(({ id }) => id),
            factors_id_list: filters.factors?.map(({ id }) => id),
        }, false);

        delete prepareData.types;
        delete prepareData.factors;

        setParams(prev => ({ ...prev, page: 1, data: prepareData }));
    };

    const resetFilter = (needRefresh: boolean) => {
        setData(initialState);

        if (needRefresh) {
            onSearch(initialState);
        }
    };

    const updateFilter = (filters = {}) => {
        const newFilter = { ...data, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data.id || ''}
                    label={titles.NUMBER}
                    onChange={(e) => handleChange('id', e.target.value)}
                />
                <TextField
                    value={data.name || ''}
                    label={titles.NAME}
                    onChange={(e) => handleChange('name', e.target.value)}
                />
                <SelectCategories
                    selected={data.category_id}
                    onChange={(value) => handleChange('category_id', value)}
                />
                
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <SelectKeywords
                    multiple
                    selected={data.keyword_id_list || []}
                    onChange={(value) => handleChange('keyword_id_list', value)}
                />

                <SelectIncidentTypes
                    multiple
                    selected={data?.types || []}
                    onChange={(value) => handleChange('types', value)}
                />

                <SelectIncidentFactors
                    multiple
                    selected={data?.factors || []}
                    onChange={(value) => handleChange('factors', value)}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.is_typical}
                            onChange={(e) => handleChange('is_typical', e.target.checked)}
                            name="is_typical"
                        />
                    }
                    label="Типовой"
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;