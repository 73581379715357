import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { changeVideoCameraStatus } from 'redux/VideoCameras/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

import type { CityCamera, Statuses } from './types';

interface StatusModalProps {
    isOpen: boolean
    onClose: () => void
    item: CityCamera
    reloadList: (isDelete?: boolean) => void
    statuses: Statuses[]
}

const StatusModal = ({ isOpen, onClose, item, statuses, reloadList }: StatusModalProps) => {
    const dispatch = useDispatch();

    const [status, setStatus] = useState(item?.status || '');

    const onSave = () => {
        dispatch(changeVideoCameraStatus(item.id, { status: Number(status) }, () => {
            reloadList();
            onClose();
        }));
    };

    const isDisabled = Number(status) === item?.status || !status;

    return (
        <Modal
            title="Изменение статуса"
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                        disabled: isDisabled
                    },
                ]}
            />}
        >
            <form autoComplete="off" className="modal__form">
                <FormControl
                    className="block" 
                    size="small" 
                    required 
                    variant="outlined"
                >
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={status}
                        label={titles.STATUS}
                        onChange={(e) => setStatus(e.target.value)}
                        name="status"
                    >
                        {statuses?.length > 0
                            ? statuses.map((item: Statuses) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </form>
        </Modal>
    );
};

export default StatusModal;
