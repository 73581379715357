import { useCallback, useEffect } from 'react';
import ReactFlow, {
    addEdge,
    Background,
    Controls,
    MiniMap,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import type { NodeTypes, OnConnect } from 'reactflow';
import { isArray } from 'lodash';
  
import NoData from 'components/common/Information/NoData';

import CustomNode from './CustomNode';
import { generateFlowData } from './renderFlow';

import type { ResponseScenario } from '../../types';

const nodeTypes = { 'cycle-node': CustomNode } satisfies NodeTypes;

const Diagram = ({ data }: { data: ResponseScenario }) => {
    
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const onConnect: OnConnect = useCallback((connection) => {
        setEdges((edges) => addEdge(connection, edges));
    }, [setEdges]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isArray(data.operation_list) && data.operation_list.length > 0) {
                // @ts-ignore
                const sortedOperations = data.operation_list.sort((a,b) => a.position - b.position);
                const { nodesData, edgesData } = generateFlowData(sortedOperations);
                // @ts-ignore
                setNodes(nodesData);
                setEdges(edgesData);
            }
        }, 100);
        
        return () => {
            setNodes([]);
            setEdges([]);
            clearTimeout(timeout);
        };
    }, [data.operation_list, setEdges, setNodes]);
  
    return (
        <div style={{ width: '100%', height: 500 }} >
            {isArray(data.operation_list) && data.operation_list.length > 0
                ? <ReactFlow
                    nodes={nodes}
                    nodeTypes={nodeTypes}
                    onNodesChange={onNodesChange}
                    edges={edges}
                    // edgeTypes={EdgeTypes}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    fitView
                >
                    <Background />
                    <MiniMap />
                    <Controls />
                </ReactFlow>
                : <NoData />
            }
            
        </div>
    );
};

export default Diagram;
