import type { CSSProperties } from 'react';
import { IconButton } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cn from 'classnames';

import type { Header } from '@tanstack/react-table';

interface DraggableHeaderProps<T> {
    header: Header<T, unknown>;
}

function DraggableHeader<T>({ header }: DraggableHeaderProps<T>) {
    const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
        id: header.column.id,
    });

    const style: CSSProperties = {
        opacity: isDragging ? 0.5 : 1,
        position: 'relative',
        transform: CSS.Translate.toString(transform),
        transition: 'width transform 0.2s ease-in-out',
        whiteSpace: 'nowrap',
        width: header.getSize() !== 150 ? header.getSize() : 'auto',
    };

    const isActions = header.column.id === 'actions';

    return (
        <div
            key={header.id}
            ref={setNodeRef}
            style={{
                ...style,
                ...(header.getSize() !== 150 ? { maxWidth: header.getSize() } : {}),
            }}
            className={cn('data-table-row-cell', { 'draggable-actions': isActions })}
        >
            <span>{header.column.columnDef.header}</span>

            <IconButton
                size="small"
                style={{ width: 30, height: 30 }}
                disableRipple
                {...attributes}
                {...listeners}
            >
                <i className="fas fa-grip-vertical" />
            </IconButton>
        </div>
    );
}

export default DraggableHeader;
