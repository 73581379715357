import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadTypicalOperations } from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';

import Item from './Item';
import ModalForm from './ModalForm';
import Filters from './Filters';

const TypicalOperations = () => {
    const dispatch = useDispatch();

    const data = useSelector(SituationalPlansSelectors.typicalOperationsData);
    const meta = useSelector(SituationalPlansSelectors.typicalOperationsMeta);
    const loadingTypicalOperations = useSelector(SituationalPlansSelectors.loadingTypicalOperations);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams({ page: params.page - 1, data: params.data });
        } else {
            dispatch(loadTypicalOperations(params.page, limit, params.data));
        }
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const renderContent = () => {
        return <>
            {data?.length > 0
                ? (
                    <LSContainer>
                        {data.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loadingTypicalOperations && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header="Типовые операции"
                loading={loadingTypicalOperations}
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent 
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />),
                    
                    total: meta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
                customStyles={{ padding: 0 }}
            />

            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={setOpenModal}
                    isNew
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default TypicalOperations;
