import { config } from '../../config';

const apiParkingUrl = () => config.get('urls').apiParkingUrl;

const apiUrls = {

    getPolygon: () => `${apiParkingUrl()}/parking/polygon`,

    getSidebar: () => `${apiParkingUrl()}/parking`,

    createPark: () => `${apiParkingUrl()}/parking`,

    editPark: (id) => `${apiParkingUrl()}/parking/${id}`,

    deletePark: (id) => `${apiParkingUrl()}/parking/${id}`,

    getStatuses: () => `${apiParkingUrl()}/category/statuses`,

    getTypes: () => `${apiParkingUrl()}/category/types`,

    getZones: () => `${apiParkingUrl()}/category/zones`,

    getCategory: () => `${apiParkingUrl()}/category`,

    getStatusStatistic: () => `${apiParkingUrl()}/parking/status_statistic`,

    getTariff: () => `${apiParkingUrl()}/tariff`,

    getTariffCategories: () => `${apiParkingUrl()}/tariff/categories`,

    getTariffDays: () => `${apiParkingUrl()}/tariff/days`,

};

export default apiUrls;