import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import {
    FormGroup,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';

import { editDtpType } from 'redux/Incidents/actions';
import { loadTransportIncidentTypes } from 'redux/SituationalPlans/actions';
import titles from 'helpers/constants/titles';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { useValidation } from 'helpers/hooks';
import UniversalSelect from 'components/common/UniversalSelect';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const DTPTypeModal = ({ isOpen, item, onClose }) => {

    // STATES
    const [colorPicker, setColorPicker] = useState(item);
    const [showList, setShowList] = useState(false);
    const [tiType, setTiType] = useState(item.ti_type || {});

    // HOOKS
    const dispatch = useDispatch();
    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    const handleColorChange = (e) => {
        const currentColor = e.hex;
        setColorPicker({ ...colorPicker, color: currentColor });
    };

    const handleSaveChanges = () => {
        dispatch(editDtpType({
            id: item.id,
            color: colorPicker.color,
            ti_type: tiType || null
        }));
    };

    const getIncidentTypes = (params) => {
        const { page, limit, query } = params;
        dispatch(loadTransportIncidentTypes(page, limit, { name: query }));
    };

    const handleTIType = (value) => {
        setTiType(value);
        setShowList(false);
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={titles.EDIT_DTP_TYPE}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSaveChanges,
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper className="block" error={validation.isKey('name')} helperText={validation.get('name')}>
                    <TextField
                        size="small"
                        variant="outlined"
                        disabled={true}
                        label="Название"
                        defaultValue={item.name || ''}
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>
                <TextField
                    className="block"
                    value={tiType?.name || ''}
                    label={titles.INCIDENT_TYPE}
                    fullWidth={true}
                    variant={'outlined'}
                    disabled={true}
                    size={'small'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={'end'}>
                                <IconButton size="small" onClick={() => setShowList(true)}>
                                    <i className="fas fa-search-plus"/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {showList
                    && <UniversalSelect
                        storeName={'SituationalPlans'}
                        storeNameProps={'transportIncidentTypes'}
                        keyProp={'id'}
                        fetchList={getIncidentTypes}
                        withSearch={true}
                        isSelected={true}
                        onAccept={(value) => handleTIType(value)}
                        selected={tiType}
                        renderProps={(el) => <div><span>{el.name}</span></div>}
                        isOpen={showList}
                        onClose={() => setShowList(false)}
                        noPadding={true}
                        title={titles.INCIDENT_TYPE}
                    />
                }
                <FormGroup className="block">
                    <DirectionsCarIcon fontSize={'large'} style={{ color: colorPicker.color }}/>
                    <Typography>{titles.EDIT_COLOR_DTP_ICON}:</Typography>
                    <SketchPicker
                        color={colorPicker.color}
                        onChange={handleColorChange}
                    />
                </FormGroup>
            </form>
        </Modal>
    );
};

export default DTPTypeModal;
