import { useMemo, useState } from 'react';
import { FormHelperText } from '@mui/material';

import { REPORT_FORMATS_SMALL } from 'helpers/report.formats';
import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SimpleSelect from 'components/common/SimpleSelect';

const GetReport = ({
    formats = REPORT_FORMATS_SMALL,
    handleDownload,
    disabled = false,
    helperText = 'Для получения отчета заполните все поля!',
    label = titles.FORMAT,
    buttonType = 'getReport',
    onChange = null,
    test_id_prefix = '',
}) => {
    const [selectedFormat, setSelectedFormat] = useState('');

    const disabledBnt = () => {
        return !selectedFormat || disabled;
    };

    const onSetSelected = (val) => {
        setSelectedFormat(val);
        onChange && onChange(val?.id);
    };

    const options = useMemo(() => {
        return Object.keys(formats).map((el) => ({ id: el, value: el }));
    }, [formats]);

    return (
        <div className="row noMargin" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <div className="row__item" style={{ minWidth: '160px' }}>
                    <SimpleSelect
                        selected={selectedFormat}
                        options={options}
                        onChange={onSetSelected}
                        renderLabel={(option) => option?.value || ''}
                        label={label}
                        test_id_prefix={test_id_prefix}
                    />
                </div>

                <FormButtons
                    noPadding
                    noMarginLeft
                    positionLeft
                    buttons={[
                        {
                            ...buttonsTypes[buttonType],
                            onClick: () => {
                                handleDownload(selectedFormat?.id);
                                setSelectedFormat('');
                            },
                            color: 'infoButton',
                            disabled: disabledBnt(),
                            testName: 'get-report',
                        },
                    ]}
                    test_id_prefix={test_id_prefix}
                />
            </div>

            {disabled && selectedFormat && (
                <FormHelperText error style={{ flex: '1 0 100%' }}>
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
};

export default GetReport;
