const common = {
    YES: 'Да',
    NO: 'Нет',
    SEARCH: 'Поиск',
    FIND: 'Найти',
    RECALCULATE: 'Пересчитать',
    RESET: 'Сбросить',
    CANCEL: 'Отменить',
    SAVE: 'Сохранить',
    APPLY: 'Применить',
    DELETE: 'Удалить',
    DELETE_ALL: 'Удалить все',
    DELETE_EVENTS: 'Удалить события',
    SEND: 'Отправить',
    CREATE: 'Создать',
    CLOSE: 'Закрыть',
    EDIT: 'Редактировать',
    COPY: 'Копировать',
    CLONE: 'Клонировать',
    OPEN_DROPDOWN: 'Открыть всплывающее меню',
    ADD: 'Добавить',
    REFRESH_SCREEN: 'Обновить экран',
    CHANGE: 'Изменить',
    SELECT: 'Выбрать',
    RESET_FILTER: 'Сбросить фильтр',
    SHOW: 'Показать',
    TABLE: 'Tаблица',
    GRAPH: 'График',
    REPORT: 'Отчет',
    GET_REPORT: 'Получить отчет',
    MARK_AS_READ: 'Отметить все как прочитанные',
    OPEN_IN_NEW_WINDOW: 'Открыть в новом окне',
    READY: 'Готово',
    CONFIRM: 'Подтвердить',
    BACK: 'Назад',
    OPEN_DEPLOYED_REPORT: 'Открыть развернутый отчет',
    OPEN_MAIN_REPORT: 'Открыть основной отчет',
    UPLOAD: 'Загрузить',
    DOWNLOAD: 'Скачать',
    SHOW_DELETED: 'Показать удаленные',
    SHOW_CURRENT: 'Показать действующие',
    ADD_NEW_KEYWORD: 'Добавить новое ключевое слово',
    SELECT_ALL: 'Выбрать все',
    PREVIEW: 'Предпросмотр',
    PRINT: 'Вывести на печать',
    SHOW_ROUTES: 'Показать маршруты',
    ADD_OR_DELETE_LINKS: 'Добавить/удалить связи',
    TODAY: 'Сегодня',
    PREV_MONTH: 'Предыдущий месяц',
    NEXT_MONTH: 'Следующий месяц',
    ADD_SPEED: 'Добавить скорость',
    HISTORY: 'История',
    CHANGE_STATUS: 'Изменить статус',
    CHAT: 'Чат',
    CREATE_INCIDENT: 'Создать инцидент',
    LINK: 'Связь',
};

export default common;
