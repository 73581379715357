import { MarkerType } from 'reactflow';

export const generateFlowData = (data) => {
    const nodes = [
        {
            id: 'start',
            data: { label: 'начало' },
            position: { x: 0, y: 0 },
            sourcePosition: 'right',
            targetPosition: 'left',
        },
    ];
    const edges = [];

    const addEdgesToStartEnd = (block, pos) => {
        if (block?.parallel.length > 1) {
            block?.parallel.forEach(el => {
                edges.push({
                    id: pos === 'start' ? `${pos}-${el}` : `${el}-${pos}`,
                    source: pos === 'start' ? pos :`${el}`,
                    target: pos === 'start' ? `${el}` : pos,
                    type: 'straight',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                });
            });
        } else {
            edges.push({
                id: pos === 'start' ? `${pos}-${block?.position}` : `${block?.position}-${pos}`,
                source: pos === 'start' ? pos : `${block?.position}`,
                target: pos === 'start' ? `${block?.position}` : pos,
                type: 'straight',
                markerEnd: { type: MarkerType.ArrowClosed },
                style: { strokeWidth: 2 },
            });
        }
    };
    
    const xSpacing = 200; // Горизонтальное расстояние между последовательными блоками
    const ySpacing = 80; // Вертикальное расстояние между параллельными блоками
    const cycleOffsetY = 80; // Смещение по вертикали для циклического блока
    const branchOffsetY = 100; // Смещение по Y для ветвей
    let currentX = 200;

    data.forEach((item, index) => {
        if (item.group?.length > 1) {
            currentX = !item?.parallel.length ? currentX + xSpacing : currentX;
            return;
        } // Пропускаем блоки, относящиеся к ветвлениям

        if (index === 0) {
            addEdgesToStartEnd(item, 'start');
        }
        
        // Проверка ветвлений для текущего блока
        const branchItems = data.filter((d) => d.group && d.group.startsWith(`${item?.group}.`));

        if (item.cycle) {
            // Если блок имеет цикл
            // основной блок с циклом
            nodes.push({
                id: String(item?.position),
                data: { label: item?.name },
                position: { x: currentX, y: 0 },
                style: {
                    border: '1px solid #777',
                    height: 40,
                    minWidth: 150,
                    textAlign: 'center',
                    alignItems: 'center',
                    backgroundColor: 'fff',
                    fontSize: 12,
                    padding: 10,
                },
                type: 'cycle-node',
            });

            // доп блок для цикла
            nodes.push({
                id: `cycle-${item?.position}`,
                data: { label: `Цикл: ${item.cycle} повторов` },
                position: { x: currentX + 30, y: cycleOffsetY },
                style: { width: 80 },
                sourcePosition: 'left',
                targetPosition: 'right',
            });

            edges.push(
                {
                    id: `e${item?.position}-cycle`,
                    source: String(item?.position),
                    target: `cycle-${item?.position}`,
                    sourceHandle: 'bottom-right',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                },
                {
                    id: `e-cycle-${item?.position}`,
                    source: `cycle-${item?.position}`,
                    target: String(item?.position),
                    targetHandle: 'bottom-left',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                }
            );

            const nextBlock = data[index + 1];
            if (nextBlock?.parallel.length <= 1) {
                edges.push({
                    id: `e${item?.position}-${nextBlock?.position}`,
                    source: String(item?.position),
                    target: String(nextBlock?.position),
                    type: 'straight',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                });
            }
        } else if (branchItems.length) {
            const completedNodeId = `completed-${item?.position}`;
            const notCompletedNodeId = `notCompleted-${item?.position}`;
            const skipNodeId = `skip-${item?.position}`;

            nodes.push({
                id: String(item?.position),
                data: { label: item.name },
                position: { x: currentX, y: 0 },
                sourcePosition: 'right',
                targetPosition: 'left',
            });
            
            currentX += xSpacing;

            nodes.push(
                {
                    id: completedNodeId,
                    data: { label: 'Выполнено' },
                    position: { x: currentX, y: -branchOffsetY },
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    style: { backgroundColor: '#c9e3ca' },
                },
                {
                    id: notCompletedNodeId,
                    data: { label: 'Не выполнено' },
                    position: { x: currentX, y: 0 },
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    style: { backgroundColor: '#f3b5c5' },
                },
                {
                    id: skipNodeId,
                    data: { label: 'Пропустить' },
                    position: { x: currentX, y: branchOffsetY },
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    style: { backgroundColor: '#d8effd' },
                }
            );

            edges.push(
                {
                    id: `e${item?.position}-completed`,
                    source: String(item?.position),
                    target: completedNodeId,
                    type: 'straight',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                },
                {
                    id: `e${item?.position}-notCompleted`,
                    source: String(item?.position),
                    target: notCompletedNodeId,
                    type: 'straight',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                },
                {
                    id: `e${item?.position}-skip`,
                    source: String(item?.position),
                    target: skipNodeId,
                    type: 'straight',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                }
            );

            let lastNodeInCompleted = completedNodeId;
            let lastNodeInNotCompleted = notCompletedNodeId;

            let comBranchX = currentX + xSpacing;
            let notComBranchX = currentX + xSpacing;

            const nextPosition = index + (branchItems.length + 1) > data.length - 1
                ? 'end'
                : data[index + (branchItems.length + 1)]?.position;

            branchItems
                .sort((a, b) => a.data - b.data)
                .forEach((branchItem, i) => {
                    const branchType = branchItem.group.split('.')[1];
                    const isCompletedBranch = branchType === '1';
                    const branchParentNode = isCompletedBranch
                        ? lastNodeInCompleted
                        : lastNodeInNotCompleted;

                    nodes.push({
                        id: String(branchItem?.position),
                        data: { label: branchItem?.name },
                        position: {
                            x: isCompletedBranch ? comBranchX : notComBranchX,
                            y: isCompletedBranch ? -branchOffsetY : 0,
                        },
                        sourcePosition: 'right',
                        targetPosition: 'left',
                    });

                    edges.push({
                        id: `e${branchParentNode}-${branchItem?.position}`,
                        source: branchParentNode,
                        target: String(branchItem?.position),
                        type: 'straight',
                        markerEnd: { type: MarkerType.ArrowClosed },
                        style: { strokeWidth: 2 },
                    });

                    if (isCompletedBranch) {
                        lastNodeInCompleted = String(branchItem?.position);
                        comBranchX = currentX + xSpacing * 2;
                    } else {
                        lastNodeInNotCompleted = String(branchItem?.position);
                        notComBranchX = currentX + xSpacing * 2;
                    }

                    if (branchItem?.group !== branchItems[i + 1]?.group) {
                        edges.push({
                            id: `e-${branchItem?.position}-${nextPosition}`,
                            source: String(branchItem?.position),
                            target: String(nextPosition),
                            type: 'straight',
                            markerEnd: { type: MarkerType.ArrowClosed },
                            style: { strokeWidth: 2 },
                        });
                    }
                });

            if (index < data.length - 1) {
                edges.push({
                    id: `e-skip-${nextPosition}`,
                    source: skipNodeId,
                    target: String(nextPosition),
                    type: 'straight',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                });
            }
        } else if (item?.parallel.length > 1) {
            // Параллельные блоки
            const yStart = -Math.floor(item?.parallel.length / 2) * ySpacing;
      
            item?.parallel.forEach((parallelPos, idx) => {
                if (item?.position === parallelPos) {
                    const yPosition = yStart + idx * ySpacing;
                    nodes.push({
                        id: String(parallelPos),
                        data: { label: data.find((d) => d?.position === parallelPos)?.name },
                        position: { x: currentX, y: yPosition },
                        sourcePosition: 'right',
                        targetPosition: 'left',
                    });

                    // Связь каждого параллельного блока с предыдущим основным блоком
                    edges.push({
                        id: `e${data[index - (idx + 1)]?.position}-${item?.position}`,
                        source: String(data[index - (idx + 1)]?.position),
                        target: String(item?.position),
                        type: 'straight',
                        markerEnd: { type: MarkerType.ArrowClosed },
                        style: { strokeWidth: 2 },
                    });
                    // Связь каждого параллельного блока с последующим основным блоком
                    if (index < data.length - 1) {
                        const nextNotParallelData = data[index + (item?.parallel.length - idx)];
                        const nextPosition = nextNotParallelData?.position;
                        edges.push({
                            id: `e${parallelPos}-${nextPosition}`,
                            source: String(parallelPos),
                            target: String(nextPosition),
                            type: 'straight',
                            markerEnd: { type: MarkerType.ArrowClosed },
                            style: { strokeWidth: 2 },
                        });
                    }
                    currentX = item?.parallel.length - 1 === idx ? currentX + xSpacing : currentX;
                }
            });
        } else if (index < data.length - 1 && data[index + 1]?.parallel.length <= 1 && (!item?.group || item?.group?.length <= 1)) {
            nodes.push({
                id: String(item?.position),
                data: { label: item.name },
                position: { x: currentX, y: 0 },
                sourcePosition: 'right',
                targetPosition: 'left',
            });
            
            // Связывание с следующим последовательным блоком
            const nextPosition = data[index + 1]?.position;
            edges.push({
                id: `e${item?.position}-${nextPosition}`,
                source: String(item?.position),
                target: String(nextPosition),
                type: 'straight',
                markerEnd: { type: MarkerType.ArrowClosed },
                style: { strokeWidth: 2 },
            });
        } else {
            // Основной узел
            nodes.push({
                id: String(item?.position),
                data: { label: item.name },
                position: { x: currentX, y: 0 },
                sourcePosition: 'right',
                targetPosition: 'left',
            });

            const nextPosition = data[index + 1];

            if (nextPosition?.cycle) {
                edges.push({
                    id: `e${item?.position}-${nextPosition?.position}`,
                    source: String(item?.position),
                    target: String(nextPosition?.position),
                    type: 'straight',
                    markerEnd: { type: MarkerType.ArrowClosed },
                    style: { strokeWidth: 2 },
                });
            }

        }

        if (index === data.length - 1) {
            addEdgesToStartEnd(item, 'end');
        }

        currentX = item?.parallel.length > 1 ? currentX : currentX + xSpacing;
    });

    nodes.push({
        id: 'end',
        data: { label: 'конец' },
        position: { x: currentX, y: 0 },
        targetPosition: 'left',
        sourcePosition: 'right',
    });

    return { nodesData: nodes, edgesData: edges };
};
