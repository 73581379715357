import { useState } from 'react';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from 'App';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PassengersCount from 'components/pages/Reports/PassengerTransport/PassengersCount';
import Modal from 'components/common/Modal';
import FormButtonsComponent from 'components/common/FormButtons';

// import PlatformData from './PlatformData';
import Stations from './Stations';

const PlatformPopUp = (props) => {
    const {
        station,
        isStation = true,
        route = null,
        category_id = null
    } = props;

    const [open, setOpen] = useState(false);
    const [openStations, setOpenStations] = useState(false);

    // забираем данные из полигона
    const { name } = station;

    return (
        <>
            {isStation && (
                <div className="filter__wrap__btn filter__btn__column" style={{ padding: '.5rem 1rem' }}>
                    <FormButtonsComponent
                        positionLeft
                        buttons={[
                            {
                                onClick: () => setOpen(true),
                                name: 'Пассажиропоток'
                            },
                            {
                                onClick: () => setOpenStations(true),
                                name: 'Расписание'
                            }
                        ]}
                    />
                </div>
            )}

            {open && (
                <Modal
                    isOpen={open}
                    onClose={() => setOpen(false)}
                    noPadding={true}
                    fullWidth
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () =>setOpen(false)
                                },
                            ]}
                        />
                    }
                >
                    <div className="modal__form">
                        <PassengersCount
                            check_point={station}
                            title={station.name}
                        />
                    </div>
                </Modal>
            )}

            {openStations && (
                <Modal
                    isOpen={openStations}
                    onClose={() => setOpenStations(false)}
                    noPadding={true}
                    title={`Расписание: ${name}`}
                    medium
                    // maxWidthProp
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () =>setOpenStations(false)
                                },
                            ]}
                        />
                    }
                >
                    <Stations
                        id={station?.id}
                        route_num={route}
                        vehicle_type={category_id}
                    />
                </Modal>
            )}

        </>
    );

    // return (
    //     <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
    //         <StyledEngineProvider injectFirst>
    //             <ThemeProvider theme={theme}>
    //                 <div className="map-popup passanger-transport">
    //                     <div className="map-popup_header">
    //                         <div>
    //                             <span className="name">{isStation ? 'Остановка' : 'Контрольная точка'}: {name}</span>
    //                         </div>
    //                     </div>
    //                     <div className="map-popup_body">
    //                         {isStation && (
    //                             <div className="filter__wrap__btn filter__btn__column">
    //                                 <FormButtonsComponent
    //                                     positionLeft
    //                                     buttons={[
    //                                         {
    //                                             onClick: () => setOpen(true),
    //                                             name: 'Пассажиропоток'
    //                                         },
    //                                         {
    //                                             onClick: () => setOpenStations(true),
    //                                             name: 'Расписание'
    //                                         }
    //                                     ]}
    //                                 />
    //                             </div>
    //                         )}
    //                     </div>
    //
    //                     {open
    //                         && (
    //                             <Modal
    //                                 isOpen={open}
    //                                 onClose={() => setOpen(false)}
    //                                 noPadding={true}
    //                                 fullWidth
    //                                 buttons={
    //                                     <FormButtons
    //                                         buttons={[
    //                                             {
    //                                                 ...buttonsTypes.close,
    //                                                 onClick: () =>setOpen(false)
    //                                             },
    //                                         ]}
    //                                     />
    //                                 }
    //                             >
    //                                 <div className="modal__form">
    //                                     <PassengersCount
    //                                         check_point={station}
    //                                         title={station.name}
    //                                     />
    //                                 </div>
    //                             </Modal>)
    //                     }
    //
    //                     {openStations
    //                         && (
    //                             <Modal
    //                                 isOpen={openStations}
    //                                 onClose={() => setOpenStations(false)}
    //                                 noPadding={true}
    //                                 title={`Расписание: ${name}`}
    //                                 medium
    //                                 // maxWidthProp
    //                                 buttons={
    //                                     <FormButtons
    //                                         buttons={[
    //                                             {
    //                                                 ...buttonsTypes.close,
    //                                                 onClick: () =>setOpenStations(false)
    //                                             },
    //                                         ]}
    //                                     />
    //                                 }
    //                             >
    //                                 <Stations
    //                                     id={station?.id}
    //                                     route_num={route}
    //                                     vehicle_type={category_id}
    //                                 />
    //                             </Modal>)
    //                     }
    //                 </div>
    //             </ThemeProvider>
    //         </StyledEngineProvider>
    //     </LocalizationProvider>
    // );
};

export default PlatformPopUp;
