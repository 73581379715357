import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadIncidentObjects } from 'modules/React/redux/actions';

// загрузка объектов
const useObjectsProvider = (fnCallback, fnLoading) => {
    const dispatch = useDispatch();

    const assignRef = useRef([]);

    const [loader, setLoader] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const loadIn = useMemo(() => loader.length > 0 ? true : loading, [loader, loading]);

    useEffect(() => {
        fnLoading?.(loadIn);
    }, [loadIn]);

    useEffect(() => {
        // загрузка завершена
        if (loadIn === false && isLoaded) {
            setIsLoaded(false);
        }
    }, [loadIn, isLoaded]);

    useEffect(() => {
        // последовательная загрузка
        if (isLoaded && loading === false && loader.length > 0) {
            setLoader(old => {
                const item = old.pop();

                dispatch(loadIncidentObjects(
                    {
                        radius: 50,
                        ...item,
                    },
                    (data) => {
                        const newAssign = assignRef.current.length === 0
                            ? data
                            : data.reduce((res, group) => {
                                const index = res.findIndex(({ name }) => name === group.name);
                                const element = res[index];

                                // объединение списка в группе
                                const elsData = group
                                    .data
                                    .reduce((rr, i) => {
                                        const isReady = rr.findIndex(({ external_id }) => external_id === i.external_id) >= 0;
                                        if (!isReady) {
                                            rr.push(i);
                                        }
                                        return rr;
                                    } , [...element.data]);

                                return [
                                    ...res.slice(0, index),
                                    {
                                        ...element,
                                        data: elsData
                                    },
                                    ...res.slice(index + 1),
                                ];
                            }, assignRef.current);

                        if (loader.length === 0 ) {
                            assignRef.current = [];
                            fnCallback(newAssign);
                        } else {
                            assignRef.current = newAssign;
                        }
                    },
                    setLoading
                ));

                return old;
            });
        }
    }, [loader, loading, isLoaded]);

    return {
        // добавление загрузки в очередь (для станций метро)
        createLoadByLatLon: (lat, lon, radius = 50) => !isLoaded && setLoader(old => ([ ...old, { lat, lon, radius }])),
        // выполнение последовательной загрузки
        doAction: () => {
            assignRef.current = [];
            setIsLoaded(true);
        },
        // сброс загрузки очереди
        clear: () => {
            setLoader([]);
            setIsLoaded(false);
            setLoading(false);
            assignRef.current = [];
        },
        // загрузить сразу но 1 элемент (для инцидента)
        loadByLatLon: (lat, lon, radius = 50) => dispatch(loadIncidentObjects(
            {
                lat,
                lon,
                radius,
            },
            fnCallback,
            fnLoading
        )),
    };
};

export default useObjectsProvider;
