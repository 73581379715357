import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

interface SelectProps<T> extends CommonAutocompleteProps<T> {
    options: CommonAutocompleteProps<T>['options'];
    disableReset?: boolean;
}

function SimpleSelect<T>({
    multiple,
    selected,
    onChange,
    options,
    renderLabel,
    filterSelectedOptions,
    isOptionEqualToValue,
    onReset,
    limitTags = 1,
    label = 'Выбрать',
    required,
    disableReset,
    disabled,
    error,
    helperText,
    test_id_prefix,
    className = '',
}: SelectProps<T>) {
    return (
        <CommonAutocomplete
            multiple={multiple}
            label={label}
            selected={selected}
            onChange={onChange}
            options={options}
            renderLabel={renderLabel}
            filterSelectedOptions={filterSelectedOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            limitTags={limitTags}
            onReset={onReset}
            showAsSelect
            required={required}
            disabled={disabled}
            error={error}
            helperText={helperText}
            disableReset={disableReset}
            test_id_prefix={test_id_prefix}
            className={className}
        />
    );
}

export default SimpleSelect;
