import { useMemo } from 'react';

import MetroInputField from '../MetroInputField';
import MetroSelectType from '../MetroSelectType';
import MetroScheme from '../MetroScheme';
import { closingTypes } from '../types';
import { BFS_STATIONS } from '../lib/data';

const MetroSchemeForm = ({
    // тип id
    type = 0,
    setType = () => {},

    stations = [], // [ { id, name, lat, lon } ]
    setStations = () => {},
}) => {

    // поиск станций в базе
    const selected = useMemo(() => {
        return stations.map(el => ({
            ...el,
            ...(BFS_STATIONS.find(i => i.id === el.id) || {})
        }));
    }, [stations, BFS_STATIONS]);

    const clearStations = () => {
        setStations([]);
    };

    const stationsString = useMemo(() => {
        return Object.values(stations)
            .map((station) => station.name)
            .join(', ');
    }, [stations]);

    const promtText = useMemo(() => {
        const selectedStationsLength = Object.keys(stations).length;

        switch (type) {
            case closingTypes.stage:
                if (selectedStationsLength === 0) {
                    return 'Выберите станцию для установки первой точки маршрута перегона';
                }
                if (selectedStationsLength === 1) {
                    return 'Для построения маршрута перегона выберите станцию, лежащую на той же ветке';
                }
                break;

            case closingTypes.station:
                if (!selectedStationsLength) return 'Одновременно может быть выбрана только одна станция';
                break;

            case closingTypes.transition:
                if (selectedStationsLength === 0) {
                    return 'Выберите станцию, имеющую переход, для установки первой точки маршрута перехода';
                }
                if (selectedStationsLength === 1) {
                    return 'Для построения маршрута перехода выберите станцию, лежащую на другой ветке';
                }
                break;

            case closingTypes.entry:
                if (!selectedStationsLength) return 'Одновременно может быть выбран только один вход';
                break;

            default:
                return '';
        }
    }, [stations, type]);

    return (
        <div className="metro-transport-incidents-edit-scheme">
            <div className="row">

                <div className="row__item">
                    {/* тип */}
                    <MetroSelectType
                        value={type}
                        error={!type}
                        helperText={!type ? 'Для выбора объекта укажите тип закрытия' : ''}
                        onChange={(type) => {
                            setType(type);
                            setStations([]);
                        }}
                    />
                </div>

                <div className="row__item">
                    {/* станции */}
                    <MetroInputField
                        value={stationsString}
                        onReset={clearStations}
                        error={!!promtText}
                        helperText={promtText}
                        required
                    />
                </div>

            </div>

            {/* схема метро */}
            <MetroScheme
                type={type}
                stations={selected}
                setStations={setStations}
            />
        </div>
    );
};

export default MetroSchemeForm;
