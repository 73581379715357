import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import { focusIncidentsSelectors } from 'redux/FocusIncidents';
import * as actions from 'redux/FocusIncidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import {
    loadConcentrationAreaStatuses,
    loadConcentrationAreaTypes,
} from 'redux/Incidents/actions';
import { useDebounce, usePrevious, useStoreFromSelectorListToObject } from 'helpers/hooks';
import mapHelper from 'helpers/mapHelper';
import config from 'components/MapComponents/Layers/DtpFocus/config';
import { createIcon, createIconMarker } from 'components/MapComponents/Layers/DtpFocus/helper';
import {
    Cluster,
    Marker,
    ToolTip,
} from 'components/MapComponents/leaflet';
import CollectorForms from 'components/MapComponents/Layers/DtpFocus/CollectorForms';
import { getFilter } from 'components/MapComponents/Layers/DtpFocus/helper';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';
import {
    MapPopUpListener
} from 'components/MapComponents/MapPopUp';

import PopUpModal from '../../../../MapComponents/Layers/DtpFocus/PopUpModal';
import usePopModalListener from '../../../../../components/MapComponents/MapPopUp/PopUpListener/usePopModalListener';

const Layer = (props) => {
    const {
        map,
        readOnly = false,
        maxClusterRadius = 50
    } = props;
    const dispatch = useDispatch();

    // const popupListener = usePopModalListener();

    const polygon = useSelector(focusIncidentsSelectors.polygon);
    const active = useSelector(focusIncidentsSelectors.active);
    const saved = useSelector(focusIncidentsSelectors.saved);
    const filters = useSelector(focusIncidentsSelectors.heatMapFilter);

    // const polygonLoading = useSelector(focusIncidentsSelectors.polygonLoading);
    // const polygonVisible = useSelector(focusIncidentsSelectors.polygonVisible);
    // const [active, setActive] = useState({});

    const prevFilters = usePrevious(filters);
    const filter = getFilters(filters, getFilter);

    const types = useStoreFromSelectorListToObject(
        loadConcentrationAreaTypes,
        incidentsSelectors.concentrationAreaTypes
    );
    const statuses = useStoreFromSelectorListToObject(
        loadConcentrationAreaStatuses,
        incidentsSelectors.concentrationAreaStatuses
    );

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadPolygon(
            polygon,
            {
                ...filter,
                with_dtp_list: 1
            }
        ));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // сдвинуться к маркеру
    const setMapToMarker = () => {
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                setTimeout(() => {
                    map.setView([lat, lon]);
                }, 200);
            }
        }
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        setMapToMarker();

        return () => {
            dispatch(actions.resetActive());
            dispatch(actions.clearPolygon());

            map
                .off('moveend', handleFetchPolygon);
        };
    }, [filters]);

    // добавление нового элемента с карты
    useEffect(() => () => {
        // dispatch(actions.setPolygonVisible());
        dispatch(actions.clearPolygon());
        dispatch(actions.resetActive());
    }, []);

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            // dispatch(actions.setEditForm());
            fetchPolygon();
        }
    }, [saved]);

    useEffect(() => {
        fetchPolygon();
    },[]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon]);
            }
        }
    }, [active]);

    const createItem = (item) => {
        const { id, lat, lon } = item;

        const typeColor = types.get(item?.type)?.color;
        const statusColor = statuses.get(item?.status)?.color;

        const componentProps = {
            ...props,
            key: `${config.slug}_${id}`,
            // для кластера
            attribution: {
                slug: config.slug,
                color: typeColor
            },
            onClick: (latlng) => {
                map.setView(latlng);
                dispatch(actions.setActive(item));
                // dispatch(actions.resetActive());
            },
        };

        return (
            <Marker
                {...componentProps}
                latlng={[lat, lon]}
                icon={createIconMarker(createIcon(
                    typeColor,
                    statusColor
                ))}
            >
                {/*<PopUp*/}
                {/*    {...popUpProps}*/}
                {/*    minWidth={800}*/}
                {/*    onClose={() => {*/}
                {/*        dispatch(actions.resetActive());*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <PopUpDtp*/}
                {/*        uuid={id}*/}
                {/*        isReport*/}
                {/*        selectedItem={item}*/}
                {/*    />*/}
                {/*</PopUp>*/}

                <ToolTip
                    direction="top"
                    offset={[0, -35]}
                >
                    <div>{item.address_text || `${lat} / ${lon}`}</div>
                </ToolTip>

            </Marker>
        );
    };

    // делим на очаги и предочаги
    // 1: "Предочаг", 2: "Очаг"
    const {
        1: pfocus,
        2: focus,
    } = useMemo(() => {
        return polygon.reduce((res, item, i) => {
            // const index = i % 2 === 0 ? 0 : 1;
            // какой параметр для очага/предочага
            const index = item['type'] || 1;
            return {
                ...res,
                [index]: [
                    ...(res[index] || []),
                    createItem(item),
                ],
            };
        }, {});
    }, [polygon, readOnly]);

    return (
        <>
            <Cluster
                {...props}
                iconCreateFunction={iconCreateFunctionSimple}
                maxClusterRadius={maxClusterRadius}
            >
                {focus}
            </Cluster>

            <Cluster
                {...props}
                iconCreateFunction={iconCreateFunctionSimple}
                maxClusterRadius={maxClusterRadius}
            >
                {pfocus}
            </Cluster>

            {/* popup */}
            <MapPopUpListener
                activeSelector={focusIncidentsSelectors.active}
                polygonSelector={focusIncidentsSelectors.polygon}
            >
                <PopUpModal
                    isReport
                    // selectedItem={item}
                    onClose={() => dispatch(actions.resetActive())}
                />
            </MapPopUpListener>

            {/* формы */}
            <CollectorForms />
        </>
    );
};

export default Layer;
