import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadBrandsList } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';

import ModalForm from './ModalForm';
import Filter from './Filter';
import Item from './Item';

import type { Meta } from 'types/pagination';
import type { Brand } from './types';

function Brands() {
    const dispatch = useDispatch();

    const list: Brand[] = useSelector(transportPassengerSelectors.brandsData);
    const meta: Meta = useSelector(transportPassengerSelectors.brandsMeta);
    const loading: boolean = useSelector(transportPassengerSelectors.loadingBrands);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [isOpenAddModal, setIsOpenAddModal] = useState(false);

    const reloadList = (isDelete?: boolean) => {
        isDelete && list.length === 1 && params.page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadBrandsList(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadBrandsList(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            <>
                {list?.length > 0 ? (
                    <LSContainer
                        headers={[
                            {
                                title: titles.TYPE_OF_VEHICLE,
                                width: '100px',
                            },
                            {
                                title: titles.NAME,
                                width: '300px',
                            },
                            {
                                isActions: true,
                            },
                        ]}
                    >
                        {list.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                ) : (
                    !loading && <NoData />
                )}
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Марки"
                filters={<Filter setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setIsOpenAddModal(true),
                                    },
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>
                    ),

                    total: meta?.total,
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit,
                }}
            />

            {isOpenAddModal && (
                <ModalForm
                    isOpen={isOpenAddModal}
                    onClose={() => setIsOpenAddModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
}

export default Brands;
