import { clearFilters } from 'redux/Incidents/actions';
import createIconCluster from 'components/MapComponents/helpers/createIconCluster';
import titles from 'helpers/constants/titles';

const config = {
    name: titles.INFRASTRUCTURE_OBJECTS,
    slug: 'infrastructura',
    mapMarkersColors: {
        default: '#7e7e7e'
    },
    mapContextMenu: {
        title: 'Добавить инфраструктуру',
        event: 'add_infrastructura',
        // className: styles.button,
        minWidth: 210
    },
    onAfterActivateAction: () => clearFilters(),
    layerIcon: 'far fa-warehouse-alt',
    clusterIcon: () => createIconCluster('far fa-warehouse-alt'),
};
// добавление на карте
export default config;
