import React, { useState } from 'react';

import { dateNow, fullDateTimeWithTimeZone, getStartOf } from 'helpers/date.config';
import PageLayout from 'components/layout/PageLayout';

import Filter from './Filter';
import HeatMap from './HeatMap';

interface HeatMapCongestionsProps {
    tab: string | undefined;
    hideTitle: string | undefined;
}

function HeatMapCongestions({
    tab,
    hideTitle,
}: HeatMapCongestionsProps) {
    const [params, setParams] = useState({
        start_date: fullDateTimeWithTimeZone(getStartOf('month')),
        end_date: fullDateTimeWithTimeZone(dateNow()),
    });

    return (
        <PageLayout
            header={hideTitle ? ' ' : 'Тепловая карта скоплений НГПТ'}
            filters={<Filter setParams={setParams} tab={tab} />}
            content={() => <HeatMap params={params} />}
            customStyles={{
                overflow: 'auto',
                padding: 0,
                background: 'transparent',
            }}
            tab={tab}
        />
    );
}

export default HeatMapCongestions;
