import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiReactMethods = {
    getIncidents: async (page, limit, params) => {
        const response = await instance.post(apiUrls.getIncidents(), { page, limit, ...params });
        return response?.data;
    },

    getIncidentById: async (id) => {
        const response = await instance.get(apiUrls.getIncidentById(id));
        return response?.data;
    },

    createIncident: async (data) => {
        const response = await instance.post(apiUrls.createIncident(), data);
        return response?.data;
    },

    editIncident: async (id, data) => {
        const response = await instance.put(apiUrls.editIncident(id), data);
        return response?.data;
    },

    deleteIncident: async (id) => {
        const response = await instance.delete(apiUrls.deleteIncident(id));
        return response?.data;
    },

    getIncidentSidebar: async (params) => {
        const response = await instance.post(apiUrls.getIncidents(), { ...params });
        return response?.data;
    },

    getIncidentPolygon: async (params) => {
        const response = await instance.post(apiUrls.getIncidentPolygon(), params);
        return response.data;
    },

    getIncidentHeatMap: async (params) => {
        const response = await instance.post(apiUrls.getIncidentHeatMap(), params);
        return response.data;
    },

    getIncidentsStatuses: async () => {
        const response = await instance.get(apiUrls.getIncidentsStatuses());

        return response?.data;
    },

    getThreatLevels: async (params) => {
        const response = await instance.get(apiUrls.getThreatLevels(), { params });
        return response?.data;
    },

    // createThreatLevel: async (data) => {
    //     const response = await instance.post(apiUrls.createThreatLevel(), data);
    //     return response?.data;
    // },

    // editThreatLevel: async (id, data) => {
    //     const response = await instance.put(apiUrls.editThreatLevel(id), data);
    //     return response?.data;
    // },

    // deleteThreatLevel: async (id) => {
    //     const response = await instance.delete(apiUrls.deleteThreatLevel(id));
    //     return response?.data;
    // },

    getVehicleCategories: async () => {
        const response = await instance.get(apiUrls.getVehicleCategories());
        return response.data;
    },

    getIncidentEvents: async (id) => {
        const response = await instance.get(apiUrls.getIncidentEvents(id));
        return response?.data;
    },

    getIncidentsFactors: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getIncidentsFactors(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    getOperationTypes: async () => {
        const response = await instance.get(apiUrls.getOperationTypes());
        return response?.data;
    },

    getTypicalOperations: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getTypicalOperations(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createTypicalOperation: async (data) => {
        const response = await instance.post(apiUrls.createTypicalOperation(), data);
        return response?.data;
    },

    editTypicalOperation: async (id, data) => {
        const response = await instance.put(apiUrls.editTypicalOperation(id), data);
        return response?.data;
    },

    deleteTypicalOperation: async (id) => {
        const response = await instance.delete(apiUrls.deleteTypicalOperation(id));
        return response?.data;
    },

    createIncidentsFactors: async (data) => {
        const response = await instance.post(apiUrls.createIncidentsFactors(), data);
        return response?.data;
    },

    editIncidentsFactors: async (id, data) => {
        const response = await instance.put(apiUrls.editIncidentsFactors(id), data);
        return response?.data;
    },

    deleteIncidentsFactors: async (id) => {
        const response = await instance.delete(apiUrls.deleteIncidentsFactors(id));
        return response?.data;
    },

    getResponseScenarios: async (params) => {
        const response = await instance.get(apiUrls.getResponseScenarios(), { params });
        return response?.data;
    },

    createResponseScenario: async (data) => {
        const response = await instance.post(apiUrls.createResponseScenario(), data);
        return response?.data;
    },

    getResponseScenarioById: async (id) => {
        const response = await instance.get(apiUrls.getResponseScenarioById(id));
        return response?.data;
    },

    editResponseScenario: async (id, data) => {
        const response = await instance.put(apiUrls.editResponseScenario(id), data);
        return response?.data;
    },

    deleteResponseScenario: async (id) => {
        const response = await instance.delete(apiUrls.deleteResponseScenario(id));
        return response?.data;
    },

    //категории сценариев
    getCategories: async (params) => {
        const response = await instance.get(apiUrls.getCategories(), { params });
        return response?.data;
    },

    //категории инцидентов
    getIncidentCategories: async () => {
        const response = await instance.get(apiUrls.getIncidentCategories());
        return response?.data;
    },

    getEvents: async (params) => {
        const response = await instance.get(apiUrls.getEvents(), { params });
        return response?.data;
    },

    createEvent: async (data) => {
        const response = await instance.post(apiUrls.createEvent(), data);
        return response?.data;
    },

    editEvent: async (id, data) => {
        const response = await instance.put(apiUrls.editEvent(id), data);
        return response?.data;
    },

    deleteEvent: async (id) => {
        const response = await instance.delete(apiUrls.deleteEvent(id));
        return response?.data;
    },

    getEventObjects: async (params) => {
        const response = await instance.get(apiUrls.getEventObjects(), { params });
        return response?.data;
    },

    createEventObjects: async (data) => {
        const response = await instance.post(apiUrls.createEventObjects(), data);
        return response?.data;
    },

    editEventObjects: async (id, data) => {
        const response = await instance.put(apiUrls.editEventObjects(id), data);
        return response?.data;
    },

    deleteEventObjects: async (id) => {
        const response = await instance.delete(apiUrls.deleteEventObjects(id));
        return response?.data;
    },

    getEventObjectsTypes: async () => {
        const response = await instance.get(apiUrls.getEventObjectsTypes());
        return response?.data;
    },

    getPhenomenons: async (params) => {
        const response = await instance.get(apiUrls.getPhenomenons(), { params });
        return response?.data;
    },

    getTriggerStatuses: async () => {
        const response = await instance.get(apiUrls.getTriggerStatuses());
        return response?.data;
    },

    getTriggers: async (params) => {
        const response = await instance.post(apiUrls.getTriggers(), { ...params });
        return response?.data;
    },

    createTrigger: async (params) => {
        const response = await instance.post(apiUrls.createTrigger(), { ...params });
        return response?.data;
    },

    editTrigger: async (id, params) => {
        const response = await instance.put(apiUrls.editTrigger(id), { ...params });
        return response?.data;
    },

    deleteTrigger: async (id) => {
        const response = await instance.delete(apiUrls.deleteTrigger(id));
        return response?.data;
    },

    getPolygonForTriggers: async (url, params) => {
        const response = await instance.post(apiUrls.getPolygonForTriggers(url), { ...params });
        return response?.data;
    },

    getIncidentTypes: async (params) => {
        const response = await instance.get(apiUrls.getIncidentTypes(), { params });
        return response?.data;
    },

    createIncidentType: async (params) => {
        const response = await instance.post(apiUrls.createIncidentTypes(), { ...params });
        return response?.data;
    },

    editIncidentType: async (id, params) => {
        const response = await instance.put(apiUrls.editIncidentTypes(id), { ...params });
        return response?.data;
    },

    deleteIncidentType: async (id) => {
        const response = await instance.delete(apiUrls.deleteIncidentTypes(id));
        return response?.data;
    },

    getIncidentTypeById: async (id) => {
        const response = await instance.get(apiUrls.getIncidentTypeById(id));
        return response?.data;
    },

    getIncidentHistoryById: async (id, data) => {
        const response = await instance.get(apiUrls.getIncidentHistory(id), { params: data });
        return response.data;
    },

    getKeywordList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getKeyWordList(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    editKeyWord: async (id, data) => {
        const response = await instance.put(apiUrls.editKeyWord(id), data);
        return response?.data;
    },

    addKeyWord: async (data) => {
        const response = await instance.post(apiUrls.addKeyWord(), data);
        return response?.data;
    },

    deleteKeyWord: async (id) => {
        const response = await instance.delete(apiUrls.deleteKeyWord(id));
        return response?.data;
    },

    getIncidentObjects: async (params) => {
        const response = await instance.get(apiUrls.getIncidentObjects(), {
            params: { ...params }
        });
        return response?.data;
    },

    getIncidentObjectTypes: async () => {
        const response = await instance.get(apiUrls.getIncidentObjectTypes());
        return response?.data;
    },

    getIncidentCloseReasons: async () => {
        const response = await instance.get(apiUrls.getIncidentCloseReasons());
        return response?.data;
    },

    getIncidentCloseTypes: async () => {
        const response = await instance.get(apiUrls.getIncidentCloseTypes());
        return response?.data;
    },

    getIncidentsNotifications: async (params) => {
        const response = await instance.get(apiUrls.getIncidentsNotifications(), { params });
        return response?.data;
    },

    createIncidentsNotifications: async (params) => {
        const response = await instance.post(apiUrls.createIncidentsNotifications(), params);
        return response?.data;
    },

    getIncidentOperations: async (id) => {
        const response = await instance.get(apiUrls.getIncidentOperations(id));
        return response?.data;
    },

    editIncidentOperations: async (id, data) => {
        const response = await instance.put(apiUrls.editIncidentOperations(id), data);
        return response?.data;
    },

    loadInfopanel: async (params) => {
        const response = await instance.get(apiUrls.loadInfopanel(), { params });
        return response?.data;
    },

    getStories: async (params) => {
        const response = await instance.get(apiUrls.getStories(), { params });
        return response?.data;
    },

    createStory: async (data) => {
        const response = await instance.post(apiUrls.createStory(), data);
        return response?.data;
    },

    editStory: async (id, data) => {
        const response = await instance.put(apiUrls.editStory(id), data);
        return response?.data;
    },

    deleteStory: async (id) => {
        const response = await instance.delete(apiUrls.deleteStory(id));
        return response?.data;
    },

    getStoryStatuses: async () => {
        const response = await instance.get(apiUrls.getStoryStatuses());
        return response?.data;
    },

    getStoryConnection: async () => {
        const response = await instance.get(apiUrls.getStoryConnections());
        return response?.data;
    },
    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },
};

export default ApiReactMethods;
