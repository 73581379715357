import {
    Cluster,
    Map,
    MapControl
} from 'components/MapComponents/leaflet';
import { config } from 'config';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';

import Layer from './Layer';

const RenderMap = ({ url, microservice, service, entity_id, onChange }) => {
    return <div className="map-form-wrap__container">
        <Map center={config.get('mapCenter')} >
            <MapControl>
                <Cluster
                    iconCreateFunction={iconCreateFunctionSimple}
                    maxClusterRadius={20}
                >
                    <Layer 
                        url={url} 
                        microservice={microservice} 
                        service={service}
                        entity_id={entity_id}
                        onChange={onChange}
                    />
                </Cluster>
            </MapControl>
        </Map>
    </div>;
};

export default RenderMap;
