import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import { loadEventObjects } from 'modules/React/redux/actions';

interface EventObject {
    id : number
    name: string
}

interface EventObjectProps extends CommonAutocompleteProps<EventObject> {
    filter?: Record<string, string | number>;
}

const SelectEventObjects = ({
    multiple,
    selected,
    onChange,
    required,
    disabled,
    label = 'Объекты мероприятий',
    error,
    helperText,
    className,
    filter = {},
}: EventObjectProps) => {
    const dispatch = useDispatch();

    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const prevData = useRef(selected);

    useEffect(() => {
        // например сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const handleClose = () => setShowList(false);

    const getList = (params: { page: number; limit: number; query?: string; }) => {
        const { page, limit, query } = params;

        dispatch(loadEventObjects(page, limit, { name: query, ...filter }));
    };

    const handleAccept = (data: EventObject | EventObject[]) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                className={className}
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                renderLabel={(option) => option?.name || ''}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
            />

            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName={'react'}
                    storeNameProps={'eventObjects'}
                    storeLoadingProps={'loadingEventObjects'}
                    keyProp={'id'}
                    withSearch
                    isSelected
                    selected={selectedItems}
                    searchTitle="поиск по названию"
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding={true}
                    title={label}
                    small
                />
            )}
        </>
    );
};

export default SelectEventObjects;
