import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, ListItem, TextField } from '@mui/material';

import { createPAK, editPAK } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import UploadImageField from 'components/common/Upload/UploadImageField';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import SelectPakModel from 'components/common/Autocomplete/RoadNetwork/PakModel';
import SelectPakVendor from 'components/common/Autocomplete/RoadNetwork/PakVendor';
import TextMaskIP from 'components/common/TextMaskIP';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';

import type { PakItem } from 'types/RoadNetwork';
import type { ValueOf } from 'types/common';

interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    reloadList: () => void
    isNew?: boolean
    item?: PakItem
}

const ModalForm = ({ isOpen, isNew, onClose, reloadList, item }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(roadNetworksSelectors.loadingButton);

    const initialState = {
        name: item?.name || '',
        vendor_id: item?.vendor_id || null,
        vendor_model_id: item?.vendor_model_id || null,
        model: item?.model || null,
        lat: item?.lat || '',
        lon: item?.lon || '',
        address: item?.address || null,
        address_text: item?.address_text || '',
        geometry: item?.geometry || null,
        ip_route: item?.ip_route || '',
        mac: item?.mac || '',
        serial_number: item?.serial_number || '',
        additional_info: item?.additional_info || '',
        room_name: item?.room_name || '',
        rack_name: item?.rack_name || '',
        organization_id: item?.organization_id || null,
        organization_contacts: item?.organization_id
            ? { ...item?.organization_contacts, id: item?.organization_id }
            : null,
        supply_agreement_number: item?.supply_agreement_number || '',
        cmp_guarantee: item?.cmp_guarantee || null,
        maintenance_agreement: item?.maintenance_agreement || '',
        maintenance_agreement_date: item?.maintenance_agreement_date || null,
        equipment_guarantee: item?.equipment_guarantee || null,
        custom_icon: item?.custom_icon || ''
    };

    const [data, setData] = useState(initialState);

    const handleChange = (name: keyof PakItem, value: ValueOf<PakItem>) => {
        setData(prev => ({ ...prev, [name]: value }));
        validation.deleteKey(name);
    };

    const onChangeCoordinates = (params: PakItem) => {
        setData({
            ...data,
            ...params
        });
        validation.deleteKeys(Object.keys(params));
    };

    const onSave = () => {
        const prepareData = removeEmptyFields({
            ...data,
            organization_id: data?.organization_contacts?.id || null,
            cmp_guarantee: dateWithDashYYYYMMDD(data?.cmp_guarantee),
            maintenance_agreement_date: dateWithDashYYYYMMDD(data?.maintenance_agreement_date),
            equipment_guarantee: dateWithDashYYYYMMDD(data?.equipment_guarantee),
            vendor_model_id: data.model?.id,
            vendor_id: data.model?.vendor?.id
        }, false);

        const callback = () => { onClose(); reloadList(); };

        isNew
            ? dispatch(createPAK(prepareData, callback))
            : dispatch(editPAK(item?.id, prepareData, callback));
    };

    const isDisabled = !data.name
        || !data.model?.id
        || !data.ip_route
        || !data.lat
        || !data.lon
        || !data.address
        || !data.address_text
        || !data.geometry;

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding={true}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            loading: loadingButton,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <form className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                >
                    <TextField
                        value={data.name}
                        name="name"
                        onChange={(e) => handleChange('name', e.target.value)}
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        required={true}
                    />
                </FormInfoWrapper>

                <SelectPakModel
                    className="block"
                    label={'Модель оборудования'}
                    required
                    selected={data.model}
                    onChange={(value) => {
                        handleChange('model', value);
                        validation.deleteKey('vendor_model_id');
                        validation.deleteKey('vendor_id');
                    }}
                    error={validation.isKey('vendor_model_id')}
                    helperText={validation.get('vendor_model_id')}
                />

                <SelectPakVendor
                    className="block"
                    label={'Вендор оборудования'}
                    required
                    disabled
                    selected={data.model?.vendor || null}
                    onChange={() => {}}
                    error={validation.isKey('vendor_id')}
                    helperText={validation.get('vendor_id')}
                />

                <FormControl className="block" variant="outlined">
                    <LatLonCoordinates
                        lat={data.lat}
                        lon={data.lon}
                        onChange={onChangeCoordinates}
                        required
                    />
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <LoadAddressByCoords
                        {...data}
                        onChange={onChangeCoordinates}
                    >
                        <AddressList required returnGeometry/>
                    </LoadAddressByCoords>
                </FormControl>

                <FormControl className="block" variant="outlined">
                    <MapGeoJson
                        visibleGeometry={data.geometry || {}}
                        onChange={onChangeCoordinates}
                        required
                        returnLatLon
                        marker={true}
                        circle={false}
                        polygon={false}
                        polyline={false}
                        isHideValidation
                    />
                </FormControl>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('ip_route')}
                    helperText={validation.get('ip_route')}
                >
                    <TextField
                        value={data.ip_route || ''}
                        required
                        name="ip_route"
                        onChange={(e) => handleChange('ip_route', e.target.value)}
                        label={titles.IP_ADDRESS}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            inputComponent: TextMaskIP as any,
                        }}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('mac')}
                    helperText={validation.get('mac')}
                >
                    <TextField
                        value={data.mac || ''}
                        name="mac"
                        onChange={(e) => handleChange('mac', e.target.value)}
                        label={'MAC адрес'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('serial_number')}
                    helperText={validation.get('serial_number')}
                >
                    <TextField
                        value={data.serial_number || ''}
                        name="serial_number"
                        onChange={(e) => handleChange('serial_number', e.target.value)}
                        label={titles.SERIAL_NUMBER}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('additional_info')}
                    helperText={validation.get('additional_info')}
                >
                    <TextField
                        value={data.additional_info || ''}
                        name="additional_info"
                        onChange={(e) => handleChange('additional_info', e.target.value)}
                        label={'Доп. информация'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('room_name')}
                    helperText={validation.get('room_name')}
                >
                    <TextField
                        value={data.room_name || ''}
                        name="room_name"
                        onChange={(e) => handleChange('room_name', e.target.value)}
                        label={'Комната'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('rack_name')}
                    helperText={validation.get('rack_name')}
                >
                    <TextField
                        value={data.rack_name || ''}
                        name="rack_name"
                        onChange={(e) => handleChange('rack_name', e.target.value)}
                        label={'Стойка'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <SelectCompany
                    className="block"
                    label={'Собственник оборудования'}
                    selected={data.organization_contacts}
                    onChange={(value) => handleChange('organization_contacts', value)}
                    selectDeletedCompanies
                    error={validation.isKey('organization_id')}
                    helperText={validation.get('organization_id')}
                />

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('supply_agreement_number')}
                    helperText={validation.get('supply_agreement_number')}
                >
                    <TextField
                        value={data.supply_agreement_number || ''}
                        name="supply_agreement_number"
                        onChange={(e) => handleChange('supply_agreement_number', e.target.value)}
                        label={'Номер договора поставки'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <SingleKeyboardDateTimePicker
                    className="block"
                    label={'Срок действия гарантии на оборудование'}
                    onChange={(date: Date | null) => handleChange('equipment_guarantee', date)}
                    value={data.equipment_guarantee}
                    dateOnly
                    error={validation.isKey('equipment_guarantee')}
                    helperText={validation.get('equipment_guarantee')}
                />

                <SingleKeyboardDateTimePicker
                    className="block"
                    label={'Срок действия гарантии на СМР'}
                    onChange={(date: Date | null) => handleChange('cmp_guarantee', date)}
                    value={data.cmp_guarantee}
                    dateOnly
                    error={validation.isKey('cmp_guarantee')}
                    helperText={validation.get('cmp_guarantee')}
                />

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('maintenance_agreement')}
                    helperText={validation.get('maintenance_agreement')}
                >
                    <TextField
                        value={data.maintenance_agreement || ''}
                        name="maintenance_agreement"
                        onChange={(e) => handleChange('maintenance_agreement', e.target.value)}
                        label={'Договор на ТО'}
                        variant="outlined"
                        size="small"
                    />
                </FormInfoWrapper>

                <SingleKeyboardDateTimePicker
                    className="block"
                    label={'Срок действия договора на ТО'}
                    onChange={(date: Date | null) => handleChange('maintenance_agreement_date', date)}
                    value={data.maintenance_agreement_date}
                    dateOnly
                    error={validation.isKey('maintenance_agreement_date')}
                    helperText={validation.get('maintenance_agreement_date')}
                />

                <ListItem divider>
                    <UploadImageField
                        value={data.custom_icon}
                        name="custom_icon"
                        onChange={handleChange}
                        errorText={validation.get('custom_icon')}
                    />
                </ListItem>
            </form>
        </Modal>
    );
};

export default ModalForm;
