import { pick } from 'lodash';

import * as types from './types';

const initialState = {
    stationsList: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loading: false,
    schedule: {},
    scheduleLoading: false,
    types: [],
    statuses: {},
    brands: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingBrands: false,
    vehicleLoading: false,
    vehicles: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    vehiclesNoWorkorder: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    models: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    companies: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    searchCompanies: {},
    vehiclesTypes:{},
    vehicleCategories:[],
    vehicleCategoriesLoaded: false,
    vehicleCategoryList: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingVehicleCategoryList: false,
    routeNums: {},
    routeNumsLoading: false,
    routes: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    routesForTS: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingRoutes: false,
    scheduleTypes: {},
    routesGroup:{},
    routesGroupLoading: false,
    transportations: {},
    routeSchedule: {},
    loadingRouteSchedule: false,
    // активные маршруты
    active: [],
    activeWsFilter: [],
    // маркеры
    markers: {},
    // currentMarker: {},
    markersExtend: {},
    nextStation: {},
    // popup
    vehiclePopup: {},
    loadingVehiclePopup: false,
    // history
    vehicleHistory: [],
    loadingVehicleHistory: false,
    vehicleFlightHistory: {},
    loadingVehicleFlightHistory: false,
    filters: {},
    // organizations: {
    //     data: [],
    //     meta: {
    //         last_page: 0
    //     }
    // }
    filter: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    currentVehiclesListLoading: false,
    currentVehiclesList: [],
    currentVehicleTypeLoading: false,
    currentVehicleType: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    routes_: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    positions: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    positionsLoading: false,
    position: {},
    positionLoading: false,
    scheduleEvents: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    scheduleEventsLoading: false,
    employees: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    employeesLoading: false,
    organizations: {},
    organizationsLoading: false,
    loadingEquipments: false,
    equipments: [],
    brigades: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    brigadesLoading: false,
    brigadesNoWorkorder: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    employee: {},
    employeeLoading: false,
    loadingParks: false,
    parks: {},
    stationTypes: {},
    routeStation: {},
    loadingRouteStation: false,
    loadingScheduleTemplate: false,
    scheduleTemplate: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    scheduleTemplateStatuses: [],
    routesIntersection: {
        data: [],
    },
    workorders: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingWorkorders: false,
    workordersByDate: {
        data: {
            data: [],
            headers: {}
        },
        meta: {
            last_page: 0,
        }
    },
    loadingWorkordersByDate: false,
    workorderEventStatuses: {},
    // currentWorkorders: {},
    currentWorkordersLoading: false,
    trafficSchedule: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingTrafficSchedule: false,
    trafficScheduleNoWorkoder: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    workorderSelectedData: {
        date_at: new Date(),
        traffic_schedules: []
    },
    scheduleTemplateStations: {},
    loadingScheduleTemplateStations: false,
    workorderStatuses: {},
    trafficScheduleStatuses: {},
    trafficScheduleTypes: {},
    trafficScheduleEventsLoading: false,
    trafficScheduleEvents: {},
    appeals: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingAppeals: false,
    appealStatus:{},
    appealType: {},
    routeCheckpoints: {},
    routeCheckpointsLoading: false,
    routeSelectList: {
        data: []
    },
    nearStation: [],
    workorderEvents: {},
    loadingWorkorderEvents: false,
    routeRequests: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingRouteRequests: false,
    statusesRouteRequests: {},
    captcha: {},
    schedules: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingSchedules: false,
    scheduleStatuses: {},
    currentScheduleEvents: {},
    loadingCurrentScheduleEvents: false,
    scheduleTemplateCheckpoints: {},
    loadingScheduleTemplateCheckpoints: false,
    ticketPoints: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    ticketPointsLoading: false,
    ticketPointTypes: {},
    currentRoute: {},
    waybills: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingWaybills: false,
    stationAttributes: {},
    waybills_licence: {},
    changeRouteStatus: false,
    tariffs: {},
    loadingTariffs: false,
    vehicleCard: {},
    loadingVehicleCard: false,
    trafficScheduleListOfEvents: {},
    coords: [],
    coordsLoading: false,
    currentVehicle: {},
    currentVehicleLoading: {},
    summarySchedule: [],
    loadingSummarySchedule: false,
    summaryScheduleFilter: {},
    organizationInformation: {},
    loadingOrganizationInformation: false,
    telemetryStatistic: {},
    telemetryVehicleStatistic: {},
    workordersByReport: {
        data: []
    },
    loadingWorkordersByReport: false,
    workordersReportFilter : {},
    requisiteChartererTypes: {},
    requisiteList: {
        data: [],
        meta: {},
    },
    loadingRequisite: false,
    radioType: {
        data: [],
        meta: {},
    },
    loadingRadioType: false,
    eventManualStatus: {},
    eventManualFlightStatus: {},
    flightDefectGroup: [],
    flightDefects: {},
    reportByFlights: {},
    loadingReportByFlights: false,
    reportByFlightsFilter: {},
    historyOption: {},
    reportByVehicles: {},
    loadingReportByVehicles: false,
    reportByVehiclesFilter: {},
    employeesTransportation: {},
    loadingEmployeesTransportation: false,
    employeesTransportationFilter: {},
    flightTypes : {},
    speedViolationsReport: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingSpeedViolationsReport: false,
    speedViolationsReportFilter: {},
    transportSettings: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingTransportSettings: false,
    settingsGroup: { data: [] },
    loadingSettingsGroup: false,
    loadingPassengersCount: false,
    passengersCount: {
        data: [],
        meta: {},
    },
    passengersCountFilter: {},
    vehicleFilteredList: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    loadingVehicleFilteredList: false,
    vehicleFilteredListFilter: {},
    sidebar: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    // список
    sidebarLoading: false,
    sidebarParams: {
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25
    },
    // маршруты с контрольными точками (для карты)
    routeWithCheckPoints: {},
    loadingRouteWithCheckPoints: false,
    loadingTemplate: false,
    // проверить
    transorganizations: [],
    loadingTransorganizations: false,

    /* color speed */
    colorSpeed: [],
    colorSpeedList: {
        data: []
    },
    colorSpeedListLoading: false,
    colorSpeedListProps: {
        page: 1,
        // limit: 2
        limit: Number(localStorage.getItem('limit')) || 25,
    },
    routesGrouped: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    loadingRoutesGrouped: false,
    milestoneEvents: {},
    loadingButton: false,
    stationPolygon: [],
    loadingStationPolygon: false,
    stationByDate: [],
    loadingStationByDate: false,
    telemetryReceivers: {
        data: [],
        meta: {
            last_page: 0
        }
    },
    telemetryReceiversLoading: false,
    telemetryReceiverTypes: {},
    //Производственный календарь
    workCalendar: {},
    workCalendarLoading: false,
    workCalendarDayTypes: {},
    workCalendarDayTypesLoading: false,
    workCalendarHistory: {},
    workCalendarHistoryLoading: false,
    vehicleClasses: [],
    loadingVehicleClasses: false,
    routeSeasonality: {},
    regularTransportationsList: {},
    routeSortDirections: {},
    loadingRouteAdditionalParams: false,
    routeHistoryHuman: {},
    loadingRouteHistoryHuman: false,
    loadingGroupsList: [],

    loadingRouteChanged: false,
    showcase: {},
    loadingShowcase: false,
    showRouteByVehicle: false,
};

const sortSchedule = (data) => {
    const schedule = [...data];
    const convertDay = (day) => {
        switch (day) {
            case 'weekdays':
                return 1;
            case 'saturday':
                return 2;
            case 'sunday':
                return 3;
            case 'holiday':
                return 4;
            default:
                return '';
        }
    };
    schedule.sort((a, b) => {
        const aTime = Number(a.time.replace(':', ''));
        const bTime = Number(b.time.replace(':', ''));
        const aDay = convertDay(a.type_weekend);
        const bDay = convertDay(b.type_weekend);
        if (aDay === bDay) {
            return aTime < bTime ? -1 : aTime > bTime ? 1 : 0;
        } else {
            return aDay < bDay ? -1 : 1;
        }
    });
    return schedule;
};

// фильтрация машин по сокетам
const filteredMarkers = (activeWsFilter, filters, markers) => {
    if (
        activeWsFilter.length > 0
        || filters?.organization_id_list?.length > 0
        || filters?.num_list
        || filters?.category_id_list
        || filters?.in_registry
        || filters?.in_work_order
        || filters?.serial_egts?.length > 0
        || filters?.grz_list?.length > 0
    ) {
        const organizations = filters?.organization_id_list?.map(({ id }) => parseInt(id)) || [];
        const filterNum = filters?.num_list || [];
        const filterCategoryId = filters?.category_id_list?.map(({ id }) => parseInt(id)) || [];
        const in_registry = filters?.in_registry?.id || null;
        const in_work_order = filters?.in_work_order?.id || null;
        const serialEgts = filters?.serial_egts || [];
        const vehicleNumbers = filters?.grz_list?.map(({ id }) => id) || [];

        // фильтруем
        return Object.keys(markers).reduce((res, key) => {
            const item = markers[key];

            const num = item?.num || null;
            const category_id = item?.vehicle?.category_id || item?.category_id || 0;
            const organization_id = item?.vehicle?.organization_id || null;
            // const number = item?.vehicle?.number?.toUpperCase() || null;
            const serial_egts = item?.vehicle?.serial_egts || null;
            const vehicle_number = item?.vehicle?.number || null;

            // в реестре
            const isInRegistry = in_registry
                ? item?.in_registry === in_registry
                : true;

            // наряд
            const isInWorkOrder = in_work_order
                ? item?.in_work_order === in_work_order
                : true;

            // проверка по маршруту
            const isInActiveWsFilter = activeWsFilter.length
                ? num && category_id && activeWsFilter.includes(`${num}_${category_id}`)
                : true;

            // проверка по организации
            const isInOrganizations = organizations.length
                ? organization_id && organizations.includes(parseInt(organization_id))
                : true;

            // проверка по номеру (номер - может быть '1' и '1а')
            // const isInNum = filterNum?.length > 0
            //     ? num && `${filterNum}` === `${num}`
            //     : true;

            const isInNum = filterNum?.length > 0
                ? num && filterNum.reduce((r, i) => r || `${num?.toUpperCase?.()}`.includes(i?.toUpperCase?.()), false)
                : true;

            const isCategoryId = filterCategoryId?.length > 0
                ? filterCategoryId.includes(parseInt(category_id))
                : true;

            const isSerialEgts = serialEgts?.length > 0
                ? serialEgts.includes(serial_egts)
                : true;

            const isVehicleNumber = vehicleNumbers?.length > 0
                ? vehicleNumbers.includes(vehicle_number)
                : true;

            if (
                isInActiveWsFilter
                && isInOrganizations
                && isInNum
                && isCategoryId
                && isInRegistry
                && isInWorkOrder
                && isSerialEgts
                && isVehicleNumber
            ) {
                return {
                    ...res,
                    [key]: item,
                };
            }

            return res;
        }, {});
    }

    return null;
};


export default function reducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADING_STATIONS:
            return {
                ...state,
                loading: payload,
            };
        case types.LOADED_STATIONS:
            return {
                ...state,
                stationsList: payload,
            };
        case types.LOADING_SCHEDULE:
            return {
                ...state,
                scheduleLoading: payload,
            };
        case types.LOADED_SCHEDULE:
            const { station_uuid, data } = payload;
            const station_schedule = data[station_uuid] ? sortSchedule(data[station_uuid]) : [];
            return {
                ...state,
                schedule: {
                    ...state.schedule,
                    [station_uuid]: station_schedule,
                },
            };
        case types.LOADED_ROUTE_TYPES:
            return {
                ...state,
                types: payload,
            };
        case types.LOADING_BRANDS:
            return {
                ...state,
                loadingBrands: payload,
            };
        case types.LOADED_BRANDS:
            return {
                ...state,
                brands: payload,
            };
        case types.LOADING_VEHICLES_LIST:
            return {
                ...state,
                vehicleLoading: payload,
            };
        case types.LOADED_VEHICLES_LIST:
            return {
                ...state,
                vehicles: payload,
            };
        case types.LOADED_VEHICLES_LIST_NO_WORKORDER:
            return {
                ...state,
                vehiclesNoWorkorder: payload,
            };
        case types.LOADED_VEHICLES_CATEGORIES:
            return {
                ...state,
                vehicleCategories: payload.data,
                vehicleCategoriesLoaded: true,
            };
        case types.LOADED_VEHICLE_CATEGORY_LIST:
            return {
                ...state,
                vehicleCategoryList: payload
            };
        case types.LOADING_VEHICLE_CATEGORY_LIST:
            return {
                ...state,
                loadingVehicleCategoryList: payload
            };
        case types.LOADED_VEHICLES_TYPES:
            return {
                ...state,
                vehiclesTypes: payload.data
            };
        // case types.LOADED_COMPANY:
        //     return {
        //         ...state,
        //         organizations: payload
        //     };
        case types.LOADED_MODELS:
            return {
                ...state,
                models: payload,
            };
        case types.LOADED_COMPANIES:
            return {
                ...state,
                companies: payload,
            };
        case types.SAVE_COMPANIES:
            return {
                ...state,
                searchCompanies: payload,
            };
        case types.LOADING_ROUTE_NUMS:
            return {
                ...state,
                routeNumsLoading: payload
            };
        case types.LOADED_ROUTE_NUMS:
            return {
                ...state,
                routeNums: payload.data
            };
        case types.LOADING_ROUTES_GROUP:
            return {
                ...state,
                routesGroupLoading: payload
            };
        case types.LOADED_ROUTES_GROUP:
            return {
                ...state,
                routesGroup: payload.data
            };
        case types.LOADED_ROUTES:
            return {
                ...state,
                routes: payload
            };
        case types.LOADED_ROUTES_FOR_TS:
            return {
                ...state,
                routesForTS: payload
            };
        case types.LOADING_ROUTES:
            return {
                ...state,
                loadingRoutes: payload
            };
        case types.LOADED_ROUTES_STATUSES:
            return {
                ...state,
                statuses: payload.data
            };
        case types.LOADED_ROUTES_TRANSPORTATIONS:
            return {
                ...state,
                transportations: payload.data
            };
        case types.LOADING_ROUTE_SCHEDULE:
            return {
                ...state,
                loadingRouteSchedule: payload
            };
        case types.LOADED_ROUTE_SCHEDULE:
            return {
                ...state,
                routeSchedule: payload.data
            };
        case types.LOADED_SCHEDULE_TYPES:
            return {
                ...state,
                scheduleTypes: payload.data
            };
        case types.GET_FILTERED_LIST:
            return {
                ...state,
                filter: payload,
            };

        case types.SET_ACTIVE: {
            // фильтр для сокетов
            const activeWsFilter = payload
                .reduce(
                    (res, { num, category_id }) => {
                        const value = `${num}_${category_id}`;
                        return res.includes(value) ? res : [ ...res, value ];
                    },
                    []
                );

            const markers = filteredMarkers(
                activeWsFilter,
                state.filters,
                state.markers
            );
            if (markers) {
                return {
                    ...state,
                    active: payload,
                    activeWsFilter,
                    markers,
                };
            }

            return {
                ...state,
                active: payload,
                activeWsFilter,
            };
        }

        case types.CLEAR_ACTIVE:
            return {
                ...state,
                active: [],
                activeWsFilter: []
            };

        case types.CLEAR_MARKERS:
            return {
                ...state,
                markers: {}
            };

        case types.SET_WS_DATA: {
            const { activeWsFilter, filters } = state;

            if (Object.keys(payload).length) {
                const markers = filteredMarkers(
                    activeWsFilter,
                    filters,
                    // payload
                    {
                        ...state.markers,
                        ...payload
                    }
                );

                if (markers) {
                    return {
                        ...state,
                        markers
                        // markers: {
                        //     ...state.markers,
                        //     ...markers,
                        // },
                    };
                }

                return {
                    ...state,
                    markers: {
                        ...state.markers,
                        ...payload,
                    },
                };
                /*
                const diff = difference(Object.keys(payload), Object.keys(state.markers));
                const diffMarkers = diff.reduce((res, key) => ({ ...res, [key]: payload[key] }), {});

                if (diff.length) {
                    const markers = filteredMarkers(
                        activeWsFilter,
                        filters,
                        diffMarkers // payload
                    );

                    if (markers) {
                        return {
                            ...state,
                            markers: {
                                ...state.markers,
                                ...markers,
                            },
                        };
                    }

                    return {
                        ...state,
                        markers: {
                            ...state.markers,
                            ...payload,
                        },
                    };
                }
                */
            }

            return state;
        }

        case types.SET_MARKERS: {
            const {
                data, useFilter
            } = payload;
            const { activeWsFilter, filters } = state;

            const getKey = (item) => item.vehicle?.id ? `v_${item.vehicle?.id}` : `c_${item.external_id}`;

            const newMarkers = data.reduce((res, item) => {
                const k = getKey(item);
                if (!res.hasOwnProperty(k)) {
                    return {
                        ...res,
                        [k]: item,
                    };
                }
                return res;
            }, state.markers);

            if (useFilter) {
                const markers = filteredMarkers(
                    activeWsFilter,
                    filters,
                    newMarkers
                );

                if (markers) {
                    return {
                        ...state,
                        markers: {
                            // ...state.markers,
                            ...markers,
                        },
                    };
                }
            }

            return {
                ...state,
                markers: newMarkers,
            };
        }

        case types.CLEAR_WS_DATA: {
            return {
                ...state,
                markers: initialState.markers,
            };
        }

        case types.SET_MARKERS_EXTEND: {
            // const newMarkersExtend = {
            //     ...state.markersExtend,
            //     ...payload,
            // };
            //
            // const markersKeys = Object.keys(state.markers || {});
            //
            // // оставляем расширенные данные по отображаемым тс на карте
            // return {
            //     ...state,
            //     markersExtend: pick(newMarkersExtend, markersKeys),
            // };

            // const newMarkersExtend = {
            //     ...state.markersExtend,
            //     ...payload,
            // };

            const markersKeys = Object.keys(state.markers || {});

            const newMarkersExtend = {
                ...markersKeys.reduce((r, k)=> (r[k]={}, r),{}),
                ...payload,
            };

            // оставляем расширенные данные по отображаемым тс на карте
            return {
                ...state,
                markersExtend: pick(newMarkersExtend, markersKeys),
            };
        }

        case types.CLEAR_MARKERS_EXTEND: {
            return {
                ...state,
                markersExtend: initialState.markersExtend,
            };
        }

        // case types.SET_CURRENT_MARKER : {
        //     return {
        //         ...state,
        //         currentMarker: payload,
        //     };
        // }
        //
        // case types.REFRESH_CURRENT_MARKER : {
        //     if (state.currentMarker) {
        //         const getKey = (item) => item.vehicle?.id ? `v_${item.vehicle?.id}` : `c_${item.external_id}`;
        //
        //         const fItem = payload.find(item => getKey(item) === getKey(state.currentMarker));
        //
        //         if (fItem) {
        //             console.log('fItem', fItem);
        //             return {
        //                 ...state,
        //                 currentMarker: {
        //                     ...state.currentMarker,
        //                     ...fItem
        //                 },
        //             };
        //         }
        //     }
        //
        //     return state;
        // }

        case types.LOADED_NEXT_STATION: {
            return {
                ...state,
                nextStation: payload
            };
        }
        case types.LOADED_VEHICLE_POPUP: {
            return {
                ...state,
                vehiclePopup: payload
            };
        }
        case types.LOADING_VEHICLE_POPUP: {
            return {
                ...state,
                loadingVehiclePopup: payload
            };
        }
        case types.LOADED_VEHICLE_HISTORY: {
            return {
                ...state,
                vehicleHistory: payload
            };
        }
        case types.LOADING_VEHICLE_HISTORY: {
            return {
                ...state,
                loadingVehicleHistory: payload
            };
        }
        case types.LOADED_VEHICLE_FLIGHT_HISTORY: {
            return {
                ...state,
                vehicleFlightHistory: payload
            };
        }
        case types.LOADING_VEHICLE_FLIGHT_HISTORY: {
            return {
                ...state,
                loadingVehicleFlightHistory: payload
            };
        }
        case types.SET_FILTER: {
            const markers = filteredMarkers(
                state.activeWsFilter,
                payload,
                state.markers
            );

            if (markers) {
                return {
                    ...state,
                    filters: payload,
                    markers,
                };
            }

            return {
                ...state,
                filters: payload
            };
        }
        case types.CHANGE_FILTER: {
            const newFilter = {
                ...state.filters,
                ...payload
            };

            const markers = filteredMarkers(
                state.activeWsFilter,
                newFilter,
                state.markers
            );

            if (markers) {
                return {
                    ...state,
                    filters: newFilter,
                    markers,
                };
            }

            return {
                ...state,
                filters: newFilter
            };
        }
        case types.LOADING_CURRENT_VEHICLES: {
            return {
                ...state,
                currentVehiclesListLoading: payload
            };
        }
        case types.LOADED_CURRENT_VEHICLES: {
            return {
                ...state,
                currentVehiclesList: { ...state.currentVehiclesList, ...payload  }
            };
        }
        case types.LOADING_CURRENT_VEHICLE_TYPE: {
            return {
                ...state,
                currentVehicleTypeLoading: payload
            };
        }
        case types.LOADED_CURRENT_VEHICLE_TYPE: {
            return {
                ...state,
                currentVehicleType: payload
            };
        }
        case types.LOADED_POSITIONS: {
            return {
                ...state,
                positions: payload
            };
        }
        case types.LOADING_POSITIONS: {
            return {
                ...state,
                positionsLoading: payload
            };
        }
        case types.LOADED_POSITION: {
            return {
                ...state,
                position: { ...state.position, ...payload }
            };
        }
        case types.LOADING_POSITION: {
            return {
                ...state,
                positionLoading: payload
            };
        }
        case types.LOADED_SCHEDULE_EVENTS: {
            return {
                ...state,
                scheduleEvents: payload
            };
        }
        case types.LOADING_SCHEDULE_EVENTS: {
            return {
                ...state,
                scheduleEventsLoading: payload
            };
        }
        case types.LOADED_EMPLOYEES: {
            return {
                ...state,
                employees: payload
            };
        }
        case types.LOADING_EMPLOYEES: {
            return {
                ...state,
                employeesLoading: payload
            };
        }
        case types.LOADED_EMPLOYEE: {
            return {
                ...state,
                employee: { ...state.employee, ...payload }
            };
        }
        case types.LOADING_EMPLOYEE: {
            return {
                ...state,
                employeeLoading: payload
            };
        }
        case types.LOADED_BRIGADES: {
            return {
                ...state,
                brigades: payload
            };
        }
        case types.LOADING_BRIGADES: {
            return {
                ...state,
                brigadesLoading: payload
            };
        }
        case types.LOADED_BRIGADES_NO_WORKORDER: {
            return {
                ...state,
                brigadesNoWorkorder: payload
            };
        }
        case types.LOADING_PARKS_LIST: {
            return {
                ...state,
                loadingParks: payload
            };
        }
        case types.LOADED_PARKS_LIST: {
            return {
                ...state,
                parks: payload
            };
        }
        case types.LOADED_STATION_TYPES: {
            return {
                ...state,
                stationTypes: payload
            };
        }
        case types.LOADING_EQUIPMENTS_LIST: {
            return {
                ...state,
                loadingEquipments: payload
            };
        }
        case types.LOADED_EQUIPMENTS_LIST: {
            return {
                ...state,
                equipments: payload
            };
        }
        case types.LOADED_ROUTE_STATION: {
            return {
                ...state,
                routeStation: { ...state.routeStation, ...payload }
            };
        }
        case types.LOADING_ROUTE_STATION: {
            return {
                ...state,
                loadingRouteStation: payload
            };
        }
        case types.LOADING_SCHEDULE_TEMPLATE_LIST: {
            return {
                ...state,
                loadingScheduleTemplate: payload
            };
        }
        case types.LOADED_SCHEDULE_TEMPLATE_LIST: {
            return {
                ...state,
                scheduleTemplate: payload
            };
        }
        case types.CLEAR_SCHEDULE_TEMPLATE_LIST: {
            return {
                ...state,
                scheduleTemplate: {}
            };
        }
        case types.LOADED_SCHEDULE_TEMPLATE_STATUSES: {
            return {
                ...state,
                scheduleTemplateStatuses: payload
            };
        }
        case types.LOADED_INTERSECTION_ROUTES: {
            return {
                ...state,
                routesIntersection: payload
            };
        }
        case types.LOADED_WORKORDERS: {
            return {
                ...state,
                workorders: payload
            };
        }
        case types.LOADING_WORKORDERS: {
            return {
                ...state,
                loadingWorkorders: payload
            };
        }
        case types.LOADED_WORKORDERS_BY_DATE: {
            return {
                ...state,
                workordersByDate: payload
            };
        }
        case types.CLEAR_WORKORDERS_BY_DATE: {
            return {
                ...state,
                workordersByDate: {
                    data: {
                        data: [],
                        headers: {}
                    },
                    meta: {
                        last_page: 0,
                    }
                },
            };
        }
        case types.LOADING_WORKORDERS_BY_DATE: {
            return {
                ...state,
                loadingWorkordersByDate: payload
            };
        }
        case types.LOADED_WORKORDER_STATUSES: {
            return {
                ...state,
                workorderStatuses: payload
            };
        }
        case types.LOADED_TRAFFIC_SCHEDULE: {
            return {
                ...state,
                trafficSchedule: payload
            };
        }
        case types.LOADING_TRAFFIC_SCHEDULE: {
            return {
                ...state,
                loadingTrafficSchedule: payload
            };
        }
        case types.LOADED_TRAFFIC_SCHEDULE_NO_WORKORDER: {
            return {
                ...state,
                trafficScheduleNoWorkoder: payload
            };
        }
        case types.SET_SELECTED_WORKORDER_DATA: {
            return {
                ...state,
                workorderSelectedData: payload
            };
        }
        case types.LOADING_SCHEDULE_TEMPLATE_STATIONS_LIST: {
            return {
                ...state,
                loadingScheduleTemplateStations: payload
            };
        }
        case types.LOADED_SCHEDULE_TEMPLATE_STATIONS_LIST: {
            return {
                ...state,
                scheduleTemplateStations: {
                    ...state.scheduleTemplateStations,
                    [payload.id]: payload.data
                }
            };
        }
        case types.LOADED_TRAFFIC_SCHEDULE_STATUSES: {
            return {
                ...state,
                trafficScheduleStatuses: payload
            };
        }
        case types.LOADED_TRAFFIC_SCHEDULE_TYPES: {
            return {
                ...state,
                trafficScheduleTypes: payload
            };
        }
        case types.LOADING_CURRENT_TRAFFIC_SCHEDULE: {
            return {
                ...state,
                trafficScheduleEventsLoading: payload
            };
        }
        case types.LOADED_CURRENT_TRAFFIC_SCHEDULE: {
            return {
                ...state,
                trafficScheduleEvents: { ...state.trafficScheduleEvents, [payload[0]?.traffic_schedule_id]: payload },
            };
        }
        case types.LOADING_APPEAL:
            return {
                ...state,
                loadingAppeals: payload,
            };
        case types.LOADED_APPEAL:
            return {
                ...state,
                appeals: payload,
            };
        case types.LOADED_APPEAL_STATUS:
            return {
                ...state,
                appealStatus: payload
            };
        case types.LOADED_APPEAL_TYPE:
            return {
                ...state,
                appealType: payload
            };
        case types.LOADING_ROUTE_CHECKPOINTS:
            return {
                ...state,
                routeCheckpointsLoading: payload
            };
        case types.LOADED_ROUTE_CHECKPOINTS:
            return {
                ...state,
                routeCheckpoints: {
                    ...state.routeCheckpoints,
                    [payload.id]: payload.data
                }
            };
        case types.LOADED_NEAR_STATION:
            return {
                ...state,
                nearStation: payload
            };
        case types.ROUTE_SELECT_LIST:
            return {
                ...state,
                routeSelectList: {
                    data: payload
                }
            };
        case types.LOADED_ROUTE_WITH_CHECK_POINTS:
            return {
                ...state,
                routeWithCheckPoints: payload.reduce((res, item) => ({
                    ...res,
                    [item.id]: item,
                }), state.routeWithCheckPoints)
            };
        case types.CLEAR_ROUTE_WITH_CHECK_POINTS:
            return {
                ...state,
                routeWithCheckPoints: {}
            };

        case types.LOADING_ROUTE_WITH_CHECK_POINTS:
            return {
                ...state,
                loadingRouteWithCheckPoints: payload
            };
        case types.LOADED_WORKORDER_EVENTS: {
            return {
                ...state,
                workorderEvents: { ...state.workorderEvents, ...payload }
            };
        }
        case types.LOADING_WORKORDER_EVENTS: {
            return {
                ...state,
                loadingWorkorderEvents: payload
            };
        }
        case types.CLEAR_WORKORDERS: {
            return {
                ...state,
                workorders: {
                    data: [],
                    meta: {
                        last_page: 0,
                    }
                },
            };
        }
        case types.LOADED_WORKORDER_EVENT_STATUSES: {
            return {
                ...state,
                workorderEventStatuses: payload
            };
        }
        // case types.LOADED_CURRENT_WORKORDER: {
        //     return {
        //         ...state,
        //         currentWorkorders: {
        //             ...state.currentWorkorders,
        //             ...payload
        //         }
        //     };
        // }
        case types.LOADING_CURRENT_WORKORDER: {
            return {
                ...state,
                currentWorkordersLoading: payload
            };
        }
        case types.LOADED_ROUTE_REQUESTS_LIST: {
            return {
                ...state,
                routeRequests: payload
            };
        }
        case types.LOADING_ROUTE_REQUESTS_LIST: {
            return {
                ...state,
                loadingRouteRequests: payload
            };
        }
        case types.LOADED_STATUSES_ROUTE_REQUESTS: {
            return {
                ...state,
                statusesRouteRequests: payload
            };
        }
        case types.LOADED_CAPTCHA: {
            return {
                ...state,
                captcha: payload
            };
        }
        case types.FETCHED_SCHEDULES: {
            return {
                ...state,
                schedules: payload
            };
        }
        case types.FETCHING_SCHEDULES: {
            return {
                ...state,
                loadingSchedules: payload
            };
        }
        case types.FETCHED_SCHEDULE_STATUSES: {
            return {
                ...state,
                scheduleStatuses: payload
            };
        }
        case types.FETCHED_SCHEDULE_EVENTS: {
            return {
                ...state,
                currentScheduleEvents: { ...state.currentScheduleEvents, ...payload }
            };
        }
        case types.FETCHING_SCHEDULE_EVENTS: {
            return {
                ...state,
                loadingCurrentScheduleEvents: payload
            };
        }
        case types.LOADED_SCHEDULETEMPLATE_CHECKPOINTS:
            return {
                ...state,
                scheduleTemplateCheckpoints: {
                    ...state.scheduleTemplateCheckpoints,
                    [payload.id]: payload.data
                }
            };
        case types.LOADING_SCHEDULETEMPLATE_CHECKPOINTS: {
            return {
                ...state,
                loadingScheduleTemplateCheckpoints: payload
            };
        }
        case types.LOADED_TICKET_POINTS: {
            return {
                ...state,
                ticketPoints: payload
            };
        }
        case types.LOADING_TICKET_POINTS: {
            return {
                ...state,
                ticketPointsLoading: payload
            };
        }
        case types.LOADED_TICKET_POINT_TYPES: {
            return {
                ...state,
                ticketPointTypes: payload
            };
        }
        case types.SAVE_CURRENT_ROUTE: {
            return {
                ...state,
                currentRoute: payload
            };
        }
        case types.CLEAR_CURRENT_ROUTE: {
            return {
                ...state,
                currentRoute: {}
            };
        }
        case types.LOADED_WAYBILLS_LIST: {
            return {
                ...state,
                waybills: payload
            };
        }
        case types.LOADING_WAYBILLS_LIST: {
            return {
                ...state,
                loadingWaybills: payload
            };
        }
        case types.LOADED_STATION_ATTRIBUTES: {
            return {
                ...state,
                stationAttributes: payload
            };
        }
        case types.LOADED_WAYBILLS_LICENCE: {
            return {
                ...state,
                waybills_licence: payload
            };
        }
        case types.CHANGE_ROUTE_AFTER: {
            // изменяем маршрут и фильтры после сохранения маршрута
            const newRouteData = payload;
            const findIndex = state.active.findIndex(({ id }) => parseInt(id) === parseInt(newRouteData.id));

            // console.log('findIndex', findIndex);

            if (findIndex >= 0) {
                const findItem = state.active[findIndex];

                // const categoryId = filters.category_id?.id || 0;
                // const num = filters.num || '';

                // if (!categoryId || (
                //     categoryId > 0 && parseInt(currentCategoryId) === categoryId
                // )) {
                //     const filterListByCategory = routeNums[currentCategoryId].filter((item) => (
                //         num?.length > 0 && item.num.includes(filters.num)) || num?.length === 0
                //     );

                const newActive = [
                    ...state.active.slice(0, findIndex),
                    {
                        ...findItem,
                        ...newRouteData,
                    },
                    ...state.active.slice(findIndex + 1),
                ];

                // фильтр для сокетов
                const activeWsFilter = newActive
                    .reduce(
                        (res, { num, category_id }) => {
                            const value = `${num}_${category_id}`;
                            return res.includes(value) ? res : [ ...res, value ];
                        },
                        []
                    );

                const markers = filteredMarkers(
                    activeWsFilter,
                    state.filters,
                    state.markers
                );

                if (markers) {
                    return {
                        ...state,
                        active: newActive,
                        activeWsFilter,
                        markers,
                    };
                }

                return {
                    ...state,
                    active: newActive,
                    activeWsFilter,
                };
            }

            return state;
        }
        case types.SET_CHANGE_ROUTE_STATUS: {
            return {
                ...state,
                changeRouteStatus: payload
            };
        }
        case types.LOADED_TARIFFS_LIST: {
            const { data, type } = payload;
            return {
                ...state,
                tariffs: {
                    ...state.tariffs,
                    [type]: data
                }
            };
        }
        case types.LOADING_TARIFFS_LIST: {
            return {
                ...state,
                loadingTariffs: payload
            };
        }
        case types.LOADING_VEHICLE_CARD: {
            return {
                ...state,
                loadingVehicleCard: payload
            };
        }
        case types.LOADED_VEHICLE_CARD: {
            return {
                ...state,
                vehicleCard: payload
            };
        }
        case types.CLEAR_VEHICLE_CARD: {
            return {
                ...state,
                vehicleCard: {}
            };
        }
        case types.LOADED_TRAFFIC_SCHEDULE_LIST_OF_EVENTS: {
            return {
                ...state,
                trafficScheduleListOfEvents: payload
            };
        }

        case types.LOADED_COORDS: {
            return {
                ...state,
                coords: payload
            };
        }

        case types.LOADING_COORDS: {
            return {
                ...state,
                coordsLoading: payload
            };
        }

        case types.CLEAR_COORDS: {
            return {
                ...state,
                coords: initialState.coords,
                coordsLoading: initialState.coordsLoading,
            };
        }

        case types.LOADED_VEHICLE: {
            const {
                key,
                data,
            } = payload;
            return {
                ...state,
                currentVehicle: {
                    ...state.currentVehicle,
                    [key]: data[0] || {}
                }
            };
        }

        case types.LOADING_VEHICLE: {
            const {
                key,
                bool,
            } = payload;

            if (bool) {
                return {
                    ...state,
                    currentVehicleLoading: {
                        ...state.currentVehicleLoading,
                        [key]: bool,
                    }
                };
            }
            const currentVehicleLoading = {
                ...state.currentVehicleLoading
            };
            delete currentVehicleLoading[key];
            return {
                ...state,
                currentVehicleLoading: {
                    ...currentVehicleLoading
                },
            };
        }

        case types.CLEAR_VEHICLE: {
            const key = payload || null;

            if (key) {
                const currentVehicle = {
                    ...state.currentVehicle,
                };
                delete currentVehicle[key];
                const currentVehicleLoading = {
                    ...state.currentVehicleLoading,
                };
                delete currentVehicleLoading[key];

                return {
                    ...state,
                    currentVehicle,
                    currentVehicleLoading,
                };
            }

            return {
                ...state,
                currentVehicle: initialState.currentVehicle,
                currentVehicleLoading: initialState.currentVehicleLoading,
            };
        }

        case types.LOADED_SUMMARY_SCHEDULE: {
            return {
                ...state,
                summarySchedule: payload
            };
        }

        case types.LOADING_SUMMARY_SHEDULE: {
            return {
                ...state,
                loadingSummarySchedule: payload
            };
        }

        case types.CLEAR_SUMMARY_SCHEDULE: {
            return {
                ...state,
                summarySchedule: initialState.summarySchedule,
                loadingSummarySchedule: initialState.loadingSummarySchedule,
            };
        }

        case types.SET_SUMMARY_SCHEDULE_FILTER: {
            return {
                ...state,
                summaryScheduleFilter: payload
            };
        }

        case types.CLEAR_SUMMARY_SCHEDULE_FILTER: {
            return {
                ...state,
                summaryScheduleFilter: {},
                summarySchedule: []
            };
        }

        case types.LOADED_ORGANIZATION_INFORMATION: {
            return {
                ...state,
                organizationInformation: payload,
            };
        }

        case types.LOADING_ORGANIZATION_INFORMATION: {
            return {
                ...state,
                loadingOrganizationInformation: payload,
            };
        }

        case types.LOADED_TELEMETRY_STATISTIC: {
            return {
                ...state,
                telemetryStatistic: payload
            };
        }

        case types.LOADED_VEHICLE_TELEMETRY_STATISTIC: {
            return {
                ...state,
                telemetryVehicleStatistic: payload
            };
        }

        case types.LOADING_WORKORDERS_REPORT: {
            return {
                ...state,
                loadingWorkordersByReport: payload
            };
        }

        case types.LOADED_WORKORDERS_REPORT: {
            return {
                ...state,
                workordersByReport: payload
            };
        }

        case types.LOADED_WO_REQUISITE_CHARTERER_TYPES: {
            return {
                ...state,
                requisiteChartererTypes: payload
            };
        }

        case types.LOADED_WO_REQUISITE_LIST: {
            return {
                ...state,
                requisiteList: payload
            };
        }

        case types.LOADING_WO_REQUISITE: {
            return {
                ...state,
                loadingRequisite: payload
            };
        }

        case types.LOADED_RADIO_TYPE: {
            return {
                ...state,
                radioType: payload,
            };
        }

        case types.LOADING_RADIO_TYPE: {
            return {
                ...state,
                loadingRadioType: payload,
            };
        }

        case types.LOADED_WORKORDER_EVENT_MANUAL_STATUSES: {
            return {
                ...state,
                eventManualStatus: payload
            };
        }

        case types.LOADED_WORKORDER_EVENT_FLIGHT_MANUAL_STATUSES: {
            return {
                ...state,
                eventManualFlightStatus: payload
            };
        }

        case types.LOADED_FLIGHT_DEFECT_GROUP: {
            return {
                ...state,
                flightDefectGroup: payload
            };
        }

        case types.LOADED_FLIGHT_DEFECTS: {
            return {
                ...state,
                flightDefects: {
                    ...state.flightDefects,
                    [payload.group]: payload.data
                }
            };
        }

        case types.LOADED_REPORT_BY_FLIGHTS: {
            return {
                ...state,
                reportByFlights: payload,
            };
        }

        case types.LOADING_REPORT_BY_FLIGHTS: {
            return {
                ...state,
                loadingReportByFlights: payload,
            };
        }

        case types.SET_REPORT_BY_FLIGHTS_FILTER: {
            return {
                ...state,
                reportByFlightsFilter: payload,
            };
        }

        case types.CLEAR_REPORT_BY_FLIGHTS: {
            return {
                ...state,
                reportByFlights: {},
                reportByFlightsFilter: {},
            };
        }

        case types.SET_HISTORY_OPTION: {
            return {
                ...state,
                historyOption: payload,
            };
        }

        case types.LOADED_REPORT_BY_VEHICLES: {
            return {
                ...state,
                reportByVehicles: payload,
            };
        }

        case types.LOADING_REPORT_BY_VEHICLES: {
            return {
                ...state,
                loadingReportByVehicles: payload,
            };
        }

        case types.SET_REPORT_BY_VEHICLES_FILTER: {
            return {
                ...state,
                reportByVehiclesFilter: payload,
            };
        }

        case types.CLEAR_REPORT_BY_VEHICLES_FILTER: {
            return {
                ...state,
                reportByVehicles: initialState.reportByVehicles,
                reportByVehiclesFilter: {},
            };
        }
        case types.LOADED_EMPLOYEES_TRANSPORTATION_REPORT: {
            return {
                ...state,
                employeesTransportation: payload,
            };
        }

        case types.LOADING_EMPLOYEES_TRANSPORTATION_REPORT: {
            return {
                ...state,
                loadingEmployeesTransportation: payload,
            };
        }

        case types.SET_EMPLOYEES_TRANSPORTATION_REPORT_FILTER: {
            return {
                ...state,
                employeesTransportationFilter: payload,
            };
        }

        case types.CLEAR_EMPLOYEES_TRANSPORTATION_REPORT_FILTER: {
            return {
                ...state,
                employeesTransportationFilter: {},
                employeesTransportation: initialState.employeesTransportation,
            };
        }

        case types.LOADED_FLIGHT_TYPES: {
            return {
                ...state,
                flightTypes: payload.data
            };
        }

        case types.LOADED_SPEED_VIOLATIONS_REPORT: {
            return {
                ...state,
                speedViolationsReport: payload,
            };
        }

        case types.LOADING_SPEED_VIOLATIONS_REPORT: {
            return {
                ...state,
                loadingSpeedViolationsReport: payload,
            };
        }

        case types.SET_SPEED_VIOLATIONS_REPORT_FILTER: {
            return {
                ...state,
                speedViolationsReportFilter: payload,
            };
        }

        case types.CLEAR_SPEED_VIOLATIONS_REPORT_FILTER: {
            return {
                ...state,
                speedViolationsReport: initialState.speedViolationsReport,
                speedViolationsReportFilter: {},
            };
        }

        case types.LOADED_TRANSPORT_SETTINGS:
            return {
                ...state,
                transportSettings: payload
            };

        case types.LOADING_TRANSPORT_SETTINGS:
            return {
                ...state,
                loadingTransportSettings: payload
            };

        case types.LOADED_SETTINGS_GROUP:
            return {
                ...state,
                settingsGroup: payload
            };

        case types.LOADING_SETTINGS_GROUP:
            return {
                ...state,
                loadingSettingsGroup: payload
            };

        case types.SET_WORKORDERS_REPORT_FILTER:
            return {
                ...state,
                workordersReportFilter: payload
            };

        case types.CLEAR_WORKORDERS_REPORT_FILTER:
            return {
                ...state,
                workordersByReport: {
                    data: []
                },
                workordersReportFilter: {},
            };

        case types.LOADED_PASSENGERS_COUNT: {
            return {
                ...state,
                passengersCount: payload
            };
        }

        case types.LOADING_PASSENGERS_COUNT: {
            return {
                ...state,
                loadingPassengersCount: payload
            };
        }

        case types.SET_PASSENGERS_COUNT_FILTER: {
            return {
                ...state,
                passengersCountFilter: payload
            };
        }

        case types.CLEAR_PASSENGERS_COUNT_FILTER: {
            return {
                ...state,
                passengersCountFilter: {},
                passengersCount: initialState.passengersCount,
            };
        }

        case types.LOADED_FILTERED_LIST: {
            return {
                ...state,
                vehicleFilteredList: payload || initialState.vehicleFilteredList
            };
        }
        case types.LOADING_FILTERED_LIST: {
            return {
                ...state,
                loadingVehicleFilteredList: payload
            };
        }

        case types.SET_FILTERED_LIST_FILTER: {
            return {
                ...state,
                vehicleFilteredListFilter: payload
            };
        }

        case types.CLEAR_FILTERED_LIST_FILTER: {
            return {
                ...state,
                vehicleFilteredListFilter: {},
                vehicleFilteredList: initialState.vehicleFilteredList
            };
        }

        case types.LOADED_SIDEBAR: {
            const {
                data,
                replace,
            } = payload;

            if (replace) {
                return {
                    ...state,
                    sidebar: data,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state.sidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_SIDEBAR: {
            return {
                ...state,
                sidebarLoading: payload,
            };
        }

        case types.SET_SIDEBAR_PARAMS: {
            return {
                ...state,
                sidebarParams: {
                    ...state.sidebarParams,
                    ...payload,
                },
            };
        }

        case types.SET_ROUTE_WS: {
            return {
                ...state,
                sidebarParams: {
                    ...state.sidebarParams,
                    page: 1,
                },
            };
        }

        case types.LOADING_TEMPLATE: {
            return {
                ...state,
                loadingTemplate: payload
            };
        }

        case types.LOADING_TRANSORGANIZATIONS: {
            return {
                ...state,
                loadingTransorganizations: payload
            };
        }

        case types.LOADED_TRANSORGANIZATIONS: {
            return {
                ...state,
                transorganizations: payload
            };
        }

        /* color speed */
        case types.LOADED_COLOR_SPEED: {
            return {
                ...state,
                colorSpeed: payload
            };
        }
        case types.LOADED_COLOR_SPEED_LIST: {
            return {
                ...state,
                colorSpeedList: payload
            };
        }
        case types.LOADING_COLOR_SPEED_LIST: {
            return {
                ...state,
                colorSpeedListLoading: payload
            };
        }
        case types.SET_COLOR_SPEED_LIST_PROPS: {
            return {
                ...state,
                colorSpeedListProps: payload
            };
        }
        case types.RESET_COLOR_SPEED_LIST_PROPS: {
            return {
                ...state,
                colorSpeedListProps: initialState.colorSpeedListProps
            };
        }
        case types.LOADED_ROUTES_GROUPED: {
            return {
                ...state,
                routesGrouped: payload
            };
        }

        case types.LOADING_ROUTES_GROUPED: {
            return {
                ...state,
                loadingRoutesGrouped: payload,
            };
        }

        case types.LOADED_MILESTONE_EVENTS: {
            return {
                ...state,
                milestoneEvents: payload,
            };
        }

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload,
            };
        }

        case types.LOADED_STATION_POLYGON: {
            return {
                ...state,
                stationPolygon: payload,
            };
        }

        case types.LOADING_STATION_POLYGON: {
            return {
                ...state,
                loadingStationPolygon: payload,
            };
        }

        case types.LOADED_STATION_SCHEDULE_BY_DATE: {
            return {
                ...state,
                stationByDate: payload,
            };
        }

        case types.LOADING_STATION_SCHEDULE_BY_DATE: {
            return {
                ...state,
                loadingStationByDate: payload,
            };
        }

        case types.LOADED_TELEMETRY_RECEIVERS: {
            return {
                ...state,
                telemetryReceivers: payload,
            };
        }

        case types.LOADING_TELEMETRY_RECEIVERS: {
            return {
                ...state,
                telemetryReceiversLoading: payload,
            };
        }

        case types.LOADED_TELEMETRY_RECEIVER_TYPES: {
            return {
                ...state,
                telemetryReceiverTypes: payload,
            };
        }

        case types.LOADING_CHANGE_ROUTE_STATUS: {
            return {
                ...state,
                loading: payload,
            };
        }

        //Производственный календарь
        case types.LOADED_WORK_CALENDAR: {
            return {
                ...state,
                workCalendar: payload,
            };
        }

        case types.LOADING_WORK_CALENDAR: {
            return {
                ...state,
                workCalendarLoading: payload,
            };
        }

        case types.LOADED_WORK_CALENDAR_DAY_TYPES: {
            return {
                ...state,
                workCalendarDayTypes: payload,
            };
        }

        case types.LOADING_WORK_CALENDAR_DAY_TYPES: {
            return {
                ...state,
                workCalendarDayTypesLoading: payload,
            };
        }

        case types.LOADED_WORK_CALENDAR_HISTORY: {
            return {
                ...state,
                workCalendarHistory: payload,
            };
        }

        case types.LOADING_WORK_CALENDAR_HISTORY: {
            return {
                ...state,
                workCalendarHistoryLoading: payload,
            };
        }

        case types.LOADING_VEHICLE_CLASSES: {
            return {
                ...state,
                loadingVehiclesClasses: payload,
            };
        }

        case types.LOADED_VEHICLE_CLASSES: {
            return {
                ...state,
                vehicleClasses: payload,
            };
        }

        case types.LOADED_REGULAR_TRANSPORTATIONS: {
            return {
                ...state,
                regularTransportationsList: payload,
            };
        }

        case types.LOADED_SEASONALITY: {
            return {
                ...state,
                routeSeasonality: payload,
            };
        }

        case types.LOADED_ROUTE_SORT_DIRECTIONS: {
            return {
                ...state,
                routeSortDirections: payload,
            };
        }

        case types.LOADING_ROUTE_ADDITIONAL_PARAMS: {
            return {
                ...state,
                loadingRouteAdditionalParams: payload,
            };
        }

        case types.LOADING_ROUTE_HISTORY_HUMAN: {
            return {
                ...state,
                loadingRouteHistoryHuman: payload,
            };
        }

        case types.LOADED_ROUTE_HISTORY_HUMAN: {
            return {
                ...state,
                routeHistoryHuman: payload,
            };
        }

        case types.ADD_GROUP_TO_LOADING_GROUPS: {
            return {
                ...state,
                loadingGroupsList: [...state.loadingGroupsList, payload]
            };
        }

        case types.REMOVE_GROUP_FROM_LOADING_GROUPS: {
            return {
                ...state,
                loadingGroupsList: state.loadingGroupsList.filter(el => {
                    return el.num !== payload.num && el.category_id !== payload.category_id;
                }),
            };
        }

        // обновление роута если есть
        case types.UPDATE_ROUTE: {
            const index = state.routes?.data?.findIndex(({ id }) => id === payload?.id);
            if (index >= 0) {
                return {
                    ...state,
                    routes: {
                        ...state.routes,
                        data: [
                            ...state.routes?.data?.slice(0, index),
                            {
                                ...state.routes?.data?.[index],
                                ...payload,
                            },
                            ...state.routes?.data?.slice(index + 1),
                        ],
                    }
                };
            }
            return state;
        }

        case types.SET_ROUTE_LOADING : {
            return {
                ...state,
                loadingRouteChanged: payload
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.SET_SHOW_ROUTE_BY_VEHICLE: {
            return {
                ...state,
                showRouteByVehicle: payload
            };
        }

        default:
            return state;
    }
};
