import React, { useState } from 'react';
import { IconButton } from '@mui/material';

import buttons from 'helpers/constants/buttons';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import type { Button } from 'components/common/FormButtons/buttonsTypes';

import type { ButtonProps } from '@mui/material';

interface FieldsModalProps {
    children: React.ReactNode;
    buttonVariant?: ButtonProps['variant'];
    // поля формы
    fields?: { [key: string]: any };
    onChange?: (value: any) => void;
    // заголовок модалки
    title?: string;
    fullWidth?: boolean;
    buttonText?: string;
    buttonType?: Button;
    iconButton?: React.ReactNode;
    hideButtonAccept?: boolean;
}

const FieldsModal = ({
    fields: data = {},
    onChange,
    title,
    fullWidth,
    buttonText = buttons.SELECT,
    buttonVariant = 'text',
    buttonType,
    children,
    iconButton,
    hideButtonAccept,
}: FieldsModalProps) => {
    const [fields, setFields] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [isChange, setIsChange] = useState(false);

    const handleCloseModal = () => {
        setIsOpen(false);
        setFields({});
    };

    const handleShowModal = () => {
        setFields(data);
        setIsOpen(true);
    };

    const handleAccept = () => {
        onChange && onChange(fields);
        setIsOpen(false);
        setFields({});
    };

    const handleChange = (obj: Iterable<Object>) => {
        setFields((old) => ({
            ...old,
            ...obj,
        }));
        setIsChange(true);
    };

    const buttonsList = () => {
        const buttons = [
            {
                ...buttonsTypes.close,
                onClick: handleCloseModal,
            },
        ];

        if (!hideButtonAccept) {
            buttons.push({
                ...buttonsTypes.apply,
                onClick: () => handleAccept(),
                disabled: !isChange,
            });
        }

        return buttons;
    };

    const components = Array.isArray(children) ? children : [children];

    return (
        <>
            {iconButton ? (
                <IconButton size="small" onClick={handleShowModal}>
                    {iconButton}
                </IconButton>
            ) : (
                <FormButtons
                    className="form__button"
                    justButton
                    buttons={[
                        {
                            ...(buttonType && { ...buttonType }),
                            onClick: handleShowModal,
                            variant: buttonVariant,
                            color: 'infoButton',
                            name: buttonText, // true ? buttons.EDIT_ON_MAP : buttons.SELECT_ON_MAP
                        },
                    ]}
                />
            )}

            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    title={title || ''}
                    fullWidth={fullWidth}
                    buttons={<FormButtons buttons={buttonsList()} />}
                >
                    {components.map((child, index) =>
                        child.type
                            ? <child.type
                                key={index}
                                {...child.props}
                                {...fields}
                                onChange={handleChange}
                            />
                            : child
                    )}
                </Modal>
            )}
        </>
    );
};

export default FieldsModal;
