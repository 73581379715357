import { lazy, Suspense } from 'react';

import Loading from 'components/common/Loading';

const InfoPanelLazy = (props) => {
    const InfoPanel = lazy(() =>
        new Promise((resolve) =>
            setTimeout(() => resolve(import('modules/React/Reports/InfoPanel')), 50)
        )
    );
    return (
        <Suspense fallback={<Loading linear/>}>
            <InfoPanel {...props} />
        </Suspense>
    );
};

export default InfoPanelLazy;
