import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import { adminSelectors } from 'redux/Admin';
import { getLibraryAPI } from 'redux/Admin/actions';
import PageLayout from 'components/layout/PageLayout';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import NoData from 'components/common/Information/NoData';
import Context from 'helpers/context';
import { LSContainer } from 'components/common/List';

import LibraryAPIItem from './LibraryAPIItem';
import Form from './Form';
import Filter from './Filter';

type LibraryAPIItemType = {
  id: number;
  name: string;
  microservice: string;
  url: string;
}

const LibraryAPI = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);
    
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [params, setParams] = useState({ page: 1, data: {} });

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const libraryList = useSelector(adminSelectors.libraryAPI);
    const loading = useSelector(adminSelectors.loading);

    const reloadList = () => dispatch(getLibraryAPI(params.page, limit, params.data));

    useEffect(() => {
        dispatch(getLibraryAPI(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (
            <>
                {libraryList?.data?.length > 0
                    ? (<>
                        <LSContainer
                            headers={[
                                { title: 'Название', width: '200px' },
                                { title: 'Микросервис', width: '25%' },
                                { title: 'URL' },
                            ]}
                        >
                            <List className="list">
                                {libraryList?.data.map((item: LibraryAPIItemType) => (
                                    <LibraryAPIItem
                                        key={item.id}
                                        item={item}
                                        reloadList={reloadList}
                                    />
                                ))}
                            </List>
                        </LSContainer>
                    </>)
                    : (!loading && <NoData/>)
                }
            </>
        );
    };

    return <>
        <PageLayout
            header="Библиотека АPI"
            filters={<Filter onFilterChanged={(filter: any) => setParams({ page: 1, data: filter })}/>}
            informPanelProps={{ 
                buttons: (
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: () => setIsOpenModal(true),
                                disabled: !permissions?.is_create
                            }
                        ]}
                        noPadding
                        noMarginLeft
                    />
                ),
                total: libraryList?.meta?.total
            }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: libraryList?.meta,
                limit,
                setLimit
            }}
        />

        {isOpenModal && (
            <Form
                onClose={() => setIsOpenModal(false)}
                isOpen={isOpenModal}
                reloadList={() => setParams({ page: 1, data: {} })}
            />
        )}
    </>;
};

export default LibraryAPI;
