import { Checkbox, FormControlLabel, TextField } from '@mui/material';

import { useValidation } from 'helpers/hooks';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import Attachments from 'components/common/Upload/Attachments';
import SelectCategories from 'modules/React/utils/components/SelectCategories';
import SelectEventObjects from 'modules/React/utils/components/SelectEventObjects';
import SelectEvents from 'modules/React/utils/components/SelectEvents';
import SelectIncidentFactors from 'modules/React/utils/components/SelectIncidentFactors';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';
import SelectKeywords from 'modules/React/utils/components/SelectKeywords';
import type { ResponseScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

interface BasicDataProps {
     formData: ResponseScenario;
     handleChange: (name: string, value: any) => void
}

const BasicData = ({ formData, handleChange }: BasicDataProps) => {
    const validation = useValidation();

    return (
        <div>
            <FormInfoWrapper
                className="block"
                helperText={validation.get('name')}
                error={validation.isKey('name')}
            >
                <TextField
                    className="block"
                    name="name"
                    label="Название"
                    required
                    value={formData?.name || ''}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                    error={validation.isKey('name') || !formData?.name}
                /> 
            </FormInfoWrapper>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={formData.is_typical}
                        onChange={(e) => handleChange('is_typical', e.target.checked)}
                        name="is_typical"
                    />
                }
                label="Типовой"
            />

            <SelectCategories
                className="block"
                selected={formData.category}
                onChange={(value) => handleChange('category', value)}
                helperText={validation.get('category_id')}
                error={validation.isKey('category_id')}
            />

            <SelectIncidentTypes
                required
                className="block"
                selected={formData.types}
                error={!!validation.isKey('types') || !formData.types?.id}
                helperText={validation.get('types')}
                onChange={(value) => handleChange('types', value)}
            />

            <SelectIncidentFactors
                className="block"
                multiple
                selected={formData?.factors || []}
                error={!!validation.isKey('factors')}
                helperText={validation.get('factors')}
                onChange={(value) => handleChange('factors', value)}
            />

            <SelectKeywords
                className="block"
                multiple
                selected={formData.keyword_list}
                onChange={(value) => handleChange('keyword_list', value)}
                helperText={validation.get('keyword_id_list')}
                error={validation.isKey('keyword_id_list')}
            />

            <SelectEvents
                className="block"
                selected={formData.event}
                onChange={(value: any) => {
                    handleChange('event', value); 
                    handleChange('event_id', value?.id);
                    handleChange('event_object_list', []);
                }}
                helperText={validation.get('event_id')}
                error={validation.isKey('event_id')}
            />

            <SelectEventObjects
                multiple={true}
                className="block"
                selected={formData.event_object_list}
                onChange={(value) => handleChange('event_object_list', value)}
                disabled={!formData.event_id}
                filter={formData.event_id ? { event_id: formData.event_id } : {}}
                helperText={validation.get('event_object_id_list')}
                error={validation.isKey('event_object_id_list')}
            />

            <FormInfoWrapper
                className="block"
                helperText={validation.get('comment')}
                error={validation.isKey('comment')}
            >
                <TextField
                    className="block"
                    name="comment"
                    label="Комментарий"
                    value={formData?.comment || ''}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                /> 
            </FormInfoWrapper>

            <Attachments
                onlyLink
                //@ts-ignore
                onChange={(value) => handleChange('attachments', value)}
                //@ts-ignore
                files={formData?.attachments || []}
            />
        </div>
    );
};

export default BasicData;
