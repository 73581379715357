import { Collapse } from '@mui/material';

import CopyTextButton from 'components/common/CopyTextButton';

import type { CityCamera } from './types';

interface InfoProps {
    isOpen: boolean;
    item: CityCamera;
}

const Info = ({ isOpen, item }: InfoProps) => {
    const externalLinks = Object.entries(item?.external_links || {});

    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
            <div>
                <b>Внешние ссылки</b>
                {externalLinks.map(el => <div key={el[0]} style={{ marginLeft: 15 }}>
                    <span>{el[0]} : {el[1]}</span> <CopyTextButton text={el[1]} />
                </div>)}
            </div>
        </Collapse>
    );
};

export default Info;
