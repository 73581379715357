import messages from 'helpers/constants/messages';
import renderAddress from 'helpers/renderAddress';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import InformationComponent from 'components/common/Information';

const Information = ({ data }) => {
    const fields = {
        id: {
            title: 'ID',
        },
        registered_at: {
            title: 'Дата/время инцидента',
            value: ({ registered_at }) => fullDateTimeWithoutSeconds(registered_at) || messages.NO_DATA
        },
        created_at: {
            title: 'Дата/время регистрации',
            value: ({ created_at }) => fullDateTimeWithoutSeconds(created_at) || messages.NO_DATA
        },
        address: {
            title: 'Адрес',
            value: ({ address_text, address }) => address_text || renderAddress(address || {}) || messages.NO_DATA
        },
        status_text: {
            title: 'Статус',
        },
        threat_level: {
            title: 'Уровень угрозы',
            value: ({ threat_level }) => threat_level?.color
                ? <span style={{ color: threat_level.color }}>{threat_level?.name}</span>
                : threat_level?.name || messages.NO_DATA
        },
        description: {
            title: 'Описание',
        },
        type_text: {
            title: 'Тип',
        },
        response_scenario: {
            title: 'Сценарий реагирования',
            value: ({ response_scenario }) => response_scenario?.name || messages.NO_DATA
        }
    };

    return (
        <InformationComponent
            title={fields}
            data={data}
            returnNull
        />
    );
};

export default Information;
