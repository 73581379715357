import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import * as actions from 'redux/TrafficLights/actions';
import SelectCoordinationGroup from 'components/common/Autocomplete/CoordinationGroup';
import SelectGreenStreet from 'components/common/Autocomplete/GreenStreet';
import useStoreProp from 'helpers/hooks/useStoreProp';
import titles from 'helpers/constants/titles';
import { useDebounce } from 'helpers/hooks';


const useStyles = makeStyles({
    adornment: {
        position: 'absolute',
        right: '16px',
    },
});

const Filter = (props) => {
    const {
        data,
        onChange,
        onChangeTextField,
    } = props;
    const dispatch = useDispatch();
    const styles = useStyles();
    const [openModalCG, setOpenModalCG] = useState(false);
    const [openModalGS, setOpenModalGS] = useState(false);

    const statuses = useStoreProp(actions.loadTrafficLightsStatuses, 'trafficLights', 'lightsStatuses');
    const states = useStoreProp(actions.loadTrafficLightsStates, 'trafficLights', 'lightStates');
    const controlTypes = useStoreProp(actions.loadTrafficLightControlTypes, 'trafficLights', 'lightControlTypes');

    const fetchCG = useDebounce((e) => {
        dispatch(actions.loadCoordinationGroups(e));
    }, 500);
    const handleFetchCG = ({ page, limit, query: name }) => fetchCG({ page, limit, name });

    const fetchGS = useDebounce((e) => {
        dispatch(actions.loadGreenStreets(e));
    }, 500);
    const handleFetchGS = ({ page, limit, query: name }) => fetchGS({ page, limit, name });

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    const handleEventAC = (key) => (_, newValue) => {
        onChange({
            [key]: newValue
        });
    };

    const handleAccept = (selectedItem) => {
        onChange({ cg_ids: selectedItem });
        setOpenModalCG(false);
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <FormControl className="block" size="small" variant="outlined">
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={data.name || ''}
                    name="name"
                    onChange={handleChange}
                    type="text"
                    className="block"
                />
            </FormControl>
            <FormControl className="block" size="small" variant="outlined" >
                <InputLabel>{titles.STATUS}</InputLabel>
                <Select
                    value={data.status?.id || ''}
                    onChange={({ target: { value } }) => onChange({ status: { id: value, name: statuses[value] } })}
                    label={titles.STATUS}
                    name="status"
                >
                    {Object.keys(statuses).map((index) => (
                        <MenuItem key={index} value={index}>{statuses[index]}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className="block">
                <Autocomplete
                    value={data.state_type_ids || null}
                    options={states}
                    noOptionsText={titles.NO_RESULTS}
                    isOptionEqualToValue={(option, value) => option.external_id === value.id?.join('')}
                    getOptionLabel={(option) => option.full_name || option.name}
                    onChange={(_, value) => onChange({ state_type_ids: { id: [value?.external_id], name: value?.full_name } })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATE}
                        />
                    )}
                />
            </div>
            <div className="block">
                <Autocomplete
                    value={data.control_type_ids || null}
                    options={controlTypes}
                    noOptionsText={titles.NO_RESULTS}
                    isOptionEqualToValue={(option, value) => option.external_id === value.id?.join('')}
                    getOptionLabel={(option) => option.full_name || option.name}
                    onChange={(_, value) => onChange({ control_type_ids: { id: [value?.external_id], name: value?.full_name } })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Тип контроля"
                        />
                    )}
                />
            </div>
            <div className="block">
                <SelectGreenStreet
                    multiple
                    selected={data.gs_ids || []}
                    onChange={(selectedItem) => {
                        onChange({ gs_ids: selectedItem });
                        setOpenModalGS(false);
                    }}
                />
            </div>
            <div className="block">
                <SelectCoordinationGroup
                    multiple
                    selected={data.cg_ids || []}
                    onChange={handleAccept}
                />
            </div>
        </form>
    );
};

export default Filter;
