import React from 'react';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import titles from 'helpers/constants/titles';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import type { PhenomenonsItem } from 'modules/React/Dictionaries/Triggers/types';

import PhenomenonForm from './Form';
import Item from './Item';


interface PhenomenonsProps {
    phenomenons: PhenomenonsItem[];
    onChange: (value: PhenomenonsItem[]) => void;
}

const Phenomenons = ({ phenomenons, onChange }: PhenomenonsProps) => {
    const [openAddForm, setOpenAddForm] = React.useState(false);


    const handleAdd = (item: PhenomenonsItem) => {
        onChange([...phenomenons, item]);
    };

    const handleEdit = ( index: number, item: PhenomenonsItem ) => {
        const newItems = [
            ...phenomenons.slice(0, index),
            item,
            ...phenomenons.slice(index + 1),
        ];
        onChange(newItems);
    };

    const handleDelete = (index: number) => {
        if (index >= 0) {
            onChange([
                ...phenomenons.slice(0, index),
                ...phenomenons.slice(index + 1),
            ]);
        }
    };
    
    return <>
        <h2>Явления* :</h2>
        <InformPanel
            buttons={
                <FormButtons
                    noPadding
                    noMarginLeft
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenAddForm(true),
                        }
                    ]}
                />
            }
        />

        {phenomenons?.length > 0
            && <LSContainer
                headers={[
                    { title: titles.NAME, width: '65%' },
                    { title: '', width: '10%' },
                    { title: 'Значение', width: '20%' },
                ]}
            >
                {phenomenons.map((item, index) => (
                    <Item
                        key={item.phenomenon?.id}
                        index={index}
                        item={item}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />
                ))}
            </LSContainer>
        }



        {openAddForm && (
            <PhenomenonForm
                isOpen={openAddForm}
                onClose={() => setOpenAddForm(false)}
                onAccept={handleAdd}
            />
        )}
    </>;
};

export default Phenomenons;