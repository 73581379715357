import * as types from './types';

/**
 * Actions
 **/

// dashboard
export const loadDashboard = (props = {}) => ({
    type: types.LOAD_DASHBOARD,
    payload: props
});
export const loadedDashboard = (data) => ({
    type: types.LOADED_DASHBOARD,
    payload: data
});
export const clearDashboard = () => ({
    type: types.CLEAR_DASHBOARD,
});

export const loadingDashboard = (bool = false) => ({
    type: types.LOADING_DASHBOARD,
    payload: bool
});

export const loadUserDashboard = (props = {}) => ({
    type: types.LOAD_USER_DASHBOARD,
    payload: props
});
export const loadedUserDashboard = (data) => ({
    type: types.LOADED_USER_DASHBOARD,
    payload: data
});
export const loadingUserDashboard = (bool = false) => ({
    type: types.LOADING_USER_DASHBOARD,
    payload: bool
});

export const editUserDashboard = (data, callback = () => {}) => ({
    type: types.EDIT_USER_DASHBOARD,
    payload: { data },
    callback
});


export const loadDashboardsItem = (filter, callback) => ({
    type: types.LOAD_DASHBOARDS_ITEM,
    payload: filter,
    callback
});
export const loadedDashboardsItem = (data) => ({
    type: types.LOADED_DASHBOARDS_ITEM,
    payload: data
});
export const loadingDashboards = (bool = false) => ({
    type: types.LOADING_DASHBOARDS,
    payload: bool
});

export const loadDashboardItemUser = (filter, callback) => ({
    type: types.LOAD_DASHBOARD_ITEM_USER,
    payload: filter,
    callback
});
export const loadedDashboardItemUser = (data) => ({
    type: types.LOADED_DASHBOARD_ITEM_USER,
    payload: data
});
export const clearDashboardItemUser = () => ({
    type: types.CLEAR_DASHBOARD_ITEM_USER,
});
export const createDashboardItemUser = (data, callback) => ({
    type: types.CREATE_DASHBOARD_ITEM_USER,
    payload: data,
    callback
});
export const editDashboardItemUser = (id, data, callback) => ({
    type: types.EDIT_DASHBOARD_ITEM_USER,
    payload: {
        id,
        data
    },
    callback
});

export const editDashboardItemUserMultiply = (data, callback) => ({
    type: types.EDIT_DASHBOARD_ITEM_USER_MULTIPLY,
    payload: data,
    callback
});
export const deleteDashboardItemUser = (id, callback) => ({
    type: types.DELETE_DASHBOARD_ITEM_USER,
    payload: id,
    callback
});
export const loadingDashboardItemUser = (bool = false) => ({
    type: types.LOADING_DASHBOARD_ITEM_USER,
    payload: bool
});

//user dashboard
export const loadUserAvailableDashboardList = (props = {}) => ({
    type: types.LOAD_USER_AVAILABLE_DASHBOARD_LIST,
    payload: props
});
export const loadedUserAvailableDashboardList = (data) => ({
    type: types.LOADED_USER_AVAILABLE_DASHBOARD_LIST,
    payload: data
});
export const loadingUserAvailableDashboardList = (bool = false) => ({
    type: types.LOADING_USER_AVAILABLE_DASHBOARD_LIST,
    payload: bool
});
