import * as actions from 'redux/TransportSpecial/actions';
import SelectDateHistory from 'components/common/Transport/TransportHistory/SelectDateHistory';

const ModalButton = ({
    vehicleId,
    titleHistory,
}) => {
    return (
        <SelectDateHistory
            uuid={vehicleId}
            title={titleHistory()}
            actions={{
                storeProp: 'transportSpecial',
                history: {
                    action: actions.loadVehicleHistoryModal,
                    clear: actions.clearVehicleHistoryModal,
                    loadingProp: 'historyModalLoading',
                    dataProp: 'historyModal',
                },
                speedList: {
                    action: actions.loadColorSpeed,
                    dataProp: 'colorSpeed',
                }
            }}
        />
    );
};

export default ModalButton;
