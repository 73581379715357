import Settings from '../Settings';

import dictionariesRoutes from './DictionariesRoutes';
import reportsRoutes from './ReportsRoutes';

const routes = [
    ...dictionariesRoutes,
    {
        path: '/react/settings',
        component: Settings,
        exact: true,
    },
    ...reportsRoutes,
];

export default routes;
