import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Badge, Container } from '@mui/material';
import { TabContext } from '@mui/lab';

import { notificationsSelectors } from 'redux/Notifications';
import titles from 'helpers/constants/titles';
import { useNotifications } from 'helpers/hooks';
import Modal from 'components/common/Modal';
import { CustomTab, CustomTabs } from 'components/common/Tabs';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import style from '../notification.module.scss';

import Filter from './Filter';
import NotificationList from './NotificationList';

import type { Meta } from 'types/pagination';

const getTabList = (count?: number) => [
    {
        header: { label: 'Все' },
        filter: {},
    },
    {
        header: {
            label: 'Новые',
            icon: (
                <Badge
                    className={style.notify__tab}
                    color="primary"
                    max={1000}
                    badgeContent={count}
                />
            ),
            className: style.notify__tab__wrap,
        },
        filter: {
            viewed: 0,
        },
        reload: true,
        clearList: true,
    },
];

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
};

interface NotificationModalProps {
    isOpen: boolean;
    onClose: () => void;
}

function NotificationModal({ isOpen, onClose }: NotificationModalProps) {
    const notifications = useNotifications();

    // const listMeta: Meta = useSelector(notificationsSelectors.listMeta);
    const countActive: number = useSelector(notificationsSelectors.countActive);
    const wsReload: boolean = useSelector(notificationsSelectors.wsReload);

    const [tab, setTab] = useState(0);
    const [filteredTotal, setFilteredTotal] = useState<number | null>(null);

    const countNotView = useMemo(() => {
        return filteredTotal ?? (countActive || 0);
    }, [filteredTotal, countActive]);

    // если открыта - перезагрузить
    useEffect(() => {
        if (wsReload) {
            const { filter, props } = params;

            notifications.reloadList({ ...filter, ...props });
            notifications.wsReloadList(false);
        }
    }, [wsReload]);

    useEffect(() => {
        return () => {
            notifications.clearList();
        };
    }, []);

    useEffect(() => {
        notifications.clearList();
    }, [tab]);

    const initialParams = {
        page: 1,
        limit: 25,
        filter: {},
        props: {
            ...getTabList(countActive)[tab].filter,
        },
    };

    const [params, setParams] = useState(initialParams);

    useEffect(() => {
        const { page, limit, filter, props } = params;

        if (Object.keys(filter).length > 0) {
            notifications.loadList(page, limit, { ...filter, ...props }, ({ meta }: { meta: Meta }) => {
                setFilteredTotal(meta?.total);
            });
        } else {
            notifications.loadList(page, limit, { ...filter, ...props });
            setFilteredTotal(null);
        }

    }, [params]);

    const handleChangeTab = (newTab: number) => {
        setTab(newTab);
        setParams((old) => ({
            ...old,
            page: 1,
            props: {
                ...getTabList()[newTab].filter,
            },
        }));
    };

    const handleAllIsMarked = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        notifications.changeReadAll();

        if (getTabList()[tab].clearList) {
            notifications.clearList();
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={titles.NOTIFICATIONS}
            showCloseInTitle
            noPadding
            wrapperStyle={{ height: '100%' }}
            contentStyle={{ paddingTop: 30, display: 'flex', flexDirection: 'column' }}
            buttonStyleWrap={{ justifyContent: 'center' }}
            noOverflowY
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.defaultInfo,
                            onClick: handleAllIsMarked,
                            disabled: countActive === 0,
                            name: 'Все просмотрены',
                        }
                    ]}
                />
            }
        >
            <Filter setParams={setParams}/>

            <TabContext value={String(tab)}>
                <AppBar position="static" color="default" style={{ marginTop: 16, zIndex: 2 }}>
                    <CustomTabs
                        value={tab}
                        onChange={(_, value) => handleChangeTab(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        {getTabList(countNotView).map((item, index) => (
                            <CustomTab key={`tab_header_${index}`} {...item.header} {...a11yProps(index)} />
                        ))}
                    </CustomTabs>
                </AppBar>

                <Container className={style.container}>
                    <NotificationList
                        params={params}
                        openedTab={getTabList()[tab]}
                        onClose={onClose}
                    />
                </Container>
            </TabContext>
        </Modal>
    );
}

export default NotificationModal;
