import { memo, useEffect, useState } from 'react';

import { loadAlertTemplateFileds } from 'redux/Boards/actions';
import { useStoreProp } from 'helpers/hooks';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import RenderTextMessage from '../../RenderTextMessage';
import RenderCondition from '../../../AlertTemplates/AddEdit/RenderCondition';

export const TextMsg = memo((props) => {
    const { onChange, data, board } = props;

    const alertFields = useStoreProp(
        loadAlertTemplateFileds,
        'boards',
        'alertTemplateFields'
    );

    const [serviceEventList, setServiceEventList] = useState({});

    useEffect(() => {
        setServiceEventList({ ...alertFields.ecologe,  ...alertFields.meteologe });
    }, [alertFields]);

    const addNewText = () => {
        onChange([
            ...data,
            {
                type: 'text',
                'line-spacing': 10,
                x: 0,
                y: 0,
                'align-x': 'center',
                'align-y': 'center',
                font: '',
                size: 24,
                text: '',
                color: '#fcff00'
            }
        ]);
    };

    const onChangeText = (i, el) => {
        const newData = [
            ...data.slice(0, i),
            el,
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    const onDeleteText = (i) => {
        const newData = [
            ...data.slice(0, i),
            ...data.slice(i + 1)
        ];
        onChange(newData);
    };

    return (
        <>
            <h2>Текстовое сообщение:</h2>
            {data.map((el, index) => (
                <div
                    key={index}
                    style={{
                        backgroundColor: index % 2 === 0 ? 'rgba(155,157,176,0.2)' : 'inherit',
                        padding: '0.5rem'
                    }}
                >
                    <RenderTextMessage
                        key={index}
                        item={el}
                        onChange={(item) => onChangeText(index, item)}
                        onDelete={() => onDeleteText(index)}
                        board={board}
                        serviceEventList={serviceEventList}
                        // disableDelete={data.length === 1}
                    />
                    <RenderCondition
                        textData={el}
                        data={el?.condition || []}
                        onChange={(val) => onChangeText(index, val)}
                    />
                </div>
            ))}

            <div style={{ marginTop: 10 }}>
                <FormButtonsComponent
                    noPadding
                    positionLeft
                    noMarginLeft
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: addNewText,
                        }
                    ]}
                />
            </div>
        </>
    );
});
