import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import { editIncident } from 'modules/React/redux/actions';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useAvailableStatuses } from 'modules/React/utils/helpers/hooks';
import type { StatusItem } from 'components/common/Autocomplete/Statuses';
import StatusesSelect from 'components/common/Autocomplete/Statuses';

import type { IncidentItem } from '../../types';

interface StatusModalProps {
    isOpen: boolean;
    onClose: () => void;
    item: IncidentItem;
    reloadList: () => void;
}

interface Data {
    is_archive: boolean;
    status: StatusItem;
}

const StatusModal = ({ isOpen, onClose, item, reloadList }: StatusModalProps) => {
    const dispatch = useDispatch();

    const availalbleStatuses: StatusItem[] = useAvailableStatuses(item?.response_scenario_id, item.status_id as number);

    const [data, setData] = useState({
        is_archive: item.is_archive || false,
        status: item.status_id ? { id: item.status_id, name: item.status_text } : availalbleStatuses[0]
    });

    const onSave = () => {
        dispatch(editIncident(
            item.id,
            removeEmptyFields({ ...item, is_archive: data.is_archive, status_id: data.status.id }),
            () => { reloadList(); onClose(); }
        ));
    };

    const onChangeIsArchive = (value: boolean) => {
        setData((prev: Data) => ({
            ...prev,
            is_archive: value,
        }));
    };

    const isDisableEdit = data.status.name === 'Завершен' || data.status.name === 'Отказ';

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            small={true}
            title="Изменение статуса"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose,
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <StatusesSelect
                    selected={data.status}
                    options={availalbleStatuses}
                    disabled={isDisableEdit || availalbleStatuses.length === 0}
                    renderLabel={(option) => option?.name || ''}
                    // @ts-ignore
                    onChange={(st) => setData(prev => ({
                        ...prev,
                        status: st
                    }))}
                    disableReset
                />
                {isDisableEdit && <FormControlLabel
                    style={{ pointerEvents: 'none' }}
                    control={
                        <Checkbox
                            disabled={false}
                            checked={data.is_archive}
                            onChange={(e) => onChangeIsArchive(e.target.checked)}
                            color="primary"
                            style={{ pointerEvents: 'auto' }}
                        />
                    }
                    label="В архив"
                />}
            </form>
        </Modal>
    );
};

export default StatusModal;
