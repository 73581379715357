import titles from 'helpers/constants/titles';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import {
    Marker,
    ToolTip
} from 'components/MapComponents/leaflet';
import { createIconMarker } from 'components/MapComponents/Layers/Incidents/helper';

const CreateMarker = ({
    item,
    color,
    onClick,
    ...props
}) => (
    <Marker
        {...props}
        icon={createIconMarker(color)}
        latlng={[item.lat, item.lon]}
        attribution={{ color }}
        onClick={onClick}
    >
        <ToolTip
            offset={[0, -30]}
            direction="top"
        >
            <div><strong>ID: </strong>{item.id || ''}</div>
            <div><strong>{titles.DTP_TYPE}: </strong>{item.dtp_type_name || ''}</div>
            <div><strong>Дата/время: </strong>{fullDateTimeWithoutSeconds(item.dtp_at) || ''}</div>
        </ToolTip>
    </Marker>
);

export default CreateMarker;
