import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, FormHelperText, TextField } from '@mui/material';

import { createPark, editPark, loadCategories } from 'redux/Parking/actions';
import buttons from 'helpers/constants/buttons';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import AddressList from 'components/common/Location/AddressList';
import FieldsModal from 'components/common/Location/FieldsModal';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import TitleValidator from 'components/common/Location/TitleValidator';
import Modal from 'components/common/Modal';
import SimpleSelect from 'components/common/SimpleSelect';
import Attachments from 'components/common/Upload/Attachments';

import type { ParkingArea, Tariff, CategoryType } from './types';

interface ModalFormProps {
    isOpen: boolean
    onClose: () => void
    isNew?: boolean
    item?: ParkingArea
    tariffs: Tariff[]
    reloadList: () => void
}

const ModalForm = ({
    isOpen = false,
    onClose = () => {},
    isNew = false,
    item,
    tariffs,
    reloadList
}: ModalFormProps) => {
    const dispatch = useDispatch();

    const categories = useStoreProp(
        loadCategories,
        'parking',
        'categories'
    );

    const initialState = {
        name: item?.name || '',
        address: item?.address || {},
        address_text: item?.address_text || '',
        contacts: item?.contacts || '',
        description: item?.description || '',
        location: item?.location || '',
        tariff_id: item?.tariff_id,
        tariff: item?.tariff || null,
        category_id: item?.category_id,
        photo: item?.photo || '',
        is_aggregating: item?.is_aggregating || false,
        is_blocked: item?.is_blocked || false,
        total: item?.spaces.total || 0,
        common: item?.spaces.common || 0,
        handicapped: item?.spaces.handicapped || 0,
        lat: item?.address?.geo_lat || null,
        lon: item?.address?.geo_lon || null,
    };

    const [data, setData] = useState(initialState);
    const [files, setFiles] = useState<string[]>(!!data?.photo ? [data?.photo] : []);
    const [dislocationData, setDislocationData] = useState(
        item?.address
            ? {
                lat: item?.address?.geo_lat,
                lon: item?.address?.geo_lon,
            }
            : {}
    );

    const changeObj = (props: any) => {
        setData((oldFields) => ({
            ...oldFields,
            ...props,
        }));

        if (props.hasOwnProperty('lat') || props.hasOwnProperty('lon')) {
            setDislocationData({
                ...props
            });
        }
    };

    const handleChange = (name: string) => (value: any) => {
        validation.deleteKey(name);
        setData((oldFields) => ({
            ...oldFields,
            [name]: value,
        }));
    };

    const validation = useValidation();

    const handleFileUpload = (url: string) => {
        setFiles([url]);
        setData((oldFields) => ({
            ...oldFields,
            photo: url,
        }));
    };

    const handleReload = () => {
        onClose();
        reloadList();
    };

    const onSubmit = () => {
        const prepareData = {
            ...data,
            tariff_id: data.tariff?.number,
            spaces: {
                common: data.common,
                total: data.total,
                handicapped: data.handicapped,
            }
        };
        isNew
            ? dispatch(createPark(prepareData, handleReload))
            : dispatch(editPark(item?.id, prepareData, handleReload));
    };

    const isDisable = !data?.name || !data?.address || !data?.contacts || !data?.description
        || !data?.location || !data?.tariff_id || !data?.category_id || !data?.photo
        || !data?.total || !data?.common || !data?.handicapped;

    return <>
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? 'Добавить парковку' : 'Редактировать парковку'}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSubmit,
                        disabled: isDisable,
                    }
                ]}
            />}
        >
            <div className="row">
                <TextField
                    value={data.name}
                    name="name"
                    onChange={(e) => handleChange('name')(e.target.value)}
                    className="row__item"
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    required={true}
                    error={validation.isKey('name')}
                    helperText={validation.get('name')}
                />
                <TextField
                    value={data.contacts}
                    name="contacts"
                    onChange={(e) => handleChange('contacts')(e.target.value)}
                    className="row__item"
                    label="Контакты"
                    variant="outlined"
                    size="small"
                    required={true}
                    error={validation.isKey('contacts')}
                    helperText={validation.get('contacts')}
                />
            </div>
            <div className="row">
                <TextField
                    value={data.description}
                    name="description"
                    onChange={(e) => handleChange('description')(e.target.value)}
                    className="row__item"
                    label={titles.DESCRIPTION}
                    variant="outlined"
                    size="small"
                    required={true}
                    error={validation.isKey('description')}
                    helperText={validation.get('description')}
                />
                <TextField
                    value={data.total}
                    name="total"
                    onChange={(e) => handleChange('total')(e.target.value)}
                    className="row__item"
                    label="Общее количество мест"
                    variant="outlined"
                    size="small"
                    required={true}
                    type="number"
                    inputProps={{ min: 0 }}
                    error={validation.isKey('spaces')}
                    helperText={validation.get('spaces')}
                />
            </div>
            <div className="row block">
                <TextField
                    value={data.common}
                    name="common"
                    onChange={(e) => handleChange('common')(e.target.value)}
                    className="row__item"
                    label="Количество общих мест"
                    variant="outlined"
                    size="small"
                    required={true}
                    type="number"
                    inputProps={{ min: 0 }}
                    error={validation.isKey('spaces')}
                    helperText={validation.get('spaces')}
                />
                <TextField
                    value={data.handicapped}
                    name="handicapped"
                    onChange={(e) => handleChange('handicapped')(e.target.value)}
                    className="row__item"
                    label="Количество мест для инвалидов"
                    variant="outlined"
                    size="small"
                    required={true}
                    type="number"
                    inputProps={{ min: 0 }}
                    error={validation.isKey('spaces')}
                    helperText={validation.get('spaces')}
                />
            </div>

            <div className="block">
                <SimpleSelect
                    selected={data.tariff}
                    options={tariffs}
                    onChange={(value) => handleChange('tariff')(value)}
                    label="Тариф"
                    renderLabel={(option) => option?.number || ''}
                    isOptionEqualToValue={(option, value) => option?.number === value?.number}
                    error={validation.isKey('tariff_id')}
                    helperText={validation.get('tariff_id')}
                    required
                />
            </div>

            <div className="block">
                <SimpleSelect
                    selected={data.category_id as CategoryType}
                    options={categories}
                    onChange={(value) => handleChange('category_id')(value)}
                    label="Категория"
                    renderLabel={(option) => option?.name_ru || ''}
                    error={validation.isKey('category_id')}
                    helperText={validation.get('category_id')}
                    required
                />
            </div>
            <div className="row">
                <div className="row__item" style={{ display: 'flex' }}>
                    <Checkbox
                        checked={data?.is_aggregating}
                        onChange={() => handleChange('is_aggregating')(!data?.is_aggregating)}
                        color="primary"
                        style={{ paddingTop: 0 }}
                        title="Перехватывающая"
                    />
                    <span>Перехватывающая</span>
                </div>
                <div className="row__item" style={{ display: 'flex' }}>
                    <Checkbox
                        checked={data?.is_blocked}
                        onChange={() => handleChange('is_blocked')(!data?.is_blocked)}
                        color="primary"
                        style={{ paddingTop: 0 }}
                        title="Заблокирована"
                    />
                    <span>Заблокирована</span>
                </div>

            </div>
            <div className="block">
                <LoadAddressByCoords
                    {...data}
                    onChange={changeObj}
                    isLoadOnChange
                >
                    <AddressList required returnGeometry/>
                </LoadAddressByCoords>
            </div>
            <div className="block">
                <MapDragMarker
                    {...dislocationData}
                    onChange={changeObj}
                    required
                />
            </div>
            <TitleValidator
                fields={data}
                validationFields={['location']}
            >
                {titles.STOP_AREA} *:
            </TitleValidator>
            {validation.isKey('location')
                ? (
                    <FormHelperText className="error">
                        {validation.get('location')}
                    </FormHelperText>
                )
                : null
            }
            <FieldsModal
                title="Выбрать область на карте"
                buttonText={
                    data?.location
                        ? buttons.EDIT_ON_MAP
                        : buttons.SELECT_ON_MAP
                }
                fields={{ geometry: data?.location || {} }}
                onChange={({ geometry }: any) => handleChange('location')(geometry)}
                fullWidth={false}
                iconButton={null}
            >
                <MapGeoJson
                    visibleGeometry={
                        createPointGJ(
                            dislocationData?.lat,
                            dislocationData?.lon
                        )
                    }
                    required
                    isHideValidation
                />
            </FieldsModal>
            <div className="block">
                <Attachments
                    // @ts-ignore
                    onChange={handleFileUpload}
                    service={'parking'}
                    files={files}
                    label={titles.ATTACH_FILE}
                    renderContent={null}
                    onlyLink
                    singleFile
                />
            </div>
        </Modal>
    </>;
};

export default ModalForm;
