import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    IconButton,
    Tooltip,
} from '@mui/material';

import { roadworksSelectors } from 'redux/RoadWorks';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import PassportPage from 'components/pages/Dictionaries/RoadWorks/Orders/PassportPage';

const Orders = ({
    data,
    readOnly,
    reloadList
}) => {
    const ordersData = useSelector(roadworksSelectors.ordersData);
    const [openedOrder, setOpenedOrder] = useState(null);

    useEffect(() => {
        // Обновить PassportPage при изменении ordersData
        setOpenedOrder(prev => prev ? ordersData.find(el => el.id === prev.id) : null);
    }, [ordersData]);

    return (
        <>
            {ordersData?.length > 0 && (
                <>
                    <h2 style={{ marginLeft: 10 }}>Заявки: </h2>
                    {ordersData?.map(item => (
                        <Fragment key={item.id}>
                            <div className="info__item" style={{ justifyContent: 'space-between' }}>
                                <div>
                                    <span className="info__item-title">
                                        Дата:
                                    </span>
                                    {fullDateTimeWithoutSeconds(item.created_at)}{', '}
                                    <span className="info__item-title">
                                        Номер:
                                    </span>
                                    {item.id}{', '}
                                    <span className="info__item-title">
                                        Название:
                                    </span>
                                    {item.name}
                                </div>
                                {!readOnly && (
                                    <Tooltip title="Открыть заявку">
                                        {/* <IconButton size="small" onClick={() => routeToOrder(item.id)}>
                                             <i className="fas fa-external-link-alt" />
                                        </IconButton> */}
                                        <IconButton size="small" onClick={() => setOpenedOrder(item)}>
                                            <i className="fas fa-external-link-alt" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                            <hr/>
                        </Fragment>
                    ))}
                </>
            )}
            <Modal
                isOpen={!!openedOrder}
                onClose={() => setOpenedOrder(null)}
                noPadding={true}
                title={data?.name}
                fullWidth
                showCloseInTitle
                buttons={
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenedOrder(null)
                            },
                        ]}
                    />
                }
            >
                <PassportPage
                    currentOrder={openedOrder}
                    tab="executionStages"
                    vehicle={data}
                    reloadList={reloadList}
                />
            </Modal>
        </>
    );
};

export default Orders;
