import * as types from './types';

// special transport Infrastructure
export const loadInfrastructureTypes = (page = 1, limit = 25, query) => ({
    type: types.LOAD_INFRASTRUCTURE_TYPES,
    payload: { page, limit, query }
});

export const loadingInfrastructureTypes = (bool = false) => ({
    type: types.LOADING_INFRASTRUCTURE_TYPES,
    payload: bool
});

export const loadedInfrastructureTypes = (data) => ({
    type: types.LOADED_INFRASTRUCTURE_TYPES,
    payload: data
});

export const createInfrastructureTypes = (data, callback = () => {}) => ({
    type: types.CREATE_INFRASTRUCTURE_TYPES,
    payload: data,
    callback
});

export const editInfrastructureTypes = (id, data, callback = () => {}) => ({
    type: types.EDIT_INFRASTRUCTURE_TYPES,
    payload: { id, data },
    callback
});

export const deleteInfrastructureTypes = (id, callback = () => {}) => ({
    type: types.DELETE_INFRASTRUCTURE_TYPES,
    payload: id,
    callback
});

export const loadingInfrastructure = (bool = false) => ({
    type: types.LOADING_INFRASTRUCTURE,
    payload: bool
});

export const loadInfrastructure = (page = 1, limit = 25, query) => ({
    type: types.LOAD_INFRASTRUCTURE,
    payload: { page, limit, query }
});

export const loadedInfrastructure = (data) => ({
    type: types.LOADED_INFRASTRUCTURE,
    payload: data
});

export const createInfrastructure = (data, callback) => ({
    type: types.CREATE_INFRASTRUCTURE,
    payload: data,
    callback
});

export const editInfrastructure = (id, data, callback) => ({
    type: types.EDIT_INFRASTRUCTURE,
    payload: { id, data },
    callback
});

export const deleteInfrastructure = (id, callback) => ({
    type: types.DELETE_INFRASTRUCTURE,
    payload: id,
    callback
});

export const infrastructureSubscription = (id, callback) => ({
    type: types.CREATE_INFRASTRUCTURE_SUBSCRIPTION,
    payload: id,
    callback
});

// road sections
export const loadRoadSectionTypes = () => ({
    type: types.LOAD_ROAD_SECTION_TYPES,
});

export const loadedRoadSectionTypes = (data) => ({
    type: types.LOADED_ROAD_SECTION_TYPES,
    payload: data
});

export const loadRoadSectionCategories = () => ({
    type: types.LOAD_ROAD_SECTION_CATEGORIES,
});

export const loadedRoadSectionCategories = (data) => ({
    type: types.LOADED_ROAD_SECTION_CATEGORIES,
    payload: data
});

export const loadRoadSectionsReport = (params) => ({
    type: types.LOAD_ROAD_SECTIONS_REPORT,
    payload: params
});

export const loadRoadSections = (params) => ({
    type: types.LOAD_ROAD_SECTIONS,
    payload: params
});

export const loadedRoadSections = (data) => ({
    type: types.LOADED_ROAD_SECTIONS,
    payload: data
});

export const loadingRoadSections = (bool) => ({
    type: types.LOADING_ROAD_SECTIONS,
    payload: bool
});

export const createRoadSection = (data, callback) => ({
    type: types.CREATE_ROAD_SECTION,
    payload: { data },
    callback,
});

export const editRoadSection = (id, data, callback) => ({
    type: types.EDIT_ROAD_SECTION,
    payload: { id, data },
    callback,
});

export const deleteRoadSection = (id, callback) => ({
    type: types.DELETE_ROAD_SECTION,
    payload: { id },
    callback,
});

// участки дорог
export const loadPolygonRoadSection = (polygon, params = {}) => ({
    type: types.LOAD_POLYGON_ROAD_SECTION,
    payload: {
        polygon,
        ...params,
    }
});

export const loadingPolygonRoadSection = (bool = false) => ({
    type: types.LOADING_POLYGON_LOADED_ROAD_SECTION,
    payload: bool
});

export const loadedPolygonRoadSection = (data) => ({
    type: types.LOADED_POLYGON_LOADED_ROAD_SECTION,
    payload: data
});

export const clearPolygonRoadSection = () => ({
    type: types.LOADED_POLYGON_LOADED_ROAD_SECTION,
    payload: [],
});

export const setDeleteFormRoadSection = (id = null) => ({
    type: types.SET_DELETE_FORM_ROAD_SECTION,
    payload: id
});

export const setEditFormRoadSection = (item = null) => ({
    type: types.SET_EDIT_FORM_ROAD_SECTION,
    payload: item
});

export const setCloneFormRoadSection = (item = null) => ({
    type: types.SET_CLONE_FORM_ROAD_SECTION,
    payload: item
});

export const setSavedRoadSection = (bool = false) => ({
    type: types.SET_SAVED_ROAD_SECTION,
    payload: bool
});

// активный
export const setActiveRoadSection = (item = {}) => ({
    type: types.SET_ACTIVE_ROAD_SECTION,
    payload: item,
});

export const clearActiveRoadSection = () => ({
    type: types.SET_ACTIVE_ROAD_SECTION,
    payload: {},
});

// фильтр
export const setFiltersRoadSection = (filter = {}) => ({
    type: types.SET_FILTERS_ROAD_SECTION,
    payload: filter,
});

export const clearFiltersRoadSection = () => ({
    type: types.SET_FILTERS_ROAD_SECTION,
    payload: {},
});

// список сайдбара
export const loadSidebarRoadSection = (page, limit, params = {}, isReplace = true) => ({
    type: types.LOAD_SIDEBAR_ROAD_SECTION,
    payload: {
        page,
        limit,
        ...params,
    },
    isReplace,
});

export const loadedSidebarRoadSection = (data, isReplace) => ({
    type: types.LOADED_SIDEBAR_ROAD_SECTION,
    payload: {
        data,
        isReplace,
    },
});

export const loadingSidebarRoadSection = (bool = false) => ({
    type: types.LOADING_SIDEBAR_ROAD_SECTION,
    payload: bool,
});

export const clearSidebarRoadSection = () => ({
    type: types.CLEAR_SIDEBAR_ROAD_SECTION,
});

export const loadStatusesRoadSection = () => ({
    type: types.LOAD_STATUSES_ROAD_SECTION,
});

export const loadedStatusesRoadSection = (data) => ({
    type: types.LOADED_STATUSES_ROAD_SECTION,
    payload: data,
});

export const setEditForm = (data = false) => ({
    type: types.SET_EDIT_FORM,
    payload: data
});

export const loadRoadSectionRelation = (data, callback) => ({
    type: types.LOAD_ROAD_SECTION_RELATION,
    payload: data,
    callback
});
export const loadingRoadSectionRelation = (bool = false) => ({
    type: types.LOADING_ROAD_SECTION_RELATION,
    payload: bool
});

export const loadRoadSectionAllRelation = (data, callback, savedRelations) => ({
    type: types.LOAD_ROAD_SECTION_ALL_RELATION,
    payload: data,
    callback,
    savedRelations
});

export const loadRoadSectionRelationTypes = () => ({
    type: types.LOAD_ROAD_SECTION_RELATION_TYPES,
});

export const loadedRoadSectionRelationTypes = (data) => ({
    type: types.LOADED_ROAD_SECTION_RELATION_TYPES,
    payload: data,
});

export const createRoadSectionRelation = (data, callback) => ({
    type: types.CREATE_ROAD_SECTION_RELATION,
    payload: data,
    callback,
});

export const createMultipleRoadSectionRelation = (road_section_id, relations, callback) => ({
    type: types.CREATE_MULTIPLE_ROAD_SECTION_RELATION,
    payload: {
        road_section_id,
        relations
    },
    callback,
});

export const deleteRoadSectionRelation = (id, road_section_id, callback) => ({
    type: types.DELETE_ROAD_SECTION_RELATION,
    payload: {
        id,
        road_section_id
    },
    callback,
});

export const loadRoadSectionByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_ROAD_SECTION_BY_RADIUS,
    payload: data,
    callback
});

export const loadedRoadSectionByRadius = (data) => ({
    type: types.LOADED_ROAD_SECTION_BY_RADIUS,
    payload: data
});

export const loadInfrastructureByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_INFRASTRUCTURE_BY_RADIUS,
    payload: data,
    callback
});

export const loadedInfrastructureByRadius = (data) => ({
    type: types.LOADED_INFRASTRUCTURE_BY_RADIUS,
    payload: data
});

export const loadOverlapPolygon = (polygon, filter = {}) => ({
    type: types.LOAD_OVERLAP_POLYGON,
    payload: {
        polygon,
        ...filter
    }
});
export const loadingOverlapPolygon = (bool = false) => ({
    type: types.LOADING_OVERLAP_POLYGON,
    payload: bool
});
export const loadedOverlapPolygon = (data) => ({
    type: types.LOADED_OVERLAP_POLYGON,
    payload: data
});
export const clearOverlapPolygon = () => ({
    type: types.LOADED_OVERLAP_POLYGON,
    payload: []
});

export const setFilterOverlap = (filter = {}) => ({
    type: types.SET_FILTER_OVERLAP,
    payload: filter
});

export const setActiveOverlap = (item = {}) => ({
    type: types.SET_ACTIVE_OVERLAP,
    payload: item,
});
export const clearActiveOverlap = () => ({
    type: types.SET_ACTIVE_OVERLAP,
    payload: {},
});

export const loadOverlapList = (params) => ({
    type: types.LOAD_OVERLAP_LIST,
    payload: params
});
export const loadedOverlapList = (data, isReplace) => ({
    type: types.LOADED_OVERLAP_LIST,
    payload: {
        data,
        isReplace
    }
});
export const loadingOverlapList = (bool) => ({
    type: types.LOADING_OVERLAP_LIST,
    payload: bool
});

export const clearOverlapList = () => ({
    type: types.CLEAR_OVERLAP_LIST,
});

// участки дорог
export const loadPolygonInfrastructure = (polygon, params = {}) => ({
    type: types.LOAD_POLYGON_INFRASTRUCTURE,
    payload: {
        polygon,
        ...params,
    }
});

export const loadingPolygonInfrastructure = (bool = false) => ({
    type: types.LOADING_POLYGON_INFRASTRUCTURE,
    payload: bool
});

export const loadedPolygonInfrastructure = (data) => ({
    type: types.LOADED_POLYGON_INFRASTRUCTURE,
    payload: data
});

export const clearPolygonInfrastructure = () => ({
    type: types.LOADED_POLYGON_INFRASTRUCTURE,
    payload: [],
});

export const loadInfrastructureSidebar = (params = {}) => ({
    type: types.LOAD_INFRASTRUCTURE_SIDEBAR,
    payload: params,
});

export const loadingInfrastructureSidebar = (bool = false) => ({
    type: types.LOADING_INFRASTRUCTURE_SIDEBAR,
    payload: bool
});

export const loadedInfrastructureSidebar = (data, isReplace) => ({
    type: types.LOADED_INFRASTRUCTURE_SIDEBAR,
    payload: {
        data,
        isReplace,
    }
});

export const clearInfrastructureSidebar = () => ({
    type: types.CLEAR_INFRASTRUCTURE_SIDEBAR,
    payload: [],
});


export const setFilterPolygonInfrastructure = (filter = {}) => ({
    type: types.SET_FILTER_POLYGON_INFRASTRUCTURE,
    payload: filter
});

export const setActivePolygonInfrastructure = (active = {}) => ({
    type: types.SET_ACTIVE_POLYGON_INFRASTRUCTURE,
    payload: active
});

export const savedInfrastructure = (bool = false) => ({
    type: types.SAVED_INFRASTRUCTURE,
    payload: bool
});

export const loadOverlapTypes = () => ({
    type: types.LOAD_OVERLAP_TYPES,
});
export const loadedOverlapTypes = (data) => ({
    type: types.LOADED_OVERLAP_TYPES,
    payload: data
});

export const loadOverlapSource = () => ({
    type: types.LOAD_OVERLAP_SOURCE,
});
export const loadedOverlapSource = (data) => ({
    type: types.LOADED_OVERLAP_SOURCE,
    payload: data
});

export const loadStreetList = (lat, lon, radius = 50) => ({
    type: types.LOAD_STREET_LIST,
    payload: {
        lat,
        lon,
        radius,
    }
});
export const loadedStreetList = (data) => ({
    type: types.LOADED_STREET_LIST,
    payload: data
});
export const loadingStreetList = (bool = false) => ({
    type: types.LOADING_STREET_LIST,
    payload: bool
});
export const clearStreetList = () => ({
    type: types.CLEAR_STREET_LIST,
});

export const loadCategoryTypes = () => ({
    type: types.LOAD_CATEGORY_TYPES,
});
export const loadedCategoryTypes = (data) => ({
    type: types.LOADED_CATEGORY_TYPES,
    payload: data
});

// Словари для городских видеокамер
export const loadVideoCamerasDictionaryInfrastructureTypes = () => ({
    type: types.LOAD_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES,
});

export const loadingVideoCamerasDictionaryInfrastructureTypes = (loading) => ({
    type: types.LOADING_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES,
    payload: loading
});

export const loadedVideoCamerasDictionaryInfrastructureTypes = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_DICTIONARY_INFRASTRUCTURE_TYPES,
    payload: data
});

export const loadVideoCamerasDictionaryCommutator = () => ({
    type: types.LOAD_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR,
});

export const loadingVideoCamerasDictionaryCommutator = (loading) => ({
    type: types.LOADING_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR,
    payload: loading
});

export const loadedVideoCamerasDictionaryCommutator = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_DICTIONARY_COMMUTATOR,
    payload: data
});

export const loadVideoCamerasDictionaryConnectionLine = () => ({
    type: types.LOAD_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE,
});

export const loadingVideoCamerasDictionaryConnectionLine = (loading) => ({
    type: types.LOADING_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE,
    payload: loading
});

export const loadedVideoCamerasDictionaryConnectionLine = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_DICTIONARY_CONNECTION_LINE,
    payload: data
});

export const loadVideoCamerasDictionaryConnection = () => ({
    type: types.LOAD_VIDEO_CAMERAS_DICTIONARY_CONNECTION,
});

export const loadingVideoCamerasDictionaryConnection = (loading) => ({
    type: types.LOADING_VIDEO_CAMERAS_DICTIONARY_CONNECTION,
    payload: loading
});

export const loadedVideoCamerasDictionaryConnection = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_DICTIONARY_CONNECTION,
    payload: data
});

export const loadVideoCamerasDictionaryElectricalOptionalSystem = () => ({
    type: types.LOAD_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM,
});

export const loadingVideoCamerasDictionaryElectricalOptionalSystem = (loading) => ({
    type: types.LOADING_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM,
    payload: loading
});

export const loadedVideoCamerasDictionaryElectricalOptionalSystem = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_DICTIONARY_ELECTRICAL_OPTIONAL_SYSTEM,
    payload: data
});

export const loadVideoCamerasDictionaryDocument = () => ({
    type: types.LOAD_VIDEO_CAMERAS_DICTIONARY_DOCUMENT,
});

export const loadingVideoCamerasDictionaryDocument = (loading) => ({
    type: types.LOADING_VIDEO_CAMERAS_DICTIONARY_DOCUMENT,
    payload: loading
});

export const loadedVideoCamerasDictionaryDocument = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_DICTIONARY_DOCUMENT,
    payload: data
});

export const loadVideoCamerasDictionaryPower = () => ({
    type: types.LOAD_VIDEO_CAMERAS_DICTIONARY_POWER,
});

export const loadingVideoCamerasDictionaryPower = (loading) => ({
    type: types.LOADING_VIDEO_CAMERAS_DICTIONARY_POWER,
    payload: loading
});

export const loadedVideoCamerasDictionaryPower = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_DICTIONARY_POWER,
    payload: data
});

export const loadVideoCamerasDictionaryReliance = () => ({
    type: types.LOAD_VIDEO_CAMERAS_DICTIONARY_RELIANCE,
});

export const loadingVideoCamerasDictionaryReliance = (loading) => ({
    type: types.LOADING_VIDEO_CAMERAS_DICTIONARY_RELIANCE,
    payload: loading
});

export const loadedVideoCamerasDictionaryReliance = (data) => ({
    type: types.LOADED_VIDEO_CAMERAS_DICTIONARY_RELIANCE,
    payload: data
});

export const loadCommutators = (page, limit, params) => ({
    type: types.LOAD_COMMUTATORS,
    payload: { page, limit, ...params }
});

export const loadingCommutators = (bool) => ({
    type: types.LOADING_COMMUTATORS,
    payload: bool
});

export const loadedCommutators = (data) => ({
    type: types.LOADED_COMMUTATORS,
    payload: data
});

export const createCommutator = (data, callback) => ({
    type: types.CREATE_COMMUTATOR,
    payload: data,
    callback
});

export const editCommutator = (id, data, callback) => ({
    type: types.EDIT_COMMUTATOR,
    payload: { id, data },
    callback
});

export const deleteCommutator = (id, callback) => ({
    type: types.DELETE_COMMUTATOR,
    payload: id,
    callback
});

export const loadCommutatorTypes = () => ({
    type: types.LOAD_COMMUTATOR_TYPES,
});

export const loadedCommutatorTypes = (data) => ({
    type: types.LOADED_COMMUTATOR_TYPES,
    payload: data
});

export const loadingButton = (bool) => ({
    type: types.LOADING_BUTTON,
    payload: bool
});

export const loadConnectionLines = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_CONNECTION_LINES,
    payload: { page, limit, ...params }
});

export const loadingConnectionLines = (bool) => ({
    type: types.LOADING_CONNECTION_LINES,
    payload: bool
});

export const loadedConnectionLines = (data) => ({
    type: types.LOADED_CONNECTION_LINES,
    payload: data
});

export const createConnectionLine = (data, callback) => ({
    type: types.CREATE_CONNECTION_LINE,
    payload: data,
    callback
});

export const editConnectionLine = (id, data, callback) => ({
    type: types.EDIT_CONNECTION_LINE,
    payload: { id, data },
    callback
});

export const deleteConnectionLine = (id, callback) => ({
    type: types.DELETE_CONNECTION_LINE,
    payload: id,
    callback
});
export const loadCameras = (page = 1, limit = 25, params) => ({
    type: types.LOAD_CAMERAS,
    payload: { page, limit, params }
});

export const loadingCameras = (bool) => ({
    type: types.LOADING_CAMERAS,
    payload: bool
});

export const loadedCameras = (data) => ({
    type: types.LOADED_CAMERAS,
    payload: data
});

export const createCamera = (data, callback) => ({
    type: types.CREATE_CAMERA,
    payload: data,
    callback
});

export const editCamera = (id, data, callback) => ({
    type: types.EDIT_CAMERA,
    payload: { id, data },
    callback
});

export const deleteCamera = (id, callback) => ({
    type: types.DELETE_CAMERA,
    payload: id,
    callback
});

export const loadCameraTypes = () => ({
    type: types.LOAD_CAMERA_TYPES,
});

export const loadingCameraTypes = (bool) => ({
    type: types.LOADING_CAMERA_TYPES,
    payload: bool
});

export const loadedCameraTypes = (data) => ({
    type: types.LOADED_CAMERA_TYPES,
    payload: data
});

// Модель оборудования Камера
export const loadCameraModel = (page = 1, limit = 25, params, returnResponse) => ({
    type: types.LOAD_CAMERA_MODEL,
    payload: { page, limit, params },
    returnResponse
});

export const loadingCameraModel = (bool) => ({
    type: types.LOADING_CAMERA_MODEL,
    payload: bool
});

export const loadedCameraModel = (data) => ({
    type: types.LOADED_CAMERA_MODEL,
    payload: data
});

export const createCameraModel = (data, callback) => ({
    type: types.CREATE_CAMERA_MODEL,
    payload: data,
    callback
});

export const editCameraModel = (id, data, callback) => ({
    type: types.EDIT_CAMERA_MODEL,
    payload: { id, data },
    callback
});

export const deleteCameraModel = (id, callback) => ({
    type: types.DELETE_CAMERA_MODEL,
    payload: id,
    callback
});

// Вендор оборудования Камера
export const loadCameraVendor = (page = 1, limit = 25, params) => ({
    type: types.LOAD_CAMERA_VENDOR,
    payload: { page, limit, params }
});

export const loadingCameraVendor = (bool) => ({
    type: types.LOADING_CAMERA_VENDOR,
    payload: bool
});

export const loadedCameraVendor = (data) => ({
    type: types.LOADED_CAMERA_VENDOR,
    payload: data
});

export const createCameraVendor = (data, callback) => ({
    type: types.CREATE_CAMERA_VENDOR,
    payload: data,
    callback
});

export const editCameraVendor = (id, data, callback) => ({
    type: types.EDIT_CAMERA_VENDOR,
    payload: { id, data },
    callback
});

export const deleteCameraVendor = (id, callback) => ({
    type: types.DELETE_CAMERA_VENDOR,
    payload: id,
    callback
});

export const loadConnectionPoints = (page, limit, params) => ({
    type: types.LOAD_CONNECTION_POINTS,
    payload: { page, limit, ...params }
});

export const loadingConnectionPoints = (loading) => ({
    type: types.LOADING_CONNECTION_POINTS,
    payload: loading
});

export const loadedConnectionPoints = (data) => ({
    type: types.LOADED_CONNECTION_POINTS,
    payload: data
});

export const createConnectionPoints = (data, callback) => ({
    type: types.CREATE_CONNECTION_POINTS,
    payload: data,
    callback
});
export const loadPillars = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_PILLARS,
    payload: { page, limit, ...params }
});

export const loadingPillars = (bool) => ({
    type: types.LOADING_PILLARS,
    payload: bool
});

export const loadedPillars = (data) => ({
    type: types.LOADED_PILLARS,
    payload: data
});

export const createPillar = (data, callback) => ({
    type: types.CREATE_PILLAR,
    payload: data,
    callback
});
export const loadElectricalOpticalSystems = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_ELECTRICAL_OPTICAL_SYSTEMS,
    payload: { page, limit, ...params }
});

export const loadingElectricalOpticalSystems = (bool) => ({
    type: types.LOADING_ELECTRICAL_OPTICAL_SYSTEMS,
    payload: bool
});

export const loadedElectricalOpticalSystems = (data) => ({
    type: types.LOADED_ELECTRICAL_OPTICAL_SYSTEMS,
    payload: data
});

export const createElectricalOpticalSystems = (data, callback) => ({
    type: types.CREATE_ELECTRICAL_OPTICAL_SYSTEMS,
    payload: data,
    callback
});
export const loadPakVendors = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_PAK_VENDORS,
    payload: { page, limit, ...params }
});

export const loadingPakVendors = (bool) => ({
    type: types.LOADING_PAK_VENDORS,
    payload: bool
});

export const loadedPakVendors = (data) => ({
    type: types.LOADED_PAK_VENDORS,
    payload: data
});

export const createPakVendor = (data, callback) => ({
    type: types.CREATE_PAK_VENDOR,
    payload: data,
    callback
});

export const editConnectionPoints = (id, data, callback) => ({
    type: types.EDIT_CONNECTION_POINTS,
    payload: { id, data },
    callback
});
export const editPillar = (id, data, callback) => ({
    type: types.EDIT_PILLAR,
    payload: { id, data },
    callback
});
export const editElectricalOpticalSystems = (id, data, callback) => ({
    type: types.EDIT_ELECTRICAL_OPTICAL_SYSTEMS,
    payload: { id, data },
    callback
});
export const editPakVendor = (id, data, callback) => ({
    type: types.EDIT_PAK_VENDOR,
    payload: { id, data },
    callback
});

export const deleteConnectionPoints = (id, callback) => ({
    type: types.DELETE_CONNECTION_POINTS,
    payload: id,
    callback
});
export const deletePillar = (id, callback) => ({
    type: types.DELETE_PILLAR,
    payload: id,
    callback
});
export const deleteElectricalOpticalSystems = (id, callback) => ({
    type: types.DELETE_ELECTRICAL_OPTICAL_SYSTEMS,
    payload: id,
    callback
});

export const loadConnectionPointTypes = () => ({
    type: types.LOAD_CONNECTION_POINT_TYPES,
});

export const loadedConnectionPointTypes = (data) => ({
    type: types.LOADED_CONNECTION_POINT_TYPES,
    payload: data
});

export const deletePakVendor = (id, callback) => ({
    type: types.DELETE_PAK_VENDOR,
    payload: id,
    callback
});

export const loadDVR = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_DVR,
    payload: { page, limit, ...params }
});

export const loadingDVR = (bool) => ({
    type: types.LOADING_DVR,
    payload: bool
});

export const loadedDVR = (data) => ({
    type: types.LOADED_DVR,
    payload: data
});

export const createDVR = (data, callback) => ({
    type: types.CREATE_DVR,
    payload: data,
    callback
});

export const editDVR = (id, data, callback) => ({
    type: types.EDIT_DVR,
    payload: { id, data },
    callback
});

export const deleteDVR = (id, callback) => ({
    type: types.DELETE_DVR,
    payload: id,
    callback
});

export const loadPakModels = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_PAK_MODELS,
    payload: { page, limit, ...params }
});

export const loadingPakModels = (bool) => ({
    type: types.LOADING_PAK_MODELS,
    payload: bool
});

export const loadedPakModels = (data) => ({
    type: types.LOADED_PAK_MODELS,
    payload: data
});

export const createPakModel = (data, callback) => ({
    type: types.CREATE_PAK_MODEL,
    payload: data,
    callback
});

export const editPakModel = (id, data, callback) => ({
    type: types.EDIT_PAK_MODEL,
    payload: { id, data },
    callback
});

export const deletePakModel = (id, callback) => ({
    type: types.DELETE_PAK_MODEL,
    payload: id,
    callback
});

export const loadPAKList = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_PAK_LIST,
    payload: { page, limit, ...params }
});

export const loadingPAKList = (bool) => ({
    type: types.LOADING_PAK_LIST,
    payload: bool
});

export const loadedPAKList = (data) => ({
    type: types.LOADED_PAK_LIST,
    payload: data
});

export const createPAK = (data, callback) => ({
    type: types.CREATE_PAK,
    payload: data,
    callback
});

export const editPAK = (id, data, callback) => ({
    type: types.EDIT_PAK,
    payload: { id, data },
    callback
});

export const deletePAK = (id, callback) => ({
    type: types.DELETE_PAK,
    payload: id,
    callback
});

export const loadInfrastructureByPolygon = (polygon, params = {}) => ({
    type: types.LOAD_INFRASTRUCTURE_BY_POLYGON,
    payload: {
        polygon,
        ...params,
    }
});

export const loadingInfrastructureByPolygon = (bool = false) => ({
    type: types.LOADING_INFRASTRUCTURE_BY_POLYGON,
    payload: bool
});

export const loadedInfrastructureByPolygon = (data) => ({
    type: types.LOADED_INFRASTRUCTURE_BY_POLYGON,
    payload: data
});

export const clearInfrastructureByPolygon = () => ({
    type: types.LOADED_INFRASTRUCTURE_BY_POLYGON,
    payload: [],
});


export const loadInfrastructureList = (params) => ({
    type: types.LOAD_INFRASTRUCTURE_LIST,
    payload: params
});

export const loadedInfrastructureList = (data) => ({
    type: types.LOADED_INFRASTRUCTURE_LIST,
    payload: data
});

export const loadingInfrastructureList = (bool) => ({
    type: types.LOADING_INFRASTRUCTURE_LIST,
    payload: bool
});

export const setActiveInfrastructureLayer = (active = {}) => ({
    type: types.SET_ACTIVE_INFRASTRUCTURE_LAYER,
    payload: active
});

export const setRoadSectionPolygonGeoItem = (data) => ({
    type: types.SET_ROAD_SECTION_POLYGON_GEO_ITEM,
    payload: data
});
