import { useState } from 'react';
import { List } from '@mui/material';

import Item from './Item';

const RenderComputerItem = ({
    types,
    item,
    currentComplex,
    readOnly = false,
    onDeleted,
    onEdited,
    test_id_prefix_passport = '',
    project
}) => {
    const [isOpenChildren, setIsOpenChildren] = useState(true);
    return (
        <>
            <Item
                key={`${item.id}${item.entity_id}`}
                item={item}
                types={types}
                readOnly={readOnly}
                currentComplex={currentComplex}
                onEdited={onEdited}
                onDeleted={onDeleted}
                onOpenChildren={() => setIsOpenChildren(!isOpenChildren)}
                isOpenChildren={isOpenChildren}
                test_id_prefix_passport={test_id_prefix_passport}
                project={project}
            />
            {(isOpenChildren && item.entities?.length > 0)
                && <List className="info">
                    {item.entities.map((el) => (
                        <Item
                            key={`${el.id}${el.entity_id}`}
                            item={el}
                            types={types}
                            readOnly={readOnly}
                            currentComplex={currentComplex}
                            onEdited={onEdited}
                            onDeleted={onDeleted}
                            test_id_prefix_passport={test_id_prefix_passport}
                            project={project}
                        />
                    )
                    )}
                </List>
            }
        </>
    );
};

export default RenderComputerItem;
