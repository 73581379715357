import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadMapLayerList, setMapLayer } from 'redux/Auth/actions';
import { authSelectors } from 'redux/Auth';
// import useStoreFromSelector from '../../../../helpers/hooks/useStoreFromSelector';

// хук слои карты тайлы
const useMapLayers = () => {
    const dispatch = useDispatch();
    // старая подгрузка тайлов
    // const mapLayers = useStoreFromSelector(loadMapLayerList, authSelectors.mapLayers);

    // код проекта
    const projectCode = useSelector(authSelectors.projectCode);
    // тайлы
    const mapLayers = useSelector(authSelectors.mapLayers);

    // запрос тайлов карты при смене проекта
    useEffect(() => {
        dispatch(loadMapLayerList());
    }, [projectCode]);

    return {
        // список слоев - массив
        mapLayers,
        // установить слой
        setLayer: (layerId) => dispatch(setMapLayer(layerId)),
    };
};

export default useMapLayers;
