import { useState } from 'react';

import { loadMethods } from 'redux/Admin/actions';
import titles from 'helpers/constants/titles';
import UniversalSelect from 'components/common/UniversalSelect/index-new';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { useStoreProp } from 'helpers/hooks';

import type { MethodTypes, Item } from './types';

const SelectMethods = ({
    multiple = false,
    selected = multiple ? [] : {},
    onChange = () => {},
    required = false,
    disabled = false,
    label = titles.METHODS,
    error = false,
    helperText = '',
    sortedList = null,
    limitTags = 1,
    test_id_prefix = ''
}: MethodTypes<Item>) => {
    const methods = useStoreProp(loadMethods, 'admin', 'methods');
    const methodList = methods?.data?.map((item: string) => ({ key: item }));
    const [showList, setShowList] = useState(false);

    const handleClose = () => setShowList(false);

    const handleAccept = (data: Item | Item[]) => {
        onChange(data);
        setShowList(false);
    };

    const onReset = () => {
        const initialState = multiple ? [] : {};
        onChange(initialState);
    };

    return (
        <>
            <CommonAutocomplete<Item>
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                required={required}
                inputName="type"
                disabled={disabled}
                onReset={onReset}
                renderLabel={(option) => option?.key || ''}
                limitTags={limitTags}
                label={label}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
                test_id_prefix={test_id_prefix}
            />
            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    list={sortedList || methodList}
                    storeName="admin"
                    storeNameProps="methods"
                    keyProp="key"
                    isSelected
                    selected={selected}
                    renderProps={(el) => <div>{el.key}</div>}
                    isOpen={showList}
                    onClose={handleClose}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.METHODS}
                    size="40%"
                />
            )}
        </>
    );
};

export default SelectMethods;
