import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    TextField,
    Tooltip,
} from '@mui/material';

import {
    createTransportIncident,
    editTransportIncident,
    loadIncidentOperations,
    loadThreatLevels,
    loadTransportIncidentStatuses,
    loadTransportIncidentTypes,
    setTIModalData,
} from 'redux/SituationalPlans/actions';
import { loadIntersectionRoutes } from 'redux/TransportPassenger/actions';
import { deleteDtp, loadDtpById, loadDtpTypes, loadedDtpById } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { setShowReturnTo } from 'redux/Menu/actions';
import { menuSelectors } from 'redux/Menu';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithTimeZone, isDateValid } from 'helpers/date.config';
import useStoreProp from 'helpers/hooks/useStoreProp';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { isFloat } from 'helpers/validator';
import useLoadByLatLon from 'helpers/hooks/Location/useLoadByLatLon';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import Attachments from 'components/common/Upload/Attachments';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import SelectTransportIncidentsTypes from 'components/common/Autocomplete/DorisReact/TransportIncidentsTypes';
import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectUsers from 'components/common/Autocomplete/Users';
import SelectScenarios from 'components/common/Autocomplete/DorisReact/Scenarios';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressFields from 'components/common/Location/AddressFields';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';
import PopUpDtp from 'components/MapComponents/Layers/Incidents/PopUp';
import Loading from 'components/common/Loading';
import InfoBlock from 'components/common/InfoBlock';
import { config } from 'config';

import DtpModal from './DtpModal';
import TrafficLightList from './TrafficLightList';
import BoardForm from './BoardForm';
import Routes from './Routes';

const ModalForm = ({
    isOpen,
    onClose,
    isNew = false,
    item = {},
    reloadList = () => {},
    // mkdtpItem = {},
    isMkDtp = false,
    loading = false
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const history = useHistory();

    // todo проверить объект
    const routesIntersection = useSelector(transportPassengerSelectors.routesIntersection);
    const statuses = useStoreProp(loadTransportIncidentStatuses, 'SituationalPlans', 'transportIncidentStatuses');
    const incidentOperations = useSelector(SituationalPlansSelectors.incidentOperations);
    const { data: typesData } = useSelector(SituationalPlansSelectors.transportIncidentTypes);
    const showReturnTo = useSelector(menuSelectors.showReturnTo || {});
    const threatLevels = useStoreProp(loadThreatLevels, 'SituationalPlans', 'threatLevels');
    const dtpById = useSelector(incidentsSelectors.dtpById);
    const dtpTypes = useSelector(incidentsSelectors.dtpTypesData);

    const [dependence, setDependence] = useState(true);
    const [isFirstLoadedAddress, setIsFirstLoadedAddress] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDtpModal, setOpenDtpModal] = useState(false);
    const [newDtpId, setNewDtpId] = useState(null);

    const dependenceHandler = (event) => {
        setDependence(event.target.checked);
    };

    useEffect(() => {
        if (!incidentOperations[item?.id] && !isNew && item?.id) {
            dispatch(loadIncidentOperations(item?.id));
        }
    }, [dispatch, incidentOperations, item?.id, isNew]);

    // проверяем выполнена ли >=1 операция в ТИ
    const disableScenario = (!isNew && Object.keys(incidentOperations).length > 0)
        && incidentOperations[item.id]?.filter(el => el.is_done && el)?.length > 0;

    const [type, setType] = useState(isNew ? {} : { id: item?.type_id, name: item?.type_text });

    const getFirstCoordRec = (value) => {
        if (!value) {
            return {
                lat: '',
                lon: '',
            };
        }

        const lat = value?.[1] || '';
        const lon = value?.[0] || '';

        if (value?.geometry) {
            return getFirstCoordRec(value?.geometry);
        }
        if (value?.coordinates) {
            return getFirstCoordRec(value?.coordinates);
        }
        if (isFloat(lat) && isFloat(lon)) {
        // if (parseFloat(lat) === lat && parseFloat(lon) === lon) {
            return {
                lat,
                lon,
            };
        }
        return getFirstCoordRec(value[0]);
    };

    const transportService = !!config.get('urls').apiTransportPassengerUrl;

    const initialState = {
        ...item,
        name: item?.name || '',
        status_id: isNew ? 1 : item?.status_id,
        type_id: item?.type_id || '',
        registered_at: item?.registered_at || new Date(),
        description: item?.description || '',
        threat_level_id: item?.threat_level_id || '',
        // address_text: mkdtpItem?.address_text || item?.address_text || '',
        // address: mkdtpItem?.address || item?.address || {},
        // lat: mkdtpItem?.lat ?? item?.lat ?? getFirstCoordRec(item?.geometry).lat ?? 0,
        // lon: mkdtpItem?.lon ?? item?.lon ?? getFirstCoordRec(item?.geometry).lon ?? 0,
        address_text: item?.address_text || '',
        address: item?.address || {},
        lat: item?.lat ?? getFirstCoordRec(item?.geometry).lat ?? 0,
        lon: item?.lon ?? getFirstCoordRec(item?.geometry).lon ?? 0,
        // lat: item?.geometry ? getFirstCoordRec(item?.geometry).lat : 0,
        // lon: item?.geometry ? getFirstCoordRec(item?.geometry).lon : 0,
        geometry: item?.geometry || {},
        route_list: item?.route_list || [],
        // event: item.event || {},
        // event_id: item?.event_id || '',
        organization: item?.organization || {},
        organization_id: item?.organization_id || null,
        user: item?.user || {},
        user_id: item?.user_id || null,
        response_scenario: item?.response_scenario || {},
        response_scenario_id: item?.response_scenario_id || null,
        attachments: item?.attachments || [],
        date_plan: item?.date_plan || null,
        date_fact : item?.date_fact  || null,
        decision: item?.decision || '',
        vehicles: item?.vehicles || [],
        type_creation: item?.type_creation || 1,
        source: item?.source || '',
        // mkdtp_id: mkdtpItem?.id || item.mkdtp_id
        mkdtp_id: item?.mkdtp_id,
        dtp_id: item?.dtp_id || null,
        traffic_control_mode_type: item?.traffic_control_mode_type || 1,
        light_ids: item?.light_ids || [],
        dit_ids: item?.dit_ids || [],
        dit_template_id: item?.dit_template_id || null,
        dit_duration: item?.dit_duration || null,
    };

    const [data, setData] = useState(initialState);

    const loadByLatLon = useLoadByLatLon((props) => {
        setData(old => ({
            ...old,
            ...props,
        }));
    });

    useEffect(() => {
        if (
            data?.lat
            && data?.lon
            && isFirstLoadedAddress === false
        ) {
            if (
                !data?.address
                || Object.keys(data?.address || {}).length === 0
            ) {
                loadByLatLon.load(data?.lat, data?.lon);
                setIsFirstLoadedAddress(true);
            }

            if (
                !data?.geometry
                || Object.keys(data?.geometry || {}).length === 0
            ) {
                setData(old => ({
                    ...old,
                    geometry: createPointGJ(data?.lat, data?.lon),
                }));
            }
        }
    }, [data, isFirstLoadedAddress]);

    const handleChangeIncidentTypes = (e) => {
        setType(e);
        setData({ ...data, type_id: e.id, type_text: e.name, vehicles: [] });
    };

    // const handleChangeEvent = (e) => {
    //     setData({
    //         ...data,
    //         event: e,
    //         event_id: e?.id || null, // для удаления поля надо передать null иначе оно не удалится из базы
    //     });
    // };

    const handleChangeOrganization = (e) => {
        setData({
            ...data,
            organization_id: e?.id || null, // для удаления поля надо передать null иначе оно не удалится из базы
            organization: e,
            user: {},
            user_id: null,
            status_id: 2,
        });
    };

    const handleChangeUser = (e) => {
        setData({
            ...data,
            user_id: e?.id || null, // для удаления поля надо передать null иначе оно не удалится из базы
            user: e,
            status_id: 2,
        });

    };

    const handleChangeResponseScenario = (e) => {
        setData({
            ...data,
            response_scenario: e,
            response_scenario_id:  e?.id || null, // для удаления поля надо передать null иначе оно не удалится из базы
        });
    };

    const handleChangeDocuments = (files) => {
        setData({
            ...data,
            attachments: files
        });
    };

    const onAcceptModal = () => {

        const correctTransportIncidentDate = fullDateTimeWithTimeZone(data.registered_at);
        const correctSolutionOfThePlanDate = fullDateTimeWithTimeZone(data.date_plan);
        const correctSolutionOfTheFactDate = fullDateTimeWithTimeZone(data.date_fact);

        const TransformationDataForSend = removeEmptyFields(data);

        const resultData = {
            ...TransformationDataForSend,
            registered_at: correctTransportIncidentDate,
            route_list: routesIntersection.data,
            date_plan: data.date_plan ? correctSolutionOfThePlanDate : null,
            date_fact: data.date_fact ? correctSolutionOfTheFactDate : null,
            dit_ids: data.dit_ids,
            light_ids: data.light_ids
        };

        const callback = (tiData) => {
            reloadList(tiData);
            onClose();
        };

        isNew
            ? dispatch(createTransportIncident(resultData, callback))
            : dispatch(editTransportIncident(item?.id, resultData, callback));
    };

    const handleClose = () => {
        if(newDtpId){
            setData((prevData) => ({
                ...prevData,
                dtp_id: null,
            }));
            dispatch(deleteDtp(newDtpId));
        }
        onClose();
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    const [isErrorDate, setIsErrorDate] = useState(false);
    const checkInvalidDate = useCallback((date) => {
        return date && !isDateValid(date);
    }, []);

    useEffect(() => {
        if (checkInvalidDate(data.registered_at) || checkInvalidDate(data.date_fact) || checkInvalidDate(data.date_plan)) {
            setIsErrorDate(true);
        } else {
            setIsErrorDate(false);
        }
    }, [checkInvalidDate, data.registered_at, data.date_fact, data.date_plan]);

    const [wasChangeGeometry, setWasChangeGeometry] = useState(false);

    useEffect(() => {
        if (Object.keys(data?.geometry || {}).length > 0 && transportService) {
            const geometryObj = data?.geometry?.geometry?.type === 'Point'
                ? { geometry: { ...data.geometry, properties: { radius: 20 } } }
                : { geometry: data.geometry };
            dispatch(loadIntersectionRoutes(geometryObj));
            setWasChangeGeometry(true);
        }
    },[dispatch, data.geometry, transportService]);

    useEffect(() => {
        if (wasChangeGeometry && routesIntersection.length > 0) {
            setData((prevData) => ({ ...prevData, route_list: routesIntersection }));
            setWasChangeGeometry(false);
        }
    },[routesIntersection, wasChangeGeometry]);

    const currentType = useMemo(() => (
        typesData?.find(type => type.name.toUpperCase() === 'МКДТП')
    ), [typesData]);

    useEffect(() => {
        // if(mkdtpItem?.id) {
        if (isMkDtp) {
            if (currentType) {
                setType(currentType);
                setData(prev => ({
                    ...prev,
                    type_id: currentType.id,
                    type_text: currentType.name,
                    vehicles: []
                }));
            } else {
                dispatch(loadTransportIncidentTypes(1, 25, { name: 'МКДТП' }));
            }
        }
    },[currentType, dispatch, isMkDtp /*mkdtpItem?.id*/]);

    const onChangeGeoJsonCoordinates = (params) => {
        setData((prevData) => ({
            ...prevData,
            ...params,
        }));
        validation.deleteKeys(Object.keys(params || {}));
    };

    const goToMKDTP = () => {
        dispatch(
            setShowReturnTo(
                history?.location?.pathname || '/map',
                'Вернуться к событию',
                history?.location?.search || ''
            )
        );
        dispatch(setTIModalData(data));
        history?.push({
            pathname: '/dictionaries/dtp/concentration-area',
            search: `id=${data?.mkdtp_id}`
        });
    };

    useEffect(() => {
        if(data?.dtp_id){
            dispatch(loadDtpById(data.dtp_id));
        }
        if (dtpTypes.length === 0) {
            dispatch(loadDtpTypes());
        }
        return () => {dispatch(loadedDtpById({}));};
    }, [data.dtp_id, dispatch, dtpTypes]);

    const isDisabled = !data?.name
        || !data?.status_id
        || !data?.type_id
        || isErrorDate
        || !data?.registered_at
        || !data?.description
        || JSON.stringify(data?.geometry) === '{}'
        || !data?.address
        || !data?.threat_level_id
        // || !data.date_plan
        || (Number(data.status_id) === 3 && !data.decision)
        || (Number(data.status_id) === 4 && !data.date_fact);

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? titles.CREATE : titles.EDIT}
            onClose={onClose}
            fullWidth
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: handleClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            {loading
                ? <div><Loading circular size="small" /></div>
                : <form className="modal__form">
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <div className="block">
                                <TextField
                                    label={titles.NAME}
                                    size="small"
                                    value={data.name}
                                    variant="outlined"
                                    name="name"
                                    type="text"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    required={true}
                                    error={!data.name || !!validation.isKey('name')}
                                    helperText={validation.get('name')}
                                    disabled={Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                                />
                            </div>

                            <div className="block">
                                <FormControl variant="outlined" size="small">
                                    <InputLabel>{`${titles.STATUS}`}</InputLabel>
                                    <Select
                                        label={titles.STATUS}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        name="status_id"
                                        value={data.status_id}
                                        disabled={isNew}
                                        error={!data.status_id || !!validation.isKey('status_id')}
                                    >
                                        {statuses?.length > 0
                                            ? statuses?.map(el => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)
                                            : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>}
                                    </Select>
                                    {validation.isKey('status_id') && (
                                        <FormHelperText error>{validation.get('status_id')}</FormHelperText>)}
                                </FormControl>
                            </div>

                            {Number(data.status_id) === 3 && (
                                <div className="block">
                                    <TextField
                                        label="Решение"
                                        size="small"
                                        value={data.decision}
                                        variant="outlined"
                                        name="decision"
                                        type="text"
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        required={true}
                                        error={!data.decision || !!validation.isKey('decision')}
                                        helperText={validation.get('decision')}
                                    />
                                </div>
                            )}

                            <div className="block">
                                <SingleKeyboardDateTimePicker
                                    onChange={date => onChange('registered_at', date)}
                                    value={data.registered_at}
                                    label={titles.DATE_AND_TIME_OF_INCIDENT}
                                    pickerFormat="dd.MM.yyyy HH:mm"
                                    required
                                    isDisabled={Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                                    error={!data.registered_at
                                        || checkInvalidDate(data.registered_at)
                                        || validation.isKey('registered_at')}
                                    helperText={validation.get('registered_at')}
                                />
                            </div>

                            <div className="block">
                                <SingleKeyboardDateTimePicker
                                    onChange={date => onChange('date_plan', date)}
                                    value={data.date_plan}
                                    label="Запланированная дата/время решения инцидента"
                                    pickerFormat="dd.MM.yyyy HH:mm"
                                    // required={true}
                                    disableFuture={false}
                                    isDisabled={Number(data.status_id) === 3}
                                    error={checkInvalidDate(data.date_plan)
                                        || validation.isKey('date_plan')}
                                    helperText={validation.get('date_plan')}
                                />
                            </div>

                            {Number(data.status_id) === 4 && (
                                <div className="block">
                                    <SingleKeyboardDateTimePicker
                                        onChange={date => onChange('date_fact', date)}
                                        value={data.date_fact}
                                        label="Фактическая дата/время решения инцидента"
                                        pickerFormat="dd.MM.yyyy HH:mm"
                                        required={true}
                                        isDisabled={Number(data.status_id) === 3}
                                        error={!data.date_fact
                                            || checkInvalidDate(data.date_fact)
                                            || validation.isKey('date_fact')}
                                        helperText={validation.get('date_fact')}
                                    />
                                </div>
                            )}

                            <div className="block">
                                <SelectTransportIncidentsTypes
                                    selected={type}
                                    error={!data?.type_id || !!validation.isKey('type_id')}
                                    helperText={validation.get('type_id')}
                                    onChange={handleChangeIncidentTypes}
                                    required
                                    // disabled={Number(data?.type_creation) === 2 || Number(data.status_id) === 3 || mkdtpItem?.id}
                                    disabled={Number(data?.type_creation) === 2 || Number(data.status_id) === 3 || isMkDtp}
                                />
                            </div>

                            {(data?.type_text === 'ДТП со спецтехникой'
                                || data?.type_text === 'ДТП с дорожной техникой')
                                && <div className="block">
                                    <SelectVehicles
                                        selected={data.vehicles}
                                        multiple
                                        onChange={(value) =>
                                            onChange('vehicles', value)
                                        }
                                        disabled={Number(data.status_id) === 3}
                                        storeName={
                                            data?.type_text === 'ДТП со спецтехникой'
                                                ? 'transportSpecial'
                                                : 'transportRoad'
                                        }
                                        storeNameProps="vehicles"
                                    />
                                </div>
                            }

                            <div className="block">
                                <TextField
                                    label={titles.DESCRIPTION}
                                    size="small"
                                    value={data.description}
                                    variant="outlined"
                                    name="description"
                                    type="text"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    error={!data.description || !!validation.isKey('description')}
                                    helperText={validation.get('description')}
                                    required={true}
                                    disabled={Number(data.status_id) === 3}
                                />
                            </div>

                            <div className="block">
                                <FormControl variant="outlined" size="small">
                                    <InputLabel style={ !data.threat_level_id ? { color: '#ff0000' } : { color: '#000000', opacity: '0.49' } }>{`${titles.THREAT_LEVEL}*`}</InputLabel>
                                    <Select
                                        label={titles.THREAT_LEVEL}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        name="threat_level_id"
                                        value={data?.threat_level_id}
                                        error={!data?.threat_level_id || !!validation.isKey('threat_level_id')}
                                        disabled={Number(data.status_id) === 3}
                                    >
                                        {threatLevels?.data?.length > 0
                                            ? threatLevels?.data?.map(el => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)
                                            : <MenuItem value="">{messages.NOTHING_FOUND}</MenuItem>}
                                    </Select>
                                    {validation.isKey('threat_level_id')
                                        && <FormHelperText error>{validation.get('threat_level_id')}</FormHelperText>}
                                </FormControl>
                            </div>

                            <div className="block">
                                <FormControl variant="outlined" size="small" error={!data?.traffic_control_mode_type} required>
                                    <InputLabel>Режим управления движением</InputLabel>
                                    <Select
                                        label={'Режим управления движением'}
                                        onChange={(e) => onChange(e.target.name, e.target.value)}
                                        name="traffic_control_mode_type"
                                        value={data?.traffic_control_mode_type}
                                        error={!data?.traffic_control_mode_type || !!validation.isKey('traffic_control_mode_type')}
                                    >
                                        {['Штатный', 'Нештатный'].map((el, i)=> <MenuItem key={i+1} value={i+1}>{el}</MenuItem>)}
                                    </Select>
                                    {validation.isKey('traffic_control_mode_type')
                                        && <FormHelperText error>{validation.get('traffic_control_mode_type')}</FormHelperText>}
                                </FormControl>
                            </div>

                            {data.dtp_id
                                ? <>
                                    <div className="block">
                                        <TextField
                                            label={'Связанное ДТП'}
                                            value={data?.dtp_id}
                                            size="small"
                                            variant="outlined"
                                            disabled
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        disabled={!dtpById?.id}
                                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                                        size="small"
                                                    >
                                                        <i className="far fa-info-circle"/>
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </div>
                                    {dtpById?.id && (
                                        <Popover
                                            open={!!anchorEl}
                                            anchorEl={anchorEl}
                                            onClose={() => setAnchorEl(null)}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <PopUpDtp dtpData={dtpById} readOnly/>
                                        </Popover>
                                    )}
                                </>
                                : <>
                                    <FormButtons
                                        noPadding
                                        positionLeft
                                        buttons={[
                                            {
                                                ...buttonsTypes.add,
                                                name: 'Добавить ДТП',
                                                onClick: () => setOpenDtpModal(true),
                                                disabled: !data?.lat && !data?.lon
                                            }
                                        ]}
                                    />

                                    {openDtpModal && (
                                        <DtpModal
                                            isOpen={openDtpModal}
                                            onClose={(new_id) => {
                                                setData((prevData) => ({
                                                    ...prevData,
                                                    dtp_id: new_id || null,
                                                }));
                                                setNewDtpId(new_id);
                                                setOpenDtpModal(false);
                                            }}
                                            tiData={data}
                                        />
                                    )}
                                </>
                            }

                            {/* <div className="block">
                                <SelectEvents
                                    onChange={handleChangeEvent}
                                    selected={data.event}
                                    error={validation.isKey('event_id')}
                                    helperText={validation.get('event_id')}
                                    disabled={Number(data.status_id) === 3}
                                />
                            </div> */}

                            <div className="block">
                                <SelectCompany
                                    filter={{ withDeleted: 1 }}
                                    selected={data.organization}
                                    onChange={handleChangeOrganization}
                                    helperText={validation.get('organization_id')}
                                    error={validation.isKey('organization_id')}
                                    disabled={Number(data.status_id) === 3}
                                    label={titles.SELECT_ORGANIZATION}
                                />
                            </div>

                            <div className="block">
                                {/* todo добавить для фильтрации пользователей по организации organization_id: data?.organization_id?.id || null */}
                                <SelectUsers
                                    selected={data.user}
                                    onChange={handleChangeUser}
                                    helperText={validation.get('user_id')}
                                    error={validation.isKey('user_id')}
                                    disabled={Number(data.status_id) === 3 || !data.organization_id}
                                    label={titles.EMPLOYEES}
                                    filter={{ org_ids: [data.organization_id] }}
                                />
                            </div>

                            <div className="block">
                                <SelectScenarios
                                    selected={data?.response_scenario}
                                    onChange={handleChangeResponseScenario}
                                    helperText={validation.get('response_scenario_id')}
                                    error={validation.isKey('response_scenario_id')}
                                    disabled={Number(data.status_id) === 3 || !!disableScenario}
                                />
                            </div>

                            <div className="block">
                                <TextField
                                    label={'Связанное МКДТП'}
                                    size="small"
                                    value={data?.mkdtp_id || ''}
                                    variant="outlined"
                                    // disabled
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip title={'Перейти к МКДТП'}>
                                                <IconButton
                                                    disabled={!data.mkdtp_id
                                                        || !!showReturnTo?.link
                                                        || isNew
                                                        || isMkDtp
                                                    }
                                                    size="small"
                                                    onClick={goToMKDTP}
                                                >
                                                    <i className="fas fa-arrow-right"/>
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }}
                                />
                            </div>

                            <div className="block">
                                <TextField
                                    disabled
                                    label={titles.DATA_SOURCE}
                                    size="small"
                                    value={data.source}
                                    variant="outlined"
                                    name="source"
                                    type="text"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                    error={!!validation.isKey('source')}
                                    helperText={validation.get('source')}
                                />
                            </div>

                            <BoardForm
                                data={data}
                                setData={setData}
                            />

                            <Attachments
                                onChange={handleChangeDocuments}
                                files={data?.attachments}
                                readOnly={Number(data.status_id) === 3}
                                service="situational-plans"
                                onlyLink={false}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <InfoBlock label={titles.LOCATION} className="block">
                                <FormControlLabel
                                    style={{ pointerEvents: 'none' }}
                                    control={
                                        <Checkbox
                                            disabled={Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                                            checked={dependence}
                                            onChange={dependenceHandler}
                                            color="primary"
                                            style={{ pointerEvents: 'auto' }}
                                        />
                                    }
                                    label="Адрес зависит от координат"
                                />

                                <FormControl className="block" variant="outlined">
                                    <LoadAddressByCoords
                                        {...data}
                                        isLoadOnLatLon={false}
                                        isLoadOnChange={dependence}
                                        onChange={onChangeGeoJsonCoordinates}
                                    >
                                        <LatLonCoordinates required/>
                                    </LoadAddressByCoords>
                                </FormControl>

                                <FormControl className="block" variant="outlined">
                                    <AddressFields
                                        {...data}
                                        onChange={(props) => onChangeGeoJsonCoordinates(props)}
                                        returnLatLon={dependence}
                                        returnAddressText={dependence}
                                        returnGeometry={Object.keys(data.geometry || {}).length === 0 || dependence}
                                    />
                                </FormControl>

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {JSON.stringify(data.geometry) === '{}'
                                        && <h2 style={{ color: 'red' }}>Укажите транспортный инцидент на карте *</h2>}
                                </div>

                                <FormControl className="block" variant="outlined">
                                    <LoadAddressByCoords
                                        {...data}
                                        isLoadOnLatLon={false}
                                        isLoadOnChange={dependence}
                                        onChange={onChangeGeoJsonCoordinates}
                                    >
                                        <MapGeoJson
                                            required
                                            circle={false}
                                            rectangle={false}
                                            polyline={true}
                                            polygon={true}
                                            marker={true}
                                            returnLatLon={true}
                                        />
                                    </LoadAddressByCoords>
                                </FormControl>
                            </InfoBlock>

                            <div>
                                {(JSON.stringify(data.geometry) !== '{}' && transportService)
                                && <Routes routes={data.route_list}/>}
                            </div>

                            <TrafficLightList
                                light_ids={data.light_ids}
                                onChange={(light_ids) => setData({ ...data, light_ids })}
                                latlng={[data.lat, data.lon]}
                            />
                        </Grid>
                    </Grid>
                </form>
            }
        </Modal>
    );
};

export default ModalForm;
