import { dateNow, getHumanDate, isDateValid } from 'helpers/date.config';

export const generateFlowData = (existingData, existingNodes = [], existingEdges = [], sourceData) => {
    const data = [...existingData];
    const nodes = [...existingNodes];
    const edges = [...existingEdges];

    const xSpacing = 200;
    const ySpacing = 100;
    const lineXPosition = 100;
    const lineYPosition = 200;

    const dateNodeYPosition = lineYPosition + 26;
    const datePositionMap = {}; // Карта для хранения x-позиций каждой даты

    // Уникальные и отсортированные даты
    const uniqueDates = Array.from(
        new Set(
            data.map(item => {
                const date = new Date(item.created_at);
                return date.toISOString().split('T')[0]; ;
            }).filter(isDateValid)
        )
    ).sort((a, b) => new Date(b) - new Date(a)
    ).map(dateStr => getHumanDate(dateStr));

    // Добавление узлов с датами
    uniqueDates.forEach((date, index) => {
        const dateXPosition = lineXPosition - ((index + 1) * xSpacing);

        nodes.push({
            id: `date-${index}`,
            data: {
                label: date,
                isDateNode: true,
            },
            position: { x: dateXPosition, y: dateNodeYPosition },
            sourcePosition: 'top',
            draggable: false,
            connectable: false,
            style: {
                border: '1px solid transparent',
                backgroundColor: 'white',
            },
        });

        datePositionMap[date] = dateXPosition;
    });

    // Добавление сегодняшней даты
    const todayFormatted = getHumanDate(dateNow());
    nodes.push({
        id: 'date-today',
        data: {
            label: todayFormatted,
            isDateNode: true,
        },
        position: { x: lineXPosition, y: dateNodeYPosition },
        sourcePosition: 'top',
        draggable: false,
        connectable: false,
        style: {
            border: '1px solid transparent',
            backgroundColor: 'white',
        },
    });

    // Добавление узлов инцидентов
    data.forEach((item, index) => {
        const nodeId = String(item.id);
        const incidentDate = getHumanDate(item.created_at);
        const incidentX = datePositionMap[incidentDate] + 75;

        if (incidentX) {
            const incidentY = lineYPosition - (index * ySpacing + 60);
            const nodeWidth = item.status === 'завершен'
                ? 200
                : lineXPosition - incidentX + 75;

            nodes.push({
                id: nodeId,
                data: {
                    label: `${item.name || `Node ${item.id}`} - ${incidentDate}`,
                    date: item.created_at,
                    item: item,
                    fixedX: incidentX, // Сохраняем фиксированное значение X
                },
                position: { x: incidentX, y: incidentY },
                targetPosition: 'left',
                sourcePosition: 'right',
                draggable: true,
                style: {
                    width: nodeWidth,
                },
            });
        }
    });

    // Добавление связей, если sourceData передан
    if (sourceData?.length > 1) {
        const [sourceNode, ...newNodes] = sourceData;
        newNodes.forEach((item) => {
            if (String(item.id) !== sourceNode.id) {
                edges.push({
                    id: `edge-${sourceNode.id}-${item.id}`,
                    connection_id: item.connection_id || 1,
                    source: String(sourceNode.id),
                    target: String(item.id),
                    type: 'smoothstep',
                    animated: false,
                    data: { connection_id: null, first_incident: sourceNode, second_incident: item },
                    
                });
            }
        });
    }

    return { nodesData: nodes, edgesData: edges };
};

export const allNodes = [
    // Узлы для горизонтальной линии
    {
        id: 'invisible-source',
        type: 'input',
        position: { x: -2000, y: 200 }, // Позиция начального узла для горизонтальной линии
        style: { opacity: 0 },
        data: {},
    },
    {
        id: 'invisible-target',
        type: 'output',
        position: { x: 2000, y: 228 }, // Позиция конечного узла для горизонтальной линии
        style: { opacity: 0 },
        data: {},
    },
    // Узлы для вертикальной линии
    {
        id: 'vertical-invisible-source',
        type: 'input',
        position: { x: 100, y: 200 }, // Начальная точка вертикальной линии (на уровне горизонтальной линии)
        style: { opacity: 0 },
        data: {},
    },
    {
        id: 'vertical-invisible-target',
        type: 'output',
        position: { x: 100, y: -1000 }, // Конечная точка вертикальной линии (выше горизонтальной линии)
        style: { opacity: 0 },
        data: {},
    },
];

export const allEdges = [
    // Ребро для горизонтальной линии
    {
        id: 'horizontal-line',
        source: 'invisible-source',
        target: 'invisible-target',
        type: 'straight',
        style: {
            stroke: 'black',
            strokeWidth: 1,
        },
        animated: false,
    },
    // Ребро для вертикальной линии
    {
        id: 'vertical-line',
        source: 'vertical-invisible-source',
        target: 'vertical-invisible-target',
        type: 'straight',
        style: {
            stroke: 'black',
            strokeWidth: 1,
        },
        animated: true,
    },
];