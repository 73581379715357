import titles from 'helpers/constants/titles';
import renderAddress from 'helpers/renderAddress';
import Information from 'components/common/Information';
import externalTransportTitles from 'modules/ExternalTransport/utils/helpers/constants';

import AirPortTabs from './AirportTabs';
import RailwayStationTabs from './RailwayTabs';

const Content = ({ data, isAirport }) => {
    const fields = {
        address_text: {
            title: titles.ADDRESS,
            value: ({ address, address_text }) => address_text || renderAddress(address),
        },
        ...(isAirport
            ? {
                website: {
                    title: externalTransportTitles.WEBSITE,
                    value: ({ station }) => station?.website || null,
                },
                iata: {
                    title: externalTransportTitles.IATA,
                    value: ({ station }) => station?.iata || null,
                },
            }
            : {}),
        phone_number: {
            title: externalTransportTitles.PHONE_NUMBER,
            value: ({ station }) => station?.phone_number || null,
        },
    };

    return (
        <>
            <Information
                data={data}
                title={fields}
                returnNull
            />
            {isAirport
                ? <AirPortTabs id={data.id} />
                : <RailwayStationTabs id={data.id} />
            }
        </>
    );
};

export default Content;
