import * as types from './types';

export const loadBoardStatuses = () => ({
    type: types.LOAD_BOARD_STATUSES,
});

export const boardStatusesLoaded = (data) => ({
    type: types.BOARD_STATUSES_LOADED,
    payload: data,
});

export const loadBoardTypes = (params = {}, callback) => ({
    type: types.LOAD_BOARD_TYPES,
    payload: params,
    callback
});

export const boardTypesLoaded = (data) => ({
    type: types.BOARD_TYPES_LOADED,
    payload: data,
});

export const loading = (bool = false) => ({
    type: types.LOADING,
    payload: bool,
});

export const loadBoards = (params, callback) => ({
    type: types.LOAD_BOARDS,
    payload: params,
    callback
});

export const boardsLoaded = (users) => ({
    type: types.BOARDS_LOADED,
    payload: users,
});

export const loadBoardBefore = (id) => ({
    type: types.LOAD_BOARD_BEFORE,
    payload: id,
});
export const loadedBoardBefore = (data) => ({
    type: types.LOADED_BOARD_BEFORE,
    payload: data,
});
export const loadingBoardBefore = (bool = false) => ({
    type: types.LOADING_BOARD_BEFORE,
    payload: bool,
});


export const createBoard = (data, callback) => ({
    type: types.CREATE_BOARD,
    payload: data,
    callback
});

export const editBoard = (id, data, callback) => ({
    type: types.EDIT_BOARD,
    payload: {
        id,
        data
    },
    callback
});

export const deleteBoard = (id, callback) => ({
    type: types.DELETE_BOARD,
    payload: id,
    callback
});

export const clearBoards = () => ({
    type: types.CLEAR_BOARDS
});

export const loadMessagePriorities = () => ({
    type: types.LOAD_MESSAGE_PRIORITIES,
});

export const loadedMessagePriorities = (data) => ({
    type: types.LOADED_MESSAGE_PRIORITIES,
    payload: data,
});

export const loadMessages = (params) => ({
    type: types.LOAD_MESSAGES,
    payload: params,
});

export const loadingMessages = (bool = false) => ({
    type: types.LOADING_MESSAGES,
    payload: bool,
});

export const loadedMessages = (data) => ({
    type: types.LOADED_MESSAGES,
    payload: data,
});

export const createMessage = (data, callback) => ({
    type: types.CREATE_MESSAGE,
    payload: data,
    callback,
});

export const editMessage = (id, data, callback) => ({
    type: types.EDIT_MESSAGE,
    payload: {
        id,
        data
    },
    callback,
});

export const deleteMessage = (id, dit_ids) => ({
    type: types.DELETE_MESSAGE,
    payload: { id, dit_ids },
});

export const loadBoardColors = () => ({
    type: types.LOAD_BOARD_COLORS,
});

export const loadedBoardColors = (data) => ({
    type: types.LOADED_BOARD_COLORS,
    payload: data,
});

export const getForPolygon = (polygon, params = {}) => ({
    type: types.GET_POLYGON,
    payload: {
        polygon,
        params
    }
});
export const saveForPolygon = (data) => ({
    type: types.POLYGON_LOADED,
    payload: data,
});
export const clearForPolygon = () => ({
    type: types.POLYGON_LOADED,
    payload: [],
});
export const setPolygonLoading = (bool = false) => ({
    type: types.POLYGON_LOADING,
    payload: bool,
});

export const setDeleteForm = (data = false) => ({
    type: types.SET_DELETE_FORM,
    payload: data
});

export const setEditForm = (data = false) => ({
    type: types.SET_EDIT_FORM,
    payload: data
});


export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});
export const resetActive = () => ({
    type: types.RESET_ACTIVE,
});


export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});
export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

export const loadSidebar = (page, limit, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        params,
    },
});
export const loadedSidebar = (data) => ({
    type: types.LOADED_SIDEBAR,
    payload: data,
});
export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool,
});
export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR,
});

export const setSaved = (bool = false) => ({
    type: types.SET_SAVED,
    payload: bool,
});

export const fetchMessagePreview = (id, data) => ({
    type: types.FETCH_PREVIEW,
    payload: {
        id,
        data
    }
});

export const fetchedMessagePreview = (data) => ({
    type: types.FETCHED_PREVIEW,
    payload: data
});

export const fetchMessageJSONPreview = (id, data) => ({
    type: types.FETCH_MESSAGE_JSON_PREVIEW,
    payload: {
        id,
        data
    }
});

export const fetchingMessageJSONPreview = (bool = false) => ({
    type: types.FETCHING_MESSAGE_JSON_PREVIEW,
    payload: bool,
});

export const fetchedMessageJSONPreview = (data) => ({
    type: types.FETCHED_MESSAGE_JSON_PREVIEW,
    payload: data
});

export const clearMessageJSONPreview = () => ({
    type: types.CLEAR_MESSAGE_JSON_PREVIEW,
});

export const fetchActiveBoardMessage = (id) => ({
    type: types.FETCH_ACTIVE_BOARD_MESSAGE,
    payload: { id }
});

export const fetchingActiveBoardMessage = (bool = false) => ({
    type: types.FETCHING_ACTIVE_BOARD_MESSAGE,
    payload: bool
});

export const fetchedActiveBoardMessage = (data) => ({
    type: types.FETCHED_ACTIVE_BOARD_MESSAGE,
    payload: data
});

export const clearActiveBoardMessage = () => ({
    type: types.CLEAR_ACTIVE_BOARD_MESSAGE,
});

export const loadFontsList = (params) => ({
    type: types.LOAD_FONTS_LIST,
    payload: params
});

export const loadedFontsList = (data) => ({
    type: types.LOADED_FONTS_LIST,
    payload: data
});

export const loadFontsListForTypeWithImg = () => ({
    type: types.LOAD_FONTS_LIST_FOR_TYPE_WITH_IMG
});

export const loadedFontsListForTypeWithImg = (data) => ({
    type: types.LOADED_FONTS_LIST_FOR_TYPE_WITH_IMG,
    payload: data
});

export const loadSignsList = () => ({
    type: types.LOAD_SIGNS_LIST
});

export const loadedSignsList = (data) => ({
    type: types.LOADED_SIGNS_LIST,
    payload: data
});

export const loadBoardRotationMessages = (id) => ({
    type: types.LOAD_BOARD_ROTATION_MESSAGES,
    payload: id
});

export const loadingBoardRotationMessages = (bool = false) => ({
    type: types.LOADING_BOARD_ROTATION_MESSAGES,
    payload: bool,
});

export const loadedBoardRotationMessages = (data) => ({
    type: types.LOADED_BOARD_ROTATION_MESSAGES,
    payload: data
});

export const loadBoardEvents = (id) => ({
    type: types.LOAD_BOARD_EVENTS,
    payload: id
});

export const loadingBoardEvents = (bool = false) => ({
    type: types.LOADING_BOARD_EVENTS,
    payload: bool,
});

export const loadedBoardEvents = (data) => ({
    type: types.LOADED_BOARD_EVENTS,
    payload: data
});

export const loadDitLink = (params = {}, microservice, callback) => ({
    type: types.LOAD_DIT_LINK,
    payload: {
        params,
        microservice
    },
    callback
});

export const loadingDitLink = (bool = false) => ({
    type: types.LOADING_DIT_LINK,
    payload: bool,
});

export const loadedDitLink = (data) => ({
    type: types.LOADED_DIT_LINK,
    payload: data
});

export const clearDitLink = () => ({
    type: types.CLEAR_DIT_LINK,
});

export const loadBoardsFromLinks = (params, callback) => ({
    type: types.LOAD_BOARDS_FROM_LINK,
    payload: params,
    callback,
});
export const loadingBoardsFromLinks = (bool = false) => ({
    type: types.LOADING_BOARDS_FROM_LINK,
    payload: bool,
});
export const loadedBoardsFromLinks = (data) => ({
    type: types.LOADED_BOARDS_FROM_LINK,
    payload: data,
});

export const createDitLink = (data, callback, microservice) => ({
    type: types.CREATE_DIT_LINK,
    payload: data,
    callback,
    microservice
});

export const loadMessageTemplates = (params, callback) => ({
    type: types.LOAD_MESSAGE_TEMPLATES,
    payload: params,
    callback
});

export const loadingMessageTemplates = (bool = false) => ({
    type: types.LOADING_MESSAGE_TEMPLATES,
    payload: bool,
});

export const loadedMessageTemplates = (data) => ({
    type: types.LOADED_MESSAGE_TEMPLATES,
    payload: data,
});

export const createMessageTemplate = (data, callback = () => {}) => ({
    type: types.CREATE_MESSAGE_TEMPLATE,
    payload: data,
    callback
});

export const editMessageTemplate = (id, data, callback = () => {}) => ({
    type: types.EDIT_MESSAGE_TEMPLATE,
    payload: {
        id,
        data
    },
    callback
});

export const deleteMessageTemplate = (id, callback = () => {}) => ({
    type: types.DELETE_MESSAGE_TEMPLATE,
    payload: id,
    callback
});

export const createMessageTemplateBoards = (data, callback = () => {}) => ({
    type: types.CREATE_MESSAGE_TEMPLATE_BOARDS,
    payload: data,
    callback
});

export const setWsDit = (events) => ({
    type: types.SET_WS_DIT,
    payload: events,
});

export const setSidebarParams = (params) => ({
    type: types.SET_SIDEBAR_PARAMS,
    payload: params,
});

/* templates image */
export const loadListTemplateImage = (params, callback) => ({
    type: types.LOAD_LIST_TEMPLATE_IMAGE,
    payload: params,
    callback,
});
export const loadedListTemplateImage = (data) => ({
    type: types.LOADED_LIST_TEMPLATE_IMAGE,
    payload: data,
});

export const createTemplateImage = (data, callback) => ({
    type: types.CREATE_TEMPLATE_IMAGE,
    payload: data,
    callback,
});
export const editTemplateImage = (id, data, callback) => ({
    type: types.EDIT_TEMPLATE_IMAGE,
    payload: {
        id,
        data
    },
    callback,
});
export const deleteTemplateImage = (id, callback) => ({
    type: types.DELETE_TEMPLATE_IMAGE,
    payload: id,
    callback,
});

export const loadingListTemplateImage = (bool = false) => ({
    type: types.LOADING_LIST_TEMPLATE_IMAGE,
    payload: bool,
});

export const loadingTemplateImage = (bool = false) => ({
    type: types.LOADING_TEMPLATE_IMAGE,
    payload: bool,
});
// шаблоны уведомлений по сервисам
export const loadAlertTemplateFileds = () => ({
    type: types.LOAD_ALERT_TEMPLATE_FILEDS,
});

export const alertTemplateFiledsLoaded = (data) => ({
    type: types.ALERT_TEMPLATE_FILEDS_LOADED,
    payload: data,
});

export const loadAlertTemplateStatuses = () => ({
    type: types.LOAD_ALERT_TEMPLATE_STATUSES,
});

export const alertTemplateStatusesLoaded = (data) => ({
    type: types.ALERT_TEMPLATE_STATUSES_LOADED,
    payload: data,
});

export const loadAlertTemplateTypes = () => ({
    type: types.LOAD_ALERT_TEMPLATE_TYPES,
});

export const alertTemplateTypesLoaded = (data) => ({
    type: types.ALERT_TEMPLATE_TYPES_LOADED,
    payload: data,
});

export const loadingAlertTemplates = (bool = false) => ({
    type: types.LOADING_ALERT_TEMPLATES,
    payload: bool,
});

export const loadAlertTemplates = (params) => ({
    type: types.LOAD_ALERT_TEMPLATES,
    payload: params,
});

export const alertTemplatesLoaded = (users) => ({
    type: types.ALERT_TEMPLATES_LOADED,
    payload: users,
});

export const createAlertTemplate = (data, callback) => ({
    type: types.CREATE_ALERT_TEMPLATE,
    payload: data,
    callback
});

export const editAlertTemplate = (id, data, callback) => ({
    type: types.EDIT_ALERT_TEMPLATE,
    payload: {
        id,
        data
    },
    callback,
});

export const deleteAlertTemplate = (id, callback) => ({
    type: types.DELETE_ALERT_TEMPLATE,
    payload: id,
    callback,
});

// загрузка и сохранение в стор sign для автокомплитов
export const loadSigns = (params, callback) => ({
    type: types.LOAD_SIGNS,
    payload: params,
    callback,
});
export const loadingSigns = (bool = false) => ({
    type: types.LOADING_SIGNS,
    payload: bool,
});
export const loadedSigns = (data) => ({
    type: types.LOADED_SIGNS,
    payload: data,
});
export const loadAlignHorizontal = () => ({
    type: types.LOAD_ALIGN_HORIZONTAL,
});
export const loadedAlignHorizontal = (data) => ({
    type: types.LOADED_ALIGN_HORIZONTAL,
    payload: data,
});
export const loadAlignVertical = () => ({
    type: types.LOAD_ALIGN_VERTICAL,
});
export const loadedAlignVertical = (data) => ({
    type: types.LOADED_ALIGN_VERTICAL,
    payload: data,
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});
export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});
export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});
export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

export const loadCategories = () => ({
    type: types.LOAD_CATEGORIES,
});

export const loadedCategories = (data) => ({
    type: types.LOADED_CATEGORIES,
    payload: data
});