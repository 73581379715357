import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import { incidentsSelectors } from 'redux/Incidents';
import { loadDtpByPercent } from 'redux/Incidents/actions';
import messages from 'helpers/constants/messages/common';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import ReportBlock from 'components/common/Report/ReportBlock';
import PageLayout from 'components/layout/PageLayout';

import Filters from './Filters';
import Graph from './Graph';

const DtpReportTypes = ({
    tab,
    hideTitle
}) => {
    const dispatch =  useDispatch();

    const dtpReportByPercent = useSelector(incidentsSelectors.dtpReportByPercent);
    const loadingDtpReportByPercent = useSelector(incidentsSelectors.loadingDtpReportByPercent);

    const initialState = {
        start_date: getStartOf(),
        end_date: getEndOf(),
        dtp_type: [],
        type_load_list: [],
        with_wounded: false,
        with_dead: false,
        category_road_id: [],
        category_street_id: [],
        graph_type: {
            pie: true,
            scatter: false,
            bar: false
        }
    };

    const [filterValues, setFilterValues] = useState(initialState);

    const getFilter = (filters) => {
        const result = {
            ...filters,
            start_date: fullDateTimeWithTimeZone(filters.start_date),
            end_date: fullDateTimeWithTimeZone(filters.end_date),
            dtp_type: filters.dtp_type.map(({ id }) => id),
            type_load_list: filters.type_load_list.map(({ id }) => id),
            with_wounded: filters.with_wounded ? 1 : 0,
            with_dead: filters.with_dead ? 1 : 0,
            category_road_id: filters.category_road_id?.map(({ id }) => id),
            category_street_id: filters.category_street_id?.map(({ id }) => id),
        };
        delete result.graph_type;
        return removeEmptyFields(result);
    };

    const getGraph = (filter) => {
        const result = getFilter(filter);
        dispatch(loadDtpByPercent(result));
    };

    const getReport = (format) => {
        const result = getFilter(filterValues);
        dispatch(loadDtpByPercent({ ...result, report: format }));
    };

    const renderContent = () => {
        return dtpReportByPercent.length > 0
            ? (filterValues.graph_type.pie || filterValues.graph_type.scatter || filterValues.graph_type.bar)
                ? <Graph filter={filterValues.graph_type} data={dtpReportByPercent} />
                :  <div>Выберите тип графика</div>
            : (!loadingDtpReportByPercent && <div>{messages.REQUEST_DATA_IS_NOT_FOUND}</div>);
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh) {
            getGraph(initialState);
        }
        setFilterValues(initialState);
    };

    return (
        <PageLayout
            header={hideTitle ? ' ': 'Отчет по типам'}
            tab={tab}
            filters={
                <Filters
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    initialState={initialState}
                    getGraph={getGraph}
                    resetFilters={resetFilters}
                    tab={tab}
                />
            }
            content={renderContent}
            loading={loadingDtpReportByPercent}
            informPanelProps={{
                buttons: <ReportBlock
                    onGetReport={getReport}
                />
            }}
            actionPanel={<>
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={(e) => setFilterValues({
                                ...filterValues,
                                graph_type: {
                                    ...filterValues.graph_type,
                                    pie: e.target.checked
                                }
                            })}
                            checked={filterValues.graph_type.pie}
                            size="small"
                        />
                    }
                    label="Круговая диаграмма"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={(e) => setFilterValues({
                                ...filterValues,
                                graph_type: {
                                    ...filterValues.graph_type,
                                    bar: e.target.checked
                                }
                            })}
                            checked={filterValues.graph_type.bar}
                            size="small"
                        />
                    }
                    label="Столбчатая диаграмма"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onClick={(e) => setFilterValues({
                                ...filterValues,
                                graph_type: {
                                    ...filterValues.graph_type,
                                    scatter: e.target.checked
                                }
                            })}
                            checked={filterValues.graph_type.scatter}
                            size="small"
                        />
                    }
                    label="Диаграмма рассеивания"
                />
            </>
            }
        />
    );
};

export default DtpReportTypes;
