import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearEventsMeteo,
    getEventsMeteo
} from 'redux/Meteo/actions';
import { meteoSelectors } from 'redux/Meteo';
import { usePrevious } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import MapScroll from 'components/MapComponents/MapScroll';

const TabEvents = (props) => {
    const { external_id } = props;
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const loading = useSelector(meteoSelectors.loading);
    const eventsDevice = useSelector(meteoSelectors.eventsDevice);
    const prevLoading = usePrevious(loading);

    useEffect(() => {
        if (page > 1) {
            dispatch(getEventsMeteo(external_id, page, limit, false));
        }
    }, [page, limit]);

    useEffect(() => {
        if (prevLoading === true && loading === false) {
            setIsOpen(true);
        }
    }, [loading]);

    useEffect(() => {
        dispatch(clearEventsMeteo());
        dispatch(getEventsMeteo(external_id, page, limit, false));
        return () => {
            dispatch(clearEventsMeteo());
        };
    }, []);

    const total = (eventsDevice.meta && eventsDevice.meta.last_page) || 0;

    const scrollHandleUpdate = () => {
        if (loading === false) {
            if (total && page < total) {
                setPage(page + 1);
            }
        }
    };

    return (
        <>
            {loading && total === 0 && <Loading size={50} className="preloader center" />}

            {isOpen && (
                <>
                    {eventsDevice.data.length > 0
                        ? <MapScroll onUpdate={scrollHandleUpdate}>
                            <table className="table-information">
                                <tbody>
                                    <tr>
                                        <th>Событие</th>
                                        <th>Дата</th>
                                    </tr>
                                    {eventsDevice.data.map(
                                        (
                                            {
                                                event_text,
                                                external_id,
                                                event_id,
                                                date_time_uts
                                            },
                                            index
                                        ) => (
                                            <tr key={`event_${external_id}_${event_id}_${index}`}>
                                                <td>
                                                    {event_text}
                                                </td>
                                                <td>
                                                    {fullDateTime(date_time_uts)}
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>

                            {((total && page < total) || loading) && (
                                <div>
                                    <Loading className="preloader center" />
                                </div>
                            )}
                        </MapScroll>
                        : <div className="info__item">{messages.EVENTS_IS_NOT_FOUND}</div>
                    }
                </>
            )}
        </>
    );
};

export default TabEvents;
