import { useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';

import type { Header } from '@tanstack/react-table';

interface ColumnMenuProps<T> {
    header: Header<T, unknown>;
}

function ColumnOptions<T>({ header }: ColumnMenuProps<T>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleHideColumn = () => {
        header.column.toggleVisibility();
        setAnchorEl(null);
    };

    const togglePinColumn = () => {
        setAnchorEl(null);
        header.column.pin(!header.column.getIsPinned() && 'left');
    };

    return (
        <div>
            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} disableRipple>
                <i className="far fa-ellipsis-v" />
            </IconButton>

            {open && (
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={handleHideColumn}>
                        <ListItemIcon>
                            <VisibilityOffIcon fontSize="small" />
                        </ListItemIcon>

                        <ListItemText>Скрыть</ListItemText>
                    </MenuItem>

                    <MenuItem onClick={togglePinColumn}>
                        <ListItemIcon>
                            <PushPinIcon style={{ transform: 'rotate(45deg)' }} fontSize="small" />
                        </ListItemIcon>

                        <ListItemText>
                            {header.column.getIsPinned() ? 'Открепить' : 'Закрепить'}
                        </ListItemText>
                    </MenuItem>
                </Menu>
            )}
        </div>
    );
}

export default ColumnOptions;
