import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import _ from 'lodash';
import {
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import type { VisibilityState } from '@tanstack/table-core/src/features/ColumnVisibility';

interface SettingsMenuProps {
    settings: {
        visibility: {
            reset: () => void;
            columns: VisibilityState;
        };
        draggable: {
            enabled: boolean;
            toggle: (enabled: boolean) => void;
        };
    };
}

function SettingsMenu({ settings }: SettingsMenuProps) {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(anchor);

    const handleShowAll = () => {
        setAnchor(null);
        settings.visibility.reset();
    };

    const toggleDraggable = (enabled: boolean) => {
        setAnchor(null);
        settings.draggable.toggle(enabled);
    };

    const isNotEmpty = !_.isEmpty(settings.visibility.columns);

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Tooltip title="Настройки">
                    <IconButton onClick={(e) => setAnchor(e.currentTarget)} disableRipple>
                        <SettingsIcon />
                    </IconButton>
                </Tooltip>
            </div>

            {open && (
                <Menu
                    anchorEl={anchor}
                    open={open}
                    onClose={() => setAnchor(null)}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem disableRipple>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={settings.draggable.enabled}
                                    onChange={(e) => toggleDraggable(e.target.checked)}
                                />
                            }
                            label="Перетаскивание столбцов"
                        />
                    </MenuItem>

                    {isNotEmpty && <Divider />}

                    {isNotEmpty && (
                        <MenuItem onClick={handleShowAll} disableRipple>
                            <ListItemIcon>
                                <VisibilityIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Показать скрытые столбцы</ListItemText>
                        </MenuItem>
                    )}
                </Menu>
            )}
        </>
    );
}

export default SettingsMenu;
