import { useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import {
    deleteWorkorderLogManualStatus,
    loadWorkorderLogManualStatus,
    loadWorkorderLogs
} from 'redux/TransportRoad/actions';
import messages from 'helpers/constants/messages';
import useStoreProp from 'helpers/hooks/useStoreProp';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import styles from '../workOrderControl.module.scss';

import ManualStatusesModal from './StatusesModal';

import type { EventContent } from '../types';

interface EventItemContentProps {
    item: EventContent;
    index: number;
}

const EventItemContent = ({ item, index }: EventItemContentProps) => {
    const dispatch = useDispatch();
    const [openChangeStatus, setOpenChangeStatus] = useState(false);
    const [openDeleteStatus, setOpenDeleteStatus] = useState(false);

    const statuses = useStoreProp(
        loadWorkorderLogManualStatus,
        'transportRoad',
        'workorderManualStatusList'
    );

    const onSuccess = () => {
        dispatch(deleteWorkorderLogManualStatus(item?.id, () => {
            dispatch(loadWorkorderLogs(item.id));
            setOpenDeleteStatus(false);
        }));
    };

    return (
        <>
            {openChangeStatus && (
                <ManualStatusesModal
                    isOpen={openChangeStatus}
                    isNew={!item.manual_status_id}
                    onClose={() => setOpenChangeStatus(false)}
                    item={item}
                />
            )}
            {openDeleteStatus &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteStatus}
                    onSuccess={onSuccess}
                    onClose={() => setOpenDeleteStatus(false)}
                />
            )}
            <div className={styles.info_block}>
                <div className={cn(styles.info_block__item, styles.info_block__item_first)}>
                    {index + 1}
                </div>
                <div className={styles.info_block__item} style={{ wordBreak: 'break-word', width: '35%' }}>
                    {item?.name}
                </div>
                <div  className={styles.info_block__item}>
                    {item?.auto_status_text}
                </div>
                <div className={cn(styles.info_block__item, styles.info_block__statuses)}>
                    {item?.manual_status_id && <div>{statuses[item?.manual_status_id]}</div>}
                    <div>
                        {item.manual_status_id
                            ? <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.editIcon,
                                        onClick: () => setOpenChangeStatus(true),
                                        style: { fontSize: '0.875rem' }
                                    },
                                    {
                                        ...buttonsTypes.deleteIcon,
                                        onClick: () => setOpenDeleteStatus(true),
                                        style: { fontSize: '0.875rem' }
                                    },
                                ]}
                                noMarginLeft
                                justButton
                            />
                            : <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.addIcon,
                                        onClick: () => setOpenChangeStatus(true),
                                        style: { fontSize: '0.875rem' }
                                    }
                                ]}
                                noMarginLeft
                                justButton
                            />
                        }
                    </div>
                </div>
                <div className={styles.info_block__item}>
                    {item?.count}
                </div>
                <div className={styles.info_block__item}>
                    {item?.entries?.length > 0
                        ? (
                            <div>
                                {item?.entries?.map((el, index) => (
                                    <div key={index}>
                                        {fullDateTimeWithoutSeconds(el.time)}
                                    </div>
                                ))}
                            </div>
                        )
                        : messages.NO_DATA}
                </div>
            </div>
        </>
    );
};

export default EventItemContent;
