import { useState, ChangeEvent } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

interface FiltersProps {
    setParams: (params: { page: number; data: { name?: string } }) => void;
}

interface FilterState {
    name: string;
}

const Filters = ({ setParams }: FiltersProps) => {
    const [value, setValue] = useState({
        name: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue({ name: e.target.value });
    };

    const search = (data: FilterState | {} = value) => {
        setParams({ page: 1, data });
    };

    const resetSearch = (needRefresh: boolean) => {
        setValue({ name: '' });
        if (needRefresh) {
            search({});
        }
    };

    const updateFilter = (filters: FilterState) => {
        setValue(filters);
        if (filters.name) {
            search(filters);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={search}
            disabled={!value.name}
            filter={value}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={value.name || ''}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;
