import { useEffect, useRef, useState } from 'react';
import { render } from 'react-dom';
import { IconButton, Tooltip } from '@mui/material';
import L from 'leaflet';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { v4 as uuidv4 } from 'uuid';

import { config } from 'config';

import { fnDeleteIndex, geometryOptions, getDistance, getMarker, getSquare, rulerTypes } from './helpers';

const InnerComponent = ({
    map,
    type,
}) => {

    // координаты
    const [coordinates, setCoordinates] = useState([]);
    // маркеры
    const markersRef = useRef({});
    // id маркера для отображения tooltip
    const tooltipMarkerId = useRef(null);
    // слой
    const featureGroupRef = useRef(null);
    // запрет клика по карте
    const mapDisabledClick = useRef(false);

    // получить tootltip
    const getTooltip = () => featureGroupRef.current?.getTooltip?.() || null;

    // изменить блокировку клика
    const handleChangeMapDisabledClick = (status = false) => () => mapDisabledClick.current = status;

    // удаление маркера по id
    const deleteMarkerById = (id) => {
        const marker = markersRef.current[id] || null;
        if (marker) {
            featureGroupRef.current?.removeLayer?.(marker);
            marker
                ?.off('move')
                ?.off('dblclick')
                ?.off('click')
                ?.remove();
            delete markersRef.current[id];
        }
    };

    // button удалить последний маркер
    const handleDeleteLast = () => {
        const markerId = tooltipMarkerId.current;
        deleteMarkerById(markerId);
        setCoordinates(fnDeleteIndex(markerId));
        tooltipMarkerId.current = null;
        setTimeout(handleChangeMapDisabledClick(false), 200);
    };

    // button удалить все маркеры
    const handleDeleteAll = () => {
        Object.keys(markersRef.current).forEach(markerId => {
            deleteMarkerById(markerId);
        });
        setCoordinates([]);
        tooltipMarkerId.current = null;
        setTimeout(handleChangeMapDisabledClick(false), 200);
    };

    // marker удалить маркер
    const handleDeleteMarker = (id) => () => {
        deleteMarkerById(id);
        setCoordinates(fnDeleteIndex(id));
        if (id === tooltipMarkerId.current) tooltipMarkerId.current = null;
    };

    // marker сдвиг маркера
    const handleChangeLatLon = (id) => ({ latlng }) => setCoordinates(old => {
        const index = old.findIndex((i) => i.id === id);
        return index >= 0
            ?  [
                ...old.slice(0, index),
                {
                    ...old[index],
                    value: latlng
                },
                ...old.slice(index + 1)
            ]
            : old;
    });

    // map новый маркер на карте
    const handleMapClick = (e) => {
        if (mapDisabledClick.current) return;

        const id = uuidv4();

        const marker = L.marker(
            e.latlng,
            {
                icon: getMarker,
                draggable: true,
                // bubblingMouseEvents: true,
            }
        )
            .on('move', handleChangeLatLon(id))
            .on('dblclick', handleDeleteMarker(id))
            .on('click', () => {});

        markersRef.current[id] = marker;
        marker.addTo(featureGroupRef.current);
        tooltipMarkerId.current = id;

        setCoordinates(old => ([
            ...old,
            {
                id,
                value: e.latlng,
            }
        ]));
    };

    // задать индекс tooltip если не задан
    useEffect(() => {
        if (tooltipMarkerId.current === null && coordinates.length > 0) {
            const lastIndex = coordinates[coordinates.length - 1]?.id || null;
            tooltipMarkerId.current = lastIndex;
        }
    }, [coordinates]);

    // конструктор
    useEffect(() => {
        if (map) {
            map.on('click', handleMapClick);

            // создание группы
            featureGroupRef.current = L.featureGroup().addTo(map);
            featureGroupRef.current.bringToFront();

            const latlng = config.get('mapCenter');

            const tooltip = L.tooltip({
                offset: [0, -37],
                permanent: true,
                direction: 'top',
                className: 'rs-tooltip',
                interactive: true
            })
                .setContent('<div id="rs-tooltip" style="width: 110px;"></div>')
                .setLatLng(latlng || [0, 0])
                .on('mouseover', handleChangeMapDisabledClick(true))
                .on('mouseout', handleChangeMapDisabledClick(false));

            featureGroupRef.current.bindTooltip(tooltip);
            featureGroupRef.current.getTooltip().closeTooltip();

            // удаление
            return () => {
                setCoordinates([]);
                Object.keys(markersRef.current).forEach(markerId => {
                    deleteMarkerById(markerId);
                });
                tooltipMarkerId.current = null;

                map.off('click', handleMapClick);

                featureGroupRef.current?.unbindTooltip?.();

                tooltip
                    .off('mouseover')
                    .off('mouseout')
                    .remove();

                if (featureGroupRef.current) {
                    map.removeLayer(featureGroupRef.current);
                    featureGroupRef.current?.remove?.();
                    featureGroupRef.current = null;
                }
            };
        }
    }, [map]);

    // формирование объекта
    useEffect(() => {
        const featureGroup = featureGroupRef.current;
        if (featureGroup && coordinates.length > 1) {

            const latLngs = coordinates.map(({ value }) => value);
            const getGeometry = () => {
                switch (type) {
                    case rulerTypes.line:
                        return new L.polyline(latLngs, geometryOptions);
                    case rulerTypes.polygon:
                        return new L.polygon(latLngs, geometryOptions);
                    default:
                        return null;
                }
            };

            const polyline = getGeometry();
            polyline.addTo(featureGroup);

            return () => {
                featureGroup?.removeLayer?.(polyline);
                polyline?.remove?.();
            };
        }
    }, [type, coordinates, featureGroupRef.current]);

    useEffect(() => {
        const featureGroup = featureGroupRef.current;

        if (featureGroup) {
            // установить координаты tooltip
            if (coordinates.length > 0 && tooltipMarkerId.current) {
                const item = coordinates
                    .find(i => i.id === tooltipMarkerId.current)
                    ?.value
                    || null;
                item && featureGroup.getTooltip?.()?.setLatLng?.(item)?.update?.();
            }

            // показывать скрывать tooltip
            if (
                (tooltipMarkerId.current && !featureGroup.isTooltipOpen?.())
                || (!tooltipMarkerId.current && featureGroup.isTooltipOpen?.())
            ) {
                // смена отображения
                featureGroup.toggleTooltip?.();
            }
        }
    }, [coordinates, featureGroupRef.current, tooltipMarkerId.current]);

    // обновление содержимого tooltip
    useEffect(() => {
        const id = document.getElementById('rs-tooltip');
        if (id) {
            const getValue = () => {
                const latlngs = coordinates.map(({ value }) => value);

                switch (type) {
                    case rulerTypes.line: return getDistance(latlngs);
                    case rulerTypes.polygon: return getSquare(latlngs);
                    default: return '';
                }
            };

            render(
                <div className="rs-tooltip-container">
                    <span className="text">
                        {getValue()}
                    </span>
                    <div className="buttons">
                        <Tooltip title="Удалить точку">
                            <IconButton size="small" onClick={handleDeleteLast} >
                                <ClearIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Удалить все точки">
                            <IconButton size="small" onClick={handleDeleteAll}>
                                <DeleteForeverIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>,
                id
            );
        }
    }, [coordinates, type, getTooltip()]);

    return null;
};

export default InnerComponent;
