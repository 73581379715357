import { useState } from 'react';
import { IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { loadOwnerships } from 'redux/Incidents/actions';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import { getValue } from 'components/MapComponents/Layers/Incidents/helper';

import EditModal from './AddEditModal';

const Item = ({
    index,
    vehicle,
    onChange,
    onDelete, }) => {
    const ownerships = useStoreProp(loadOwnerships, 'incidents', 'ownerships');

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        onDelete && onDelete(index);
        setIsDelete(false);
    };

    return (
        <ListItem className="list_item">
            <ListItemText>
                <div>
                    <strong>{titles.TS}:</strong>&nbsp;
                    {index + 1}
                </div>
                <div>
                    <strong>{titles.LEAVING_SCENE}:</strong>&nbsp;
                    {vehicle?.leaving_scene || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.GRZ}:</strong>&nbsp;
                    {vehicle?.grn || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.TRANSPORT_TYPE}:</strong>&nbsp;
                    {vehicle?.class_name || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.BRAND}:</strong>&nbsp;
                    {vehicle?.brand || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.MODEL}:</strong>&nbsp;
                    {vehicle?.model || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.COLOR}:</strong>&nbsp;
                    {vehicle?.color || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.PLACES_OF_DAMAGE}:</strong>&nbsp;
                    {vehicle?.places_of_damage_text?.join(', ') || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.YEAR_OF_MANUFACTURE}:</strong>&nbsp;
                    {vehicle?.year || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.TECHNICAL_PROBLEMS}:</strong>&nbsp;
                    {vehicle?.technical_issues || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.DRIVE_LOCATION_TYPE}:</strong>&nbsp;
                    {vehicle?.drive_location_type || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.OWNERSHIP_FORM}:</strong>&nbsp;
                    {getValue(ownerships, vehicle?.ownership_type)?.name || messages.NO_DATA}
                </div>
                <div>
                    <strong>{titles.COMMENT}:</strong>&nbsp;
                    {vehicle?.comment || messages.NO_DATA}
                </div>
            </ListItemText>
            <Tooltip title={titles.EDIT}>
                <IconButton onClick={() => setOpenEditModal(true)} size="small">
                    <EditIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Tooltip title={titles.DELETE}>
                <IconButton onClick={() => setIsDelete(true)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {openEditModal &&  (
                <EditModal
                    vehicle={vehicle}
                    index={index}
                    isOpen={openEditModal} 
                    onClose={() => setOpenEditModal(false)}
                    onAccept={onChange}
                />
            )}
            {isDelete &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isDelete}
                    onSuccess={handleDelete}
                    onClose={() => setIsDelete(false)}
                />
            )}
        </ListItem>
    );
};

export default Item;
