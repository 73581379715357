import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    createRoadSection,
    deleteRoadSection,
    editRoadSection,
    setCloneFormRoadSection,
    setDeleteFormRoadSection,
    setEditFormRoadSection,
    setSavedRoadSection,
} from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import ConfirmDelete from 'components/common/ConfirmModal';
import EditorForm from 'components/pages/Dictionaries/RoadNetwork/RoadSections/EditorForm';
import messages from 'helpers/constants/messages';

// формы
const CollectorForms = () => {
    const dispatch = useDispatch();
    const deletedFormRoadSection = useSelector(roadNetworksSelectors.deletedFormRoadSection);
    const editedFormRoadSection = useSelector(roadNetworksSelectors.editedFormRoadSection);
    const clonedFormRoadSection = useSelector(roadNetworksSelectors.clonedFormRoadSection);
    const savedRoadSection = useSelector(roadNetworksSelectors.savedRoadSection);

    useEffect(() => {
        if (savedRoadSection) {
            dispatch(setSavedRoadSection(false));
        }
    }, [dispatch, savedRoadSection]);

    const variant = clonedFormRoadSection
        ? 'clone'
        : editedFormRoadSection?.id
            ? 'edit'
            : 'add';

    return (
        <>
            {/* форма редактирования */}
            {(!!editedFormRoadSection || !!clonedFormRoadSection) && (
                <EditorForm
                    isOpen={!!editedFormRoadSection || !!clonedFormRoadSection}
                    noPadding
                    isNew={!editedFormRoadSection?.id && !clonedFormRoadSection?.id}
                    isClone={!!clonedFormRoadSection?.id}
                    dataForm={editedFormRoadSection?.id ? editedFormRoadSection : clonedFormRoadSection}
                    onClose={() => {
                        const action = variant === 'clone' ? setCloneFormRoadSection : setEditFormRoadSection;
                        dispatch(action());
                    }}
                    onSuccess={(newFields) => {
                        if (!newFields['id'] || variant === 'clone') {
                            dispatch(createRoadSection(newFields, () => {
                                dispatch(setSavedRoadSection(true));
                                dispatch(setEditFormRoadSection());
                                dispatch(setCloneFormRoadSection());
                            }));
                        } else {
                            dispatch(editRoadSection(newFields.id, newFields, () => {
                                dispatch(setSavedRoadSection(true));
                                dispatch(setEditFormRoadSection());
                            }));
                        }
                    }}
                    inModal
                />
            )}

            {/* удаление */}
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={!!deletedFormRoadSection}
                onSuccess={() => {
                    dispatch(deleteRoadSection(deletedFormRoadSection, () => {
                        dispatch(setSavedRoadSection(true));
                    }));
                    dispatch(setDeleteFormRoadSection());
                }}
                onClose={() => {
                    dispatch(setDeleteFormRoadSection());
                }}
            />
        </>
    );
};

export default CollectorForms;
