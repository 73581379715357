import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

const SubscriptionModal = ({
    onClose = () => {},
    onAcceptModal = () => {},
    isOpen = false,
    isSubscribe = false
}) => {

    return (
        <Modal
            title={isSubscribe ? 'Отменить получение уведомлений об изменениях по данному объекту' : 'Получать уведомления об изменениях по данному объекту'}
            onClose={onClose}
            noPadding
            small
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        name: 'Нет',
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        name: 'Да',
                        onClick: onAcceptModal,
                    }
                ]}
            />}
        />
    );
};

export default SubscriptionModal;
