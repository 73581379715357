import { ChangeEvent, useState } from 'react';
import { TextField } from '@mui/material';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import type { FilterProp } from './types';


const Filters = ({ setParams }: FilterProp) => {
    const [filter, setFilter] = useState({
        name: ''
    });
    
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => setFilter({ name: e.target.value });

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams((prev) => ({ ...prev, page: 1, data: {} }));
        }

        setFilter({ name: '' });
    };

    const onSearch = () => {
        setParams((prev) => ({ ...prev, page: 1, data: filter }));
    };

    const updateFilter = (filters: { name: string }) => {
        setFilter(filters);

        setParams((prev) => ({ ...prev, page: 1, data: filters }));
    };

    return (
        <LayoutFilter
            filter={filter}
            onResetFilter={resetFilter}
            onSearch={onSearch}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filter?.name}
                    onChange={handleChange}
                    className="row__item"
                    variant="outlined"
                    size="small"
                    label="Поиск ключевых слов"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;