import { IconButton, InputAdornment, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import FormInfoWrapper from 'components/common/FormInfoWrapper';

const MetroInputField = ({
    label = 'Станции метрополитена',
    value,
    error,
    helperText,
    onReset,
}) => (
    <FormInfoWrapper
        error={error}
        helperText={helperText}
    >
        <TextField
            value={value}
            error={error}
            label={label}
            name="stations"
            fullWidth
            variant="outlined"
            size="small"
            readOnly
            InputProps={{
                endAdornment: (
                    <>
                        {value ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={onReset}
                                    edge="end"
                                    size="small"
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </InputAdornment>
                        ) : ''}
                    </>
                )
            }}
        />
    </FormInfoWrapper>
);

export default MetroInputField;
