export const roadNetworks = (state) => state.roadNetworks;

export const infrastructure = (state) => roadNetworks(state).infrastructure;
export const infrastructureData = (state) => infrastructure(state).data;
export const infrastructureMeta = (state) => infrastructure(state).meta;
export const loadingInfrastructure = (state) => roadNetworks(state).loadingInfrastructure;

export const loadingInfrastructureTypes = (state) => roadNetworks(state).loadingInfrastructureTypes;
export const infrastructureTypes = (state) => roadNetworks(state).infrastructureTypes;
export const infrastructureTypesData = (state) => infrastructureTypes(state).data;
export const infrastructureTypesMeta = (state) => infrastructureTypes(state).meta;

export const loadingRoadSections = (state) => roadNetworks(state).loadingRoadSections;

export const roadSections = (state) => roadNetworks(state).roadSections;
export const roadSectionsData = (state) => roadSections(state).data;
export const roadSectionsMeta = (state) => roadSections(state).meta;

export const polygonRoadSection = (state) => roadNetworks(state).polygonRoadSection;
export const loadingPolygonRoadSection = (state) => roadNetworks(state).loadingPolygonRoadSection;
export const sidebarRoadSection = (state) => roadNetworks(state).sidebarRoadSection;
export const sidebarRoadSectionData = (state) => sidebarRoadSection(state).data;
export const sidebarRoadSectionMeta = (state) => sidebarRoadSection(state).meta;
export const loadingSidebarRoadSection = (state) => roadNetworks(state).loadingSidebarRoadSection;
export const activeRoadSection = (state) => roadNetworks(state).activeRoadSection;
export const filtersRoadSection = (state) => roadNetworks(state).filtersRoadSection;
export const deletedFormRoadSection = (state) => roadNetworks(state).deletedFormRoadSection;
export const editedFormRoadSection = (state) => roadNetworks(state).editedFormRoadSection;
export const savedRoadSection = (state) => roadNetworks(state).savedRoadSection;
// export const statusesRoadSection = (state) => roadNetworks(state).statusesRoadSection;

export const editForm = (state) => roadNetworks(state).editForm;

export const allRelations = (state) => roadNetworks(state).roadSectionAllRelation;
export const relationTypes = (state) => roadNetworks(state).roadSectionRelationTypes;

export const overlapPolygon = (state) => roadNetworks(state).overlapPolygon;
export const loadingOverlapPolygon = (state) => roadNetworks(state).loadingOverlapPolygon;
export const filterOverlap = (state) => roadNetworks(state).filterOverlap;

export const overlapList = (state) => roadNetworks(state).overlapList;
export const overlapListData = (state) => overlapList(state).data;
export const overlapListMeta = (state) => overlapList(state).meta;
export const loadingOverlapList = (state) => roadNetworks(state).loadingOverlapList;

export const activeOverlap = (state) => roadNetworks(state).activeOverlap;

export const polygonInfrastructure = (state) => roadNetworks(state).polygonInfrastructure;
export const loadingPolygonInfrastructure = (state) => roadNetworks(state).loadingPolygonInfrastructure;
export const filterPolygonInfrastructure = (state) => roadNetworks(state).filterPolygonInfrastructure;
export const activePolygonInfrastructure = (state) => roadNetworks(state).activePolygonInfrastructure;
export const sidebarInfrastructure = (state) => roadNetworks(state).sidebarInfrastructure;
export const loadingSidebarInfrastructure = (state) => roadNetworks(state).loadingSidebarInfrastructure;
export const savedInfrastructure = (state) => roadNetworks(state).savedInfrastructure;

export const overlapTypes = (state) => roadNetworks(state).overlapTypes;
export const overlapSource = (state) => roadNetworks(state).overlapSource;

export const streetList = (state) => roadNetworks(state).streetList;
export const loadingStreetList = (state) => roadNetworks(state).loadingStreetList;

export const categoryTypes = (state) => roadNetworks(state).categoryTypes;
export const loadedCategoryTypes = (state) => roadNetworks(state).loadedCategoryTypes;

export const loadingRoadSectionRelation = (state) => roadNetworks(state).loadingRoadSectionRelation;

export const videoCamerasDictionaryInfrastructureTypes = (state) => roadNetworks(state).videoCamerasDictionaryInfrastructureTypes;
export const loadingVideoCamerasDictionaryInfrastructureTypes = (state) => roadNetworks(state).loadingVideoCamerasDictionaryInfrastructureTypes;

export const videoCamerasDictionaryCommutator = (state) => roadNetworks(state).videoCamerasDictionaryCommutator;
export const loadingVideoCamerasDictionaryCommutator = (state) => roadNetworks(state).loadingVideoCamerasDictionaryCommutator;

export const videoCamerasDictionaryConnectionLine = (state) => roadNetworks(state).videoCamerasDictionaryConnectionLine;
export const loadingVideoCamerasDictionaryConnectionLine = (state) => roadNetworks(state).loadingVideoCamerasDictionaryConnectionLine;

export const videoCamerasDictionaryConnection = (state) => roadNetworks(state).videoCamerasDictionaryConnection;
export const loadingVideoCamerasDictionaryConnection = (state) => roadNetworks(state).loadingVideoCamerasDictionaryConnection;

export const videoCamerasDictionaryElectricalOptionalSystem = (state) => roadNetworks(state).videoCamerasDictionaryElectricalOptionalSystem;
export const loadingVideoCamerasDictionaryElectricalOptionalSystem = (state) => roadNetworks(state).loadingVideoCamerasDictionaryElectricalOptionalSystem;

export const videoCamerasDictionaryDocument = (state) => roadNetworks(state).videoCamerasDictionaryDocument;
export const loadingVideoCamerasDictionaryDocument = (state) => roadNetworks(state).loadingVideoCamerasDictionaryDocument;

export const videoCamerasDictionaryPower = (state) => roadNetworks(state).videoCamerasDictionaryPower;
export const loadingVideoCamerasDictionaryPower = (state) => roadNetworks(state).loadingVideoCamerasDictionaryPower;

export const videoCamerasDictionaryReliance = (state) => roadNetworks(state).videoCamerasDictionaryReliance;
export const loadingVideoCamerasDictionaryReliance = (state) => roadNetworks(state).loadingVideoCamerasDictionaryReliance;

export const commutator = (state) => roadNetworks(state).commutators;
export const loadingCommutator = (state) => roadNetworks(state).loadingCommutator;
export const commutatorTypes = (state) => roadNetworks(state).commutatorTypes;

export const loadingButton = (state) => roadNetworks(state).loadingButton;

export const connectionLines = (state) => roadNetworks(state).connectionLines;
export const connectionLinesData = (state) => connectionLines(state).data;
export const connectionLinesMeta = (state) => connectionLines(state).meta;
export const loadingConnectionLines = (state) => roadNetworks(state).loadingConnectionLines;

export const cameras = (state) => roadNetworks(state).cameras;
export const camerasList = (state) => cameras(state).data;
export const camerasMeta = (state) => cameras(state).meta;
export const loadingCameras = (state) => roadNetworks(state).loadingCameras;

export const cameraTypes = (state) => roadNetworks(state).cameraTypes;
export const loadingCameraTypes = (state) => roadNetworks(state).loadingCameraTypes;

export const cameraModel = (state) => roadNetworks(state).cameraModel;
export const cameraModelList = (state) => cameraModel(state).data;
export const cameraModelMeta = (state) => cameraModel(state).meta;
export const loadingCameraModel = (state) => roadNetworks(state).loadingCameraModel;

export const cameraVendor = (state) => roadNetworks(state).cameraVendor;
export const cameraVendorList = (state) => cameraVendor(state).data;
export const cameraVendorMeta = (state) => cameraVendor(state).meta;
export const loadingCameraVendor = (state) => roadNetworks(state).loadingCameraVendor;

export const connectionPoints = (state) => roadNetworks(state).connectionPoints;
export const connectionPointsData = (state) => connectionPoints(state).data;
export const connectionPointsMeta = (state) => connectionPoints(state).meta;
export const loadingConnectionPoints = (state) => roadNetworks(state).loadingConnectionPoints;

export const pillars = (state) => roadNetworks(state).pillars;
export const pillarsData = (state) => pillars(state).data;
export const pillarsMeta = (state) => pillars(state).meta;
export const loadingPillars = (state) => roadNetworks(state).loadingPillars;

export const electricalOpticalSystems = (state) => roadNetworks(state).electricalOpticalSystems;
export const electricalOpticalSystemsData = (state) => electricalOpticalSystems(state).data;
export const electricalOpticalSystemsMeta = (state) => electricalOpticalSystems(state).meta;
export const loadingElectricalOpticalSystems = (state) => roadNetworks(state).loadingElectricalOpticalSystems;

export const pakVendors = (state) => roadNetworks(state).pakVendors;
export const pakVendorsData = (state) => pakVendors(state).data;
export const pakVendorsMeta = (state) => pakVendors(state).meta;
export const loadingPakVendors = (state) => roadNetworks(state).loadingPakVendors;

export const pakModels = (state) => roadNetworks(state).pakModels;
export const pakModelsData = (state) => pakModels(state).data;
export const pakModelsMeta = (state) => pakModels(state).meta;
export const loadingPakModels = (state) => roadNetworks(state).loadingPakModels;

export const PAKList = (state) => roadNetworks(state).PAKList;
export const PAKListData = (state) => PAKList(state).data;
export const PAKListMeta = (state) => PAKList(state).meta;
export const loadingPAKList = (state) => roadNetworks(state).loadingPAKList;

export const DVR = (state) => roadNetworks(state).DVR;
export const DVRData = (state) => DVR(state).data;
export const DVRMeta = (state) => DVR(state).meta;
export const loadingDVR = (state) => roadNetworks(state).loadingDVR;

export const infrastructureByPolygon = (state) => roadNetworks(state).infrastructureByPolygon;
export const loadingInfrastructureByPolygon = (state) => roadNetworks(state).loadingInfrastructureByPolygon;

export const infrastructureList = (state) => roadNetworks(state).infrastructureList;
export const loadingInfrastructureList = (state) => roadNetworks(state).loadingInfrastructureList;
export const activeInfrastructureLayer = (state) => roadNetworks(state).activeInfrastructureLayer;

export const polygonGeoItemRoadSection = (state) => roadNetworks(state).polygonGeoItemRoadSection;
