import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';

import { deleteConcentrationArea, editConcentrationArea } from 'redux/Incidents/actions';
import { clearTransportIncident, loadTransportIncidentById } from 'redux/SituationalPlans/actions';
import messages from 'helpers/constants/messages';
import { fullDateTimeWithTimeZone, } from 'helpers/date.config';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';
import { getItemToSave } from 'components/MapComponents/Layers/DtpFocus/helper';
import ConfirmModal from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import Loading from 'components/common/Loading';
import getAddressStringFromObject from 'components/common/Location/LoadAddressByCoords/getAddressStringFromObject';
import Modal from 'components/common/Modal';
import CircleStatus from 'components/common/CircleStatus';

import TIModalForm from '../../SituationalPlans/TransportIncidents/ModalForm';
import RenderContent from '../../SituationalPlans/TransportIncidents/RenderContent';
import ModalFormTI from '../../SituationalPlans/TransportIncidents/ModalForm';

import Info from './Info';
import ModalForm from './ModalForm';


const Item = ({ item, types = {}, statuses = {}, loadList, }) => {
    const dispatch = useDispatch();

    const ti = useSelector(({ SituationalPlans }) => SituationalPlans?.transportIncident || {});
    const tiLoading = useSelector(({ SituationalPlans }) => SituationalPlans?.loadingTransportIncident || false);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    const [addTIModal, setAddTIModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const [tiById, setTIById] = useState({});

    const handleAddTI = (e) => {
        e.stopPropagation();
        setAddTIModal(true);
    };

    const TiCallback = (data) => {
        if (!!data) {
            setTIById(data);
        } else {
            setIsEditMode(false);
        }
    };

    const handleReloadTI = () => {
        dispatch(loadTransportIncidentById(item?.transport_incident_id, TiCallback));
    };

    useEffect(() => {
        if (item?.transport_incident_id && (openViewModal || isEditMode)) {
            handleReloadTI();
        }
    }, [dispatch, item?.transport_incident_id, openViewModal, isEditMode]);

    useEffect(() => {
        if (openViewModal === false) {
            dispatch(clearTransportIncident());
        }
    }, [dispatch, openViewModal]);

    const statusRender = () => {
        if (item?.status) {
            const currentStatus = statuses?.get?.(item?.status) || {};

            return (
                <CircleStatus
                    title={currentStatus?.name || ''}
                    color={currentStatus?.color}
                />
            );
        }
    };

    return (
        <>
            <LSContentItem onClick={() => setOpenInfo(!openInfo)}>
                <LSContentColumn>
                    {statusRender()}
                </LSContentColumn>
                <LSContentColumn>
                    {item.id || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {types?.get?.(item.type)?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.address_text || getAddressStringFromObject(item.address) || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.transport_incident_id
                        ? <Button
                            size="small"
                            variant="text"
                            sx={{
                                textDecoration: 'underline',
                                minWidth: '10px',
                                display: 'inline-block',
                                fontSize: '1rem',
                                padding: '0 4px',
                            }}
                            onClick={(e) => {e.stopPropagation(); setIsEditMode(true);}}
                        >
                            {item?.transport_incident_id}
                        </Button>
                        : messages.NO_VALUE
                    }
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            item.transport_incident_id
                                ? {
                                    icon: <i className="far fa-info-circle" fontSize="inherit"/>,
                                    name: 'Просмотр плана мероприятий',
                                    onClick: () => setOpenViewModal(true),
                                }
                                : {
                                    icon: <i className="far fa-car-bump" fontSize="inherit"/>,
                                    name: 'Создать план мероприятий',
                                    onClick: handleAddTI,
                                    accessProp: 'is_create',
                                },

                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            }
                        ]}
                    />
                    <ActionMore
                        isOpen={openInfo}
                        // onClick={() => setOpenInfo(!openInfo)}
                    />
                </LSContentColumn>
            </LSContentItem>

            {(addTIModal) && (
                <TIModalForm
                    isOpen={addTIModal}
                    isNew={addTIModal}
                    isMkDtp
                    item={addTIModal
                        ? { // добавление
                            address_text: item.address_text,
                            address: item.address,
                            lat: item.lat,
                            lon: item.lon,
                            geometry: createPointGJ(item.lat, item.lon),
                            mkdtp_id: item.id,
                            source: 'МКДТП',
                        }
                        : { // редактирование
                            ...ti?.data,
                            mkdtp_id: ti?.data?.mkdtp_id || item.id,
                            source: ti?.data?.source || 'МКДТП',
                        }
                    }
                    onClose={() => {
                        setAddTIModal(false);
                    }}
                    reloadList={(tiData) => {
                        // добавить ти
                        if (addTIModal) {
                            dispatch(editConcentrationArea(
                                item.id,
                                getItemToSave({
                                    ...item,
                                    transport_incident_id: tiData.id,
                                    // status: 3,
                                    confirmed_at: item.confirmed_at || fullDateTimeWithTimeZone(new Date())
                                }),
                                () => {
                                    loadList();
                                }
                            ));
                        }
                    }}
                />
            )}
            {openEditModal
                && <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    loadList={loadList}
                    item={item}
                />
            }
            {openDeleteModal
                && <ConfirmModal
                    open={openDeleteModal}
                    onSuccess={() => dispatch(deleteConcentrationArea(item.id, () => loadList(true)))}
                    onClose={() => setOpenDeleteModal(false)}
                />
            }
            {openInfo
                && <Info
                    isOpen={openInfo}
                    dtpList={item.dtp_list}
                />
            }
            {openViewModal
                && <Modal
                    isOpen={openViewModal}
                    onClose={() => setOpenViewModal(false)}
                    title={'Просмотр плана мероприятий'}
                    size={'98%'}
                    buttons={<FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenViewModal(false)
                            }
                        ]}
                    />}
                >
                    {tiLoading && <div style={{ padding: '10px 0' }}><Loading circular/></div>}
                    {!!tiById?.id
                        ? <RenderContent data={[tiById]} reloadList={handleReloadTI} isMkDtp/>
                        : (!tiLoading && <h2>{messages.DATA_IS_NOT_FOUND}</h2>)
                    }
                </Modal>
            }
            {(isEditMode && tiById?.id)
                && <ModalFormTI
                    isOpen={isEditMode}
                    onClose={() => setIsEditMode(false)}
                    item={tiById}
                    reloadList={handleReloadTI}
                    loading={tiLoading}
                    isMkDtp
                />
            }
        </>
    );
};

export default Item;
