import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { usePrevious } from 'helpers/hooks';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import { durationFromObjectToFormat, fullDateTimeWithoutSeconds } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import { LSContainer, LSContentColumn, LSContentItem } from 'components/common/List';
import MapScroll from 'components/MapComponents/MapScroll';
import { loadDockEvents } from 'modules/TransportWater/redux/actions';

const List = ({ id }) => {

    const prevId = usePrevious(id);

    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [{ data, meta }, setData] = useState({ data: [], meta: {} });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPage(1);
    }, [id]);

    useEffect(() => () => {
        setLoading(false);
        setData({ data: [], meta: {} });
    }, []);

    useEffect(() => {
        if (!id) return;
        if (id !== prevId) {
            setPage(1);
        } else {
            const start_date = fullDateTimeWithTimeZone(getStartOf());
            const end_date = fullDateTimeWithTimeZone(getEndOf());
            dispatch(loadDockEvents(
                id,
                {
                    page,
                    limit,
                    start_date,
                    end_date,
                },
                setLoading,
                (newData) => {
                    setData((old) => ({
                        ...old,
                        ...newData,
                        data: page === 1
                            ? newData.data
                            : [
                                ...old.data,
                                ...newData.data
                            ]
                    }));
                }
            ));
        }
    }, [page, limit, id]);

    const handleScrollUpdate = () => {
        const { last_page = 0 } = meta;
        if (
            loading === false
            && page < last_page
        ) {
            setPage(page + 1);
        }
    };

    return (
        <>
            {loading && <Loading circular/>}

            {data?.length > 0
                ? (
                    <>
                        <h2 style={{ padding: '1rem 1rem 0', color: '#000' }}>ПРИШВАРТОВАННЫЕ КОРАБЛИ:</h2>
                        <MapScroll
                            onUpdate={handleScrollUpdate}
                            renderTrackVertical={({ style, ...props }) =>
                                <div
                                    {...props}
                                    style={{
                                        ...style,
                                        width: '6px',
                                        right: '2px',
                                        bottom: '2px',
                                        top: '2px',
                                        borderRadius: '3px',
                                        zIndex: 999,
                                    }}
                                />
                            }
                            renderThumbVertical={({ style, ...props }) =>
                                <div
                                    {...props}
                                    style={{
                                        ...style,
                                        position: 'relative',
                                        display: 'block',
                                        width: '100%',
                                        borderRadius: 'inherit',
                                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                        cursor: 'pointer'
                                    }}
                                />
                            }
                        >
                            <LSContainer
                                headers={[
                                    { title: 'Дата и время начала стоянки', width: '30%' },
                                    { title: 'Продолжительность', width: '20%' },
                                    { title: 'MMSI', width: '15%' },
                                    { title: 'Наименование', width: '20%' },
                                    { title: 'Позывной', width: '15%' },
                                    { title: '', isActions: true }
                                ]}
                            >

                                {data.map(({ id, start_at, vehicle = {}, end_at }) => (
                                    <LSContentItem key={id}>
                                        <LSContentColumn>{fullDateTimeWithoutSeconds(start_at)}</LSContentColumn>
                                        <LSContentColumn>
                                            {start_at && end_at
                                                ? durationFromObjectToFormat({ milliseconds: new Date(end_at) - new Date(start_at) }, 'm') || '0'
                                                : '0'
                                            }
                                        </LSContentColumn>
                                        <LSContentColumn>{vehicle.mmsi}</LSContentColumn>
                                        <LSContentColumn>{vehicle.name}</LSContentColumn>
                                        <LSContentColumn>{vehicle.short_name}</LSContentColumn>
                                        <LSContentColumn></LSContentColumn>
                                    </LSContentItem>
                                ))}

                            </LSContainer>
                        </MapScroll>
                    </>
                )
                : (
                    <div className="not-found" style={{ padding: '1rem' }}>{messages.NO_DATA}</div>
                )
            }
        </>
    );
};

export default List;
