import * as types from './types';

export const loadCountByMonths = (filter, fnCallback, fnLoading,) => ({
    type: types.LOAD_COUNT_BY_MONTH,
    payload: filter,
    fnLoading,
    fnCallback
});

export const loadCountByPeriods = (filter, fnCallback, fnLoading) => ({
    type: types.LOAD_COUNT_BY_PERIODS,
    payload: filter,
    fnLoading,
    fnCallback
});

// данные тепловой карты по регионам
export const loadHeatMapDistricts = (filter, fnCallback, fnLoading) => ({
    type: types.LOAD_HEAT_MAP_DISTRICTS,
    payload: filter,
    fnLoading,
    fnCallback
});

// Типы ДТП
export const loadedDtpTypesAll = (data) => ({
    type: types.LOADED_DTP_TYPES_ALL,
    payload: data
});

export const loadDtpTypes = (page = 1, limit = 25, data = {}) => ({
    type: types.LOAD_DTP_TYPES,
    payload: {
        page,
        limit,
        ...data,
    },
});

export const loadingDtpTypes = (bool = false) => ({
    type: types.LOADING_DTP_TYPES,
    payload: bool,
});

export const loadedDtpTypes = (data) => ({
    type: types.LOADED_DTP_TYPES,
    payload: data,
});

export const editDtpType = (params) => ({
    type: types.EDIT_DTP_TYPE,
    payload: params,
});

export const loadDtpList = (page = 1, limit = 25, data, replaceStore = true) => ({
    type: types.LOAD_DTP_LIST,
    payload: {
        page,
        limit,
        data,
        replaceStore
    },
});

export const loadedDtpList = (data, replaceStore = true) => ({
    type: types.LOADED_DTP_LIST,
    payload: {
        data,
        replaceStore,
    }
});

export const loading = (bool = false) => ({
    type: types.LOADING,
    payload: bool,
});

export const editDTPStatuses = (params, callback = () => {}) => ({
    type: types.EDIT_DTP_STATUSES,
    payload: params,
    callback
});

export const loadingEditStatus = (bool = false) => ({
    type: types.LOADING_EDIT_STATUS,
    payload: bool,
});

export const createNewDtp = (data, callback) => ({
    type: types.CREATE_NEW_DTP,
    payload: {
        data,
        callback
    },
});

export const saveDtp = (bool = false) => ({
    type: types.SAVE_DTP,
    payload: bool,
});

export const loadDtpStreets = (data) => ({
    type: types.LOAD_DTP_STREETS,
    payload: data
});

export const loadedDtpStreets = (data) => ({
    type: types.LOADED_DTP_STREETS,
    payload: data
});

export const loadingDtpStreets = (bool = false) => ({
    type: types.LOADING_DTP_STREETS,
    payload: bool
});

export const editDtp = (id, data, callback) => ({
    type: types.EDIT_DTP,
    payload: {
        id,
        data,
        callback
    },
});

export const loadDtpById = (id, fnCallback, fnLoading) => ({
    type: types.LOAD_DTP_BY_ID,
    payload: id,
    fnCallback,
    fnLoading,
});

export const loadedDtpById = (id) => ({
    type: types.LOADED_DTP_BY_ID,
    payload: id,
});