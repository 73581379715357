import { v4 as uuid_v4 } from 'uuid';

import titles from 'helpers/constants/titles';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import type { OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';

import Item from './Item';

interface RenderOperationListProps {
    data: OperationInScenario[];
}

const RenderOperationList = ({
    data,
}: RenderOperationListProps) => {

    return <>
        {data.length > 0
            ? <LSContainer
                headers={[
                    { title: <span>№</span>, width: '4%', align: 'center' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >{titles.NAME}</span>, width: '16%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >{titles.ORGANIZATION}</span>, width: '15%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Цикл</span>, width: '6%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Параллельное <br /> исполнение</span>, width: '12%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Время по регламенту (мин)</span>, width: '10%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Выполнять автомат.<br /> после регламента</span>, width: '14%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Передать в организацию автомат.</span>, width: '14%' },
                    { title: <span style={{ fontSize: '0.8rem', textAlign: 'center', width: '100%' }} >Типовая</span>, width: '6%' },
                ]}
            >
                {data.map((row) => (
                    <Item
                        key={uuid_v4()}
                        row={row}
                        readOnly
                    />
                ))}
            </LSContainer>
            : <NoData />
        }
    </>;
};

export default RenderOperationList;
