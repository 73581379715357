import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadPakModels } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import titles from 'helpers/constants/titles';

import ModalForm from './ModalForm';
import Item from './item';

import type { PakModelItem } from 'types/RoadNetwork';

const PakModel = () => {
    const dispatch = useDispatch();

    const data = useSelector(roadNetworksSelectors.pakModelsData);
    const meta = useSelector(roadNetworksSelectors.pakModelsMeta);
    const loading = useSelector(roadNetworksSelectors.loadingPakModels);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = useCallback(() => {
        dispatch(loadPakModels(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    return (
        <>
            <PageLayout
                header="Модель оборудования ПАК"

                informPanelProps={{
                    buttons: (
                        <FormButtons
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setOpenAddModal(true),
                            }]
                            }
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}

                loading={loading}

                content={() => data.length > 0
                    ? (
                        <LSContainer
                            headers={[
                                { title: titles.NAME, width: '30%' },
                                { title: 'Вендор', width: '30%' },
                                { title: 'Системный', width: '20%' },
                                { title: 'Действия', isActions: true },
                            ]}
                        >
                            {data.map((item: PakModelItem) => (
                                <Item
                                    key={item.id}
                                    item={item}
                                    reloadList={reloadList}
                                />
                            ))}
                        </LSContainer>
                    )
                    : !loading && <NoData/>
                }

                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default PakModel;
