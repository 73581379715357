import { TextField } from '@mui/material';

import DateRange from 'components/common/Dates/DateRange';
import SelectEdgeTypes from 'modules/DigitalTwin/utils/components/EdgeTypes';
import SelectEdgeClasses from 'modules/DigitalTwin/utils/components/EdgeClasses';

import type { FilterParamsType } from './types';

type FormProps = {
    data: FilterParamsType['data'];
    onChange: (a: { [key: string]: any }) => void;
};

const Form = ({
    data,
    onChange,
}: FormProps) => {

    const handleChange = (name: keyof FilterParamsType['data'], value: any) => {
        onChange({ [name]: value });
    };

    return (
        <div>
            <DateRange
                title="Дата создания"
                valueStartDate={data.from_created_at || null}
                valueEndDate={data.to_created_at || null}
                handleDate={(value) => handleChange('from_created_at', value)}
                handleEndDate={(value) => handleChange('to_created_at', value)}
            />

            <DateRange
                title="Дата изменения"
                valueStartDate={data.from_updated_at || null}
                valueEndDate={data.to_updated_at || null}
                handleDate={(value) => handleChange('from_updated_at', value)}
                handleEndDate={(value) => handleChange('to_updated_at', value)}
            />
            <div className="row">
                <SelectEdgeTypes
                    selected={data.type || null}
                    onChange={(value) => handleChange('type', value)}
                    className="row__item"
                />

                <SelectEdgeClasses
                    selected={data.class || null}
                    onChange={(value) => handleChange('class', value)}
                    className="row__item"
                />
            </div>
            <div className="row">
                <TextField
                    label="Название дороги"
                    size="small"
                    value={data.road_name}
                    variant="outlined"
                    name="road_name"
                    type="text"
                    onChange={({ target }) => handleChange('road_name', target.value)}
                    className="row__item"
                />

                <TextField
                    label="Номер дороги"
                    size="small"
                    value={data.road_number}
                    variant="outlined"
                    name="road_number"
                    type="text"
                    onChange={({ target }) => handleChange('road_number', target.value)}
                    className="row__item"
                />
            </div>
        </div>
    );
};

export default Form;