import { useState } from 'react';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import ConfirmModal from 'components/common/ConfirmModal';

const StatusButton = ({
    is_skip = false,
    onChange,
}) => {

    const [isModal, setIsModal] = useState(false);

    const handleDoChange = () => {
        onChange?.(!is_skip);
        setIsModal(false);
    };
    const handleChange = () => setIsModal(true);

    return (
        <>
            <span>Нет</span>
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.changeStatusIcon,
                        ...is_skip ? { icon: <i className="fas fa-toggle-off" /> } : {},
                        onClick: handleChange,
                    }
                ]}
                noMarginLeft
                justButton
            />
            <span>Да</span>

            {isModal && (
                <ConfirmModal
                    open={isModal}
                    onClose={() => setIsModal(false)}
                    onSuccess={handleDoChange}
                    message="Изменить учет в отчетности?"
                />
            )}
        </>
    );

};

export default StatusButton;
