import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import { TextField } from '@mui/material';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import removeEmptyFields from 'helpers/removeEmptyFields';


type FiltersProps = {
  setParams: Dispatch<SetStateAction<{ page: number, data: {} }>>;
}

interface FilterParams {
    road_name: string,
    road_number: string,
    from_created_at: Date | null,
    to_created_at: Date | null,
    from_updated_at: Date | null,
    to_updated_at: Date | null,
}

const initState: FilterParams = {
    road_name: '',
    road_number: '',
    from_created_at: null,
    to_created_at: null,
    from_updated_at: null,
    to_updated_at: null,
};

export const Filters = ({ setParams }: FiltersProps) => {
    const [filter, setFilter] = useState<FilterParams>(initState);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams(prev => ({ ...prev, page: 1, data: {} }));
        }

        setFilter(initState);
    };

    const onSearch = () => {
        const paramsWithoutEmpty = removeEmptyFields(filter);

        setParams(prev => ({ ...prev, page: 1, data: paramsWithoutEmpty }));
    };

    const updateFilter = (filters: { [key: string]: any }) => {
        setFilter({ ...initState, ...filters });
        const paramsWithoutEmpty = removeEmptyFields( { ...initState, ...filters });
        setParams(prev => ({ ...prev, page: 1, data: paramsWithoutEmpty }));
    };

    return (
        <LayoutFilter
            filter={filter}
            onResetFilter={resetFilter}
            onSearch={onSearch}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filter?.from_created_at || null}
                    valueEndDate={filter?.to_created_at || null}
                    startLabel="Дата создания - От"
                    endLabel="Дата создания - До"
                    handleDate={(from_created_at) => setFilter({ ...filter, from_created_at })}
                    handleEndDate={(to_created_at) => setFilter({ ...filter, to_created_at })}
                    equalDates={true}
                />
                <DateRange
                    valueStartDate={filter?.from_updated_at || null}
                    valueEndDate={filter?.to_updated_at || null}
                    startLabel="Дата изменения - От"
                    endLabel="Дата изменения - До"
                    handleDate={(from_updated_at) => setFilter({ ...filter, from_updated_at })}
                    handleEndDate={(to_updated_at) => setFilter({ ...filter, to_updated_at })}
                    equalDates={true}
                />
                <TextField
                    label={'Название дороги'}
                    variant="outlined"
                    size="small"
                    value={filter.road_name}
                    name="road_name"
                    onChange={onChange}
                />
                <TextField
                    label={'Номер дороги'}
                    variant="outlined"
                    size="small"
                    value={filter.road_number}
                    name="road_number"
                    onChange={onChange}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};
