import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar } from '@mui/material';

import { loadDtpById } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import tabsList from 'components/MapComponents/Layers/Incidents/PopUp/tabsList';
import Members from 'components/MapComponents/Layers/Incidents/PopUp/TabComponents/TabMembers';
import Vehicles from 'components/MapComponents/Layers/Incidents/PopUp/TabComponents/TabVehicles';
import Information from 'components/MapComponents/Layers/Incidents/PopUp/TabComponents/TabInfo';
import Situation from 'components/MapComponents/Layers/Incidents/PopUp/TabComponents/TabSituation';
import { CustomTab, CustomTabs } from 'components/common/Tabs';

import type { IncidentItem } from '../../types';

interface EventProps {
    item: IncidentItem | null;
}

const DTP = ({ item }: EventProps) => {
    const dispatch = useDispatch();

    const dtpData = useSelector(incidentsSelectors.dtpById);

    const [tabIndex, setTabIndex] = useState(tabsList[0].key);

    useEffect(() => {
        dispatch(loadDtpById(item?.id));
    }, [dispatch, item?.id]);

    return (
        <div>
            <AppBar position="relative" color="default" >
                <CustomTabs
                    onChange={(_, tab) => {
                        setTabIndex(tab);
                    }}
                    textColor="primary"
                    value={tabIndex}
                    variant="scrollable"
                >
                    {tabsList.map(({ key, title }) => (
                        <CustomTab
                            key={key}
                            id={`scrollable-force-tab-${key}`}
                            aria-controls={`scrollable-force-tabpanel-${key}`}
                            value={key}
                            label={title}
                        />
                    ))}
                </CustomTabs>
            </AppBar>

            <div
                role="tabpanel"
                id={`scrollable-force-tabpanel-${tabIndex}`}
                aria-labelledby={`scrollable-force-tab-${tabIndex}`}
                className="tab__container"
            >
                {tabsList.map(({ key }) => (
                    tabIndex === key && (
                        <div
                            style={{ height: '100%' }}
                            key={key}
                        >
                            {key === 'members'
                                ? <Members
                                    data={dtpData?.members || []}
                                    tag="strong"
                                />
                                : key === 'vehicles'
                                    ? <Vehicles
                                        data={dtpData?.vehicles || []}
                                        tag="strong"
                                    />
                                    : key === 'situation'
                                        ? <Situation data={dtpData} />
                                        : <Information
                                            readOnly
                                            data={dtpData}
                                        />
                            }
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default DTP;
