import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';

import { createTypicalOperation, editTypicalOperation } from 'modules/React/redux/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import formatDate from 'helpers/constants/dateFormat';
import { getFormatFromDate } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';

import type { OperationItem } from './types';

interface ModalFormProps {
    isOpen: boolean;
    onClose: (isOpen?: boolean) => void;
    isNew?: boolean;
    item?: OperationItem;
    reloadList: () => void;
}

const ModalForm = ({ isOpen, onClose, isNew = false, item = {}, reloadList }: ModalFormProps) => {
    const dispatch = useDispatch();

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }: { validation: { success: boolean } }) => validation);

    const [data, setData] = useState({
        name: item?.name || '',
        regulation_at: item?.regulation_at || '',
    });

    const closeForm = () => onClose(false);

    const onAcceptModal = () => {
        if (isNew) {
            dispatch(createTypicalOperation(data, reloadList));
        } else {
            dispatch(editTypicalOperation(item?.id || '', data, reloadList));
        }
    };

    const onChange = (name: string, value: string | Date | null) => {
        setData({
            ...data,
            [name]: value
        });
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose(false);
            validation.clear();
        }
    }, [requestStatus.success, onClose, validation]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: closeForm,
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: data.name === '' || validation.isValidation(),
                    },
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <FormInfoWrapper
                        error={!data.name || validation.isKey('name')}
                        helperText={validation.get('name')}
                    >
                        <TextField
                            label={titles.NAME}
                            size="small"
                            value={data.name}
                            variant="outlined"
                            name="name"
                            type="text"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.name, e.target.value)}
                            error={!data.name || validation.isKey('name')}
                            required
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        error={!data.regulation_at || validation.isKey('regulation_at')}
                        helperText={validation.get('regulation_at')}
                    >
                        <SingleKeyboardDateTimePicker
                            timeOnly
                            label={'Время по регламенту'}
                            value={data.regulation_at || null}
                            onChange={(e) => onChange('regulation_at', getFormatFromDate(e, formatDate.HH_MM))}
                            minTime={{ hour: 0, minute: 0, second: 0 }}
                            error={validation.isKey('regulation_at')}
                        />
                    </FormInfoWrapper>
                </div>
            </form>
        </Modal>
    );
};

export default ModalForm;
