import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

// данные
function* loadListSaga({ payload, callback }) {
    const { page } = payload;
    yield put(actions.setLoadingNotification(true));
    const response = yield call(api.fetchList, payload);

    if (typeof callback === 'function') {
        const response = yield call(api.fetchList, { ...payload, viewed: 0 });
        yield callback && callback(response);
    }

    if (response) {
        yield put(actions.loadedListNotification(response, page === 1));
    }
    yield put(actions.setLoadingNotification(false));
}

// загрузка списка для конкретного сервиса
function* loadServiceListSaga({ payload }) {
    const { microservice } = payload;
    yield put(actions.setLoadingNotification(true));
    const response = yield call(api.fetchList, payload);
    if (response?.success) {
        yield put(actions.loadedServiceListNotification(response, microservice));
    }
    yield put(actions.setLoadingNotification(false));
}

function* loadCountActiveSaga() {
    const response = yield call(api.fetchCountActive);
    if (response) {
        yield put(actions.loadedCountActiveNotification(response));
    }
}

function* changeReadSaga({ payload }) {
    const {
        id,
    } = payload;
    const response = yield call(api.setRead, id);
    if (response?.success) {
        yield put(actions.setReadNotification(id));
    }
}

function* changeReadAllSaga() {
    const response = yield call(api.setReadAll);
    if (response?.success) {
        yield put(actions.setReadAllNotification());
    }
}

function* loadFreeSpaceServerSaga({ payload }) {
    const response = yield call(api.loadFreeSpaceServer, payload);
    if (response) {
        yield put(actions.loadedFreeSpaceServer(response.data));
    }
}

function* reloadListSaga({ payload }) {
    const response = yield call(api.fetchList, payload);
    if (response) {
        yield put(actions.reloadedListNotification(response));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_LIST, loadListSaga),
        takeLatest(types.LOAD_COUNT_ACTIVE, loadCountActiveSaga),
        takeEvery(types.CHANGE_READ, changeReadSaga),
        takeLatest(types.CHANGE_READ_ALL, changeReadAllSaga),
        takeLatest(types.LOAD_SERVICE_LIST, loadServiceListSaga),
        takeLatest(types.LOAD_FREE_SPACE_SERVER, loadFreeSpaceServerSaga),
        takeLatest(types.RELOAD_LIST_NOTIFICATION, reloadListSaga),
    ]);
}
