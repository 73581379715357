import { useEffect } from 'react';
import L from 'leaflet';

import useMapLayers from '../helpers/useMapLayers';
import useMapCurrentLayer from '../helpers/useMapCurrentLayer';

import renderChild from './helper/renderChild';

// управление картой - слои, зум
const MapControl = ({
    children,
    map,
    ...prop
}) => {
    // все слои
    const mapLayersProvider = useMapLayers();
    // текущий слой
    const mapCurrentLayer = useMapCurrentLayer();

    L.changeLayerToStore = (layerId) => {
        mapLayersProvider.setLayer(parseInt(layerId));

        const options = mapLayersProvider
            .mapLayers
            ?.find(({ id }) => id === parseInt(layerId))?.options || {};
        const max = options?.maxZoom || 18;
        const min = options?.minZoom || 1;

        map.setMinZoom(min);
        map.setMaxZoom(max);

        // сообщаем кластеру что необходимо обновится - иначе ошибка
        map.fire('baselayerchange');
    };

    const generateKey = (item) => `
        <span class="layer-control__item${mapCurrentLayer?.id === item.id ? ' checked' : ''}" onClick="L.changeLayerToStore('${item.id}')">
            <img src="${item.image}"/> <span>${item.name}</span>
        </span>
    `;

    // TODO: переписать функцию т.к. это временное решение
    useEffect(() => {
        if (map) {
            map.on('layeradd', () => {
                const a = [];
                map.eachLayer((layer) => {
                    if (layer?._url) a.push(layer);
                });
                a.slice(0, -1).forEach(layer => map.removeLayer(layer));
            });

            return () => map.off('layeradd');
        }
    }, [map]);

    useEffect(() => {
        // преобразовываем в нужный объект для карты
        const layersData = mapLayersProvider
            .mapLayers
            ?.reduce((res, layer) => ({
                ...res,
                [generateKey(layer)]: L.tileLayer(layer.url, layer?.options || {}),
            }), {});

        // вывод слоев
        const layersControl = L.control.layers(layersData);

        // устанавливаем на карту контрол для слоев
        layersControl.addTo(map).getContainer().classList.add('map-control__layer');

        return () => {
            layersControl.remove();
        };
    }, [mapLayersProvider?.mapLayers, mapCurrentLayer]);

    useEffect(() => {
        const scaleControl = L
            .control
            .scale({
                position: 'bottomright',
                imperial: false,
            });

        scaleControl
            .addTo(map)
            .getContainer()
            .classList.add('map-control__layer');

        return () => {
            scaleControl.remove();
        };
    }, [map]);

    return renderChild(
        children,
        {
            map,
            ...prop,
        }
    );
};

export default MapControl;
