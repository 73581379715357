import type { Dispatch, ReactNode, SetStateAction } from 'react';

import titles from 'helpers/constants/titles';
import PassportTabsGrid from 'components/common/PassportTabs/PassportTabsGrid';
import PassportTabs from 'components/common/PassportTabs/PassportTabs';
import reactTitles from 'modules/React/utils/helpers/constants';
import HistoryList from 'components/common/HistoryList';
import Item from 'components/common/HistoryList/Item.js';
import { loadIncidentHistoryById } from 'modules/React/redux/actions';
import { HistoryItemInfo }  from 'modules/React/Dictionaries/Incidents/PassportPage/History/HistoryItemInfo';

import BasicData from './PassportPage/BasicData';
import Events from './PassportPage/Events';
import DTP from './PassportPage/DTP';
import Scenario from './PassportPage/Scenario';
import InfrastructureObjects from './PassportPage/InfrastructureObjects';

import type { FilterParams, Incident, ObjectTypes, } from './types';

interface PassportTabsProps {
    selectedItem: Incident | null;
    reloadList: (item?: boolean) => void; // либо передаем элемент (в попапе для обновления), либо true в случае удаления
    passportOnly?: boolean; // чтобы на карте открыть в модалке
    queryKey?: string;
    urlParams?: Record<string, number | null>;
    setUrlParams?: Dispatch<SetStateAction<{[key: string]: number | null; }>>;
    setParams?: Dispatch<SetStateAction<FilterParams>>
    renderList?: () => ReactNode;
    objectTypes: ObjectTypes;
    tabToOpen?: string;
    setTabToOpen?: Dispatch<SetStateAction<string>>;
}

export const RenderPassportTabs = ({
    selectedItem,
    reloadList,
    passportOnly = false,
    queryKey,
    urlParams = {},
    setUrlParams = () => {},
    setParams = () => {},
    renderList = () => {},
    objectTypes,
    tabToOpen,
    setTabToOpen
}: PassportTabsProps) => {

    const tabs = [
        {
            value: 'data',
            label: titles.BASIC_DATA,
            icon: <i className="fal fa-info-square"/>,
            contentComponent: <BasicData
                item={selectedItem as Incident}
                onEdited={reloadList}
                onDeleted={() => reloadList(true)}
                objectTypes={objectTypes}
            />,
        },
        {
            value: 'events',
            label: titles.EVENTS,
            icon: <i className="far fa-car-bump"/>,
            contentComponent: <Events
                item={selectedItem as Incident}
            />,
        },
        {
            value: 'scenario',
            label: reactTitles.SCENARIO,
            icon: <i className="far fa-bezier-curve"/>,
            contentComponent: <Scenario
                item={selectedItem}
                onEdited={reloadList}
            />,
        },
        ...(selectedItem?.objects?.length
            ? [{
                value: 'infrastructure',
                label: titles.INFRASTRUCTURE_OBJECTS,
                icon: <i className="far fa-warehouse-alt"/>,
                contentComponent: <InfrastructureObjects
                    item={selectedItem as Incident}
                    objectTypes={objectTypes}
                />,
            }]
            : []
        ),
        ...(selectedItem?.dtp_id
            ? [{
                value: 'dtp',
                label: titles.DTP,
                icon: <i className="far fa-car-crash"/>,
                contentComponent: <DTP dtp_id={selectedItem?.dtp_id} />,
            }]
            : []
        ),
        {
            value: 'history',
            label: titles.HISTORY_OF_CHANGES,
            icon: <i className="fal fa-history"/>,
            // contentComponent: <History item={selectedItem}/>
            contentComponent: <HistoryList
                storeName="react"
                storeProp="incidentHistory"
                loadingProp="loadingIncidentHistory"
                loadAction={loadIncidentHistoryById}
                id={selectedItem?.id}
                ItemInfo={HistoryItemInfo}
            />
        },
    ];

    return (<>
        {passportOnly
            ? <PassportTabs tabs={tabs} initialTab={tabToOpen} resetInitialTab={setTabToOpen} />
            : <PassportTabsGrid
                initialTab={tabToOpen}
                resetInitialTab={setTabToOpen}
                tabs={tabs}
                selectedItem={!!selectedItem?.id}
                queryKey={queryKey}
                urlParams={urlParams}
                setUrlParams={setUrlParams}
                //TODO - придумать как избавиться от any
                setParams={setParams as any}
                renderList={renderList}
            />
        }
    </>
    );
};
