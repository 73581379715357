import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Divider,
    Grid,
    Typography,
} from '@mui/material';

import {
    clearActiveBoardMessage,
    deleteBoard,
    fetchActiveBoardMessage,
    loadBoardStatuses,
} from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages/common';
import useStoreFromSelector from 'helpers/hooks/useStoreFromSelector';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import ConfirmDelete from 'components/common/ConfirmModal';

import AddEditModal from '../Modal';

import ActiveMessage from './ActiveMessage';
import MsgSource from './MsgSource';
import ShowOnMap from './ShowOnMap';

function BasicData({ data, reloadList }) {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isShowActiveMessage, setIsShowActiveMessage] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

    const handleConfirmDelete = () => {
        dispatch(deleteBoard(data.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    const handleClickToShowActiveBoardMessage = () => {
        setIsShowActiveMessage(true);
        dispatch(fetchActiveBoardMessage(data?.id));
    };

    const closeActiveBoardMessageModalForm = () => {
        setIsShowActiveMessage(false);
        dispatch(clearActiveBoardMessage());
    };

    const statuses = useStoreFromSelector(loadBoardStatuses, boardsSelectors.boardStatuses);

    return (
        <div className="tab__container">
            <Grid container spacing={1} justifyContent="center" alignItems="flex-start">
                <Grid item xs={6}>
                    <Typography>
                        <b>{titles.STATUS}: </b>
                        {data?.status
                            ? <strong style={{ color: statuses.find(i => i.id === data?.status)?.color }}>
                                {data?.status_text}
                            </strong>
                            : <span>{messages.NO_DATA}</span>
                        }
                    </Typography>
                    <Divider style={{ margin: '5px 0' }}/>
                    <div className="filter filter__wrap__btn filter__btn__column">
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.editIcon,
                                    onClick: () => setOpenEditModal(true),

                                },
                                {
                                    ...buttonsTypes.deleteIcon,
                                    onClick: () => setOpenDeleteModal(true),
                                }
                            ]}
                        />
                    </div>
                    <Typography style={{ marginTop: 5 }}>
                        <b>{titles.NAME}:</b> {data?.name || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography>
                        <b>{titles.STATUS}:</b> {data?.status_text || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography>
                        <b>{titles.SPEED_LIMIT}:</b> {data?.speed_limit || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography>
                        <b>{titles.CATEGORY}:</b> {data?.category_text || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography>
                        <b>{titles.TYPE}:</b> {data?.type_text || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography>
                        <b>{titles.ADDRESS}:</b> {data?.address_text || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography>
                        <b>{titles.LAT}:</b> {data?.lat || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Typography>
                        <b>{titles.LON}:</b> {data?.lon || messages.INFO_IS_NOT_FOUND}
                    </Typography>
                    <Divider style={{ margin: '10px 0' }}/>
                    <Typography>
                        <b>Адрес для получения сообщений: </b>
                        <span>
                            {data?.callback || messages.INFO_IS_NOT_FOUND}
                        </span>
                    </Typography>
                    <FormButtonsComponent
                        positionLeft
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.defaultSecondary,
                                name: 'Активное сообщение',
                                onClick: handleClickToShowActiveBoardMessage
                            }
                        ]}
                    />
                    <Divider style={{ margin: '10px 0' }}/>
                    <MsgSource board={data} />
                </Grid>
                <Grid item xs={6}>
                    <ShowOnMap latlon={[data?.lat, data?.lon]}/>
                </Grid>
            </Grid>
            {openEditModal && (
                <AddEditModal
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    reloadList={reloadList}
                    el={data}
                    statuses={statuses}
                    isNew={false}
                />
            )}
            {isShowActiveMessage && (
                <ActiveMessage
                    isOpen={isShowActiveMessage}
                    onClose={closeActiveBoardMessageModalForm}
                    title={data?.name}
                />
            )}
            {isOpenDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={isOpenDeleteModal}
                    onSuccess={handleConfirmDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </div>
    );
}

export default BasicData;
