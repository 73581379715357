import {
    createIcon,
    MapPopUp,
} from 'components/MapComponents/MapPopUp';
import MapScroll from 'components/MapComponents/MapScroll';

import { getMarkerColor, getMarkerIcon } from '../helper';

import Content from './Content';
import Buttons from './Buttons';

const PopUpModal = ({
    isOpen,
    data,
    onClose,
}) => {

    const {
        uuid = '',
        is_regular = false,
    } = data || {};

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={createIcon(getMarkerIcon)}
            title={`${uuid}${is_regular ? ' (Регулярное)' : ''}`}
            titleBackgroundColor={getMarkerColor}
            onClose={onClose}
            small
            buttons={<Buttons data={data}/>}
        >
            <MapScroll>
                <Content data={data} />
            </MapScroll>
        </MapPopUp>
    );
};

export default PopUpModal;
