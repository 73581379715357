import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { loadBoardTypes } from 'redux/Boards/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages/common';
import removeEmptyFields from 'helpers/removeEmptyFields';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const Filter = ({ setParams, categories }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const initialState = {
        name: '',
        type: '',
        serial_number: '',
        address_text: '',
        uuid: [],
        category_id: ''
    };

    const [data, setData] = useState(initialState);
    const [types, setTypes] = useState({});

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value
        });
    };

    const prepareData = (filter = data) => removeEmptyFields({
        ...filter,
    });

    useEffect(() => {
        if (data.category_id) {
            dispatch(loadBoardTypes({ category_id: data.category_id }, setTypes));
        }
    }, [data.category_id]);

    const onSearch = () => {
        setParams({ page: 1, data: prepareData() });
        history.replace({ pathname: '/dictionaries/board' });
    };

    const resetFilter = (needRefresh) => {
        if(needRefresh){
            setParams({ page: 1, data: prepareData(initialState) });
            history.replace({ pathname: '/dictionaries/board' });
        }
        setData(initialState);
    };

    const updateFilter = (filters) => {
        setData({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({
                page: 1,
                data: {
                    ...removeEmptyFields(initialState),
                    ...prepareData(filters)
                }
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={onSearch}
            filter={data}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={data.name}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={({ target }) => onChange(target.name, target.value)}
                />

                <TextField
                    label={titles.SERIAL_NUMBER}
                    size="small"
                    value={data.serial_number}
                    variant="outlined"
                    name="serial_number"
                    type="text"
                    onChange={({ target }) => onChange(target.name, target.value)}
                />
                <FormControl size="small" variant="outlined" >
                    <InputLabel>{titles.CATEGORY}</InputLabel>
                    <Select
                        value={data.category_id}
                        onChange={({ target }) => onChange(target.name, target.value)}
                        label={titles.CATEGORY}
                        name="category_id"
                    >
                        {Object.keys(categories)?.length
                            ? Object.keys(categories)?.map((key) =>
                                <MenuItem value={key} key={key}>{categories[key]}</MenuItem>
                            )
                            : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                        }
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <FormInfoWrapper helperText="Выберите Категорию">
                    <FormControl size="small" variant="outlined">
                        <InputLabel>{titles.TYPE}</InputLabel>
                        <Select
                            value={data.type}
                            label={titles.TYPE}
                            onChange={({ target }) => onChange(target.name, target.value)}
                            name="type"
                            disabled={!data.category_id}
                        >
                            {Object.keys(types)?.length
                                ? Object.keys(types)?.map((key) =>
                                    <MenuItem value={key} key={key}>{types[key]}</MenuItem>
                                )
                                : <MenuItem defaultValue="">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>

                <MultipleInput
                    value={data?.uuid}
                    onChange={(e) => onChange('uuid', e) }
                    label="UUID"
                />

                <TextField
                    label={titles.ADDRESS}
                    size="small"
                    value={data.address_text}
                    variant="outlined"
                    name="address_text"
                    type="text"
                    onChange={({ target }) => onChange(target.name, target.value)}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
