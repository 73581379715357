import { memo } from 'react';
import { Handle, Position } from 'reactflow';

export default memo(({ data, isConnectable }: any) => {
    return (
        <>
            <Handle
                type="target"
                position={Position.Left}
                style={{ background: '#000' }}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <Handle
                type="source"
                position={Position.Right}
                style={{ background: '#000' }}
                isConnectable={isConnectable}
            />
            <span>{data?.label}</span>
            <Handle
                type="source"
                position={Position.Right}
                id="bottom-right"
                style={{ bottom: 0, top: 'auto', background: '#000' }}
                isConnectable={isConnectable}
            />
            <Handle
                type="target"
                position={Position.Left}
                id="bottom-left"
                style={{ bottom: 0, top: 'auto', background: '#000' }}
                isConnectable={isConnectable}
            />
        </>
    );
});
