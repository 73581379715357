import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import Information from 'components/common/Information';

const PropertiesTab = ({ marker }) => {
    return (
        <Information
            data={marker.vehicle}
            title={{
                mmsi: {
                    title: 'MMSI',
                },
                imo: {
                    title: 'ИМО номер судна',
                },
                distance_to_stern: {
                    title: 'Размер',
                },
                distance_to_bow: {
                    title: 'Размер до кормы',
                },
                distance_to_port: {
                    title: 'Расстояние до порта',
                },
                distance_to_starboard: {
                    title: 'Размер по правому борту',
                },
                position_fixation_type_text: {
                    title: 'Тип фиксации положения',
                },
                arrival_time: {
                    title: 'Время прибытия',
                    value: ({ arrival_time }) => fullDateTimeWithoutSeconds(arrival_time),
                },
                draught: {
                    title: 'Осадка (дм)',
                },
                destination: {
                    title: 'Пункт назначения',
                },
            }}
            defaultValue={messages.NO_VALUE}
            returnNull
        />
    );
};

export default PropertiesTab;
