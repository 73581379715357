import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadRoadSectionRelation } from 'redux/RoadNetwork/actions';
import InformPanel from 'components/layout/PageLayout/InformPanel';

import FormButtonsComponent, { buttonsTypes } from '../FormButtons';

import type { Address } from 'types/common';

type Relation = {
    entity_type_text: string;
    entity_data: {
        name: string;
        address_text: string;
        address: Address;
    };
};

type MapRelation = {
    address: Address;
    address_text: string;
    name: string;
};

interface RoadSectionRelationListProps {
    roadSectionId: number;
    isMap?: boolean;
    openClick?: boolean;
    readOnly?: boolean;
}

const RoadSectionRelationList = ({
    roadSectionId,
    isMap,
    openClick,
    readOnly,
}: RoadSectionRelationListProps) => {
    const dispatch = useDispatch();

    const [relations, setRelations] = useState<Relation[]>([]);

    useEffect(() => {
        let isMounted = true; // сделано для предотвращения утечки памяти

        if (roadSectionId) {
            dispatch(
                loadRoadSectionRelation(
                    { road_section_id_list: [roadSectionId] },
                    (data: Relation[]) => {
                        if (isMounted) setRelations(data);
                    }
                )
            );
        }

        return () => {
            isMounted = false;
        };
    }, [dispatch, roadSectionId]);

    const renderAddress = (address: Address) => {
        if (!address?.area) return 'Адрес отсутвует';
        return `${address?.area}, ${address?.city_name}, ${address?.district}, ${address?.street}`;
    };

    const sortFunc = <T, K extends keyof T>(arr: T[] = [], option: K) => {
        return arr.sort((a, b) => {
            if (a[option] > b[option]) {
                return 1;
            } else if (a[option] < b[option]) {
                return -1;
            }
            return 0;
        });
    };

    const uniqueRelations: string[] = [
        ...new Set(relations?.map(({ entity_type_text }) => entity_type_text)),
    ];

    const reducedRelations = uniqueRelations.reduce((acc, item) => {
        const filteredRelations = relations?.filter(
            ({ entity_type_text }) => entity_type_text === item
        );
        const mapedRelations: MapRelation[] = filteredRelations.map((el) => ({
            address: el.entity_data.address,
            address_text: el.entity_data.address_text,
            name: el.entity_data.name,
        }));
        const sorted = sortFunc(mapedRelations, 'name');

        acc.push({
            entity_type_text: item,
            data: sorted,
        });
        return acc;
    }, [] as { entity_type_text: string; data: MapRelation[] }[]);

    const renderRelations = () => {
        const sortRelations = sortFunc(reducedRelations, 'entity_type_text');

        return sortRelations?.map((item, i) => (
            <div key={`${item.entity_type_text}_${i}`}>
                {isMap ? (
                    <p>
                        <strong>{item.entity_type_text}:</strong>
                    </p>
                ) : (
                    <h3>{item.entity_type_text}:</h3>
                )}

                {item.data?.map((el, i) => (
                    <div key={i} style={{ marginLeft: 10 }}>
                        {i + 1}. {el.name};{' '}
                        {el.address_text ? el.address_text : renderAddress(el.address)}
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <div style={isMap ? { marginLeft: 15 } : {}}>
            {isMap ? <h4>Присвоенные объекты:</h4> : <h2>Присвоенные объекты:</h2>}
            {!isMap && !readOnly && (
                <InformPanel
                    buttons={
                        <FormButtonsComponent
                            noPadding
                            noMarginLeft
                            buttons={[
                                {
                                    ...buttonsTypes.change,
                                    color: 'infoButton',
                                    onClick: openClick,
                                },
                            ]}
                        />
                    }
                />
            )}
            <div style={{ marginLeft: 10 }}>
                {relations?.length > 0 ? renderRelations() : 'Нет присвоенных объектов'}
            </div>
        </div>
    );
};

export default RoadSectionRelationList;
