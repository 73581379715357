import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteKeyWord } from 'redux/SituationalPlans/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import ModalAddEdit from './ModalAddEdit';
import type { ItemProp } from './types';


const Item = ({ data, reloadList }: ItemProp) => {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const onAccept = () => dispatch(deleteKeyWord(data?.id, () => {
        reloadList(true);
        setConfirmOpen(false);
    }));

    return (
        <>
            <LSContentItem>
                <LSContentColumn>{data?.name}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setModalOpen(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setConfirmOpen(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            
            {confirmOpen && (
                <ConfirmDelete
                    onClose={() => setConfirmOpen(false)}
                    onSuccess={onAccept}
                    open={confirmOpen}
                />
            )}

            {modalOpen && (
                <ModalAddEdit
                    onClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                    data={data}
                    reloadList={reloadList} 
                />
            )}
        </>

    );
};

export default Item;
