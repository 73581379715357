import { Collapse } from '@mui/material';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import Attachments from 'components/common/Upload/Attachments';
import { getHumanDate } from 'helpers/date.config';

import type { ParkingArea } from './types';

interface InfoProps {
    isOpen: boolean;
    item: ParkingArea;
}

const Info = ({ isOpen, item }: InfoProps) => {
    return (
        <Collapse in={isOpen} timeout="auto" className="info">
            <div>
                <strong>{titles.CREATED_AT}: </strong>
                <span>{getHumanDate(item?.created_at) || messages.NO_DATA}</span>
            </div>
            <div>
                <strong>Контакты: </strong>
                <span>{item.contacts || messages.NO_DATA}</span>
            </div>
            <div>
                <strong>Количество общих мест: </strong>
                <span>{item.spaces?.common ?? messages.NO_DATA}</span>
            </div>
            <div>
                <strong>Количество мест для инвалидов: </strong>
                <span>{item.spaces?.handicapped ?? messages.NO_DATA}</span>
            </div>
            <div>
                <strong>Тариф: </strong>
                <span>{item.tariff?.number || messages.NO_DATA}</span>
            </div>
            <div>
                <strong>Перехватывающая: </strong>
                <span>{item.is_aggregating ? 'Да' : 'Нет'}</span>
            </div>
            <div>
                <strong>Заблокирована: </strong>
                <span>{item.is_blocked ? 'Да' : 'Нет'}</span>
            </div>
            <div>
                <strong>{titles.DESCRIPTION}: </strong>
                <span>{item.description || messages.NO_DATA}</span>
            </div>
            <Attachments
                files={!!item?.photo ? [item?.photo] : []}
                readOnly
                renderContent={null}
                divider={false}
            />
        </Collapse>
    );
};

export default Info;
