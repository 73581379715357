import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteLibraryAPI } from 'redux/Admin/actions';
import ConfirmDelete from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';


import Form from './Form';

const LibraryAPIItem = ({ item, reloadList }) => {
    const dispatch = useDispatch();
    const [modalVisible, showModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const clickEdit = (e) => {
        e.stopPropagation();
        showModal(true);
    };

    const clickDelete = (e) => {
        e.stopPropagation();
        setOpenDeleteModal(true);
    };

    const openLinkInNewTab = (url) => {
        const otherWindow = window.open();
        otherWindow.opener = null;
        otherWindow.location = url;
    };

    return (
        <>
            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onSuccess={() => {
                        dispatch(deleteLibraryAPI(item.id, () => {
                            reloadList();
                            setOpenDeleteModal(false);
                        }));
                    }}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
            {modalVisible
                && <Form
                    isOpen={modalVisible}
                    item={item}
                    onClose={() => showModal(false)}
                    reloadList={reloadList}
                />
            }
            <LSContentItem>
                <LSContentColumn>
                    {item?.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.microservice || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item?.url ? <span onClick={() => openLinkInNewTab(item.url)}>{item.url}</span> : messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: clickEdit,
                                accessProp: ''
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: clickDelete,
                                accessProp: ''
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
        </>
    );
};

export default LibraryAPIItem;
