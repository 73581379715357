import React, { useState } from 'react';
import { TextField } from '@mui/material';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';

import type { FilterData } from './types';

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{page: number, data: {}}>>
    test_id_prefix: string
}

const initialState: FilterData = { name: '' };

const Filter = ({ setParams, test_id_prefix }: FilterProps) => {
    const [data, setData] = useState(initialState);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setData(prev => ({ ...prev, [name]: value }));
    };

    const onSearch = (filters = data) => {
        setParams({ page: 1, data: removeEmptyFields(filters) });
    };

    const resetFilter = (needRefresh: boolean) => {
        needRefresh && onSearch(initialState);
        setData(initialState);
    };

    const updateFilter = (filters: FilterData) => {
        const newFilter = { ...initialState, ...filters };
        setData(newFilter);

        if (Object.keys(filters).length > 0) {
            onSearch(newFilter);
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={onSearch}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
            disabled={!data.name.trim()}
            test_id_prefix={test_id_prefix}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={data.name}
                    onChange={handleChange}
                    label={titles.NAME}
                    name="name"
                    inputProps={{
                        ...{ 'data-testid': `${test_id_prefix}:name/input` },
                    }}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
