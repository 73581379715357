import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import {
    createTicketPoint,
    editTicketPoint,
    loadTicketPointTypes
} from 'redux/TransportPassenger/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import TextMaskPhone from 'components/common/TextMaskPhone';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import InfoBlock from 'components/common/InfoBlock';

import type { SelectChangeEvent } from '@mui/material';
import type { Ticket } from './types';

interface AddEditModalProps {
    isOpen: boolean;
    onClose: () => void;
    reloadList: () => void;
    isNew?: boolean;
    data?: Ticket;
}

function AddEditModal({ isOpen, isNew, onClose, data, reloadList }: AddEditModalProps) {
    const dispatch = useDispatch();
    const validation = useValidation();

    const [formValues, setFormValues] = useState({
        type: data?.type || '',
        address_text: data?.address_text || '',
        address: data?.address || {},
        name: data?.name || '',
        phone: data?.phone || '',
        mode: data?.mode || '',
        lat: data?.address?.geo_lat || '',
        lon: data?.address?.geo_lon || '',
    });

    const ticketPointTypes = useStoreProp(
        loadTicketPointTypes,
        'transportPassenger',
        'ticketPointTypes'
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
        const { target: { name, value } } = event;

        setFormValues({ ...formValues, [name]: value });
        validation.deleteKey(name);
    };

    const handleChangeAddress = (params: unknown) => {
        if (typeof params === 'object') {
            setFormValues({ ...formValues, ...params });
        }
    };

    const save = () => {
        const { lat, lon, ...result } = formValues;

        const callback = () => {
            onClose();
            reloadList();
        };

        isNew
            ? dispatch(createTicketPoint(result, callback))
            : dispatch(editTicketPoint(data?.id, result, callback));
    };

    const isButtonDisabled = !Object.keys(formValues.address).length
        || !formValues.type
        || (+formValues?.type !== 1
            && (
                !formValues.mode
                || !formValues.phone
                || !formValues.name
                || !formValues.address_text)
        );

    return (
        <Modal
            title={isNew ? 'Добавить пункт продажи билетов' : 'Редактировать пункт продажи билетов'}
            onClose={onClose}
            noPadding={true}
            isOpen={isOpen}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isButtonDisabled,
                        onClick: save
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper
                    error={!formValues.type || validation.isKey('type')}
                    helperText={validation.get('type')}
                    className="block noMargin"
                >
                    <FormControl
                        required={true}
                        variant="outlined"
                        size="small"
                        error={!formValues.type || validation.isKey('type')}
                    >
                        <InputLabel>{titles.TYPE}</InputLabel>
                        <Select
                            label={titles.TYPE}
                            value={String(formValues.type)}
                            name="type"
                            onChange={handleChange}
                        >
                            {Object.keys(ticketPointTypes)?.length > 0
                                ? Object.keys(ticketPointTypes)?.map(key =>
                                    <MenuItem key={key} value={key}>
                                        {ticketPointTypes[key]}
                                    </MenuItem>
                                )
                                : <MenuItem value=" ">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>

                <div className="row">
                    <FormInfoWrapper
                        error={(+formValues?.type !== 1 && !formValues.name) || validation.isKey('name')}
                        helperText={validation.get('name')}
                        className="row__item"
                    >
                        <TextField
                            value={formValues.name}
                            required={+formValues?.type !== 1}
                            variant="outlined"
                            size="small"
                            name="name"
                            label={titles.NAME}
                            onChange={handleChange}
                            error={(+formValues?.type !== 1 && !formValues.name) || validation.isKey('name')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper
                        error={(+formValues?.type !== 1 && !formValues.mode) || validation.isKey('mode')}
                        helperText={validation.get('mode')}
                        className="row__item"
                    >
                        <TextField
                            value={formValues.mode}
                            required={+formValues?.type !== 1}
                            variant="outlined"
                            size="small"
                            name="mode"
                            label={titles.MODE}
                            onChange={handleChange}
                            error={(+formValues?.type !== 1 && !formValues.mode) || validation.isKey('mode')}
                        />
                    </FormInfoWrapper>
                </div>

                <FormInfoWrapper
                    error={(+formValues?.type !== 1 && !formValues.phone) || validation.isKey('phone')}
                    helperText={validation.get('phone')}
                    className="block"
                >
                    <TextField
                        required={+formValues?.type !== 1}
                        label={titles.PHONE_NUMBER}
                        error={(+formValues?.type !== 1 && !formValues.phone) || validation.isKey('phone')}
                        value={formValues.phone}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        name="phone"
                        InputProps={{
                            // @ts-ignore
                            inputComponent: TextMaskPhone,
                        }}
                    />
                </FormInfoWrapper>

                <InfoBlock label={titles.LOCATION} className="block">
                    <div className="block noMargin">
                        <LoadAddressByCoords
                            {...formValues}
                            onChange={handleChangeAddress}
                        >
                            <AddressList
                                required={undefined}
                                returnGeometry={undefined}
                            />
                        </LoadAddressByCoords>
                    </div>

                    <div className="block">
                        <MapDragMarker
                            {...formValues}
                            onChange={handleChangeAddress}
                        />
                    </div>
                </InfoBlock>
            </form>
        </Modal>
    );
}

export default AddEditModal;
