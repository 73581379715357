import { moduleName } from 'modules/DigitalTwin/module-config';

const prefix = `${moduleName}`;

export const LOAD_NODES = `${prefix}/LOAD_NODES`;
export const LOADED_NODES = `${prefix}/LOADED_NODES`;
export const LOADING_NODES = `${prefix}/LOADING_NODES`;

export const CREATE_NODES = `${prefix}/CREATE_NODES`;
export const EDIT_NODES = `${prefix}/EDIT_NODES`;
export const DELETE_NODES = `${prefix}/DELETE_NODES`;

export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;
export const LOAD_POLYGON = `${prefix}/LOAD_POLYGON`;
export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;

export const LOAD_TYPES = `${prefix}/LOAD_TYPES`;
export const LOADED_TYPES = `${prefix}/LOADED_TYPES`;

export const SET_SAVED = `${prefix}/SET_SAVED`;

export const LOAD_POPUP = `${prefix}/LOAD_POPUP`;

export const SET_DELETE_FORM = `${prefix}/SET_DELETE_FORM`;
export const SET_EDIT_FORM = `${prefix}/SET_EDIT_FORM`;

export const LOAD_EDGES = `${prefix}/LOAD_EDGES`;
export const LOADING_EDGES = `${prefix}/LOADING_EDGES`;
export const LOADED_EDGES = `${prefix}/LOADED_EDGES`;

export const LOAD_EDGES_BY_ID = `${prefix}/LOAD_EDGES_BY_ID`;
export const LOAD_EDGES_INFO_BY_ID = `${prefix}/LOAD_EDGES_INFO_BY_ID`;
export const LOAD_NODE_BY_ID = `${prefix}/LOAD_NODE_BY_ID`;

export const LOAD_EDGE_TYPES = `${prefix}/LOAD_EDGE_TYPES`;
export const LOADING_EDGE_TYPES = `${prefix}/LOADING_EDGE_TYPES`;
export const LOADED_EDGE_TYPES = `${prefix}/LOADED_EDGE_TYPES`;

export const LOAD_EDGE_CLASSES = `${prefix}/LOAD_EDGE_CLASSES`;
export const LOADING_EDGE_CLASSES = `${prefix}/LOADING_EDGE_CLASSES`;
export const LOADED_EDGE_CLASSES = `${prefix}/LOADED_EDGE_CLASSES`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const CREATE_EDGE = `${prefix}/CREATE_EDGE`;
export const EDIT_EDGE = `${prefix}/EDIT_EDGE`;
export const DELETE_EDGE = `${prefix}/DELETE_EDGE`;

export const LOAD_OBJECT_BINDINGS = `${prefix}/LOAD_OBJECT_BINDINGS`;
export const LOAD_OBJECT_BINDINGS_ALL = `${prefix}/LOAD_OBJECT_BINDINGS_ALL`;
export const LOAD_OBJECT_BINDINGS_NEARLY = `${prefix}/LOAD_OBJECT_BINDINGS_NEARLY`;
export const CREATE_OBJECT_BINDINGS = `${prefix}/CREATE_OBJECT_BINDINGS`;
export const UPDATE_OBJECT_BINDINGS = `${prefix}/UPDATE_OBJECT_BINDINGS`;
export const DELETE_OBJECT_BINDINGS = `${prefix}/DELETE_OBJECT_BINDINGS`;
