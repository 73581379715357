import { ListItem, ListSubheader } from '@mui/material';

import Visible from './Visible';

const HeaderItem = ({
    children,
    top = '0', // ноль чтобы при прокрутке заголовок не убегал
    isMobile = false
}) => {
    return (
        <Visible
            variant="desktop"
            isMobile={isMobile}
        >
            <ListSubheader
                style={{
                    top,
                    padding: 0,
                    zIndex: 2
                }}
                className="header"
            >
                <ListItem component="div">
                    {children}
                </ListItem>
            </ListSubheader>
        </Visible>
    );
};

export default HeaderItem;
