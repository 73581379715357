import { useState } from 'react';
import { Checkbox, TextField, Tooltip } from '@mui/material';

import messages from 'helpers/constants/messages';
import { useStoreFromSelector } from 'helpers/hooks';
import CircleStatus from 'components/common/CircleStatus';
import SelectCompany from 'components/common/Autocomplete/Companies';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import { reactSelectors } from 'modules/React';
import { loadOperationTypes } from 'modules/React/redux/actions';
import type { Operation, OperationInScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';
import SelectTypicalOperations from 'modules/React/utils/components/SelectTypicalOperations';
import reactTitles from 'modules/React/utils/helpers/constants';

interface ItemProps {
    row: OperationInScenario;
    isEditable?: boolean;
    onAdd?: () => void;
    onEdit?: (row: OperationInScenario) => void;
    onDelete?: () => void;
    disableDelete?: boolean
    readOnly?: boolean
}

const Item = ({
    row,
    isEditable = false,
    onAdd = () => {},
    onEdit = () => {},
    onDelete = () => {},
    disableDelete = false,
    readOnly = false
}: ItemProps) => {
    const types = useStoreFromSelector(loadOperationTypes, reactSelectors.operationTypes);

    const [openDelete, setOpenDelete] = useState(false);

    const [data, setData] = useState(row);

    const handleChange = (field: string, value: any)  => {
        setData(({ ...data, [field]: value }));
    };

    const isCondition = row.group?.includes('.');


    return <>
        <LSContentItem>
            <LSContentColumn>
                <Tooltip title={types[row?.type] || ''}>
                    <span style={{ cursor: 'pointer' }}>{row?.position}</span>
                </Tooltip>
            </LSContentColumn>

            <LSContentColumn style={{ display: row.group?.includes('.') ? 'flex' : 'block', alignItems: 'center', width: '100%', }}>
                {row.group?.includes('.') && (
                    <CircleStatus
                        title={row.group.endsWith('.1') ? 'Если выполнено' : 'Если отклонено'}
                        color={row.group.endsWith('.1') ? 'green' : 'red'}
                        styles={{ width: '10px', height: 10, marginBottom: 2, marginRight: 2, flex: 'none' }}
                    />
                )}
                {isEditable
                    ? row?.is_typical
                        ? <SelectTypicalOperations
                            selected={row as Operation || null}
                            onChange={(value: any) => {
                                onEdit({ ...row,
                                    // operation: value,
                                    operation_id:  value?.id || null,
                                    name: value?.name || '',
                                    time:  value ? (value.regulation_at || '').split(':').reduce((h: string, m: string) => +h * 60 + +m) : null
                                });

                            }}
                            required
                            error={!!row.is_typical && !row.name}
                            style={{ flex: 1 }}
                            label={reactTitles.TYPICAL_OPERATION}
                        />
                        : <TextField
                            value={data?.name || ''}
                            onChange={e => handleChange('name', e.target.value)}
                            fullWidth
                            multiline
                            maxRows={2}
                            // disabled={row?.is_typical}
                            error={data?.name === ''}
                            onBlur={(e) => onEdit({
                                ...row,
                                name: data?.name
                            })}
                            label={'Нетиповая операция'}
                        />
                    : row?.name || <span style={{ color: 'red' }}>{messages.NO_DATA}</span>
                }
            </LSContentColumn>

            <LSContentColumn>
                {isEditable
                    ? <SelectCompany
                        selected={row?.organization}
                        onChange={(value: any)=> {
                            onEdit({
                                ...row,
                                organization: value,
                                organization_id: value?.id
                            });
                        }}
                        error={!row?.organization?.id}
                    />
                    : row.organization?.title || <span style={{ color: 'red' }}>{messages.NO_DATA}</span>
                }
            </LSContentColumn>

            <LSContentColumn>
                {isEditable && !isCondition
                    ? <TextField
                        value={data?.cycle || ''}
                        onChange={(e) => {
                            const value = Number(e.target.value);
                            if (value >= 2 || e.target.value === '') {
                                handleChange('cycle', e.target.value);
                            }
                        }}
                        fullWidth
                        type="number"
                        inputProps={{ min: 2 }}
                        onBlur={(e) => onEdit({
                            ...row,
                            cycle: data?.cycle
                        })}
                    />
                    : row?.cycle || messages.NO_VALUE
                }
            </LSContentColumn>

            <LSContentColumn align="center">
                {row.parallel && row.parallel.length > 0 && <span>{row.parallel?.join(', ') || ''}</span>}
                <Checkbox
                    disabled={!isEditable || isCondition}
                    checked={row?.is_parallel}
                    onChange={e => onEdit({
                        ...row,
                        is_parallel: e.target.checked
                    })}
                />
            </LSContentColumn>

            <LSContentColumn align="center">
                {isEditable
                    ? <TextField
                        value={data?.time || ''}
                        onChange={e => handleChange('time', e.target.value)}
                        fullWidth
                        type="number"
                        inputProps={{ min: 0 }}
                        onBlur={(e) => onEdit({
                            ...row,
                            time: data?.time
                        })}
                    />
                    : row?.time || messages.NO_VALUE
                }
            </LSContentColumn>

            <LSContentColumn align="center">
                <Checkbox
                    disabled={!isEditable}
                    checked={row?.is_auto}
                    onChange={e => onEdit({
                        ...row,
                        is_auto: e.target.checked
                    })}
                />
            </LSContentColumn>

            <LSContentColumn align="center">
                <Checkbox
                    disabled={!isEditable}
                    checked={row?.is_transfer}
                    onChange={e => onEdit({
                        ...row,
                        is_transfer: e.target.checked
                    })}
                />
            </LSContentColumn>

            <LSContentColumn align="center">
                <Checkbox
                    checked={row.is_typical}
                    onChange={(e) => onEdit({
                        ...row,
                        is_typical: e.target.checked,
                        operation_id: null,
                        name: '',
                        time: null,
                    })
                    }
                    name="is_typical"
                    disabled={!isEditable}
                />

            </LSContentColumn>

            {!readOnly && (
                <LSContentColumn isActions>
                    {isEditable
                        && <FormButtons
                            noMarginLeft
                            justButton
                            buttons={[
                                {
                                    ...buttonsTypes.addIcon,
                                    onClick: onAdd,
                                },
                                {
                                    ...buttonsTypes.deleteIcon,
                                    onClick: () => setOpenDelete(true),
                                    disabled: disableDelete,

                                },
                            ]}
                        />
                    }
                </LSContentColumn>
            )}
        </LSContentItem>

        {openDelete && (
            <ConfirmDelete
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                onSuccess={onDelete}
            />
        )}
    </>;
};

export default Item;
