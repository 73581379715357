import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { clearActiveBoardMessage, fetchActiveBoardMessage } from 'redux/Boards/actions';
import { setSelectedLayer } from 'redux/Menu/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import InfoTabs from 'components/pages/Dictionaries/Boards/BoardAndSigns/InfoTabs';
import ActiveMessage from 'components/pages/Dictionaries/Boards/BoardAndSigns/BasicData/ActiveMessage';


// кнопки в модалке
const ModalButtons = ({
    data,
}) => {

    const dispatch = useDispatch();

    const [isShowActiveMessage, setIsShowActiveMessage] = useState(false);
    const [openBoardTab, setOpenBoardTab] = useState(false);

    const closeActiveBoardMessageModalForm = () => {
        setIsShowActiveMessage(false);
        dispatch(clearActiveBoardMessage());
    };

    return (
        <>
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.defaultPrimary,
                        size: 'small',
                        name: 'Основные данные',
                        onClick: () => {
                            dispatch(setSelectedLayer());
                            // history && history.push(`/dictionaries/board/${data?.id}`);
                            setOpenBoardTab('data');
                        }
                    },
                    {
                        ...buttonsTypes.defaultPrimary,
                        size: 'small',
                        name: 'Список сообщений',
                        onClick: () => {
                            setOpenBoardTab('messages');
                        }
                    },
                    {
                        ...buttonsTypes.defaultSecondary,
                        size: 'small',
                        name: 'Активное сообщение',
                        onClick: () => {
                            setIsShowActiveMessage(true);
                            dispatch(fetchActiveBoardMessage(data?.id));
                        }
                    }
                ]}
            />

            {!!openBoardTab && (
                <Modal
                    isOpen={!!openBoardTab}
                    onClose={() => setOpenBoardTab(null)}
                    noPadding={true}
                    title={data?.name}
                    fullWidth
                    showCloseInTitle
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenBoardTab(null)
                            },
                        ]}
                    />}
                >
                    <InfoTabs
                        selectedItem={data}
                        tab={openBoardTab}
                    />
                </Modal>
            )}

            {isShowActiveMessage && (
                <ActiveMessage
                    title={data?.name}
                    onClose={closeActiveBoardMessageModalForm}
                    isOpen={isShowActiveMessage}
                />
            )}
        </>
    );
};

export default ModalButtons;
