import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import Clear from '@mui/icons-material/Clear';

import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

import SelectChannel from './SelectChannel';

import type { Alert } from '../../types';

interface FilterState {
    query: string;
    channel_list: Alert[];
    start_date: Date | null;
    end_date: Date | null;
}

const initState: FilterState = {
    query: '',
    channel_list: [],
    start_date: null,
    end_date: null,
};

interface FilterProps {
    setParams: React.Dispatch<
        React.SetStateAction<{
            page: number;
            limit: number;
            filter: {};
            props: { viewed?: number };
        }>
    >;
}

const Filter = ({ setParams }: FilterProps) => {
    const [filterState, setFilterState] = useState(initState);

    const handleChange = (key: string, value: unknown) => {
        setFilterState({ ...filterState, [key]: value });
    };

    const search = () => {
        const packageData = removeEmptyFields({
            ...filterState,
            start_date: filterState.start_date
                ? fullDateTimeWithTimeZone(filterState.start_date)
                : '',
            end_date: filterState.start_date ? fullDateTimeWithTimeZone(filterState.end_date) : '',
            channel_list: filterState.channel_list.map((item) => item.name),
        });

        setParams((prev) => ({
            ...prev,
            page: 1,
            filter: packageData,
        }));
    };

    const resetSearch = (needReload: boolean) => {
        setFilterState(initState);

        if (needReload) {
            setParams((prev) => ({
                ...prev,
                page: 1,
                filter: {},
            }));
        }
    };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton onClick={() => handleChange('query', '')} size="small">
                <Clear />
            </IconButton>
        </InputAdornment>
    );

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={search}
            filter={filterState}
            setUserFilter={() => {}}
            isHideSaveBtn
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChange('start_date', value)}
                    handleEndDate={(value) => handleChange('end_date', value)}
                    valueStartDate={filterState.start_date}
                    valueEndDate={filterState.end_date}
                />

                <TextField
                    value={filterState.query}
                    onChange={(e) => handleChange('query', e.target.value)}
                    label="Текст сообщений"
                    variant="outlined"
                    size="small"
                    InputProps={filterState.query ? { endAdornment } : {}}
                />

                <SelectChannel
                    selected={filterState.channel_list}
                    onChange={(value) => handleChange('channel_list', value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
