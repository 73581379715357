import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { notificationsSelectors } from 'redux/Notifications';
import { useNotifications } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';

import Filter from './Filter';
import Item from './Item';

import type { ItemTypes } from './types';

const OperatorLog = () => {
    const microservice = 'control';
    const notifications = useNotifications({ microservice });
    const loading = useSelector(notificationsSelectors.loading);
    const serviceList = useSelector(notificationsSelectors.serviceList);
    const list = serviceList?.[microservice] || {};

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({});

    const fetchList = (page = 1, limit: number | undefined, params: any) => notifications.loadServiceList(page, limit, params);

    const renderContent = () => (
        list.data?.length > 0
            ? <LSContainer
                headers={[
                    { title: '', width: '50px' },
                    { title: titles.DATE, width: '10%' },
                    { title: titles.TIME, width: '10%' },
                    { title: titles.INFORMATION, width: '76%' },
                ]}
            >
                {list.data.map((item: ItemTypes) => <Item key={item.id} item={item} />)}
            </LSContainer>
            : !loading && <NoData/>
    );

    useEffect(() => {
        fetchList(1, limit, params);
    }, [limit, params]);

    return (
        <PageLayout
            header="Журнал оператора"
            filters={<Filter setParams={setParams} />}
            informPanelProps={{ total: list?.meta?.total }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page, limit) => fetchList(page, limit, params),
                list: list?.meta || {},
                limit: limit,
                setLimit: setLimit,
            }}
        />
    );
};

export default OperatorLog;
