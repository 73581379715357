import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';

import { dorisControlSelectors } from 'redux/DorisControl';
import { editDuploCodes } from 'redux/DorisControl/actions';
import Context from 'helpers/context';
import RenderDuploCodesItem from 'components/common/DorisControl/RenderDuploCodesItem';
import ConfirmModal from 'components/common/ConfirmModal';
import { LSContentColumn, LSContentItem } from 'components/common/List';

import type { DuploCodesItem } from './types';

interface ItemProps {
    item: DuploCodesItem;
    reloadList: () => void;
    test_id_prefix: string;
}

const Item = ({ item, reloadList, test_id_prefix }: ItemProps) => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const loadingButton = useSelector(dorisControlSelectors.loadingButton);

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handleChangeIsOTK = () => {
        const callback = () => {
            setOpenConfirmModal(false);
            reloadList();
        };

        dispatch(editDuploCodes(item?.id, { is_otk: !item.is_otk }, callback));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    <Checkbox
                        color={'success'}
                        checked={item?.is_otk}
                        onChange={() => permissions?.is_update && setOpenConfirmModal(true)}
                        data-testid={`${test_id_prefix}:is_otk/checkbox`}
                    />
                </LSContentColumn>

                <LSContentColumn>
                    <RenderDuploCodesItem item={item} test_id_prefix={test_id_prefix} />
                </LSContentColumn>
            </LSContentItem>

            {openConfirmModal && (
                <ConfirmModal
                    open={openConfirmModal}
                    onClose={() => setOpenConfirmModal(false)}
                    onSuccess={handleChangeIsOTK}
                    loadingButton={loadingButton}
                    message={`Вы уверены, что ${item?.is_otk ? 'не' : ''} хотите использовать этот код ДУПЛО в ОТК?`}
                />
            )}
        </>
    );
};

export default Item;
