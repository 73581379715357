import { Scatter } from 'react-chartjs-2';

export const RenderScatter = ({ data }) => {
    const getLabel = (context) => {
        const label = context?.formattedValue || '';
        const count = context?.dataset?.count || '';
        return ` ${label}${label && '%'} (${count}${count && 'шт.'})`;
    };

    const toolTipTitle = (tooltipItems) => {
        return tooltipItems[0]?.dataset.title;
    };
    const options = {
        maintainAspectRatio: false,
        indexAxis: 'x',
        redraw: false,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    label: (context) => getLabel(context, ),
                    title: toolTipTitle
                }
            },
            legend: {
                align: 'left',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                }
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: '%'
                }
            },
        },
        elements: {
            point: {
                radius: 8,
                pointStyle: 'circle'
            }
        }
    };

    const renderData = data?.map((item, i) => {
        const label = `${item.dtp_type_name} - ${item.percent || 0}% (${item.count || 0})`;
        return {
            data: [{ y: item.percent, x: item.count }],
            maxBarThickness: 40,
            label,
            backgroundColor: item.dtp_type_color || '#000',
            borderColor: '#fff',
            count:  item.count,
            title: item.dtp_type_name,
            fill: true,
        };
    });

    return (
        <div
            style={{
                maxWidth: '900px',
                margin: '0 auto',
                paddingBottom: '1rem'
            }}
        >
            <Scatter
                width={'500px'}
                height={'500px'}
                data={{
                    labels: data?.map(({  count, }) => `${count || 0}`),
                    datasets: renderData
                }}
                options={options}
                // plugins={[ChartDataLabels]}
                type="Line"
            />
        </div>
    );
};



