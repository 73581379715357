import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { dorisControlSelectors } from 'redux/DorisControl';
import { loadMaterials } from 'redux/DorisControl/actions';
import Context from 'helpers/context';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ModalForm from './ModalForm';
import Item from './Item';
import Filter from './Filter';

import type { ItemType } from './types';

const test_id_prefix = '/dictionaries/doris-control/materials';

const Materials = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const data = useSelector(dorisControlSelectors.materialsData);
    const meta = useSelector(dorisControlSelectors.materialsMeta);
    const loading = useSelector(dorisControlSelectors.loadingMaterials);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openAddModal, setOpenAddModal] = useState(false);

    const reloadList = useCallback(() => {
        dispatch(loadMaterials(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);
    
    return (
        <>
            <PageLayout
                header="Материалы"

                filters={<Filter setParams={setParams} test_id_prefix={test_id_prefix}/>}
            
                informPanelProps={{
                    buttons: permissions?.is_create && (
                        <FormButtonsComponent
                            buttons={[{
                                ...buttonsTypes.add,
                                onClick: () => setOpenAddModal(true),
                            }]
                            }
                            positionLeft
                            noPadding
                            test_id_prefix={test_id_prefix}
                        />
                    ),
                    total: meta?.total
                }}

                loading={loading}

                content={() => data?.length > 0
                    ? (
                        <LSContainer
                            headers={[
                                { title: 'Коды ДУПЛО', width: '45%' },
                                { title: 'Типы комплексов', width: '20%' },
                                { title: 'Коллаж', width: '15%' },
                                { title: 'Видео', width: '10%' },
                                { title: 'Действия', isActions: true }
                            ]}
                        >
                            {data?.map((item: ItemType) => (
                                <Item
                                    key={item?.id}
                                    item={item}
                                    reloadList={reloadList}
                                    permissions={permissions}
                                    test_id_prefix={test_id_prefix}
                                />
                            ))}
                        </LSContainer>
                    )
                    : !loading && <NoData/>
                }

                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit,
                    setLimit
                }}
            />

            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                    test_id_prefix={test_id_prefix}
                />
            )}
        </>
    );
};

export default Materials;
