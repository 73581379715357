import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import Actions from 'modules/DigitalTwin/Dictionaries/Edges/Actions';
import { getTypeText } from 'modules/DigitalTwin/utils/helpers';
import messages from 'helpers/constants/messages';
import DataTable from 'components/common/DataTable';
import titles from 'helpers/constants/titles';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { loadEdges } from 'modules/DigitalTwin/redux/actions';
import { digitalTwinSelectors } from 'modules/DigitalTwin';
import type { Edge } from 'modules/DigitalTwin/utils/types';
import AddEditEdge from 'modules/DigitalTwin/utils/components/AddEditEdge';

import { Filter } from './Filter';
import Info from './Info';

import type { Meta } from 'types/pagination';
import type { FilterParamsType } from './types';

function Edges() {
    const dispatch = useDispatch();

    const list: Edge[] = useSelector(digitalTwinSelectors.edgesData);
    const meta: Meta = useSelector(digitalTwinSelectors.edgesMeta);
    const loading = useSelector(digitalTwinSelectors.loadingEdges);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState<FilterParamsType>({ page: 1, data: {} });

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        dispatch(loadEdges({ page: params.page, per_page: limit, ...params.data }));
    }, [dispatch, limit, params]);

    const reloadList = () =>
        dispatch(loadEdges({ page: params.page, per_page: limit, ...params.data }));

    const renderContent = () => {
        if (list.length === 0) return <NoData />;

        return (
            <DataTable
                data={list}
                renderInfo={(item) => <Info item={item} />}
                columns={[
                    {
                        id: 'name',
                        accessorKey: 'name',
                        header: titles.NAME,
                        cell: (info) => info.getValue() || messages.NO_VALUE,
                    },
                    {
                        id: 'created_at',
                        accessorKey: 'created_at',
                        header: titles.DATE_TIME_CREATED,
                        cell: (info) =>
                            getFullDateTimeWithTextMonth(info.getValue()) || messages.NO_VALUE,
                    },
                    {
                        id: 'type',
                        accessorKey: 'type',
                        header: titles.TYPE,
                        cell: (info) => getTypeText(info.getValue() as string) || messages.NO_VALUE,
                    },
                    {
                        id: 'actions',
                        header: titles.ACTIONS,
                        enableHiding: false,
                        cell: (info) => (
                            <Actions
                                item={info.row.original}
                                onReload={reloadList}
                                toggleInfo={info.row.getToggleExpandedHandler()}
                            />
                        ),
                    },
                ]}
            />
        );
    };

    return (
        <>
            <PageLayout
                header="Рёбра"
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                },
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total,
                }}
                content={renderContent}
                loading={loading}
                filters={<Filter setParams={setParams} />}
                paginationProps={{
                    loadList: (page) => setParams((prev) => ({ ...prev, page })),
                    list: meta,
                    limit,
                    setLimit,
                }}
            />

            {openModal && (
                <AddEditEdge
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    reloadList={reloadList}
                />
            )}
        </>
    );
}

export default Edges;
