import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    IconButton,
    TextField,
    Tooltip
} from '@mui/material';

import { createDtpLink, editDtpLink } from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import SelectDtpLinkType from '../SelectDtpLinkType';
import SelectDtpLinkStatus from '../SelectDtpLinkStatus';
import SelectDtpMap from '../SelectDtpMap';

const LinkFormModal = ({
    isOpen,
    data,
    onClose,
    onSave,
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialFields = {
        ...data,
        dtp_id: data?.dtp_id || 0,
        link_type: data?.link_type || 1,
        linked_dtp_id: data?.linked_dtp_id || 0,
        status: data?.status || 1,
        dtp: data?.dtp || {},
        linked_dtp: data?.linked_dtp || {},
    };

    const [fields, setFields] = useState(initialFields);
    const [showDtp, setShowDtp] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (key) => (value) => {
        setFields(old => ({ ...old, [key]: value }));
        validation.deleteKey(key);
    };

    const handleSaved = () => {
        onClose?.();
        onSave?.();
    };

    const handleSave = () => {
        dispatch(
            fields?.id
                ? editDtpLink(fields.id, fields, handleSaved, setLoading)
                : createDtpLink(fields, handleSaved, setLoading)
        );
    };

    const isDisabled = !fields.dtp_id
        || !fields.linked_dtp_id
        || !fields.status
        || !fields.link_type;

    const RenderDtp = ({ fieldKey, label = '', dtp, onSelect, disabled }) => (
        <FormInfoWrapper
            className="block"
            helperText={validation.get(fieldKey)}
            error={validation.isKey(fieldKey)}
        >
            <TextField
                size="small"
                variant="outlined"
                label={label}
                value={dtp && dtp.id ? `(${dtp.id}) - ${dtp.address_text}` : 'Нет данных'}
                disabled
                InputProps={{
                    endAdornment: (
                        <>
                            {onSelect && (
                                <Tooltip title="Выбрать ДТП">
                                    <IconButton
                                        disabled={!fields.dtp}
                                        size="small"
                                        onClick={() => setShowDtp(true)}
                                    >
                                        <i className="fas fa-arrow-right"/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    )
                }}
            />
        </FormInfoWrapper>
    );

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={`${!fields?.id ? titles.ADD : titles.EDIT} связь`}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            disabled: isDisabled,
                            loading,
                        },
                    ]}
                />
            }
        >
            <form autoComplete="off" className="modal__form">

                <RenderDtp
                    label="Основное дтп"
                    dtp={fields.dtp}
                    fieldKey="dtp"
                />

                <RenderDtp
                    label="Связанное дтп"
                    dtp={fields.linked_dtp}
                    onSelect={()=> setShowDtp(true)}
                    disabled={!fields.dtp}
                    fieldKey="linked_dtp"
                />

                <div className="block">
                    <SelectDtpLinkType
                        value={fields.link_type}
                        onChange={handleChange('link_type')}
                        error={validation.isKey('link_type')}
                        helperText={validation.get('link_type')}
                    />
                </div>

                <div className="block">
                    <SelectDtpLinkStatus
                        value={fields.status}
                        onChange={handleChange('status')}
                        error={validation.isKey('status')}
                        helperText={validation.get('status')}
                    />
                </div>

                {showDtp && (
                    <SelectDtpMap
                        isOpen={showDtp}
                        value={fields.linked_dtp || {}}
                        mainDtp={fields.dtp}
                        onSave={(newData) => {
                            setFields(old => ({
                                ...old,
                                linked_dtp_id: newData.id,
                                linked_dtp: newData,
                            }));
                            validation.deleteKey('linked_dtp');
                            validation.deleteKey('linked_dtp_id');
                        }}
                        onClose={() => setShowDtp(false)}
                    />
                )}

            </form>
        </Modal>
    );
};

export default LinkFormModal;
