import { Scrollbars } from 'react-custom-scrollbars';
import { List, ListItem, ListItemText } from '@mui/material';

import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import { diffString } from 'helpers/diffString';

const WarningsInfo = ({ warnings = {} }) => {
    const dataForRender = () => {
        return Object.entries(warnings).reduce((res, [key, value]) => {
            if (value.is_warning) {
                if (key === 'address') {
                    const addressDiff = diffString(value?.complex_address, value?.event_address);
                    res.push({
                        description: value?.description,
                        data: [
                            { title: 'адрес (в системе)', value: addressDiff.str1  },//value?.complex_address
                            { title: 'адрес (комплекс)', value: addressDiff.str2  },//value?.event_address
                        ],
                        key
                    });
                }
                if (key === 'coords') {
                    const camera_lat = prepareCoords(value?.camera_lat) || messages.NO_DATA;
                    const camera_lon = prepareCoords(value?.camera_lon) || messages.NO_DATA;
                    const event_lat = prepareCoords(value?.event_lat) || messages.NO_DATA;
                    const event_lon = prepareCoords(value?.event_lon) || messages.NO_DATA;

                    const coordsDiff = diffString(`${camera_lat}, ${camera_lon}`, `${event_lat}, ${event_lon}`);

                    res.push({
                        description: value?.description,
                        data: [
                            {
                                title: 'камера',
                                value:  coordsDiff.str1 //`${value?.camera_lat ? parseFloat(value?.camera_lat.toFixed(2)) : messages.NO_DATA} , ${value?.camera_lon ? parseFloat(value?.camera_lon.toFixed(2)) : messages.NO_DATA}`
                            },
                            {
                                title: 'событие',
                                value: coordsDiff.str2 //`${value?.event_lat ? parseFloat(value?.event_lat.toFixed(2)) : messages.NO_DATA} , ${value?.event_lon ? parseFloat(value?.event_lon.toFixed(2)) : messages.NO_DATA}`
                            },
                        ],
                        key
                    });
                }
                if (key === 'direction') {
                    res.push({
                        description: value?.description,
                        data: [
                            { title: 'камера', value: value?.camera_direction?.direction },
                            { title: 'события', value: value?.event_direction?.direction },
                        ],
                        key
                    });
                }
                if (key === 'speed') {
                    res.push({
                        description: value?.description,
                        data: [
                            { title: 'камера', value: value?.camera_speed_threshold },
                            { title: 'события', value: value?.event_speed_threshold },
                        ],
                        key
                    });
                }
            }
            return res;
        }, []);
    };

    return (
        <Scrollbars
            style={{ width: '45%', height: '100%' }}
            renderView={(props) => <div style={{ paddingRight: '16px', ...props?.style }} />}
        >
            <h2>Разногласия</h2>
            {
                dataForRender().length > 0
                    ? <List disablePadding>
                        {dataForRender().map((el, index) =>
                            <ListItem divider dense key={el.key}>
                                <ListItemText disableTypography>
                                    {el.description}
                                    <List disablePadding>
                                        {el.data.map((elem, i) => <ListItem dense key={`${el.key}${i}`}>
                                            <ListItemText>
                                                <b>- {elem.title}: </b>
                                            </ListItemText>
                                            <ListItemText>
                                                {elem.value || messages.NO_DATA}
                                            </ListItemText>
                                        </ListItem>)}
                                    </List>
                                </ListItemText>
                            </ListItem>
                        )}
                    </List>
                    : <span>Нет разногласий</span>
            }
        </Scrollbars>
    );
};

export default WarningsInfo;
