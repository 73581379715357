import React, { useMemo, useState } from 'react';
import { Checkbox, FormControl } from '@mui/material';
import { FormControlLabel } from '@mui/material/';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import { dateNow, fullDateTimeWithTimeZone, getStartOf } from 'helpers/date.config';

interface FilterState {
    start_date: Date | string | null;
    end_date: Date | string | null;
    with_regular: boolean;
}

interface FilterProps {
    tab: string | undefined;
    setParams: React.Dispatch<React.SetStateAction<{ start_date: string | null, end_date: string | null }>>;
}

function Filter({ tab, setParams }: FilterProps) {
    const initialState = useMemo(() => ({
        start_date: fullDateTimeWithTimeZone(getStartOf('month')),
        end_date: fullDateTimeWithTimeZone(dateNow()),
        with_regular: false,
    }), []);

    const [filterData, setFilterData] = useState<FilterState>(initialState);

    const prepareData = (filter = filterData) => {
        return {
            ...filter,
            start_date: fullDateTimeWithTimeZone(filter.start_date),
            end_date: fullDateTimeWithTimeZone(filter.end_date),
        };
    };

    const handleChangeFilter = (name: keyof FilterState, value: FilterState[keyof FilterState] ) => {
        setFilterData({ ...filterData, [name]: value });
    };

    const handleSearch = () => {
        setParams(prepareData());
    };

    const handleResetFilter = (needRefresh: boolean) => {
        setFilterData(initialState);

        if (needRefresh) {
            setParams(initialState);
        }
    };

    const handleUpdateFilter = (filters: FilterState) => {
        setFilterData({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams(prepareData({ ...initialState, ...filters }));
        }
    };

    return (
        <LayoutFilter
            filter={filterData}
            onSearch={handleSearch}
            onResetFilter={handleResetFilter}
            setUserFilter={handleUpdateFilter}
            filterException={['start_date', 'end_date']}
            tab={tab}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterData.start_date}
                    valueEndDate={filterData.end_date}
                    handleDate={(start_date) => start_date && handleChangeFilter('start_date', start_date)}
                    handleEndDate={(end_date) => end_date && handleChangeFilter('end_date', end_date)}
                />

                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterData.with_regular}
                                onChange={(e) =>
                                    handleChangeFilter('with_regular', e.target.checked)
                                }
                            />
                        }
                        label="Показывать регулярные скопления"
                    />
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
}

export default Filter;
