import React, { ChangeEvent, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import { addKeyWord, editKeyWord } from 'redux/SituationalPlans/actions';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';

import type { ModalAddEditProps } from './types';


const ModalAddEdit = ({ data, isOpen, isNew,  onClose, reloadList }: ModalAddEditProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const [keyWord, setKeyWord] = useState(isNew ? '' : data?.name);
    const requestStatus = useSelector((state: any) => state.validation);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setKeyWord(e.target.value );
        validation.deleteKey('name');
    };

    const onAccept = () => {
        isNew
            ? dispatch(addKeyWord({ name: keyWord }, reloadList))
            : dispatch(editKeyWord(data?.id, { name: keyWord }, reloadList));
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    return ( 
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAccept,
                        disabled: keyWord === '' || validation.isValidation()
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <TextField
                        value={keyWord}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        label="Ключевое слово"
                        name="name"
                        error={validation.isKey('name')}
                        helperText={validation.get('name')}
                    />
                </div>
            </form>
        </Modal>
    );
};

export default ModalAddEdit;
