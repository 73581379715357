import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent from 'components/common/FormButtons';
import NoData from 'components/common/Information/NoData';
import titles from 'helpers/constants/titles';
import { loadStories } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';

import Filters from './Filters';
import Item from './Item';
import ModalForm from './ModalForm';

const Story = () => {
    const dispatch = useDispatch();

    const data = useSelector(reactSelectors.storiesData);
    const meta = useSelector(reactSelectors.storiesMeta);
    const loading = useSelector(reactSelectors.loadingStories);

    const [openModal, setOpenModal] = useState(false);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const reloadList = (isDelete: boolean) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadStories(params.page, limit, params.data));
    },[dispatch, limit, params]);

    const renderContent = () => (
        data?.length > 0 
            ? (<LSContainer headers={[
                { title: '', width: '20%' },
                { title: titles.ID, width: '20%' },
                { title: titles.DATE_OF_CREATION, width: '20%' },
                { title: titles.NAME, width: '20%' },
                { title: titles.ACTIONS, align: 'right', isActions: true },
            ]}>
                {data?.map((item: { id: number; name: string; }) => 
                    <Item
                        key={item.id + item.name}
                        item={item}
                        reloadList={reloadList}
                    />
                )}
            </LSContainer>) 
            : (!loading && <NoData/>)
    );

    return (
        <>
            <PageLayout
                header="Сюжеты"
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    accessProp: '',
                                    onClick: () => setOpenModal(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page, limit) => dispatch(loadStories(page, limit, { name: params })),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />
            {openModal
                && <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew
                    reloadList={reloadList}
                />
            }
        </>
    );
};

export default Story;
