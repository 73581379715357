import prepareCoords from 'helpers/prepareCoords';
import renderAddress from 'helpers/renderAddress';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { fullDateTime } from 'helpers/date.config';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import TypeValue from './TabComponents/TabInfo/TypeValue';

const numberValue = (value) =>
    typeof value === 'number'
        ? value || '0'
        : messages.INFO_IS_NOT_FOUND;

const arrayValue = (value) =>
    Array.isArray(value) && value.length > 0
        ? value.map((item, i) => <div key={i}>{item}</div>)
        : messages.INFO_IS_NOT_FOUND;

const tabsList = [
    {
        key: 'information',
        title: 'Информация',
        data: {
            status_text: {
                title: titles.STATUS
            },
            emtp_number: {
                title: titles.DTP_NUMBER
            },
            dtp_type: {
                title: titles.DTP_TYPE,
                value: (value) => <TypeValue id={value} />
            },
            manual_edit: {
                title: titles.MANUAL_EDIT,
                value: (value) => value ? 'Да' : 'Нет'
            },
            dtp_at: {
                title: titles.DATE_TIME_DTP,
                value: (value) => fullDateTime(value)
            },
            lat: {
                title: titles.LAT,
                value: (value) => prepareCoords(value, 6)
            },
            lon: {
                title: titles.LON,
                value: (value) => prepareCoords(value, 6)
            },
            address: {
                title: titles.ADDRESS,
                value: (address, dtp) => dtp?.address_text || Object.keys(address || {}).length > 0
                    ? dtp?.address_text || renderAddress(address)
                    : messages.INFO_IS_NOT_FOUND
            },
            intersection: {
                title: titles.INTERSECTION_STREET,
            },
            km: {
                title: titles.DTP_KM,
                value: numberValue
            },
            m: {
                title: titles.DTP_M,
                value: numberValue
            },
            description: {
                title: titles.DTP_DESCRIPTION
            },
            schema_img_url: {
                title: titles.SCHEMA_DTP,
                value: (value) =>
                    value && value.length > 0 ? (
                        <img
                            alt="img"
                            style={{
                                height: 'auto',
                                width: '200px',
                                maxWidth: '100%',
                                padding: '10px 0'
                            }}
                            src={value}
                        />
                    ) : (
                        messages.INFO_IS_NOT_FOUND
                    )
            },
            type_load_text: {
                title: titles.DTP_DATA_SOURCE
            },
            ti_id: {
                title: titles.RELATIVE_EVENT,
                value: (value, action) => {
                    if (value) {
                        return <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.show,
                                    variant: 'default',
                                    onClick: action,
                                },
                            ]}
                        />;
                    }
                    return messages.NO_DATA;
                },
                modal: true,
            },
            count_members: {
                title: titles.COUNT_DTP_MEMBERS,
                value: numberValue
            },
            dead: {
                title: titles.COUNT_DTP_DEAD,
                value: numberValue
            },
            wounded: {
                title: titles.COUNT_DTP_WOUNDED,
                value: numberValue
            },
            dead_child: {
                title: titles.COUNT_DTP_DEAD_KIDS,
                value: numberValue
            },
            wounded_child: {
                title: titles.COUNT_DTP_WOUNDED_KIDS,
                value: numberValue
            },
            count_ts: {
                title: titles.COUNT_DTP_TS,
                value: numberValue
            },

        },
    },
    {
        key: 'members',
        title: 'Участники ДТП',
    },
    {
        key: 'vehicles',
        title: 'Транспортные средства',
    },
    {
        key: 'situation',
        title: 'Обстановка',
        data: {
            road: {
                title: titles.ROAD
            },
            road_purpose_text: {
                title: titles.ROAD_VALUE
            },
            road_category: {
                title: titles.ROAD_CATEGORY
            },
            road_condition: {
                title: titles.ROAD_CONDITION
            },
            street_category: {
                title: titles.STREET_CATEGORY
            },
            weather: {
                title: titles.WEATHER
            },
            lighting: {
                title: titles.LIGHTING
            },
            changes_driving: {
                title: titles.CHANGES_DRIVING
            },
            device_traffic_light_crossroads: {
                title: titles.DEVICE_TRAFFIC_LIGHT_CROSSROAD
            },
            device_traffic_light_crosswalk: {
                title: titles.DEVICE_TRAFFIC_LIGHT_CROSSWALK
            },
            road_objects_around: {
                title: titles.ROAD_OBJECTS_AROUND,
                value: arrayValue
            },
            road_defects_text: {
                title: titles.ROAD_DEFECTS,
                value: arrayValue
            },
            driving_mode_influence_factors_text: {
                title: titles.DRIVING_MODE_INFLUENCE_FACTORS,
                value: arrayValue
            },
        }
    }
];

export default tabsList;
