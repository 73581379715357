import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCurrentCompany } from 'redux/Companies/actions';
import * as actions from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import { companiesSelectors } from 'redux/Companies';
import useTransportTypes from 'helpers/hooks/useTransportTypes';
import { getEndOf, getHumanDate, getStartOf } from 'helpers/date.config';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import SelectDateHistory from 'components/common/Transport/TransportHistory/SelectDateHistory';
import { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormButtons from 'components/common/FormButtons';
import WorkordersControl from 'components/pages/Dictionaries/PassengerTransport/WorkordersControl';
import TextWrap from 'components/MapComponents/helpers/TextWrap';

import {
    // getVTName,
    getVehicleCategory,
} from '../helper';
import config from '../config';
import icons from '../icons/icons';

import TabInformation from './TabInformation';
// import StripText from 'components/common/StripText';

const PopUp = ({
    data: uuid,
    onClosePopUp,
}) => {
    // const { uuid, onClosePopUp } = props;
    const dispatch = useDispatch();
    // todo подгрузка данных транспорта
    // const getVehicle = useTPVehicle();
    // const marker = useSelector(transportPassengerSelectors.currentMarker);

    const types = useTransportTypes();

    const getTransportCategory = useTransportCategory(
        actions.loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    // данные маркеров
    const markers = useSelector(transportPassengerSelectors.markers);
    // расширенные данные
    const markersExtend = useSelector(transportPassengerSelectors.markersExtend);
    //
    const [telemetryData] = useSelector(transportPassengerSelectors.telemetryReceiversData);
    //const { markers, telemetryReceivers: { data: [telemetryData] }  } = useSelector(({ transportPassenger }) => transportPassenger);
    const currentCompany = useSelector(companiesSelectors.currentCompany);

    // данные маркера
    const marker = markers[uuid] || {};
    // тс
    const vehicle = marker?.vehicle || {};
    const vehicleId = vehicle?.id || null;

    // расширенные данные маркера
    const markerExtend = markersExtend[uuid] || {};

    const [isOpenWorkOrders, setIsOpenWorkOrders] = useState(false);
    const [workOrdersProps, setWorkOrdersProps] = useState({});

    const [loadingRoute, setLoadingRoute] = useState(false);

    useEffect(() => {
        if(vehicle?.egts_receiver_id) {
            dispatch(actions.loadTelemetryReceivers({ id: vehicle.egts_receiver_id }));
        }
    }, []);

    useEffect(() => {
        if(vehicle?.organization_id) {
            dispatch(loadCurrentCompany(vehicle.organization_id));
        }
    }, []);

    const {
        external_id,
        num = null,
        in_registry = false,
        in_work_order = false,
        speed = 0,
        navigation_at = null,
    } = marker;

    const telemetry_receiver = telemetryData?.name || '';
    const organization = currentCompany[vehicle.organization_id]?.title || '';

    const dataInfo = {
        // ...getVehicle.data,
        ...vehicle,
        external_id,
        num,
        speed,
        navigation_at,
        telemetry_receiver,
        organization
    };

    // useEffect(() => {
    //     getVehicle(client_full);
    // }, [client_full]);

    const vehicleCategory = getVehicleCategory(marker);

    // const {
    //     color: categoryColor,
    //     getImageTpl,
    // } = getTransportCategory
    //     .getHelper
    //     .getCategoryPassenger(vehicleCategory, in_registry, in_work_order);

    const categoryName = getTransportCategory
        .getHelper
        .getName(vehicleCategory);

    const titleHistory = () => {
        const title = [];
        const categoryNameCurrent = categoryName || config.name;

        title.push(categoryNameCurrent);
        const garage_number = marker?.vehicle?.garage_number || null;
        if (garage_number) {
            title.push(`номер ${garage_number}`);
        } else if (num) {
            title.push(`маршрут ${num}`);
        } else if (uuid) {
            title.push(uuid);
        }
        return title.join(', ');
    };

    const handleCloseWorkOrdersModal = () => {
        setIsOpenWorkOrders(false);
        setWorkOrdersProps({});
    };

    return (
        <>
            <TabInformation
                data={/*getVehicle.isLoaded ?*/ dataInfo || {}}
                title={{
                    external_id: {
                        title: 'Идентификатор'
                    },
                    number: {
                        title: 'Номер',
                    },
                    garage_number: {
                        title: 'Гаражный номер',
                    },
                    organization: {
                        title: 'Организация',
                    },
                    telemetry_receiver: {
                        title: 'Приемник/организация телеметрии',
                    },
                    brand_name: {
                        title: 'Марка',
                    },
                    model_name: {
                        title: 'Модель',
                    },
                    num: {
                        title: 'Маршрут',
                    },
                    speed: {
                        title: 'Последняя скорость',
                        value: ({ speed, navigation_at }) => `${speed} км/ч ${navigation_at ? `(на ${getHumanDate(navigation_at)})` : ''}`
                    },
                }}
            />
            {/*<ShowHistory uuid={uuid} categoryName={categoryName} garage_number={marker?.vehicle?.garage_number || null} num={num} />*/}

            <SelectDateHistory
                uuid={vehicleId}
                title={titleHistory()}
                actions={{
                    storeProp: 'transportPassenger',
                    history: {
                        action: actions.loadVehicleHistory,
                        clear: actions.clearVehicleHistory,
                        loadingProp: 'loadingVehicleHistory',
                        dataProp: 'vehicleHistory',
                    },
                    speedList: {
                        action: actions.loadColorSpeed,
                        dataProp: 'colorSpeed',
                    }
                }}
                addButtons={[
                    {
                        ...buttonsTypes.apply,
                        name: 'Контроль нарядов',
                        onClick: () => {
                            setIsOpenWorkOrders(true);
                            setWorkOrdersProps({
                                // фильтр по дефолту
                                filters: {
                                    start_date_at: getStartOf('day'),
                                    end_date_at: getEndOf('day'),
                                    grz: [vehicle],
                                    id: marker?.work_order_id || markerExtend?.work_order_id || ''
                                },
                                // дисеблим поля
                                disableFilterFields: ['grz'],

                                // данные для раскрытию элементов и переходу
                                work_order_id: markerExtend?.work_order_id,
                                work_order_event_id: markerExtend?.work_order_event_id,
                            });
                        },
                        disabled: !marker?.in_work_order || !vehicle.number,
                    },
                    {
                        ...buttonsTypes.apply,
                        name: 'Показать маршрут',
                        onClick: () => {
                            // категория
                            const category_id = marker?.category_id || vehicle?.category_id;
                            dispatch(actions.loadRoutesByNCCallback(
                                marker.route?.num, // encodeURIComponent(marker.route),
                                category_id,
                                setLoadingRoute,
                                (routes = []) => {
                                    const route_from_transport_id = marker.route?.id;
                                    const type = types?.find(({ id }) => id === category_id);
                                    const selected_route = routes.filter(el => el.id === route_from_transport_id);
                                    const active_route = selected_route.length > 0
                                        ? selected_route
                                        : routes.slice(0, 1) || [];
                                    // устанавливаем активный маршрут
                                    dispatch(actions.setActive(active_route));
                                    // устанавливаем фильтр для сайдбара
                                    dispatch(actions.changeFilter({
                                        num_list: [marker.route?.num],
                                        category_id_list: type ? [type] : []
                                    }));
                                    dispatch(actions.setShowRouteByVehicle(true));
                                    onClosePopUp?.();
                                }
                            ));
                        },
                        disabled: !marker.route,
                        loading: loadingRoute,
                    }
                ]}
            />

            {isOpenWorkOrders && (
                <Modal
                    title={`${categoryName || ''} (${vehicle.number})`}
                    onClose={handleCloseWorkOrdersModal}
                    isOpen={isOpenWorkOrders}
                    fullWidth
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: handleCloseWorkOrdersModal
                                }
                            ]}
                        />
                    }
                >
                    <WorkordersControl
                        {...workOrdersProps}
                    />
                </Modal>
            )}
        </>
    );
};

export default PopUp;
