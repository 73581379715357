import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setMapLoading } from 'redux/Map/actions';
import { showMessage } from 'redux/Message/actions';
import messageTypes from 'redux/Message/messagetypes';
import messages from 'helpers/constants/messages';

import * as actions from './actions';
import * as types from './types';
import api from './api.methods';

function* loadTypesSaga() {
    const response = yield call(api.getTypes);
    if (response?.success) {
        yield put(actions.loadedTypes(response.data));
    }
}

function* loadNodesSaga({ payload, onCallback, onLoading }) {
    yield onLoading
        ? onLoading(true)
        : put(actions.loadingNodes(true));
    const response = yield call(api.getNodes, payload);
    if (response) {
        yield onCallback
            ? onCallback(response)
            : put(actions.loadedNodes(response));
    }
    yield onLoading
        ? onLoading(false)
        : put(actions.loadingNodes(false));
}

function* createNodesSaga({ payload, onLoading, onCallback }) {
    yield onLoading && put(onLoading(true));
    const response = yield call(api.createNodes, payload);
    if (response?.status === 200 || response?.status === 204) {
        yield onCallback && onCallback();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
    yield onLoading && put(onLoading(false));
}

function* editNodesSaga({ payload, onLoading, onCallback }) {
    yield onLoading && put(onLoading(true));
    const { id, data } = payload;

    const response = yield call(api.editNodes, id, data);
    if (response?.status === 200 || response?.status === 204) {
        yield onCallback && onCallback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield onLoading && put(onLoading(false));
}

function* deleteNodesSaga({ payload, onLoading, onCallback }) {
    yield onLoading && put(onLoading(true));
    const { id } = payload;
    const response = yield call(api.deleteNodes, id);
    if (response?.status === 200 || response?.status === 204) {
        yield onCallback && onCallback();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
    yield onLoading && put(onLoading(false));
}

function* loadSidebarSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getSidebar, payload);
    yield fnCallback?.(response || {});
    yield fnLoading?.(false);
}

function* loadPopupSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getPopup, payload);
    if (response?.success) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* loadPolygonSaga({ payload, fnLoading, fnCallback }) {
    yield put(setMapLoading('digitalTwin', true));
    yield fnLoading?.(true);
    const response = yield call(api.getPolygon, payload);
    if (response) {
        yield fnCallback?.(response || []);
    }
    yield fnLoading?.(false);
    yield put(setMapLoading('digitalTwin', false));
}

function* loadEdgerByIdSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getEdgeById, payload);
    if (response) {
        yield fnCallback?.(response || []);
    }
    yield fnLoading?.(false);
}

function* loadEdgerInfoByIdSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getEdgeInfoById, payload);
    if (response) {
        yield fnCallback?.(response || []);
    }
    yield fnLoading?.(false);
}

function* loadNodeByIdSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getNodeById, payload);
    if (response) {
        yield fnCallback?.(response || []);
    }
    yield fnLoading?.(false);
}

function* loadEdgesSaga({ payload }){
    yield put(actions.loadingEdges(true));
    const response = yield call(api.getEdges, payload);
    if (response) {
        yield put(actions.loadedEdges(response));
    }
    yield put(actions.loadingEdges(false));
}

function* loadEdgeTypes({ payload }){
    yield put(actions.loadingEdgeTypes(true));
    const response = yield call(api.getEdgeTypes, payload);
    if (response) {
        yield put(actions.loadedEdgeTypes(response));
    }
    yield put(actions.loadingEdgeTypes(false));
}

function* loadEdgeClasses({ payload }){
    yield put(actions.loadingEdgeClasses(true));
    const response = yield call(api.getEdgeClasses, payload);
    if (response) {
        yield put(actions.loadedEdgeClasses(response));
    }
    yield put(actions.loadingEdgeClasses(false));
}

function* createEdgeSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.createEdge, payload);
    if (response) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
    yield fnLoading?.(false);
}

function* editEdgeSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const { id, data } = payload;

    const response = yield call(api.editEdge, id, data);
    if (response) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield fnLoading?.(false);
}

function* deleteEdgeSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const { id } = payload;
    const response = yield call(api.deleteEdge, id);
    if (response) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
    yield fnLoading?.(false);
}

function* loadObjectBindingsSaga({ payload, fnCallback, fnLoading }){
    yield fnLoading?.(true);
    const response = yield call(api.getObjectBindings, payload);
    if (response) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* loadObjectBindingsAllSaga({ payload, fnCallback, fnLoading }){
    yield fnLoading?.(true);
    const response = yield call(api.getObjectBindingsAll, payload);
    if (response) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* loadObjectBindingsNearlySaga({ payload, fnCallback, fnLoading }){
    yield fnLoading?.(true);
    const response = yield call(api.getObjectBindingsNearly, payload);
    if (response) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* createObjectBindingsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.createObjectBindings, payload);
    if (response) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.SAVE_SUCCESS);
    }
    yield fnLoading?.(false);
}

function* updateObjectBindingsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const { id, data } = payload;

    const response = yield call(api.updateObjectBindings, id, data);
    if (response) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield fnLoading?.(false);
}

function* deleteObjectBindingsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.deleteObjectBindings, payload);
    if (response) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_TYPES, loadTypesSaga),

        takeLatest(types.LOAD_NODES, loadNodesSaga),

        takeLatest(types.CREATE_NODES, createNodesSaga),
        takeLatest(types.EDIT_NODES, editNodesSaga),
        takeLatest(types.DELETE_NODES, deleteNodesSaga),

        takeLatest(types.LOAD_SIDEBAR, loadSidebarSaga),
        takeLatest(types.LOAD_POLYGON, loadPolygonSaga),

        takeLatest(types.LOAD_POPUP, loadPopupSaga),

        takeLatest(types.LOAD_EDGES, loadEdgesSaga),
        takeLatest(types.LOAD_EDGES_BY_ID, loadEdgerByIdSaga),
        takeLatest(types.LOAD_EDGES_INFO_BY_ID, loadEdgerInfoByIdSaga),
        takeLatest(types.LOAD_NODE_BY_ID, loadNodeByIdSaga),

        takeLatest(types.LOAD_EDGE_TYPES, loadEdgeTypes),
        takeLatest(types.LOAD_EDGE_CLASSES, loadEdgeClasses),

        takeLatest(types.CREATE_EDGE, createEdgeSaga),
        takeLatest(types.EDIT_EDGE, editEdgeSaga),
        takeLatest(types.DELETE_EDGE, deleteEdgeSaga),

        takeLatest(types.LOAD_OBJECT_BINDINGS, loadObjectBindingsSaga),
        takeLatest(types.LOAD_OBJECT_BINDINGS_ALL, loadObjectBindingsAllSaga),
        takeLatest(types.LOAD_OBJECT_BINDINGS_NEARLY, loadObjectBindingsNearlySaga),

        takeLatest(types.CREATE_OBJECT_BINDINGS, createObjectBindingsSaga),
        takeLatest(types.UPDATE_OBJECT_BINDINGS, updateObjectBindingsSaga),
        takeLatest(types.DELETE_OBJECT_BINDINGS, deleteObjectBindingsSaga),
    ]);
}
