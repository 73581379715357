import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadDuploCodes } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';

import Item from './Item';
import Filter from './Filter';

import type { DuploCodesItem } from './types';

const test_id_prefix = '/dictionaries/doris-control/duplo-codes';

const DuploCodes = () => {
    const dispatch = useDispatch();

    const data = useSelector(dorisControlSelectors.duploCodesData);
    const meta = useSelector(dorisControlSelectors.duploCodesMeta);
    const loading = useSelector(dorisControlSelectors.loadingDuploCodes);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = useCallback(() => {
        dispatch(loadDuploCodes(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    return (
        <PageLayout
            header="Коды ДУПЛО"
            filters={<Filter setParams={setParams} test_id_prefix={test_id_prefix} />}
            informPanelProps={{
                total: meta?.total
            }}
            loading={loading}
            content={() => (
                <>
                    {data?.length > 0
                        ? (<>
                            <LSContainer
                                headers={[
                                    { width: '5%' },
                                    { width: '95%' },
                                ]}
                            >
                                {data.map((item: DuploCodesItem) => (
                                    <Item
                                        key={item?.id}
                                        item={item}
                                        reloadList={reloadList}
                                        test_id_prefix={test_id_prefix}
                                    />
                                ))}
                            </LSContainer>
                        </>)
                        : !loading && <NoData/>
                    }
                </>
            )}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit,
                setLimit
            }}
        />
    );
};

export default DuploCodes;
