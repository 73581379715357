import { ChangeEvent, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, SelectChangeEvent } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreProp } from 'helpers/hooks';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import { loadCommutatorTypes, loadVideoCamerasDictionaryInfrastructureTypes } from 'modules/InfrastructureObjects/redux/actions';

import type { FilterProps, FilterState } from './types';

const Filter = ({ setParams }: FilterProps) => {
    const commutatorTypes = useStoreProp(loadCommutatorTypes, 'roadNetworks', 'commutatorTypes');
    const infrastructureTypes = useStoreProp(loadVideoCamerasDictionaryInfrastructureTypes, 'roadNetworks', 'videoCamerasDictionaryInfrastructureTypes');

    const initialState: FilterState = {
        name: '',
        infrastructure_type_id: '',
        type_id: ''
    };

    const [data, setData] = useState<FilterState>(initialState);

    const prepareData = (filter: FilterState = data) => removeEmptyFields({ ...filter });

    const onChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
    ) => {
        const { name, value } = event.target;

        if (name) {
            setData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const resetFilters = (needRefresh: boolean) => {
        setData(initialState);
        if (needRefresh) {
            setParams(prepareData(initialState));
        }
    };

    const onSearch = (filters: FilterState = data) => {
        setParams(prepareData(filters));
    };

    const updateFilter = (filters: Partial<FilterState>) => {
        const updatedFilters = { ...initialState, ...filters };
        setData(updatedFilters);

        if (Object.keys(filters).length > 0) {
            setParams(updatedFilters);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={onSearch}
            filter={data}
            setUserFilter={updateFilter}
            disabled={!data.name && !data.infrastructure_type_id && !data.type_id}
        >
            <LayoutFilter.Visible>
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={data.name}
                    name="name"
                    onChange={onChange}
                    type="text"
                />
                <FormControl size={'small'} variant="outlined">
                    <InputLabel>Тип коммутатора</InputLabel>
                    <Select
                        value={data.type_id}
                        onChange={onChange}
                        label="Тип коммутатора"
                        name="type_id"
                    >
                        <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                        {Object?.keys(commutatorTypes)?.map((key) => (
                            <MenuItem key={key} value={key}>{commutatorTypes[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl size={'small'} variant="outlined">
                    <InputLabel>Тип инфраструктуры</InputLabel>
                    <Select
                        value={data.infrastructure_type_id}
                        onChange={onChange}
                        label="Тип инфраструктуры"
                        name="infrastructure_type_id"
                    >
                        <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>
                        {Object?.keys(infrastructureTypes)?.map((key) => (
                            <MenuItem key={key} value={key}>{infrastructureTypes[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
