import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import AppBar from '@mui/material/AppBar';
import { Card } from '@mui/material';

import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import titles from 'helpers/constants/titles';
import { CustomTabs, CustomTab } from 'components/common/Tabs';

import BasicData from './BasicData';

import type { CommutatorItem } from 'types';

interface PassportTabsProps {
    item: CommutatorItem;
    tab?: string;
    onEdited?: () => void;
    onDeleted?: () => void;
    callback?: () => void;
}

const PassportTabs = ({
    item,
    tab = 'data',
    onEdited = () => {},
    onDeleted = () => {},
    callback = () => {},
}: PassportTabsProps) => {
    const scrollRef = useRef<Scrollbars>(null);
    const loading_commutator = useSelector(roadNetworksSelectors.loadingCommutator);

    const tabPanelProps = (tabName: string) => ({
        role: 'tabpanel',
        id: `scrollable-force-tabpanel-${tabName}`,
        'aria-labelledby': `scrollable-force-tab-${tabName}`
    });

    const tabProps = (tabName: string) => ({
        id: `scrollable-force-tab-${tabName}`,
        'aria-controls': `scrollable-force-tabpanel-${tabName}`,
    });

    const [value, setValue] = useState( tab || 'data');
    const [currentCommutator, setCurrentCommutator] = useState(item);

    const scrollTop = () => {
        if (scrollRef.current) scrollRef.current.scrollTop(0);
    };

    const handleChange = (_: any, newValue: React.SetStateAction<string>) => {
        setValue(newValue);
        scrollTop();
    };

    useEffect(() => {
        if (item.id) {
            scrollTop();
        }
    }, [item.id]);

    useEffect(() => {
        setCurrentCommutator(item);
    }, [item]);

    return (
        <Card variant="elevation" style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
            <AppBar position="sticky" color="default" style={{ zIndex: 2 }}>
                <CustomTabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    variant="scrollable"
                    aria-label="scrollable force tabs example"
                >
                    <CustomTab
                        icon={<i className="fal fa-info-square"/>}
                        iconPosition="start"
                        value="data"
                        label={titles.BASIC_DATA}
                        {...tabProps('data')}
                    />
                </CustomTabs>
            </AppBar>
            <div style={{ height: '100%', display: 'flex' }}>
                <Scrollbars style={{ height: '100%' }} ref={scrollRef} >
                    {value === 'data'
                        && <div {...tabPanelProps('data')}>
                            <BasicData
                                currentCommutator={currentCommutator}
                                loading={loading_commutator}
                                onEdited={onEdited}
                                onDeleted={onDeleted}
                                callback={callback}
                            />
                        </div>}
                </Scrollbars>
            </div>
        </Card>
    );
};

export default PassportTabs;
