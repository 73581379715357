import passengerTransport from './passengerTransport';
import situationalPlans from './situationalPlans';
import roadWorks from './roadWorks';
import incidents from './incidents';
import videoAnalytics from './videoAnalytics';
import dorisControl from './dorisControl';
import trafficLights from './trafficLights';
import trafficFlow from './trafficFlow';
import companies from './companies';
import cityCameras from './cityCameras';
import boards from './boards';
import ecology from './ecology';
import meteo from './meteo';
import aip from './aip';
import transportSpecial from './transportSpecial';
import transnavigation from './Transnavigation';
import efficiency from './efficiency';
import workRequests from './workRequests';
import roadNetworks from './roadNetwork';
import overlap from './overlap';
import parking from './parkingArea';

const routes = [
    ...passengerTransport,
    ...situationalPlans,
    ...roadWorks,
    ...incidents,
    ...videoAnalytics,
    ...dorisControl,
    ...trafficLights,
    ...companies,
    ...cityCameras,
    ...boards,
    ...ecology,
    ...meteo,
    ...aip,
    ...transportSpecial,
    ...trafficFlow,
    ...transnavigation,
    ...efficiency,
    ...workRequests,
    // ...roadNetworks,
    ...overlap,
    ...parking
];

export default routes;