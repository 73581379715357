// костыль для обработки ответа при создании/редактировании/удалении
export const requestReturnCUD = (response) => response?.status === 204
    ? { }
    : response?.data;

export const requestConvert = (data) => {
    if (data) {
        const {
            current_page = 1,
            items,
            last_page = 0,
            per_page = 0,
            total = 0,
        } = data || {};

        return {
            data: items || [],
            meta: {
                current_page,
                last_page,
                per_page,
                total,
            }
        };
    }
    return {};
};
