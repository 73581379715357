import SelectIncidentFactors from 'modules/React/utils/components/SelectIncidentFactors';
import type { FactorType } from 'modules/React/reactTypes';

import CheckIncidentFactors from './CheckIncidentFactors';


interface FactorsProps {
    factors: FactorType[];
    onChange: (value: FactorType[]) => void;
}

const Factors = ({ factors, onChange }: FactorsProps) => {
    const handleChangeFactors = (value: FactorType[] ) => {
        const updatedFactors = value.map((el) => ({
            ...el,
            is_default: el.is_default || false,
        }));

        onChange(updatedFactors);
    };

    const handleCheck = (selectedItems: number[]) => {
        const updatedFactors = factors.map((el) => ({
            ...el,
            is_default: selectedItems.includes(el.id as number),
        }));

        onChange(updatedFactors);
    };

    return (
        <>
            <SelectIncidentFactors
                // @ts-ignore
                onChange={handleChangeFactors}
                selected={factors}
                multiple
            />

            {factors?.length > 0 && (
                <CheckIncidentFactors
                    data={factors}
                    onChange={handleCheck}
                    selectedItems={factors
                        .filter((el) => el.is_default)
                        .map((el) => el.id as number)
                    }
                />
            )}
        </>
    );
};

export default Factors;
