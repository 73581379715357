import { useState } from 'react';
import { IconButton } from '@mui/material';

import messages from 'helpers/constants/messages';

import type { Objects } from '../../types';

const ObjectsItem = ({ item }: { item: Objects }) => {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => setOpen(prev => !prev)}>
                    {open
                        ? <i className="fas fa-caret-down" style={{ width: 10 }}></i>
                        : <i className="fas fa-caret-right" style={{ width: 10 }}></i>
                    }
                    
                </IconButton>
                <h3>{item.name} {`(${item?.data.length})`}</h3>
            </div>
            {open
                && <div style={{ marginLeft: 30 }}>
                    {item?.data.length > 0
                        ? item?.data.map((el, i) => <div key={`${i}_${el.name}`}>{el.name}</div>)
                        : <div>{messages.NO_DATA}</div>
                    }
                </div>
            }
            
        </div>
    );
};

export default ObjectsItem;
