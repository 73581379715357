import { useState } from 'react';
import { useDispatch } from 'react-redux';

import colorExtend from 'helpers/mapHelper/colorExtend';
import MapScroll from 'components/MapComponents/MapScroll';
import { createIcon, MapPopUp } from 'components/MapComponents/MapPopUp';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import { RenderPassportTabs } from 'modules/React/Dictionaries/Incidents/RenderPassportTabs';
import * as actions from 'modules/React/redux/actions';

import config from '../config';

import Information from './Information';

const PopUpModal = ({ isOpen, onClose, data }) => {
    const dispatch = useDispatch();
    const [isOpenPassport, setIsOpenPassport] = useState(false);

    const [currentItem, setCurrentItem] = useState(data);

    const closePassport = () => {
        setIsOpenPassport(false);
    };

    const onEditItem = (item) => {
        // после редактирования в модалке-паспорте обновляем данные в попапе
        setCurrentItem((prev) => ({
            ...prev,
            ...item
        }));
        dispatch(actions.reloadIncidentSidebar(true));
    };

    return (
        <>
            <MapPopUp
                isOpen={isOpen}
                onClose={onClose}
                icon={createIcon(config.layerIcon)}
                title={currentItem?.name}
                titleBackgroundColor={colorExtend(currentItem.threat_level_color)}
                medium
                buttons={
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                name: 'Паспорт инцидента',
                                onClick: () => setIsOpenPassport(true),
                            },
                        ]}
                    />
                }
            >
                <MapScroll>
                    <Information
                        data={currentItem}
                    />
                </MapScroll>
            </MapPopUp>
            {isOpenPassport && (
                <Modal
                    isOpen={isOpenPassport}
                    onClose={closePassport}
                    noPadding={true}
                    title={currentItem?.name}
                    fullWidth
                    showCloseInTitle
                    buttons={<FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: closePassport
                            },
                        ]}
                    />}
                >
                    <RenderPassportTabs
                        selectedItem={currentItem}
                        passportOnly
                        reloadList={onEditItem}
                    />
                </Modal>
            )}
        </>
    );
};

export default PopUpModal;
