import { useEffect, useMemo, useState } from 'react';

import Loading from 'components/common/Loading';
import NoData from 'components/common/Information/NoData';
import groupObjects from 'modules/React/utils/helpers/incidents/groupObjects';
import { useStoreFromSelector } from 'helpers/hooks';
import { loadIncidentObjectTypes } from 'modules/React/redux/actions';
import * as reactSelector from 'modules/React/redux/selectors';

import ObjectsItem from './ObjectsItem';

import type { IncidentItem, Objects, ObjectsData, ObjectTypes } from '../../types';
import { Grid } from '@mui/material';
import {
    Map,
    GeoJson,
    FeatureGroup,
    Marker,
    ToolTip
} from 'components/MapComponents/leaflet';
import MapDragGeoJson from 'components/MapComponents/leaflet/components/geoJSON/MapDragGeoJson';
import { config } from 'config';

interface InfrastructureProps {
    objects?: Objects[];
    loading?: boolean;
    objectTypes: ObjectTypes;
    item: IncidentItem;
}

const Infrastructure = ({ loading, item }: InfrastructureProps) => {
    const objectTypes: ObjectTypes = useStoreFromSelector(loadIncidentObjectTypes, reactSelector.objectTypes);

    const [geoJson, setGeoJson] = useState([]);

    useEffect(() => {
        // @ts-ignore
        const geoJson = item.objects.reduce((res, { geometry, ...props }) => [
            ...res,
            {
                ...geometry,
                properties: {
                    ...geometry.properties,
                    data: {
                        ...props,
                        geometry
                    },
                    attribution: {
                        color: 'red'
                    },
                },
                style: {
                    color: 'red',
                    weight: 2
                }
            }
        ], []);

        // @ts-ignore
        setGeoJson(geoJson);
    }, [item.objects]);

    const preparedObjects = useMemo(() => {
        return groupObjects(
            item.objects,
            objectTypes
        );
    }, [item.objects, objectTypes]);

    return (<div style={{ padding: 20 }}>
        <Grid
            container
            direction="row"
            spacing={1}
        >
            <Grid item xs={6}>
                {preparedObjects.length > 0
                    ? preparedObjects.map((item: any) => <ObjectsItem key={item.name} item={item} />)
                    : !loading && <NoData />
                }
            </Grid>
            <Grid item xs={6}>
                <div style={{ border: '1px solid #000', height: 400 }}>
                    {/* @ts-ignore */}
                    <Map center={config.get('mapCenter')}>
                        {/* @ts-ignore */}
                        <Marker
                            latlng={[item.lat, item.lon]}
                        >
                            {/* @ts-ignore */}
                            <ToolTip
                                offset={[0, -40]}
                                permanent={true}
                                direction="top"
                            >
                                <div>
                                    <strong>Широта: </strong>
                                    {item.lat}
                                </div>
                                <div>
                                    <strong>Долгота: </strong>
                                    {item.lon}
                                </div>
                            </ToolTip>
                        </Marker>
                        {/* @ts-ignore */}
                        <FeatureGroup>
                            {/* @ts-ignore */}
                            <MapDragGeoJson
                                geometry={item.geometry}
                                readOnly
                            />
                            {geoJson
                                && (
                                    <GeoJson
                                        data={geoJson}
                                        toolTipTemplate={(data: ObjectsData) => <div>{data.name}</div>}
                                        toolTipOptions={{
                                            direction: 'top',
                                            offset: [0, 0],
                                        }}
                                    />
                                )
                            }
                        </FeatureGroup>
                    </Map>
                </div>
            </Grid>
            {loading && <Loading circular />}
        </Grid>
    </div>);
};

export default Infrastructure;
