import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOAD_MENU = `${prefix}/LOAD_MENU`;
export const LOADING = `${prefix}/LOADING`;
export const LOADED = `${prefix}/LOADED`;
export const MENU_GET_ID = `${prefix}/MENU_GET_ID`;
export const SAVE_ACTIVE_LAYER = `${prefix}/SAVE_ACTIVE_LAYER`;
export const SELECT_LAYER = `${prefix}/SELECT_LAYER`;
export const ADD_ACTIVE_LAYER = `${prefix}/ADD_ACTIVE_LAYER`;

export const ADD_ATTACH_LAYERS = `${prefix}/ADD/ATTACH/LAYERS`;

export const SET_SHOW_RETURN_TO = `${prefix}/SET_SHOW_RETURN_TO`;

export const LOAD_PAGE_HEADERS = `${prefix}/LOAD_PAGE_HEADERS`;
export const LOADED_PAGE_HEADERS = `${prefix}/LOADED_PAGE_HEADERS`;
