import { lazy, Suspense } from 'react';

import Loading from 'components/common/Loading';

const HeatMapLazy = (props) => {
    const InfoPanel = lazy(() =>
        new Promise((resolve) =>
            setTimeout(() => resolve(import('modules/HeatMapCongestions/Dictionaries/HeatMapCongestions')), 50)
        )
    );
    return (
        <Suspense fallback={<Loading linear/>}>
            <InfoPanel {...props} />
        </Suspense>
    );
};

export default HeatMapLazy;
