import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

const TableComponent = ({
    data = {},
}) => {
    const headers = data.headers || [];
    const cells = data.cells || [];

    return (
        <TableContainer component={Paper}>
            <Table
                // sx={{ minWidth: 650 }}
                size="small"
                // aria-label="a dense table"
            >
                {(headers?.length > 0) && (
                    <TableHead>
                        <TableRow>
                            {headers.map(({ value, align = 'center' }, index) => (
                                <TableCell key={`header_item_${index}`} align={align}>{value}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                )}

                <TableBody>
                    {cells?.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0, boxShadow: 'none !important' } }}
                        >
                            {row?.map(({ value, color = null, align = 'center' }, index) => (
                                <TableCell key={index} align={align} className="tableCell" >
                                    {color
                                        ? (
                                            <span style={{ color }}>{value}</span>
                                        )
                                        : value
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableComponent;
