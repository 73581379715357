import { useState } from 'react';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import AddressList from 'components/common/Location/AddressList';
import SelectThreatLevels from 'modules/React/utils/components/SelectThreatLevels';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';

const Filter = ({
    initialFilter,
    onSearch,
    tab,
}) => {
    const [data, setData] = useState(initialFilter);

    const handleChange = (name, value) => {
        setData(prev => ({ ...prev, [name]: value }));
    };

    const resetSearch = (needRefresh) => {
        setData(initialFilter);
        if (needRefresh) {
            onSearch(initialFilter);
        }
    };

    const updateFilter = (filters) => {
        const newFilters = { ...initialFilter, ...filters };
        setData(newFilters);
        if (Object.keys(filters).length > 0) {
            onSearch(newFilters);
        }
    };

    return (
        <LayoutFilter
            filter={data}
            onSearch={() => onSearch(data)}
            setUserFilter={updateFilter}
            onResetFilter={resetSearch}
            isFilterVisible
            tab={tab}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={data?.start_date || null}
                    valueEndDate={data?.end_date || null}
                    handleDate={(val) => handleChange('start_date', val)}
                    handleEndDate={(val) => handleChange('end_date', val)}
                    equalDates={true}
                />
                <SelectIncidentTypes
                    multiple
                    label="Тип инцидента"
                    selected={data.type_id_list}
                    onChange={(val) => handleChange('type_id_list', val)}
                />
                <SelectThreatLevels
                    multiple
                    selected={data.threat_level_id_list}
                    onChange={(val) => handleChange('threat_level_id_list', val)}
                />

            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <AddressList
                    label="Адрес"
                    className={''}
                    address={data?.address_text}
                    address_text={data?.address_text}
                    onChange={(value) => handleChange('address_text', value?.address_text)}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
