// Тип объекта ЦД
export const objectTypes = {
    node: 'node',
    edge: 'edge',
};
// Тип объекта Eputs
export const eputsTypes = {
    infrastructure: 'infrastructure',
    roadNetwork: 'road-section',
};
// названия
export const typesRu = {
    [objectTypes.node]: 'Узлы',
    [objectTypes.edge]: 'Рёбра',
    [eputsTypes.infrastructure]: 'Объекты инфраструктуры',
    [eputsTypes.roadNetwork]: 'Элементы дорожной сети',
};
export const typesRuOne = {
    [objectTypes.node]: 'Узел',
    [objectTypes.edge]: 'Ребро',
    [eputsTypes.infrastructure]: 'Объект инфраструктуры',
    [eputsTypes.roadNetwork]: 'Элемент дорожной сети',
};
// иконки
export const typesIcons = {
    [objectTypes.node]: 'far fa-dot-circle',
    [objectTypes.edge]: 'fas fa-horizontal-rule',
    [eputsTypes.infrastructure]: 'far fa-warehouse-alt',
    [eputsTypes.roadNetwork]: 'far fa-road',
};

export const getColor = (isSelected) => isSelected
    ? '#0000FF'
    : '#00BFFF';

/**/
export const getItemData = (data) => data?.success ? data?.data || {} : data || {};

// текущий объект
export const getItemCurrentObject = (itemObject, itemType) => {
    switch (itemType) {
        case objectTypes.node:
        case objectTypes.edge:
            return {
                object: getItemData(itemObject.object),
                id: itemObject.object_id || null,
                type: itemObject.object_type || null,
                type_text: typesRu[itemObject.object_type] || '',
            };
        case eputsTypes.infrastructure:
        case eputsTypes.roadNetwork:
            return {
                object: getItemData(itemObject.eputs),
                id: itemObject.eputs_id || null,
                type: itemObject.eputs_type || null,
                type_text: typesRu[itemObject.eputs_type] || '',
            };
        default:
            return null;
    }
};

// линкованный объект
export const getItemLinkedObject = (itemObject, itemType) => {
    switch (itemType) {
        case objectTypes.node:
        case objectTypes.edge:
            return {
                object: getItemData(itemObject.eputs),
                id: itemObject.eputs_id || null,
                type: itemObject.eputs_type || null,
                type_text: typesRu[itemObject.eputs_type] || '',
            };
        case eputsTypes.infrastructure:
        case eputsTypes.roadNetwork:
            return {
                object: getItemData(itemObject.object),
                id: itemObject.object_id || null,
                type: itemObject.object_type || null,
                type_text: typesRu[itemObject.object_type] || '',
            };
        default:
            return null;
    }
};

// тест для отображения
export const renderItemText = (itemObject, renderType = false) => {
    const text = renderType ? `${itemObject.type_text}: ` : '';
    if (itemObject?.object?.name) return text + itemObject?.object?.name;
    if (itemObject?.object?.lat && itemObject?.object?.lon) return text +  `lat: ${itemObject.object?.lat}, lon: ${itemObject.object?.lon}`;
    if (itemObject?.object?.uuid) return text + `id: ${itemObject.object?.uuid}`;
    if (itemObject?.object?.id) return text + `id: ${itemObject.object?.id}`;
    return '';
};
