import React from 'react';
import { Typography } from '@mui/material';

const RenderValue = ({ title, children, color = null, width = '120px' }) => (
    <div className="wanted__flex" style={{ marginBottom: '.5rem' }}>
        <Typography variant="body2" component="div" style={{ width, flex: 'none', marginRight: '1rem' }}>
            <strong>{title}: </strong>
        </Typography>
        <div>
            {
                color
                    ? <span
                        style={{
                            background: color,
                            padding: '5px 10px',
                            borderRadius: '10px',
                            fontSize: '14px',
                            ...(color === '#ad1818' && { color: '#fff' })
                        }}
                    >
                        {children}
                    </span>
                    : <>
                        <Typography variant="body2" component="div" style={{ marginRight: '1rem' }}>{children}</Typography>
                    </>
            }
        </div>
    </div>
);

export default RenderValue;
