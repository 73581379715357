import { useState } from 'react';
import { CompareArrows } from '@mui/icons-material';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import ConfirmModal from 'components/common/ConfirmModal';

const RevertDtp = ({
    index,
    onChange,
}) => {

    const [isModal, setIsModal] = useState(false);

    const handleDoChange = () => {
        onChange();
        setIsModal(false);
    };
    const handleChange = () => setIsModal(true);

    return (
        <>
            <span>Причина</span>
            <FormButtons
                buttons={[
                    {
                        ...buttonsTypes.changeStatusIcon,
                        ...index === 1 ? { icon: <i className="fas fa-toggle-off"/> } : {},
                        onClick: handleChange,
                    }
                ]}
                // buttons={[{
                //     ...buttonsTypes.changeStatusIcon,
                //     icon: <CompareArrows />,
                //     name: 'Изменить связь',
                //     onClick: handleChange,
                // }]}
                noMarginLeft
                justButton
            />
            <span>Следствие</span>

            {isModal && (
                <ConfirmModal
                    open={isModal}
                    onClose={() => setIsModal(false)}
                    onSuccess={handleDoChange}
                    message="Изменить связь причина/следствие?"
                />
            )}
        </>
    );

};

export default RevertDtp;
