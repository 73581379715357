import React, { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

interface FilterState {
    name?: string;
}

const initialState: FilterState = {
    name: '',
};

interface FilterProps {
    setParams: React.Dispatch<React.SetStateAction<{ page: number; data: FilterState }>>;
}

const Filter = ({ setParams }: FilterProps) => {
    const [filterData, setFilterData] = useState(initialState);

    const handleChange = (name: keyof FilterState, value: string) => {
        setFilterData({ ...filterData, [name]: value });
    };

    const search = () => {
        setParams({ page: 1, data: removeEmptyFields(filterData) });
    };

    const resetFilter = (needRefresh: boolean) => {
        setFilterData(initialState);

        if (needRefresh) {
            setParams({ page: 1, data: {} as FilterState });
        }
    };

    const updateFilter = (filters: Partial<FilterState>) => {
        setFilterData({ ...initialState, ...filters });

        if (Object.keys(filters).length > 0) {
            setParams({ page: 1, data: removeEmptyFields({ ...initialState, ...filters }) });
        }
    };

    return (
        <LayoutFilter
            filter={filterData}
            onSearch={search}
            onResetFilter={resetFilter}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filterData.name}
                    label={titles.NAME}
                    size="small"
                    variant="outlined"
                    onChange={(e) => handleChange('name', e.target.value)}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
