import { Box, Icon } from '@mui/material';

import messages from 'helpers/constants/messages';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import type { ResponseScenario } from 'modules/React/Dictionaries/ResponseScenarios/types';
import type { FactorType } from 'modules/React/reactTypes/factors';

import type { FilterFields } from './types';

interface ItemProps {
    item: ResponseScenario;
    isSelected: boolean;
    onClickItem: () => void;
    filter: FilterFields;
}

const Item = ({ item, isSelected, onClickItem, filter }: ItemProps) => {
    const renderTag = (children: string | undefined, highlight: boolean, key?: number) => (
        <span
            {...(key && { key })}
            style={{
                backgroundColor: highlight ? 'lightblue' : 'transparent',
                padding: '3px 5px',
                borderRadius: '5px',
            }}
        >
            {children}
        </span>
    );

    return (
        <LSContentItem key={item.id} onClick={() => onClickItem()}>
            <LSContentColumn>
                <Icon
                    component="span"
                    className={isSelected ? 'far fa-check' : 'far fa-plus'}
                    sx={{ color: 'green' }}
                />
            </LSContentColumn>
            <LSContentColumn>
                {item.id || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {item.name || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                <Icon
                    component="span"
                    className={item.is_typical ? 'far fa-check' : ''}
                    sx={{ color: 'green' }}
                />
            </LSContentColumn>
            <LSContentColumn>
                {item.event_object_list?.map((obj) => obj.name) || messages.NO_VALUE}
            </LSContentColumn>
            <LSContentColumn>
                {!!item.factors?.length
                    ? <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {item.factors?.map((fact: FactorType) => renderTag(
                            fact.name as string,
                            !!filter.factors_list?.find((el) => el.id === fact.id),
                            fact.id
                        ))}
                    </Box>
                    : messages.NO_VALUE
                }
            </LSContentColumn>
            <LSContentColumn>
                {!!item.keyword_list?.length
                    ? <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                        {item.keyword_list?.map((kw) => renderTag(
                            kw.name,
                            !!filter.keyword_list?.find((el) => el.id === kw.id),
                            kw.id
                        ))}
                    </Box>
                    : messages.NO_VALUE
                }
            </LSContentColumn>
            <LSContentColumn>
                {!!item.category?.name
                    ? renderTag(item.category.name, filter.category?.id === item.category?.id)
                    : messages.NO_VALUE
                }
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
