import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import * as actions from 'redux/Ecology/actions';
import { ecologySelectors } from 'redux/Ecology';
import { loadDeviceStatuses, wsDataEcologe } from 'redux/Ecology/actions';
import mapHelper from 'helpers/mapHelper';
import { useWsActive, useWsSubscribe } from 'helpers/ws/hooks';
import { useStoreFromSelector } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    Marker,
} from 'components/MapComponents/leaflet';
import MapLegends from 'components/common/Transport/MapLegends';
import { createIcon, MapPopUp, MapPopUpListener }  from 'components/MapComponents/MapPopUp';

import { createIconMarker } from './helper';
import EcologyPopup from './PopUp';
import config from './config.js';
import Legend from './Legend';


const Layer = (props) => {
    const { map, readOnly = false, visibleLegend = false } = props;
    const dispatch = useDispatch();

    const statuses = useStoreFromSelector(loadDeviceStatuses, ecologySelectors.deviceStatuses);
    const statusesObj = useMemo(() => statuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [statuses]);

    const types = useSelector(ecologySelectors.types);
    // const statuses = useSelector(ecologySelectors.statuses);
    const polygon = useSelector(ecologySelectors.polygon);
    const active = useSelector(ecologySelectors.active);


    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygonEcology(polygon));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useWsSubscribe('ecologe_telemetry_v2', (events) => {
        if (events?.length > 0) {
            dispatch(wsDataEcologe(events));
        }
    });

    useEffect(() => {
        if (Object.keys(types).length === 0) {
            dispatch(actions.getDeviceTypesEcology());
        }

        fetchPolygon();

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.resetActiveEcology());
            dispatch(actions.clearForPolygonEcology());
            map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (active?.lat && active?.lon) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    return (
        <>
            {polygon.map((item) => {
                const { external_id, lat, lon, status, status_color } = item;
                const color = colorExtend(status_color || statusesObj[status]?.color);

                const componentProps = {
                    ...props,
                    key: `${config.slug}_${external_id}`,
                    attribution: {
                        slug: config.slug,
                        color
                    },
                    onClick: (latlng) => {
                        map.setView(latlng);
                        dispatch(actions.setActiveEcology(item));
                    }
                };

                return (
                    <Marker
                        {...componentProps}
                        latlng={[lat, lon]}
                        icon={createIconMarker(color)}
                    />
                );
            })}

            {/* модалка */}
            <MapPopUpListener
                activeSelector={ecologySelectors.active}
                polygonSelector={ecologySelectors.polygon}
            >
                <MapPopUp
                    title={({ name = '' }) => name}
                    icon={createIcon(config.layerIcon)}
                    onClose={() => {
                        dispatch(actions.resetActiveEcology());
                    }}
                    titleBackgroundColor={({ status_color, status }) => colorExtend(status_color || statusesObj[status]?.color)}
                    small
                >
                    <EcologyPopup readOnly={readOnly}/>
                </MapPopUp>
            </MapPopUpListener>

            <MapLegends
                layer={config.slug}
                visibleRequired={visibleLegend}
            >
                <Legend/>
            </MapLegends>
        </>
    );
};

export default Layer;
