import Incidents from 'modules/React/Dictionaries/Incidents';
import TypicalOperations from 'modules/React/Dictionaries/TypicalOperations';
import IncidentFactors from 'modules/React/Dictionaries/IncidentFactors';
import Triggers from 'modules/React/Dictionaries/Triggers';
import IncidentsTypes from 'modules/React/Dictionaries/IncidentsTypes';
import IncidentsHeatMap from 'modules/React/Dictionaries/IncidentsHeatMap';
import KeyWords from 'modules/React/Dictionaries/KeyWords';
import Dangers from 'modules/React/Dictionaries/Dangers';
import ResponseScenarios from 'modules/React/Dictionaries/ResponseScenarios';
import InfoPanel from 'modules/React/Reports/InfoPanel';
import Story from 'modules/React/Dictionaries/Story';
import EventObjects from 'modules/React/Dictionaries/EventObjects';
import Events from 'modules/React/Dictionaries/Events';

const dictionariesRoutes = [
    {
        path: '/react/dictionaries/incidents',
        component: Incidents,
        exact: true,
    },
    {
        path: '/react/typical-operations',
        component: TypicalOperations,
        exact: true,
    },
    {
        path: '/react/user-incident-factors',
        component: IncidentFactors,
        exact: true,
    },
    {
        path: '/react/dictionaries/triggers',
        component: Triggers,
        exact: true,
    },
    {
        path: '/react/dictionaries/incidents-types',
        component: IncidentsTypes,
        exact: true,
    },
    {
        path: '/react/dictionaries/incidents-heat-map',
        component: IncidentsHeatMap,
        exact: true,
    },
    {
        path: '/react/dictionaries/keywords',
        component: KeyWords,
        exact: true,
    },
    {
        path: '/react/dictionaries/dangers',
        component: Dangers,
        exact: true,
    },
    {
        path: '/react/dictionaries/response-scenarios',
        component: ResponseScenarios,
        exact: true,
    },
    {
        path: '/react/dictionaries/event-objects',
        component: EventObjects,
        exact: true,
    },
    {
        path: '/react/dictionaries/events',
        component: Events,
        exact: true,
    },
    {
        path: '/react/dictionaries/story',
        component: Story,
        exact: true,
    },
];

export default dictionariesRoutes;
