import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'redux/TrafficFlow/actions';
import { trafficFlowSelectors } from 'redux/TrafficFlow';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import DateRange from 'components/common/Dates/DateRange';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

function Filter({ onFilter, onReset, component = null }) {
    const dispatch = useDispatch();

    const heatMapFilter = useSelector(trafficFlowSelectors.heatMapFilter);

    const [disabledButton, setDisabledButton] = useState(false);

    const initialState = {
        startDate: new Date(new Date().getFullYear(), 0, 1),
        endDate: new Date(),
    };

    const [data, setData] = useState({
        ...initialState,
        ...heatMapFilter,
    });
    const [isFiltered, setIsFiltered] = useState(false);

    const handleChangeData = (name, value) => {
        setData({ ...data, [name]: value });
    };

    const filter = useMemo(() => {
        return removeEmptyFields({
            ...data,
            start_date: data.startDate ? dateWithDashYYYYMMDD(data.startDate) : null,
            end_date: data.endDate ? dateWithDashYYYYMMDD(data.endDate) : null,
        });
    },[data]);

    const search = () => {
        setIsFiltered(true);
        dispatch(actions.setFilterHeatMap(filter));
        onFilter(filter);
    };

    const resetSearch = () => {
        setData(initialState);
        dispatch(actions.setFilterHeatMap({}));

        if (isFiltered) {
            setIsFiltered(false);
            onReset();
        }
    };

    return (
        <div className="filter">
            <div className="filter__wrap filter__wrap__more__field">
                <DateRange
                    handleDate={(value) => handleChangeData('startDate', value)}
                    handleEndDate={(value) => handleChangeData('endDate', value)}
                    valueStartDate={data.startDate}
                    valueEndDate={data.endDate}
                    dateOnly={true}
                    isDisabledHandler={setDisabledButton}
                />

                {component}

            </div>
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.resetFilter,
                            onClick: resetSearch
                        },
                        {
                            ...buttonsTypes.search,
                            onClick: search,
                            disabled: Object.values(filter).length === 0 || disabledButton
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export default Filter;
