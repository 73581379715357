import { useState, SetStateAction } from 'react';

import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import SelectCompany from 'components/common/Autocomplete/Companies';
import GovernmentContractList from 'components/common/Autocomplete/GovernmentContract';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import { PageReportFields, useReportTemplatesFrontendLinks } from 'components/common/PageReport';

interface FilterState {
    start_date: Date | null;
    end_date: Date | null;
    organization_id: number | null;
    contract_id_list: Array<{ id: number, number: number }>;
    company: { id?: number };
}

const Filter: React.FC = () => {

    const templateProvider = useReportTemplatesFrontendLinks();
    const reportFilter = templateProvider.filter;

    const initialState: FilterState = {
        start_date: null,
        end_date: null,
        organization_id: null,
        contract_id_list: [],
        company: {},
    };

    const [filters, setFilters] = useState<FilterState>(initialState);

    const handleChange = (name: keyof FilterState, value: any) => {
        setFilters({ ...filters, [name]: value });
    };

    const download = () => {
        const newData = {
            ...filters,
            start_date: dateWithDashYYYYMMDD(filters.start_date),
            end_date: dateWithDashYYYYMMDD(filters.end_date),
            organization_id: filters?.company?.id,
            contract_id_list: filters?.contract_id_list?.map(({ id }) => id),
        };

        const {
            url,
            formats,
        } = reportFilter.getToApi();

        templateProvider.loadReport(
            url,
            formats,
            newData
        );
    };

    const resetFilter = () => setFilters(initialState);

    const updateFilter = (filter: SetStateAction<FilterState> = initialState) => {
        setFilters({
            ...initialState,
            ...filter
        });
    };

    const isDisabled = !filters?.start_date
        || !filters?.end_date
        || reportFilter.isDisabled;

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={download}
            filter={filters}
            filterException={['start_date', 'end_date']}
            setUserFilter={updateFilter}
            isFilterVisible={true}
            disabled={isDisabled}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filters.start_date}
                    valueEndDate={filters.end_date}
                    handleDate={(date: Date | null) => handleChange('start_date', date)}
                    handleEndDate={(date: Date | null) => handleChange('end_date', date)}
                    dateOnly
                />
                <SelectCompany
                    selected={filters.company}
                    onChange={(company) => handleChange('company', company)}
                    label="Балансодержатель"
                    withRole={false}
                    filter={{ withDeleted: 1 }}
                    selectDeletedCompanies
                />
                <GovernmentContractList
                    label="Госконтракт"
                    multiple
                    selected={filters?.contract_id_list || []}
                    onChange={(value) => handleChange('contract_id_list', value)}
                />
                <PageReportFields
                    reportType={reportFilter.type}
                    reportFormats={reportFilter.formats}
                    onReportType={reportFilter.setType}
                    onReportFormat={reportFilter.setFormats}
                    isOneTypeAuto
                    error={!reportFilter?.formats?.length}
                    required
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
