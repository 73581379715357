import { useMemo, useRef } from 'react';

import { loadPlanFactReportTypes } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import titles from 'helpers/constants/titles';
import { useStoreFromSelector } from 'helpers/hooks';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import SimpleSelect from 'components/common/SimpleSelect';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({
    filter = {},
    onChangeFilter,
    onReset,
    onSearch,
}) => {
    const initialState = useRef(filter);

    const types = useStoreFromSelector(loadPlanFactReportTypes, roadworksSelectors.planFactReportTypes);
    const typeList = useMemo(() => Object.keys(types).map(name => ({ name, title: types[name] })), [types]);

    const handleChange = (name, value) => {
        const newFiler = {
            ...filter,
            [name]: value,
        };
        onChangeFilter(newFiler);
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            onReset();
        }
        onChangeFilter(initialState.current);
    };

    const updateFilter = (filters) => {
        const params = {
            ...initialState.current,
            ...filters,
        };
        onChangeFilter(params);
        onSearch(params); // отправляем в родителя, чтобы произошел запрос
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={onSearch}
            filter={filter}
            filterException={['date']}
            setUserFilter={updateFilter}
            disabled={!filter.date}
        >
            <LayoutFilter.Visible>
                <SingleKeyboardDateTimePicker
                    value={filter.date}
                    onChange={(value) => handleChange('date', value)}
                    label={titles.DATE}
                    dateOnly={true}
                />
                <SelectCompany
                    selected={filter.organization}
                    onChange={(value) => handleChange('organization', value)}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />
                <SimpleSelect
                    selected={filter.type}
                    options={typeList}
                    onChange={(value) => handleChange('type', value)}
                    renderLabel={(option) => option?.title || ''}
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    disableReset
                    label="Тип отчета"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
