import { config } from 'config';

const apiReactUrl = () => config.get('urls').apiReactUrl;
const apiBase = () => config.get('urls').apiBase;

const apiUrls = {
    getIncidents: () => `${apiReactUrl()}/incident/list`,

    getIncidentById: (id) => `${apiReactUrl()}/incident/${id}`,

    createIncident: () => `${apiReactUrl()}/incident`,

    editIncident: (id) => `${apiReactUrl()}/incident/${id}`,

    deleteIncident: (id) => `${apiReactUrl()}/incident/${id}`,

    getOperationTypes: () => `${apiReactUrl()}/operation/types`,

    getTypicalOperations: () => `${apiReactUrl()}/operation/typical`,

    createTypicalOperation:  () => `${apiReactUrl()}/operation/typical`,

    editTypicalOperation:  (id) => `${apiReactUrl()}/operation/${id}`,

    deleteTypicalOperation:  (id) => `${apiReactUrl()}/operation/${id}`,

    getIncidentPolygon: () => `${apiReactUrl()}/incident/polygon`,

    getIncidentHeatMap: () => `${apiReactUrl()}/incident/heat-map`,

    getIncidentsStatuses: () => `${apiReactUrl()}/incident/statuses`,

    getVehicleCategories: () => `${apiReactUrl()}/incident/vehicle/types`,

    getThreatLevels: () => `${apiReactUrl()}/threat-level`,

    // createThreatLevel: () => `${apiReactUrl()}/threat-level`,

    // editThreatLevel: () => `${apiReactUrl()}/threat-level`,

    // deleteThreatLevel: () => `${apiReactUrl()}/threat-level`,

    getResponseScenarios: () => `${apiReactUrl()}/response-scenario/`,

    createResponseScenario: () => `${apiReactUrl()}/response-scenario`,

    getResponseScenarioById: (id) => `${apiReactUrl()}/response-scenario/${id}`,

    editResponseScenario: (id) => `${apiReactUrl()}/response-scenario/${id}`,

    deleteResponseScenario: (id) => `${apiReactUrl()}/response-scenario/${id}`,

    getCategories: () => `${apiReactUrl()}/category/`,

    getIncidentCategories: () => `${apiReactUrl()}/incident/categories`,

    getEvents: () => `${apiReactUrl()}/event/`,

    createEvent:  () => `${apiReactUrl()}/event/`,

    editEvent:  (id) => `${apiReactUrl()}/event/${id}`,

    deleteEvent:  (id) => `${apiReactUrl()}/event/${id}`,

    getEventObjects: () => `${apiReactUrl()}/event-object/`,

    createEventObjects:  () => `${apiReactUrl()}/event-object/`,

    editEventObjects:  (id) => `${apiReactUrl()}/event-object/${id}`,

    deleteEventObjects:  (id) => `${apiReactUrl()}/event-object/${id}`,

    getEventObjectsTypes: () => `${apiReactUrl()}/event-object/type/`,

    getIncidentEvents: (id) => `${apiReactUrl()}/incident/${id}/events`,

    getIncidentsFactors: () => `${apiReactUrl()}/factor`,

    createIncidentsFactors:  () => `${apiReactUrl()}/factor`,

    editIncidentsFactors:  (id) => `${apiReactUrl()}/factor/${id}`,

    deleteIncidentsFactors:  (id) => `${apiReactUrl()}/factor/${id}`,

    getPhenomenons: () => `${apiReactUrl()}/phenomenon/`,

    getTriggerStatuses: () => `${apiReactUrl()}/trigger/statuses`,

    getTriggers: () => `${apiReactUrl()}/trigger/list`,

    createTrigger: () => `${apiReactUrl()}/trigger`,

    editTrigger: (id) => `${apiReactUrl()}/trigger/${id}`,

    deleteTrigger: (id) => `${apiReactUrl()}/trigger/${id}`,

    getPolygonForTriggers: (url) => apiBase() ? `${apiBase()}${url}` : url,

    getIncidentTypes: () => `${apiReactUrl()}/type`,

    createIncidentTypes: () => `${apiReactUrl()}/type`,

    editIncidentTypes: (id) => `${apiReactUrl()}/type/${id}`,

    deleteIncidentTypes: (id) => `${apiReactUrl()}/type/${id}`,

    getIncidentTypeById: (id) => `${apiReactUrl()}/type/${id}`,

    getIncidentHistory: (id) => `${apiReactUrl()}/incident/${id}/history/human`,

    getKeyWordList: () => `${apiReactUrl()}/keyword`,

    addKeyWord: () => `${apiReactUrl()}/keyword`,

    editKeyWord: (id) => `${apiReactUrl()}/keyword/${id}`,

    deleteKeyWord: (id) => `${apiReactUrl()}/keyword/${id}`,

    getIncidentObjects: () => `${apiReactUrl()}/incident/objects`,

    getIncidentObjectTypes: () => `${apiReactUrl()}/incident/object/types`,

    getIncidentCloseReasons: () => `${apiReactUrl()}/incident/close/reasons`,

    getIncidentCloseTypes: () => `${apiReactUrl()}/incident/close/types`,

    getIncidentsNotifications: () => `${apiReactUrl()}/incident/notifications`,

    createIncidentsNotifications: () => `${apiReactUrl()}/incident/notifications`,

    getIncidentOperations: (id) => `${apiReactUrl()}/transport-incident/${id}/operations`,

    editIncidentOperations: (id) => `${apiReactUrl()}/transport-incident/${id}/operations`,

    loadInfopanel: () => `${apiReactUrl()}/transport-incident/info_panel`,

    getStories: () => `${apiReactUrl()}/story`,

    createStory: () => `${apiReactUrl()}/story`,

    editStory: (id) => `${apiReactUrl()}/story/${id}`,

    deleteStory: (id) => `${apiReactUrl()}/story/${id}`,

    getStoryStatuses: () => `${apiReactUrl()}/story/status`,

    getStoryConnections: () => `${apiReactUrl()}/story/connection`,

    getSettingsGroup: () => `${apiReactUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiReactUrl()}/settings_group/${id}/settings/update`,

    editSettingsList: () => `${apiReactUrl()}/settings/list`,
};

export default apiUrls;
