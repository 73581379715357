import { useEffect, useRef } from 'react';
import { Button } from '@mui/material';

import { getInitialFields } from '../helper';

import LineItem from './LineItem';

const SpeedLimits = ({ data,  setData, validation, fields }) => {
    const isFirstRender = useRef(true);
    const lines = data.lines || [];

    useEffect(() => {
        if (!isFirstRender.current) {
            setData({
                ...data,
                lines: data.lines.map(line => ({ ...line, direction: data.direction[line.direction_id]?.direction }))
            });
        } else isFirstRender.current = false;
    }, [data.direction]);

    const addLine = () => {
        const newLines = [
            ...lines,
            getInitialFields(fields?.fields)
        ];

        setData({
            ...data,
            lines: newLines
        });
    };

    const deleteLine = (index) => {
        setData({
            ...data,
            lines: [
                ...data.lines.slice(0, index),
                ...data.lines.slice(index + 1 ),
            ]
        });
    };

    const changeLine = (el, index) => {
        setData({
            ...data,
            lines: [
                ...data.lines.slice(0, index),
                el,
                ...data.lines.slice(index + 1 ),
            ]
        });
    };

    return (
        <>
            <h3 style={{ color: fields.require && lines.lengh === 0 ? '#d32f2f' : 'inherit' }}>Полосы {fields.require && '*'}</h3>
            <Button variant="text" onClick={() => addLine()}>
                Добавить полосу
            </Button>
            {lines?.map((line, index) => (
                <LineItem
                    key={index}
                    lines={lines}
                    index={index}
                    fields={fields?.fields}
                    validation={validation}
                    data={data}
                    line={line}
                    deleteLine={() => deleteLine(index)}
                    changeLine={(el) => changeLine(el, index)}
                />
            ))}
        </>
    );
};

export default SpeedLimits;
