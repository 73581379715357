import renderAddress from 'helpers/renderAddress';
import Information from 'components/common/Information';
import MapScroll from 'components/MapComponents/MapScroll';

const PopUp = ({ data }) => {

    const isNumber = (value) => typeof value === 'number';

    const fields = {
        address_text: {
            title: 'Адрес',
            value: ({ address, address_text }) => address ? renderAddress(address) : address_text,
        },
        // category: {
        //     title: 'Категория',
        //     value: ({ category }) => category?.name_ru,
        // },
        // created_at: {
        //     title: 'Дата создания',
        //     value: ({ created_at }) => getHumanDate(created_at),
        // },
        // fixated_at: {
        //     title: 'Дата регистрации',
        //     value: ({ fixated_at }) => getHumanDate(fixated_at),
        // },
        // description: {
        //     title: 'Описание',
        //     value: ({ description }) => description || messages.INFO_IS_NOT_FOUND,
        // },

        spaces_total: {
            title: 'Всего мест',
            value: ({ spaces }) => isNumber(spaces?.total)
                ? spaces?.total?.toString()
                : '0',
        },
        // spaces_common: {
        //     title: 'Общих',
        //     value: ({ spaces }) => isNumber(spaces?.common)
        //         ? spaces?.common?.toString()
        //         : '0',
        // },
        spaces_handicapped: {
            title: 'Места для инвалидов',
            value: ({ spaces }) => isNumber(spaces?.handicapped)
                ? spaces?.handicapped?.toString()
                : '0',
        },
        // spaces_fill: {
        //     title: 'Занято',
        //     value: ({ spaces, free_spaces }) => isNumber(spaces?.total) && spaces?.total > 0
        //         ? (spaces?.total - free_spaces || 0)?.toString()
        //         : '0',
        // },
        free_spaces: {
            title: 'Свободные места',
            value: ({ free_spaces }) => isNumber(free_spaces)
                ? free_spaces?.toString()
                : '0',
        },
    };

    return (
        <MapScroll>
            <Information
                data={data}
                title={fields}
                returnNull
            />
        </MapScroll>
    );
};

export default PopUp;
