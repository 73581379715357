import { config } from 'config';

const apiTransportPassengerUrl = () => config.get('urls').apiTransportPassengerUrl;

const apiUrls = {

    getCongestionTypes: () => `${apiTransportPassengerUrl()}/congestion/types`,

    getCongestionsHeatMap: () => `${apiTransportPassengerUrl()}/congestion/heat-map`,

    getCongestionsHeatMapSummary: () => `${apiTransportPassengerUrl()}/congestion/heat-map/summary`,

    getCongestionPolygon: () => `${apiTransportPassengerUrl()}/congestion/polygon`,

    getCongestionList: () => `${apiTransportPassengerUrl()}/congestion/list`,

    сongestionMarkRegular: (uuid) => `${apiTransportPassengerUrl()}/congestion/${uuid}/mark-as-regular`,

};

export default apiUrls;
