import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { difference } from 'lodash';

import {
    clearMarkersExtend,
    loadMilestoneEvents,
    setMarkersExtend,
    setWsTelemetry as setWsTelemetryTP,
} from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import * as actions from 'redux/TransportPassenger/actions';
import {
    TransportBigIcon,
    TransportMarker,
    TransportSmallIcon
} from 'components/MapComponents/leaflet/customComponents/leaflet.TransportMarker';
import { getTransportIcon } from 'components/MapComponents/leaflet/transportIcon/transportSvgIcon';
import { MapPopUp } from 'components/MapComponents/MapPopUp';
import { centerToLatLon } from 'components/MapComponents/helpers/goToGeometry';
import { usePrevious, useStoreFromSelector } from 'helpers/hooks';
import useWsSubscribe from 'helpers/ws/hooks/useWsSubscribe';
import compareEventWsFunc from 'helpers/ws/helper/compareEventWsFunc';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';

import { getKey, getVehicleCategory } from './helper';
import config from './config';
import TransportPopUp from './TransportPopUp';
import icons from './icons/icons';

// маркер карты
// todo продумать обновление иконки
const Markers = ({
    map,
    parent,
    onDestroy = null,
    transportHelper,
}) => {
    const dispatch = useDispatch();

    const getTransportCategory = useTransportCategory(
        actions.loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    const data = useSelector(transportPassengerSelectors.markers);
    const markersExtend = useSelector(transportPassengerSelectors.markersExtend);
    const statusObject = useStoreFromSelector(loadMilestoneEvents, transportPassengerSelectors.milestoneEvents);
    // костыль
    const refMilestoneEven = useRef({ statusObject });
    useEffect(() => {
        refMilestoneEven.current.statusObject = statusObject;
    }, [statusObject]);

    const dataKeys = Object.keys(data);
    const prevDataKeys = usePrevious(dataKeys);

    const addKeys = difference(dataKeys, prevDataKeys);
    const deleteKeys = difference(prevDataKeys, dataKeys);

    const markers = useRef({});

    const [modal, setModal] = useState(null);

    useEffect(() => {
        Object
            .keys(markersExtend)
            .map(key => {
                const item = markersExtend[key];
                const marker = markers.current[key]?.marker;
                marker?.setDelta(item.delta || 0);
                marker?.setEvent(item.event_id || 0);
            });
    }, [markersExtend]);

    // обработка элемента
    const wsTelemetryItem = (item) => {
        const key = getKey(item);
        // уже есть
        if (markers.current[key]) {
            const { lat, lon } = item;

            markers.current[key].latlon = [lat, lon];
            const marker = markers.current[key].marker;

            marker.setNewLatLon([lat, lon]);
            marker.setRoute(item.num || '');

            const vehicleCategory = getVehicleCategory(item);

            const {
                color,
                getImageTplHtml,
            } = transportHelper
                .getCategoryPassenger(vehicleCategory, item.in_registry, item.in_work_order);

            // обновление иконки категории
            marker.setImg(getImageTplHtml());
            // обновление цвета
            marker.setColor(color);
        }
    };

    // подключение к каналу
    useWsSubscribe('transport-passenger_telemetry_v2', (events) => {
        events.map(item => wsTelemetryItem(item));
        // dispatch(refreshCurrentMarkers(events));

        // объединение
        compareEventWsFunc(
            'transport-passenger_telemetry',
            (data) => dispatch(setWsTelemetryTP(data)),
            getKey
        ).list(events);
    });

    // канал опережение/опоздание
    useWsSubscribe('transport-passenger_wol_telemetry', (events) => {

        dispatch(setMarkersExtend(events
            .reduce((r, item) => ({
                ...r,
                [getKey(item)]: item,
            }), {})
        ));

        // events.map(item => {
        //     const key = getKey(item);
        //     markers.current[key]?.marker?.setDelta(item.delta || 0);
        // });

        // compareEventWsFunc(
        //     'transport-passenger_wol_telemetry',
        //     (data) => dispatch(setMarkersExtend(data)), // данные не очищаются
        //     getKey
        // ).list(events);
    });

    useEffect(() => () => {
        // очистка данных
        dispatch(clearMarkersExtend());
    }, []);

    const renderTooltip = (event) => {
        const vehicleCategory = getVehicleCategory(event);
        const findCategory = transportHelper
            ?.getName(vehicleCategory);
        const { num, external_id, in_registry, vehicle = null } = event;
        return `<div>
        ${num
            ? `<span>${findCategory || ''}${findCategory ? ' на' : 'На'} маршруте №${num}${!in_registry ? ' (отсутствует в реестре)' : ''}</span>`
            : `<span>${findCategory || config.name} (${external_id})</span>`
        }
        ${vehicle?.number ? `<div>ГРЗ: ${vehicle?.number}</div>` : ''}
        </div>`;
    };

    // добавление маркеров
    const handleAdd = (key) => {
        const item = markers.current[key];
        const {
            lat,
            lon,
            in_registry,
            in_work_order,
            num,
            direction,
        } = data[key];

        if (!item) {
            const vehicleCategory = getVehicleCategory(data[key]);

            const {
                color,
                getImageTplHtml,
            } = transportHelper
                .getCategoryPassenger(vehicleCategory, in_registry, in_work_order);

            // минимальная иконка
            const minimizeIcon = (options = {}) => new TransportSmallIcon({
                color,
                iconSize: [10, 10],
                iconAnchor: [5, 5],
                className: 'tp-marker',
                ...options
            });

            // промежуточная иконка
            const mediumIcon = (options = {}) => new TransportSmallIcon({
                color,
                iconSize: [14, 14],
                iconAnchor: [7, 7],
                className: 'tp-marker',
                ...options
            });

            // иконка транспорта
            const maxiIcon = (options = {}) => new TransportBigIcon({
                iconSize: [40, 40],
                iconAnchor: [20, 20],
                className: 'tp-marker',
                bgIcon: (color) => getTransportIcon({ fill: color, stroke: '#fff', strokeWidth: 3 }),
                img: getImageTplHtml(),
                color,
                route: num || '',
                // delta: 100,
                direction,
                ...options
            });

            const newMarker = new TransportMarker(
                [lat, lon],
                {
                    id: key,
                    tooltip: renderTooltip(data[key]),
                    deltaTooltip: (delta, event_id) => {
                        const eventText = refMilestoneEven.current?.statusObject?.[event_id] || 'Нет данных';

                        const getDelta = () => {
                            if (delta === 0) return '';

                            const secondsAbs = Math.abs(delta);
                            const getTime = value => Math.floor(value).toString().padStart(2, '0');

                            const time = [
                                getTime(secondsAbs / 3600),
                                getTime(secondsAbs % 3600 / 60),
                                getTime(secondsAbs % 60)
                            ].join(':');

                            return `: <span class="delta-tooltip">${delta > 0 ? '+' : '-'}${time}</span>`;
                        };

                        return `${eventText}${getDelta()}`;
                    }
                },
                // набор иконок для зумов
                {
                    0: minimizeIcon,
                    10: mediumIcon,
                    13: maxiIcon
                }
            );
            parent.addLayer(newMarker);

            newMarker.on('click', () => {
                centerToLatLon(map, newMarker.getLatLng());
                setModal(key);
            });

            markers.current[key] = {
                marker: newMarker,
                latlon: [lat,lon],
            };
        }
    };

    const handleDelete = (key) => {
        if (markers.current[key]) {
            const {
                marker,
            } = markers.current[key];
            marker.off('click');

            parent.removeLayer(marker);

            if (key === modal) {
                setModal(null);
            }

            marker.remove();
            delete markers.current[key];
        }
    };

    useEffect(() => {
        // добавляем маркеры
        if (addKeys.length) {
            addKeys.map((itemKey) => {
                if (data[itemKey]) {
                    handleAdd(itemKey);
                }
            });
        }
    }, [addKeys]);

    useEffect(() => {
        // удаляем маркеры
        if (deleteKeys.length) {
            deleteKeys.map((itemKey) => {
                handleDelete(itemKey);
            });
        }
    }, [deleteKeys]);

    useEffect(() => {
        return () => {
            Object.keys(markers.current).map((itemKey) => {
                handleDelete(itemKey);
            });
        };
    }, []);

    useEffect(() => {
        return () => {
            onDestroy && dispatch(onDestroy());
        };
    }, []);

    // модалка транспорта
    const renderPopUp = (uuid) => {
        if (!uuid) return null;

        const marker = data?.[uuid] || {};
        const {
            in_registry,
            in_work_order,
            external_id,
            num,
        } = marker;

        const vehicleCategory = getVehicleCategory(marker);
        const {
            color: categoryColor,
            getImageTpl,
        } = getTransportCategory
            .getHelper
            .getCategoryPassenger(vehicleCategory, in_registry, in_work_order);

        const categoryName = getTransportCategory
            .getHelper
            .getName(vehicleCategory);

        return (
            <MapPopUp
                isOpen={!!modal}
                data={modal}
                icon={() => getImageTpl('img', icons({ fill: '#000' }))}
                title={num
                    ? `${categoryName || ''}${categoryName ? ' на' : 'На'} маршруте №${num}${in_registry === false ? ' (отсутствует в реестре)' : ''}`
                    : `${categoryName || config.name} (${external_id})`
                }
                titleBackgroundColor={categoryColor}
                onClose={() => setModal(null)}
            >
                <TransportPopUp onClosePopUp={() => setModal(null)}/>
            </MapPopUp>
        );
    };

    return renderPopUp(modal);
};

export default Markers;
