import { isValue } from 'helpers/checkValue';
import Image from 'components/common/Image';
import { checkBase64 } from 'components/MapComponents/Layers/TransportPassenger/helper';
import type { DuploCodesItem } from 'components/pages/Dictionaries/DorisControl/DuploCodes/types';

interface DuploCodeItemProps {
    item: DuploCodesItem;
    hideImage?: boolean;
    className?: string;
    test_id_prefix?: string;
}

const DuploCodeItem = ({ item, hideImage, className, test_id_prefix }: DuploCodeItemProps) => {
    return (
        <div className={className ?? 'row align-items-center'}>
            {!hideImage && (
                <>
                    <Image
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 0,
                            width: '40px',
                            height: '40px',
                        }}
                        src={checkBase64(item?.icon)}
                        alt=""
                        data-testid={`${test_id_prefix}:item/img`}
                    />
                    &nbsp;
                </>
            )}

            {isValue(item?.id) && <span>{item.id} </span>}
            {item?.gost_code && <span>{item.gost_code} </span>}
            {item?.name && <span data-testid={`${test_id_prefix}:item/name`}>{item.name} </span>}
            {item?.koap_name && <span>({item.koap_name})</span>}
        </div>
    );
};

export default DuploCodeItem;
