import {
    IconButton,
    Tooltip,
} from '@mui/material';

const LineButton = ({
    tooltip = '',
    onClick,
    isVisible,
}) => (
    <Tooltip title={tooltip}>
        <IconButton
            size="small"
            onClick={onClick}
            color={isVisible ? 'primary' : 'default'}
        >
            <i className="fas fa-ruler"></i>
        </IconButton>
    </Tooltip>
);

export default LineButton;
