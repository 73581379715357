import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';

import { deleteAppeal } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import { fullDateTime } from 'helpers/date.config';
import ConfirmDelete from 'components/common/ConfirmModal';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem,
} from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import Info from './Info';
import ModalForm from './ModalForm';

function Item({ item, statusColors }) {
    const dispatch = useDispatch();

    const [openInfo, setOpenInfo] = useState(false);
    const toggleOpenInfo = () => setOpenInfo(!openInfo);
    const [openModal, setOpenModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const handleDelete = () => {
        dispatch(deleteAppeal(item.id));
    };

    const statusRender = () => (item?.status_id
        ? <Tooltip title={item.status_text || ''}>
            <div style={{ backgroundColor: statusColors[item.status_id] || '#000', width: 20, height: 20, borderRadius: '50%' }}></div>
        </Tooltip>
        : messages.NO_VALUE
    );

    return (
        <>
            <LSContentItem onClick={toggleOpenInfo}>
                <LSContentColumn>
                    {statusRender()}
                </LSContentColumn>

                <LSContentColumn>
                    {item.id || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.type_text || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.title || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {fullDateTime(item.created_at) || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setIsDelete(true),
                            }
                        ]}
                    />

                    <ActionMore
                        isOpen={openInfo}
                        // onClick={toggleOpenInfo}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openInfo && <Info isOpen={openInfo} info={item}/>}

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={()=> setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}

            {openModal && (
                <ModalForm
                    isNew={false}
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    el={item}
                />
            )}
        </>
    );
}

export default Item;
