import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { loadCameraTypes } from 'modules/InfrastructureObjects/redux/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';

interface SelectTypeProps {
    value: string
    onChange: (value: string) => void
    required?: boolean
    error?: boolean
    className?: string
}

const SelectType = ({ value, onChange, className, required, error = false }: SelectTypeProps) => {
    const types = useStoreProp(loadCameraTypes, 'roadNetworks', 'cameraTypes');

    return (
        <FormControl className={className} size="small" variant="outlined" required={required} error={error}>
            <InputLabel>Тип</InputLabel>

            <Select
                value={value}
                onChange={(e) => onChange(e.target.value)}
                label={titles.TYPE}
                name="type_id"
            >
                {Object?.keys(types)?.map((key) => (
                    <MenuItem key={key} value={key}>
                        {types[key]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SelectType;
