import * as types from './types';

const initialState = {
    menuList: [],
    loading: false,
    activeId: '',
    activeLayers: [],
    selectedLayer: null,
    attachList: [],
    showReturnTo: {},
    pageHeaders: []
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADING:
            return {
                ...state,
                loading: payload
            };
        case types.LOADED:
            return {
                ...state,
                menuList: payload
            };
        case types.MENU_GET_ID:
            return {
                ...state,
                activeId: payload
            };
        case types.SAVE_ACTIVE_LAYER:
            return {
                ...state,
                activeLayers: payload || []
            };
        case types.ADD_ACTIVE_LAYER: {
            if (state.activeLayers.includes(payload)) {
                return {
                    ...state,
                };
            }
            return {
                ...state,
                activeLayers: [
                    ...state.activeLayers,
                    payload,
                ]
            };
        }
        case types.SELECT_LAYER: {
            return {
                ...state,
                selectedLayer: payload
            };
        }
        case types.ADD_ATTACH_LAYERS:
            return {
                ...state,
                attachList: payload
            };

        case types.SET_SHOW_RETURN_TO:
            return {
                ...state,
                showReturnTo: payload
            };
        case types.LOADED_PAGE_HEADERS: {
            return {
                ...state,
                pageHeaders: payload
            };
        }
        default:
            return state;
    }
}
