import { useMemo } from 'react';

import { loadDtpLinkTypes } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { useStoreFromSelector } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import { generateOptions } from '../helper';

const SelectDtpLinkType = ({
    multiple = false,
    value = multiple ? [] : null,
    onChange = () => {},
    required = false,
    disabled = false,
    label='Тип связи',
    error = false,
    helperText = '',
    limitTags = 1
}) => {

    const types = useStoreFromSelector(loadDtpLinkTypes, incidentsSelectors.dtpLinkTypes);
    const typesOptions = useMemo(() => generateOptions(types), [types]);

    const valueOptions = useMemo(() => {
        if (!value) return {};
        return multiple
            ? value?.map(key => ({
                id: key,
                name: types?.[key] || ''
            }))
            : {
                id: value,
                name: types?.[value] || ''
            };
    }, [value, multiple, types]);

    const handleChange = (value) => {
        onChange(
            value?.id || null,
            value
        );
    };

    const handleReset = () => {
        onChange(multiple ? [] : null);
    };

    return (
        <CommonAutocomplete
            multiple={multiple}
            selected={valueOptions}
            onChange={handleChange}
            onReset={handleReset}
            required={required}
            disabled={disabled}
            renderLabel={(option) => option?.name || ''}
            label={label}
            error={error}
            helperText={helperText}
            options={typesOptions}
            limitTags={limitTags}
            isOptionEqualToValue={(option, value) => parseInt(option?.id) === parseInt(value?.id)}
            disableCloseOnSelect={multiple}
            filterSelectedOptions={!multiple}
            showAsSelect
        />
    );
};

export default SelectDtpLinkType;
