import { useState } from 'react';
import {
    Tabs as TabContainer,
    TabPanel as TabContent,
    Tab as TabHeaderItem,
    TabList as TabHeaders
} from 'react-tabs';

// interface TabListComponentProps {
//     defaultTabKey?: string; // установить вкладку по умолчанию - key
//     defaultTabIndex?: number; // установить вкладку по умолчанию - индекс
//     // список
//     list: Array<{
//         key?: string, // не обязательно
//         icon?: React.ReactElement | string, // иконка
//         disabled?: boolean, // неаквтивно
//         title: string, // заголовок
//         component: React.ReactElement | string, // компонент
//     }>;
// }

// список табов
const TabList = ({
    // дефолтно открытый таб по ключу
    defaultTabKey,
    // дефолтно открытый таб по индексу
    defaultTabIndex = 0,
    // табы
    list = [],
}) => {
    const defaultTabKeyValue = defaultTabKey
        ? list?.findIndex(({ key }) => key === defaultTabKey)
        : null;
    const [tabIndex, setTabIndex] = useState(defaultTabKeyValue || defaultTabIndex);

    return (
        <TabContainer
            selectedIndex={tabIndex}
            onSelect={setTabIndex}
            style={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1 auto',
            }}
        >
            {/* заголовки */}
            <TabHeaders>
                {list?.map(({ title, icon = null, component, key, disabled = false }, index) => (
                    <TabHeaderItem key={key || index} disabled={disabled} icon={icon}>
                        {title}
                    </TabHeaderItem>
                ))}
            </TabHeaders>

            {/* контент */}
            {list?.map(({ component, key }, index) => (
                <TabContent key={key || index} style={{ height: '100%' }}>
                    {tabIndex === index
                        ? component
                        : null
                    }
                </TabContent>
            ))}
        </TabContainer>
    );
};

export default TabList;
