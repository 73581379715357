import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ConfirmDelete from 'components/common/ConfirmModal';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import Modal from 'components/common/Modal';
import { digitalTwinSelectors } from 'modules/DigitalTwin';
import ModalForm from 'modules/DigitalTwin/Dictionaries/Nodes/ModalForm';
import { deleteNodes, loadingButton } from 'modules/DigitalTwin/redux/actions';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons } from 'components/common/List';

import type { CellContext } from '@tanstack/react-table';

import { helpers, ObjectBindingModal } from 'modules/DigitalTwin/utils/components/ObjectBindings';
import type { Node } from '../../utils/types';

interface ActionsProps {
    item: CellContext<Node, unknown>;
    reloadList: (isDelete?: boolean) => void;
}

function Actions({ item, reloadList }: ActionsProps) {
    const dispatch = useDispatch();

    const loading = useSelector(digitalTwinSelectors.loadingButton);

    const [modalState, setModalState] = useState({
        map: false,
        edit: false,
        delete: false,
        link: false,
    });

    const openModal = (key: keyof typeof modalState) => {
        setModalState((prev) => ({ ...prev, [key]: true }));
    };

    const closeModal = (key: keyof typeof modalState) => {
        setModalState((prev) => ({ ...prev, [key]: false }));
    };

    const handleDelete = () => {
        const callback = () => {
            reloadList(true);
            closeModal('delete');
        };

        dispatch(deleteNodes(item.row.original.id, callback, loadingButton));
    };

    // const isPinned = item.row.getIsPinned() === 'top';

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <ActionsButtons
                    buttons={[
                        // {
                        //     icon: <i class="far fa-thumbtack" />,
                        //     name: isPinned ? 'Открепить' : 'Закрепить',
                        //     onClick: () => (isPinned ? item.row.pin(false) : item.row.pin('top')),
                        // },
                        {
                            ...buttonsTypes.mapIcon,
                            onClick: () => openModal('map'),
                            disabled: !item.row.original?.type,
                        },
                        {
                            ...buttonsTypes.linkIcon,
                            onClick: () => openModal('link'),
                        },
                        {
                            ...buttonsTypes.editIcon,
                            onClick: () => openModal('edit'),
                        },
                        {
                            ...buttonsTypes.deleteIcon,
                            onClick: () => openModal('delete'),
                        },
                    ]}
                />

                {/*{item.row.getIsPinned() === 'top' && <PushPinIcon color={'disabled'}/>}*/}
            </div>



            {modalState.map && (
                <Modal
                    isOpen={modalState.map}
                    onClose={() => closeModal('map')}
                    noPadding
                    buttons={
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => closeModal('map'),
                                },
                            ]}
                        />
                    }
                >
                    <MapDragMarker {...item.row.original} />
                </Modal>
            )}

            {modalState.edit && (
                <ModalForm
                    isOpen={modalState.edit}
                    onClose={() => closeModal('edit')}
                    reloadList={reloadList}
                    item={item.row.original}
                />
            )}

            {modalState.delete && (
                <ConfirmDelete
                    open={modalState.delete}
                    onSuccess={handleDelete}
                    loadingButton={loading}
                    onClose={() => closeModal('delete')}
                />
            )}

            {modalState.link && (
                <ObjectBindingModal
                    isOpen={modalState.link}
                    currentId={item.row.original.id}
                    currentType={helpers.objectTypes.node}
                    currentLatLon={
                        item.row.original.lat && item.row.original.lon
                            ? [item.row.original.lat, item.row.original.lon]
                            : null
                    }
                    onClose={() => closeModal('link')}
                />
            )}
        </>
    );
}

export default Actions;
