import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

import { loadDtpById } from 'redux/Incidents/actions';
import TabList from 'components/MapComponents/TabList';
import MapScroll from 'components/MapComponents/MapScroll';
import { createIcon, MapPopUp }  from 'components/MapComponents/MapPopUp';

import configLayer from '../config';
import { getColor } from '../helper';

import TabMembers from './TabComponents/TabMembers';
import TabVehicles from './TabComponents/TabVehicles';
import InfoTrafficBlocking from './InfoTrafficBlocking';
import TabInfo from './TabComponents/TabInfo';
import TabSituation from './TabComponents/TabSituation';
import Links from './TabComponents/Links';
import TabHistory from './TabComponents/TabHistory';

// import ChangeStatus from './PopUp/ChangeStatus';

const PopUpModal = ({
    readOnly,
    isTrafficBlocking,
    data = {},
    typesAll,
    isOpen,
    onClose,
}) => {
    const dispatch = useDispatch();

    const [dtp, setDtp] = useState(data);

    useEffect(() => {
        if (data?.id) {
            dispatch(loadDtpById(data.id, setDtp));
        }
    }, [data.id, dispatch]);

    const list = useMemo(() => {
        return [
            {
                key: 'information',
                title: 'Информация',
                component: (
                    <MapScroll>
                        <TabInfo data={dtp} readOnly={readOnly} />
                    </MapScroll>
                )
            },
            {
                key: 'members',
                title: 'Участники ДТП',
                component: (
                    <MapScroll>
                        <TabMembers data={dtp?.members || []} />
                    </MapScroll>
                )
            },
            {
                key: 'vehicles',
                title: 'Транспортные средства',
                component: (
                    <MapScroll>
                        <TabVehicles data={dtp?.vehicles || []} />
                    </MapScroll>
                )
            },
            {
                key: 'situation',
                title: 'Обстановка',
                component: (
                    <MapScroll>
                        <TabSituation data={dtp} />
                    </MapScroll>
                )
            },
            {
                key: 'links',
                title: 'Связь',
                component: (
                    <MapScroll>
                        <Links
                            data={dtp}
                            // links={links}
                            // onRefresh={handleRefresh}
                        />
                    </MapScroll>
                )
            },
            {
                key: 'history',
                title: 'История изменений',
                component: (
                    <MapScroll>
                        <TabHistory data={dtp} />
                    </MapScroll>
                )
            },
        ];
    }, [dtp]);


    return (
        <MapPopUp
            isOpen={isOpen}
            title="ДТП"
            icon={createIcon(configLayer.layerIcon)}
            // titleButtons={
            //     <ChangeStatus
            //         data={data}
            //         readOnly={readOnly}
            //     />
            // )}
            onClose={onClose}
            titleBackgroundColor={getColor(dtp, typesAll.get)}
            medium
        >
            {isTrafficBlocking
                // Перекрытие движения
                ? (
                    <MapScroll>
                        <InfoTrafficBlocking data={dtp} />
                    </MapScroll>
                )
                // стандартные данные
                : (
                    <TabList list={list} />
                )
            }
        </MapPopUp>
    );
};

export default PopUpModal;
