import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getHashString from '../getHashString';

import type { DefaultRootState } from 'react-redux';
import type { Action } from 'types/common';

const mutex: { [key: string]: any } = {};

// получить поле из store с загрузкой данных
const useStoreFromSelector = (
    fetchAction: Action,
    selector: (state: DefaultRootState) => unknown,
) => {
    const dispatch = useDispatch();

    const storeField: any = useSelector(selector);
    const selectorHash: number = useMemo(() => getHashString(selector.toString()), [selector]);

    useEffect(() => {
        const isEmptyArray = Array.isArray(storeField) && storeField?.length === 0;
        const isEmptyObject = typeof storeField === 'object' && Object.keys(storeField).length === 0;

        if ((isEmptyArray || isEmptyObject) && !mutex[selectorHash]) {
            mutex[selectorHash] = true; // для предотвращения бесконечных запросов если данных нет
            dispatch(fetchAction());
        }
    }, [storeField, dispatch, fetchAction, selectorHash]);

    useEffect(() => {
        return () => {
            mutex[selectorHash] = false;
        };
    }, [selectorHash]);

    return storeField;
};

export default useStoreFromSelector;
