import React from 'react';

import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import PasswordInput from 'components/common/PasswordInput/PasswordInput';

const RenderPasswords = ({ password = '', password_confirmation = '', onChange }) => {
    const validation = useValidation();
  
    return (
        <div className="row">
            <PasswordInput
                value={password}
                name="password"
                label={titles.PASSWORD}
                validation={!!validation.get('password')}
                helperText="Пароль должен быть от 9 до 30 символов в нем должны содержаться минимум символ, цифра, большие и маленькие буквы."
                onChange={onChange}   
            />
            <PasswordInput
                value={password_confirmation}
                name="password_confirmation"
                label={titles.REPEAT_PASSWORD}
                validation={!!validation.get('password_confirmation')}
                helperText={validation.get('password_confirmation')}
                onChange={onChange}  
            />
        </div>
    );
};

export default RenderPasswords;