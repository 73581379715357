import { useDispatch } from 'react-redux';

import { setActive } from 'redux/TrafficLights/actions';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    createIcon as createIconTitle,
    MapPopUp,
} from 'components/MapComponents/MapPopUp';
import MapScroll from 'components/MapComponents/MapScroll';

import config from '../config';

import Information from './Information';


const PopUpModal = ({
    isOpen,
    data,
}) => {
    const dispatch = useDispatch();

    const { name, color } = data || {};

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={createIconTitle(config.layerIcon)}
            title={name}
            onClose={() => dispatch(setActive({}))}
            titleBackgroundColor={colorExtend(color)}
            medium
        >
            <MapScroll>
                <Information
                    data={data}
                />
            </MapScroll>
        </MapPopUp>
    );
};

export default PopUpModal;
