import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, FormGroup, FormHelperText, TextField } from '@mui/material';

import { createLibraryAPI, editLibraryAPI } from 'redux/Admin/actions';
import useValidation from 'helpers/hooks/useValidation';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

import styles from './styles.module.scss';

const Form = ({
    item = {},
    onClose = () => {},
    isOpen = false,
    reloadList
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initialState = {
        name: item.name || '',
        microservice: item.microservice || '',
        url: item.url || '',
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        if (validation[name]) validation.deleteKey(name);
    };

    const onSuccess = () => {
        const saveFn = item.id ? editLibraryAPI : createLibraryAPI;
        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(saveFn(formData, callback));
    };

    const inputSettings = {
        name: {
            label: 'Название',
            required: true,
            disabled: !!formData.id
        },
        microservice: {
            label: 'Микросервис',
            required: true,
        },
        url: {
            label: 'URL',
            required: false
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title={item.id ? 'Изменить' : 'Добавить'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSuccess,
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormGroup>
                    {Object.keys(inputSettings).map((key) => {
                        const { required, label, disabled } = inputSettings[key];
                        return (
                            <FormControl className="block" variant={'outlined'} key={key}>
                                <TextField
                                    type="text"
                                    value={formData[key] || ''}
                                    name={key}
                                    onChange={handleChange}
                                    error={!!validation.get(key)}
                                    disabled={disabled}
                                    required={required}
                                    label={label}
                                    variant="outlined"
                                    size="small"
                                />
                                <FormHelperText className={styles.error}>{validation.get(key)}</FormHelperText>
                            </FormControl>);
                    })}
                </FormGroup> 
            </div>
        </Modal>
    );
};

export default Form;
