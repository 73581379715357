import { useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
    Autocomplete,
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';

import { useValidation } from 'helpers/hooks';
import keyboardKeys from 'helpers/constants/keyboardKeys';
import UniversalSelect from 'components/common/UniversalSelect';

import type { DevicesAutocompleteProps } from '../types';

const useStyles = makeStyles({
    input: {
        paddingRight: '54px !important',
    },
    adornment: {
        position: 'absolute',
        right: '6px',
    },
});

const DevicesAutocomplete = ({
    value,
    onChange,
    actions,
    storeName,
    required
}: DevicesAutocompleteProps) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const validation = useValidation();

    const [showSelect, setShowSelect] = useState(false);

    const handleAccept = (data: any) => {
        onChange(data);
        setShowSelect(false);
    };

    const loadList = (params: { page: number, limit: number, query?: string }) => {
        const { page, limit, query } = params;

        dispatch(actions.loadDevices({ page, limit, ...{ name: query, in_group: 0 } }));
    };

    const autoCompleteComplexChange = (e: any, newValue: any) => {
        if (e?.key !== keyboardKeys.ENTER) onChange(newValue);
    };

    return (
        <>
            <Autocomplete
                className="block"
                multiple
                value={value}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={[]}
                filterSelectedOptions
                getOptionLabel={(option) => option?.name || ''}
                onChange={autoCompleteComplexChange}
                freeSolo
                size="small"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        required={required}
                        label="Станции"
                        InputProps={{
                            ...params.InputProps,
                            classes: { root: styles.input },
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    classes={{ root: styles.adornment }}
                                >
                                    {value && Object.keys(value).length > 0 && (
                                        <IconButton size="small" onClick={() => onChange([])}>
                                            <i className="far fa-times" />
                                        </IconButton>
                                    )}
                                    <IconButton size="small" onClick={() => setShowSelect(true)}>
                                        <i className="fas fa-search-plus" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={
                            validation.isKey('device_ids') || validation.isKey('device_id')
                        }
                        helperText={
                            (validation.isKey('device_ids')
                                || validation.isKey('device_id'))
                            && (validation.get('device_ids') || validation.get('device_id'))
                        }
                    />
                )}
            />

            {showSelect && (
                <UniversalSelect
                    multiple
                    fetchList={loadList}
                    storeName={storeName}
                    storeNameProps="list"
                    keyProp="id"
                    isSelected
                    selected={value}
                    renderProps={(el: any) => `${el.name}`}
                    isOpen={showSelect}
                    onClose={() => setShowSelect(false)}
                    onAccept={handleAccept}
                    noPadding
                    title="Выбрать свободную станцию"
                    withSearch
                    notFoundText="Не найдено свободных станций"
                />
            )}
        </>
    );
};

export default DevicesAutocomplete;
