import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import { getStatusesRW, loadTypeCreation } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import buttons from 'helpers/constants/buttons';
import useCompany from 'helpers/hooks/useCompany';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SelectInfrastructures from 'components/common/Autocomplete/RoadNetwork/Infrastructure';
import Attachments from 'components/common/Upload/Attachments';
import Modal from 'components/common/Modal';
import Companies from 'components/common/Autocomplete/Companies';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import SelectTrafficFlow from 'components/common/Autocomplete/TrafficFlow';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import FieldsModal from 'components/common/Location/FieldsModal';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import TitleValidator from 'components/common/Location/TitleValidator';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';
import InfoBlock from 'components/common/InfoBlock';

import Defects from './Defects';
import NearDefects from './NearDefects';

const EditorForm = (props) => {
    const { dataForm, onClose, onSuccess, isOpen, isNew = true } = props;
    const loading = useSelector(roadworksSelectors.loading);
    const statuses = useStoreProp(getStatusesRW, 'roadworks', 'statuses');
    const typeCreation = useStoreProp(loadTypeCreation, 'roadworks', 'typeCreation');
    const validation = useValidation();

    const initialFields = {
        ...dataForm,
        name: dataForm?.name || '',
        is_can_deleted: dataForm?.is_can_deleted || false,
        status: dataForm?.status || 1,
        description: dataForm?.description || '',
        troubleshooting_plan: dataForm?.troubleshooting_plan ? new Date(dataForm?.troubleshooting_plan) : '',
        troubleshooting_fact: dataForm?.troubleshooting_fact ? new Date(dataForm?.troubleshooting_fact) : '',
        infrastructures: dataForm?.infrastructures || [],
        fixated_at: dataForm?.fixated_at ? new Date(dataForm?.fixated_at) : '',
        defections: dataForm?.defections || [],
        type_creation: dataForm?.type_creation || 1,
        address_text: dataForm?.address_text || '',
        detectors: dataForm?.detectors || [],
        detector_id_list: dataForm?.detector_id_list || [],
        data: dataForm?.data,
        organization: dataForm?.organization || {},
        organization_id: dataForm?.organization_id || null,
    };

    const company = useCompany(dataForm?.organization_id, dataForm?.organization);

    const [fields, setFields] = useState(initialFields);

    useEffect(() => {
        if (company && Object.keys(company).length > 0) {
            setFields((prev) => ({
                ...prev,
                organization: company
            }));
        }
    }, [company]);

    const [dislocationData, setDislocationData] = useState(
        dataForm?.address
            ? {
                lat: dataForm?.address?.geo_lat,
                lon: dataForm?.address?.geo_lon,
            }
            : {}
    );

    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleSuccess = () => {
        if (onSuccess) {
            const { address = null } = fields;
            const address_text = () => {
                if (address) {
                    const { city_name = '', street = '', house = '' } = address;
                    return [city_name, street, house].join(', ');
                }
                return '';
            };

            onSuccess({
                ...fields,
                fixated_at: fields.fixated_at ? dateWithDashYYYYMMDD(fields.fixated_at) : null,
                troubleshooting_fact: fields.troubleshooting_fact ? dateWithDashYYYYMMDD(fields.troubleshooting_fact) : null,
                troubleshooting_plan: fields.troubleshooting_plan ? dateWithDashYYYYMMDD(fields.troubleshooting_plan) : null,
                is_can_deleted: false,
                address_text: address_text(),
            });
        }
    };
    const changeObj = (props) => {
        setFields((oldFields) => ({
            ...oldFields,
            ...props,
        }));

        if (props.hasOwnProperty('lat') || props.hasOwnProperty('lon')) {
            setDislocationData({
                ...props
            });
        }
    };

    const handleChange = (name) => (value) => {
        validation.deleteKey(name);
        setFields((oldFields) => ({
            ...oldFields,
            [name]: value,
        }));
    };

    const handleChangeGeometry = ({ address, address_text }) => {
        validation.deleteKey('address');
        validation.deleteKey('address_text');

        setFields({
            ...fields,
            address,
            address_text,
        });
        setDislocationData({
            lat: address?.geo_lat,
            lon: address?.geo_lon,
        });
    };

    const isDisabled = fields.name.length === 0
        || !fields.fixated_at
        || !fields.data
        || !(fields.address && fields.address_text);

    return (
        <Modal
            isOpen={isOpen}
            noPadding
            title={isNew ? 'Добавление участка' : 'Редактирование участка'}
            maxWidthProp={'md'}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: handleClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSuccess,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <div className="block">
                    <TextField
                        className="block"
                        size="small"
                        multiline
                        variant="outlined"
                        label="Название участка"
                        maxRows={5}
                        required={true}
                        value={fields.name}
                        onChange={(event) => {
                            handleChange('name')(event.target.value);
                        }}
                        helperText={validation.get('name')}
                        error={validation.isKey('name')}
                    />
                </div>

                <div className="row">
                    {fields?.created_at && (
                        <SingleKeyboardDateTimePicker
                            value={fields?.created_at || null}
                            onChange={handleChange('created_at')}
                            label={titles.DATE_TIME_CREATED}
                            isDisabled={true}
                            className={'row__item'}
                        />
                    )}

                    <SingleKeyboardDateTimePicker
                        value={fields?.fixated_at || null}
                        onChange={handleChange('fixated_at')}
                        label="Дата регистрации"
                        dateOnly={true}
                        error={validation.isKey('fixated_at')}
                        helperText={validation.get('fixated_at')}
                        required
                        className={'row__item'}
                    />
                </div>

                <div className="row">
                    <SingleKeyboardDateTimePicker
                        value={fields?.troubleshooting_plan || null}
                        onChange={handleChange('troubleshooting_plan')}
                        label="Дата окончания плановая"
                        dateOnly={true}
                        className="row__item"
                        disableFuture={false}
                    />
                    <SingleKeyboardDateTimePicker
                        value={fields?.troubleshooting_fact || null}
                        onChange={handleChange('troubleshooting_fact')}
                        label="Дата окончания фактическая"
                        dateOnly={true}
                        className="row__item"
                    />
                </div>

                <FormControl className="block" required={true} size="small" variant="outlined">
                    <InputLabel>{titles.SELECT_STATUS}</InputLabel>
                    <Select
                        value={fields.status}
                        onChange={(event) => {
                            handleChange('status')(event.target.value);
                        }}
                        label={titles.SELECT_STATUS}
                    >
                        {Object.keys(statuses).map((id) => (
                            <MenuItem key={id} value={id}>
                                {statuses[id]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    className="block"
                    size="small"
                    multiline
                    variant="outlined"
                    label={titles.DESCRIPTION}
                    maxRows={5}
                    defaultValue={fields.description}
                    onChange={(event) => {
                        handleChange('description')(event.target.value);
                    }}
                    helperText={validation.get('description')}
                    error={validation.isKey('description')}
                />
                <div className="block">
                    <SelectInfrastructures
                        onChange={(infraList) => {
                            handleChange('infrastructures')(infraList);
                        }}
                        selected={fields.infrastructures}
                        helperText={validation.get('infrastructures')}
                        error={validation.isKey('infrastructures')}
                        multiple
                    />
                </div>

                <InfoBlock label={titles.LOCATION} className="block">
                    <div className="block">
                        <LoadAddressByCoords
                            {...fields}
                            onChange={handleChangeGeometry}
                            isLoadOnChange
                        >
                            <AddressList required />
                        </LoadAddressByCoords>
                    </div>

                    <div className="block">
                        <MapDragMarker
                            {...dislocationData}
                            onChange={changeObj}
                            required
                        />
                    </div>

                    <div className="block">
                        <TitleValidator
                            fields={fields}
                            validationFields={['data']}
                        >
                            {titles.STOP_AREA} *:
                        </TitleValidator>

                        {validation.isKey('data')
                            ? (
                                <FormHelperText className="error">
                                    {validation.get('data')}
                                </FormHelperText>
                            )
                            : null
                        }

                        <FieldsModal
                            title="Выбрать область на карте"
                            buttonText={buttons.SELECT}
                            buttonType={buttonsTypes.selectOnMap}
                            buttonVariant="contained"
                            fields={{ geometry: fields?.data || {} }}
                            onChange={({ geometry }) => handleChange('data')(geometry)}
                        >
                            <MapGeoJson
                                visibleGeometry={
                                    createPointGJ(
                                        dislocationData?.lat,
                                        dislocationData?.lon
                                    )
                                }
                                required
                                isHideValidation
                                marker={false}
                            />
                        </FieldsModal>
                    </div>

                    {/*отображать радиус окружности, длину линий полигона, длину линий ломаной в метрах*/}
                    {fields?.address && (
                        <div className="block">
                            <TextField
                                label="Район"
                                disabled={true}
                                value={fields?.address?.area || fields?.address?.district || ''}
                            />
                        </div>
                    )}
                </InfoBlock>

                <div className="block">
                    <Companies
                        filter={{ withDeleted: 1 }}
                        label="Ответственная организация"
                        selected={fields.organization}
                        onChange={(value) => {
                            setFields({
                                ...fields,
                                organization: value,
                                organization_id: value?.id || null,
                            });
                            validation.deleteKey('organization_id');
                        }}
                    />
                </div>

                <div className="block">
                    <Attachments
                        onlyLink={true}
                        singleFile={false}
                        isRequired={false}
                        label={titles.ATTACHMENTS}
                        onChange={(files) => handleChange('attachments')(files)}
                        files={fields?.attachments ? fields?.attachments : []}
                        helperText={validation.get('attachments')}
                        error={validation.isKey('attachments')}
                    />
                </div>

                <NearDefects data={fields.data} />

                <Defects
                    site={fields}
                    onChange={(value) => {
                        setFields({
                            ...fields,
                            defections: value,
                        });
                    }}
                />

                {fields?.grz && (
                    <div className="block">
                        <TextField
                            className="block"
                            size="small"
                            variant="outlined"
                            label="Номер автолаборатории"
                            disabled={true}
                            value={fields?.grz}
                        />
                    </div>
                )}

                {fields?.type_creation && (
                    <div className="block">
                        <TextField
                            className="block"
                            size="small"
                            variant="outlined"
                            label="Способ создания"
                            disabled={true}
                            value={typeCreation[fields?.type_creation] || ''}
                        />
                    </div>
                )}

                {fields?.order_id && (
                    <div className="block">
                        <TextField
                            className="block"
                            size="small"
                            variant="outlined"
                            label="Номер заявки на дорожные работы"
                            disabled={true}
                            value={fields?.order_id || ''}
                        />
                    </div>
                )}

                <SelectTrafficFlow
                    multiple
                    selected={fields.detectors}
                    onChange={(value) => {
                        setFields({
                            ...fields,
                            detectors: value,
                            detector_id_list: value.map(({ id }) => id),
                        });
                        validation.deleteKey('detector_id_list');
                    }}
                />
            </div>

            {loading && <Loading linear={true} />}
        </Modal>
    );
};

export default EditorForm;
