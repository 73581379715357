import type { Dispatch, SetStateAction } from 'react';

import titles from 'helpers/constants/titles';
import PassportTabs from 'components/common/PassportTabs';
import PassportTabsGrid from 'components/common/PassportTabs/PassportTabsGrid';

import BasicData from './PassportPage/BasicData';
import Operations from './PassportPage/Operations';
import Diagram from './PassportPage/Diagram';

import type { ResponseScenario } from './types';

interface PassportTabsProps {
    selectedItem?: ResponseScenario | null;
    reloadList?: (isDelete?: boolean) => void;
    passportOnly?: boolean;
    queryKey?: string;
    urlParams?: Record<string, number | null>;
    setUrlParams?: Dispatch<SetStateAction<{[key: string]: number | null; }>>;
    setParams?: Dispatch<SetStateAction<{page: number; limit: number;}>>;
    renderList?: () => void;
}

const RenderPassportTabs = ({
    selectedItem,
    reloadList = () => {},
    passportOnly = false,
    queryKey,
    urlParams = {},
    setUrlParams = () => {},
    setParams = () => {},
    renderList = () => {},
}: PassportTabsProps) => {

    const tabs=[
        {
            value: 'basicData',
            label: titles.BASIC_DATA,
            icon: <i className="fal fa-info-square"/>,
            contentComponent: <BasicData
                item={selectedItem as ResponseScenario}
                reloadList={reloadList}
            />
        },
        {
            value: 'operations',
            label: titles.OPERATIONS,
            icon: <i className="fas fa-th"/>,
            contentComponent: <Operations
                item={selectedItem as ResponseScenario}
                reloadList={reloadList}
            />
        },
        {
            value: 'diagram',
            label: 'Диаграмма',
            icon: <i className="far fa-project-diagram"/>,
            contentComponent: <Diagram
                data={selectedItem as ResponseScenario}
            />
        }
    ];

    return (
        passportOnly
            ? <PassportTabs tabs={tabs} />
            : <PassportTabsGrid
                tabs={tabs}
                selectedItem={!!selectedItem?.id}
                queryKey={queryKey}
                urlParams={urlParams}
                setUrlParams={setUrlParams}
                setParams={setParams}
                renderList={renderList}
            />
    );
};

export default RenderPassportTabs;
