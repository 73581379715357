import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';

import { incidentsSelectors } from 'redux/Incidents';
import * as actions from 'redux/Incidents/actions';
import mapHelper from 'helpers/mapHelper';
import { useDebounce } from 'helpers/hooks';
import getFilters from 'components/MapComponents/helpers/getFilters';

import { getFilter } from '../../helper';

import CreateMarker from './CreateMarker';

const LoadPolygon = ({
    map,
    parent,
    selected,
    multiply,
    onChange,
    mainDtp
}) => {

    const dispatch = useDispatch();

    const filtersSelector = useSelector(incidentsSelectors.filters);
    const filter = getFilters(filtersSelector, getFilter);
    const [polygonData, setPolygonData] = useState([]);
    const [polygonLoading, setPolygonLoading] = useState(false);

    useEffect(() => {
        const bounds = [
            mainDtp,
            ...selected,
        ].map(({ lat, lon }) => [lat, lon]);

        if (bounds.length > 1) {
            map.fitBounds(bounds);
        }

        return () => {
            setPolygonData([]);
            setPolygonLoading(false);
        };
    }, []);

    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadDtpPolygon(
            polygon,
            filter,
            setPolygonData,
            setPolygonLoading
        ));
    };

    const debounceFetchPolygon = useDebounce(fetchPolygon, 500);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [map]);

    useEffect(() => {
        debounceFetchPolygon();
    }, [filtersSelector]);

    // загруженные маркеры
    const nearDtpMarkers = useMemo(() => {
        const color = 'rgba(103,106,103,0.62)';

        // id выбранных дтп
        const selectDtpIds = selected?.map(i => parseInt(i?.id));

        return polygonData.reduce((res, item) => {
            if (
                !selectDtpIds.includes(parseInt(item.id))
                && item.id !== mainDtp?.id
            ) {
                res.push(
                    <CreateMarker
                        parent={parent}
                        key={item.id}
                        item={item}
                        color={color}
                        onClick={() => {
                            onChange(
                                multiply
                                    ? [...selected, item]
                                    : item
                            );
                        }}
                    />
                );
            }
            return res;
        }, []);
    }, [polygonData, selected, onChange, multiply, mainDtp]);

    return (
        <>
            {polygonLoading && (
                <LinearProgress
                    style={{
                        position: 'absolute',
                        zIndex: '999',
                        left: '0',
                        right: '0',
                    }}
                />
            )}

            {nearDtpMarkers}
        </>
    );
};

export default LoadPolygon;
