import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';

import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';
import { loadIncidentsFactors } from 'modules/React/redux/actions';
import type { FactorType } from 'modules/React/reactTypes';

import type { GetListArguments } from 'types';


interface SelectFactorsProps<T> extends CommonAutocompleteProps<T> {
    filter?: {
        type_ids?: number[]
    },
    sortedList?: FactorType[]
}

const SelectIncidentFactors = ({
    multiple = false,
    selected,
    onChange = () => {},
    required = false,
    disabled = false,
    label = 'Пользовательские факторы',
    className = '',
    error = false,
    helperText = '',
    renderLabel = (option: FactorType | null) => option?.name || '',
    limitTags = 1,
    filter = {},
    sortedList,
}: SelectFactorsProps<FactorType>) => {
    const dispatch = useDispatch();

    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);

    useEffect(() => {
        if (!isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    const getList = (params: GetListArguments) => {
        const { page, limit, query } = params;

        dispatch(loadIncidentsFactors(page, limit, { ...filter, ...(query ? { name: query } : {}) } ));
    };

    const onReset = () => {
        const initialState = multiple ? [] : {};
        onChange(initialState as FactorType);
    };

    const handleAccept = (data: FactorType | FactorType[]) => {
        onChange(data);
        setShowList(false);
    };

    const handleClose = () => setShowList(false);

    return (
        <>
            <CommonAutocomplete<FactorType>
                multiple={multiple}
                label={label}
                className={className}
                selected={selected}
                onChange={onChange}
                required={required}
                disabled={disabled}
                onReset={onReset}
                renderLabel={renderLabel}
                limitTags={limitTags}
                onShowList={() => setShowList(true)}
                error={error}
                helperText={helperText}
            />
            {showList
              && <UniversalSelect
                  multiple={multiple}
                  title={label}
                  storeName="react"
                  storeNameProps="incidentFactors"
                  storeLoadingProps="loadingIncidentFactors"
                  keyProp="id"
                  isSelected
                  fetchList={getList}
                  sortedList={sortedList}
                  selected={selectedItems}
                  renderProps={(el) => <div><span>{el.name}</span></div>}
                  isOpen={showList}
                  onClose={handleClose}
                  onAccept={handleAccept}
                  noPadding
                  withSearch
              />
            }
        </>
    );
};

export default SelectIncidentFactors;
