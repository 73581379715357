import { loadBoardStatuses } from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import { useStoreFromSelector } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { createIcon, MapPopUp }  from 'components/MapComponents/MapPopUp';

import { getCategoryIcon } from '../helper';

import ModalButtons from './ModalButtons';
import FieldsList from './FieldsList';
import Info from './Info';

const PopUpModal = ({
    isOpen = false,
    data,
    readOnly = false,
    onClose,
}) => {

    const statuses = useStoreFromSelector(loadBoardStatuses, boardsSelectors.boardStatuses);
    const {
        name = '',
        status = 0,
    } = data || {};

    return (
        <MapPopUp
            isOpen={isOpen}
            title={name}
            icon={createIcon(getCategoryIcon(data?.category_id))}
            onClose={onClose}
            titleBackgroundColor={statuses?.find(i => i.id === status)?.color || ''}
            medium
            buttons={readOnly
                ? null
                : <ModalButtons data={data} />
            }
        >
            {data
                ? <Info
                    data={data}
                    innerComponent={<FieldsList data={data}/>}
                />
                : <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>
            }
        </MapPopUp>
    );
};

export default PopUpModal;
