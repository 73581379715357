import {
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';

import titles from 'helpers/constants/titles';

const Form = ({ data, onChange, onDelete }) => {
    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="block">
                <TextField
                    label={titles.ADDRESS}
                    value={data.address_text || ''}
                    variant="outlined"
                    size="small"
                    name="address_text"
                    onChange={(e) => onChange({ address_text: e.target.value })}
                />
            </div>
            <div className="block">
                <TextField
                    label="Номер парковочной зоны"
                    value={data.name || ''}
                    variant="outlined"
                    size="small"
                    name="name"
                    onChange={(e) => onChange({ name: e.target.value })}
                />
            </div>
            <div className="row">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data?.free_spaces?.id === 1 || false}
                            onChange={(event) =>
                                (event.target.checked)
                                    ? onChange({
                                        free_spaces: {
                                            id: event.target.checked ? 1: 0,
                                            name: 'Свободные места'
                                        }
                                    })
                                    : onDelete('free_spaces')
                            }
                            color="primary"
                        />
                    }
                    label={
                        <Typography variant="body2">
                            Свободные места
                        </Typography>
                    }
                />
            </div>
        </form>
    );
};

export default Form;
