import { IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Item = ({ index, text, handleDeleteItem }) => {
    return (
        <>
            <ListItem className="list_item">
                <ListItemIcon>
                    {index + 1}.
                </ListItemIcon>
                <ListItemText>
                    <b>{text}</b>
                </ListItemText>
                <Tooltip title="Удалить связь">
                    <IconButton onClick={handleDeleteItem} size="small">
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            </ListItem>

        </>
    );
};

export default Item;
