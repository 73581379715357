import { useMemo } from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography,
} from '@mui/material';

import getMonth from 'helpers/getMonth';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

const Report = ({
    data = {},
    filter = () => {},
    date,
}) => {
    const fields = [
        {
            label: titles.DTP_COUNT,
            key: 'count',
        },
        {
            label: 'Без пострадавших',
            key: 'count_without_wounded_and_dead',
        },
        {
            label: 'С пострадавшими',
            key: 'count_wounded_and_dead',
        },
        {
            label: 'Погибло',
            key: 'count_dead',
        },
        {
            label: 'Ранено',
            key: 'count_wounded',
        },
    ];

    const monthData = useMemo(() => filter(data?.month || {}), [data, filter]);
    const yearData = useMemo(() => filter(data?.year || {}), [data, filter]);

    const renderItem = (obj) => {
        const {
            value = null,
            apgg = null,
            color = null,
        } = obj;

        if (value !== null) {
            return (
                <div style={{ color: color || '#000' }}>
                    {value}{apgg !== null ? ` (${apgg}%)` : ''}
                </div>
            );
        }

        return messages.NO_DATA;
    };

    return (
        <TableContainer
            component={Paper}
            sx={{
                boxShadow: 'none',
            }}
        >
            <Table
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">
                            {getMonth(date?.getMonth?.())} {date?.getFullYear?.()}
                            <Typography variant="body2">
                                Количество(+/-% АППГ)
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            {date?.getMonth?.() > 0
                                ? `${getMonth(0)}-${getMonth(date?.getMonth())} ${date?.getFullYear?.()}`
                                : `${getMonth(date?.getMonth?.())} ${date?.getFullYear?.()}`
                            }
                            <Typography variant="body2">
                                Количество(+/-% АППГ)
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {fields?.map(({ key, label }) => {
                        const monthItem = monthData?.[key] || {};
                        const yearItem = yearData?.[key] || {};

                        return (
                            <TableRow
                                key={key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">
                                    <strong>{label}</strong>
                                </TableCell>

                                <TableCell align="center">
                                    {renderItem(monthItem)}
                                </TableCell>

                                <TableCell align="center">
                                    {renderItem(yearItem)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Report;
