import { memo } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import makeStyles from '@mui/styles/makeStyles';

import { getMonthYear, getYear, humanDateTimeWithoutSecongsWithoutTZ, humanDateWithoutTZ, isWeekend } from 'helpers/date.config';

import RenderHeader from './RenderHeader';
import styles from './vaGraph.module.scss';

const useStyles = makeStyles({
    pieBlock: {
        marginTop: 15
    }
});

const Graph = ({ reportData, params })  => {
    const classes = useStyles();
    const { group, type, with_line } = params;

    const typeOptions = {
        byHours: {
            caption: 'Часы',
            // format: dateFormat.DATE_WITH_HOUR_MIN,
            format: humanDateTimeWithoutSecongsWithoutTZ,
            tableTitle: 'часам'
        },
        byDays: {
            caption: 'Дни',
            // format: dateFormat.DATE,
            format: humanDateWithoutTZ,
            tableTitle: 'дням'
        },
        byDayOfWeek: {
            caption: 'Дни',
            // format: dateFormat.DATE,
            format: humanDateWithoutTZ,
            tableTitle: 'дням'
        },
        byMonth: {
            caption: 'Месяцы',
            // format: dateFormat.MONTH_YEAR,
            format: getMonthYear,
            tableTitle: 'месяцам'
        },
        byYears: {
            caption: 'Годы',
            // format: dateFormat.YEAR,
            format: getYear,
            tableTitle: 'годам'
        },
    };


    const groupOptions = {
        intensity: {
            key: 'cars',
            title: `По интенсивности проезда по ${typeOptions[type]?.tableTitle}`,
            maxTitle: 'Макс. значение',
            minTitle: 'Мин. значение',
            totalTitle: 'Итого',
            values: ['percent', 'cars', 'intensity'],
            valueTitle: [' % от общего'], // % от общего - убираю т.к. не выводим проценты
            findMaxKey: 'cars',
            findMinKey: 'cars',
            defaultUnits: 'шт',
        },
        speed: {
            key: 'avg_speed',
            title: `По средней скорости проезда по ${typeOptions[type]?.tableTitle}`,
            maxTitle: 'Макс. значение',
            minTitle: 'Мин. значение',
            totalTitle: 'Среднее за период',
            values: ['max_speed', 'min_speed'],
            valueTitle: ['Макс.', 'Мин.'],
            findMaxKey: 'max_speed',
            findMinKey: 'min_speed',
            defaultUnits: 'км/ч',
        },
        dtp: {
            key: 'count',
            title: `По фактам ДТП ${typeOptions[type]?.tableTitle}`,
            maxTitle: 'Макс. значение',
            minTitle: 'Мин. значение',
            totalTitle: 'Итого',
            defaultUnits: 'шт',
        }
    };

    // возвращает красный цвет если это выходной
    const weekendColor = (date) => {
        const [day, month, year] = date.split('.');
        return isWeekend(new Date(Number(year), Number(month) - 1, Number(day))) // TODO проеверить по таймзоне
        && '#ff0000';
    };


    const itemObject = (el, accum, dirData) => {
        // собираем данные по одному элементу
        if (el.hasOwnProperty('all')) {
            dirData.all = el.all;
        } else {
            dirData = {
                ...dirData,
                backgroundColor: '#'+el?.color,
                units: el.units || groupOptions[group]?.defaultUnits,
                label: el.direction_way  || 'Не указано',
            };
            accum.data = [
                ...accum.data,
                {
                    date: el.date,
                    val: el[groupOptions[group]?.key],
                    values: groupOptions[group]?.values
                        ? groupOptions[group]?.values?.map((key) => {
                            return el[key];
                        })
                        : []
                }
            ];
            accum.values = groupOptions[group]?.values
                ? [
                    ...accum.values,
                    groupOptions[group]?.values?.reduce((e, key) => {
                        e[key] =  el[key];
                        return e;
                    }, {})
                ]
                : [];
            accum.dates =  Array.from(new Set([
                ...accum.dates,
                el.date
            ])).sort();
        }
        return { directionObj: accum, directionData: dirData };
    };

    const itemArray = (item = [], dirData = {}, dates = []) => {
        const directionObj = item?.reduce((accum, el) => {
            // массив по одному направлению
            const itemData = itemObject(el, accum, dirData);
            dirData = itemData.directionData;
            return itemData.directionObj;
        }, { data: [], values: [], dates: dates });
        return { directionObj, directionData: dirData };
    };

    const createDataArrayDTP = () => {
        return Object.entries(reportData).reduce((res, [camera_id, item]) => {
            // проходим внутри массива данных для одной камеры
            const camera = params?.cameras?.find(item => item.id === Number(camera_id));
            const data = {};
            data.key = camera_id;
            data.dates = [];
            data.title = `${groupOptions[group]?.title} (${camera?.name || camera_id})`;
            let dirData = {
                borderColor: '#000',
                maxBarThickness: 80,
            };
            const directionObj = item.reduce((accum, el) => {
            // внутри одного элемента собираем данные в массив
                dirData = {
                    ...dirData,
                    backgroundColor: '#'+el?.color,
                    units: el.units || groupOptions[group]?.defaultUnits,
                    label: '',
                };
                accum.data = [
                    ...accum.data,
                    {
                        date: el.date,
                        val: el[groupOptions[group]?.key],
                    }
                ];
                accum.dates =  Array.from(new Set([
                    ...accum.dates,
                    el.date
                ])).sort();
                return accum;
            }, { data: [], dates: data.dates });
            dirData.fullData = directionObj.data;
            data.dates = directionObj.dates;
            data.directions = [dirData];
            res.push(data);
            return res;
        }, []);
    };

    const createDirectionDataArray = (camera_id, directions = [], line) => {
        const camera = params?.cameras?.find(item => item.id === Number(camera_id));
        // проходим внутри объекта для конкретной камеры по полосам
        // data это объект для 1 камеры + 1 полоса
        const data = {};
        data.key = line ? `${camera_id}_${line}` : camera_id;
        data.dates = [];
        if (line) {
            data.line = line;
        }
        data.title = `${groupOptions[group]?.title} (${camera?.name}${line ? ',  Полоса: ' + line : ''})`;
        data.directions = directions.reduce((res, item) => {
            // проходим внутри массива данных для конкретного направления
            let dirData = {
                borderColor: '#000',
                maxBarThickness: 80,
            };
            const unionData = itemArray(item, dirData, data.dates);
            const { directionObj, directionData } = unionData;
            dirData = directionData;
            dirData.fullData = directionObj.data;
            dirData.values = directionObj.values;
            data.dates = directionObj.dates;
            res.push(dirData);
            return res;
        }, []);
        return data;
    };

    const createData = (dataType) => {
        switch (dataType) {
            case 'dtp': {
                return createDataArrayDTP();
            }
            case 'with_line': {
                return Object.entries(reportData).reduce((result, [camera_id, obj]) => {
                    if (Array.isArray(obj) && obj.length === 0) {
                        const data = createDirectionDataArray(camera_id, obj);
                        return result.concat(data);
                    }
                    // проходим по данным полученным из запроса по камерам
                    const cameraArray = Object.entries(obj).reduce((acc, [line, directions]) => {
                        const data = createDirectionDataArray(camera_id, directions, line);
                        acc.push(data);
                        return acc;
                    }, []);
                    return result.concat(cameraArray);
                    // return result;
                }, []);
            }
            default: {
                return Object.entries(reportData).reduce((acc, [camera_id, directions]) => {
                    const data = createDirectionDataArray(camera_id, directions);
                    return acc.concat(data);
                }, []);
            }

        }
    };
    const param = with_line ? 'with_line' : group;
    const graphData = createData(param);
    const dataForBar = (obj) => {
        if (obj.dates?.length > 0) {
            return ({
                labels: obj.dates.map(el => typeOptions[type]?.format(el)),
                datasets: obj.directions.map(item => ({
                    ...item,
                    data: obj.dates.map((date) => {
                        return item.fullData.find((el) => el.date === date)?.val;
                    }),
                })),
            });
        }
        return null;
    };
    const dataForPie = (obj) => {
        if (obj.directions?.length > 0) {

            const getPercent = (value = 0, sum = 1) => {
                return Math.round((value * 10000) / sum) / 100;
            };
            const total = obj.directions.reduce((res, { all }) => {
                res = res + all;
                return res;
            }, 0);
            const dataSet = obj.directions.reduce((result, item) => {
                // направление - всего - units - % от общего
                const percent = getPercent(item.all, total);
                result.labels = [
                    ...(result.labels || []),
                    `${item.label} - ${item.all}${item.units} - ${percent}%`
                ];
                result.data = [
                    ...(result.data || []),
                    item.all
                ];
                result.backgroundColor = [
                    ...(result.backgroundColor || []),
                    item.backgroundColor
                ];
                result.borderColor = [
                    ...(result.borderColor || []),
                    item.borderColor
                ];
                result.items = [
                    ...(result.items || []),
                    item,
                ];
                return result;
            }, {});
            return ({
                labels: dataSet.labels,
                datasets: [
                    {
                        data: dataSet.data,
                        backgroundColor: dataSet.backgroundColor,
                        borderColor: dataSet.borderColor,
                        items: dataSet.items
                    }
                ],
            });
        }
        return null;
    };

    const getLabel = (context) => {
        const value = context.formattedValue || '';
        const label = context.label || '';
        return `${label} - ${value} ${context.dataset.units}`;
    };

    const getPieLabel = (context) => {
        return `${context.label}`;
    };

    const getFooter = (tooltipItems) => {
        const { fullData, units = '', } = tooltipItems[0].dataset;
        const dataItem = fullData.find(item => typeOptions[type]?.format(item.date) === tooltipItems[0].label) || {};
        return dataItem?.values
            ? dataItem?.values?.reduce((res, el, index) => {
                const title = groupOptions[group]?.valueTitle[index];
                const currentKey = groupOptions[group]?.values[index];
                if (currentKey !== groupOptions[group]?.key) {
                    res = `${res}${index > 0 ? ', ' : ''}${title ? title + ': ' : ' '}${el}${group === 'speed' ? units : ''}`;
                }
                return res;
            }, '')
            : '';
    };

    const toolTipTitle = (context) => {
        const datasetLabel = context?.[0]?.dataset?.label;
        return group === 'dtp'
            ? ''
            : `Направление: ${datasetLabel}`;
    };

    const options = (el) => ({
        responsive: true,
        indexAxis: 'x',
        redraw: false,
        scales: {
            x: {
                display: true,
                ticks: {
                    color: (context) => weekendColor(context.tick.label),
                },
                title: {
                    display: true,
                    text: typeOptions[type]?.caption
                }
            },
            y: {
                title: {
                    display: true,
                    text: el?.directions[0]?.units
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: { // рендерим всплывашку по наведению на бар
                    label: getLabel,
                    title: toolTipTitle,
                    footer: getFooter
                }
            },
            legend: {
                display: group !== 'dtp',
                align: 'center',
                position: 'bottom',
                labels: {
                    boxWidth: 20
                }
            }
        },
    });

    const pieOptions = (el) => ({
        maintainAspectRatio: false,
        indexAxis: 'y',
        redraw: false,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    label: getPieLabel,
                }
            },
            legend: {
                align: 'center',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                },
                onClick: () => {},
            }
        },
    });

    return (
        graphData.map((el) => {
            const dataSet = dataForBar(el);
            const dataPieSet = dataForPie(el);
            return(
                <div key={el.key} className={styles.graph_item}>
                    <div>
                        <h2 className={styles.title}>{el.title}</h2>
                        {group !== 'dtp'
                            && <RenderHeader
                                el={el} group={groupOptions[group]}
                                type={typeOptions[type]}
                                renderColor={weekendColor}
                            />
                        }
                    </div>
                    {dataSet !== null
                        && <Bar
                            data={dataSet}
                            options={options(el)}
                            type="Bar"
                            key={el.key}
                        />
                    }
                    {group !== 'dtp' && dataPieSet !== null
                        && <div className={classes.pieBlock}>
                            <Pie
                                width={'500px'}
                                height={'500px'}
                                data={dataPieSet}
                                options={pieOptions(el)}
                                type="Pie"
                            />
                        </div>
                    }
                </div>);
        }));
};

export default memo(Graph);
