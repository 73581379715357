import { useState } from 'react';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { getStatusesRW } from 'redux/RoadWorks/actions';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import Companies from 'components/common/Autocomplete/Companies';
import DefectGroups from 'components/common/Autocomplete/RoadWorks/DefectGroups';
import DefectTypes from 'components/common/Autocomplete/RoadWorks/DefectTypes';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

function Filters({ setParams }) {
    const statuses = useStoreProp(getStatusesRW, 'roadworks', 'statuses');

    const initialState = {
        name: '',
        query: '',
        address: '',
        area: '',
        organization_id: {},
        grz: '',
        date_start: null,
        date_end: null,
        status: '',
        groups_of_defections_ids: [],
        types_of_defections_ids: [],
    };

    const [filterValues, setFilterValues] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isNotDisabledSaveButton, setIsNotDisabledSaveButton] = useState(false);

    const getPrepareData = (filter) => removeEmptyFields({
        ...filter,
        organization_id: filter.organization_id.id,
        date_start: fullDateTimeWithTimeZone(filter.date_start),
        date_end: fullDateTimeWithTimeZone(filter.date_end),
        groups_of_defections_ids: filter.groups_of_defections_ids.map(item => item.id),
        types_of_defections_ids: filter.types_of_defections_ids.map(item => item.id)
    }, false);

    const handleChange = (value, key ) => {
        setFilterValues({ ...filterValues, [key]: value });

        setIsNotDisabledSaveButton(Object.keys(getPrepareData({ ...filterValues, [key]: value })).length > 0 ? true : false);
        setIsDisabled(false);
    };

    const search = (filter) => {
        const prepareData = getPrepareData(filter);

        setParams({ page: 1, data: prepareData });
        setIsDisabled(true);
        setIsNotDisabledSaveButton(Object.keys(prepareData).length > 0 > 0 ? true : false);
    };

    const resetFilter = (needRefresh) => {
        setFilterValues(initialState);
        if (needRefresh) {
            setParams({ page: 1, data: {} });
        }
        setIsNotDisabledSaveButton(false);
        setIsDisabled(true);
    };

    const handleClickEnter = (e) => {
        if (e?.key === 'Enter') search();
    };

    const updateFilter = (filters) => {
        setFilterValues({ // обновляем локальный стейт
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            search({ // отправляем в родителя, чтобы произошел запрос
                ...initialState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={() => search(filterValues)} 
            filter={filterValues} 
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
            filterException={['date_start', 'date_end',]}
            saveButtonFilterNoDisabled={isNotDisabledSaveButton}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterValues.date_start}
                    valueEndDate={filterValues.date_end}
                    handleDate={(e) => handleChange(e, 'date_start')}
                    handleEndDate={(e) => handleChange(e, 'date_end')}
                    startLabel="Начало регистрации"
                    endLabel="Конец регистрации"
                />
                <TextField
                    label="Название"
                    value={filterValues.name}
                    onChange={(e) => handleChange(e.target.value, 'name')}
                    name="name"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {filterValues?.name?.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleChange('', 'name')}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>✕</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />

                <FormControl
                    size="small"
                    variant="outlined"
                >
                    <InputLabel>{titles.STATUS}</InputLabel>
                    <Select
                        value={filterValues.status}
                        onChange={(event) => {
                            handleChange(event.target.value, 'status');
                        }}
                        label={titles.STATUS}
                    >
                        <MenuItem value={''}>
                            Не выбрано
                        </MenuItem>
                        {Object.keys(statuses).map((key) => (
                            <MenuItem key={key} value={key}>                            
                                {statuses[key]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>

                <TextField
                    variant="outlined"
                    size="small"
                    value={filterValues.query}
                    label={titles.DESCRIPTION}
                    onKeyPress={handleClickEnter}
                    onChange={({ target: { value } }) => handleChange(value, 'query')}
                />

                <TextField
                    label="Адрес"
                    value={filterValues.address}
                    onChange={(e) =>  handleChange(e.target.value, 'address')}
                    //helperText="Some important text"
                    name="address"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {filterValues?.address ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleChange('', 'address')}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />
                <TextField
                    label="Район"
                    value={filterValues.area}
                    onChange={(e) =>  handleChange(e.target.value, 'area')}
                    name="area"
                    fullWidth
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {filterValues?.area?.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleChange('', 'area')}
                                            edge="end"
                                            size="large">
                                            <span style={{ fontSize: '1rem' }}>x</span>
                                        </IconButton>
                                    </InputAdornment>
                                ) : ''}
                            </>
                        )
                    }}
                />

                <Companies
                    label="Организация"
                    selected={filterValues.organization_id}
                    onChange={(value) => {
                        setFilterValues({
                            ...filterValues,
                            organization_id: value,
                        });
                        setIsDisabled(false);
                    }}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                />

                <TextField
                    size="small"
                    variant="outlined"
                    label="Номер автолаборатории"
                    value={filterValues.grz}
                    onChange={(e) => handleChange(e.target.value, 'grz')}
                />

                <DefectGroups
                    selected={filterValues?.groups_of_defections_ids || []}
                    multiple
                    onChange={(value) => handleChange(value, 'groups_of_defections_ids')}
                />

                <DefectTypes
                    selected={filterValues?.types_of_defections_ids || []}
                    multiple
                    onChange={(value) => handleChange(value, 'types_of_defections_ids')}
                    filter={{ group_ids: filterValues?.groups_of_defections_ids?.map(({ id }) => id) || [] }}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
}

export default Filters;