import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { createBrand, editBrand, loadVehicleCategories } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import type { Brand, Category } from './types';

interface BasePros {
    isOpen: boolean;
    onClose: () => void;
    reloadList: () => void;
}

interface AddProps extends BasePros {
    item?: never;
    isNew: true;
}

interface EditProps extends BasePros {
    item: Brand;
    isNew?: false;
}

function ModalForm({ isOpen, onClose, item, isNew, reloadList }: AddProps | EditProps) {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingButton = useSelector(transportPassengerSelectors.loadingButton);

    const categories: Category[] = useStoreProp(
        loadVehicleCategories,
        'transportPassenger',
        'vehicleCategories'
    );

    const initialState = {
        name_ru: item?.name_ru || '',
        name_en: item?.name_en || '',
        slug: item?.slug || '',
        category_id: item?.category_id || categories?.[0]?.id
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (
        name: keyof typeof initialState,
        value: (typeof initialState)[keyof typeof initialState]
    ) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        dispatch(isNew ? createBrand(formData, callback) : editBrand(item.id, formData, callback));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            disabled: !formData.name_ru || !formData.name_en || !formData.slug,
                            loading: loadingButton,
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name_ru')}
                    helperText={validation.get('name_ru')}
                >
                    <TextField
                        value={formData.name_ru}
                        label={titles.BRAND_NAME_RU}
                        onChange={(e) => handleChange('name_ru', e.target.value)}
                        required={true}
                        error={validation.isKey('name_ru')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('name_en')}
                    helperText={validation.get('name_en')}
                >
                    <TextField
                        value={formData.name_en}
                        label={titles.BRAND_NAME_EN}
                        onChange={(e) => handleChange('name_en', e.target.value)}
                        required={true}
                        error={validation.isKey('name_en')}
                    />
                </FormInfoWrapper>

                <FormInfoWrapper
                    className="block"
                    error={validation.isKey('slug')}
                    helperText={validation.get('slug')}
                >
                    <TextField
                        value={formData.slug}
                        label={titles.SLUG}
                        onChange={(e) => handleChange('slug', e.target.value)}
                        required={true}
                        error={validation.isKey('slug')}
                    />
                </FormInfoWrapper>

                <FormControl
                    variant="outlined"
                    size="small"
                    className="block"
                    error={validation.isKey('category_id')}
                    required={true}
                >
                    <InputLabel>{titles.TYPE_OF_VEHICLE}</InputLabel>
                    <Select
                        label={titles.TYPE_OF_VEHICLE}
                        value={formData.category_id}
                        onChange={(e) => handleChange('category_id', e.target.value)}
                        required={true}
                    >
                        {categories?.length > 0 ? (
                            categories.map((el) => (
                                <MenuItem key={el.id} value={el.id}>
                                    {el.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled={true} value="">
                                {messages.NO_DATA}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
        </Modal>
    );
}

export default ModalForm;
