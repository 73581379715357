import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reactSelectors } from 'modules/React';
import { loadIncidentsFactors } from 'modules/React/redux/actions';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import reactTitles from 'modules/React/utils/helpers/constants';
import type { FactorType } from 'modules/React/reactTypes';

import ModalForm from './ModalForm';
import Filters from './Filter';
import Item from './Item';


const IncidentFactors = () => {
    const dispatch = useDispatch();

    const data: FactorType[] = useSelector(reactSelectors.incidentFactorsData);
    const meta = useSelector(reactSelectors.incidentFactorsMeta);
    const loading = useSelector(reactSelectors.loadingIncidentFactors);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openModal, setOpenModal] = useState(false);

    const reloadList = useCallback((shouldGoToPreviousPage = false) => {
        if (shouldGoToPreviousPage) {
            setParams({ page: params.page - 1, data: params.data });
        } else {
            dispatch(loadIncidentsFactors(params.page, limit, params.data));
        }
    }, [dispatch, limit, params.data, params.page]);

    useEffect(() => {
        reloadList();
    }, [reloadList]);

    const renderContent = () => {
        return (
            data?.length > 0
                ? <LSContainer
                    headers={[
                        { title: reactTitles.NAME, width: '40%' },
                        { title: reactTitles.GEOZONE, width: '40%' }
                    ]}
                >
                    {data.map((item) => <Item key={item.id} item={item} reloadList={reloadList}/>)}
                </LSContainer>
                : (!loading && <NoData/>)
        );
    };

    return (
        <>
            <PageLayout
                header="Пользовательские факторы инцидентов"
                filters={<Filters setParams={setParams} />}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total,
                }}
                loading={loading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams((prev) => ({ page, data: prev.data })),
                    list: meta,
                    limit,
                    setLimit,
                }}
            />

            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={setOpenModal}
                    isNew
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default IncidentFactors;
