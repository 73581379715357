import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Information from 'components/common/Information';
import { MapPopUp } from 'components/MapComponents/MapPopUp';
import NoData from 'components/common/Information/NoData';
import { loadCongestionsHeatMapSummary } from 'modules/HeatMapCongestions/redux/actions';

import IncidentsList from './IncidentsList';

const useStyles = makeStyles({
    title: {
        width: '75% !important',
        minWidth: '165px',
        paddingRight: '10px !important',
        fontWeight: '700'
    },
    value: {
        width: '25% !important',
        textAlign: 'right',
        padding: '10px 0 !important'
    }
});

const PopUp = ({ ids = [], isOpen, onClose }) => {
    const dispatch = useDispatch();

    const classes = useStyles();

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            dispatch(loadCongestionsHeatMapSummary(
                ids,
                (data) => {
                    console.log(data);
                    setData(data);
                },
                setLoading
            ));
        }
    }, [ids, isOpen]);

    useEffect(() => () => {
        setData({});
        setLoading(false);
    }, []);

    const infoObject = useMemo(() => {

        const dataObject = {
            ...data
        };
        const objectFields = {};

        data?.types?.forEach?.((item) => {
            dataObject[`type_${item.id}`] = item.count;
            objectFields[`type_${item.id}`] = {
                title: `Кол-во скоплений типа "${item.name || ''}"`
            };
        });
        objectFields['min_time_life'] = {
            title: 'Мин. время жизни скопления, мин',
        };
        objectFields['max_time_life'] = {
            title: 'Макс. время жизни скопления, мин',
        };

        return {
            data: dataObject,
            fields: objectFields,
        };
    }, [data]);

    const incident_id_list = useMemo(() => {
        return (data?.incident_id_list || []).filter(id => id);
    }, [data]);

    return (
        <MapPopUp
            isOpen={isOpen}
            title="Параметры скоплений в кластере"
            titleBackgroundColor="#D9A555"
            onClose={onClose}
            small
        >
            {loading && <LinearProgress/>}

            {Object.keys(data).length > 0
                ? (
                    <div>
                        <Information
                            data={infoObject.data}
                            title={infoObject.fields}
                            titleTpl={(value) => <b className={classes.title}>{value || ''}{': '}</b>}
                            valueTpl={(value) => <span className={classes.value}>{value}</span>}
                        />

                        {incident_id_list.length > 0 && (
                            <IncidentsList ids={incident_id_list} loading={loading} />
                        )}
                    </div>
                )
                : (
                    <div style={{ padding: '15px' }}>
                        <NoData/>
                    </div>
                )
            }
        </MapPopUp>
    );
};

export default PopUp;
