import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Delete, Edit } from '@mui/icons-material';

import { deleteCamera, loadCameraTypes } from 'modules/InfrastructureObjects/redux/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import { checkBase64 } from 'helpers/transport.types';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';

import ModalForm from './ModalForm';

import type { Camera } from './types';

interface ItemProps {
    item: Camera;
    reloadList: (isDelete?: boolean) => void;
}

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();

    const types = useStoreProp(loadCameraTypes, 'roadNetworks', 'cameraTypes');

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

    const handleDelete = () => {
        const callback = () => {
            reloadList();
            setOpenDeleteModal(false);
        };

        dispatch(deleteCamera(item.id, callback));
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {item.custom_icon ? (
                        <img
                            style={{ margin: 0 }}
                            src={checkBase64(item.custom_icon)}
                            alt="иконка"
                        />
                    ) : (
                        messages.NO_VALUE
                    )}
                </LSContentColumn>

                <LSContentColumn>{item.name || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn>{types[item.type_id] || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn>{item?.serial_number || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn>{item?.address_text || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <Edit fontSize="inherit" />,
                                name: titles.EDIT,
                                onClick: () => setIsOpenEditModal(true),
                            },
                            {
                                icon: <Delete fontSize="inherit" />,
                                name: titles.DELETE,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpenEditModal && (
                <ModalForm
                    isOpen={isOpenEditModal}
                    onClose={() => setIsOpenEditModal(false)}
                    reloadList={reloadList}
                    item={item}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onSuccess={handleDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
