import { useMemo } from 'react';

import { loadRegions } from 'redux/Dadata/actions';
import { dadataSelectors } from 'redux/Dadata';
import { config } from 'config';
import { useStoreFromSelector } from 'helpers/hooks';
import {
    FeatureGroup,
    Map,
    MapControl,
} from 'components/MapComponents/leaflet';
import { GeoJsonNew } from 'components/MapComponents/leaflet';

const MapRegions = ({
    isArea = false,
    selectedRegion = null,
    onSelectRegion: changeSelectedRegion,
    regionsStyle,
    defaultStyle,
    selectedStyle,
    onBlocked,
    // children,
}) => {
    // регионы
    const regionsAll = useStoreFromSelector(loadRegions, dadataSelectors.regions);

    const regionsGeo = useMemo(() => {
        const regions = isArea === false
            ? regionsAll?.cities || []
            : regionsAll?.areas || [];

        // преобразование
        return regions
            ?.map(({ geo_json, id, name }) => ({
                type: 'Feature',
                properties: {
                    id,
                    name,
                },
                geometry: {
                    ...geo_json,
                },
                style: regionsStyle?.[name] || {}
            }));
    }, [regionsAll, isArea, regionsStyle]);

    // отображение регионов
    const componentRegion = useMemo(() => {
        if (Object.keys(regionsGeo).length > 0) {
            return (
                <GeoJsonNew
                    // данные
                    data={regionsGeo}
                    // название региона
                    toolTipTemplate={(properties) => `${properties?.name}`}
                    // параметры информации
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5],
                        sticky: true,
                    }}
                    // выбранный регион (как параметры { id })
                    selected={selectedRegion}
                    // стили
                    style={
                        defaultStyle
                        || {
                            opacity: 1,
                            fillOpacity: .1,
                            color: '#006699',
                            fillColor: '#ffffff',
                        }
                    }
                    // кликнули
                    onClick={changeSelectedRegion}
                    // центруем
                    centerAfter={true}
                    // selectedStyle={{
                    //     color: '#bababa'
                    // }}
                    selectedStyle={selectedStyle || {
                        color: '#00ff00',
                    }}
                    onBlocked={onBlocked}
                />
            );
        }
        return null;
    }, [regionsGeo, selectedRegion]);

    return (
        <div
            style={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                marginBottom: '.5rem',
            }}
        >
            <div
                style={{
                    display: 'contents',
                    minHeight: '400px',
                }}
            >
                <Map
                    center={config.get('mapCenter')}
                >
                    <MapControl>

                        <FeatureGroup>
                            {/* регионы */}
                            {componentRegion}
                        </FeatureGroup>

                        {/*{children && (*/}
                        {/*    <FeatureGroup>*/}
                        {/*        /!* слои *!/*/}
                        {/*        {children}*/}
                        {/*    </FeatureGroup>*/}
                        {/*)}*/}

                    </MapControl>
                </Map>
            </div>
        </div>
    );
};

export default MapRegions;
