export const heatMapCongestions = (state) => state.heatMapCongestions;

export const congestionTypes = (state) => heatMapCongestions(state).congestionTypes;

export const congestionsHeatMap = (state) => heatMapCongestions(state).congestionsHeatMap;
export const loadingCongestionsHeatMap = (state) => heatMapCongestions(state).loadingCongestionsHeatMap;

export const ngptActive = (state) => heatMapCongestions(state).ngptActive;
export const ngptFilters = (state) => heatMapCongestions(state).ngptFilters;
export const congestionPolygon = (state) => heatMapCongestions(state).congestionPolygon;

export const ngptSaved = (state) => heatMapCongestions(state).ngptSaved;
