import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

const usePopModalListenerList = (
    list= [],
    findKey= 'id',
) => {

    const [modal, setModal] = useState(null);

    useEffect(() => {
        if (
            list.length > 0
            && modal
        ) {
            const findElementInList = list.find((item) => item?.[findKey] === modal?.[findKey]);
            if (
                findElementInList
                && !isEqual(modal, findElementInList)
            ) {
                setModal(findElementInList || null);
            }
        }
    }, [list, findKey, modal]);

    return {
        isOpen: !!modal,
        modal,
        setModal,
        clear: () => setModal(null),
    };
};

export default usePopModalListenerList;
