import { IStation, ILine } from './types';

export type SvgCoordinations = ([number, number] | [number, number, string])[];
export type SvgPosition = [number, number];
export type IBFS = [string, string, SvgCoordinations, string];
export type IBFSLabel = [...IBFS, SvgPosition];
export type IInch = ['inch', string, SvgCoordinations];
export type IBFSLine = IBFS | [...IBFS, SvgPosition] | IInch;

export const BFS_LABELS: IBFSLabel[] = [
    ['neva_river1', 'nr', [], 'Малая Нева', [-94, 5]],
    ['neva_river2', 'nr', [], 'река Нева', [-20, 30]],
    ['neva_river3', 'nr', [], 'Большая Нева', [-94, 50]],
];

// линии
export const BFS_LINES: IBFSLine[] = [
    [
        'neva_river1',
        '1',
        [
            [-108, 0],
            [-103, 0, 'Q'],
            [-100, 0, ''],
            [-97, 2.5, 'L'],
            [-67, 30],
        ],
        'река Большая Нева',
        [-93, -5],
    ],
    [
        'neva_river2',
        '1',
        [
            [-68, 30],
            [46, 30, 'Q'],
            [58, 30, ''],
            [58, 20, 'L'],
            [58, -61, 'Q'],
            [58, -64, ''],
            [60, -67, 'L'],
            [72, -80],
        ],
        'река Нева',
        [-20, 30],
    ],
    [
        'neva_river3',
        '1',
        [
            [-108, 54],
            [-103, 54, 'Q'],
            [-100, 54, ''],
            [-97, 51.5, 'L'],
            [-67, 30],
        ],
        'река Малая Нева',
        [-100, 62],
    ],
    [
        'r1',
        '1',
        [
            [-94, 60],
            [-94, 38, 'Q'],
            [-94, 34, ''],
            [-88, 30, 'L'],
            [-70, 18, 'Q'],
            [-67, 16, ''],
            [-64, 16, 'L'],
            [50, 16, 'Q'],
            [53, 16, ''],
            [53, 13, 'L'],
            [53, -80],
        ],
        'Невско-Василеостровская линия (зеленая)',
    ],
    [
        'r2',
        '1',
        [
            [-60, 70],
            [-60, 30, 'Q'],
            [-60, 26, ''],
            [-57, 23, 'L'],
            [10, -40, 'Q'],
            [12, -42, ''],
            [12, -46, 'L'],
            [12, -80],
        ],
        'Фрунзенская линия (фиолетовая)',
    ],
    [
        'r3',
        '1',
        [
            [-30, 80],
            [-30, 0],
            [-30, -80],
        ],
        'Московско-Петроградская линия (синяя)',
    ],
    [
        'r4',
        '1',
        [
            [-27, -5],
            [56, -5, 'Q'],
            [58, -5, ''],
            [60, -7, 'L'],
            [82, -26, 'Q'],
            [84, -28, ''],
            [84, -30, 'L'],
            [84, -55],
        ],
        'Правобережная линия (желтая)',
    ],
    [
        'r5',
        '1',
        [
            [20, 80],
            [20, 34, 'Q'],
            [20, 31, ''],
            [17, 28, 'L'],
            [-55, -42, 'Q'],
            [-58, -45, ''],
            [-58, -48, 'L'],
            [-58, -80],
        ],
        'Кировско-Выборгская линия (красная)',
    ],
    //
    [
        'inch',
        'Nevskiy_Prospekt-Gostiniy_Dvor',
        [
            [-30, 20],
            [-30, 16],
        ],
    ],
    [
        'inch',
        'Mayakovskaya-Ploschad_Vosstaniya',
        [
            [-1, 16],
            [2, 13.42],
        ],
    ],
    [
        'inch',
        'Ploschad_Alexandra_Nevskogo',
        [
            [53, -1],
            [53, -5],
        ],
    ],
    [
        'inch',
        'Sadovaya-Sennaya_Ploschad-Spasskaya',
        [
            [-33, 0.44],
            [-30, -2.38],
            [-27, -5],
        ],
    ],
    [
        'inch',
        'Pushkinskaya-Zvenigorodskaya',
        [
            [-24.9, -12.73],
            [-19, -12.73],
        ],
    ],
    [
        'inch',
        'Dostoevskaya-Vladimirskaya',
        [
            [-23, -5],
            [-20, -8],
        ],
    ],
    [
        'inch',
        'Tehnologicheskiy_Institut',
        [
            [-30, -17.68],
            [-33, -20.6],
        ],
    ],
];

// остановки
export type IBFSStation = [...IBFS, IStation & { transition?: string }, ILine];
export const BFS_STATIONS: IBFSStation[] = [
    [ // ftype, _, coords, label, item, line
        's1',
        'Begovaya',
        [[-94, 60]],
        'Беговая',
        {
            id: 4,
            name: 'Беговая',
            lat: 59.988304,
            lon: 30.202317,
            order: 1,
            ethrances: [
                {
                    id: '64910abe-dabf-48b3-ae5f-a650ff3be035',
                    lat: 59.988304,
                    lon: 30.202317,
                },
                {
                    id: '7a1cb645-4ba8-4a1c-9779-a69520960a52',
                    lat: 59.988218,
                    lon: 30.203111,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1',
        'Zenit',
        [[-94, 54]],
        'Зенит',
        {
            id: 5,
            name: 'Новокрестовская',
            lat: 59.972803,
            lon: 30.211962,
            order: 2,
            ethrances: [
                {
                    id: 'c1d38d8d-ca78-4fe5-a9d4-46be22685ac9',
                    lat: 59.970996,
                    lon: 30.211957,
                },
                {
                    id: 'ce68d324-5f35-4f31-af42-6d09b962ccd6',
                    lat: 59.972803,
                    lon: 30.211962,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1',
        'Primorskaya',
        [[-94, 42]],
        'Приморская',
        {
            id: 15,
            name: 'Приморская',
            lat: 59.948521,
            lon: 30.23447,
            order: 3,
            ethrances: [
                {
                    id: '872f6696-e427-4e54-ae93-bf677c9e1da2',
                    lat: 59.948593,
                    lon: 30.234392,
                },
                {
                    id: 'cc3f1a5a-cde6-4f5a-90d3-73c7fce70af1',
                    lat: 59.948522,
                    lon: 30.234277,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1',
        'Vasileostrovskaya',
        [[-88, 30]],
        'Василеостровская',
        {
            id: 6,
            name: 'Василеостровская',
            lat: 59.942577,
            lon: 30.278254,
            order: 4,
            ethrances: [
                {
                    id: 'b3fe8c72-924e-4e5b-8ffd-8af0c1cb9fe8',
                    lat: 59.942704,
                    lon: 30.278442,
                },
                {
                    id: 'fa3c222c-4be8-46d6-9a63-80c555dc96fa',
                    lat: 59.942775,
                    lon: 30.278209,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1_1',
        'Gostiniy_Dvor',
        [[-30, 16]],
        'Гостиный двор',
        {
            id: 7,
            name: 'Гостиный двор',
            lat: 59.933933,
            lon: 30.33341,
            order: 5,
            transition: 'Nevskiy_Prospekt',
            ethrances: [
                {
                    id: 'a1db1153-b02d-4bdf-9b8f-79e46ef1fe36',
                    lat: 59.934103,
                    lon: 30.333997,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1_2',
        'Mayakovskaya',
        [[-1, 16]],
        'Маяковская',
        {
            id: 8,
            name: 'Маяковская',
            lat: 59.931366,
            lon: 30.354645,
            order: 6,
            transition: 'Ploschad_Vosstaniya',
            ethrances: [
                {
                    id: '838cb3f0-262f-44e2-9eba-6325021e8dd7',
                    lat: 59.931627,
                    lon: 30.355208,
                },
                {
                    id: 'e959e2af-b959-46ff-9900-b66600912c0e',
                    lat: 59.931585,
                    lon: 30.355275,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1_3',
        'Ploschad_Alexandra_Nevskogo-1',
        [[53, -1]],
        'Площадь Александра Невского-1',
        {
            id: 9,
            name: 'Площадь Александра Невского 1',
            lat: 59.924369,
            lon: 30.384989,
            order: 7,
            transition: 'Ploschad_Alexandra_Nevskogo-2',
            ethrances: [
                {
                    id: '37cee7d9-a40c-421b-ab21-b9bba338dde4',
                    lat: 59.924357,
                    lon: 30.385341,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1_3',
        'Elizarovskaya',
        [[53, -44]],
        'Елизаровская',
        {
            id: 10,
            name: 'Елизаровская',
            lat: 59.89669,
            lon: 30.423656,
            order: 8,
            ethrances: [
                {
                    id: 'a138c530-c226-4da0-97ad-8aace0bd9238',
                    lat: 59.896673,
                    lon: 30.423783,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1_3',
        'Lomonosovskaya',
        [[53, -53]],
        'Ломоносовкая',
        {
            id: 11,
            name: 'Ломоносовская',
            lat: 59.877342,
            lon: 30.441715,
            order: 9,
            ethrances: [
                {
                    id: '2519eac3-b5a2-46d0-a60e-6910eb8ca68a',
                    lat: 59.877216,
                    lon: 30.44198,
                },
                {
                    id: '252e5e39-8f49-4b7f-8a13-b5b2ca391fe0',
                    lat: 59.877427,
                    lon: 30.442154,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1_3',
        'Proletarskaya',
        [[53, -62]],
        'Пролетарская',
        {
            id: 12,
            name: 'Пролетарская',
            lat: 59.865215,
            lon: 30.470264,
            order: 10,
            ethrances: [
                {
                    id: 'd1a03a14-fab3-4357-b823-456ad19f3ffe',
                    lat: 59.865072,
                    lon: 30.470368,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1_3',
        'Obukhovo',
        [[53, -71]],
        'Обухово',
        {
            id: 13,
            name: 'Обухово',
            lat: 59.848709,
            lon: 30.457743,
            order: 11,
            ethrances: [
                {
                    id: 'a6a34b76-516e-446c-970e-45ba69782037',
                    lat: 59.848644,
                    lon: 30.457831,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's1_3',
        'Rybatskoe',
        [[53, -80]],
        'Рыбацкое',
        {
            id: 14,
            name: 'Рыбацкое',
            lat: 59.830986,
            lon: 30.501259,
            order: 12,
            ethrances: [
                {
                    id: '4ffb1455-6219-4a30-93ba-dd8d4a7b8776',
                    lat: 59.830526,
                    lon: 30.50075,
                },
                {
                    id: 'c5a3a7e4-12cb-4a0a-9570-b0a5e1cd6e7a',
                    lat: 59.831532,
                    lon: 30.502293,
                },
            ],
        },
        {
            id: 'ca965689-dbd8-47c7-8f67-b196a8a9f14c',
            name: 'Невско-Василеостровская',
            color: '#009A49',
        },
    ],
    [
        's2',
        'Komendantskiy_Prospekt',
        [[-60, 70]],
        'Комендантский проспект',
        {
            id: 31,
            name: 'Комендантский проспект',
            lat: 60.008591,
            lon: 30.258663,
            order: 1,
            ethrances: [
                {
                    id: 'cbda63b3-10f5-4009-a8a8-9de72ec4396a',
                    lat: 60.008577,
                    lon: 30.258082,
                },
                {
                    id: '5477f366-4d7e-4253-bc9e-dbde05f010ea',
                    lat: 60.008805,
                    lon: 30.25886,
                },
                {
                    id: 'fcbe50ee-c0ff-4670-bb43-07775af77d25',
                    lat: 60.007787,
                    lon: 30.259477,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2',
        'Staraya_Derevnya',
        [[-60, 62]],
        'Старая деревня',
        {
            id: 63,
            name: 'Старая Деревня',
            lat: 59.989433,
            lon: 30.255163,
            order: 2,
            ethrances: [
                {
                    id: 'b4598cc7-0183-4027-9507-9e0e145e48f5',
                    lat: 59.989529,
                    lon: 30.25476,
                },
                {
                    id: 'f15f1135-d164-457e-868d-a7ed38dc4770',
                    lat: 59.989562,
                    lon: 30.2555,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2',
        'Krestovskiy_Ostrov',
        [[-60, 54]],
        'Крестовский остров',
        {
            id: 32,
            name: 'Крестовский остров',
            lat: 59.971821,
            lon: 30.259436,
            order: 3,
            ethrances: [
                {
                    id: '6ddd98bd-a16c-4b35-815e-024350b5b830',
                    lat: 59.971676,
                    lon: 30.259477,
                },
                {
                    id: '1ad8b73f-a067-4ebe-bd57-4659bbf11ae0',
                    lat: 59.972004,
                    lon: 30.259338,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2',
        'Chkalovskaya',
        [[-60, 46]],
        'Чкаловская',
        {
            id: 71,
            name: 'Чкаловская',
            lat: 59.961033,
            lon: 30.292006,
            order: 4,
            ethrances: [
                {
                    id: '3c22d7fa-7ba2-4e45-bd0f-814778677b99',
                    lat: 59.961108,
                    lon: 30.291822,
                },
                {
                    id: 'ae8adc52-3d82-4699-8d6e-146198035e85',
                    lat: 59.961125,
                    lon: 30.2922,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Sportivnaya',
        [[-60, 38]],
        'Спортивная',
        {
            id: 62,
            name: 'Спортивная',
            lat: 59.952026,
            lon: 30.291338,
            order: 5,
            ethrances: [
                {
                    id: '4dd5f0bc-c94c-47f9-b4e6-207651ce7814',
                    lat: 59.947745,
                    lon: 30.283352,
                },
                {
                    id: '98cd7d24-8e5a-4e45-8f95-42660d2397ff',
                    lat: 59.948261,
                    lon: 30.283368,
                },
                {
                    id: '28464e63-562e-40ae-baa7-f18fcb040415',
                    lat: 59.948199,
                    lon: 30.283696,
                },
                {
                    id: '28aa60b6-a5ca-4445-be6e-b505e37bf642',
                    lat: 59.947833,
                    lon: 30.284828,
                },
                {
                    id: '6d3417ce-3da1-4de6-9e3f-1a74518f7aee',
                    lat: 59.94751,
                    lon: 30.284039,
                },
                {
                    id: 'c0ce1f53-3efd-4a8f-a9b8-53215235fc47',
                    lat: 59.952217,
                    lon: 30.291153,
                },
                {
                    id: '36aa385c-a4c4-4a09-8dd1-6d17d0f0d2f2',
                    lat: 59.952037,
                    lon: 30.291839,
                },
                {
                    id: '5e1998c4-7da1-416a-b7b0-baf731013d74',
                    lat: 59.951854,
                    lon: 30.290697,
                },
                {
                    id: '77e0f679-9362-4076-b601-142efa7776e0',
                    lat: 59.951625,
                    lon: 30.2917,
                },
                {
                    id: 'bdc3dd0e-537a-48a0-b360-7a8d9d8845f6',
                    lat: 59.951703,
                    lon: 30.292027,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2',
        'Admiralteyskaya',
        [[-45.5, 12]],
        'Адмиралтейская',
        {
            id: 17,
            name: 'Адмиралтейская',
            lat: 59.935867,
            lon: 30.31523,
            order: 6,
            ethrances: [
                {
                    id: '2645483e-5521-4981-809a-228418419f4a',
                    lat: 59.935889,
                    lon: 30.315362,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2',
        'Sadovaya',
        [[-33, 0.44]],
        'Садовая',
        {
            id: 59,
            name: 'Садовая',
            lat: 59.926739,
            lon: 30.317753,
            order: 7,
            transition: 'Sennaya_Ploschad',
            ethrances: [
                {
                    id: '709c6c5d-6bf1-40f1-8575-9677cdeba5aa',
                    lat: 59.926902,
                    lon: 30.316849,
                },
                {
                    id: '84d09d2f-cb0f-4c41-b99b-d64bc0a283b5',
                    lat: 59.926649,
                    lon: 30.318646,
                },
                {
                    id: 'f8e487cc-2f5d-467a-a086-095d8e48c908',
                    lat: 59.92722,
                    lon: 30.317643,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Zvenigorodskaya',
        [[-19, -12.73]],
        'Звенигородская',
        {
            id: 28,
            name: 'Звенигородская',
            lat: 59.92065,
            lon: 30.329599,
            order: 8,
            transition: 'Pushkinskaya',
            ethrances: [
                {
                    id: 'cb1565cf-75ef-4979-beec-4427332c830a',
                    lat: 59.922227,
                    lon: 30.33593,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Obvodniy_Kanal',
        [[1.5, -32]],
        'Обводный канал',
        {
            id: 44,
            name: 'Обводный Канал',
            lat: 59.914686,
            lon: 30.34815,
            order: 9,
            ethrances: [
                {
                    id: '6e84f763-34c9-4cc6-a6e4-983e81ccdcdb',
                    lat: 59.914612,
                    lon: 30.349658,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Volkovskaya',
        [[10, -40]],
        'Волковская',
        {
            id: 22,
            name: 'Волковская',
            lat: 59.896023,
            lon: 30.35754,
            order: 10,
            ethrances: [
                {
                    id: '718a2730-753d-43ca-8aec-d5e91537114c',
                    lat: 59.895961,
                    lon: 30.358034,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Bukharestskaya',
        [[12, -48]],
        'Бухарестская',
        {
            id: 20,
            name: 'Бухарестская',
            lat: 59.883769,
            lon: 30.368932,
            order: 11,
            ethrances: [
                {
                    id: 'e8ad85c5-0fb8-47ae-a0b3-bd3cd9b55b8c',
                    lat: 59.88415,
                    lon: 30.369031,
                },
                {
                    id: 'd552295a-3454-4822-a831-4222fc2b6a8a',
                    lat: 59.883739,
                    lon: 30.368779,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Mezhdunrodnaya',
        [[12, -56]],
        'Международная',
        {
            id: 38,
            name: 'Международная',
            lat: 59.870203,
            lon: 30.379289,
            order: 12,
            ethrances: [
                {
                    id: '01f0365c-d494-42b1-846d-0af619cba4d4',
                    lat: 59.870549,
                    lon: 30.379177,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Prospekt_Slavy',
        [[12, -64]],
        'Проспект Славы',
        {
            id: 1,
            name: 'Проспект Славы',
            lat: 59.858519,
            lon: 30.39357,
            order: 13,
            ethrances: [
                {
                    id: 'd3ccb719-9237-4b7b-91b2-304a294e1477',
                    lat: 59.858521,
                    lon: 30.393552,
                },
                {
                    id: 'bf5a3651-2405-4a33-ad99-f6ac6a02fa79',
                    lat: 59.858519,
                    lon: 30.39357,
                },
                {
                    id: '9d0f70b1-ce55-4402-8818-154ea975b94e',
                    lat: 59.858287,
                    lon: 30.392572,
                },
                {
                    id: 'ec7c4a31-0dc3-4a9d-ab8d-e71f08673533',
                    lat: 59.854382,
                    lon: 30.39784,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Dunayskaya',
        [[12, -72]],
        'Дунайская',
        {
            id: 2,
            name: 'Дунайская',
            lat: 59.838908,
            lon: 30.412173,
            order: 14,
            ethrances: [
                {
                    id: '7b0614a2-97c2-4328-b060-fee86b2d7032',
                    lat: 59.841057,
                    lon: 30.409985,
                },
                {
                    id: 'aff4886e-7294-4c01-89f0-8691375d697c',
                    lat: 59.838908,
                    lon: 30.412173,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's2_1',
        'Shushary',
        [[12, -80]],
        'Шушары',
        {
            id: 3,
            name: 'Шушары',
            lat: 59.819868,
            lon: 30.432236,
            order: 15,
            ethrances: [
                {
                    id: '0de7f67f-383a-4821-817c-33c696680f3a',
                    lat: 59.819868,
                    lon: 30.432236,
                },
            ],
        },
        {
            id: 'ef2f84b3-5734-4411-b1aa-593a24fb5c61',
            name: 'Фрунзенско-Приморская',
            color: '#702785',
        },
    ],
    [
        's3',
        'Parnas',
        [[-30, 80]],
        'Парнас',
        {
            id: 47,
            name: 'Парнас',
            lat: 60.06699,
            lon: 30.333839,
            order: 1,
            ethrances: [
                {
                    id: 'f294a761-4c9c-4c1d-bd75-695b33fcbf4f',
                    lat: 60.06698,
                    lon: 30.334367,
                },
                {
                    id: '5c948dd9-bc47-4629-9530-f58b418a7efb',
                    lat: 60.067222,
                    lon: 30.334324,
                },
                {
                    id: '48bf262e-eacc-407a-9f90-c583df19cb1b',
                    lat: 60.067067,
                    lon: 30.33393,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Prospekt_Prosvescheniya',
        [[-30, 74]],
        'Проспект Просвещения',
        {
            id: 57,
            name: 'Проспект Просвещения',
            lat: 60.051456,
            lon: 30.332544,
            order: 2,
            ethrances: [
                {
                    id: '425880f4-151a-48da-a5fb-742334280deb',
                    lat: 60.051313,
                    lon: 30.332838,
                },
                {
                    id: 'cc62acde-146f-424d-9618-299842da487c',
                    lat: 60.051583,
                    lon: 30.332873,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Ozerki',
        [[-30, 68]],
        'Озерки',
        {
            id: 45,
            name: 'Озерки',
            lat: 60.037098,
            lon: 30.321495,
            order: 3,
            ethrances: [
                {
                    id: '01cbb8c2-2ebb-4c74-aa6b-446e8d18b43b',
                    lat: 60.037321,
                    lon: 30.321381,
                },
                {
                    id: 'fc48c492-75ca-4d70-8df5-0ddab2723e86',
                    lat: 60.03724,
                    lon: 30.32173,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Udelnaya',
        [[-30, 62]],
        'Удельная',
        {
            id: 66,
            name: 'Удельная',
            lat: 60.016697,
            lon: 30.315607,
            order: 4,
            ethrances: [
                {
                    id: 'a7e0d275-eb31-4595-b80e-981ed1f61cec',
                    lat: 60.016839,
                    lon: 30.315241,
                },
                {
                    id: '22e53c43-5584-40a3-87c9-a138e92b19a6',
                    lat: 60.016606,
                    lon: 30.315349,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Pionerskaya',
        [[-30, 56]],
        'Пионерская',
        {
            id: 49,
            name: 'Пионерская',
            lat: 60.002487,
            lon: 30.296759,
            order: 5,
            ethrances: [
                {
                    id: 'cdc4ba89-aae2-4a68-aead-251f62f26d0f',
                    lat: 60.002734,
                    lon: 30.296494,
                },
                {
                    id: '3f6ce724-e3c6-44bd-8af2-347b891b66c7',
                    lat: 60.002602,
                    lon: 30.297111,
                },
                {
                    id: '1f4e3323-c3ae-4ed4-a8b4-3d915f4d6e04',
                    lat: 60.002388,
                    lon: 30.296596,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Chernaya_Rechka',
        [[-30, 50]],
        'Чёрная речка',
        {
            id: 70,
            name: 'Чёрная речка',
            lat: 59.985455,
            lon: 30.300833,
            order: 6,
            ethrances: [
                {
                    id: '6a0ece53-8e49-4695-b4bc-fe883cd0d509',
                    lat: 59.985634,
                    lon: 30.300658,
                },
                {
                    id: '01acbaaa-1781-492f-ab91-e945c726dce5',
                    lat: 59.985533,
                    lon: 30.301023,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Petrogradskaya',
        [[-30, 44]],
        'Петроградская',
        {
            id: 48,
            name: 'Петроградская',
            lat: 59.966389,
            lon: 30.311293,
            order: 7,
            ethrances: [
                {
                    id: '078ce06d-fae8-46a3-914b-02159440de72',
                    lat: 59.96651,
                    lon: 30.310699,
                },
                {
                    id: 'fc4a3be3-af55-4170-ba4c-b0b62d463973',
                    lat: 59.966431,
                    lon: 30.311313,
                },
                {
                    id: '9400e87b-cfe5-46c2-99ee-030c283d1cc3',
                    lat: 59.96627,
                    lon: 30.3109,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Gorkovskaya',
        [[-30, 38]],
        'Горьковская',
        {
            id: 24,
            name: 'Горьковская',
            lat: 59.956112,
            lon: 30.31889,
            order: 8,
            ethrances: [
                {
                    id: '0afd919d-abc9-4e70-b8ef-d01dde0f3580',
                    lat: 59.956209,
                    lon: 30.319118,
                },
                {
                    id: '44031e0c-f8dc-41fb-8d68-52c4c8da45da',
                    lat: 59.956382,
                    lon: 30.318815,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Nevskiy_Prospekt',
        [[-30, 20]],
        'Невский проспект',
        {
            id: 42,
            name: 'Невский проспект',
            lat: 59.935421,
            lon: 30.327052,
            order: 9,
            transition: 'Gostiniy_Dvor',
            ethrances: [
                {
                    id: '143ea95c-6c2f-4730-9f14-64dae1c4d10f',
                    lat: 59.93466,
                    lon: 30.330668,
                },
                {
                    id: '4ca6c0c7-f0ed-4bb3-a073-ce7259a041a6',
                    lat: 59.934743,
                    lon: 30.329917,
                },
                {
                    id: '3e0df719-442b-4922-b8d0-400793090410',
                    lat: 59.934983,
                    lon: 30.330909,
                },
                {
                    id: '2599fd32-61c7-4984-a53f-02b04a43ebf9',
                    lat: 59.935075,
                    lon: 30.330094,
                },
                {
                    id: '5d7fd116-f499-4abc-a45a-930d8762b926',
                    lat: 59.935717,
                    lon: 30.326975,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3_1',
        'Sennaya_Ploschad',
        [[-30, -2.38]],
        'Сенная площадь',
        {
            id: 60,
            name: 'Сенная площадь',
            lat: 59.927135,
            lon: 30.320316,
            order: 10,
            transition: 'Sadovaya', // Также связана со 'Spasskaya'
            ethrances: [
                {
                    id: 'ca46d2a9-3ffe-494b-b9c4-ce4b87f70b90',
                    lat: 59.927161,
                    lon: 30.320264,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3_1',
        'Tehnologicheskiy_Institut-2',
        [[-30, -17.68]],
        'Технологический институт-2',
        {
            id: '65',
            name: 'Технологический институт 2',
            lat: 59.916512,
            lon: 30.318485,
            order: 11,
            transition: 'Tehnologicheskiy_Institut-1',
            ethrances: [
                {
                    id: '649f264c-d096-41b6-96fa-36320ae9d467',
                    lat: 59.916618,
                    lon: 30.318483,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Frunzenskaya',
        [[-30, -32]],
        'Фрунзенская',
        {
            id: 68,
            name: 'Фрунзенская',
            lat: 59.906273,
            lon: 30.31745,
            order: 12,
            ethrances: [
                {
                    id: '9f5164ef-452e-4b10-bda3-f2cf5dddd1bf',
                    lat: 59.90634,
                    lon: 30.317562,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Moskovskie_Vorota',
        [[-30, -40]],
        'Московские ворота',
        {
            id: 40,
            name: 'Московские ворота',
            lat: 59.891788,
            lon: 30.317873,
            order: 13,
            ethrances: [
                {
                    id: '9cd2d81b-e2ee-48ec-90c0-f7dda6ecee10',
                    lat: 59.891532,
                    lon: 30.317986,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Elektrosila',
        [[-30, -48]],
        'Электросила',
        {
            id: 72,
            name: 'Электросила',
            lat: 59.879189,
            lon: 30.318659,
            order: 14,
            ethrances: [
                {
                    id: '27ef0bdb-a4cd-498e-9d77-f960fb8c7bcd',
                    lat: 59.879147,
                    lon: 30.318724,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Park_Pobedy',
        [[-30, -56]],
        'Парк Победы',
        {
            id: 46,
            name: 'Парк Победы',
            lat: 59.866344,
            lon: 30.321802,
            order: 15,
            ethrances: [
                {
                    id: '8879acca-89a4-4b65-808a-dbae7d7a530f',
                    lat: 59.866302,
                    lon: 30.321896,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Moskovskaya',
        [[-30, -64]],
        'Московская',
        {
            id: 39,
            name: 'Московская',
            lat: 59.848873,
            lon: 30.321483,
            order: 16,
            ethrances: [
                {
                    id: 'c66a51df-d5e2-4e8b-a3d1-d6c6d66ff8d3',
                    lat: 59.853216,
                    lon: 30.321799,
                },
                {
                    id: '81c084bc-fb31-43ef-9a47-835dbbbd6429',
                    lat: 59.853243,
                    lon: 30.320881,
                },
                {
                    id: 'bf0a144d-4986-4721-a1e8-1a7c0fedcb09',
                    lat: 59.853464,
                    lon: 30.321788,
                },
                {
                    id: '9a1f13cb-8223-49e8-95d5-84b11000c7ab',
                    lat: 59.853432,
                    lon: 30.320881,
                },
                {
                    id: 'a5b8649f-f638-4625-a37a-ccb009524927',
                    lat: 59.849106,
                    lon: 30.321997,
                },
                {
                    id: 'c67ba038-d31e-4266-b179-f7822327e70e',
                    lat: 59.849398,
                    lon: 30.321978,
                },
                {
                    id: '207be600-1893-4deb-af87-5de37b32e21a',
                    lat: 59.849113,
                    lon: 30.321209,
                },
                {
                    id: '407bc44a-a56b-4911-a6cd-c9aaf80a1ba5',
                    lat: 59.849337,
                    lon: 30.321198,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Zvezdnaya',
        [[-30, -72]],
        'Звёздная',
        {
            id: 29,
            name: 'Звёздная',
            lat: 59.833241,
            lon: 30.349428,
            order: 17,
            ethrances: [
                {
                    id: 'd71e5686-ef6d-41e4-82ae-0008bc4aaf89',
                    lat: 59.83341,
                    lon: 30.349359,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's3',
        'Kupchino',
        [[-30, -80]],
        'Купчино',
        {
            id: 33,
            name: 'Купчино',
            lat: 59.829781,
            lon: 30.375702,
            order: 18,
            ethrances: [
                {
                    id: '9d403241-f86f-4f19-9b6d-6b2c6d7e8ba8',
                    lat: 59.830014,
                    lon: 30.374891,
                },
                {
                    id: 'c33e9b91-54aa-4ad4-888d-d6bb603693bc',
                    lat: 59.830257,
                    lon: 30.376828,
                },
                {
                    id: '09bf0594-e135-4eec-b131-01414466e561',
                    lat: 59.829927,
                    lon: 30.373732,
                },
                {
                    id: '92327465-bcbb-48bf-9778-a01932c1bd4c',
                    lat: 59.829079,
                    lon: 30.37532,
                },
                {
                    id: 'e695ce82-a850-4774-9148-cbca62581b83',
                    lat: 59.829322,
                    lon: 30.377262,
                },
            ],
        },
        {
            id: '74b10cdf-d3e0-4b8e-94ca-cc8f8705c9d8',
            name: 'Московско-Петроградская',
            color: '#0078C9',
        },
    ],
    [
        's4',
        'Spasskaya',
        [[-27, -5]],
        'Спасская',
        {
            id: 61,
            name: 'Спасская',
            lat: 59.927135,
            lon: 30.320316,
            order: 1,
            transition: 'Sennaya_Ploschad',
            ethrances: [
                {
                    id: '0938c63f-b4af-4f08-a419-27c99e0c7d5d',
                    lat: 59.9269,
                    lon: 30.319728,
                },
            ],
        },
        {
            id: 'f69899ef-42d8-4baf-a065-4962a9c2a378',
            name: 'Правобережная',
            color: '#EA7125',
        },
    ],
    [
        's4_1',
        'Dostoevskaya',
        [[-23, -5]],
        'Достоевская',
        {
            id: 27,
            name: 'Достоевская',
            lat: 59.928234,
            lon: 30.346029,
            order: 2,
            transition: 'Vladimirskaya',
            ethrances: [
                {
                    id: '10210e3e-b9c3-49de-91e2-0c63c7b28683',
                    lat: 59.928195,
                    lon: 30.346277,
                },
            ],
        },
        {
            id: 'f69899ef-42d8-4baf-a065-4962a9c2a378',
            name: 'Правобережная',
            color: '#EA7125',
        },
    ],
    [
        's4_1',
        'Ligovskiy_Prospekt',
        [[13, -5]],
        'Лиговский проспект',
        {
            id: 37,
            name: 'Лиговский проспект',
            lat: 59.920811,
            lon: 30.355055,
            order: 3,
            ethrances: [
                {
                    id: 'd6c0eb3c-91cf-49dd-bb7a-add9aea46418',
                    lat: 59.920778,
                    lon: 30.355184,
                },
            ],
        },
        {
            id: 'f69899ef-42d8-4baf-a065-4962a9c2a378',
            name: 'Правобережная',
            color: '#EA7125',
        },
    ],
    [
        's4_2',
        'Ploschad_Alexandra_Nevskogo-2',
        [[53, -5]],
        'Площадь Александра Невского-2',
        {
            id: 50,
            name: 'Площадь Александра Невского 2',
            lat: 59.923563,
            lon: 30.383421,
            order: 4,
            transition: 'Ploschad_Alexandra_Nevskogo-1',
            ethrances: [
                {
                    id: 'd8ddef74-cce2-4832-910c-af117f4aad76',
                    lat: 59.923579,
                    lon: 30.383449,
                },
            ],
        },
        {
            id: 'f69899ef-42d8-4baf-a065-4962a9c2a378',
            name: 'Правобережная',
            color: '#EA7125',
        },
    ],
    [
        's4_2',
        'Novocherkasskaya',
        [[75, -20]],
        'Новочеркасская',
        {
            id: 43,
            name: 'Новочеркасская',
            lat: 59.929092,
            lon: 30.411915,
            order: 5,
            ethrances: [
                {
                    id: '396f3401-1d0c-411b-a572-3964fb586451',
                    lat: 59.928879,
                    lon: 30.412713,
                },
            ],
        },
        {
            id: 'f69899ef-42d8-4baf-a065-4962a9c2a378',
            name: 'Правобережная',
            color: '#EA7125',
        },
    ],
    [
        's4_2',
        'Ladozhskaya',
        [[84, -35]],
        'Ладожская',
        {
            id: 34,
            name: 'Ладожская',
            lat: 59.93243,
            lon: 30.439274,
            order: 6,
            ethrances: [
                {
                    id: '033f8cb7-b912-48c7-b021-eb53455f2faa',
                    lat: 59.932578,
                    lon: 30.438996,
                },
                {
                    id: '13405631-ae89-416a-9150-29725d398eed',
                    lat: 59.932368,
                    lon: 30.439688,
                },
            ],
        },
        {
            id: 'f69899ef-42d8-4baf-a065-4962a9c2a378',
            name: 'Правобережная',
            color: '#EA7125',
        },
    ],
    [
        's4_2',
        'Plospekt_Dolshevikov',
        [[84, -45]],
        'Проспект Большевиков',
        {
            id: 55,
            name: 'Проспект Большевиков',
            lat: 59.919838,
            lon: 30.466757,
            order: 7,
            ethrances: [
                {
                    id: '061b3745-f3ce-49a4-8d35-e25bc019760b',
                    lat: 59.919669,
                    lon: 30.467032,
                },
            ],
        },
        {
            id: 'f69899ef-42d8-4baf-a065-4962a9c2a378',
            name: 'Правобережная',
            color: '#EA7125',
        },
    ],
    [
        's4_2',
        'Ulitsa_Dybenko',
        [[84, -55]],
        'Улица Дыбенко',
        {
            id: 67,
            name: 'Улица Дыбенко',
            lat: 59.907417,
            lon: 30.483311,
            order: 8,
            ethrances: [
                {
                    id: 'dc6cf7d5-c535-4bec-91a0-41b257fc729f',
                    lat: 59.907448,
                    lon: 30.48334,
                },
            ],
        },
        {
            id: 'f69899ef-42d8-4baf-a065-4962a9c2a378',
            name: 'Правобережная',
            color: '#EA7125',
        },
    ],
    [
        's5',
        'Devyatkino',
        [[20, 80]],
        'Девяткино',
        {
            id: 26,
            name: 'Девяткино',
            lat: 60.050182,
            lon: 30.443045,
            order: 1,
            ethrances: [
                {
                    id: '0d9c9480-bafb-4eb0-8c0f-7e5d332736fd',
                    lat: 60.050746,
                    lon: 30.443816,
                },
                {
                    id: '39e9b47a-45fb-4ea2-a4d2-8b26cb25abdf',
                    lat: 60.050822,
                    lon: 30.443334,
                },
                {
                    id: '47dd5b2e-0c3d-482c-af98-4053d9b4c855',
                    lat: 60.050901,
                    lon: 30.442609,
                },
                {
                    id: 'c9620bf2-b33c-4e79-9c9c-c79af88f558f',
                    lat: 60.050644,
                    lon: 30.442435,
                },
                {
                    id: '9e1fbbd2-fb73-4386-99fa-f4cda30d18cc',
                    lat: 60.050527,
                    lon: 30.443141,
                },
                {
                    id: '254321af-be39-4e6f-a221-ae0ec26d74ee',
                    lat: 60.05048,
                    lon: 30.443615,
                },
                {
                    id: 'd122a264-60f2-424d-94a2-749f1332b69e',
                    lat: 60.050707,
                    lon: 30.442161,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Grazhdanskiy_Prospekt',
        [[20, 74]],
        'Гражданский проспект',
        {
            id: 25,
            name: 'Гражданский проспект',
            lat: 60.034969,
            lon: 30.418224,
            order: 2,
            ethrances: [
                {
                    id: 'deb943e4-6053-4e41-9a36-47029a01addc',
                    lat: 60.035263,
                    lon: 30.418433,
                },
                {
                    id: '7f6d27ab-57ae-48a8-b2ac-34203520b381',
                    lat: 60.034916,
                    lon: 30.418549,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Academicheskaya',
        [[20, 68]],
        'Академическая',
        {
            id: 18,
            name: 'Академическая',
            lat: 60.012806,
            lon: 30.396044,
            order: 3,
            ethrances: [
                {
                    id: 'a02e6658-1f3d-4d38-bc59-89238a83ffa9',
                    lat: 60.01285,
                    lon: 30.396298,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Politehnicheskaya',
        [[20, 62]],
        'Политехническая',
        {
            id: 54,
            name: 'Политехническая',
            lat: 60.008942,
            lon: 30.370907,
            order: 4,
            ethrances: [
                {
                    id: 'c8be4371-4651-4b60-9f7b-404908548105',
                    lat: 60.00881,
                    lon: 30.370821,
                },
                {
                    id: 'a313b5ba-c06d-44c9-90c1-0b09f2157ef2',
                    lat: 60.008989,
                    lon: 30.370671,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Ploschad_Muzhestva',
        [[20, 56]],
        'Площадь Мужества',
        {
            id: 53,
            name: 'Площадь Мужества',
            lat: 59.999828,
            lon: 30.366159,
            order: 5,
            ethrances: [
                {
                    id: '61e45c04-9433-4a47-ad32-c04a5525186f',
                    lat: 59.999652,
                    lon: 30.366489,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Lesnaya',
        [[20, 50]],
        'Лесная',
        {
            id: 36,
            name: 'Лесная',
            lat: 59.984947,
            lon: 30.344259,
            order: 6,
            ethrances: [
                {
                    id: '18b2fcc8-a2e5-4ff8-a16a-85e76e400603',
                    lat: 59.984902,
                    lon: 30.344166,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Vyborgskaya',
        [[20, 44]],
        'Выборгская',
        {
            id: 23,
            name: 'Выборгская',
            lat: 59.970925,
            lon: 30.347408,
            order: 7,
            ethrances: [
                {
                    id: '7bf1b849-7972-473f-be17-fea295e53847',
                    lat: 59.971132,
                    lon: 30.347612,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Ploschad_Lenina',
        [[20, 38]],
        'Площадь Ленина',
        {
            id: 52,
            name: 'Площадь Ленина',
            lat: 59.955611,
            lon: 30.35605,
            order: 8,
            ethrances: [
                {
                    id: '96348ac4-1f92-4d7b-84c6-8e883e9ef1a9',
                    lat: 59.955617,
                    lon: 30.355868,
                },
                {
                    id: 'b464745c-778e-47f7-be5d-1483840ba939',
                    lat: 59.95873,
                    lon: 30.35489,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Chernyschevskaya',
        [[12.74, 23.86]],
        'Чернышевская',
        {
            id: 69,
            name: 'Чернышевская',
            lat: 59.94453,
            lon: 30.359919,
            order: 9,
            ethrances: [
                {
                    id: '168da5d5-84d7-46f7-bd13-f933adc18ebc',
                    lat: 59.944621,
                    lon: 30.35964,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Ploschad_Vosstaniya',
        [[2, 13.42]],
        'Площадь Восстания',
        {
            id: 51,
            name: 'Площадь Восстания',
            lat: 59.930279,
            lon: 30.361069,
            order: 10,
            transition: 'Mayakovskaya',
            ethrances: [
                {
                    id: '7ef3f9d7-118b-4e1a-ba03-222e6a337e87',
                    lat: 59.929434,
                    lon: 30.360862,
                },
                {
                    id: '1f66ea89-f26b-4bb1-957e-74fbc6eed785',
                    lat: 59.929747,
                    lon: 30.361074,
                },
                {
                    id: '69ea13c7-163c-417a-9364-4d6868a5cc1e',
                    lat: 59.92977,
                    lon: 30.360755,
                },
                {
                    id: '237a5bcb-ab8c-4b7d-9a53-1f3c1c712ef5',
                    lat: 59.931663,
                    lon: 30.360257,
                },
                {
                    id: '137b1b3a-5a90-4ed6-a213-9cdb36a4164a',
                    lat: 59.931661,
                    lon: 30.360863,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5',
        'Vladimirskaya',
        [[-20, -8]],
        'Владимирская',
        {
            id: 21,
            name: 'Владимирская',
            lat: 59.927628,
            lon: 30.347898,
            order: 11,
            transition: 'Dostoevskaya',
            ethrances: [
                {
                    id: '7335a099-3f89-42a1-a29b-5432a8d9b068',
                    lat: 59.927565,
                    lon: 30.348204,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5_1',
        'Pushkinskaya',
        [[-24.9, -12.73]],
        'Пушкинская',
        {
            id: 58,
            name: 'Пушкинская',
            lat: 59.92065,
            lon: 30.329599,
            order: 12,
            transition: 'Zvenigorodskaya',
            ethrances: [
                {
                    id: 'c2eb8b24-c592-4ebf-9001-33a684da2e74',
                    lat: 59.920656,
                    lon: 30.329891,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5_1',
        'Tehnologicheskiy_Institut-1',
        [[-33, -20.6]],
        'Технологический институт-1',
        {
            id: 64,
            name: 'Технологический институт-1',
            lat: 59.916512,
            lon: 30.318485,
            order: 13,
            transition: 'Tehnologicheskiy_Institut-2',
            ethrances: [
                {
                    id: '02efb1f3-0c63-48be-bb03-d36697525b00',
                    lat: 59.916242,
                    lon: 30.318405,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5_1',
        'Baltiyskaya',
        [[-45.8, -33]],
        'Балтийская',
        {
            id: 19,
            name: 'Балтийская',
            lat: 59.907211,
            lon: 30.299578,
            order: 14,
            ethrances: [
                {
                    id: '94ab8c62-4e60-454b-9079-d2d30c024449',
                    lat: 59.907393,
                    lon: 30.299557,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5_1',
        'Narvskaya',
        [[-58, -48]],
        'Нарвская',
        {
            id: 41,
            name: 'Нарвская',
            lat: 59.901218,
            lon: 30.274908,
            order: 15,
            ethrances: [
                {
                    id: 'a000aa83-3f9b-477d-8e44-05d19c570a14',
                    lat: 59.901081,
                    lon: 30.274947,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5_1',
        'Kirovskiy_Zavod',
        [[-58, -56]],
        'Кировский завод',
        {
            id: 30,
            name: 'Кировский завод',
            lat: 59.879688,
            lon: 30.261921,
            order: 16,
            ethrances: [
                {
                    id: '806763d1-76f7-46b5-aacf-7e8b31ae7131',
                    lat: 59.87979,
                    lon: 30.261733,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5_1',
        'Avtovo',
        [[-58, -64]],
        'Автово',
        {
            id: 16,
            name: 'Автово',
            lat: 59.867325,
            lon: 30.261337,
            order: 17,
            ethrances: [
                {
                    id: '79328768-4de0-4af0-915a-04518a7aaed1',
                    lat: 59.867343,
                    lon: 30.261429,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5_1',
        'Leninskiy_Prospekt',
        [[-58, -72]],
        'Ленинский проспект',
        {
            id: 35,
            name: 'Ленинский проспект',
            lat: 59.85117,
            lon: 30.268274,
            order: 18,
            ethrances: [
                {
                    id: '87728caa-ac40-42b3-aa6e-0af4ca85a908',
                    lat: 59.851678,
                    lon: 30.269645,
                },
                {
                    id: '77836652-6f4a-4c8e-ab75-b5c43d364248',
                    lat: 59.851673,
                    lon: 30.266995,
                },
                {
                    id: '3e4a94c8-3ca2-4f1f-b5e4-7ef5daf35fc0',
                    lat: 59.852035,
                    lon: 30.266963,
                },
                {
                    id: 'e05a77df-4aa4-4843-a553-cbc2d28cd2a5',
                    lat: 59.852029,
                    lon: 30.269645,
                },
                {
                    id: 'e64ff75f-6877-40df-8b28-59f4c02d8946',
                    lat: 59.849643,
                    lon: 30.26766,
                },
                {
                    id: 'f12bd6fb-c121-49e5-87b3-4e9d8e61e24e',
                    lat: 59.849627,
                    lon: 30.268991,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
    [
        's5_1',
        'Prospekt_Veteranov',
        [[-58, -80]],
        'Проспект Ветеранов',
        {
            id: 56,
            name: 'Проспект Ветеранов',
            lat: 59.84211,
            lon: 30.250588,
            order: 19,
            ethrances: [
                {
                    id: 'ddf25dc6-011f-40e5-8850-c0fa81542360',
                    lat: 59.842234,
                    lon: 30.250083,
                },
                {
                    id: '149c4fec-8fb5-4ed2-97ff-228326e818c2',
                    lat: 59.841851,
                    lon: 30.249916,
                },
                {
                    id: 'af6c2f0d-4aef-43a5-b3f9-d9012f24731b',
                    lat: 59.841488,
                    lon: 30.253717,
                },
                {
                    id: '6bdb81bb-28b3-4712-8d29-9a8cff84aa18',
                    lat: 59.841672,
                    lon: 30.253744,
                },
            ],
        },
        {
            id: '9c95d351-fdad-47c7-afce-32741d8f0cba',
            name: 'Кировско-Выборгская',
            color: '#D6083B',
        },
    ],
];
