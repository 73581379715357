export const videoCameras = (state) => state.videoCameras;

export const videoCameraList = (state) => videoCameras(state).videoCameraList;
export const videoCameraListData = (state) => videoCameraList(state).data;
export const videoCameraListMeta = (state) => videoCameraList(state).meta;

export const loadingVideoCameraList = (state) => videoCameras(state).loadingVideoCameraList;

export const videoCameraStatuses = (state) => videoCameras(state).videoCameraStatuses || [];

export const polygon = (state) => videoCameras(state).polygon;
export const active = (state) => videoCameras(state).active;
export const savedCamera = (state) => videoCameras(state).savedCamera;
export const filters = (state) => videoCameras(state).filters;
