import L from 'leaflet';

import removeEmptyFields from 'helpers/removeEmptyFields';
import { getMarker } from 'components/MapComponents/marker/Marker';

export const getFilter = (filter) => {
    return removeEmptyFields(
        {
            ...filter,
            // type_id: filter.type_id?.id || null,
        },
        false
    );
};

export const moduleName = 'congestion-ngpt';

// export const getLayerIcon = 'fas fa-cars';
export const getLayerIcon = 'far fa-chart-scatter';
export const getMarkerIcon = 'fas fa-cars';

export const getMarkerColor = '#7e7e7e';

export const getMapMarker = () => L.divIcon({
    className: 'marker',
    iconSize: [30, 36],
    iconAnchor: [15, 36],
    popupAnchor: [0, -36],
    html: `
        ${getMarker(getMarkerColor)}
        <i class="${getMarkerIcon}"
           style="width:100%;color:#fff;position:absolute;left:0;top:0;height:calc(100% - 5px);display:flex;justify-content:center;align-items:center;font-size:1.2rem;"
        />
    `
});
