import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import NoData from 'components/common/Information/NoData';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { reactSelectors } from 'modules/React';
import { loadResponseScenarios } from 'modules/React/redux/actions';

import Item from './Item';
import Filter from './Filter';
import FormModal from './FormModal';
import RenderPassportTabs from './RenderPassportTabs';

import type { Meta } from 'types/pagination';
import type { ResponseScenario } from './types';

const ResponseScenarios = () => {
    const dispatch = useDispatch();

    const data: ResponseScenario[] = useSelector(reactSelectors.responseScenariosData);
    const meta: Meta = useSelector(reactSelectors.responseScenariosMeta);
    const loading = useSelector(reactSelectors.loadingResponseScenarios);

    const [params, setParams] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
        data: {}
    });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [urlParams, setUrlParams] = useState<Record<string, number | null>>({});
    const [selectedItem, setSelectedItem] = useState<ResponseScenario | null>(null);

    const onClickItem = (item: ResponseScenario) => {
        setUrlParams(prev => ({ ...prev, id: item.id === selectedItem?.id ? null : item.id }));
    };

    const onChangePage = (page: number, limit?: number) => {
        setParams(prevParams => ({ ...prevParams, page }));
        setUrlParams({ page, limit: limit || null, id: null });
    };

    useEffect(() => {
        dispatch(loadResponseScenarios(params.page, params.limit, params.data));
    }, [dispatch, params]);

    useEffect(() => {
        //При изменении urlParams?.id устанавливаем его в setSelectedItem
        if (data?.length > 0) {
            const id  = urlParams?.id;
            const item = id ? data.find((item: ResponseScenario) => item.id === Number(id)) : null;
            setSelectedItem(item || null);
        }
    }, [data, urlParams?.id, dispatch]);

    const reloadList = (isDelete?: boolean) => {
        const page = isDelete && data.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setUrlParams({ page, limit: params.limit || null, id: selectedItem?.id || null });

        dispatch(loadResponseScenarios(params.page, params.limit, params.data));
    };

    const renderList = () => (
        <LSContainer
            isMobile={!!selectedItem?.id}
            headers={[
                { title: titles.NUMBER, width: '10%' },
                { title: titles.NAME, width: '30%' },
                { title: 'Типовой', width: '20%' },
                { title: titles.THREAT_CATEGORY, width: '35%' },
                { title: titles.ACTIONS, isActions: true }
            ]}
        >
            {data.map((item: any) => (
                <Item
                    key={item.id}
                    item={item}
                    onClickItem={() => onClickItem(item)}
                    selectedId={selectedItem?.id || null}
                    reloadList={reloadList}
                />
            ))}
        </LSContainer>
    );

    return (<>
        <PageLayout
            header="Сценарии реагирования"
            filters={<Filter setParams={setParams} />}
            informPanelProps={{
                buttons: (
                    <>
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    accessProp: '',
                                    onClick: () => setOpenAddModal(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    </>),
                total: meta?.total
            }}
            loading={loading}
            content={() => (
                data?.length > 0
                    ? <RenderPassportTabs
                        selectedItem={selectedItem}
                        queryKey="id"
                        urlParams={urlParams}
                        setUrlParams={setUrlParams}
                        setParams={setParams as any}
                        renderList={renderList}
                        reloadList={reloadList}
                    />
                    : !loading && <NoData />
            )}
            paginationProps={{
                loadList: onChangePage,
                list: meta,
                limit: params.limit,
                setLimit: (limit) => setParams(prev => ({ ...prev, limit })),
            }}
            customStyles={{ ...(!!selectedItem?.id && { padding: 0 }) }}
        />
        {openAddModal && (
            <FormModal
                isOpen={openAddModal}
                onClose={() => setOpenAddModal(false)}
                isNew
                reloadList={reloadList}
            />
        )}
    </>);
};

export default ResponseScenarios;
