import { Fragment, useMemo } from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

import { loadComplexEntityTypes } from 'redux/DorisControl/actions';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { config } from 'config';

// список оборудования деревом
const EntitiesList = ({
    entities = [],
}) => {
    const projectCode = config.project?.code;
    const types = useStoreProp(loadComplexEntityTypes, 'dorisControl', 'entities_types');

    const list = useMemo(() => {
        // добавляем в объект entity_id для каждого эл-та
        const dataList = Object.entries(entities).reduce((res, [entity_id, el]) => {
            res[entity_id] = el?.map(elem => ({ ...elem, entity_id }));
            return res;
        }, {});

        const resultArr = (entities[3] || [])?.map(elem => {
            const recognition = (dataList[4] || []).filter((el) => el.computer_id === elem.id);
            const observation = (dataList[5] || []).filter((el) => el.computer_id === elem.id);
            return {
                ...elem,
                entity_id: 3,
                entities: [...recognition, ...observation]
            };
        });

        return [
            // пока только 3 типа
            ...resultArr,
            // ...(dataList[1] || []),
            // ...(dataList[2] || []),
            // ...(dataList[6] || [])
        ];
    }, [entities]);

    const getItemStyle = (isBottomBorder) => ({
        padding: '.4rem 1rem',
        cursor: 'default',
        border: 0,
        borderBottom: isBottomBorder ? '1px solid #d9d9d9' : 0,
    });

    const RenderItem = ({
        item,
        isBottomBorder,
    }) => (
        <ListItem className="list_item" style={getItemStyle(isBottomBorder)} >
            <ListItemText>
                <Typography variant="body2">
                    <span><strong>Тип: </strong> {types?.[item.entity_id]?.name || messages.NO_DATA}</span>
                    {item?.serial && projectCode !== '34_vlg_vlz' && <span>; <strong>Серийный номер: </strong> {item?.serial}</span>}
                    {item?.code && projectCode !== '34_vlg_vlz' && <span>; <strong>Идентификатор: </strong> {item?.code}</span>}
                    {item?.name && projectCode !== '34_vlg_vlz' && <span>; <strong>Название: </strong> {item.name}</span>}
                    {item?.ip_route && <span>; <strong>IP адрес: </strong> {item.ip_route || messages.NO_DATA}</span>}
                    {item?.admin_url && (
                        <span>; <strong>Админпанель: </strong>
                            <a href={item?.admin_url} target="_blank" rel="noreferrer">{item?.admin_url}</a>
                        </span>
                    )}
                </Typography>
            </ListItemText>
        </ListItem>
    );

    const getKey = (el) => `${el.id}${el.entity_id}`;

    return (
        <>
            <h3>Оборудование:</h3>
            <List className="list">
                {list?.map((item) => (
                    <Fragment key={getKey(item)}>
                        <RenderItem item={item}/>

                        {item.entities?.length > 0 && (
                            <List className="info" style={{ background: '#fff', paddingTop: 0 }}>
                                {item.entities.map((el) => (
                                    <RenderItem
                                        key={getKey(el)}
                                        item={el}
                                        isBottomBorder
                                    />
                                ))}
                            </List>
                        )}
                    </Fragment>
                ))}
            </List>
        </>
    );
};

export default EntitiesList;
