import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { valid } from 'geojson-validation';
import L from 'leaflet';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Loading from 'components/common/Loading';
import Information from 'components/common/Information';
import { getFullDateTimeWithTextMonth } from 'helpers/date.config';
import { loadEdgerById, loadEdgerInfoById } from 'modules/DigitalTwin/redux/actions';
import { getTypeText } from 'modules/DigitalTwin/utils/helpers';
import { helpers, ObjectBindingList } from 'modules/DigitalTwin/utils/components/ObjectBindings';

import Node from './Node';

const Edge = ({
    id,
    item,
    isHideNodes,
}) => {

    const dispatch = useDispatch();

    const [data, setData] = useState(item || {});
    const [loading, setLoading] = useState(false);

    // допинфо
    // const [dataInfo, setDataInfo] = useState({});
    // const [loadingInfo, setLoadingInfo] = useState(false);

    useEffect(() => {
        if (id && !item) {
            dispatch(loadEdgerById(
                id,
                setData,
                setLoading
            ));
            // dispatch(loadEdgerInfoById(
            //     id,
            //     setDataInfo,
            //     setLoadingInfo
            // ));
        }
    }, [id, item, dispatch]);

    const fields = {
        created_at: {
            title: titles.DATE_TIME_CREATED,
            value: ({ created_at }) => getFullDateTimeWithTextMonth(created_at) || messages.INFO_IS_NOT_FOUND
        },
        name: {
            title: titles.NAME,
        },
        type: {
            title: titles.TYPE,
            value: ({ type }) => getTypeText(type)
        }
    };

    const getTitle = (currentId) => {
        switch (currentId) {
            case data?.previous_node_id:
                return 'Предыдущий узел';
            case data?.next_node_id:
                return 'Следующий узел';
            default:
                return '';
        }
    };

    const renderNodes = () => {
        return isHideNodes
            ? null
            : data?.nodes?.map?.(node => (
                <div key={node?.id}>
                    <hr/>
                    <Node
                        item={node}
                        title={getTitle(node?.id)}
                        isHideEdges
                    />
                </div>
            ));
    };

    const center = useMemo(() => {
        if (data?.geometry && valid(data?.geometry)) {
            const json = L.geoJSON(data?.geometry || {});
            const latLng = json?.getBounds?.()?.getCenter?.();
            return latLng || null;
        }
        return null;
    }, [data?.geometry]);

    return (
        <>
            {loading && <Loading circular/>}

            <Information
                data={data}
                title={fields}
                returnNull
            />

            {/* связанные узлы */}
            {renderNodes()}

            {!isHideNodes && (
                <ObjectBindingList
                    itemId={id}
                    itemType={helpers.objectTypes.edge}
                    itemLatLon={center}
                />
            )}

        </>
    );
};

export default Edge;
