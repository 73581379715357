import Graphics from '../../DigitalTwin/Dictionaries/Graphics';
import Nodes from '../Dictionaries/Nodes';
import Edges from '../Dictionaries/Edges';

const dictionariesRoutes = [
    {
        path: '/digital-twin/dictionaries/graphics',
        component: Graphics,
        exact: true,
    },
    {
        path: '/digital-twin/dictionaries/nodes',
        component: Nodes,
        exact: true,
    },
    {
        path: '/digital-twin/dictionaries/edges',
        component: Edges,
        exact: true,
    },
];

export default dictionariesRoutes;
