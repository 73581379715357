import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import queryString from 'query-string';
import { Grid } from '@mui/material';

import {
    loadBoardBefore,
    loadBoardColors,
    loadBoards,
    loadBoardStatuses,
    loadCategories
} from 'redux/Boards/actions';
import { boardsSelectors } from 'redux/Boards';
import messages from 'helpers/constants/messages';
import { useStoreFromSelector, useStoreProp } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import titles from 'helpers/constants/titles';

import AddEditModal from './Modal';
import InfoTabs from './InfoTabs';
import Item from './Item';
import Filter from './Filter';

const Boards = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const scrollRef = useRef(null);

    const boadrCategories = useStoreProp(loadCategories, 'boards', 'boadrCategories');
    const boardColors = useStoreProp(loadBoardColors, 'boards', 'boardColors');
    const boardStatuses = useStoreFromSelector(loadBoardStatuses, boardsSelectors.boardStatuses);
    const boardStatusesObj = useMemo(() => boardStatuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [boardStatuses]);

    const boards = useSelector(boardsSelectors.boards);
    const loading = useSelector(boardsSelectors.loading);
    const loadingBefore = useSelector(boardsSelectors.loadingBefore);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [openAddModal, setOpenAddModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [focus, setFocus] = useState(null);

    const reloadList = (isDelete) => {
        const page = isDelete && boards?.data?.length === 1 && boards?.meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    const filterFunc = (params) => {
        const asArray = Object.entries(params).filter(([key, value]) => value);
        return Object.fromEntries(asArray);
    };

    const stringifyUrlFunc = (param = {}) => {
        const stringifyUrl = (queryString.stringify(filterFunc(param), { arrayFormat: 'bracket' }));
        history.replace({ pathname: '/dictionaries/board', search: stringifyUrl });
    };

    const handleClick = (item) => {
        setFocus(item.id);
        if (item.id === selectedItem?.id) {
            stringifyUrlFunc({ page: params.page, limit });
        } else {
            stringifyUrlFunc({ page: params.page, limit, board_id: item.id });
        }
    };

    useEffect(()=> {
        setSelectedItem(prevState => (boards?.data?.find(el => prevState?.id === el?.id) || {}));
    }, [boards.data]);

    useEffect(() => {
        dispatch(loadBoards({ page: params.page, limit, ...params.data }));
    }, [params, limit, dispatch]);

    useEffect(()=> {
        const { page, limit } = (queryString.parse(location.search, { arrayFormat: 'bracket' }));

        if (page || limit) {
            const filterParams = filterFunc({ page, limit });

            setParams((params)=> ({
                ...params,
                ...filterParams
            }));
        }
    }, []);

    useEffect(() => {
        if (boards?.data?.length > 0) {
            const { board_id } = queryString.parse(location.search, { arrayFormat: 'bracket' });
            const board = board_id ? boards.data?.find(item => item.id === Number(board_id)) : null;
            setSelectedItem(board);

            if (board_id && !board) {
                dispatch(loadBoardBefore(board_id));
            }
        }
    },[dispatch, boards.data, location.search]);

    const RenderList = () => {
        return (
            <LSContainer
                headers={[
                    { title: '', width: '50px' },
                    { title: titles.NAME, width: '20%' },
                    { title: titles.CATEGORY, width: '20%' },
                    { title: titles.SERIAL_NUMBER, width: '20%' },
                    { title: titles.ACTIONS, align: 'right', isActions: true }
                ]}
                isMobile={!!selectedItem}
            >
                {boards?.data?.map((el) => 
                    <Item
                        el={el}
                        key={el.id}
                        reloadList={reloadList}
                        onClick={() => handleClick(el)}
                        selectedItem={selectedItem}
                        boardStatus={boardStatusesObj[el?.status]}
                        focus={focus}
                    />
                )}
            </LSContainer>
        );
    };

    const renderContent = () => boards?.data?.length > 0
        ? (
            <Grid container style={selectedItem?.id ? { height: '100%' } : {}}>
                <Grid
                    item
                    xs={Object.keys(selectedItem || {}).length > 0 ? 3 : 12}
                >
                    {selectedItem ? (
                        <Scrollbars ref={scrollRef}>
                            <RenderList/>
                        </Scrollbars>
                    ) : (
                        <RenderList/>
                    )}
                </Grid>
                {Object.keys(selectedItem || {}).length > 0 && (
                    <Grid item xs={9}>
                        <InfoTabs
                            colors={boardColors}
                            selectedItem={selectedItem}
                            reloadList={reloadList}
                        />
                    </Grid>
                )}
            </Grid>
        )
        : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>);

    return (
        <>
            <PageLayout
                header="Справочник ДИТ и ЗПИ"
                filters={<Filter setParams={setParams} categories={boadrCategories} />}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            positionLeft
                            noPadding
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                }
                            ]}
                        />
                    ),
                    total: boards.meta?.total
                }}
                loading={loading || loadingBefore}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: boards.meta,
                    limit,
                    setLimit
                }}
                customStyles={{ 
                    overflowY: selectedItem?.id ? 'hidden' : 'scroll', 
                    ...(selectedItem?.id && { padding: 0 })
                }}
            />
            {openAddModal && (
                <AddEditModal
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                    statuses={boardStatuses}
                />
            )}
        </>
    );
};

export default Boards;
