import { Collapse, List, ListItem, ListItemText } from '@mui/material';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import type { Trigger } from './types';


interface InfoProps {
    isOpen: boolean,
    data: Trigger
}

const Info = ({ isOpen, data }: InfoProps) => {
    return (
        <Collapse className="info" in={isOpen} timeout={'auto'}>
            <b>Явления:</b>
            <List className="list" disablePadding>
                {data?.phenomenons?.length > 0
                    ? data?.phenomenons.map(item =>
                        <ListItem key={item.phenomenon?.id}>
                            <ListItemText>
                                <div>
                                    <b>{titles.NAME}: </b> {item.phenomenon?.name || messages.NO_DATA}
                                </div>
                                <div>
                                    <b>Присвоенный объект: </b> {item?.entity_id || messages.NO_DATA}
                                </div>
                                <div>
                                    <b>Значение: </b> {item?.value || messages.NO_DATA}
                                </div>
                                <div>
                                    <b>Единица измерения: </b> {item.phenomenon?.unit || messages.NO_DATA}
                                </div> 
                            </ListItemText>
                        </ListItem>
                    )
                    : messages.INFO_IS_NOT_FOUND
                }
            </List>
        </Collapse>
    );
};

export default Info;