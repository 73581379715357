import { useState } from 'react';

import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import SelectTrafficSchedule from 'components/common/Autocomplete/PassengerTransport/TrafficSchedule';
import SelectMilestoneEvents from 'components/common/Autocomplete/PassengerTransport/MilestoneEvents';
import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import InputNumber from 'components/common/Inputs/InputNumber';

const Filters = ({
    setSelect,
    setParams,
    initialState,
    disabledFields = [],
    setFiltersValues,
    filterValues,
    isSearched,
    setTrafficSchedule
}) => {
    const [isDateInvalid, setIsDateInvalid] = useState(false);

    const handleChange = (value, name, isMulti = false) => {
        setFiltersValues({
            ...filterValues,
            [name]: isMulti ? [...filterValues[name], value] : value
        });
    };

    const handleChangeSelect = (value, name) => {
        setFiltersValues({
            ...filterValues,
            [name]: value
        });
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh || isSearched) {
            setParams(initialState);
            setTrafficSchedule([]);
        }
        setFiltersValues(initialState);
        setSelect({});
    };

    const updateFilter = (filters) => {
        setFiltersValues({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            setParams({
                ...initialState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={() => setParams(filterValues)}
            filter={filterValues}
            setUserFilter={updateFilter}
            disabled={isDateInvalid
                || !filterValues.start_date_at
                || !filterValues.end_date_at
            }
            filterException={['start_date_at', 'end_date_at',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChange(value, 'start_date_at')}
                    handleEndDate={(value) => handleChange(value, 'end_date_at')}
                    valueStartDate={filterValues.start_date_at}
                    valueEndDate={filterValues.end_date_at}
                    dateOnly
                    isDisabledHandler={setIsDateInvalid}
                    disableStartField={disabledFields.includes('start_date_at')}
                    disableEndField={disabledFields.includes('end_date_at')}
                    equalDates
                />
                <SelectTrafficSchedule
                    selected={filterValues.traffic_schedule_ids}
                    onChange={(value) => handleChangeSelect(value, 'traffic_schedule_ids')}
                    multiple
                    isDisabled={disabledFields.includes('traffic_schedule_ids')}
                />
                <SelectRoutes
                    selected={filterValues.route}
                    onChange={(value) => handleChange(value, 'route')}
                    multiple={true}
                    disabled={disabledFields.includes('route')}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectVehicles
                    multiple
                    selected={filterValues.grz}
                    onChange={(value) => handleChange(value, 'grz')}
                    label="Номер ТС"
                    disabled={disabledFields.includes('grz')}
                />
                <SelectCompany
                    multiple
                    selected={filterValues.organization_id}
                    onChange={(value) => handleChange(value, 'organization_id')}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                    disabled={disabledFields.includes('organization_id')}
                />

                <SelectMilestoneEvents
                    selected={filterValues.milestone_event_id_list || []}
                    onChange={(value) => handleChangeSelect(value, 'milestone_event_id_list')}
                    multiple
                    isDisabled={disabledFields.includes('milestone_event_id_list')}
                />

                <InputNumber
                    value={filterValues.id}
                    onChange={({ target: { value, name } }) => handleChange(value, name)}
                    name="id"
                    label="ID наряда"
                    disabled={disabledFields.includes('id')}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
