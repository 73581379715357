/**
 * Sagas
 * **/
import { all, call, put, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { showMessage } from 'redux/Message/actions';
import messageTypes from 'redux/Message/messagetypes';
import { setValidation } from 'redux/Validation/actions';
import { saveStatus } from 'redux/DorisControl/actions';
import { setMapLoading } from 'redux/Map/actions';
import { getReport } from 'helpers/getReport';
import common from 'helpers/constants/messages/common';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { getHumanDate } from 'helpers/date.config';
import { messageHelper } from 'helpers/messageHelper';

import * as actions from './actions';
import * as types from './types';
import api from './api.methods';

function* loadStationsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingStationsList(true));
    const stationsList = yield call(api.getStationList, page, limit, query);
    if (stationsList) {
        yield put(actions.loadedStationsList(stationsList));
    }
    yield put(actions.loadingStationsList(false));
}

function* createNewStationSaga({ payload, callback }) {
    const response = yield call(api.createNewStation, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
        yield callback?.();
    }
}

function* getOrganizationReport({ payload = {} }) {
    const { report, date_start = '', date_end = '' } = payload;

    let period = date_start !== '' ? `_from_${getHumanDate(date_start)}` : '';
    period += date_end !== '' ? `_to_${getHumanDate(date_end)}` : '';

    const response = yield call(api.getOrganizationReport, payload);

    if (response) {
        getReport(response, report, period);
    }
}

function* editStationSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editStation, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Данные были успешно изменены');
        yield callback?.();
    }
}

function* addScheduleSaga({ payload }) {
    yield call(api.addSchedule, payload);
}

function* editScheduleSaga({ payload }) {
    yield call(api.editSchedule, payload);
}

function* deleteScheduleSaga({ payload }) {
    yield call(api.deleteSchedule, payload);
}

function* loadScheduleSaga({ payload }) {
    yield put(actions.loadingSchedule(true));
    const schedule = yield call(api.getStationSchedule, payload);
    if (schedule) {
        yield put(actions.loadedSchedule({ ...schedule, ...payload }));
    }
    yield put(actions.loadingSchedule(false));
}

function* loadRouteTypesSaga() {
    const routeTypes = yield call(api.getRouteTypes);
    if (routeTypes) {
        yield put(actions.loadedRouteTypes(routeTypes.data));
    }
}

function* loadBrandsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingBrandsList(true));
    const brandsList = yield call(api.getBrandList, page, limit, query);
    if (brandsList) {
        yield put(actions.loadedBrandsList(brandsList));
    }
    yield put(actions.loadingBrandsList(false));
}

function* createBrandSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createBrand, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
    }
    yield put(actions.loadingButton(false));
}

function* editBrandSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editBrand, id, data);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* deleteBrandSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteBrand, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadVehiclesSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingVehicleList(true));
    const response = yield call(api.getVehicleList, page, limit, data);
    if (response) {
        yield put(actions.loadedVehicleList(response));
    }
    yield put(actions.loadingVehicleList(false));
}

function* loadVehiclesNoWorkorderSaga({ payload }) {
    const { page, limit, data } = payload;
    const response = yield call(api.getVehiclesNoWorkorder, page, limit, data);
    if (response) {
        yield put(actions.loadedVehicleListNoWorkorder(response));
    }
}

// Отчет по транспортным средствам
function* getFilteredListSaga({ payload }) {
    const report = payload?.report || false;
    const config = { params: { ...payload } };

    yield put(actions.loadingFilteredTSList(true));
    const response = yield call(api.getVehicleReportList, config);
    if (response?.success) {
        if (report) {
            yield messageHelper(response);
        } else {
            yield put(actions.loadedFilteredTSList(response));
        }
    }
    yield put(actions.loadingFilteredTSList(false));
}

function* loadVehicleCategoriesSaga() {
    const response = yield call(api.getCategories);
    if (response) {
        yield put(actions.loadedVehicleCategories(response));
    }
}

function* loadVehicleCategoryListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingVehicleCategoryList(true));
    const response = yield call(api.getVehicleCategoryList, page, limit, query);
    if (response) {
        yield put(actions.loadedVehicleCategoryList(response));
    }
    yield put(actions.loadingVehicleCategoryList(false));
}

function* createVehicleCategorySaga({ payload, callback }) {
    const response = yield call(api.createVehicleCategory, payload);
    if (response?.success) {
        yield callback();
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
    }
}

function* editVehicleCategorySaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editVehicleCategory, id, data);
    if (response?.success) {
        yield callback();
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteVehicleCategorySaga({ payload, callback }) {
    const response = yield call(api.deleteVehicleCategory, payload);
    if (response) {
        yield callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadVehiclesTypesSaga() {
    const response = yield call(api.getTypes);
    if (response) {
        yield put(actions.loadedVehiclesTypes(response));
    }
}

function* deleteVehicleSaga({ payload, callback }) {
    yield call(api.deleteVehicle, payload);
    callback && callback(true);
}

function* loadModelsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingStationsList(true));
    const modelsList = yield call(api.getModelList, page, limit, query);
    if (modelsList) {
        yield put(actions.loadedModelsList(modelsList));
    }
    yield put(actions.loadingStationsList(false));
}

function* createModelSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createModel, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* editModelSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editModel, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* deleteModelSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteModel, payload);
    if (response.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield callback?.();
    }
    yield put(actions.loadingButton(false));
}

function* loadCompaniesListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingStationsList(true));
    const companiesList = yield call(api.getCompanyList, page, limit, query);
    if (companiesList) {
        yield put(actions.loadedCompaniesList(companiesList));
    }
    yield put(actions.loadingStationsList(false));
}

function* createCompanySaga({ payload, callback }) {
    const response = yield call(api.createCompany, payload);
    if (response?.success) {
        yield callback();
    }
}

function* editCompanySaga({ payload, callback }) {
    const response = yield call(api.editCompany, payload);
    if (response?.success) {
        yield callback();
    }
}

function* deleteCompanySaga({ payload }) {
    const response = yield call(api.deleteCompany, payload);
    if (response) {
        yield put(actions.loadCompaniesList());
    }
}

function* getCompaniesListSaga({ payload }) {
    // TODO сервис dadata вынести в отдельный модуль
    const { page, limit, query } = payload;
    yield put(actions.loadingStationsList(true));
    const companiesList = yield call(api.getCompanies, page, limit, query);
    if (companiesList) {
        yield put(actions.saveCompaniesList(companiesList));
    }
    yield put(actions.loadingStationsList(false));
}

// function* loadCompanySaga({ payload }) {
//     const { page, limit, query } = payload;
//     const response = yield call(api.getCompanyList, page, limit, query);
//     if (response) {
//         yield put(loadedCompany(response));
//     }
// }

function* createNewVehicleSaga({ payload, callback }) {
    const response = yield call(api.createNewVehicle, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        callback && callback();
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
    }
}

function* editVehicleSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editVehicle, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        callback && callback();
        yield showMessage(messageTypes.success, 'Данные были успешно изменены');
    }
}

function* loadRouteNumsSaga() {
    yield put(actions.loadingRouteNums(true));
    const response = yield call(api.getRouteNums);
    if (response?.success) {
        yield put(actions.loadedRouteNums(response));
    }
    yield put(actions.loadingRouteNums(false));
}

function* loadRoutesGroupSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingRoutesGroup(true));
    const response = yield call(api.getRoutes, page, limit, data);
    if (response?.success) {
        yield put(actions.loadedRoutesGroup(response));
    }
    yield put(actions.loadingRoutesGroup(false));
}

function* loadRoutesSaga({ payload, callback }) {
    const { page, limit, data, bool } = payload;
    yield put(actions.loadingRoutes(true));
    const response = yield call(api.getRoutes, page, limit, data);
    if (response?.success) {
        if (bool){
            yield put(actions.loadedRoutesForTrafficSchedule(response));
        } else {
            yield put(actions.loadedRoutes(response));
            yield callback && callback(response);
        }
    }
    yield put(actions.loadingRoutes(false));
}

function* loadRoutesStatusesSaga() {
    const response = yield call(api.getRouteStatuses);
    if (response?.success) {
        yield put(actions.loadedRoutesStatuses(response));
    }
}

function* loadRoutesTransportationsSaga() {
    const response = yield call(api.getRouteTransportations);
    if (response?.success) {
        yield put(actions.loadedRoutesTransportations(response));
    }
}

function* saveRoutesSaga({ payload }) {
    const response = yield call(api.saveRoutes, payload);
    if (response?.success) {
        yield put(actions.loadRoutesGroup());
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
    }
}

function* createRouteSaga({ payload, callback }) {
    const response = yield call(api.createRoute, payload);
    if (response?.success) {
        callback(response?.data);
        yield showMessage(messageTypes.success, 'Маршрут был успешно сохранен');
    }
}

function* changeRouteSaga({ payload, callback }) {
    const { uuid, data } = payload;
    const response = yield call(api.changeRoute, uuid, data);
    if (response?.success) {
        callback && callback();
        // yield put(actions.loadRoutes()); // обновляем список через callback
        // yield showMessage(messageTypes.success, 'Успешно сохранено');

        yield put(actions.changeRoutesAfter(response?.data || {}));
    }
    yield put(actions.setChangeRoutesStatus(response?.success || false));
}

function* createRouteV2Saga({ payload, callback }) {
    yield put(actions.setRouteLoading(true));
    const response = yield call(api.createRouteV2, payload);
    if (response?.success) {
        callback?.();
        yield showMessage(messageTypes.success, 'Маршрут был успешно сохранен');
    }
    yield put(actions.setRouteLoading(false));
}

function* changeRouteV2Saga({ payload, callback }) {
    yield put(actions.setRouteLoading(true));
    const { uuid, data } = payload;
    const response = yield call(api.changeRouteV2, uuid, data);
    if (response?.success) {
        callback?.();
        yield put(actions.changeRoutesAfter(response?.data || {}));
    }
    yield put(actions.setChangeRoutesStatus(response?.success || false));
    yield put(actions.setRouteLoading(false));
}

function* loadRouteScheduleSaga({ payload }) {
    yield put(actions.loadingRouteSchedule(true));
    const response = yield call(api.getRouteSchedule, payload);
    if (response?.success) {
        yield put(actions.loadedRouteSchedule(response));
    }
    yield put(actions.loadingRouteSchedule(false));
}

function* loadScheduleTypesSaga({ payload }) {
    const response = yield call(api.getScheduleTypes);
    if (response?.success) {
        yield put(actions.loadedScheduleTypes(response));
    }
}

function* getVehicleReport({ payload = {} }) {
    const { report, date_start = '', date_end = '' } = payload;

    let period = date_start !== '' ? `_from_${getHumanDate(date_start)}` : '';
    period += date_end !== '' ? `_to_${getHumanDate(date_end)}` : '';

    const response = yield call(api.getVehicleReport, payload);
    if (response) {
        getReport(response, report, period);
    }
}

// function* getCurrentPassengerReportSaga({ payload= {}, callbackClose }) {
//     const id = payload.id;
//
//     const response = yield call(api.getCurrentPassengerReport, id, payload.params);
//     if (response) {
//         yield messageHelper(response);
//         // getReport(response, report, period);
//         yield callbackClose();
//     }
// }

function* getCurrentSpecialReportSaga({ payload = {}, callbackClose }) {
    const uid = payload.uid;
    const { report, date_start = '', date_end = '' } = payload.params;
    let period = date_start !== '' ? `_from_${getHumanDate(date_start)}` : '';
    period += date_end !== '' ? `_to_${getHumanDate(date_end)}` : '';
    const response = yield call(api.getCurrentSpecialReport, uid, payload.params);
    if (response) {
        getReport(response, report, period);
        yield callbackClose();
    }
}

function* loadNextStationSaga({ payload }) {
    const response = yield call(api.getNextStation, payload);
    if (response) {
        yield put(actions.loadedNextStation(response.data));
    }
}

function* loadVehiclePopupSaga({ payload }) {
    yield put(actions.loadingVehiclePopup(true));
    const response = yield call(api.getVehiclePopup, {
        page: 1,
        limit: 1,
        ...payload
    });
    if (response) {
        yield put(actions.loadedVehiclePopup(response?.data[0] || {}));
    }
    yield put(actions.loadingVehiclePopup(false));
}

function* loadVehicleHistorySaga({ payload }) {
    yield put(actions.loadingVehicleHistory(true));
    const response = yield call(api.getVehicleHistory, payload);

    if (response) {
        const { data } = response;
        if (data.length === 0) {
            yield put(showMessage(messageTypes.success, 'История ТС не найдена', ''));
        }
        yield put(actions.loadedVehicleHistory(response?.data || []));
    }
    yield put(actions.loadingVehicleHistory(false));
}

function* loadVehicleFlightHistorySaga({ payload }) {
    yield put(actions.loadingVehicleFlightHistory(true));
    const response = yield call(api.getVehicleFlightHistory, payload);

    if (response) {
        const { data } = response;
        if (data?.points?.features?.length === 0) {
            yield put(showMessage(messageTypes.success, 'История движения ТС в рейсе не найдена', ''));
        }
        yield put(actions.loadedVehicleFlightHistory(response?.data || {}));
    }
    yield put(actions.loadingVehicleFlightHistory(false));
}

function* loadCurrentVehiclesSaga ({ payload }) {
    yield put(actions.loadingCurrentVehicles(true));
    const response = yield call(api.getCurrentVehicles, payload);
    if (response) {
        yield put(actions.loadedCurrentVehicles({ [payload]: response.data } ));
    }
    yield put(actions.loadingCurrentVehicles(false));
}

function* editVehicleToRouteSaga({ payload }) {
    const { id, data, routeNum } = payload;
    yield put(actions.loadingCurrentVehicles(true));
    const response = yield call(api.editVehicle, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(actions.loadCurrentVehicles(routeNum));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingCurrentVehicles(false));
}

function* loadCurrentVehicleTypeSaga ({ payload }) {
    const { page, limit, query, category_id } = payload;
    yield put(actions.loadingCurrentVehicleType(true));
    const response = yield call(api.getCurrentVehiclesType, page, limit, query, category_id);
    if (response?.success) {
        yield put(actions.loadedCurrentVehicleType(response));
    }
    yield put(actions.loadingCurrentVehicleType(false));
}

function* saveSelectedVehicleToRouteSaga ({ payload }) {
    const { data, routeNum } = payload;
    const response = yield call(api.saveSelectedVehicleToRoute, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(actions.loadCurrentVehicles(routeNum));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* loadRoutes_Saga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingRoutes(true));
    const response = yield call(api.getRoutes_, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedRoutes_(response));
    }
    yield put(actions.loadingRoutes(false));
}

function* loadPositionsSaga ({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingPositions(true));
    const response = yield call(api.getPositions, page, limit, data);
    if (response) {
        yield put(actions.loadedPositions(response));
    }
    yield put(actions.loadingPositions(false));
}

function* addPositionsSaga ({ payload, callback }) {
    yield put(actions.loadingPositions(true));
    const response = yield call(api.addPositions, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingPositions(false));
}

function* editPositionsSaga ({ payload, callback }) {
    yield put(actions.loadingPositions(true));
    const { id, data } = payload;
    const response = yield call(api.editPositions, id, data);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingPositions(false));
}

function* deletePositionsSaga ({ payload, callback }) {
    yield put(actions.loadingPositions(true));
    const response = yield call(api.deletePositions, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingPositions(false));
}

function* loadPositionSaga ({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingPosition(true));
    const response = yield call(api.getPositions, page, limit, data);
    if (response) {
        const positions = response.data?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
        yield put(actions.loadedPosition(positions));
    }
    yield put(actions.loadingPosition(false));
}

function* loadScheduleEventSaga ({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingScheduleEvent(true));
    const response = yield call(api.getScheduleEvent, page, limit, data);
    if (response) {
        yield put(actions.loadedScheduleEvent(response));
    }
    yield put(actions.loadingScheduleEvent(false));
}

function* addScheduleEventSaga ({ payload }) {
    const response = yield call(api.addScheduleEvent, payload);
    if (response) {
        yield put(actions.loadScheduleEvent());
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editScheduleEventSaga ({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editScheduleEvent, id, data);
    if (response) {
        yield put(actions.loadScheduleEvent());
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteScheduleEventSaga ({ payload }) {
    const response = yield call(api.deleteScheduleEvent, payload);
    if (response) {
        yield put(actions.loadScheduleEvent());
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadEmployeesSaga ({ payload }) {
    const { page, limit, data } = payload;
    const report = data?.report;
    const config = report
        ? { params: { ...data }, responseType: 'blob' }
        : { params: { page, limit, ...data } };

    yield put(actions.loadingEmployees(true));
    const response = yield call(api.getEmployees, config);
    if (response) {
        report
            ? getReport(response, report)
            : yield put(actions.loadedEmployees(response));
    }
    yield put(actions.loadingEmployees(false));
}

function* loadEmployeeSaga ({ payload, callback }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingEmployee(true));
    const response = yield call(api.getEmployees, page, limit, data);
    if (response) {
        const employees = response.data?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
        yield put(actions.loadedEmployee(employees));
        callback && callback(employees);
    }
    yield put(actions.loadingEmployee(false));
}

function* addEmployeesSaga ({ payload, callback }) {
    const response = yield call(api.addEmployees, payload);
    yield put(actions.loadingEmployees(true));
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingEmployees(false));
}

function* editEmployeesSaga ({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingEmployees(true));
    const response = yield call(api.editEmployees, id, data);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingEmployees(false));
}

function* deleteEmployeesSaga ({ payload, callback }) {
    const response = yield call(api.deleteEmployees, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadBrigadesSaga ({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingBrigades(true));
    const response = yield call(api.getBrigades, page, limit, data);
    if (response) {
        yield put(actions.loadedBrigades(response));
    }
    yield put(actions.loadingBrigades(false));
}

function* loadBrigadesNoWorkorderSaga ({ payload }) {
    const { page, limit, data } = payload;
    const response = yield call(api.getBrigadesNoWorkorder, page, limit, data);
    if (response) {
        yield put(actions.loadedBrigadesNoWorkorder(response));
    }
}

function* addBrigadesSaga ({ payload, callback }) {
    yield put(actions.loadingBrigades(true));
    const response = yield call(api.addBrigades, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingBrigades(false));
}

function* editBrigadesSaga ({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingBrigades(true));
    const response = yield call(api.editBrigades, id, data);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingBrigades(false));
}

function* deleteBrigadesSaga ({ payload, callback }) {
    yield put(actions.loadingBrigades(true));
    const response = yield call(api.deleteBrigades, payload);
    if (response) {
        callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingBrigades(false));
}

//PARKS
function* loadParksListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingParksList(true));
    const response = yield call(api.getParks, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedParksList(response));
    }
    yield put(actions.loadingParksList(false));
}

function* createParkSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.postPark, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* editParkSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.putPark, id, data);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* deleteParkSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deletePark, payload);
    if (response?.success) {
        yield callback?.();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadStationTypesSaga() {
    const response = yield call(api.getStationTypes);
    if (response?.success) {
        yield put(actions.loadedStationTypes(response?.data));
    }
}

function* deleteStationSaga({ payload, callback }) {
    const response = yield call(api.deleteStation, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield callback?.();
    }
}

// Equipments
function*  loadEquipmentsSaga () {
    yield put(actions.loadingEquipmentsList(true));
    const response = yield call(api.getEquipments);
    if (response?.success) {
        yield put(actions.loadedEquipmentsList(response?.data));
    }
    yield put(actions.loadingEquipmentsList(false));
}

function* loadRouteStationSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingRouteStation(true));
    const response = yield call(api.getRouteStation, page, limit, data);
    if (response) {
        yield put(actions.loadedRouteStation({ [data?.route_id]: response?.data }));
    }
    yield put(actions.loadingRouteStation(false));
}

// SCHEDULE_TEMPLATE
function* loadScheduleTemplateListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingScheduleTemplateList(true));
    const response = yield call(api.getScheduleTemplateList, page, limit, query);
    if (response) {
        yield put(actions.loadedScheduleTemplateList(response));
    }
    yield put(actions.loadingScheduleTemplateList(false));
}

function* loadScheduleTemplateStatusesSaga() {
    const response = yield call(api.getScheduleTemplateStatuses);
    if (response) {
        yield put(actions.loadedScheduleTemplateStatuses(response?.data));
    }
}

function* createScheduleTemplateSaga({ payload, callback }) {
    const response = yield call(api.createScheduleTemplate, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* saveRouteStationsSaga ({ payload, callback }) {
    const response = yield call(api.saveRouteStations, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editScheduleTemplateSaga({ payload, callback }) {
    const { template, data } = payload;
    const response = yield call(api.editScheduleTemplate, template.id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteScheduleTemplateSaga({ payload, callback }) {
    const { template } = payload;
    const response = yield call(api.deleteScheduleTemplate, template.id);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadIntersectionRoutesSaga({ payload }) {
    const response = yield call(api.getIntersectionRoutes, payload);
    if (response?.success) {
        yield put(actions.loadedIntersectionRoutes(response?.data));
    }
}

function* loadWorkordersSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingWorkorders(true));
    const response = yield call(api.getWorkorders, page, limit, data);
    if (response) {
        yield put(actions.loadedWorkorders(response));
    }
    yield put(actions.loadingWorkorders(false));
}

function* loadCurrentWorkorderSaga({ payload, callback }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingCurrentWorkorder(true));
    const response = yield call(api.getWorkorders, page, limit, data);
    if (response) {
        // yield put(actions.loadedCurrentWorkorder({ [data?.id]: response?.data[0] }));
        callback && callback(response?.data[0]);
    }
    yield put(actions.loadingCurrentWorkorder(false));
}

function* loadWorkordersByDateSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingWorkordersByDate(true));
    const response = yield call(api.getWorkordersByDate, page, limit, data);
    if (response) {
        yield put(actions.loadedWorkordersByDate(response));
    }
    yield put(actions.loadingWorkordersByDate(false));
}

function* loadWorkorderEventsSaga({ payload }) {
    yield put(actions.loadingWorkorderEvents(true));
    const response = yield call(api.getWorkorderEvents, payload);
    if (response) {
        yield put(actions.loadedWorkorderEvents({ [payload]: response?.data }));
    }
    yield put(actions.loadingWorkorderEvents(false));
}

function* loadWorkorderEventStatusesSaga() {
    const response = yield call(api.getWorkorderEventStatuses);
    if (response) {
        yield put(actions.loadedWorkorderEventStatuses(response?.data));
    }
}

function* addWorkorderSaga ({ payload, callback }) {
    const response = yield call(api.saveWorkorder, payload);
    if (response) {
        callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editWorkorderSaga ({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editWorkorder, id, data);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* editWorkorderStatusSaga ({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editWorkorderStatus, id, data);

    if (response?.success) {
        yield typeof callback === 'function' && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

function* loadWorkorderStatusesSaga () {
    const response = yield call(api.getWorkorderStatuses);
    if (response) {
        yield put(actions.loadedWorkorderStatuses(response?.data));
    }
}

function* loadScheduleTemplateStationsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingScheduleTemplateStationsList(query.schedule_template_id));
    const response = yield call(api.getScheduleTemplateStationsList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedScheduleTemplateStationsList(query.schedule_template_id, response));
    }
    yield put(actions.loadingScheduleTemplateStationsList(false));
}

function* createScheduleTemplateStationSaga({ payload }) {
    const { data, clickedId, selectedRoute } = payload;
    const response = yield call(api.createScheduleTemplateStation, data);
    if (response?.success) {
        yield put(actions.loadScheduleTemplateStationsList(1, 25, { schedule_template_id: clickedId }));
        yield put(actions.loadScheduleTemplateList(1, 25, { route_id: selectedRoute?.id }));
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* deleteScheduleTemplateStationSaga({ payload }) {
    const { id, clickedId, selectedRoute } = payload;
    const response = yield call(api.deleteScheduleTemplateStation, id);
    if (response?.success) {
        yield put(actions.loadScheduleTemplateStationsList(1, 25, { schedule_template_id: clickedId }));
        yield put(actions.loadScheduleTemplateList(1, 25, { route_id: selectedRoute?.id }));
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* editScheduleTemplateStationSaga({ payload }) {
    const { id, clickedId, selectedRoute, data } = payload;
    const response = yield call(api.editScheduleTemplateStation, id, data);
    if (response?.success) {
        yield put(actions.loadScheduleTemplateStationsList(1, 25, { schedule_template_id: clickedId }));
        yield put(actions.loadScheduleTemplateList(1, 25, { route_id: selectedRoute?.id }));
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteWorkorderSaga ({ payload, callback }) {
    const response = yield call(api.deleteWorkorder, payload);
    if (response) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback && callback();
    }
}

function* loadTrafficScheduleSaga({ payload, callback }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingTrafficSchedule(true));
    const response = yield call(api.getTrafficSchedule, page, limit, data);
    if (response.success) {
        if (callback) {
            callback(response);
        } else {
            yield put(actions.loadedTrafficSchedule(response));
        }

    }
    yield put(actions.loadingTrafficSchedule(false));
}

function* loadTrafficScheduleNoWorkorderSaga({ payload, callback }) {
    const { data } = payload;
    yield put(actions.loadingTrafficSchedule(true));
    const response = yield call(api.getTrafficScheduleNoWorkorder, data);
    if (response.success) {
        if (callback) {
            callback(response);
        } else {
            yield put(actions.loadedTrafficScheduleNoWorkorder(response));
        }
    }
    yield put(actions.loadingTrafficSchedule(false));
}

function* loadTrafficScheduleStatusesSaga() {
    const statuses = yield call(api.getTrafficScheduleStatuses);
    if (statuses) {
        yield put(actions.loadedTrafficScheduleStatuses(statuses.data));
    }
}

function* createTrafficScheduleSaga({ payload: data, callback }) {
    yield put(actions.loadingTrafficSchedule(true));
    const response = yield call(api.createTrafficSchedule, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
        yield callback?.();
    }
    yield put(saveStatus(response?.success || false));
    yield put(actions.loadingTrafficSchedule(false));
}

function* loadTrafficScheduleTypesSaga() {
    const types = yield call(api.getTrafficScheduleTypes);
    if (types) {
        yield put(actions.loadedTrafficScheduleTypes(types.data));
    }
}

function* loadCurrentTrafficScheduleEventsSaga ({ payload }) {
    yield put(actions.loadingCurrentTrafficScheduleEvents(true));
    const response = yield call(api.getCurrentTrafficSchedule, payload);
    if (response) {
        yield put(actions.loadedCurrentTrafficScheduleEvents(response.data ));
    }
    yield put(actions.loadingCurrentTrafficScheduleEvents(false));
}

function* loadRouteRequestsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingRouteRequestsList(true));
    const response = yield call(api.getRouteRequestsList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedRouteRequestsList(response));
    }
    yield put(actions.loadingRouteRequestsList(false));
}

function* loadStatusesRouteRequestsSaga() {
    const response = yield call(api.loadStatusesRouteRequests);
    if (response?.success) {
        yield put(actions.loadedStatusesRouteRequests(response?.data));
    }
}

function* createRouteRequestSaga({ payload, callback }) {
    const response = yield call(api.createRouteRequest, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield put(actions.loadRouteRequestsList());
    }
    callback && callback(response?.success || false);
}

function* editRouteRequestSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editRouteRequest, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield put(actions.loadRouteRequestsList());
    }
}

function* deleteRouteRequestSaga({ payload }) {
    const response = yield call(api.deleteRouteRequest, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield put(actions.loadRouteRequestsList());
    }
}

function* loadAppealSaga({ payload }) {
    const { page, limit, query } = payload;

    yield put(actions.loadingAppeal(true));

    const config = query?.report
        ? { params: { ...query }, responseType: 'blob' }
        : { params: { page, limit, ...query } };
    const response = yield call(api.getAppeal, config);
    if (response) {
        query?.report
            ? getReport(response, query?.report)
            : yield put(actions.loadedAppeal(response));
    }

    yield put(actions.loadingAppeal(false));
}

function* createAppealSaga({ payload, callback }) {
    const response = yield call(api.createAppeal, payload);
    if (response?.success) {
        callback && callback();
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
    }
}

function* editAppealSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editAppeal, id, data);
    if (response?.success) {
        yield put(actions.loadAppeal());
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteAppealSaga({ payload }) {
    const response = yield call(api.deleteAppeal, payload);
    if (response) {
        yield put(actions.loadAppeal());
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* getAppealStatusSaga({ payload }) {
    const response = yield call(api.getAppealStatus, payload);
    if (response) {
        yield put(actions.loadedAppealStatus(response?.data));
    }
}

function* getAppealTypeSaga({ payload }) {
    const response = yield call(api.getAppealType, payload);
    if (response) {
        yield put(actions.loadedAppealType(response?.data));
    }
}

function* getRouteCheckpointsSaga({ payload: id, callback }) {
    yield put(actions.loadingRouteCheckpoints(true));
    const response = yield call(api.getRouteCheckpoints, id);
    if (response) {
        yield put(actions.loadedRouteCheckpoints(id, response.data));
    }
    yield put(actions.loadingRouteCheckpoints(false));
}

function* getNearStationSaga({ payload }) {
    const { geojson } = payload;
    const response = yield call(api.getNearStation, { geojson });
    if (response) {
        yield put(actions.loadedNearStation(response.data));
    }
}

function* saveRouteCheckpointsSaga({ payload, callback }) {
    const { id, checkpoints } = payload;
    yield put(actions.loadingRouteCheckpoints(true));
    const response = yield call(api.saveRouteCheckpoints, id, checkpoints);
    if (response?.success) {
        callback && callback();
        yield put(actions.loadRouteCheckpoints(id));
        yield showMessage(messageTypes.success, 'Данные были успешно добавлены');
    }
    yield put(actions.loadingRouteCheckpoints(false));
}

function* loadRouteWithCheckPointsSaga({ payload, callback }) {
    yield put(actions.loadingRouteWithCheckPoints(true));
    const response = yield call(api.loadRouteWithCheckPoints, { params: { ...payload, with_trashed: 1 } });

    if (response) {
        yield put(actions.loadedRouteWithCheckPoints(response?.data));
        yield callback(response?.data);
    }
    yield put(actions.loadingRouteWithCheckPoints(false));
}

function* changeTrafficScheduleStatusSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.putTrafficScheduleStatus, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
        callback?.();
    }
}

//CAPTCHA
function* loadCaptchaSaga() {
    const response = yield call(api.getCaptcha);
    if (response) {
        yield put(actions.loadedCaptcha(response));
    }
}

function* fetchSchedulesSaga ({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.fetchingSchedule(true));
    const response = yield call(api.fetchSchedule, page, limit, data);
    if (response?.success) {
        yield put(actions.fetchedSchedule(response));
    }
    yield put(actions.fetchingSchedule(false));
}

function* postScheduleSaga({ payload, callback }) {
    const response = yield call(api.postSchedule, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY);
    }
}

function* fetchScheduleStatusesSaga() {
    const response = yield call(api.fetchScheduleStatuses);
    if (response?.success) {
        yield put(actions.fetchedScheduleStatuses(response?.data));
    }
}

function* putScheduleSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.putSchedule, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY);
    }
}

function* delScheduleSaga({ payload, callback }) {
    const response = yield call(api.delSchedule, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* fetchScheduleEventsSaga ({ payload }) {
    const { id, data } = payload;
    yield put(actions.fetchingScheduleEvents(true));
    const response = yield call(api.fetchScheduleEvents, id, data);
    if (response?.success) {
        yield put(actions.fetchedScheduleEvents({ [id]: response.data } ));
    }
    yield put(actions.fetchingScheduleEvents(false));
}

function* putScheduleStatusSaga({ payload, callback }) {
    const { id, statusId } = payload;
    const response = yield call(api.putScheduleStatus, id, statusId);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

// schedule template check-points
function* getScheduleTemplateCheckpointsSaga({ payload: id }) {
    yield put(actions.loadingScheduleTemplateCheckpoints(true));
    const response = yield call(api.getScheduleTemplateCheckpoints, id);
    if (response) {
        yield put (actions.loadedScheduleTemplateCheckpoints(id, response.data));
    }
    yield put(actions.loadingScheduleTemplateCheckpoints(false));
}

function* saveScheduleTemplateCheckpointsSaga({ payload, callback, reloadTemplates }) {
    const { template, checkpoints } = payload;
    const response = yield call(api.saveScheduleTemplateCheckpoints, template.id, checkpoints);
    if (response?.success) {
        callback();
        reloadTemplates();
        yield showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY);
    }
}

//ROUTE_TRANSPORTATION_TARIFF
function* saveTransportationTariffSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.saveTransportationTariff, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
        yield put(actions.loadRoutes());
    }
}

function* loadTicketPointsSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingTicketPoints(true));
    const response = yield call(api.getTicketPoints, page, limit, data);
    if (response?.success) {
        yield put(actions.loadedTicketPoints(response));
    }
    yield put(actions.loadingTicketPoints(false));
}

function* createTicketPointSaga({ payload, callback }) {
    const response = yield call(api.createTicketPoint, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editTicketPointSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editTicketPoint, id, data);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteTicketPointSaga({ payload, callback }) {
    const response = yield call(api.deleteTicketPoint, payload);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadTicketPointTypesSaga() {
    const response = yield call(api.getTicketPointTypes);
    if (response?.success) {
        yield put(actions.loadedTicketPointTypes(response?.data));
    }
}

//WAYBILLS
function* loadWaybillsListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingWaybillsList(true));
    const response = yield call(api.getWaybillsList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedWaybillsList(response));
    }
    yield put(actions.loadingWaybillsList(false));
}

function* createWaybillsSaga({ payload }) {
    const response = yield call(api.postWaybills, payload);
    if (response?.success) {
        yield put(actions.loadWaybillsList());
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editWaybillSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editWaybill, id, data);
    if (response?.success) {
        yield put(actions.loadWaybillsList());
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}


function* deleteWaybillSaga({ payload }) {
    const response = yield call(api.deleteWaybill, payload);
    if (response?.success) {
        yield put(actions.loadWaybillsList());
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* getWaybillSaga({ payload }) {
    const response = yield call(api.getWaybill, payload);
    if (response) {
        yield getReport(response, 'XLS');
    }
}

function* loadStationAttributesSaga() {
    const response = yield call(api.getStationAttributes);
    if (response?.success) {
        yield put(actions.loadedStationAttributes(response?.data));
    }
}

function* loadTariffsListSaga({ payload }) {
    const { page, limit, query, type } = payload;
    yield put(actions.loadingTariffsList(true));
    const response = yield call(api.getTariffs, page, limit, query, type);
    if (response?.success) {
        yield put(actions.loadedTariffsList(response, type?.type_transportation));
    }
    yield put(actions.loadingTariffsList(false));
}

function* createTariffSaga({ payload }) {
    const { data, type } = payload;
    const response = yield call(api.postTariff, data);
    if (response?.success) {
        yield put(actions.loadTariffsList(1, 25, {}, type));
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editTariffSaga({ payload }) {
    const { id, data, type } = payload;
    const response = yield call(api.putTariff, id, data);
    if (response?.success) {
        yield put(actions.loadTariffsList(1, 25, {}, type));
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* deleteTariffSaga({ payload }) {
    const { id, type } = payload;
    const response = yield call(api.deleteTariff, id);
    if (response?.success) {
        yield put(actions.loadTariffsList(1, 25, {}, type));
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
}

function* loadVehicleCardSaga({ payload, callback }) {
    const { id, data } = payload;
    const report = data?.report;
    const config = report
        ? { params: { ...data }, responseType: 'blob' }
        : { params: { ...data } };

    yield put(actions.loadingVehicleCard(true));
    const response = yield call(api.getVehicleCard, id, config);
    if (response) {
        report
            ? getReport(response, report)
            : callback
                ? callback(response?.data)
                : yield put(actions.loadedVehicleCard(response?.data));
    }
    yield put(actions.loadingVehicleCard(false));
}

function* loadWaybillsLicenceSaga() {
    const response = yield call(api.getWaybillsLicence);
    if (response?.success) {
        yield put(actions.loadedWaybillsLicence(response?.data));
    }
}

function* loadTrafficScheduleListOfEvents() {
    const response = yield call(api.getTrafficScheduleListOfEvents);
    if (response?.success) {
        yield put(actions.loadedTrafficScheduleListOfEvents(response?.data));
    }
}

function* editTrafficScheduleSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingTrafficSchedule(true));
    const response = yield call(api.editTrafficSchedule, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
        yield callback();
        yield put(setValidation({ success: response.success }));
        // yield put(actions.loadTrafficSchedule(1, 25));
    }
    yield put(actions.loadingTrafficSchedule(false));
}

function* loadCoordsSaga({ payload, callback }) {
    yield put(actions.loadingCoords(true));
    yield put(setMapLoading('tp_coords', true));
    const response = yield call(api.getCoords, payload);
    if (response?.success) {
        yield put(actions.loadedCoords(response?.data));
        yield callback?.(response.data);
    }
    yield put(actions.loadingCoords(false));
    yield put(setMapLoading('tp_coords', false));
}

function* loadVehicleSaga({ payload }) {
    yield put(actions.loadingVehicle(payload, true));
    const response = yield call(api.getVehicle, payload);
    if (response?.success) {
        yield put(actions.loadedVehicle(payload, response?.data));
    }
    yield put(actions.loadingVehicle(payload, false));
}

function* loadReportSummaryScheduleSaga({ payload }) {
    yield put(actions.loadingSummarySchedule(true));
    const { page, limit, data } = payload;
    const report = data?.report;
    const downloadFormat = report || false;
    const config = downloadFormat
        ? { params: { ...data }, responseType: 'blob' }
        : { params: { page, limit, ...data } };
    const response = yield call(api.getReportSummarySchedule, data.station_id, config);
    if (response) {
        data.report
            ? getReport(response, data.report)
            : yield put(actions.loadedSummarySchedule(response));
    }
    yield put(actions.loadingSummarySchedule(false));
}

function* loadOrganizationInformationSaga({ payload }) {
    const params = removeEmptyFields(payload);

    const report = params?.report || false;
    const config = { params: { ...params } };

    yield put(actions.loadingOrganizationInformation(true));
    const response = yield call(api.loadOrganizationInformation, config);
    if (response?.success) {
        if (report) {
            yield messageHelper(response);
        } else {
            yield put(actions.loadedOrganizationInformation(response?.data || {}));
        }
    }
    yield put(actions.loadingOrganizationInformation(false));
}

function* loadVehicleTelemetryStatisticSaga() {
    yield put(actions.loadingTransportSettings(true));
    const response = yield call(api.getVehicleTelemetryStatistic);
    if (response?.success) {
        yield put(actions.loadedVehicleTelemetryStatistic(response?.data));
    }
    yield put(actions.loadingTransportSettings(false));
}

function* loadTelemetryStatisticSaga() {
    yield put(actions.loadingTransportSettings(true));
    const response = yield call(api.getTelemetryStatistic);
    if (response?.success) {
        yield put(actions.loadedTelemetryStatistic(response?.data));
    }
    yield put(actions.loadingTransportSettings(false));
}

function* loadWorkorderReportSaga({ payload }) {
    const { page, limit, data } = payload;

    yield put(actions.loadingWorkorderReport(true));

    const report = data?.report;
    const downloadFormat = report || false;
    const config = downloadFormat
        ? { params: { ...data }, responseType: 'blob' }
        : { params: { page, limit, ...data } };
    const response = yield call(api.getWorkordersReport, config);
    if (response) {
        data.report
            ? getReport(response, data.report)
            : yield put(actions.loadedWorkorderReport(response));
    }
    yield put(actions.loadingWorkorderReport(false));
}

function* loadWoRequisiteChartererTypesSaga({ payload }) {
    const response = yield call(api.getWoRequisiteChartererTypes, payload);
    if (response?.success) {
        yield put(actions.loadedWoRequisiteChartererTypes(response?.data));
    }
}

function* loadWoRequisiteListSaga({ payload, callback }) {
    yield put(actions.loadingWoRequisite(true));
    const response = yield call(api.getWoRequisiteList, payload);
    if (response?.success) {
        yield put(actions.loadedWoRequisiteList(response));
        callback && callback();
    }
    yield put(actions.loadingWoRequisite(false));
}

function* createWoRequisiteSaga({ payload, callback }) {
    yield put(actions.loadingWoRequisite(true));
    const response = yield call(api.createWoRequisite, payload);
    if (response?.success) {
        yield put(actions.loadedWoRequisiteList(response?.data));
        callback && callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingWoRequisite(false));
}

function* editWoRequisiteSaga({ payload, callback }) {
    const {
        id,
        data
    } = payload;
    yield put(actions.loadingWoRequisite(true));
    const response = yield call(api.editWoRequisite, id, data);
    if (response?.success) {
        yield put(actions.loadedWoRequisiteList(response?.data));
        callback && callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingWoRequisite(false));
}

function* deleteWoRequisiteSaga({ payload, callback }) {
    yield put(actions.loadingWoRequisite(true));
    const response = yield call(api.deleteWoRequisite, payload);
    if (response?.success) {
        yield put(actions.loadedWoRequisiteList(response?.data));
        callback && callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingWoRequisite(false));
}

function* loadRadioTypeSaga({ payload }) {
    yield put(actions.loadingRadioTypeList(true));
    const response = yield call(api.getRadioType, payload);
    if (response?.success) {
        yield put(actions.loadedRadioTypeList(response));
    }
    yield put(actions.loadingRadioTypeList(false));
}

function* loadWorkorderEventManualStatusSaga() {
    const response = yield call(api.getWorkorderEventManualStatus);
    if (response?.success) {
        yield put(actions.loadedWorkorderEventManualStatus(response.data));
    }
}

function* loadWorkorderEventFlightManualStatusSaga() {
    const response = yield call(api.getWorkorderEventFlightManualStatus);
    if (response?.success) {
        yield put(actions.loadedWorkorderEventFlightManualStatus(response.data));
    }
}

function* loadFlightDefectGroupSaga() {
    const response = yield call(api.getFlightDefectGroup);
    if (response?.success) {
        yield put(actions.loadedFlightDefectGroup(response.data));
    }
}

function* loadFlightDefectsSaga({ payload }) {
    const response = yield call(api.getFlightDefects, payload);
    if (response?.success) {
        const group = payload?.defect_group_id;
        yield put(actions.loadedFlightDefects({ group, data: response.data }));
    }
}

function* createWorkorderEventStatusSaga({ payload }) {
    // id события из наряда
    const { id, data } = payload;
    const response = yield call(api.createWorkorderEventStatus, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}
function* editWorkorderEventStatusSaga({ payload }) {
    // id события из наряда
    const { id, data } = payload;
    const response = yield call(api.updateWorkorderEventStatus, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}
function* deleteWorkorderEventStatusSaga({ payload }) {
    // id события из наряда
    const { id } = payload;
    const response = yield call(api.deleteWorkorderEventStatus, id);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* loadReportByFlightsSaga({ payload }) {
    const report = payload?.report || false;
    const config = report
        ? { params: { ...payload }, responseType: 'blob' }
        : { params: { ...payload } };
    yield put(actions.loadingReportByFlights(true));
    const response = yield call(api.getReportByFlights, config);
    if (response) {
        report
            ? getReport(response, report)
            : yield put(actions.loadedReportByFlights(response));
    }
    yield put(actions.loadingReportByFlights(false));
}

function* loadReportByVehiclesSaga({ payload }) {
    yield put(actions.loadingReportByVehicles(true));
    const response = yield call(api.getReportByVehicles, payload);
    if (response?.success) {
        yield put(actions.loadedReportByVehicles(response));
    }
    yield put(actions.loadingReportByVehicles(false));
}

function* loadEmployeesTransportationSaga({ payload }) {
    const report = payload?.report || false;
    const config = report
        ? { params: { ...payload }, responseType: 'blob' }
        : { params: { ...payload } };
    yield put(actions.loadingEmploadEmployeesTransportationReport(true));
    const response = yield call(api.getEmployeesTransportationReport, config);
    if (response) {
        report
            ? getReport(response, report)
            : yield put(actions.loadedEmploadEmployeesTransportationReport(response));
    }
    yield put(actions.loadingEmploadEmployeesTransportationReport(false));
}

function* loadFlightTypesSaga() {
    const response = yield call(api.getFlightTypes);
    if (response) {
        yield put(actions.loadedFlightTypes(response));
    }
}

function* editFlightTypeSaga({ payload }) {
    const { data, params } = payload;
    const response = yield call(api.editFlightType, data.entity_id, params);
    if (response?.success) {
        yield put(actions.loadCurrentTrafficScheduleEvents(data.traffic_schedule_id));
        yield put(setValidation({ success: response.success }));
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* copyScheduleTemplateSaga ({ payload, callback }) {
    const response = yield call(api.copyScheduleTemplate, payload);

    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.COPY_SUCCESS);
    }
}

function* loadSpeedViolationsReportSaga({ payload }) {
    const report = payload?.report || false;
    const config = report
        ? { params: { ...payload }, responseType: 'blob' }
        : { params: { ...payload } };
    yield put(actions.loadingSpeedViolationsReport(true));
    const response = yield call(api.getSpeedViolationsReport, config);
    if (response) {
        report
            ? getReport(response, report)
            : yield put(actions.loadedSpeedViolationsReport(response));
    }
    yield put(actions.loadingSpeedViolationsReport(false));
}

function* loadTransportSettingsSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingTransportSettings(true));
    const response = yield call(api.getTransportSettings, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedTransportSettings(response));
    }
    yield put(actions.loadingTransportSettings(false));
}

function* editTransportSettingSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editTransportSetting, id, data);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        callback?.();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
}

function* editSettingsListSaga({ payload, callback }) {
    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
}

function* loadPassengersCountSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingPassengersCount(true));
    const response = yield call(api.getPassengersCount, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedPassengersCount(response));
    }
    yield put(actions.loadingPassengersCount(false));
}

function* loadSidebarSaga({ payload }) {
    const page = payload?.page || 1;
    yield put(actions.loadingSidebar(true));
    const response = yield call(api.getSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedSidebar(response, page === 1));
    }
    yield put(actions.loadingSidebar(false));
}

function* getTemplateSaga({ payload }) {
    const { format } = payload;

    const config = { params: { format }, responseType: 'blob' };

    yield put(actions.loadingTemplate(true));

    const response = yield call(api.getTemplate, config);

    if (response) {
        getReport(response, format);
    }

    yield put(actions.loadingTemplate(false));
}

function* loadTransorganizationsSaga({ payload }) {
    const { page, limit, params } = payload;

    yield put(actions.loadingTransorganizations(true));

    const response = yield call(api.getTransorganizations, page, limit, params);

    if (response?.success) {
        yield put(actions.loadedTransorganizations(response));
    }

    yield put(actions.loadingTransorganizations(false));
}

function* editTransorganizationSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingTransorganizations(true));
    const response = yield call(api.editTransorganization, id, data);
    if (response?.success) {
        callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingTransorganizations(false));
}

function* loadColorSpeedSaga() {
    const response = yield call(api.getColorSpeedList, {});
    if (response?.success) {
        yield put(actions.loadedColorSpeed(response?.data || []));
    }
}

function* loadColorSpeedListSaga({ payload }) {
    yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.getColorSpeedList, payload);
    if (response?.success) {
        yield put(actions.loadedColorSpeedList(response));
    }
    yield put(actions.loadingColorSpeedList(false));
}

function* addColorSpeedSaga({ payload, callback }) {
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.addColorSpeed, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}
function* editColorSpeedSaga({ payload, callback }) {
    const { id, data } = payload;
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.editColorSpeed, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}
function* deleteColorSpeedSaga({ payload, callback }) {
    // yield put(actions.loadingColorSpeedList(true));
    const response = yield call(api.deleteColorSpeed, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    callback && callback(response?.success);
    // yield put(actions.loadingColorSpeedList(false));
}

function* loadRoutesGroupedSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingRoutesGrouped(true));
    const response = yield call(api.getRouteGroup, page, limit, data);
    if (response?.success) {
        yield put(actions.loadedRoutesGrouped(response));
    }
    yield put(actions.loadingRoutesGrouped(false));
}

function* loadMilestoneEventsSaga() {
    const response = yield call(api.getMilestoneEvents);
    if (response?.success) {
        yield put(actions.loadedMilestoneEvents(response.data));
    }
}

function* loadStationPolygonSaga({ payload }) {
    yield put(actions.loadingStationPolygon(true));
    yield put(setMapLoading('passenger', true));
    const response = yield call(api.loadStationPolygon, payload);
    if (response?.success) {
        yield put(actions.loadedStationPolygon(response.data));
    }
    yield put(actions.loadingStationPolygon(false));
    yield put(setMapLoading('passenger', false));
}

// function* loadStationScheduleSaga({ payload }) {
//     const { id, ...params } = payload;
//     yield put(actions.loadingStationShedule(true));
//     const response = yield call(api.loadStationSchedule, id, params);
//     if (response?.success) {
//         yield put(actions.loadedStationShedule(response.data));
//     }
//     yield put(actions.loadingStationShedule(false));
// }

function* loadStationScheduleByDateSaga({ payload }) {
    const { id, ...params } = payload;
    yield put(actions.loadingStationScheduleByDate(true));
    const response = yield call(api.loadStationScheduleByDate, id, params);
    if (response?.success) {
        yield put(actions.loadedStationScheduleByDate(response.data));
    }
    yield put(actions.loadingStationScheduleByDate(false));
}

function* loadTelemetryReceiversSaga({ payload }) {
    yield put(actions.loadingTelemetryReceivers(true));
    const response = yield call(api.getTelemetryReceivers, payload);
    if (response?.success) {
        yield put(actions.loadedTelemetryReceivers(response));
    }
    yield put(actions.loadingTelemetryReceivers(false));
}

function* addTelemetryReceiverSaga({ payload, callback }) {
    const response = yield call(api.addTelemetryReceiver, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* editTelemetryReceiverSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editTelemetryReceiver, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        callback && callback();
    }
}

function* deleteTelemetryReceiverSaga({ payload, callback }) {
    const response = yield call(api.deleteTelemetryReceiver, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        callback && callback();
    }
}

function* loadTelemetryReceiverTypesSaga({ payload }) {
    const response = yield call(api.getTelemetryReceiverTypes, payload);
    if (response?.success) {
        yield put(actions.loadedTelemetryReceiverTypes(response.data));
    }
}

function* copyTrafficScheduleSaga ({ payload, callback }) {
    const response = yield call(api.copyTrafficSchedule, payload);

    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.COPY_SUCCESS);
    }
}

function* changeRouteStatusSaga ({ payload, callback }) {
    const {
        routeId,
        statusId,
    } = payload;
    yield put(actions.loadingSetRouteStatus(true));

    const response = yield call(api.changeRouteStatus, routeId, statusId);

    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }

    yield put(actions.loadingSetRouteStatus(false));
}

function* loadWorkCalendarSaga({ payload }) {
    yield put(actions.loadingWorkCalendar(true));
    const response = yield call(api.getWorkCalendar, payload);
    if (response?.success) {
        yield put(actions.loadedWorkCalendar(response.data));
    }
    yield put(actions.loadingWorkCalendar(false));
}

function* editWorkCalendarSaga({ payload, callback }) {
    const response = yield call(api.editWorkCalendar, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield callback?.();
    }
}

function* loadWorkCalendarDayTypesSaga({ payload }) {
    yield put(actions.loadingWorkCalendarDayTypes(true));
    const response = yield call(api.getWorkCalendarDayTypes, payload);
    if (response?.success) {
        yield put(actions.loadedWorkCalendarDayTypes(response.data));
    }
    yield put(actions.loadingWorkCalendarDayTypes(false));
}

function* loadWorkCalendarHistorySaga({ payload }) {
    yield put(actions.loadingWorkCalendarHistory(true));
    const response = yield call(api.getWorkCalendarHistory, payload);
    if (response?.success) {
        yield put(actions.loadedWorkCalendarHistory(response.data));
    }
    yield put(actions.loadingWorkCalendarHistory(false));
}

function* deleteRouteSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteRoute, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadVehicleClassesSaga({ payload }) {
    yield put(actions.loadingVehicleClasses(true));
    const response = yield call(api.getVehicleClasses, payload);
    if (response?.success) {
        yield put(actions.loadedVehicleClasses(response.data));
    }
    yield put(actions.loadingVehicleClasses(false));
}

function* createVehicleClassSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createVehicleClass, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* editVehicleClassSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editVehicleClass, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* deleteVehicleClassSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteVehicleClass, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadRegularTransportationsSaga() {
    const response = yield call(api.getRegularTransportations);
    if (response?.success) {
        yield put(actions.loadedRegularTransportations(response.data));
    }
}

function* loadSeasonalitySaga() {
    const response = yield call(api.getSeasonality);
    if (response?.success) {
        yield put(actions.loadedSeasonality(response.data));
    }
}

function* loadRouteSortDirectionsSaga() {
    const response = yield call(api.getRouteSortDirections);
    if (response?.success) {
        yield put(actions.loadedRouteSortDirections(response.data));
    }
}

function* editRouteAdditionalParamsSaga({ payload }) {
    const { id, data } = payload;
    yield put(actions.loadingRouteAdditionalParams(true));
    const response = yield call(api.editRouteAdditionalParams, id, data);
    if (response?.success) {
        yield put(actions.updateRoute(response?.data || {}));
        // yield put(actions.loadRoutes());
        // yield take(types.LOADED_ROUTES);
        yield showMessage(messageTypes.success, common.EDIT_SUCCESS);
    } else {
        yield showMessage(messageTypes.success, common.SOMETHING_WENT_WRONG);
    }
    yield put(actions.loadingRouteAdditionalParams(false));
}
function* loadRouteHistoryHumanSaga({ payload }) {
    const { id, params } = payload;
    yield put(actions.loadingRouteHistoryHuman(true));
    const response = yield call(api.getRouteHistoryHuman, id, params);
    if (response?.success) {
        yield put(actions.loadedRouteHistoryHuman(response));
    }
    yield put(actions.loadingRouteHistoryHuman(false));
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

// function* loadRouteByNumSaga({ payload, callback }) {
//     const response = yield call(api.getSidebar, payload);
//     yield callback(response?.data || []);
// }

function* loadRoutesByNCCallbackSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getSRoutesByNCCallback, payload);
    if (response?.success) {
        yield fnCallback?.(response?.data || []);
    }
    yield fnLoading?.(false);
}

function* loadEgtsTransmittersSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.loadEgtsTransmitters, payload);
    if (response?.success) {
        yield fnCallback?.(response);
    }
    yield fnLoading?.(false);
}

function* addEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.addEgtsTransmitter, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

function* editEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const { id, data } = payload;
    const response = yield call(api.editEgtsTransmitter, id, data);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.SAVE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

function* deleteEgtsTransmitterSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.deleteEgtsTransmitter, payload);
    if (response?.success) {
        yield showMessage(messageTypes.success, common.DELETE_SUCCESS);
        yield fnCallback?.();
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_STATIONS, loadStationsListSaga),
        takeLatest(types.LOAD_SCHEDULE, loadScheduleSaga),
        takeLatest(types.LOAD_ROUTE_TYPES, loadRouteTypesSaga),
        takeLatest(types.LOAD_BRANDS, loadBrandsListSaga),
        takeLatest(types.CREATE_BRAND, createBrandSaga),
        takeLatest(types.EDIT_BRAND, editBrandSaga),
        takeLatest(types.DELETE_BRAND, deleteBrandSaga),
        takeLatest(types.LOAD_VEHICLES_LIST, loadVehiclesSaga),
        takeLatest(types.LOAD_VEHICLES_LIST_NO_WORKORDER, loadVehiclesNoWorkorderSaga),
        takeLatest(types.LOAD_VEHICLES_CATEGORIES, loadVehicleCategoriesSaga),
        takeLatest(types.LOAD_VEHICLE_CATEGORY_LIST, loadVehicleCategoryListSaga),
        takeLatest(types.CREATE_VEHICLE_CATEGORY, createVehicleCategorySaga),
        takeLatest(types.EDIT_VEHICLE_CATEGORY, editVehicleCategorySaga),
        takeLatest(types.DELETE_VEHICLE_CATEGORY, deleteVehicleCategorySaga),
        takeLatest(types.LOAD_VEHICLES_TYPES, loadVehiclesTypesSaga),
        takeLatest(types.DELETE_VEHICLE, deleteVehicleSaga),
        takeLatest(types.CREATE_VEHICLE, createNewVehicleSaga),
        takeLatest(types.EDIT_VEHICLE, editVehicleSaga),
        takeLatest(types.LOAD_MODELS, loadModelsListSaga),
        takeLatest(types.CREATE_MODEL, createModelSaga),
        takeLatest(types.EDIT_MODEL, editModelSaga),
        takeLatest(types.DELETE_MODEL, deleteModelSaga),
        takeLatest(types.LOAD_COMPANIES, loadCompaniesListSaga),
        takeLatest(types.CREATE_COMPANY, createCompanySaga),
        takeLatest(types.EDIT_COMPANY, editCompanySaga),
        takeLatest(types.DELETE_COMPANY, deleteCompanySaga),
        takeLatest(types.GET_COMPANIES, getCompaniesListSaga),
        takeLatest(types.LOAD_ROUTE_NUMS, loadRouteNumsSaga),
        takeLatest(types.LOAD_ROUTES_GROUP, loadRoutesGroupSaga),
        takeLatest(types.LOAD_ROUTES, loadRoutesSaga),
        takeLatest(types.LOAD_ROUTES_STATUSES, loadRoutesStatusesSaga),
        takeLatest(types.LOAD_ROUTES_TRANSPORTATIONS, loadRoutesTransportationsSaga),
        takeLatest(types.SAVE_ROUTES, saveRoutesSaga),
        takeLatest(types.SAVE_ROUTE, createRouteSaga),
        takeLatest(types.CHANGE_ROUTE, changeRouteSaga),

        takeLatest(types.SAVE_ROUTE_V2, createRouteV2Saga),
        takeLatest(types.CHANGE_ROUTE_V2, changeRouteV2Saga),

        takeLatest(types.LOAD_ROUTE_SCHEDULE, loadRouteScheduleSaga),
        takeLatest(types.LOAD_SCHEDULE_TYPES, loadScheduleTypesSaga),
        takeLatest(types.CREATE_NEW_STATIONS, createNewStationSaga),
        takeLatest(types.EDIT_STATION, editStationSaga),
        takeLatest(types.ADD_SCHEDULE, addScheduleSaga),
        takeLatest(types.DELETE_SCHEDULE, deleteScheduleSaga),
        takeLatest(types.EDIT_SCHEDULE, editScheduleSaga),
        takeLatest(types.GET_FILTERED_LIST, getFilteredListSaga),
        takeLatest(types.GET_VEHICLE_REPORT, getVehicleReport),
        // takeLatest(types.GET_CURRENT_PASSENGER_REPORT, getCurrentPassengerReportSaga),
        takeLatest(types.GET_CURRENT_SPECIAL_REPORT, getCurrentSpecialReportSaga),
        takeLatest(types.LOAD_NEXT_STATION, loadNextStationSaga),
        takeLatest(types.LOAD_VEHICLE_POPUP, loadVehiclePopupSaga),
        takeLatest(types.LOAD_VEHICLE_HISTORY, loadVehicleHistorySaga),
        takeLatest(types.LOAD_VEHICLE_FLIGHT_HISTORY, loadVehicleFlightHistorySaga),
        takeLatest(types.LOAD_CURRENT_VEHICLES, loadCurrentVehiclesSaga),
        takeLatest(types.EDIT_VEHICLE_TO_ROUTE, editVehicleToRouteSaga),
        takeLatest(types.LOAD_CURRENT_VEHICLE_TYPE, loadCurrentVehicleTypeSaga),
        takeLatest(types.SAVE_SELECTED_VEHICLE_TO_ROUTE, saveSelectedVehicleToRouteSaga),
        takeLatest(types.LOAD_ROUTES_, loadRoutes_Saga),
        takeLatest(types.LOAD_POSITIONS, loadPositionsSaga),
        takeLatest(types.EDIT_POSITIONS, editPositionsSaga),
        takeLatest(types.ADD_POSITIONS, addPositionsSaga),
        takeLatest(types.DELETE_POSITIONS, deletePositionsSaga),
        takeLatest(types.LOAD_SCHEDULE_EVENTS, loadScheduleEventSaga),
        takeLatest(types.EDIT_SCHEDULE_EVENTS, editScheduleEventSaga),
        takeLatest(types.ADD_SCHEDULE_EVENTS, addScheduleEventSaga),
        takeLatest(types.DELETE_SCHEDULE_EVENTS, deleteScheduleEventSaga),
        takeLatest(types.LOAD_EMPLOYEES, loadEmployeesSaga),
        takeLatest(types.LOAD_EMPLOYEE, loadEmployeeSaga),
        takeLatest(types.EDIT_EMPLOYEES, editEmployeesSaga),
        takeLatest(types.ADD_EMPLOYEES, addEmployeesSaga),
        takeLatest(types.DELETE_EMPLOYEES, deleteEmployeesSaga),
        takeLatest(types.LOAD_EQUIPMENTS_LIST, loadEquipmentsSaga),
        takeLatest(types.LOAD_POSITION, loadPositionSaga),
        takeLatest(types.LOAD_BRIGADES, loadBrigadesSaga),
        takeLatest(types.LOAD_BRIGADES_NO_WORKORDER, loadBrigadesNoWorkorderSaga),
        takeLatest(types.EDIT_BRIGADES, editBrigadesSaga),
        takeLatest(types.ADD_BRIGADES, addBrigadesSaga),
        takeLatest(types.DELETE_BRIGADES, deleteBrigadesSaga),
        takeLatest(types.LOAD_PARKS_LIST, loadParksListSaga),
        takeLatest(types.CREATE_PARK, createParkSaga),
        takeLatest(types.EDIT_PARK, editParkSaga),
        takeLatest(types.DELETE_PARK, deleteParkSaga),
        takeLatest(types.LOAD_STATION_TYPES, loadStationTypesSaga),
        takeLatest(types.DELETE_STATION, deleteStationSaga),
        takeLatest(types.LOAD_ROUTE_STATION, loadRouteStationSaga),
        takeLatest(types.LOAD_SCHEDULE_TEMPLATE_LIST, loadScheduleTemplateListSaga),
        takeLatest(types.LOAD_SCHEDULE_TEMPLATE_STATUSES, loadScheduleTemplateStatusesSaga),
        takeLatest(types.CREATE_SCHEDULE_TEMPLATE, createScheduleTemplateSaga),
        takeLatest(types.EDIT_SCHEDULE_TEMPLATE, editScheduleTemplateSaga),
        takeLatest(types.DELETE_SCHEDULE_TEMPLATE, deleteScheduleTemplateSaga),
        takeLatest(types.SAVE_ROUTE_STATIONS, saveRouteStationsSaga),
        takeLatest(types.LOAD_INTERSECTION_ROUTES, loadIntersectionRoutesSaga),
        takeLatest(types.LOAD_WORKORDERS, loadWorkordersSaga),
        takeLatest(types.LOAD_CURRENT_WORKORDER, loadCurrentWorkorderSaga),
        takeLatest(types.LOAD_WORKORDERS_BY_DATE, loadWorkordersByDateSaga),
        takeLatest(types.CREATE_WORKORDER, addWorkorderSaga),
        takeLatest(types.DELETE_WORKORDER, deleteWorkorderSaga),
        takeLatest(types.EDIT_WORKORDER, editWorkorderSaga),
        takeLatest(types.EDIT_WORKORDER_STATUS, editWorkorderStatusSaga),
        takeLatest(types.LOAD_WORKORDER_STATUSES, loadWorkorderStatusesSaga),
        takeLatest(types.LOAD_TRAFFIC_SCHEDULE, loadTrafficScheduleSaga),
        takeLatest(types.LOAD_TRAFFIC_SCHEDULE_NO_WORKORDER, loadTrafficScheduleNoWorkorderSaga),
        takeLatest(types.LOAD_SCHEDULE_TEMPLATE_STATIONS_LIST, loadScheduleTemplateStationsListSaga),
        takeLatest(types.DELETE_SCHEDULE_TEMPLATE_STATION, deleteScheduleTemplateStationSaga),
        takeLatest(types.EDIT_SCHEDULE_TEMPLATE_STATION, editScheduleTemplateStationSaga),
        takeLatest(types.CREATE_SCHEDULE_TEMPLATE_STATION, createScheduleTemplateStationSaga),
        takeLatest(types.LOAD_TRAFFIC_SCHEDULE_STATUSES, loadTrafficScheduleStatusesSaga),
        takeLatest(types.CREATE_TRAFFIC_SCHEDULE, createTrafficScheduleSaga),
        takeLatest(types.LOAD_TRAFFIC_SCHEDULE_TYPES, loadTrafficScheduleTypesSaga),
        takeEvery(types.LOAD_CURRENT_TRAFFIC_SCHEDULE, loadCurrentTrafficScheduleEventsSaga),
        takeLatest(types.LOAD_APPEAL, loadAppealSaga),
        takeLatest(types.CREATE_APPEAL, createAppealSaga),
        takeLatest(types.EDIT_APPEAL, editAppealSaga),
        takeLatest(types.DELETE_APPEAL, deleteAppealSaga),
        takeLatest(types.LOAD_APPEAL_STATUS, getAppealStatusSaga),
        takeLatest(types.LOAD_APPEAL_TYPE, getAppealTypeSaga),
        takeLatest(types.LOAD_ROUTE_CHECKPOINTS, getRouteCheckpointsSaga),
        takeLatest(types.LOAD_NEAR_STATION, getNearStationSaga),
        takeLatest(types.SAVE_CHECKPOINTS_TO_ROUTE, saveRouteCheckpointsSaga),
        takeEvery(types.LOAD_ROUTE_WITH_CHECK_POINTS, loadRouteWithCheckPointsSaga),
        takeLatest(types.LOAD_WORKORDER_EVENTS, loadWorkorderEventsSaga),
        takeLatest(types.LOAD_WORKORDER_EVENT_STATUSES, loadWorkorderEventStatusesSaga),
        takeLatest(types.LOAD_ROUTE_REQUEST_LIST, loadRouteRequestsListSaga),
        takeLatest(types.LOAD_STATUSES_ROUTE_REQUESTS, loadStatusesRouteRequestsSaga),
        takeLatest(types.LOAD_CAPTCHA, loadCaptchaSaga),
        takeLatest(types.CREATE_ROUTE_REQUEST, createRouteRequestSaga),
        takeLatest(types.EDIT_ROUTE_REQUEST, editRouteRequestSaga),
        takeLatest(types.DELETE_ROUTE_REQUEST, deleteRouteRequestSaga),
        takeLatest(types.CHANGE_TRAFFIC_SCHEDULE_STATUS, changeTrafficScheduleStatusSaga),
        takeLatest(types.FETCH_SCHEDULES, fetchSchedulesSaga),
        takeLatest(types.POST_SCHEDULE, postScheduleSaga),
        takeLatest(types.FETCH_SCHEDULE_STATUSES, fetchScheduleStatusesSaga),
        takeLatest(types.PUT_SCHEDULE, putScheduleSaga),
        takeLatest(types.DEL_SCHEDULE, delScheduleSaga),
        takeEvery(types.FETCH_SCHEDULE_EVENTS, fetchScheduleEventsSaga),
        takeLatest(types.PUT_SCHEDULE_STATUS, putScheduleStatusSaga),
        takeLatest(types.LOAD_SCHEDULETEMPLATE_CHECKPOINTS, getScheduleTemplateCheckpointsSaga),
        takeLatest(types.SAVE_CHECKPOINTS_TO_SCHEDULETEMPLATE, saveScheduleTemplateCheckpointsSaga),
        takeLatest(types.SAVE_TRANSPORTATION_TARIFF, saveTransportationTariffSaga),
        takeLatest(types.LOAD_TICKET_POINTS, loadTicketPointsSaga),
        takeLatest(types.CREATE_TICKET_POINT, createTicketPointSaga),
        takeLatest(types.EDIT_TICKET_POINT, editTicketPointSaga),
        takeLatest(types.DELETE_TICKET_POINT, deleteTicketPointSaga),
        takeLatest(types.LOAD_TICKET_POINT_TYPES, loadTicketPointTypesSaga),
        takeLatest(types.LOAD_WAYBILLS_LIST, loadWaybillsListSaga),
        takeLatest(types.CREATE_WAYBILLS, createWaybillsSaga),
        takeLatest(types.LOAD_STATION_ATTRIBUTES, loadStationAttributesSaga),
        takeLatest(types.LOAD_TARIFFS_LIST, loadTariffsListSaga),
        takeLatest(types.CREATE_TARIFF, createTariffSaga),
        takeLatest(types.EDIT_TARIFF, editTariffSaga),
        takeLatest(types.DELETE_TARIFF, deleteTariffSaga),
        takeLatest(types.LOAD_VEHICLE_CARD, loadVehicleCardSaga),
        takeLatest(types.LOAD_WAYBILLS_LICENCE, loadWaybillsLicenceSaga),
        takeLatest(types.EDIT_WAYBILLS, editWaybillSaga),
        takeLatest(types.GET_WAYBILLS, getWaybillSaga),
        takeLatest(types.DELETE_WAYBILLS, deleteWaybillSaga),
        takeLatest(types.LOAD_TRAFFIC_SCHEDULE_LIST_OF_EVENTS, loadTrafficScheduleListOfEvents),
        takeLatest(types.EDIT_TRAFFIC_SCHEDULE, editTrafficScheduleSaga),
        takeLatest(types.LOAD_COORDS, loadCoordsSaga),
        takeLatest(types.LOAD_VEHICLE, loadVehicleSaga),
        takeLatest(types.GET_REPORT_SUMMARY_SCHEDULE, loadReportSummaryScheduleSaga),
        takeLatest(types.LOAD_ORGANIZATION_INFORMATION, loadOrganizationInformationSaga),
        takeLatest(types.LOAD_VEHICLE_TELEMETRY_STATISTIC, loadVehicleTelemetryStatisticSaga),
        takeLatest(types.LOAD_TELEMETRY_STATISTIC, loadTelemetryStatisticSaga),
        takeLatest(types.LOAD_WORKORDERS_REPORT, loadWorkorderReportSaga),
        takeLatest(types.LOAD_WO_REQUISITE_CHARTERER_TYPES, loadWoRequisiteChartererTypesSaga),
        takeLatest(types.LOAD_WO_REQUISITE_LIST, loadWoRequisiteListSaga),
        takeLatest(types.CREATE_WO_REQUISITE, createWoRequisiteSaga),
        takeLatest(types.EDIT_WO_REQUISITE, editWoRequisiteSaga),
        takeLatest(types.DELETE_WO_REQUISITE, deleteWoRequisiteSaga),
        takeLatest(types.LOAD_RADIO_TYPE, loadRadioTypeSaga),
        takeLatest(types.LOAD_WORKORDER_EVENT_MANUAL_STATUSES, loadWorkorderEventManualStatusSaga),
        takeLatest(types.LOAD_WORKORDER_EVENT_FLIGHT_MANUAL_STATUSES, loadWorkorderEventFlightManualStatusSaga),
        takeLatest(types.LOAD_FLIGHT_DEFECT_GROUP, loadFlightDefectGroupSaga),
        takeLatest(types.LOAD_FLIGHT_DEFECTS, loadFlightDefectsSaga),
        takeLatest(types.CREATE_WORKORDER_EVENT_STATUS, createWorkorderEventStatusSaga),
        takeLatest(types.EDIT_WORKORDER_EVENT_STATUS, editWorkorderEventStatusSaga),
        takeLatest(types.DELETE_WORKORDER_EVENT_STATUS, deleteWorkorderEventStatusSaga),
        takeLatest(types.LOAD_REPORT_BY_FLIGHTS, loadReportByFlightsSaga),
        takeLatest(types.LOAD_REPORT_BY_VEHICLES , loadReportByVehiclesSaga),
        takeLatest(types.LOAD_EMPLOYEES_TRANSPORTATION_REPORT, loadEmployeesTransportationSaga),
        takeLatest(types.LOAD_FLIGHT_TYPES, loadFlightTypesSaga),
        takeLatest(types.EDIT_FLIGHT_TYPE, editFlightTypeSaga),
        takeLatest(types.COPY_SCHEDULE_TEMPLATE, copyScheduleTemplateSaga),
        takeLatest(types.LOAD_SPEED_VIOLATIONS_REPORT , loadSpeedViolationsReportSaga),
        takeLatest(types.LOAD_TRANSPORT_SETTINGS, loadTransportSettingsSaga),
        takeLatest(types.EDIT_TRANSPORT_SETTING, editTransportSettingSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.LOAD_PASSENGERS_COUNT, loadPassengersCountSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSidebarSaga),
        takeLatest(types.GET_TEMPLATE, getTemplateSaga),

        takeLatest(types.LOAD_TRANSORGANIZATIONS, loadTransorganizationsSaga),
        takeLatest(types.EDIT_TRANSORGANIZATION, editTransorganizationSaga),

        /* color speed */
        takeLatest(types.LOAD_COLOR_SPEED, loadColorSpeedSaga),
        takeLatest(types.LOAD_COLOR_SPEED_LIST, loadColorSpeedListSaga),
        takeLatest(types.ADD_COLOR_SPEED, addColorSpeedSaga),
        takeLatest(types.EDIT_COLOR_SPEED, editColorSpeedSaga),
        takeLatest(types.DELETE_COLOR_SPEED, deleteColorSpeedSaga),
        takeLatest(types.LOAD_ROUTES_GROUPED, loadRoutesGroupedSaga),

        takeLatest(types.LOAD_MILESTONE_EVENTS, loadMilestoneEventsSaga),
        takeLatest(types.LOAD_STATION_POLYGON, loadStationPolygonSaga),
        takeLatest(types.LOAD_STATION_SCHEDULE_BY_DATE, loadStationScheduleByDateSaga),
        takeLatest(types.LOAD_TELEMETRY_RECEIVERS, loadTelemetryReceiversSaga),
        takeLatest(types.ADD_TELEMETRY_RECEIVER, addTelemetryReceiverSaga),
        takeLatest(types.EDIT_TELEMETRY_RECEIVER, editTelemetryReceiverSaga),
        takeLatest(types.DELETE_TELEMETRY_RECEIVER, deleteTelemetryReceiverSaga),
        takeLatest(types.LOAD_TELEMETRY_RECEIVER_TYPES, loadTelemetryReceiverTypesSaga),
        takeLatest(types.COPY_TRAFFIC_SCHEDULE, copyTrafficScheduleSaga),

        takeLatest(types.CHANGE_ROUTE_STATUS, changeRouteStatusSaga),

        takeLatest(types.LOAD_WORK_CALENDAR, loadWorkCalendarSaga),
        takeLatest(types.EDIT_WORK_CALENDAR, editWorkCalendarSaga),
        takeLatest(types.LOAD_WORK_CALENDAR_DAY_TYPES, loadWorkCalendarDayTypesSaga),
        takeLatest(types.LOAD_WORK_CALENDAR_HISTORY, loadWorkCalendarHistorySaga),

        takeLatest(types.DELETE_ROUTE, deleteRouteSaga),

        takeLatest(types.LOAD_VEHICLE_CLASSES, loadVehicleClassesSaga),
        takeLatest(types.CREATE_VEHICLE_CLASS, createVehicleClassSaga),
        takeLatest(types.EDIT_VEHICLE_CLASS, editVehicleClassSaga),
        takeLatest(types.DELETE_VEHICLE_CLASS, deleteVehicleClassSaga),
        takeLatest(types.LOAD_REGULAR_TRANSPORTATIONS, loadRegularTransportationsSaga),
        takeLatest(types.LOAD_SEASONALITY, loadSeasonalitySaga),
        takeLatest(types.LOAD_ROUTE_SORT_DIRECTIONS, loadRouteSortDirectionsSaga),
        takeLatest(types.EDIT_ROUTE_ADDITIONAL_PARAMS, editRouteAdditionalParamsSaga),
        takeLatest(types.LOAD_ROUTE_HISTORY_HUMAN, loadRouteHistoryHumanSaga),

        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),

        // takeLatest(types.LOAD_ROUTE_BY_NUM, loadRouteByNumSaga),

        takeLatest(types.LOAD_ROUTES_BY_NC_CALLBACK, loadRoutesByNCCallbackSaga),

        takeLatest(types.LOAD_EGTS_TRANSMITTER, loadEgtsTransmittersSaga),
        takeLatest(types.ADD_EGTS_TRANSMITTER, addEgtsTransmitterSaga),
        takeLatest(types.EDIT_EGTS_TRANSMITTER, editEgtsTransmitterSaga),
        takeLatest(types.DELETE_EGTS_TRANSMITTER, deleteEgtsTransmitterSaga),
    ]);
}
