import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Card,
    CardContent,
    Collapse,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import ConfirmDelete from 'components/common/ConfirmModal';
import PasswordInput from 'components/common/PasswordInput/PasswordInput';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import classes from './cameras.module.scss';

const AdditionalVideoParams = ({ onChange, data = [] }) => {
    const validation = useSelector(({ validation }) => validation);

    const [isModalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [deletedIndex, setDeletedIndex] = useState(null);

    const { data_types } = useSelector(({ dorisControl }) => dorisControl);

    const labels = {
        id: 0,
        video: titles.VIDEO_STREAM_PROTOCOL_TYPE,
        url: titles.TYPE_URL,
        port: titles.TYPE_PORT,
        username: titles.LOGIN_VIDEO_ACCESS,
        password: titles.PASSWORD
    };

    const handleConfirmDelete = (index) => {
        setDeletedIndex(index);
        setModalDeleteOpen(true);
    };

    const handleAddCard = () => {
        onChange([...data, {}]);
    };

    const handleChangeArray = (event, index) => {
        event.preventDefault();
        const { name, value } = event.target;
        const newCardData = data[index];
        newCardData[name] = value;
        onChange([
            ...data.slice(0, index),
            newCardData,
            ...data.slice(index + 1)
        ]);
    };

    const onSuccessDelete = () => {
        const filteredParams = data.filter((element, index) => index !== deletedIndex);
        onChange(filteredParams);
        setModalDeleteOpen(false);
        setDeletedIndex(null);
    };

    return (
        <Collapse in={true} timeout="auto" unmountOnExit>
            {data?.map((element, index) => (
                <div key={element.url} className={classes.card_block}>
                    <Card className={classes.card} variant="outlined">
                        <CardContent>
                            <div className={classes.card_line}>
                                <FormControl
                                    size="small"
                                    required
                                    error={validation['data.0.type']}
                                    variant="outlined"
                                    className={classes.card_field}
                                >
                                    <InputLabel htmlFor="type" style={{ fontSize: '0.9rem' }}>{labels.video}</InputLabel>
                                    <Select
                                        value={element.type || ''}
                                        label={labels.video}
                                        onChange={(e) => handleChangeArray(e, index)}
                                        name="type"
                                    >
                                        {Object.keys(data_types)?.length
                                            ? Object.keys(data_types)?.map((key) =>
                                                <MenuItem value={key} key={key}>{data_types[key]}</MenuItem>
                                            )
                                            : <MenuItem defaultValue="Нет данных">{messages.NO_DATA}</MenuItem>
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.card_line}>
                                <FormControl required className={classes.card_field} size="small" error={!!validation['data.0.url']} variant="outlined">
                                    <TextField
                                        label={labels.url}
                                        variant="outlined"
                                        size="small"
                                        required
                                        value={element.url || ''}
                                        name={'url'}
                                        onChange={(e) => handleChangeArray(e, index)}
                                        error={validation['data.0.url']}
                                        helperText={validation['data.0.url']}
                                        type="text"
                                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                                    />
                                </FormControl>
                                <FormControl required className={classes.card_field} size="small" error={!!validation['data.0.port']} variant="outlined">
                                    <TextField
                                        label={labels.port}
                                        variant="outlined"
                                        size="small"
                                        required
                                        value={element.port || ''}
                                        name={'port'}
                                        onChange={(e) => handleChangeArray(e, index)}
                                        error={validation['data.0.port']}
                                        helperText={validation['data.0.port']}
                                        type="text"
                                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                                    />
                                </FormControl>
                            </div>
                            <div className={classes.card_line}>
                                <FormControl required className={classes.card_field} size="small" error={!!validation['data.0.username']} variant="outlined">
                                    <TextField
                                        label={labels.username}
                                        variant="outlined"
                                        required
                                        size="small"
                                        value={element.username || ''}
                                        name={'username'}
                                        onChange={(e) => handleChangeArray(e, index)}
                                        error={validation['data.0.username']}
                                        helperText={validation['data.0.username']}
                                        type="text"
                                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                                    />
                                </FormControl>
                                <FormControl required className={classes.card_field} size="small" variant="outlined">
                                    <PasswordInput
                                        value={element.password || ''}
                                        name="password"
                                        label={labels.password}
                                        validation={!!validation['data.0.password']}
                                        helperText={validation['data.0.password']}
                                        onChange={(e) => handleChangeArray(e, index)} 
                                    />
                                </FormControl>
                            </div>
                        </CardContent>
                    </Card>
                    <IconButton
                        onClick={() => handleConfirmDelete(index)}
                        aria-label="delete"
                        size="large">
                        <DeleteIcon />
                    </IconButton>
                </div>
            ))}
            <FormButtonsComponent              
                buttons={[
                    {
                        ...buttonsTypes.add,
                        onClick: handleAddCard,
                    }
                ]}
            />
            <ConfirmDelete
                message={messages.CONFIRM_DELETE}
                open={isModalDeleteOpen}
                onSuccess={onSuccessDelete}
                onClose={() => {
                    setModalDeleteOpen(false);
                    setDeletedIndex(null);
                }}
            />
        </Collapse>
    );
};

export default AdditionalVideoParams;
