// <MapScroll
//     onUpdate={handleScrollUpdate}
//     renderTrackVertical={renderTrackVertical}
//     renderThumbVertical={renderThumbVertical}
// >
//     <LSContainer
//         headers={[
//             { title: 'Дата и время', width: '30%' },
//             ...
//             { title: '', isActions: true }
//         ]}
//     >
//         {list.map(({ id, start_at }) => (
//             <LSContentItem key={id}>
//                 <LSContentColumn>{fullDateTimeWithoutSeconds(start_at)}</LSContentColumn>
//                 ...
//                 <LSContentColumn></LSContentColumn>
//             </LSContentItem>
//         ))}
//
//     </LSContainer>
// </MapScroll>

export const renderTrackVertical = ({ style, ...props }) =>
    <div
        {...props}
        style={{
            ...style,
            width: '6px',
            right: '2px',
            bottom: '2px',
            top: '2px',
            borderRadius: '3px',
            zIndex: 999,
        }}
    />;

export const renderThumbVertical = ({ style, ...props }) =>
    <div
        {...props}
        style={{
            ...style,
            position: 'relative',
            display: 'block',
            width: '100%',
            borderRadius: 'inherit',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            cursor: 'pointer'
        }}
    />;
