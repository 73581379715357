import instance from 'helpers/axios.config';
import { requestConvert, requestReturnCUD } from 'modules/DigitalTwin/utils/helperApi';

import apiUrls from './api.urls';

const ApiMethods = {

    getTypes: async () => {
        const response = await instance.get(apiUrls.getTypes());
        return response.data;
    },

    getNodes: async (params) => {
        const response = await instance.get(apiUrls.getNodes(), { params });
        return response.data;
    },

    createNodes: async (data) => {
        return await instance.post(apiUrls.createNodes(), data);
    },

    editNodes: async (id, data) => {
        return await instance.put(apiUrls.editNodes(id), data);
    },

    deleteNodes: async (id) => {
        return await instance.delete(apiUrls.deleteNodes(id));
    },

    getSidebar: async (params) => {
        const response = await instance.get(apiUrls.getSidebar(), { params });
        return response.data;
    },

    getPolygon: async (params) => {
        const response = await instance.post(apiUrls.getPolygon(), params);
        return response.data;
    },

    getPopup: async (params) => {
        const response = await instance.get(apiUrls.getPopup(), { params });
        return response.data;
    },

    getEdges: async (params) => {
        const response = await instance.get(apiUrls.getEdges(), { params });
        return response.data;
    },

    getEdgeById: async (id) => {
        const response = await instance.get(apiUrls.getEdgeById(id));
        return response.data;
    },

    getEdgeInfoById: async (id) => {
        const response = await instance.get(apiUrls.getEdgeInfoById(id));
        return response.data;
    },

    getNodeById: async (id) => {
        const response = await instance.get(apiUrls.getNodeById(id));
        return response.data;
    },

    getEdgeTypes: async (params) => {
        const response = await instance.get(apiUrls.getEdgeTypes(), { params });
        return response.data;
    },

    getEdgeClasses: async (params) => {
        const response = await instance.get(apiUrls.getEdgeClasses(), { params });
        return response.data;
    },

    createEdge: async (data) => {
        const response = await instance.post(apiUrls.createEdge(), data);
        return requestReturnCUD(response); // временный костыль
    },

    editEdge: async (id, data) => {
        const response = await instance.put(apiUrls.editEdge(id), data);
        return requestReturnCUD(response); // временный костыль
    },

    deleteEdge: async (id) => {
        const response = await instance.delete(apiUrls.deleteEdge(id));
        return requestReturnCUD(response); // временный костыль
    },

    // связи
    getObjectBindings: async (params) => {
        const response = await instance.get(apiUrls.getObjectBindings(), { params });
        return response.data;
    },

    getObjectBindingsAll: async (params) => {
        const response = await instance.get(apiUrls.getObjectBindingsAll(), { params });
        return requestConvert(response.data);
    },

    getObjectBindingsNearly: async (params) => {
        const response = await instance.get(apiUrls.getObjectBindingsNearly(), { params });
        return response.data;
    },

    createObjectBindings: async (data) => {
        const response = await instance.post(apiUrls.createObjectBindings(), data);
        return requestReturnCUD(response); // временный костыль
    },

    updateObjectBindings: async (id, data) => {
        const response = await instance.put(apiUrls.updateObjectBindings(id), data);
        return requestReturnCUD(response); // временный костыль
    },

    deleteObjectBindings: async (id) => {
        const response = await instance.delete(apiUrls.deleteObjectBindings(id));
        return requestReturnCUD(response); // временный костыль
    },
};

export default ApiMethods;
