import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import { LSContainer } from 'components/common/List';
import { reactSelectors } from 'modules/React';
import { loadIncidentTypes } from 'modules/React/redux/actions';
import type { IncidentType } from 'modules/React/reactTypes/incidents';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

const IncidentsTypes = () => {
    const dispatch = useDispatch();

    const list = useSelector(reactSelectors.incidentTypesData);
    const meta = useSelector(reactSelectors.incidentTypesMeta);

    const loading = useSelector(reactSelectors.loadingIncidentTypes);

    const [openModal, setOpenModal] = useState(false);
    const [params, setParams] = useState({ page: 1, data: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const reloadList = (isDelete = false) => {
        const page = isDelete && list.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        dispatch(loadIncidentTypes(page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadIncidentTypes(params.page, limit, params.data));
    }, [dispatch, limit, params.data, params.page]);

    const renderContent = () => {
        return (
            <>
                {list?.length > 0 ? (
                    <LSContainer
                        headers={[
                            { title: '', width: '60px' },
                            { title: titles.NAME, width: '80%' },
                            { title: titles.ACTION, align: 'right', isActions: true },
                        ]}
                    >
                        {list.map((item: IncidentType) => (
                            <Item
                                key={item.id + item.name}
                                item={item}
                                reloadList={reloadList}
                            />
                        ))}
                    </LSContainer>
                ) : (
                    !loading && <NoData />
                )}
            </>
        );
    };

    return (
        <>
            <PageLayout
                header="Типы инцидентов"
                filters={<Filter setParams={setParams} />}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <FormButtons
                            noMarginLeft
                            noPadding
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                },
                            ]}
                        />
                    ),

                    total: meta.total,
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />

            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default IncidentsTypes;
