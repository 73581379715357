const filterList = {
    name: {
        key: 'name',
        f: (filter /* строка */) => (value /* строка */) => value?.includes(filter)
    },
    ip_list: {
        key: 'ip_list',
        f: (filter /* строка */) => (value /* строка */) => value?.includes(filter)
    },
    status_list: {
        key: 'status_list',
        f: (filter /* строка */) => (value /* строка */) => value?.toLowerCase()?.includes(filter.toLowerCase())
    },
    serial_number_list: {
        key: 'serial_number_list',
        f: (filter /* массив */) => (value /* строка */) => filter?.includes(value) // filter - массив
    },
    address: {
        key: 'complex_id',
        f: (filter) => (value) => filter.map(({ id }) => id).includes(value)
    },
    contract_id_list: {
        key: 'contract_id_list',
        f: (filter) => (value) => filter.map(({ id }) => id).includes(value)
    },
    id_list: {
        key: 'id_list',
        f: (filter) => (value) => filter.map(({ id }) => id).includes(value)

    }
};

export default filterList;
