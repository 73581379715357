import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';

import { deletePillar } from 'modules/InfrastructureObjects/redux/actions';
import { roadNetworksSelectors } from 'modules/InfrastructureObjects';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import titles from 'helpers/constants/titles';

import ModalForm from './ModalForm';
import Info from './Info';

import type { PillarsItem } from 'types/RoadNetwork';

interface ItemProps {
    item: PillarsItem
    reloadList: () => void
}

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();
    const  loadingButton  = useSelector(roadNetworksSelectors.loadingButton);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isInfo, setIsInfo] = useState(false);

    const handleInfo = () => setIsInfo(!isInfo);

    const handleDelete = () => {
        const callback = () => {
            setIsDelete(false);
            reloadList();
        };

        dispatch(deletePillar(item.id, callback));
    };

    return (
        <>
            <LSContentItem onClick={handleInfo} >
                <LSContentColumn>
                    {item.custom_icon && <img
                        className="flex-center"
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            margin: 0,
                        }}
                        src={item.custom_icon}
                        alt="custom_icon"
                    />}
                </LSContentColumn>

                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.type_text || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {item.address_text || messages.NO_VALUE}
                </LSContentColumn>

                <LSContentColumn>
                    {prepareCoords(item.lat, 2)}/{prepareCoords(item.lon, 2)}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <Edit fontSize="inherit"/>,
                                name: titles.EDIT,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                icon: <Delete fontSize="inherit"/>,
                                name: titles.DELETE,
                                onClick: () => setIsDelete(true),
                            }
                        ]}
                    />

                    <ActionMore
                        isOpen={isInfo}
                        // onClick={handleInfo}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isInfo && (
                <Info
                    isOpen={isInfo}
                    data={item}
                />
            )}

            {isDelete && (
                <ConfirmDelete
                    open={isDelete}
                    onClose={()=> setIsDelete(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    loadingButton={loadingButton}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default Item;
